import styled from 'styled-components'
import { media } from 'style/breakpoints'

const Container = styled.div.attrs({ className: 'frame' })`
    && {
        margin-left: 0;
        margin-right: 0;

        ${media.xlMin`
            margin-left: auto;
            margin-right: auto;
        `}
    }
`

export default Container
