import config from 'config'

const oauthConfig = {
    linkedIn: {
        clientId: config.branding.sharing.linkedIn.clientId,
        authUrl: 'https://www.linkedin.com/oauth/v2/authorization',
        scope: 'r_basicprofile w_member_social rw_organization_admin w_organization_social',
        redirectUri: (widgetVariant: string, currentLocation: string) => {
            const url = new URL(currentLocation)
            const params = new URLSearchParams(url.search)

            ;['code', 'state', 'error', 'error_description'].forEach((param) => {
                if (params.has(param)) {
                    params.delete(param)
                }
            })

            params.set('widgetVariant', widgetVariant)

            url.search = params.toString()
            return url.toString()
        },
    },
}

const generateCSRFToken = (): string => {
    const array = new Uint32Array(10)
    window.crypto.getRandomValues(array)
    return array.join('')
}

const storeCSRFToken = (token: string): void => {
    sessionStorage.setItem('engage:branding:oauthCSRFToken', token)
}

const retrieveCSRFToken = (): string | null => {
    return sessionStorage.getItem('engage:branding:oauthCSRFToken')
}

const generateAuthUrl = (platform: keyof typeof oauthConfig, widgetVariant: string): string => {
    const state = generateCSRFToken()
    storeCSRFToken(state)
    const { clientId, authUrl, scope, redirectUri } = oauthConfig[platform]
    const currentLocation = window.location.href

    return `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectUri(widgetVariant, currentLocation)
    )}&state=${state}&scope=${scope}`
}

export const redirectToAuthPage = (platform: keyof typeof oauthConfig, widgetVariant: string): void => {
    window.location.href = generateAuthUrl(platform, widgetVariant)
}

export const getAuthCodeAndWidgetFromURL = (): {
    authCode: string | null
    widgetVariant: string | null
    state: string | null
    error: string | null
    errorDescription: string | null
} => {
    const params = new URLSearchParams(window.location.search)
    return {
        authCode: params.get('code'),
        widgetVariant: params.get('widgetVariant'),
        state: params.get('state'),
        error: params.get('error'),
        errorDescription: params.get('error_description'),
    }
}

export const validateState = (returnedState: string | null): boolean => {
    const storedState = retrieveCSRFToken()
    return storedState === returnedState
}
