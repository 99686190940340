import { Button } from '@energage/components'
import cx from 'clsx'
import type { HeaderData } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import twpLogoNoYear from 'images/awardAnnouncement/twpLogoNoyear.svg'
import { useDownloadAwardAnnouncement } from './useDownloadAwardAnnouncement'

export const StickyHeader = ({ awardListParticipantId, showHeaderBg }: HeaderData) => {
    const { downloadAnnouncementPage, isDownloading } = useDownloadAwardAnnouncement(awardListParticipantId)
    return (
        <div
            className={cx(
                'fixed w-full bg-opacity-75 h-16 z-40 flex items-center justify-end',
                showHeaderBg && 'bg-white'
            )}>
            {showHeaderBg && <img src={twpLogoNoYear} alt="twp" className="h-12 pb-2 pl-3 mr-auto" />}
            <Button
                className={cx('mr-12 md:mr-16 mt-1 h-9 bg-purple700 text-white', {
                    'mr-12': showHeaderBg,
                })}
                isDisabled={isDownloading}
                onPress={downloadAnnouncementPage}>
                {`Download Announcement`}
            </Button>
        </div>
    )
}
