import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useWorkplaceSurveyQuery } from 'api'
import type { QueryConfig, ResponseError } from 'api'
import showErrorAlert from 'util/showErrorAlert'

export type SurveyScopeResponse = {
    isTopWorkplaceParticipant: boolean
    isRandomSample: boolean
    hasAllLocationsSurveyed?: boolean
    inviteeCount: number
    isEmployeeCountConfirmed: boolean
    regionsConfirmedEmployeeTotal: number
    hasNationalAward: boolean
    isInviteeCountSubstantiallyDifferentFromRegionalCounts: boolean
    hasMinimumQualifyingInviteeCount: boolean
    minimumInviteeCountForRandomSampling: number
}

export function useSurveyScope(
    surveyEventId: number | string,
    queryOptions: QueryConfig<SurveyScopeResponse, ResponseError<string>> = {}
) {
    const queryClient = useQueryClient()
    const [saveComplete, setSaveComplete] = useState(false)

    const { mutateAsync: saveSurveyScope, isLoading: isSurveyScopeSaving } = useWorkplaceSurveyQuery.mutate<
        void,
        ResponseError<string>,
        { isRandomSample: boolean; hasAllLocationsSurveyed: boolean | null },
        () => void
    >(`surveyEvent/${surveyEventId}/surveyScope`, {
        onMutate: (data) => {
            const currentData = queryClient.getQueryData<SurveyScopeResponse>(['survey-event-scope', surveyEventId])
            queryClient.setQueryData(['survey-event-scope', surveyEventId], {
                ...currentData,
                isRandomSample: data.isRandomSample,
                hasAllLocationsSurveyed: data.hasAllLocationsSurveyed,
            })
            return () => queryClient.setQueryData(['survey-event-scope', surveyEventId], currentData)
        },
        onError: (error, _: unknown, rollback) => {
            rollback?.()
            showErrorAlert('Error while saving survey scope', error.parsed)
        },
        onSettled: () => {
            setSaveComplete(true)
            queryClient.invalidateQueries(['survey-event-scope', surveyEventId])
            setTimeout(() => setSaveComplete(false), 2000)
        },
    })

    const surveyScopeSaveState = isSurveyScopeSaving ? 'Saving...' : saveComplete ? 'Changes saved' : null

    const { data: surveyScope, isLoading: isLoadingSurveyScope } = useWorkplaceSurveyQuery<
        SurveyScopeResponse,
        ResponseError<string>
    >(['survey-event-scope', surveyEventId], `SurveyEvent/${surveyEventId}/surveyScope`, {
        ...queryOptions,
        onError: (error) => showErrorAlert('There was an error loading survey scope data', error.parsed),
    })

    const handleChangeWhoYouAreSurveying = useCallback(
        (hasAllLocationsSurveyed: boolean | null, isRandomSample: boolean) => {
            if (
                hasAllLocationsSurveyed === surveyScope?.hasAllLocationsSurveyed &&
                isRandomSample === surveyScope?.isRandomSample
            ) {
                return
            }

            return saveSurveyScope({
                hasAllLocationsSurveyed,
                isRandomSample,
            })
        },
        [saveSurveyScope, surveyScope]
    )

    return {
        surveyScope: surveyScope,
        surveyScopeSaveState,
        handleChangeWhoYouAreSurveying,
        isLoadingSurveyScope,
    }
}
