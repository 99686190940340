import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import head from 'lodash/head'
import map from 'lodash/map'
import styled from 'styled-components'
import { Filters } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { previewType, TileWidth } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getFilterQueryParams, getSelectedFiltersRecord } from '../Widgets/utils'
import { Watermark } from '../Widgets/Watermark'
import { OrgStrengthsCirculerePreview } from './OrgStrengthsCirculerPreview'
import { OrgStrengthsReelsPreview } from './OrgStrengthsReelsPreview'
import { OrgStrengthsTrangulerPreview } from './OrgStrengthsTrangulerPreview'
import SelectedFilterPills from './SelectedFilterPills'
import Tile, { tileTypes } from './Tile'
import useFetchPublicPage from './useFetchPublicPage'

const Frame = styled.div.attrs(({ isExpiredWidget, orgStrenghtFontSize }) => ({
    className: cx('p-4', { 'opacity-25': isExpiredWidget }, orgStrenghtFontSize),
}))`
    filter: blur(${(props) => (props.isSubscribed ? '0px' : '10px')});

    ${(props) =>
        props.isPdf &&
        `
        padding: 32px;

        & > blockquote {
            & h4 {
                font-size: 3.8rem;
            }

            & p {
                font-size: 40px;
            }
        }
    `}
`

const StrengthsList = ({ isVisible, orgStrengths, orgStrenghtFontSize, isExpiredWidget, isPdf }) => (
    <Frame
        isSubscribed={isVisible}
        isExpiredWidget={isExpiredWidget}
        orgStrenghtFontSize={orgStrenghtFontSize}
        isPdf={isPdf}>
        {isExpiredWidget && <Watermark />}
        {map(orgStrengths, (strength, index) => (
            <blockquote
                className={cx(isPdf ? 'mb-8 mt-4 px-6' : 'mb-5 mt-2 px-3', 'relative leading-normal')}
                key={strength.name}>
                <h4>{strength.name}</h4>
                <p className="mt-1">{strength.text}</p>
                {index < orgStrengths.length - 1 && <hr className={isPdf ? 'my-8' : 'my-4'} />}
            </blockquote>
        ))}
    </Frame>
)

const orgStrengthsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })
).isRequired

StrengthsList.propTypes = {
    orgStrengths: orgStrengthsPropType,
}

const OrganizationalStrengths = forwardRef(
    (
        {
            title,
            orgStrengths,
            backgroundColor,
            className,
            headerBgColor,
            headerTextColor,
            type,
            width,
            height,
            orgStrenghtFontSize,
            dataId,
            isExpiredWidget,
            filterData,
            hasEmployerBrandingSubscription,
            isPdf,
        },
        ref
    ) => (
        <Tile
            header={title}
            bgColor={backgroundColor}
            className={className}
            headerBgColor={headerBgColor}
            headerTextColor={headerTextColor}
            ref={ref}
            type={type}
            width={TileWidth(isPdf, width)}
            height={height}
            dataId={dataId}
            pills={SelectedFilterPills}
            blurTileBody={!hasEmployerBrandingSubscription}
            filterData={filterData}
            isPdf={isPdf}>
            <>
                {head(orgStrengths) ? (
                    <StrengthsList
                        orgStrengths={orgStrengths}
                        orgStrenghtFontSize={orgStrenghtFontSize}
                        isExpiredWidget={isExpiredWidget}
                        isVisible={hasEmployerBrandingSubscription}
                        isPdf={isPdf}
                    />
                ) : (
                    <InsufficientDataWarning warningText="Not enough data for Organizational Strengths" />
                )}
            </>
        </Tile>
    )
)

OrganizationalStrengths.propTypes = {
    title: PropTypes.string.isRequired,
    orgStrengths: orgStrengthsPropType,
    type: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    orgStrenghtFontSize: PropTypes.number,
    dataId: PropTypes.string,
}
export default OrganizationalStrengths

export function OrganizationalStrengthsFetch({ location }) {
    const parsedQuery = parseQueryParams(location)
    const query = getFilterQueryParams(parsedQuery)
    const {
        companyId,
        surveyEventId,
        isDownloadable,
        orgStrengthDesignType,
        orgStrengthTitle,
        orgStrengthText,
        isReels,
    } = parsedQuery

    const { data, isLoading, error } = useFetchPublicPage(
        'orgStrengths-filter',
        `SurveyCompanies/${companyId}/culturefacts/orgstrengths/${surveyEventId}/${isDownloadable}?${query}`
    )
    const {
        data: surveyEvents,
        isLoading: isLoadingSurveyEvents,
        error: surveyEventsError,
    } = useFetchPublicPage(
        'ContentSubFilters',
        `SurveyCompanies/${companyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}&${query}`
    )

    if (isLoading || isLoadingSurveyEvents) {
        return null
    }

    if (error || surveyEventsError) {
        return null
    }

    if (orgStrengthDesignType === previewType.TriangleDesign && !isReels) {
        return (
            <OrgStrengthsTrangulerPreview
                name={orgStrengthTitle}
                text={orgStrengthText}
                width="1080px"
                height="1080px"
                backgroundColor={data?.backgroundColor}
                highlightColor={data?.highlightColor}
                textColor={data?.textColor}
                isDownloadable={isDownloadable}
            />
        )
    }

    if (orgStrengthDesignType === previewType.CircleDesign && !isReels) {
        return (
            <OrgStrengthsCirculerePreview
                name={orgStrengthTitle}
                text={orgStrengthText}
                width="1080px"
                height="1080px"
                backgroundColor={data?.backgroundColor}
                highlightColor={data?.highlightColor}
                textColor={data?.textColor}
                isDownloadable={isDownloadable}
            />
        )
    }

    if (isReels) {
        return <OrgStrengthsReelsPreview data={data} selectedDesignType={orgStrengthDesignType} />
    }

    return (
        <Filters.Provider
            value={{
                filterData: surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery),
            }}>
            <OrganizationalStrengths
                {...data}
                type={tileTypes.vertical}
                filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                hasEmployerBrandingSubscription
                width="1080px"
                isPdf
            />
        </Filters.Provider>
    )
}
