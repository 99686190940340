import PropTypes from 'prop-types'
import { Display1, Display4, RadialChart } from '@energage/charts'
import { Text } from '@energage/typography'
import styled from 'styled-components'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../Common/SummaryCard'

const Chip = styled((props) => <ChipComponent {...props} />)`
    @media (max-width: 765px) {
        width: auto;
    }
`

const ResponsiveLg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 97px;
    height: auto;
    margin-left: 11px;
    margin-right: 11px;

    @media (max-width: 765px) {
        margin-left: 0;
        margin-right: 0;
    }
`
function ChipComponent({ children, className }) {
    return (
        <Text
            textStyle="label"
            aria-hidden="true"
            className={`rounded bg-blue500 text-center px-comfortable text-white inline-block ${className}`}>
            {children}
        </Text>
    )
}

const ErrorResponseRate = () => (
    <SummaryCard padding="tight" status="error">
        <CardContent className="items-center justify-center">
            <p>{'Something went wrong'}</p>
        </CardContent>
    </SummaryCard>
)

const ResponseRateTile = ({ responseRate, error, onDownload, inviteeCount, responderCount }) => {
    const roundUpOrDown = (num) => {
        const parsedNum = parseFloat(num) * 100
        return parsedNum % 1 >= 0.5 ? Math.ceil(parsedNum) : Math.floor(parsedNum)
    }

    return (
        <SummaryCard padding="tight">
            <CardIcon className="justify-center" ariaLabel={`${roundUpOrDown(responseRate)} percent, In Progress.`}>
                {error ? (
                    <ErrorResponseRate />
                ) : (
                    <ResponsiveLg>
                        <RadialChart
                            fillColor="#0a83ac"
                            value={Math.ceil((parseFloat(responseRate) * 100).toFixed(1))}
                            width={97}
                            variant="donut"
                            role="img">
                            <Display1
                                fill="#313239"
                                fontFamily="Libre Franklin"
                                fontWeight={700}
                                alignmentBaseline="middle"
                                textAnchor="middle"
                                aria-hidden="true"
                                y={10}
                                x={-5}>
                                {`${roundUpOrDown(responseRate)}`}
                            </Display1>
                            <Display4
                                fill="#313239"
                                fontFamily="Libre Franklin"
                                fontWeight={400}
                                alignmentBaseline="middle"
                                aria-label={`${roundUpOrDown(responseRate)} percent, In Progress.`}
                                textAnchor="middle"
                                y={0}
                                x={roundUpOrDown(responseRate) < 10 ? 10 : 20}>
                                {`%`}
                            </Display4>
                            <Chip aria-hidden="true">{'In Progress'}</Chip>
                        </RadialChart>
                        {parseFloat(responseRate) < 1 && <Chip>{'In Progress'}</Chip>}
                    </ResponsiveLg>
                )}
            </CardIcon>
            <CardBody>
                <CardContent className="p-1">
                    <CardTitle className="mb-2">{'Response Rate'}</CardTitle>
                    <p>{`${responderCount} out of ${inviteeCount} invitees completed the survey. Response rate is approximate until survey is completed.`}</p>
                </CardContent>
                <div className="w-fit p-1 cursor-pointer">
                    <SummaryButton onPress={onDownload} className="w-fit" buttonText="Download Response Rates" />
                </div>
            </CardBody>
        </SummaryCard>
    )
}

ResponseRateTile.propTypes = {
    className: PropTypes.string,
    responseRate: PropTypes.number.isRequired,
    currentDate: PropTypes.string,
    previousDate: PropTypes.string,
    onDownload: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            currentValue: PropTypes.number.isRequired,
            previousValue: PropTypes.number,
        })
    ),
}

export default ResponseRateTile
