import { AdditionalChances } from 'containers/Main/AwardAnnouncement/Aspirant/AdditionalChances'
import { CommunicateWithLeaders } from 'containers/Main/AwardAnnouncement/Aspirant/CommunicateWithLeaders'
import { FeatureYourCulture } from 'containers/Main/AwardAnnouncement/Aspirant/FeatureYourCulture'
import { ThankYouForParticipating } from 'containers/Main/AwardAnnouncement/Aspirant/ThankYouForParticipating'
import { WhatsNext as WhatsNextAspiring } from 'containers/Main/AwardAnnouncement/Aspirant/WhatsNext'
import type { ClassicAspiringAwardPdf } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { BgContainer } from 'containers/Main/AwardAnnouncement/BgContainer'
import colors from 'containers/Main/AwardAnnouncement/colors'
import { COMPANY_PROFILE_URL, FAQ_URL } from 'containers/Main/AwardAnnouncement/constants'
import { PageBreaker } from './AwardAnnouncementPdf'
import { FAQs } from './FAQs'

export const ClassicAspiringPdf = ({ award, baseUrl }: ClassicAspiringAwardPdf) => {
    const { companyName, year, listName, lastSurveyCloseDate, publicWebsiteCode, lastYearAwards } = award

    return (
        <>
            <BgContainer backgroundColor={colors.bgBlue} isDefaultBgHeight>
                <ThankYouForParticipating
                    companyName={companyName}
                    listName={listName}
                    lastYearAwards={lastYearAwards}
                    colors={{
                        bgColor: colors.bgLightGrey,
                        textColor: colors.textBlue,
                    }}
                    baseUrl={baseUrl}
                    isPdf
                />
                <WhatsNextAspiring companyName={companyName} bgColor={colors.bgBlue} />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.bgLightGrey} isDefaultBgHeight>
                <FeatureYourCulture
                    companyName={companyName}
                    colors={{
                        bgColor: colors.bgLightGrey,
                        textColor: colors.textBlue,
                    }}
                    baseUrl={baseUrl}
                    isPdf
                />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.bgLightGrey} isDefaultBgHeight>
                <AdditionalChances lastSurveyCloseDate={lastSurveyCloseDate} bgColor={colors.bgBlue} />
                <CommunicateWithLeaders
                    companyName={companyName}
                    year={year}
                    colors={{
                        bgColor: colors.bgLightGrey,
                        textColor: colors.textBlue,
                    }}
                />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.bgBlue} isDefaultBgHeight>
                <FAQs
                    isWinner={false}
                    companyProfileUrl={`${COMPANY_PROFILE_URL}${publicWebsiteCode}`}
                    faqUrl={FAQ_URL}
                    companyName={companyName}
                    year={year}
                    colors={{
                        bgColor: colors.bgBlue,
                        textColor: '',
                    }}
                />
            </BgContainer>
        </>
    )
}
