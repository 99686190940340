import type { ReactNode } from 'react'
import cx from 'clsx'
import styled from 'styled-components'
import { DatePicker, datePickerStyles, TimePicker } from 'components/DateTimePicker'
import { ErrorMessage } from './surveyInvitationValidation'

const Section = styled.div.attrs({ className: 'text-sm mb-4' })`
    ${datePickerStyles};
`

type EmailEventProps = {
    children?: ReactNode
    title: ReactNode
    timeTitle?: ReactNode
    dateTime: Date
    readOnly?: boolean
    minDate: Date
    maxDate: Date
    holidays: Date[]
    onDateChange: (date: Date, isTimeChange: boolean) => void
    validationInfo?: {
        hasError: boolean
        errorType: number
        errorAfterMaxCloseDate?: string
    }
    timePickerDisabled?: boolean
}
const EmailEvent = ({
    children = null,
    title,
    timeTitle = 'Send Time',
    dateTime,
    readOnly,
    minDate,
    maxDate,
    holidays,
    onDateChange,
    validationInfo,
    timePickerDisabled = false,
}: EmailEventProps) => {
    const currentDate = new Date()
    const editableMinDate = minDate < currentDate ? currentDate : minDate

    return (
        <Section>
            <div className="flex  mb-1 text-grey500">
                <div className={cx('w-40', validationInfo?.hasError && 'text-red400 font-bold')}>{title}</div>
                <div className="w-32 mr-2">{timeTitle}</div>
            </div>
            <div className="flex items-center">
                <DatePicker
                    disabled={readOnly}
                    inputClassName={validationInfo?.hasError && 'border-red400'}
                    date={dateTime}
                    minDate={editableMinDate}
                    maxDate={maxDate}
                    holidays={holidays}
                    onChange={(date: Date) => onDateChange(date, false)}
                    readonly
                />
                <TimePicker
                    disabled={readOnly || timePickerDisabled}
                    date={dateTime}
                    onChange={(date: Date) => onDateChange(date, true)}
                />
                {children}
            </div>
            {validationInfo?.hasError && (
                <div className="flex text-red400 mb-1">
                    <div className="w-40">
                        {ErrorMessage[validationInfo.errorType]} {validationInfo?.errorAfterMaxCloseDate}
                    </div>
                </div>
            )}
        </Section>
    )
}

export default EmailEvent
