import noop from 'lodash/noop'
import { useMutation } from 'react-query'
import { api } from 'api'
import config from 'config'
import { getError } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'

const RETRY_COUNT = 3

const notificationDismissed = async ({ notificationId, userId }) =>
    await api.patch(`${config.api.platform}/Api/Notifications/${notificationId}`, {
        json: userId,
    })

const showErrorMsg = async (error) =>
    showErrorAlert('There was an error in dismissing notification!', await getError(error))

const useDismissNotification = (onSuccess = noop) => {
    const { mutateAsync: dismiss, status } = useMutation(notificationDismissed, {
        onSuccess,
        onError: showErrorMsg,
        retry: RETRY_COUNT,
        refetchOnMount: false,
    })

    return {
        dismiss,
        isDismissing: status === 'loading',
    }
}

export default useDismissNotification
