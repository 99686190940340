import reduce from 'lodash/reduce'
import curry from 'lodash/fp/curry'

const generateObject = curry((attributes, item) =>
    reduce(
        attributes,
        (data, attribute, name) => ({
            ...data,
            [name]: attribute(item),
        }),
        {}
    )
)

export default generateObject
