import { forwardRef } from 'react'
import { useMediaQuery } from '@energage/hooks'
import { colors } from '@energage/theme'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import { LONG_ISLAND_PUBLISHER_ID, TWP_USA_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { viewports } from 'style/breakpoints'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getBadge } from '../Widgets/utils'
import Tile from './Tile'

const MAX_COMPANY_NAME_LENGTH = 55

const MultiYearTWPBadge = forwardRef(
    (
        {
            isPngDownload,
            className,
            backgroundColor,
            companyName,
            year,
            yearsRange,
            namedStreakYears,
            awardLogoUrl,
            publisherId,
            isFileDownload,
        },
        ref
    ) => {
        const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMin })
        const { Component, logoClassName, applyDynamicHeight } = getBadge(awardLogoUrl, publisherId)
        const widgetText = `${companyName} is a ${year} Top Workplace!`
        const isTWPUSA = publisherId === TWP_USA_PUBLISHER_ID
        const minHeight = applyDynamicHeight ? (companyName.length > MAX_COMPANY_NAME_LENGTH ? 250 : 200) : null
        const isPdfDownload = !isPngDownload && isFileDownload && isMobileDevice
        const width = useMediaQuery({ minWidth: viewports.smMin }) ? (isTWPUSA ? 400 : 350) : isPdfDownload ? 594 : 280
        const tileWidth = isPngDownload ? 1400 : width
        const isLongIslandPublisherId = publisherId === LONG_ISLAND_PUBLISHER_ID

        return (
            <Tile
                width={tileWidth + 'px'}
                minHeight={minHeight}
                className={className}
                bgColor={colors.transparent}
                footerBackgroundColor={backgroundColor}
                ref={ref}>
                <Component
                    companyName={companyName}
                    widgetText={widgetText}
                    awardLogoUrl={awardLogoUrl}
                    yearsRange={yearsRange}
                    namedStreakYears={namedStreakYears}
                    logoClassName={logoClassName}
                    badgeYear={year}
                    isPngDownload={isPngDownload}
                    isLongIslandPublisherId={isLongIslandPublisherId}
                    isPdfDownload={isPdfDownload}
                    publisherId={publisherId}
                />
            </Tile>
        )
    }
)

const StyledMultiYearBadge = styled(MultiYearTWPBadge)`
    svg {
        width: 110px;
    }
`

export default StyledMultiYearBadge

export function MultiYearBadgeFetch({ location }) {
    const { id, awardListParticipantId, isFilterEnabled, tileTypeId, isPngDownload } = parseQueryParams(location)
    const { data } = useBrandingQuery(
        ['surveyCompanies', id, awardListParticipantId],
        `SurveyCompanies/${id}/${isFilterEnabled ? `Tile/${tileTypeId}/${awardListParticipantId}` : 'multiyearbadges'}`
    )

    if (!data) {
        return null
    }

    return <StyledMultiYearBadge isPngDownload={isPngDownload} isFileDownload {...data} />
}

export function MultiYearBadge({ location }) {
    const {
        backgroundColor,
        companyName,
        year,
        yearsRange,
        namedStreakYears,
        awardLogoUrl,
        publisherId,
        isPngDownload,
    } = parseQueryParams(location)

    return (
        <StyledMultiYearBadge
            backgroundColor={backgroundColor}
            companyName={companyName}
            year={year}
            yearsRange={yearsRange}
            namedStreakYears={namedStreakYears}
            awardLogoUrl={awardLogoUrl}
            publisherId={publisherId}
            isPngDownload={isPngDownload}
            isFileDownload
        />
    )
}
