import cx from 'clsx'

const withClassName = (addClassName, Component, displayName = Component.displayName) => {
    const WrappedComponent = ({ className = '', ...props }) => (
        <Component {...props} className={cx(className, addClassName)} />
    )
    WrappedComponent.displayName = displayName
    return WrappedComponent
}

export default withClassName
