import QuestionCategory from '../QuestionCategory'

export const HELP_TEXT = {
    [QuestionCategory.BusinessOutcomes]:
        'These statements provide an approximation of business outcomes that can be measured and benchmarked across all industries.',
    [QuestionCategory.Template]: "These are custom items created as part of this survey's template.",
    [QuestionCategory.Leader]:
        "Results for these questions are used a bit differently. The leader question's results are only available to one person, while the 3-word culture question forms a culture cloud that can help you and others understand your culture better.",
    [QuestionCategory.TheBasics]:
        'These statements measure things that cost your organization time and money, so the important thing is to know where you stand and meet expectations set in the hiring process.',
    [QuestionCategory.Other]:
        "Results for these questions are used a bit differently. The leader question's results are only available to one person, while the 3-word culture question forms a culture cloud that can help you and others understand your culture better.",
    [QuestionCategory.CultureDrivers]:
        'These statements provide deep insights into how your organization is working. Doing well on these statements indicates a healthy organization, which means more engaged employees and better business results.',
}

const CATEGORY_DESCRIPTIONS = {
    [QuestionCategory.Template]: "These are custom items created as part of this survey's template.",
    [QuestionCategory.HealthCare]:
        'These statements are specific to areas that are important to organizations in the healthcare sectors.  Understanding how your organization performs against these statements can help you decide how to focus your efforts to improve employee and patient experience.',
    [QuestionCategory.Regional]:
        'Regional questions are used by media partners to inform research and special awards. They do not appear in results by default.',
    [QuestionCategory.OperationalReadiness]: 'Assess the readiness and response of your organization to COVID-19',
    [QuestionCategory.OperationalReadinessWorkingRemotely]:
        "Assess the readiness and execution of your organization's remote policies.",
    [QuestionCategory.OperationalReadinessCommunicationCollaboration]:
        'Assess the collaboration and communication of your organization during complex times.',
    [QuestionCategory.OperationalReadinessReturnToWork]: 'Learn how employees are feeling about the return to work.',
    [QuestionCategory.Other]:
        "Results for these questions are used a bit differently. The leader question's results are only available to one person, while the 3-word culture question forms a culture cloud that can help you and others understand your culture better.",
    [QuestionCategory.DEI]: (
        <>
            <p>
                {
                    "Understanding how different groups view your organization's culture is important to providing a positive work environment for all employees."
                }
                <span className="font-bold">{`The responses to these statements are not included in your Insights by default.`}</span>
            </p>
            <p className="mt-3">
                {
                    'You may opt-out of including any of these questions on your survey simply by unchecking the boxes. If you would like to gain access to these results please contact your Customer Success Manager.'
                }
            </p>
        </>
    ),
}

export const getCategoryDescription = (categoryId) => {
    return CATEGORY_DESCRIPTIONS[categoryId] ?? null
}

export const categoryNameOverrideMap = {
    [QuestionCategory.Other]: 'Other Items',
    [QuestionCategory.Template]: 'Template',
    [QuestionCategory.DEI]: 'Diversity, Equity, and Inclusion',
    [QuestionCategory.Demographic]: 'Demographic Questions',
    [QuestionCategory.Custom]: 'Additional Questions',
    [QuestionCategory.BusinessOutcomes]: 'Engagement',
    [QuestionCategory.CultureDrivers]: 'Culture Drivers',
    [QuestionCategory.Regional]: 'Regional Specific Items',
}

export function getCategoryNameOverride({ categoryId, categoryName }) {
    return categoryNameOverrideMap[categoryId] ?? categoryName
}

export const THREE_WORD_CULTURE_QUESTION_ESSENCE_ID = 60003
export const LEADER_QUESTION_ESSENCE_ID = 20037
