export const EnergageCeoStatement =
    "“Earning a Top Workplaces award is a badge of honor for companies, especially because it comes authentically from their employees,” said Eric Rubino, Energage CEO. “That's something to be proud of. In today's market, leaders must ensure they’re allowing employees to have a voice and be heard. That's paramount. Top Workplaces do this, and it pays dividends.”"

export const BOSTON_PUBLISHER_ID = 2
export const DALLAS_PUBLISHER_ID = 7
export const PHILADELPHIA_INQUIRER_PUBLISHER_ID = 8
export const MINNEAPOLIS_PUBLISHER_ID = 13
export const LONG_ISLAND_PUBLISHER_ID = 93
export const TWP_USA_PUBLISHER_ID = 669
export const REMOTE_WORK_PUBLISHER_ID = 5132
export const CE_AND_INDUSTRY_PUBLISHER_ID = 98
export const COLUMBUS_PUBLISHER_ID = 33
export const WASHINGTON_POST_PUBLISHER_ID = 37
export const EXCLUDED_PUBLISHER_IDS = [2, 37]

export const CE_AWARD_NAME = 'Culture Excellence'
export const TWP_USA_AWARD_NAME = 'Top Workplaces USA'
export const INDUSTRY_AWARD_NAME = 'Industry'

export const EMPLOYEE_FEEDBACK_URL = 'https://www.energage.com/survey/'
export const TOPWORKPLACES_URL = 'http://bit.ly/NTW13'

export const MIN_PUBLISHER_YEAR = 2018
export const YEAR_2020 = 2020
export const YEAR_2021 = 2021
export const YEAR_2022 = 2022
export const YEAR_2024 = 2024

export const ALWAYS_SELECTED_TILE_TYPES = [7]
export const MEDIA_KIT_LITE_DISABLED_TILE_TYPES = [1, 2, 3, 4, 5, 7]
export const MEDIA_KIT_SECTIONS = {
    LOGO_SET: 'logoSet',
    CULTURE_EXCELLENCE: 'cultureExcellence',
    INDUSTRY_AWARDS: 'industryAwards',
    NATIONAL_AWARDS: 'nationalAwards',
    REGIONAL_AWARDS: 'regionalAwards',
    LINKED_IN: 'linkedIn',
    BRAND_GUIDELINES: 'brandGuidelines',
    VIRTUAL_BACKGROUND: 'virtualBackground',
    PRESS_RELEASE: 'pressRelease',
    AWARD_ANNOUNCEMENT: 'awardAnnouncement',
    AWARD_CHECK_LIST: 'awardCheckList',
    ANNOUNCEMENT_LETTER: 'announcementLetter',
    PUBLICITY_GUIDE: 'publicityGuide',
    GAIN_ACCESS: 'gainAccess',
}

export const DOWNLOADABLE_PDF_FORMAT = { downloadableFileFormats: [{ id: 1, name: 'PDF' }] }

export const OFFICE_APPS_EMBED_URL = 'https://view.officeapps.live.com/op/embed.aspx'

export const TRACKING_CLASS = {
    COLLAPSE: 'mk-collapse-',
    DOWNLOAD_ASSETS: 'mk-download-assets',
    EXPAND: 'mk-expand-',
    SELECT: 'mk-select-',
    SELECT_ALL: 'mk-select-all',
    SELECT_BAR_BOTTOM: 'mk-select-bar-bottom',
    SELECT_BAR_TOP: 'mk-select-bar-top',
    SELECT_MULTI_REGION: 'mk-select-MultiRegion',
    TOGGLE: 'mk-toggle-',
}
