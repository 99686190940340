import { Group } from '@energage/icons'
import { colors } from '@energage/theme'
import { SUPPORT_EMAIL } from 'constants/strings'
import pluralize from 'util/pluralize'
import SurveyFormats from '../../../Recipients/FormatSurvey/SurveyFormats'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryCard } from '../../Common/SummaryCard'

function InvitationText({ hasSmsConsent, emailOnlyInviteeCount, emailAndSmsInviteeCount, smsOnlyInviteeCount }) {
    let resultString = ``

    const pluralizeInvitations = (count) => (count === 1 ? 'invitation' : 'invitations')

    if (emailOnlyInviteeCount > 0) {
        resultString += `${pluralize(emailOnlyInviteeCount, 'employee')} will receive their ${pluralizeInvitations(
            emailOnlyInviteeCount
        )} by email. `
    }

    if (smsOnlyInviteeCount > 0 && hasSmsConsent) {
        resultString += `${pluralize(smsOnlyInviteeCount, 'employee')} will receive their ${pluralizeInvitations(
            smsOnlyInviteeCount
        )} by SMS. `
    }

    if (emailAndSmsInviteeCount > 0) {
        resultString += `${pluralize(emailAndSmsInviteeCount, 'employee')} will receive their ${pluralizeInvitations(
            emailAndSmsInviteeCount
        )} by email${hasSmsConsent ? ' and SMS.' : '. '}`
    }

    return resultString
}

export const RecipientsCompleted = ({
    surveyFormat,
    inviteeCount,
    paperInviteeCount,
    linkInviteeCount,
    hasSmsConsent,
    emailOnlyInviteeCount,
    emailAndSmsInviteeCount,
    smsOnlyInviteeCount,
}) => {
    const totalParticipantsAvailableForContact =
        emailOnlyInviteeCount + emailAndSmsInviteeCount + (hasSmsConsent ? smsOnlyInviteeCount : 0)
    return (
        <SummaryCard padding="tight">
            <CardIcon className="text-grey300 justify-center">
                <Group fill={colors.grey500} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="mb-2">{`Your survey is going to ${inviteeCount} employees`}</CardTitle>
                    {totalParticipantsAvailableForContact > 0 && surveyFormat.id !== SurveyFormats.Paper && (
                        <p>
                            <InvitationText
                                hasSmsConsent={hasSmsConsent}
                                emailOnlyInviteeCount={emailOnlyInviteeCount}
                                emailAndSmsInviteeCount={emailAndSmsInviteeCount}
                                smsOnlyInviteeCount={smsOnlyInviteeCount}
                            />
                        </p>
                    )}

                    {linkInviteeCount > 0 && <p>{`${linkInviteeCount} employees will receive links.`}</p>}

                    {paperInviteeCount > 0 && surveyFormat.hasPaper && (
                        <p>{`${paperInviteeCount} employees will receive paper surveys.`}</p>
                    )}
                    {surveyFormat.id === SurveyFormats.Links && (
                        <p>{`To add or remove recipients from this survey, please contact ${SUPPORT_EMAIL}.`}</p>
                    )}
                    {surveyFormat.id !== SurveyFormats.Links && linkInviteeCount > 0 && (
                        <p>{`To add or remove recipients receiving printable links please contact ${SUPPORT_EMAIL}.`}</p>
                    )}
                </CardContent>
            </CardBody>
        </SummaryCard>
    )
}
