import { useMemo } from 'react'
import { Loading as Spinner } from '@energage/components'
import { format } from 'date-fns'
import get from 'lodash/get'
import map from 'lodash/map'
import { parse } from 'query-string'
import styled from 'styled-components'
import config from 'config'
import Divider from 'containers/Main/OrderManagement/common/Divider'
import { introText } from 'containers/Main/OrderManagement/Orders/OrderCart/text'
import AnnualPlan from 'containers/Main/OrderManagement/Renewals/Confirmation/AnnualPlan'
import buildSummary from 'containers/Main/OrderManagement/Renewals/Confirmation/buildSummary'
import ServiceTermContent from 'containers/Main/OrderManagement/Renewals/Confirmation/Terms'
import Account, { useAccountData } from 'containers/Main/OrderManagement/Renewals/Shared/Account'
import { Contact } from 'containers/Main/OrderManagement/Renewals/Shared/Contacts'
import { buildContacts } from 'containers/Main/OrderManagement/Renewals/Shared/Contacts/utils'
import PaymentMethodSelection from 'containers/Main/OrderManagement/Renewals/Shared/PaymentMethodSelection'
import { useMatchFetch } from 'hooks'
import energageLogo from 'images/company-logo-color.svg'

const DATE_FORMAT = 'h:mma M/dd/yyyy'
const SIGNATURE_DATE_FORMAT = 'MM/dd/yyyy'

const NoPageBreakInside = styled.div`
    page-break-inside: avoid;
`

const getUrls = ({ organizationId, subscriptionId }) => [
    `${config.api.ordering}/AmendmentOrders/Organization/${organizationId}/Subscription/${subscriptionId}`,
    `${config.api.subscription}/Subscriptions/${subscriptionId}`,
]

const Contacts = () => {
    const { accountData } = useAccountData()
    const contacts = buildContacts(accountData)
    return (
        <div className="flex flex-row">
            {map(contacts, (contact) => (
                <div key={contact.type} className="w-1/2">
                    <Contact {...contact} />
                </div>
            ))}
        </div>
    )
}

const SignatureContent = ({ signedInUserFullName }) => (
    <div className="flex flex-col mt-8 pl-5">
        <p className="w-3/4">
            {
                '"By signing this Order, you represent and certify that you are authorized to sign on behalf of your respective organization and agree to the terms and conditions of this Order and any terms referenced herein."'
            }
        </p>
        <div className="w-1/2">
            <p className="text-white my-10">{'please_sign_here'}</p>
            <hr className="my-3 border-black" />
            <p className="flex justify-between">
                <span>{signedInUserFullName}</span>
                <span>{format(new Date(), SIGNATURE_DATE_FORMAT)}</span>
            </p>
        </div>
    </div>
)

const SummaryPage = ({
    order,
    subscription,
    organizationId,
    isDigitalDocument,
    defaultSignerFullName,
    isNewRenewalPage,
}) => {
    const {
        currentTerm,
        newTerm,
        netCreditTerm,
        orderConfirmationDate,
        confirmedBy,
        disableAutoRenew,
        purchaseOrderNumber,
        organizationName,
    } = useMemo(() => buildSummary(order, subscription, isNewRenewalPage), [order, subscription, isNewRenewalPage])
    return (
        <div className="w-full bg-white p-4 min-h-screen">
            <div className="w-full flex justify-between items-center">
                <img className="w-1/4 p-2" src={energageLogo} alt="Energage Logo" />
                <h3>{'Order Summary'}</h3>
            </div>
            <Divider />
            <NoPageBreakInside>
                <AnnualPlan {...currentTerm} />
            </NoPageBreakInside>
            <NoPageBreakInside>
                <AnnualPlan {...newTerm} />
            </NoPageBreakInside>
            <Divider />
            <NoPageBreakInside>
                <h5>{organizationName}</h5>
                <Contacts />
            </NoPageBreakInside>
            <Divider />
            <NoPageBreakInside>
                <PaymentMethodSelection
                    organizationId={organizationId}
                    title={`Payment Details`}
                    netCreditTerm={netCreditTerm}
                    purchaseOrderNumber={purchaseOrderNumber}
                    isRenewalSummary
                />
            </NoPageBreakInside>
            <Divider />
            {orderConfirmationDate && confirmedBy && (
                <div className="font-bold pl-2">{`Confirmed by ${confirmedBy} at ${format(
                    new Date(orderConfirmationDate),
                    DATE_FORMAT
                )}`}</div>
            )}
            <div className="mt-8">
                <span className="text-xl pb-3">{'Terms and Conditions'}</span>
                <p>{introText}</p>
                <ServiceTermContent
                    newTerm={newTerm}
                    disableAutoRenewal={disableAutoRenew}
                    removeIncreaseLimit={false}
                />
                <Divider />
                {isDigitalDocument && <SignatureContent signedInUserFullName={defaultSignerFullName} />}
            </div>
        </div>
    )
}

const RenewalSummary = ({ location }) => {
    const parsedQuery = parse(location.search)
    const organizationId = get(parsedQuery, 'organizationId')
    const subscriptionId = get(parsedQuery, 'subscriptionId')
    const salesforceAccountId = get(parsedQuery, 'salesforceAccountId')
    const isDigitalDocument = get(parsedQuery, 'isDigitalDocument') === 'True'
    const defaultSignerFullName = get(parsedQuery, 'defaultSignerFullName')
    const isNewRenewalPage = get(parsedQuery, 'isNewRenewalPage') === 'True'

    const urls = useMemo(() => getUrls({ organizationId, subscriptionId }), [organizationId, subscriptionId])

    const render = useMatchFetch(urls)

    return (
        <Account salesforceAccountId={salesforceAccountId}>
            {render({
                loading: () => <Spinner />,
                error: () => (
                    <div className="p-8">
                        <p>{'There was an error loading your order'}</p>
                    </div>
                ),
                data: ([order, subscription]) => (
                    <SummaryPage
                        order={order}
                        subscription={subscription}
                        organizationId={organizationId}
                        isDigitalDocument={isDigitalDocument}
                        defaultSignerFullName={defaultSignerFullName}
                        isNewRenewalPage={isNewRenewalPage}
                    />
                ),
            })}
        </Account>
    )
}

export default RenewalSummary
