import { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { Delete, DragIndicator, Edit, RemoveCircleOutline } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { useAtomValue } from 'jotai/utils'
import noop from 'lodash/noop'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import { Tooltip } from 'components/Tooltip'
import { useCustomDemographicTargets } from '../../CustomDemographicTargetContext'
import { QuestionType } from '../../CustomQuestions/QuestionType'
import { useDemographicSectionContext } from '../../DemographicCategorySection/DemographicSectionContext'
import { useDepartmentContext } from '../../departmentContext'
import QuestionOverflowMenu from '../../QuestionOverflowMenu'
import { availableTargetDemographicQuestions } from '../../statementSetupUtility/store'
import { computeTargetingInformation } from '../../Targeting/computeTargetingInformation'
import { useDemographicOptions } from '../DemographicOptionsContext'
import QuestionTableContext from '../QuestionTableContext'
import { TableCell, TableRow } from '../TableElements'
import { ComplexConditionDescription } from '../TargetingMessagingFragments'
import DemographicAnswerFormat from './DemographicAnswerFormat'
import DemographicEditBox from './DemographicEditBox'
import DemographicTypeSelect from './DemographicTypeSelect'

const PanelEditActions = styled.div.attrs({ className: 'ml-auto flex flex-row flex-no-wrap absolute right-0 mr-8' })`
    height: 100%;
    min-height: 45px;
`
const PanelEditActionsContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
`
const PanelEditActionsContainerPadding = styled.div`
    padding-right: 10px;
    padding-bottom: 20px;
`

const EditableQuestionCells = ({
    questionId,
    questionText,
    isRequired,
    disableSelection,
    demographicTypeId,
    demographicTypeName,
    isEditing,
    handleQuestionTextChange,
    setDemographicType,
    demographicTypeValid,
    errorText,
}) => {
    return (
        <>
            <TableCell className="w-1/3 md:w-1/6">
                <div className="mr-2 pl-7 break-words">
                    {isEditing ? (
                        <DemographicTypeSelect
                            selectedValue={{ id: demographicTypeId, name: demographicTypeName }}
                            setDemographicType={setDemographicType}
                            showFrequentlyUsed={false}
                        />
                    ) : (
                        demographicTypeName
                    )}
                    {isRequired && <span>{` *`}</span>}
                    <div>{!demographicTypeValid && <span className="text-red500">{'Type must be unique'}</span>}</div>
                </div>
            </TableCell>
            <TableCell className="w-2/3 md:w-1/3 pr-6 md:pr-3">
                {isEditing ? (
                    <DemographicEditBox
                        className="w-full"
                        name={`question-text-${questionId}`}
                        value={questionText}
                        onChange={(value) => handleQuestionTextChange(value)}
                        maxLength={350}
                        placeholder="Enter question (ex: Which of the following best describes your job?)"
                    />
                ) : (
                    <label htmlFor={questionId} className={cx({ 'text-grey500': disableSelection })}>
                        {questionText}
                    </label>
                )}
                <div>{errorText}</div>
            </TableCell>
        </>
    )
}

const REQUIRED_MESSAGE_TEXT = 'Required item cannot be removed or edited.'
const DEPENDENT_QUESTION_TEXT =
    'This survey relies on a targeted custom question based on this demographic question. Please remove the targeting to continue with editing or removing.'

const DemographicQuestionTableRow = ({
    question,
    parentSelected,
    disabled,
    isSortingDisabled,
    isEditing,
    isEditable,
    handleDeleteQuestion,
    handleSaveQuestion,
    handleDemographicQuestionTextChange,
    isPosting,
    setEditingQuestionId,
    validateDuplicateDemographicType,
    hasActiveDependentQuestion,
    savedQuestion,
}) => {
    const {
        questionEssenceId,
        isRequired,
        text,
        demographicTypeId,
        demographicTypeName,
        conditionalQuestionEssenceIds,
        isHighlighted,
    } = question
    const { demographicQuestionToEdit, setDemographicQuestionToEdit } = useDemographicSectionContext()

    const questionId = questionEssenceId ?? 0
    const toggleQuestion = useContext(QuestionTableContext)
    const [demographicTypeValid, setDemographicTypeValid] = useState(true)
    const { isSuperUser } = useIdentity()

    const {
        setAnswerOptions,
        currentAnswerOptions,
        setCurrentAnswerOptions,
        answerOptionsValid,
        verifyAndSetAnswerOptions,
        hasDuplicateOptions,
    } = useDemographicOptions()

    const [demographicType, setDemographicType] = useState({
        id: demographicTypeId,
        name: demographicTypeName,
    })

    const handleQuestionTextChange = (value) => {
        handleDemographicQuestionTextChange(value, questionId)
    }

    const verifyAndSetDemographicType = (demographicType) => {
        const isValid = validateDuplicateDemographicType(questionId, demographicType.name)
        setDemographicTypeValid(isValid)
        setDemographicType(demographicType)
    }

    const handleSetEditing = () => {
        setEditingQuestionId(questionId)
        verifyAndSetAnswerOptions(currentAnswerOptions)
    }

    const handleSave = () => {
        if (!answerOptionsValid || isPosting) {
            return
        }
        if (questionId) {
            setDemographicQuestionToEdit({
                ...demographicQuestionToEdit,
                [questionId]: {
                    ...demographicQuestionToEdit[questionId],
                    text,
                    demographicTypeName: demographicType.name,
                    answerOptionsOriginal: currentAnswerOptions,
                },
            })
        }

        handleSaveQuestion({
            questionEssenceId: questionId === 0 ? null : questionId,
            demographicType,
            questionText: text,
            optionsList: {
                options: currentAnswerOptions,
            },
        })
    }

    const disableSelection = conditionalQuestionEssenceIds.length > 0 && !parentSelected

    const demographicQuestions = useAtomValue(availableTargetDemographicQuestions)
    const { flattenedDepartments } = useDepartmentContext()

    const customDemographicTargets = useCustomDemographicTargets()

    const targetingInformation = useMemo(() => {
        return computeTargetingInformation({
            questionTargetDemographics: question.targetDemographic,
            questionTargetDepartmentIds: question.departmentIds,
            flattenedDepartments,
            demographicQuestions,
            customDemographicTargets,
        })
    }, [
        question.targetDemographic,
        question.departmentIds,
        flattenedDepartments,
        demographicQuestions,
        customDemographicTargets,
    ])

    useEffect(() => {
        setAnswerOptions(currentAnswerOptions)
    }, [currentAnswerOptions, setAnswerOptions])

    const cancelEdit = () => {
        handleDeleteQuestion(questionId, true, demographicQuestionToEdit)
        if (questionId) {
            verifyAndSetAnswerOptions(demographicQuestionToEdit[questionId].answerOptionsOriginal)
            setCurrentAnswerOptions(demographicQuestionToEdit[questionId].answerOptionsOriginal)
            setDemographicType({ ...demographicType, name: demographicQuestionToEdit[questionId].demographicTypeName })
        }
    }
    return (
        <TableRow className={isHighlighted && 'is-highlighted'}>
            <div className="flex flex-wrap md:flex-no-wrap relative items-start">
                <TableCell className="absolute" css={{ left: '-3px', top: '0.5rem', transform: 'translateY(-50%)' }}>
                    <Tooltip
                        trigger={<DragIndicator color={isSortingDisabled ? colors.grey500 : colors.grey800} />}
                        disabled={!isSortingDisabled}
                        text="Item cannot be reordered."
                        tooltipColor={colors.grey600}
                        textColor={colors.white}
                        tooltipArrowColor={colors.grey500}
                    />
                </TableCell>
                <EditableQuestionCells
                    questionId={questionId}
                    questionText={text}
                    isRequired={isRequired}
                    disableSelection={disableSelection}
                    demographicTypeId={demographicType.id}
                    demographicTypeName={demographicType.name}
                    isEditing={isEditing}
                    handleQuestionTextChange={handleQuestionTextChange}
                    setDemographicType={verifyAndSetDemographicType}
                    errorText={
                        question._errors?.required && <span className="text-red500">{question._errors.required}</span>
                    }
                    demographicTypeValid={demographicTypeValid}
                />
                <TableCell
                    className={cx(
                        'w-full md:w-1/2 pl-7 pt-1 md:pt-0 md:pl-0 flex flex-col pr-10',
                        disableSelection && 'text-grey500'
                    )}>
                    <DemographicAnswerFormat
                        questionId={questionId}
                        questionTypeId={QuestionType.Demographic}
                        options={currentAnswerOptions}
                        isEditing={isEditing}
                        handleSetAnswerOptions={verifyAndSetAnswerOptions}
                        savedQuestion={savedQuestion}
                    />
                    {hasDuplicateOptions && (
                        <div className="text-red500 pb-2 md:pl-2 md:pb-0">{'Options must be unique'}</div>
                    )}
                </TableCell>
                {isEditing ? (
                    <PanelEditActions>
                        <PanelEditActionsContainer>
                            <PanelEditActionsContainerPadding>
                                <Button
                                    className="py-2 leading-none text-xs"
                                    type="button"
                                    variant="link"
                                    onClick={!isPosting ? cancelEdit : noop}>
                                    {'Cancel'}
                                </Button>
                                <Button
                                    className="py-2 leading-none text-xs"
                                    type="button"
                                    variant="secondary"
                                    outline
                                    disabled={
                                        !text ||
                                        !answerOptionsValid ||
                                        isPosting ||
                                        !demographicTypeValid ||
                                        !demographicType.name
                                    }
                                    onClick={
                                        !isPosting &&
                                        answerOptionsValid &&
                                        demographicTypeValid &&
                                        demographicType.name &&
                                        text
                                            ? handleSave
                                            : undefined
                                    }>
                                    {'Save Item'}
                                </Button>
                            </PanelEditActionsContainerPadding>
                        </PanelEditActionsContainer>
                    </PanelEditActions>
                ) : (
                    <TableCell
                        className="absolute z-10"
                        css={{ top: '0.5rem', right: 0, transform: 'translateY(-50%)' }}>
                        <QuestionOverflowMenu
                            key={`${questionId}-overflow-menu`}
                            questionText={question.text}
                            toolTipText={
                                question.isRequired
                                    ? REQUIRED_MESSAGE_TEXT
                                    : hasActiveDependentQuestion
                                    ? DEPENDENT_QUESTION_TEXT
                                    : null
                            }
                            menuItems={
                                !question.isTemplateDemographicQuestion && !question.isEditableDemographicQuestion
                                    ? [
                                          {
                                              text: 'Delete',
                                              icon: Delete,
                                              onSelect: !isPosting ? () => handleDeleteQuestion(questionId) : noop,
                                          },
                                      ]
                                    : [
                                          ...(!disabled && isEditable && !hasActiveDependentQuestion
                                              ? [
                                                    {
                                                        text: 'Edit',
                                                        icon: Edit,
                                                        onSelect: !isPosting ? handleSetEditing : noop,
                                                    },
                                                ]
                                              : []),
                                          ...(!disabled && !isRequired && !hasActiveDependentQuestion
                                              ? [
                                                    {
                                                        text: 'Remove',
                                                        icon: RemoveCircleOutline,
                                                        onSelect: hasActiveDependentQuestion
                                                            ? noop
                                                            : () => toggleQuestion(question.questionEssenceId, false),
                                                    },
                                                ]
                                              : []),
                                          ...(isSuperUser && isEditable && !hasActiveDependentQuestion
                                              ? [
                                                    {
                                                        text: 'Delete Completely (Internal)',
                                                        icon: Delete,
                                                        onSelect:
                                                            !isPosting && !hasActiveDependentQuestion
                                                                ? () => handleDeleteQuestion(questionId)
                                                                : noop,
                                                    },
                                                ]
                                              : []),
                                      ]
                            }
                        />
                    </TableCell>
                )}
            </div>
            {targetingInformation.targetingDescription !== null ? (
                <ComplexConditionDescription
                    className="text-grey600 italic pt-2"
                    descriptions={targetingInformation.targetingDescription}
                />
            ) : null}
        </TableRow>
    )
}

DemographicQuestionTableRow.propTypes = {
    question: PropTypes.shape({
        questionEssenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        demographicTypeId: PropTypes.number,
        demographicTypeName: PropTypes.string,
        conditionalQuestionEssenceIds: PropTypes.arrayOf(PropTypes.number),
        answerOptions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                name: PropTypes.string,
                isEditable: PropTypes.bool,
            })
        ),
        targetGroupDescription: PropTypes.arrayOf(PropTypes.string),
    }),
    parentSelected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    handleDeleteQuestion: PropTypes.func,
    handleSaveQuestion: PropTypes.func,
    handleDemographicQuestionTextChange: PropTypes.func,
    setEditingQuestionId: PropTypes.func,
    validateDuplicateDemographicType: PropTypes.func,
    isCustom: PropTypes.bool,
}

export default DemographicQuestionTableRow
