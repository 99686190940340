import type { Feature, FeatureBucket } from '@energage/components/lib/SettingsDrawer'
import { useManagementQuery } from 'api'
import { useIdentity } from 'components/Identity'
import { monthDateFullYearDate } from 'util/formatters'

/*

const useFetchOrganizationFeatures = () => {
    const { surveyCompanyId, isTopLevelUser } = useIdentity()
    return useManagementQuery(['organization-features', surveyCompanyId], `/feature/organization/${surveyCompanyId}`, {
        enabled: isTopLevelUser,
    })
}

const useFetchSurveyEventFeatures = (surveyEventId: number | null) => {
    // This is the call for all Survey Events not including active state

    const { data, isLoading } = useManagementQuery(
        ['survey-features', surveyEventId],
        `feature/surveyevent/${surveyEventId}`
    )

    return {
        surveyFeatures: data,
        isLoading,
    }
}

const useFetchSurveyEventFeatureCategories = () => {
    const { isTopLevelUser } = useIdentity()
    return useManagementQuery(['survey-features'], 'feature/surveyevent', { enabled: isTopLevelUser })
}
*/

const formatExpirationDate = (date: string) => monthDateFullYearDate(date)

type FeatureResponse = Omit<Feature, 'formattedExpirationDate'> & {
    expiration: string
}

type FeatureBucketResponse = Omit<FeatureBucket, 'features'> & {
    features: FeatureResponse[]
}

export const useFetchFeaturePreviews = () => {
    const { surveyCompanyId } = useIdentity()

    return useManagementQuery<FeatureBucketResponse, unknown, FeatureBucket>(
        ['feature-preview', surveyCompanyId],
        `/feature/preview/${surveyCompanyId}`,
        {
            select: (features) => {
                return {
                    ...features,
                    features: features?.features?.map((feature) => ({
                        ...feature,
                        formattedExpirationDate: feature.expiration
                            ? formatExpirationDate(feature.expiration)
                            : undefined,
                    })),
                }
            },
        }
    )
}

/*
const useFetchSurveyEventIds = () => {
    const { surveyCompanyId, isTopLevelUser } = useIdentity()
    const { data } = useManagementQuery(
        ['survey-event-ids', surveyCompanyId],
        `feature/organization/surveyeventids/${surveyCompanyId}`,
        { enabled: isTopLevelUser }
    )

    const surveyEventIds = data?.map((x: number) => ({
        label: x,
        value: x,
    }))

    return {
        surveyEventIds,
    }
}

const useFetchSettings = () => {
    const {
        data: organizationFeatures,
        isLoading: organizationFeaturesIsFetching,
        error: organizationFeaturesError,
    } = useFetchOrganizationFeatures()

    const {
        data: surveyEventFeatures,
        isLoading: surveyEventFeaturesIsFetching,
        error: surveyEventFeaturesError,
    } = useFetchSurveyEventFeatureCategories()

    const {
        data: previewFeatures,
        isLoading: featurePreviewsIsFetching,
        error: featurePreviewsError,
    } = useFetchFeaturePreviews()

    const isLoading = organizationFeaturesIsFetching || surveyEventFeaturesIsFetching || featurePreviewsIsFetching

    const error = organizationFeaturesError || surveyEventFeaturesError || featurePreviewsError

    return {
        isLoading,
        error,
        organizationFeatures,
        surveyEventFeatures,
        previewFeatures,
    }
}*/

// export { useFetchSurveyEventFeatures, useFetchSurveyEventFeatureCategories }
