import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import {
    Tooltip as ReakitTooltip,
    TooltipArrow as ReakitTooltipArrow,
    TooltipReference,
    useTooltipState,
} from 'reakit/Tooltip'
import styled from 'styled-components'

const TooltipArrow = styled(ReakitTooltipArrow)`
    top: -22px;
    svg path {
        fill: currentColor;
    }
    color: ${(props) => props.$tooltipArrowColor};
`

const TooltipContainer = styled.div.attrs({
    className: 'z-tooltip text-left rounded paragraph-small inline-block',
})`
    background-color: ${(props) => props.tooltipColor};
    color: ${(props) => props.textColor};
    max-width: 16rem;
    transition: opacity 250ms linear, transform 0ms 300ms;
    opacity: 0;
    transform-origin: top center;
    transform: scale(0.9);
    [data-enter] & {
        opacity: 1;
        transform: scale(1);
        transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
`

export function Tooltip({
    trigger,
    disabled = false,
    text,
    placement = 'bottom',
    tooltipColor,
    textColor = colors.white,
    tooltipArrowColor = tooltipColor,
    className = '',
}) {
    const tooltip = useTooltipState({ placement: placement, animated: true })
    const backgroundColor = tooltipColor ?? colors.grey700
    const arrowColor = tooltipArrowColor ?? backgroundColor

    if (disabled) {
        return trigger
    }

    return (
        <>
            <TooltipReference {...tooltip} as="span">
                {trigger}
            </TooltipReference>
            <ReakitTooltip {...tooltip} className="z-tooltip">
                <TooltipContainer tooltipColor={backgroundColor} textColor={textColor} className={className}>
                    <TooltipArrow $tooltipArrowColor={arrowColor} {...tooltip} />
                    <div className="py-2 px-3">{text}</div>
                </TooltipContainer>
            </ReakitTooltip>
        </>
    )
}

Tooltip.propTypes = {
    text: PropTypes.node,
    trigger: PropTypes.node,
    tooltipColor: PropTypes.string,
    textColor: PropTypes.string,
}
