import { ButtonLink } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { routes } from 'constants/routes'
import type { AwardAnnouncementSection } from '../awardAnnouncement.types'
import { Section } from '../Section'

export const MoreAwards = ({ baseUrl, isPdf }: AwardAnnouncementSection) => (
    <Section bgColor={colors.yellow100} textColor={colors.grey700}>
        <div className={cx('flex flex-col items-center justify-center', { 'py-24': isPdf })}>
            <h3 className="text-center font-bold m-2 text-xl md:w-2/5">
                {
                    'Get ready for your next opportunity to earn recognition. There may be more awards you are eligible for.'
                }
            </h3>
            <div className="mt-5">
                <ButtonLink
                    href={isPdf ? `${baseUrl}employer-recognition/award/add` : routes.employerRecognition.award.add()}
                    target="_blank"
                    appearance="secondary">
                    {'Find More Awards'}
                </ButtonLink>
            </div>
        </div>
    </Section>
)
