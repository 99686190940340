import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const jiggle = keyframes`
    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-1px);
    }

    75% {
        transform: translateX(1px);
    }
`

const Jiggle = styled.div`
    ${(props) =>
        props.active
            ? css`
                  animation: ${jiggle} 0.25s;
              `
            : ''}
`

Jiggle.propTypes = {
    active: PropTypes.bool,
}

export default Jiggle
