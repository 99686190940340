import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { LegacyButton as Button, Loading } from '@energage/components'
import { Modal } from 'components/Modals'
import { useVisibility } from 'hooks'
import { optionTagShape } from '../../propTypes'
import FormEmployee from './FormEmployee'
import useSaveEmployee from './useSaveEmployee'

const EditEmployee = ({ isLoading, allDepartments, locations, employee, onComplete }) => {
    const { visible, show, hide } = useVisibility()
    const onSuccess = useCallback(() => {
        hide()
        onComplete()
    }, [hide, onComplete])

    const { save, isSaving } = useSaveEmployee(onSuccess, employee.id, employee.modifiedDateTime)

    const employeeProcessed = {
        ...employee,
        isFullTime: employee.isFullTime,
        department: employee.departmentId,
        startDate: employee.startDate ? new Date(employee.startDate.replace('Z', '')) : null,
    }

    return (
        <>
            <Button onClick={show} variant="link" className="p-0 mr-4">
                {'Edit'}
            </Button>
            <Modal isOpen={visible} onClose={hide} title="Edit Employee" className="w-5/6 sm:w-1/2 xl:w-1/4">
                {isLoading ? (
                    <Loading className="bg-white border border-grey300 border-t-0" text={`Loading...`} />
                ) : (
                    <FormEmployee
                        onSubmit={save}
                        allDepartments={allDepartments}
                        isSaving={isSaving}
                        onClose={hide}
                        locations={locations}
                        employee={employeeProcessed}
                    />
                )}
            </Modal>
        </>
    )
}

EditEmployee.propTypes = {
    isLoading: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    allDepartments: PropTypes.arrayOf(PropTypes.shape(optionTagShape)),
    onComplete: PropTypes.func,
}

export default EditEmployee
