import { ExternalLink } from 'components/Anchor'
import { routes } from 'constants/routes'
import type { Timelines } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { DALLAS_PUBLISHER_ID, DEFAULT_DATE } from 'containers/Main/AwardAnnouncement/constants'
import { monthDayDate, parseDateOnly, shortMonthYearDate } from 'util/formatters'

export default (
    publisherId: number,
    companyName: string | undefined | null,
    year: number,
    listName: string | undefined,
    baseUrl: string | undefined,
    isPdf: boolean | undefined,
    mediaKitExternalLinkParameters: string,
    {
        isApproxPublishDate,
        publicationDate,
        pressReleaseDate,
        eventDate,
        hasEvent,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate,
    }: Timelines
) => {
    const isDallasPublisher = publisherId === DALLAS_PUBLISHER_ID
    const mediaKitExternalLink = `${routes.branding.mediaKit()}${mediaKitExternalLinkParameters}`

    let formattedPublicationDate = DEFAULT_DATE
    if (publicationDate) {
        formattedPublicationDate = isApproxPublishDate
            ? shortMonthYearDate(parseDateOnly(publicationDate))
            : monthDayDate(parseDateOnly(publicationDate))
    }

    let formattedAlphabeticalWinnersListDate = DEFAULT_DATE
    if (hasAlphabeticalWinnersList && alphabeticalWinnersListDate) {
        formattedAlphabeticalWinnersListDate = monthDayDate(parseDateOnly(alphabeticalWinnersListDate))
    }

    return [
        {
            heading: 'Today',
            subtitle: 'Update your company profile',
            text: (
                <>
                    <ExternalLink
                        href={isPdf ? `${baseUrl}profile` : routes.profile()}
                        target="_blank"
                        className="text-yellow500 font-bold">
                        <b> {'Update your company profile'}</b>
                    </ExternalLink>
                    <span>{' for the media partner and topworkplaces.com website.'}</span>
                </>
            ),
        },
        {
            heading: 'ASAP',
            subtitle: `Tell senior leaders ${companyName} is a Top Workplaces winner`,
            text: `Share the exciting news with senior leaders. Don't forget to take a few data-driven insights with you. But remember, word of the award needs to be kept quiet until ${
                hasAlphabeticalWinnersList ? formattedAlphabeticalWinnersListDate : formattedPublicationDate
            }.`,
        },
        ...(!isDallasPublisher
            ? [
                  {
                      heading: pressReleaseDate ? `${monthDayDate(parseDateOnly(pressReleaseDate))}` : DEFAULT_DATE,
                      subtitle: 'Prep your press release',
                      text: (
                          <>
                              <span>{`Get ready to tell the world ${companyName} is a Top Workplace! `}</span>
                              {(mediaKitExternalLink || isPdf) && (
                                  <ExternalLink
                                      className="text-yellow500 font-bold text-lg"
                                      href={
                                          isPdf
                                              ? `${baseUrl}branding/media-kit${mediaKitExternalLinkParameters}`
                                              : mediaKitExternalLink
                                      }
                                      target="_blank">
                                      <b>
                                          <p>{'See your Media Kit'}</p>
                                      </b>
                                  </ExternalLink>
                              )}
                          </>
                      ),
                  },
              ]
            : []),
        ...(hasAlphabeticalWinnersList
            ? [
                  {
                      heading: formattedAlphabeticalWinnersListDate,
                      subtitle: `Alphabetical Award Publication`,
                      text: (
                          <>
                              <span>{`Share the news that ${companyName} is a Top Workplaces Winner!`}</span>
                              <br />
                              <span>{'Be sure to check the alphabetical list of winners!'}</span>
                          </>
                      ),
                  },
              ]
            : []),
        ...(!isDallasPublisher && hasEvent
            ? [
                  {
                      heading: eventDate ? `${monthDayDate(parseDateOnly(eventDate))}` : DEFAULT_DATE,
                      subtitle: `Celebrate with fellow Top Workplaces`,
                      text: `Join with Top Workplaces winners for the ${listName}.`,
                  },
              ]
            : []),
        {
            heading: `${formattedPublicationDate}`,
            subtitle: 'The announcement goes live',
            text: `Now the world knows  ${companyName} is a Top Workplaces winner. Make sure you check online and pick up your copy of the section!`,
        },
        {
            heading: `${formattedPublicationDate}`,
            subtitle: 'Celebrate with your employees',
            text: `It's time to share the excitement! Let employees know you've been named a Top Workplaces - and don't forget to thank them for participating in the survey.`,
        },
        {
            heading: `Get ready to participate in ${year + 1}`,
            text: `${companyName} is already registered to participate next year. You will be notified when it is time to start the process. In the meantime, leverage your data through Insights, Pulse, and Employer Branding to keep your culture in top shape.`,
        },
    ]
}
