import { size, spacing } from '@energage/theme'
import Carousel from 'nuka-carousel'
import styled from 'styled-components'

export const StyledCarousel = styled(Carousel)`
    outline: none;
    padding: 1.5rem 0 4.5rem;

    .slider-list {
        cursor: default !important;
        left: -58px !important;
        top: 10px !important;
        height: 120px !important;
    }

    .slider-slide {
        display: flex !important;
        align-items: center;
        padding: 0 4rem;
        width: 250px !important;
    }

    button,
    .slider-slide {
        outline: none;
        &::focus {
            outline: none;
        }
    }

    .paging-dot {
        height: ${size(3)} !important;
        width: ${size(3)} !important;
        margin-right: ${spacing(2)};
    }

    .slider-control-bottomcenter > ul {
        top: -20px !important;
    }

    .slider-control-centerright {
        transform: translateX(29px) !important;
        top: 100px !important;
    }

    .slider-control-centerleft {
        transform: translateX(-44px) !important;
        top: 100px !important;
    }
`
