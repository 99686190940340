import { useCallback, useRef } from 'react'

function copyToClipboard(element: HTMLTextAreaElement | HTMLInputElement): void {
    const selectionStart = element.selectionStart
    const selectionEnd = element.selectionEnd

    const currentFocus = document.activeElement as HTMLElement
    element.focus()
    element.setSelectionRange(0, element.value.length)

    try {
        document.execCommand('copy')
    } catch (err) {}

    if (currentFocus && 'focus' in currentFocus && typeof currentFocus.focus === 'function') {
        currentFocus.focus()
    }

    element.setSelectionRange(selectionStart, selectionEnd)
}

export function useClipboard() {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | undefined>(null)

    const doCopy = useCallback(() => {
        if (inputRef.current) {
            copyToClipboard(inputRef.current)
        }
    }, [])

    return [inputRef, doCopy] as const
}
