import { Route, Switch } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AddUser } from 'containers/Main/Manage/Users/AddUser'
import { EditUser } from './EditUser'
import { UserManagement } from './UserManagement'

function UserManagementRoutes() {
    return (
        <Switch>
            <Route path={routes.manage.users.add()} component={AddUser} />
            <Route path={routes.manage.users.edit()} component={EditUser} />
            <Route path={routes.manage.users()} component={UserManagement} />
        </Switch>
    )
}

export { UserManagementRoutes }
