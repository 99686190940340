// https://en.wikipedia.org/wiki/List_of_file_signatures
export default function getMimeType(header, defaultType) {
    switch (header) {
        case '89504e47':
            return 'image/png'
        case '47494638':
            return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            return 'image/jpeg'
        default:
            return defaultType
    }
}
