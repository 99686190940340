import styled from 'styled-components'
import config from 'config'

export const BOSTON_PUBLISHER_ID = 2
export const DALLAS_PUBLISHER_ID = 7
export const MINNEAPOLIS_PUBLISHER_ID = 13
export const AZ_CENTRAL_PUBLISHER_ID = 1137
export const TWP_USA_PUBLISHER_ID = 669
export const MONTANA_PUBLISHER_ID = 820
export const CE_AND_I_PUBLISHER_ID = 98
export const DEFAULT_DATE = 'TBD'

export const UPCOMING_AWARD_URL = `${config.urls.topWorkplaces}/awards-schedule/`
export const COMPANY_PROFILE_URL = 'https://topworkplaces.com/company/'
export const MERCHANDISE_URL = 'https://www.americanregistry.com/topworkplaces/'
export const FAQ_URL = 'https://topworkplaces.com/faqs/'

export const AWARD_CATEGORY = {
    CULTURE_EXCELLENCE: 1,
    INDUSTRY: 2,
    USA_TODAY: 3,
    REGIONAL: 4,
}

export enum AnnouncementExperience {
    OLD_ASPIRING = 0,
    NEW_ASPIRING,
    OLD_WINNER,
    NEW_WINNER,
}

export const Button = styled.button`
    padding-left: 8px;
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid black;
        outline-offset: 6px;
    }
`
