import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useIdentity, usePermissions } from 'components/Identity'
import Inset from 'components/Inset'
import { useNavigation } from 'components/Navigation'
import { SurveysAndInsightsContainer } from 'components/SwimLane/SurveysAndInsights'
import { TopNav } from 'components/TopNav'
import config from 'config'
import { routes } from 'constants/routes'
import TaskDefinition from 'constants/taskDefinition'
import { useFetchOrgTaskStatus } from 'containers/Main/EmployerRecognition/Shared/useFetchOrgTaskStatus'
import { isTwpWizardEnabled } from 'store'
import { SurveyInfoFetch } from '../Widgets/SurveyInfo'
import { UpsellPulse } from '../Widgets/UpsellPulse'
import { AwardsSwimLane } from './Awards'
import { BrandingSwimLane } from './Branding'
import { GoodToKnowContainer } from './GoodToKnow'
import { ScienceLabContainer } from './ScienceLab'

const Dashboard = ({ match }) => {
    const { addItems } = useNavigation(match.url)
    const isWizardEnabled = useSelector(isTwpWizardEnabled)
    const identity = useIdentity()
    const { hasPlatformSurveySetup, hasEmployerBranding, hasSmartPulse } = usePermissions()

    const {
        isCompleted,
        loading: companySetupLoading,
        error: companySetupError,
    } = useFetchOrgTaskStatus(TaskDefinition.CompanyInitialSetup)

    useEffect(() => {
        addItems(match.url, 'Dashboard', null)
    }, [addItems, match.url])

    if (!identity) {
        // TODO: Show some indication of loading
        return null
    }

    if (
        identity?.permissions?.hasEmployerRecognitionWizard &&
        !companySetupLoading &&
        !companySetupError &&
        !isCompleted &&
        isWizardEnabled
    ) {
        return <Redirect to={routes.employerRecognition.setup()} />
    }

    return (
        <div>
            <TopNav xsHidden title="Dashboard">
                {hasPlatformSurveySetup && (
                    <Inset padding className="pb-20">
                        <AwardsSwimLane />
                    </Inset>
                )}
                {hasPlatformSurveySetup && hasSmartPulse ? (
                    <Inset padding className="pb-20">
                        <SurveysAndInsightsContainer />
                    </Inset>
                ) : hasPlatformSurveySetup ? (
                    <Inset padding className="pb-20">
                        <div className="flex flex-wrap gap-6 items-center">
                            {<SurveyInfoFetch />}
                            {!hasSmartPulse && <UpsellPulse />}
                        </div>
                    </Inset>
                ) : (
                    <></>
                )}
                {hasEmployerBranding && (
                    <Inset padding className="pb-20">
                        <BrandingSwimLane />
                    </Inset>
                )}
                <Inset padding="pb-20">
                    <GoodToKnowContainer enableQueryable={config.featureFlag.enableQueryEducationalContent} />
                </Inset>
                <Inset padding className="pb-20">
                    <ScienceLabContainer />
                </Inset>
            </TopNav>
        </div>
    )
}

export default Dashboard
