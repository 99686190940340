import { useContext, useEffect, useState } from 'react'
import { Text } from '@energage/typography'
import filter from 'lodash/filter'
import some from 'lodash/some'
import { withRouter } from 'react-router-dom'
import { useManagementQuery, useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import SurveyType from 'constants/SurveyType'
import TaskDefinition from 'constants/taskDefinition'
import showErrorAlert from 'util/showErrorAlert'
import HeaderTeleporter from '../HeaderTeleporter'
import SurveyFormats from '../Recipients/FormatSurvey/SurveyFormats'
import { useSurveySetupContext } from '../SurveySetup'
import SurveySetupContext from '../SurveySetupContext'
import { AwardIdentificationTile } from './AwardIdentificationTile'
import SummaryCardSkeleton from './Common/SummaryCardSkeleton'
import { LaunchReadiness } from './LaunchReadiness'
import RecipientsTile from './RecipientsTile'
import ResponseRateTile from './ResponseRateTile'
import ScheduleTile from './ScheduleTile'
import SurveySetupTile from './SurveySetupTile'

function usesLaunchReadiness(surveyFormatId, surveyHasLaunched) {
    if (surveyFormatId !== SurveyFormats.Paper && surveyFormatId !== SurveyFormats.Links) {
        return !surveyHasLaunched
    }

    return false
}

const Header = () => (
    <Text textStyle="title3">
        <h2>{'Please Review Your Survey Summary'}</h2>
    </Text>
)

const Summary = ({
    surveyCompanyId,
    surveyEventId,
    surveyFormatId,
    launchDate,
    surveyHasLaunched,
    recipientsData,
    isSelectAwardsTileRelevant,
    awardIdentification,
    isAwardIdentificationLoading,
    awardIdentificationError,
    isRecipientsLoading,
    errorRecipients,
    isSurveyPreviewConfirmed,
    isSurveyLaunchApproved,
    hasConfirmedRecipients,
    hasIncompleteSmsSetup,
    surveySetupData,
    isSurveySetupDataLoading,
    surveySchedule,
    isScheduleLoading,
    scheduleError,
    errorSurveySetupSummary,
    organizationData,
    isOrganizationLoading,
    launchApprovalWorkflowTask,
    refreshWorkflow,
    hasDeliveryContactsSetup,
    isDeliveryContactsLoading,
    errorDeliveryContacts,
}) => {
    const [isApproved, setApproved] = useState(isSurveyLaunchApproved)

    const { data } = useWorkplaceSurveyQuery(
        ['survey-setup-summary', surveyEventId],
        `SurveySummary/${surveyEventId}/SurveySetup`
    )
    const { surveyTypeId } = useSurveySetupContext()

    const showWorkplaceTrendingStatementsValidation =
        surveyTypeId === SurveyType.WorkplaceTrending && data?.workplaceExperienceStatementsCount === 0

    const { mutate: dismissSurveyLaunchNotifications } = useManagementQuery.mutate(
        async (_, api) =>
            await api.post(`Notifications/${surveyCompanyId}/${surveyEventId}/DismissSurveyLaunchNotifications`)
    )

    const { mutateAsync: approve, isLoading: isApproving } = useWorkplaceSurveyQuery.mutate(
        `/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/${TaskDefinition.ApproveSurveyLaunch}/CompleteSurveyTask`,
        {
            updateTwpWorkflow: true,
            onSuccess: () => {
                setApproved(true)
                refreshWorkflow()
                dismissSurveyLaunchNotifications()
            },
            onError: () => {
                showErrorAlert("We're sorry, an error occurred while approving your survey launch.")
            },
        }
    )

    const { mutateAsync: unapprove, isLoading: isUnapproving } = useWorkplaceSurveyQuery.mutate(
        `/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/${TaskDefinition.ApproveSurveyLaunch}/InvalidateSurveyTask`,
        {
            updateTwpWorkflow: true,
            onSuccess: () => {
                setApproved(false)
                refreshWorkflow()
            },
            onError: () => {
                showErrorAlert("We're sorry, an error occurred while unapproving your survey launch.")
            },
        }
    )

    const isLoading =
        isRecipientsLoading ||
        isSurveySetupDataLoading ||
        isScheduleLoading ||
        isOrganizationLoading ||
        isDeliveryContactsLoading
    const showLaunchReadiness = usesLaunchReadiness(surveyFormatId, surveyHasLaunched)

    const { hidePrimaryAction, isTaskRelevant } = useContext(SurveySetupContext)
    const isSurveyScopeTaskRelevant = isTaskRelevant(TaskDefinition.SurveyScope)

    useEffect(() => {
        if (showLaunchReadiness) {
            return hidePrimaryAction()
        }
    }, [hidePrimaryAction, showLaunchReadiness])

    return (
        <>
            {!showLaunchReadiness && (
                <HeaderTeleporter.Source>
                    <Header />
                </HeaderTeleporter.Source>
            )}
            <div>
                {!isLoading && showLaunchReadiness && (
                    <LaunchReadiness
                        launchDate={launchDate}
                        isSurveyPreviewConfirmed={isSurveyPreviewConfirmed}
                        recipientsData={recipientsData}
                        surveySetupData={surveySetupData}
                        organizationData={organizationData}
                        timeZone={surveySchedule?.timeZone}
                        isApproved={isApproved && isSurveyLaunchApproved}
                        hasSelectedAwards={filter(awardIdentification?.awards, ['isSelected', true]).length > 0}
                        isTopWorkplaceParticipant={awardIdentification?.isTopWorkplaceParticipant}
                        hasConfirmedRegionalCounts={awardIdentification?.isEmployeeCountConfirmed}
                        isApproving={isApproving}
                        hasConfirmedRecipients={hasConfirmedRecipients}
                        onApprove={approve}
                        hasError={!!errorRecipients || !!errorSurveySetupSummary || errorDeliveryContacts}
                        onUnapprove={unapprove}
                        isUnapproving={isUnapproving}
                        launchApprovalWorkflowTask={launchApprovalWorkflowTask}
                        hasDeliveryContactsSetup={hasDeliveryContactsSetup}
                        hasIncompleteSmsSetup={hasIncompleteSmsSetup}
                        showWorkplaceTrendingStatementsValidation={showWorkplaceTrendingStatementsValidation}
                    />
                )}

                <Inset padding className="max-w-4xl flex flex-wrap lg:items-start gap-6">
                    {isSelectAwardsTileRelevant && (
                        <div data-testid="summary-awards-tile">
                            <AwardIdentificationTile
                                data={awardIdentification}
                                surveyEventId={surveyEventId}
                                isAwardIdentificationLoading={isAwardIdentificationLoading}
                                awardIdentificationError={awardIdentificationError}
                                isInviteeCountSubstantiallyDifferentFromRegionalCounts={
                                    isSurveyScopeTaskRelevant &&
                                    recipientsData.isInviteeCountSubstantiallyDifferentFromRegionalCounts
                                }
                                hasMinimumQualifyingInviteeCount={recipientsData.hasMinimumQualifyingInviteeCount}
                            />
                        </div>
                    )}

                    {surveyFormatId !== SurveyFormats.Paper && <SurveySetupTile surveyEventId={surveyEventId} />}
                    {isRecipientsLoading ? (
                        <SummaryCardSkeleton />
                    ) : (
                        <div data-testid="summary-recipients-tile">
                            <RecipientsTile
                                surveyEventId={surveyEventId}
                                hasConfirmedRecipients={hasConfirmedRecipients}
                                hasIncompleteSmsSetup={hasIncompleteSmsSetup}
                                {...recipientsData}
                                isSurveyScopeTaskRelevant={isSurveyScopeTaskRelevant}
                                error={errorRecipients}
                            />
                        </div>
                    )}
                    {surveyHasLaunched && (
                        <ResponseRateTile surveyEventId={surveyEventId} surveyFormatId={surveyFormatId} />
                    )}
                    {surveyFormatId !== SurveyFormats.Paper && surveyFormatId !== SurveyFormats.Links && (
                        <ScheduleTile
                            data={surveySchedule}
                            surveyEventId={surveyEventId}
                            isScheduleLoading={isScheduleLoading}
                            scheduleError={scheduleError}
                        />
                    )}
                </Inset>
            </div>
        </>
    )
}

const SummaryFetch = ({ match }) => {
    const { surveyEventId } = match.params
    const { surveyCompanyId, organizationId } = useIdentity()

    const {
        data: recipientsData,
        isLoading: isRecipientsLoading,
        error: errorRecipients,
    } = useWorkplaceSurveyQuery(
        ['survey-recipients-summary', surveyEventId],
        `SurveySummary/${surveyEventId}/Recipients`
    )

    const {
        data: surveyEventScope,
        isLoading: isSurveyEventScopeLoading,
        error: errorSurveyEventProfile,
    } = useWorkplaceSurveyQuery(['survey-event-scope', surveyEventId], `SurveyEvent/${surveyEventId}/surveyScope`)

    const {
        data: surveySetupSummary,
        isLoading: isSurveySetupSummaryLoading,
        error: errorSurveySetupSummary,
    } = useWorkplaceSurveyQuery(['survey-setup-summary', surveyEventId], `SurveySummary/${surveyEventId}/SurveySetup`)

    const {
        data: surveySchedule,
        isLoading: isScheduleLoading,
        error: scheduleError,
    } = useWorkplaceSurveyQuery(['invitation-data', surveyEventId], `Invitation/${surveyEventId}`)

    const { data: organization, isLoading: isOrganizationLoading } = useManagementQuery(
        ['company-details', organizationId],
        'Organization/CompanyDetail'
    )

    const {
        data: awardIdentification,
        isLoading: isAwardIdentificationLoading,
        error: awardIdentificationError,
    } = useWorkplaceSurveyQuery(['award-selection', surveyEventId], `surveyevent/${surveyEventId}/awards`)

    const {
        data: deliveryContacts,
        isLoading: isDeliveryContactsLoading,
        error: errorDeliveryContacts,
    } = useManagementQuery(['delivery-contacts', organizationId], 'OrganizationContact/DeliveryContact')

    const {
        launchDate,
        surveyFormatId,
        surveyHasLaunched,
        getTaskCompletedDateTime,
        getTaskStatusInfo,
        refreshWorkflow,
        isTaskRelevant,
    } = useContext(SurveySetupContext)

    const launchApprovalTask = getTaskStatusInfo(TaskDefinition.ApproveSurveyLaunch)
    const isSurveyLaunchApproved = !!launchApprovalTask

    const previewApprovalDate = getTaskCompletedDateTime(TaskDefinition.ConfirmSurvey)
    const isSurveyPreviewConfirmed = !!previewApprovalDate

    const hasConfirmedRecipients = isTaskRelevant(TaskDefinition.ConfirmRecipients)
        ? getTaskCompletedDateTime(TaskDefinition.ConfirmRecipients)
        : true

    const hasCompletedSmsSetup = getTaskCompletedDateTime(TaskDefinition.SmsSetup)

    const isSmsTaskRelevant = isTaskRelevant(TaskDefinition.SmsSetup)
    const isSelectAwardsTaskRelevant = isTaskRelevant(TaskDefinition.SelectAwards)
    const hasIncompleteSmsSetup = isSmsTaskRelevant && !hasCompletedSmsSetup
    let hasDeliveryContactsSetup = some(deliveryContacts, 'isDeliveryContact')
    // HOTFIX until we figure out issue with user management permissions
    hasDeliveryContactsSetup = true

    return (
        <Summary
            surveyCompanyId={surveyCompanyId}
            surveyEventId={surveyEventId}
            surveyFormatId={surveyFormatId}
            launchDate={launchDate}
            surveyHasLaunched={surveyHasLaunched}
            isRecipientsLoading={isRecipientsLoading || isSurveyEventScopeLoading}
            errorRecipients={errorRecipients || errorSurveyEventProfile}
            recipientsData={{ ...recipientsData, ...surveyEventScope }}
            isSelectAwardsTileRelevant={isSelectAwardsTaskRelevant}
            awardIdentification={awardIdentification}
            isAwardIdentificationLoading={isAwardIdentificationLoading}
            awardIdentificationError={awardIdentificationError}
            surveySchedule={surveySchedule}
            isScheduleLoading={isScheduleLoading}
            isSurveyPreviewConfirmed={isSurveyPreviewConfirmed}
            isSurveyLaunchApproved={isSurveyLaunchApproved}
            hasConfirmedRecipients={hasConfirmedRecipients}
            hasIncompleteSmsSetup={hasIncompleteSmsSetup}
            launchApprovalWorkflowTask={launchApprovalTask}
            surveySetupData={surveySetupSummary}
            scheduleError={scheduleError}
            errorSurveySetupSummary={errorSurveySetupSummary}
            isSurveySetupDataLoading={isSurveySetupSummaryLoading}
            organizationData={organization}
            isOrganizationLoading={isOrganizationLoading}
            refreshWorkflow={refreshWorkflow}
            hasDeliveryContactsSetup={hasDeliveryContactsSetup}
            isDeliveryContactsLoading={isDeliveryContactsLoading}
            errorDeliveryContacts={errorDeliveryContacts}
        />
    )
}

export default withRouter(SummaryFetch)
