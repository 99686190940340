import { useMemo } from 'react'
import map from 'lodash/map'
import take from 'lodash/take'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { findInProgressTWP } from 'containers/Main/Survey/MySurveys/findInProgressTWPSurvey'
import extractWorkplaceTrendingTemplate from 'containers/Main/Survey/MySurveys/SurveySelection/extractWorkplaceTrendingTemplate'
import { buildTemplate } from 'containers/Main/Survey/MySurveys/SurveySelection/Full/FullSurveySelection'
import useFetchSurveyHistory from 'containers/Main/Survey/MySurveys/useFetchSurveyHistory'
import { SurveyPageConfiguration } from 'containers/Main/Survey/surveyPage'
import { useFetchSurveyTemplates } from 'containers/Main/Survey/Templates/useFetchSurveyTemplates'
import { useCreateSurvey } from 'containers/Main/Survey/useCreateSurvey'
import { SurveyInfoFetch } from 'containers/Main/Widgets/SurveyInfo'
import type { HelpCardItem, Item, PulseCardItem, TailPlacement } from '../models'
import { ScrollSnapSpeechBubble } from '../Shared'
import SwimLaneContainer from '../SwimLane'

type Template = {
    surveyGenerationId: number
    subType: string
}

export const SurveysAndInsightsContainer = () => {
    const { templatesToShow: maxFeaturedTemplates } = SurveyPageConfiguration.pulse
    const { templates, loadingTemplates: isFetching } = useFetchSurveyTemplates()

    const items: Item[] = useMemo(() => {
        if (!templates || isFetching) {
            return []
        }

        const availableTemplates = [...(templates as Template[])]
        const [workplaceTrendingTemplate] = extractWorkplaceTrendingTemplate(availableTemplates)
        const featuredTemplates = workplaceTrendingTemplate
            ? [...take(availableTemplates, maxFeaturedTemplates - 1), workplaceTrendingTemplate]
            : take(availableTemplates, maxFeaturedTemplates)

        const templatesToDisplay = map(featuredTemplates, (templateToDisplay) => ({
            ...templateToDisplay,
            subType: 'pulseSurvey',
        }))
        templatesToDisplay.unshift({ subType: 'surveyTile' } as Template)

        return templatesToDisplay.map((item: Item) => item)
    }, [templates, isFetching, maxFeaturedTemplates])

    return (
        <SwimLaneContainer
            sectionTitle="Your surveys & insights"
            items={items.map((item, index) => (
                <ContentDisplay key={`${index}-survey-and-insights`} item={item} />
            ))}
            isFetching={isFetching}
            dataEventId={'dashboard-pulse-tiles'}
        />
    )
}

const StyledSurveyInfoContainer = styled.div`
    scroll-snap-align: start;
    display: flex;
    margin-left: -8px;
    margin-right: -12px;
`

const PulseCardContainer = styled.div`
    scroll-snap-align: start;
    height: 472px;
    margin-right: -16px;
`

const SurveyInfoContainer = () => {
    return (
        <StyledSurveyInfoContainer tabIndex={0}>
            <SurveyInfoFetch />
        </StyledSurveyInfoContainer>
    )
}

const PulseCard = ({ item }: { item: PulseCardItem }) => {
    const configuration = SurveyPageConfiguration.pulse
    const { surveyHistory } = useFetchSurveyHistory(configuration.id)
    const location = useLocation()
    const inProgressTWPSurvey = findInProgressTWP(surveyHistory)

    const useCreateSurveyHook = useCreateSurvey({
        hasInProgressTWPSurvey: Boolean(inProgressTWPSurvey),
    })

    const { setupSurvey, workingSurveyGenerationId } = useCreateSurveyHook

    const commonOptions = {
        setupSurvey,
        workingSurveyGenerationId,
        returnUrl: location.pathname,
        labelKey: 'Pulse Swim Line Templates',
    }

    return (
        <PulseCardContainer>
            {buildTemplate({ template: item, keepMobileView: true, ...commonOptions })}
        </PulseCardContainer>
    )
}

const ContentDisplay = ({ item }: { item: Item }) => {
    switch (item.subType) {
        case 'speech':
            const { tailPlacement, text: textSpeechBubble, variant } = item as HelpCardItem
            return (
                <ScrollSnapSpeechBubble
                    tailPlacement={(tailPlacement as TailPlacement) ?? undefined}
                    tabIndex={0}
                    variant={variant ?? 'speech'}>
                    {textSpeechBubble}
                </ScrollSnapSpeechBubble>
            )
        case 'pulseSurvey':
            return <PulseCard item={item as PulseCardItem} />
        case 'surveyTile':
            return <SurveyInfoContainer />
        default:
            return <></>
    }
}
