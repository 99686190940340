import styled from 'styled-components'
import { MINNEAPOLIS_PUBLISHER_ID, REMOTE_WORK_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import colors from './colors'

export const StyledLogoYear = styled.p.attrs({ className: 'absolute font-bold' })`
    font-size: 13px;
    color: ${(props) => (props.isTopWorkplace ? colors.textBlue : colors.primaryTextColor)};
    ${(props) =>
        (props.showNationalStandardDetails && props.year > 2021) ||
        (props.publisherId === MINNEAPOLIS_PUBLISHER_ID && !props.showNationalStandardDetails)
            ? 'margin-left: 45px; margin-top: -55px;'
            : props.isDallasRankedWinner
            ? 'color: #231f20; font-size: 21px; font-weight: 900; margin-left: 274px; margin-top: -109px;'
            : props.publisherId === REMOTE_WORK_PUBLISHER_ID
            ? 'margin-left: 45px; margin-top: -99px; font-size: 10px;'
            : 'margin-left: 38px; margin-top: -78px;'};

    @media screen and (max-width: 440px) {
        ${(props) => props.isDallasRankedWinner && 'margin-left: 79%'};
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        ${(props) =>
            (props.showNationalStandardDetails && props.year > 2021) ||
            (props.publisherId === MINNEAPOLIS_PUBLISHER_ID && !props.showNationalStandardDetails)
                ? 'margin-left: 49px; margin-top: -55px;'
                : props.isDallasRankedWinner
                ? 'font-size: 23px; margin-left: 333px; margin-top: -122px;'
                : props.publisherId === REMOTE_WORK_PUBLISHER_ID
                ? 'margin-left: 36px; margin-top: -95px;'
                : 'margin-left: 33px; margin-top: -76px;'};
    }
`
