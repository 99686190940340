import map from 'lodash/map'
import { HeaderCell, HeaderRow } from 'components/Table'
import { DEFAULT } from 'containers/Main/EmployerRecognition/Shared/getSortedData'
import { SortingIcon } from 'containers/Main/EmployerRecognition/Shared/SortingIcon'
import type { TableHeaderProps } from './awardHistory.types'
import { tableColumns } from './historyTableColumns'

export const AwardHistoryTableHeader = ({ sortByColumn, isSort, sort }: TableHeaderProps) => (
    <HeaderRow className="text-sm sm:text-base flex">
        {map(tableColumns, ({ header, name, className }, index) => {
            const isSorted = Number(isSort) > DEFAULT && sortByColumn === name
            const ariaLabel = isSorted ? `${header} sorted in acending order` : header
            const ariaSort = isSorted ? 'ascending' : 'descending'
            return (
                <HeaderCell key={`${index}-award-history-table-header`} className={className} aria-sort={ariaSort}>
                    <button
                        className="flex items-center focus:outline-none transform transition-transform"
                        id={name}
                        onClick={sort}>
                        <span className="mr-1 font-bold" aria-label={ariaLabel}>
                            {header}
                        </span>
                        <SortingIcon columnName={name} activeColumnName={sortByColumn} sortingOrder={isSort} />
                    </button>
                </HeaderCell>
            )
        })}
        <HeaderCell className="w-10 sm:hidden" />
    </HeaderRow>
)
