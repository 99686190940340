import { useCallback, useEffect, useRef, useState } from 'react'
import { alert, Input, LegacyButton as Button, Modal, Skeleton, Toggle } from '@energage/components'
import { ContentCopy, Delete } from '@energage/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'clsx'
import copy from 'copy-to-clipboard'
import { get, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import { SUPPORT_EMAIL } from 'constants/strings'
import { AuthConnectionAction } from './constants'
import type { SamlAuthConnection, SsoApproveProps, SsoFormProps } from './models'
import { ssoValidationSchema } from './ssoValidationSchema'
import {
    useCreateSamlConnection,
    useEditSamlConnection,
    useGetSamlConnection,
    useTurnOnSsoConnection,
} from './useSecuritySettingsQueries'

const SsoFormContainer = styled.div`
    max-width: 612px;
`

export const SsoSettings = ({ ssoEnabled, mfaEnabled }: { ssoEnabled: boolean; mfaEnabled: boolean }) => {
    const { surveyCompanyId } = useIdentity()
    const { data: samlConnection, isFetching: isFetchingSamlConnection } = useGetSamlConnection(surveyCompanyId)

    if (isFetchingSamlConnection) {
        return (
            <div>
                <h5 className="pb-4">{'(SAML 2.0) Single Sign On'}</h5>
                <Skeleton.RectangularShape className="w-full h-40" />
            </div>
        )
    }

    return (
        <SsoFormComponent
            samlConnection={samlConnection as SamlAuthConnection}
            ssoEnabled={ssoEnabled}
            mfaEnabled={mfaEnabled}
            surveyCompanyId={surveyCompanyId}
        />
    )
}

type SsoFormComponentProps = {
    samlConnection: SamlAuthConnection
    ssoEnabled: boolean
    mfaEnabled: boolean
    surveyCompanyId: number
}

const SsoFormComponent = ({ samlConnection, ssoEnabled, mfaEnabled, surveyCompanyId }: SsoFormComponentProps) => {
    const { mutateAsync: createSamlConnection, isLoading: isCreatingSamlConnection } =
        useCreateSamlConnection(surveyCompanyId)
    const { mutateAsync: editSamlConnection, isLoading: isEditingSamlConnection } =
        useEditSamlConnection(surveyCompanyId)

    const { mutateAsync: turnOnSsoSamlConnection } = useTurnOnSsoConnection(surveyCompanyId)

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        trigger,
    } = useForm<SsoFormProps>({
        defaultValues: {
            signInUrl: samlConnection?.signInUrl || '',
            signOutUrl: '',
            callbackUrl: '',
            userIdAttribute:
                samlConnection?.userIdAttribute ??
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
            certificateFile:
                samlConnection?.base64SigningCertificate != null
                    ? new File([samlConnection?.base64SigningCertificate], 'Signing Certificate')
                    : undefined,
            base64SigningCertificate: samlConnection?.base64SigningCertificate ?? '',
            domains: samlConnection?.domains?.map((domain) => {
                return { value: domain }
            }) ?? [{ value: '' }],
            idpSsoEnabled: samlConnection?.idpInitiatedEnabled ?? false,
        },
        mode: 'all',
        delayError: 500,
        resolver: yupResolver(ssoValidationSchema),
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'domains',
    })

    const [certificateFileName, setCertificateFileName] = useState(getValues('certificateFile')?.name)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isAddingCertificate, setIsAddingCertificate] = useState<boolean>(false)

    const idpSsoEnabledValue = useWatch({ name: 'idpSsoEnabled', control })

    const modalState = useDialogState()

    const SsoApproveRequest: SsoApproveProps = {
        id: samlConnection?.id ?? '',
        action: AuthConnectionAction.Approve,
    }

    const isEditing = samlConnection?.id != null && !ssoEnabled

    const handleSsoSubmit = (data: SsoFormProps) => {
        if (isEditing) {
            // Add the Id to the editSamlConnection
            editSamlConnection({ ...data, id: samlConnection.id })
        } else {
            createSamlConnection(data)
        }
    }

    const openSsoConfirmationModal = () => {
        if (!ssoEnabled) {
            modalState.show()
        }
    }

    const onSsoEnabled = async () => {
        await turnOnSsoSamlConnection(SsoApproveRequest)
    }

    const onChange = async () => {
        await onSsoEnabled()
        modalState.hide()
    }

    const onCancel = () => {
        modalState.hide()
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const certificateFile = event.target.files[0]

            setValue('certificateFile', certificateFile)
            setCertificateFileName(event.target.files[0].name)
            trigger('certificateFile')

            if (certificateFile) {
                const reader = new FileReader()

                reader.onload = function (e) {
                    const result = e.target?.result as string
                    // Extract Base64 data from data URL
                    const base64Data = result.split(',')[1]
                    setValue('base64SigningCertificate', base64Data)
                }

                reader.readAsDataURL(certificateFile)
            }
        }
        setIsAddingCertificate(false)
    }

    const handleChooseFileClick = () => {
        setIsAddingCertificate(true)
        fileInputRef.current?.click()
    }

    useEffect(() => {
        const handleFocus = () => {
            // Assuming the user might close the dialog without selecting a file.
            setIsAddingCertificate(false)
        }

        if (isAddingCertificate) {
            window.addEventListener('focus', handleFocus)
        }

        // Cleanup function to remove the event listener.
        return () => {
            window.removeEventListener('focus', handleFocus)
        }
    }, [isAddingCertificate])

    return (
        <>
            <h5 className="pb-4">{'(SAML 2.0) Single Sign On'}</h5>
            <div id={'sso-form'} className="flex flex-row pb-4">
                <Toggle
                    aria-label="Single Sign-On"
                    size="md"
                    id="sso-toggle"
                    onContent="ON"
                    offContent="OFF"
                    checked={ssoEnabled}
                    disabled={mfaEnabled || samlConnection?.setupDetails == null || ssoEnabled}
                    onChange={openSsoConfirmationModal}
                />
                <Modal
                    className="settings-drawer__confirmation-modal"
                    {...modalState}
                    modalAriaLabel="Confirm if you want to turn on single sign on">
                    <Modal.Header hasDivider={true} onHide={modalState.hide}>
                        <p className="font-medium text-xl">{'Are you sure?'}</p>
                    </Modal.Header>
                    <Modal.Body className="py-4">
                        <p className="h5 pb-1">{'You are turning SSO on for your entire organization'}</p>
                        <p className="paragraph-lead text-lg pb-4">
                            {'This will force everyone in your organization to use SSO to sign on.'}
                        </p>
                        <p className="paragraph-lead text-base font-bold">
                            {'You will have to reach out to Energage Support to turn SSO off again.'}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button outline variant="primary" className="mr-3" size="sm" onClick={onCancel}>
                                {'Cancel'}
                            </Button>
                            <Button variant="primary" size="sm" onClick={onChange}>
                                {'Turn on SSO'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <div className="flex flex-col pl-4 pt-1 pb-2 text-base max-w-sm">
                    <p className="font-bold">{`Single Sign On is ${
                        ssoEnabled ? 'enabled' : 'off'
                    } for your Organization`}</p>
                    <span>
                        {mfaEnabled ? (
                            'MFA is currently enabled for you organization. SSO and MFA cannot be enabled at the same time. You will need to disable MFA in order to enable SSO.'
                        ) : ssoEnabled ? (
                            <>
                                {'In order to turn SSO off or make updates to these settings, you’ll need '}
                                <span className="font-bold">
                                    <ExternalLink
                                        variant="link"
                                        className="font-bold p-0 paragraph-lead text-base"
                                        aria-label="contact Energage support to turn sso off"
                                        href={`mailto:${SUPPORT_EMAIL}?subject=Disable Single Sign-On`}>
                                        {'to reach out to Energage Support.'}
                                    </ExternalLink>
                                </span>
                            </>
                        ) : (
                            'You can turn on SSO only when all the information below are complete. Test the connection and ensure it’s successful before turning on.'
                        )}
                    </span>
                </div>
            </div>
            <SsoFormContainer>
                <form
                    data-testid={'settings-sso-form'}
                    onSubmit={handleSubmit((data: SsoFormProps) => handleSsoSubmit(data))}
                    className={cx({ hidden: mfaEnabled }, 'flex flex-col pl-20 gap-5')}
                    noValidate>
                    <div className="flex flex-col gap-5 pr-6">
                        <Input
                            {...register('signInUrl')}
                            label="SSO Sign-in URL"
                            placeholder="Paste the SSO Sign-in URL from your identity provider"
                            required
                            info={errors['signInUrl']?.message}
                            error={!!get(errors, 'signInUrl')}
                            disabled={ssoEnabled}
                        />
                        <Input
                            {...register('userIdAttribute')}
                            label="User ID Attribute"
                            placeholder="Paste User ID Attribute from your identity provider"
                            required
                            info={errors['userIdAttribute']?.message}
                            error={!!get(errors, 'userIdAttribute')}
                            disabled={ssoEnabled}
                        />
                    </div>
                    <div className="flex flex-col items-start">
                        <span
                            className={cx(
                                { 'text-red500': !!errors['certificateFile'] },
                                'input-group input-group--md'
                            )}>
                            {'X509 Signing Certificate'}
                            <span className="input-group__required">{' *'}</span>
                        </span>
                        <div className="flex flex-row items-center py-2">
                            <Button
                                {...register('certificateFile')}
                                outline
                                variant="primary"
                                onClick={handleChooseFileClick}
                                disabled={ssoEnabled}>
                                {'Choose File'}
                            </Button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept=".pem,.cer,.cert"
                                style={{ display: 'none' }}
                            />
                            {!isAddingCertificate && certificateFileName != null && !errors['certificateFile'] && (
                                <div className="flex pl-4">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" rx="12" fill="#A46DF8" />
                                        <path
                                            d="M9.59631 14.9062L6.46881 11.7787L5.40381 12.8362L9.59631 17.0287L18.5963 8.02869L17.5388 6.97119L9.59631 14.9062Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <p className="pl-1 text-sm">{`${certificateFileName} uploaded`}</p>
                                </div>
                            )}
                            {isAddingCertificate && (
                                <div className="flex pl-4">
                                    <div className="loading-spinner w-4 h-4 pl-4" />
                                    <p className="pl-1 text-sm">{'Uploading file'}</p>
                                </div>
                            )}
                        </div>
                        <span className="text-xs text-grey600">
                            {'SAMLP server public key encoded in PEM or CER format'}
                        </span>
                        {!isAddingCertificate && !!errors['certificateFile'] && (
                            <p className="text-red500 text-sm">{errors['certificateFile'].message}</p>
                        )}
                    </div>
                    <div className="flex flex-col items-start gap-1 pb-5">
                        {fields.map((field, index: number) => {
                            if (index === 0) {
                                return (
                                    <Input
                                        key={field.id}
                                        {...register(`domains.${index}.value` as const)}
                                        aria-label={`Domain ${index + 1}`}
                                        label="Domain"
                                        placeholder="Enter your domain (example [organization name].com)"
                                        required
                                        className="w-full pr-6"
                                        info={!!errors['domains'] && errors['domains'][index]?.value?.message}
                                        error={!!get(errors, `domains.${index}.value`)}
                                        disabled={ssoEnabled}
                                    />
                                )
                            }

                            const hasError = !!get(errors, `domains.${index}.value`)
                            return (
                                <div className="flex flex-row w-full">
                                    <Input
                                        key={field.id}
                                        {...register(`domains.${index}.value` as const)}
                                        aria-label={`Domain ${index + 1}`}
                                        label="Domain"
                                        placeholder="Enter your domain (example [organization name].com)"
                                        className={cx(ssoEnabled ? 'w-full pr-6' : 'w-full')}
                                        info={!!errors['domains'] && errors['domains'][index]?.value?.message}
                                        error={hasError}
                                        disabled={ssoEnabled}
                                    />
                                    {!ssoEnabled && (
                                        <Button
                                            variant="link"
                                            aria-label={`Remove domain number ${index + 1}`}
                                            className={cx(
                                                hasError ? 'mb-2' : 'mt-4',
                                                'p-0 text-primary-dark hover:text-grey600'
                                            )}
                                            onClick={() => remove(index)}
                                            disabled={ssoEnabled}>
                                            <Delete />
                                        </Button>
                                    )}
                                </div>
                            )
                        })}
                        <Button
                            outline
                            variant="primary"
                            onClick={() => append({ value: '' })}
                            className="my-2"
                            disabled={ssoEnabled}>
                            {'Add Another Domain'}
                        </Button>
                    </div>
                    <div className="flex flex-row items-center">
                        <Toggle
                            {...register('idpSsoEnabled')}
                            aria-label="Identity Provider Single Sign-On"
                            size="sm"
                            id="idp-sso-toggle"
                            onContent="on"
                            offContent="off"
                            disabled={ssoEnabled}
                        />
                        <span className="pl-4">{`IdP initiated SSO ${idpSsoEnabledValue ? 'on' : 'off'}`}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        {isEditing ? (
                            <div>
                                <p className="text-base font-bold">{'Need to make updates?'}</p>
                                <p className="text-sm">
                                    {
                                        'If there are problems with your current setup, you can edit your connection before enabling it.'
                                    }
                                </p>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="my-2"
                                    disabled={isEditingSamlConnection || ssoEnabled}>
                                    {'Edit'}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <p className="text-base font-bold">{'Submit to Create your connection'}</p>
                                <p className="text-sm">
                                    {
                                        'After submitting, you’ll get the EntityID and ReplyUrl to enter into your identity provider.'
                                    }
                                </p>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="my-2"
                                    disabled={isCreatingSamlConnection || ssoEnabled}>
                                    {'Submit'}
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            </SsoFormContainer>
            <div className={cx({ hidden: mfaEnabled }, 'pl-20 pt-4 flex flex-col items-start')}>
                {isCreatingSamlConnection ? (
                    <TestSsoInfoSkeleton />
                ) : (
                    samlConnection?.setupDetails != null && (
                        <div className="flex flex-col gap-4 bg-white rounded p-3 mb-5">
                            <p className="text-purple700 text-sm font-medium">
                                {
                                    'Please enter the following information into your Energage Application in your identity provider before testing your SSO connection:'
                                }
                            </p>
                            <div>
                                <CopyableItem label={'EntityID'} value={samlConnection?.setupDetails?.entityId} />
                                <CopyableItem label={'Reply URL'} value={samlConnection?.setupDetails?.replyUrl} />
                            </div>
                            <p className="text-purple700 text-xs font-normal">
                                {
                                    'Please reach out to your IT resources if you don’t have access to edit enterprise apps in your identity provider.'
                                }
                            </p>
                        </div>
                    )
                )}
                <p className="text-base font-bold">{'Test Your SSO Connection'}</p>
                <span className="text-sm">
                    {
                        "Once you've verified your connection through testing and are ready, you can fully enable single sign-on for your organization."
                    }
                </span>
                <Button
                    variant="primary"
                    disabled={samlConnection?.setupDetails == null || ssoEnabled}
                    className="my-2"
                    onClick={() => window.open(samlConnection?.setupDetails?.testUrl, '_blank')}>
                    {'Test SSO Settings'}
                </Button>
            </div>
        </>
    )
}

export const ParagraphWithLineCutOff = styled.p.attrs({ className: 'pr-2' })`
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

const CopyableItem = ({ label, value }: { label: string; value: string }) => {
    const copyNameToClipboard = useCallback((value: string) => {
        const copied = copy(value)
        if (copied) {
            alert.success(`"${value}" copied to clipboard!`)
        }
    }, [])

    return (
        <div className="flex flex-row text-sm">
            <label className="font-bold pr-1 whitespace-no-wrap">{`${label}:`}</label>
            <ParagraphWithLineCutOff>{value}</ParagraphWithLineCutOff>
            <Button
                variant="link"
                onClick={() => copyNameToClipboard(value)}
                aria-label={`Copy ${label} to clipboard`}
                className="p-0 text-primary-dark hover:text-grey600">
                <ContentCopy key={value} name={value} width={18} height={18} />
            </Button>
        </div>
    )
}

const TestSsoInfoSkeleton = () => (
    <div className="flex flex-col gap-1 bg-white rounded p-3 mb-5 w-full">
        <Skeleton.RectangularShape className="w-full h-5" />
        <Skeleton.RectangularShape className="w-2/3 h-5" />
        <Skeleton.SingleLineShape className="w-3/4" />
        <Skeleton.SingleLineShape className="w-3/4" />
        <Skeleton.RectangularShape className="w-full h-4" />
    </div>
)
