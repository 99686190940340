import map from 'lodash/map'
import { routes } from 'constants/routes'
import colors from 'containers/Main/AwardNotification/colors'
import Section from 'containers/Main/AwardNotification/Section'
import { getFaqsForAspiring, getFaqsForWinner } from 'containers/Main/AwardNotification/Sections/FAQs/faqList'
import YellowButton from 'containers/Main/AwardNotification/YellowButton'
import { CE_AWARD_NAME, INDUSTRY_AWARD_NAME } from 'containers/Main/EmployerBranding/MediaKit/constants'

const FAQs = ({
    bgColor,
    textColor,
    faqUrl,
    isWinner,
    companyName,
    year,
    isPdf,
    companyProfileUrl,
    sectionClassName,
    baseUrl,
    surveyCompanyId,
    awardName,
    hasCEPremiumMediaKit,
    hasIndustryPremiumMediaKit,
    isDallasRankedWinner,
    showDallasNationalStandardDetails,
    isMediaKitAnnouncement,
}) => {
    const isCEAward = awardName === CE_AWARD_NAME && hasCEPremiumMediaKit
    const isIndustryAward = awardName === INDUSTRY_AWARD_NAME && hasIndustryPremiumMediaKit
    const mediaKitExternalLink = routes.branding.mediaKit()
    const faqList = isWinner
        ? getFaqsForWinner(
              isPdf,
              companyProfileUrl,
              mediaKitExternalLink,
              isMediaKitAnnouncement,
              baseUrl,
              surveyCompanyId,
              year,
              isCEAward,
              isIndustryAward
          )
        : getFaqsForAspiring(companyName, year)
    const isDallasAwardMediaKit = isDallasRankedWinner || showDallasNationalStandardDetails

    return (
        <Section
            bgColor={isDallasAwardMediaKit ? colors.bgBlue : bgColor}
            textColor={isDallasAwardMediaKit ? colors.textWhite : textColor}
            companyName={companyName}
            sectionClassName={sectionClassName}
            isPdf={isPdf}>
            <h1 className="text-5xl font-black pb-6">{'Frequently Asked Questions'}</h1>
            <div>
                {map(faqList, ({ question, answer }) => (
                    <>
                        <h2 className="text-xl font-bold mt-8">{question}</h2>
                        <p className="mt-2 sm:hidden">{answer}</p>
                    </>
                ))}
            </div>
            <div className="mt-12 text-center">
                <YellowButton variant="link" href={faqUrl} target="_blank">
                    {'Top Workplaces FAQ'}
                </YellowButton>
            </div>
        </Section>
    )
}

export default FAQs
