import { Text } from '@energage/typography'
import cx from 'clsx'
import { Breadcrumb, Breadcrumbs, Link } from 'react-aria-components'
import styled from 'styled-components'
import type { BreadcrumbsProps } from './models'

const StyledBreadcrumb = styled(Breadcrumb)`
    &:not(:last-child)::after {
        content: '>' / '';
        alt: ' ';
        padding: 0 2px;
    }
`

// todo(cm, color-change): text-primary-dark is going to become grey900 which will make this
//  go from dark to light instead of light to dark
const activePathClasses = 'text-primary-dark hover:text-grey800'
const inactivePathClasses = 'text-secondary-dark hover:text-grey700'

export const BreadcrumbsContainer = ({ path, onBreadcrumbClick, onRootClick }: BreadcrumbsProps) => {
    return (
        <Breadcrumbs className="pl-0 list-none flex flex-wrap gap-2 title4 text-grey600">
            <StyledBreadcrumb className="flex items-center">
                <Text textStyle="title4" className={cx(path.length > 0 ? inactivePathClasses : activePathClasses)}>
                    <Link
                        className="cursor-pointer hover:underline"
                        onPress={() => onRootClick()}
                        aria-label={'Go to all company files'}>
                        {'All files'}
                    </Link>
                </Text>
            </StyledBreadcrumb>
            {path.map((folder, index) => (
                <StyledBreadcrumb key={index} className="flex items-center">
                    <Text
                        textStyle="title4"
                        className={cx(path.length === index + 1 ? activePathClasses : inactivePathClasses)}>
                        <Link
                            className="cursor-pointer hover:underline"
                            onPress={() => onBreadcrumbClick(index)}
                            aria-label={`Go to ${folder} folder`}>
                            {folder}
                        </Link>
                    </Text>
                </StyledBreadcrumb>
            ))}
        </Breadcrumbs>
    )
}
