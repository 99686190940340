import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import some from 'lodash/some'
import CreatableSelect from 'react-select/creatable'
import keyCodes from 'constants/keyCodes'

const components = {
    DropdownIndicator: null,
}

const createOption = (label) => ({
    label,
    value: label,
})

const MultiSelectTextInput = ({ placeholderText, initialOptions = [], onOptionsChange }) => {
    const [inputValue, setInput] = useState('')
    const [options, setOptions] = useState(map(initialOptions, createOption))

    useEffect(() => {
        let arr = map(options, (el) => el.label)
        onOptionsChange(arr)
    }, [options, onOptionsChange])

    const handleChange = (value) => {
        setOptions(value ?? [])
    }
    const handleInputChange = (input) => {
        setInput(input)
    }
    const handleKeyDown = (event) => {
        const code = event.charCode || event.keyCode
        switch (code) {
            case keyCodes.ENTER:
            case keyCodes.SPACE:
            case keyCodes.TAB:
                updateOptions()
                event.preventDefault()
                break
            default:
                break
        }
    }
    const handleBlur = (event) => {
        updateOptions()
        event.preventDefault()
    }

    const updateOptions = () => {
        if (!inputValue) {
            return
        }
        const optionExists = some(options, (el) => el.value.toUpperCase() === inputValue.toUpperCase())
        if (!optionExists) {
            setOptions([...options, createOption(inputValue)])
        }
        setInput('')
    }

    return (
        <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onBlur={handleBlur}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholderText}
            value={options}
        />
    )
}

MultiSelectTextInput.propTypes = {
    placeHolderText: PropTypes.string,
    initialOptions: PropTypes.arrayOf(PropTypes.string),
    onOptionsChange: PropTypes.func.isRequired,
}

export default MultiSelectTextInput
