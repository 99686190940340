import { useContext } from 'react'
import { Loading as Spinner } from '@energage/components'
import { EventAvailable, FormatPaint, Group, Icon, List } from '@energage/icons'
import { height } from '@energage/theme'
import loadable from '@loadable/component'
import includes from 'lodash/includes'
import { Redirect, Route } from 'react-router-dom'
import styled from 'styled-components'
import { useIdentity, usePermissions } from 'components/Identity'
import { useBackButton } from 'components/Navigation'
import { TopNavLayout, TopNavRoute } from 'components/TopNav'
import { makePath, routes } from 'constants/routes'
import { TemplateCategory } from 'constants/SurveyType'
import { useSetUserMenuSurveyEventId } from 'hooks'
import { ReactComponent as TwpIcon } from 'images/twp.svg'
import { AwardSelectionPage } from './AwardSelection'
import ConfigureSurvey from './ConfigureSurvey'
import GoToNextSetupStep from './GoToNextSetupStep'
import HeaderTeleporter from './HeaderTeleporter'
import Recipients from './Recipients'
import { SurveyFormats } from './Recipients/FormatSurvey'
import ScheduleSurvey from './ScheduleSurvey'
import Summary from './Summary'
import SurveySetupContext from './SurveySetupContext'
import { SurveyTitle } from './SurveyTitle'
import useSurveyEventSetup from './useSurveyEventSetup'
import { useSurveySetupWorkflow } from './useSurveySetupWorkflow'
import { SetupTask } from './workflow'

const RecipientsV2Component = loadable(async () => {
    const comp = await import('./RecipientsV2')
    return comp.RecipientsView
})

const StyledTopNavLayout = styled(TopNavLayout).attrs((props) => ({
    'sequential': true,
    'displayName': 'Survey Setup',
    'primaryAction': props.showPrimaryAction ? <HeaderTeleporter.Target /> : null,
    'data-testid': 'survey-setup-nav',
}))`
    .top-nav__primary-action {
        height: ${height(16)};
    }
`

function getBackPath(surveyTemplateCategoryId) {
    return surveyTemplateCategoryId === TemplateCategory.Standard ? routes.survey.workplace() : routes.survey.pulse()
}

const SurveySetup = ({ location, match }) => {
    const { surveyEventId } = match.params
    const { hasUploadRecipientsAtSurveyEventLevel } = usePermissions()
    const { surveySetupContext, data, status, currentPage, taskStatuses, showAwardsPage, workflowTasks } =
        useSurveySetupWorkflow(surveyEventId, location.pathname)

    useSetUserMenuSurveyEventId(surveyEventId)

    const { organizationId } = useIdentity()
    const { surveyEventSetup, ...surveyEventSetupMethods } = useSurveyEventSetup(surveyEventId, organizationId)

    const backPath = useBackButton(null, getBackPath(data?.surveyTemplateCategoryId))

    if (isNaN(surveyEventId)) {
        return <Redirect to={routes.survey.workplace()} />
    }

    if (!data || !surveyEventSetup) {
        return <Spinner />
    }

    if (data && data.surveyFormatId === SurveyFormats.CrossRegion && data.sourceSurveyEventId) {
        return <Redirect to={routes.survey.setup.nextStep({ surveyEventId: data.sourceSurveyEventId })} />
    }

    if (data && (status[currentPage?.id]?.disabled || currentPage === undefined)) {
        return <Redirect to={surveySetupContext.getNextPage().route({ surveyEventId })} />
    }

    const buildPath = makePath(match.params)

    return (
        <SurveySetupContext.Provider value={{ ...surveySetupContext, surveyEventSetup, ...surveyEventSetupMethods }}>
            <StyledTopNavLayout
                showPrimaryAction={surveySetupContext.showPrimaryAction}
                title={<SurveyTitle value={data.name} surveyEventId={surveyEventId} backPath={backPath} />}>
                <Redirect exact from={routes.survey.setup()} to={routes.survey.setup.create()} />
                <Route
                    path={routes.survey.setup.nextStep()}
                    render={(props) => (
                        <GoToNextSetupStep {...props} taskStatuses={taskStatuses} workflowTasks={workflowTasks} />
                    )}
                />
                {showAwardsPage ? (
                    <TopNavRoute
                        path={buildPath(routes.survey.setup.awards)}
                        title="Select Awards"
                        status={status[SetupTask.Award]?.status}
                        disabled={status[SetupTask.Award]?.disabled}
                        component={AwardSelectionPage}
                        icon={<Icon as={TwpIcon} />}
                        onClick={surveySetupContext?.refreshWorkflow}
                    />
                ) : null}
                <TopNavRoute
                    path={buildPath(routes.survey.setup.create)}
                    title="Configure Survey"
                    icon={<FormatPaint />}
                    status={status[SetupTask.Create]?.status}
                    disabled={status[SetupTask.Create]?.disabled}
                    component={ConfigureSurvey}
                    onClick={surveySetupContext?.refreshWorkflow}
                />
                <TopNavRoute
                    path={buildPath(routes.survey.setup.recipients)}
                    title="Recipients"
                    icon={<Group />}
                    status={status[SetupTask.Recipients]?.status}
                    disabled={status[SetupTask.Recipients]?.disabled}
                    isActive={(_, location) =>
                        includes(location.pathname, routes.survey.setup.recipients({ surveyEventId }))
                    }
                    onClick={surveySetupContext?.refreshWorkflow}>
                    {hasUploadRecipientsAtSurveyEventLevel ? <RecipientsV2Component /> : <Recipients />}
                </TopNavRoute>
                <TopNavRoute
                    path={buildPath(routes.survey.setup.schedule)}
                    title="Schedule"
                    icon={<EventAvailable />}
                    status={status[SetupTask.Schedule]?.status}
                    disabled={status[SetupTask.Schedule]?.disabled}
                    component={ScheduleSurvey}
                    onClick={surveySetupContext?.refreshWorkflow}
                />
                <TopNavRoute
                    path={buildPath(routes.survey.setup.summary)}
                    title="Summary"
                    icon={<List />}
                    status={status[SetupTask.Summary]?.status}
                    component={Summary}
                    onClick={surveySetupContext?.refreshWorkflow}
                />
            </StyledTopNavLayout>
        </SurveySetupContext.Provider>
    )
}

export default SurveySetup

export const useSurveySetupContext = () => useContext(SurveySetupContext)
