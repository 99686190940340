import { useState } from 'react'
import { alert } from '@energage/components'
import { useAtomValue } from 'jotai/utils'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import merge from 'lodash/merge'
import some from 'lodash/some'
import { useWorkplaceSurveyQuery } from 'api'
import { generateRandomKey } from 'util/generateRandomKey'
import { isEmptyString } from 'util/strings'
import { QuestionType } from './CustomQuestions/QuestionType'
import QuestionCategory from './QuestionCategory'
import { createSurveySetupAtom, demographicQuestionsAtom, sectionFamily } from './statementSetupUtility/store'

const diversityDemographicsAtom = createSurveySetupAtom((get) => {
    const deiSection = get(sectionFamily({ id: QuestionCategory.DEI }))

    if (!deiSection) {
        return []
    }

    const { questions } = deiSection
    return filter(questions, (q) => q.questionTypeId === QuestionType.Demographic)
})

function addError(question, error) {
    if (!question._errors) {
        question._errors = {}
    }

    merge(question._errors, error)
}

function useDemographicQuestionCreation(
    surveyEventId,
    [{ questions: demographicQuestions }, updateSectionQuestions],
    isSelected,
    isParentSelected
) {
    const diversityQuestions = useAtomValue(diversityDemographicsAtom)
    const allDemographicQuestions = useAtomValue(demographicQuestionsAtom)
    const [editingQuestionId, setEditingQuestionId] = useState()
    const [savedQuestion, setSavedQuestion] = useState(null)

    const changeQuestionId = (questionId) => {
        if (editingQuestionId === 0) {
            handleDeleteDemographicQuestion(editingQuestionId, true)
        }
        setEditingQuestionId(questionId)
    }

    const setDemographicQuestions = (questions) => {
        updateSectionQuestions((section) => ({ ...section, questions }))
    }

    const handleAddDemographicQuestion = () => {
        const newDemographicQuestions = demographicQuestions.slice()
        const newQuestion = {
            questionEssenceId: 0,
            questionTypeId: QuestionType.Demographic,
            name: '',
            text: '',
            demographicTypeName: '',
            isSelected: true,
            isParentSelected: false,
            isSubmitting: false,
            isRequired: false,
            demographicTypeId: 0,
            answerOptions: [
                { id: '', name: '', _key: generateRandomKey() },
                { id: '', name: '', _key: generateRandomKey() },
            ],
            isEditing: true,
            isEditableDemographicQuestion: true,
            conditionalQuestionEssenceIds: [],
            targetGroupDescription: null,
            _key: generateRandomKey(),
            _errors: {},
        }
        newDemographicQuestions.push(newQuestion)
        setDemographicQuestions(newDemographicQuestions)
        setEditingQuestionId(0)
    }

    const { mutateAsync: _deleteDemographicQuestion, ...deleteDemographicQuestionState } =
        useWorkplaceSurveyQuery.mutate(async (questionToDelete, api) => {
            await api.delete(`demographicquestion/${surveyEventId}/type/${questionToDelete.demographicTypeId}`)
        })

    const handleDeleteDemographicQuestion = async (
        questionId,
        isCancelAction = false,
        demographicQuestionToEdit = {}
    ) => {
        const question = filter(demographicQuestions, (q) => q.questionEssenceId === questionId)[0]

        try {
            if (isCancelAction && question.questionEssenceId !== 0) {
                handleDemographicQuestionTextChange(demographicQuestionToEdit[questionId].text, questionId)
                setEditingQuestionId()
            } else {
                if (question.questionEssenceId !== 0 && question.demographicTypeId) {
                    await _deleteDemographicQuestion(question)
                }

                onDeleteDemographicQuestion(question)
            }
        } catch {
            alert.danger('There was an error deleting your question')
        }
    }

    const handleDemographicQuestionTextChange = (value, questionId) => {
        const updatedDemographicQuestions = demographicQuestions.slice()
        const question = find(updatedDemographicQuestions, (q) => q.questionEssenceId === questionId)
        question.text = value
        addError(question, { required: isEmptyString(value) ? 'Required' : null })
        setDemographicQuestions(updatedDemographicQuestions)
    }

    const onDeleteDemographicQuestion = (questionToDelete) => {
        const updatedDemographicQuestions = demographicQuestions.slice()
        const index = updatedDemographicQuestions.indexOf(questionToDelete)
        updatedDemographicQuestions.splice(index, 1)
        setDemographicQuestions(updatedDemographicQuestions)
        if (questionToDelete.questionEssenceId === editingQuestionId) {
            setEditingQuestionId()
        }
    }

    const onDemographicQuestionSave = (data) => {
        const updatedDemographicQuestions = demographicQuestions.slice()
        const oldQuestionIndex = findIndex(updatedDemographicQuestions, (q) => q.questionEssenceId === 0)
        updatedDemographicQuestions.splice(oldQuestionIndex, 1)
        const newQuestion = {
            ...data,
            isSubmitting: false,
            isEditing: false,
            _key: data.questionEssenceId,
            _errors: {},
        }
        updatedDemographicQuestions.push(newQuestion)
        setEditingQuestionId()
        setDemographicQuestions(updatedDemographicQuestions)
    }

    const onDemographicQuestionUpdate = (updatedQuestion, questionSaved) => {
        const updatedDemographicQuestions = demographicQuestions.slice()
        const index = demographicQuestions.indexOf(questionSaved)

        updatedDemographicQuestions[index] = updatedQuestion

        setSavedQuestion(updatedQuestion)
        setEditingQuestionId()
        setDemographicQuestions(updatedDemographicQuestions)
    }

    function validateDuplicateDemographicType(questionId, name) {
        const otherQuestions = filter(allDemographicQuestions, (q) => q.questionEssenceId !== questionId)

        const findDemographicTypeName = (item) => {
            return item.demographicTypeName.toLowerCase() === name.toLowerCase()
        }

        return !some(otherQuestions, findDemographicTypeName) && !some(diversityQuestions, findDemographicTypeName)
    }

    return {
        demographicQuestions: map(demographicQuestions, (q) => ({
            ...q,
            isSelected: isSelected(q),
            isParentSelected: isParentSelected(q),
            isConditional: q.conditionalQuestionEssenceIds.length > 0,
        })),
        setDemographicQuestions,
        handleAddDemographicQuestion,
        handleDemographicQuestionTextChange,
        handleDeleteDemographicQuestion,
        onDemographicQuestionSave,
        onDemographicQuestionUpdate,
        setEditingQuestionId,
        editingQuestionId,
        deleteDemographicQuestionState,
        validateDuplicateDemographicType,
        changeQuestionId,
        savedQuestion,
    }
}

export default useDemographicQuestionCreation
