import { ButtonLink } from '@energage/components'
import cx from 'clsx'
import { routes } from 'constants/routes'
import HighlightCultureImg from 'images/awardAnnouncement/highlightCultureRevamp.svg'
import type { AwardAnnouncementSection } from '../awardAnnouncement.types'
import { BgContainer } from '../BgContainer'

export const HighlightCulture = ({ baseUrl, isPdf }: AwardAnnouncementSection) => (
    <BgContainer className="p-4 bg-grey100">
        <div className="flex flex-col items-center justify-center ">
            <h3 className="text-center font-bold m-2 text-xl md:w-2/5">
                {'While this is not the result you had hoped for, we are here to help with next steps!'}
            </h3>
        </div>
        <div className="flex flex-col align-center justify-center md:flex-row items-center">
            <div className={cx('p-4', { 'mt-4 md:ml-4 md:w-1/2': !isPdf })}>
                <div className={cx({ 'flex justify-center': isPdf, 'md:float-right pr-10': !isPdf })}>
                    <img className={cx({ 'w-4/5': isPdf })} alt={'Show off Organization'} src={HighlightCultureImg} />
                </div>
            </div>
            <div className="p-4 md:w-1/2">
                <h2 className="text-xl">{'Highlight your unique culture'}</h2>
                <div>
                    <p className="text-base md:w-4/5 mt-2">
                        {
                            'Discover the incredible achievements waiting to be showcased within your organization! Explore the power of Employer Branding to amplify your employees’ positive survey feedback across your website, social media platforms, recruitment materials, and beyond. Elevate your brand and attract top talent with confidence.'
                        }
                    </p>
                    <div className="mt-5">
                        <ButtonLink
                            href={isPdf ? `${baseUrl}branding` : routes.branding()}
                            target="_blank"
                            appearance="secondary">
                            {'Visit Employer Branding'}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </div>
    </BgContainer>
)
