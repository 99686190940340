import * as d3 from 'd3'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import join from 'lodash/join'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import './getComputedStylePolyfill'
import cloud from './subclouds'
import WordCloudRenderer from './WordCloudRenderer'

const FONT_SIZE = { minFontSize: 10, maxFontSize: 28 }

class WordGroups {
    constructor() {
        this.wordGroups = []
    }

    addWordGroup(wordGroup) {
        this.wordGroups.push(wordGroup)
    }

    groupForWord(word) {
        return find(this.wordGroups, (group) => includes(group, word))
    }
}

export default {
    create(parentElement, data) {
        const { displaySize, ariaTitle, ariaDescription } = data
        const svg = d3
            .select(parentElement)
            .append('svg')
            .attr('height', displaySize.height)
            .attr('width', displaySize.width)
            .attr('preserveAspectRatio', 'xMidYMid')
            .attr('class', 'w-full sm:w-auto')

        if (ariaTitle) {
            svg.append('title').text(ariaTitle)
        }

        if (ariaDescription) {
            svg.append('desc').text(ariaDescription)
        }

        svg.append('g').attr('class', 'slash')
        svg.append('g').attr('class', 'sub-cloud-connections')
        svg.append('g').attr('class', 'word-cloud-highlights')
        svg.append('g').attr('class', 'word-cloud')
        this.update(svg, data)
        return svg
    },

    update(element, data) {
        const identity = (d) => d

        const {
            displaySize,
            chartSize,
            zoomToFit,
            droplets,
            onDropletClick,
            colors,
            textWeightScale = getWeightScale,
            opacity = getOpacityScale,
            spiralType = 'archimedean',
            requiresBoundingBox = false,
        } = data

        if (!droplets || droplets.length === 0) {
            return
        }
        const words = flattenSingleDropletSubClouds(droplets)
        const allWords = reduce(
            words,
            (array, droplet) => (droplet.droplets ? concat(array, droplet.droplets) : concat(array, droplet)),
            []
        )
        let fontScale = getFontScale(allWords)
        let maxFontSize = FONT_SIZE.maxFontSize
        const wordGroups = new WordGroups()
        const fontOpts = {
            fontScale,
            colors,
            fontWeight: textWeightScale,
            opacityScale: opacity,
        }

        const wordCloudRenderer = WordCloudRenderer(wordGroups, fontOpts)

        layoutSubClouds()
        layoutCloud(words, spiralType, cloudBuildComplete, null, true)

        function cloudBuildComplete(wordsToRender, bounds, isSuccess) {
            if (isSuccess) {
                drawWords(wordsToRender, bounds)
            } else {
                let placeAllWords = true
                maxFontSize = maxFontSize - 1

                if (maxFontSize < FONT_SIZE.maxFontSize - 10) {
                    // just in case: recursion exit condition if font reduced too much
                    maxFontSize = FONT_SIZE.maxFontSize
                    placeAllWords = false
                }

                fontScale = getFontScale(allWords, { minFontSize: FONT_SIZE.minFontSize, maxFontSize })
                layoutCloud(words, spiralType, cloudBuildComplete, null, placeAllWords)
            }
        }

        function layoutSubClouds() {
            const subClouds = filter(words, (d) => !!d.droplets)
            forEach(subClouds, (subCloud) => {
                wordGroups.addWordGroup(map(subCloud.droplets, (d) => d.text))
                layoutCloud(
                    subCloud.droplets,
                    'rectangular',
                    (subWords, bounds) => {
                        const [cloudStart, cloudEnd] = bounds
                        const boundingBox = {
                            x: cloudStart.x,
                            y: cloudStart.y,
                            width: cloudEnd.x - cloudStart.x,
                            height: cloudEnd.y - cloudStart.y,
                        }
                        subCloud.boundingBox = boundingBox
                        subCloud.width = boundingBox.width * 1.2
                        subCloud.height = boundingBox.height

                        if (requiresBoundingBox) {
                            addDropletBoundingBox(subWords)
                        }
                    },
                    true
                )
            })
        }

        function addDropletBoundingBox(subWords) {
            // Populate the sub-cloud into a temporary SVG to determine the size of the bounding box
            const temp = element.append('g')
            temp.selectAll('text')
                .data(subWords)
                .enter()
                .append('text')
                .style('font-size', (d) => `${fontScale(d)}px`)
                .attr('text-anchor', 'middle')
                .text((d) => d.text)
                .call(wordCloudRenderer.calculateBoundingBox)
            temp.remove()
        }

        function layoutCloud(cloudWords, spiral, onEnd, isSubCloud, placeAllWords = false) {
            cloud()
                .size([chartSize.width, chartSize.height])
                .words(cloudWords)
                .padding(isSubCloud ? 5 : 3)
                .rotate(0)
                .spiral(spiral)
                .font('sans-serif')
                .fontSize(fontScale)
                .text((d) => d.text)
                .on('end', onEnd)
                .placeAllWords(placeAllWords)
                .start()
        }

        function drawWords(wordsToRender, bounds) {
            const groupTransform = `translate(${[chartSize.width / 2, chartSize.height / 2]})`
            const subClouds = filter(words, (d) => !!d.droplets)
            const wordCloudWords = flattenWordCloudDroplets(wordsToRender)
            const subCloudWordBorderColor = getParentBackgroundColor(element.node())

            element
                .select('.sub-cloud-connections')
                .attr('transform', groupTransform)
                .selectAll('g')
                .data(subClouds)
                .enter()
                .append('g')
                .each(function (d) {
                    wordCloudRenderer.renderSubCloudConnections(this, d, subCloudWordBorderColor)
                })
            const wordCloud = element
                .select('.word-cloud')
                .attr('transform', groupTransform)
                .selectAll('text')
                .data(wordCloudWords)
                .enter()
                .append('text')
                .on('click', onDropletClick || identity)
                .call(wordCloudRenderer.renderTooltip)
            wordCloudRenderer.renderWordCloudWords(wordCloud, !!onDropletClick).then(() => {
                const [cloudStart, cloudEnd] = bounds
                const cloudRect = {
                    x: cloudStart.x,
                    y: cloudStart.y,
                    width: cloudEnd.x - cloudStart.x,
                    height: cloudEnd.y - cloudStart.y,
                }
                const wordCloudViewBox = zoomToFit
                    ? [cloudRect.x, cloudRect.y, cloudRect.width, cloudRect.height]
                    : getTrimmedViewBox()

                const height = zoomToFit ? displaySize.height : Math.min(displaySize.height, cloudRect.height)
                element.attr('height', height).attr('viewBox', join(wordCloudViewBox, ' '))

                function getTrimmedViewBox() {
                    const isTrimming = cloudRect.height < chartSize.height
                    if (!isTrimming) {
                        return [0, 0, chartSize.width, chartSize.height]
                    }
                    const displayHeight = Math.min(chartSize.height, cloudRect.height + 40)
                    return [0, (chartSize.height - displayHeight) / 2, chartSize.width, displayHeight]
                }
            })
        }
    },

    destroy(svg) {
        if (svg) {
            svg.remove()
        }
    },
}

function flattenSingleDropletSubClouds(droplets) {
    return reduce(
        droplets,
        (array, droplet) =>
            !droplet.droplets || droplet.droplets.length > 1
                ? concat(array, droplet)
                : concat(array, droplet.droplets[0]),
        []
    )
}

function flattenWordCloudDroplets(droplets) {
    return reduce(
        droplets,
        (array, droplet) => {
            if (droplet.droplets) {
                const offsets = { x: droplet.x, y: droplet.y }
                return concat(
                    array,
                    map(droplet.droplets, (subDroplet) => ({
                        ...subDroplet,
                        x: subDroplet.x + offsets.x,
                        y: subDroplet.y + offsets.y,
                    }))
                )
            } else {
                return concat(array, droplet)
            }
        },
        []
    )
}

function getOpacityScale() {
    return 1.0
}

function getWeightScale() {
    return 'normal'
}

function getFontScale(words, fontSize = FONT_SIZE) {
    const { minFontSize, maxFontSize } = fontSize
    const scale = d3
        .scaleLinear()
        .domain(d3.extent(words, (d) => parseFloat(d.weight)))
        .range([minFontSize, maxFontSize])

    return (d) => scale(parseFloat(d.weight))
}

function getParentBackgroundColor(node) {
    let currNode = node
    let parentBgColor = 'white'

    const getBgColor = (ele) => window.getComputedStyle(ele, null).getPropertyValue('background-color')
    const isTransparent = (value) => value === 'transparent' || /rgba\(\d{1,3}, \d{1,3}, \d{1,3}, 0\)/.test(value)

    while ((currNode = getParentNode(currNode)) !== null) {
        const bgColor = getBgColor(currNode)
        if (bgColor !== '' && !isTransparent(bgColor)) {
            parentBgColor = bgColor
            break
        }
    }
    return parentBgColor
}

function getParentNode(node) {
    // IE doesn't have parentElement on SVG
    // so use parentNode... but switch back to parentElement
    // when not an svg because parentNode can return
    // document fragments which getComputedStyle barfs on
    if (node.tagName.toLowerCase() === 'svg') {
        return node.parentNode
    }

    return node.parentElement
}
