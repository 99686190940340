export const MissedParticipationText = () => {
    return (
        <>
            <p className="paragraph-bold mb-1">{'This award has recently closed'}</p>
            <p className="text-sm">
                {
                    'This award has recently closed. Stay tuned for next year, we will notify you when the award is ready for participation.'
                }
            </p>
        </>
    )
}
export const MissedParticipationComponent = () => {
    return (
        <div className="flex flex-col justify-between h-full">
            <div className="flex">
                <div className="my-3">
                    <MissedParticipationText />
                </div>
            </div>
        </div>
    )
}
