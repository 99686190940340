export default {
    bgLightBlue: '#F2FCFF',
    bgLightGrey: '#F4F4F4',
    bgBlue: '#334373',
    bgDarkYellow: '#FDBF3F',
    bgDarkBlue: '#001450',
    bgYellow: '#FFF6E4',
    textBlue: '#001450',
    textWhite: '#ffffff',
    textDark: '#313239',
    textDarkBlue: '#334373',
    primaryTextColor: '#2E3336',
    textPurple: '#4A09B3',
}
