import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAtomValue } from 'jotai/utils'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import noop from 'lodash/noop'
import some from 'lodash/some'
import { useDemographicSectionContext } from '../../DemographicCategorySection/DemographicSectionContext'
import QuestionCategory from '../../QuestionCategory'
import { useDemographicQuestionToggles } from '../../statementSetupHooks/useDemographicQuestionToggles'
import { allQuestionsAtom, selectedQuestionsAtom } from '../../statementSetupUtility/store'
import { DemographicOptionsProvider } from '../DemographicOptionsContext'
import QuestionTableContext from '../QuestionTableContext'
import { HeaderCell, HeaderRow, Table, TableRow } from '../TableElements'
import DemographicQuestionTableRow from './DemographicQuestionTableRow'

const DemographicQuestionTable = ({
    title,
    demographicQuestions,
    onUpdateQuestions,
    editingQuestionId,
    isEditable,
    handleDemographicQuestionTextChange,
    handleDeleteDemographicQuestion,
    handleSaveDemographicQuestion,
    isPosting,
    disabled,
    setEditingQuestionId,
    validateDuplicateDemographicType,
    hasOptionalItems,
    isPurchaser,
    savedQuestion,
}) => {
    const allQuestions = useAtomValue(allQuestionsAtom)
    const selectedQuestionAtoms = useAtomValue(selectedQuestionsAtom)
    const { demographicQuestionToEdit, setDemographicQuestionToEdit } = useDemographicSectionContext()

    useEffect(() => {
        if (editingQuestionId && !demographicQuestionToEdit[editingQuestionId]) {
            const editQuestion = find(demographicQuestions, (q) => q.questionEssenceId === editingQuestionId)

            setDemographicQuestionToEdit({
                ...demographicQuestionToEdit,
                [editingQuestionId]: {
                    ...editQuestion,
                    answerOptionsOriginal: cloneDeep(editQuestion.answerOptions),
                },
            })
        }
    }, [editingQuestionId, demographicQuestions, demographicQuestionToEdit, setDemographicQuestionToEdit])

    const { handleToggleQuestion } = useDemographicQuestionToggles(
        demographicQuestions,
        allQuestions,
        onUpdateQuestions,
        noop
    )
    return (
        <Table>
            <HeaderRow>
                <HeaderCell className="w-1/3 md:w-1/6">
                    <div className="pl-7 pr-4 md:pr-2">{'Topic'}</div>
                </HeaderCell>
                <HeaderCell className="w-2/3 md:w-1/3">
                    <label htmlFor={QuestionCategory.Demographic}>{title}</label>
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/2 pr-2">
                    {'Details'}
                </HeaderCell>
            </HeaderRow>
            <div className="w-full">
                {hasOptionalItems && demographicQuestions.length === 0 && (
                    <TableRow>{`Add optional demographics from the list below if you would like to include them in your survey.`}</TableRow>
                )}

                <QuestionTableContext.Provider value={handleToggleQuestion}>
                    {map(demographicQuestions, (q, index) => (
                        <DemographicOptionsProvider
                            key={`${q.questionEssenceId}-${index}`}
                            initialState={{ question: q }}
                            onUpdateQuestions={onUpdateQuestions}>
                            <DemographicQuestionTableRow
                                questionId={q.questionEssenceId}
                                question={q}
                                parentSelected={q.isParentSelected}
                                disabled={disabled || q.isRequired}
                                isSortingDisabled={true}
                                hasActiveDependentQuestion={some(selectedQuestionAtoms, (selectedQuestion) => {
                                    return includes(selectedQuestion.conditionalQuestionEssenceIds, q.questionEssenceId)
                                })}
                                targetGroupDescription={q.targetGroupDescription}
                                isEditing={q.questionEssenceId === editingQuestionId}
                                isEditable={isEditable && q.isEditableDemographicQuestion && isPurchaser}
                                handleDeleteQuestion={handleDeleteDemographicQuestion}
                                handleSaveQuestion={handleSaveDemographicQuestion}
                                handleDemographicQuestionTextChange={handleDemographicQuestionTextChange}
                                isPosting={isPosting}
                                setEditingQuestionId={setEditingQuestionId}
                                validateDuplicateDemographicType={validateDuplicateDemographicType}
                                isHighlighted={q.isHighlighted}
                                savedQuestion={savedQuestion}
                            />
                        </DemographicOptionsProvider>
                    ))}
                </QuestionTableContext.Provider>
            </div>
        </Table>
    )
}

DemographicQuestionTable.propTypes = {
    title: PropTypes.string,
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

export default DemographicQuestionTable
