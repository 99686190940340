import { useCallback, useEffect } from 'react'
import { ExpandLess, ExpandMore, Icon } from '@energage/icons'
import cx from 'clsx'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import filter from 'lodash/filter'
import map from 'lodash/map'
import noop from 'lodash/noop'
import { Accordion } from 'components/Accordion'
import { usePrevious } from 'hooks'
import QuestionCategory from './QuestionCategory'
import { getOffset, reorder, resetSequenceForSubsection } from './questionsReorderFunctions'
import { SortableQuestionTable } from './QuestionTable/SortableQuestionTable'
import {
    forceAdditionalItemsAccordion,
    questionFamily,
    sectionFamily,
    sectionQuestionCountFamily,
    subSectionFamily,
} from './statementSetupUtility/store'
import useEditQuestion from './useEditQuestion'

function useRemoveQuestion(sectionId, onChange = noop) {
    const updateAdditionalQuestionsSection = useUpdateAtom(sectionFamily({ id: sectionId }))
    return useCallback(
        (questionEssenceId) => {
            updateAdditionalQuestionsSection((section) => {
                const subsectionsWithRemovedQuestion = map(section.subsections, (subsection) => ({
                    ...subsection,
                    questions: filter(
                        subsection.questions,
                        (question) => question.questionEssenceId !== questionEssenceId
                    ),
                }))

                return {
                    ...section,
                    subsections: map(subsectionsWithRemovedQuestion, (subsection, index) => {
                        const offsetIndex = getOffset(index, subsectionsWithRemovedQuestion)
                        return {
                            ...subsection,
                            questions: map(subsection.questions, (question, questionIndex) => ({
                                ...question,
                                sequence: offsetIndex + questionIndex,
                            })),
                        }
                    }),
                }
            })
            questionFamily.remove({ questionEssenceId })
            onChange()
        },
        [onChange, updateAdditionalQuestionsSection]
    )
}

function AccordionTrigger({ title, text, open, toggle }) {
    const [forcedOpen, setForcedOpen] = useAtom(forceAdditionalItemsAccordion)

    useEffect(() => {
        if (forcedOpen && !open) {
            toggle()
        }
    }, [forcedOpen, open, toggle])

    const onClick = () => {
        if (open) {
            setForcedOpen(null)
        }
        toggle()
    }

    return (
        <>
            <button
                className={cx('w-full flex justify-between items-center pr-5 focus:outline-none pt-4', !text && 'pb-4')}
                onClick={onClick}>
                <div className="text-left">{title}</div>
                <div className="text-grey600">
                    <Icon className="block" as={open ? ExpandLess : ExpandMore} />
                </div>
            </button>
            {text && <div className="pb-4 pt-2 max-w-md paragraph">{text}</div>}
        </>
    )
}

function AdditionalQuestionsSubsectionProvider({
    sectionId,
    subsectionId,
    disabled,
    isSortingDisabled,
    onRemoveQuestion,
    onDragEnd,
    className,
    editingQuestionId,
    originalQuestionText,
    onCancelEditAdditionalQuestion,
    onEditAdditionalQuestion,
    onSaveAdditionalQuestion,
    isSavingQuestion,
    departments,
}) {
    const subsection = useAtomValue(subSectionFamily({ sectionId, subsectionId }))

    const handleDragEnd = useCallback(
        (result) => {
            if (!result.destination) {
                return
            }

            const newOrder = reorder(subsection.questions, result.source.index, result.destination.index)
            onDragEnd(newOrder, subsectionId)
        },
        [onDragEnd, subsection.questions, subsectionId]
    )

    return subsection.questions.length > 0 ? (
        <SortableQuestionTable
            categoryId={QuestionCategory.Custom}
            sortableQuestions={subsection.questions}
            otherQuestions={[]}
            droppableId={subsection.subsectionId}
            title={subsection.categoryName}
            disabled={disabled}
            isSortingDisabled={isSortingDisabled}
            onRemoveQuestion={onRemoveQuestion}
            onDragEnd={handleDragEnd}
            className={className}
            editingQuestionId={editingQuestionId}
            originalQuestionText={originalQuestionText}
            onCancelEditQuestion={onCancelEditAdditionalQuestion}
            onEditQuestion={onEditAdditionalQuestion}
            onUpdateQuestion={onSaveAdditionalQuestion}
            isSavingQuestion={isSavingQuestion}
            departments={departments}
        />
    ) : null
}

export function AdditionalQuestionsCategorySection({
    sectionName,
    subsections,
    sectionId,
    disabled,
    onUpdateQuestions,
    surveyEventId,
    testId,
    departments,
}) {
    const { totalCount } = useAtomValue(sectionQuestionCountFamily({ sectionId }))
    const updateAdditionalQuestionsSection = useUpdateAtom(sectionFamily({ id: sectionId }))
    const onRemoveQuestion = useRemoveQuestion(sectionId, onUpdateQuestions)
    const prevTotalCount = usePrevious(totalCount)

    const {
        editingQuestionId,
        onUpdateQuestion,
        onEditQuestion,
        onCancelEditQuestion,
        isSavingQuestion,
        originalQuestionText,
    } = useEditQuestion(surveyEventId, updateAdditionalQuestionsSection)

    const resetSequence = useCallback(
        (subsectionId, newQuestionOrder) =>
            resetSequenceForSubsection(updateAdditionalQuestionsSection)(subsectionId, newQuestionOrder),
        [updateAdditionalQuestionsSection]
    )

    const onDragEnd = useCallback(
        (newQuestionOrder, subsectionId) => {
            resetSequence(subsectionId, newQuestionOrder)
            onUpdateQuestions()
        },
        [resetSequence, onUpdateQuestions]
    )

    if (totalCount === 0) {
        return null
    }

    return (
        <Accordion
            key={QuestionCategory.Custom}
            testId={testId}
            title={<div className="h5 px-6">{sectionName}</div>}
            text={null}
            renderTrigger={AccordionTrigger}
            defaultOpen={prevTotalCount === 0 && totalCount > 0}>
            <div className="px-0 sm:px-6">
                {map(subsections, (subsection, index) => (
                    <AdditionalQuestionsSubsectionProvider
                        key={subsection.subsectionId}
                        sectionId={sectionId}
                        subsectionId={subsection.subsectionId}
                        onDragEnd={onDragEnd}
                        onRemoveQuestion={onRemoveQuestion}
                        disabled={disabled}
                        isSortingDisabled={disabled}
                        className={index === 0 ? '' : 'mt-4'}
                        editingQuestionId={editingQuestionId}
                        originalQuestionText={originalQuestionText}
                        onCancelEditAdditionalQuestion={onCancelEditQuestion}
                        onEditAdditionalQuestion={onEditQuestion}
                        onSaveAdditionalQuestion={onUpdateQuestion}
                        isSavingQuestion={isSavingQuestion}
                        departments={departments}
                    />
                ))}
            </div>
        </Accordion>
    )
}
