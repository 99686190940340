import type { CSSProperties } from 'react'
import { Alert, InputGroup, Select } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import find from 'lodash/find'
import type { ControlProps } from 'react-select'
import { ExternalLink } from 'components/Anchor'

export const RandomSampleHelpCenterLink = ({ linkText }: { linkText: string }) => (
    <ExternalLink
        href="https://energagecommunity.force.com/FAQ/s/article/How-to-Use-Random-Sampling"
        target="_blank"
        rel="noopener">
        {linkText}
    </ExternalLink>
)

export const TwpUsaNotSurveyingAllMessage =
    'In order to qualify for Top Workplaces USA, Culture Excellence, or Industry awards you must survey your entire employee population.'
export const RandomSampleCountMismatch = `Your uploaded file doesn't match your confirmed employees. Please adjust your employee count on the Select Awards page, upload a new file or deselect random sampling`
export const RandomSampleNotAnOption =
    'In order to use random sampling organization must have at least 1000 employees in award regions'

export const randomSampleMinimumRequirementNotMet = (minimumInviteeCount: number) => {
    return `Based on your confirmed employee counts, you must survey at least  ${minimumInviteeCount} employees in order to meet the random sampling guidelines`
}

const whoYouAreSurveyingOptions = [
    { value: 1, label: 'Entire employee population' },
    {
        value: 2,
        label: 'Entire employee population with a random sample of that population',
    },
    {
        value: 3,
        label: 'Surveying regionally',
    },
    {
        value: 4,
        label: 'Surveying regionally with a random sample of that population',
    },
]

type SurveyScopeOption = {
    value: number
    label: string
    isDisabled?: boolean
}

type SurveyScopeProps = {
    isRandomSample: boolean
    surveyScopeSaveState: string | null
    minimumInviteeCountForRandomSampling: number
    hasAllLocationsSurveyed?: boolean
    isInviteeCountSubstantiallyDifferentFromRegionalCounts: boolean
    hasMinimumQualifyingInviteeCount: boolean
    isEmployeeCountConfirmed: boolean
    regionsConfirmedEmployeeTotal: number
    inviteeCount: number
    hasNationalAward: boolean
    handleChangeWhoYouAreSurveying: (hasAllLocationsSurveyed: boolean | null, isRandomSample: boolean) => void
    label?: string
    className?: string
    placeholder?: string
    disabled?: boolean
}

function GetRandomSamplingErrorMessage(
    minimumInviteeCountForRandomSampling: number,
    isEmployeeCountConfirmed: boolean,
    inviteeCount: number,
    regionsConfirmedEmployeeTotal: number
) {
    if (!isEmployeeCountConfirmed) {
        return 'Please provide and confirm employee counts on the Select Awards page to determine random sampling eligibility'
    }

    if (regionsConfirmedEmployeeTotal < 1000) {
        return RandomSampleNotAnOption
    }

    const isRandomSampleMeetsMinimumRequirement = inviteeCount >= minimumInviteeCountForRandomSampling
    if (!isRandomSampleMeetsMinimumRequirement) {
        return randomSampleMinimumRequirementNotMet(minimumInviteeCountForRandomSampling)
    }

    const isRandomSamplingValid = regionsConfirmedEmployeeTotal > inviteeCount
    if (!isRandomSamplingValid) {
        return RandomSampleCountMismatch
    }
    return ''
}

function GetWhoYouAreSurveyingDefaultOption(hasAllLocationsSurveyed: boolean | undefined, isRandomSample: boolean) {
    if (hasAllLocationsSurveyed == null) {
        return 0
    }

    if (!hasAllLocationsSurveyed) {
        return isRandomSample ? 4 : 3
    }

    return isRandomSample ? 2 : 1
}

export const SurveyScope = ({
    isRandomSample,
    surveyScopeSaveState,
    minimumInviteeCountForRandomSampling,
    hasAllLocationsSurveyed,
    isInviteeCountSubstantiallyDifferentFromRegionalCounts,
    hasMinimumQualifyingInviteeCount,
    isEmployeeCountConfirmed,
    regionsConfirmedEmployeeTotal,
    inviteeCount,
    hasNationalAward,
    handleChangeWhoYouAreSurveying,
    label = 'Please select who you are surveying',
    placeholder,
    className,
    disabled,
}: SurveyScopeProps) => {
    const errorMessage =
        hasAllLocationsSurveyed === false && hasNationalAward
            ? TwpUsaNotSurveyingAllMessage
            : isRandomSample
            ? GetRandomSamplingErrorMessage(
                  minimumInviteeCountForRandomSampling,
                  isEmployeeCountConfirmed,
                  inviteeCount,
                  regionsConfirmedEmployeeTotal
              )
            : ''

    const defaultWhoYouAreSurveyOption = GetWhoYouAreSurveyingDefaultOption(hasAllLocationsSurveyed, isRandomSample)

    const onWhoYouAreSurveyingChange = ({ value }: SurveyScopeOption) => {
        const hasAllLocationsSurveyed = value === 1 || value === 2
        const isRandomSample = value === 2 || value === 4

        handleChangeWhoYouAreSurveying(hasAllLocationsSurveyed, isRandomSample)
    }

    const hasErrorMessage =
        (!isInviteeCountSubstantiallyDifferentFromRegionalCounts || hasMinimumQualifyingInviteeCount) && errorMessage

    return (
        <>
            <div className={cx('pt-4 pb-2', className)}>
                {hasErrorMessage && (
                    <div className="flex flex-col pb-4 max-w-sm">
                        <Alert variant="danger" className="text-xs px-3 py-2 w-auto">
                            {errorMessage}
                        </Alert>
                    </div>
                )}
                <div className="flex items-center">
                    <InputGroup
                        className="w-full max-w-sm"
                        styles={{
                            control: (
                                provided: CSSProperties,
                                { menuIsOpen, isFocused }: ControlProps<SurveyScopeOption, false>
                            ) => {
                                const styles = {
                                    'borderColor': hasErrorMessage
                                        ? colors.red500
                                        : isFocused || menuIsOpen
                                        ? colors.blue300
                                        : colors.grey300,
                                    ':hover': {
                                        borderColor: hasErrorMessage ? colors.red500 : colors.grey300,
                                    },
                                }

                                return { ...provided, outline: 'none', boxShadow: 'none', ...styles }
                            },
                            menuList: (provided: CSSProperties) => ({
                                ...provided,
                                color: colors.grey700,
                            }),
                        }}
                        required
                        error={hasErrorMessage}
                        name="whoYouAreSurveying"
                        label={label}
                        placeholder={placeholder}
                        as={Select}
                        id="whoYouAreSurveying"
                        options={whoYouAreSurveyingOptions}
                        onChange={onWhoYouAreSurveyingChange}
                        isDisabled={disabled}
                        defaultValue={find(whoYouAreSurveyingOptions, (x) => x.value === defaultWhoYouAreSurveyOption)}
                    />
                    <AnimatePresence>
                        {surveyScopeSaveState ? (
                            <motion.span
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="text-grey500 ml-5 text-sm">
                                {surveyScopeSaveState}
                            </motion.span>
                        ) : null}
                    </AnimatePresence>
                </div>
                <RandomSampleHelpCenterLink linkText="Learn more about random sampling" />
            </div>
        </>
    )
}
