const SurveyFormats = {
    Paper: 1,
    OnlinePaper: 2,
    Online: 3,
    Links: 4,
    CrossRegion: 5,
} as const

export default SurveyFormats

export type SurveyFormat = typeof SurveyFormats[keyof typeof SurveyFormats]

export const onlineFormats = new Set<SurveyFormat>([SurveyFormats.Online, SurveyFormats.OnlinePaper])
export const paperFormats = new Set<SurveyFormat>([SurveyFormats.Paper, SurveyFormats.OnlinePaper])
