export enum AuthConnectionAction {
    RequestApproval = 0,
    Approve = 1,
    RequestDisable = 2,
    Disable = 3,
}

export const ACTIVE_MFA_CONNECTION_EXCEPTION = 'ActiveMfaConnectionException'

export const ACTIVE_SSO_CONNECTION_EXCEPTION = 'ActiveSsoConnectionException'
