import { UPCOMING_AWARD_URL } from 'containers/Main/AwardAnnouncement/constants'

export const faqList = (companyName: string, year: number) => [
    {
        question: 'How is the Top Workplaces list determined?',
        answer: (
            <>
                {
                    'Top Workplaces winners are determined by who? Those who know your organization best : your employees. The Top Workplaces program starts with capturing their feedback on what matters most about your culture. Workplace Survey measures 15 culture drivers organized into four themes or "imperatives". Scoring well on these statements indicates a healthy organizaion, which means more engaged employees and better business results. We also measure what we call The Basics, The Leader and Engagement. Each of the statements is measured using seven-point Likert scale, from "Strongly Disagree" to "Neutral" and "Strongly Agree".'
                }
            </>
        ),
    },
    {
        question: `Are you saying ${companyName} is a bad place to work?`,
        answer: (
            <>
                {
                    'Not at all. The Top Workplaces program is highly competitive and only a fraction of the participants win the award. Unfortunately, lots of very good workplaces did not make the list.'
                }
            </>
        ),
    },
    {
        question: 'Will anyone else know we were not named a Top Workplace?',
        answer: (
            <>
                {
                    "You and Energage. That's it. Confidentiality is vitally important to us. We do not (and will not) share the names of participating organizations. Energage only provides our media partner with the list of organizations that will be recognized as Top Workplaces."
                }
            </>
        ),
    },
    {
        question: 'When is the next chance for my organization to participate in Top Workplace?',
        answer: (
            <>
                <span>{'Your survey results apply to any award for 12 months after your survey closes.'}</span>
                <a
                    className="text-yellow500 font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={UPCOMING_AWARD_URL}>
                    {` Here is the list of awards for ${year}.`}
                </a>
                <span>{' You can resurvey at any time to try to improve your scores.'}</span>
                <div>{'Besides: '}</div>
                <div>
                    <li>
                        {
                            'Regular Employee Surveys provide refreshed data-driven insights to focus your efforts and guide your decision making.'
                        }
                    </li>
                    <li>
                        {`Participation illustrates to employees that you value their opinions and you're committed to making ${companyName}, the best workplace it can be.`}
                    </li>
                    <li>
                        {
                            "There's no negative press when you don't make the list. But there is positive recognition when you do."
                        }
                    </li>
                </div>
            </>
        ),
    },
]
