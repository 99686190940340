import { useEffect } from 'react'
import { Alert, LegacyButton as Button } from '@energage/components'
import { useToggle } from '@energage/hooks'
import { Add, Delete, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import size from 'lodash/size'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import SimpleTooltip from 'components/SimpleTooltip'
import { LocationType } from 'containers/Main/EmployerRecognition/constants'
import { getDefaultLocation, modifyRegionName } from 'containers/Main/EmployerRecognition/utils'
import type { FieldT, Location } from './awardRegistration.types'
import { Field } from './Field'

const MIN_LOCATIONS_DISPLAY_COUNT = 3

type RegionLocationProps = {
    formFields: FieldT
    awardName: string
    isNationalPublisher: boolean
    regionName: string
    locations: Location[]
    setFormLocations: (locations: Location[]) => void
    setDeletedLocation: (location: Location) => void
    error: unknown
}

const StyledLocationDiv = styled('div')`
    .has-address-error .address-input input,
    .has-address-error .address-input div > div {
        border-color: ${colors.red500};
    }
`

export const RegionLocationForm = ({
    formFields,
    awardName,
    isNationalPublisher,
    regionName,
    locations,
    setFormLocations,
    setDeletedLocation,
    error,
}: RegionLocationProps) => {
    const [isExpanded, expandBlock] = useToggle(false)

    const formMethods = useFormContext()

    const { append, fields, remove, replace } = useFieldArray({
        name: 'locations',
        keyName: 'companyLocationId',
    })

    const handleRemoveLocation = async (index: number, id: undefined) => {
        const location = fields[index]
        const formLocations = filter(locations, (location, i) => index !== i)
        await setFormLocations(formLocations)
        if (location && id) {
            setDeletedLocation(location as Location)
        }
        remove(index)
        formMethods.trigger('locations')
    }

    const addLocation = () => {
        if (!isExpanded) {
            expandBlock()
        }
        append(getDefaultLocation(isNationalPublisher, size(fields)))
    }

    const onLocationFieldChange = async (fieldName: string) => {
        const index = fieldName?.match(/\[(.*?)\]/)?.[1]
        if (index) {
            const outsideRegionFieldName = `locations[${index}].isOutsideRegion`
            if (formMethods.getValues(outsideRegionFieldName)) {
                formMethods.setValue(outsideRegionFieldName, false)
                formMethods.setValue('locations', formMethods.getValues('locations'))
            }
            await setFormLocations(formMethods.getValues('locations'))

            const isAnyDuplicate = includes(
                map(formMethods.formState.errors?.locations, (value) => {
                    return value?.zipCode?.message === 'Duplicate Zip'
                }),
                true
            )
            if (isAnyDuplicate) {
                formMethods.trigger('locations')
            }

            const location = formMethods.getValues(`locations[${index}]`)
            if (
                !isAnyDuplicate &&
                location.stateAbbreviation &&
                location.streetAddress &&
                location.zipCode &&
                location.city
            ) {
                formMethods.trigger(`locations[${index}]`)
            }
        }
    }

    useEffect(() => {
        if (fields.length === 0 && !locations) {
            append(getDefaultLocation(isNationalPublisher))
        }
    }, [fields, locations, append, isNationalPublisher])

    useEffect(() => {
        if (error && error !== null) {
            replace(locations)
        }
        // eslint-disable-next-line
    }, [error])

    return (
        <div>
            {map(
                fields,
                (field: Location, index: number) =>
                    (index < MIN_LOCATIONS_DISPLAY_COUNT || (index >= MIN_LOCATIONS_DISPLAY_COUNT && isExpanded)) && (
                        <div key={field.index}>
                            <h6
                                className={cx('font-bold text-base my-3 flex', {
                                    'justify-between': index > 0,
                                    'justify-end': index === 0,
                                })}>
                                {index > 0 && <span>{`${modifyRegionName(regionName)} Location ${index + 1}`}</span>}
                                {fields.length > 1 && (
                                    <SimpleTooltip
                                        disabled={field.typeId !== LocationType.Headquarters}
                                        placement="top"
                                        trigger={
                                            <Button
                                                variant="link"
                                                type="button"
                                                disabled={field.typeId === LocationType.Headquarters}
                                                className="p-0 block text-grey600"
                                                onClick={() => handleRemoveLocation(index, field.id)}>
                                                <Icon as={Delete} />
                                            </Button>
                                        }
                                        className="m-0 inline-block">
                                        <div className="w-48">{'Primary Location cannot be deleted'}</div>
                                    </SimpleTooltip>
                                )}
                            </h6>
                            {field.isOutsideRegion && (
                                <Alert variant="danger" className="mb-4 mt-3">
                                    {`This location is not a part of ${awardName} area. Please enter another location in order to participate in ${awardName}.`}
                                </Alert>
                            )}
                            <StyledLocationDiv>
                                <div
                                    className={cx('flex flex-wrap', {
                                        'has-address-error': field.isOutsideRegion,
                                    })}>
                                    {map(formFields, (formField: FieldT, key: number) => (
                                        <div key={key} className={formField.className}>
                                            <Field
                                                {...formField}
                                                fieldName={`locations[${index}].${formField?.fieldName}`}
                                                onLocationFieldChange={onLocationFieldChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </StyledLocationDiv>
                        </div>
                    )
            )}
            <div
                className={cx('flex mb-7 text-xs sm:text-sm', {
                    'justify-between': fields.length > MIN_LOCATIONS_DISPLAY_COUNT,
                    'justify-end': fields.length <= MIN_LOCATIONS_DISPLAY_COUNT,
                })}>
                {fields.length > MIN_LOCATIONS_DISPLAY_COUNT && (
                    <Button variant="link" type="button" className="p-0" onClick={expandBlock}>
                        {`See ${isExpanded ? 'Less' : `${fields.length - MIN_LOCATIONS_DISPLAY_COUNT} More Locations`}`}
                    </Button>
                )}
                <Button variant="link" type="button" className="p-0" onClick={addLocation}>
                    {'Add Another Location'}
                    <Icon as={Add} />
                </Button>
            </div>
        </div>
    )
}
