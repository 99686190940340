import PropTypes from 'prop-types'
import cx from 'clsx'
import format from 'date-fns/format'
import map from 'lodash/map'
import styled from 'styled-components'

const DATE_FORMAT = 'MMMM yyyy'

const CheckedPill = styled.span.attrs(({ isPdf }) => ({
    className: cx('w-auto inline-flex overflow-hidden rounded-full leading-5', isPdf ? 'mb-4 ml-8' : 'mb-2 ml-4'),
}))`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    padding: ${(props) => (props.isPdf ? '6px 16px 10px' : '2px 8px 4px')};
    font-weight: 600;
`

const StyledDiv = styled.div`
    background-color: ${(props) => props.backgroundColor};
`

const renderName = (pillsTextColor, foregroundColor, isPdf) => (value, key) =>
    value && (
        <CheckedPill bgColor={foregroundColor} textColor={pillsTextColor} key={key} isPdf={isPdf}>
            {key === 'surveyEventName' ? format(new Date(value), DATE_FORMAT) : value}
        </CheckedPill>
    )

const SelectedFilterPills = ({ foregroundColor, pillsTextColor, filterData, isPdf }) => {
    return (
        <StyledDiv className={cx(isPdf ? 'text-2xl' : 'text-sm', 'block p-px')}>
            {map(filterData, renderName(pillsTextColor, foregroundColor, isPdf))}
        </StyledDiv>
    )
}

SelectedFilterPills.propTypes = {
    bgColor: PropTypes.string.isRequired,
}

export default SelectedFilterPills
