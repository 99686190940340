import { useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import styled from 'styled-components'
import { EmpCommentsCirclePreview } from 'containers/Public/RevampWidgets/EmpCommentsCirclePreview'
import { EmpCommentsQuotePreview } from 'containers/Public/RevampWidgets/EmpCommentsQuotePreview'
import LoveMyJobBecause from 'containers/Public/RevampWidgets/LoveMyJobBecause'
import { InsufficientDataWarning } from '../InsufficientDataWarning'
import type { CarouselControlProps, EmpCommentsPreviewProps } from '../LoveMyJobConfiguration/EmpComments.types'
import { StyledCarousel } from '../StyledCarousel'
import { previewType } from '../utils'

const Button = styled.button`
    &:focus:not(:focus-visible) {
        outline: none;
    }
`

const DataWarning = styled.div<{ backgroundColor?: string; textColor?: string }>`
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.backgroundColor};
`

const renderLeftControls = ({ previousSlide, slideCount = 1 }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="ml-2" aria-label="Previous Comment Preview" onClick={previousSlide}>
                <KeyboardArrowLeft size={24} />
            </Button>
        )
    )
}

const renderRightControls = ({ nextSlide, slideCount = 1 }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="mr-2" aria-label="Next Comment Preview" onClick={nextSlide}>
                <KeyboardArrowRight size={24} />
            </Button>
        )
    )
}

const renderBottomCenterControls = ({ currentSlide, slideCount = 1 }: CarouselControlProps) =>
    slideCount > 1 && `${currentSlide + 1} of ${slideCount}`

const renderAnnounceSlideMsg = ({ currentSlide, selectedData }: CarouselControlProps) => {
    const altText = 'Verified by Top Workplaces. I love my job because'
    const slideData = selectedData[currentSlide] ? selectedData[currentSlide].text : ''

    return `${altText}, ${slideData}, image`
}

export const EmployeeCommentsPreview = ({
    data,
    dataId,
    hasEmployerBrandingSubscription,
    isExpiredWidget,
    filterData,
}: EmpCommentsPreviewProps) => {
    const { comments, selectedDesignType, backgroundColor, highlightColor, titleColor, textColor } = data || {}
    const selectedData =
        selectedDesignType === previewType.Classical
            ? filter(comments, 'isSelected')
            : filter(comments, (d) => d.numberOfCharacters <= 250 && d.isSelected)

    useEffect(() => {
        const sliderDivElements = document.querySelector('.empComments .slider-frame')
        if (sliderDivElements) {
            sliderDivElements.setAttribute('aria-hidden', 'true')
        }
    }, [])

    if (selectedDesignType === previewType.Classical || size(comments) <= 0) {
        return (
            <LoveMyJobBecause
                //@ts-ignore
                dataId={dataId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                {...data}
            />
        )
    }

    if (!head(selectedData)) {
        return (
            <DataWarning className="rounded-lg" backgroundColor={backgroundColor} textColor={textColor}>
                <InsufficientDataWarning warningText="Not enough data for Employee Comments" />
            </DataWarning>
        )
    }

    return (
        //@ts-ignore
        <StyledCarousel
            width="449px"
            height="auto"
            className="empComments"
            autoGenerateStyleTag={false}
            wrapAround
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderAnnounceSlideMessage={({ currentSlide }) => renderAnnounceSlideMsg({ currentSlide, selectedData })}
            renderBottomLeftControls={renderLeftControls}
            renderBottomRightControls={renderRightControls}
            renderBottomCenterControls={renderBottomCenterControls}>
            {map(selectedData, ({ text }, index) =>
                selectedDesignType === previewType.TriangleDesign ? (
                    <EmpCommentsQuotePreview
                        key={index}
                        text={text}
                        width="449px"
                        height="480px"
                        backgroundColor={backgroundColor}
                        highlightColor={highlightColor}
                        titleColor={titleColor}
                        textColor={textColor}
                    />
                ) : (
                    <EmpCommentsCirclePreview
                        key={index}
                        text={text}
                        width="449px"
                        height="490px"
                        backgroundColor={backgroundColor}
                        highlightColor={highlightColor}
                        titleColor={titleColor}
                        textColor={textColor}
                    />
                )
            )}
        </StyledCarousel>
    )
}
