interface WarningTextProps {
    className?: string
    warningText: string
}

export const InsufficientDataWarning = ({ className, warningText }: WarningTextProps) => (
    <div
        className={
            className ? className : 'h-48 font-bold flex text-xl items-center px-8 sm:px-12'
        }>{`${warningText}`}</div>
)
