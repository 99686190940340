import { Close } from '@energage/icons'
import { colors } from '@energage/theme'

export default function CloseButton({ dataId, size = 24, disabled, ...rest }) {
    return (
        <button
            disabled={disabled}
            aria-label="Close modal"
            type="button"
            data-event-id={dataId && `${dataId}-close`}
            {...rest}>
            <Close color={disabled ? colors.grey300 : colors.grey700} size={size} />
        </button>
    )
}
