import type { AwardSelectionPageResult, SelectedAward } from './awardselection.types'

const findOverlappingGroups = (awardId: number, overlappingAwards: AwardSelectionPageResult['overlappingGroups']) => {
    return overlappingAwards.find((overlappingAward) => overlappingAward.awardIds.includes(awardId))?.awardIds
}

function getAwardsMatchingOverlaps(awardsToFind: number[], awards: SelectedAward[]) {
    return awards.filter((award) => awardsToFind.includes(award.awardId))
}

const getEmployeeCountForAward = (award: SelectedAward) =>
    Math.max(Number(award.employeeCount ?? 0), award.minimumEmployeeCount ?? 0)

const getMaxOverlappingEmployeeCount = (
    overlappingAwardIds: number[],
    selectedAwards: SelectedAward[],
    getQualifyingEmployeeCount = getMinimumEmployeeCountForAward
) => {
    const overlappingAwards = getAwardsMatchingOverlaps(overlappingAwardIds, selectedAwards)

    return Math.max(...overlappingAwards.map((award) => getQualifyingEmployeeCount(award)))
}

export function getEmployeeCountsUsingOverlaps(
    selectedAwards: SelectedAward[],
    overlappingGroups: AwardSelectionPageResult['overlappingGroups'],
    getQualifyingEmployeeCount = getEmployeeCountForAward
) {
    const alreadyAddedAwards = new Set<number>()
    let count = 0

    for (const selectedAward of selectedAwards) {
        if (alreadyAddedAwards.has(selectedAward.awardId)) {
            continue
        }

        const overlappingAwards =
            overlappingGroups.length > 0 && findOverlappingGroups(selectedAward.awardId, overlappingGroups)
        if (overlappingAwards) {
            count += getMaxOverlappingEmployeeCount(overlappingAwards, selectedAwards, getQualifyingEmployeeCount)
            for (const overlappingAward of overlappingAwards) {
                alreadyAddedAwards.add(overlappingAward)
            }
        } else {
            count += getQualifyingEmployeeCount(selectedAward)
            alreadyAddedAwards.add(selectedAward.awardId)
        }
    }

    return count
}

export function getMinimumEmployeeCountForAward(award: SelectedAward) {
    return award.minimumEmployeeCount ?? 0
}
