import isPast from 'date-fns/isPast'
import parseISO from 'date-fns/parseISO'
import compact from 'lodash/compact'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import head from 'lodash/head'
import includes from 'lodash/includes'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'
import reduce from 'lodash/reduce'
import replace from 'lodash/replace'
import round from 'lodash/round'
import size from 'lodash/size'
import trim from 'lodash/trim'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import pipe from 'lodash/fp/pipe'
import {
    getPercentageOfNumber,
    isOneTimeChargedProduct,
    isPublished,
    renewalOptionTypes,
    validateExpirationDate,
    validateOpportunityId,
    validatePurchaseOrderNumber,
} from 'containers/Main/OrderManagement/common/utils'
import getZuoraCostCalculator from 'containers/Main/OrderManagement/common/zuoraCostCalculator'
import { terms } from 'containers/Main/OrderManagement/Orders/OrderCart/text'
import generateObject from 'util/generateObject'

const DEFAULT_PRODUCT_QUANTITY = 1

const DEFAULT_TIME_ZONE = {
    displayName: '(UTC-05:00) Eastern Time (US & Canada)',
    ianaKey: 'America/New_York',
    id: 55,
    microsoftKey: 'Eastern Standard Time',
    name: 'America/New_York',
}

const { None, AddToSubscription, Included, RemoveFromSubscription } = renewalOptionTypes

export const calculatePriceByZuoraCalculator = (product, quantity) => {
    if (!quantity) {
        return 0
    }
    const productTotalCost = reduce(
        product.productRatePlanCharges,
        (total, ratePlanCharge) => {
            const {
                model,
                pricing: [pricing],
            } = ratePlanCharge

            const calculator = getZuoraCostCalculator(model.name)
            const productPrice = calculator({ ...pricing, quantity })
            const totalCost = total + productPrice
            return totalCost
        },
        0
    )
    return round(productTotalCost, 2)
}

const getPriceIncreasePercentage = (product) =>
    reduce(
        product.productRatePlanCharges,
        (totalPriceIncreasePercentage, productRatePlanChargesItem) =>
            totalPriceIncreasePercentage + productRatePlanChargesItem.priceIncreasePercentage,
        0
    )

export const calculatePriceWithIncreasedPercentage = (products, price) => {
    const priceIncreasePercentage = getPriceIncreasePercentage(products)
    const totalCurrentPriceWithIncreasedPercentage = getPercentageOfNumber(priceIncreasePercentage, price) + price
    return round(totalCurrentPriceWithIncreasedPercentage, 2)
}

const isProductExpired = ({ effectiveEndDate }) => isPast(parseISO(effectiveEndDate))

const hasProductWithRatePlanCharges = ({ productRatePlanCharges }) => size(productRatePlanCharges) > 0

const getDefaultProductRatePlanCharges = (ratePlans) =>
    get('productRatePlanCharges')(find(ratePlans, (ratePlan) => ratePlan.isDefault))

const getOverrideQuantity = (product) => get('overrideQuantity', product) || get('quantity', product)

const getOverridePrice = (product) =>
    round(get('overridePrice', product) || get('currentPrice', product) || get('listPrice', product), 2)

const getLastRenewalType = (product) => get('renewalType', product) || get('defaultRenewalType', product)

const getOverrideOneTimeChargeRenewalType = (product) => {
    if (isOneTimeChargedProduct(getRatePlanCharges(product))) {
        const lastRenewalType = getLastRenewalType(product)
        if (lastRenewalType === AddToSubscription.value) {
            return AddToSubscription.value
        }
        return None.value
    }
}

const getOverrideRenewalType = (product) => {
    const renewalType = getLastRenewalType(product)
    return getOverrideOneTimeChargeRenewalType(product) || renewalType
}

const getFormattedDateWithOutTimeZone = (date) =>
    new Date(trim(replace(replace(date, new RegExp('-+', 'g'), '/'), new RegExp('T|:\\d\\dZ', 'g'), ' ')))

const validatePrice = (price) => (parseInt(price) > 0 ? price : undefined)

const getListPriceCalculatedProduct = (productItem) => {
    const defaultPrice = calculatePriceByZuoraCalculator(productItem, productItem.overrideQuantity)
    const updatedCurrentPrice =
        calculatePriceWithIncreasedPercentage(productItem, productItem.overridePrice) ||
        validatePrice(calculatePriceWithIncreasedPercentage(productItem, productItem.currentPrice)) ||
        defaultPrice
    const updatedOverridePrice =
        productItem.overrideRenewalType === Included.value ||
        productItem.overrideRenewalType === AddToSubscription.value ||
        productItem.overrideRenewalType === RemoveFromSubscription.value
            ? updatedCurrentPrice
            : ''

    return {
        ...productItem,
        defaultPrice,
        lastOverridePrice: updatedOverridePrice,
        overridePrice: updatedOverridePrice,
    }
}

export const getLineItemAmendment = (lineItemAmendments, productId) =>
    find(lineItemAmendments, (p) => p.productId === productId) || {}

export const getLineItem = (lineItems, productId) => {
    const lineItem = find(lineItems, (item) => item.productId === productId)
    if (lineItem) {
        return {
            ...lineItem,
            quantity: lineItem.quantity || DEFAULT_PRODUCT_QUANTITY,
        }
    }
    return
}

export const validateDeprecatedProducts = (product) => {
    const isIncluded = product.defaultRenewalType === Included.value
    if (isProductExpired(product)) {
        if (isIncluded) {
            return {
                ...product,
                isActive: !isIncluded,
            }
        }
        return
    }
    return product
}

const getRatePlanCharges = pipe([get('ratePlans'), getDefaultProductRatePlanCharges])
const getOneTimeChargedProduct = (product) => isOneTimeChargedProduct(getRatePlanCharges(product))

const shouldDisableOverride = (product) => {
    const isIncluded = getOverrideRenewalType(product) === Included.value
    const ratePlanCharges = getRatePlanCharges(product)
    return isIncluded && isOneTimeChargedProduct(ratePlanCharges)
}

const getRenewalsTerms = (products) => find(terms, (x) => includes(x.productIds, products.productId))

const PRODUCT_ATTRIBUTES = {
    id: get('id'),
    productId: get('productId'),
    productName: get('name'),
    unitType: get('unitType'),
    isActive: getOr(true, 'isActive'),
    category: get('category.name'),
    productRatePlanCharges: getRatePlanCharges,
    modifiedDateTime: get('modifiedDateTime'),
    currentPrice: get('currentPrice'),
    defaultPrice: get('listPrice'),
    lastOverridePrice: getOverridePrice,
    overridePrice: getOverridePrice,
    defaultQuantity: get('quantity'),
    lastOverrideQuantity: getOverrideQuantity,
    overrideQuantity: getOverrideQuantity,
    defaultRenewalType: get('defaultRenewalType'),
    isPriceLocked: get('isPriceLocked'),
    lastRenewalType: getLastRenewalType,
    overrideRenewalType: getOverrideRenewalType,
    disableOverride: shouldDisableOverride,
    renewalsTerms: getRenewalsTerms,

    isOneTimeChargedProduct: getOneTimeChargedProduct,
}

export const buildProduct = (productItem) => generateObject(PRODUCT_ATTRIBUTES, productItem)

const ensureValidateOpportunityId = ({ id, opportunities }) => validateOpportunityId(id, opportunities)

const validateAmendmentPurchaseOrderNumber = (purchaseOrderNumber) =>
    validatePurchaseOrderNumber(purchaseOrderNumber, !!purchaseOrderNumber)

const ensureValidateExpirationDate = (date) => validateExpirationDate(getFormattedDateWithOutTimeZone(date))

const AMENDMENT_CONFIGURATION_ATTRIBUTES = {
    organizationId: get('organizationId'),
    subscriptionId: get('subscriptionId'),
    expirationDate: pipe([get('expirationDate'), getFormattedDateWithOutTimeZone]),
    netCreditTerm: get('netCreditTerm'),
    disableAutoRenew: get('disableAutoRenew'),
    salesforceOpportunityId: get('salesforceOpportunityId'),
    purchaseOrderNumber: getOr('', 'purchaseOrderNumber'),
    timeZone: getOr(DEFAULT_TIME_ZONE, 'timeZone'),
    products: get('products'),
    overrideSalesforceOpportunityId: get('salesforceOpportunityId'),
    hasValidSalesforceOpportunityId: pipe([
        generateObject({
            id: get('salesforceOpportunityId'),
            opportunities: get('opportunities'),
        }),
        ensureValidateOpportunityId,
    ]),
    salesforceOrderOwner: get('salesforceOrderOwner'),
    overridePurchaseOrderNumber: getOr('', 'purchaseOrderNumber'),
    isPurchaseOrderNumberVisible: getOr('', 'purchaseOrderNumber') ?? !!get('purchaseOrderNumber'),
    purchaseOrderValidationError: pipe([getOr('', 'purchaseOrderNumber'), validateAmendmentPurchaseOrderNumber]),
    overrideExpirationDate: pipe([get('expirationDate'), getFormattedDateWithOutTimeZone]),
    expirationDateValidationError: pipe([get('expirationDate'), ensureValidateExpirationDate]),
    overrideDisableAutoRenew: get('disableAutoRenew'),
    overrideNetCreditTerm: get('netCreditTerm'),
    defaultDisableAutoRenew: pipe([get('subscription.autoRenew'), (x) => !x]),
    amendmentOrderId: get('id'),
    modifiedDateTime: get('modifiedDateTime'),
    isPublished: pipe([get('status'), isPublished]),
    status: get('status'),
}

const buildAmendmentConfiguration = (amendmentConfigurationResponse, subscription, opportunities) => {
    const { amendmentOrder } = amendmentConfigurationResponse

    const products = compact(
        map(amendmentConfigurationResponse.products, (productItem) => {
            const productId = replace(productItem.id, /-/g, '')

            const lineItem = getLineItem(amendmentOrder.lineItems, productId)

            if (!lineItem) {
                return
            }

            const product = {
                ...productItem,
                productId,
                ...lineItem,
                ...getLineItemAmendment(amendmentOrder.lineItemAmendments, productId),
            }

            const renewalTypeOfProduct = getOverrideRenewalType(product)
            if (renewalTypeOfProduct === Included.value || renewalTypeOfProduct === AddToSubscription.value) {
                const subscriptionProduct = find(subscription.products, (item) => item.productId === productId)
                if (!!subscriptionProduct) {
                    forEach(product.ratePlans, (productRatePlan) => {
                        if (
                            replace(productRatePlan.id, /-/g, '') === subscriptionProduct.productRatePlanId &&
                            !!productRatePlan.productRatePlanCharges
                        ) {
                            forEach(productRatePlan.productRatePlanCharges, (productRatePlanCharge) => {
                                const isRatePlanActive = (dateValue) =>
                                    new Date(new Date(dateValue).setHours(0, 0, 0, 0)).getTime() >
                                    new Date(new Date().setHours(0, 0, 0, 0)).getTime()
                                const ratePlan = find(subscriptionProduct.ratePlanCharges, (item) =>
                                    isRatePlanActive(item.effectiveEndDate)
                                )

                                if (!!productRatePlanCharge.isDefault && !!ratePlan?.tiers) {
                                    productRatePlanCharge.pricing = [
                                        {
                                            ...head(productRatePlan.pricing),
                                            tiers: ratePlan.tiers,
                                        },
                                    ]
                                    const subscriptionPriceIncrease = parseInt(ratePlan.priceIncreasePercentage)
                                    if (subscriptionPriceIncrease >= 0) {
                                        productRatePlanCharge.priceIncreasePercentage = subscriptionPriceIncrease
                                    }
                                }
                            })
                        }
                    })
                }
            }

            const validatedProduct = validateDeprecatedProducts(product)

            if (!validatedProduct) {
                return
            }

            const mappedProduct = buildProduct(validatedProduct)

            if (!hasProductWithRatePlanCharges(mappedProduct)) {
                return
            }

            return getListPriceCalculatedProduct(mappedProduct)
        })
    )

    return generateObject(AMENDMENT_CONFIGURATION_ATTRIBUTES, {
        ...amendmentOrder,
        subscription,
        products,
        opportunities,
    })
}

export default buildAmendmentConfiguration
