import styled from 'styled-components'
import type { Sections } from './awardAnnouncement.types'

const StyledDiv = styled.div<{ bgColor?: string; textColor?: string; isPdf?: boolean }>`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    min-height: ${(props) => props.isPdf && '1370px'};
`

const sectionClassName = 'px-4 lg:px-8 md:px-5 pt-12 pb-12 md:py-12 text-white lg:px-48'
const pdfSectionClassName = 'px-4 md:px-8 sm:px-5 pt-8 pb-8 sm:py-8 text-white lg:px-48'

export const Section = ({ bgColor, textColor, children, isPdf }: Sections) => (
    <StyledDiv
        className={isPdf ? pdfSectionClassName : sectionClassName}
        bgColor={bgColor}
        textColor={textColor}
        isPdf={isPdf}>
        {children}
    </StyledDiv>
)
