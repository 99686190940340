import { createContext, useContext, useState } from 'react'

const DemographicSectionContext = createContext()

function useDemographicSectionContext() {
    const context = useContext(DemographicSectionContext)

    if (!context) {
        throw new Error('useDemographicSectionContext must be used within a DemographicSectionProvider')
    }

    return context
}

function DemographicSectionProvider({ children }) {
    const [demographicQuestionToEdit, setDemographicQuestionToEdit] = useState({})

    const valueToShare = {
        demographicQuestionToEdit,
        setDemographicQuestionToEdit,
    }

    return <DemographicSectionContext.Provider value={valueToShare}>{children}</DemographicSectionContext.Provider>
}

export { useDemographicSectionContext, DemographicSectionProvider }
