import { LegacyButton as Button, Loading } from '@energage/components'
import { KeyboardArrowRight } from '@energage/icons'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import TemplateImage from 'containers/Main/Survey/Templates/TemplateImage'
import { media } from 'style/breakpoints'
import Container from './Container'

const ActionsContainer = styled.div.attrs((props) => ({
    className: `flex justify-center items-end ${
        props.keepMobileView ? 'h-full mt-5 mb-7' : 'sm:items-center h-full mt-5 mb-7 sm:my-0'
    }`,
}))`
    background-color: rgba(255, 255, 255, 0.8);
`

const Spinner = styled(Loading)`
    padding: 0;
    .loading__title {
        display: none;
    }

    .loading-spinner {
        width: 2rem;
        height: 2rem;
    }

    ${media.smMin`
        .loading-spinner {
            width: 3rem;
            height: 3rem;
        }
    `};
`

const TruncationWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

const Actions = ({
    onSetup,
    linkTo,
    keepMobileView,
    hasConfidentialModal,
    handleModalOpen,
    title,
    surveyGenerationId,
    surveyTypeId,
}) => {
    return (
        <div className={keepMobileView ? 'flex flex-row-reverse' : 'flex flex-row-reverse sm:flex-col'}>
            {hasConfidentialModal ? (
                <Button
                    onClick={() => handleModalOpen(surveyTypeId, surveyGenerationId, title)}
                    size="sm"
                    className="w-24">
                    {'Set Up'}
                </Button>
            ) : (
                <Button onClick={onSetup} size="sm" className="w-24">
                    {'Set Up'}
                </Button>
            )}
            <Button
                as={Link}
                to={linkTo}
                size="sm"
                className={keepMobileView ? 'text-center w-24 mr-2' : 'text-center w-24 mr-2 sm:mr-0 sm:mt-2'}
                outline>
                {'Preview'}
            </Button>
        </div>
    )
}

const LockMessage = () => {
    return (
        <div className="text-blue500 paragraph-small font-bold">
            <span>{'Upgrade to Unlock'}</span>
            <KeyboardArrowRight css="margin-top: -2px;" />
        </div>
    )
}

export function SurveyTemplateCardController({
    onSetup,
    locked,
    linkTo,
    working,
    keepMobileView,
    surveyTypeId,
    surveyGenerationId,
    title,
    handleModalOpen,
    hasConfidentialModal,
    ...delegatedProps
}) {
    const actions = locked ? (
        <LockMessage />
    ) : (
        <Actions
            onSetup={onSetup}
            linkTo={linkTo}
            keepMobileView={keepMobileView}
            hasConfidentialModal={hasConfidentialModal}
            handleModalOpen={handleModalOpen}
            title={title}
            surveyGenerationId={surveyGenerationId}
            surveyTypeId={surveyTypeId}
        />
    )

    return (
        <SurveyTemplateCard
            onSetup={onSetup}
            working={working}
            actions={actions}
            keepMobileView={keepMobileView}
            title={title}
            {...delegatedProps}
        />
    )
}

export const SurveyTemplateCard = ({ working, title, description, className, image, actions, keepMobileView }) => {
    return (
        <Container
            enableInteraction={Boolean(actions)}
            className={cx(className, { loading: working })}
            keepMobileView={keepMobileView}>
            <TemplateImage src={image} keepMobileView={keepMobileView}>
                {!keepMobileView && (
                    <ActionsContainer className="actions-container">
                        {working ? <Spinner /> : actions ? actions : null}
                    </ActionsContainer>
                )}
            </TemplateImage>
            <div
                className={
                    keepMobileView
                        ? 'flex flex-col items-center justify-center text-center px-5 mt-4'
                        : 'flex flex-col items-center sm:items-start justify-center text-center sm:text-left px-5 mt-4 sm:mt-0'
                }>
                <p className="font-bold mb-1">{title}</p>
                <TruncationWrapper>
                    <p className="paragraph-small text-grey500">{description}</p>
                </TruncationWrapper>
            </div>
            <ActionsContainer className={cx({ 'sm:hidden': !keepMobileView })} keepMobileView={keepMobileView}>
                {working ? <Spinner /> : actions ? actions : null}
            </ActionsContainer>
        </Container>
    )
}
