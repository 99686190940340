import { Group } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const NotEnoughRecipients = ({ surveyEventId, title, message, showManageRecipients }) => {
    const tileUrl = routes.survey.setup.recipients({ surveyEventId })

    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="justify-center mt-2">
                <Group fill={colors.red600} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="text-red600 mb-2" ariaText={`Required: ${title}`}>
                        {title}
                    </CardTitle>
                    <p>{message}</p>
                </CardContent>
                {showManageRecipients && (
                    <SummaryButton tileUrl={tileUrl} buttonText={'Manage Recipients'} className={'w-fit'} />
                )}
            </CardBody>
        </SummaryCard>
    )
}
