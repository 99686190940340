import { useEffect } from 'react'
import { butterBar, LegacyButton as Button } from '@energage/components'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import filter from 'lodash/filter'
import get from 'lodash/get'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import { getButterBarForTaskWorkflow } from 'components/TaskWorkflow'
import config from 'config'
import { routes } from 'constants/routes'
import { useVisibility } from 'hooks'
import { TASK_WORKFLOW_NOTIFICATION } from './useUpdateWorkflowNotifications'

const DATE_FORMAT = 'dd MMMM yyyy'

export const OrderExpiring = ({ history, message }) => {
    const accountExecutive = get(message, 'object.accountExecutive')
    const expireDate = get(message, 'object.expireDate')
    useEffect(() => {
        if (!accountExecutive || !expireDate) {
            return null
        }

        const go = () => {
            butterBar.closeAll()
            history.push(config.urls.peakCommerce)
        }

        const message = (
            <div>
                <p>
                    {`If you don't place your order by ${format(
                        new Date(expireDate),
                        DATE_FORMAT
                    )}, the order you discussed with ${accountExecutive.name} will expire.`}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Manage Your Order'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.danger(message)
    })

    return null
}

export const OrderExpired = ({ message }) => {
    const accountExecutive = get(message, 'object.accountExecutive')
    useEffect(() => {
        if (!accountExecutive) {
            return null
        }

        const message = (
            <div>
                <p>
                    {`The order you discussed with ${accountExecutive.name} has expired. Please call ${accountExecutive.phone} to place your order`}
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.danger(message, {
            dismissible: true,
        })
    })

    return null
}

export const OrderPublished = ({ history, message }) => {
    const expireDate = get(message, 'object.expirationDate')
    useEffect(() => {
        if (!expireDate) {
            return
        }
        const go = () => {
            butterBar.closeAll()
            history.push(config.urls.peakCommerce)
        }
        const message = (
            <div>
                <p>
                    {`Your order page is ready. It will expire on ${format(new Date(expireDate), DATE_FORMAT)}. `}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Click here to place your order'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.notify(message, {
            dismissible: true,
        })
    })

    return null
}

export const Subscription30DayLapse = ({ history, message }) => {
    const { visible, hide } = useVisibility(true)

    const expireDate = get(message, 'object.expirationDate')
    if (!expireDate) {
        return null
    }

    const title = 'Your subscription is about to expire'
    const go = () => history.push(config.urls.peakCommerce)

    const cancel = () => {
        hide()
        const message = (
            <div>
                <p>
                    {`Your subscription will expire in ${differenceInDays(new Date(), new Date(expireDate))} days. `}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Manage Your Order'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.danger(message)
    }

    return (
        <Modal isOpen={visible} title={title} onClose={cancel} className="lg:w-2/5 md:w-2/3 sm:w-4/5 w-5/6">
            <Inset>
                <p className="p-4">
                    {`If you don't turn on renewal by ${format(
                        new Date(expireDate),
                        DATE_FORMAT
                    )}, your subscription and product access will expire.`}
                </p>
            </Inset>
            <Inset className="border-t clearfix">
                <div className="p-4 flex justify-center flex-col-reverse sm:flex-row sm:justify-end">
                    <Button variant="link" onClick={cancel}>
                        {'Later'}
                    </Button>
                    <Button variant="primary" onClick={go}>
                        {'Manage My Orders'}
                    </Button>
                </div>
            </Inset>
        </Modal>
    )
}

export const Subscription60DayLapse = ({ history }) => {
    useEffect(() => {
        const go = () => {
            butterBar.closeAll()
            history.push(config.urls.peakCommerce)
        }
        const message = (
            <div>
                <p>
                    {'Your subscription is about to expire. '}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Manage My Orders'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.warning(message, {
            dismissible: true,
        })
    })

    return null
}

export const TaskWorkflow = ({ history, message, notifications, identity }) => {
    useEffect(() => {
        const notification = get(message, 'object', {})
        const workflowNotifications = filter(
            notifications,
            ({ objectType, object }) =>
                objectType === TASK_WORKFLOW_NOTIFICATION && object?.surveyEventId === notification.surveyEventId
        )

        const showButterBar = getButterBarForTaskWorkflow(notification, history, workflowNotifications, identity)

        if (!showButterBar) {
            return
        }

        butterBar.closeAll()
        showButterBar()
    })

    return null
}

export const ProductDelivered = ({ history, message }) => {
    const surveyName = get(message, 'object.surveyName')
    const productName = get(message, 'object.productName')

    useEffect(() => {
        if (!surveyName && !productName) {
            return null
        }

        const go = () => {
            butterBar.closeAll()
            history.push(config.urls.peakCommerce)
        }
        const message = (
            <div>
                <p>
                    {`${surveyName} results are available to view in the ${productName} product`}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Manage Your Order'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.notify(message, {
            dismissible: true,
        })
    })

    return null
}

export const AwardNominationInApp = ({ history, message }) => {
    const programName = get(message, 'object.programName')

    useEffect(() => {
        if (!programName) {
            return null
        }

        const go = () => {
            butterBar.closeAll()
            history.push(routes.employerRecognition.award())
        }
        const message = (
            <div>
                <p>
                    {`Congratulations, you've been entered for the`} <b>{programName}</b>
                    {` award. `}
                    <Button className="inline ml-4" onClick={go} variant="secondary" outline size="sm">
                        {'Manage entries here >>'}
                    </Button>
                </p>
            </div>
        )
        butterBar.closeAll()
        butterBar.notify(message, {
            dismissible: true,
        })
    })

    return null
}
