import CompletedIcon from 'components/CompletedIcon'

const SectionTitle = ({ title, isStepComplete }) => (
    <div className="h5">
        <div className="text-green500 h-6 w-6 inline-flex justify-center items-center mr-1">
            <CompletedIcon visible={isStepComplete} />
        </div>
        {title}
    </div>
)

export default SectionTitle
