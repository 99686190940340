import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'

export const targets = [
    { id: 3, label: 'This company' },
    { id: 1, label: 'Pulse companies' },
    { id: 2, label: 'All companies' },
]

export const defaultTarget = targets[1].id

export const categories = [
    { label: 'Workplace', value: 1 },
    { label: 'Workplace Experience Themes and Statements', value: 9 },
    { label: 'Other Culture Topics', value: 10 },
    { label: 'Do It Yourself!', value: 4 },
]

export const categoryDictionary = fromPairs(map(categories, (c) => [c.label.replace(' ', ''), c.value]))

export const defaultCategory = categories[1]
