import { useEffect, useState } from 'react'
import type { ChangeEvent, Ref } from 'react'
import { Button, InputGroup, Loading, Textarea, UnderlineSelect } from '@energage/components'
import { FileCopy, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import find from 'lodash/find'
import head from 'lodash/head'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import { useIdentity } from 'components/Identity'
import type {
    AiContentProps,
    DataProps,
    FilterDataProps,
    LemmatizationList,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureCloudConfiguration/CultureCloud.types'
import { useClipboard } from 'hooks'
import { errorMessage, MINIMUM_DROPLETS_REQUIRED, ToneOption } from './utils'

const Section = styled.div<{ isError?: boolean }>`
    .input-group {
        &.input-group--md {
            font-size: 1rem;

            & > textarea {
                padding-right: 35px;
                font-size: 0.87rem;
                line-height: 20px;
                border-color: ${colors.grey300};
                ${(props) => props.isError && `border: 1px solid ${colors.red500}; font-size: 1rem; font-weight: 500;`}
            }
        }
    }

    .selectDropdown {
        width: 310px;
    }
`

type Payload = {
    LemmatizationWords: LemmatizationList | undefined
    Tone: string
    ContentType: string
    CompanyId: number
    CompanyTypeId: string
    CompanyName: string
    UserId: string
    userRole: string
    processTypeId: string | null | undefined
}

type Options = {
    id: string
    name: string
}

interface CloudWordProps {
    dataId: string
    data: DataProps
    onChange: (aiContent: DataProps) => void
    isLoading: boolean
    isDisabled: boolean
    filterData: FilterDataProps
}

const getGeneratedData = (surveyCompanyId: number, dataType: string, topThreeSelectedWords?: string[]) => {
    const companyId = parseInt(localStorage.getItem('companyId') ?? '0')
    const localStorageWords = localStorage.getItem('selectedWords') ?? ''

    if (companyId === surveyCompanyId && JSON.stringify(topThreeSelectedWords) === localStorageWords) {
        return localStorage.getItem(dataType)
    }
}

export const AiContentGenerator = ({ data, onChange, isLoading, isDisabled, filterData }: CloudWordProps) => {
    const { surveyCompanyId, organizationName } = useIdentity()
    const [textAriaRef, copyToClipboard] = useClipboard()
    const [isClickToCopy, setClickToCopy] = useState(false)
    const [selectedWords, setSelectedWords] = useState<string[]>()
    const [aiMessage, setAiMessage] = useState<string>()
    const [selectedTone, setSelectedTone] = useState<string>('Fun')
    const [processTypeId, setProcessTypeId] = useState<string | null>()
    const [isError, setIsError] = useState(false)
    const minDropletsRequired = data?.droplets && data?.droplets?.length >= MINIMUM_DROPLETS_REQUIRED

    const { handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            communicationTone: head(ToneOption),
            generatedMessage: '',
        },
    })

    useEffect(() => {
        const topThreeSelectedWords = data?.droplets?.slice(0, 3).map((value) => value.text)
        setSelectedWords(topThreeSelectedWords)
        const localStorageTone = getGeneratedData(surveyCompanyId, 'selectedTone', topThreeSelectedWords)
        const localStorageMessage = getGeneratedData(surveyCompanyId, 'generatedMessage', topThreeSelectedWords)

        if (localStorageMessage && localStorageTone) {
            setSelectedTone(localStorageTone ?? 'Fun')
            setAiMessage(localStorageMessage ?? '')
        } else {
            setAiMessage('')
            setSelectedTone('Fun')
        }
        // eslint-disable-next-line
    }, [filterData, data?.droplets])

    useEffect(() => {
        onChange({ ...data, aiContent: aiMessage })
        // eslint-disable-next-line
    }, [aiMessage, filterData, data?.droplets])

    const clickToCopy = () => {
        copyToClipboard()
        setClickToCopy(true)
        setTimeout(() => setClickToCopy(false), 2000)
    }

    const handleToneChange = (communicationTone: Options) => {
        setSelectedTone(communicationTone.id)
        setValue('communicationTone', communicationTone)
        const localStorageTone = getGeneratedData(surveyCompanyId, 'selectedTone', selectedWords)
        const localStorageMessage = getGeneratedData(surveyCompanyId, 'generatedMessage', selectedWords)
        const localStorageProcessTypeId = getGeneratedData(surveyCompanyId, 'processTypeId', selectedWords)

        if (communicationTone.id === localStorageTone) {
            setAiMessage(localStorageMessage ?? '')
            setProcessTypeId(localStorageProcessTypeId ?? null)
        } else {
            setAiMessage('')
            setProcessTypeId(null)
            setIsError(false)
        }
    }

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const currentText = event?.target?.value
        setAiMessage(currentText)
        setValue('generatedMessage', currentText)
        localStorage.setItem('selectedTone', selectedTone)
        localStorage.setItem('generatedMessage', currentText)
        localStorage.setItem('companyId', surveyCompanyId)
    }

    const { mutate: doPost, isLoading: isFetching } = useBrandingQuery.mutate<AiContentProps, unknown, Payload>(
        `surveycompanies/${surveyCompanyId}/CultureFacts/GetAiContent`,
        {
            onSuccess: (data: AiContentProps) => {
                if (data.status === 1) {
                    setIsError(false)
                    localStorage.setItem('generatedMessage', data?.response)
                } else {
                    setIsError(true)
                    localStorage.setItem('generatedMessage', '')
                }
                setAiMessage(data?.response)
                setProcessTypeId(data?.processTypeId)
                localStorage.setItem('selectedTone', selectedTone)
                localStorage.setItem('processTypeId', data?.processTypeId ?? '')
                localStorage.setItem('companyId', surveyCompanyId)
                localStorage.setItem('selectedWords', JSON.stringify(selectedWords))
            },
            onError: () => {
                setIsError(true)
            },
        }
    )

    const handleGenerate = () => {
        const payLoad = {
            LemmatizationWords: data?.lemmatizationList,
            Tone: selectedTone,
            ContentType: '2',
            CompanyId: surveyCompanyId,
            CompanyTypeId: '1',
            CompanyName: organizationName,
            UserId: '1',
            userRole: '1',
            processTypeId: processTypeId,
        }
        doPost(payLoad)
    }

    return (
        <form onSubmit={handleSubmit(handleGenerate)} noValidate>
            <Section>
                <InputGroup
                    as={UnderlineSelect}
                    className="flex-1 text-base mt-1 sm:inline-block selectDropdown"
                    name="communicationTone"
                    id="communicationTone"
                    label="Select communication tone"
                    displayArrow={true}
                    primaryColor={colors.purple700}
                    secondaryColor={colors.purple100}
                    options={ToneOption}
                    getOptionLabel={(opt: Options) => opt.name}
                    getOptionValue={(opt: Options) => opt.id}
                    value={find(ToneOption, (x) => x.id === selectedTone)}
                    onChange={handleToneChange}
                    isDisabled={isDisabled || isFetching}
                />
                <Button
                    className="ml-4"
                    appearance="secondary"
                    type="submit"
                    isDisabled={isLoading || isDisabled || isFetching}>
                    {aiMessage && !isError ? 'Regenerate' : 'Generate'}
                </Button>
            </Section>
            <Section className="relative" isError={isError}>
                {isFetching ? (
                    <Loading text={'Loading...'} />
                ) : (
                    <>
                        <InputGroup
                            as={Textarea}
                            className="flex-1 text-base mt-4"
                            name="generatedMessage"
                            id="generatedMessage"
                            label="Generated message"
                            rows={10}
                            placeholder="Click 'Generate' for the AI generator to create content for a post about your culture."
                            value={isError ? errorMessage : minDropletsRequired ? aiMessage : ''}
                            ref={textAriaRef as Ref<HTMLTextAreaElement>}
                            onChange={handleTextChange}
                            readOnly={isError || isDisabled}
                        />
                        <Button
                            className={cx(
                                'absolute right-0 bottom-0',
                                (isClickToCopy || !aiMessage) && 'cursor-default'
                            )}
                            appearance="tertiary"
                            aria-label="Copy AI Generated Text"
                            onPress={clickToCopy}
                            isDisabled={isLoading || isDisabled || isFetching}>
                            <Icon
                                as={FileCopy}
                                className="mr-1"
                                color={isClickToCopy || !aiMessage ? colors.grey300 : colors.grey700}
                            />
                        </Button>
                    </>
                )}
            </Section>
        </form>
    )
}
