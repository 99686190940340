import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from 'components/Authentication'
import { useIdentity } from 'components/Identity'
import { addNotification } from 'store'
import connection from 'util/signalRConnection'
import events from './events'
import useNotification from './useNotification'

const historyNotification = (message) => {
    const event = events[message.objectType]
    if (!event) {
        return
    }

    addNotification(message)
}

const hubs = {
    NotificationHistory: 'NotificationHistory',
    NewNotification: 'NewNotification',
}

const connectionStates = {
    Connected: 'Connected',
    Disconnected: 'Disconnected',
}

const useNotificationHub = () => {
    const history = useHistory()
    const identity = useIdentity()
    const { accessToken } = useAuthentication()
    const { notifications } = useNotification()

    const newNotification = useCallback(
        (message) => {
            const event = events[message.objectType]
            if (!event) {
                return
            }

            addNotification(message)
            if (event.handler) {
                event.handler({ message, history, notifications, identity })
            }
        },
        [history, notifications, identity]
    )

    useEffect(() => {
        const start = async () => {
            if (!connection || !accessToken) {
                return
            }

            connection.on(hubs.NotificationHistory, historyNotification)
            connection.on(hubs.NewNotification, newNotification)

            if (connection.connectionState === connectionStates.Disconnected) {
                await connection.start()
            }

            return () => {
                connection.off(hubs.NotificationHistory, historyNotification)
                connection.off(hubs.NewNotification, newNotification)
            }
        }

        start()
    }, [newNotification, accessToken])

    return connection?.connectionState === connectionStates.Connected
}

export default useNotificationHub
