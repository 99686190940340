import { useEffect, useReducer, useState } from 'react'
import { Checkbox, RadioButton, RadioGroup } from '@energage/components'
import { ExpandLess, ExpandMore, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import remove from 'lodash/remove'
import styled from 'styled-components'
import { createAction } from 'util/actionCreator'

const RadioButtonRow = styled.div.attrs({ className: 'flex flex-row flex-wrap text-xs px-4 py-3 border-t-2' })`
    background-color: ${colors.white};
    :nth-child(even) {
        background-color: ${colors.grey100};
    }
`

const ExpandIcon = ({ onClick, isExpanded }) => (
    <span className="cursor-pointer select-none" onClick={onClick}>
        <Icon as={isExpanded ? ExpandLess : ExpandMore} size={16} color={colors.grey600} className="ml-1" />
    </span>
)

function reducer(state, action) {
    const id = action.payload
    switch (action.type) {
        case 'toggle':
            if (includes(state, id)) {
                return filter(state, (d) => d !== id)
            }
            return [...state, id]
        case 'expand':
            if (!includes(state, id)) {
                return [...state, id]
            }
            return state
        default:
            return state
    }
}

const toggleOptions = createAction('toggle')
const expandOptions = createAction('expand')

export const RadioGroupWithCheckboxes = ({
    name,
    typesWithOptions,
    defaultId,
    selectedOptionIds,
    setSelectedOptionIds,
}) => {
    const [selectedId, setSelectedId] = useState(defaultId)
    const [expandedTypes, dispatch] = useReducer(reducer, [selectedId])

    useEffect(() => {
        const selectedTagId = find(typesWithOptions, { options: [{ id: selectedOptionIds[0] }] })?.id ?? defaultId
        setSelectedId(selectedTagId)
        dispatch(expandOptions(selectedTagId))
    }, [typesWithOptions, selectedOptionIds, defaultId])

    const handleTypeChange = (event) => {
        const id = event.target.value

        if (selectedId !== id) {
            const options = find(typesWithOptions, (t) => t.id === id)?.options
            const optionIds = map(options, (d) => d.id)
            setSelectedOptionIds(optionIds)
        }
    }

    const handleOptionChange = (id, optionId, event) => {
        const optionIds = id !== selectedId ? [] : selectedOptionIds.slice()
        if (event.target.checked) {
            optionIds.push(optionId)
        } else {
            remove(optionIds, (d) => d === optionId)
        }
        setSelectedOptionIds(optionIds)
    }

    return (
        <div className="mb-4 flex flex-col border-2 border-t-0 mt-3">
            <RadioGroup name={name} onChange={handleTypeChange} selectedValue={selectedId}>
                {map(typesWithOptions, (typeWithOptions) => (
                    <RadioButtonRow key={typeWithOptions.id}>
                        <RadioButton
                            key={typeWithOptions.id}
                            className="text-xs"
                            value={typeWithOptions.id}
                            label={typeWithOptions.name}
                        />
                        {typeWithOptions.options?.length > 0 && (
                            <ExpandIcon
                                onClick={() => {
                                    dispatch(toggleOptions(typeWithOptions.id))
                                }}
                                isExpanded={includes(expandedTypes, typeWithOptions.id)}
                            />
                        )}
                        <div className="flex flex-col ml-6 w-full">
                            {includes(expandedTypes, typeWithOptions.id) &&
                                map(typeWithOptions.options, (option) => (
                                    <Checkbox
                                        inline
                                        label={option.value}
                                        className="mt-2 text-xs"
                                        key={option.id}
                                        value={option.id}
                                        checked={
                                            selectedId === typeWithOptions.id && includes(selectedOptionIds, option.id)
                                        }
                                        onChange={(event) => {
                                            handleOptionChange(typeWithOptions.id, option.id, event)
                                        }}
                                    />
                                ))}
                        </div>
                    </RadioButtonRow>
                ))}
            </RadioGroup>
        </div>
    )
}
