import PropTypes from 'prop-types'
import size from 'lodash/size'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation'
import { departmentShape } from '../../propTypes'
import { UpdateActiveSurveysCheckbox } from '../../UpdateActiveSurveys'
import useRemoveDepartment from './useRemoveDepartment'

const confirmationMessage = ({ departmentName, departmentMembersCount }) => (
    <>
        <div className="pb-3">{`Are you sure you want to dissolve the ${departmentName} department?`}</div>
        {departmentMembersCount > 0 && (
            <span>{`${departmentMembersCount} direct reports to ${departmentName} will no longer have a department. `}</span>
        )}
        {`All child department and employees will move up in the department tree.`}
    </>
)

const RemoveDepartment = ({ department, onDepartmentRemove }) => {
    const { name: departmentName, members: departmentMembers } = department
    const { remove, isRemoving } = useRemoveDepartment(department, onDepartmentRemove)

    return (
        <ButtonWithConfirmation
            variant="link"
            className="p-0 ml-4"
            additionalButtonBarContent={<UpdateActiveSurveysCheckbox />}
            title="Dissolve Department?"
            eventName={'Dissolve departments'}
            onAccept={remove}
            confirmationMessage={confirmationMessage({
                departmentName,
                departmentMembersCount: size(departmentMembers),
            })}
            rejectLabel="Cancel"
            acceptLabel="Dissolve Department"
            actionVariant="danger">
            {isRemoving ? 'Dissolving...' : 'Dissolve'}
        </ButtonWithConfirmation>
    )
}

RemoveDepartment.propTypes = {
    department: PropTypes.shape(departmentShape).isRequired,
    onDepartmentRemove: PropTypes.func.isRequired,
}

export default RemoveDepartment
