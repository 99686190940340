import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import size from 'lodash/size'
import { useSelector } from 'react-redux'
import taskInformation from 'components/TaskWorkflow/taskInformation'
import { selectNotifications } from 'store'
import useFetchArchivedNotification from './useFetchArchivedNotification'

const useNotification = () => {
    const notifications = useSelector(selectNotifications)
    const archivedNotifications = useFetchArchivedNotification()

    const validNotifications = filter(
        notifications,
        (n) => !n?.object?.workflowTaskName || includes(keys(taskInformation), n?.object?.workflowTaskName)
    )

    const { active, dismissed } = groupBy(validNotifications, (n) => (n.readDate ? 'dismissed' : 'active'))

    return {
        notifications: active ?? [],
        unreadCount: size(active),
        archivedNotifications: {
            ...archivedNotifications,
            notifications: [...(dismissed ?? []), ...archivedNotifications.notifications],
        },
    }
}

export default useNotification
