import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import cx from 'clsx'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { routes } from 'constants/routes'
import type { ThankYouForParticipatingSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { YellowButton } from 'containers/Main/AwardAnnouncement/YellowButton'
import { CE_AWARD_NAME } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { StyledCarousel } from './StyledCarousel'

export const ThankYouForParticipating = ({
    companyName,
    listName,
    lastYearAwards,
    colors,
    baseUrl,
    isPdf,
}: ThankYouForParticipatingSection) => {
    const showAwards = size(lastYearAwards?.national) > 0 || size(lastYearAwards?.regional) > 0
    const mediaKitUrl = isPdf ? `${baseUrl}branding/media-kit` : routes.branding.mediaKit()
    const award = listName === CE_AWARD_NAME ? ' awards' : ''

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor}>
            <h2 className="sm:text-5xl text-2xl font-black">
                {'Thank you for participating in the Top Workplaces program'}
            </h2>
            <p className="sm:text-2xl text-xl py-4">{`Unfortunately, ${companyName} is not a Top Workplaces winner for ${listName}${award}. The results are based on the employee engagement survey you recently issued.`}</p>
            {showAwards && (
                <>
                    <h1 className="sm:text-5xl text-2xl mt-8 font-black">
                        {'Continue to promote the awards you have earned'}
                    </h1>
                    <p className="sm:text-2xl text-xl py-4">
                        {`While this isn't news you have hoped, there`}
                        {` is plenty for ${companyName} to celebrate. Continue to use the awards you have won to showcase what makes your culture so special:`}
                    </p>
                    <div className={cx('mt-4 mb-12 text-center block', { 'sm:hidden': !isPdf })}>
                        <YellowButton className={isPdf ? 'px-10 py-3' : 'px-12'} href={mediaKitUrl}>
                            {'Go to Awards'}
                        </YellowButton>
                    </div>
                    <div className="hidden sm:block">
                        <StyledCarousel
                            slidesToShow={5}
                            //@ts-ignore
                            heightMode="adaptive"
                            autoGenerateStyleTag={false}
                            wrapAround
                            renderCenterLeftControls={({ previousSlide, slideCount }) =>
                                slideCount > 5 && (
                                    <KeyboardArrowLeft className="ml-2 -mt-12" size={30} onClick={previousSlide} />
                                )
                            }
                            renderCenterRightControls={({ nextSlide, slideCount }) =>
                                slideCount > 5 && (
                                    <KeyboardArrowRight className="mr-2 -mt-12" size={30} onClick={nextSlide} />
                                )
                            }>
                            {size(lastYearAwards.national) > 0 &&
                                map(lastYearAwards.national, ({ svgUrl, year }, index: number) => (
                                    <SvgLoader path={svgUrl} className={isPdf ? 'py-6 px-4' : 'py-3 px-4'} key={index}>
                                        <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
                                    </SvgLoader>
                                ))}
                            {size(lastYearAwards.regional) > 0 &&
                                map(lastYearAwards.regional, ({ svgUrl, year }, index: number) => (
                                    <SvgLoader key={index} path={svgUrl} height={'9rem'} className="py-3 px-4">
                                        <SvgProxy selector="text" font-family={`'Yantramanav', sans-serif`} />
                                        <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
                                    </SvgLoader>
                                ))}
                        </StyledCarousel>
                    </div>
                </>
            )}
        </Section>
    )
}
