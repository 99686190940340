import { Component } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { LegacyButton as Button } from '@energage/components'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/strings'
import { ExternalLink } from './Anchor'

const Error = () => (
    <div className="p-20 flex justify-center">
        <div className="p-20 m-10 mt-20 text-center bg-white rounded-lg shadow-lg">
            <h2 className="text-center">{'Something went wrong'}</h2>
            <p className="mt-10">{"Let's back up and get going in the right direction."}</p>
            <div className="mt-3">
                <Button onClick={() => window.location.reload()}>{'Try again'}</Button>
            </div>
            <p className="mt-10">
                {`If that didn't help, please call us at ${SUPPORT_PHONE} `}
                <br />
                <span>{'or email us at '}</span>
                <span>
                    <ExternalLink href={`mailto:${SUPPORT_EMAIL}?subject=Platform Error`}>{SUPPORT_EMAIL}</ExternalLink>
                </span>
                <span>{'.'}</span>
            </p>
        </div>
    </div>
)

class FullPageErrorBoundary extends Component {
    state = {
        hasError: false,
        eventId: null,
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        datadogLogs.logger.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <Error eventId={this.state.eventId} />
        }

        return this.props.children
    }
}

export default FullPageErrorBoundary
