import { useEffect, useMemo } from 'react'
import { Alert, LegacyButton as Button, Tooltip } from '@energage/components'
import { useToggle } from '@energage/hooks'
import { ArrowDropDown, ArrowDropUp, Icon } from '@energage/icons'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity, usePermissions } from 'components/Identity'
import { EnergageTag } from 'constants/tags'
import { RadioGroupWithCheckboxes } from './RadioGroupWithCheckboxes'

export const TargetRecipients = ({ surveyEventId, options, selectedFilterOptionIds, setSelectedFilterOptionIds }) => {
    const { isSuperUser } = useIdentity()
    const { hasMultiRegionTwpSurveys } = usePermissions()
    const [isTypesVisible, toggleTypes] = useToggle(true)
    const optionsWithDefault = useMemo(() => [{ id: '0', name: 'No additional filtering' }, ...options], [options])
    const { data: locations } = useFetchAwardLocations(surveyEventId)
    const lowercaseLocations = map(locations, (x) => x.toLowerCase())
    const locationsTag = find(optionsWithDefault, (t) => isEqual(t.energageTag, EnergageTag.LocationName))
    const existingAwardLocationOptions = filter(locationsTag?.options, (x) =>
        includes(lowercaseLocations, x.value.toLowerCase())
    )
    const hasAnyAwardLocationOptions = (existingAwardLocationOptions?.length ?? 0) > 0

    useEffect(() => {
        if (locationsTag && hasMultiRegionTwpSurveys && !hasAnyAwardLocationOptions) {
            const name = locationsTag.name
            locationsTag.name = (
                <Tooltip
                    className="inline p-0 m-0"
                    placement="right"
                    renderTrigger={({ ref, getTriggerProps }) => (
                        <span ref={ref} {...getTriggerProps()}>
                            {name}
                        </span>
                    )}>
                    <span className="text-xs">{`Recipient locations do not match award locations`}</span>
                </Tooltip>
            )
        }
    }, [locationsTag, hasMultiRegionTwpSurveys, hasAnyAwardLocationOptions])

    const selectAwardLocations = () => {
        setSelectedFilterOptionIds(map(existingAwardLocationOptions, (x) => x.id))
    }

    const selectedTagName = find(options, { options: [{ id: selectedFilterOptionIds[0] }] })?.name
    const nonSuperUserMessage = `Additional recipient filtering has been applied by Energage: only employees that meet specific ${selectedTagName} criteria are included. Please contact support@energage.com if you need changes.`

    if (isSuperUser && options?.length > 0) {
        return (
            <div className="pt-5 max-w-md">
                <span className="text-blue600 font-bold paragraph cursor-pointer select-none" onClick={toggleTypes}>
                    {`Filter recipients (internal)`}
                    <Icon as={isTypesVisible ? ArrowDropUp : ArrowDropDown} size={20} />
                </span>
                {hasAnyAwardLocationOptions && (
                    <Button variant="link" onClick={selectAwardLocations} className="mx-2 p-1 underline">
                        {'Select Award Locations'}
                    </Button>
                )}
                {isTypesVisible && (
                    <RadioGroupWithCheckboxes
                        name="tagAssignment"
                        typesWithOptions={optionsWithDefault}
                        defaultId="0"
                        selectedOptionIds={selectedFilterOptionIds}
                        setSelectedOptionIds={setSelectedFilterOptionIds}
                    />
                )}
            </div>
        )
    }
    if (!isSuperUser && selectedFilterOptionIds?.length > 0) {
        return (
            <div className="pt-5 flex flex-col items-center">
                <Alert variant="info" className="text-center w-auto text-xs px-3 py-2">
                    {nonSuperUserMessage}
                </Alert>
            </div>
        )
    }

    return null
}

const useFetchAwardLocations = (surveyEventId, options = {}) => {
    return useWorkplaceSurveyQuery(
        ['award-locations', surveyEventId],
        `SurveyEvent/${surveyEventId}/locationNames`,
        options
    )
}
