import map from 'lodash/map'
import styled from 'styled-components'
import { routes } from 'constants/routes'
import {
    CE_AWARD_NAME,
    DALLAS_PUBLISHER_ID,
    INDUSTRY_AWARD_NAME,
    PHILADELPHIA_INQUIRER_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { media } from 'style/breakpoints'
import withClassName from 'style/util/withClassName'
import Section from '../../Section'
import YellowButton from '../../YellowButton'
import {
    dallasSubscriberHeaders,
    freeNonSubscriberHeaders,
    listFreeNonSubscriber,
    listFreeSubscriber,
    listPhillyNonSubscriber,
    listPhillySubscriber,
    listPurchaseNonSubscriber,
    listPurchaseSubscriber,
    nationalStandardHeaders,
    nationalStandardListDiy,
    nationalStandardListPro,
    nonSubscriberHeaders,
    subscriberHeaders,
} from './sectionsListArray'

const SectionHeading = withClassName('text-xl sm:text-4xl', 'h1', 'sectionHeading')
const ListSubtitle = withClassName('text-base sm:text-2xl', 'h2', 'subtitle')
const Text = withClassName('text-base sm:text-base', 'p', 'text')
const Subtext = withClassName('text-base sm:text-xl', 'p', 'text')

const BadgeLogo = styled.img`
    ${(props) => props.isProBadgeLogo && 'right: 5px;'}
    ${(props) => (props.isProBadgeLogo ? 'top: 5px;' : 'top : 1px;')}

    ${media.smMin`
        ${(props) => props.isProBadgeLogo && 'right: 5px;'}
        ${(props) => (props.isProBadgeLogo ? 'top: -4px;' : 'top : -8px;')}

`}
`
const PdfBadgeLogo = styled.img`
    ${(props) => props.isProBadgeLogo && 'right: 5px;'}
    ${(props) => (props.isProBadgeLogo ? 'top: 5px;' : 'top : 1px;')}
`
const Wrapper = styled.div.attrs({
    className: 'ml-5',
})`
    overflow-wrap: anywhere;
`

const ListView = (listItems) => (
    <div className="sm:py-4 py-2">
        {map(listItems, ({ imgPath, heading, text }, index) => (
            <div key={index} className="flex items-start items-center">
                <div className="flex-none">
                    <img
                        alt="CE awards logos"
                        src={require(`images/employerBranding/mediaKit/${imgPath}`).default}
                        className="object-cover"
                    />
                </div>
                <Wrapper>
                    <ListSubtitle>{heading}</ListSubtitle>
                    <Subtext>{text}</Subtext>
                </Wrapper>
            </div>
        ))}
    </div>
)

const ListSection = ({
    heading,
    text,
    subText,
    list,
    sectionLogoPath,
    isProBadgeLogo,
    isPdf,
    mediaKitFreeNonSubscriber,
    isMediaKitAnnouncement,
}) => {
    const BadgeLogoComponent = isPdf ? PdfBadgeLogo : BadgeLogo

    return (
        <div className="flex flex-col flex-1 mt-8 sm:mt-2">
            {(mediaKitFreeNonSubscriber || !isMediaKitAnnouncement) && (
                <div className="relative w-full">
                    <BadgeLogoComponent
                        alt="logo"
                        src={require(`images/employerBranding/mediaKit/${sectionLogoPath}`).default}
                        className="absolute right-0 pr-6 w-32 -mt-2 sm:mt-px"
                        isProBadgeLogo={isProBadgeLogo}
                        isPdf={isPdf}
                    />
                </div>
            )}
            <div className="flex-1 bg-white m-2 sm:m-2 px-4 sm:px-8">
                <SectionHeading className="mt-20 sm:mt-24">{heading}</SectionHeading>
                <Text>{text}</Text>
                <Subtext className="sm:mt-8 mt-4">{subText}</Subtext>
                <div className="sm:my-8 my-2">
                    {map(list, (listItems, index) => (
                        <ListView key={index} listItems={listItems} />
                    ))}
                </div>
            </div>
        </div>
    )
}

const PromoteAwardsComponent = styled.div`
    ${(props) => (props.isPdf ? 'display:flex' : 'flex-direction:row')};
    ${media.smMin`
        display: flex
    `}
`

const getPromoteList = (
    hasEmployerBrandingSubscription,
    MediaKitFree,
    isPhiladelphiaPremiumMediaKit,
    isMediaKitAnnouncement,
    isCEPremiumMediaKit,
    isIndustryPremiumMediaKit,
    isTwpUsaPremiumMediaKit,
    isDallasAwardMediaKit,
    publisherId
) => {
    const isPhiladelphiaAwardMediaKit =
        isPhiladelphiaPremiumMediaKit || publisherId === PHILADELPHIA_INQUIRER_PUBLISHER_ID

    if (!isMediaKitAnnouncement) {
        return listFreeNonSubscriber
    }

    switch (true) {
        case MediaKitFree:
        case isDallasAwardMediaKit:
            return hasEmployerBrandingSubscription ? listFreeSubscriber : listFreeNonSubscriber
        case isPhiladelphiaAwardMediaKit:
            return hasEmployerBrandingSubscription || isPhiladelphiaPremiumMediaKit
                ? listPhillySubscriber
                : listPhillyNonSubscriber
        default:
            return hasEmployerBrandingSubscription ||
                isCEPremiumMediaKit ||
                isIndustryPremiumMediaKit ||
                isTwpUsaPremiumMediaKit
                ? listPurchaseSubscriber
                : listPurchaseNonSubscriber
    }
}

const PromoteYourAward = ({
    bgColor,
    textColor,
    isPdf,
    isMediaKitAnnouncement,
    baseUrl,
    sectionClassName,
    isNationalStandardAwardId,
    publisherId,
    awardName,
    hasEmployerBrandingSubscription,
    hasCEPremiumMediaKit,
    hasPremiumMediaKit,
    hasTwpUsaPremiumMediaKit,
    hasIndustryPremiumMediaKit,
    isDallasRankedWinner,
}) => {
    const isCEPremiumMediaKit = awardName === CE_AWARD_NAME && hasCEPremiumMediaKit
    const isTwpUsaPremiumMediaKit = publisherId === TWP_USA_PUBLISHER_ID && hasTwpUsaPremiumMediaKit
    const isPhiladelphiaPremiumMediaKit = publisherId === PHILADELPHIA_INQUIRER_PUBLISHER_ID && hasPremiumMediaKit
    const isIndustryPremiumMediaKit = awardName === INDUSTRY_AWARD_NAME && hasIndustryPremiumMediaKit
    const isDallasAwardMediaKit = publisherId === DALLAS_PUBLISHER_ID
    const isMediaKitFreeAward =
        awardName !== CE_AWARD_NAME &&
        publisherId !== TWP_USA_PUBLISHER_ID &&
        awardName !== INDUSTRY_AWARD_NAME &&
        publisherId !== PHILADELPHIA_INQUIRER_PUBLISHER_ID
    const MediaKitFree =
        !isCEPremiumMediaKit &&
        !isTwpUsaPremiumMediaKit &&
        !isPhiladelphiaPremiumMediaKit &&
        !isIndustryPremiumMediaKit &&
        isMediaKitFreeAward
    const mediaKitFreeNonSubscriber = !hasEmployerBrandingSubscription && MediaKitFree
    const nonSubscriberHeader = mediaKitFreeNonSubscriber ? freeNonSubscriberHeaders : nonSubscriberHeaders
    const mediaKitHeaders =
        hasEmployerBrandingSubscription ||
        isCEPremiumMediaKit ||
        isTwpUsaPremiumMediaKit ||
        isIndustryPremiumMediaKit ||
        isPhiladelphiaPremiumMediaKit
            ? isDallasRankedWinner
                ? dallasSubscriberHeaders
                : subscriberHeaders
            : nonSubscriberHeader
    const mediaKitAnnouncementHeaders = !isMediaKitAnnouncement ? nationalStandardHeaders : mediaKitHeaders

    const { sectionName, headingDiy, headingPro, textDiy, textPro, subTextDiy, subTextPro } = isNationalStandardAwardId
        ? nationalStandardHeaders
        : mediaKitAnnouncementHeaders

    const PromoteList = getPromoteList(
        hasEmployerBrandingSubscription,
        MediaKitFree,
        isPhiladelphiaPremiumMediaKit,
        isMediaKitAnnouncement,
        isCEPremiumMediaKit,
        isIndustryPremiumMediaKit,
        isTwpUsaPremiumMediaKit,
        isDallasAwardMediaKit,
        publisherId
    )

    return (
        <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName} isPdf={isPdf}>
            <h1 className="sm:text-5xl text-3xl font-black text-white">{sectionName}</h1>
            <PromoteAwardsComponent className="sm:mt-24 mt-10" isPdf={isPdf}>
                {(mediaKitFreeNonSubscriber || !isMediaKitAnnouncement) && (
                    <ListSection
                        heading={headingDiy}
                        text={textDiy}
                        subText={subTextDiy}
                        list={isNationalStandardAwardId ? nationalStandardListDiy : PromoteList.listDiy}
                        sectionLogoPath="diy.svg"
                        isPdf={isPdf}
                        mediaKitFreeNonSubscriber={mediaKitFreeNonSubscriber}
                        isMediaKitAnnouncement={isMediaKitAnnouncement}
                    />
                )}
                <ListSection
                    heading={headingPro}
                    text={textPro}
                    subText={subTextPro}
                    list={isNationalStandardAwardId ? nationalStandardListPro : PromoteList.listPro}
                    sectionLogoPath="pro.svg"
                    isProBadgeLogo
                    isPdf={isPdf}
                    mediaKitFreeNonSubscriber={mediaKitFreeNonSubscriber}
                    isMediaKitAnnouncement={isMediaKitAnnouncement}
                />
            </PromoteAwardsComponent>
            <div className="mt-12 text-center">
                <YellowButton href={isPdf ? `${baseUrl}branding` : routes.branding()}>{'Learn More'}</YellowButton>
            </div>
        </Section>
    )
}

export default PromoteYourAward
