import { Fragment } from 'react'
import { AppLink } from 'components/Anchor'
import { routes } from 'constants/routes'

const Message = (props) => {
    return <Fragment>{props.children}</Fragment>
}

const getLaunchReadinessMessageElements = (tasks) => {
    let children = []

    children.push(
        <Message key="please">
            {'Please '}
            {tasks[0]}
        </Message>
    )
    for (let index = 1; index < tasks.length - 1; index++) {
        children.push(
            // tasks[index] is a string
            <Message key={tasks[index]}>
                {', '}
                {tasks[index]}
            </Message>
        )
    }
    children.push(
        tasks.length > 1 && (
            <Message key="conjuction">
                {tasks.length > 2 ? ', and ' : ' and '}
                {tasks[tasks.length - 1]}
                {'.'}
            </Message>
        )
    )

    return children
}

export const checkReadinessOfSelectAwardsTask = ({
    isTopWorkplaceParticipant,
    hasSelectedAwards,
    hasConfirmedRegionalCounts,
    isInviteeCountSubstantiallyDifferentFromRegionalCounts,
    hasMinimumQualifyingInviteeCount,
}) => {
    if (
        isTopWorkplaceParticipant &&
        (!hasSelectedAwards ||
            !hasConfirmedRegionalCounts ||
            isInviteeCountSubstantiallyDifferentFromRegionalCounts ||
            !hasMinimumQualifyingInviteeCount)
    ) {
        return false
    }

    return true
}

export const getPendingTasksMessage = ({
    hasOrganizationData,
    hasConfirmedRecipients,
    hasEnoughRecipients,
    hasIncompleteSmsSetup,
    hasSelectedAwards,
    isTopWorkplaceParticipant,
    hasConfirmedRegionalCounts,
    isSurveyPreviewConfirmed,
    hasNonDemographicQuestion,
    hasValidLaunchDate,
    hasDeliveryContactsSetup,
    responderCountSummaryThreshold,
    hasSelectedWhoAreSurveyed,
    isTwpUsaQualified,
    isRandomSampleMeetsMinimumRequirement,
    minimumInviteeCountForRandomSampling,
    isRandomSamplingValid,
    isInviteeCountSubstantiallyDifferentFromRegionalCounts,
    hasMinimumQualifyingInviteeCount,
    showWorkplaceTrendingStatementsValidation,
}) => {
    const tasks = []
    if (showWorkplaceTrendingStatementsValidation) {
        tasks.push(`add at least one Workplace Experience item`)
    }
    if (!hasOrganizationData) {
        tasks.push(`complete your organization overview in the top right corner`)
    }
    if (!hasConfirmedRecipients) {
        tasks.push(`confirm your recipients`)
    } else if (!hasEnoughRecipients) {
        tasks.push(`make sure you have ${responderCountSummaryThreshold} or more recipients`)
    } else if (hasIncompleteSmsSetup) {
        tasks.push(`complete your SMS setup by adding a message to your SMS invitation`)
    } else if (!hasSelectedWhoAreSurveyed) {
        tasks.push(`select who you're surveying`)
    } else if (!isTwpUsaQualified) {
        tasks.push(`select to survey your entire employee population or deselect all national awards`)
    } else if (!isRandomSampleMeetsMinimumRequirement) {
        tasks.push(`make sure you have ${minimumInviteeCountForRandomSampling} or more recipients`)
    } else if (hasConfirmedRegionalCounts && !isRandomSamplingValid) {
        tasks.push(`make sure you meet our random sampling requirements`)
    }

    if (!isSurveyPreviewConfirmed || !hasNonDemographicQuestion) {
        tasks.push('approve your survey preview')
    }
    if (!hasValidLaunchDate) {
        tasks.push('schedule your survey dates in the future')
    }
    if (isTopWorkplaceParticipant) {
        if (!hasSelectedAwards) {
            tasks.push('select your award(s) for this survey')
        } else if (!hasConfirmedRegionalCounts) {
            tasks.push('provide and confirm employee counts on the Select Awards page')
        } else if (!hasMinimumQualifyingInviteeCount) {
            tasks.push('upload the minimum amount of employees required for each award')
        } else if (isInviteeCountSubstantiallyDifferentFromRegionalCounts) {
            tasks.push('adjust your regional employee count or uploaded recipients')
        }
    }

    if (!hasDeliveryContactsSetup) {
        tasks.push(
            <>
                {'select your delivery contact(s) in '}
                <AppLink to={routes.manage.deliveryContacts}>{'Organization Management'}</AppLink>
            </>
        )
    }
    return getLaunchReadinessMessageElements(tasks)
}
