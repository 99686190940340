import { routes } from 'constants/routes'
import TaskDefinition from 'constants/taskDefinition'

const getPublicProfileUrl = ({ awardId }) => {
    const baseRoute = routes.profile()
    return awardId ? `${baseRoute}?award=${awardId}` : baseRoute
}

export default [
    {
        name: 'AcceptTerms',
        title: 'agree to Terms and Conditions',
        type: 'SurveyLaunch',
        path: routes.terms(),
        tasks: [
            {
                taskDefinitionId: TaskDefinition.AcceptTerms,
                name: 'Agree to Terms and Conditions',
                path: routes.terms(),
            },
        ],
    },
    {
        name: 'ProvideNationalEmployeeCount',
        title: 'enter your national employee count',
        type: 'SurveyLaunch',
        path: routes.manage(),
        tasks: [
            {
                taskDefinitionId: TaskDefinition.ProvideNationalEmployeeCount,
                name: 'Enter your national employee count',
                path: routes.manage(),
            },
        ],
    },
    {
        name: 'OrganizationOverview',
        title: 'finish organization management overview',
        type: 'SurveyLaunch',
        path: routes.manage(),
        tasks: [
            {
                taskDefinitionId: TaskDefinition.OrganizationOverview,
                name: 'Finish organization management overview',
                path: routes.manage(),
            },
        ],
    },
    {
        name: 'ScheduleSurvey',
        title: 'schedule your survey',
        type: 'SurveyLaunch',
        path: routes.survey.setup.schedule,
        tasks: [
            {
                taskDefinitionId: TaskDefinition.ChooseSurveyStartDate,
                name: 'Schedule your survey',
                path: routes.survey.setup.schedule,
            },
        ],
    },
    {
        name: 'SetupSurvey',
        title: 'upload your employees and confirm survey recipients',
        type: 'SurveyLaunch',
        path: routes.survey.setup.recipients,
        tasks: [
            {
                taskDefinitionId: TaskDefinition.UploadEmployees,
                name: 'Upload your survey employees',
                path: routes.survey.setup.recipients.add,
            },
            {
                taskDefinitionId: TaskDefinition.ConfirmRecipients,
                name: 'Confirm your survey recipients',
                path: routes.survey.setup.recipients,
            },
        ],
    },
    {
        name: 'ApproveSurveyLaunch',
        title: 'approve your survey launch',
        type: 'SurveyLaunch',
        path: routes.survey.setup.summary,
        tasks: [
            {
                taskDefinitionId: TaskDefinition.ApproveSurveyLaunch,
                name: 'Approve your survey launch',
                path: routes.survey.setup.summary,
            },
        ],
    },
    {
        name: 'CompleteRegistration',
        title: 'confirm your employee count, provide your primary contact information',
        type: 'ListClosure',
        path: routes.employerRecognition.award.registration,
        tasks: [
            {
                taskDefinitionId: TaskDefinition.RegistrationCompletion,
                name: 'Complete your registration',
                path: routes.employerRecognition.award.registration,
            },
        ],
    },
    {
        name: 'ConfirmProfile',
        title: 'publish your profile',
        type: 'ProfileDeadline',
        path: getPublicProfileUrl,
        tasks: [
            {
                taskDefinitionId: TaskDefinition.ConfirmProfile,
                name: 'Publish your profile',
                path: getPublicProfileUrl,
            },
        ],
    },
]
