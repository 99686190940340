import { Loading as Spinner } from '@energage/components'
import styled from 'styled-components'
import type { Pdf, PdfLocation } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { AnnouncementExperience } from 'containers/Main/AwardAnnouncement/constants'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'
import { AspiringPdf } from './AspiringPdf'
import { ClassicAspiringPdf } from './ClassicAspiringPdf'
import { ClassicWinnerPdf } from './ClassicWinnerPdf'
import { useFetchAwardAnnouncementPdf } from './useFetchAwardAnnouncementPdf'
import { WinnerPdf } from './WinnerPdf'

export const PageBreaker = styled.div`
    page-break-before: always;
`

export const AwardAnnouncementPdf = ({ location }: PdfLocation) => {
    //@ts-ignore
    const { awardListParticipantId, url, baseUrl }: Pdf = parseQueryParams(location)
    const { data, isLoading } = useFetchAwardAnnouncementPdf(url, 'award-announcement')

    if (isLoading) {
        return <Spinner />
    }

    switch (data?.experienceType) {
        case AnnouncementExperience.OLD_ASPIRING:
            return <ClassicAspiringPdf award={data} baseUrl={baseUrl} />
        case AnnouncementExperience.OLD_WINNER:
            return <ClassicWinnerPdf award={data} awardListParticipantId={awardListParticipantId} baseUrl={baseUrl} />
        case AnnouncementExperience.NEW_ASPIRING:
            return <AspiringPdf award={data} baseUrl={baseUrl} />
        case AnnouncementExperience.NEW_WINNER:
            return <WinnerPdf award={data} baseUrl={baseUrl} />
        default:
            return null
    }
}
