import includes from 'lodash/includes'
import { AnnouncementExperience } from './constants'

export const getAwardTypeName = (awardCategoryId: number) => {
    switch (awardCategoryId) {
        case 1:
            return 'Culture Excellence Award'
        case 2:
            return 'Industry Award'
        case 3:
            return 'USA Award'
        case 4:
            return 'Regional Award'

        // no default
    }
}

export const updatedAwardAliasName = (awardAliasName: string) => {
    return awardAliasName ? awardAliasName.substring(0, awardAliasName.length - 5) : ''
}

export const showRevampExp = (experienceType: number) =>
    includes([AnnouncementExperience.NEW_ASPIRING, AnnouncementExperience.NEW_WINNER], experienceType)

export const getUpdatedPublisherName = (requiresTheBeforeName: boolean | undefined, publisherName: string) => {
    return requiresTheBeforeName ? `The ${publisherName}` : publisherName
}
