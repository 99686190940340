import cx from 'clsx'
import styled from 'styled-components'
import type { PreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/LoveMyJobConfiguration/EmpComments.types'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

const PreviewContainer = styled.div<{
    backgroundColor?: string
    width?: string
    height?: string
    alt?: string
}>`
    width: ${({ width }) => width ?? '340px'};
    height: ${({ height }) => height ?? '340px'};
    background: ${({ backgroundColor }) => backgroundColor};
`

const TextWrapper = styled.div<{ textColor: string }>`
    color: ${({ textColor }) => textColor};
`

const Heading = styled.h3<{ titleColor?: string; isDownloadable?: boolean }>`
    padding-bottom: ${({ isDownloadable }) => (isDownloadable ? '42px' : '24px')};
    color: ${({ titleColor }) => titleColor};
    font-size: ${({ isDownloadable }) => (isDownloadable ? '108px' : '48px')};
    line-height: 104%;
`

const Paragraph = styled.p<{ isDownloadable?: boolean }>`
    font-size: ${({ isDownloadable }) => (isDownloadable ? '54px' : '24px')};
    line-height: 136%;
`

export const EmpCommentsQuotePreview = ({
    text,
    width,
    height,
    backgroundColor,
    titleColor,
    textColor,
    isDownloadable,
}: PreviewProps) => {
    return (
        <PreviewContainer
            className={cx(isDownloadable ? 'p-16' : 'p-8', 'relative grid content-center')}
            width={width}
            height={height}
            backgroundColor={backgroundColor}>
            <div className={cx(isDownloadable ? 'w-40 mt-8 mr-8' : 'w-20 mt-4 mr-4', 'absolute top-0 right-0')}>
                <img src={CultureFactTWP} alt="" className="border rounded-full w-full" />
            </div>
            <TextWrapper className="text-left" textColor={textColor}>
                <Heading className="font-black" titleColor={titleColor} isDownloadable={isDownloadable}>
                    {'I love my job because'}
                </Heading>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isDownloadable ? '135' : '69'}
                    viewBox="0 0 135 91"
                    fill="none">
                    <path
                        d="M17.9082 0.678706L57.0577 0.678706L42.1151 30.5638H59.8967L59.8967 90.3339L0.12663 90.3339V36.2419L17.9082 0.678706ZM92.6209 0.678706L131.77 0.678706L116.828 30.5638H134.609V90.3339L74.8393 90.3339V36.2419L92.6209 0.678706Z"
                        fill={titleColor}
                    />
                </svg>
                <Paragraph className="font-semibold mt-1" isDownloadable={isDownloadable}>{`${text}`}</Paragraph>
            </TextWrapper>
        </PreviewContainer>
    )
}
