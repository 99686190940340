import { useQueryClient } from 'react-query'
import { useDocumentManagementQuery } from 'api/apiQueries'
import showErrorAlert from 'util/showErrorAlert'
import type { ShareableLinkRequest } from './models'

function useInvalidateDocumentExplorer() {
    const queryClient = useQueryClient()

    function invalidate(surveyCompanyId: number, path: string) {
        if (path.endsWith('/')) {
            path = path.slice(0, -1)
        }
        const lastSlashIndex = path.lastIndexOf('/')
        const folderPath = path.substring(0, lastSlashIndex + 1)
        queryClient.invalidateQueries(['get-documents-explorer', surveyCompanyId, folderPath])
    }

    return invalidate
}

export function useGetDocuments(surveyCompanyId: number, path: string, continuationToken: string, pageSize: number) {
    return useDocumentManagementQuery<{
        continuationToken: string
        items: {
            name: string
            type: number
            modifiedDate: string
            sizeInBytes: number
            accessTier: string
        }[]
    }>(
        ['get-documents-explorer', surveyCompanyId, path],
        `documents/${surveyCompanyId}/explorer?continuationToken=${continuationToken}&path=${path}&pageSize=${pageSize}`,
        {
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )
}

export function useCreateNewFolder(surveyCompanyId: number) {
    const invalidateQuery = useInvalidateDocumentExplorer()
    const mutation = useDocumentManagementQuery.mutate<unknown, unknown, { folderName: string }>(async (data, api) => {
        return await api.postJson(`documents/${surveyCompanyId}/folder`, { path: data.folderName })
    })

    const createFolder = async (data: { folderName: string }) => {
        try {
            const response = await mutation.mutateAsync(data)
            invalidateQuery(surveyCompanyId, data.folderName)
            return response
        } catch (error) {
            showErrorAlert('There was an error creating a new folder', error)
        }
    }

    return {
        mutateCreateFolder: createFolder,
        ...mutation,
    }
}

export function useGetShareableLink(surveyCompanyId: number) {
    const mutation = useDocumentManagementQuery.mutate<{ url: string }, unknown, ShareableLinkRequest>(
        async (data, api) => {
            return await api.postJson(`documents/${surveyCompanyId}/share`, data)
        }
    )

    const getShareableLink = async (data: ShareableLinkRequest) => {
        try {
            return await mutation.mutateAsync(data)
        } catch (error) {
            showErrorAlert('There was an error getting the link of the file', error)
        }
    }

    return {
        mutateGetShareableLink: getShareableLink,
        ...mutation,
    }
}

export function useDeleteFolder(surveyCompanyId: number) {
    const invalidateQuery = useInvalidateDocumentExplorer()

    const mutation = useDocumentManagementQuery.mutate<unknown, unknown, { path: string }>(async (data, api) => {
        return await api.postJson(`documents/${surveyCompanyId}/delete`, data)
    })

    const deleteDocument = async (data: { path: string }) => {
        try {
            const result = await mutation.mutateAsync(data)
            invalidateQuery(surveyCompanyId, data.path)
            return result
        } catch (error) {
            showErrorAlert('There was an error deleting folder/file', error)
        }
    }

    return {
        mutateDeleteDocument: deleteDocument,
        ...mutation,
    }
}

export function useUploadDocument(surveyCompanyId: number) {
    const invalidateQuery = useInvalidateDocumentExplorer()

    const mutation = useDocumentManagementQuery.mutate<
        { url: string },
        unknown,
        { path: string; file: File; surveyUserId: number }
    >(async (data, api) => {
        const response = await api.postJson<{ url: string }>(`documents/${surveyCompanyId}`, data)
        return response
    })

    const mutationUpload = useDocumentManagementQuery.mutate<
        Response,
        unknown,
        { url: string; surveyUserId: number; surveyCompanyId: number; file: File }
    >(async (data) => {
        const response = await fetch(data.url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/octet-stream',
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-meta-createdBy': '' + data.surveyUserId,
                'x-ms-meta-companyId': '' + data.surveyCompanyId,
            },
            body: data.file,
        })
        return response
    })

    const uploadDocument = async (data: { path: string; file: File; surveyUserId: number }) => {
        try {
            const result = await mutation.mutateAsync(data)
            const uploadRequest = {
                url: result.url,
                surveyUserId: data.surveyUserId,
                surveyCompanyId: surveyCompanyId,
                file: data.file,
            }
            const response = await mutationUpload.mutateAsync(uploadRequest)
            if (!response.ok) {
                showErrorAlert(`There was an error uploading ${data.file.name} file`, response.statusText)
                return response
            }
            invalidateQuery(surveyCompanyId, data.path)
            return response
        } catch (error) {
            showErrorAlert('There was an error uploading the company documents')
        }
    }

    return {
        mutateUploadDocument: uploadDocument,
        ...mutation,
    }
}
