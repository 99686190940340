import { RadioButton } from '@energage/components'
import { Text } from '@energage/typography'
import styled from 'styled-components'
import { previewType } from './utils'

const StyledContainer = styled.div<{ isSelected?: boolean }>`
    padding: 0 10px 10px 0;
    background-color: white;
    border: ${(props) => (props.isSelected ? '2px solid #2FCAFE' : '')};
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23);
`

type PreviewTypeSelectionProps = {
    selectedDesignType: number
    dataId?: string
    designTypeChangeHandler: (selectedDesign: number) => void
    selectStatement: string
    rectangle: string
    rectangleGray: string
    rectangleCircle: string
    rectangleCircleGray: string
    classic: string
    classicGray: string
}

export const PreviewTypeSelection = ({
    selectedDesignType,
    designTypeChangeHandler,
    selectStatement,
    rectangle,
    rectangleGray,
    rectangleCircle,
    rectangleCircleGray,
    classic,
    classicGray,
    dataId,
}: PreviewTypeSelectionProps) => {
    const isRectangleDesignSelected = selectedDesignType === previewType.TriangleDesign
    const isRectangleCircleDesignSelected = selectedDesignType === previewType.CircleDesign
    const isClassicDesignSelected = selectedDesignType === previewType.Classical

    const rectangleDesignImage = () => {
        return (
            <img
                alt="Rectangle framed"
                src={isRectangleDesignSelected ? rectangle : rectangleGray}
                className="mt-3 self-center sm:self-start"
            />
        )
    }

    const rectangleCircleDesignImage = () => {
        return (
            <img
                alt="Rectangle Circle"
                src={isRectangleCircleDesignSelected ? rectangleCircle : rectangleCircleGray}
                className="mt-3 self-center sm:self-start"
            />
        )
    }

    const classicDesignImage = () => {
        return (
            <img
                alt="Classical"
                src={isClassicDesignSelected ? classic : classicGray}
                className="mt-3 self-center sm:self-start"
            />
        )
    }

    return (
        <div className="mt-2 mb-8">
            <Text textStyle="title6" className="mb-2 text-sm">
                <h5>{selectStatement}</h5>
            </Text>
            <div className="flex flex-row">
                <StyledContainer className="mr-6" isSelected={isRectangleDesignSelected}>
                    <div className="ml-1 mt-1">
                        <RadioButton
                            label={rectangleDesignImage()}
                            name={`${dataId}-designTypeTriangle`}
                            value={previewType.TriangleDesign}
                            onChange={() => designTypeChangeHandler(previewType.TriangleDesign)}
                            checked={isRectangleDesignSelected}
                        />
                    </div>
                </StyledContainer>
                <StyledContainer className="mr-6" isSelected={isRectangleCircleDesignSelected}>
                    <div className="ml-1 mt-1">
                        <RadioButton
                            label={rectangleCircleDesignImage()}
                            name={`${dataId}-designTypeCircle`}
                            value={previewType.CircleDesign}
                            onChange={() => designTypeChangeHandler(previewType.CircleDesign)}
                            checked={isRectangleCircleDesignSelected}
                        />
                    </div>
                </StyledContainer>
                <StyledContainer className="mr-6" isSelected={isClassicDesignSelected}>
                    <div className="ml-1 mt-1">
                        <RadioButton
                            label={classicDesignImage()}
                            name={`${dataId}-designTypeClassic`}
                            value={previewType.Classical}
                            onChange={() => designTypeChangeHandler(previewType.Classical)}
                            checked={isClassicDesignSelected}
                        />
                    </div>
                </StyledContainer>
            </div>
        </div>
    )
}
