import { useCallback, useRef } from 'react'
import { alert } from '@energage/components'
import noop from 'lodash/noop'
import { stringify } from 'query-string'
import { useQueryClient } from 'react-query'
import { useWorkplaceSurveyQuery } from 'api'
import { routes } from 'constants/routes'
import LoadingButton from '../../Common/SummaryCard/LoadingButton'

export function getSurveyPreviewUrl({ surveyUrl, isConfirmed, surveyCompanyId, surveyEventId }) {
    const query = stringify({
        url: surveyUrl,
        a: isConfirmed ? 'y' : undefined,
        id: surveyCompanyId,
    })
    return `${routes.survey.surveyPreview({ surveyEventId })}?${query}`
}

export const PreviewSurveyButton = ({ surveyEventId, previewUrl = '', buttonText, className }) => {
    const { mutateAsync: clearCache, isLoading } = useWorkplaceSurveyQuery.mutate(`surveycache/${surveyEventId}`, {
        onError: () => alert.danger('There was an error generating your preview'),
    })

    async function onClick(event) {
        event.preventDefault()
        await clearCache()
        window.open(previewUrl, '_blank', 'noreferrer,noopener')
    }

    return (
        <div className="w-fit">
            <LoadingButton
                isLoading={isLoading}
                onClick={onClick}
                className={className}
                role="link"
                data-href={previewUrl}>
                {buttonText}
            </LoadingButton>
        </div>
    )
}

export const usePreviewQuery = (surveyEventId, surveyCompanyId, refreshWorkflow = noop) => {
    const firstCall = useRef(false)
    const queryClient = useQueryClient()

    const {
        status,
        isLoading,
        data = {},
    } = useWorkplaceSurveyQuery(['survey-preview', surveyEventId], `surveypreview/${surveyEventId}`, {
        refetchOnWindowFocus: true,
        onSettled: () => {
            if (!firstCall.current) {
                firstCall.current = true
                return
            }

            refreshWorkflow()
        },
    })

    const { isConfirmed = true, surveyUrl } = data

    const previewUrl =
        status === 'loading'
            ? undefined
            : getSurveyPreviewUrl({ surveyEventId, surveyCompanyId, isConfirmed, surveyUrl })

    return {
        previewUrl,
        isLoading,
        isConfirmed,
        fetchPreview: useCallback(
            () => queryClient.invalidateQueries(['survey-preview', surveyEventId], { refetchInactive: true }),
            [queryClient, surveyEventId]
        ),
    }
}
