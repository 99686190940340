import Carousel from 'nuka-carousel'
import styled from 'styled-components'

export const StyledCarousel = styled(Carousel)`
    outline: none;

    .slider-frame {
        border-radius: 12px;
    }

    .slider-list {
        pointer-events: none;
    }

    .slider-control-bottomcenter {
        bottom: -42px !important;
        background-color: #f0f1f6;
        padding: 5px 25px;
        border-radius: 15px;
        font-weight: 700;
    }

    .slider-control-bottomright {
        transform: translateX(-140px) !important;
        bottom: -37px !important;
    }

    .slider-control-bottomleft {
        transform: translateX(140px) !important;
        bottom: -37px !important;
    }
`
