import { useContext, useMemo, useState } from 'react'
import { alert, Alert, LegacyButton as Button } from '@energage/components'
import loadable from '@loadable/component'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import map from 'lodash/map'
import { useHistory } from 'react-router-dom'
import { useIdentity, usePermissions } from 'components/Identity'
import SurveyType from 'constants/SurveyType'
import StatementDownloadLink from '../StatementDownload/StatementDownloadLink'
import { NextPageButton } from '../SurveySetup/ConfigureSurvey/SurveySetupHeader'
import { PreviewSurveyButton, usePreviewQuery } from '../SurveySetup/Preview'
import SurveySetupContext from '../SurveySetup/SurveySetupContext'
import CustomQuestionsSection from './CustomQuestionsSection'
import { DepartmentContext } from './departmentContext'
import { EmptyState, LeadingDemographicDescription, TrailingDemographicDescription } from './fragments'
import {
    demographicSectionAtom,
    sectionsAtom,
    selectedQuestionCountAtom,
    updateAdditionalQuestionsAtom,
} from './statementSetupUtility/store'
import SurveyIntroduction from './SurveyIntroduction'
import useFetchDepartments from './useFetchDepartments'
import useStatementSetup from './useStatementSetup'
import useSubmitSurveyDefinition from './useSubmitSurveyDefinition'

const AddMoreQuestionsModal = loadable(async () => {
    const component = await import('./AddMoreQuestions')
    return { default: component.AddMoreQuestionsModal }
})

export const SurveyStatementSetup = ({
    surveyEventId,
    surveyType,
    hasAdvancedCustomizations,
    draftCustomQuestions,
    templateName,
    header: Header,
    readOnly = false,
}) => {
    const setDemographicSection = useUpdateAtom(demographicSectionAtom)
    const sections = useAtomValue(sectionsAtom)
    const demographicSection = useAtomValue(demographicSectionAtom)
    const selectedQuestionCount = useAtomValue(selectedQuestionCountAtom)
    const updateCustomQuestionSection = useUpdateAtom(updateAdditionalQuestionsAtom)
    const { getNextPage } = useContext(SurveySetupContext)
    const { isSuperUser, surveyCompanyId } = useIdentity()
    const { hasSmartPulse, hasInsights, hasHideQuestionBank } = usePermissions()
    const hideQuestionBank = !hasSmartPulse || hasHideQuestionBank
    const [showCustomQuestions, setShowCustomQuestions] = useState(draftCustomQuestions?.length > 0)

    const { previewUrl, isConfirmed, fetchPreview } = usePreviewQuery(surveyEventId, surveyCompanyId)

    const { autosave, submit, saveState: surveySaveState } = useSubmitSurveyDefinition(surveyEventId, fetchPreview)

    const { data: departments } = useFetchDepartments(surveyEventId, surveyCompanyId)

    const departmentInformation = useMemo(() => {
        function flattenDepartments(depts, map) {
            if (!depts) {
                return map
            }
            const departments = Array.isArray(depts) ? depts : [depts]
            if (departments.length > 0) {
                for (const d of depts) {
                    map.set(d.id, d)
                    if (d.children?.length > 0) {
                        flattenDepartments(d.children, map)
                    }
                }
            }
        }

        const departmentMap = new Map()
        flattenDepartments(departments, departmentMap)

        return { flattenedDepartments: departmentMap, departments }
    }, [departments])

    function onCustomQuestionSave(questions) {
        updateCustomQuestionSection(questions)
        setShowCustomQuestions(false)
    }

    const {
        isReapprovalRequired,
        customQuestions,
        submitCustomQuestions,
        isSavingCustomQuestions,
        saveCustomQuestions,
        isAutoSavingCustomQuestions,
        isDeletingQuestion,
        saveQuestionBankQuestions,
        isSavingQuestionBankQuestions,
        deleteQuestionBankQuestion,
        ...questionCreationMethods
    } = useStatementSetup({ surveyEventId, draftCustomQuestions, onCustomQuestionSave, refetchPreview: fetchPreview })

    const deleteCustomQuestion = (index, question) => {
        if (question.id) {
            questionCreationMethods
                .deleteCustomQuestion(question)
                .then(() => questionCreationMethods.handleDeleteQuestion(index))
                .catch(() => alert.danger('There was an error deleting the question draft'))
        } else {
            questionCreationMethods.handleDeleteQuestion(index)
        }
    }

    function onQuestionBankUpdate(addedQuestions) {
        updateCustomQuestionSection(addedQuestions)
    }

    function addSelectedDemographics(question, subsectionId) {
        setDemographicSection((demoSection) => {
            return {
                ...demoSection,
                subsections: map(demoSection.subsections, (subsection) => {
                    if (subsection.subsectionId === subsectionId) {
                        return { ...subsection, questions: [...subsection.questions, question] }
                    }
                    return subsection
                }),
            }
        })
    }

    const history = useHistory()

    const nextButtonProps = {
        onSubmit: () => {
            if (!readOnly) {
                submit()
            } else {
                history.push(getNextPage().route({ surveyEventId }))
            }
        },
        isDisabled: surveySaveState === 'SAVING' || selectedQuestionCount === 0,
    }

    const isFullSurvey = surveyType === SurveyType.Standard
    const isCustomSurvey = surveyType === SurveyType.Custom

    return (
        <DepartmentContext.Provider value={departmentInformation}>
            <div className="relative">
                <Header
                    {...nextButtonProps}
                    saveState={surveySaveState}
                    requiresPreview={!isConfirmed}
                    previewUrl={previewUrl}
                    surveyEventId={surveyEventId}
                    templateName={templateName}
                />
                <div className="px-6">
                    {sections.length === 0 && <EmptyState />}
                    {sections.length > 0 && (
                        <div>
                            {hasAdvancedCustomizations && (
                                <div className="flex flex-col justify-center items-center border-b-2 p-3">
                                    <Alert variant="info" className="text-center text-xs px-3 py-2 w-auto">
                                        {`This survey has advanced customizations - to edit or add questions, please contact support@energage.com`}
                                    </Alert>
                                </div>
                            )}
                            <div data-testid="survey-setup-statement">
                                <SurveyIntroduction
                                    isFullSurvey={isFullSurvey}
                                    disabled={readOnly}
                                    onChange={autosave}
                                />
                                <demographicSection.Component
                                    testId="survey-setup-demographic-section"
                                    surveyEventId={surveyEventId}
                                    onUpdateQuestions={autosave}
                                    isSuperUser={isSuperUser}
                                    addSelectedDemographic={addSelectedDemographics}
                                    disabled={readOnly}
                                    leadingDescription={
                                        <LeadingDemographicDescription
                                            isFullSurvey={isFullSurvey}
                                            isPurchaser={hasSmartPulse || hasInsights}
                                        />
                                    }
                                    trailingDescription={<TrailingDemographicDescription />}
                                />
                                {map(sections, ({ Component, ...props }) => {
                                    return (
                                        <Component
                                            key={props.sectionId}
                                            testId={`survey-setup-section-${props.sectionId}`}
                                            surveyEventId={surveyEventId}
                                            {...props}
                                            onUpdateQuestions={autosave}
                                            disabled={readOnly}
                                            isCustomSurvey={isCustomSurvey}
                                            isSuperUser={isSuperUser}
                                            removeQuestion={deleteQuestionBankQuestion}
                                            departments={departments}
                                        />
                                    )
                                })}
                            </div>
                            <div className="clearfix">
                                {!readOnly && showCustomQuestions && (
                                    <CustomQuestionsSection
                                        hasSmartPulse={hasSmartPulse}
                                        customQuestions={customQuestions}
                                        {...questionCreationMethods} //should go before handleDeleteQuestion
                                        handleDeleteQuestion={deleteCustomQuestion}
                                        handleSubmitQuestions={(e) => {
                                            e.preventDefault()
                                            submitCustomQuestions(customQuestions)
                                        }}
                                        autoSaving={isAutoSavingCustomQuestions}
                                        disableSubmit={isSavingCustomQuestions || isDeletingQuestion}
                                        handleOnQuestionSave={saveCustomQuestions}
                                        departments={departments}
                                    />
                                )}
                            </div>
                            {!readOnly && (
                                <div className="flex justify-center text-center sm:text-left sm:flex-row sm:justify-end">
                                    <div className="w-64 sm:w-auto flex flex-col sm:flex-row">
                                        {!hideQuestionBank && (
                                            <AddMoreQuestionsModal
                                                onQuestionBankUpdate={onQuestionBankUpdate}
                                                surveyEventId={surveyEventId}
                                            />
                                        )}
                                        <Button
                                            outline
                                            variant="secondary"
                                            className="mt-6 w-full sm:w-auto"
                                            onClick={() => {
                                                if (hasSmartPulse) {
                                                    setShowCustomQuestions(true)
                                                    questionCreationMethods.handleAddCustomQuestion()
                                                }
                                            }}
                                            type="button">
                                            {'Create Custom Item'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div className="sm:hidden text-center mt-6 pt-6 pb-3 px-6 border-t-2">
                                <PreviewSurveyButton
                                    className="w-64 block mx-auto mb-3"
                                    previewUrl={previewUrl}
                                    surveyEventId={surveyEventId}
                                    buttonText="Preview"
                                    outline
                                    variant="primary"
                                />
                                <NextPageButton className="w-64" {...nextButtonProps} />
                            </div>
                            <div className="text-center sm:text-right py-4">
                                <StatementDownloadLink surveyEventId={surveyEventId} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </DepartmentContext.Provider>
    )
}
