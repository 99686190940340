import { QuestionType } from '../CustomQuestions/QuestionType'

export const QuestionTypeAnswerFormats = {
    [QuestionType.Agreement]: { name: 'Statement' },
    [QuestionType.MultiComment]: { name: 'Text Boxes' },
    [QuestionType.Feedback]: { name: 'Rating Scale (1-10)' },
    [QuestionType.Comment]: {
        name: 'Open-ended',
        description: 'Open ended answers allow employees to give long form text feedback.',
    },
    [QuestionType.Rating]: { name: 'Rating Scale' },
    [QuestionType.OldYesNo]: { name: 'Checkbox' },
    [QuestionType.YesNo]: { name: 'Multiple Choice' },
    [QuestionType.TrueFalse]: { name: 'Multiple Choice' },
    [QuestionType.Demographic]: { name: 'Multiple Choice' },
    [QuestionType.CustomScale]: { name: 'Custom Scale' },
    [QuestionType.TextBlock]: { name: 'Text Block' },
    [QuestionType.MultiChoice]: { name: 'Multiple Choice' },
} as const

export const agreementOptions = {
    0: { id: 0, name: 'Strongly Disagree' },
    1: { id: 1, name: 'Disagree' },
    2: { id: 2, name: 'Slightly Disagree' },
    3: { id: 3, name: 'Neutral' },
    4: { id: 4, name: 'Slightly Agree' },
    5: { id: 5, name: 'Agree' },
    6: { id: 6, name: 'Strongly Agree' },
} as const
