import type { HTMLAttributes } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@energage/icons'

type CollapsibleButtonProps = {
    text: string
    open: boolean
    onToggle: () => void
} & HTMLAttributes<HTMLButtonElement>

export function CollapsibleButton({ open, onToggle, text, ...buttonProps }: CollapsibleButtonProps) {
    const Icon = open ? ArrowDropUp : ArrowDropDown
    return (
        <button
            className="flex justify-between items-center sm:pr-5 focus:outline-none text-blue600"
            onClick={onToggle}
            {...buttonProps}
            aria-expanded={open}>
            <div className="text-left">{text}</div>
            <div className="text-blue600 -my-1">
                <Icon className="block" />
            </div>
        </button>
    )
}
