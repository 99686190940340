import styled from 'styled-components'
import type { DataTile, PercentageTile } from '../models'
import { TileCard } from './TileCard'

const EmbedFrame = styled.iframe.attrs({ tabIndex: -1 })`
    pointer-events: none;
    aspect-ratio: 16/9;
`

const DataChartCard = (dataTile: DataTile) => (
    <TileCard className="flex flex-col justify-between pt-2 px-4 pb-4" tile={dataTile} height={'264px'}>
        <h5>{dataTile.text}</h5>
        <EmbedFrame
            className="w-full m-auto"
            id={`embed-${dataTile.id}`}
            title={dataTile.text}
            src={dataTile.queryUrl}
        />
    </TileCard>
)

const DataPercentageCard = (dataTile: PercentageTile) => (
    <TileCard className="flex flex-col justify-center pt-2 px-4 pb-4" tile={dataTile} height={'128px'}>
        <h1 className={`text-${dataTile.color}`}>{`${dataTile.data}%`}</h1>
        <h5>{dataTile.text}</h5>
    </TileCard>
)

const DataCard = (dataTile: DataTile) => {
    switch (dataTile.dataType) {
        case 'chart':
            return <DataChartCard {...dataTile} />
        case 'percentage':
            return <DataPercentageCard {...(dataTile as PercentageTile)} />
        default:
            return <></>
    }
}

export { DataCard }
