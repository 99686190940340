import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { addHistory, addNavigationItems, selectBackPath, selectNavigationItems } from 'store'

const NavigationListener = () => {
    const history = useHistory()
    const location = useLocation()
    const lastLocation = useRef(location)

    useEffect(() => {
        lastLocation.current = location
    }, [location])

    useEffect(
        () =>
            history.listen((location) => {
                addHistory({ source: lastLocation.current.pathname, destination: location.pathname })
            }),
        [history]
    )

    return null
}

function useNavigation(currentPath) {
    const items = useSelector(selectNavigationItems(currentPath))
    const addItems = useCallback((path, title, items) => {
        addNavigationItems({ path, title, items })
    }, [])
    return { addItems, ...items }
}

function useBackButton(url, defaultBackPath) {
    return useSelector(selectBackPath(url)) ?? defaultBackPath
}

export { NavigationListener, useNavigation, useBackButton }
