import { useRef } from 'react'
import { Button } from '@energage/components'
import { FileCopy } from '@energage/icons'
import { DialogTrigger, Modal } from '@energage/overlays'
import reduce from 'lodash/reduce'
import styled from 'styled-components'
import { INTERNAL_COMPANY_NAME, SUPPORT_EMAIL, SURVEY_EMAIL } from 'constants/strings'
import { copyRichTextToClipboard } from 'util/copyToClipboard'

const tdStyle = {
    paddingRight: '10px',
    paddingBottom: '20px',
    verticalAlign: 'top',
    border: '0',
}

const DynamicLinkComponent = styled.span`
    color: red;
    font-style: italic;
`

const DynamicLink = ({ baseUrl, variedComponents }) => {
    const dynamicParts = reduce(
        variedComponents,
        (acc, component, index) => {
            acc.push(<DynamicLinkComponent key={component}>{component}</DynamicLinkComponent>)
            if (index < variedComponents.length - 1) {
                acc.push('/')
            }
            return acc
        },
        []
    )

    return (
        <>
            {baseUrl}
            {dynamicParts}
        </>
    )
}

const Td = ({ children }) => <td style={tdStyle}>{children}</td>

const TechnicalPreparationEmailLink = ({
    companyName,
    surveyEventStartDateTimeText,
    surveyCode,
    firstTimeEmailSubject,
    reminderEmailSubject,
}) => {
    const emailContent = useRef(null)

    return (
        <div>
            <DialogTrigger>
                <Button className="pl-0" appearance="tertiary">
                    {'Click here for the Technical Preparation Email Template'}
                </Button>
                <Modal title="Technical Team Survey Preparation" maxWidth={850}>
                    <p className="text-red500 mb-4">
                        <strong>{'Please copy and paste the text below into an email to your technical staff.'}</strong>
                        <span
                            className="cursor-pointer text-grey500 hover:text-grey600 ml-2"
                            onClick={() => copyRichTextToClipboard(emailContent.current)}
                            title="Copy to Clipboard">
                            <FileCopy />
                        </span>
                    </p>
                    <div ref={emailContent}>
                        <p style={{ marginBottom: '5px', border: '0' }}>
                            {`Starting ${surveyEventStartDateTimeText},
                            ${INTERNAL_COMPANY_NAME}, an external survey company, will be sending out multiple survey
                            invitation emails to ${companyName} employees. Employees who fill out
                            the survey in the first couple days will only get one email.  Employees who
                            do not immediately fill out the survey could get up to three additional reminder
                            emails during the following weeks.`}
                        </p>
                        <p style={{ border: '0' }}>
                            {`Each email will have a link to an ${INTERNAL_COMPANY_NAME} web application where the
                            employee fills out the survey. Employees do not need to reply via email to
                            ${INTERNAL_COMPANY_NAME}. The survey web application uses both cookies and
                            JavaScript. Survey takers will need to have both enabled to take the survey.`}
                        </p>
                        <br />
                        <table cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <Td colSpan="2">
                                        <strong style={{ border: 0 }}>{'Spam filter configuration details'}</strong>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>{'SMTP server IP address:'}</Td>
                                    <Td>{'198.2.187.91'}</Td>
                                </tr>
                                <tr>
                                    <Td>{'Email "From:" addresses:'}</Td>
                                    <Td>
                                        {SURVEY_EMAIL} <br />
                                        {SUPPORT_EMAIL}
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>{'Email "From:" display names:'}</Td>
                                    <Td>
                                        {'Energage Survey'}
                                        <br />
                                        {`${INTERNAL_COMPANY_NAME} Support`}
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>{'Email subjects (may be any of these):'}</Td>
                                    <Td>
                                        {firstTimeEmailSubject}
                                        <br />
                                        {reminderEmailSubject}
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>{'URLs in emails (part in red varies):'}</Td>
                                    <Td>
                                        {'www.energage.com'}
                                        <br />
                                        <DynamicLink
                                            baseUrl="https://survey.energage.com/"
                                            variedComponents={[surveyCode, '42AGV359K']}
                                        />
                                        <br />
                                        <DynamicLink
                                            baseUrl="https://survey.energage.com/Decline/"
                                            variedComponents={[surveyCode, '42AGV359K']}
                                        />
                                        <br />
                                        <DynamicLink
                                            baseUrl="https://survey.energage.com/ConfidentialityFaq/"
                                            variedComponents={[surveyCode, '42AGV359K']}
                                        />
                                        <br />
                                    </Td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <Td colSpan="2">
                                        <strong style={{ border: 0 }}>{'Web filter configuration details'}</strong>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>{'Domains:'}</Td>
                                    <Td>
                                        {'survey.energage.com'}
                                        <br />
                                        {'secure.energage.com'}
                                    </Td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal>
            </DialogTrigger>
        </div>
    )
}

export default TechnicalPreparationEmailLink
