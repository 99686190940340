import { useManagementQuery } from 'api'
import { CultureCloudWordPreview } from 'containers/Public/RevampWidgets/CultureCloudWordPreview'
import likeIcons from 'images/employerBranding/cultureFacts/likeIcons.png'
import noImageIcon from 'images/employerBranding/cultureFacts/noImageIcon.svg'
import repostCount from 'images/employerBranding/cultureFacts/repostCount.png'
import type { CloudPreviewProps } from './CultureCloud.types'

type OrganizationResponse = {
    logo: undefined | { previewUrl: string }
    name: string
}

export const CultureCloudPreviewTypeOne = ({
    ariaTitle,
    ariaDescription,
    droplets,
    backgroundColor,
    isExpiredWidget,
    hasEmployerBrandingSubscription,
    minDroplets,
    filterData,
}: CloudPreviewProps) => {
    const { data } = useManagementQuery<OrganizationResponse>('organization', 'Organization')
    const logoUrl = data?.logo?.previewUrl ?? noImageIcon

    return (
        <>
            <div className="flex mx-4 my-7">
                <img alt="" src={logoUrl} className="w-24 self-center sm:self-start" />
                <h5 className="mt-4 ml-2">{data?.name}</h5>
            </div>
            <section className="m-4 text-xs" aria-label="AI Generated text">
                <p className="mt-3">{`Exciting news from ${data?.name}: Thriving in a culture of excellence!`}</p>
                <p className="mt-3">
                    {`Today, I am thrilled to share a glimpse into the vibrant culture that makes ${data?.name} truly exceptional.`}
                </p>
                <p className="mt-3">
                    {`At ${data?.name}, our culture is not just a word; it's a way of life. We take immense pride in fostering an environment that is not only helpful, great, ... `}
                    <span className="text-blue600">{'Read More'}</span>
                </p>
            </section>
            <CultureCloudWordPreview
                ariaTitle={ariaTitle}
                ariaDescription={ariaDescription}
                droplets={droplets}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                className="w-12 mt-2 mr-2"
                minDroplets={minDroplets}
                backgroundColor={backgroundColor}
                width="449px"
                cloudChartWidth={350}
                cloudChartHeight={270}
            />
            <div className="flex justify-between">
                <img alt="" src={likeIcons} width={84} className="rounded-lg" />
                <img alt="" src={repostCount} width={84} className="rounded-lg" />
            </div>
        </>
    )
}
