import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { useDownloadFile } from 'hooks'
import chain from 'util/chainFunctions'

const DownloadFileButton = (props) => {
    const { fileLocation, onClick, ...rest } = props

    const { element, downloadFile } = useDownloadFile(fileLocation)
    return (
        <>
            {element}
            <Button {...rest} onClick={chain(onClick, downloadFile)} />
        </>
    )
}

DownloadFileButton.propTypes = {
    ...Button.propTypes,
    fileLocation: PropTypes.string.isRequired,
}

export default DownloadFileButton
