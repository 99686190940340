import { colors } from '@energage/theme'
import map from 'lodash/map'
import styled from 'styled-components'
import type { CommunicateWithLeadersSection } from '../awardAnnouncement.types'
import { Section } from '../Section'

const tips = (companyName: string, year: number) => [
    "Asking your employees to participate in the Workplace Survey shows them you're being intentional about culture and that their input is valuable.",
    'The Top Workplaces program is highly competitive.',
    <>
        {`${companyName} aspires to be a Top Workplace and now's the time to prep for the future. `}
        <span className="font-bold">{'Insights'}</span>
        {' can show you where to focus your efforts and Pulse establishes a continuous conversation.'}
    </>,
    <>
        {`While ${companyName} isn't a ${year} Top Workplace, there's still a lot to celebrate. You'll find your bright spots in `}
        <span className="font-bold">{'Insights'}</span>
        {' and '}
        <span className="font-bold">{'Employer Branding'}</span>
        {' will enable you to highlight your hiring differentiators.'}
    </>,
]

const List = styled.ol.attrs({ className: 'list-none flex flex flex-wrap mt-8' })`
    li {
        counter-increment: inst;
        p {
            color: ${(props) => props.color};
        }
    }
    li::before {
        content: counter(inst);
        height: 34px;
        width: 34px;
        background-color: ${colors.yellow500};
        color: ${(props) => props.color};
        font-size: 25px;
        font-weight: 900;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        left: 5px;
        padding: 0px;
    }
`

export const CommunicateWithLeaders = ({ companyName, year, colors }: CommunicateWithLeadersSection) => {
    const communicateWithLeadersTips = tips(companyName, year)
    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor}>
            <h2 className="sm:text-5xl text-2xl mt-6 font-black">
                {'What to communicate with leaders about your Top Workplaces participation.'}
                <List>
                    {map(communicateWithLeadersTips, (tip, index) => (
                        <li className="relative w-full sm:w-1/2 sm:px-12 pl-12 pr-4 py-3 mb-6" key={index}>
                            <p
                                className="relative sm:text-xl text-lg font-normal leading-8 -mt-4"
                                color={colors.textColor}>
                                {tip}
                            </p>
                        </li>
                    ))}
                </List>
            </h2>
        </Section>
    )
}
