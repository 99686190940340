import { forwardRef } from 'react'
import { SettingsDrawer as BaseSettingsDrawer, useSettingsDrawer } from '@energage/components'
import type { FeatureBucket } from '@energage/components/lib/SettingsDrawer/settings-drawer.types'
import { Settings } from '@energage/icons'
import styled from 'styled-components'
import { useFetchFeaturePreviews } from './useFetchSettings'
import { useUpdateToggle } from './useUpdateToggle'

type StyledSettingsDrawerProps = {
    $top: number
}

const StyledSettingsDrawer = styled(BaseSettingsDrawer)<StyledSettingsDrawerProps>`
    top: ${(props) => `${props.$top ?? 0}px`};
`

const SettingsTrigger = forwardRef<HTMLButtonElement, { showText: boolean }>(({ showText, ...delegated }, ref) => {
    return (
        <button type="button" ref={ref} {...delegated} className="sidebar-menu__navigation-link">
            <Settings className="sidebar-menu__navigation-icon" />
            <span className="sidebar-menu__navigation-label md:sr-only">{'Settings'}</span>
        </button>
    )
})

type VisibleFeatureBucket = FeatureBucket & { isVisible: boolean }

export function SettingsDrawer({ showText, top }: { showText: boolean; top: number }) {
    const updateToggle = useUpdateToggle()
    const { data } = useFetchFeaturePreviews()

    // Filtering for visible preview features and then omitting the isVisible property since it is not needed after this point
    const featureBucket = {
        ...data,
        features: (data?.features as VisibleFeatureBucket[])
            ?.filter((f) => f.isVisible)
            .map(({ isVisible, ...rest }) => rest),
    }
    const drawerState = useSettingsDrawer()

    return (
        <StyledSettingsDrawer
            $top={top}
            onToggleChange={updateToggle}
            drawerState={drawerState}
            previewFeatures={featureBucket as FeatureBucket}
            trigger={<SettingsTrigger showText={showText} />}
        />
    )
}
