import cx from 'clsx'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import { DALLAS_PUBLISHER_ID, REMOTE_WORK_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { getYearPosition } from 'containers/Main/EmployerBranding/MediaKit/Utils'
import withClassName from 'style/util/withClassName'
import { ShowIndustryAward } from './IndustryAwardView'
import { ShowRegionalAwards } from './RegionalAwardView'
import { ShowTWPUSAAward } from './TWPUSAAwardView'

const VerticleLine = withClassName('border border-grey300 border-r-0 border-t-0 border-b-0', 'p')

const getWidthForAward = ({ CEWonAwards }) => {
    if (CEWonAwards < 3) {
        return 155
    }
    if (CEWonAwards < 5) {
        return 305
    }
    if (CEWonAwards < 7) {
        return 344
    }
    if (CEWonAwards < 9) {
        return 460
    }
    return 500
}

const CultureExcellenceContainer = styled.div.attrs({ className: 'flex justify-center flex-wrap' })`
    max-width: ${(props) => getWidthForAward(props)}px;
`

const getSvgClassNames = (sizeOfAwards) => {
    const className = 'inline-block relative h-32'

    switch (true) {
        case sizeOfAwards === 1:
            return cx(className, 'mb-12 px-0')
        case sizeOfAwards === 2:
            return cx(className, 'px-0')
        case sizeOfAwards >= 3 && sizeOfAwards <= 4:
            return cx(className, 'w-2/5 px-0')
        case sizeOfAwards >= 5 && sizeOfAwards <= 6:
            return cx(className, 'w-1/3 px-0')
        case sizeOfAwards >= 7 && sizeOfAwards <= 8:
            return cx(className, 'w-1/4 px-0')
        case sizeOfAwards >= 9 && sizeOfAwards <= 18:
            return cx(className, 'w-1/5 px-0')
        // no default
    }
}

const ShowCultureExcellenceAwards = ({ ceWonAwards, ceAspiringAwards, className }) => {
    const wonSvgClass = size(ceWonAwards) > 0 && getSvgClassNames(size(ceWonAwards))
    const svgClass = size(ceAspiringAwards) > 0 && getSvgClassNames(size(ceAspiringAwards))

    return (
        <div className={className}>
            {size(ceWonAwards) > 0 && (
                <div className="flex flex-col self-end items-center mt-4">
                    <CultureExcellenceContainer CEWonAwards={size(ceWonAwards)}>
                        {map(ceWonAwards, ({ svgUrl, year, awardName }, index) => (
                            <SvgLoader
                                path={svgUrl}
                                key={index}
                                height="10rem"
                                alt="CE awards logos"
                                className={wonSvgClass}>
                                <SvgProxy
                                    selector="text"
                                    font-family="'Yantramanav', sans-serif"
                                    font-size="46"
                                    font-weight="900"
                                />
                                <SvgProxy selector="text tspan" y={getYearPosition(awardName)}>
                                    {`${year}`}
                                </SvgProxy>
                            </SvgLoader>
                        ))}
                    </CultureExcellenceContainer>
                    <div className="justify-center text-center font-bold mt-2">{'Culture Excellence Award'}</div>
                </div>
            )}
            {size(ceAspiringAwards) > 0 && (
                <div className="flex flex-col self-end text-center mt-4">
                    <CultureExcellenceContainer CEWonAwards={size(ceAspiringAwards)}>
                        {map(ceAspiringAwards, ({ svgUrl, year }, index) => (
                            <SvgLoader
                                path={svgUrl}
                                key={index}
                                height="10rem"
                                alt="CE awards logos"
                                className={svgClass}>
                                <SvgProxy
                                    selector="text"
                                    font-family="'Yantramanav', sans-serif"
                                    font-size="46"
                                    font-weight="900"
                                />
                                <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
                            </SvgLoader>
                        ))}
                    </CultureExcellenceContainer>
                    <div className="justify-center text-center font-bold mt-2 ml-4">{'Not Awarded'}</div>
                </div>
            )}
        </div>
    )
}

const DesktopView = ({
    twpUsaAward,
    regionalAwards,
    ceWonAwards,
    ceAspiringAwards,
    showNationalStandardDetails,
    industryAward,
    showDallasNationalStandardDetails,
}) => {
    const hasCeAwards = size(ceWonAwards) > 0 || size(ceAspiringAwards) > 0
    const isRemoteWork = head(regionalAwards)?.publisherId === REMOTE_WORK_PUBLISHER_ID

    return (
        <div className="flex flex-row justify-center mt-8">
            {size(twpUsaAward) > 0 && (
                <div className="flex flex-col self-end justify-center mt-4">
                    <div className="flex justify-center relative mb-8 mx-3">
                        <ShowTWPUSAAward svgUrl={twpUsaAward.svgUrl} year={twpUsaAward.year} />
                    </div>
                    <div className="justify-center text-center font-bold mt-2 ">{'USA Award'}</div>
                </div>
            )}
            {size(twpUsaAward) > 0 && (size(regionalAwards) > 0 || hasCeAwards) && (
                <VerticleLine className="relative" />
            )}
            {size(regionalAwards) > 0 && (
                <div className="flex flex-col self-end justify-center mt-4">
                    <div className="flex justify-center relative mb-2 mx-7">
                        {map(regionalAwards, (x, index) => (
                            <ShowRegionalAwards
                                index={index}
                                svgUrl={x.svgUrl}
                                publisherId={x.publisherId}
                                year={x.year}
                                isTopWorkplace={x.isTopWorkplace}
                                showNationalStandardDetails={showNationalStandardDetails}
                                isDallasRankedWinner={x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard}
                            />
                        ))}
                    </div>
                    <div className="justify-center text-center font-bold mt-4 ">
                        {showDallasNationalStandardDetails
                            ? 'National Standard Award'
                            : isRemoteWork
                            ? 'Remote Work Award'
                            : 'Regional Award'}
                    </div>
                </div>
            )}
            {size(regionalAwards) > 0 && hasCeAwards && <VerticleLine className="relative" />}
            <ShowCultureExcellenceAwards
                ceWonAwards={ceWonAwards}
                ceAspiringAwards={ceAspiringAwards}
                className="flex justify-center"
            />
            {size(regionalAwards) > 0 && size(industryAward) > 0 && <VerticleLine className="relative" />}
            {size(industryAward) > 0 && (
                <div className="flex flex-col self-end justify-center mt-4">
                    <div className="flex justify-center relative mb-8 mx-3">
                        <ShowIndustryAward
                            svgUrl={industryAward.svgUrl}
                            year={industryAward.year}
                            awardName={industryAward.awardName}
                        />
                    </div>
                    <div className="justify-center text-center font-bold mt-2 ">{'Industry Award'}</div>
                </div>
            )}
        </div>
    )
}
export default DesktopView
