import { Children, cloneElement, createContext, useContext, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import compact from 'lodash/compact'
import find from 'lodash/find'
import map from 'lodash/map'
import noop from 'lodash/noop'
import { matchPath, Switch, withRouter } from 'react-router-dom'
import { useNavigation } from 'components/Navigation'
import { useVisibility } from 'hooks'
import { hasAuthorization } from 'util/auth'
import chain from 'util/chainFunctions'
import safeInvoke from 'util/safeInvoke'
import { useIdentity } from '../Identity'
import TopNav from './TopNav'
import TopNavLink from './TopNavLink'
import TopNavRoute from './TopNavRoute'

const getPath = (path, withParams) => safeInvoke(path, withParams)

function createSubItem(child) {
    const { path, title, render, component, ...props } = child.props
    return { to: getPath(path, true), label: title, ...props }
}

const TopNavContext = createContext({ show: noop, hide: noop })

export const useHideTopNav = () => {
    const { hide, show } = useContext(TopNavContext)

    useEffect(() => {
        hide()
        return () => {
            show()
        }
    }, [hide, show])
}

const TopNavLayout = ({
    children = [],
    className,
    title,
    displayName = title,
    sequential = false,
    backPath,
    match,
    primaryAction,
    showBackArrow,
}) => {
    const { items, addItems } = useNavigation(match.url)
    const visibility = useVisibility(true)
    const { visible } = visibility
    const identity = useIdentity()

    /* eslint-disable @energage/energage/prefer-lodash-method */
    const subItems = Children.toArray(children)
        /*
             Removes items from top nav layout like <Redirect/> and such
             todo(cm): figure out how to get rid of this necessity
        */
        .filter((child) => child.type === TopNavRoute)
        .filter((child) => hasAuthorization(identity, child.props.authorize))
        .map(createSubItem)
    /* eslint-enable */

    useLayoutEffect(() => {
        if (!backPath && !items) {
            addItems(match.url, displayName, subItems)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.url, displayName])

    const activeItem = find(subItems, (navItem) =>
        matchPath(window.location.pathname, { path: navItem.to, exact: true })
    )

    return (
        <TopNavContext.Provider value={visibility}>
            <TopNav
                className={className}
                title={title}
                primaryAction={primaryAction}
                showBackArrow={showBackArrow}
                ariaLabel="Navigation"
                activeLabel={
                    <TopNavLink
                        as="span"
                        className="flex items-center"
                        icon={activeItem?.icon}
                        label={activeItem?.label}
                        status={activeItem?.status}
                    />
                }
                navigation={
                    visible
                        ? ({ linkClassName, activeLinkClassName, close }) =>
                              map(subItems, ({ to, ...linkProps }, index) => (
                                  <TopNavLink
                                      {...linkProps}
                                      key={to}
                                      to={to}
                                      exact
                                      className={linkClassName}
                                      activeClassName={`text-purple700 ${activeLinkClassName}`}
                                      sequential={sequential && index > 0}
                                      onClick={chain(close, linkProps.onClick)}
                                  />
                              ))
                        : null
                }>
                <Switch>
                    {/* eslint-disable-next-line @energage/energage/prefer-lodash-method */}
                    {Children.map(compact(children), (child) =>
                        cloneElement(child, {
                            path: getPath(child.props.path, false),
                        })
                    )}
                </Switch>
            </TopNav>
        </TopNavContext.Provider>
    )
}

TopNavLayout.propTypes = {
    title: PropTypes.node,
    sequential: PropTypes.bool,
    backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    primaryAction: PropTypes.node,
}

export default withRouter(TopNavLayout)
