import find from 'lodash/find'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import { EMPTY_GUID } from 'constants/emptyGuid'
import generateObject from 'util/generateObject'
import { DEPARTMENT_ATTRIBUTES } from '../util'

function getDefaultDepartment(departmentTag, organization) {
    return {
        energageTagOption: null,
        id: EMPTY_GUID,
        lastModifiedDate: null,
        modifiedBy: null,
        modifiedDateTime: null,
        parentTagOptionId: null,
        tagId: departmentTag?.id,
        value: organization?.name,
    }
}

function buildDepartmentHierarchy(departmentTag, members, organization) {
    if (members.length === 0) {
        return []
    }

    let membersByDepartmentId = groupBy(members, (member) =>
        get(
            find(member.tagValues, (x) => x.tagId === departmentTag?.id),
            'tagOptionId',
            null
        )
    )
    const membersWithOutDepartment = membersByDepartmentId['null']

    let departments = departmentTag?.options

    if (membersWithOutDepartment?.length > 0) {
        const departmentForEmployeesWithNoDepartment = getDefaultDepartment(departmentTag, organization)
        departments = [departmentForEmployeesWithNoDepartment].concat(departments)

        membersByDepartmentId = {
            [departmentForEmployeesWithNoDepartment.id]: membersWithOutDepartment,
            ...membersByDepartmentId,
        }
    }

    const departmentsByParent = groupBy(departments, 'parentTagOptionId')

    const getChildDepartments = (parentId) =>
        map(departmentsByParent[parentId], (department) =>
            generateObject(DEPARTMENT_ATTRIBUTES, {
                ...department,
                departmentTag,
            })
        )

    const addChildDepartments = (department) => ({
        ...department,
        children: map(getChildDepartments(department.id), addChildDepartments),
        members: membersByDepartmentId[department.id],
    })

    const calculateDepartmentEmployeeCount = (departments) =>
        reduce(
            departments,
            (count, d) => {
                d.employeeCount = size(d.members) + calculateDepartmentEmployeeCount(d.children)
                return count + d.employeeCount
            },
            0
        )

    const hierarchy = map(getChildDepartments('null'), addChildDepartments)
    calculateDepartmentEmployeeCount(hierarchy)

    return hierarchy
}

export default buildDepartmentHierarchy
