import format from 'date-fns/format'
import find from 'lodash/find'
import mapKeys from 'lodash/mapKeys'
import pick from 'lodash/pick'
import CenterAlignedBadge from '../RevampWidgets/Tile/Badges/CenterAligned'
import LeftAlignedBadge from '../RevampWidgets/Tile/Badges/LeftAligned'
import RegularBadge from '../RevampWidgets/Tile/Badges/Regular'

const DATE_FORMAT = 'MMMM yyyy'

const getRecordNameById = (items, id) => find(items, (x) => x.id === +id)?.name

export const getSelectedFiltersRecord = (surveyEvent, parsedQuery) => {
    const { departmentId, locationId, jobRoleId } = parsedQuery
    return [
        format(new Date(surveyEvent.surveyEventClosedDate), DATE_FORMAT),
        getRecordNameById(surveyEvent.departments, departmentId),
        getRecordNameById(surveyEvent.locations, locationId),
        getRecordNameById(surveyEvent.jobRoles, jobRoleId),
    ]
}

export const getFilterQueryParams = (parsedQuery) => {
    const query = []
    mapKeys(pick(parsedQuery, ['locationId', 'departmentId', 'jobRoleId']), (value, key) => {
        return value ? query.push(`${key}=${value}`) : null
    })
    return query.join('&') || true
}

const badgeTypes = {
    regular: 'regular',
    left: 'left',
    center: 'center',
}

const badges = {
    [badgeTypes.regular]: RegularBadge,
    [badgeTypes.left]: LeftAlignedBadge,
    [badgeTypes.center]: CenterAlignedBadge,
}

const customLists = [
    {
        //Boston
        publisherId: 2,
        badgeType: badgeTypes.center,
        logoClassName: 'py-4',
        companyClassName: 'text-4xl',
    },
    {
        //Dallas
        publisherId: 7,
        badgeType: badgeTypes.center,
        logoClassName: 'py-4 px-8',
    },
    {
        //Minneapolis
        publisherId: 13,
        badgeType: badgeTypes.regular,
    },
]

export const getBadge = (awardLogoUrl, publisherId, isNationalStandard) => {
    const list = find(customLists, (l) => publisherId !== null && l.publisherId === +publisherId && !isNationalStandard)
    const badgeType = awardLogoUrl ? list?.badgeType || badgeTypes.regular : badgeTypes.left

    return {
        Component: badges[badgeType],
        logoClassName: list?.logoClassName,
        companyClassName: list?.companyClassName,
        applyDynamicHeight: badgeType === badgeTypes.regular,
    }
}
