import { Sidebar as SidebarBase } from '@energage/components'
import { useMediaQueries } from '@energage/hooks'
import {
    AccountBox,
    AssignmentTurnedIn,
    Dashboard,
    Domain,
    EmojiEvents,
    Icon,
    Insights,
    Pulse,
    StarBorder,
} from '@energage/icons'
import { zIndex } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import styled from 'styled-components'
import { useIdentity, usePermissions } from 'components/Identity'
import { useNavigation } from 'components/Navigation'
import { useFetchFeaturePreviews } from 'components/Sidebar/SettingsDrawer/useFetchSettings'
import getAccountNavItems from 'components/StatusBar/UserMenu/getNavItems'
import config from 'config'
import { azureFeatureFlags } from 'constants/organizationFeatures'
import { routes } from 'constants/routes'
import { EMPLOYER_BRANDING_DISPLAY_NAME } from 'constants/strings'
import { useButterBarHeight } from 'hooks'
import { ReactComponent as TwpIcon } from 'images/twp.svg'
import { media, viewports } from 'style/breakpoints'
import NavigationLink from './NavigationLink'
import ResponsiveSidebar, { SubMenuContextConsumer } from './ResponsiveSidebar'
import { SettingsDrawer } from './SettingsDrawer'
import SupportInfo from './SupportInfo'

const Root = styled.div`
    .sidebar-menu {
        top: ${(props) => props.$top}px;
        transition: width 500ms, top 250ms;
        z-index: ${+zIndex('navigation') + 1};

        ${media.smMin`
            transition: width 150ms, top 250ms;
        `}
        ${media.mdMin`
            transition: top 250ms;
        `}

        .fixed-header {
            top: ${(props) => props.top}px;
            transition: top 250ms;
        }
`

const createClickHandler = (open, path, onLinkClick, onToggle, forceOpenSubMenu = false) =>
    open ? (onLinkClick ? () => onLinkClick(path, forceOpenSubMenu) : onToggle) : null

const getVisibleNavItems = (navItems) => {
    const allItems = flatten(map(navItems, (x) => (x.hidden ? [] : x.items)))
    return filter(allItems, (x) => !x.hidden)
}

const MobileStatusBar = ({ visible, isTopLevelUser, open, onLinkClick, onToggle, identity }) => {
    const { addItems } = useNavigation()

    if (!identity) {
        return
    }

    const navItems = getVisibleNavItems(getAccountNavItems(identity))

    const items = map(navItems, (x) => ({
        label: x.label,
        to: x.props.to || x.props.href || '/',
        appliesAdditionalProps: x.appliesAdditionalProps,
        onClick: x.props.onSelect,
        props: x.props,
    }))

    const handleAccountClick = () => {
        addItems(routes.fakeProfile(), 'Profile', items)
        createClickHandler(open, null, onLinkClick, onToggle, true)
    }

    return (
        <div
            className={cx('pt-3 pb-3 flex justify-center gap-x-4 border-b border-grey300 mb-4', !visible && 'hidden', {
                'flex-col items-center gap-y-4': !open,
            })}>
            {isTopLevelUser && (
                <NavigationLink
                    to={routes.manage()}
                    icon={<Domain size={30} />}
                    onClick={createClickHandler(open, routes.manage(), onLinkClick, onToggle)}
                />
            )}
            <NavigationLink to={routes.fakeProfile()} icon={<AccountBox size={30} />} onClick={handleAccountClick} />
        </div>
    )
}

const Sidebar = () => {
    const top = useButterBarHeight()
    const identity = useIdentity()
    const { hasSmartPulse, hasProfile, hasEmployerBranding, hasEmployerRecognition, hasPlatformSurveySetup } =
        usePermissions()
    const { isMobile, isTablet } = useMediaQueries({
        isMobile: { maxWidth: viewports.xsMax },
        isTablet: { minWidth: viewports.smMin, maxWidth: viewports.smMax },
    })

    const { data } = useFetchFeaturePreviews()

    let isParticipationReviewExperienceFeatureActive = false
    if (data) {
        isParticipationReviewExperienceFeatureActive = find(
            data.features,
            (f) => f.name === azureFeatureFlags.ParticipationReviewExperience
        )?.isActive
    }

    // todo(cm): see if the hook can be completely deleted
    // const { companyLogoUrl, isHeaderImageLoading } = useHeaderImage({ identity })
    const isIdentityLoading = identity == null

    return (
        <Root $top={top}>
            <SidebarBase
                renderContainer={(props) => <ResponsiveSidebar {...props} />}
                renderBottomSection={({ open }) => {
                    return (
                        <>
                            <SupportInfo />
                            {identity.isTopLevelUser && <SettingsDrawer showText={open || !isTablet} top={top} />}
                        </>
                    )
                }}
                headerImage=""
                aria-label="Main Navigation">
                {({ linkClassName, activeLinkClassName, open, onToggle }) => {
                    if (isIdentityLoading) {
                        return null
                    }
                    const isTopLevelUser = identity.isTopLevelUser
                    const isEmployerRecognitionAccessible = config.featureFlag.enableNewRoles
                        ? identity.isSurveyEventExists && hasEmployerRecognition
                        : identity.isSurveyEventExists && isTopLevelUser

                    return (
                        <SubMenuContextConsumer>
                            {(onLinkClick) => (
                                <div className="flex flex-col items-center">
                                    <div className="flex-1 w-full">
                                        <MobileStatusBar
                                            identity={identity}
                                            visible={isMobile || isTablet}
                                            isTopLevelUser={isTopLevelUser}
                                            open={open}
                                            onLinkClick={onLinkClick}
                                            onToggle={onToggle}
                                        />
                                        <NavigationLink
                                            className={linkClassName}
                                            activeClassName={activeLinkClassName}
                                            to={routes.dashboard()}
                                            icon={<Dashboard />}
                                            label="Dashboard"
                                            onClick={createClickHandler(
                                                open,
                                                routes.dashboard(),
                                                onLinkClick,
                                                onToggle
                                            )}
                                        />
                                        {isEmployerRecognitionAccessible && (
                                            <NavigationLink
                                                className={linkClassName}
                                                activeClassName={activeLinkClassName}
                                                to={routes.employerRecognition()}
                                                icon={<EmojiEvents />}
                                                label="Employer Recognition"
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.employerRecognition(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                        {hasPlatformSurveySetup && (
                                            <NavigationLink
                                                className={linkClassName}
                                                activeClassName={activeLinkClassName}
                                                to={routes.survey.workplace()}
                                                icon={<AssignmentTurnedIn />}
                                                label="Workplace Survey"
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.survey.workplace(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                        {hasSmartPulse && (
                                            <NavigationLink
                                                className={linkClassName}
                                                activeClassName={activeLinkClassName}
                                                to={routes.survey.pulse()}
                                                icon={<Pulse />}
                                                label="Pulse"
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.survey.pulse(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                        {(identity.permissions.hasInsights ||
                                            (isParticipationReviewExperienceFeatureActive &&
                                                identity.isTopLevelUser)) && (
                                            <NavigationLink
                                                className={linkClassName}
                                                activeClassName={activeLinkClassName}
                                                to={routes.insights()}
                                                icon={<Insights />}
                                                label="Insights"
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.insights(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                        {hasEmployerBranding && (
                                            <NavigationLink
                                                className={linkClassName}
                                                activeClassName={activeLinkClassName}
                                                to={routes.branding()}
                                                icon={<StarBorder />}
                                                label={EMPLOYER_BRANDING_DISPLAY_NAME}
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.branding(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                        {hasProfile && (
                                            <NavigationLink
                                                className={`${linkClassName} nav-top-workplaces-profile`}
                                                activeClassName={activeLinkClassName}
                                                to={routes.profile()}
                                                icon={<Icon as={TwpIcon} />}
                                                label={'Top Workplaces Profile'}
                                                onClick={createClickHandler(
                                                    open,
                                                    routes.profile(),
                                                    onLinkClick,
                                                    onToggle
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </SubMenuContextConsumer>
                    )
                }}
            </SidebarBase>
        </Root>
    )
}

Sidebar.propTypes = {}

export default Sidebar
