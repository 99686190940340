import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { butterBar } from '@energage/components'
import { useMediaQuery } from '@energage/hooks'
import cx from 'clsx'
import ReactModal from 'react-modal'
import Inset from 'components/Inset'
import { POWERED_BY_ENERGAGE_URL } from 'constants/strings'
import { ReactComponent as CompanyLogo } from 'images/company-logo-color.svg'
import { viewports } from 'style/breakpoints'
import DefaultCloseButton from './CloseButton'

const TakeoverModalContext = createContext()

export const TakeoverModalData = () => useContext(TakeoverModalContext)

const TakeoverModal = ({
    children,
    onClose,
    dataId,
    disableClose,
    isOpen,
    customCloseButton,
    customButton: CustomButton,
    onCustomButtonClick,
    disableCustomButton,
    ...props
}) => {
    const [isTwpChevronVisible, setIsTwpChevronVisible] = useState(false)
    const [isBrandingVisible, setIsBrandingVisible] = useState(false)
    const closeBtnSize = useMediaQuery({ minWidth: viewports.smMin }) ? 48 : 24
    const CloseButton = customCloseButton || DefaultCloseButton

    useEffect(() => {
        if (isOpen) {
            butterBar.closeAll()
        }
    }, [isOpen])

    const showBranding = useCallback(() => {
        setIsTwpChevronVisible(true)
        setIsBrandingVisible(true)
    }, [setIsTwpChevronVisible, setIsBrandingVisible])

    const hideBranding = useCallback(() => {
        setIsTwpChevronVisible(false)
        setIsBrandingVisible(false)
    }, [setIsTwpChevronVisible, setIsBrandingVisible])

    return (
        <TakeoverModalContext.Provider
            value={{ setIsTwpChevronVisible, setIsBrandingVisible, showBranding, hideBranding }}>
            <ReactModal
                className="bg-white h-full w-full overflow-auto m-auto bg-grey200"
                onRequestClose={onClose}
                isOpen={isOpen}
                overlayClassName="modal-overlay"
                {...props}>
                <Inset padding className="min-h-screen flex flex-col">
                    <div className="flex justify-between items-center w-full">
                        <img
                            alt="Twp logo"
                            src={require('images/twp-logo.svg').default}
                            className={cx('h-16 sm:h-24', { invisible: !isTwpChevronVisible })}
                        />
                        <div className="m-0 inline-block justify-end">
                            {onCustomButtonClick && (
                                <CustomButton
                                    onClick={onCustomButtonClick}
                                    dataId={dataId}
                                    disabled={disableCustomButton}
                                    size={closeBtnSize}
                                />
                            )}
                            {onClose && (
                                <CloseButton
                                    onClick={onClose}
                                    dataId={dataId}
                                    disabled={disableClose}
                                    size={closeBtnSize}
                                />
                            )}
                        </div>
                    </div>
                    <div className="p-0 md:px-10 xl:px-16 flex flex-col flex-1">{children}</div>
                    {isBrandingVisible && (
                        <div className="text-xs mt-16 self-center sm:self-start">
                            <a className="" href={POWERED_BY_ENERGAGE_URL} target="_blank" rel="noopener noreferrer">
                                <div>
                                    <span>{'Powered by'}</span>
                                </div>
                                <CompanyLogo className="h-10" role="img" alt="" width="100" />
                            </a>
                        </div>
                    )}
                </Inset>
            </ReactModal>
        </TakeoverModalContext.Provider>
    )
}

TakeoverModal.propTypes = {
    onClose: PropTypes.func,
    disableClosed: PropTypes.bool,
    customCloseButton: PropTypes.func,
    children: PropTypes.node,
}

export default TakeoverModal
