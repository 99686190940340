import { useEffect, useState } from 'react'
import { alert, Button, Input, Modal } from '@energage/components'
import { Text } from '@energage/typography'
import { yupResolver } from '@hookform/resolvers/yup'
import { parse } from 'query-string'
import { get, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import { TopNav } from 'components/TopNav'
import copyToClipboard from 'util/copyToClipboard'
import showErrorAlert from 'util/showErrorAlert'
import { BreadcrumbsContainer } from './Breadcrumbs'
import { DOWNLOAD_EXPIRATION_MINUTES, MAX_SIZE, PAGE_SIZE, ROOT_PATH, SHARELINK_EXPIRATION_MINUTES } from './constants'
import { createFolderValidationSchema } from './CreateFolderValidationSchema'
import { DocumentsTable } from './DocumentsTable'
import type { CreateFolderRequest, DeleteFolderRequest, FileItem, ShareableLinkRequest } from './models'
import {
    useCreateNewFolder,
    useDeleteFolder,
    useGetDocuments,
    useGetShareableLink,
    useUploadDocument,
} from './useDocumentQueries'
import { cleanCompanyFromPath, convertParameterToString, formatBytes, getFormatterLocaleDate } from './util'

const StyledModal = styled(Modal)`
    .reakit-modal__divider {
        border-bottom-width: 2px;
    }
`

export const Documents = () => {
    const { surveyCompanyId, surveyUserId } = useIdentity()
    const [currentPath, setCurrentPath] = useState(`${surveyCompanyId}/${ROOT_PATH}`)
    const [pathParameter, setPathParameter] = useState<string>(cleanCompanyFromPath(currentPath))
    const [continuationToken, setContinuationToken] = useState('')
    const {
        data,
        isLoading,
        isSuccess,
        isError: isErrorGettingDocuments,
    } = useGetDocuments(surveyCompanyId, pathParameter, continuationToken, PAGE_SIZE)
    const { mutateCreateFolder: createNewFolder, isLoading: isCreatingNewFolder } = useCreateNewFolder(surveyCompanyId)
    const { mutateGetShareableLink: getShareableLink, isLoading: isGettingShareableLink } =
        useGetShareableLink(surveyCompanyId)
    const { mutateDeleteDocument: deleteFolder, isLoading: isDeletingFolder } = useDeleteFolder(surveyCompanyId)
    const { mutateUploadDocument: uploadDocument } = useUploadDocument(surveyCompanyId)

    const [files, setFiles] = useState<FileItem[]>([])

    const [path, setPath] = useState<string[]>([])
    const createFolderModalState = useDialogState()
    const deleteModalState = useDialogState()
    const [folderToDelete, setFolderToDelete] = useState('')
    const { search } = useLocation()
    const queryParams = parse(search)
    const history = useHistory()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<CreateFolderRequest>({
        defaultValues: {
            folderName: '',
        },
        mode: 'all',
        delayError: 500,
        resolver: yupResolver(createFolderValidationSchema),
    })

    if (isErrorGettingDocuments) {
        showErrorAlert('There was an error retrieving the company documents')
    }

    useEffect(() => {
        if (isSuccess) {
            const files = data.items.map(
                (item: {
                    name: string
                    type: number
                    modifiedDate: string
                    sizeInBytes: number
                    accessTier: string
                }) => ({
                    id: item.name,
                    name: item.name.replace(currentPath, '').replace(/\/$/, ''),
                    modified: item.type === 1 ? getFormatterLocaleDate(item.modifiedDate) : '',
                    type: item.type === 0 ? 'folder' : ('file' as 'file' | 'folder'),
                    contents: item.type === 1 ? formatBytes(item.sizeInBytes) : '',
                    accessTier: item.accessTier,
                })
            )

            setFiles((prevFiles) => [...prevFiles, ...files])
            setContinuationToken(data.continuationToken)
        }
    }, [isSuccess, data, currentPath])

    useEffect(() => {
        const urlPath = getPathFromUrlParameter(queryParams.folder)
        if (urlPath !== ROOT_PATH) {
            setPath(getBreadcrumbPath(urlPath))
            setCurrentPath(`${surveyCompanyId}/${ROOT_PATH}${urlPath}`)
            setPathParameter(`${ROOT_PATH}${urlPath}`)
        } else {
            setPathParameter(ROOT_PATH)
        }
    }, [pathParameter, queryParams.folder, surveyCompanyId])

    const handleNavigate = (id: string) => {
        setFiles([])
        setPath([...path, id.replace(currentPath, '').replace(/\/$/, '')])
        setCurrentPath(id)
        setPathParameter(cleanCompanyFromPath(id))
        const urlPath = cleanCompanyFromPath(id).replace(ROOT_PATH, '')
        history.push(`/documents?folder=${encodeURIComponent(urlPath)}`)
    }

    const handleGetShareableLink = async (id: string) => {
        const shareableLinkRequest: ShareableLinkRequest = {
            Path: cleanCompanyFromPath(id),
            Expiration: SHARELINK_EXPIRATION_MINUTES,
        }
        const response = await getShareableLink(shareableLinkRequest)

        if (response) {
            copyToClipboard(response.url)
            alert.success('Your link was successfully copied.')
        }
    }

    const handleDownload = async (id: string) => {
        const shareableLinkRequest: ShareableLinkRequest = {
            Path: cleanCompanyFromPath(id),
            Expiration: DOWNLOAD_EXPIRATION_MINUTES,
        }
        const response = await getShareableLink(shareableLinkRequest)
        if (response && response.url) {
            const fileName = getFileName(id)
            await handleDownloadBlobFile(response.url, fileName)
        }
    }

    const handleOpenModalDelete = (id: string) => {
        setFolderToDelete(id)
        deleteModalState.show()
    }

    const handleDelete = async () => {
        if (folderToDelete) {
            const deleteFolderRequest: DeleteFolderRequest = {
                path: cleanCompanyFromPath(folderToDelete),
            }
            await deleteFolder(deleteFolderRequest)
            setFolderToDelete('')
            setFiles([])
            setPathParameter(cleanCompanyFromPath(currentPath))
            deleteModalState.hide()
        }
    }

    const onCancelDelete = () => {
        setFolderToDelete('')
        deleteModalState.hide()
    }

    const handleRootClick = () => {
        setPath([])
        setFiles([])
        setCurrentPath(`${surveyCompanyId}/${ROOT_PATH}`)
        setPathParameter(ROOT_PATH)
        history.push(`/documents?folder=`)
    }

    const handleBreadcrumbClick = (index: number) => {
        const newPath = path.slice(0, index + 1)
        setPath(newPath)

        setFiles([])

        const fullPath = `${surveyCompanyId}/${ROOT_PATH}${newPath.join('/')}/`
        setPathParameter(`${ROOT_PATH}${newPath.join('/')}/`)
        setCurrentPath(fullPath)
        history.push(`/documents?folder=${encodeURIComponent(`${newPath.join('/')}`)}`)
    }

    const uploadFile = async (file: File) => {
        const fileToUploadPath =
            path.length === 0 ? `${ROOT_PATH}${file.name}` : `${ROOT_PATH}${path.join('/')}/${file.name}`

        const uploadResponse = await uploadDocument({
            path: fileToUploadPath,
            surveyUserId,
            file,
        })

        if (uploadResponse && uploadResponse.ok) {
            setFiles([])
        }
    }

    const handleDrop = (acceptedFiles: File[]) => {
        const totalSize = acceptedFiles.reduce((total, file) => total + file.size, 0)

        if (totalSize > MAX_SIZE) {
            showErrorAlert(`The size of your file(s) is greater than the max size allowed`)
            return
        }

        for (const file of acceptedFiles) {
            uploadFile(file)
        }
    }

    const handleOpenModalCreateFolder = async () => {
        createFolderModalState.show()
    }

    const handleCreateFolder = async (data: CreateFolderRequest) => {
        if (data) {
            data.folderName = cleanCompanyFromPath(currentPath) + data.folderName.trim()
            await createNewFolder(data)
            setFiles([])
            setPathParameter(cleanCompanyFromPath(currentPath))
            createFolderModalState.hide()
            reset()
        }
    }

    const onCancelCreateFolder = () => {
        createFolderModalState.hide()
    }

    const getFileName = (filePath: string) => {
        const parts = filePath.split('/')
        const lastPart = parts[parts.length - 2]
        const fileName = parts[parts.length - 1]
        return fileName ? fileName : lastPart
    }

    const handleDownloadBlobFile = async (fileUrl: string, fileName: string) => {
        try {
            const response = await fetch(fileUrl)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
        } catch (error) {}
    }

    const getPathFromUrlParameter = (folderParameter: string | string[] | null) => {
        let urlFolder = convertParameterToString(folderParameter)
        if (urlFolder && urlFolder !== '/') {
            if (!urlFolder.endsWith('/')) {
                urlFolder += '/'
            }
            return urlFolder
        } else {
            return ROOT_PATH
        }
    }

    const getBreadcrumbPath = (urlFolder: string) => {
        return urlFolder
            .split('/')
            .map((segment) => segment.trim())
            .filter((segment) => segment !== '')
    }

    return (
        <>
            <Modal {...deleteModalState} modalAriaLabel="Delete">
                <Modal.Header hasDivider={false} onHide={deleteModalState.hide}>
                    <p className="text-2xl font-bold">{'Are you sure?'}</p>
                </Modal.Header>
                <Modal.Body className="flex flex-col items-start self-stretch">
                    <p className="flex flex-col items-start text-grey900 font-bold text-base self-stretch">
                        {`You’re deleting ${getFileName(folderToDelete)}`}
                    </p>
                    <p className="flex flex-col items-start text-grey900 font-normal text-base self-stretch">
                        {'Deleting files and folders is permanent. All content inside folders will be deleted.'}
                    </p>
                </Modal.Body>
                <Modal.Footer className="h-20">
                    <div>
                        <Button
                            appearance="tertiary"
                            className="mr-3"
                            onPress={() => {
                                onCancelDelete()
                            }}>
                            {'Keep It'}
                        </Button>
                        <Button
                            appearance="destructive"
                            aria-label="Yes, Delete It"
                            className="my-2"
                            isDisabled={isCreatingNewFolder}
                            onPress={() => {
                                handleDelete()
                            }}>
                            {'Yes, Delete It'}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <StyledModal {...createFolderModalState} modalAriaLabel="Create Folder">
                <form
                    data-testid={'documents-create-folder-form'}
                    onSubmit={handleSubmit((data: CreateFolderRequest) => handleCreateFolder(data))}>
                    <Modal.Header hasDivider={true} onHide={createFolderModalState.hide}>
                        <p className="font-medium text-gray-900 text-xl leading-7 not-italic">{'Create Folder'}</p>
                    </Modal.Header>
                    <Modal.Body className="flex h-40 flex-col items-start self-stretch justify-center pt-10">
                        <div className="flex flex-col justify-center items-center flex-auto self-stretch">
                            <p className="font-normal text-base text-grey900 text-xl leading-7 not-italic">
                                {'What is the name of your folder?'}
                                <Input
                                    {...register('folderName')}
                                    placeholder="Folder Name"
                                    maxLength={100}
                                    required
                                    error={!!get(errors, 'folderName')}
                                    info={
                                        errors['folderName']?.message ? (
                                            <p className="input-group__info-text">{errors['folderName']?.message}</p>
                                        ) : null
                                    }
                                    className="items-start block"
                                />
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="h-20">
                        <div>
                            <Button
                                appearance="secondary"
                                className="mr-3"
                                onPress={() => {
                                    onCancelCreateFolder()
                                }}>
                                {'Cancel'}
                            </Button>
                            <Button
                                type="submit"
                                appearance="primary"
                                aria-label="Create Folder"
                                className="my-2"
                                isDisabled={isCreatingNewFolder}>
                                {'Create Folder'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </form>
            </StyledModal>
            <div data-testid="documents-management">
                <TopNav
                    xsHidden
                    title="Publisher Document Manager"
                    showBackArrow={false}
                    arrowBackRoute={undefined}
                    className=""
                    primaryAction={undefined}
                    hideShadow={undefined}>
                    <div className="px-6 pt-8">
                        <BreadcrumbsContainer
                            path={path}
                            onBreadcrumbClick={handleBreadcrumbClick}
                            onRootClick={handleRootClick}
                        />
                        <div className="py-4 flex items-center gap-3">
                            <Button
                                appearance="secondary"
                                aria-label="Create Folder"
                                onPress={() => {
                                    handleOpenModalCreateFolder()
                                }}>
                                {'Create Folder'}
                            </Button>
                            <Text textStyle="subtext" className="pl-3 text-grey600">
                                {'Drag & drop files below to add them to this folder'}
                            </Text>
                        </div>
                        <DocumentsTable
                            files={files}
                            isLoading={isLoading}
                            onNavigate={handleNavigate}
                            onLink={handleGetShareableLink}
                            onDownload={handleDownload}
                            onDelete={handleOpenModalDelete}
                            onDrop={handleDrop}
                            isGettingShareableLink={isGettingShareableLink}
                            isDeletingFolder={isDeletingFolder}
                        />
                    </div>
                </TopNav>
            </div>
        </>
    )
}
