import { Loading as Spinner } from '@energage/components'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import cx from 'clsx'
import { format } from 'date-fns'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import styled, { css } from 'styled-components'
import { routes } from 'constants/routes'
import colors from 'containers/Main/AwardNotification/colors'
import YellowButton from 'containers/Main/AwardNotification/YellowButton'
import { DALLAS_PUBLISHER_ID, MINNEAPOLIS_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { YEAR_FORMAT } from 'util/formatters'
import Section from '../../Section'
import { useFetchAwardNotificationDetails } from '../../useFetchAwardNotificationDetails'
import { StyledCarousel } from './StyledCarousel'

const dallasLogoYearCss = css`
    font-size: 11px;
    margin-left: 95px;
    margin-top: 4px;
    color: #231f20;
`

const yearCss = css`
    font-size: 13px;
    margin-left: 16px;
    margin-top: ${(props) => (props.publisherId === MINNEAPOLIS_PUBLISHER_ID ? '52px' : '25px')};
    color: ${colors.textBlue};
`
const Year = styled.p.attrs({ className: 'absolute font-bold' })`
    ${(props) => (props.publisherId === DALLAS_PUBLISHER_ID ? dallasLogoYearCss : yearCss)}
`

const ShowRegionalAwards = ({ index, svgUrl, year, publisherId }) => (
    <>
        <img
            key={index}
            alt="Regional logos"
            src={svgUrl}
            className={cx('h-1/2', publisherId === DALLAS_PUBLISHER_ID ? 'w-full' : ' w-1/2')}
        />
        <Year publisherId={publisherId}>{year}</Year>
    </>
)

const ThankYouForParticipating = ({
    bgColor,
    textColor,
    companyName,
    isParticipated,
    participatedListName,
    isPdf,
    baseUrl,
    sectionClassName,
}) => {
    const year = format(new Date(), YEAR_FORMAT)
    const { data, isLoading } = useFetchAwardNotificationDetails(year)

    if (isLoading) {
        return <Spinner />
    }

    const {
        regional,
        national: { twpUsa, industry, cultureExcellence },
    } = data?.lastYear
    const regionalAwards = filter(regional, (x) => !isEmpty(x?.svgUrl))
    const totalAwards = regional.concat(twpUsa, industry, cultureExcellence)
    const totalAwardsSize = size(filter(totalAwards, (x) => x !== null))
    const mediaKitUrl = isPdf ? `${baseUrl}branding/media-kit` : routes.branding.mediaKit()

    return (
        <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName}>
            <h1 className="sm:text-5xl text-2xl font-black">
                {'Thank you for participating in the Top Workplaces program'}
            </h1>
            {isParticipated ? (
                <p className="sm:text-2xl text-xl py-4">{`Unfortunately, ${companyName} is not a Top Workplaces winner for ${participatedListName}. The results are based on the employee engagement survey you recently issued.`}</p>
            ) : (
                <p className="sm:text-2xl text-xl py-4">{'You have no award announcements this week.'}</p>
            )}
            {totalAwardsSize > 0 && (
                <>
                    <h1 className="sm:text-5xl text-2xl mt-8 font-black">
                        {'Continue to promote the awards you have earned'}
                    </h1>
                    <p className="sm:text-2xl text-xl py-4">
                        {isParticipated ? `While this isn't news you have hoped, there` : `There`}
                        {` is plenty for ${companyName} to celebrate. Continue to use the awards you have won to showcase what makes your culture so special:`}
                    </p>
                    <div className={cx('mt-4 mb-12 text-center block', !isPdf ? 'sm:hidden' : '')}>
                        <YellowButton href={mediaKitUrl}>{'Go to Awards'}</YellowButton>
                    </div>

                    <div className="hidden sm:block">
                        <StyledCarousel
                            slidesToShow={5}
                            autoGenerateStyleTag={false}
                            heightMode="adaptive"
                            wrapAround
                            renderCenterLeftControls={({ previousSlide, slideCount }) =>
                                slideCount > 5 && (
                                    <KeyboardArrowLeft className="ml-2 -mt-12" size={30} onClick={previousSlide} />
                                )
                            }
                            renderCenterRightControls={({ nextSlide, slideCount }) =>
                                slideCount > 5 && (
                                    <KeyboardArrowRight className="mr-2 -mt-12" size={30} onClick={nextSlide} />
                                )
                            }>
                            {twpUsa && <img alt="twpUsa logo" src={twpUsa?.svgUrl} className="h-40" />}
                            {industry && <img alt="industry logo" src={industry?.svgUrl} />}
                            {size(cultureExcellence) > 0 &&
                                map(cultureExcellence, ({ svgUrl }, index) => (
                                    <img key={index} alt="CE logos" src={svgUrl} />
                                ))}
                            {size(regionalAwards) > 0 &&
                                map(regionalAwards, (x, index) => (
                                    <ShowRegionalAwards
                                        index={index}
                                        svgUrl={x?.svgUrl}
                                        year={x.year}
                                        publisherId={x?.publisherId}
                                    />
                                ))}
                        </StyledCarousel>
                    </div>
                </>
            )}
        </Section>
    )
}

export default ThankYouForParticipating
