import { colors } from '@energage/theme'
import styled from 'styled-components'
import { CultureStatsGraphicType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import topXDark1 from 'images/employerBranding/cultureFacts/topXDark1.svg'
import topXDark2 from 'images/employerBranding/cultureFacts/topXDark2.svg'
import topXLight1 from 'images/employerBranding/cultureFacts/topXLight1.svg'
import topXLight2 from 'images/employerBranding/cultureFacts/topXLight2.svg'
import contrast from 'util/contrast'

interface CultureStatsTopXViewProps {
    className?: string
    topXPercent?: number
    backgroundColor?: string
    selectedTopXDesignType?: number
    topXTextTop: string
    topXLaurelTextTop: string
    topXTextLeft: string
    percentTextFontSize: string
    percentTextSupFontSize: string
    topXImageHeight: string
    topXImageWidth: string
    topXSectionMarginBottom: string
}

interface TopXTextBlockProps {
    selectedTopXDesignType?: number
    topXTextTop: string
    topXLaurelTextTop: string
    topXTextLeft: string
}

const topXTextTop = (selectedTopXDesignType: number, top: string, topLaurel: string) => {
    const isSelectedDesignTypeCircle = selectedTopXDesignType === CultureStatsGraphicType.Circle

    return isSelectedDesignTypeCircle ? top : topLaurel
}

const TopXTextBlock = styled.div<TopXTextBlockProps>`
    position: absolute;
    display: block;
    top: ${(props) => topXTextTop(props.selectedTopXDesignType ?? 1, props.topXTextTop, props.topXLaurelTextTop)};
    left: ${(props) => props.topXTextLeft ?? '67px'};
    transform: translate(-50%, -50%);
`

interface PercentTextBlockProps {
    backgroundColor?: string
    percentTextFontSize?: string
    percentTextSupFontSize?: string
}

const PercentTextBlock = styled.span.attrs({ className: 'font-bold' })<PercentTextBlockProps>`
    color: ${(props) => (contrast(props.backgroundColor).isLight ? colors.black : colors.white)};
    display: block;
    font-size: ${(props) => props.percentTextFontSize ?? '40px'};
    font-family: 'Yantramanav', 'system-ui';

    sup {
        font-size: ${(props) => props.percentTextSupFontSize ?? '16px'};
        top: -0.9em;
    }
`

interface TopXImageSectionProps {
    imageHeight?: string
    imageWidth?: string
    marginBottom?: string
}

const TopXImageSection = styled.div<TopXImageSectionProps>`
    width: ${(props) => props.imageWidth ?? '125px'};
    height: ${(props) => props.imageHeight ?? '120px'};
    ${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : '')}
`

interface TopXImageProps {
    backgroundColor?: string
    selectedTopXDesignType?: number
    imageWidth?: string
}

const TopXImage = ({ backgroundColor, selectedTopXDesignType, imageWidth }: TopXImageProps) => {
    const isSelectedDesignTypeCircle = selectedTopXDesignType === CultureStatsGraphicType.Circle

    if (contrast(backgroundColor).isLight) {
        return (
            <img
                alt="Top X dark"
                src={isSelectedDesignTypeCircle ? topXDark2 : topXDark1}
                width={imageWidth ?? '125px'}
                className="self-center sm:self-start"
            />
        )
    }

    return (
        <img
            alt="Top X light"
            src={isSelectedDesignTypeCircle ? topXLight2 : topXLight1}
            width={imageWidth ?? '125px'}
            className="self-center sm:self-start"
        />
    )
}

export const CultureStatsTopXView = ({
    className,
    topXPercent,
    backgroundColor,
    selectedTopXDesignType,
    topXTextTop,
    topXLaurelTextTop,
    topXTextLeft,
    percentTextFontSize,
    percentTextSupFontSize,
    topXImageHeight,
    topXImageWidth,
    topXSectionMarginBottom,
}: CultureStatsTopXViewProps) => {
    return (
        <TopXImageSection
            className={className}
            imageHeight={topXImageHeight}
            imageWidth={topXImageWidth}
            marginBottom={topXSectionMarginBottom}>
            <TopXImage
                backgroundColor={backgroundColor}
                imageWidth={topXImageWidth}
                selectedTopXDesignType={selectedTopXDesignType}
            />
            <TopXTextBlock
                selectedTopXDesignType={selectedTopXDesignType}
                topXLaurelTextTop={topXLaurelTextTop}
                topXTextTop={topXTextTop}
                topXTextLeft={topXTextLeft}>
                <PercentTextBlock
                    backgroundColor={backgroundColor}
                    percentTextFontSize={percentTextFontSize}
                    percentTextSupFontSize={percentTextSupFontSize}>
                    {topXPercent}
                    <sup>{'%'}</sup>
                </PercentTextBlock>
            </TopXTextBlock>
        </TopXImageSection>
    )
}
