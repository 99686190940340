import { useCallback, useMemo, useState } from 'react'
import config from 'config'
import { useFetch, usePost, usePostWithTaskCompletion } from 'hooks'

function useSurveyEventSetup(surveyEventId, organizationId) {
    const [surveyEventSetup, setSurveyEventSetup] = useState(null)

    const onComplete = (data) => {
        let smsText = data?.smsText ?? ''
        setSurveyEventSetup({
            ...data,
            smsText,
            skipEmailForSmsUsers: data?.skipEmailForSmsUsers ?? false,
        })
    }

    const { doPost: createSurveyEventSetup } = usePost({
        url: `${config.api.platform}/SurveyEventSetup`,
        onComplete,
    })

    const { doFetch } = useFetch({
        url: `${config.api.platform}/SurveyEventSetup/SurveyEvent/${surveyEventId}`,
        onComplete,
        onError(x) {
            if (x[0]?.code === 'ContentNotFound') {
                createSurveyEventSetup({ surveyEventId: surveyEventId, organizationId })
            }
        },
    })

    const { doPost, reset, isPosting } = usePostWithTaskCompletion({
        url: `${config.api.platform}/SurveyEventSetup`,
        onComplete,
    })

    const saveSurveyEventSetup = useCallback(
        (data, onSuccess, onError) => {
            doPost(data)
                .then(onSuccess, (error) => {
                    onError(error)
                    reset()
                })
                .then(doFetch, doFetch)
        },
        [doPost, doFetch, reset]
    )

    return useMemo(
        () => ({
            surveyEventSetup,
            saveSurveyEventSetup,
            setSurveyEventSetup,
            updatingSurveyEventSetup: isPosting,
        }),
        [surveyEventSetup, saveSurveyEventSetup, setSurveyEventSetup, isPosting]
    )
}

export default useSurveyEventSetup
