import { Input, InputGroup, Select } from '@energage/components'
import { Controller, useFormContext } from 'react-hook-form'
import { StateObjects } from 'constants/states'

const PaperContactInfo = ({ disabled }) => {
    const { register, control } = useFormContext()

    return (
        <div className="w-full lg:w-2/3">
            <p className="pb-4 text-sm text-grey500">{'Who will receive the surveys?'}</p>
            <Input
                required
                label={'First name'}
                disabled={disabled}
                className="w-1/2 inline-block text-sm pr-3"
                type="text"
                {...register('contact.firstName')}
            />
            <Input
                required
                label={'Last name'}
                disabled={disabled}
                className="sm:w-1/2 max-w-sm inline-block text-sm"
                {...register('contact.lastName')}
                type="text"
            />
            <Input
                required
                label={'Phone'}
                disabled={disabled}
                className="w-1/2 inline-block text-sm pr-3"
                {...register('contact.phoneNumber')}
                type="text"
            />
            <Input
                required
                label={'Email'}
                disabled={disabled}
                className="sm:w-1/2 max-w-sm inline-block text-sm"
                {...register('contact.email')}
                type="text"
            />
            <Input
                required
                label={'Address'}
                disabled={disabled}
                className="w-full inline-block text-sm"
                {...register('contact.address.street')}
                type="text"
            />
            <div className="flex">
                <Input
                    required
                    label={'City'}
                    disabled={disabled}
                    className="sm:w-1/2 max-w-sm text-sm pr-3"
                    {...register('contact.address.city')}
                    type="text"
                />
                <Controller
                    name="contact.address.state"
                    control={control}
                    render={({ field }) => (
                        <InputGroup
                            required
                            label={'State'}
                            disabled={disabled}
                            className="w-1/4 text-sm pr-3"
                            autoComplete="off"
                            options={StateObjects}
                            as={Select}
                            onChange={(selected) => field.onChange(selected)}
                        />
                    )}
                />
                <Input
                    required
                    label={'Zip code'}
                    disabled={disabled}
                    className="sm:w-1/4 max-w-sm  text-sm"
                    type="text"
                    {...register('contact.address.zipCode')}
                />
            </div>
        </div>
    )
}

export default PaperContactInfo
