import styled from 'styled-components'
import Inset from 'components/Inset'
import { media } from 'style/breakpoints'
import { AwardHistoryTable } from './AwardHistoryTable'

const Container = styled.div`
    && {
        margin-left: 0;
        margin-right: 0;

        ${media.xlMin`
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
        `}
    }
`

export const AwardHistory = () => (
    <Inset padding className="pl-0 pr-0">
        <Container>
            <div className="w-full md:w-3/4 lg:w-2/3 mb-6 ml-2 sm:ml-0">
                <h3>{'Participation History'}</h3>
            </div>
            <p className="hidden sm:block w-full sm:3/4 md:w-2/3 text-sm mb-8">
                {'A list of all surveys that are linked to Top Workplaces Award participation.'}
            </p>
            <AwardHistoryTable />
        </Container>
    </Inset>
)
