import { useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import styled from 'styled-components'
import type {
    CarouselControlProps,
    CultureCloudPreviewProps,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureCloudConfiguration/CultureCloud.types'
import CultureCloud from 'containers/Public/RevampWidgets/CultureCloud'
import { CultureCloudWordPreview } from 'containers/Public/RevampWidgets/CultureCloudWordPreview'
import { monthYearDate } from 'util/formatters'
import { StyledCarousel } from './StyledCarousel'

const Button = styled.button`
    border: 3px solid;

    &:focus:not(:focus-visible) {
        outline: none;
    }
`
const renderLeftControls = ({ previousSlide, slideCount }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="rounded-full" aria-label="Previous Preview Image" onClick={previousSlide}>
                <KeyboardArrowLeft size={25} />
            </Button>
        )
    )
}

const renderRightControls = ({ nextSlide, slideCount }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="rounded-full" aria-label="Next Preview Image" onClick={nextSlide}>
                <KeyboardArrowRight size={25} />
            </Button>
        )
    )
}

const renderBottomCenterControls = ({ currentSlide, slideCount }: CarouselControlProps) =>
    slideCount > 1 && `${currentSlide + 1} of ${slideCount}`

export const CultureCloudSharePreview = ({
    data,
    ariaTitle,
    ariaDescription,
    dataId,
    isExpiredWidget,
    filterData,
    hasEmployerBrandingSubscription,
    minDroplets,
}: CultureCloudPreviewProps) => {
    const department = filterData?.Departments ? `${filterData?.Departments},` : ''
    const location = filterData?.Locations ? `${filterData?.Locations},` : ''
    const jobRole = filterData?.JobRoles ? `${filterData?.JobRoles},` : ''

    useEffect(() => {
        const sliderDivElements = document.querySelector('.cultureCloud .slider-frame')
        if (sliderDivElements) {
            sliderDivElements.setAttribute('aria-hidden', 'true')
        }
    }, [])

    return (
        //@ts-ignore
        <StyledCarousel
            heightMode="current"
            width="450px"
            height="auto"
            className="cultureCloud"
            autoGenerateStyleTag={false}
            wrapAround
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderAnnounceSlideMessage={({ currentSlide }) =>
                `${currentSlide + 1 === 2 ? `${data.title}, Verified by Top Workplaces. ` : ''}${monthYearDate(
                    filterData?.surveyEventName
                )}, ${department} ${location} ${jobRole} Culture Cloud, image`
            }
            renderBottomLeftControls={renderLeftControls}
            renderBottomRightControls={renderRightControls}
            renderBottomCenterControls={renderBottomCenterControls}>
            <CultureCloudWordPreview
                ariaTitle={ariaTitle}
                ariaDescription={ariaDescription}
                droplets={data?.droplets}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                className="w-12 mt-2 mr-2"
                minDroplets={minDroplets}
                backgroundColor={data?.backgroundColor}
                width="449px"
                cloudChartWidth={449}
                cloudChartHeight={305}
                isShare
            />
            <CultureCloud
                //@ts-ignore
                ariaTitle={ariaTitle}
                ariaDescription={ariaDescription}
                width="449px"
                cloudChartWidth={449}
                cloudChartHeight={270}
                dataId={dataId}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                minDroplets={minDroplets}
                {...data}
            />
        </StyledCarousel>
    )
}
