import PropTypes from 'prop-types'
import { Input, Textarea } from '@energage/components'
import { useAtom } from 'jotai'
import styled from 'styled-components'
import { Accordion } from 'components/Accordion'
import { descriptionAtom, surveyHeadlineAtom } from './statementSetupUtility/store'

const StyledTextArea = styled(Textarea).attrs({ className: 'mt-5 w-full md:w-11/12 lg:w-1/3' })`
    textarea {
        height: 10rem;
    }
`

function SurveyIntroduction({ isFullSurvey, disabled, onChange }) {
    const text =
        'The survey introduction is the first thing an employee sees when taking the survey. We recommend encouraging open and honest feedback.' +
        (isFullSurvey
            ? ' Top Workplace surveys are monitored to ensure responders do not feel pressure to respond positively.'
            : '')

    const maxLength = 500
    const [headline, setHeadline] = useAtom(surveyHeadlineAtom)
    const [description, setDescription] = useAtom(descriptionAtom)

    return (
        <Accordion
            testId="survey-setup-introduction-section"
            className="pl-6"
            title={<h5>{'Survey Introduction'}</h5>}
            text={null}
            key="survey-introduction">
            <p className="max-w-md paragraph pb-4">{text}</p>
            <Input
                className="w-full sm:w-3/4 lg:w-1/3"
                label="Survey Greeting"
                value={headline}
                maxLength={maxLength}
                disabled={disabled}
                onChange={({ target: { value } }) => {
                    setHeadline(value)
                    onChange()
                }}
                error={!disabled && !headline}
                info={!disabled && !headline ? 'Required' : null}
            />

            <StyledTextArea
                label="Survey Description"
                value={description}
                disabled={disabled}
                maxLength={maxLength}
                onChange={({ target: { value } }) => {
                    setDescription(value)
                    onChange()
                }}
            />

            <p className="w-full md:w-11/12 lg:w-1/3 text-right text-xs text-grey500">
                {description.length}
                {'/'}
                {maxLength}
            </p>
        </Accordion>
    )
}

SurveyIntroduction.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    isFullSurvey: PropTypes.bool,
}

export default SurveyIntroduction
