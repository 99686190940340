import { useMemo } from 'react'
import type { ReactNode } from 'react'
import { AddCircle, Check, EmojiEvents, Warning } from '@energage/icons'
// TODO: Fix the module to correctly export @energage/icons/filled
// @ts-ignore - Ignored to be able to use icon filled version of Info
import { Info } from '@energage/icons/filled'
import { colors } from '@energage/theme'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SwimLaneContainer from 'components/SwimLane'
import { routes } from 'constants/routes'
import { getDifferenceInDays } from 'containers/Main/EmployerRecognition/Overview/Timeline/TimelineCardConfig/fragments'
import { MissedParticipationText } from 'containers/Main/EmployerRecognition/Overview/YourAwards/MissedParticipationComponent'
import { StateDefinition, useFetchYourAwards } from './useFetchYourAwards'
import type { AwardStatus } from './useFetchYourAwards'

const AwardTimelineCardContainer = styled.div.attrs({
    className: 'flex flex-col flex-start bg-white shadow-md rounded-lg p-6 gap-4',
})`
    width: 424px;
    height: 160px;
`

const StyledTitle = styled.p.attrs({ className: 'h5 w-full' })`
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
`

const StyledCheck = styled(Check).attrs<{ className?: string }>({ className: 'rounded-full' })`
    padding: 6px;
`

const AwardTimelineCard = ({ route, children }: { route: string; children: ReactNode }) => {
    return <AwardTimelineCardLink route={route}>{children}</AwardTimelineCardLink>
}

const SnapAwardTimelineCard = styled(AwardTimelineCard)`
    scroll-snap-align: start;
`

const AwardTimeLineStatusIcon = ({ type }: { type: StateDefinition }) => {
    const getIcon = (type: StateDefinition) => {
        switch (type) {
            case StateDefinition.GoodForNow:
                return <StyledCheck color={colors.white} size={48} className="bg-grey500" />
            case StateDefinition.Warning:
                return <Warning fill={colors.yellow700} size={48} />
            case StateDefinition.Danger:
                return <Warning fill={colors.red500} size={48} />
            case StateDefinition.Incomplete:
                return <Info fill={colors.blue500} size={48} />
            case StateDefinition.Complete:
                return <StyledCheck color={colors.white} size={48} className="bg-purple300" />
            case StateDefinition.Help:
                return <Info fill={colors.blue500} size={48} />
            default:
                return <Info fill={colors.blue500} size={48} />
        }
    }
    return <div>{getIcon(type)}</div>
}

const AwardTimelineCardLink = ({ route, children }: { route: string; children: ReactNode }) => (
    <div>
        <Link to={route}>
            <AwardTimelineCardContainer>{children}</AwardTimelineCardContainer>
        </Link>
    </div>
)

const getSurveyStateTextDetails = (
    daysLeft: number,
    recentSurveyEventId: number | null,
    isSurveyConnected: boolean
) => {
    const textColor = daysLeft <= 14 ? 'text-red500' : 'text-yellow700'
    const daysLeftText = daysLeft === 0 ? 'TODAY' : `${daysLeft} ${pluralize('day', daysLeft)}`
    const iconState = daysLeft <= 14 ? StateDefinition.Danger : StateDefinition.Warning

    const disconnectedCommonSubtext = (
        <p>
            {`Please ${
                recentSurveyEventId
                    ? 'add this award to your existing workplace survey'
                    : 'select a workplace survey to launch your survey'
            }`}
        </p>
    )

    if (daysLeft < 0) {
        return {
            iconState: iconState,
            title: <p className="paragraph-bold">{'You have missed the survey launch deadline'}</p>,
            subtext: <p>{'Please contact support for more information'}</p>,
        }
    }
    if (daysLeft <= 30) {
        return {
            iconState: iconState,
            title: (
                <p className="paragraph-bold">
                    {`Your survey deadline is ${daysLeft === 0 ? '' : 'in'} `}
                    <span className={textColor}>{daysLeftText}</span>
                </p>
            ),
            subtext: isSurveyConnected ? (
                <p>{'Please complete your remaining survey setup tasks'}</p>
            ) : (
                disconnectedCommonSubtext
            ),
        }
    }

    return {
        iconState: StateDefinition.Help,
        title: (
            <p className="paragraph-bold">
                {isSurveyConnected ? 'Survey setup incomplete' : 'You need a survey to participate'}
            </p>
        ),
        subtext: isSurveyConnected ? (
            <p>{'Get ahead by finishing your survey setup now.'}</p>
        ) : (
            disconnectedCommonSubtext
        ),
    }
}

const getAwardTimelineStatusContent = (awardStatus: AwardStatus) => {
    const {
        awardListParticipantId,
        awardNameAlias,
        surveyStateDefinitionId,
        surveyScheduleDeadline,
        registrationStateDefinitionId,
        registrationDeadline,
        profileDeadline,
        profileStateDefinitionId,
        surveyEventId,
        daysLeft,
        isSurveyConnected,
        recentSurveyEventId,
        isParticipationMissed,
    } = awardStatus

    if (isParticipationMissed) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-col">
                        <MissedParticipationText />
                    </div>
                </>
            ),
        }
    }

    if (!isSurveyConnected) {
        const { iconState, title, subtext } = getSurveyStateTextDetails(
            daysLeft,
            recentSurveyEventId,
            isSurveyConnected
        )
        return {
            route: recentSurveyEventId
                ? routes.survey.setup.awards({ surveyEventId: recentSurveyEventId })
                : routes.survey.workplace(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <>
                            <AwardTimeLineStatusIcon type={iconState} />
                            <div className="flex flex-col pl-2">
                                {title}
                                {subtext}
                            </div>
                        </>
                    </div>
                </>
            ),
        }
    }

    if (
        surveyStateDefinitionId === StateDefinition.Danger ||
        surveyStateDefinitionId === StateDefinition.Warning ||
        surveyStateDefinitionId === StateDefinition.Help
    ) {
        const { iconState, title, subtext } = getSurveyStateTextDetails(
            daysLeft,
            recentSurveyEventId,
            isSurveyConnected
        )
        return {
            route: surveyEventId ? routes.survey.setup.summary({ surveyEventId }) : routes.survey.workplace(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <>
                            <AwardTimeLineStatusIcon type={iconState} />
                            <div className="flex flex-col pl-2">
                                {title}
                                {subtext}
                            </div>
                        </>
                    </div>
                </>
            ),
        }
    }

    if (registrationStateDefinitionId === StateDefinition.GoodForNow) {
        return {
            route: routes.employerRecognition.award.registration({ awardListParticipantId }),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.GoodForNow} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">{'You’re good for now!'}</p>
                            <p>{'Complete your registration'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    const daysToRegistrationDeadline = surveyScheduleDeadline ? getDifferenceInDays(registrationDeadline) : -1

    if (registrationStateDefinitionId === StateDefinition.Warning) {
        return {
            route: routes.employerRecognition.award.registration({ awardListParticipantId }),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Warning} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Registration deadline is in '}
                                <span className="text-yellow700">{`${daysToRegistrationDeadline} ${pluralize(
                                    'day',
                                    daysToRegistrationDeadline
                                )}`}</span>
                            </p>
                            <p>{'Please complete registration'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (registrationStateDefinitionId === StateDefinition.Danger && daysToRegistrationDeadline === 0) {
        return {
            route: routes.employerRecognition.award.registration({ awardListParticipantId }),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Danger} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Registration deadline is '}
                                <span className="text-red500">{'today'}</span>
                            </p>
                            <p>{'Please complete registration'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (registrationStateDefinitionId === StateDefinition.Danger) {
        return {
            route: routes.employerRecognition.award.registration({ awardListParticipantId }),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Danger} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Registration deadline is in '}
                                <span className="text-red500">{`${daysToRegistrationDeadline} ${pluralize(
                                    'day',
                                    daysToRegistrationDeadline
                                )}`}</span>
                            </p>
                            <p>{'Please complete registration'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (
        registrationStateDefinitionId === StateDefinition.Incomplete ||
        registrationStateDefinitionId === StateDefinition.Help
    ) {
        return {
            route: routes.employerRecognition.award.registration({ awardListParticipantId }),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Incomplete} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">{'Missed registration deadline'}</p>
                            <p>{'Info on file was sent to publisher'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (profileStateDefinitionId === StateDefinition.GoodForNow) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Complete} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">{'You’ve completed all of your tasks'}</p>
                            <p>{'Please update your profile as needed'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    const daysToProfileDeadline = profileDeadline ? getDifferenceInDays(profileDeadline) : -1

    if (profileStateDefinitionId === StateDefinition.Warning) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Warning} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Your profile is due in '}
                                <span className="text-yellow700">{`${daysToProfileDeadline} ${pluralize(
                                    'day',
                                    daysToProfileDeadline
                                )}`}</span>
                            </p>
                            <p>{'Please update your profile as needed'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (profileStateDefinitionId === StateDefinition.Danger && daysToProfileDeadline === 0) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Danger} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Your profile is due '}
                                <span className="text-red500">{'today'}</span>
                            </p>
                            <p>{'Please update it now'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (profileStateDefinitionId === StateDefinition.Danger) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Danger} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">
                                {'Your profile is due in '}
                                <span className="text-red500">{`${daysToProfileDeadline} ${pluralize(
                                    'day',
                                    daysToProfileDeadline
                                )}`}</span>
                            </p>
                            <p>{'Please update your profile as needed'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    if (profileStateDefinitionId === StateDefinition.Help) {
        return {
            route: routes.profile(),
            children: (
                <>
                    <StyledTitle>{awardNameAlias}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AwardTimeLineStatusIcon type={StateDefinition.Help} />
                        <div className="flex flex-col pl-2">
                            <p className="paragraph-bold">{'Your profile is missing information'}</p>
                            <p>{'Please finish it now'}</p>
                        </div>
                    </div>
                </>
            ),
        }
    }

    return {}
}

export const AwardsSwimLane = () => {
    const { data, isFetching } = useFetchYourAwards()

    const items: ReactNode[] = useMemo(() => {
        if (!data || isFetching) {
            return []
        }

        if (data.length === 0) {
            return [
                <SnapAwardTimelineCard key={'find-awards-card'} route={routes.employerRecognition()}>
                    <StyledTitle>{'You aren’t signed up for any awards yet'}</StyledTitle>
                    <div className="flex flex-row w-full">
                        <AddCircle size={48} className="flex-shrink-0" />
                        <div className="pl-2 flex-grow">
                            <p>{'Let us help you find more awards you’re qualified to participate in.'}</p>
                        </div>
                    </div>
                </SnapAwardTimelineCard>,
            ]
        }

        const items = data
            .map((awardStatus: AwardStatus) => {
                const { route, children } = getAwardTimelineStatusContent(awardStatus)

                if (!children) {
                    return null
                }

                return (
                    <SnapAwardTimelineCard key={awardStatus.awardListParticipantId} route={route} children={children} />
                )
            })
            .filter(Boolean)

        const stayUpToDateCard = (
            <SnapAwardTimelineCard key={'stay-up-to-date-card'} route={routes.employerRecognition()}>
                <StyledTitle>{'Stay Up To Date'}</StyledTitle>
                <div className="flex flex-row w-full">
                    <EmojiEvents size={48} className="flex-shrink-0" />
                    <div className="pl-2 flex-grow">
                        <p>{'Check out all your Employer Recognition awards and add more awards'}</p>
                    </div>
                </div>
            </SnapAwardTimelineCard>
        )

        items.push(stayUpToDateCard)

        return items
    }, [data, isFetching])

    return (
        <SwimLaneContainer
            sectionTitle={'Awards you’re participating in'}
            items={items}
            isFetching={isFetching}
            // TODO: add css tag if required
        />
    )
}
