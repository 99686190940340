import { Checkbox, LegacyButton as Button, Skeleton } from '@energage/components'
import { colors } from '@energage/theme'
import styled from 'styled-components'
import { SaveButton } from './AwardSelection.atoms'
import { AwardSelectionTableSkeleton } from './AwardSelectionTable'
import { TermsLink } from './SurveyConfirmations'

const BlueTextSkeleton = styled(Skeleton.RectangularShape).attrs({ className: 'inline-block h-10' })`
    width: 10rem;
    .skeleton__shape {
        background-image: linear-gradient(-90deg, ${colors.blue50} 0%, ${colors.blue100} 50%, ${colors.blue50} 100%);
    }
`

function ParticipationPromptSkeleton() {
    return (
        <div>
            <Skeleton.RectangularShape className="h-10 block max-w-md" />
            <BlueTextSkeleton />
            <Skeleton.RectangularShape className="h-10 inline-block w-24 md:w-96" />
        </div>
    )
}

const CheckboxLabel = styled(Skeleton.ParagraphShape)`
    width: 1000px;
`

function SurveyConfirmationsSkeleton() {
    return (
        <section className="my-5">
            <p>
                <TermsLink />
            </p>
            <Checkbox
                disabled={true}
                name="employeeCount_skeleton"
                label={<CheckboxLabel rows={2} />}
                className="mt-1"
            />
        </section>
    )
}

export function AwardSelectionSkeleton() {
    return (
        <>
            <ParticipationPromptSkeleton />
            <div className="my-4">
                <AwardSelectionTableSkeleton />
            </div>
            <Button className="p-0 text-base block" variant="link">
                {'Add Awards'}
            </Button>
            <SurveyConfirmationsSkeleton />
            <SaveButton disabled={true} />
        </>
    )
}
