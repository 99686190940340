import { useLocation } from 'react-router-dom'
import { useIdentity } from 'components/Identity'
import { CE_AND_INDUSTRY_PUBLISHER_ID, TWP_USA_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { CeAndIndustryPressReleaseFormDetails } from 'containers/Public/MediaKit/CeAndIndustryPressReleaseFormDetails'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'
import useFetchMediaKitAwardImages from '../useFetchMediaKitAwardImages'
import PressReleaseConfiguration from './PressReleaseConfiguration'
import { PressReleaseFormDetails } from './PressReleaseFormDetails'
import { UsaPressReleaseFormDetails } from './UsaPressReleaseFormDetails'

const MediaKitPressReleaseFormPreview = () => {
    const location = useLocation()
    const parsedQuery = parseQueryParams(location)
    const { surveyCompanyId } = useIdentity()
    const {
        awardListParticipantId,
        publisherId,
        awardCategoryId,
        publishYear,
        isNationalStandard,
        mediaKitLiteExperience,
    } = parsedQuery || {}
    const { pressReleaseResponse } = useFetchMediaKitAwardImages(
        publisherId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
        awardCategoryId
    )
    const awardImageUrl = pressReleaseResponse?.awardImageUrl
    const PressReleaseDetailsComp =
        publisherId === CE_AND_INDUSTRY_PUBLISHER_ID || awardCategoryId === 1
            ? CeAndIndustryPressReleaseFormDetails
            : publisherId === TWP_USA_PUBLISHER_ID
            ? UsaPressReleaseFormDetails
            : PressReleaseFormDetails

    return (
        <>
            <PressReleaseConfiguration
                publisherId={publisherId}
                surveyCompanyId={surveyCompanyId}
                publishYear={publishYear}
                isNationalStandard={isNationalStandard}
                awardCategoryId={awardCategoryId}
            />
            <PressReleaseDetailsComp
                publisherId={publisherId}
                surveyCompanyId={surveyCompanyId}
                awardListParticipantId={awardListParticipantId}
                awardCategoryId={awardCategoryId}
                publishYear={publishYear}
                isNationalStandard={isNationalStandard}
                awardImageUrl={awardImageUrl}
                isMediaKitLite={!!mediaKitLiteExperience}
                isPreviewPage
            />
        </>
    )
}

export default MediaKitPressReleaseFormPreview
