import { Loading } from '@energage/components'
import { useParams } from 'react-router'
import { useIdentity } from 'components/Identity'
import type { CompanyUser, CompanyUserDto, CompanyUserPublisher, UserType } from '../models'
import { UserFormContainer } from '../UserFormContainer'
import { EDIT_USER } from '../UserManagement.util'
import { useGetCompanySurveyEvents, useGetCompanyUser, useUpdateCompanyUser } from '../useUserManagementQueries'

function getRoles(user: CompanyUserDto, isPublisher: boolean) {
    if (isPublisher) {
        const userData = user as CompanyUserPublisher
        return {
            hasAdminAccess: userData?.roles?.admin ?? false,
            hasMarketingAccess: userData?.roles?.marketing?.enabled ?? false,
        }
    }

    const userData = user as CompanyUser

    return {
        hasAdminAccess: userData?.roles?.admin ?? false,
        hasBrandingAccess: userData?.roles?.brandingManager?.enabled ?? false,
        hasInsightsAccess: userData?.roles?.insightsManager?.enabled ?? false,
        hasSurveyAccess: userData?.roles?.surveyManager?.enabled ?? false,
        departments: !!userData.roles.insightsManager?.enabled ? userData.roles.insightsManager.departments : [],
        isActionTrackerRestriction: !!userData.roles.insightsManager?.enabled
            ? userData.roles.insightsManager?.actionTrackingOnly
            : false,
        hasSecurityAccess: userData?.roles?.securityManager?.enabled ?? false,
    }
}

function EditUser() {
    const { userId } = useParams<{ userId: string }>()
    const {
        surveyCompanyId,
        company: { isPublisher },
    } = useIdentity()
    const { data: userData, dataUpdatedAt } = useGetCompanyUser(surveyCompanyId, Number(userId))
    const { data: surveyEventsData } = useGetCompanySurveyEvents(surveyCompanyId)
    const { mutateAsync: updateUser, isLoading, error } = useUpdateCompanyUser(surveyCompanyId, Number(userId))

    const isLoadingInitialData = !userData || !surveyEventsData

    if (isLoadingInitialData) {
        return <Loading />
    }

    const companyUser: UserType = {
        id: Number(userId),
        firstName: userData.firstName ?? '',
        lastName: userData.lastName ?? '',
        email: userData.email ?? '',
        isCustomerSuccessPrimaryContact: !!userData.roles.isCustomerSuccessPrimaryContact,
        isDisabled: userData.isDisabled,
        roles: getRoles(userData, isPublisher),
        securitySettings: {
            hasActiveMfa: userData?.securitySettings?.hasActiveMfa ?? false,
        },
    }

    return (
        <UserFormContainer
            key={dataUpdatedAt}
            defaultValues={companyUser}
            surveyEvents={surveyEventsData}
            action={EDIT_USER}
            onSave={updateUser}
            isLoading={isLoading}
            savingError={error?.parsed?.data}
        />
    )
}

export { EditUser }
