import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Alert, LegacyButton as Button } from '@energage/components'
import { AppLink, ExternalLink } from 'components/Anchor'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation'
import FancyCheckbox from 'components/FancyCheckbox'
import { usePermissions } from 'components/Identity'
import LoadingButton from 'components/LoadingButton'
import config from 'config'
import { routes } from 'constants/routes'
import TaskDefinition from 'constants/taskDefinition'
import { conjunctionJoin } from 'util/conjunctionJoin'
import { formatDate } from 'util/formatters'
import { pluralizeNounOnly } from 'util/pluralize'
import SurveySetupContext from '../SurveySetupContext'
import {
    InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage,
    InviteeCountSubstantiallyDifferentFromRegionalCountsMessage,
} from './messages'
import { SurveyScope } from './SurveyScope'

const ModifyRecipientButton = ({ isTwpSurvey, onModifyRecipients, disabled }) => {
    const { hasMultiRegionTwpSurveys } = usePermissions()

    const confirmationMessage = hasMultiRegionTwpSurveys ? (
        <>
            <p>{`Your account settings require special considerations.`}</p>
            <p>{`Please contact support@energage.com for help with your survey setup.`}</p>
        </>
    ) : (
        'Top Workplaces surveys require that all employees be invited to participate in the survey. Removing recipients will mean this survey cannot be used for participation in a Top Workplaces award.'
    )

    if (isTwpSurvey) {
        return (
            <ButtonWithConfirmation
                acceptLabel="Continue"
                confirmationMessage={confirmationMessage}
                onAccept={onModifyRecipients}
                rejectLabel="Cancel"
                className="mr-4 mt-3"
                outline
                disabled={disabled}
                variant="secondary">
                {'Modify Recipients'}
            </ButtonWithConfirmation>
        )
    }

    return (
        <Button onClick={onModifyRecipients} className="mr-4 mt-3" outline variant="secondary" disabled={disabled}>
            {'Modify Recipients'}
        </Button>
    )
}

ModifyRecipientButton.propTypes = {
    isTwpSurvey: PropTypes.bool.isRequired,
    onModifyRecipients: PropTypes.func.isRequired,
}

const taskCompletionDateFormat = formatDate('MMMM d, y')

const RecipientPageDetails = ({
    hasSms,
    surveyEventMemberCount,
    skipEmailForSmsUsers,
    onChangeSkipEmailForSmsUsers,
    showModifyRecipients,
    surveyScope,
    handleModifyRecipients,
    handleConfirmRecipients,
    hasConfirmedRecipients,
    isPosting,
    confirmDisabled,
    targetDepartmentNames,
    surveyScopeSaveState,
    handleChangeWhoYouAreSurveying,
}) => {
    const { surveyHasLaunched, getTaskCompletedDateTime } = useContext(SurveySetupContext)

    const relevantTaskCompletionDate = hasConfirmedRecipients
        ? getTaskCompletedDateTime(TaskDefinition.ConfirmRecipients)
        : getTaskCompletedDateTime(TaskDefinition.UploadEmployees)
    let start = ''
    if (relevantTaskCompletionDate) {
        const relevantTaskCompletionDateFormatted = taskCompletionDateFormat(relevantTaskCompletionDate)
        start = hasConfirmedRecipients
            ? `Your employee list was confirmed on ${relevantTaskCompletionDateFormatted}.`
            : `Your last employee list upload was on ${relevantTaskCompletionDateFormatted}.`
    }

    // commented out until skipEmailForSmsUsers is supported by backend
    const hasMixedCommunicationType = false //hasSms && surveyEventMemberCount.withEmailPhoneNumberAndSmsConsent > 0

    const showInviteeRegionalCountMessage =
        !surveyScope?.hasMinimumQualifyingInviteeCount ||
        surveyScope?.isInviteeCountSubstantiallyDifferentFromRegionalCounts

    const inviteeRegionalCountMessage = !surveyScope?.hasMinimumQualifyingInviteeCount
        ? InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage
        : surveyScope?.isInviteeCountSubstantiallyDifferentFromRegionalCounts
        ? InviteeCountSubstantiallyDifferentFromRegionalCountsMessage
        : ''

    return (
        <div className="pb-4 border-b-2 pl-6">
            {showInviteeRegionalCountMessage && (
                <div className="flex flex-col pb-4">
                    <Alert variant="danger" className="text-xs px-3 py-2 w-auto">
                        {inviteeRegionalCountMessage}
                    </Alert>
                </div>
            )}

            <InvitationCountText
                hasSms={hasSms}
                skipEmailForSmsUsers={skipEmailForSmsUsers}
                surveyEventMemberCount={surveyEventMemberCount}
                targetDepartmentNames={targetDepartmentNames}
            />

            <p className="pt-2">
                {hasMixedCommunicationType && 'Some employees may receive both SMS and email invitations. '}
                {`${start} If you need to update recipients, please visit `}
                <AppLink to={routes.manage.employees.departments()} target="_blank">
                    {'Organization Management'}
                </AppLink>
                {'.'}
            </p>

            {hasMixedCommunicationType && (
                <p className="pt-2 pb-2 text-xs">
                    <FancyCheckbox
                        className="flex"
                        checked={skipEmailForSmsUsers}
                        onChange={onChangeSkipEmailForSmsUsers}>
                        {`Skip emails for SMS users?`}
                    </FancyCheckbox>
                </p>
            )}
            {showModifyRecipients && (
                <ModifyRecipientButton
                    onModifyRecipients={handleModifyRecipients}
                    isTwpSurvey={surveyScope.isTopWorkplaceParticipant}
                    disabled={surveyHasLaunched}
                />
            )}
            <LoadingButton
                variant="secondary"
                disabled={confirmDisabled || hasConfirmedRecipients}
                isLoading={isPosting}
                onClick={handleConfirmRecipients}>
                {hasConfirmedRecipients ? 'Confirmed' : 'Confirm'}
            </LoadingButton>

            {surveyScope.isTopWorkplaceParticipant && (
                <SurveyScope
                    isRandomSample={surveyScope.isRandomSample}
                    surveyScopeSaveState={surveyScopeSaveState}
                    isEmployeeCountConfirmed={surveyScope.isEmployeeCountConfirmed}
                    regionsConfirmedEmployeeTotal={surveyScope.regionsConfirmedEmployeeTotal}
                    inviteeCount={surveyScope.inviteeCount}
                    isInviteeCountSubstantiallyDifferentFromRegionalCounts={
                        surveyScope.isInviteeCountSubstantiallyDifferentFromRegionalCounts
                    }
                    hasMinimumQualifyingInviteeCount={surveyScope.hasMinimumQualifyingInviteeCount}
                    hasNationalAward={surveyScope.hasNationalAward}
                    hasAllLocationsSurveyed={surveyScope.hasAllLocationsSurveyed}
                    minimumInviteeCountForRandomSampling={surveyScope.minimumInviteeCountForRandomSampling}
                    handleChangeWhoYouAreSurveying={handleChangeWhoYouAreSurveying}
                />
            )}

            <p className="pt-6 italic">
                {'*If a recipient needs a new invitation, '}
                <ExternalLink href={`${config.urls.survey}resend`} target="_blank" rel="noopener noreferrer">
                    {'please resend the invitation to their email'}
                </ExternalLink>
                {'.'}
            </p>
        </div>
    )
}

const InvitationCountText = ({ hasSms, skipEmailForSmsUsers, surveyEventMemberCount, targetDepartmentNames }) => {
    const totalParticipantsAvailableForContact =
        surveyEventMemberCount.withEmailOnly +
        surveyEventMemberCount.withEmailPhoneNumberAndSmsConsent +
        (hasSms ? surveyEventMemberCount.withPhoneNumberAndSmsConsentOnly : 0)

    const totalParticipantsNotAvailableForContact = surveyEventMemberCount.total - totalParticipantsAvailableForContact
    const canAllBeContacted = totalParticipantsNotAvailableForContact === 0

    return (
        <>
            {totalParticipantsAvailableForContact > 0 && (
                <h5>
                    <InvitationText
                        surveyEventMemberCount={surveyEventMemberCount}
                        totalParticipantsAvailableForContact={totalParticipantsAvailableForContact}
                        targetDepartmentNames={targetDepartmentNames}
                        canAllBeContacted={canAllBeContacted}
                        hasSms={hasSms}
                        skipEmailForSmsUsers={skipEmailForSmsUsers}
                    />
                </h5>
            )}
            {!canAllBeContacted && (
                <>
                    <h5>{`There are ${totalParticipantsNotAvailableForContact} employees with no usable contact information.`}</h5>
                    <p>
                        {`We need either emails or phone numbers with consent in order to send your online survey invitations.
                        Please add the missing information to your recipients list or change your survey format.`}
                    </p>
                </>
            )}
        </>
    )
}

function InvitationText({
    surveyEventMemberCount,
    totalParticipantsAvailableForContact,
    targetDepartmentNames,
    canAllBeContacted,
    hasSms,
    skipEmailForSmsUsers,
}) {
    const inviteeCountWithSms =
        surveyEventMemberCount.withPhoneNumberAndSmsConsentOnly +
        (skipEmailForSmsUsers ? surveyEventMemberCount.withEmailPhoneNumberAndSmsConsent : 0)
    const inviteeCountWithEmailAndSms = skipEmailForSmsUsers
        ? 0
        : surveyEventMemberCount.withEmailPhoneNumberAndSmsConsent
    const inviteeCountWithEmail = surveyEventMemberCount.withEmailOnly

    const hasEmails = inviteeCountWithEmailAndSms > 0 || surveyEventMemberCount.withEmailOnly > 0
    const isTheSameCommunicationTypeForAll =
        !hasSms ||
        inviteeCountWithEmail === totalParticipantsAvailableForContact ||
        inviteeCountWithSms === totalParticipantsAvailableForContact ||
        inviteeCountWithEmailAndSms === totalParticipantsAvailableForContact

    let targetDepartmentText = ''
    let hasTargetDepartmentText = targetDepartmentNames.length > 0
    if (hasTargetDepartmentText) {
        if (targetDepartmentNames.length <= 4) {
            targetDepartmentText =
                conjunctionJoin('and', targetDepartmentNames) +
                `${pluralizeNounOnly(targetDepartmentNames.length, ' department')}`
        } else {
            let hiddenDepartmentCount = targetDepartmentNames.length - 3
            targetDepartmentText = `${targetDepartmentNames[0]}, ${targetDepartmentNames[1]}, ${
                targetDepartmentNames[2]
            }, and ${hiddenDepartmentCount} more ${pluralizeNounOnly(hiddenDepartmentCount, 'department')}`
        }
        targetDepartmentText = ' in the ' + targetDepartmentText
    }

    if (isTheSameCommunicationTypeForAll) {
        return `Your survey will be sent to ${
            canAllBeContacted && !hasTargetDepartmentText ? 'all' : ''
        }  ${totalParticipantsAvailableForContact} employees ${targetDepartmentText} by ${
            hasEmails && hasSms ? 'email and SMS' : hasEmails ? 'email' : 'SMS'
        } invitations.`
    }

    return getMixedInvitationsText(
        canAllBeContacted && !hasTargetDepartmentText,
        targetDepartmentText,
        totalParticipantsAvailableForContact,
        inviteeCountWithSms,
        inviteeCountWithEmailAndSms,
        inviteeCountWithEmail
    )
}

function getMixedInvitationsText(
    canAllBeContacted,
    targetDepartmentText,
    totalParticipantsAvailableForContact,
    inviteeCountWithSms,
    inviteeCountWithEmailAndSms,
    inviteeCountWithEmail
) {
    let resultString = `Your survey will be sent to ${
        canAllBeContacted ? 'all' : ''
    } ${totalParticipantsAvailableForContact} employees ${targetDepartmentText}. `
    if (inviteeCountWithEmail > 0) {
        resultString += `${inviteeCountWithEmail} will receive email ${pluralizeNounOnly(
            inviteeCountWithEmail,
            'invitation',
            true
        )}, `
    }
    if (inviteeCountWithSms > 0) {
        resultString += `${inviteeCountWithSms} will receive SMS ${pluralizeNounOnly(
            inviteeCountWithSms,
            'invitation'
        )}`
    }
    if (inviteeCountWithEmailAndSms > 0) {
        if (inviteeCountWithSms > 0 && inviteeCountWithEmail > 0) {
            resultString += `, and ${inviteeCountWithEmailAndSms} will receive both`
        } else {
            if (inviteeCountWithSms > 0) {
                resultString += `, `
            }
            resultString += ` ${inviteeCountWithEmailAndSms} will receive email and SMS ${pluralizeNounOnly(
                inviteeCountWithEmailAndSms,
                'invitation'
            )}`
        }
    }

    resultString += `.`
    return resultString
}

RecipientPageDetails.propTypes = {
    hasSms: PropTypes.bool,
    skipEmailForSmsUsers: PropTypes.bool.isRequired,
    onChangeSkipEmailForSmsUsers: PropTypes.func.isRequired,
    surveyEventMemberCount: PropTypes.object.isRequired,
    surveyScope: PropTypes.object.isRequired,
    showModifyRecipients: PropTypes.bool.isRequired,
    handleModifyRecipients: PropTypes.func.isRequired,
    handleConfirmRecipients: PropTypes.func.isRequired,
}

export default RecipientPageDetails
