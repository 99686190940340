import map from 'lodash/map'

const TestInvitationHistoryTable = ({ testInvitations }) => {
    return (
        <div className="text-primary-dark mt-5 ">
            <div className="bg-white rounded shadow-md border-t">
                <TableHeader />
                {map(testInvitations, (testInvitation, i) => {
                    return <TestInvitationRow key={i} {...testInvitation} />
                })}
            </div>
        </div>
    )
}

const TestInvitationRow = ({
    emailAddress,
    emailSentDateTimeText,
    shortEmailSentDateTimeText,
    surveyViewedDateTimeText,
    shortSurveyViewedDateTimeText,
    status,
}) => (
    <div className="border-t border-grey300 text-xs sm:pl-8 flex">
        <TableCell className="w-1/2 sm:w-2/5 md:w-1/4">
            <span title={emailAddress}>{emailAddress}</span>
        </TableCell>
        <TableCell className="w-1/4 md:w-1/4 hidden md:inline-block">{emailSentDateTimeText}</TableCell>
        <TableCell className="w-1/4 sm:w-1/5 md:hidden">{shortEmailSentDateTimeText}</TableCell>
        <TableCell className="md:w-1/4 hidden md:inline-block ">{surveyViewedDateTimeText}</TableCell>
        <TableCell className="sm:w-1/5 hidden sm:inline-block md:hidden ">{shortSurveyViewedDateTimeText}</TableCell>
        <TableCell className="w-1/4 sm:w-1/5 md:w-1/6">{status}</TableCell>
    </div>
)

const TableHeader = () => (
    <div className="sm:pl-8">
        <HeaderCell className="w-1/2 sm:w-2/5 md:w-1/4">{'Email'}</HeaderCell>
        <HeaderCell className="w-1/4 sm:w-1/5 md:w-1/4">{'Sent'}</HeaderCell>
        <HeaderCell className="sm:w-1/5 md:w-1/4 hidden sm:inline-block ">{'Survey Viewed'}</HeaderCell>
        <HeaderCell className="w-1/4 sm:w-1/5 md:w-1/6">{'Email Test Status'}</HeaderCell>
    </div>
)

const cellStyle = {
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    padding: '0.6rem',
}

const TableCell = ({ className, children }) => {
    return (
        <div style={cellStyle} className={className + ' inline-block'}>
            {children}
        </div>
    )
}

const HeaderCell = ({ className, children }) => {
    return (
        <div style={cellStyle} className={className + ' inline-block paragraph-small font-bold align-middle'}>
            {children}
        </div>
    )
}

export default TestInvitationHistoryTable
