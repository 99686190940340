import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import cx from 'clsx'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import { CultureCloudId } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'
import contrast from 'util/contrast'
import safeInvoke from 'util/safeInvoke'

const MIN_HEADER_LENGTH = 55
const MAX_HEADER_LENGTH = 80

export const getForegroundColor = (backgroundColor) =>
    contrast(backgroundColor).isLight ? colors.grey700 : colors.white

const Blur = styled.div`
    filter: ${(props) => (props.blurTileBody ? 'blur(4px)' : 'blur(0)')};
`
const TileContainer = styled.div.attrs((props) => ({
    className: cx('rounded shadow-md', { 'bg-blue400': !props.backgroundColor, 'shadow-none': !props.shadow }),
}))`
    color: ${(props) => props.foregroundColor};
`
const TileBody = styled.div`
    color: ${(props) => props.foregroundColor};
    min-width: 275px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: ${(props) => (props.bodyBorderRadius ? props.bodyBorderRadius : '0 0 12px 12px')};
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
`
const TileHeader = styled.div.attrs({ className: 'font-bold' })`
    background-color: ${(props) => props.backgroundColor};
    font-family: 'Libre Franklin';
    font-size: 1.3rem;
    border-radius: ${(props) => (props.headerBorderRadius ? props.headerBorderRadius : '12px 12px 0 0')};
`
const TileHeaderTitle = styled.div.attrs(({ isPdf }) => ({ className: isPdf ? 'w-4/5' : 'w-3/4' }))`
    color: ${(props) => props.headerTextColor};
    font-size: ${(props) =>
        props.isPdf
            ? '3.1rem'
            : props.header.length <= MIN_HEADER_LENGTH && props.dataId !== CultureCloudId
            ? '1.3rem'
            : props.header.length <= MAX_HEADER_LENGTH
            ? '1.2rem'
            : '1.1rem'};
`

const VerticalTile = ({
    header,
    children,
    className,
    bgColor,
    footerBackgroundColor,
    pills,
    minHeight,
    dataId,
    filterData,
    shadow,
    blurTileBody,
    bodyBorderRadius,
    headerBorderRadius,
    isPdf,
}) => {
    const foregroundColor = getForegroundColor(footerBackgroundColor ? footerBackgroundColor : bgColor)
    const pillsTextColor = contrast(bgColor).isLight ? colors.black : colors.white

    return (
        <TileContainer
            className={className}
            backgroundColor={bgColor}
            foregroundColor={foregroundColor}
            shadow={shadow}>
            {header && (
                <TileHeader backgroundColor={colors.white} headerBorderRadius={headerBorderRadius} isPdf={isPdf}>
                    <div className={cx('flex flex-row pb-1', isPdf ? 'pt-6 pr-8 pl-8' : 'pt-2 pr-2 pl-4')}>
                        <TileHeaderTitle header={header} headerTextColor={colors.black} dataId={dataId} isPdf={isPdf}>
                            {safeInvoke(header, { bgColor })}
                        </TileHeaderTitle>
                        <div className={isPdf ? 'w-1/5' : 'w-1/4'}>
                            <ProgressiveImage src={CultureFactTWP} placeholder="">
                                {(src) => (
                                    <img src={src} alt={'Top work places'} className="border rounded-full w-full" />
                                )}
                            </ProgressiveImage>
                        </div>
                    </div>
                    {safeInvoke(pills, {
                        foregroundColor: bgColor,
                        pillsTextColor,
                        filterData,
                        isPdf,
                    })}
                </TileHeader>
            )}
            <Blur blurTileBody={blurTileBody}>
                <TileBody
                    minHeight={minHeight}
                    backgroundColor={bgColor}
                    bodyBorderRadius={bodyBorderRadius}
                    isPdf={isPdf}>
                    {safeInvoke(children, { foregroundColor })}
                </TileBody>
            </Blur>
        </TileContainer>
    )
}

const StyledVerticalTile = styled(VerticalTile)`
    ${(props) => props.height && 'height: ' + props.height};
    width: ${(props) => (props.width ? props.width : '448px')};
`
StyledVerticalTile.propTypes = {
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
    bgColor: PropTypes.string,
    bodyBorderRadius: PropTypes.string,
    headerBorderRadius: PropTypes.string,
    footerBackgroundColor: PropTypes.string,
    dataId: PropTypes.string,
    height: PropTypes.string,
}

StyledVerticalTile.defaultProps = {
    bgColor: null,
    footerBackgroundColor: null,
}

export default StyledVerticalTile
