import { ChevronLeft, ChevronRight, Icon } from '@energage/icons'
import { colors, spacing } from '@energage/theme'
import cx from 'clsx'
import { differenceInDays, endOfDay, endOfWeek, isSameDay, isWithinInterval, startOfDay, startOfWeek } from 'date-fns'
import filter from 'lodash/filter'
import some from 'lodash/some'
import styled, { css } from 'styled-components'

const INDICATOR_DIAMETER = spacing(12)
const MAX_NUMBER_DAYS_TO_SHOW_TWP_LOGO = 365

export const isDateDisabled = (date, minDate, maxDate) => date < minDate || date > maxDate
export const isLaunchDate = (date, launchDate) => isSameDay(date, launchDate)
export const isCloseDate = (date, closeDate) => isSameDay(date, closeDate)
export const isReminderDate = (date, reminderDates) =>
    some(reminderDates, (reminderDate) => isSameDay(date, reminderDate))
export const isDateHighlighted = (date, launchDate, closeDate) =>
    launchDate && closeDate && isWithinInterval(date, { start: startOfDay(launchDate), end: endOfDay(closeDate) })
export const isStartOfWeek = (date) => isSameDay(date, startOfWeek(date))
export const isEndOfWeek = (date) => isSameDay(date, endOfWeek(date))
export const showTwpLogo = (TwpAwardDeadlineDate) =>
    Math.abs(differenceInDays(TwpAwardDeadlineDate, new Date())) <= MAX_NUMBER_DAYS_TO_SHOW_TWP_LOGO
export const showIsTentativeLogo = (awardListDeadlineDateTimes) => {
    return some(awardListDeadlineDateTimes, (value) => value.isTentative)
}

export const isTwpAwardDeadlineDate = (date, awardListScheduleCloseDates) => {
    const nonTentativeAwardDates = filter(awardListScheduleCloseDates, (date) => !date.isTentative)

    return some(nonTentativeAwardDates, (nonTentativeAwardDate) =>
        isSameDay(new Date(nonTentativeAwardDate.internalScheduledCloseDate), date)
    )
}

export const isTentativeDate = (date, awardListScheduleCloseDates) =>
    some(
        awardListScheduleCloseDates,
        (awardListScheduleCloseDate) =>
            isSameDay(new Date(awardListScheduleCloseDate.internalScheduledCloseDate), date) &&
            awardListScheduleCloseDate.isTentative
    )

export const NavigationIcon = ({ direction }) => (
    <Icon
        color={colors.grey500}
        className={cx(direction === 'prev' ? 'ml-4' : 'mr-4')}
        as={direction === 'prev' ? ChevronLeft : ChevronRight}
    />
)

export const MonthYearLabel = ({ children }) => <span className="h6">{children}</span>

export const baseCalendarCSS = css`
    width: calc(${INDICATOR_DIAMETER} * 7);
    .react-calendar {
        &__navigation {
            padding: ${spacing(2)} 0;
        }

        &__month-view {
            &__weekdays {
                border-bottom: 1px solid ${colors.grey300};
                padding-bottom: ${spacing(1)};

                &__weekday {
                    text-align: center;
                    text-transform: uppercase;

                    abbr {
                        text-decoration: none !important;
                    }
                }
            }

            &__days {
                &__day {
                    overflow: visible !important;

                    &:focus {
                        outline: none;
                    }
                    *[disabled] {
                        cursor: default;
                    }
                    abbr {
                        display: none;
                    }
                }
            }
        }
    }
`

export const HighlightIndicator = styled.div.attrs((props) => ({
    className: cx(
        'bg-green200 absolute',
        props.hidden && 'hidden',
        props.startOfWeek && 'rounded-l-full',
        props.endOfWeek && 'rounded-r-full'
    ),
}))`
    top: 0;
    right: ${(props) => (props.isCloseDate ? '50%' : 0)};
    bottom: 0;
    left: ${(props) => (props.isLaunchDate ? '50%' : 0)};
`

export const SelectedIndicator = styled.div.attrs((props) => ({
    className: cx(
        props.className,
        'absolute rounded-full',
        !(props.isPrimary || props.isSecondary) && 'hidden',
        props.isPrimary && 'bg-green500',
        props.isSecondary && 'bg-blue200 border-4 border-blue500'
    ),
}))`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
export const IsTentativeDateIndicator = styled.div.attrs((props) => ({
    className: cx(props.className, 'absolute rounded-full bg-purple100', !props.isTentativeDate && 'hidden'),
}))`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
