import cx from 'clsx'
import format from 'date-fns/format'
import getYear from 'date-fns/getYear'
import styled from 'styled-components'
import { ReactComponent as TwpLogo } from 'images/twp-chevron-logo.svg'

const DAY_MONTH_FORMAT = 'MM/dd'
export const DAY_MONTH_YEAR_FORMAT = 'MM/dd/yyyy'

export const SurveyDates = ({ launchDateTime, closeDateTime }) => {
    if (!launchDateTime && !closeDateTime) {
        return null
    }

    return (
        <p className="mb-2 text-grey500 text-xs">
            {launchDateTime &&
                format(
                    launchDateTime,
                    getYear(launchDateTime) === getYear(closeDateTime) ? DAY_MONTH_FORMAT : DAY_MONTH_YEAR_FORMAT
                )}
            {launchDateTime && closeDateTime && ' - '}
            {closeDateTime && format(closeDateTime, DAY_MONTH_YEAR_FORMAT)}
        </p>
    )
}

export const RoundedIconHolder = styled.div.attrs((props) => ({
    'className': cx('rounded-full flex items-center justify-center m-auto', props.backgroundColor),
    'aria-hidden': 'true',
}))`
    width: 114px;
    height: 114px;
`

export const AccessibleTileStatus = ({ backgroundColor, statusText }) => (
    <p className="pb-2">
        <TileStatus backgroundColor={backgroundColor}>{statusText}</TileStatus>
    </p>
)

const TileStatus = styled.span.attrs((props) => ({
    className: cx('rounded-full text-white text-xs', props.backgroundColor),
}))`
    padding: 2px 10px;
`

export const SurveyNameWithIcon = ({ surveyName, isTwpParticipant }) => (
    <h5 className="pb-1 pt-4 px-6">
        {isTwpParticipant && <TwpLogo className="h-5 w-3 -mt-1 mr-1" title={surveyName} />}
        {surveyName}
    </h5>
)
