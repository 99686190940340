import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import find from 'lodash/find'
import noop from 'lodash/noop'
import { useQueryClient } from 'react-query'
import { matchPath } from 'react-router-dom'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import TaskDefinition from 'constants/taskDefinition'
import {
    getNextAvailablePage,
    getStatusByPage,
    getTaskStatusCompletedDateTimeFromWorkflow,
    getTaskStatusFromWorkflow,
} from './taskStatus'
import { getWorkflowTasks } from './workflow'

function getCurrentPage(currentPath, workflowTasks) {
    if (!workflowTasks) {
        return undefined
    }

    return find(workflowTasks, ({ route }) => matchPath(currentPath, { path: route() }))
}

const defaultWaitingPromise = { resolve: noop, reject: noop }

const hasTask = (tasks = [], taskDefinition) => find(tasks, { taskDefinition }) !== undefined

export function useSurveySetupWorkflow(surveyEventId, currentPath) {
    const [showPrimaryAction, setShowPrimaryAction] = useState(true)
    const queryClient = useQueryClient()

    const waitingPromise = useRef(defaultWaitingPromise)
    const { data, isLoading } = useWorkplaceSurveyQuery(
        ['survey-workflow', surveyEventId],
        `/workflow/${surveyEventId}`,
        {
            onError: (error) => {
                waitingPromise.current.reject(error)
            },
        }
    )

    const loadingWorkflow = isLoading && !data
    const surveyHasLaunched = data?.hasSurveyLaunched
    const closedDate = useMemo(() => new Date(data?.closedDate ?? '9999-01-01T00:00:00'), [data?.closedDate])
    const surveyHasClosed = useMemo(() => new Date() > new Date(closedDate), [closedDate])

    const hasAccessToSelectAwards = hasTask(data?.tasks, TaskDefinition.SelectAwards)
    const workflowTasks = useMemo(() => getWorkflowTasks(hasAccessToSelectAwards), [hasAccessToSelectAwards])

    const currentPage = getCurrentPage(currentPath, workflowTasks)
    const taskStatuses = useMemo(() => getTaskStatusFromWorkflow(data), [data])
    const taskStatusesCompletedDateTime = useMemo(() => getTaskStatusCompletedDateTimeFromWorkflow(data), [data])
    const getTaskCompletedDateTime = useCallback(
        (id) => taskStatusesCompletedDateTime[id],
        [taskStatusesCompletedDateTime]
    )

    const { isSuperUser } = useIdentity()

    const getTaskStatus = useCallback((id) => taskStatuses[id], [taskStatuses])
    const status = useMemo(
        () => getStatusByPage(workflowTasks, taskStatuses, data, isSuperUser),
        [workflowTasks, taskStatuses, data, isSuperUser]
    )
    const getStatus = useCallback((id) => status[id.toString()], [status])
    const getNextPage = useCallback(
        () => getNextAvailablePage(workflowTasks, data, currentPage?.id, taskStatuses),
        [workflowTasks, data, currentPage, taskStatuses]
    )

    const getTaskStatusInfo = useCallback((id) => find(data?.statuses, (s) => s.taskDefinitionId === id), [data])
    const isTaskRelevant = useCallback((task) => getTaskStatus(task) !== undefined, [getTaskStatus])
    const hidePrimaryAction = useCallback(() => {
        setShowPrimaryAction(false)
        return () => {
            setShowPrimaryAction(true)
        }
    }, [])

    const refreshWorkflow = useCallback(() => {
        return new Promise((resolve, reject) => {
            waitingPromise.current = { resolve, reject }
            queryClient.invalidateQueries(['survey-workflow', surveyEventId])
        })
    }, [queryClient, surveyEventId])

    useEffect(() => {
        if (data) {
            waitingPromise.current.resolve({ getStatus, getTaskStatus, getTaskCompletedDateTime, getNextPage })
            waitingPromise.current = defaultWaitingPromise
        }
    }, [data, getStatus, getTaskStatus, getTaskCompletedDateTime, getNextPage])

    const surveySetupContext = useMemo(
        () => ({
            isLoading: loadingWorkflow,
            surveyHasLaunched,
            surveyHasClosed,
            getStatus,
            getNextPage,
            refreshWorkflow,
            taskStatuses,
            getTaskStatus,
            getTaskCompletedDateTime,
            isTaskRelevant,
            surveyTypeId: data?.surveyTypeId,
            surveyFormatId: data?.surveyFormatId,
            templateName: data?.templateName,
            launchDate: data?.launchDate,
            surveyGenerationId: data?.surveyGenerationId,
            showPrimaryAction,
            hidePrimaryAction,
            getTaskStatusInfo,
        }),
        [
            surveyHasClosed,
            loadingWorkflow,
            surveyHasLaunched,
            getStatus,
            getNextPage,
            refreshWorkflow,
            taskStatuses,
            getTaskStatus,
            getTaskCompletedDateTime,
            isTaskRelevant,
            data,
            showPrimaryAction,
            hidePrimaryAction,
            getTaskStatusInfo,
        ]
    )

    return {
        surveySetupContext,
        data,
        status,
        currentPage,
        taskStatuses,
        showAwardsPage: hasAccessToSelectAwards,
        workflowTasks,
    }
}
