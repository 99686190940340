import { useEffect, useMemo } from 'react'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import join from 'lodash/join'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import { useTwpWorkflow } from 'components/TwpWorkflow'
import { updateNotifications } from 'store'
import useDismissNotification from './useDismissNotification'
import useNotification from './useNotification'

export const TASK_WORKFLOW_NOTIFICATION = 'TaskWorkflow'

const filterWorkflowNotifications = (notifications, surveyEventIds) =>
    filter(
        notifications,
        ({ objectType, object }) =>
            objectType === TASK_WORKFLOW_NOTIFICATION && includes(surveyEventIds, object?.surveyEventId)
    )

const useUpdateWorkflowNotifications = () => {
    const { notifications } = useNotification()
    const { dismiss } = useDismissNotification()
    const { workflow } = useTwpWorkflow()

    const { workflowNotifications, categoriesByNameOfSurveyEventId } = useMemo(() => {
        if (!workflow) {
            return {}
        }
        const workflowNotifications = filterWorkflowNotifications(
            notifications,
            map(workflow, (w) => w.surveyEventId)
        )

        const categoriesByNameOfSurveyEventId = reduce(
            workflow,
            (a, w) => ({ ...a, [w.surveyEventId]: groupBy(w.newCategories, (category) => category.name) }),
            {}
        )

        return {
            workflowNotifications,
            categoriesByNameOfSurveyEventId,
        }
    }, [notifications, workflow])

    useEffect(() => {
        if (size(workflowNotifications) === 0) {
            return
        }

        const updatedNotifications = compact(
            map(workflowNotifications, (notification) => {
                const {
                    id: notificationId,
                    object: { workflowTaskName, incompleteTaskList, surveyEventId },
                    userId,
                } = notification

                const category = head(categoriesByNameOfSurveyEventId[surveyEventId]?.[workflowTaskName])

                if (!category) {
                    return
                }

                const tasks = filter(category.tasks, (task) => !task.isComplete)

                if (size(tasks) === 0) {
                    dismiss({ notificationId, userId })
                    return {
                        ...notification,
                        readDate: new Date(),
                    }
                }

                const incompleteTaskListIds = map(tasks, (task) => `${task.taskDefinitionId}`)

                if (!isEqual(sortBy(split(incompleteTaskList, ',')), sortBy(incompleteTaskListIds))) {
                    return {
                        ...notification,
                        object: {
                            ...notification.object,
                            incompleteTaskList: join(incompleteTaskListIds),
                        },
                    }
                }
            })
        )

        updateNotifications(updatedNotifications)
    }, [dismiss, workflowNotifications, categoriesByNameOfSurveyEventId])
}

export default useUpdateWorkflowNotifications
