import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { ReactComponent as TwpIcon } from 'images/twp.svg'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard/index'
import type { MessageWithSurveyEventId } from '../AwardIdentificationTile.types'

export const EmployeeCountMismatch = ({ message, surveyEventId }: MessageWithSurveyEventId) => {
    const tileUrl = routes.survey.setup.awards({ surveyEventId })
    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="text-red600 justify-center mt-2" color={colors.red600}>
                <Icon as={TwpIcon} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="text-red600 mb-2" ariaText="Required: Employee count mismatch">
                        {'Employee count mismatch'}
                    </CardTitle>
                    <p>{message}</p>
                </CardContent>
                <SummaryButton tileUrl={tileUrl} buttonText="Select Your Awards" />
            </CardBody>
        </SummaryCard>
    )
}
