import { useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { butterBar, Loading as Spinner } from '@energage/components'
import { useSelector } from 'react-redux'
import { useManagementQuery } from 'api'
import { routes } from 'constants/routes'
import { IS_LOGGED_IN } from 'constants/storageKeys'
import Identity from 'models/Identity'
import type { IdentityModel } from 'models/Identity'
import { selectAuthentication, setIdentity, setLoggedIn, updateTokens } from 'store'
import auth from 'util/auth'
import ImpersonationBar from './ImpersonationBar'

const SurveyEventDoesNotExist = () => (
    <div>
        {
            'Employer Recognition is not accessible as survey event is not created. Login again to access the wizard and add awards'
        }
    </div>
)

async function renewSession() {
    try {
        await auth.renewSession()
        const accessToken = auth.getAccessToken()
        const idToken = auth.getIdToken()
        // @ts-ignore
        updateTokens({ accessToken, idToken })
    } catch (e) {
        window.location.assign(routes.loginDenied())
    }
}

const AuthenticationManager = ({ children }: { children: ReactNode }) => {
    const { accessToken, isLoggedIn } = useAuthentication()
    const [isActuallyLoggedIn, setIsActuallyLoggedIn] = useState(false)

    useEffect(() => {
        // TODO: Temporary, remove when renderer fixed
        if (accessToken) {
            // @ts-ignore
            setLoggedIn({ accessToken, idToken: accessToken })
            setIsActuallyLoggedIn(true)
            return
        }

        if (isLoggedIn) {
            // @ts-ignore
            window.localStorage.setItem(IS_LOGGED_IN, true)
            renewSession().then(() => setIsActuallyLoggedIn(true))
        } else {
            window.localStorage.removeItem(IS_LOGGED_IN)
            auth.login(window.location.pathname)
        }
    }, [accessToken, isLoggedIn])

    const { isLoading, refetch } = useManagementQuery('identity', 'identity', {
        enabled: isActuallyLoggedIn,
        onSuccess: (data: IdentityModel) => {
            const identity = data ? Identity(data) : null

            if (!identity) {
                return
            }

            // @ts-ignore
            setIdentity({ ...identity, doFetch: refetch })

            if (identity.isImpersonating) {
                butterBar.danger(<ImpersonationBar emailAddress={identity.emailAddress} />, {
                    dismissible: false,
                })
            }

            if (!identity.isSurveyEventExists) {
                butterBar.danger(<SurveyEventDoesNotExist />, {
                    dismissible: true,
                })
            }
        },
        onError: (err: Error) => {
            datadogLogs.logger.error('Identity Error', err)
            window.location.assign(routes.loginDenied())
        },
    })

    if (isLoading) {
        return <Spinner />
    }

    return isActuallyLoggedIn ? children : null
}

function useAuthentication() {
    return useSelector(selectAuthentication)
}

export { AuthenticationManager, useAuthentication }
