export default {
    state: {
        updateActiveSurveys: true,
    },
    reducers: {
        setUpdateActiveSurveys(state, data) {
            state.updateActiveSurveys = data
        },
    },
    selectors: (slice) => ({
        selectUpdateActiveSurveys: () => slice(({ updateActiveSurveys }) => updateActiveSurveys),
    }),
}
