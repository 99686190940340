import PropTypes from 'prop-types'
import { Block, Done, Icon, MoreHoriz } from '@energage/icons'
import cx from 'clsx'

function getStatusProps(status) {
    switch (status) {
        case 'complete':
            return { background: 'bg-green500', as: Done }
        case 'in-progress':
            return { background: 'bg-blue500', as: MoreHoriz }
        case 'alert':
            return { background: 'bg-red500', as: Block }
        default:
            return null
    }
}

const Status = ({ className, status, size }) => {
    const props = getStatusProps(status)
    if (!props) {
        return null
    }

    return (
        <Icon className={cx(className, props.background, 'rounded-full p-px text-white')} size={size} as={props.as} />
    )
}

Status.propTypes = {
    status: PropTypes.oneOf(['complete', 'in-progress', 'alert']),
    size: PropTypes.number,
}

export default Status
