import { useMemo } from 'react'
import { atom, Provider } from 'jotai'
import { atomFamily } from 'jotai/utils'
import every from 'lodash/every'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import flatMap from 'lodash/fp/flatMap'
import map from 'lodash/fp/map'
import reduce from 'lodash/fp/reduce'
import sumBy from 'lodash/fp/sumBy'
import safeInvoke from 'util/safeInvoke'
import { getAdditionalQuestionsSubsections } from './dataTransform'

export const __SCOPE = 'survey-setup'

export const createSurveySetupAtom = (initialization, update = null) => {
    const newAtom = atom(initialization, update)
    newAtom.scope = __SCOPE
    return newAtom
}

export const forceAdditionalItemsAccordion = createSurveySetupAtom(null)

export const surveyHeadlineAtom = createSurveySetupAtom('')

export const descriptionAtom = createSurveySetupAtom('')
descriptionAtom.onMount = (setAtom) => {
    setAtom((v) => stripParagraphTags(v))
}

export const demographicSectionAtom = createSurveySetupAtom([])
export const demographicQuestionsAtom = createSurveySetupAtom((get) => {
    const demoSection = get(demographicSectionAtom)
    return flatMap((subsection) => subsection.questions, demoSection.subsections)
})

export const sectionsAtom = createSurveySetupAtom([])

export const allQuestionsAtom = createSurveySetupAtom((get) => {
    const sections = get(sectionsAtom)
    const demoQuestions = get(demographicQuestionsAtom)
    const nonDemoQuestions = flatMap(
        (section) => flatMap((subsection) => subsection.questions, section.subsections),
        sections
    )

    return [...demoQuestions, ...nonDemoQuestions]
})

export const questionMapAtom = createSurveySetupAtom((get) => {
    const allQuestions = get(allQuestionsAtom)
    return reduce(
        (prev, curr) => {
            prev[curr.questionEssenceId] = curr
            return prev
        },
        {},
        allQuestions
    )
})

function findSection(sectionId, atomGet) {
    const demoSection = atomGet(demographicSectionAtom)
    if (sectionId === demoSection.sectionId) {
        return demoSection
    }

    return find({ sectionId }, atomGet(sectionsAtom)) ?? {}
}

export const sectionFamily = atomFamily(
    ({ id }) => {
        return createSurveySetupAtom(
            (get) => {
                return findSection(id, get)
            },
            (get, set, update) => {
                const sections = get(sectionsAtom)
                set(
                    sectionsAtom,
                    map((section) => {
                        if (section.sectionId === id) {
                            return safeInvoke(update, section)
                        }
                        return section
                    }, sections)
                )
            }
        )
    },
    (a, b) => a.id === b.id
)

export const subSectionFamily = atomFamily(
    ({ sectionId, subsectionId }) => {
        function findSubsection(sectionId, subsectionId, atomGet) {
            const section = findSection(sectionId, atomGet)
            return find((subsection) => subsection.subsectionId === subsectionId, section.subsections)
        }

        return createSurveySetupAtom(
            (get) => {
                return findSubsection(sectionId, subsectionId, get)
            },
            (get, set, update) => {
                const sections = get(sectionsAtom)
                set(
                    sectionsAtom,
                    map(
                        (section) => ({
                            ...section,
                            subsections: map((subsection) => {
                                if (subsection.subsectionId === subsectionId) {
                                    return safeInvoke(update, subsection)
                                }
                                return subsection
                            }, section.subsections),
                        }),
                        sections
                    )
                )
            }
        )
    },
    (a, b) => a.subsectionId === b.subsectionId
)

export const questionFamily = atomFamily(
    ({ questionEssenceId }) => {
        const questionAtom = createSurveySetupAtom(
            (get) => {
                return get(questionMapAtom)[questionEssenceId]
            },
            (get, set, update) => {
                const curr = get(questionAtom)
                set(questionAtom, safeInvoke(update, curr))
            }
        )
        return questionAtom
    },
    (a, b) => a.questionEssenceId === b.questionEssenceId
)

export const questionFamilyArray = atomFamily(
    ({ questionEssenceIds }) => {
        const questionsAtom = createSurveySetupAtom((get) => {
            const questionMap = get(questionMapAtom)
            return map((questionEssenceId) => questionMap[questionEssenceId], questionEssenceIds)
        })

        return questionsAtom
    },
    (prev, next) => {
        if (prev.questionEssenceIds.length !== next.questionEssenceIds.length) {
            return false
        }

        const sortedA = prev.questionEssenceIds.sort()
        const sortedB = next.questionEssenceIds.sort()
        return every(sortedA, (questionEssenceId, index) => questionEssenceId === sortedB[index])
    }
)

export const sectionQuestionCountFamily = atomFamily(
    ({ sectionId }) => {
        return createSurveySetupAtom((get) => {
            const section = findSection(sectionId, get) //find({ sectionId }, get(sectionsAtom))

            const questions = flatMap((subsection) => subsection.questions, section.subsections)

            const questionsSelectedState = map(
                (question) => get(questionFamily({ questionEssenceId: question.questionEssenceId })).isSelected,
                questions
            )

            return {
                selectedCount: sumBy((isSelected) => (isSelected ? 1 : 0), questionsSelectedState),
                totalCount: questionsSelectedState.length,
            }
        })
    },
    (a, b) => a.sectionId === b.sectionId
)

export const selectedQuestionsAtom = createSurveySetupAtom((get) => {
    const allQuestions = get(allQuestionsAtom)
    return filter(
        (question) => get(questionFamily({ questionEssenceId: question.questionEssenceId }))?.isSelected,
        allQuestions
    )
})

export const updateAdditionalQuestionsAtom = createSurveySetupAtom(null, (get, set, allAdditionalQuestions) => {
    const sections = get(sectionsAtom)

    function sectionsIteratee(section) {
        if (section.sectionId !== 'additional') {
            return section
        }

        return { ...section, subsections: getAdditionalQuestionsSubsections(allAdditionalQuestions) }
    }

    set(sectionsAtom, map(sectionsIteratee, sections))
})

export const selectedIdsAtom = createSurveySetupAtom((get) => {
    const selectedQuestions = get(selectedQuestionsAtom)
    return map('questionEssenceId', selectedQuestions)
})

export const selectedQuestionCountAtom = createSurveySetupAtom((get) => {
    const selectedQuestions = get(selectedQuestionsAtom)
    return selectedQuestions?.length ?? 0
})

export const selectedAdditionalQuestionsAtom = createSurveySetupAtom((get) => {
    const additionalQuestionSection = findSection('additional', get)
    return flatMap((subsection) => subsection.questions, additionalQuestionSection.subsections)
})

export const selectedAdditionalQuestionNamesAtom = createSurveySetupAtom((get) => {
    const additionalQuestionSection = findSection('additional', get)
    return flatMap(
        (subsection) => map(({ name }) => ({ name }), subsection.questions),
        additionalQuestionSection?.subsections
    )
})

export const availableTargetDemographicQuestions = createSurveySetupAtom((get) => {
    const demoQuestions = get(demographicQuestionsAtom)
    return filter((q) => q.isAvailableForTargeting && q.isSelected, demoQuestions)
})

export function SurveyScopeProvider({ initialData, children }) {
    const initialValues = useMemo(() => {
        return [
            [demographicSectionAtom, initialData.demographicSection],
            [sectionsAtom, initialData.sections],
            [surveyHeadlineAtom, initialData.headline],
            [descriptionAtom, initialData.description],
        ]
    }, [initialData])

    return (
        <Provider initialValues={initialValues} scope={__SCOPE}>
            {children}
        </Provider>
    )
}

function stripParagraphTags(text) {
    return text?.replace(/<p>(.*?)<\/p>/gi, '$1\n').trim()
}
