import concat from 'lodash/concat'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

export default function getDisplayableSections(data) {
    const sections = concat([data.demographicSection], data.sections)

    return reduce(
        sections,
        (displayableSections, section) => {
            const questions = flatMap(section.subsections, 'questions')
            const selectedQuestions = filter(questions, 'isSelected')

            if (selectedQuestions.length === 0) {
                return displayableSections
            }

            section.subsections = map(section.subsections, (s) => ({
                ...s,
                questions: filter(s.questions, 'isSelected'),
            }))
            displayableSections.push(section)
            return displayableSections
        },
        []
    )
}
