import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useBrandingQuery } from 'api'
import { useIdentity } from 'components/Identity'
import useFiltersDataReducer from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/useFiltersDataReducer'

export const getInitialStateForFilterData = (surveyEventName = '') => ({
    surveyEventName,
    Locations: '',
    Departments: '',
    JobRoles: '',
})

export const getInitialCurrentSelectedFilter = (surveyEventName = '') => ({
    surveyEventName,
    Locations: -1,
    Departments: -1,
    JobRoles: -1,
})

export const Filters = createContext({})

const useFetchFiltersData = (
    hasEmployerBrandingSubscription,
    hasEmployerBrandingPlusSubscription,
    isSuperUser,
    surveyCompanyId,
    state,
    actions
) => {
    const { data, errorMsg: error } = useBrandingQuery(
        ['filter-options', state.serviceURL],
        `SurveyCompanies/${surveyCompanyId}/CultureFacts/${state.serviceURL}`,
        {
            onSuccess: (data) => {
                const errorMsg = error
                actions.updateDataOnSuccess({
                    data,
                    hasEmployerBrandingSubscription,
                    hasEmployerBrandingPlusSubscription,
                    isSuperUser,
                    errorMsg,
                })
            },
        }
    )

    return data
}

function useFiltersData() {
    return useContext(Filters)
}

const FiltersDataProvider = ({ children }) => {
    const {
        permissions: { hasEmployerBrandingSubscription, hasEmployerBrandingPlusSubscription } = {},
        isSuperUser,
        surveyCompanyId,
    } = useIdentity()
    const store = useFiltersDataReducer()
    const { state, actions } = store
    useFetchFiltersData(
        hasEmployerBrandingSubscription,
        hasEmployerBrandingPlusSubscription,
        isSuperUser,
        surveyCompanyId,
        state,
        actions
    )

    return <Filters.Provider value={store}>{children}</Filters.Provider>
}

FiltersDataProvider.propTypes = {
    children: PropTypes.node,
}

export { FiltersDataProvider, useFiltersData }
