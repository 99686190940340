import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@energage/components'
import { Info } from '@energage/icons'
import { colors } from '@energage/theme'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import map from 'lodash/map'
import some from 'lodash/some'
import { Accordion } from 'components/Accordion'
import { Tooltip } from 'components/Tooltip'
import { NonExpandableSection } from '../../../StatementSetup/fragments'
import { useQuestionToggles } from '../../../StatementSetup/statementSetupHooks'
import { sectionQuestionCountFamily, subSectionFamily } from '../../../StatementSetup/statementSetupUtility/store'
import QuestionTable, { QuestionTableRow } from './QuestionTable'

function InfoTooltip({ text }) {
    const trigger = <Info size={16} color={colors.grey500} className="-mt-1 ml-1" />
    return (
        <Tooltip trigger={trigger} text={text} disabled={false} tooltipColor={colors.grey600} textColor="text-white" />
    )
}

export function QuestionCategorySection({
    sectionId,
    sectionName,
    subsections,
    categoryDescription,
    onUpdateQuestions,
    defaultOpen,
    disabled,
    testId,
}) {
    const { totalCount } = useAtomValue(sectionQuestionCountFamily({ sectionId }))

    if (totalCount === 0) {
        return null
    }

    return (
        <Accordion
            testId={testId}
            defaultOpen={defaultOpen}
            key={sectionName}
            title={
                <div className="h5 px-6">
                    <span>
                        {sectionName}
                        {categoryDescription ? <InfoTooltip text={categoryDescription} /> : null}
                    </span>
                </div>
            }>
            {map(subsections, (subsection) => (
                <div className="px-0 sm:px-6" key={subsection.categoryName}>
                    <div className="font-bold mb-2">
                        <span>{subsection.categoryName}</span>{' '}
                        {subsection.helpText ? <InfoTooltip text={subsection.helpText} /> : null}
                    </div>
                    <QuestionCategorySubsection
                        onUpdateQuestions={onUpdateQuestions}
                        subsectionId={subsection.subsectionId}
                        sectionId={sectionId}
                        disabled={disabled}
                    />
                </div>
            ))}
        </Accordion>
    )
}

const QuestionCategorySubsection = ({ sectionId, subsectionId, onUpdateQuestions, disabled }) => {
    const [subsection, updateSubsection] = useAtom(subSectionFamily({ sectionId, subsectionId }))

    function onUpdate(updates) {
        updateSubsection((ss) => ({
            ...ss,
            questions: map(ss.questions, (q) => {
                if (updates.hasOwnProperty(q.questionEssenceId)) {
                    return { ...q, isSelected: updates[q.questionEssenceId] }
                }
                return q
            }),
        }))
        onUpdateQuestions()
    }

    const onUpdateForRequired = useCallback(
        (updates) => {
            updateSubsection((ss) => ({
                ...ss,
                questions: map(ss.questions, (q) => {
                    if (updates.hasOwnProperty(q.questionEssenceId)) {
                        if (q.isSelected === false) {
                            return {
                                ...q,
                                isSelected: updates[q.questionEssenceId],
                                isRequired: updates[q.questionEssenceId],
                            }
                        } else {
                            return { ...q, isRequired: updates[q.questionEssenceId] }
                        }
                    }
                    return q
                }),
            }))
            onUpdateQuestions()
        },
        [onUpdateQuestions, updateSubsection]
    )

    const handleToggleRequiredQuestion = useCallback(
        (id, checked) => {
            const updates = { [id]: checked }
            onUpdateForRequired(updates)
        },
        [onUpdateForRequired]
    )

    const { someChecked, allChecked, handleToggleAll, handleToggleQuestion } = useQuestionToggles(
        subsection.questions,
        onUpdate
    )

    return (
        <div className="pb-4 pr-8">
            <QuestionTable
                someChecked={someChecked}
                allChecked={allChecked}
                handleToggleAll={handleToggleAll}
                handleToggleQuestion={handleToggleQuestion}
                categoryId={subsection.categoryId}
                disabled={disabled || some(subsection.questions, (q) => q.isRequired)}>
                {map(subsection.questions, (q) => (
                    <QuestionTableRow
                        key={`${q.questionEssenceId}-${q.text}`}
                        questionId={q.questionEssenceId ?? 0}
                        handleToggleRequiredQuestion={handleToggleRequiredQuestion}
                        name={q.name}
                        text={q.text}
                        disabled={disabled || q.isRequired}
                    />
                ))}
            </QuestionTable>
        </div>
    )
}

QuestionCategorySection.propTypes = {
    sectionName: PropTypes.string.isRequired,
    subsections: PropTypes.array.isRequired,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

function QuestionCategorySectionSkeleton() {
    return (
        <NonExpandableSection
            title={<Skeleton.HeaderShape className="w-64 pl-6" />}
            text={<Skeleton.ParagraphShape rows={5} className="pl-5" />}
        />
    )
}

QuestionCategorySection.Skeleton = QuestionCategorySectionSkeleton

export default QuestionCategorySection
