import { Collapse } from '@energage/components'
import { useMediaQuery, useToggle } from '@energage/hooks'
import { Icon, KeyboardArrowDown, KeyboardArrowUp } from '@energage/icons'
import { colors } from '@energage/theme'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import { usePermissions } from 'components/Identity'
import { TableCell, TableRow } from 'components/Table'
import { routes } from 'constants/routes'
import { AwardStatus } from 'containers/Main/EmployerRecognition/constants'
import { getSurveyNameLink } from 'containers/Main/EmployerRecognition/utils'
import { ReactComponent as TwpIcon } from 'images/twp-chevron-logo.svg'
import { media, viewports } from 'style/breakpoints'
import type { Awards, SurveyNameLinkT, SurveyOutcomeLinkT } from './awardHistory.types'

const QUALIFIED_NEW_SURVEY_FROM_YEAR = 2018

const TableRowContainer = styled.div`
    background-color: ${colors.white};
    border: 1px solid #d7d9e0;

    > div:after {
        border: 0;
    }

    :nth-child(even) {
        background-color: ${colors.grey100};
        div:after {
            background-color: ${colors.grey100};
        }
    }

    .topWorkplaceName {
        ${media.mdMin`
            min-width: 145px;
        `}

        ${media.lgMin`
            min-width: 195px;
        `}
    }

    .regionCategory {
        min-width: 160px;

        ${media.mdMin`
            width: 19%;
            min-width: 178px;
        `}
    }

    .year {
        width: 12%;
        min-width: 70px;

        ${media.mdMin`
            width: 6%;
        `}
    }
`

const SurveyNameLink = ({ surveyName, hrefUrl }: SurveyNameLinkT) => (
    <ExternalLink href={hrefUrl} target="_blank" rel="noopener noreferrer">
        {surveyName}
    </ExternalLink>
)

const SurveyOutcomeLink = ({ awardListParticipantId, salesforceYear, status, hasRevampDesign }: SurveyOutcomeLinkT) => {
    const isNotNamed = status === AwardStatus.NotNamed
    const isTopWorkplace = status === AwardStatus.Twp
    const isNationalStandard = status === AwardStatus.NationalStandard
    const isSurveyAfter2018 = salesforceYear >= QUALIFIED_NEW_SURVEY_FROM_YEAR
    const isTabletOrBigger = useMediaQuery({ minWidth: viewports.mdMin })
    const { hasEmployerBranding } = usePermissions()
    const isRevampNationalStandard = isNationalStandard && hasRevampDesign

    const TwpChevronIcon = isTabletOrBigger && isTopWorkplace && <Icon className="w-4 h-6 ml-4" as={TwpIcon} />

    if (isSurveyAfter2018 && (isNotNamed || isTopWorkplace || isRevampNationalStandard) && hasEmployerBranding) {
        return (
            <Link
                rel="noopener noreferrer"
                to={{
                    pathname: routes.awardAnnouncement({ awardListParticipantId }),
                }}>
                <span className="text-blue600 font-bold">{status}</span>
                {TwpChevronIcon}
            </Link>
        )
    }

    return (
        <>
            <span>{status}</span>
            {TwpChevronIcon}
        </>
    )
}

export const AwardHistoryTableRow = ({ award }: Awards) => {
    const {
        surveyEventId,
        awardListParticipantId,
        surveyName,
        status,
        salesforceYear,
        topWorkplaceName,
        regionCategoryName,
        isSurveyTrackerOnly,
        isInsightsReady,
        hasInsightsSubscription,
        hasRevampDesign,
    } = award
    const [isExpanded, expandBlock] = useToggle(false)
    const hrefUrl = getSurveyNameLink(
        surveyEventId,
        status,
        hasInsightsSubscription,
        isSurveyTrackerOnly,
        isInsightsReady
    )

    return (
        <TableRowContainer>
            <TableRow className="flex items-start text-sm sm:text-base">
                <TableCell className="w-1/4 hidden sm:inline-block flex-grow topWorkplaceName">
                    {topWorkplaceName}
                </TableCell>
                <TableCell className="w-5/12 sm:w-1/5 flex-grow regionCategory">{regionCategoryName}</TableCell>
                <TableCell className="w-1/3 sm:w-1/5 font-bold text-blue600 hidden sm:inline-block flex-grow overflow-hidden text-ellipsis">
                    <SurveyNameLink surveyName={surveyName} hrefUrl={hrefUrl} />
                </TableCell>
                <TableCell className="year">{salesforceYear}</TableCell>
                <TableCell className="w-32 sm:w-48">
                    <SurveyOutcomeLink
                        awardListParticipantId={awardListParticipantId}
                        salesforceYear={salesforceYear}
                        status={status}
                        hasRevampDesign={hasRevampDesign}
                    />
                </TableCell>
                <TableCell className="w-10 sm:hidden text-right">
                    <button onClick={expandBlock}>
                        <Icon as={isExpanded ? KeyboardArrowUp : KeyboardArrowDown} />
                    </button>
                </TableCell>
            </TableRow>
            <Collapse className="sm:hidden" open={isExpanded}>
                <div className="w-full p-2">
                    <p className="text-sm sm:text-base font-bold">{'Top Workplace Award'}</p>
                    <p className="text-xs sm:text-sm">{topWorkplaceName}</p>
                    <p className="text-sm sm:text-base font-bold mt-2">{'Survey Name'}</p>
                    <p className="text-xs sm:text-sm font-bold text-blue600">
                        <SurveyNameLink surveyName={surveyName} hrefUrl={hrefUrl} />
                    </p>
                </div>
            </Collapse>
        </TableRowContainer>
    )
}
