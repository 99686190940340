import PropTypes from 'prop-types'
import cx from 'clsx'
import styled from 'styled-components'

const className = 'text-black text-2xl font-semibold text-center'

const AwardSpecificLogo = styled.img`
    ${(props) => props.isPngDownload && 'width: 1200px; height: 400px;'};
    ${(props) => props.isPdfDownload && 'width: 100%;'};
`
const CenterAligned = ({
    awardLogoUrl,
    namedStreakYears,
    companyName,
    logoClassName,
    companyClassName,
    badgeYear,
    isPngDownload,
    isPdfDownload,
}) => (
    <div className="justify-center p-4">
        <div className={cx(className, companyClassName)}>{`${companyName} is a ${
            badgeYear ? `${badgeYear}` : ''
        }`}</div>
        <AwardSpecificLogo
            id="logo"
            src={awardLogoUrl}
            alt="Award Logo"
            className={cx(logoClassName, { 'mx-16': isPngDownload })}
            isPngDownload={isPngDownload}
            isPdfDownload={isPdfDownload}
        />
        {namedStreakYears && <div className={className}>{`${namedStreakYears} Years Running`}</div>}
    </div>
)

CenterAligned.propTypes = {
    awardLogoUrl: PropTypes.string.isRequired,
    namedStreakYears: PropTypes.number,
    year: PropTypes.number,
}

export default CenterAligned
