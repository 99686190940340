import { Children, createElement } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { routes } from 'constants/routes'
import auth, { hasAuthorization } from 'util/auth'
import { useAuthentication } from './Authentication'
import { useIdentity } from './Identity'

const AuthenticatedRoute = ({ authorize, requireSuperUser, children, component, render, ...props }) => {
    const identity = useIdentity()
    const { accessToken, isLoggedIn } = useAuthentication()

    if (!identity) {
        return null
    }

    return (
        <Route
            {...props}
            render={(routeProps) => {
                if (isAuthenticated(accessToken)) {
                    if (hasAuthorization(identity, authorize)) {
                        return renderRoute(children, component, render, routeProps)
                    } else {
                        return <Redirect to={routes.dashboard()} />
                    }
                } else if (isLoggedIn) {
                    // if User is logged in, wait until accessToken is retrieved
                    return null
                } else {
                    auth.login(window.location.pathname)
                }
            }}
        />
    )
}

const authorizePropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.number, PropTypes.bool])

AuthenticatedRoute.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
    render: PropTypes.func,
    authorize: PropTypes.oneOfType([authorizePropType, PropTypes.arrayOf(authorizePropType)]),
}

function isAuthenticated(accessToken) {
    return accessToken != null
}

function renderRoute(children, component, render, routeProps) {
    // This mostly re-implements react-router's logic for handling multiple ways to render a route
    if (Array.isArray(children) && children.length === 0) {
        children = null
    }

    if (typeof children === 'function') {
        children = children(routeProps)

        if (children === undefined) {
            children = null
        }
    }

    return children && Children.count(children) > 0
        ? children
        : routeProps.match
        ? component
            ? createElement(component, routeProps)
            : render
            ? render(routeProps)
            : null
        : null
}

export default AuthenticatedRoute
