export default {
    state: {
        companyLogoUrl: null,
    },
    reducers: {
        setCompanyLogoUrl(state, data) {
            state.companyLogoUrl = data
        },
    },
    selectors: (slice) => ({
        selectCompanyLogoUrl: () => slice(({ companyLogoUrl }) => companyLogoUrl),
    }),
}
