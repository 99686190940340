import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { isEmptyString } from 'util/strings'

const CREATE_OPTION_ID = 0

const removeEmptyOptions = filter<{ name: string }>((option) => !isEmptyString(option.name))
const retrieveOptionNames = map('name')
const updateNewOptionIds = map<{ id: string | number; name: string }, { id: number; name: string }>((option) => ({
    ...option,
    id: typeof option.id === 'string' ? CREATE_OPTION_ID : option.id,
}))

export const prepareMultiChoiceOptionsForEdit = pipe(removeEmptyOptions, updateNewOptionIds)
export const prepareMultiChoiceOptionsForCreation = pipe(removeEmptyOptions, retrieveOptionNames)
