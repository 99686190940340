// From TagType.cs
export const TagType = {
    Option: 1,
    Integer: 2,
    String: 3,
    Member: 4,
    Date: 5,
    Boolean: 6,
}

// From EnergageTags.cs
export const EnergageTag = {
    Department: {
        id: 1,
        name: 'Department',
        tagValueType: TagType.Option,
    },
    Supervisor: {
        id: 2,
        name: 'Supervisor',
        tagValueType: TagType.Member,
    },
    PhoneNumberConsent: {
        id: 4,
        name: 'PhoneNumberConsent',
        tagValueType: TagType.Boolean,
    },
    StartDate: {
        id: 5,
        name: 'StartDate',
        tagValueType: TagType.Date,
    },
    ApproximateSalary: {
        id: 6,
        name: 'ApproximateSalary',
        tagValueType: TagType.Integer,
    },
    IsFullTime: {
        id: 7,
        name: 'IsFullTime',
        tagValueType: TagType.Boolean,
    },
    JobGrade: {
        id: 8,
        name: 'JobGrade',
        tagValueType: TagType.Option,
    },
    LocationName: {
        id: 15,
        name: 'LocationName',
        tagValueType: TagType.Option,
    },
}
