import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import config from 'config'
import { routes } from 'constants/routes'
import colors from 'containers/Main/AwardAnnouncement/colors'
import { UPCOMING_AWARD_URL } from 'containers/Main/AwardAnnouncement/constants'
import withClassName from 'style/util/withClassName'

const mediaKitExternalLink = routes.branding.mediaKit()

export const StyledLink = styled(ExternalLink).attrs({})`
    color: ${colors.textDarkBlue};
    &:hover {
        color: ${colors.textDarkBlue};
    }
`

const LineBreaker = withClassName('my-3', 'div', 'div')
const className = 'text-blue600 font-bold underline'
const textClassName = 'font-bold'

export const faqList = (companyProfileUrl: string, isPdf?: boolean) => [
    {
        question: 'How are Top Workplaces awards determined?',
        answer: (
            <>
                {
                    'All Top Workplaces awards are based solely on employee feedback. There are no subjective elements to the process, making Top Workplaces the most credible, authentic employer recognition program.'
                }
                <LineBreaker className="mt-2" />
                {
                    'Top Workplaces are determined on quantitative employee feedback, as well as the statements and demographic information that we collect within the Workplace Survey. Because we have collected data from more than 23 million surveys at over 70,000 organizations, the Energage benchmark is the most accurate benchmark available.'
                }
                <LineBreaker />
                <StyledLink
                    className={className}
                    href={`${config.urls.topWorkplaces}/resources/national-top-workplaces-methodology/`}
                    target="_blank">
                    <p className={textClassName}> {'Go here for more information about Top Workplaces Methodology.'}</p>
                </StyledLink>
                <LineBreaker className="mt-4" />
                {
                    'Read more about our methodology for determining TopWorkplaces from Dr. Greg Barnett, Energage Chief People Scientist:'
                }
                <LineBreaker />
                <StyledLink className={className} href={'https://vimeo.com/538777429/56d31456c5'} target="_blank">
                    <p className={textClassName}> {'Watch What Makes a Top Workplace'}</p>
                </StyledLink>
            </>
        ),
    },
    {
        question: 'Can we use the results from one survey for all Top Workplaces awards?',
        answer: (
            <>
                {
                    'Yes! Completing the Workplace Survey puts you in the running for all Top Workplaces awards determined within 12 months from the close of your survey, including the Top Workplaces USA award, Regional, Industry, and Culture Excellence awards.'
                }
                <LineBreaker className="mt-4" />
                {'For more information:'}
                <LineBreaker />
                <StyledLink className={className} href={UPCOMING_AWARD_URL} target="_blank">
                    <p className={textClassName}> {'View the Top Workplaces National Awards schedule'}</p>
                </StyledLink>
                <LineBreaker />
                <StyledLink
                    className={className}
                    href={`${config.urls.topWorkplaces}/regional-program-information/`}
                    target="_blank">
                    <p className={textClassName}> {'View the Top Workplaces Regional Awards program information'}</p>
                </StyledLink>
            </>
        ),
    },
    {
        question: 'How can we promote our award?',
        answer: (
            <>
                {
                    'First, visit your media kit page. While your media kit may not yet be unlocked, it will be your home base for materials to promote your award. In the media kit, you can access the award logos that you have unlocked, templates to announce your award internally and externally, and ideas for how to use your award across your properties.'
                }
                <LineBreaker />
                <StyledLink target={!isPdf && '_blank'} className={className} href={mediaKitExternalLink}>
                    <p className={textClassName}> {'Go to the media kit'}</p>
                </StyledLink>
                <LineBreaker className="mt-4" />
                <span>{'Also be sure to update your'}</span>
                <span className="font-black">{' topworkplaces.com '}</span>
                <span>{`profile. This is your hub for promoting what makes your organization great. Promoting your profile on your website's Careers page or third-party recruiting sites is visible evidence that you care about your employee culture.`}</span>
                <LineBreaker />
                <StyledLink className={className} href={companyProfileUrl} target="_blank">
                    <p className={textClassName}> {'Update profile page'}</p>
                </StyledLink>
            </>
        ),
    },
]
