import createStore from './createStore'
import * as models from './models'

const store = createStore(models)

export default store
export const { dispatch, select } = store

export const { setIdentity, clearIdentity, updateCompanyName } = dispatch.identity
export const { selectIdentity } = select.identity

export const { setLoggedIn, updateTokens, setLoggedOut } = dispatch.authentication
export const { selectAuthentication, selectAccessToken } = select.authentication

export const { addHistory, addItems: addNavigationItems } = dispatch.navigation
export const { selectBackPath, selectNavigationItems } = select.navigation

export const { addNotification, dismissNotification, updateNotifications } = dispatch.notification
export const { selectNotifications } = select.notification

export const { setCompanyLogoUrl } = dispatch.companyLogoUrl
export const { selectCompanyLogoUrl } = select.companyLogoUrl

export const { setSurveyEventId, clearSurveyEventId } = dispatch.userMenu
export const { selectSurveyEventId } = select.userMenu

export const { enable: enableTwpWizard, disable: disableTwpWizard } = dispatch.twpWizard
export const { isTwpWizardEnabled } = select.twpWizard

export const { setUpdateActiveSurveys } = dispatch.updateActiveSurveys
export const { selectUpdateActiveSurveys } = select.updateActiveSurveys
