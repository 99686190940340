import forIn from 'lodash/forIn'
import head from 'lodash/head'
import join from 'lodash/join'
import mapKeys from 'lodash/mapKeys'
import filter from 'lodash/fp/filter'
import mapFp from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import pipe from 'lodash/fp/pipe'
import { endPoints } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureFactsConfiguration'
import { conjunctionJoin } from 'util/conjunctionJoin'

export const publishBodyParams = (filters) => {
    const params = {
        surveyEventId: filters.surveyEventName,
        locationId: filters.Locations,
        departmentId: filters.Departments,
        jobRoleId: filters.JobRoles,
    }

    let selectedFilters = {}
    forIn(params, (value, key) => {
        if (value && value !== -1) {
            selectedFilters = { ...selectedFilters, ...{ [key]: params[key] } }
        }
    })
    return selectedFilters
}

export const getUrl = (surveyCompanyId, serviceName, filters, surveyEventId) => {
    const forLinkedInBanner = serviceName === endPoints.LinkedInBanner
    const isCultureStatsOrLinkedInBanner =
        serviceName === endPoints.CultureStats || serviceName === endPoints.LinkedInBanner
    const isDownloadable = false

    // Call CultureStats API for LinkedInBanner until API can be updated
    if (forLinkedInBanner) {
        serviceName = endPoints.CultureStats
    }

    const url = `surveycompanies/${surveyCompanyId}/culturefacts/${serviceName}`

    const params = {
        locationId: filters.Locations,
        departmentId: filters.Departments,
        jobRoleId: filters.JobRoles,
    }
    const surveyEventID = surveyEventId || filters.surveyEventName || ''
    const constructURL = []

    mapKeys(params, (value, key) => {
        return value && value !== -1 ? constructURL.push(`${key}=${value}`) : null
    })
    const sendFilterParameter = head(constructURL) ? `?${join(constructURL, '&')}` : ''

    if (isCultureStatsOrLinkedInBanner) {
        return `${url}/${surveyEventID}/${isDownloadable}/${forLinkedInBanner}/${sendFilterParameter}`
    }

    return `${url}/${surveyEventID}/${isDownloadable}/${sendFilterParameter}`
}

export const getAriaCultureCloudDescription = (droplets) => {
    const sortedWords = pipe(filter('isSelected'), orderBy('weight', 'desc'), mapFp('text'))(droplets)

    const sentenceWords = conjunctionJoin('and', sortedWords)

    return `These are the Culture Cloud terms that best describe our organization in order: ${sentenceWords}.`
}

export const getAwardDownloadFilesURL = ({ pngUrl, pdfUrl, url }) => {
    return {
        pngUrl: pngUrl ?? '',
        pdfUrl: pdfUrl ?? '',
        htmlUrl: url ?? '',
    }
}

export const CultureStatsType = {
    PercentPositive: 1,
    TopXPercent: 2,
}
