import { alert } from '@energage/components'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useAuthenticationQuery } from 'api/apiQueries'
import { routes } from 'constants/routes'
import showErrorAlert from 'util/showErrorAlert'
import type { CompanyUserDto, CompanyUserSurveyEventDto, ErrorT, UserType } from './models'
import { DUPLICATED_COMPANY_USER_NAME_EXCEPTION, formatData } from './UserManagement.util'

export function useGetCompanyUser(surveyCompanyId: number, userId: number) {
    const queryClient = useQueryClient()

    return useAuthenticationQuery<CompanyUserDto, ErrorT>(
        ['get-company-user', userId],
        `companies/${surveyCompanyId}/users/${userId}`,
        {
            onError: (error) => {
                showErrorAlert('There was an error loading user data', error.parsed)
            },
            placeholderData: () => {
                const usersData: CompanyUserDto[] | undefined = queryClient.getQueryData([
                    'get-company-users',
                    surveyCompanyId,
                ])

                return usersData?.find((user: CompanyUserDto) => user.id === userId)
            },
        }
    )
}

export function useGetCompanyUsers(surveyCompanyId: number) {
    return useAuthenticationQuery<CompanyUserDto[], ErrorT>(
        ['get-company-users', surveyCompanyId],
        `companies/${surveyCompanyId}/users/information`,
        {
            onError: (error) => {
                showErrorAlert('There was an error loading users data', error.parsed)
            },
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )
}

export function useCreateCompanyUser(surveyCompanyId: number) {
    const history = useHistory()
    const queryClient = useQueryClient()

    return useAuthenticationQuery.mutate<unknown, ErrorT, UserType>(
        async (data, api) => await api.postJson(`companies/${surveyCompanyId}/users`, formatData(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['get-company-users', surveyCompanyId])
                history.push(routes.manage.users())
                alert.success('User was created successfully.')
            },
            onError: (err: ErrorT) => {
                if (err.parsed?.data?.code === DUPLICATED_COMPANY_USER_NAME_EXCEPTION) {
                    queryClient.invalidateQueries(['get-company-users', surveyCompanyId])
                }

                if (err.parsed?.data?.code) {
                    return
                }
                showErrorAlert('There was an error creating the user', err.parsed)
            },
        }
    )
}

export function useUpdateCompanyUser(surveyCompanyId: number, userId: number) {
    const queryClient = useQueryClient()

    return useAuthenticationQuery.mutate<unknown, ErrorT, UserType, unknown>(
        async (data, api) => await api.postJson(`companies/${surveyCompanyId}/users/${userId}`, formatData(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['get-company-users', surveyCompanyId])
                queryClient.invalidateQueries(['get-company-user', userId])
                alert.success('Changes saved successfully.')
            },
            onError: (err: ErrorT) => {
                if (err.parsed?.data?.code === DUPLICATED_COMPANY_USER_NAME_EXCEPTION) {
                    queryClient.invalidateQueries(['get-company-users', surveyCompanyId])
                }

                if (err.parsed?.data?.code) {
                    return
                }
                showErrorAlert('There was an error updating the user', err.parsed)
            },
        }
    )
}

export function useGetCompanySurveyEvents(surveyCompanyId: number) {
    return useAuthenticationQuery<CompanyUserSurveyEventDto[], ErrorT>(
        ['get-company-survey-events', surveyCompanyId],
        `companies/${surveyCompanyId}/surveyEvents`,
        {
            onError: (error) => {
                showErrorAlert('There was an error loading survey events', error.parsed)
            },
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )
}

export function useGetPasswordResetUrl(surveyCompanyId: number, userId: number) {
    return useAuthenticationQuery<{ url: string }, ErrorT>(
        ['get-password-reset-url', userId],
        `companies/${surveyCompanyId}/users/${userId}/resetpassword`,
        {
            enabled: false,
            onError: (error) => {
                showErrorAlert('There was an error loading the password reset url', error.parsed)
            },
        }
    )
}

export function useSendPasswordResetEmail(surveyCompanyId: number, userId: number, email: string) {
    return useAuthenticationQuery(
        ['send-password-reset-email', userId],
        `companies/${surveyCompanyId}/users/${userId}/resetpasswordemail`,
        {
            enabled: false,
            onSuccess: () => {
                alert.success(`Email with login successfully sent to ${email}`)
            },
            onError: (error: ErrorT) => {
                showErrorAlert('There was an error sending the password reset email', error.parsed)
            },
        }
    )
}

export function useGetSecuritySettingsStatus(surveyCompanyId: number) {
    return useAuthenticationQuery<{ isSsoEnabled: boolean; isMfaEnabled: boolean }, ErrorT>(
        ['get-security-settings-status', surveyCompanyId],
        `companies/${surveyCompanyId}/settings/security`,
        {
            onError: (error) => {
                showErrorAlert('There was an error retrieving security settings status', error.parsed)
            },
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )
}

export function useResetUserMfa(surveyCompanyId: number, userId: number, firstName: string, lastName: string) {
    return useAuthenticationQuery.mutate(
        async (_, api) => await api.postJson(`companies/${surveyCompanyId}/users/${userId}/resetmfa`),
        {
            onSuccess: () => {
                alert.success(
                    `Success! ${firstName} ${lastName} will be prompted to reset their MFA next time they sign in.`
                )
            },
            onError: (error: ErrorT) => {
                showErrorAlert('There was an error resetting the MFA configuration', error.parsed)
            },
        }
    )
}
