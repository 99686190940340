import { useContext, useState } from 'react'
import { Input, Toggle } from '@energage/components'
import { yupResolver } from '@hookform/resolvers/yup'
import cloneDeep from 'lodash/cloneDeep'
import { FormProvider, useForm } from 'react-hook-form'
import { number, object, string } from 'yup'
import FancyCheckbox from 'components/FancyCheckbox'
import LoadingButton from 'components/LoadingButton'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/strings'
import { optionalNumber } from 'util/validators'
import SurveySetupContext from '../../../SurveySetupContext'
import PaperContactInfo from './PaperContactInfo'

const ConfigurePaperFormat = ({
    contact,
    paperCount,
    spanishPaperCount,
    paperPrice,
    needsSpanish: _needsSpanish,
    hasCustomQuestions,
    hasOrderedPaperSurveys,
    regionName,
    isOrdering,
    paperOnly,
    onSubmit,
}) => {
    const { surveyHasLaunched: readOnly = true } = useContext(SurveySetupContext)
    const [agreeTerms, setAgreeTerms] = useState(false)
    const [needsSpanish, setNeedsSpanish] = useState(_needsSpanish)

    const formMethods = useForm({
        defaultValues: {
            paperCount,
            spanishPaperCount,
            contact,
        },
        resolver: yupResolver(schema),
        context: { regionName, paperOnly, needsSpanish },
        mode: 'onBlur',
    })

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors },
    } = formMethods

    const [watchedPaperCount, watchedSpanishCount] = watch(['paperCount', 'spanishPaperCount'])

    const paperOrderTotal = (watchedPaperCount * paperPrice).toFixed(2)

    const internalSubmit = (data) => {
        const submitData = cloneDeep(data)
        const state = data?.contact?.address?.state?.value
        if (state) {
            submitData.contact.address.state = state
        }

        onSubmit(submitData)
    }

    return (
        <>
            {!hasCustomQuestions && !hasOrderedPaperSurveys && (
                <FormProvider {...formMethods}>
                    <h5>{'Paper Surveys must be purchased in order to schedule your survey'}</h5>
                    <form className="pt-4" noValidate onSubmit={handleSubmit(internalSubmit)}>
                        <div className="flex">
                            <Input
                                label="Number of surveys"
                                className="w-full sm:w-32 text-sm mr-4"
                                type="number"
                                autoComplete="off"
                                {...register('paperCount', {
                                    onChange: () => trigger(['spanishPaperCount']),
                                    onBlur: () => trigger(['spanishPaperCount']),
                                })}
                                readOnly={readOnly}
                                min={0}
                            />
                            <Input
                                className="w-full sm:w-32"
                                label="Price Estimate"
                                readOnly={true}
                                type="text"
                                value={paperOrderTotal}
                            />
                        </div>

                        <div className="pt-4 sm:pt-8">
                            <p className="pb-4 text-sm text-grey500">{'Do you need Spanish language surveys?'}</p>
                            <Toggle
                                id="platform-preview-toggle"
                                label="Do you need Spanish language surveys?"
                                onContent="Yes"
                                offContent="No"
                                checked={needsSpanish}
                                onChange={() => setNeedsSpanish((state) => !state)}
                                disabled={readOnly}
                            />
                            {needsSpanish && (
                                <div className="pt-4 sm:pt-8 flex">
                                    <Input
                                        label={'# of Spanish language surveys'}
                                        className="w-full sm:w-64 pr-3"
                                        type="number"
                                        autoComplete="off"
                                        error={errors?.spanishPaperCount?.message}
                                        info={errors?.spanishPaperCount?.message}
                                        readOnly={readOnly}
                                        min={0}
                                        {...register('spanishPaperCount')}
                                    />
                                    <Input
                                        label={'# of English language surveys'}
                                        disabled={true}
                                        className="w-full sm:w-64 max-w-sm"
                                        name="englishPaper"
                                        type="number"
                                        autoComplete="off"
                                        value={Math.max(0, +watchedPaperCount - +watchedSpanishCount)}
                                    />
                                </div>
                            )}
                        </div>
                        {watchedPaperCount > 0 && (
                            <>
                                <div className="pt-4 sm:pt-8">
                                    <div className="text-left">
                                        <PaperContactInfo disabled={readOnly} />
                                    </div>
                                </div>

                                <div className="pt-4 sm:pt-8 w-full lg:w-2/3">
                                    <div className="text-left pb-4 sm:pb-8">
                                        <label>
                                            <FancyCheckbox
                                                onChange={() => setAgreeTerms((s) => !s)}
                                                disabled={readOnly}
                                                checked={agreeTerms}
                                                className="mr-2"
                                            />
                                            <span className="pt-3 text-sm text-primary-dark">
                                                {`I understand that my organization will be billed
                                                    $${paperOrderTotal} to cover the costs of printing,
                                                    shipping, return postage, and scanning ${watchedPaperCount}
                                                    paper surveys. I also understand that if I need to
                                                    return surveys for a refund, I cannot return fewer than 250.`}
                                            </span>
                                        </label>
                                    </div>
                                    <LoadingButton
                                        eventName="Order Paper Surveys"
                                        isLoading={isOrdering}
                                        variant="secondary"
                                        type="submit"
                                        disabled={readOnly || !agreeTerms}>
                                        {'Purchase Paper Surveys'}
                                    </LoadingButton>
                                </div>
                            </>
                        )}
                    </form>
                </FormProvider>
            )}
            {hasCustomQuestions && (
                <div className="pt-8 text-sm text-primary-dark">
                    <p className="font-bold pb-2">{'Paper Survey Order Completion'}</p>
                    <p className="pb-2">
                        {`You have selected to add custom items to your paper survey. This will require us to provide you with a custom quote for this work. `}
                    </p>
                    <p>{`Please contact ${SUPPORT_EMAIL} or call ${SUPPORT_PHONE} to complete your request.`}</p>
                </div>
            )}
            {hasOrderedPaperSurveys && (
                <p>{`Your request for ${paperCount} paper surveys has been submitted. We will contact you to complete the order.`}</p>
            )}
        </>
    )
}

export default ConfigurePaperFormat

const paperCountCheck = (message) => (paperCount, schema) => {
    return schema.max(paperCount, message)
}

const schema = object({
    paperCount: number().min(0),
    spanishPaperCount: optionalNumber
        .when('paperCount', paperCountCheck('Number of spanish paper surveys should be less than total'))
        .when('$needsSpanish', (needsSpanish, schema) => {
            if (needsSpanish) {
                return schema.min(0).required('Please provide a number of spanish paper surveys')
            }
        }),
    contact: object({
        firstName: string().required('required'),
        lastName: string().required(),
        phoneNumber: string().required(),
        address: object({
            street: string().required(),
            city: string().required(),
            state: object().required(),
            zipCode: string().required(),
        }),
    }).when('paperCount', (paperCount, schema) => {
        if (isNaN(paperCount) || paperCount === 0) {
            return schema
        }

        return schema.required()
    }),
})
