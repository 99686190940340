import { useCallback } from 'react'
import { alert, Checkbox, Tooltip } from '@energage/components'
import { Info } from '@energage/icons'
import { colors } from '@energage/theme'
import { useSelector } from 'react-redux'
import useSyncSurvey from 'containers/Organization/Upload/useSyncSurvey'
import { selectUpdateActiveSurveys, setUpdateActiveSurveys } from 'store'
import { useEmployeeData } from './EmployeeDataProvider'

export const useScheduleActiveSurveysSync = () => {
    const onSyncSuccess = () => {
        alert.success('All new changes will update active surveys.')
    }

    const { onSync } = useSyncSurvey(onSyncSuccess)
    const { organization } = useEmployeeData()
    const updateActiveSurveys = useSelector(selectUpdateActiveSurveys)

    const doSync = useCallback(() => {
        if (organization.hasActiveSurveys && updateActiveSurveys) {
            onSync({ delayInSeconds: 60 })
        }
    }, [onSync, updateActiveSurveys, organization])

    return { doSync }
}

export const UpdateActiveSurveysCheckbox = () => {
    const updateActiveSurveys = useSelector(selectUpdateActiveSurveys)
    const { organization } = useEmployeeData()

    if (!organization.hasActiveSurveys) {
        return null
    }

    return (
        <div className="flex-grow">
            <Checkbox
                label="Update Active Surveys"
                className="my-2 text-sm inline-flex"
                key={`updateActiveSurveys${organization.id}`}
                value={updateActiveSurveys}
                checked={updateActiveSurveys}
                onChange={() => setUpdateActiveSurveys(!updateActiveSurveys)}
            />

            <Tooltip
                placement="top"
                className="pb-1"
                renderTrigger={({ ref, getTriggerProps }) => (
                    <span ref={ref} {...getTriggerProps()} className="pl-1">
                        <Info size={16} color={colors.grey500} />
                    </span>
                )}>
                <div className="text-xs text-left">
                    {`Sends updated organization structure to all active surveys.`} <br />
                    {`Newly added employees will receive an invitation within 10-15 minutes.`} <br />
                    {`Removed employees will lose survey access and no longer receive invitations, but all responses will be preserved.`}
                </div>
            </Tooltip>
        </div>
    )
}
