import PropTypes from 'prop-types'
import { useMediaQuery } from '@energage/hooks'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled, { css } from 'styled-components'
import {
    LONG_ISLAND_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
    YEAR_2024,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { media, viewports } from 'style/breakpoints'

const getPositionForWidgetText = (
    isNationalStandard,
    isPngDownload,
    isMobileDevice,
    isDallasNationalStandard,
    isPdfDownload,
    isTwpUsa,
    isMinnesota
) => {
    switch (true) {
        case isNationalStandard && (!isDallasNationalStandard || !isMinnesota):
            return isPngDownload
                ? 'left: 37%; top: 390px;'
                : isPdfDownload
                ? 'left: 42%; top : 390px;'
                : 'left: 40%; top: 60px;'
        case isPngDownload:
            return isTwpUsa
                ? 'left: 50%; top : 335px;'
                : isMinnesota
                ? 'left: 38%; top : 390px;'
                : 'left: 33%; top: 554px;'
        case isMobileDevice && !isPdfDownload:
            return isTwpUsa ? 'left: 64%; top : 100px;' : 'left: 31%; top : 116px;'
        case isPdfDownload:
            return isTwpUsa
                ? 'left: 53%; top : 335px;'
                : isMinnesota
                ? 'left: 41%; top : 390px;'
                : 'left: 33%; top : 467px;'
        default:
            return isTwpUsa
                ? 'left: 45%; top : 100px;'
                : isMinnesota
                ? 'left: 45%; top : 390px;'
                : 'left: 35%; top : 122px;'
    }
}

const awardLogoCss = css`
    height: ${(props) => (props.isTwpUsa ? '220px' : '170px')};
    width: ${(props) => (props.isTwpUsa ? '57%' : props.isMinnesota ? '42%' : '100%')};
`
const awardLogoCssForFiles = css`
    height: 808px;
    width: ${(props) => (!props.isNationalStandard ? '1400px' : '')};
    max-width: ${(props) =>
        props.isTwpUsa ? (props.year < YEAR_2024 ? '65%' : '50%') : props.isMinnesota ? '42%' : '100%'};
`
const AwardLogoWrapper = styled.div`
    .award {
        ${(props) => (props.isAssetForDownload ? awardLogoCssForFiles : awardLogoCss)}
    }
`

const getAwardYearTextCoordinatesForSvg = (publisherId, year, yearsRange) => {
    switch (publisherId) {
        case TWP_USA_PUBLISHER_ID:
            return year < YEAR_2024
                ? { x: '287', y: yearsRange ? '336' : '348' }
                : { x: '172', y: yearsRange ? '415' : '427' }
        case REMOTE_WORK_PUBLISHER_ID:
            return { x: '174', y: yearsRange ? '238' : '248' }
        case MINNEAPOLIS_PUBLISHER_ID:
            return { x: '122', y: '288' }
        case LONG_ISLAND_PUBLISHER_ID:
            return {} // Leave values setup in the SVG markup intact/unaltered
        default:
            // TODO: We shouldn't be altering these values. Our SVG markup should be setup to adapt accordingly.
            //  We do **not** want to continue piling on publisher and/or award specific conditionals; this is not
            //  maintainable/scalable!
            return { x: '100', y: yearsRange ? '216' : '228' }
    }
}

const WidgetText = styled.p.attrs({
    className: 'absolute text-grey600 font-semibold leading-none text-center text-xs sm:text-sm leading-tight p-4',
})`
    ${(props) =>
        getPositionForWidgetText(
            props.isNationalStandard,
            props.isPngDownload,
            props.isMobileDevice,
            props.isDallasNationalStandard,
            props.isPdfDownload,
            props.isTwpUsa,
            props.isMinnesota
        )}};
    font-size: ${(props) => props.isPdfDownload && (props.isTwpUsa ? '23px' : '21px')};
    width: ${(props) => (props.isPngDownload && props.isTwpUsa && '30%') || (!props.isTwpUsa ? '64%' : '50%')};
    ${media.smMin`
       font-size: ${(props) =>
           !props.isPngDownload && !props.isPdfDownload
               ? '14px'
               : props.isPngDownload && props.isTwpUsa
               ? '32px'
               : '52px'};
    `}
`

const RegularBadge = ({
    awardLogoUrl,
    namedStreakYears,
    yearsRange,
    year,
    widgetText,
    isNationalStandard,
    isPngDownload,
    isDallasNationalStandard,
    isPdfDownload,
    publisherId,
}) => {
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMin })
    const yearTextCoordinates = getAwardYearTextCoordinatesForSvg(publisherId, year, yearsRange)

    return (
        <AwardLogoWrapper
            className="relative justify-center px-4 py-4 mb-4"
            year={year}
            isTwpUsa={publisherId === TWP_USA_PUBLISHER_ID}
            isMinnesota={publisherId === MINNEAPOLIS_PUBLISHER_ID}
            isAssetForDownload={isPngDownload || isPdfDownload}>
            <SvgLoader className="award" path={awardLogoUrl}>
                <SvgProxy
                    selector="text"
                    font-family="'Yantramanav', sans-serif"
                    font-size={yearsRange ? '30' : '46'}
                    font-weight="900"
                />
                <SvgProxy selector="text tspan" {...yearTextCoordinates}>
                    {`${yearsRange || year}`}
                </SvgProxy>
            </SvgLoader>
            {publisherId !== REMOTE_WORK_PUBLISHER_ID && (
                <WidgetText
                    isNationalStandard={isNationalStandard}
                    isPngDownload={isPngDownload}
                    isPdfDownload={isPdfDownload}
                    isMobileDevice={isMobileDevice}
                    isDallasNationalStandard={isDallasNationalStandard}
                    isTwpUsa={publisherId === TWP_USA_PUBLISHER_ID}
                    isMinnesota={publisherId === MINNEAPOLIS_PUBLISHER_ID}>
                    <span className="block">{widgetText}</span>
                    {namedStreakYears && <span className="block">{`${namedStreakYears} Years Running`}</span>}
                </WidgetText>
            )}
        </AwardLogoWrapper>
    )
}

RegularBadge.propTypes = {
    awardLogoUrl: PropTypes.string.isRequired,
    year: PropTypes.number,
    namedStreakYears: PropTypes.number,
    yearsRange: PropTypes.string,
}

export default RegularBadge
