import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import * as d3 from 'd3'
import styled from 'styled-components'
import withClassName from 'style/util/withClassName'
import { percentage } from 'util/formatters'

const innerRadius = 75
const outerRadius = 120

const LargeTextBlock = styled.tspan.attrs({ className: 'font-light' })`
    fill: ${colors.blue500};
    font-size: 45px;
`

const TextBlock = withClassName('text-lg font-light', 'tspan')

const ResponseRateChart = ({ responseRate, className, displayText = true, size = '250px' }) => {
    const respondedAngle = responseRate * (Math.PI * 2)

    const respondedArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(respondedAngle)

    const nonRespondedArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(respondedAngle)
        .endAngle(Math.PI * 2)

    const roundedResponseRate = percentage(responseRate, 1)

    return (
        <svg width={size} height={size} viewBox="-125 -125 250 250" className={className}>
            <path stroke={colors.white} fill={colors.blue500} d={respondedArc()} />
            <path stroke={colors.white} fill={colors.grey300} d={nonRespondedArc()} />
            <text x="0" y="0" textAnchor="middle" fill={colors.grey700} alignmentBaseline="central">
                <LargeTextBlock x="0" y={displayText ? 0 : 20}>{`${roundedResponseRate}`}</LargeTextBlock>
                {displayText && (
                    <>
                        <TextBlock x="0" y="30">
                            {`Response`}
                        </TextBlock>
                        <TextBlock x="0" y="50">
                            {`Rate`}
                        </TextBlock>
                    </>
                )}
            </text>
        </svg>
    )
}

export default ResponseRateChart

ResponseRateChart.propTypes = {
    responseRate: PropTypes.number.isRequired,
    className: PropTypes.string,
    displayText: PropTypes.bool,
    size: PropTypes.string,
}
