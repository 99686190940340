import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import some from 'lodash/some'

export const getFileFormats = (downloadableFileFormats) =>
    filter(
        map(downloadableFileFormats, (item) => item?.name),
        Boolean
    )

export const isFilenameWhiteLogo = (filename) => {
    const whiteLogoStrings = ['_WhiteNoBG']

    return some(whiteLogoStrings, (whiteString) => includes(filename, whiteString))
}

export const sectionBorderColor = ({ isDisabled, isSelected, hasError = false, isMediaKitLite = false }) => {
    if (hasError) {
        return 'border-red500'
    }
    if (isSelected && !isDisabled) {
        return 'border-blue400'
    } else if (isDisabled && !isMediaKitLite) {
        return 'border-gray400'
    }

    return 'border-white'
}

export const getYearPosition = (awardName) => {
    switch (awardName) {
        case 'Work-Life Flexibility':
        case 'Clued-In Leaders':
        case 'Empowering Employees':
        case 'Employee Value Prop':
        case 'Formal Training':
        case 'DE&I Practices':
        case 'Employee Well-Being':
        case 'Employee Appreciation':
        case 'Professional Development':
        case 'Compensation & Benefits':
        case 'Purpose & Values':
            return '253'
        case 'Financial Services':
        case 'Energy & Utilities':
            return '520'
        case 'Construction':
        case 'Healthcare':
        case 'Nonprofit':
        case 'Manufacturing':
        case 'Distribution':
        case 'Technology':
        case 'Education':
        case 'Real Estate':
            return '493'
        case 'Remote Work':
            return '252'
        default:
            return '280'
    }
}

export const removeNonAlphaCharacters = (inputString) => {
    const regex = /[^a-zA-Z]/g
    const resultString = inputString.replace(regex, '')

    return resultString
}
