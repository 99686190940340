import PropTypes from 'prop-types'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

const TopNavRoute = (props) => <AuthenticatedRoute {...props} />

const singleAuthorize = [PropTypes.string, PropTypes.func, PropTypes.number]

TopNavRoute.propTypes = {
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    component: PropTypes.elementType,
    authorize: PropTypes.oneOfType([...singleAuthorize, PropTypes.arrayOf(PropTypes.oneOfType(singleAuthorize))]),
}

export default TopNavRoute
