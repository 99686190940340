import { useWorkplaceSurveyQuery } from 'api'
import type {
    Aspiring,
    ClassicAspiring,
    ClassicWinner,
    Winner,
} from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'

type ClassicAspiringPdfResponse = ClassicAspiring & {
    experienceType: 0
}

type OldWinnerPdfResponse = ClassicWinner & {
    experienceType: 2
}

type NewAspiringPdfResponse = Aspiring & {
    experienceType: 1
}

type NewWinnerPdfResponse = Winner & {
    experienceType: 3
}

type AwardAnnouncementPdfResponse =
    | NewWinnerPdfResponse
    | NewAspiringPdfResponse
    | ClassicAspiringPdfResponse
    | OldWinnerPdfResponse

export const useFetchAwardAnnouncementPdf = (url: string, text: string) => {
    const { data, isFetching } = useWorkplaceSurveyQuery<AwardAnnouncementPdfResponse>([`${text}`], url)
    return {
        data: data,
        isLoading: isFetching,
    }
}
