import { Route, Switch } from 'react-router-dom'
import { routes } from 'constants/routes'
import AddRecipients from './AddRecipients'
import ModifyRecipients from './ModifyRecipients'
import RecipientsConfirmation from './RecipientsConfirmation'

const Recipients = () => (
    <Switch>
        <Route path={routes.survey.setup.recipients.add()} component={AddRecipients} />
        <Route path={routes.survey.setup.recipients.modify()} component={ModifyRecipients} />
        <Route path={routes.survey.setup.recipients()} component={RecipientsConfirmation} />
    </Switch>
)

export default Recipients
