import { useManagementQuery, useWorkplaceSurveyQuery } from 'api'
import { transformSurveyDefinition } from './statementSetupUtility/dataTransform'

export const getSurveyDefinition = async (surveyCompanyId, surveyEventId, api) => {
    const definition = await api.json(`surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}`)
    return transformSurveyDefinition(definition)
}

export default function useFetchSurveyDefinition(surveyEventId, surveyCompanyId, organizationId) {
    const { data, error, isLoading, refetch } = useWorkplaceSurveyQuery(
        ['survey-definition', surveyCompanyId, surveyEventId],
        (_, api) => getSurveyDefinition(surveyCompanyId, surveyEventId, api)
    )
    const { data: accountManager, isLoading: accountManagerIsLoading } = useManagementQuery(
        'organization-account-manager',
        `Organization/${organizationId}/AccountManager?nodefault=true`
    )

    return {
        data,
        accountManager,
        isLoading: accountManagerIsLoading || isLoading,
        error,
        refetch,
    }
}
