import { useBrandingQuery } from 'api'
import { useIdentity } from 'components/Identity'

export const useFetchSubscriber = (path: string, controller?: string) => {
    const { surveyCompanyId } = useIdentity()
    const action = controller ? `${controller}/` : ''
    const { data, isFetching, isError, error } = useBrandingQuery(
        [{ path }, surveyCompanyId],
        `${path}/${surveyCompanyId}/${action}Subscriber`
    )

    return { data, isFetching, isError, error }
}
