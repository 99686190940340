import { useToggle } from '@energage/hooks'
import cx from 'clsx'
import map from 'lodash/map'
import type { FAQSection, FAQsSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { YellowButton } from 'containers/Main/AwardAnnouncement/YellowButton'
import { faqList } from './faqList'

const FAQ = ({ question, answer }: FAQSection) => {
    const [isBoxExpanded, expandBox] = useToggle(false)

    return (
        <>
            <div className="block mt-4 sm:ml-4">
                <h2 className="text-xl sm:hidden">{question}</h2>
                <div className="flex flex-col-reverse align-items start sm:flex-row sm:mt-4" onClick={expandBox}>
                    <img
                        alt="logo"
                        className={cx('align self-center sm:self-start rotate-90 sm:rotate-0 transform', {
                            '-rotate-90 sm:rotate-90': isBoxExpanded,
                        })}
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        src={require('images/employerBranding/mediaKit/faqIcon.svg').default}
                    />
                    <h2 className="hidden sm:block sm:ml-4 text-2xl">{question}</h2>
                    {isBoxExpanded && <p className="mt-2 sm:hidden">{answer}</p>}
                </div>
                {isBoxExpanded && <p className="mt-2 ml-8 p-2 hidden sm:block">{answer}</p>}
            </div>
            <hr className="my-1 sm:my-4 border-t-3 h-4" />
        </>
    )
}

export const FAQs = ({
    companyProfileUrl,
    faqUrl,
    colors,
    companyId,
    year,
    baseUrl,
    isPdf,
    isCEAward,
    isIndustryAward,
}: FAQsSection) => (
    <Section bgColor={colors.bgColor} textColor={colors.textColor}>
        <h2 className="text-3xl sm:text-5xl font-black pb-6">{'Frequently Asked Questions'}</h2>
        {map(
            faqList(companyProfileUrl, companyId, year, baseUrl, isPdf, isCEAward, isIndustryAward),
            ({ question, answer }, index) => (
                <FAQ key={index} question={question} answer={answer} />
            )
        )}
        <div className="mt-12 text-center">
            <YellowButton variant="link" href={faqUrl} target="_blank">
                {'Top Workplaces FAQ'}
            </YellowButton>
        </div>
    </Section>
)
