import { differenceInDays } from 'date-fns'
import { SUPPORT_EMAIL } from 'constants/strings'
import { ReactComponent as SurveyNotificationIcon } from 'images/survey-notification.svg'
import { ReactComponent as TwpNotificationIcon } from 'images/twp-notification.svg'
import { fullDayOfWeekMonthDayYearDate } from 'util/formatters'

const beforeDaysMakeSurveyLaunchUrgent = 10
const afterDaysMakeListClosureUrgent = 1
const afterDaysMakeProfileDeadlineUrgent = 1

const contactSupportTeamNote = `Please reach out to our support team at ${SUPPORT_EMAIL} if you need help.`

export default {
    SurveyLaunch: {
        icon: SurveyNotificationIcon,
        iconBackground: 'bg-blue400',
        getDeadlineDate: ({ launchDate }) => launchDate,
        isUrgent: (deadlineDate) =>
            deadlineDate && differenceInDays(new Date(deadlineDate), new Date()) <= beforeDaysMakeSurveyLaunchUrgent,
        getTaskTakeoverInfo: ({ name: surveyName, launchDate }) => {
            if (!launchDate) {
                return
            }

            const noOfDays = differenceInDays(new Date(launchDate), new Date())

            if (noOfDays <= 1) {
                return {
                    title: 'Action Required!',
                    subtitle: `You have incomplete tasks that are required for survey launch. Please complete the tasks below so your ${surveyName} Survey can launch as scheduled.`,
                    reminderNote: contactSupportTeamNote,
                }
            }

            if (noOfDays <= 5) {
                return {
                    title: 'Important!',
                    subtitle: `${surveyName} is scheduled to launch in ${noOfDays} days.`,
                    reminderNote: 'These tasks must be completed before your survey can launch.',
                }
            }

            const displayLaunchDate = fullDayOfWeekMonthDayYearDate(new Date(launchDate))

            if (noOfDays <= 10) {
                return {
                    title: 'Welcome Back!',
                    subtitle: `Your ${surveyName} Survey is scheduled for ${displayLaunchDate}. These tasks must be completed before your survey can launch.`,
                }
            }
        },
    },

    ListClosure: {
        icon: TwpNotificationIcon,
        iconBackground: 'bg-yellow400',
        getDeadlineDate: ({ listClosureDate }) => listClosureDate,
        isUrgent: (deadlineDate) =>
            deadlineDate && differenceInDays(new Date(), new Date(deadlineDate)) > afterDaysMakeListClosureUrgent,
        getTaskTakeoverInfo: ({ programName, closedDate, listClosureDate }) => {
            if (!listClosureDate) {
                return
            }

            const noOfDaysToCloseList = differenceInDays(new Date(listClosureDate), new Date())
            const displayListClosureDate = fullDayOfWeekMonthDayYearDate(new Date(listClosureDate))

            if (noOfDaysToCloseList <= 2) {
                return {
                    title: 'Action Required!',
                    subtitle: `The registration window for ${programName} Program will be closed on ${displayListClosureDate}. Please complete these tasks or your company will not be considered for recognition in the ${programName} Program.`,
                    reminderNote: contactSupportTeamNote,
                }
            }

            if (!closedDate) {
                return
            }

            const noOfDays = differenceInDays(new Date(), new Date(closedDate))

            if (noOfDays >= 5) {
                return {
                    title: 'Important!',
                    subtitle: `The registration window for ${programName} Program will be closed on ${displayListClosureDate}. You must complete the tasks below to complete your registration.`,
                }
            }

            if (noOfDays >= 1) {
                return {
                    title: 'Welcome Back!',
                    subtitle: `The registration window for ${programName} Program will be closed on ${displayListClosureDate} and you still have a few incomplete tasks. Please complete the required tasks below to complete your registration.`,
                }
            }
        },
    },

    ProfileDeadline: {
        icon: TwpNotificationIcon,
        iconBackground: 'bg-yellow400',
        getDeadlineDate: ({ confirmProfileDeadline }) => confirmProfileDeadline,
        isUrgent: (deadlineDate) =>
            deadlineDate && differenceInDays(new Date(), new Date(deadlineDate)) > afterDaysMakeProfileDeadlineUrgent,
        getTaskTakeoverInfo: ({ programName, confirmProfileDeadline, notificationCallsCompleteDate }) => {
            if (!confirmProfileDeadline) {
                return
            }

            const noOfDaysToProfileDeadline = differenceInDays(new Date(confirmProfileDeadline), new Date())
            if (noOfDaysToProfileDeadline <= 2) {
                return {
                    title: 'Action Required!',
                    subtitle: `Please complete the tasks below or your company name cannot be published under for ${programName}.`,
                    reminderNote: contactSupportTeamNote,
                }
            }

            if (!notificationCallsCompleteDate) {
                return
            }

            const noOfDaysToNotify = differenceInDays(new Date(), new Date(notificationCallsCompleteDate))
            const displayProfileDeadlineDate = fullDayOfWeekMonthDayYearDate(new Date(confirmProfileDeadline))
            if (noOfDaysToNotify >= 5) {
                return {
                    title: 'Important!',
                    subtitle: `List for ${programName} is scheduled to be published very soon. You must complete all of the tasks below prior to ${displayProfileDeadlineDate}.`,
                }
            }

            if (noOfDaysToNotify >= 1) {
                return {
                    title: 'Welcome Back!',
                    subtitle: `List for ${programName} will be published soon. You must complete the tasks below or your company can not be published under Top Workplaces.`,
                }
            }
        },
    },
}
