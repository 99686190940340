import map from 'lodash/map'
import { routes } from 'constants/routes'
import Section from 'containers/Main/AwardNotification/Section'
import { listItems } from 'containers/Main/AwardNotification/Sections/FeatureYourCulture'
import YellowButton from 'containers/Main/AwardNotification/YellowButton'

const FeatureYourCulture = ({ bgColor, textColor, companyName, isPdf, baseUrl, sectionClassName }) => (
    <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName}>
        <h1 className="sm:text-5xl text-2xl font-black">{'And feature what makes your culture special and unique.'}</h1>
        <p className="sm:text-2xl text-lg pt-4 pb-0 sm:py-4 ">{`Employer Branding tools will help ${companyName} to stand out among the competition:`}</p>
        <div className="flex flex-col mt-12 bg-white px-3 py-6 shadow-xl">
            {map(listItems, ({ imgPath, heading, text }, index) => (
                <div key={index} className="flex flex-row px-3 py-6">
                    <img
                        alt="CE awards logos"
                        src={require(`images/employerBranding/mediaKit/${imgPath}`).default}
                        className="pb-0 pr-6 self-start w-24"
                    />
                    <div className="ml-4 w-3/4">
                        <h2 className="text-2xl text-center">{heading}</h2>
                        <p className="text-xl pt-2 text-center">{text}</p>
                    </div>
                </div>
            ))}
        </div>
        <div className="mt-12 text-center">
            <YellowButton href={isPdf ? `${baseUrl}branding` : routes.branding()} variant="link" target="_blank">
                {'Go to Employer Branding'}
            </YellowButton>
        </div>
    </Section>
)

export default FeatureYourCulture
