import { SvgLoader, SvgProxy } from 'react-svgmt'
import {
    BOSTON_PUBLISHER_ID,
    DALLAS_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'

const awardFontSize = (publisherId, isDallasRankedWinner) => {
    switch (publisherId) {
        case BOSTON_PUBLISHER_ID:
            return '33px'
        case DALLAS_PUBLISHER_ID:
            return isDallasRankedWinner ? '21.5px' : '46px'
        default:
            return '46px'
    }
}

const awardYearPosition = (publisherId, isDallasRankedWinner, year, showNationalStandardDetails) => {
    switch (publisherId) {
        case DALLAS_PUBLISHER_ID:
            return isDallasRankedWinner ? { x: '272', y: '28.5' } : { x: '102', y: '230' }
        case BOSTON_PUBLISHER_ID:
            return { x: '370', y: '67.5' }
        case MINNEAPOLIS_PUBLISHER_ID:
            return year < 2021 && !showNationalStandardDetails ? { x: '105', y: '255' } : { x: '122', y: '288' }
        case REMOTE_WORK_PUBLISHER_ID:
            return { x: '174', y: '250' }
        default:
            return { x: '102', y: '230' }
    }
}

export const ShowRegionalAwards = ({
    index,
    svgUrl,
    publisherId,
    year,
    isDallasRankedWinner,
    showNationalStandardDetails,
}) => {
    const fontFamily = publisherId === BOSTON_PUBLISHER_ID ? null : `'Yantramanav', sans-serif`
    const fontSize = awardFontSize(publisherId, isDallasRankedWinner)
    const yearPosition = awardYearPosition(publisherId, isDallasRankedWinner, year, showNationalStandardDetails)

    return (
        <SvgLoader
            path={svgUrl}
            key={index}
            height={isDallasRankedWinner || publisherId === BOSTON_PUBLISHER_ID ? '8rem' : '10rem'}
            alt="Regional awards logos"
            className="py-3 px-4">
            <SvgProxy selector="text" font-family={fontFamily} />
            <SvgProxy selector="text tspan" font-size={fontSize} font-weight="900" {...yearPosition}>
                {`${year}`}
            </SvgProxy>
        </SvgLoader>
    )
}
