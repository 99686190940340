import { DoneAll, ExitToApp, Group, Power, Security, ShoppingCart } from '@energage/icons'
import includes from 'lodash/includes'
import { Link, useLocation } from 'react-router-dom'
import config from 'config'
import { routes } from 'constants/routes'
import auth from 'util/auth'
import copyToClipboard from 'util/copyToClipboard'

const useGetNavItems = (identity, targetedSurveyEventId, close) => {
    const surveyManagerQuery = targetedSurveyEventId ? `e=${targetedSurveyEventId}` : `c=${identity.surveyCompanyId}`
    const hideManageSubscriptionPeakLink = !identity.zuoraAccountId || !identity.permissions.hasUserManagement
    const location = useLocation()
    const resultsUrl =
        includes(location.pathname, 'participation-review') && targetedSurveyEventId != null
            ? `${config.urls.results}/Home.aspx?e=${targetedSurveyEventId}&fcc=y&fpp=y&nav=1`
            : ''

    return [
        {
            items: [
                {
                    label: 'Manage Users',
                    icon: <Group />,
                    props: {
                        to: routes.manage.users(),
                        as: Link,
                        onSelect: close,
                    },
                    hidden: !identity.permissions.hasUserManagement,
                    appliesAdditionalProps: true,
                },
                {
                    label: 'Terms',
                    icon: <DoneAll />,
                    props: {
                        to: routes.terms(),
                        as: Link,
                        onSelect: close,
                    },
                    hidden: !identity.permissions.hasUserManagement,
                },
                {
                    label: 'Security',
                    icon: <Security />,
                    props: {
                        to: routes.settings.security(),
                        as: Link,
                        onSelect: close,
                    },
                    hidden: !config.featureFlag.enableSecurityManagement || !identity.permissions.hasSecurity,
                },
                {
                    label: 'Integrations',
                    icon: <Power />,
                    props: {
                        to: routes.integrations(),
                        as: Link,
                        onSelect: close,
                    },
                    hidden: !(
                        identity.permissions.hasEnableMsTeamsIntegration ||
                        identity.permissions.hasEnableHrisIntegration
                    ),
                },
                {
                    label: 'Manage Subscription',
                    icon: <ShoppingCart />,
                    props: {
                        href: identity.isSuperUser
                            ? `${config.urls.peakCommerce}/myAccount/index?accId=${identity.zuoraAccountId}&origin=salesforce`
                            : config.urls.peakCommerce,
                        as: 'a',
                        target: '_blank',
                    },
                    hidden: hideManageSubscriptionPeakLink,
                },
                {
                    label: 'Sign Out',
                    icon: <ExitToApp />,
                    props: {
                        to: '/logoutcallback',
                        onSelect: auth.logout,
                        as: 'button',
                        className: 'w-full text-left',
                    },
                    appliesAdditionalProps: true,
                },
            ],
        },
        {
            title: 'Internal Only',
            isInternal: true,
            hidden: !identity.isSuperUser,
            items: [
                {
                    label: 'Legacy Subscription Page',
                    props: {
                        to: routes.legacyOrder(),
                        as: Link,
                        onSelect: close,
                    },
                    appliesAdditionalProps: true,
                },
                {
                    label: 'View Account Summary',
                    props: {
                        href: `${config.urls.peakCommerce}/myAccount/index?accId=${identity.zuoraAccountId}&origin=salesforce`,
                        as: 'a',
                        target: '_blank',
                    },
                    appliesAdditionalProps: true,
                    hidden: hideManageSubscriptionPeakLink,
                },
                {
                    label: 'View Subscription',
                    props: {
                        href: `${config.urls.peakCommerce}/home/index?accId=${identity.zuoraAccountId}&origin=salesforce`,
                        as: 'a',
                        target: '_blank',
                    },
                    appliesAdditionalProps: true,
                    hidden: hideManageSubscriptionPeakLink,
                },
                {
                    label: 'Survey Manager',
                    props: {
                        href: `${config.urls.surveyManager}/SurveyEvent.aspx?${surveyManagerQuery}`,
                        as: 'a',
                        target: '_blank',
                    },
                    appliesAdditionalProps: true,
                },
                {
                    label: 'Insights',
                    props: {
                        href: resultsUrl,
                        as: 'a',
                        target: '_blank',
                    },
                    appliesAdditionalProps: true,
                    hidden: !resultsUrl,
                },
                {
                    label: 'Copy SuperUser Link',
                    props: {
                        as: 'button',
                        className: 'w-full text-left',
                        onSelect: () => {
                            const redirectPath = encodeURIComponent(window.location.pathname.substring(1))
                            copyToClipboard(
                                `${config.urls.surveyManager}/SuperUserToPage.ashx?type=PF&companyId=${identity.surveyCompanyId}` +
                                    `&surveyEventId=${identity.mostRecentSurveyEventId}&platformRoute=${redirectPath}`
                            )
                            return close && close()
                        },
                    },
                    appliesAdditionalProps: true,
                },
            ],
        },
    ]
}

export default useGetNavItems
