import { useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import find from 'lodash/find'
import map from 'lodash/map'
import safeInvoke from 'util/safeInvoke'
import { createSurveySetupAtom, demographicSectionAtom } from '../statementSetupUtility/store'

const demographicSubsectionFamily = atomFamily(
    ({ id }) => {
        return createSurveySetupAtom(
            (get) => {
                const section = get(demographicSectionAtom)
                return find(section.subsections, { subsectionId: id })
            },
            (get, set, update) => {
                const section = get(demographicSectionAtom)
                const newSection = {
                    ...section,
                    subsections: map(section.subsections, (subsection) => {
                        if (subsection.subsectionId === id) {
                            return safeInvoke(update, subsection)
                        }

                        return subsection
                    }),
                }
                set(demographicSectionAtom, newSection)
            }
        )
    },
    (a, b) => {
        return a.id === b.id
    }
)

export function useDemographicSubsection(subsectionId) {
    return useAtom(demographicSubsectionFamily({ id: subsectionId }))
}
