import { Paragraph } from './MediaKitExternalLetterModal'

export const UsaTodayExternalLetterModal = () => {
    return (
        <div className="px-5 py-5 text-grey500">
            <h5 className="text-grey600">{`Headline Options`}</h5>
            <Paragraph>{`[Company Name] Earns Prestigious 2024 Top Workplaces USA Employer Recognition Award`}</Paragraph>
            <Paragraph>{`[Company Name] Shines on the National Stage with the 2024 Top Workplaces USA Honor`}</Paragraph>
            <Paragraph>{`[Company Name] Secures Place Among the Nation's Top Workplaces in 2024`}</Paragraph>
            <h5 className="mt-10 text-grey600">{`Press Release Body`}</h5>
            <Paragraph>{`[Location], [Date] – [Company Name] announced it is a recipient of the prestigious 2024 Top Workplaces USA award, featured in USA TODAY. This achievement underscores the organization’s dedication to fostering a people-first workplace culture and prioritizing the employee experience.`}</Paragraph>
            <Paragraph>{`[Optional:  Add senior leader quote about receiving the Top Workplaces award]`}</Paragraph>
            <Paragraph>{`With a history spanning 17 years, the Top Workplaces program has surveyed over 27 million employees, spotlighting culture excellence across 60 regional markets. The Top Workplaces USA designation honors the nation’s greatest employers of choice.`}</Paragraph>
            <Paragraph>{`The national recognition celebrates organizations with 150 or more employees that have cultivated exceptional workplace environments. Of the more than 42,000 invited organizations, [Company Name] secured the award based solely on an employee engagement survey administered by Energage, the HR technology and research firm behind the Top Workplaces program. Survey results were evaluated by comparing responses to research-based statements that predict high performance against industry benchmarks.`}</Paragraph>
            <Paragraph>{`Eric Rubino, Energage CEO, commented, "The Top Workplaces award is a badge of honor and something companies should be very proud to showcase. The people-first cultures they have built and nurtured will set them apart in a highly competitive market. These organizations represent the best of the best."`}</Paragraph>
            <Paragraph>{`For media inquiries, please contact:`}</Paragraph>
            <Paragraph>{`[Contact Name]`}</Paragraph>
            <Paragraph>{`[Contact Email]`}</Paragraph>
            <Paragraph>{`[Contact Phone]`}</Paragraph>
            <Paragraph>{`[Company Logo]`}</Paragraph>
        </div>
    )
}
