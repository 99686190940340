import map from 'lodash/map'

export const setAllChecked = (checked) => (updates, id) => ({ ...updates, [id]: checked })

export function updateSelectedQuestions(setFn, updates) {
    setFn((sections) => {
        return map(sections, (section) => {
            return {
                ...section,
                questions: map(section.questions, (question) => {
                    if (updates[question.questionEssenceId] !== undefined) {
                        return { ...question, isSelected: updates[question.questionEssenceId] }
                    }
                    return question
                }),
            }
        })
    })
}
