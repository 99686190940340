export enum SurveyStatus {
    Active = 1,
    Closed,
    Approved,
    NotApprovedUrgent,
    NotApproved,
    Unscheduled,
    NewSurvey,
}

export const QueryKeys = {
    InFlightSurvey: 'dashboard:goodToKnow:inFlightSurvey',
    PostSurveySubscriber: 'dashboard:goodToKnow:postSurveySubscriber',
    NonPostSurveySubscriber: 'dashboard:goodToKnow:postSurveyNonSubscriber',
    SurveySetUp: 'dashboard:goodToKnow:surveySetUp',
    Overview: 'employerRecognition:goodToKnow:overview',
    Default: 'dashboard:goodToKnow:default',
}
