const SortDirection = {
    ascending: 'asc',
    descending: 'desc',

    toggle(direction) {
        return direction === SortDirection.ascending ? SortDirection.descending : SortDirection.ascending
    },
}

export default SortDirection
