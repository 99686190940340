import PropTypes from 'prop-types'
import { endOfDay } from 'date-fns'
import SummaryCardSkeleton from '../Common/SummaryCardSkeleton'
import ScheduleTile from './ScheduleTile'

const ScheduleTileFetch = ({ surveyEventId, data, isScheduleLoading, scheduleError }) => {
    if (isScheduleLoading) {
        return <SummaryCardSkeleton />
    }

    const schedule = data || {}
    const isEditable = new Date() < endOfDay(new Date(schedule.lastAvailableSurveyDate))

    return (
        <div data-testid="survey-setup-summary-schedule-tile">
            <ScheduleTile
                invitations={[
                    ...(schedule?.invitations ?? []),
                    ...(schedule?.scheduledCloseDateTime ? [{ scheduledTime: schedule.scheduledCloseDateTime }] : []),
                ]}
                completedTask={schedule.completedChooseScheduleTask}
                useScheduledSurveyClose={schedule.useScheduledSurveyClose}
                isEditable={isEditable}
                error={scheduleError}
                surveyEventId={surveyEventId}
                timeZone={schedule.timeZone}
                earliestStartDateTime={schedule.earliestStartDateTime}
                latestStartDateTime={schedule.latestStartDateTime}
                awardListDeadlineDateTimes={schedule.awardListScheduleCloseDates}
                lastAvailableSurveyDateTime={schedule.lastAvailableSurveyDate}
            />
        </div>
    )
}

ScheduleTileFetch.propTypes = {
    surveyEventId: PropTypes.string.isRequired,
}

export default ScheduleTileFetch
