import { useCallback } from 'react'
import { alert } from '@energage/components'
import noop from 'lodash/noop'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePostWithTaskCompletion } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import { useScheduleActiveSurveysSync } from '../../UpdateActiveSurveys'

function useRemoveDepartment(department, onComplete = noop) {
    const { organizationId } = useIdentity()
    const { doSync } = useScheduleActiveSurveysSync()

    const { doPost, isPosting, reset } = usePostWithTaskCompletion({
        url: `${config.api.platform}/Organizations/${organizationId}/Members/RemoveDepartment`,
        onComplete: (data) => {
            onComplete(data)
            alert.success(`Department dissolved successfully.`)
        },
        onError: (error) => {
            showErrorAlert('There was an error in dissolving department', error)
            reset()
        },
    })

    const remove = useCallback(() => {
        const { id: tagOptionId } = department
        doPost({
            tagOptionId,
        }).then(doSync)
    }, [department, doPost, doSync])

    return { remove, isRemoving: isPosting }
}

export default useRemoveDepartment
