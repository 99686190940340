import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { butterBar } from '@energage/components'
import every from 'lodash/every'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import trim from 'lodash/trim'
import { useParams } from 'react-router-dom'
import { management, useManagementQuery } from 'api'
import { useIdentity, usePermissions } from 'components/Identity'
import { ERRORS } from 'components/Upload'
import { collectColumnValues, convertFileToJson, mapCustomDemographicOptions, mapData } from 'components/Upload/helpers'
import { createAction, createDispatchableActions } from 'util/actionCreator'
import { uploadToStorage } from 'util/azure'
import readFile from 'util/readFile'
import showErrorAlert from 'util/showErrorAlert'

const headerInfo = {
    titles: {
        A: '* at least one of these fields is required*',
        D: '*required*',
        E: '*recommended*',
        F: '*optional*',
        J: '*uncommon*',
    },
    rows: 2,
}

const defaultState = {
    data: null,
    file: null,
    startRow: null,
    headerMapping: null,
    valuesMapping: null,
    rows: null,
    loading: false,
    onQueue: false,
    error: null,
    isFullUpload: false,
    duplicateLocations: [],
}

const Action = {
    RESET: 'RESET',
    PARSE_REQUEST: 'PARSE_REQUEST',
    PARSE_SUCCESS: 'PARSE_COMPLETE_SUCCESS',
    SET_ERROR: 'SET_ERROR',
    UPLOAD_REQUEST: 'UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPDATE_DEMOGRAPHICS: 'UPDATE_DEMOGRAPHICS',
    RESET_DEMOGRAPHICS: 'RESET_DEMOGRAPHICS',
    UPDATE_HEADER_MAPPING: 'UPDATE_HEADER_MAPPING',
    QUEUE_FILE: 'QUEUE_FILE',
    HAS_DUPLICATE_LOCATION: 'HAS_DUPLICATE_LOCATION',
}

const actions = {
    parseRequest: createAction(Action.PARSE_REQUEST),
    parseSuccess: createAction(Action.PARSE_SUCCESS),
    setError: createAction(Action.SET_ERROR),
    uploadRequest: createAction(Action.UPLOAD_REQUEST),
    uploadSuccess: createAction(Action.UPLOAD_SUCCESS),
    updateHeaderMapping: createAction(Action.UPDATE_HEADER_MAPPING),
    updateDemographics: createAction(Action.UPDATE_DEMOGRAPHICS),
    resetDemographics: createAction(Action.RESET_DEMOGRAPHICS),
    queueFile: createAction(Action.QUEUE_FILE),
    addDuplicateLocation: createAction(Action.HAS_DUPLICATE_LOCATION),
}

function reducer(state, action) {
    switch (action.type) {
        case Action.PARSE_REQUEST: {
            return { ...defaultState, loading: true }
        }
        case Action.QUEUE_FILE: {
            const { file, isFullUpload } = action.payload

            return { ...state, file, isFullUpload, onQueue: true, loading: true }
        }
        case Action.PARSE_SUCCESS: {
            return { ...state, ...action.payload, onQueue: false, loading: false }
        }
        case Action.HAS_DUPLICATE_LOCATION: {
            return {
                ...state,
                duplicateLocations: [
                    ...filter(state.duplicateLocations, (location) => location.index !== action.payload.index),
                    action.payload,
                ],
            }
        }
        case Action.RESET: {
            return defaultState
        }
        case Action.SET_ERROR: {
            return { ...state, error: action.payload, loading: false }
        }
        case Action.SUBMIT_REQUEST: {
            return { ...state, loading: true }
        }
        case Action.UPLOAD_REQUEST: {
            const data = {
                membersToUpload: action.payload,
                successful: false,
            }

            return { ...state, data, loading: true }
        }
        case Action.UPLOAD_SUCCESS: {
            const warnings = action.payload

            const prunedWarnings = filter(warnings, (warning) => warning.data.code !== 'ActiveSurveyEventWarning')

            const data = {
                ...state.data,
                uploadedMembers: state.membersToUpload,
                warnings: prunedWarnings,
                hasActiveSurveyEvents: size(prunedWarnings) !== size(warnings),
                successful: true,
            }

            return { ...state, data, loading: false }
        }
        case Action.UPDATE_DEMOGRAPHICS: {
            const { columnKey, valueKey, nextValue } = action.payload
            const { valuesMapping } = state

            const mappingsForColumn = valuesMapping[columnKey]
            const currentValueMapped = mappingsForColumn[valueKey]

            const nextColumnMappingValues = { ...currentValueMapped, mapping: nextValue }
            const nextMapping = { ...mappingsForColumn, [valueKey]: nextColumnMappingValues }
            const nextValuesMapping = { ...valuesMapping, [columnKey]: nextMapping }

            return { ...state, valuesMapping: nextValuesMapping }
        }
        case Action.RESET_DEMOGRAPHICS: {
            const { columnKey } = action.payload
            const { valuesMapping, headerMapping } = state

            const mappingsForColumn = valuesMapping[columnKey]
            const { mapping: mappedheader } = headerMapping[columnKey]

            const nextMapping = reduce(
                mappingsForColumn,
                (acc, value, key) => {
                    let resetValue = { ...value, mapping: null }

                    if (mappedheader?.hasDemographicOptions) {
                        const { customDemographicOptions } = mappedheader
                        resetValue = collectColumnValues(customDemographicOptions, value.originalText)
                    }

                    acc[key] = resetValue

                    return acc
                },
                {}
            )

            const nextValuesMapping = { ...valuesMapping, [columnKey]: nextMapping }

            return { ...state, valuesMapping: nextValuesMapping }
        }
        case Action.UPDATE_HEADER_MAPPING: {
            const { columnKey, mapping, removeMapping = false } = action.payload
            const { headerMapping } = state

            const nextHeaderColumn = { ...headerMapping[columnKey], mapping, removeMapping }
            const nextHeaderMapping = { ...headerMapping, [columnKey]: nextHeaderColumn }
            return { ...state, headerMapping: nextHeaderMapping }
        }
        default:
            return state
    }
}

async function fetchToken({ organizationId, surveyEventId, hasUploadRecipientsAtSurveyEventLevel, mimeType }) {
    const query = `mimeType=${mimeType}`
    if (hasUploadRecipientsAtSurveyEventLevel) {
        return management.get(`organization/${organizationId}/Bulkupload/upload-token/${surveyEventId}?${query}`).json()
    } else {
        return management.get(`organization/${organizationId}/Bulkupload/upload-token?${query}`).json()
    }
}

const useSetTestInviteeDemographicInfo = () => {
    const { organizationId } = useIdentity()

    return useManagementQuery.mutate(
        async (_, api) => await api.postJson(`Organization/${organizationId}/SetUpTestInviteeDemographics`, []),
        {
            onError: (err) => {
                showErrorAlert('Something went wrong. Your changes were not saved', err.parsed)
            },
        }
    )
}

export default function useBulkUpload(onPostComplete, opts = {}) {
    const [
        { data, duplicateLocations, startRow, file, headerMapping, valuesMapping, rows, loading, error, isFullUpload },
        dispatch,
    ] = useReducer(reducer, defaultState)
    const identity = useIdentity()
    const organizationId = identity.organizationId
    const { mutate: doSetInviteeDemographicInfo } = useSetTestInviteeDemographicInfo()
    const { hasUploadRecipientsAtSurveyEventLevel } = usePermissions()
    const { surveyEventId } = useParams()
    const { getFileUploadMSTags, postMembers, memberColumns, pendingDemographics, uploadedDemographicColumns } = opts

    const dispatchableActions = useMemo(() => createDispatchableActions(actions, dispatch), [dispatch])

    const {
        parseRequest,
        parseSuccess,
        setError,
        uploadRequest,
        uploadSuccess,
        updateHeaderMapping,
        resetDemographics,
        queueFile,
        onQueue,
    } = dispatchableActions

    useEffect(() => {
        forEach(data?.warnings, (w) => {
            butterBar.warning(w.data.message || w.data.code, {
                dismissible: true,
            })
        })
    }, [data])

    const reset = useCallback(() => {
        dispatch({ type: Action.RESET })
    }, [])

    const onComplete = useCallback(
        (res) => {
            // Mark the waiting members as successful
            uploadSuccess(res?.warnings)
            if (!hasUploadRecipientsAtSurveyEventLevel) {
                doSetInviteeDemographicInfo()
            }
        },
        [doSetInviteeDemographicInfo, hasUploadRecipientsAtSurveyEventLevel, uploadSuccess]
    )

    useEffect(() => {
        if (data?.successful && !loading) {
            onPostComplete(data)
        }
    }, [data, onPostComplete, loading])

    const doSaveUploadedFile = useCallback(
        async (file) => {
            const headers = {
                'x-ms-tags': getFileUploadMSTags(),
            }
            const { mimeType } = await readFile(file)
            fetchToken({ organizationId, surveyEventId, hasUploadRecipientsAtSurveyEventLevel, mimeType }).then(
                ({ uploadUrl }) => uploadToStorage({ url: uploadUrl, mimeType, blob: file, headers })
            )
        },
        [getFileUploadMSTags, organizationId, surveyEventId, hasUploadRecipientsAtSurveyEventLevel]
    )

    const submit = useCallback(
        ({ startRow, file, headerMapping, rows, isFullUpload, valuesMapping }) => {
            const customDemographicToRemove = map(
                filter(headerMapping, (c) => c.removeMapping),
                (c) => trim(c.originalText)
            )

            let members = filter(
                mapData({ columns: memberColumns, startRow, headerMapping, valuesMapping, rows, filterMapping: true }),
                (m) => m.emailAddress !== 'john.smith@sampleco.com' && m.emailAddress !== 'jane.doe@sampleco.com'
            )

            forEach(members, (member) => {
                member.additionalProperties = filter(member.additionalProperties, (c) =>
                    every(customDemographicToRemove, (r) => r !== c.key)
                )
            })

            const customDemographicTypeMapping = mapCustomDemographicOptions({
                headerMapping,
                valuesMapping,
                filterMapping: true,
            })

            uploadRequest(members)
            postMembers({ items: members, organizationId, isFullUpload, customDemographicTypeMapping })
                .then(onComplete)
                .catch((data) => {
                    setError(data)
                })
            doSaveUploadedFile(file)
        },
        [memberColumns, postMembers, organizationId, onComplete, doSaveUploadedFile, uploadRequest, setError]
    )

    const onSubmit = useCallback(() => {
        submit({ startRow, file, headerMapping, rows, isFullUpload, valuesMapping })
    }, [submit, startRow, file, headerMapping, rows, isFullUpload, valuesMapping])

    const processFile = useCallback(
        ({ file, isFullUpload }) => {
            const uploadedColumns = isFullUpload ? [] : uploadedDemographicColumns
            convertFileToJson({
                columns: memberColumns,
                headerInfo,
                file,
                uploadedColumns,
            })
                .then((data) => {
                    const payload = { ...data, file, isFullUpload }
                    parseSuccess(payload)
                })
                .catch((error) => {
                    const knownError = ERRORS[error.message]
                    setError(knownError || error)
                })
        },
        [memberColumns, parseSuccess, setError, uploadedDemographicColumns]
    )

    const fullUpdateHeaderMapping = useCallback(
        (payload) => {
            updateHeaderMapping(payload)
            resetDemographics(payload)
        },
        [updateHeaderMapping, resetDemographics]
    )

    const onFileDrop = useCallback(
        (file, isFullUpload) => {
            parseRequest()
            return pendingDemographics ? queueFile({ file, isFullUpload }) : processFile({ file, isFullUpload })
        },
        [processFile, pendingDemographics, parseRequest, queueFile]
    )

    const hasDuplicateError = useCallback(
        (duplicate, index) => {
            dispatchableActions.addDuplicateLocation({ duplicate, index })
        },
        [dispatchableActions]
    )

    useEffect(() => {
        if (file && onQueue && !pendingDemographics) {
            processFile({ file, isFullUpload })
        }
    }, [processFile, pendingDemographics, onQueue, file, isFullUpload])

    return {
        data,
        startRow,
        valuesMapping,
        headerMapping,
        rows,
        loading,
        error,
        duplicateLocations,
        hasDuplicateError,
        onFileDrop,
        onSubmit,
        reset,
        fullUpdateHeaderMapping,
        ...dispatchableActions,
    }
}
