import { LegacyButton as Button } from '@energage/components'
import { FormatPaint } from '@energage/icons'
import { colors } from '@energage/theme'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { RoundedIconHolder } from '../fragments'

const NewSurvey = () => (
    <div className="h-full text-center py-7">
        <RoundedIconHolder backgroundColor="bg-purple100 ">
            <FormatPaint fill={colors.purple700} size={80} />
        </RoundedIconHolder>

        <h5 className="text-purple700 px-10 pt-6 pb-10">{`Get employee feedback that makes a difference.`}</h5>
        <Button size="lg" as={Link} to={routes.survey.workplace()}>{`Create a Survey`}</Button>
    </div>
)

export default NewSurvey
