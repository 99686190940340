import { useEffect, useMemo } from 'react'
import { Skeleton } from '@energage/components'
import { useRouteMatch } from 'react-router-dom'
import { useGlobalFilter, usePagination, useTable } from 'react-table'
import type { Column, FilterType } from 'react-table'
import { useIdentity } from 'components/Identity'
import { useNavigation } from 'components/Navigation'
import { TopNav } from 'components/TopNav'
import { routes } from 'constants/routes'
import type { CompanyUserDto, CompanyUserRolesDto } from './models'
import { Columns, Table } from './Table'
import type { GlobalFilterValue, TableData } from './Table'
import { flattenDepartmentsFromSurveyEvents, getRoles, getSurveyCount } from './UserManagement.util'
import { useGetCompanySurveyEvents, useGetCompanyUsers } from './useUserManagementQueries'

function UserManagement() {
    const match = useRouteMatch(routes.manage.users())
    const { addItems } = useNavigation(match?.url)
    const {
        surveyCompanyId,
        isSuperUser,
        company: { isPublisher },
    } = useIdentity()
    const { data: companyUsersData, isFetching: isFetchingCompanyUsers } = useGetCompanyUsers(surveyCompanyId)
    const { data: surveyEventsData, isFetching: isFetchingSurveyEvents } = useGetCompanySurveyEvents(surveyCompanyId)

    useEffect(() => {
        addItems(match?.url, 'User Management', null)
    }, [addItems, match?.url])

    const companyUsers: TableData[] = useMemo(() => {
        if (isFetchingCompanyUsers || isFetchingSurveyEvents) {
            return Array(5).fill({})
        }

        if (!companyUsersData || !surveyEventsData) {
            return []
        }

        const flatDepartmentMap = flattenDepartmentsFromSurveyEvents(surveyEventsData)

        const companyUsers = companyUsersData.map((i: CompanyUserDto) => ({
            id: i.id,
            firstName: i.firstName,
            lastName: i.lastName,
            email: i.email,
            roles: getRoles(i, isPublisher),
            surveyCount:
                !isPublisher && (i.roles as CompanyUserRolesDto)?.insightsManager?.enabled
                    ? getSurveyCount(
                          (i.roles as CompanyUserRolesDto)?.insightsManager?.departments ?? [],
                          flatDepartmentMap
                      )
                    : 0,
            isCustomerSuccess: i.roles?.isCustomerSuccessPrimaryContact,
            lastActive: i.lastActiveDate ? new Date(i.lastActiveDate).toLocaleDateString() : null,
            isDisabled: i.isDisabled,
        }))

        return companyUsers
    }, [companyUsersData, surveyEventsData, isFetchingCompanyUsers, isFetchingSurveyEvents, isPublisher])

    const tableColumns: Column<TableData>[] = useMemo(
        () =>
            isFetchingCompanyUsers || isFetchingSurveyEvents
                ? Columns.map((column) => ({
                      ...column,
                      Cell: <Skeleton.SingleLineShape className="w-full" />,
                  }))
                : Columns,
        [isFetchingCompanyUsers, isFetchingSurveyEvents]
    )

    const hiddenColumns =
        isSuperUser && !isPublisher ? ['firstName', 'lastName'] : ['firstName', 'lastName', 'impersonate']

    const globalFilter: FilterType<TableData> = useMemo(
        () => (rows, ids, filterValue: GlobalFilterValue) => {
            return rows.filter((row) => {
                const filterText = filterValue.text.toLowerCase()
                const firstName = row.original.firstName?.toLowerCase()
                const lastName = row.original.lastName?.toLowerCase()

                const isMatch =
                    `${firstName} ${lastName}`.startsWith(filterText) ||
                    row.original.firstName?.toLowerCase().startsWith(filterText) ||
                    row.original.lastName?.toLowerCase().startsWith(filterText) ||
                    row.original.email?.toLowerCase().startsWith(filterText)

                if (isMatch && !filterValue.activeOnly) {
                    return true
                } else if (isMatch && filterValue.activeOnly && !row.original.isDisabled) {
                    return true
                }

                return false
            })
        },
        []
    )

    const tableInstance = useTable<TableData>(
        {
            columns: tableColumns,
            data: companyUsers,
            pageCount: -1,
            initialState: { hiddenColumns, globalFilter: { activeOnly: true, text: '' } },
            globalFilter,
        },
        useGlobalFilter,
        usePagination
    )

    return (
        <div data-testid="manageusers">
            <TopNav
                xsHidden
                title="User Management"
                showBackArrow={false}
                arrowBackRoute={undefined}
                className=""
                primaryAction={undefined}
                hideShadow={undefined}>
                <p className="max-w-lg h-20 py-6 px-6 text-primary-dark">
                    {'Add, change, and remove users and user permissions.'}
                </p>
                <Table {...tableInstance} />
            </TopNav>
        </div>
    )
}

export { UserManagement }
