import { Skeleton } from '@energage/components'
import TemplateImage from 'containers/Main/Survey/Templates/TemplateImage'
import Container from './Container'

const SurveyTemplateCardSkeleton = ({ className }) => {
    return (
        <Container className={className}>
            <TemplateImage skeleton />
            <div className="flex flex-col items-center sm:items-start justify-center px-5 mt-4 sm:mt-0 w-full">
                <Skeleton.RectangularShape className="h-5 w-2/3 mb-1" />
                <Skeleton.RectangularShape className="h-5" />
                <Skeleton.RectangularShape className="h-5" />
                <Skeleton.RectangularShape className="h-5 w-4/5" />
            </div>
            <div className="sm:hidden flex justify-center items-end sm:items-center h-full mt-5 mb-7 sm:my-0">
                <Skeleton.RectangularShape className="h-10 w-1/3" />
                <Skeleton.RectangularShape className="h-10 w-1/3" />
            </div>
        </Container>
    )
}

export default SurveyTemplateCardSkeleton
