import { useFetch } from './useFetch'

const tryInvoke =
    (func, defaultValue = null) =>
    (...args) =>
        func ? func(...args) : defaultValue

const render = (loading, error, data, rest) => match =>
    loading
        ? tryInvoke(match.loading)()
        : error
        ? tryInvoke(match.error)(error)
        : data
        ? match.data(data, rest)
        : null // prettier-ignore

function matchFetch(fetchMethod, url, opts) {
    const { data, isLoading, error, ...rest } = fetchMethod(url, opts)
    return render(isLoading, error, data, rest)
}

export function useMatchFetch(url, opts) {
    return matchFetch(useFetch, url, opts)
}
