import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import type { ContextProviderArg, Data } from './awardAnnouncement.types'
import { useFetchAwardAnnouncement } from './useFetchAwardAnnouncement'

const AwardAnnouncementContext = createContext<Data>({} as Data)

export const useAwardAnnouncementdata = () => useContext(AwardAnnouncementContext)

export const AwardAnnouncementProvider = ({ awardListParticipantId, children }: ContextProviderArg) => {
    const awardsData = { ...useFetchAwardAnnouncement(awardListParticipantId), awardListParticipantId } as Data
    return <AwardAnnouncementContext.Provider value={awardsData}>{children}</AwardAnnouncementContext.Provider>
}

AwardAnnouncementProvider.propTypes = {
    children: PropTypes.node,
}
