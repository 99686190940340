import { useLayoutEffect, useRef } from 'react'
import cx from 'clsx'
import { scaleQuantile } from 'd3'
import filter from 'lodash/filter'
import map from 'lodash/map'
import styled from 'styled-components'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { MINIMUM_DROPLETS_REQUIRED } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { getForegroundColor } from 'containers/Public/RevampWidgets/Tile/VerticalTile'
import { media } from 'style/breakpoints'
import { Watermark } from '../Widgets/Watermark'
import wordCloudBuilder from '../Widgets/WordCloud/WordCloudBuilder'
import type { Droplet } from './Widget.types'

interface FrameProps {
    isExpiredWidget?: boolean
    isSubscribed?: boolean
    minWidth?: number
}

const Frame = styled.div.attrs<FrameProps>(({ isExpiredWidget }) => ({
    className: cx('py-2 relative overflow-hidden', { 'opacity-25': isExpiredWidget }),
}))<FrameProps>`
    filter: blur(${(props) => (props.isSubscribed ? '0px' : '10px')});

    ${media.smMin`
    min-width: ${(props: { minWidth: string }) => (props.minWidth ? `${props.minWidth}px` : null)};
  `}
`

interface WordCloudProps {
    isVisible: boolean
    data: {
        ariaTitle: string
        ariaDescription: string
        droplets: Droplet[]
        hoverInteraction: boolean
        withSlash: boolean
        zoomToFit: boolean
        colors: {
            positive: string
            negative: string
            neutral: string
        }
        textWeightScale: string
        spiralType: string
        displaySize: {
            width: number
            height: number
        }
        chartSize: {
            width: number
            height: number
        }
        opacity: (d: Droplet) => number
    }
    isExpiredWidget: boolean
}

export function getOpacityScale(droplets: Droplet[]) {
    const scale = scaleQuantile<number>()
        .domain(map(droplets, (d) => d.weight))
        .range([0.5, 0.8, 1])
    return (d: { weight: number }) => scale(d.weight)
}

export function WordCloud({ isVisible, data, isExpiredWidget }: WordCloudProps): JSX.Element {
    const cloudContainer = useRef<HTMLDivElement>(null)
    const wordCloud = useRef<ReturnType<typeof wordCloudBuilder.create> | null>(null)

    useLayoutEffect(() => {
        wordCloud.current = wordCloudBuilder.create(cloudContainer.current, data)
        return () => wordCloudBuilder.destroy(wordCloud.current)
    }, [data])

    return (
        <Frame
            isSubscribed={isVisible}
            isExpiredWidget={isExpiredWidget}
            minWidth={data.displaySize.width}
            ref={cloudContainer}>
            {isExpiredWidget && <Watermark />}
        </Frame>
    )
}

interface LinkedInBannerWordCloudProps {
    ariaTitle?: string
    ariaDescription?: string
    droplets: Droplet[]
    backgroundColor?: string
    isExpiredWidget?: boolean
    isVisible?: boolean
    isDownloadable?: boolean
}

export const LinkedInBannerWordCloud = ({
    ariaTitle,
    ariaDescription,
    droplets,
    backgroundColor,
    isExpiredWidget,
    isVisible,
    isDownloadable,
}: LinkedInBannerWordCloudProps) => {
    const foregroundColor = getForegroundColor(backgroundColor)
    const opacity = getOpacityScale(droplets)
    const selectedDroplets = filter(droplets, 'isSelected').slice(0, 12)
    const minDropletsRequired = droplets.length >= MINIMUM_DROPLETS_REQUIRED

    return (
        <>
            {minDropletsRequired ? (
                <WordCloud
                    isVisible={isVisible ?? false}
                    data={{
                        ariaTitle: ariaTitle ?? '',
                        ariaDescription: ariaDescription ?? '',
                        droplets: selectedDroplets,
                        hoverInteraction: false,
                        withSlash: false,
                        zoomToFit: true,
                        colors: {
                            positive: foregroundColor,
                            negative: foregroundColor,
                            neutral: foregroundColor,
                        },
                        textWeightScale: 'bold',
                        spiralType: 'rectangular',
                        displaySize: {
                            width: isDownloadable ? 644 : 322,
                            height: isDownloadable ? 366 : 183,
                        },
                        chartSize: {
                            width: isDownloadable ? 644 : 322,
                            height: isDownloadable ? 366 : 183,
                        },
                        opacity,
                    }}
                    isExpiredWidget={isExpiredWidget ?? false}
                />
            ) : (
                <InsufficientDataWarning warningText="Not enough data for culture cloud" />
            )}
        </>
    )
}
