import { Checkbox, Input, Skeleton } from '@energage/components'
import noop from 'lodash/noop'
import type { Column } from 'react-table'
import styled from 'styled-components'
import type { AwardSelectionWithRHFKey } from '../awardselection.types'
import { Description, Label, StyledEmployeeCountInput } from './AwardSelectionTable.atoms'
import { AwardSelectionTableDesktop } from './AwardSelectionTableDesktop'
import { AwardSelectionTableMobile } from './AwardSelectionTableMobile'

const DisabledCheckbox = () => <Checkbox disabled={true} />

const columns: Column<AwardSelectionWithRHFKey>[] = [
    {
        id: 'selection',
        Header: () => <DisabledCheckbox />,
        Cell: () => <DisabledCheckbox />,
        width: 0,
    },
    {
        Header: 'Award',
        id: 'title',
        minWidth: 200,
        maxWidth: 300,
        accessor: () => '',
        Cell: () => <Skeleton.SingleLineShape />,
    },
    {
        Header: 'Year',
        id: 'year',
        accessor: () => '',
        minWidth: 75,
        Cell: () => <Skeleton.SingleLineShape />,
    },
    {
        Header: 'Locations in award region',
        id: 'locations',
        minWidth: 400,
        accessor: () => '',
        Cell: () => <Skeleton.SingleLineShape />,
    },
    {
        Header: 'Employees in award region',
        id: 'employees',
        accessor: () => '',
        Cell: () => <StyledEmployeeCountInput disabled={true} size="sm" />,
    },
]

const makeFakeAward = (_: undefined, i: number): AwardSelectionWithRHFKey => ({
    id: i,
    title: '',
    isSelected: false,
    isFinalized: true,
    minimumEmployeeCount: 0,
    locations: [],
    publisherLogoUrl: '',
    isEmployeeCountConfirmed: false,
    employeeCount: 0,
    year: 0,
    isNational: false,
    rhfKey: '' + i,
    awardId: 1,
    sizeBands: [],
})

const fakeAwards = Array.from({ length: 5 }, makeFakeAward)

export function AwardSelectionDesktopTableSkeleton() {
    return <AwardSelectionTableDesktop awards={fakeAwards} columns={columns} />
}

const SkeletonLine = styled(Skeleton.SingleLineShape)<{ $width: number }>`
    width: ${(props) => props.$width}px;
`

const renderMobileTitle = () => <SkeletonLine $width={150} />

function AwardLayout() {
    return (
        <>
            <Skeleton.RectangularShape className="h-16 w-32" />
            <Label className="mt-2">{'Awards'}</Label>
            <Description>
                <Skeleton.SingleLineShape />
            </Description>
            <Label className="mt-2">{'Locations in award region'}</Label>
            <Description>
                <Skeleton.SingleLineShape />
            </Description>
            <Label className="mt-2">{'Employees in award region'}</Label>
            <Description>
                <Input readOnly={true} disabled={true} size="sm" />
            </Description>
        </>
    )
}

export function AwardSelectionAccordionSkeleton() {
    return (
        <AwardSelectionTableMobile
            awards={fakeAwards}
            onSelectAll={noop}
            awardLayout={AwardLayout}
            renderTitle={renderMobileTitle}
            selectAllCheckbox={DisabledCheckbox}
            selectRowCheckbox={DisabledCheckbox}
            canSelectNational={false}
            overlappingGroups={[]}
        />
    )
}

export function AwardSelectionTableSkeleton() {
    return (
        <>
            <section className="hidden sm:block">
                <AwardSelectionDesktopTableSkeleton />
            </section>
            <section className="sm:hidden">
                <AwardSelectionAccordionSkeleton />
            </section>
        </>
    )
}
