import styled from 'styled-components'

type SummaryCardIconProps = {
    children: React.ReactNode
    className?: string
    width?: string
    color?: string
    ariaLabel?: string
}

export const CardIconComponent: React.FC<SummaryCardIconProps> = ({ children, className, ariaLabel = '' }) => {
    return (
        <>
            {ariaLabel ? (
                <div aria-label={ariaLabel} role="img" className={className}>
                    {children}
                </div>
            ) : (
                <div aria-hidden={true} role="img" className={className}>
                    {children}
                </div>
            )}
        </>
    )
}

export const CardIcon = styled(CardIconComponent)`
    display: flex;
    color: ${({ color }) => color || 'inherit'};
    width: ${({ width }) => width || 'auto'};
`
