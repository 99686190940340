import { Loading as Spinner } from '@energage/components'
import auth from 'util/auth'

// Callback from Auth0
// Calls all external applications to logout from
// Redirects back to main application root for it to decide what to do
const LogoutCallback = ({ history }) => {
    auth.externalLogout()
        .then(() => history.replace('/'))
        .catch(() => history.replace('/'))

    return <Spinner />
}

export default LogoutCallback
