import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import { format } from 'date-fns'
import head from 'lodash/head'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { useManagementQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { ENERGAGE_URL } from 'constants/strings'
import {
    DALLAS_PUBLISHER_ID,
    EMPLOYEE_FEEDBACK_URL,
    EnergageCeoStatement,
    REMOTE_WORK_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { MonsterPressReleaseFormDetails } from 'containers/Public/MediaKit/MonsterPressReleaseFormDetails'
import withClassName from 'style/util/withClassName'
import { DATE_FORMAT } from 'util/formatters'
import aboutEnergageText from './aboutEnergageText'
import DownloadPressReleaseFormButton from './DownloadPressReleaseFormButton'
import { useFetchPressReleaseData } from './useFetchPressReleaseData'

const titleClass = 'text-purple700 font-bold text-2xl uppercase'
const SubSection = withClassName('flex mt-8 mr-6', 'div')

export const PressReleaseFormDetails = ({
    publisherId,
    surveyCompanyId,
    awardListParticipantId,
    awardCategoryId,
    publishYear,
    isMediaKitLite,
    isNationalStandard,
    awardImageUrl,
    isPreviewPage,
}) => {
    const { data, isLoading, isError } = useFetchPressReleaseData(
        awardCategoryId,
        publisherId,
        publishYear,
        surveyCompanyId,
        isNationalStandard
    )

    const logoUrls = useManagementQuery(['company-logo', surveyCompanyId], 'Organization/Logo')
    const companyLogoUrl = logoUrls?.data?.previewUrl
    const awardImageUrlPath = head(awardImageUrl)

    if (isLoading) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const {
        description,
        quote,
        city,
        publishDate,
        contactName,
        contactEmail,
        contactPhone,
        url,
        awardName,
        publisherName,
        region,
        stateName,
        companyName,
        mediaKitLiteExperience,
    } = data

    const downloadPdfProps = {
        label: 'Download',
        variant: 'secondary',
        awardListParticipantId,
        publisherId,
        awardCategoryId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
    }

    if (publisherId === REMOTE_WORK_PUBLISHER_ID) {
        return (
            <MonsterPressReleaseFormDetails
                titleClass={titleClass}
                data={data}
                companyLogoUrl={companyLogoUrl}
                publishYear={publishYear}
                awardImageUrl={awardImageUrl}
                isMediaKitLite={isMediaKitLite}
                isPreviewPage={isPreviewPage}
                downloadPdfProps={downloadPdfProps}
            />
        )
    }

    return (
        <>
            <div className="px-24 md:px-48 mt-8">
                <div className={cx(titleClass, 'text-center')}>
                    {companyLogoUrl && <img src={companyLogoUrl} alt="Logo" className="inline mb-6 h-16 w-auto" />}
                    <p>{`${publisherName} names ${companyName}`}</p>
                    <p>{`a winner of the ${region} top workplaces ${publishYear} award`}</p>
                </div>
                <div className="text-xl mt-8">
                    {`${city}, ${stateName} ${format(
                        new Date(publishDate),
                        DATE_FORMAT
                    )} - ${companyName} has been awarded a Top Workplaces ${publishYear} honor by The ${awardName}. This list is based solely on `}
                    <ExternalLink href={EMPLOYEE_FEEDBACK_URL} target={EMPLOYEE_FEEDBACK_URL}>
                        {'employee feedback'}
                    </ExternalLink>
                    {' gathered through a third-party survey administered by employee engagement technology partner '}
                    <ExternalLink href={ENERGAGE_URL} target={ENERGAGE_URL}>
                        {'Energage'}
                    </ExternalLink>
                    {
                        ', LLC. The confidential survey uniquely measures the employee experience and its component themes, including employees feeling Respected & Supported, Enabled to Grow, and Empowered to Execute, to name a few.'
                    }
                    <div className="mt-8">{EnergageCeoStatement}</div>
                    <div className="mt-8 whitespace-pre-wrap">{quote}</div>
                </div>
            </div>
            <SubSection>
                <div className={cx(titleClass, 'text-right w-1/5')}>{'about'}</div>
                <div className="flex flex-col px-8 w-4/5">
                    <div className="text-xl whitespace-pre-wrap">{description}</div>
                    <SubSection>
                        <p className="font-bold text-lg">{'Company Contact'}</p>
                        <div className="text-base px-5">
                            <p>{`${contactName}`}</p>
                            <p>{`${contactEmail}, ${contactPhone}`}</p>
                            <p>{`${url}`}</p>
                        </div>
                    </SubSection>
                </div>
            </SubSection>
            {!mediaKitLiteExperience && !isMediaKitLite && (
                <SubSection>
                    <p className={cx(titleClass, 'text-right w-1/5')}>{'awards'}</p>
                    <div className="px-8">
                        <div className="relatives">
                            <SvgLoader
                                path={awardImageUrlPath}
                                alt="award-images"
                                className={isNationalStandard && publisherId !== DALLAS_PUBLISHER_ID ? 'w-20' : 'w-64'}>
                                <SvgProxy
                                    selector="text tspan"
                                    font-family="'Yantramanav', sans-serif"
                                    font-size="46"
                                    font-weight="900">
                                    {`${publishYear}`}
                                </SvgProxy>
                            </SvgLoader>
                        </div>
                    </div>
                </SubSection>
            )}
            <SubSection>
                <p className={cx(titleClass, 'text-right w-1/5')}>{'about energage'}</p>
                <div className="text-base px-8 w-4/5">{aboutEnergageText(publisherId)}</div>
            </SubSection>
            <div className="flex justify-center my-8 pb-16">
                <DownloadPressReleaseFormButton {...downloadPdfProps} />
            </div>
        </>
    )
}
