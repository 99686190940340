import { LegacyButton as Button } from '@energage/components'
import { CheckCircle } from '@energage/icons'
import { colors } from '@energage/theme'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AccessibleTileStatus, DAY_MONTH_YEAR_FORMAT, RoundedIconHolder, SurveyDates } from '../fragments'

const Ready = ({ id, surveyName, launchDateTime, scheduledCloseDateTime }) => (
    <div className="h-full text-center">
        {surveyName}
        <AccessibleTileStatus backgroundColor="bg-purple700" statusText={'Ready'} />
        <SurveyDates launchDateTime={launchDateTime} closeDateTime={scheduledCloseDateTime} />
        <RoundedIconHolder backgroundColor="bg-purple100 mt-7 ">
            <CheckCircle fill={colors.purple700} size={80} />
        </RoundedIconHolder>
        <h5 className="text-purple700 px-10 py-6">{`Your survey is ready to go! It will launch on  ${format(
            new Date(launchDateTime),
            DAY_MONTH_YEAR_FORMAT
        )}. `}</h5>
        <div className="flex justify-center py-4 border-grey200 border-t-2">
            <Button
                size="sm"
                variant="secondary"
                as={Link}
                to={routes.survey.setup.summary({ surveyEventId: id })}>{`View Survey`}</Button>
        </div>
    </div>
)

export default Ready
