import reduce from 'lodash/reduce'
import getFileHeader from './getFileHeader'
import getMimeType from './getMimeType'

function arrayToDataUrl(mimeType, dataArray) {
    const string = reduce(dataArray, (builtString, byte) => builtString + String.fromCharCode(byte), '')
    const base64String = btoa(string)
    return `data:${mimeType};base64,${base64String}`
}

export default function readFile(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = function (e) {
            const fullArray = new Uint8Array(e.target.result)

            const header = getFileHeader(fullArray)
            const mimeType = getMimeType(header, file.type)
            const dataUrl = arrayToDataUrl(mimeType, fullArray)

            const data = {
                mimeType,
                dataUrl,
                size: file.size,
            }

            resolve(data)
        }

        fileReader.onerror = function (e) {
            reject(e)
        }

        fileReader.readAsArrayBuffer(file)
    })
}
