import { useCallback, useMemo } from 'react'
import { HierarchyMultiSelect, InputGroup, useHierarchyMultiSelect } from '@energage/components'
import type { HierarchyOption as Option } from '@energage/components'
import { useCustomDemographicTargets } from '../CustomDemographicTargetContext'

type Settings = Parameters<typeof useHierarchyMultiSelect>[1]
type Id = string | number

const placeholder = {
    clearSelection: 'Select Demographics',
    allSelected: 'All Demographics',
    someSelected: (count: number) => `${count} Selected Demographics`,
}

const settings: Settings = {
    initialSelected: 'all',
    placeholder,
    root: {
        name: 'All Demographics',
    },
}

type DemographicQuestion = {
    demographicTypeId: number
    demographicTypeName: string
    answerOptions: {
        id: number
        name: string
    }[]
}

type TargetDemographic = {
    demographicTypeId: number
    demographicIds: number[]
}

interface DemographicTargetProps {
    demographicQuestions: DemographicQuestion[]
    handleDemographicTargetChange: (targetDemographics: TargetDemographic[]) => void
    selectedDemographicTargets?: TargetDemographic[]
}

const ALL_DEMOGRAPHIC_ID = 'allDemographics'

const getUpdatedTargetDemographic = (selectedOptions: Id[]) => {
    if (selectedOptions.includes(ALL_DEMOGRAPHIC_ID) || selectedOptions.length === 0) {
        return []
    }

    const targetDemographics: TargetDemographic[] = []
    selectedOptions.forEach((customId) => {
        const [parentId, id] = customId.toString().split(':').map(Number)

        const match = targetDemographics.find(({ demographicTypeId }) => demographicTypeId === parentId)
        if (match) {
            match.demographicIds.push(id)
        } else {
            targetDemographics.push({
                demographicTypeId: parentId,
                demographicIds: [id],
            })
        }
    })

    return targetDemographics
}

export const DemographicTarget = ({
    demographicQuestions,
    handleDemographicTargetChange,
    selectedDemographicTargets,
}: DemographicTargetProps) => {
    const customDemographicTargets = useCustomDemographicTargets()
    const options = useMemo(() => {
        let results: Option[] = [
            {
                id: ALL_DEMOGRAPHIC_ID,
                type: 'option',
                parentId: null,
                name: 'All Demographics',
            },
        ]

        demographicQuestions.forEach(({ demographicTypeId, demographicTypeName, answerOptions }) => {
            const parentId = demographicTypeId.toString()

            const children = answerOptions.map((answer) => {
                const childId = `${demographicTypeId}:${answer.id}`

                return {
                    id: childId,
                    name: answer.name,
                    parentId,
                }
            })

            results.push({
                id: parentId,
                type: children.length ? 'optgroup' : 'option',
                parentId: null,
                name: demographicTypeName,
                children,
            })
        })

        results = results.concat(
            customDemographicTargets?.map(({ demographicTypeId, name, answerOptions }) => {
                const parentId = demographicTypeId.toString()
                const children = (answerOptions ?? []).map(({ id, name }) => {
                    const childId = `${parentId}:${id}`

                    return {
                        id: childId,
                        name,
                        parentId,
                    }
                })

                return {
                    id: parentId,
                    type: answerOptions.length > 0 ? 'optgroup' : 'option',
                    parentId: null,
                    name,
                    children,
                }
            })
        )

        return results
    }, [demographicQuestions, customDemographicTargets])

    const selectableOptionIds = useMemo(() => {
        const result: Id[] = []
        options.forEach(({ type, id, children }) => {
            if (type === 'option') {
                result.push(id)
            }

            if (children?.length) {
                children.forEach(({ id }) => result.push(id))
            }
        })

        return result
    }, [options])

    const onSelect = useCallback(
        (selectedId: Id, previousSelected: Id[]): Id[] => {
            const getNextSelected = () => {
                const isSelected = previousSelected.includes(selectedId)
                const isAllSelectedId = selectedId === ALL_DEMOGRAPHIC_ID

                if (isAllSelectedId) {
                    return isSelected ? [] : selectableOptionIds
                }

                if (isSelected) {
                    const isAllSelected = previousSelected.length === selectableOptionIds.length
                    return previousSelected.filter((id) => {
                        if (isAllSelected) {
                            return id !== ALL_DEMOGRAPHIC_ID && id !== selectedId
                        }
                        return id !== selectedId
                    })
                }

                // hard to come up with a variable name for "2", but it represents the incoming selected option
                // and the "all" option.
                const willAllBeSelected = previousSelected.length === selectableOptionIds.length - 2

                return willAllBeSelected
                    ? [...previousSelected, ALL_DEMOGRAPHIC_ID, selectedId]
                    : [...previousSelected, selectedId]
            }

            const nextSelected = getNextSelected()

            const targetDemographics = getUpdatedTargetDemographic(nextSelected)
            handleDemographicTargetChange(targetDemographics)

            return nextSelected
        },
        [handleDemographicTargetChange, selectableOptionIds]
    )

    const selectedIds = useMemo(() => {
        if (!selectedDemographicTargets || selectedDemographicTargets?.length === 0) {
            return 'all'
        }

        return selectedDemographicTargets.flatMap(({ demographicIds, demographicTypeId }) => {
            return demographicIds.map((id) => `${demographicTypeId}:${id}`)
        })
        // generate the initial set of selected Ids, having selectedDemographicTargets as a dependency
        // makes it so that the multilayer select collapses the sections each time an option is selected
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { control } = useHierarchyMultiSelect(options, {
        ...settings,
        onSelect,
        initialSelected: selectedIds,
    })

    return <InputGroup label="Demographics" control={control} as={HierarchyMultiSelect} />
}
