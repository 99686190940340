import type { MouseEvent } from 'react'
import { useIsMutating } from 'react-query'
import { useParams } from 'react-router-dom'
import LoadingButton from 'components/LoadingButton'

type NonParticipatingConfirmationPromptProps = {
    onParticipateClick: (e: MouseEvent<HTMLButtonElement>) => void
}

export function NonParticipatingConfirmationPrompt({ onParticipateClick }: NonParticipatingConfirmationPromptProps) {
    const { surveyEventId } = useParams<{ surveyEventId: string }>()
    const updatingParticipation = useIsMutating(['award-selection', surveyEventId, 'post']) !== 0

    return (
        <section className="h6">
            <p>{'This survey is not currently being used for Top Workplaces.'}</p>
            <p>
                <LoadingButton
                    disabled={updatingParticipation}
                    isLoading={updatingParticipation}
                    onClick={onParticipateClick}
                    className="p-0 h6"
                    variant="link">
                    {'Click here'}
                </LoadingButton>
                {' if you would like to use this survey for Top Workplaces.'}
            </p>
        </section>
    )
}
