import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@energage/hooks'
import { colors } from '@energage/theme'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import {
    DALLAS_PUBLISHER_ID,
    LONG_ISLAND_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { viewports } from 'style/breakpoints'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getBadge } from '../Widgets/utils'
import Tile from './Tile'

const MAX_COMPANY_NAME_LENGTH = 60

const createQueryFn = (url) => async (_, api) => await api.json(url)

export const BadgeFetch = ({ location }) => {
    const { id, awardListParticipantId, isFilterEnabled, tileTypeId, isPngDownload } = parseQueryParams(location)
    const { data } = useBrandingQuery(
        'surveyCompanies',
        createQueryFn(
            `SurveyCompanies/${id}/${isFilterEnabled ? `Tile/${tileTypeId}/${awardListParticipantId}` : 'Badges'}`
        )
    )

    if (!data) {
        return null
    }

    return <StyledBadge isPngDownload={isPngDownload} isFileDownload {...data} />
}

export const SingleYearSingleAward = ({ location }) => {
    const {
        year,
        className,
        backgroundColor,
        companyName,
        awardLogoUrl,
        publisherId,
        isNationalStandard,
        isPngDownload,
    } = parseQueryParams(location)

    return (
        <StyledBadge
            year={year}
            className={className}
            backgroundColor={backgroundColor}
            companyName={companyName}
            awardLogoUrl={awardLogoUrl}
            publisherId={publisherId}
            isNationalStandard={isNationalStandard}
            isPngDownload={isPngDownload}
            isFileDownload
        />
    )
}

const TWPBadge = forwardRef(
    (
        {
            isPngDownload,
            year,
            className,
            backgroundColor,
            companyName,
            awardLogoUrl,
            publisherId,
            isNationalStandard,
            isFileDownload,
        },
        ref
    ) => {
        const { Component, logoClassName, companyClassName, applyDynamicHeight } = getBadge(
            awardLogoUrl,
            publisherId,
            isNationalStandard
        )
        const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMin })
        const isPdfDownload = !isPngDownload && isFileDownload && isMobileDevice
        const isDallasNationalStandard = publisherId === DALLAS_PUBLISHER_ID && isNationalStandard
        const isTWPUSA = publisherId === TWP_USA_PUBLISHER_ID
        const widgetText = `${companyName} is a ${year} Top Workplace!`
        const minHeight = applyDynamicHeight ? (companyName.length > MAX_COMPANY_NAME_LENGTH ? 250 : 200) : null
        const width = useMediaQuery({ minWidth: viewports.smMin }) ? (isTWPUSA ? 400 : 350) : isPdfDownload ? 594 : 280
        const tileWidth = isPngDownload ? 1400 : width
        const isLongIslandPublisherId = publisherId === LONG_ISLAND_PUBLISHER_ID

        return (
            <Tile
                width={tileWidth + 'px'}
                minHeight={minHeight}
                className={className}
                bgColor={colors.transparent}
                footerBackgroundColor={backgroundColor}
                ref={ref}
                shadow={false}>
                <Component
                    companyName={companyName}
                    widgetText={widgetText}
                    awardLogoUrl={awardLogoUrl}
                    year={year}
                    logoClassName={logoClassName}
                    companyClassName={companyClassName}
                    badgeYear={year}
                    isNationalStandard={isNationalStandard}
                    isPngDownload={isPngDownload}
                    isDallasNationalStandard={isDallasNationalStandard}
                    isLongIslandPublisherId={isLongIslandPublisherId}
                    isPdfDownload={isPdfDownload}
                    publisherId={publisherId}
                />
            </Tile>
        )
    }
)

const StyledBadge = styled(TWPBadge)`
    svg {
        width: 110px;
    }
`

StyledBadge.propTypes = {
    year: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
}

export default StyledBadge
