import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'

export const useFetchCompletedSurveyTasks = () => {
    const { surveyCompanyId } = useIdentity()

    const {
        data: statuses,
        isFetching,
        error,
    } = useWorkplaceSurveyQuery(
        ['survey-completed-task', surveyCompanyId],
        `Workflow/${surveyCompanyId}/AllCompletedSurveyTasks`,
        {
            enabled: Boolean(surveyCompanyId),
        }
    )

    return {
        statuses,
        isFetching,
        error,
    }
}
