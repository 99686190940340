import colors from 'containers/Main/AwardAnnouncement/colors'
import { FAQ_URL } from 'containers/Main/AwardAnnouncement/constants'
import type { ClassicAspiring } from '../awardAnnouncement.types'
import { Header } from '../Header'
import { AdditionalChances } from './AdditionalChances'
import { CommunicateWithLeaders } from './CommunicateWithLeaders'
import { FAQs } from './FAQs/FAQs'
import { FeatureYourCulture } from './FeatureYourCulture'
import { ThankYouForParticipating } from './ThankYouForParticipating/ThankYouForParticipating'
import { WhatsNext } from './WhatsNext'

export const Aspirant = (announcement: ClassicAspiring) => {
    const { companyName, year, listName, lastSurveyCloseDate, lastYearAwards, awardListParticipantId } = announcement
    return (
        <>
            <Header awardListParticipantId={awardListParticipantId} />
            <ThankYouForParticipating
                companyName={companyName}
                listName={listName}
                lastYearAwards={lastYearAwards}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
            />
            <WhatsNext companyName={companyName} bgColor={colors.bgBlue} />
            <FeatureYourCulture
                companyName={companyName}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
            />
            <AdditionalChances lastSurveyCloseDate={lastSurveyCloseDate} bgColor={colors.bgBlue} />
            <CommunicateWithLeaders
                companyName={companyName}
                year={year}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
            />
            <FAQs companyName={companyName} year={year} faqUrl={FAQ_URL} bgColor={colors.bgBlue} />
        </>
    )
}
