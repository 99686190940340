import type { CSSProperties } from 'react'
import { UnderlineSelect as NavSelect } from '@energage/components'
import { textSize } from '@energage/theme'
import omit from 'lodash/omit'
import { useSurveyWorkflowContext } from '../useSurveyWorkflowContext'
import type { FormatDropdownOption } from './FormatSurvey/useSurveyFormatData'

type FormatSelectTitleProps = {
    onChange: (format: FormatDropdownOption) => Promise<void>
    hideFormatDropdown?: boolean
    options: FormatDropdownOption[]
    value: FormatDropdownOption
    isDisabled?: boolean
}

export const FormatSelectTitle = ({ onChange, hideFormatDropdown, ...props }: FormatSelectTitleProps) => {
    const { refreshWorkflow } = useSurveyWorkflowContext()
    return (
        <div className="pl-6">
            {hideFormatDropdown ? (
                <h4>{'Who would you like to send your survey to?'}</h4>
            ) : (
                <div className="flex items-center">
                    <span className="h4">{'Who would you like to send your '}</span>
                    <NavSelect
                        className="mx-2 h4 inline-block"
                        primaryColor="#560BCE"
                        secondaryColor="#FAF7FF"
                        fontSize={24}
                        styles={{
                            option: () => ({ fontSize: textSize('sm') }),
                            singleValue: (styles: CSSProperties) => ({
                                ...omit(styles, ['maxWidth', 'position', 'transform']),
                                fontWeight: '600',
                            }),
                            valueContainer: () => ({ paddingLeft: 0, minWidth: 'auto' }),
                            dropdownIndicator: (styles: CSSProperties) => ({ ...styles, paddingRight: 0 }),
                        }}
                        isSearchable={false}
                        onChange={(value) => {
                            if (value) {
                                onChange(value).then(refreshWorkflow)
                            }
                        }}
                        {...props}
                    />
                    <span className="h4">{'to?'}</span>
                </div>
            )}
        </div>
    )
}

export default FormatSelectTitle
