import { CalendarMonth } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const NoScheduleSet = (surveyEventId) => {
    const tileUrl = routes.survey.setup.schedule(surveyEventId).concat('?section=schedule')
    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="text-red700 justify-center mt-2">
                <CalendarMonth fill={colors.red600} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle
                        className="text-red600 mb-2"
                        ariaText="Required: You haven't set a launch schedule for your survey">
                        {"You haven't set a launch schedule for your survey"}
                    </CardTitle>
                    <p>
                        {
                            'You’ll need to schedule your survey before it can launch. Please visit the schedule survey section to schedule your launch, close and reminder date(s).'
                        }
                    </p>
                </CardContent>
                <SummaryButton tileUrl={tileUrl} buttonText={'Edit Schedule'} className="w-fit" />
            </CardBody>
        </SummaryCard>
    )
}
