import { forwardRef } from 'react'
import type { ForwardedRef, ReactNode } from 'react'
import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import styled from 'styled-components'
import {
    isDesignTypeDonut,
    isTopXSelected,
    isVersionTable,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { MINIMUM_DROPLETS_REQUIRED } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { CultureStatsType } from 'containers/Main/EmployerBranding/util'
import Tile from 'containers/Public/RevampWidgets/Tile'
import linkedInBannerBackground from 'images/employerBranding/cultureFacts/linkedInBannerBackground.png'
import contrast from 'util/contrast'
import { CultureStatsDonutGraph } from './CultureStatsDonutGraph'
import { CultureStatsHalfDonutGraph } from './CultureStatsHalfDonutGraph'
import { CultureStatsTopXView } from './CultureStatsTopXView'
import { LinkedInBannerWordCloud } from './LinkedInBannerWordCloud'
import SelectedFilterPills from './SelectedFilterPills'
import type { Droplet } from './Widget.types'

const CultureStatsHalfDonutContainer = styled.div<{ isDownloadable?: boolean }>`
    height: ${(props) => (props.isDownloadable ? '190px' : '95px')};
`

const LinkedInBannerContainer = styled.div.attrs((props) => ({
    className: props.className ? props.className : 'relative',
}))`
    height: ${(props: { isDownloadable?: boolean }) => (props.isDownloadable ? '382px' : '191px')};
`

const LinkedInBannerTWPImage = styled.img<{ isDownloadable?: boolean }>`
    height: ${(props) => (props.isDownloadable ? '382px' : '191px')};
`

const SelectedStatement = styled.div<{ isDownloadable?: boolean }>`
    font-family: 'Libre Franklin';
    max-width: ${(props) => (props.isDownloadable ? '860px' : '430px')};
`

interface FilteredDataProps {
    Locations?: unknown
    Departments?: unknown
    JobRoles?: unknown
}

export const isTopXDisabled = (
    filterData: FilteredDataProps,
    selectedCultureType: string,
    cultureStatTypeSelected: number
) => {
    const { Locations, Departments, JobRoles } = filterData ?? {}

    return (
        CultureStatsType.TopXPercent === (selectedCultureType || cultureStatTypeSelected) &&
        (Locations || Departments || JobRoles)
    )
}

export const filteredData = (filterData: FilteredDataProps) => {
    const { Locations, Departments, JobRoles } = filterData ?? {}

    return Locations || Departments || JobRoles
}

const GetHighlightedText = ({
    text,
    highlight = '',
    isDownloadable,
}: {
    text: string
    highlight: string
    isDownloadable?: boolean
}) => {
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'))

    return (
        <SelectedStatement
            className={cx(`leading-6 text-center`, isDownloadable ? 'text-xl' : 'text-sm')}
            isDownloadable={isDownloadable}>
            {map(parts, (part, i) =>
                part.toLowerCase() === highlight?.toLowerCase() ? (
                    <span key={`${part}${i}`} className={cx('font-bold', isDownloadable ? 'text-3xl' : 'text-xl')}>
                        {part}
                    </span>
                ) : (
                    <span key={`${part}${i}`}>{part}</span>
                )
            )}
        </SelectedStatement>
    )
}

interface LinkedInBannerTypeChangeProps {
    percentage?: number
    backgroundColor?: string
    selectedTopXDesignType?: number
    selectedPercentPositiveDesignType?: number
    cultureStatTypeSelected?: number
    isContentFilter?: boolean
    cultureStatsTableData?: {
        designTypeId?: number
    }
    isDownloadable?: boolean
}

const LinkedInBannerTypeChange = ({
    percentage,
    backgroundColor,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    cultureStatTypeSelected,
    isContentFilter,
    cultureStatsTableData,
    isDownloadable,
}: LinkedInBannerTypeChangeProps) => {
    const isTopXView = isTopXSelected(cultureStatTypeSelected)

    if (isTopXView) {
        return (
            <CultureStatsTopXView
                className="flex self-center relative"
                topXPercent={percentage}
                backgroundColor={backgroundColor}
                selectedTopXDesignType={isContentFilter ? cultureStatsTableData?.designTypeId : selectedTopXDesignType}
                topXTextTop={isDownloadable ? '108px' : '54px'}
                topXLaurelTextTop={isDownloadable ? '94px' : '46px'}
                topXTextLeft={isDownloadable ? '90px' : '45px'}
                percentTextFontSize={isDownloadable ? '58px' : '29px'}
                percentTextSupFontSize={isDownloadable ? '24px' : '12px'}
                topXImageHeight={isDownloadable ? '170px' : '85px'}
                topXImageWidth={isDownloadable ? '170px' : '85px'}
                topXSectionMarginBottom="6px"
            />
        )
    }

    return (
        <div className="flex self-center relative">
            {isDesignTypeDonut(
                isContentFilter ? cultureStatsTableData?.designTypeId : selectedPercentPositiveDesignType
            ) ? (
                <CultureStatsDonutGraph
                    positivePercent={percentage ?? 0}
                    className="flex-auto"
                    height={isDownloadable ? '214px' : '107px'}
                    width={isDownloadable ? '214px' : '107px'}
                    backgroundColor={backgroundColor ?? ''}
                />
            ) : (
                <CultureStatsHalfDonutContainer isDownloadable={isDownloadable}>
                    <CultureStatsHalfDonutGraph
                        positivePercent={percentage ?? 0}
                        className="flex-auto"
                        height={isDownloadable ? '304px' : '152px'}
                        width={isDownloadable ? '304px' : '152px'}
                        backgroundColor={backgroundColor ?? ''}
                    />
                </CultureStatsHalfDonutContainer>
            )}
        </div>
    )
}

interface LinkedInBannerProps {
    ariaTitle?: string
    ariaDescription?: string
    droplets?: Droplet[]
    isLoading: boolean
    statements?: unknown
    backgroundColor: string
    className?: string
    type?: string
    dataId?: string
    filterData?: unknown
    isDownloadable?: boolean
    isExpiredWidget?: boolean
    hasEmployerBrandingSubscription?: boolean
    selectedTopXDesignType?: number
    selectedPercentPositiveDesignType?: number
    cultureStatTypeSelected?: number
    cultureStatsTableData?: {
        designTypeId?: number
        surveyFactorId?: number
    }
    isTopXFiltered?: boolean
}

export const LinkedInBanner = forwardRef(
    (
        {
            ariaTitle,
            ariaDescription,
            droplets,
            isLoading,
            statements,
            backgroundColor,
            className,
            type,
            dataId,
            filterData,
            isDownloadable,
            isExpiredWidget,
            isTopXFiltered,
            hasEmployerBrandingSubscription,
            selectedTopXDesignType,
            selectedPercentPositiveDesignType,
            cultureStatTypeSelected,
            cultureStatsTableData,
        }: LinkedInBannerProps,
        ref: ForwardedRef<ReactNode>
    ) => {
        const dropletsData = droplets ?? []
        const minDropletsRequired = dropletsData.length >= MINIMUM_DROPLETS_REQUIRED
        const isContentFilter = isVersionTable(cultureStatsTableData, isExpiredWidget)
        const selectedItems = filter(statements ?? [], 'isSelected')
        const selectedStatement = isContentFilter
            ? head(
                  filter(
                      statements ?? [],
                      (item: { surveyFactorId: number }) =>
                          item.surveyFactorId === cultureStatsTableData?.surveyFactorId
                  )
              )
            : head(selectedItems)
        const {
            percentPositiveStatement,
            topXPercentStatement,
        }: { percentPositiveStatement?: string; topXPercentStatement?: string } = selectedStatement ?? {}

        const statementsData: unknown = isTopXSelected(cultureStatTypeSelected)
            ? topXPercentStatement
            : percentPositiveStatement
        const {
            percentage = 0,
            linkedInStatement = '',
            linkedInHighlightedWord = '',
            text = '',
        }: {
            percentage?: number
            linkedInStatement?: string
            linkedInHighlightedWord?: string
            text?: string
        } = statementsData ?? {}

        return (
            /* @ts-ignore todo: tile types */
            <Tile
                bgColor={backgroundColor}
                bodyBorderRadius={isDownloadable ? '0' : '12px'}
                className={className}
                ref={ref}
                type={type}
                height={isDownloadable ? '382px' : '191px'}
                width={isDownloadable ? '2256px' : '1128px'}
                dataId={dataId}
                pills={SelectedFilterPills}
                blurTileBody={!hasEmployerBrandingSubscription}
                filterData={filterData}>
                <>
                    {isLoading && (
                        <LinkedInBannerContainer className="flex items-center relative justify-center">
                            <Spinner className={contrast(backgroundColor).isLight ? 'text-black' : 'text-white'} />
                        </LinkedInBannerContainer>
                    )}
                    {!isLoading && minDropletsRequired && selectedStatement && !isTopXFiltered ? (
                        <LinkedInBannerContainer className="flex" isDownloadable={isDownloadable}>
                            <div className="flex items-center justify-center w-full">
                                <LinkedInBannerTWPImage
                                    alt=""
                                    className="flex self-start"
                                    src={linkedInBannerBackground}
                                    isDownloadable={isDownloadable}
                                />
                                <div className="flex items-center justify-center w-full">
                                    <div className="flex flex-col self-center">
                                        <LinkedInBannerTypeChange
                                            percentage={percentage}
                                            backgroundColor={backgroundColor}
                                            selectedTopXDesignType={selectedTopXDesignType}
                                            selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                                            cultureStatTypeSelected={cultureStatTypeSelected}
                                            isContentFilter={isContentFilter}
                                            cultureStatsTableData={cultureStatsTableData}
                                            isDownloadable={isDownloadable}
                                        />
                                        <GetHighlightedText
                                            text={isTopXSelected(cultureStatTypeSelected) ? text : linkedInStatement}
                                            highlight={linkedInHighlightedWord}
                                            isDownloadable={isDownloadable}
                                        />
                                    </div>
                                </div>
                                <div className="flex self-end">
                                    <LinkedInBannerWordCloud
                                        ariaTitle={ariaTitle ?? ''}
                                        ariaDescription={ariaDescription ?? ''}
                                        backgroundColor={backgroundColor}
                                        droplets={dropletsData}
                                        isExpiredWidget={isExpiredWidget}
                                        isVisible={hasEmployerBrandingSubscription ?? false}
                                        isDownloadable={isDownloadable}
                                    />
                                </div>
                            </div>
                        </LinkedInBannerContainer>
                    ) : (
                        !isLoading && (
                            <InsufficientDataWarning
                                className="h-48 font-bold flex justify-center text-xl items-center px-8 sm:px-12"
                                warningText="There are no culture stats above the 25% threshold for this survey"
                            />
                        )
                    )}
                </>
            </Tile>
        )
    }
)
