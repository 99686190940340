import { useEffect, useState } from 'react'
import type { ElementType } from 'react'
import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import ReactModal from 'react-modal'
import { useHistory, useParams } from 'react-router-dom'
import CloseButton from 'components/Modals/CloseButton'
import { routes } from 'constants/routes'
import { Aspirant as AspirantOld } from './Aspirant/Aspirant'
import { AspirantRevamp } from './AspirantRevamp/Aspirant'
import type { QueryParams } from './awardAnnouncement.types'
import { AwardAnnouncementProvider, useAwardAnnouncementdata } from './AwardAnnouncementProvider'
import { AnnouncementExperience } from './constants'
import { StickyHeader } from './HeaderRevamp'
import { showRevampExp } from './utils'
import { Winners as WinnersOld } from './Winners/Winners'
import { WinnersRevamp } from './WinnersRevamp/Winners'

const AwardAnnouncementModal = () => {
    const { awardListParticipantId, awardAnnouncementData, isLoading } = useAwardAnnouncementdata()
    const history = useHistory()
    const [showHeaderBg, setShow] = useState(false)

    useEffect(() => {
        const onScroll = (e: Event) => {
            const target = e.target as HTMLElement
            if (target.scrollTop > 10) {
                setShow(true)
            } else {
                setShow(false)
            }
        }
        document.addEventListener('scroll', onScroll, true)
        return () => document.removeEventListener('scroll', onScroll, true)
    }, [])

    if (isLoading) {
        return <Spinner />
    }

    if (!awardAnnouncementData?.showAnnouncement || awardAnnouncementData?.isDisqualified) {
        history.push(routes.employerRecognition.participationHistory())
    }

    const getAnnouncementExperience = (experienceType: number) => {
        const experience: { [key: number]: ElementType } = {
            [AnnouncementExperience.OLD_ASPIRING]: AspirantOld,
            [AnnouncementExperience.NEW_ASPIRING]: AspirantRevamp,
            [AnnouncementExperience.OLD_WINNER]: WinnersOld,
            [AnnouncementExperience.NEW_WINNER]: WinnersRevamp,
        }
        return experience[experienceType]
    }

    const handleOnClose = () => {
        const referrerUrl = document.referrer

        if (referrerUrl && referrerUrl.includes('/timeline')) {
            history.push(routes.employerRecognition.awardTimeline({ awardListParticipantId }))
        } else if (history.length > 1) {
            history.goBack()
        } else {
            history.push(routes.dashboard())
        }
    }

    const AwardAnnouncementPage: ElementType = getAnnouncementExperience(awardAnnouncementData.experienceType)
    const revampExp = showRevampExp(awardAnnouncementData.experienceType)

    return (
        <ReactModal
            className="h-full w-full fixed m-auto"
            css={`
                background-color: #f4f4f4;
                overflow-y: scroll;
            `}
            overlayClassName="modal-overlay"
            isOpen>
            <StickyHeader awardListParticipantId={awardListParticipantId} showHeaderBg={showHeaderBg} />
            <div className="flex flex-col relative">
                <CloseButton
                    className={cx('self-end', revampExp ? 'fixed mt-2 z-50' : 'mt-24')}
                    onClick={handleOnClose}
                    size={48}
                    dataId={undefined}
                    disabled={undefined}
                />
            </div>
            <AwardAnnouncementPage {...awardAnnouncementData} awardListParticipantId={awardListParticipantId} />
        </ReactModal>
    )
}

export const AwardAnnouncement = () => {
    const { awardListParticipantId }: QueryParams = useParams()

    return (
        <AwardAnnouncementProvider awardListParticipantId={parseInt(awardListParticipantId ?? '')}>
            <AwardAnnouncementModal />
        </AwardAnnouncementProvider>
    )
}
