import { format } from 'd3'
import { format as dateFormat, formatRelative, isDate, parseISO } from 'date-fns'
import { enUS } from 'date-fns/locale'
import camelCase from 'lodash/camelCase'
import parseInt from 'lodash/parseInt'
import upperFirst from 'lodash/upperFirst'
import numeral from 'numeral'

export const percentage = (value, precision = 0) => {
    return format(`.${precision}%`)(value)
}
export const readableNumber = format(',')

export const currency = formatCurrency('$0,0[.]00')
export const currencyWithoutSign = formatCurrency('0,0[.]00')

function formatCurrency(format) {
    return (value) => numeral(value).format(format)
}
export const dayMonthYearDate = formatDate('dd MMMM yyyy')
export const monthYearDate = formatDate('MMMM yyyy')
export const monthDayShortYearDate = formatDate('MM/dd/yy')
export const monthDayYearDate = formatDate('MMM dd, yyyy')
export const fullMonthDayYearDate = formatDate('MMMM dd, yyyy')
export const dayOfWeekMonthDayYearDate = formatDate('EEE, MMM dd, yyyy')
export const shortMonthDayDate = formatDate('MMM dd')
export const monthDayDate = formatDate('MM/dd')
export const monthOrdinalDate = formatDate('MMMM do')
export const dateWithTime = formatDate('MM/dd/yyyy hh:mm a')
export const fullDayOfWeekMonthDayYearDate = formatDate('EEEE, MMM dd, yyyy')
export const fullDayOfWeekFullMonthDayYearDate = formatDate('EEEE, MMMM dd, yyyy')
export const monthDateFullYearDate = formatDate('MM/dd/yyyy')
export const shortMonthYearDate = formatDate('MMM, yyyy')
export const DATE_FORMAT = 'MMMM dd, yyyy'
export const YEAR_FORMAT = 'yyyy'

export function formatDate(format) {
    return (date) => dateFormat(isDate(date) ? date : parseISO(date), format)
}

export const parseDateOnly = ({ day, month, year }) => new Date(year, month - 1, day)

export const pascalCase = (str) => upperFirst(camelCase(str))

const formatRecentLocale = {
    lastWeek: "MMMM d',' y",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "MMMM d',' y",
    other: "MMMM d',' y",
}

const formatRecentFromNowLocale = {
    ...enUS,
    formatRelative: (token) => formatRecentLocale[token],
}

export function formatRecentFromNow(date) {
    return formatRelative(isDate(date) ? date : parseISO(date), new Date(), { locale: formatRecentFromNowLocale })
}

export const dateObject = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()

    return parseDateOnly({ day, month, year })
}

export const dateToObject = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()

    return { day, month, year }
}

// Convert the hex color in RGB
export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha ?? 1})`
}
