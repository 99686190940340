import { useWorkplaceSurveyQuery } from 'api'

export const useFetchAwardNotificationDetails = (year) => {
    const { data, isLoading } = useWorkplaceSurveyQuery(
        ['award-announcement-data'],
        `Announcements/AwardAnnouncement/${year}`
    )

    return { data: data, isLoading: isLoading }
}
