import styled from 'styled-components'

const StyledDiv = styled.div`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    min-height: ${(props) => props.isPdf && '1460px'};
`

const Section = ({ bgColor, textColor, children, sectionClassName, isPdf }) => (
    <StyledDiv className={sectionClassName} bgColor={bgColor} textColor={textColor} isPdf={isPdf}>
        {children}
    </StyledDiv>
)

export default Section
