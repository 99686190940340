import { spacing } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'
import { media } from 'style/breakpoints'

const Container = ({ className, enableInteraction, keepMobileView, ...props }) => {
    const baseClasses = 'flex whitespace-normal bg-white shadow-md rounded p-0 mr-4 h-full overflow-hidden'
    const responsiveClasses = keepMobileView ? 'flex-col' : 'flex flex-col sm:flex-row'

    return <div tabIndex="0" className={cx(`${baseClasses} ${responsiveClasses}`, className)} {...props} />
}

const StyledContainer = styled(Container)`
    width: 282px;
    min-width: 282px;
    max-width: ${spacing(96)};

    ${(props) =>
        !props.keepMobileView &&
        `
        .actions-container {
            display: none;
        }
    `}

    ${(props) =>
        !props.keepMobileView &&
        media.smMin`
            width: auto;
            flex: 0 0 ${spacing(96)};

            ${(props) =>
                props.enableInteraction
                    ? `
                &.loading,
                &:hover,
                &:focus-within {
                    .actions-container {
                        display: flex;
                    }
                }
            `
                    : null}

            &:focus {
                .actions-container {
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                }
            }
    `}
`

export default StyledContainer
