import { cloneElement } from 'react'
import cx from 'clsx'
import styled from 'styled-components'
import Status from '../Status'

const StatusIcon = styled(Status).attrs({ className: 'absolute', size: 12 })`
    right: -2px;
    bottom: -2px;
`

function TopNavIcon({ icon, status, className }) {
    if (!icon) {
        return null
    }

    return (
        <span className={cx('relative inline-block', className)} css="max-width: 24px;">
            {cloneElement(icon, { className: cx(icon.props.className, 'w-5 sm:w-auto') })}
            <StatusIcon status={status} />
        </span>
    )
}

export default TopNavIcon
