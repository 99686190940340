import { colors } from '@energage/theme'
import { ShareBottomSheet } from 'containers/Main/EmployerBranding/CultureFacts/SocialSharing/ShareBottomSheet'
import { ReadyToSharePage } from './ReadyToSharePage'

export const LinkedInIntegration = ({ ...props }) => {
    const {
        isOpenSheet,
        setIsOpenSheet,
        dataId,
        overallData,
        filterData,
        hasEmployerBrandingSubscription,
        selectedCultureType,
        selectedRectangleDesignType,
        backgroundColor,
        highlightColor,
        textColor,
        widgetRef,
    } = props

    const data = {
        ...overallData,
        backgroundColor,
        highlightColor,
        textColor,
        headerBgColor: colors.white,
        headerTextColor: colors.black,
        className: `rounded-lg overflow-hidden border`,
        ref: widgetRef,
    }

    return (
        <ShareBottomSheet title="Ready To Share to LinkedIn" isOpen={isOpenSheet} onDismiss={setIsOpenSheet}>
            <ReadyToSharePage
                dataId={dataId}
                data={data}
                filterData={filterData}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                selectedCultureType={selectedCultureType}
                selectedRectangleDesignType={selectedRectangleDesignType}
                bgColor={backgroundColor}
                highlightColor={highlightColor}
                textColor={textColor}
            />
        </ShareBottomSheet>
    )
}
