import { TERMS_URL } from 'constants/strings'

export const complimentaryServices =
    'During the term, Energage will provide access to, and use of, Complimentary Services. Customer may order additional support and Professional Services for a fee at any time by contacting your Energage representative. Complimentary Services include basic product support through online chat and phone (Monday-Friday from 8:30AM - 8:00PM ET), product training videos, survey configuration support, benchmark quality assurance, online education materials, helping hand advantage, market insights webinars, support case escalation, customer advising sessions, and communication tools and templates.'

export const introText =
    'Fees shown above do not include any taxes that may apply at the receipt of the invoice. Reasonable and necessary business and travel expenses actually incurred by Energage, shall be reimbursed by Customer upon submission of expense reports with appropriate documentation.'

export const multiYearTermDescription = (creditTerms) =>
    `The fees set forth herein are based on the total number of Customer's employees as of the Start Date, (the "Employee Headcount") shown on the above Order Form. Such Customer employees are also referred to as Users in the Order Form. The annual Fees will not change during the first year of the initial term of the Agreement (“Year One”), unless there is an increase to number of Users. Customer may be invoiced for additional Users added during the first year, and will be invoiced at the beginning of each successive Term Year for any additional Users above the original User count for additional Services requested, with such sums being due and payable in full ${
        creditTerms || 'Net 30'
    } days from Customer's receipt of the invoice. In the event Customer terminates the Agreement, Customer shall be responsible for immediately paying Energage any and all fees for Services purchased through duration of Agreement.`

export const serviceAndPaymentTerms = (creditTerms) => [
    {
        heading: 'Payment Terms',
        text: `Customer will be invoiced for the current balance due upon acceptance of this proposal with payment due ${
            creditTerms || 'Net 30'
        } days from date of invoice unless otherwise noted in the above order form.`,
    },
    {
        heading: 'Services Order Terms',
        text: (
            <span>
                {
                    'This Service Order is entered into on the Contract Start Date, (the “Effective Date”) between the Customer listed above (“Customer”) and Energage LLC with its principal place of business at 397 Eagleview Blvd., Suite 200, Exton, PA 19341 (“Energage”). This Service Order includes and incorporates the above Order Form, the terms as specified herein, and the General Terms found at '
                }
                <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="text-blue500">
                    {TERMS_URL}
                </a>
                {
                    ' (together, the “Agreement”). There shall be no force or effect to any different terms of any related purchase order or similar form even if signed by the parties after the date hereof.'
                }
                <p>
                    <br />
                    {
                        'This Agreement constitutes a legally binding agreement between Energage and Customer. In the event of any conflicts between this Service Order and the Energage General Terms, this Service Order will take precedence and prevail. Terms not defined in this Service Order have the meaning indicated in the Energage General Terms. This Service Order and the Energage General Terms constitutes the entire Agreement between the Parties regarding this subject matter, and supersedes all prior agreements, representations, and understandings between the Parties regarding this subject matter unless otherwise modified in writing by the Parties under a separate subsequent amendment.'
                    }
                </p>
            </span>
        ),
    },
]

export const getAutoRenewalDescription = (removeIncreaseLimit) => {
    const withIncreaseLimitClause =
        'The Service Order hereunder will automatically renew at price not greater than 10% above previous term price for a successive twelve-month term.'
    const withoutIncreaseLimitClause =
        'The Service Order hereunder will automatically renew for a successive twelve-month term.'
    return `${
        removeIncreaseLimit ? withoutIncreaseLimitClause : withIncreaseLimitClause
    } If Customer would like to opt-out of the autorenewal or change any of the terms of the renewal subscription prior to renewal, Customer must notify Energage at contracts@energage.com at least 30 days before the renewal term is to start. If Customer does not elect to opt-out of autorenewal at least 30 days before the renewal term is to start, Customer will be invoiced at the renewed price for the successive twelve-month term with payment due according to Payment Terms specified in this order form. If Customer elects to opt-out of autorenewal, renewal pricing will be set to current price at time of renewal. Energage will send a notification email to Customer 60 days before the renewal term.`
}

export const terms = {
    employerBranding: {
        productIds: ['2c92a0fe69999f000169b62196584240', '2c92c0f8697a2691016990c55ec23d3a'],
        title: 'Employer Branding',
        text: 'Energage provides access to the use of Employer Branding for showcasing Customer’s authentic and unique hiring differentiators to attract, recruit, and retain employees who share the same passion and purpose. Energage will deliver Employer Branding assets, including award logos, culture badges, and culture facts, as a service. These assets are licensed to Customer for commercial use for the duration of the subscription term. If the subscription lapses, Customer will lose access to the service.',
    },
    insights: {
        productIds: ['2c92a0fe69d82ca00169da7bb8f95ad4', '2c92c0f9697a3180016990c9588e308e'],
        title: 'Insights',
        text: 'Energage provides access to the use of Insights for survey data analysis. Access will be granted through individual log-in credentials to specified number of users in this Service Order, each designated by the Customer. Designated administrators are responsible for determining access levels for each user. These terms provide access to all data from previous surveys. If subscription lapses, Customer will lose access to the service.',
    },
    pulse: {
        productIds: ['2c92a0086db4fff2016db73396ed53f8', '2c92c0f86dd8a869016dd909253341de'],
        title: 'Pulse',
        text: 'Energage provides access to the use of Pulse for short survey data collection and survey customization. Pulse surveys can be issued electronically. Customers sending SMS survey invitations through Energage must acquire and maintain explicit phone use consent from each survey recipient. For more detail, please see energage.com/sms-terms/. Energage will provide access to the specified number of users in this Service Order, each designated by the Customer. If Customer purchases Pulse without Insights, Energage will deliver an excel file with confidential-protected data. If Customer purchases Pulse and is a subscriber of Insights, Pulse survey data will be entered into Insights for analysis. If subscription lapses, Customer will lose access to the service.',
    },
    guidanceService: {
        productIds: ['2c92c0f96e446ae8016e44ad4bd76b74', '2c92a00e6dd31d91016dd58e27802126'],
        title: 'Premier Support',
        text: 'Energage will provide access to a team of platform experts. Energage Premier Support is an on-demand premier product support solution. A member of our dedicated team of experts is available Monday-Friday from 9:00AM - 5:30PM ET. to ensure you experience the full potential of your Energage subscription. Premier Support provides support, a guided product tour, assistance with survey insights/messaging, implementation planning, and implementation support.',
    },
    executiveBriefing: {
        productIds: ['2c92a0fc5e5094aa015e6180555a4f41', '2c92c0f95c86a44d015c875e928627f0'],
        title: 'Executive Culture Briefing',
        text: (
            <span>
                <p className="font-bold">{'Project Assumptions'}</p>
                <span>
                    <p>
                        {
                            '1. Customer will provide Energage staff access to needed facilities while on site (if applicable).'
                        }
                    </p>
                    <p>
                        {
                            '2. Customer will provide a primary point of contact that will be responsible for client-side scheduling of facilities and meetings as required to successfully complete the project.'
                        }
                    </p>

                    <p>{'3. Customer will follow the outlined communication plan below:'}</p>

                    <p className="pl-5 text-justify">
                        {
                            'a. Customer’s designated contact person will coordinate with the Energage Project Manager directly for all requests during survey setup, execution, and analysis. This will aid the Energage team in coordinating internal resources and help ensure deliverables are completed on-time and within scope.'
                        }
                    </p>

                    <p className="pl-5 text-justify">
                        {
                            'b. When necessary, Customer’s designated point of contact will be available for regularly scheduled sync calls with the Energage Project Manager or Account Manager.'
                        }
                    </p>

                    <p>
                        {
                            '4. Energage will provide the Project Manager, Consultants, and Implementation Specialists needed to complete the work outlined in the proposal.'
                        }
                    </p>
                </span>
                <p className="font-bold">{'Out of Scope'}</p>
                <p>
                    {
                        'Any phases not outlined in the proposal are considered out-of-scope and can be subject to additional fees based on the level of effort required by the Energage Project Team.'
                    }
                </p>
                <p className="font-bold">{'Business and Travel Expenses'}</p>
                <p>
                    {
                        'Reasonable and necessary business and travel expenses actually incurred by Energage, LLC shall be reimbursed by Customer upon submission of expense reports with appropriate documentation.'
                    }
                </p>
            </span>
        ),
    },
    cultureRoadmap: {
        productIds: ['2c92a0086999aa6b0169a70d82ee3aaa', '2c92c0f96a01b91f016a06c0be4a2038'],
        title: 'Manager Culture Workshop',
        text: (
            <span>
                <p className="font-bold">{'Project Assumptions'}</p>
                <span>
                    <p>
                        {
                            '1. Customer will provide Energage staff access to needed facilities while on site (if applicable).'
                        }
                    </p>
                    <p>
                        {
                            '2. Customer will provide a primary point of contact that will be responsible for client-side scheduling of facilities and meetings as required to successfully complete the project.'
                        }
                    </p>

                    <p>{'3. Customer will follow the outlined communication plan below:'}</p>

                    <p className="pl-5 text-justify">
                        {
                            'a. Customer’s designated contact person will coordinate with the Energage Project Manager directly for all requests during survey setup, execution, and analysis. This will aid the Energage team in coordinating internal resources and help ensure deliverables are completed on-time and within scope.'
                        }
                    </p>

                    <p className="pl-5 text-justify">
                        {
                            'b. When necessary, Customer’s designated point of contact will be available for regularly scheduled sync calls with the Energage Project Manager or Account Manager.'
                        }
                    </p>

                    <p>
                        {
                            '4. Energage will provide the Project Manager, Consultants, and Implementation Specialists needed to complete the work outlined in the proposal.'
                        }
                    </p>
                </span>
                <p className="font-bold">{'Out of Scope'}</p>
                <p>
                    {
                        'Any phases not outlined in the proposal are considered out-of-scope and can be subject to additional fees based on the level of effort required by the Energage Project Team.'
                    }
                </p>
                <p className="font-bold">{'Business and Travel Expenses'}</p>
                <p>
                    {
                        'Reasonable and necessary business and travel expenses actually incurred by Energage, LLC shall be reimbursed by Customer upon submission of expense reports with appropriate documentation.'
                    }
                </p>
            </span>
        ),
    },
}

export const packageDetails = {
    29: {
        description: (
            <span>
                {'Recruit and retain'}
                <br />
                {'the right talent'}
            </span>
        ),
        features: [
            'Quickly close the loop with employees',
            'Recruiting insights',
            'Embed and share culture facts',
            'Enhanced Top Workplaces profile',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture Badges',
        ],
        serviceTerm: {
            title: terms.employerBranding.title,
            termDescription: terms.employerBranding.text,
        },
        defaultVisibility: false,
    },
    31: {
        description: (
            <span>
                {'Recruit, retain, &'}
                <br />
                {'inspire talent'}
            </span>
        ),
        features: [
            'Year-round survey insights',
            'Employee comments and highlights',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Recruiting and specialist insights',
            'Embed and share culture facts',
            'Enhanced Top Workplaces profile',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture Badges',
        ],
        serviceTerm: {
            title: `${terms.insights.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.insights.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    30: {
        description: (
            <span>
                {'Actionable insights to'}
                <br />
                {'improve business outcomes'}
            </span>
        ),
        features: [
            'Year-round survey insights',
            'Employee comments and highlights',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Specialist insights reports',
        ],
        serviceTerm: {
            title: terms.insights.title,
            termDescription: terms.insights.text,
        },
        defaultVisibility: false,
    },
    35: {
        description: (
            <span>
                {'Research-based custom'}
                <br />
                {'survey tool'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Gather actionable data from survey scores',
            'Reveal employee sentiment via comments',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Integrate with Insights and Branding solutions',
        ],
        serviceTerm: {
            title: terms.pulse.title,
            termDescription: terms.pulse.text,
        },
        defaultVisibility: false,
    },
    36: {
        description: (
            <span>
                {'Custom surveys &'}
                <br />
                {'actionable insights'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Year-round survey insights',
            'Gather actionable data from survey scores',
            'Reveal employee sentiment via comments',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Specialist insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh insights with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title} & ${terms.insights.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.insights.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    37: {
        description: (
            <span>
                {'Recruit, retain &'}
                <br />
                {'act on employee data'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Gather actionable data from survey score',
            'Quickly close the loop with employees',
            'Reveal employee sentiment via comments',
            'Embed and share culture facts',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture badges',
            'Recruiting insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh content with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    38: {
        description: (
            <span>
                {'Continuously recruit,'}
                <br />
                {'retain, and inspire talent'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Year-round survey insights',
            'Gather actionable data from survey score',
            'Quickly close the loop with employees',
            'Reveal employee sentiment via comments',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Embed and share culture facts',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture badges',
            'Recruiting and specialist insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh insights with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title}, ${terms.insights.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.insights.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    40: {
        name: 'Insights for Small Business',
        description: (
            <span>
                {'Actionable insights to'}
                <br />
                {'improve business outcomes'}
            </span>
        ),
        features: [
            'Year-round survey insights',
            'Employee comments and highlights',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Specialist insights reports',
        ],
        serviceTerm: {
            title: terms.insights.title,
            termDescription: terms.insights.text,
        },
        defaultVisibility: false,
    },
    44: {
        description: (
            <span>
                {'Recruit and retain'}
                <br />
                {'the right talent'}
            </span>
        ),
        features: [
            'Quickly close the loop with employees',
            'Recruiting insights',
            'Embed and share culture facts',
            'Enhanced Top Workplaces profile',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture Badges',
        ],
        serviceTerm: {
            title: terms.employerBranding.title,
            termDescription: terms.employerBranding.text,
        },
        defaultVisibility: false,
    },
    41: {
        description: (
            <span>
                {'Recruit, retain, &'}
                <br />
                {'inspire talent'}
            </span>
        ),
        features: [
            'Year-round survey insights',
            'Employee comments and highlights',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Recruiting and specialist insights',
            'Embed and share culture facts',
            'Enhanced Top Workplaces profile',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture Badges',
        ],
        serviceTerm: {
            title: `${terms.insights.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.insights.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    45: {
        description: (
            <span>
                {'Research-based custom'}
                <br />
                {'survey tool'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Gather actionable data from survey scores',
            'Reveal employee sentiment via comments',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Integrate with Insights and Branding solutions',
        ],
        serviceTerm: {
            title: terms.pulse.title,
            termDescription: terms.pulse.text,
        },
        defaultVisibility: false,
    },
    42: {
        description: (
            <span>
                {'Custom surveys &'}
                <br />
                {'actionable insights'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Year-round survey insights',
            'Gather actionable data from survey scores',
            'Reveal employee sentiment via comments',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Quickly close the loop with employees',
            'Specialist insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh insights with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title} & ${terms.insights.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.insights.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    46: {
        description: (
            <span>
                {'Recruit, retain &'}
                <br />
                {'act on employee data'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Gather actionable data from survey score',
            'Quickly close the loop with employees',
            'Reveal employee sentiment via comments',
            'Embed and share culture facts',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture badges',
            'Recruiting insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh content with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
    43: {
        description: (
            <span>
                {'Continuously recruit,'}
                <br />
                {'retain, and inspire talent'}
            </span>
        ),
        features: [
            'Check-in with employees any time',
            'Year-round survey insights',
            'Gather actionable data from survey score',
            'Quickly close the loop with employees',
            'Reveal employee sentiment via comments',
            'Advanced comparative analytics',
            'Action planning and tracking tools',
            'Embed and share culture facts',
            'Social sharing content',
            'Employee advocacy tools',
            'Unlock Culture badges',
            'Recruiting and specialist insights reports',
            'Add or remove unlimited survey statements',
            'Choose from ready-to-launch statements',
            'Select and target demographics',
            'Refresh insights with year-round surveys',
        ],
        serviceTerm: {
            title: `${terms.pulse.title}, ${terms.insights.title} & ${terms.employerBranding.title}`,
            termDescription: (
                <ul>
                    <li>{terms.pulse.text}</li>
                    <li>{terms.insights.text}</li>
                    <li>{terms.employerBranding.text}</li>
                </ul>
            ),
        },
        defaultVisibility: false,
    },
}

export const addOnsDetails = [
    {
        ids: ['2c92a0fc5e5094aa015e6180555a4f41', '2c92c0f95c86a44d015c875e928627f0'],
        name: 'Executive Culture Alignment Workshop',
        description: '',
        featureDescriptions: [
            { title: 'Expert analysis of survey insights' },
            { title: 'On-site alignment workshop for senior executives' },
            { title: 'Guide team toward action' },
            { title: 'Help drive success with culture' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        serviceTerm: {
            title: terms.executiveBriefing.title,
            termDescription: terms.executiveBriefing.text,
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92a0086999aa6b0169a70d82ee3aaa', '2c92c0f96a01b91f016a06c0be4a2038'],
        name: 'Manager Action Training',
        description: '',
        featureDescriptions: [
            { title: 'Expert analysis of survey insights' },
            { title: 'On-site action training for managers' },
            { title: 'Guide department action planning' },
            { title: 'Help improve performance, skills, and retention' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        serviceTerm: {
            title: terms.cultureRoadmap.title,
            termDescription: terms.cultureRoadmap.text,
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92a0fc5e5094aa015e6180554a4f3f', '2c92c0f95c86a44a015c876227065047'],
        name: 'Custom Survey Statement',
        description: '',
        featureDescriptions: [
            { title: 'Add custom survey statement' },
            { title: 'Available for all surveys while subscription active' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92a0fc5e5094aa015e618054344f05', '2c92c0f85c86952a015c87503449673a'],
        name: 'Custom Survey Demographic',
        description: '',
        featureDescriptions: [
            { title: 'Add custom survey demographic' },
            { title: 'Applied to all surveys while subscription active' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92a0fc5e5094aa015e6180547c4f14', '2c92c0f86a0276c4016a06c65f022451'],
        name: 'Tailored Benchmark',
        description: '',
        featureDescriptions: [
            { title: 'Create your own benchmark' },
            {
                title: 'Choose from extensive list of sectors and organization',
            },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92c0f96e446ae8016e44ad4bd76b74', '2c92a00e6dd31d91016dd58e27802126'],
        name: 'Premier Support',
        description: '',
        featureDescriptions: [
            { title: 'On-demand premium support' },
            { title: 'Tour the Insights tool with a Platform expert' },
            { title: 'Develop an implementation plan' },
            { title: 'Plan communications for employees and leadership' },
            { title: 'Meet with Platform expert every six months' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        serviceTerm: {
            title: terms.guidanceService.title,
            termDescription: terms.guidanceService.text,
        },
        defaultVisibility: false,
    },
    {
        ids: ['8ad098317d6eee77017d714968446ba3', '8a129bb27d1c76b0017d2e9a9d31209e'],
        name: 'Standard',
        description: 'Self-service, email, and phone support',
        featureDescriptions: [
            { title: 'Unlimited support via email & phone' },
            {
                title: 'Access to customer success calls designed to share best practices on how to use your products to benefit your organization',
            },
            { title: 'On-demand self-service support through help center.' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        serviceTerm: {
            title: terms.guidanceService.title,
            termDescription: terms.guidanceService.text,
        },
        defaultVisibility: true,
    },
    {
        ids: ['2c92c0f86e445b12016e44af7f6f246e', '8a129e727d1c76af017d2e9d9c0f1c66'],
        name: 'Plus',
        description: 'Survey next-steps support',
        featureDescriptions: [
            { title: 'Includes everything in Standard Support' },
            { title: "Personalized guidance using your organization's employee feedback and branding assets." },
            {
                title: '3 key next-steps planning calls to give you a running start on improving your workplace or standing out to talent and customers.',
            },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: true,
    },
    {
        ids: ['8ad0965d7d6eee76017d714ff13a7357', '8a1297307d1c76b5017d2ea01bc42b99'],
        name: 'Pro',
        description: 'Designated end-to-end support and survey planning',
        featureDescriptions: [
            { title: 'Includes everything in Plus Support' },
            { title: 'Designated Customer Success Manager to your organization' },
            {
                title: 'End-to-end support for Insights from Survey Design, through interpreting results, action planning, and improvement actions.',
            },
            {
                title: "End-to-end support for Employer Branding, from defining the story of your culture to distributing that story to those you're looking to attract to your organization.",
            },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: true,
    },
    {
        ids: ['2c92c0f85d4e954f015d4f3021170c91', '2c92a0fc5e5094aa015e618054904f1b'],
        name: 'Custom Work',
        description: '',
        featureDescriptions: [
            { title: 'Additional customization to improve Energage platform functionality and insights' },
        ],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: false,
    },
    {
        ids: ['2c92c0f95d4e8c1b015d4f5ce88667d5', '2c92a0fc5e5094aa015e618055e84f52'],
        name: 'Consulting Services',
        description: '',
        featureDescriptions: [{ title: 'Consulting services, as explained in the statement of work' }],
        learnMore: {
            title: 'Learn More',
            productImages: [],
        },
        defaultVisibility: false,
    },
]
