import { Checkbox } from '@energage/components'
import cx from 'clsx'
import map from 'lodash/map'
import AwardCheckListConfig from 'containers/Main/EmployerBranding/MediaKit/AwardCheckListConfig'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'
import withClassName from 'style/util/withClassName'
import MediaKitPDFHeader from './MediaKitPDFHeader'

const CheckBoxGroupContainer = withClassName('float-left p-1 grey300 w-1/3', 'div')

const CheckBoxGroup = ({ title, className, checkboxes }) => (
    <div className={cx('p-4 bg-grey200', className)}>
        <h8 className="font-bold">{title}</h8>
        <div className="pt-3">
            {map(checkboxes, (item, index) => (
                <Checkbox key={index} disabled inline {...item} className="text-black" />
            ))}
        </div>
    </div>
)

const AwardCheckList = ({ location }) => {
    const pdfHeaderText = 'Award Check List'
    const { year, publisherId, awardCategoryId, mediaKitLiteExperience } = parseQueryParams(location) || {}

    return (
        <>
            <MediaKitPDFHeader
                title={pdfHeaderText}
                year={year}
                publisherId={publisherId}
                awardCategoryId={awardCategoryId}
                mediaKitLiteExperience={mediaKitLiteExperience}
                isAwardCheckList
            />
            <div className="px-6 pt-10">
                <CheckBoxGroupContainer>
                    <CheckBoxGroup {...AwardCheckListConfig.celebrateNewsInternallyItems} />
                    <CheckBoxGroup className="mt-4" {...AwardCheckListConfig.integrateIntoYourHiring} />
                </CheckBoxGroupContainer>
                <CheckBoxGroupContainer>
                    <CheckBoxGroup {...AwardCheckListConfig.useTheTopWorkplacesLogo} />
                    <CheckBoxGroup className="mt-4" {...AwardCheckListConfig.enlistYourLeader} />
                </CheckBoxGroupContainer>
                <CheckBoxGroupContainer>
                    <CheckBoxGroup {...AwardCheckListConfig.shareTheNewsExternally} />
                    <CheckBoxGroup className="mt-4" {...AwardCheckListConfig.otherIdeas} />
                </CheckBoxGroupContainer>
            </div>
        </>
    )
}

export default AwardCheckList
