import { useCallback, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { Alert, LegacyButton as Button } from '@energage/components'
import { AnimatePresence, motion } from 'framer-motion'
import every from 'lodash/every'
import filter from 'lodash/filter'
import partition from 'lodash/partition'
import some from 'lodash/some'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import { Inset } from 'components/Inset'
import { Page } from 'components/Page'
import Prompt from 'components/Prompt'
import { FAQ_URL, SUPPORT_EMAIL, SUPPORT_PHONE, TWP_GUIDELINES } from 'constants/strings'
import { usePrevious } from 'hooks'
import HeaderTeleporter from '../HeaderTeleporter'
import { useSurveyScope } from '../Recipients/useSurveyScope'
import { useSurveyWorkflowContext } from '../useSurveyWorkflowContext'
import { AddAwardButton, AddLocationButton, SaveButton } from './AwardSelection.atoms'
import type { AwardSelection, AwardSelectionPagePost, AwardSelectionPageResult } from './awardselection.types'
import { getParticipatingNationalEmployeeCount, hasSizeBandDiscrepancy } from './awardselection.utils'
import { AwardSelectionSkeleton } from './AwardSelectionSkeleton'
import { AwardSelectionTable } from './AwardSelectionTable'
import { getEmployeeCountsUsingOverlaps, getMinimumEmployeeCountForAward } from './getEmployeeCountsUsingOverlaps'
import {
    InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage,
    InviteeCountSubstantiallyDifferentFromRegionalCountsMessage,
} from './messages'
import {
    NoAwardReasons,
    NonParticipatingConfirmationPrompt,
    ParticipatingConfirmationPrompt,
} from './ParticipationPrompts'
import { isSelectedAward, SurveyConfirmations } from './SurveyConfirmations'
import { useAwardSelection } from './useAwardSelection'

declare module 'framer-motion' {
    interface AnimatePresenceProps {
        children: ReactNode
    }
}

const NextPageButton = ({ surveyEventId, className }: { surveyEventId: string; className?: string }) => {
    const { getNextPage } = useSurveyWorkflowContext()

    return (
        <Button as={Link} className={className} to={getNextPage().route({ surveyEventId })}>
            {'Next'}
        </Button>
    )
}

function getNoAwardReason(noAwardsAdded: boolean, awards: AwardSelection[]) {
    if (awards.length > 0) {
        return undefined
    }

    return noAwardsAdded ? NoAwardReasons.NOT_ADDED : NoAwardReasons.ALL_USED
}

function AwardSelectionPage({
    pageData,
    onSave,
    saveButton,
}: {
    pageData: AwardSelectionPageResult
    onSave: (data: Partial<AwardSelectionPagePost>) => void
    saveButton: ReactNode
}) {
    const { organizationName } = useIdentity()
    const { surveyEventId } = useParams<{ surveyEventId: string }>()
    const previousPageData = usePrevious(pageData)
    const { surveyScope } = useSurveyScope(surveyEventId)
    const { surveyHasLaunched } = useSurveyWorkflowContext()
    const [
        isInviteeCountSubstantiallyDifferentFromRegionalCounts,
        setIsInviteeCountSubstantiallyDifferentFromRegionalCounts,
    ] = useState(false)

    const [hasMinimumQualifyingInviteeCount, setHasMinimumQualifyingInviteeCount] = useState(true)

    const form = useForm<AwardSelectionPageResult>({
        mode: 'onBlur',
        defaultValues: pageData,
    })

    const { watch, register, unregister, setValue, reset, getValues, formState } = form
    const { isDirty, isSubmitSuccessful } = formState

    useEffect(() => {
        if (pageData !== previousPageData && previousPageData !== undefined) {
            reset(pageData)
        }
    }, [reset, pageData, previousPageData])

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(getValues())
        }
    }, [getValues, isSubmitSuccessful, reset])

    useEffect(() => {
        register('isTopWorkplaceParticipant', { value: pageData?.isTopWorkplaceParticipant })
        return () => {
            unregister('isTopWorkplaceParticipant', { keepValue: true, keepDefaultValue: true })
        }
    }, [register, unregister, pageData?.isTopWorkplaceParticipant])

    useEffect(() => {
        // validate the form onload in case invalid data somehow made it into
        // the system from elsewhere.
        form.trigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = useCallback(
        async ({ awards, overlappingGroups, isEmployeeCountConfirmed, ...rest }: AwardSelectionPageResult) => {
            const selectedAwards = awards.filter(isSelectedAward)

            if (surveyHasLaunched && some(selectedAwards, (x) => !x.isFinalized)) {
                const [selectedRegionalAwards, selectedNationalAwards] = partition(
                    selectedAwards,
                    (award) => !award.isNational
                )
                const regionEmployeeCountUsingOverlaps = getEmployeeCountsUsingOverlaps(
                    selectedRegionalAwards,
                    overlappingGroups
                )

                const minimumQualifyingEmployeeCount = getEmployeeCountsUsingOverlaps(
                    selectedRegionalAwards,
                    overlappingGroups,
                    getMinimumEmployeeCountForAward
                )

                const nationalAwardEmployeeCount = getParticipatingNationalEmployeeCount(selectedNationalAwards)
                const employeeCount =
                    selectedNationalAwards.length > 0 ? nationalAwardEmployeeCount : regionEmployeeCountUsingOverlaps

                const isTopWorkplaceParticipant = surveyScope?.isTopWorkplaceParticipant
                const isRandomSample = surveyScope?.isRandomSample
                const isInviteeCountValid = (surveyScope?.inviteeCount ?? 0) > 0
                const isEmployeeCountValid = employeeCount > 0
                const isInviteeCountSubstantiallyDifferentFromEmployeeCounts =
                    (surveyScope?.inviteeCount ?? 0) < employeeCount * 0.9

                const hasMinimumQualifyingInviteeCount =
                    !isTopWorkplaceParticipant ||
                    surveyScope?.inviteeCount === 0 ||
                    minimumQualifyingEmployeeCount === 0 ||
                    (surveyScope?.inviteeCount ?? 0) >= minimumQualifyingEmployeeCount

                setHasMinimumQualifyingInviteeCount(hasMinimumQualifyingInviteeCount)

                const sizeBandDiscrepancyExists = hasSizeBandDiscrepancy(surveyScope, selectedAwards)

                if (
                    isEmployeeCountConfirmed &&
                    !isRandomSample &&
                    isInviteeCountValid &&
                    isEmployeeCountValid &&
                    (isInviteeCountSubstantiallyDifferentFromEmployeeCounts || sizeBandDiscrepancyExists)
                ) {
                    setIsInviteeCountSubstantiallyDifferentFromRegionalCounts(true)
                    return
                }
            }

            setIsInviteeCountSubstantiallyDifferentFromRegionalCounts(false)
            setHasMinimumQualifyingInviteeCount(true)

            const postBody: AwardSelectionPagePost = {
                ...rest,
                overlappingGroups,
                isEmployeeCountConfirmed,
                selectedAwards: filter(awards, 'isSelected'),
            }

            onSave(postBody)
        },
        [onSave, surveyHasLaunched, surveyScope]
    )

    const awards = watch('awards', pageData?.awards ?? [])
    const isTopWorkplaceParticipant = watch('isTopWorkplaceParticipant', pageData?.isTopWorkplaceParticipant)

    const onParticipateClick = useCallback(async () => {
        await onSave({
            isTopWorkplaceParticipant: true,
            selectedAwards: [],
            isEmployeeCountConfirmed: false,
        })

        setValue('isTopWorkplaceParticipant', true, { shouldDirty: false })
    }, [onSave, setValue])

    const onNoParticipateClick = useCallback(() => {
        setValue('isTopWorkplaceParticipant', false, { shouldDirty: false })
        onSave({ isTopWorkplaceParticipant: false })
    }, [onSave, setValue])

    const { noAwardsAdded } = pageData

    const noAwardReason = getNoAwardReason(noAwardsAdded, awards)

    const hideOptOut = awards.some((a) => a.isFinalized)

    const showInviteeRegionalCountMessage =
        !hasMinimumQualifyingInviteeCount || isInviteeCountSubstantiallyDifferentFromRegionalCounts

    const inviteeRegionalCountMessage = !hasMinimumQualifyingInviteeCount
        ? InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage
        : isInviteeCountSubstantiallyDifferentFromRegionalCounts
        ? InviteeCountSubstantiallyDifferentFromRegionalCountsMessage
        : ''

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {!isTopWorkplaceParticipant ? (
                    <NonParticipatingConfirmationPrompt onParticipateClick={onParticipateClick} />
                ) : (
                    <>
                        {showInviteeRegionalCountMessage && (
                            <div className="pb-6 flex flex-col">
                                <Alert variant="danger" className="text-xs px-3 py-2 w-auto inline">
                                    <>
                                        {inviteeRegionalCountMessage}

                                        {hasMinimumQualifyingInviteeCount &&
                                            isInviteeCountSubstantiallyDifferentFromRegionalCounts && (
                                                <>
                                                    <ExternalLink
                                                        className="inline"
                                                        target="_blank"
                                                        href={`${TWP_GUIDELINES}`}>
                                                        {' TWP guidelines'}
                                                    </ExternalLink>
                                                    {' surrounding adding awards to an in-flight/closed survey.'}
                                                </>
                                            )}

                                        {' You can also contact support at  '}
                                        <ExternalLink className="inline" href={`mailto:${SUPPORT_EMAIL}`}>
                                            {SUPPORT_EMAIL}
                                        </ExternalLink>
                                        {` or by phone at ${SUPPORT_PHONE} for help.`}
                                    </>
                                </Alert>
                            </div>
                        )}
                        <ParticipatingConfirmationPrompt
                            noAwardReason={noAwardReason}
                            onNoParticipateClick={onNoParticipateClick}
                            hideOptOut={hideOptOut}
                        />
                        {awards.length > 0 ? (
                            <>
                                <div className="my-4">
                                    <AwardSelectionTable overlappingGroups={pageData.overlappingGroups} />
                                </div>
                                <div className="flex justify-between text-bold">
                                    <div>
                                        {'Looking for more awards? '}
                                        <AddAwardButton className="p-0" />
                                        {' or '}
                                        <AddLocationButton className="p-0" />
                                        {' to find more awards you are eligible for.'}
                                    </div>
                                    <Button<'a'>
                                        className="text-base p-0"
                                        variant="link"
                                        href={FAQ_URL}
                                        target="_blank">
                                        {'Top Workplaces FAQ'}
                                    </Button>
                                </div>
                                <SurveyConfirmations
                                    className="my-5"
                                    awards={awards}
                                    companyName={organizationName}
                                    overlappingGroups={pageData.overlappingGroups}
                                />
                                <div className="flex sm:block w-full flex-col items-center gap-y-4 pt-6 border-t-2 sm:border-none">
                                    {saveButton}
                                    <NextPageButton
                                        className="w-64 sm:hidden text-center"
                                        surveyEventId={surveyEventId}
                                    />
                                </div>
                            </>
                        ) : null}
                        {noAwardReason === NoAwardReasons.NOT_ADDED && (
                            <AddAwardButton className="p-4 mt-4" variant="primary" />
                        )}
                    </>
                )}
                <Prompt when={isDirty} />
            </form>
        </FormProvider>
    )
}

function PageLayout({ children }: { children: ReactNode }) {
    const { surveyEventId } = useParams<{ surveyEventId: string }>()

    return (
        <>
            <HeaderTeleporter.Source>
                <Page.Heading border title="Select Your Awards">
                    <NextPageButton surveyEventId={surveyEventId} />
                </Page.Heading>
            </HeaderTeleporter.Source>
            <Inset padding>
                <section className="max-w-2xl">{children}</section>
            </Inset>
        </>
    )
}

function AwardSelectionPageFetch() {
    const { surveyEventId } = useParams<{ surveyEventId: string }>()

    const {
        query: { isLoading, data },
        mutation: { mutateAsync, isLoading: isSaving },
        saveComplete,
    } = useAwardSelection(surveyEventId)

    if (isLoading) {
        return (
            <PageLayout>
                <AwardSelectionSkeleton />
            </PageLayout>
        )
    }

    return (
        <PageLayout>
            <AwardSelectionPage
                pageData={data as AwardSelectionPageResult}
                onSave={mutateAsync}
                saveButton={
                    <>
                        <SaveButton
                            className="w-64 sm:w-auto"
                            disabled={every(data?.awards, 'isFinalized')}
                            isLoading={isSaving}
                        />
                        <AnimatePresence>
                            {isSaving ? (
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="text-grey500 ml-5">
                                    {'Saving...'}
                                </motion.span>
                            ) : saveComplete ? (
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="text-grey500 ml-5">
                                    {'All Changes Saved'}
                                </motion.span>
                            ) : null}
                        </AnimatePresence>
                    </>
                }
            />
        </PageLayout>
    )
}

export { AwardSelectionPageFetch as AwardSelectionPage }
