import { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Delete, Icon } from '@energage/icons'
import cx from 'clsx'
import keyCodes from 'constants/keyCodes'

function useEdit(startingValue, submitChange) {
    const inputRef = useRef(null)
    const [currentValue, setCurrentValue] = useState(startingValue)

    const onChange = useCallback((e) => {
        setCurrentValue(e.target.value)
    }, [])

    const onKeyUp = useCallback(() => {
        submitChange(currentValue)
    }, [currentValue, submitChange])

    const onKeyDown = useCallback((e) => {
        const code = e.charCode || e.keyCode
        switch (code) {
            case keyCodes.ENTER:
                e.preventDefault()
                break
            default:
                break
        }
    }, [])

    return { currentValue, inputRef, onKeyDown, onKeyUp, onChange }
}

const DemographicEditBox = ({ className, value = '', onChange, hasDelete, onDelete, placeholder, ...rest }) => {
    const { currentValue, inputRef, onKeyDown, onKeyUp, onChange: onInputChange } = useEdit(value, onChange)

    return (
        <div className={cx('grid', hasDelete ? 'grid-cols-2' : 'grid-cols-1')}>
            <div
                className={cx(
                    className,
                    'group mr-3 whitespace-no-wrap sm:whitespace-normal overflow-hidden sm:overflow-auto w-full rounded border border-grey300 flex bg-white'
                )}>
                <input
                    ref={inputRef}
                    className={cx('w-full px-1  py-2 outline-none max-w-md text-grey500 leading-snug')}
                    type="text"
                    value={currentValue}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
            {hasDelete && onDelete && (
                <div className="border-0 cursor-pointer my-auto">
                    <Icon className="hover:text-grey500" as={Delete} onClick={onDelete} />
                </div>
            )}
        </div>
    )
}

DemographicEditBox.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: function (props, propName, componentName) {
        if (props.required && !props[propName]) {
            return new Error(`A placeholder should be provided if required is true on ${componentName}`)
        }
    },
}

export default DemographicEditBox
