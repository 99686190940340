import type { Reducer } from 'react'
import {
    addDays,
    addMilliseconds,
    differenceInCalendarDays,
    differenceInMilliseconds,
    isMonday,
    isSameDay,
    startOfDay,
} from 'date-fns'
import { getTimezoneOffset, toDate, utcToZonedTime } from 'date-fns-tz'
import filter from 'lodash/filter'
import map from 'lodash/map'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { SurveyType } from 'constants/SurveyType'
import type { SurveyTypeOption } from 'constants/SurveyType'
import { generateRandomKey } from 'util/generateRandomKey'
import {
    ErrorType,
    isValid,
    validateBeforeMaxCloseFit,
    validateDates,
    validateNewDate,
} from '../surveyInvitationValidation'
import { Action } from './actions'
import type { ScheduleReducerActions } from './actions'
import type {
    AwardListScheduleCloseDate,
    AwardListScheduleCloseDates,
    Invitation,
    Invitations,
    TimeZone,
} from './scheduleReducer.types'
import { slideReminders } from './slideReminders'

function setReminderOffset(startDate: Date, invitationDate: Invitation) {
    if (invitationDate.dateTime != null) {
        invitationDate.reminderOffsetMs = differenceInMilliseconds(invitationDate.dateTime, startDate)
    }
}

function getMsDifferenceBetweenTimeZones(dateToCheck: Date, timeZoneFromIanaKey: string, timeZoneToIanaKey: string) {
    const currentTimeZoneOffset = getTimezoneOffset(timeZoneFromIanaKey, dateToCheck)
    const newTimeZoneOffset = getTimezoneOffset(timeZoneToIanaKey, dateToCheck)
    const differenceInMs = newTimeZoneOffset - currentTimeZoneOffset
    return differenceInMs
}

function setReminderAndCloseDateOffsets(invitationDates: Invitations, closeDate: Invitation | null) {
    const startDate = invitationDates[0]?.dateTime ?? new Date()

    for (const invitationDate of invitationDates) {
        setReminderOffset(startDate, invitationDate)
    }

    if (closeDate != null) {
        setReminderOffset(startDate, closeDate)
    }
}

function createInvitationDateObject({
    key = null,
    dateTime,
    errorType = null,
    isLocked = false,
}: {
    key?: string | null
    dateTime: Date | null
    errorType?: unknown
    isLocked?: boolean
}) {
    return {
        key: key ?? generateRandomKey(),
        dateTime: dateTime,
        validationInfo: { hasError: errorType != null, errorType },
        reminderOffsetMs: 0,
        isLocked: isLocked,
    }
}

function getRushedDate(awardListScheduleCloseDates: AwardListScheduleCloseDates, selectedDate: Date) {
    const targetStartDate = new Date(selectedDate)
    const surveyDays = isMonday(targetStartDate) ? -13 : -15

    const foundDates = filter(awardListScheduleCloseDates, (date) => {
        const startDate = addDays(date.internalScheduledCloseDate, surveyDays)
        const endDate = addDays(date.internalScheduledCloseDate, -4)
        return startDate < targetStartDate && targetStartDate <= endDate
    })

    return foundDates[0]?.internalScheduledCloseDate
}

function isSurveyCloseTimePickerDisabled(closeDate: Date, awardListDeadline: AwardListScheduleCloseDates) {
    const foundDates = filter(awardListDeadline, (date) => {
        return isSameDay(startOfDay(date.internalScheduledCloseDate), startOfDay(closeDate))
    })

    return foundDates.length > 0
}

function getSurveyCloseTimePickerValue(closeDate: Date, awardListDeadline: AwardListScheduleCloseDates) {
    const foundDates = filter(awardListDeadline, (date) => {
        return isSameDay(startOfDay(date.internalScheduledCloseDate), startOfDay(closeDate))
    })

    const internalScheduledCloseDate = foundDates[0] as AwardListScheduleCloseDate
    return internalScheduledCloseDate?.internalScheduledCloseDate
}

export type InvitationDate = ReturnType<typeof createInvitationDateObject>

export type ReducerState = {
    invitationDates: InvitationDate[]
    launchDate: Date
    scheduledCloseDateTime: null | InvitationDate
    timeZone: TimeZone
    submitDisabled: boolean
    useScheduledSurveyClose: boolean
    surveyType: SurveyTypeOption
    isTopWorkplaceParticipant: boolean
    latestReminderDate: Date
    maxScheduledCloseDate: Date
    isRushed: boolean
    awardListDeadlineDateTimes: AwardListScheduleCloseDates
    lastAvailableSurveyDateTime: Date
    awardListDeadline: Date
    surveyCloseTimePickerDisabled: boolean
}

export const reducer: Reducer<ReducerState, ScheduleReducerActions> = (
    state: ReducerState,
    action: ScheduleReducerActions
) => {
    switch (action.type) {
        case Action.FETCH_DATA_SUCCESS: {
            const {
                invitations,
                scheduledCloseDateTime,
                timeZone,
                surveyType,
                isTopWorkplaceParticipant,
                useScheduledSurveyClose,
                completedChooseScheduleTask,
                awardListScheduleCloseDates,
                lastAvailableSurveyDate,
            } = action.payload

            const invitationDates = map(invitations, (i) =>
                createInvitationDateObject({
                    dateTime: utcToZonedTime(i.scheduledTime, timeZone.ianaKey),
                    isLocked: i.isLocked,
                })
            )

            const closeDate = !useScheduledSurveyClose
                ? null
                : createInvitationDateObject({
                      dateTime: utcToZonedTime(
                          scheduledCloseDateTime ??
                              addDays(invitationDates[invitationDates.length - 1].dateTime as Date, 1),
                          timeZone.ianaKey
                      ),
                      isLocked: !!scheduledCloseDateTime && toDate(scheduledCloseDateTime) < new Date(),
                  })
            setReminderAndCloseDateOffsets(invitationDates, closeDate)

            const awardListDeadlineDateTimes = map(awardListScheduleCloseDates, (date) => ({
                ...date,
                internalScheduledCloseDate: utcToZonedTime(date.internalScheduledCloseDate, timeZone.ianaKey),
            }))

            const lastAvailableSurveyDateTime = utcToZonedTime(lastAvailableSurveyDate, timeZone.ianaKey)

            const awardListDeadline = getRushedDate(awardListDeadlineDateTimes, invitationDates[0]?.dateTime as Date)
            const isRushed =
                surveyType === SurveyType.Custom ? false : awardListDeadline !== undefined && isTopWorkplaceParticipant
            const launchDate = invitationDates[0]?.dateTime as Date

            const surveyCloseTimePickerDisabled = isSurveyCloseTimePickerDisabled(
                closeDate?.dateTime as Date,
                awardListDeadlineDateTimes
            )

            return {
                ...state,
                launchDate,
                invitationDates,
                scheduledCloseDateTime: closeDate,
                timeZone,
                submitDisabled: completedChooseScheduleTask,
                useScheduledSurveyClose,
                surveyType,
                isTopWorkplaceParticipant,
                isRushed,
                awardListDeadlineDateTimes,
                lastAvailableSurveyDateTime,
                awardListDeadline: awardListDeadline,
                surveyCloseTimePickerDisabled,
            }
        }
        case Action.FETCH_TIME_ZONES_SUCCESS:
            return { ...state, timeZones: action.payload }
        case Action.RESET_INVITATION_DATES: {
            const { invitations, scheduledCloseDateTime } = action.payload
            const { timeZone, awardListDeadlineDateTimes } = state

            const invitationDates = map(invitations, (d) =>
                createInvitationDateObject({ dateTime: toDate(d.scheduledTime) })
            )

            let closeDateTimeObj = null
            if (scheduledCloseDateTime) {
                closeDateTimeObj = createInvitationDateObject({
                    dateTime: utcToZonedTime(scheduledCloseDateTime, timeZone.ianaKey),
                })
                setReminderAndCloseDateOffsets(invitationDates, closeDateTimeObj)
            }

            const surveyCloseTimePickerDisabled = isSurveyCloseTimePickerDisabled(
                closeDateTimeObj?.dateTime as Date,
                awardListDeadlineDateTimes
            )

            const launchDate = invitationDates[0]?.dateTime as Date

            return {
                ...state,
                launchDate,
                invitationDates,
                scheduledCloseDateTime: closeDateTimeObj,
                surveyCloseTimePickerDisabled,
            }
        }
        case Action.SUBMIT_SUCCESS: {
            const { timeZone } = state
            const { awardListDeadlineDates } = action.payload
            return {
                ...state,
                invitationDates: filter(state.invitationDates, (d) => d.dateTime != null),
                awardListDeadlineDateTimes: map(awardListDeadlineDates, (date) => ({
                    ...date,
                    internalScheduledCloseDate: utcToZonedTime(date.internalScheduledCloseDate, timeZone.ianaKey),
                })),
                stepComplete: true,
                submitDisabled: true,
            }
        }
        case Action.ADD_INVITATION_DATE: {
            const { newDate, index } = action.payload
            const invitationDates = state.invitationDates.slice()
            invitationDates.splice(index, 0, createInvitationDateObject({ dateTime: newDate }))
            return { ...state, invitationDates, submitDisabled: true }
        }
        case Action.DELETE_INVITATION_DATE: {
            const { index } = action.payload
            const { scheduledCloseDateTime } = state
            const invitationDates = state.invitationDates.slice()
            invitationDates.splice(index, 1)

            let validatedInvitationDates = validateDates(invitationDates, scheduledCloseDateTime?.dateTime)

            const isFormValid = isValid(validatedInvitationDates)
            if (isFormValid) {
                validatedInvitationDates = sortBy(validatedInvitationDates, 'dateTime')
            }
            return {
                ...state,
                invitationDates: validatedInvitationDates,
                submitDisabled: !isFormValid,
            }
        }
        case Action.UPDATE_LAUNCH_DATE: {
            const { newDate, isTimeChanged } = action.payload
            const {
                launchDate,
                invitationDates,
                isTopWorkplaceParticipant,
                lastAvailableSurveyDateTime,
                awardListDeadlineDateTimes,
                timeZone,
                surveyType,
            } = state

            const awardListDeadline = getRushedDate(awardListDeadlineDateTimes, newDate)
            const isRushed =
                surveyType === SurveyType.Custom ? false : awardListDeadline !== undefined && isTopWorkplaceParticipant

            if (isSameDay(state.launchDate, newDate) && isTimeChanged) {
                return reducer(state, { type: Action.UPDATE_INVITATION_DATE, payload: { ...action.payload, index: 0 } })
            }

            if (isSameDay(launchDate, newDate) && !isTimeChanged) {
                return state
            }

            const differenceInDays = differenceInCalendarDays(newDate, launchDate)
            const newStartDateWithCorrectTime = addDays(launchDate, differenceInDays)

            let updatedInvitationDates = isRushed ? [] : slideReminders(newStartDateWithCorrectTime, invitationDates)
            let newScheduledCloseDateTime = state.scheduledCloseDateTime
            if (state.scheduledCloseDateTime && !isRushed) {
                const lastReminder = updatedInvitationDates[updatedInvitationDates.length - 1]

                const msOffsetFromLastReminder =
                    state.scheduledCloseDateTime.reminderOffsetMs - lastReminder.reminderOffsetMs

                // as Date is safe here -- if all reminders are removed, lastReminder ends up being
                // the survey launch date
                const newCloseDate = addMilliseconds(lastReminder.dateTime as Date, msOffsetFromLastReminder)
                const callApi = some(
                    awardListDeadlineDateTimes,
                    (d) => d.internalScheduledCloseDate >= launchDate && d.internalScheduledCloseDate < newCloseDate
                )

                if (callApi) {
                    updatedInvitationDates = []
                }
                newScheduledCloseDateTime = { ...state.scheduledCloseDateTime, dateTime: newCloseDate }
            }

            const surveyCloseTimePickerDisabled = isSurveyCloseTimePickerDisabled(
                newScheduledCloseDateTime?.dateTime as Date,
                awardListDeadlineDateTimes
            )

            if (surveyCloseTimePickerDisabled) {
                const newDate = getSurveyCloseTimePickerValue(
                    newScheduledCloseDateTime?.dateTime as Date,
                    awardListDeadlineDateTimes
                )
                if (newScheduledCloseDateTime && newDate) {
                    newScheduledCloseDateTime.dateTime = newDate
                }
            }

            const { validatedInvitationDates, validatedScheduledCloseDateTime } = validateBeforeMaxCloseFit(
                updatedInvitationDates,
                newScheduledCloseDateTime,
                lastAvailableSurveyDateTime,
                timeZone
            )

            return {
                ...state,
                invitationDates: validatedInvitationDates,
                scheduledCloseDateTime: validatedScheduledCloseDateTime,
                launchDate: newStartDateWithCorrectTime as Date,
                submitDisabled:
                    !isValid(validatedInvitationDates) ||
                    (validatedScheduledCloseDateTime?.validationInfo?.hasError ?? false),
                isRushed: isRushed,
                awardListDeadline: awardListDeadline,
                surveyCloseTimePickerDisabled,
            }
        }
        case Action.UPDATE_INVITATION_DATE: {
            const { newDate, index, isTimeChanged } = action.payload
            const { scheduledCloseDateTime } = state
            const key = state.invitationDates[index].key
            const invitationDates = state.invitationDates.slice()

            const errorType = validateNewDate(invitationDates, index, newDate, isTimeChanged)
            const newInvitationDate = createInvitationDateObject({
                key,
                dateTime: errorType === ErrorType.NO_DATE ? null : newDate,
                errorType,
            })
            invitationDates.splice(index, 1, newInvitationDate)
            setReminderAndCloseDateOffsets(invitationDates, scheduledCloseDateTime)

            let validatedInvitationDates = validateDates(invitationDates, scheduledCloseDateTime?.dateTime)
            validatedInvitationDates = sortBy(validatedInvitationDates, 'dateTime')

            return {
                ...state,
                invitationDates: validatedInvitationDates,
                launchDate: invitationDates[0].dateTime as Date,
                submitDisabled: !isValid(validatedInvitationDates),
            }
        }
        case Action.UPDATE_CLOSE_DATE: {
            let { newDate } = action.payload
            const { isTimeChanged } = action.payload
            const {
                invitationDates,
                scheduledCloseDateTime,
                lastAvailableSurveyDateTime,
                timeZone,
                awardListDeadlineDateTimes,
            } = state

            const surveyCloseTimePickerDisabled = isSurveyCloseTimePickerDisabled(newDate, awardListDeadlineDateTimes)

            if (!scheduledCloseDateTime) {
                return state
            }

            if (surveyCloseTimePickerDisabled && !isTimeChanged) {
                newDate = getSurveyCloseTimePickerValue(newDate, awardListDeadlineDateTimes)
            }

            const newScheduledCloseDateTime = {
                ...scheduledCloseDateTime,
                dateTime: newDate,
            }

            setReminderOffset(invitationDates[0].dateTime as Date, newScheduledCloseDateTime)
            const updatedInvitationDates = validateDates(invitationDates, newDate)

            const { validatedInvitationDates, validatedScheduledCloseDateTime } = validateBeforeMaxCloseFit(
                updatedInvitationDates,
                newScheduledCloseDateTime,
                lastAvailableSurveyDateTime,
                timeZone
            )

            return {
                ...state,
                invitationDates: validatedInvitationDates,
                scheduledCloseDateTime: validatedScheduledCloseDateTime,
                submitDisabled:
                    !isValid(validatedInvitationDates) ||
                    (validatedScheduledCloseDateTime?.validationInfo?.hasError ?? false),
                surveyCloseTimePickerDisabled,
            }
        }
        case Action.CHANGE_TIME_ZONE:
            const { timeZone, invitationDates, awardListDeadlineDateTimes, scheduledCloseDateTime } = state
            if (timeZone.ianaKey === action.payload.ianaKey) {
                return state
            }

            const newAwardListDeadlineDateTimes = map(awardListDeadlineDateTimes, (date) => ({
                ...date,
                internalScheduledCloseDate: addMilliseconds(
                    date.internalScheduledCloseDate,
                    getMsDifferenceBetweenTimeZones(
                        date.internalScheduledCloseDate,
                        timeZone.ianaKey,
                        action.payload.ianaKey
                    )
                ),
            }))

            if (
                !scheduledCloseDateTime?.dateTime ||
                !isSurveyCloseTimePickerDisabled(scheduledCloseDateTime.dateTime, awardListDeadlineDateTimes)
            ) {
                return {
                    ...state,
                    awardListDeadlineDateTimes: newAwardListDeadlineDateTimes,
                    timeZone: action.payload,
                }
            }

            const newCloseDateTime = addMilliseconds(
                scheduledCloseDateTime.dateTime,
                getMsDifferenceBetweenTimeZones(
                    scheduledCloseDateTime.dateTime,
                    timeZone.ianaKey,
                    action.payload.ianaKey
                )
            )
            const newScheduledCloseDateTime = {
                ...scheduledCloseDateTime,
                dateTime: newCloseDateTime,
            }
            setReminderOffset(invitationDates[0].dateTime as Date, newScheduledCloseDateTime)

            return {
                ...state,
                awardListDeadlineDateTimes: newAwardListDeadlineDateTimes,
                scheduledCloseDateTime: newScheduledCloseDateTime,
                timeZone: action.payload,
                submitDisabled: !isValid(state.invitationDates),
            }
        case Action.UPDATE_STATUS:
            return { ...state, stepComplete: action.payload }
        default:
            return state
    }
}
