import PropTypes from 'prop-types'
import { Loading, Tooltip } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import FancyCheckbox from 'components/FancyCheckbox'
import { media } from 'style/breakpoints'

const DESKTOP_INDENT = 32
const MOBILE_INDENT = 16

const getIndentationWidth = (indentLevel, unitIndentWidth) => (indentLevel || 0) * unitIndentWidth
const getColumnWidth = (width, indentLevel, unitIndentWidth) =>
    `${width} - ${(indentLevel || 0) * (unitIndentWidth - 16)}`

export const ColumnHeader = ({ className, ...props }) => (
    <span className={cx(className, 'inline-block p-2')} {...props} />
)

ColumnHeader.propTypes = {
    className: PropTypes.string.isRequired,
}

export const TableRow = styled.div.attrs({ className: 'text-xs -mt-px relative' })`
    :after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: ${(props) => getIndentationWidth(props.indentLevel, MOBILE_INDENT)}px;
        content: '';
        z-index: 1;
        background-color: ${colors.white};
        border: 1px solid ${colors.grey300};
    }

    :nth-child(even):after {
        background-color: ${colors.grey100};
    }

    ${media.mdMin`
        :after {
            left: ${(props) => getIndentationWidth(props.indentLevel, DESKTOP_INDENT)}px;
        }
    `}
`

export const TableCell = styled.span.attrs({ className: 'inline-block p-2 relative z-10' })`
    ${(props) =>
        props.cellWidth && `width: calc(${getColumnWidth(props.cellWidth, props.indentLevel, MOBILE_INDENT)}px);`}
    padding-left: ${(props) => getIndentationWidth(props.indentLevel, MOBILE_INDENT) + 8}px !important;

    ${media.mdMin`
        ${(props) =>
            props.cellWidth && `width: calc(${getColumnWidth(props.cellWidth, props.indentLevel, DESKTOP_INDENT)}px);`}
        padding-left: ${(props) => getIndentationWidth(props.indentLevel, DESKTOP_INDENT) + 8}px !important;
    `}
`

export const TableHeader = ({ columns, isSelectable, checked, onCheckboxClick }) => (
    <div className="bg-white border border-b-2 border-grey300 font-bold text-sm">
        {map(columns, ({ label, className, hasCheckbox }) => (
            <ColumnHeader className={className} key={label}>
                {hasCheckbox && isSelectable && onCheckboxClick && (
                    <FancyCheckbox checked={checked} className="align-middle mx-2" onChange={onCheckboxClick} />
                )}
                {label}
            </ColumnHeader>
        ))}
    </div>
)

TableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    isSelectable: PropTypes.bool,
    checked: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
}

export const TableErrorMsg = ({ listName }) => (
    <div className="bg-white text-center p-4 text-base">{`There was a problem loading ${listName}. Please try again later.`}</div>
)

TableErrorMsg.propTypes = {
    listName: PropTypes.string.isRequired,
}

export const TableContainer = ({ columns, children, isLoading, isEditable, error, listName, ...restProps }) => (
    <>
        <div className="w-full text-left">
            {columns && (
                <TableHeader
                    columns={isEditable ? columns : filter(columns, (x) => x.label !== 'Actions')}
                    {...restProps}
                />
            )}
            <div>{error ? <TableErrorMsg listName={listName} /> : children}</div>
        </div>
        {isLoading && !error && (
            <Loading className="bg-white border border-grey300 border-t-0" text={`Loading ${listName}...`} />
        )}
    </>
)

TableContainer.propTypes = {
    columns: PropTypes.array,
    children: PropTypes.node,
    isLoading: PropTypes.bool.isRequired,
    listName: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
}

const ViewLink = ({ to, icon, tooltip }) => (
    <Tooltip
        placement="bottom"
        className="inline-block"
        renderTrigger={({ ref, getTriggerProps }) => (
            <NavLink to={to} exact className="mr-6" activeClassName="text-purple700" ref={ref} {...getTriggerProps()}>
                {icon}
            </NavLink>
        )}>
        <span className="paragraph-small flex justify-center content-center">{tooltip}</span>
    </Tooltip>
)

export const ViewControl = ({ links, className }) => (
    <div className={cx('float-left p-3', className)}>
        {map(links, ({ url, icon, tooltip }) => (
            <ViewLink key={url} to={url} icon={icon} tooltip={tooltip} />
        ))}
    </div>
)
