import compact from 'lodash/compact'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import replace from 'lodash/replace'
import size from 'lodash/size'
import startsWith from 'lodash/startsWith'
import toString from 'lodash/toString'
import uniqWith from 'lodash/uniqWith'
import queryString from 'query-string'
import config from 'config'
import { routes } from 'constants/routes'
import TaskDefinition from 'constants/taskDefinition'
import { AwardStatus, DUPLICATE_LOCATION_ERROR, LocationType } from 'containers/Main/EmployerRecognition/constants'
import { parseDateOnly } from 'util/formatters'
import showErrorAlert from 'util/showErrorAlert'

export const SPECIAL_CHARS_REGEX = /[!@#$%*]/

export const IS_FULLY_REMOTE_COMPANY = 'hasFullyRemoteParticipation'

export const DUPLICATE_ERROR_TYPE = 'duplicate'

export const numberInputDefaultProps = {
    type: 'number',
    min: 0,
    onKeyDown: (evt) => includes(['e', 'E', '+', '-', '.'], evt.key) && evt.preventDefault(),
}

export const specialCharsValidation = (labelName) => {
    return {
        name: 'pattern',
        message: `${labelName} is not valid`,
        test: (value) => value.length === 0 || !SPECIAL_CHARS_REGEX.test(value),
    }
}

const generateUrl = (baseUrl) => (pageName, surveyEventId, params) =>
    queryString.stringifyUrl({
        url: `${baseUrl}/${pageName}.aspx`,
        query: {
            e: surveyEventId,
            ...((typeof params !== 'string' && params) ?? {}),
        },
        fragmentIdentifier: typeof params === 'string' && params,
    })

/**
 * Generate Survey Tracker Url using page name and survey event id
 *
 * @param {string} pageName - Survey Tracker page name
 * @param {number} surveyEventId - Survey Event Id
 * @param {(string|Object.<string, any>)} params - String or query object
 * @return {string} - Survey Tracker Url
 * @example
 *
 * generateSurveyTrackerUrl('Default', 1234)
 * // => surveyTrackerBaseUrl/Default.aspx?e=1234
 *
 * generateSurveyTrackerUrl('Default', 1234, 'eventInfo')
 * // => surveyTrackerBaseUrl/Default.aspx?e=1234#eventInfo
 *
 * generateSurveyTrackerUrl('Default', 1234, { t = 5678 })
 * // => surveyTrackerBaseUrl/Default.aspx?e=1234&t=5678
 */

export const generateSurveyTrackerUrl = generateUrl(config.urls.surveyTracker)

/**
 * Generate Results Url using page name and survey event id
 *
 * @param {string} pageName - Results page name
 * @param {number} surveyEventId - Survey Event Id
 * @param {(string|Object.<string, any>)} params - String or query object
 * @return {string} - Results Url
 * @example
 *
 * generateResultsUrl('Default', 1234)
 * // => resultsBaseUrl/Default.aspx?e=1234
 *
 * generateResultsUrl('Default', 1234, 'eventInfo')
 * // => resultsBaseUrl/Default.aspx?e=1234#eventInfo
 *
 * generateResultsUrl('Default', 1234, { t = 5678 })
 * // => resultsBaseUrl/Default.aspx?e=1234&t=5678
 */

export const generateResultsUrl = generateUrl(config.urls.results)

export const getTaskStatus = (statuses, taskDefinitionId, surveyEventId) =>
    find(statuses, (s) => s.taskDefinitionId === taskDefinitionId && s.surveyEventId === surveyEventId) || {}

const findById = (id, array) => find(array, { id })

/**
 * Get sector information of selected sector
 * @param {Object} selectedSector - selectedSector object
 * @param {Array.<Object>} sectors - List of all possible sectors
 * @return {Object} - Results of sector information
 * @example
 *
 * getSectorInfo({
 *      sectorId: 123,
 *      sectorDescription: 'test description',
 *      isSectorApproved: false
 * }, [{ }])
 * // =>
 *   {
 *      parentSectors,
 *      childSectors,
 *      sectorInfo,
 *      isSectorApproved
 *   }
 *
 */

export const getSectorInfo = (selectedSector = {}, sectors) => {
    const { parentSectors, childSectors } = groupBy(sectors, (s) =>
        s.parentId === null ? 'parentSectors' : 'childSectors'
    )

    const { sectorId: defaultSectorId, sectorDescription, isSectorApproved } = selectedSector

    const findSector = (sectorId) => findById(sectorId, sectors)
    const getEnhancedSectorInfo = (sector) => ({
        parentSector: sector && findSector(sector.parentId || sector.id),
        childSector: sector && sector.parentId ? sector : null,
        sectorDescription,
    })

    const sectorInfo = getEnhancedSectorInfo(findSector(defaultSectorId))

    return { parentSectors, childSectors, sectorInfo, isSectorApproved }
}

/**
 * Get ownership type information of organization
 * @param {Array.<Object>} ownershipTypes - List of all possible ownership types
 * @param {Number} orgOwnershipTypeId - selected ownership type id of org
 * @return {Object} - Results of ownership type information
 * @example
 *
 * getOrgOwnershipType( [{id: 1, name: "Public"}, 1: {id: 2, name: "Private"}, 2: {id: 3, name: "Government"}], 1 )
 * // => {id: 1, name: "Public"}
 */

export const getOrgOwnershipType = (ownershipTypes, orgOwnershipTypeId) =>
    find(ownershipTypes, (t) => t.id === orgOwnershipTypeId)

/**
 * Evaluate the status of task for company
 * @param {Array.<Object>} statuses - An array of completed statuses
 * @param {number} surveyEventId - Survey Event Id
 * @param {number} taskId - Task Id
 */

export const getTaskCurrentStatus = (statuses, surveyEventId, taskId) => {
    const { completedDateTime } = getTaskStatus(statuses, taskId, surveyEventId)

    return !!completedDateTime
}

/**
 * Sorts the awards in the ascending order of their registration dates
 * @param {Array.<Object>} awards - An array of active awards
 * @param {Array.<Object>} statuses - An array of completed statuses
 */

export const sortAwardsWithRegistrationDeadlineDate = (awards, statuses) => {
    const mappedPrograms = map(
        orderBy(awards, (award) => parseDateOnly(award.registrationCloseDateTime || award.registrationCloseDate)),
        (award) => {
            const isRegistered = getTaskCurrentStatus(
                statuses,
                award.surveyEventId,
                TaskDefinition.RegistrationCompletion
            )
            return { ...award, isRegistered }
        }
    )

    const { unregisteredPrograms = [], registeredPrograms = [] } = groupBy(mappedPrograms, (p) =>
        p.isRegistered ? 'registeredPrograms' : 'unregisteredPrograms'
    )

    return [...unregisteredPrograms, ...registeredPrograms]
}

export const getPublisherName = (publisherName) => {
    if (!publisherName) {
        return ''
    }

    return publisherName.startsWith('The ') ? publisherName : `The ${publisherName}`
}

export const getSurveyNameLink = (
    surveyEventId,
    awardStatus,
    hasInsightsSubscription,
    isSurveyTrackerOnly,
    isInsightsReady
) => {
    let href = ''
    if (awardStatus === AwardStatus.InProgress) {
        href = hasInsightsSubscription ? routes.survey.setup.summary({ surveyEventId }) : routes.survey.workplace()
    } else if (hasInsightsSubscription) {
        href = isInsightsReady
            ? `${config.urls.results}/Home.aspx?e=${surveyEventId}`
            : routes.survey.setup.summary({ surveyEventId })
    } else {
        href = isSurveyTrackerOnly
            ? generateSurveyTrackerUrl('Default', surveyEventId)
            : routes.survey.setup.nextStep({ surveyEventId })
    }

    return href
}

export function duplicateLocationValidation(data, currentLocation, path = '') {
    const isHQ = (name) => name === 'headquarters'
    const currentLocationIndex = currentLocation.index?.toLowerCase().trim()
    const currentLocationName = currentLocation.name?.toLowerCase().trim()
    if (isHQ(currentLocationIndex) || isHQ(currentLocationName)) {
        const editedDuplicateLocations = filter(
            data.editedLocations,
            (location) =>
                !isHQ(location.name?.toLowerCase().trim()) &&
                !isHQ(location.index?.toLowerCase().trim()) &&
                checkDuplicateLocation(location, currentLocation)
        )
        if (size(editedDuplicateLocations) !== 0) {
            return false
        }
    }

    if (data?.validatedData && checkFieldsForDuplicate(currentLocation)) {
        const { validatedData } = data
        if (data.invalidLocation) {
            const errorLocation = filter(data.invalidLocation, (location) => {
                const findValidLocation = filter(validatedData, (item) => item.index === location.index)
                if (size(findValidLocation) > 0) {
                    return false
                }
                return location.index !== currentLocation.index
            })
            return !checkIsLocationDuplicate([...validatedData, ...errorLocation], currentLocation)
        } else {
            const index = path?.match(/\[(.*?)\]/)
            if (index && index !== null && data.editedLocations && size(data.editedLocations) > 1) {
                const editedDuplicateLocations = filter(setIndex(data.editedLocations), (location) => {
                    return (
                        checkDuplicateLocation(location, currentLocation) &&
                        toString(location.index) !== toString(index[1])
                    )
                })
                if (size(editedDuplicateLocations) !== 0) {
                    return false
                }
            }
            const nonEditedLocation = filter(
                data?.validatedData,
                (location) =>
                    find(data.editedLocations, { companyLocationId: location.companyLocationId }) === undefined
            )
            return !checkIsLocationDuplicate(nonEditedLocation, currentLocation)
        }
    }
    return true
}

export const setIndex = (locations) => {
    return map(locations, (location, index) => ({ ...location, index }))
}

export const getIsDuplicateLocation = (locations) => {
    const uniqLocations = uniqWith(locations, checkDuplicateLocation)
    return locations.length !== uniqLocations.length
}

export const checkIsLocationDuplicate = (locations, location) => {
    const duplicateLocation = map(locations, (item) => {
        const isSameRow = item.index !== location.index || !(item.index && location.index)
        const isCompanyLocationIdSame =
            item.companyLocationId !== location.companyLocationId || !location.companyLocationId
        return isSameRow && isCompanyLocationIdSame && checkDuplicateLocation(item, location) && item
    })
    return size(compact(duplicateLocation)) > 0
}

export const checkDuplicateLocation = (locationA, locationB) => {
    return (
        locationA.streetAddress?.toLowerCase().trim() === locationB.streetAddress?.toLowerCase().trim() &&
        locationA.zipCode === locationB.zipCode &&
        locationA.stateAbbreviation?.toLowerCase().trim() === locationB.stateAbbreviation?.toLowerCase().trim() &&
        locationA.city?.toLowerCase().trim() === locationB.city?.toLowerCase().trim()
    )
}

export const hasDuplicateLocationError = (errors) => {
    if (errors) {
        const { city, stateAbbreviation, streetAddress, zipCode } = errors
        const checkLocation =
            city?.type === DUPLICATE_ERROR_TYPE &&
            stateAbbreviation?.type === DUPLICATE_ERROR_TYPE &&
            streetAddress?.type === DUPLICATE_ERROR_TYPE &&
            zipCode?.type === DUPLICATE_ERROR_TYPE
        return errors && checkLocation
    }
    return false
}

export const findDuplicateLocationError = (errors) => {
    const duplicateError = filter(errors?.locations, (location) => hasDuplicateLocationError(location))
    if (size(duplicateError) > 0) {
        showErrorAlert(DUPLICATE_LOCATION_ERROR)
    }
}

export const checkDuplicateLocationFields = (name, data, formMethods) => {
    const index = name?.match(/\[(.*?)\]/)
    if (index && index !== null) {
        const locationIndex = parseInt(index[1])
        if (data?.locations[locationIndex]) {
            const location = data?.locations[locationIndex]
            if (checkFieldsForDuplicate(location)) {
                triggerLocationValidation(formMethods, index[0])
            }
        }
    }
}

export const locationWatcher = (name, data, formMethods, setFormMethods, setEditedLocation) => {
    setFormMethods(formMethods)
    const index = name?.match(/\[(.*?)\]/)
    if (index && index !== null) {
        const locationIndex = parseInt(index[1])
        const currentRecord = data?.locations[locationIndex]
        if (checkFieldsForDuplicate(currentRecord)) {
            map(data?.locations, (location, index) => {
                if (checkFieldsForDuplicate(location)) {
                    triggerLocationValidation(formMethods, index)
                }
            })
        }
    }

    if (index == null && startsWith(name, 'headquarter')) {
        map(data?.locations, (location, index) => {
            if (checkFieldsForDuplicate(location)) {
                triggerLocationValidation(formMethods, index)
            }
        })
    }

    if (checkFieldsForDuplicate(data?.headquarter)) {
        formMethods.trigger([
            'headquarter.streetAddress',
            'headquarter.stateAbbreviation',
            'headquarter.city',
            'headquarter.zipCode',
        ])
    }
    checkDuplicateLocationFields(name, data, formMethods)
    setEditedLocation(compact([...data?.locations, data.headquarter]))
}

export const checkFieldsForDuplicate = (location) =>
    location && location.city && location.zipCode && location.stateAbbreviation && location.streetAddress

export const triggerLocationValidation = (formMethods, index) => {
    formMethods.trigger([
        'locations[' + index + '].streetAddress',
        'locations[' + index + '].stateAbbreviation',
        'locations[' + index + '].city',
        'locations[' + index + '].zipCode',
    ])
}

export const compareDuplicateLocation = (locationsA, locationsB) => {
    const duplicateLocations = filter(locationsA, function (locationA) {
        return size(filter(locationsB, (locationB) => checkDuplicateLocation(locationA, locationB))) > 0
    })
    return size(duplicateLocations) > 0
}

export const modifyRegionName = (name) => replace(name, 'the ', '')

export const getDefaultLocation = (isNationalPublisher, totalLocations = 0) => {
    const locationTypeId = isNationalPublisher ? LocationType.Headquarters : LocationType.NonHeadquarters
    return {
        id: null,
        name: '',
        employeeCount: null,
        index: totalLocations + 1,
        streetAddress: '',
        city: '',
        stateAbbreviation: '',
        zipCode: '',
        typeId: locationTypeId,
    }
}
