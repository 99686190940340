import { useMediaQuery } from '@energage/hooks'
import { colors } from '@energage/theme'
import map from 'lodash/map'
import styled from 'styled-components'
import { media, viewports } from 'style/breakpoints'
import timelineConfig from './timelineConfig'

const BubbleWrapper = styled.div.attrs({ className: 'border-grey300 sm:w-16 w-10 h-full ml-4 sm:ml-0' })`
    position: relative;
    border-width: 2px;
    border-style: solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    ${media.smMin`
        border-top: 0;
        border-bottom: 0;
    `}
`

const Bubble = styled.ul.attrs({
    className: 'rounded-full h-6 sm:h-10 w-5 sm:w-10 bg-yellow500 -ml-3 -mt-5 px-3 sm:-ml-5 md:ml-0 sm:mt-0 sm:px-0',
})`
    position: absolute;
`

const Step = styled.li.attrs({ className: 'flex flex-row self-start text-left w-1/2 h-40' })`
    & :nth-child(odd) {
        flex-direction: row-reverse;
        text-align: right;
        ${BubbleWrapper} {
            border-left: 0;
            border-top: 0;
            border-bottom: 0;
            border-right: 2px solid ${colors.grey300};
            margin-left: 10px;
            ${Bubble} {
                right: -22px;
            }
        }
    }
    & :nth-child(even) {
        align-self: flex-end;
        ${BubbleWrapper} {
            border-right: 0;
            border-top: 0;
            border-bottom: 0;
            margin-right: 10px;
            margin-left: -1px;
            ${Bubble} {
                left: -21px;
            }
        }
    }
    & :last-child {
        ${BubbleWrapper} {
            height: 240px;
        }
    }
`
const MobileStep = styled.li.attrs({ className: 'flex flex-row self-start text-left h-64 sm:h-40' })`
    & :last-child {
        ${BubbleWrapper} {
            height: 336px;
            ${media.smMin`
                height: 240px;
            `}
        }
    }
    }
`

const Timeline = ({
    companyName,
    publicationDate,
    pressReleaseDate,
    eventDate,
    listName,
    isApproxPublishDate,
    year,
    mediaKitExternalLink,
    mediaKitExternalLinkParameters,
    showNationalStandardDetails,
    isDallasRankedWinner,
    showDallasNationalStandardDetails,
    isCeOrIndustryAward,
    hasAlphabeticalWinnersList,
    alphabeticalWinnersListDate,
}) => {
    const timelineList = timelineConfig(
        companyName,
        publicationDate,
        pressReleaseDate,
        eventDate,
        listName,
        isApproxPublishDate,
        year,
        mediaKitExternalLink,
        mediaKitExternalLinkParameters,
        showNationalStandardDetails,
        isDallasRankedWinner,
        showDallasNationalStandardDetails,
        isCeOrIndustryAward,
        false,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate
    )
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMax })
    const StepComponent = isMobileDevice ? MobileStep : Step

    return (
        <ul className="mt-16 flex flex-col items-center p-0 sm:mt-20">
            {map(timelineList, ({ heading, subtitle, text }, index) => (
                <StepComponent key={index}>
                    <BubbleWrapper>
                        <Bubble />
                    </BubbleWrapper>
                    <div className="flex-1 -mt-5 sm:mt-0">
                        <h3 className="sm:text-2xl text-xl font-bold">{heading}</h3>
                        <h3 className="text-base mt-2 sm:mt-0 font-bold">{subtitle}</h3>
                        {text && <p className="mt-4 sm:mt-0">{text}</p>}
                    </div>
                </StepComponent>
            ))}
        </ul>
    )
}

export default Timeline
