import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import circleGradient from 'images/employerBranding/cultureFacts/employeeComments/circleGradient.png'
import circleGradientGray from 'images/employerBranding/cultureFacts/employeeComments/circleGradientGray.png'
import classic from 'images/employerBranding/cultureFacts/employeeComments/classic.png'
import classicGray from 'images/employerBranding/cultureFacts/employeeComments/classicGray.png'
import quote from 'images/employerBranding/cultureFacts/employeeComments/quote.png'
import quoteGray from 'images/employerBranding/cultureFacts/employeeComments/quoteGray.png'
import type { EmpCommentsProps } from '../LoveMyJobConfiguration/EmpComments.types'
import { PreviewTypeSelection } from '../PreviewTypeSelection'

export const EmployeeCommentsDesignSelection = ({ data, dataId, onChange }: EmpCommentsProps) => {
    const SELECT_STATEMENT = 'Select Design'

    const handleDesignTypeChange = (selectedDesign: number) => {
        const allComments = map(data?.comments, (comment) => {
            if (head(filter(data?.comments, (d) => d.numberOfCharacters <= 250)) && comment.numberOfCharacters > 250) {
                return {
                    ...comment,
                    isSelected: false,
                }
            }
            return comment
        })
        onChange({ ...data, comments: allComments, selectedDesignType: selectedDesign })
    }

    return (
        <PreviewTypeSelection
            selectedDesignType={data?.selectedDesignType}
            dataId={dataId}
            designTypeChangeHandler={handleDesignTypeChange}
            selectStatement={SELECT_STATEMENT}
            rectangle={quote}
            rectangleGray={quoteGray}
            rectangleCircle={circleGradient}
            rectangleCircleGray={circleGradientGray}
            classic={classic}
            classicGray={classicGray}
        />
    )
}
