import { useCallback, useEffect, useState } from 'react'
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'
import Fade from 'embla-carousel-fade'
import useEmblaCarousel from 'embla-carousel-react'
import { LazyLoadImage } from './EmblaCarouselLazyLoadImage'
import { NextButton, PrevButton, usePrevNextButtons } from './ThumbCarouselArrowButtons'
import { Thumb } from './ThumbCarouselButton'

type Slide = {
    id: string
    mainImage: string
    thumbImage: string
    backgroundColor: string[]
}

type PropType = {
    slides: Slide[]
    options?: EmblaOptionsType
}

export const ThumbCarousel = ({ slides, options }: PropType) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [slideAnnouncement, setSlideAnnouncement] = useState('')
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options, [Fade()])
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({ dragFree: true }, [Fade()])

    const [slidesInView, setSlidesInView] = useState<number[]>([])

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(
        emblaMainApi,
        slides.length
    )

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return
            emblaMainApi.scrollTo(index)
            setSelectedIndex(index)
        },
        [emblaMainApi, emblaThumbsApi]
    )

    const onSelect = useCallback(() => {
        if (!emblaMainApi) return
        const selected = emblaMainApi.selectedScrollSnap()
        setSelectedIndex(selected)
        emblaThumbsApi?.scrollTo(selected)
        setSlideAnnouncement(`Inspiration Gallery Example ${selected + 1}`)
    }, [emblaMainApi, emblaThumbsApi])

    function onThumbClickHandler(slideId: string) {
        const index = slides.findIndex((s) => s.id === slideId)
        onThumbClick(index)
    }

    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((prevSlidesInView) => {
            if (prevSlidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off('slidesInView', updateSlidesInView)
            }
            const inView = emblaApi.slidesInView().filter((index) => !prevSlidesInView.includes(index))
            return prevSlidesInView.concat(inView)
        })
    }, [])

    useEffect(() => {
        if (!emblaMainApi) return

        updateSlidesInView(emblaMainApi)
        emblaMainApi.on('slidesInView', updateSlidesInView)
        emblaMainApi.on('reInit', updateSlidesInView)
        emblaMainApi.on('select', onSelect)
        emblaMainApi.on('reInit', onSelect)
    }, [emblaMainApi, updateSlidesInView, onSelect])

    useEffect(() => {
        emblaThumbsApi?.scrollTo(selectedIndex)
    }, [selectedIndex, emblaThumbsApi])

    const generateGradient = (colors: string[]) => {
        return `linear-gradient(90deg, ${colors.join(', ')})`
    }

    return (
        <section
            className="relative"
            style={{
                background: generateGradient(slides[selectedIndex]?.backgroundColor || ['#ffffff']),
                padding: '0px',
            }}
            id="myCarousel"
            aria-roledescription="carousel"
            aria-label="Inspiration Gallery Examples">
            <div className="px-8">
                <PrevButton
                    onClick={onPrevButtonClick}
                    disabled={prevBtnDisabled}
                    tabIndex={selectedIndex === 0 ? -1 : 1}
                    direction={'prev'}
                />
                <div aria-live="polite" aria-atomic="true" className="sr-only">
                    {slideAnnouncement}
                </div>
                <div className="m-auto" style={{ maxWidth: '48rem' }}>
                    <div className="overflow-hidden" ref={emblaMainRef} role="group" aria-roledescription="slide">
                        <div className="flex ml-[-16px] touch-pan-y">
                            {slides.map((slide, index) => (
                                <div
                                    className="flex justify-center items-center"
                                    style={{ flex: '0 0 100%' }}
                                    key={slide.id}
                                    id={`slide-${slide.id}`}
                                    aria-hidden={slide.id !== slides[selectedIndex].id}>
                                    <LazyLoadImage
                                        key={index}
                                        index={index}
                                        imgSrc={slide.mainImage}
                                        inView={slidesInView.includes(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <NextButton
                        onClick={onNextButtonClick}
                        disabled={nextBtnDisabled}
                        tabIndex={selectedIndex === 0 ? 1 : 2}
                        direction={'next'}
                    />
                </div>
            </div>
            <div className="list-none py-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                <div className="embla-thumbs__viewport overflow-hidden" ref={emblaThumbsRef}>
                    <ul className="embla-thumbs__container flex justify-center pl-0 overflow-x-auto">
                        {slides.map((slide) => (
                            <li className="list-none flex-none" key={slide.id}>
                                <Thumb
                                    onClick={() => onThumbClickHandler(slide.id)}
                                    selected={slide.id === slides[selectedIndex].id}
                                    imageUrl={slide.thumbImage}
                                    ariaControls={`slide-${slide.id}`}
                                    slideNumber={slides.findIndex((s) => s.id === slide.id) + 1}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default ThumbCarousel
