import size from 'lodash/size'
import { CardContainer } from 'components/CardContainer'
import DownloadFileButton from 'components/DownloadFileButton'
import FileDropZone, { contentByState } from './FileDropZone'
import type { BaseContent } from './FileDropZone'

export const ERRORS = {
    'Unable to parse the file': {
        wrongFile: {
            errorCode: 'WrongFile',
            errorMessage: (
                <>
                    <span>{'Looks like you uploaded the wrong file.'}</span>
                    <br />
                    <span>{'Please try again.'}</span>
                </>
            ),
            propertyName: 'WrongFile',
            attemptedValue: 'Wrong File',
            customState: '',
        },
    },
    'File is password-protected': {
        passwordProtected: [
            {
                errorCode: 'PasswordProtected',
                errorMessage:
                    'This spreadsheet appears to be password-protected. Please remove the protection and upload again.',
                propertyName: 'PasswordProtected',
                attemptedValue: 'File encrypted',
                customState: '',
                severity: 0,
            },
        ],
    },
    "Cannot read properties of undefined (reading 'isMappable')": {
        wrongHeaders: {
            errorMessage: 'The file you uploaded does not match the template. Please try again.',
            attemptedValue: 'Wrong Headers',
            customState: '',
        },
    },
}

type Members = Record<string, unknown>

interface Warning {
    data: {
        code: string
    }
}

interface UploadState {
    membersToUpload: Members[] | null
    uploadedMembers: Members[] | null
    warnings: Warning[]
    hasActiveSurveyEvents: boolean
    successful: boolean
    validationErrors?: Error
}

type GetDropZoneState = (data?: UploadState, loading?: boolean, error?: Error) => BaseContent
const getDropZoneState: GetDropZoneState = (data, loading, error) => {
    if (error) {
        return contentByState.error
    }
    if (loading) {
        return contentByState.loading
    }
    if (data) {
        return data.validationErrors ? contentByState.error : contentByState.success
    }
    return contentByState.waiting
}

interface UploadProps {
    errors: Error | undefined
    onFileDrop: (file: File) => void
    data: UploadState
    loading: boolean
    templateName: string
}

const Upload = ({ errors, onFileDrop, data, loading, templateName }: UploadProps) => {
    const hasErrors = size(errors) > 0

    return (
        <div className="h-full mx-auto text-center">
            <CardContainer>
                <p className="text-xl text-center">{hasErrors ? 'Upload New Spreadsheet' : 'Upload a Spreadsheet'}</p>
                {/* @ts-ignore */}
                <DownloadFileButton
                    className="p-0 py-3"
                    variant="link"
                    fileLocation={`${process.env.PUBLIC_URL}/assets/${templateName}`}>
                    {'Download a template'}
                </DownloadFileButton>

                <div className="p-4">
                    <FileDropZone
                        state={getDropZoneState(data, loading, errors)}
                        onFileDrop={onFileDrop}
                        errorMessage={errors?.message}
                    />
                </div>
            </CardContainer>
        </div>
    )
}

export default Upload
