import { OpenInNew } from '@energage/icons'
import getInspiredCtaBg from 'images/employerBranding/inspirationGallery/getInspiredCtaBg.png'
import getInspiredCtaHoverBg from 'images/employerBranding/inspirationGallery/getInspiredCtaBgHover.png'
import { CtaButton } from './CtaButton'

type GetInspiredButtonProps = {
    dataId: 'cultureCloud' | 'linkedInBanner' | 'cultureStats' | 'comments' | 'strengths'
    onClick: () => void
}

export const GetInspiredCtaButton = ({ dataId, onClick }: GetInspiredButtonProps) => {
    const label = 'Get Inspired'

    const getAriaLabel = (dataId: GetInspiredButtonProps['dataId']) => {
        switch (dataId) {
            case 'cultureCloud':
                return 'Get Inspired: Culture Cloud'
            case 'linkedInBanner':
                return 'Get Inspired: LinkedIn Banner'
            case 'cultureStats':
                return 'Get Inspired: Culture Stats'
            case 'comments':
                return 'Get Inspired: Employee Comments'
            case 'strengths':
                return 'Get Inspired: Organizational Strengths'
            default:
                return 'Get Inspired'
        }
    }

    return (
        <CtaButton
            label={label}
            ariaLabel={getAriaLabel(dataId)}
            background={getInspiredCtaBg}
            backgroundHover={getInspiredCtaHoverBg}
            icon={OpenInNew}
            onClick={onClick}
        />
    )
}
