import { RadialChart } from '@energage/charts'
import { colors } from '@energage/theme'
import tint from 'polished/lib/color/tint'
import styled from 'styled-components'
import contrast from 'util/contrast'

export type CultureStatsDonutGraphProps = {
    positivePercent: number
    className?: string
    height?: string
    topMargin?: string
    width?: string
    backgroundColor: string
}

const LargeTextBlock = styled.tspan.attrs({ className: 'font-bold mr-1' })`
    fill: ${(props: { backgroundColor: string }) =>
        contrast(props.backgroundColor).isLight ? colors.black : colors.white};
    font-size: 36px;
    font-family: 'Yantramanav', 'system-ui';
`

const PercentTextBlock = styled.tspan`
    fill: ${(props: { backgroundColor: string }) =>
        contrast(props.backgroundColor).isLight ? colors.black : colors.white};
    font-size: 14px;
    font-family: 'Yantramanav', 'system-ui';
`

const DonutContainer = styled.div<{ height: string; width: string }>`
    margin-bottom: 10px;
    height: ${(props: { height: string }) => props.height};
    width: ${(props: { width: string }) => props.width};
`

const backgroundColorOpacity = (bgColor: string) => {
    try {
        return tint(0.24, bgColor)
    } catch (err) {
        return '#F0F1F6'
    }
}

export const CultureStatsDonutGraph = ({
    positivePercent,
    className,
    height = '150px',
    width = '150px',
    backgroundColor,
}: CultureStatsDonutGraphProps) => {
    const backgroundFillColor =
        backgroundColor === '#FDBF3F' || contrast(backgroundColor).isLight ? '#15161A' : '#FDBF3F'

    return (
        <DonutContainer className={className} height={height} width={width}>
            <RadialChart
                value={positivePercent}
                backgroundColor={backgroundColorOpacity(backgroundColor)}
                fillColor={backgroundFillColor}
                variant="donut">
                <text
                    className="relative"
                    x="0"
                    y="0"
                    textAnchor="middle"
                    fill={colors.white}
                    alignmentBaseline="central">
                    <LargeTextBlock backgroundColor={backgroundColor} x={positivePercent >= 100 ? -3 : 0} y={12}>
                        {positivePercent}
                    </LargeTextBlock>
                    <PercentTextBlock backgroundColor={backgroundColor} x={positivePercent >= 100 ? 29 : 24} y={-3}>
                        {'%'}
                    </PercentTextBlock>
                </text>
            </RadialChart>
        </DonutContainer>
    )
}
