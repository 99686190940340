import { useState } from 'react'
import { alert } from '@energage/components'
import { useOrderingQuery } from 'api'
import showErrorAlert from 'util/showErrorAlert'

const createQueryFn = (url) => async (_, api) => await api.json(url)

const defaultOptions = {
    cacheTime: 0,
}

export const useFetchContacts = (surveyEventId, organizationId, onSuccess) =>
    useOrderingQuery(
        'order-page-contacts',
        createQueryFn(`Orders/Organization/${organizationId}/Orders/${surveyEventId}/Contacts`),
        {
            ...defaultOptions,
            onSuccess,
            onError: (error) => showErrorAlert('There was an error loading contacts', error.parsed),
        }
    )

export const useSaveContacts = (surveyEventId, organizationId) => {
    const [saveContactsError, setError] = useState()
    const {
        mutateAsync: saveContactDetails,
        error,
        isLoading: isSavingContacts,
        reset: resetSaveContacts,
    } = useOrderingQuery.mutate(
        async (payload, api) =>
            await api.postJson(`Orders/Organization/${organizationId}/Orders/${surveyEventId}/Contacts`, payload),
        {
            onSuccess: () => alert.success('Contact details saved successfully!'),
            onError: () => {
                setError(!!error || true)
                resetSaveContacts()
                showErrorAlert('Something went wrong while saving the contacts. Please try again. Error', error)
            },
        }
    )

    return { saveContactDetails, saveContactsError, isSavingContacts }
}
