import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'

function useFetchSurveyTemplates(surveyPage) {
    const { surveyCompanyId } = useIdentity()
    const { data, error, status } = useWorkplaceSurveyQuery(
        [`survey-templates-${surveyPage}`, surveyCompanyId],
        `surveycompanies/${surveyCompanyId}/surveytemplate/${surveyPage ?? ''}`
    )
    return {
        templates: data,
        fetchSurveyTemplatesError: error,
        loadingTemplates: status === 'loading',
    }
}

export { useFetchSurveyTemplates }
