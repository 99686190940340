import cx from 'clsx'
import filter from 'lodash/filter'
import map from 'lodash/map'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import type { ReelsPreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/OrgStrengthsConfiguration/OrgStrengths.types'
import { previewType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

const PreviewContainer = styled.div<{
    backgroundColor?: string
    width?: string
    height?: string
}>`
    width: ${(props) => props.width ?? '340px'};
    height: ${(props) => props.height ?? '340px'};
    background-color: ${(props) => props.backgroundColor};
`

const Trangle = styled.div<{ backgroundColor: string }>`
    border-top: 1920px solid transparent;
    border-left: 1080px solid rgba(255, 255, 255, 0.3);
`
const Circle = styled.div<{ highlightColor: string; top: string }>`
    width: 550px;
    height: 550px;
    border: 1px solid ${(props) => props.highlightColor};
    border-radius: 100%;
    top: ${(props) => props.top};
    left: 95px;
`

const CompanyStrengths = styled.h3<{ highlightColor: string }>`
    color: ${(props) => props.highlightColor};
    font-size: 6rem;
    bottom: 30px;
    right: 60px;
`
const TextWrapper = styled.div<{ textColor: string }>`
    color: ${(props) => props.textColor};
`

const StrengthsName = styled.h3`
    font-size: 6rem;
    line-height: 1;
`

export const OrgStrengthsReelsPreview = ({ data, selectedDesignType }: ReelsPreviewProps) => {
    const selectedData = filter(data?.orgStrengths, 'isSelected')
    const isTrangleDesignType = selectedDesignType === previewType.TriangleDesign

    return (
        <PreviewContainer className="relative" width="1080px" height="1920px" backgroundColor={data?.backgroundColor}>
            {isTrangleDesignType && (
                <Trangle className="absolute top-0 left-0" backgroundColor={data?.backgroundColor} />
            )}
            {!isTrangleDesignType && (
                <>
                    <Circle className="absolute" highlightColor={data?.highlightColor} top="260px" />
                    <Circle className="absolute" highlightColor={data?.highlightColor} top="700px" />
                    <Circle className="absolute" highlightColor={data?.highlightColor} top="1140px" />
                </>
            )}
            <div className={cx('grid', isTrangleDesignType ? 'justify-items-end mr-12 ' : 'ml-12 ')}>
                <div className="w-48 mt-12 mb-12">
                    <ProgressiveImage src={CultureFactTWP} placeholder="">
                        {(src) => <img src={src} alt={'Top work places'} className="border rounded-full w-full" />}
                    </ProgressiveImage>
                </div>
            </div>
            {map(selectedData, ({ name, text }, index) => (
                <>
                    <TextWrapper
                        key={index}
                        className={cx(
                            'mr-12 mt-20 relative',
                            isTrangleDesignType ? 'text-right ml-12 pl-40' : 'text-left ml-24 pr-32'
                        )}
                        textColor={data?.textColor}>
                        <StrengthsName className="uppercase">{`${name}`}</StrengthsName>
                        <p className="text-6xl mt-8">{`${text}`}</p>
                    </TextWrapper>
                </>
            ))}
            <CompanyStrengths className="absolute transform" highlightColor={data?.highlightColor}>
                {'Company Strengths'}
            </CompanyStrengths>
        </PreviewContainer>
    )
}
