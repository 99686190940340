import map from 'lodash/map'

export function getOffset(index, subsections) {
    let offset = 0

    if (index === 0) {
        return offset
    }

    let count = 0
    while (count < index) {
        offset += subsections[count].questions.length
        count++
    }

    return offset
}

export function reorder(list, startIndex, destinationIndex) {
    const result = list.slice()
    const [removed] = result.splice(startIndex, 1)
    result.splice(destinationIndex, 0, removed)

    return result
}

export function resetSequenceForSubsection(updateQuestionsSection) {
    return (subsectionId, newQuestionOrder = null) => {
        updateQuestionsSection((section) => ({
            ...section,
            subsections: map(section.subsections, (subsection, index) => {
                if (subsection.subsectionId === subsectionId) {
                    return {
                        ...subsection,
                        questions: map(newQuestionOrder ?? subsection.questions, (question, questionIndex) => {
                            const offsetIndex = getOffset(index, section.subsections)
                            return { ...question, sequence: offsetIndex + questionIndex }
                        }),
                    }
                }
                return subsection
            }),
        }))
    }
}
