import PropTypes from 'prop-types'

const departmentsTagShape = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    modifiedDateTime: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
}

export const departmentShape = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    modifiedDateTime: PropTypes.string.isRequired,
    departmentsTag: PropTypes.shape(departmentsTagShape).isRequired,
}
departmentShape.children = PropTypes.arrayOf(PropTypes.shape(departmentShape))

export const optionTagShape = {
    id: PropTypes.string.isRequired,
    modifiedDateTime: PropTypes.string.isRequired,
    parentTagOptionId: PropTypes.string,
    tagId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

const tagValueShape = {
    id: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
    modifiedDateTime: PropTypes.string.isRequired,
    tagId: PropTypes.string.isRequired,
    tagOptionId: PropTypes.string,
    valueTypeId: PropTypes.number.isRequired,
}

export const memberShape = {
    emailAddress: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    name: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    modifiedDateTime: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    tagValues: PropTypes.arrayOf(PropTypes.shape(tagValueShape)).isRequired,
}
