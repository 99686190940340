import type { ComponentPropsWithoutRef } from 'react'
import { LegacyButton as Button } from '@energage/components'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LoadingButton from 'components/LoadingButton'
import { routes } from 'constants/routes'

type Variant = 'primary' | 'secondary' | 'danger' | 'link'

export function AddAwardButton({ className, variant = 'link' }: { className?: string; variant?: Variant }) {
    return (
        <Button
            as={Link}
            to={routes.employerRecognition.award.add()}
            className={cx('text-base inline-block', className)}
            variant={variant}
            target="_blank">
            {'Add eligible awards'}
        </Button>
    )
}

export function AddLocationButton({ className, variant = 'link' }: { className?: string; variant?: Variant }) {
    return (
        <Button
            as={Link}
            to={routes.employerRecognition.manageLocations()}
            className={cx('text-base inline-block', className)}
            variant={variant}
            target="_blank">
            {'add locations'}
        </Button>
    )
}

export function SaveButton({ className, isLoading, disabled }: ComponentPropsWithoutRef<typeof LoadingButton>) {
    return (
        <LoadingButton outline className={className} type="submit" isLoading={isLoading} disabled={disabled}>
            {'Save'}
        </LoadingButton>
    )
}

export const NationAwardsInfoTooltipContainer = styled.div.attrs({
    className: 'text-white text-left paragraph-small p-1',
})`
    max-width: 10rem;
`
