import get from 'lodash/get'
import merge from 'lodash/merge'
import * as yup from 'yup'

yup.addMethod(yup.mixed, 'whenStrict', function (builder) {
    return this.when('$strictMode', (...args) => builder(...args))
})

yup.addMethod(yup.mixed, 'requiredWhenStrict', function (message = 'Required') {
    return this.whenStrict((strictMode, schema) => (strictMode ? schema.required(message) : schema.notRequired()))
})

yup.addMethod(yup.mixed, 'validateStrict', async function (object, options) {
    return this.validate(object, merge({}, options, { context: { strictMode: true } }))
})

yup.addMethod(yup.mixed, 'validateStrictSync', function (object, options = {}) {
    return this.validateSync(object, merge({}, options, { context: { strictMode: true } }))
})

// eslint-disable-next-line no-template-curly-in-string
const maxMsg = 'Must be at most ${max} characters'

export const stringMax255 = yup.string().max(255, maxMsg)
export const requiredStringMax255 = stringMax255.required('Required')
export const stringMax100 = yup.string().max(100, maxMsg)
export const requiredStringMax100 = stringMax100.required('Required')
export const stringMax50 = yup.string().max(50, maxMsg)
export const requiredStringMax50 = stringMax50.required('Required')
export const isValidHexColor = (hex) => /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3,4})$/.test(hex)
export const PHONE_NUMBER_REGEX =
    /^(\+*)(\d*)([(.]\d{1,3}[).]*)*(\s?\d+|\+\d{2,3}\s\d+|\d+)[\s|-]?\d+([\s|-]?\d+){1,2}(\s)*$/

// this should be used in conjunction with yup schema validation
export function getErrorMessage(errors, path) {
    return get(errors, path)?.message
}

export const optionalNumber = yup
    .number()
    .nullable()
    .optional()
    .transform((value) => (isNaN(value) ? undefined : value))
