import cx from 'clsx'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import Section from 'containers/Main/AwardNotification/Section'
import { getMarginValueForMediaScreen } from 'containers/Main/AwardNotification/Sections/utils'
import { getExternalLinkParameters } from 'containers/Main/AwardNotification/Sections/WhatsNext/WhatsNext'
import { StyledLogoYear } from 'containers/Main/AwardNotification/StyledLogoYear'
import {
    CE_AND_INDUSTRY_PUBLISHER_ID,
    CE_AWARD_NAME,
    DALLAS_PUBLISHER_ID,
    INDUSTRY_AWARD_NAME,
    LONG_ISLAND_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    TWP_USA_AWARD_NAME,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { getYearPosition } from 'containers/Main/EmployerBranding/MediaKit/Utils'
import withClassName from 'style/util/withClassName'
import { PageBreaker as PageBreakerTimeline } from './OnlineAnnouncementPagePdf'
import Timeline from './Timeline'

const HorizontalLine = withClassName(
    'border-grey300 border-2 border-solid border-r-0 border-l-0 border-t-0 pb-8 -mx-64',
    'hr',
    'horizontalLine'
)

const RegionalAwardImage = styled.img.attrs({ className: 'inline-block relative' })`
    height: ${(props) => (props.isDallasRankedWinner ? '8rem' : '10rem')};
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: ${(props) => props.publisherId === LONG_ISLAND_PUBLISHER_ID && '0.5rem'};

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        width: ${(props) =>
            props.publisherId === MINNEAPOLIS_PUBLISHER_ID ? '8rem' : props.isDallasRankedWinner ? '12rem' : '6rem'};
        margin-bottom: ${(props) => getMarginValueForMediaScreen(props.publisherId)};
        height: ${(props) => (props.publisherId === MINNEAPOLIS_PUBLISHER_ID ? '10rem' : '8rem')};
    }
`

const TimlineWrapper = styled.div`
    min-height: 1236px;
`

const CEAwardsSection = ({
    awards,
    quarter,
    ceAwards,
    isPdf,
    mediaKitExternalLink,
    baseUrl,
    companyName,
    isMediaKitAnnouncement,
}) => {
    const awardDetails = head(awards)
    const forOnlineAnnouncement = quarter === '0'
    const isLastWinningQuarter = Object.keys(ceAwards).pop() === quarter
    const isPageBreakRequired = quarter < size(ceAwards) || !isLastWinningQuarter
    const isCEAward = true

    return (
        <TimlineWrapper>
            <div className={cx('flex flex-row flex-wrap justify-center')}>
                {map(awards, (x, index) => (
                    <SvgLoader path={x.svgUrl} key={index} height="8rem" alt="CE awards logos">
                        <SvgProxy
                            selector="text"
                            font-family="'Yantramanav', sans-serif"
                            font-size="46"
                            font-weight="900"
                        />
                        <SvgProxy selector="text tspan" y={getYearPosition(x.awardName)}>
                            {`${x.year}`}
                        </SvgProxy>
                    </SvgLoader>
                ))}
            </div>
            <div className="flex flex-col justify-center mt-3 pb-0">
                <h1 className="text-xl sm:text-5xl text-center font-black mt-3">
                    {awardDetails.publisherId !== CE_AND_INDUSTRY_PUBLISHER_ID ? awardDetails.awardName : CE_AWARD_NAME}
                </h1>
                <Timeline
                    companyName={companyName}
                    publicationDate={awardDetails.publicationDate}
                    pressReleaseDate={awardDetails.pressReleaseDate}
                    eventDate={awardDetails.eventDate}
                    listName={awardDetails.listName}
                    isApproxPublishDate={awardDetails.isApproxPublishDate}
                    year={awardDetails.year}
                    isPdf={isPdf}
                    baseUrl={baseUrl}
                    mediaKitExternalLink={mediaKitExternalLink}
                    mediaKitExternalLinkParameters={getExternalLinkParametersForPdf(
                        isMediaKitAnnouncement,
                        awardDetails.awardListParticipantId,
                        awardDetails.year,
                        isCEAward
                    )}
                    isMediaKitAnnouncement={isMediaKitAnnouncement}
                    isCeOrIndustryAward={true}
                    hasAlphabeticalWinnersList={awardDetails.hasAlphabeticalWinnersList}
                    alphabeticalWinnersListDate={awardDetails.alphabeticalWinnersListDate}
                />
                <HorizontalLine />
            </div>
            {!forOnlineAnnouncement && isPageBreakRequired && <PageBreakerTimeline />}
        </TimlineWrapper>
    )
}

const getExternalLinkParametersForPdf = (isMediaKitAnnouncement, awardListParticipantId, year, isCEAward = 0) => {
    return !isMediaKitAnnouncement ? getExternalLinkParameters(year, awardListParticipantId, isCEAward) : ''
}

export const getCeAwards = (cultureExcellence) => {
    if (cultureExcellence) {
        return groupBy(cultureExcellence, (ceAward) => ceAward.quarter)
    }

    return []
}

const WhatsNext = ({
    bgColor,
    textColor,
    isPdf,
    baseUrl,
    timelineAwards,
    awardName,
    companyName,
    sectionClassName,
    mediaKitExternalLink,
    showNationalStandardDetails,
    isMediaKitAnnouncement,
    isDallasRankedWinner,
    showDallasNationalStandardDetails,
}) => {
    const {
        regional,
        national: { twpUsa, industry, cultureExcellence },
    } = timelineAwards

    const regionalAwards = filter(regional, (x) => x.isTopWorkplace)
    const industryAward = industry?.isTopWorkplace ? industry : null
    const ceAwards = filter(cultureExcellence, (x) => x.isTopWorkplace)
    const cultureExcellenceAwards = getCeAwards(ceAwards)

    const hasAllAwards = size(regionalAwards) > 0 || size(cultureExcellenceAwards) > 0 || size(industryAward) > 0
    return (
        <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName} isPdf={isPdf}>
            <h1 className="sm:text-5xl font-black text-3xl pl-4 pt-10 sm:pl-0 sm:pt-0">{"What's Next?"}</h1>
            <h1 className="sm:text-5xl text-3xl font-black pl-4 sm:pl-0">{'The countdown to the big celebration.'}</h1>
            {twpUsa && twpUsa.isTopWorkplace && (
                <div>
                    <div className="flex flex-col justify-center mt-4">
                        <div className="relative self-center">
                            <SvgLoader path={twpUsa.svgUrl} height="10rem" alt="TWPUSA awards logos">
                                <SvgProxy
                                    selector="text"
                                    font-family="'Yantramanav', sans-serif"
                                    font-size="46"
                                    font-weight="900"
                                />
                                <SvgProxy selector="text tspan">{`${twpUsa.year}`}</SvgProxy>
                            </SvgLoader>
                        </div>
                        <h1 className="text-xl sm:text-5xl text-center font-black mt-3">{TWP_USA_AWARD_NAME}</h1>
                        <Timeline
                            companyName={companyName}
                            publicationDate={twpUsa.publicationDate}
                            pressReleaseDate={twpUsa.pressReleaseDate}
                            eventDate={twpUsa.eventDate}
                            listName={twpUsa.listName}
                            isApproxPublishDate={twpUsa.isApproxPublishDate}
                            year={twpUsa.year}
                            isPdf={isPdf}
                            baseUrl={baseUrl}
                            mediaKitExternalLink={mediaKitExternalLink}
                            mediaKitExternalLinkParameters={getExternalLinkParametersForPdf(
                                isMediaKitAnnouncement,
                                twpUsa.awardListParticipantId,
                                twpUsa.year
                            )}
                            hasAlphabeticalWinnersList={twpUsa.hasAlphabeticalWinnersList}
                            alphabeticalWinnersListDate={twpUsa.alphabeticalWinnersListDate}
                        />
                        <HorizontalLine />
                    </div>
                    {hasAllAwards && <PageBreakerTimeline />}
                </div>
            )}
            {size(regionalAwards) > 0 &&
                map(regionalAwards, (x, index) => (
                    <TimlineWrapper>
                        <div
                            className={
                                index === 0
                                    ? 'flex flex-col justify-center mt-4'
                                    : 'flex flex-col justify-center mt-4 pt-16'
                            }>
                            <div className="relative self-center">
                                <RegionalAwardImage
                                    alt="logo"
                                    src={x.svgUrl}
                                    className="h-32"
                                    isDallasRankedWinner={
                                        x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard
                                    }
                                    publisherId={x.publisherId}
                                />
                                <StyledLogoYear
                                    publisherId={x.publisherId}
                                    year={x.year}
                                    showNationalStandardDetails={showNationalStandardDetails}
                                    isDallasRankedWinner={
                                        x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard
                                    }>
                                    {x.year}
                                </StyledLogoYear>
                            </div>
                            <h1 className="text-xl sm:text-5xl text-center font-black mt-3">
                                {showDallasNationalStandardDetails
                                    ? 'National Standard Award'
                                    : awardName || x.regionName}
                            </h1>
                            <Timeline
                                companyName={companyName}
                                publicationDate={x.publicationDate}
                                pressReleaseDate={x.pressReleaseDate}
                                eventDate={x.eventDate}
                                listName={x.listName}
                                isApproxPublishDate={x.isApproxPublishDate}
                                year={x.year}
                                isPdf={isPdf}
                                baseUrl={baseUrl}
                                mediaKitExternalLink={mediaKitExternalLink}
                                mediaKitExternalLinkParameters={getExternalLinkParametersForPdf(
                                    isMediaKitAnnouncement,
                                    x.awardListParticipantId,
                                    x.year
                                )}
                                showNationalStandardDetails={showNationalStandardDetails}
                                isMediaKitAnnouncement={isMediaKitAnnouncement}
                                isDallasRankedWinner={isDallasRankedWinner}
                                showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                                hasAlphabeticalWinnersList={x.hasAlphabeticalWinnersList}
                                alphabeticalWinnersListDate={x.alphabeticalWinnersListDate}
                            />
                            {hasAllAwards && <HorizontalLine />}
                        </div>
                        {(size(cultureExcellenceAwards) > 0 || size(industryAward) > 0) && <PageBreakerTimeline />}
                    </TimlineWrapper>
                ))}
            {size(cultureExcellenceAwards) > 0 &&
                map(
                    cultureExcellenceAwards,
                    (awards, key) =>
                        awards && (
                            <CEAwardsSection
                                awards={awards}
                                quarter={key}
                                isPdf={isPdf}
                                baseUrl={baseUrl}
                                mediaKitExternalLink={mediaKitExternalLink}
                                companyName={companyName}
                                key={key}
                                isMediaKitAnnouncement={isMediaKitAnnouncement}
                                ceAwards={cultureExcellenceAwards}
                            />
                        )
                )}
            {industryAward && (
                <div>
                    <div className="flex flex-col justify-center mt-4">
                        <div className="relative self-center">
                            <SvgLoader path={industryAward.svgUrl} height="10rem" alt="Industry awards logos">
                                <SvgProxy
                                    selector="text"
                                    font-family="'Yantramanav', sans-serif"
                                    font-size="46"
                                    font-weight="900"
                                />
                                <SvgProxy selector="text tspan" y={getYearPosition(industryAward.awardName)}>
                                    {`${industryAward.year}`}
                                </SvgProxy>
                            </SvgLoader>
                        </div>
                        <h1 className="text-xl sm:text-5xl text-center font-black mt-3">{INDUSTRY_AWARD_NAME}</h1>
                        <Timeline
                            companyName={companyName}
                            publicationDate={industryAward.publicationDate}
                            pressReleaseDate={industryAward.pressReleaseDate}
                            eventDate={industryAward.eventDate}
                            listName={industryAward.listName}
                            isApproxPublishDate={industryAward.isApproxPublishDate}
                            year={industryAward.year}
                            isPdf={isPdf}
                            baseUrl={baseUrl}
                            mediaKitExternalLink={mediaKitExternalLink}
                            mediaKitExternalLinkParameters={getExternalLinkParametersForPdf(
                                isMediaKitAnnouncement,
                                industryAward.awardListParticipantId,
                                industryAward.year
                            )}
                            isCeOrIndustryAward={true}
                            hasAlphabeticalWinnersList={industryAward.hasAlphabeticalWinnersList}
                            alphabeticalWinnersListDate={industryAward.alphabeticalWinnersListDate}
                        />
                        <HorizontalLine />
                    </div>
                </div>
            )}
        </Section>
    )
}

export default WhatsNext
