import PropTypes from 'prop-types'
import { NavigationLink as Base } from '@energage/components'
import { NavLink } from 'react-router-dom'

const NavigationLink = ({ to, label, onClick, tracking, ...props }) => {
    return (
        <Base as={NavLink} to={to} onClick={onClick} {...props}>
            {label}
        </Base>
    )
}

NavigationLink.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.node,
    label: PropTypes.node,
    onClick: PropTypes.func,
}

export default NavigationLink
