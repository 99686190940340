import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import { routes } from 'constants/routes'

function formatDate(date, timeZone, hasYear) {
    const formatString = `h:mm aaaa z, MMMM d${hasYear ? ', yyyy' : ''}`
    return (
        date &&
        format(utcToZonedTime(toDate(date), timeZone?.ianaKey), formatString, {
            timeZone: timeZone?.ianaKey,
        })
    )
}

const SurveyInvitationOverlappingScheduleConfirmation = ({
    show,
    onConfirm,
    onCancel,
    overlappingSurvey,
    timeZone,
}) => {
    const launchDateAsString = formatDate(overlappingSurvey?.launchDateTime, timeZone, false)
    const closeDateAsString = formatDate(overlappingSurvey?.scheduledCloseDateTime, timeZone, true)

    return (
        <Modal
            isOpen={show}
            onClose={onCancel}
            className="w-5/6 sm:w-4/5 md:w-2/3 lg:w-2/5"
            title="Run two surveys at once?">
            <Inset padding>
                <p>
                    {`This schedule overlaps with "`}
                    <a href={routes.survey.setup.schedule(overlappingSurvey?.id)}>{`${overlappingSurvey?.name}`}</a>
                    {`", which runs from ${launchDateAsString} to ${closeDateAsString}. Did you want to send two surveys at the same time?`}
                </p>
            </Inset>
            <div className="flex justify-end px-3 pb-3">
                <Button variant="secondary" outline onClick={onCancel}>
                    {'No, schedule for another time'}
                </Button>
                <Button variant="secondary" className="ml-4" onClick={onConfirm}>
                    {'Yes, run multiple surveys at once'}
                </Button>
            </div>
        </Modal>
    )
}

SurveyInvitationOverlappingScheduleConfirmation.propTypes = {
    show: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    overlappingSurvey: PropTypes.shape({
        id: PropTypes.number,
        launchDateTime: PropTypes.instanceOf(Date),
        scheduledCloseDateTime: PropTypes.instanceOf(Date),
    }),
    timeZone: PropTypes.shape({
        ianaKey: PropTypes.string.isRequired,
    }),
}

export default SurveyInvitationOverlappingScheduleConfirmation
