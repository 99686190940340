import map from 'lodash/map'
import { faqList as faqListAspiring } from 'containers/Main/AwardAnnouncement/Aspirant/FAQs/faqList'
import type { PdfFAQsSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { faqList } from 'containers/Main/AwardAnnouncement/Winners/FAQs/faqList'
import { YellowButton } from 'containers/Main/AwardAnnouncement/YellowButton'

export const FAQs = ({ isWinner, companyProfileUrl, companyName, year, faqUrl, colors }: PdfFAQsSection) => {
    const faqLists = isWinner ? faqList(companyProfileUrl) : faqListAspiring(companyName, year)

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor} isPdf={isWinner}>
            <h2 className={isWinner ? 'text-4xl font-black pb-6 -mt-4' : 'text-5xl font-black pb-6 -mt-4'}>
                {'Frequently Asked Questions'}
            </h2>
            {map(faqLists, ({ question, answer }) => (
                <>
                    <h2 className={isWinner ? 'text-lg font-bold mt-6' : 'text-xl font-bold mt-6'}>{question}</h2>
                    <p className={isWinner ? 'text-sm mt-2' : 'text-base mt-2'}>{answer}</p>
                </>
            ))}
            <div className="mt-12 text-center">
                <YellowButton className="px-10 py-3 text-xs" variant="link" href={faqUrl} target="_blank">
                    {'Top Workplaces FAQ'}
                </YellowButton>
            </div>
        </Section>
    )
}
