import cx from 'clsx'
import type { WhatsNextSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { AWARD_CATEGORY } from 'containers/Main/AwardAnnouncement/constants'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import withClassName from 'style/util/withClassName'
import { WinnerAwards } from '../../WinnerAwards'
import { Timeline } from './Timeline'

const HorizontalLine = withClassName(
    'border-grey300 border-2 border-solid border-r-0 border-l-0 border-t-0 pb-20 -mx-4',
    'hr',
    'horizontalLine'
)

const getRegionName = (awardCategoryId: number) => {
    switch (awardCategoryId) {
        case 1:
            return 'Culture Excellence'
        case 2:
            return 'Industry'

        // no default
    }
}

export const WhatsNext = ({
    publisherId,
    awardsSvg,
    isBlackListedFromTwpMerch,
    companyName,
    publisherName,
    year,
    listName,
    mediaKitExternalLinkParameters,
    timeline,
    colors,
    baseUrl = '',
    isPdf,
    awardCategoryId,
}: WhatsNextSection) => {
    const updatedRegionName =
        awardCategoryId === AWARD_CATEGORY.CULTURE_EXCELLENCE || awardCategoryId === AWARD_CATEGORY.INDUSTRY
            ? getRegionName(awardCategoryId)
            : publisherName

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor} isPdf={isPdf}>
            <h2
                className={cx('font-black text-3xl', {
                    'pt-6': isPdf,
                    'sm:text-5xl pl-4 pt-10 sm:pl-0 sm:pt-0': !isPdf,
                })}>
                {"What's Next?"}
            </h2>
            <h2 className={cx('text-3xl font-black', { 'md:text-5xl pl-4 sm:pl-0': !isPdf })}>
                {'The countdown to the big celebration.'}
            </h2>
            <div className={cx('flex flex-col justify-center', { 'mt-2': isPdf })}>
                <div className="flex relative justify-center">
                    <WinnerAwards
                        awardsSvg={awardsSvg}
                        isPdf={isPdf}
                        publisherId={publisherId}
                        awardCategoryId={awardCategoryId}
                        year={year}
                    />
                </div>
                <h2
                    className={cx('text-xl font-black text-center', {
                        'mt-2 -mb-8': isPdf,
                        'mt-3 sm:text-5xl -mb-8': !isPdf,
                    })}>
                    {updatedRegionName}
                </h2>
                <Timeline
                    publisherId={publisherId}
                    companyName={companyName}
                    year={year}
                    listName={listName}
                    timeline={timeline}
                    mediaKitExternalLinkParameters={mediaKitExternalLinkParameters}
                    baseUrl={baseUrl}
                    isPdf={isPdf}
                />
                {(isBlackListedFromTwpMerch || isPdf) && (
                    <HorizontalLine className={isPdf ? '-mx-48 -mt-12' : 'sm:-mx-48'} />
                )}
            </div>
        </Section>
    )
}
