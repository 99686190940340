import { alert } from '@energage/components'
import { NoteAdd } from '@energage/icons'
import loadable from '@loadable/component'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import SimpleTooltip from 'components/SimpleTooltip'
import { routes } from 'constants/routes'
import { useVisibility } from 'hooks'

const CreateNewTemplateComponent = loadable(() => import(`./CreateNewTemplate.jsx`))
const CreateNewTemplate = (props) => <CreateNewTemplateComponent {...props} />

export default function CreateNewTemplateButton({ surveyEventId }) {
    const { visible, show, hide } = useVisibility()
    const { surveyCompanyId } = useIdentity()
    const history = useHistory()
    const queryClient = useQueryClient()

    const { mutateAsync } = useWorkplaceSurveyQuery.mutate(
        `surveycompanies/${surveyCompanyId}/surveytemplate/${surveyEventId}/create`,
        {
            onSuccess: () => queryClient.invalidateQueries('survey-templates', { refetchInactive: true }),
        }
    )

    const save = async (data, { exit }) => {
        try {
            await mutateAsync(data)

            if (exit) {
                history.push(routes.survey())
            } else {
                hide()
            }
        } catch (e) {
            const message = e.parsed?.[0]?.message
            alert.danger(`There was an error saving the template${!!message && `: ${message}`}`)
            throw e
        }
    }

    return (
        <>
            <SimpleTooltip
                trigger={
                    <div
                        className="rounded-full h-10 w-10 bg-grey300 flex justify-center items-center cursor-pointer"
                        onClick={show}>
                        <NoteAdd className="text-grey600" />
                    </div>
                }>
                {'Create New Template'}
            </SimpleTooltip>
            <CreateNewTemplate visible={visible} hide={hide} onSave={save} />
        </>
    )
}
