import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import { ReactComponent as EmptyNotification } from 'images/celebrate3.svg'
import events from './events'

export const Notification = ({ objectType, ...object }) => {
    const event = events[objectType]
    return event ? event.render(object) : null
}

Notification.propTypes = {
    objectType: PropTypes.string.isRequired,
}

export const List = styled.ul.attrs({ className: 'list-none p-0' })`
    li:nth-child(even) {
        background-color: ${colors.grey100};
    }
`

const NotificationList = ({ notifications, hideNotificationWindow }) => {
    const identity = useIdentity()

    if (!size(notifications)) {
        return (
            <div className="m-6 flex flex-col items-center">
                <EmptyNotification className="m-4 w-24 lg:w-32 xl:w-40" />
                <p className="h6 text-center">{'All done for now!'}</p>
                <p className="paragraph p-2 text-center">{'Click View Archives to see past notifications.'}</p>
            </div>
        )
    }

    const sortedNotifications = orderBy(notifications, 'creationDate', 'desc')

    return (
        <List>
            {map(sortedNotifications, (notification) => (
                <Notification
                    key={notification.id}
                    {...notification}
                    identity={identity}
                    onClick={hideNotificationWindow}
                />
            ))}
        </List>
    )
}

NotificationList.propTypes = {
    notifications: PropTypes.array.isRequired,
    hideNotificationWindow: PropTypes.func.isRequired,
}

export default NotificationList
