import { LegacyButton as Button, Loading as Spinner } from '@energage/components'
import { Icon } from '@energage/icons'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import { Modal } from 'components/Modals'
import {
    EnergageCeoStatement,
    TWP_USA_PUBLISHER_ID,
    YEAR_2024,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { ReactComponent as DownloadIcon } from 'images/employerBranding/mediaKit/fileDownloadArrow.svg'
import withClassName from 'style/util/withClassName'
import { UsaTodayExternalLetterModal } from './UsaTodayExternalLetterModal'
import { useDownloadExternalLetter } from './useDownloadExternalLetter'

const ModalBackground = styled.div.attrs({ className: 'overflow-y-auto w-full' })`
    border-radius: 5.07px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    max-height: 65vh;
    scrollbar-width: 16px;
`
export const Paragraph = withClassName('mt-5', 'p')

const ParagraphContent = ({ awardDetails }) => {
    if (awardDetails?.publisherId === TWP_USA_PUBLISHER_ID) {
        return (
            <>
                <Paragraph>
                    {
                        '[Location] [Date] - Today [company] has announced it has earned the [Year] Top Workplaces USA award, issued by Energage, a purpose-driven organization that develops solutions to build and brand Top Workplaces. The Top Workplaces program has a 16-year history of surveying more than 20 million employees and recognizing the top organizations across 60 markets for regional Top Workplaces awards.'
                    }
                </Paragraph>
                <Paragraph>
                    {
                        'Top Workplaces USA celebrates organizations with 150 or more employees that have built great cultures. Over 42,000 organizations were invited to participate in the Top Workplaces survey. Winners of the Top Workplaces USA list are chosen based solely on employee feedback gathered through an employee engagement survey, issued by Energage.'
                    }
                </Paragraph>
                <Paragraph>
                    {
                        'Results are calculated by comparing the survey’s research-based statements, including 15 Culture Drivers that are proven to predict high performance against industry benchmarks.'
                    }
                </Paragraph>
            </>
        )
    }
    return (
        <Paragraph>
            {
                '{City}, {State} {Date} - {Organization Name} has been awarded a Top Workplaces {Year} honor by The {Publisher Name} Top Workplaces. This list is based solely on employee feedback gathered through a third-party survey administered by employee engagement technology partner Energage, LLC. The confidential survey uniquely measures the employee experience and its component themes, including employees feeling Respected & Supported, Enabled to Grow, and Empowered to Execute, to name a few.'
            }
        </Paragraph>
    )
}

const MediaKitExternalLetterModal = ({ isOpen, onClose, awardDetails }) => {
    const { download, isDownloading } = useDownloadExternalLetter(awardDetails)

    return (
        <Modal
            className="w-4/5 sm:overflow-y-hidden h-full"
            title="Press Release Template"
            isOpen={isOpen}
            onClose={onClose}>
            <div className="p-4">
                <label className="w-full">{'Press Release Preview'}</label>
                <p className="py-2">
                    <b>
                        {
                            '* Please customize the template for your use. You may copy and paste the text or download the template as a '
                        }
                        <ExternalLink onClick={download} className="font-bold text-blue500">
                            {'Word Document'}
                        </ExternalLink>
                    </b>
                </p>
                <ModalBackground>
                    {awardDetails?.publisherId === TWP_USA_PUBLISHER_ID && awardDetails?.year >= YEAR_2024 ? (
                        <UsaTodayExternalLetterModal />
                    ) : (
                        <div className="px-5 py-5 text-grey500">
                            <p>
                                {
                                    'THE {PUBLISHER NAME} NAMES {ORGANIZATION NAME} A WINNER OF THE {PROGRAM NAME} TOP WORKPLACES {YEAR} AWARD'
                                }
                            </p>
                            <ParagraphContent awardDetails={awardDetails} />
                            <Paragraph>{EnergageCeoStatement}</Paragraph>
                            <Paragraph>{'{Add Quote from Organization Leader}'}</Paragraph>
                            <Paragraph>{'ABOUT'}</Paragraph>
                            <Paragraph>{'{Description of Organization}'}</Paragraph>
                            <Paragraph>{'Company Contact'}</Paragraph>
                            <p>{'{Organization Name}'}</p>
                            <p>{'{Contact Email} {Contact Phone}'}</p>
                            <p>{'{Organization Web Site}'}</p>
                            <Paragraph>{'ABOUT ENERGAGE'}</Paragraph>
                            <Paragraph>{'Making the world a better place to work together.™'}</Paragraph>
                            <Paragraph>
                                {
                                    'Energage is a purpose-driven company that helps organizations turn employee feedback into useful business intelligence and credible employer recognition through Top Workplaces. Built on 14 years of culture research and the results from 23 million employees surveyed across more than 70,000 organizations,  Energage delivers the most accurate competitive benchmark available. With access to a unique combination of patented analytic tools and expert guidance, Energage customers lead the competition with an engaged workforce and an opportunity to gain recognition for their people-first approach to culture. For more information or to nominate your organization, visit energage.com or topworkplaces.com.'
                                }
                            </Paragraph>
                        </div>
                    )}
                </ModalBackground>
                <div className="flex float-left p-2 pl-8 xl:pl-8">
                    <Button className="font-medium" disabled={isDownloading} onClick={download}>
                        <Icon as={DownloadIcon} size={14} className="mr-4" />
                        {'Download'}
                        {isDownloading && <Spinner className="ml-5" size="small" text="" inline />}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default MediaKitExternalLetterModal
