import { useState } from 'react'
import type { ChangeEvent } from 'react'
import { Loading as Spinner } from '@energage/components'
import { Text } from '@energage/typography'
import cx from 'clsx'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import { FancyCheckboxWithLabel } from 'components/FancyCheckbox'
import Jiggle from 'components/Jiggle'
import type { OrgStrengthsProps, OrgStrengthsStatementProps } from './OrgStrengths.types'

export const OrgStrengthsStatementSelector = ({
    data,
    onChange,
    isLoading,
    dataId,
    hasEmployerBrandingSubscription,
}: OrgStrengthsProps) => {
    const [error, setError] = useState(false)

    const setStatementSelected = (orgStrength: OrgStrengthsStatementProps) => {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const newStatements = map(data?.orgStrengths, (d) => {
                if (d.text === orgStrength.text) {
                    return {
                        ...d,
                        isSelected: e.target.checked,
                    }
                }
                return d
            })

            const selectedCount = size(filter(newStatements, 'isSelected'))
            if (selectedCount < 1) {
                setError(true)
                setTimeout(() => setError(false), 250)
                return
            }

            onChange({ ...data, orgStrengths: newStatements })
        }
    }

    if (!hasEmployerBrandingSubscription) {
        return <h2 className="text-center p-12">{'Unlock Employer Branding to get access to your data!'}</h2>
    }

    if (!head(data?.orgStrengths)) {
        return ''
    }

    return (
        <>
            <Text textStyle="title6" className="text-sm mt-8">
                <h5>{'Select Statements'}</h5>
            </Text>
            {/* @ts-ignore */}
            <Jiggle active={error}>
                <div className={cx({ 'text-red500': error }, 'mt-1 mb-2 text-sm')}>
                    {`You can select multi company strengths to display in your widget`}
                </div>
            </Jiggle>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className="mt-6">
                    {map(data?.orgStrengths, (item, index) => (
                        <div
                            key={index}
                            className="flex mb-8"
                            data-event-id={dataId && `${dataId}-org-strength-selection`}>
                            {/* @ts-ignore */}
                            <FancyCheckboxWithLabel
                                checked={item.isSelected}
                                className="mr-2 items-baseline"
                                onChange={setStatementSelected(item)}
                                label={
                                    <div>
                                        <h5 className="text-base font-bold">{item.name}</h5>
                                        <p className="text-sm">{item.text}</p>
                                    </div>
                                }
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}
