import { useCallback, useReducer } from 'react'
import useSubmitPaperSurvey from './PaperFormat/useSubmitPaperSurvey'
import useSubmitFormatSurvey from './useSubmitFormatSurvey'
import { useSurveyFormatData } from './useSurveyFormatData'

function useSurveyFormat(surveyEventId) {
    const [state, dispatch] = useReducer(reducer, {
        paperDetails: {},
        selectedFormat: {},
    })

    const { isLoading, refetch, error } = useSurveyFormatData(surveyEventId, {
        onSuccess: (data) => dispatch({ type: 'INIT', payload: data }),
    })

    const { submit: saveFormat, isPosting: savingFormat } = useSubmitFormatSurvey(surveyEventId, refetch)
    const { orderPaperSurveys, orderingPaperSurveys } = useSubmitPaperSurvey(surveyEventId, (succeeded) =>
        dispatch({ type: 'ORDERED_PAPER_SURVEYS', payload: succeeded })
    )

    const setSelectedFormat = useCallback(
        (format) => {
            return saveFormat(format.id)
        },
        [saveFormat]
    )

    const setPaperContact = useCallback((contact) => {
        dispatch({ type: 'SET_PAPER_CONTACT', payload: contact })
    }, [])

    const orderPaperSurvey = useCallback(
        async (paperInfo) => {
            dispatch({ type: 'ORDERING_PAPER_SURVEYS', payload: paperInfo })
            orderPaperSurveys({ formatId: state.selectedFormat.id, ...paperInfo })
        },
        [state, orderPaperSurveys]
    )

    return {
        data: state,
        methods: {
            setSelectedFormat,
            setPaperContact,
            saveFormat,
            orderPaperSurvey,
        },
        fetchingSurveyFormat: isLoading,
        orderingPaperSurveys,
        savingFormat,
        error,
        refetch,
    }
}

export default useSurveyFormat

function reducer(state, { type, payload }) {
    switch (type) {
        case 'INIT':
            const {
                paperContactDetails: contact,
                paperCount,
                spanishPaperCount,
                needsSpanish = !!spanishPaperCount,
                hasOrderedPaperSurveys,
                paperSurveyPrice,
                hasCustomQuestions,
                awardRegionName,
                selectedFormat,
                formats,
            } = payload

            return {
                formats,
                selectedFormat: selectedFormat,
                paperDetails: {
                    contact,
                    paperCount,
                    spanishPaperCount,
                    needsSpanish,
                    hasOrderedPaperSurveys,
                    paperPrice: !hasCustomQuestions ? paperSurveyPrice?.toFixed(2) : null,
                    regionName: awardRegionName,
                },
            }
        case 'SET_SELECTED_FORMAT':
            return {
                ...state,
                selectedFormat: payload,
            }
        case 'SET_PAPER_CONTACT':
            return {
                ...state,
                paperContactDetails: payload,
            }
        case 'ORDERED_PAPER_SURVEYS':
            if (!payload) {
                const newState = {
                    ...state,
                    paperDetails: state.oldPaperDetails,
                    hasOrderedPaperSurveys: false,
                }
                delete newState.oldPaperDetails
                return newState
            }

            return {
                ...state,
                paperDetails: {
                    ...state.paperDetails,
                    hasOrderedPaperSurveys: true,
                },
            }
        case 'ORDERING_PAPER_SURVEYS':
            return {
                ...state,
                paperDetails: {
                    ...state.paperDetails,
                    ...payload,
                },
                oldPaperDetails: state.paperDetails,
            }
        // no default
    }
}
