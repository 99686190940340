import UnderstandYourOrgImg from 'images/awardAnnouncement/understandYourOrgRevamp.svg'
import { BgContainer } from '../BgContainer'

export const UnderstandYourOrg = () => (
    <BgContainer className="p-4 bg-white">
        <div className="flex flex-col align-center justify-center md:flex-row items-center">
            <div className="md:p-12 p-4 md:w-1/2">
                <div>
                    <h4 className="text-xl">{'Use our data to drive change within your organization'}</h4>
                    <div>
                        <p className="text-base md:w-4/5 mt-2">
                            {
                                "Unlock the analytics behind every facet of your employees' experience. Celebrate what truly sets you apart and pinpoint areas in need of improvement using our advanced Insights platform."
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="p-4 md:ml-4 mt-4 md:w-1/2 ">
                <div className="md:float-right pr-10">
                    <img alt={'Understand Your Organization'} src={UnderstandYourOrgImg} />
                </div>
            </div>
        </div>
    </BgContainer>
)
