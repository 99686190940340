import { LegacyButton as Button } from '@energage/components'
import cx from 'clsx'
import styled from 'styled-components'
import { TaskLink } from 'components/TaskWorkflow'
import { dismissNotification } from 'store'
import { shortMonthDayDate } from 'util/formatters'
import useDismissNotification from './useDismissNotification'

const IconContainer = styled.div`
    width: 30px;
`

const NotificationDate = styled.span.attrs({ className: 'text-grey500 font-light' })`
    font-size: 10px;
`

const DismissButton = styled(Button).attrs({ className: 'p-0 text-grey500 font-light' })`
    font-size: 10px !important;
`

const Notification = ({
    id,
    userId,
    title,
    text,
    link,
    icon,
    readDate,
    creationDate,
    isDismissible = true,
    onClick,
}) => {
    const { dismiss, isDismissing } = useDismissNotification(() => dismissNotification(id))

    const handleDismissed = () => {
        dismiss({
            notificationId: id,
            userId,
        })
    }

    return (
        <li
            className={cx('border-b p-2 flex flex-row', {
                'opacity-50': isDismissing,
            })}>
            {icon && <IconContainer>{icon}</IconContainer>}
            <div className="w-full text-xs p-2">
                <div className="pb-1 flex justify-between items-center">
                    <NotificationDate>{shortMonthDayDate(new Date(creationDate))}</NotificationDate>
                    {isDismissible && !readDate && (
                        <DismissButton variant="link" onClick={handleDismissed} disabled={isDismissing}>
                            {'Dismiss'}
                        </DismissButton>
                    )}
                </div>
                <div className="flex justify-between items-center" onClick={onClick}>
                    {typeof text === 'function' ? (
                        <div>{text()}</div>
                    ) : (
                        <p className="p-0">
                            <b>{title}</b>
                            {link ? (
                                <TaskLink to={link} variant="link" className="flex-1 p-0 text-primary-dark" size="sm">
                                    {` - ${text} »`}
                                </TaskLink>
                            ) : (
                                text
                            )}
                        </p>
                    )}
                </div>
            </div>
        </li>
    )
}

export default Notification
