import styled from 'styled-components'
import { media } from 'style/breakpoints'

interface StyledProps {
    removeBorder?: boolean
    isLast?: boolean
    lessThanSix?: boolean
    isPdf?: boolean
}

export const PointBorderPdf = styled.div.attrs({})<StyledProps>`
    border-top: 11px solid #a4b0c4;
    width: 10rem;
    margin: 15px -3px 0px 3px;
    ${(props: { removeBorder?: boolean; lessThanSix?: boolean; isPdf?: boolean }) =>
        `width: ${props.lessThanSix ? (props.isPdf ? 15 : 16.5) : 15.2}rem;`}
`

export const PointBorder = styled.div.attrs({})<StyledProps>`
    width: 10rem;
    margin: 0 0 -37px 16px;
    ${media.smMin`
        border-top: 11px solid #a4b0c4;
        border-left: 0;
        margin: 15px -3px 0px 3px;
    `}
    ${(props) => `${
        props.isLast
            ? `border-left: 0;
                ${media.smMin`
                    padding-left: 0px;
                `}
                padding-left: 11px;
                padding-bottom: 0px;`
            : `border-left: 11px solid #a4b0c4;
                border-top: 0;
                padding-bottom: 64px;`
    }
        width: ${props.lessThanSix ? 16.5 : 15.2}rem;
    `}
    ${media.smMin`
        padding-bottom: 0px;
    `}
`

export const LeftBorderPdf = styled.div.attrs({})`
    border-left: 2px solid #fdbf3f;
    padding-left: 16px;
    margin-left: 20px;
    margin-top: 20px;
`

export const LeftBorder = styled.div.attrs({})`
    border-left: 0;
    margin-left: 31px;
    ${media.smMin`
        border-left: 2px solid #fdbf3f;
        margin-left: 20px;
    `}
    padding-left: 16px;
    margin-top: 36px;
`

export const TimelineHeading = styled.h5`
    &::before {
        content: '';
        position: absolute;
        left: -26px;
        top: 19px;
        transform: translateY(-50%);
        width: 22px;
        height: 4px;
        background-color: #fdbf3f;
    }
    ${media.smMin`
        &::before {
            position: initial;
        }
    `}
`

export const StyledTimeLineContent = styled.div.attrs({})`
    ${(props: StyledProps) => `width: ${props.lessThanSix ? (props.isPdf ? 13 : 15) : 13.7}rem;`}
`

export const Confetti = styled.img.attrs({})<StyledProps>`
    left: -43px;
    top: -62px;
    width: 16rem;
    ${media.smMin`
        left: -36px;
        top: -67px;
        ${(props: StyledProps) => `width: ${props.lessThanSix ? 17 : 14}rem;`}
    `}
    position: absolute;
    max-width: fit-content;
`
