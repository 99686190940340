import { LegacyButton as Button } from '@energage/components'
import { colors as themeColors } from '@energage/theme'
import styled from 'styled-components'
import type { HeaderData } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import colors from 'containers/Main/AwardAnnouncement/colors'
import twpLogoNoYear from 'images/awardAnnouncement/twpLogoNoyear.svg'
import { useDownloadAwardAnnouncement } from './useDownloadAwardAnnouncement'

const DownloadButton = styled(Button).attrs({
    className: 'bg-white text-center mt-4 sm:mt-px mb-2 sm:mb-0 px-2 mr-2 sm:mr-3',
})`
    color: ${colors.textBlue};
    border-color: ${colors.textBlue};
    line-height: 7px;
    & :hover {
        color: ${themeColors.yellow500};
        background-color: ${colors.textBlue};
    }
`
export const Header = ({ awardListParticipantId }: HeaderData) => {
    const { downloadAnnouncementPage, isDownloading } = useDownloadAwardAnnouncement(awardListParticipantId)

    return (
        <div className="p-3 h-20 fixed left-0 right-0 top-0 flex flex-row justify-between bg-white shadow-md z-50 md:p-6">
            <img src={twpLogoNoYear} alt="twp" className="h-24 w-16 z-10" />
            <DownloadButton outline disabled={isDownloading} onClick={downloadAnnouncementPage}>
                <span className="md:hidden">{`Download`}</span>
                <span className="hidden md:inline-block">{`Download Announcement`}</span>
            </DownloadButton>
        </div>
    )
}
