import { Input, Textarea } from '@energage/components'
import { size } from '@energage/theme'
import cx from 'clsx'
import get from 'lodash/get'
import styled from 'styled-components'

const BaseTextAreaInput = ({ errors, errorKey, ...rest }) => {
    const error = get(errors, errorKey, null)

    return <Textarea {...rest} rows={3} maxLength={350} error={!!error} info={error} />
}

export const QuestionInput = ({ errors, errorKey, ...rest }) => {
    const error = get(errors, errorKey, null)

    return <Input {...rest} className="max-w-md mt-2 mb-0" rows={3} maxLength={350} error={!!error} info={error} />
}

export const TextAreaInput = (props) => <BaseTextAreaInput className="w-full max-w-md mt-2 mb-0" {...props} />

export const TextAreaInputSmall = (props) => <BaseTextAreaInput className="w-full mb-3 mt-2" {...props} />

export const TextAreaInputPreview = (props) => (
    <Textarea {...props} className="w-full sm:w-3/4 max-w-md focus:outline-none mt-2 mb-3 mt-2" readOnly rows={3} />
)

export const ReadonlyInput = (props) => <Input {...props} readOnly />

export const RadioButtonPreview = styled(ReadonlyInput).attrs({ type: 'radio' })`
    input {
        height: ${size(4)};
        width: ${size(4)};
    }
`

export const TextInputPreview = ({ className = '', ...rest }) => (
    <Textarea {...rest} className={cx(className, 'w-full ml-0')} rows={3} type="text" readOnly />
)

export const TextInputSmallPreview = ({ className = '', ...rest }) => (
    <ReadonlyInput {...rest} className={cx(className, 'w-full pl-0 py-2')} type="text" />
)
