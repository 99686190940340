import { useEffect, useState } from 'react'
import showErrorAlert from 'util/showErrorAlert'
import { useSurveySetupContext } from '../SurveySetup'

function useSmsInvitationSetup({ organizationId, surveyEventId, refreshWorkflow }) {
    const { surveyEventSetup, saveSurveyEventSetup, setSurveyEventSetup, updatingSurveyEventSetup } =
        useSurveySetupContext()

    const [isSmsTextSubmitted, setIsSmsTextSubmitted] = useState(false)
    const [isSubmittingSmsText, setIsSubmittingSmsText] = useState(false)
    const [isSmsInvitationOptionSubmitted, setIsSmsInvitationOptionSubmitted] = useState(false)

    useEffect(() => {
        setIsSmsTextSubmitted(surveyEventSetup.smsText.length > 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeSmsText = (value) => {
        setSurveyEventSetup((surveyEventSetup) => {
            return { ...surveyEventSetup, smsText: value }
        })
        setIsSmsTextSubmitted(false)
    }

    const onChangeSkipEmailForSmsUsers = () => {
        doSubmit({ skipEmailForSmsUsers: !surveyEventSetup.skipEmailForSmsUsers })
    }

    const doSubmit = ({ isSmsInvitationEnabled = null, skipEmailForSmsUsers = null }) => {
        saveSurveyEventSetup(
            {
                id: surveyEventSetup.id,
                organizationId,
                surveyEventId,
                smsText: surveyEventSetup.smsText,
                isSmsInvitationEnabled: isSmsInvitationEnabled ?? surveyEventSetup.isSmsInvitationEnabled,
                skipEmailForSmsUsers: skipEmailForSmsUsers ?? surveyEventSetup.skipEmailForSmsUsers,
                modifiedDateTime: surveyEventSetup.modifiedDateTime,
            },
            (data) => {
                setIsSmsTextSubmitted(data?.smsText.length > 0)
                refreshWorkflow()
            },
            (error) => {
                showErrorAlert('There was an error saving sms configuration', error)
            }
        )
    }

    const onSubmitSmsText = () => {
        setIsSubmittingSmsText(true)
        doSubmit({})
    }

    useEffect(() => {
        if (updatingSurveyEventSetup === false && isSubmittingSmsText === true) {
            setIsSubmittingSmsText(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingSurveyEventSetup])

    const onConfirmSmsInvitationOption = (isSmsInvitationEnabled) => {
        doSubmit({ isSmsInvitationEnabled })
        setIsSmsInvitationOptionSubmitted(true)
    }

    return {
        isSmsInvitationEnabled: surveyEventSetup.isSmsInvitationEnabled,
        isSmsInvitationOptionSubmitted,
        skipEmailForSmsUsers: surveyEventSetup.skipEmailForSmsUsers,
        onChangeSkipEmailForSmsUsers,
        smsText: surveyEventSetup.smsText,
        isSmsTextSubmitted,
        onChangeSmsText,
        onSubmitSmsText,
        onConfirmSmsInvitationOption,
        isSubmittingSmsText,
    }
}

export default useSmsInvitationSetup
