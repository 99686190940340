import { useCallback, useEffect, useState } from 'react'
import config from 'config'
import { useGetConnectionStatus, usePostConnection } from './usePlatform'
import { getAuthCodeAndWidgetFromURL, redirectToAuthPage, validateState } from './utils/platformAuth'

export const notify = (type: 'success' | 'warning' | 'danger', message: string) => {
    // eslint-disable-next-line no-console
    console.log(`${type.toUpperCase()}: ${message}`)
}

type Widget = 'cultureCloud' | 'linkedInBanner' | 'cultureStats' | 'strengths' | 'comments'

export const useShareContent = (widgetVariant: Widget) => {
    const [sharingSheet, setSharingSheet] = useState<boolean>(false)
    const {
        authCode: linkedInAuthCode,
        widgetVariant: urlWidgetVariant,
        state: linkedInState,
        error: linkedInError,
        errorDescription: linkedInErrorDescription,
    } = getAuthCodeAndWidgetFromURL()
    const isAuthenticatedByLinkedIn = !!linkedInAuthCode

    const connectionQuery = useGetConnectionStatus('LinkedIn')
    const { data: connectionData, refetch: refetchConnectionStatus } = connectionQuery
    const { platform, connected, widget, staleStatusTimestamp } = connectionData ?? {}
    const linkedInConnection = platform === 'linkedin'
    const isConnectedToLinkedIn = linkedInConnection && !!connected

    const mutationQuery = usePostConnection('LinkedIn')
    const { mutate: connectLinkedInAccount } = mutationQuery
    const isLoading = mutationQuery.isLoading || connectionQuery.isLoading

    useEffect(() => {
        if (linkedInError) {
            // eslint-disable-next-line no-console
            console.error(`LinkedIn Error: ${linkedInErrorDescription}`)
            switch (linkedInError) {
                case 'user_cancelled_login':
                    notify('warning', 'Login to LinkedIn was cancelled.')
                    break
                case 'user_cancelled_authorize':
                    notify('warning', 'Request for access to LinkedIn was cancelled.')
                    break
                default:
                    notify('danger', 'Attempt to connect to LinkedIn was unsuccessful.')
                    break
            }
            return
        }

        if (linkedInAuthCode) {
            if (validateState(linkedInState)) {
                const scopes = [config.branding.sharing.linkedIn].map((item) => item.clientId)
                connectLinkedInAccount({ authCode: linkedInAuthCode, scopes })
            } else {
                // eslint-disable-next-line no-console
                console.error('CSRF token mismatch. Connection not made successfully.')
                notify('danger', 'Attempt to connect to LinkedIn was unsuccessful.')
            }
        }
    }, [connectLinkedInAccount, linkedInAuthCode, linkedInState, linkedInError, linkedInErrorDescription])

    useEffect(() => {
        if (mutationQuery.isSuccess) {
            refetchConnectionStatus()
        }
    }, [mutationQuery.isSuccess, refetchConnectionStatus])

    useEffect(() => {
        const openSheetOnLinkedInAuth = () => {
            if (!isAuthenticatedByLinkedIn) {
                return
            }
            if (isConnectedToLinkedIn && widgetVariant === urlWidgetVariant) {
                setSharingSheet(true)
            }
        }

        openSheetOnLinkedInAuth()
    }, [widget, widgetVariant, urlWidgetVariant, isConnectedToLinkedIn, isAuthenticatedByLinkedIn])

    const onShareRequest = useCallback(async () => {
        const now = new Date()
        const isStale = staleStatusTimestamp && new Date(staleStatusTimestamp) <= now
        if (isStale) {
            await refetchConnectionStatus()
        }
        if (!isConnectedToLinkedIn) {
            return redirectToAuthPage('linkedIn', widgetVariant)
        }
        setSharingSheet(true)
    }, [refetchConnectionStatus, staleStatusTimestamp, isConnectedToLinkedIn, widgetVariant])

    return [sharingSheet, setSharingSheet, onShareRequest, isLoading]
}
