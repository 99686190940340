import { useEffect, useState } from 'react'
import { butterBar } from '@energage/components'

export function useButterBarHeight() {
    const [height, setHeight] = useState(() => butterBar.getHeightAfterTransition())
    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight)
        const unsub = butterBar.subscribe(updateHeight)

        return () => {
            window.removeEventListener('resize', updateHeight)
            unsub()
        }

        function updateHeight() {
            setHeight(butterBar.getHeightAfterTransition())
        }
    }, [])

    return height
}
