import { useCallback, useEffect, useState } from 'react'
import type { PropsWithChildren } from 'react'
import type { EmblaCarouselType } from 'embla-carousel'

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean
    nextBtnDisabled: boolean
    onPrevButtonClick: () => void
    onNextButtonClick: () => void
}

export const usePrevNextButtons = (
    emblaApi: EmblaCarouselType | undefined,
    totalSlides: number
): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        const index = emblaApi.selectedScrollSnap()
        setPrevBtnDisabled(index === 0)
        setNextBtnDisabled(index === totalSlides - 1)
    }, [emblaApi, totalSlides])

    useEffect(() => {
        if (!emblaApi) return
        onSelect()
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onSelect, totalSlides])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    }
}

type PropType = PropsWithChildren<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & {
    disabled: boolean
    direction: 'prev' | 'next'
}

const BaseButton: React.FC<PropType> = (props) => {
    const { tabIndex, disabled, direction, ...restProps } = props
    const isPrev = direction === 'prev'

    return (
        <button
            className={`absolute top-1/2 transform -translate-y-1/2 w-15 h-15 z-10 rounded-full text-white flex items-center justify-center ${
                disabled ? 'hidden' : ''
            } w-6 h-6 sm:w-10 sm:h-10 md:w-16 md:h-16`}
            style={{
                top: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
            type="button"
            {...restProps}
            tabIndex={tabIndex}
            aria-disabled={disabled}
            aria-label={isPrev ? 'Previous Slide' : 'Next Slide'}>
            <svg className="w-1/3 h-1/3" viewBox="0 0 532 532" aria-hidden="true">
                <path
                    fill="currentColor"
                    d={
                        isPrev
                            ? 'M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z'
                            : 'M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z'
                    }
                />
            </svg>
        </button>
    )
}

export const PrevButton: React.FC<PropType> = (props) => <BaseButton {...props} direction="prev" />

export const NextButton: React.FC<PropType> = (props) => <BaseButton {...props} direction="next" />
