export const sectionsList = {
    listDiy: [
        {
            imgPath: 'pro2.svg',
            heading: 'Social media images',
            text: 'Capture the attention of followers and attract new ones.',
        },
        {
            imgPath: 'diy4.svg',
            heading: 'Formatted press release template',
            text: 'Gain immediate media exposure and expand your reach.',
        },
        {
            imgPath: 'diy3.svg',
            heading: 'Internal announcement template',
            text: 'Boost employee morale by sharing the exciting news.',
        },
    ],
    listPro: [
        {
            imgPath: 'pro8.svg',
            heading: 'Premium Media Kits',
            text: 'Access kits for current and any future premium Top Workplaces awards.',
        },
        {
            imgPath: 'pro6.svg',
            heading: 'Premium Company Profile on topworkplaces.com',
            text: 'Create a custom profile that’s featured on the site seen by millions each year.',
        },
        {
            imgPath: 'pro7.svg',
            heading: 'Culture Facts',
            text: 'Leverage employee feedback to show what makes your culture unique.',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Culture Badges',
            text: 'Show off where your company culture excels.',
        },
    ],
}
