import { LegacyButton as Button, SpeechBubble } from '@energage/components'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ScrollSnapSpeechBubble = styled(SpeechBubble)`
    scroll-snap-align: start;

    p {
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const EndCard = styled.div`
    scroll-snap-align: start;

    .more-help--container {
        min-height: 246px;
        width: 288px;
    }
`

type EndCardProps = {
    text: string
    linkText: string
    link: string
    openNewTab: boolean
}

export const EndCardContent = ({ text, linkText, link, openNewTab = false }: EndCardProps) => (
    <EndCard>
        <div className="grid content-center more-help--container">
            <p className="h3 pb-6">{text}</p>
            {openNewTab ? (
                <a
                    href={link}
                    className="btn-link text-sm font-bold"
                    target="_blank"
                    rel="noreferrer noopener"
                    tabIndex={0}>
                    <span className="sr-only">{'opens in a new window'}</span>
                    {linkText}
                </a>
            ) : (
                <Button as={Link} variant="link" to={link}>
                    {linkText}
                </Button>
            )}
        </div>
    </EndCard>
)
