import styled from 'styled-components'

const baseClasses = 'inline-block text-primary-dark bg-white rounded-lg h-full py-5 px-3'

export const CardContainer = styled.div.attrs<{ className?: string }>({ className: baseClasses })`
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
    max-width: 352px;
    width: 100%;
    min-height: 112px;
`
