import { createAction } from 'util/actionCreator'
import type {
    ChangeTimezonePayload,
    CreateInvitationDatePayload,
    DeleteInvitationDatePayload,
    InvitationScheduleResponse,
    ScheduleSubmitSuccess,
    SurveyInvitationsResponse,
    TimeZonesResponse,
    UpdateCloseDatePayload,
    UpdateInvitationPayload,
    UpdateLaunchDateTimePayload,
    UpdateStatusPayload,
} from './scheduleReducer.types'

export const Action = {
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
    FETCH_TIME_ZONES_SUCCESS: 'FETCH_TIME_ZONES_SUCCESS',
    RESET_INVITATION_DATES: 'RESET_INVITATION_DATES',
    SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
    ADD_INVITATION_DATE: 'ADD_INVITATION_DATE',
    DELETE_INVITATION_DATE: 'DELETE_INVITATION_DATE',
    UPDATE_INVITATION_DATE: 'UPDATE_INVITATION_DATE',
    UPDATE_LAUNCH_DATE: 'UPDATE_LAUNCH_DATE',
    UPDATE_CLOSE_DATE: 'UPDATE_CLOSE_DATE',
    CHANGE_TIME_ZONE: 'CHANGE_TIME_ZONE',
    UPDATE_STATUS: 'UPDATE_STATUS',
} as const

export const fetchDataSuccess = createAction<SurveyInvitationsResponse, typeof Action.FETCH_DATA_SUCCESS>(
    Action.FETCH_DATA_SUCCESS
)
export const fetchTimeZonesSuccess = createAction<TimeZonesResponse, typeof Action.FETCH_TIME_ZONES_SUCCESS>(
    Action.FETCH_TIME_ZONES_SUCCESS
)
export const resetInvitationDates = createAction<InvitationScheduleResponse, typeof Action.RESET_INVITATION_DATES>(
    Action.RESET_INVITATION_DATES
)
export const submitSuccess = createAction<ScheduleSubmitSuccess, typeof Action.SUBMIT_SUCCESS>(Action.SUBMIT_SUCCESS)
export const setInvitationDate = createAction<UpdateInvitationPayload, typeof Action.UPDATE_INVITATION_DATE>(
    Action.UPDATE_INVITATION_DATE
)
export const createInvitationDate = createAction<CreateInvitationDatePayload, typeof Action.ADD_INVITATION_DATE>(
    Action.ADD_INVITATION_DATE
)
export const deleteInvitationDate = createAction<DeleteInvitationDatePayload, typeof Action.DELETE_INVITATION_DATE>(
    Action.DELETE_INVITATION_DATE
)
export const changeTimeZone = createAction<ChangeTimezonePayload, typeof Action.CHANGE_TIME_ZONE>(
    Action.CHANGE_TIME_ZONE
)
export const updateStatus = createAction<UpdateStatusPayload, typeof Action.UPDATE_STATUS>(Action.UPDATE_STATUS)
export const updateCloseDate = createAction<UpdateCloseDatePayload, typeof Action.UPDATE_CLOSE_DATE>(
    Action.UPDATE_CLOSE_DATE
)
export const updateLaunchDateTime = createAction<UpdateLaunchDateTimePayload, typeof Action.UPDATE_LAUNCH_DATE>(
    Action.UPDATE_LAUNCH_DATE
)

export type ScheduleReducerActions =
    | ReturnType<typeof fetchDataSuccess>
    | ReturnType<typeof fetchTimeZonesSuccess>
    | ReturnType<typeof resetInvitationDates>
    | ReturnType<typeof submitSuccess>
    | ReturnType<typeof setInvitationDate>
    | ReturnType<typeof createInvitationDate>
    | ReturnType<typeof deleteInvitationDate>
    | ReturnType<typeof changeTimeZone>
    | ReturnType<typeof updateStatus>
    | ReturnType<typeof updateCloseDate>
    | ReturnType<typeof updateLaunchDateTime>
