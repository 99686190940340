import { useManagementQuery } from 'api'
import { useIdentity } from 'components/Identity'

export type SalesBlockerResponse = {
    status: string
    token: string
    info: string
}

export type CompanyNominationModel = {
    companyId: number
    isCompanyBlocked: boolean
    isCompanyNominated: boolean
    blockerVersion: number
    salesforceUserId: string
    salesforceOwnerId: string
    nationalEmployeeCount: number
    salesBlockerResponse: SalesBlockerResponse
}

export const useCompanyStatusDetails = () => {
    const { organizationId } = useIdentity()
    const {
        data: companyNomination,
        isLoading,
        isError,
        error,
    } = useManagementQuery<CompanyNominationModel>(['company-nomination', organizationId], 'identity/CompanyNomination')

    return {
        ...companyNomination,
        isLoading,
        isError,
        error,
    }
}
