import { Loading as Spinner } from '@energage/components'
import loadable from '@loadable/component'
import find from 'lodash/find'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import FullPageErrorBoundary from 'components/FullPageErrorBoundary'
import { useIdentity } from 'components/Identity'
import MainContent from 'components/MainContent'
import { NavigationListener } from 'components/Navigation'
import Sidebar from 'components/Sidebar'
import { useFetchFeaturePreviews } from 'components/Sidebar/SettingsDrawer/useFetchSettings'
import config from 'config'
import { azureFeatureFlags } from 'constants/organizationFeatures'
import { Permission } from 'constants/permission'
import { routes } from 'constants/routes'
import { NEW_SALES_BLOCKER } from 'containers/Main/EmployerRecognition/constants'
import { AwardRegistration } from 'containers/Main/EmployerRecognition/ManageProgram/AwardRegistration'
import { useCompanyStatusDetails } from 'containers/Main/EmployerRecognition/Shared/useCompanyStatusDetails'
import { UserManagementRoutes } from 'containers/Main/Manage/Users'
import { useOrganizationFeatures } from 'hooks'
import withClassName from 'style/util/withClassName'
import showErrorAlert from 'util/showErrorAlert'
import AccessDenied from './AccessDenied'
import Admin from './Admin'
import { AwardAnnouncement } from './AwardAnnouncement'
import Dashboard from './Dashboard'
import { Documents } from './Documents'
import MediaKitPressReleaseFormPreview from './EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview'
import { AwardHistory } from './EmployerRecognition/AwardHistory'
import { Security } from './Settings/Security'

const Root = withClassName('h-full', 'div')
const AsyncComponent = loadable(({ name }) => import(`./${name}/index`))
const Insights = () => <AsyncComponent name="Insights" />
const Manage = () => <AsyncComponent name="Manage" />
const Survey = () => <AsyncComponent name="Survey" />
const Terms = () => <AsyncComponent name="Terms" />
const Integrations = () => <AsyncComponent name="Integrations" />
const EmployerRecognition = () => <AsyncComponent name="EmployerRecognition" />
const EmployerRecognitionSetup = () => <AsyncComponent name="EmployerRecognition/Setup" />
const Participation = () => <AsyncComponent name="EmployerRecognition/Participation" />
const OrderManagement = () => <AsyncComponent name="OrderManagement" />
const EmployerBranding = () => <AsyncComponent name="EmployerBranding" />
const Profile = () => <AsyncComponent name="Profile" />

const Main = () => {
    const identity = useIdentity()
    const { data: previewFeatures, isLoading: isLoadingPreviewFeatures } = useFetchFeaturePreviews()
    if (isLoadingPreviewFeatures) {
        return <Spinner />
    }

    const isParticipationReviewExperienceFeatureActive = find(
        previewFeatures.features,
        (f) => f.name === azureFeatureFlags.ParticipationReviewExperience
    )?.isActive

    return (
        <>
            <MainContent>
                <FullPageErrorBoundary>
                    <Switch>
                        <Route path={routes()} exact render={() => <Redirect to={routes.dashboard()} />} />
                        <Route path={routes.dashboard()} component={Dashboard} />
                        <AuthenticatedRoute path={routes.awardNotification()} component={AwardHistory} />
                        <AuthenticatedRoute
                            path={routes.awardAnnouncement()}
                            component={
                                identity.permissions.hasNewOnlineAnnouncementExperience
                                    ? AwardAnnouncement
                                    : AwardHistory
                            }
                        />
                        <AuthenticatedRoute
                            authorize={Permission.EmployerBranding}
                            path={routes.branding()}
                            component={EmployerBranding}
                        />
                        <AuthenticatedRoute
                            authorize={(identity) =>
                                (isParticipationReviewExperienceFeatureActive && identity.isTopLevelUser) ||
                                identity.permissions.hasInsights
                            }
                            path={routes.insights()}
                            component={Insights}
                        />
                        <AuthenticatedRoute
                            authorize={(identity) => identity.isSuperUser || identity.hasUserManagementPermission}
                            path={routes.manage.users()}
                            component={UserManagementRoutes}
                        />
                        <AuthenticatedRoute
                            authorize={Permission.PlatformSurveySetup || Permission.EmployerBranding}
                            path={routes.manage()}
                            component={Manage}
                        />
                        <AuthenticatedRoute
                            authorize={Permission.Security}
                            path={routes.settings.security()}
                            component={Security}
                        />
                        <AuthenticatedRoute
                            authorize={Permission.PlatformSurveySetup}
                            path={routes.survey()}
                            component={Survey}
                        />
                        <AuthenticatedRoute
                            authorize={Permission.PlatformSurveySetup}
                            path={routes.terms()}
                            component={Terms}
                        />
                        <AuthenticatedRoute
                            authorize={Permission.PlatformSurveySetup}
                            path={routes.integrations()}
                            component={Integrations}
                        />
                        <AuthenticatedRoute
                            authorize={
                                config.featureFlag.enableNewRoles
                                    ? ['isSurveyEventExists', Permission.EmployerRecognition]
                                    : ['isSurveyEventExists', Permission.EmployerRecognition, 'isTopLevelUser']
                            }
                            path={routes.employerRecognition()}
                            component={EmployerRecognition}
                        />
                        <AuthenticatedRoute
                            authorize={[Permission.Profile]}
                            path={routes.profile()}
                            component={Profile}
                        />
                        <AuthenticatedRoute
                            path={config.urls.peakCommerce}
                            component={(props) => {
                                props.history.replace('/') // Replace the history so when the user clicks back they don't get in an endless loop
                                window.location.href = config.urls.peakCommerce
                                return null
                            }}
                        />
                        <AuthenticatedRoute path={routes.legacyOrder()} component={OrderManagement} />
                        <AuthenticatedRoute
                            authorize={(identity) => identity.company.isPublisher}
                            path={routes.documents()}
                            component={Documents}
                        />
                        <AuthenticatedRoute authorize="isSuperUser" path={routes.admin()} component={Admin} />
                        <Route path={routes.accessDenied()} component={AccessDenied} />
                        {/* There's no "profile" page. This is a hack to enable "Profile" submenu in the mobile sidebar */}
                        <Route path={routes.fakeProfile()} component={() => null} />
                        <Redirect from={'/employerrecognition'} to={routes.employerRecognition()} />
                    </Switch>
                </FullPageErrorBoundary>
            </MainContent>
            <Sidebar />
        </>
    )
}

const RedirectToExternalLink = (token) => {
    return window.location.replace(`${config.urls.energageSetup}/survey-setup/${token}`)
}

const MainContainer = () => {
    const identity = useIdentity()
    const { isLoading: orgFeaturesLoading } = useOrganizationFeatures()
    const { blockerVersion, isCompanyBlocked, salesBlockerResponse, isLoading, isError, error } =
        useCompanyStatusDetails()

    if (isLoading || orgFeaturesLoading) {
        return <Spinner />
    }

    if (isError) {
        return showErrorAlert('There was an error', error.parsed)
    }

    if (blockerVersion === NEW_SALES_BLOCKER && isCompanyBlocked && !!salesBlockerResponse) {
        RedirectToExternalLink(salesBlockerResponse?.token)
    }

    if (identity.permissions.hasEmployerRecognitionWizard && isCompanyBlocked && !identity.isSuperUser) {
        return <Redirect to={routes.blocked()} />
    }

    const employerRecognitionAuthorize = config.featureFlag.enableNewRoles
        ? ['isSurveyEventExists', Permission.EmployerRecognition]
        : ['isSurveyEventExists', Permission.EmployerRecognition, 'isTopLevelUser']

    return (
        <Root>
            <NavigationListener />
            <Switch>
                <AuthenticatedRoute
                    authorize={
                        config.featureFlag.enableNewRoles
                            ? [Permission.EmployerRecognition, Permission.EmployerRecognitionWizard]
                            : ['isTopLevelUser', Permission.EmployerRecognitionWizard]
                    }
                    path={routes.employerRecognition.setup()}
                    component={EmployerRecognitionSetup}
                />
                <AuthenticatedRoute
                    authorize={
                        config.featureFlag.enableNewRoles
                            ? [Permission.EmployerRecognition, Permission.EmployerRecognitionWizard]
                            : ['isTopLevelUser', Permission.EmployerRecognitionWizard]
                    }
                    path={routes.employerRecognition.participation()}
                    component={Participation}
                />
                <AuthenticatedRoute
                    authorize={employerRecognitionAuthorize}
                    path={routes.employerRecognition.award.registration()}
                    component={AwardRegistration}
                />
                <AuthenticatedRoute
                    path={routes.branding.mediaKit.preview()}
                    component={MediaKitPressReleaseFormPreview}
                />
                <Route path="/" component={Main} />
            </Switch>
        </Root>
    )
}

export default MainContainer
