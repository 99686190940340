import Container from 'components/Container'
import Inset from 'components/Inset'
import { SUPPORT_PHONE } from 'constants/strings'

const AccountNotFound = () => (
    <Container>
        <Inset padding>
            <div className="h5 inline-block">{'You are using a free account.'}</div>
            <div className="paragraph w-1/3 sm:pt-2">
                <span className="hidden sm:inline-block">
                    {`It doesn't look like you have any subscriptions. If you would like to enhance your surveying experience and would like to get the most out of your data, please contact one of our sales reps at ${SUPPORT_PHONE}`}
                </span>
            </div>
        </Inset>
    </Container>
)

export default AccountNotFound
