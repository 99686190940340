import { useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import filter from 'lodash/filter'
import map from 'lodash/map'
import OrganizationalStrengths from 'containers/Public/RevampWidgets/OrganizationalStrengths'
import { OrgStrengthsCirculerePreview } from 'containers/Public/RevampWidgets/OrgStrengthsCirculerPreview'
import { OrgStrengthsTrangulerPreview } from 'containers/Public/RevampWidgets/OrgStrengthsTrangulerPreview'
import { StyledCarousel } from '../StyledCarousel'
import { previewType } from '../utils'
import type { OrgStrengthsPreviewProps } from './OrgStrengths.types'

export const OrgStrengthsPreview = ({
    data,
    dataId,
    hasEmployerBrandingSubscription,
    isExpiredWidget,
    filterData,
}: OrgStrengthsPreviewProps) => {
    const {
        orgStrengths,
        selectedDesignType,
        title,
        backgroundColor,
        className,
        headerBgColor,
        headerTextColor,
        highlightColor,
        textColor,
    } = data || {}
    const selectedData = filter(orgStrengths, 'isSelected')
    const altText = `Company Strengths, Verified by Top Workplaces.`

    useEffect(() => {
        const sliderDivElements = document.querySelector('.orgStrength .slider-frame')
        if (sliderDivElements) {
            sliderDivElements.setAttribute('aria-hidden', 'true')
        }
    }, [])

    if (selectedDesignType === previewType.Classical || !orgStrengths) {
        return (
            <OrganizationalStrengths
                //@ts-ignore
                title={title}
                orgStrengths={orgStrengths}
                backgroundColor={backgroundColor}
                className={className}
                headerBgColor={headerBgColor}
                headerTextColor={headerTextColor}
                isPdf={false}
                dataId={dataId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
            />
        )
    }

    return (
        <StyledCarousel
            //@ts-ignore
            heightMode=""
            width="449px"
            height="479px"
            className="orgStrength"
            autoGenerateStyleTag={false}
            wrapAround
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderAnnounceSlideMessage={({ currentSlide }) =>
                currentSlide + 1 === 1
                    ? `${altText} ${selectedData[0]?.name}, ${selectedData[0]?.text}, image`
                    : currentSlide + 1 === 2
                    ? `${altText} ${selectedData[1]?.name}, ${selectedData[1]?.text}, image`
                    : `${altText} ${selectedData[2]?.name}, ${selectedData[2]?.text}, image`
            }
            renderBottomLeftControls={({ previousSlide, slideCount }) =>
                slideCount > 1 && (
                    <button aria-label="Previous Statement Preview" onClick={previousSlide}>
                        <KeyboardArrowLeft className="ml-2 -m-comfortable cursor-pointer" size={24} />
                    </button>
                )
            }
            renderBottomRightControls={({ nextSlide, slideCount }) =>
                slideCount > 1 && (
                    <button aria-label="Next Statement Preview" onClick={nextSlide}>
                        <KeyboardArrowRight className="mr-2 -m-comfortable cursor-pointer" size={24} />
                    </button>
                )
            }
            renderBottomCenterControls={({ currentSlide, slideCount }) =>
                slideCount > 1 && `${currentSlide + 1} of ${slideCount}`
            }>
            {map(selectedData, ({ name, text }, index) =>
                selectedDesignType === previewType.TriangleDesign ? (
                    <OrgStrengthsTrangulerPreview
                        key={index}
                        name={name}
                        text={text}
                        width="449px"
                        height="480px"
                        backgroundColor={backgroundColor}
                        highlightColor={highlightColor}
                        textColor={textColor}
                        altText={altText}
                    />
                ) : (
                    <OrgStrengthsCirculerePreview
                        key={index}
                        name={name}
                        text={text}
                        width="449px"
                        height="480px"
                        backgroundColor={backgroundColor}
                        highlightColor={highlightColor}
                        textColor={textColor}
                        altText={altText}
                    />
                )
            )}
        </StyledCarousel>
    )
}
