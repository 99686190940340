import find from 'lodash/find'
import map from 'lodash/map'
import { useWorkplaceSurveyQuery } from 'api'
import type { QueryConfig } from 'api'
import type { SurveyFormat } from './SurveyFormats'

type Format = {
    name: string
    id: SurveyFormat
    isSelected: boolean
    isDefault: boolean
    sequence: number
    hasPaper: boolean
    hasEmail: boolean
}

export type FormatDropdownOption = Format & {
    value: SurveyFormat
    label: string
    isDisabled: boolean
}

const findSelectedFormat = (formats: FormatDropdownOption[]) =>
    find(formats, 'isSelected') ?? find(formats, 'isDefault')

const formatSurveyOption =
    (isPaperEnabled: boolean) =>
    (format: Format): FormatDropdownOption => {
        return {
            ...format,
            value: format.id,
            label: format.name,
            isDisabled: !isPaperEnabled && format.hasPaper,
        }
    }

type FormatResponse = {
    paperCount: number
    spanishPaperCount: number
    paperSurveyPrice: number
    hasOrderedPaperSurveys: boolean
    hasCustomQuestions: boolean
    isPaperEnabled: boolean
    awardRegionName: string
    paperContactDetails: unknown
    formats: Format[]
}

export type SurveyFormatData = Omit<FormatResponse, 'formats'> & {
    selectedFormat: FormatDropdownOption
    formats: FormatDropdownOption[]
}

export function useSurveyFormatData(surveyEventId: string | number, options: QueryConfig<SurveyFormatData>) {
    return useWorkplaceSurveyQuery<SurveyFormatData>(
        ['survey-format', surveyEventId],
        async (_, api) => {
            const data = await api.json<FormatResponse>(`surveyformat/${surveyEventId}`)
            const formatOption = formatSurveyOption(data.isPaperEnabled)
            const options = map(data.formats, formatOption)
            return {
                ...data,
                selectedFormat: findSelectedFormat(options),
                formats: options,
            } as SurveyFormatData
        },
        {
            ...options,
            placeholderData: {
                formats: [],
                selectedFormat: {} as FormatDropdownOption,
                hasCustomQuestions: false,
                hasOrderedPaperSurveys: false,
                paperSurveyPrice: 0,
                isPaperEnabled: false,
                paperCount: 0,
                spanishPaperCount: 0,
                paperContactDetails: undefined,
                awardRegionName: '',
            },
        }
    )
}
