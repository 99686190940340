export default {
    state: { surveyEventId: null },
    reducers: {
        setSurveyEventId(state, surveyEventId) {
            state.surveyEventId = surveyEventId
            return state
        },
        clearSurveyEventId(state) {
            state.surveyEventId = null
            return state
        },
    },
    selectors: (slice) => ({
        selectSurveyEventId() {
            return slice((state) => state.surveyEventId)
        },
    }),
}
