import { colors } from '@energage/theme'
import styled from 'styled-components'

interface BottomContentProps {
    image: string
    title: string
    subtitle: string
    description: string
}

const BottomContainer = styled.div`
    padding: 24px;
    background-color: ${colors.grey200};
    padding-top: 24px !important;
`

const Title = styled.h3`
    margin: 0;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: ${colors.grey700};
`

const Subtitle = styled.p`
    color: ${colors.purple400};
    text-decoration: none;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`

const Description = styled.p`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.grey600};
`

const BottomContent: React.FC<BottomContentProps> = ({ image, title, subtitle, description }) => {
    return (
        <BottomContainer>
            <div className="flex flex-col">
                <div className="flex items-center mb-4">
                    <div className="w-12 h-12 p-3 bg-white rounded-lg flex justify-center items-center">
                        <img src={image} alt={title} />
                    </div>
                    <div className="ml-4">
                        <Title>{title}</Title>
                        <Subtitle>{subtitle}</Subtitle>
                    </div>
                </div>
                <Description>{description}</Description>
            </div>
        </BottomContainer>
    )
}

export default BottomContent
