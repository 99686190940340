import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'

export const useFetchAwardAnnouncement = (awardListParticipantId: number) => {
    const { surveyCompanyId } = useIdentity()
    const { data, isLoading } = useWorkplaceSurveyQuery<{
        experienceType: number
        showAnnouncement: boolean
        isDisqualified: boolean
        awardListParticipantId: number
    }>(['award-announcement-data', surveyCompanyId], `AwardAnnouncement/${awardListParticipantId}`)

    return { awardAnnouncementData: data, awardListParticipantId, isLoading }
}
