import { Fragment, useCallback, useMemo } from 'react'
import { useMediaQueries } from '@energage/hooks'
import { width } from '@energage/theme'
import map from 'lodash/map'
import { useFieldArray, useFormContext } from 'react-hook-form'
import type { CellProps, Column } from 'react-table'
import { viewports } from 'style/breakpoints'
import type {
    AwardSelection,
    AwardSelectionPageResult,
    AwardSelectionTableProps,
    AwardSelectionWithRHFKey,
} from '../awardselection.types'
import {
    AwardImage,
    AwardLayout,
    LocationsCell,
    MaxHeightAwardTable,
    SelectAllCheckbox,
    SelectRowCheckbox,
} from './AwardSelectionTable.atoms'
import { AwardSelectionTableProvider } from './AwardSelectionTableContext'
import { AwardSelectionTableMobile } from './AwardSelectionTableMobile'
import { EditEmployeeCountInput, EditNationalEmployeeCountInput } from './EditEmployeeCountInput'

type AwardTitle = Pick<AwardSelection, 'publisherLogoUrl' | 'title'>

const renderMobileTitle = (award: AwardSelection) => award.title

function getColumns(
    canSelectNational: boolean,
    onSelectAll: (checked: boolean) => void,
    overlappingGroups: AwardSelectionPageResult['overlappingGroups']
): Column<AwardSelectionTableProps['awards'][0]>[] {
    return [
        {
            id: 'selection',
            Header: () => <SelectAllCheckbox onSelectAll={onSelectAll} />,
            Cell: ({ row }: CellProps<AwardSelectionWithRHFKey>) => (
                <SelectRowCheckbox
                    canSelectNational={canSelectNational}
                    index={row.index}
                    isReadOnly={row.original.isFinalized}
                />
            ),
            width: 0,
        },
        {
            Header: 'Award',
            accessor: ({ publisherLogoUrl, title }): AwardTitle => ({
                publisherLogoUrl,
                title,
            }),
            id: 'title',
            width: 310,
            Cell: ({ value, row }: CellProps<AwardSelectionWithRHFKey>) => (
                <label htmlFor={`awards.${row.index}.isSelected`}>
                    <div className="flex" style={{ gap: width(4) }}>
                        <AwardImage src={value.publisherLogoUrl} /> <span>{value.title}</span>
                    </div>
                </label>
            ),
        },
        {
            Header: 'Year',
            accessor: 'year',
            width: 100,
        },
        {
            Header: 'Locations in award region',
            accessor: 'locations',
            width: 400,
            Cell: ({ value }) => <LocationsCell locations={value} />,
        },
        {
            Header: 'Employees in award region',
            accessor: 'employeeCount',
            width: 170,
            Cell: ({ row }) => {
                const Input = row.original.isNational ? EditNationalEmployeeCountInput : EditEmployeeCountInput
                return (
                    <Input
                        index={row.index}
                        key={row.original.id}
                        readOnly={row.original.isFinalized}
                        minimumEmployeeCount={row.original.minimumEmployeeCount}
                        overlappingGroups={overlappingGroups}
                        awardName={row.original.title}
                    />
                )
            },
        },
    ]
}

export function AwardSelectionTable({
    className,
    overlappingGroups,
}: {
    className?: string
    overlappingGroups: AwardSelectionPageResult['overlappingGroups']
}) {
    const { register, getValues, control } = useFormContext<AwardSelectionPageResult>()
    const hasAllLocationsSurveyed = getValues('hasAllLocationsSurveyed')
    const canSelectNational = hasAllLocationsSurveyed !== false

    const { fields: awards, replace } = useFieldArray<AwardSelectionPageResult, 'awards', 'rhfKey'>({
        name: 'awards',
        keyName: 'rhfKey', // not changing this overrides our 'id'
        control,
    })

    const onSelectAll = useCallback(
        (checked: boolean) => {
            const updatedAwards = map(getValues('awards'), (a) =>
                a.isFinalized || (a.isNational && !canSelectNational) ? a : { ...a, isSelected: checked }
            )
            replace(updatedAwards)
        },
        [getValues, replace, canSelectNational]
    )

    const { isMobile } = useMediaQueries({ isMobile: { maxWidth: viewports.xsMax } })
    const columns = useMemo(
        () => getColumns(canSelectNational, onSelectAll, overlappingGroups),
        [canSelectNational, onSelectAll, overlappingGroups]
    )

    return (
        <section>
            <AwardSelectionTableProvider>
                {/* to include the value in form submit via react-hook-form */}
                {map(awards, (award, i) => (
                    <Fragment key={award.id}>
                        <input {...register(`awards.${i}.isFinalized`)} type="hidden" />
                        <input {...register(`awards.${i}.isNational`)} type="hidden" />
                    </Fragment>
                ))}
                {isMobile ? (
                    <AwardSelectionTableMobile
                        className={className}
                        awards={awards}
                        canSelectNational={canSelectNational}
                        onSelectAll={onSelectAll}
                        awardLayout={AwardLayout}
                        renderTitle={renderMobileTitle}
                        selectAllCheckbox={SelectAllCheckbox}
                        selectRowCheckbox={SelectRowCheckbox}
                        overlappingGroups={overlappingGroups}
                    />
                ) : (
                    <>
                        <MaxHeightAwardTable columns={columns} className={className} awards={awards} />
                    </>
                )}
            </AwardSelectionTableProvider>
        </section>
    )
}
