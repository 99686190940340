import { colors } from '@energage/theme'
import { LinkedInBannerId } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { Filters } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { tileTypes } from 'containers/Public/RevampWidgets/Tile'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getFilterQueryParams, getSelectedFiltersRecord } from '../Widgets/utils'
import { LinkedInBanner } from './LinkedInBanner'
import useFetchPublicPage from './useFetchPublicPage'
import type { CultureStatsStatement, Droplet } from './Widget.types'

interface ParseQueryProps {
    companyId?: string
    cultureStatsType?: string
    cultureStatsDesignType?: string
    surveyEventId?: string
    isDownloadable?: boolean
    isPreview?: string | number | boolean | (string | number | boolean)[] | null
    versionDetails?: unknown
}

type CultureCloudLinkedinBanner = {
    backgroundColor: string
    cultureStats: {
        statements: CultureStatsStatement[]
    }
    cultureCloud: {
        droplets: Droplet[]
    }
}

export interface FilterData {
    id: number
    name: string
    isEnabled: boolean
    isSelected: boolean
}

interface ContentFilterData {
    surveyEventId: number
    surveyEventName: string
    surveyEventClosedDate: string
    filteredRespondersCount: number
    totalRespondersCount: number
    isPulseSurvey: boolean
    isBrandingResultsEnabled: boolean
    isBenchmarksAvailable: boolean
    locations: FilterData[]
    departments: FilterData[]
    jobRoles: FilterData[]
}

export const LinkedInBannerFetch = ({ location }: { location: Location }) => {
    const parsedQuery = parseQueryParams(location)
    const query = getFilterQueryParams(parsedQuery)
    const { companyId, cultureStatsType, cultureStatsDesignType, surveyEventId, isDownloadable }: ParseQueryProps =
        parsedQuery

    const { data, isLoading, error } = useFetchPublicPage<CultureCloudLinkedinBanner>(
        'cultureCloud-filter',
        `SurveyCompanies/${companyId}/culturefacts/linkedinbanner/${surveyEventId}/${isDownloadable}?${query}`
    )

    const {
        data: surveyEvents,
        isLoading: isLoadingSurveyEvents,
        error: surveyEventsError,
    } = useFetchPublicPage<ContentFilterData>(
        'ContentSubFilters',
        `SurveyCompanies/${companyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}&${query}`
    )

    if (isLoading || isLoadingSurveyEvents) {
        return null
    }

    if (error || surveyEventsError) {
        return null
    }

    return (
        <Filters.Provider
            value={{
                filterData: surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery),
            }}>
            <LinkedInBanner
                {...data}
                isLoading={isLoading}
                isDownloadable
                type={tileTypes.vertical}
                filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                hasEmployerBrandingSubscription
                droplets={data?.cultureCloud?.droplets}
                dataId={LinkedInBannerId}
                statements={data?.cultureStats?.statements ?? []}
                backgroundColor={data?.backgroundColor || colors.darkblue500}
                cultureStatTypeSelected={Number(cultureStatsType)}
                selectedTopXDesignType={Number(cultureStatsDesignType)}
                selectedPercentPositiveDesignType={Number(cultureStatsDesignType)}
            />
        </Filters.Provider>
    )
}
