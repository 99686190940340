import { useCallback, useState } from 'react'
import forOwn from 'lodash/forOwn'
import map from 'lodash/map'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import showErrorAlert from 'util/showErrorAlert'
import { QuestionType } from './CustomQuestions/QuestionType'
import { prepareMultiChoiceOptionsForEdit } from './questionApiUtility'

function prepForQuestionUpdate(question) {
    if (question.questionTypeId === QuestionType.MultiChoice) {
        return {
            ...question,
            answerOptions: prepareMultiChoiceOptionsForEdit(question.answerOptions),
        }
    }

    return question
}

const useUpdateQuestion = (surveyEventId, options = {}) => {
    const { surveyCompanyId } = useIdentity()

    const { mutateAsync: updateQuestion, ...postOptions } = useWorkplaceSurveyQuery.mutate(
        `surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/questionTextEdit`,
        {
            onError: (err) => {
                let messages = []
                forOwn(err.parsed?.errors, function (value) {
                    messages.push({ message: value[0] })
                })
                showErrorAlert('There was an error saving your question', messages.length > 0 ? messages : err.parsed)
                postOptions.reset()
            },
            ...options,
        }
    )

    return [updateQuestion, postOptions]
}

const useFetchOriginalText = ({ surveyCompanyId, surveyEventId, questionEssenceId, sourceType }) => {
    const { data, isLoading } = useWorkplaceSurveyQuery(
        ['original-question-text', surveyEventId, questionEssenceId],
        `surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/${questionEssenceId}/${
            sourceType ?? 0
        }/GetOriginalQuestionText`,
        {
            enabled: !!questionEssenceId,
        }
    )

    return { originalQuestionText: data ?? null, isLoading }
}

function useEditQuestion(surveyEventId, updateQuestionsSection) {
    const { surveyCompanyId } = useIdentity()
    const [editingQuestionId, setEditingQuestionId] = useState()
    const [editingQuestionSourceType, setEditingQuestionSourceType] = useState()

    const { originalQuestionText } = useFetchOriginalText({
        surveyCompanyId,
        surveyEventId,
        questionEssenceId: editingQuestionId,
        sourceType: editingQuestionSourceType,
    })

    const onCancelEditQuestion = () => {
        setEditingQuestionId()
    }
    const onEditQuestion = (questionEssenceId, sourceType) => {
        setEditingQuestionId(questionEssenceId)
        setEditingQuestionSourceType(sourceType)
    }

    const [updateQuestion, { isLoading: isSavingQuestion }] = useUpdateQuestion(surveyEventId, {
        onSuccess: (updatedQuestion) => {
            updateQuestionsSection((section) => {
                return {
                    ...section,
                    subsections: map(section.subsections, (subsection) => ({
                        ...subsection,
                        questions: map(subsection.questions, (question) => {
                            if (question.questionEssenceId === updatedQuestion.questionEssenceId) {
                                return updatedQuestion
                            }

                            return question
                        }),
                    })),
                }
            })
            onCancelEditQuestion()
        },
    })

    const onUpdateQuestion = useCallback(
        ({ targetGroupDescription, targetGroupTitle, ...questionToSave }) => {
            return updateQuestion(prepForQuestionUpdate(questionToSave))
        },
        [updateQuestion]
    )

    return {
        editingQuestionId,
        onCancelEditQuestion,
        onEditQuestion,
        onUpdateQuestion,
        isSavingQuestion,
        originalQuestionText,
    }
}

export default useEditQuestion
