import { MultiOptionPreview, PreviewTitleDisplay } from './Fragments'

export type YesNoPreviewProps = {
    text?: string
    answerOptions?: { id: string | number; name: string }[]
}

const defaultOptions = [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' },
    { id: 3, name: 'N/A' },
]

export function YesNoPreview({ text, answerOptions = defaultOptions }: YesNoPreviewProps) {
    return (
        <div>
            <PreviewTitleDisplay>{text}</PreviewTitleDisplay>
            <MultiOptionPreview name="yesno" options={answerOptions} />
        </div>
    )
}
