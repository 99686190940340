import { useState } from 'react'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { CultureStatsGraphicType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { getAriaCultureCloudDescription } from 'containers/Main/EmployerBranding/util'
import ConfigurationSection from 'containers/Main/EmployerBranding/WidgetsConfigurationSection'
import CultureStats, { filteredData, isTopXDisabled } from 'containers/Public/RevampWidgets/CultureStats'
import { CultureStatsRadioButtonSelection } from 'containers/Public/RevampWidgets/CultureStatsRadioButtonSelection'
import EmployeeComment from 'containers/Public/RevampWidgets/EmployeeComment'
import { AiContentGenerator } from './AiContentGenerator'
import CultureCloudWordCheckboxes from './CultureCloudConfiguration'
import { CultureCloudPreview } from './CultureCloudConfiguration/CultureCloudPreview'
import CultureStatsCommentSelector from './CultureStatsConfiguration'
import {
    CultureCloudId,
    CultureStatsId,
    EmpCommentsId,
    LinkedInBannerId,
    OrgStrengthsId,
} from './CultureStatsConfiguration/utils'
import { LinkedInBannerPreview } from './LinkedInBannerPreview'
import LoveMyJobCommentSelector from './LoveMyJobConfiguration'
import { EmployeeCommentsDesignSelection } from './LoveMyJobConfiguration/EmployeeCommentsDesignSelection'
import { EmployeeCommentsPreview } from './LoveMyJobConfiguration/EmployeeCommentsPreview'
import { OrgStrengthsDesignSelection } from './OrgStrengthsConfiguration/OrgStrengthsDesignSelection'
import { OrgStrengthsPreview } from './OrgStrengthsConfiguration/OrgStrengthsPreview'
import { OrgStrengthsStatementSelector } from './OrgStrengthsConfiguration/OrgStrengthsStatementSelector'
import { MINIMUM_DROPLETS_REQUIRED, previewType } from './utils'

export const CultureCloudConfiguration = ({
    widgetServiceURL,
    selectedFilters,
    surveyCompanyId,
    hasEmployerBrandingSubscription,
    endPoint,
    actionType,
    forceShowFlag,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    resetExpiredFlag,
    filterData,
}) => (
    <ConfigurationSection
        title="Culture Cloud"
        getUrl={widgetServiceURL}
        postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/${endPoint}/download`}
        renderPreview={(data) => (
            <CultureCloudPreview
                data={data}
                ariaDescription={getAriaCultureCloudDescription(data?.droplets)}
                dataId={CultureCloudId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                minDroplets={data?.cultureCloudWordThreshold || MINIMUM_DROPLETS_REQUIRED}
            />
        )}
        renderCustomizations={(data, setData, isLoading) => (
            <>
                <CultureCloudWordCheckboxes
                    data={data}
                    onChange={setData}
                    isLoading={isLoading}
                    dataId={CultureCloudId}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                />
            </>
        )}
        renderCustomAiSection={(data, setData, isLoading, isDisabled) => (
            <AiContentGenerator
                data={data}
                onChange={setData}
                isLoading={isLoading}
                isDisabled={isDisabled}
                dataId={CultureCloudId}
                filterData={filterData}
            />
        )}
        dataId={CultureCloudId}
        isWidgetDisabled={(data) =>
            size(data?.droplets) < (data?.cultureCloudWordThreshold || MINIMUM_DROPLETS_REQUIRED)
        }
        contentFilterOptions={selectedFilters}
        widgetName="Culture Cloud Preview"
        forceShowFlag={forceShowFlag}
        actionType={actionType}
        companyData={companyData}
        isExpiredWidget={isExpiredWidget}
        resetExpiredFlag={resetExpiredFlag}
        updatePublishStatus={updatePublishStatus}
        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
        resetFlag={resetFlag}
        forceHidden={forceHidden}
        filterData={filterData}
    />
)

export const LinkedInBannerConfiguration = ({
    widgetServiceURL,
    surveyCompanyId,
    selectedFilters,
    hasEmployerBrandingSubscription,
    forceShowFlag,
    actionType,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    cultureStatsTableData,
    resetExpiredFlag,
    filterData,
    cultureCloudServiceURL,
}) => {
    const [selectedCultureType, setSelectedCultureType] = useState()
    const [selectedTopXDesignType, setSelectedTopXDesignType] = useState()
    const [selectedPercentPositiveDesignType, setSelectedPercentPositiveDesignType] = useState(
        CultureStatsGraphicType.HalfDonut
    )
    const isTopXFiltered = isTopXDisabled(filterData, selectedCultureType)
    const isFilterApplied = filteredData(filterData)?.length !== 0

    return (
        <ConfigurationSection
            title="LinkedIn Banner"
            tooltipMessage="Show off what your company stands for with Culture Stats. You can choose how to display your company's strengths. Filter by Department, Location or Job Role if those demographics were included in the survey."
            className="justify-center"
            getUrl={widgetServiceURL}
            postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/linkedinbanner/download`}
            renderPreview={(data) => (
                <LinkedInBannerPreview
                    data={data}
                    ariaDescription={getAriaCultureCloudDescription(data?.droplets)}
                    cultureCloudServiceURL={cultureCloudServiceURL}
                    dataId={LinkedInBannerId}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                    isExpiredWidget={isExpiredWidget}
                    filterData={filterData}
                    cultureStatTypeSelected={selectedCultureType || data?.cultureStatTypeSelected}
                    selectedTopXDesignType={selectedTopXDesignType}
                    selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                    isTopXFiltered={isTopXFiltered}
                    cultureStatsTableData={cultureStatsTableData}
                />
            )}
            renderCustomizations={(data, setData, isLoading, isDisabled) =>
                !isDisabled && (
                    <CultureStatsCommentSelector
                        data={data}
                        onChange={setData}
                        isLoading={isLoading}
                        dataId={LinkedInBannerId}
                        selectedCultureType={selectedCultureType || data?.cultureStatTypeSelected}
                        selectedTopXDesignType={selectedTopXDesignType}
                        selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                        setSelectedCultureType={setSelectedCultureType}
                        setSelectedPercentPositiveDesignType={setSelectedPercentPositiveDesignType}
                        setSelectedTopXDesignType={setSelectedTopXDesignType}
                        cultureStatsTableData={cultureStatsTableData}
                        isExpiredWidget={isExpiredWidget}
                        isFilterApplied={isFilterApplied}
                        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                    />
                )
            }
            renderCustomGraphicSection={(data, setData, isLoading) =>
                !isEmpty(data?.statements) &&
                size(data?.droplets) >= MINIMUM_DROPLETS_REQUIRED && (
                    <CultureStatsRadioButtonSelection
                        data={data}
                        dataId={LinkedInBannerId}
                        onChange={setData}
                        isLoading={isLoading}
                        selectedCultureType={selectedCultureType || data?.cultureStatTypeSelected}
                        selectedTopXDesignType={selectedTopXDesignType}
                        selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                        setSelectedCultureType={setSelectedCultureType}
                        setSelectedPercentPositiveDesignType={setSelectedPercentPositiveDesignType}
                        setSelectedTopXDesignType={setSelectedTopXDesignType}
                        isTopXFiltered={isTopXFiltered}
                        cultureStatsTableData={cultureStatsTableData}
                        isExpiredWidget={isExpiredWidget}
                    />
                )
            }
            dataId={LinkedInBannerId}
            isWidgetDisabled={(data) => !head(data?.statements) || size(data?.droplets) < MINIMUM_DROPLETS_REQUIRED}
            contentFilterOptions={selectedFilters}
            widgetName="LinkedIn Banner Preview"
            forceShowFlag={forceShowFlag}
            actionType={actionType}
            companyData={companyData}
            isExpiredWidget={isExpiredWidget}
            resetExpiredFlag={resetExpiredFlag}
            updatePublishStatus={updatePublishStatus}
            resetFlag={resetFlag}
            forceHidden={forceHidden}
            filterData={filterData}
            selectedCultureType={selectedCultureType}
            selectedTopXDesignType={selectedTopXDesignType}
            selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
            hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
            cultureStatsTableData={cultureStatsTableData}
        />
    )
}

export const CultureStatsConfiguration = ({
    widgetServiceURL,
    surveyCompanyId,
    selectedFilters,
    hasEmployerBrandingSubscription,
    forceShowFlag,
    actionType,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    cultureStatsTableData,
    resetExpiredFlag,
    filterData,
}) => {
    const [selectedCultureType, setSelectedCultureType] = useState()
    const [selectedTopXDesignType, setSelectedTopXDesignType] = useState()
    const [selectedPercentPositiveDesignType, setSelectedPercentPositiveDesignType] = useState()
    const [selectedRectangleDesignType, setSelectedRectangleDesignType] = useState(
        CultureStatsGraphicType.RectangleBorderDesign
    )
    const isTopXFiltered = isTopXDisabled(filterData, selectedCultureType)
    const isFilterApplied = filteredData(filterData)?.length !== 0

    return (
        <ConfigurationSection
            title="Culture Stats"
            tooltipMessage="Show off what your company stands for with Culture Stats. You can choose how to display your company's strengths. Filter by Department, Location or Job Role if those demographics were included in the survey."
            className="justify-center"
            getUrl={widgetServiceURL}
            postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/PercentPositive/download`}
            renderPreview={(data) => (
                <CultureStats
                    {...data}
                    dataId={CultureStatsId}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                    isExpiredWidget={isExpiredWidget}
                    filterData={filterData}
                    selectedCultureType={selectedCultureType ?? data?.cultureStatTypeSelected}
                    selectedTopXDesignType={selectedTopXDesignType}
                    selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                    selectedRectangleDesignType={selectedRectangleDesignType}
                    isTopXFiltered={isTopXFiltered}
                    cultureStatsTableData={cultureStatsTableData}
                />
            )}
            renderCustomizations={(data, setData, isLoading) => (
                <CultureStatsCommentSelector
                    data={data}
                    onChange={setData}
                    isLoading={isLoading}
                    dataId="cloud"
                    selectedCultureType={selectedCultureType || data?.cultureStatTypeSelected}
                    setSelectedCultureType={setSelectedCultureType}
                    setSelectedTopXDesignType={setSelectedTopXDesignType}
                    setSelectedPercentPositiveDesignType={setSelectedPercentPositiveDesignType}
                    setSelectedRectangleDesignType={setSelectedRectangleDesignType}
                    selectedTopXDesignType={selectedTopXDesignType}
                    selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                    selectedRectangleDesignType={selectedRectangleDesignType}
                    cultureStatsTableData={cultureStatsTableData}
                    isExpiredWidget={isExpiredWidget}
                    isFilterApplied={isFilterApplied}
                    isTopXFiltered={isTopXFiltered}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                />
            )}
            renderCustomGraphicSection={(data, setData, isLoading) =>
                !isEmpty(data?.statements) && (
                    <CultureStatsRadioButtonSelection
                        data={data}
                        dataId={CultureStatsId}
                        onChange={setData}
                        isLoading={isLoading}
                        selectedCultureType={selectedCultureType || data?.cultureStatTypeSelected}
                        selectedTopXDesignType={selectedTopXDesignType}
                        selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                        selectedRectangleDesignType={selectedRectangleDesignType}
                        setSelectedCultureType={setSelectedCultureType}
                        setSelectedPercentPositiveDesignType={setSelectedPercentPositiveDesignType}
                        setSelectedTopXDesignType={setSelectedTopXDesignType}
                        setSelectedRectangleDesignType={setSelectedRectangleDesignType}
                        isTopXFiltered={isTopXFiltered}
                        cultureStatsTableData={cultureStatsTableData}
                        isExpiredWidget={isExpiredWidget}
                    />
                )
            }
            dataId={CultureStatsId}
            isWidgetDisabled={(data) => !head(data?.statements)}
            contentFilterOptions={selectedFilters}
            widgetName="Culture Stats Preview"
            forceShowFlag={forceShowFlag}
            actionType={actionType}
            companyData={companyData}
            isExpiredWidget={isExpiredWidget}
            resetExpiredFlag={resetExpiredFlag}
            updatePublishStatus={updatePublishStatus}
            resetFlag={resetFlag}
            forceHidden={forceHidden}
            filterData={filterData}
            selectedCultureType={selectedCultureType}
            selectedTopXDesignType={selectedTopXDesignType}
            selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
            selectedRectangleDesignType={selectedRectangleDesignType}
            setSelectedTopXDesignType={setSelectedTopXDesignType}
            setSelectedPercentPositiveDesignType={setSelectedPercentPositiveDesignType}
            setSelectedRectangleDesignType={setSelectedRectangleDesignType}
            hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
            cultureStatsTableData={cultureStatsTableData}
        />
    )
}

export const OrgStrengthsConfiguration = ({
    widgetServiceURL,
    surveyCompanyId,
    selectedFilters,
    hasEmployerBrandingSubscription,
    endPoint,
    forceShowFlag,
    actionType,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    resetExpiredFlag,
    filterData,
    organizationName,
}) => (
    <ConfigurationSection
        title="Organizational Strengths"
        tooltipMessage={`Share employee-sourced strengths that make ${organizationName} stand out from the rest. Filter by Department, Location, and Job Role to spotlight specific segments of your organization.`}
        className="justify-center"
        getUrl={widgetServiceURL}
        postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/${endPoint}/download`}
        renderPreview={(data) => (
            <OrgStrengthsPreview
                data={data}
                dataId={OrgStrengthsId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
            />
        )}
        renderCustomizations={(data, setData, isLoading) =>
            data?.selectedDesignType !== previewType.Classical && (
                <OrgStrengthsStatementSelector
                    data={data}
                    onChange={setData}
                    isLoading={isLoading}
                    dataId={OrgStrengthsId}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                />
            )
        }
        renderCustomGraphicSection={(data, setData, isLoading) =>
            !isEmpty(data?.orgStrengths) && (
                <OrgStrengthsDesignSelection
                    data={data}
                    dataId={OrgStrengthsId}
                    onChange={setData}
                    isLoading={isLoading}
                />
            )
        }
        dataId={OrgStrengthsId}
        isWidgetDisabled={(data) => !head(data?.orgStrengths)}
        contentFilterOptions={selectedFilters}
        widgetName="Culture Fact Preview"
        forceShowFlag={forceShowFlag}
        actionType={actionType}
        companyData={companyData}
        isExpiredWidget={isExpiredWidget}
        resetExpiredFlag={resetExpiredFlag}
        updatePublishStatus={updatePublishStatus}
        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
        resetFlag={resetFlag}
        forceHidden={forceHidden}
        filterData={filterData}
    />
)

export const LoveMyJobConfiguration = ({
    widgetServiceURL,
    surveyCompanyId,
    selectedFilters,
    hasEmployerBrandingSubscription,
    endPoint,
    forceShowFlag,
    actionType,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    resetExpiredFlag,
    filterData,
    organizationName,
}) => (
    <ConfigurationSection
        title="Employee Comments"
        tooltipMessage={`Let the world see why your employees love working at ${organizationName}. Filter by Department, Location, or Job Role if those demographics were included in the survey.`}
        className="justify-center"
        getUrl={widgetServiceURL}
        postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/${endPoint}/download`}
        renderPreview={(data) => (
            <EmployeeCommentsPreview
                data={data}
                dataId={EmpCommentsId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
            />
        )}
        renderCustomizations={(data, setData, isLoading) => (
            <LoveMyJobCommentSelector
                data={data}
                onChange={setData}
                isLoading={isLoading}
                dataId={EmpCommentsId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
            />
        )}
        renderCustomGraphicSection={(data, setData, isLoading) =>
            !isEmpty(data?.comments) && (
                <EmployeeCommentsDesignSelection
                    data={data}
                    dataId={EmpCommentsId}
                    onChange={setData}
                    isLoading={isLoading}
                />
            )
        }
        dataId={EmpCommentsId}
        isWidgetDisabled={(data) => !head(data?.comments)}
        contentFilterOptions={selectedFilters}
        widgetName="Comments Preview"
        forceShowFlag={forceShowFlag}
        actionType={actionType}
        companyData={companyData}
        isExpiredWidget={isExpiredWidget}
        resetExpiredFlag={resetExpiredFlag}
        updatePublishStatus={updatePublishStatus}
        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
        resetFlag={resetFlag}
        forceHidden={forceHidden}
        filterData={filterData}
    />
)

export const EmployeeCommentSection = ({
    widgetServiceURL,
    surveyCompanyId,
    selectedFilters,
    hasEmployerBrandingSubscription,
    endPoint,
    forceShowFlag,
    actionType,
    companyData,
    updatePublishStatus,
    resetFlag,
    forceHidden,
    isExpiredWidget,
    resetExpiredFlag,
    filterData,
}) => (
    <ConfigurationSection
        title="Employee Comment"
        className="justify-center"
        getUrl={widgetServiceURL}
        postUrl={`surveycompanies/${surveyCompanyId}/culturefacts/${endPoint}/download`}
        renderPreview={(data) => (
            <EmployeeComment
                {...data}
                dataId="Employee Comment"
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
            />
        )}
        renderCustomizations={(data, setData, isLoading) => (
            <LoveMyJobCommentSelector
                data={data}
                onChange={setData}
                isLoading={isLoading}
                dataId="cloud"
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
            />
        )}
        dataId="Employee Comment"
        isWidgetDisabled={(data) => !head(data?.comments)}
        contentFilterOptions={selectedFilters}
        widgetName="Culture Fact Preview"
        forceShowFlag={forceShowFlag}
        actionType={actionType}
        companyData={companyData}
        isExpiredWidget={isExpiredWidget}
        resetExpiredFlag={resetExpiredFlag}
        updatePublishStatus={updatePublishStatus}
        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
        resetFlag={resetFlag}
        forceHidden={forceHidden}
        filterData={filterData}
    />
)
