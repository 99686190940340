import flatten from 'lodash/flatten'
import { useInfiniteQuery } from 'react-query'
import { management } from 'api'

const LIMIT = 10

function getArchivedNotifications({ pageParam = 0 }) {
    return management.fetch(`Api/Notifications/Archived?offset=${pageParam}&limit=${LIMIT}`)
}

const useFetchArchivedNotification = () => {
    const { data, isFetching, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
        ['archive-notifications'],
        getArchivedNotifications,
        {
            refetchOnMount: false,
            getNextPageParam: (lastPage, allPages) => {
                // there might be more if we requested LIMIT and got back that amount
                // so return an offset to continue getting notifications from
                if (lastPage?.length === LIMIT) {
                    return allPages?.length * LIMIT
                }

                return false
            },
        }
    )

    return {
        notifications: flatten(data?.pages),
        isFetching,
        isFetchingMore: isFetchingNextPage,
        fetchMore: fetchNextPage,
        canFetchMore: hasNextPage,
    }
}

export default useFetchArchivedNotification
