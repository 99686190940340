import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import generateObject from 'util/generateObject'

const DEMOGRAPHIC_KEY_NAMES = ['startDate', 'approximateSalary', 'isFullTime', 'jobGrade']

export const DEPARTMENT_TAG_ATTRIBUTES = {
    id: get('id'),
    name: get('name'),
    modifiedDateTime: get('modifiedDateTime'),
    organizationId: get('organizationId'),
}

export const DEPARTMENT_ATTRIBUTES = {
    id: get('id'),
    name: get('value'),
    modifiedDateTime: get('modifiedDateTime'),
    departmentsTag: pipe([get('departmentsTag'), generateObject(DEPARTMENT_TAG_ATTRIBUTES)]),
    checked: get('checked'),
    parentDepartmentId: get('parentTagOptionId'),
}

export const SUPERVISOR_ATTRIBUTES = {
    id: get('id'),
    name: get('name'),
    emailAddress: get('emailAddress'),
    supervisorId: get('supervisorId'),
    directs: get('directs'),
    department: pipe([
        get('department'),
        generateObject({
            id: get('id'),
            name: get('value'),
        }),
    ]),
    employeeCount: get('employeeCount'),
}

const hasDemographicUser = (user) => !isEmpty(pick(user, DEMOGRAPHIC_KEY_NAMES))
export const hasSomeDemographicData = (users) => !isEmpty(find(users, hasDemographicUser))
