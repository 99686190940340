import type { ChangeEvent } from 'react'
import noop from 'lodash/noop'
import { useDebouncedCallback } from 'use-debounce'
import { TextAreaInputSmall } from '../CustomQuestionTextInputs'

type ChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => void

type ConditionalQuestionProps = {
    text?: string
    name: string
    onChange: ChangeHandler
    prompt: string
    placeholder: string
}

const ConditionalQuestion = ({ text, name, onChange = noop, prompt, placeholder }: ConditionalQuestionProps) => {
    const debouncedOnChange = useDebouncedCallback(onChange, 500)

    return (
        <div className="mb-2 w-full">
            <TextAreaInputSmall
                name={name}
                label={prompt}
                placeholder={placeholder}
                defaultValue={text ?? ''}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    e.persist()
                    debouncedOnChange(e)
                }}
            />
        </div>
    )
}

type ConditionalQuestionsProps = {
    positiveText?: string
    negativeText?: string
    onChangePositiveText: ChangeHandler
    onChangeNegativeText: ChangeHandler
}

export const ConditionalQuestions = ({
    positiveText,
    negativeText,
    onChangePositiveText,
    onChangeNegativeText,
}: ConditionalQuestionsProps) => (
    <div className="mt-6 w-full max-w-md">
        <ConditionalQuestion
            name={`positive-conditional`}
            text={positiveText}
            onChange={onChangePositiveText}
            placeholder={'Enter your conditional comment question here'}
            prompt={'Conditional questions for responses of agree or strongly agree'}
        />
        <ConditionalQuestion
            name={`negative-conditional`}
            text={negativeText}
            onChange={onChangeNegativeText}
            placeholder={'Enter your conditional comment question here'}
            prompt={'Conditional questions for responses of disagreement or only slightly agree'}
        />
    </div>
)
