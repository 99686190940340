import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import pluralize from 'pluralize'
import { routes } from 'constants/routes'
import { ReactComponent as TwpIcon } from 'images/twp.svg'
import type { AwardSelection } from '../../../AwardSelection'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const AwardsSelected = ({ awards, surveyEventId }: { awards: AwardSelection[]; surveyEventId: number }) => {
    const tileUrl = routes.survey.setup.awards({ surveyEventId })

    const maxDisplayedAwards = 4
    const awardsTextArray = awards.map((award) => `${award.title} ${award.year}`)
    const displayedAwards = awardsTextArray.slice(0, maxDisplayedAwards)
    const remainingAwardsCount = awardsTextArray.length - displayedAwards.length
    const awardWord = pluralize('award', remainingAwardsCount)
    const awardsText =
        'You are participating in ' +
        displayedAwards.join(', ') +
        (remainingAwardsCount > 0 ? `, plus ${remainingAwardsCount} more ${awardWord}.` : '.')
    return (
        <SummaryCard padding="tight">
            <CardIcon color={colors.grey500}>
                <Icon as={TwpIcon} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="mb-2">{'Your awards'}</CardTitle>
                    {awardsText}
                </CardContent>
                <SummaryButton className="w-fit" tileUrl={tileUrl} buttonText="View Award Selections" />
            </CardBody>
        </SummaryCard>
    )
}
