import { format } from 'date-fns'
import { DATE_FORMAT, parseDateOnly } from 'util/formatters'
import type { AdditionalChancesSection } from '../awardAnnouncement.types'
import { Section } from '../Section'

export const AdditionalChances = ({ lastSurveyCloseDate, bgColor }: AdditionalChancesSection) => (
    <Section bgColor={bgColor} textColor={''}>
        <>
            <h2 className="sm:text-5xl text-2xl font-black">
                {'You have additional chances to earn Top Workplaces recognition'}
            </h2>
            <p className="sm:text-2xl text-lg sm:mt-16 mt-8">{`Your survey results qualify you to participate in Top Workplaces programs open within 12 months. That means your current survey will expire on 
            ${
                lastSurveyCloseDate && format(parseDateOnly(lastSurveyCloseDate), DATE_FORMAT)
            }. To continue participating in Top Workplaces, complete your next survey before this date. You can also choose to survey again at any time, and the most recent results of any Top Workplaces-eligible survey will determine your Top Workplaces outcome.`}</p>
        </>
    </Section>
)
