import TaskLink from './TaskLink'

const Link = (props) => <TaskLink variant="link" className="p-0 font-bold text-primary-dark" size="sm" {...props} />

export default {
    AcceptTerms: ({ isUrgent, path, ...props }) =>
        isUrgent ? (
            <>
                {'You need to '}
                <Link {...props} to={path}>
                    {'agree to Terms and Conditions >'}
                </Link>
                {'before your survey can launch >'}
            </>
        ) : (
            <>
                {'Please '}
                <Link {...props} to={path}>
                    {'agree to Terms and Conditions >'}
                </Link>
            </>
        ),
    ProvideNationalEmployeeCount: ({ isUrgent, path, ...props }) => (
        <>
            {isUrgent
                ? 'We cannot launch your survey until you enter '
                : 'How many national USA employees do you have? Please '}
            <Link {...props} to={path}>
                {isUrgent ? 'your national USA employee count >' : 'enter your employee count >'}
            </Link>
        </>
    ),
    OrganizationOverview: ({ isUrgent, path, ...props }) => (
        <>
            {isUrgent
                ? 'Your survey cannot launch until you complete your '
                : 'Please complete your organization overview with '}
            <Link {...props} to={path}>
                {'organization name and sector >'}
            </Link>
        </>
    ),
    ScheduleSurvey: ({ isUrgent, path, tasks, ...props }) =>
        isUrgent ? (
            <>
                {'Your survey cannot launch until you '}
                <Link {...props} to={path}>
                    {'choose your survey launch date >'}
                </Link>
            </>
        ) : (
            <>
                {"It's time to schedule your survey! "}
                <Link {...props} to={path}>
                    {'Go to survey schedule >'}
                </Link>
            </>
        ),
    SetupSurvey: ({ isUrgent, path, tasks, ...props }) => (
        <>
            {isUrgent ? 'Your survey cannot launch until you ' : 'Please '}
            <Link {...props} to={path}>
                {'upload employees and confirm recipients '}
            </Link>
            {isUrgent ? '>' : 'so your survey can launch >'}
        </>
    ),
    ApproveSurveyLaunch: ({ isUrgent, path, tasks, ...props }) => (
        <>
            {isUrgent ? 'Your survey cannot launch until you ' : 'Please '}
            <Link {...props} to={path}>
                {'approve your survey '}
            </Link>
            {isUrgent ? '>' : 'so your survey can launch >'}
        </>
    ),
    CompleteRegistration: ({ isUrgent, awardName, path, tasks, ...props }) => (
        <>
            {isUrgent
                ? `You still need to complete your ${awardName} registration. `
                : `It's time to complete your ${awardName} registration. `}
            <Link {...props} to={path}>
                {'Finish your registration >'}
            </Link>
        </>
    ),
    ConfirmProfile: ({ isUrgent, programName, path, ...props }) => (
        <>
            {isUrgent
                ? "We can't display your profile on the topworkplaces.com website until you publish "
                : 'Put your best foot forward by completing and publishing '}

            <Link {...props} to={path}>
                {isUrgent ? `your ${programName} profile >` : 'your Top Workplaces profile now'}
            </Link>
        </>
    ),
}
