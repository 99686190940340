const PROGRAM_DEADLINE_TYPES = {
    OneMonth: 1,
    TwoMonths: 2,
    ThreeMonths: 3,
    FourMonths: 4,
}

export const getProgramDeadlineNotifMessage = (surveyDeadlineTypeId) => {
    switch (surveyDeadlineTypeId) {
        case PROGRAM_DEADLINE_TYPES.OneMonth:
            return {
                isUrgent: true,
                text: 'Top Workplaces is closing soon, ',
                link: 'set up your survey now to qualify!',
            }
        case PROGRAM_DEADLINE_TYPES.TwoMonths:
            return {
                isUrgent: true,
                text: "Don't forget to launch your survey for Top Workplaces. ",
                link: 'Click here to begin!',
            }
        case PROGRAM_DEADLINE_TYPES.ThreeMonths:
            return {
                isUrgent: false,
                text: "Don't miss out on Top Workplaces participation. ",
                link: 'Set up your survey today!',
            }
        case PROGRAM_DEADLINE_TYPES.FourMonths:
            return {
                isUrgent: false,
                text: "It's time to start a new survey for Top Workplaces. ",
                link: 'Click here to begin!',
            }
        default:
            return null
    }
}
