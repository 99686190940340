import every from 'lodash/every'
import filter from 'lodash/filter'
import findKey from 'lodash/findKey'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import replace from 'lodash/replace'
import some from 'lodash/some'
import toLower from 'lodash/toLower'
import trim from 'lodash/trim'
import loadXlsxAsync from 'util/loadXlsxAsync'

const ignorableCharsForValues = /[\r\n\t_*?\\/',()"]/gm
const ignorableCharsForHeaders = /[\s_*?\\/',()"]/gm

/**
 *
 * @param input string
 * @returns boolean
 */
export function matchHeaderAliases(input) {
    // lowercases and Removes: ' ', '_', '-', '*', '?', '/', '\', "'", ',', '(', ')'
    // aliases should already be done to save workload
    return some(this.aliases, (s1) => {
        return toLower(s1) === normalizeHeader(input)
    })
}

const isCompanyCustomUniqueIdentifier = (input) => /(email|phone)/i.test(input)

export const getImportColumnMapping = (headerMapping, firstRow) =>
    map(headerMapping, (mapping, key) => ({
        key,
        headerText: mapping.originalText,
        firstRowValue: firstRow?.[key]?.trim() ?? 'No content to show',
        mapping: mapping.mapping,
        isMappable: mapping.isMappable,
        removeMapping: mapping.removeMapping,
    }))

export const convertFileToJson = ({ columns, headerInfo, file, uploadedColumns = [] }) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
        reader.onload = () => {
            handleData({ columns, data: reader.result, headerInfo, uploadedColumns })
                .then((jsonData) => {
                    const { headerMapping, rows } = jsonData

                    if (!some(headerMapping)) {
                        reject(new Error('No headers found'))
                    }

                    if (!some(rows)) {
                        const error = new Error('No data found in this spreadsheet')
                        error.name = ''
                        reject(error)
                    }

                    resolve(jsonData)
                })
                .catch((e) => {
                    reject(new Error(e.message))
                })
        }

        reader.onerror = reject

        reader.readAsBinaryString(file)
    })
}

const isColumnAlreadyUploaded = (matchedColumnName, columnText, uploadedColumns) => {
    return some(
        uploadedColumns,
        (uploadedColumn) =>
            toLower(columnText) === toLower(uploadedColumn) || toLower(matchedColumnName) === toLower(uploadedColumn)
    )
}

const collectColumnHeaders = (columns, uploadedColumns) => (mapping, columnText, columnKey) => {
    const columnName = findKey(columns, (column) => column.matchHeader(columnText))
    const mapTo = columns[columnName]

    const isMappable =
        isCompanyCustomUniqueIdentifier(columnText) ||
        mapTo?.alwaysUpload ||
        isColumnAlreadyUploaded(columnName, columnText, uploadedColumns)

    mapping[columnKey] = {
        originalText: columnText,
        isMappable,
        mapping: mapTo,
    }
    return mapping
}

const matchHeadersToDataColumns = (columns, headerRow, uploadedColumns) => {
    const headerMapping = reduce(headerRow, collectColumnHeaders(columns, uploadedColumns), {})

    return { headerMapping }
}

//remove unwanted characters from input and replace multiple spaces with a single
const normalizeValue = (input) => trim(replace(toLower(replace(input, ignorableCharsForValues, '')), /\s\s+/g, ' '))
export const normalizeHeader = (input) => toLower(replace(input, ignorableCharsForHeaders, ''))

export const collectColumnValues = (customDemographicOptions, columnText) => {
    const matchingKey = findKey(customDemographicOptions, ({ name }) => {
        const newColumnText = toLower(columnText) === 'y' ? 'Yes' : toLower(columnText) === 'n' ? 'No' : columnText
        return normalizeValue(name) === normalizeValue(newColumnText)
    })

    return {
        originalText: columnText,
        mapping: customDemographicOptions[matchingKey],
    }
}

const matchValuesToDataColumns = (headerMapping, rows) => {
    const valuesMapping = {}
    const { trackValueForColumn, isColumnMappable } = mappableColumnsValidator()

    forEach(rows, (row) => {
        forEach(row, (value, column) => {
            const normalizedValue = normalizeValue(value)

            if (!headerMapping[column].isMappable) {
                trackValueForColumn(column, normalizedValue)
                headerMapping[column].isMappable = isColumnMappable(column)
            }

            valuesMapping[column] = valuesMapping[column] ?? {}
            const valueMapping = valuesMapping[column]

            if (normalizedValue in valueMapping || normalizedValue === '') {
                return
            }
            const { mapping } = headerMapping[column]
            if (!mapping || !mapping?.hasDemographicOptions) {
                valueMapping[normalizedValue] = {
                    originalText: value,
                }
                return
            }

            valueMapping[normalizedValue] = collectColumnValues(mapping.customDemographicOptions, value)
        })
    })
    return { valuesMapping }
}

const mappableColumnsValidator = () => {
    const columns = {}
    const trackValueForColumn = (columnKey, value) => {
        columns[columnKey] = columns[columnKey] ?? {
            uniqueValues: true,
            values: new Set(),
        }

        const column = columns[columnKey]

        if (value.trim() === '') {
            return
        }

        if (column.values.has(value)) {
            column.uniqueValues = false
            return
        }

        column.values.add(value)
    }

    const isColumnMappable = (columnKey) => !columns[columnKey].uniqueValues

    return {
        trackValueForColumn,
        isColumnMappable,
    }
}

const handleData = async ({ columns, data, headerInfo, uploadedColumns }) => {
    const { startRow, headerRow, rows } = await convertDataToJson(data, headerInfo)
    const { headerMapping } = matchHeadersToDataColumns(columns, headerRow, uploadedColumns)
    const { valuesMapping } = matchValuesToDataColumns(headerMapping, rows)

    const nonEmptyRows = filterEmptyRows(rows, headerRow)

    return { startRow, headerMapping, valuesMapping, rows: nonEmptyRows }
}

const isEmptyHeaderRow = (headerRow, headerInfo) => {
    const titles = Object.keys(headerInfo.titles)
    return some(titles, (title) => headerRow?.[title]?.toLowerCase() === '')
}

const hasHeaderRow = (headerRow, headerInfo) => {
    const titles = Object.keys(headerInfo.titles)
    return some(titles, (title) => headerRow?.[title]?.toLowerCase() === headerInfo.titles[title].toLowerCase())
}

const convertDataToJson = async (data, headerInfo) => {
    const xlsx = await loadXlsxAsync()
    const workbook = xlsx.read(data, { type: 'binary' })
    const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]

    // Skip first row
    const range = xlsx.utils.decode_range(firstWorksheet['!ref'])
    range.s.r = 0
    firstWorksheet['!ref'] = xlsx.utils.encode_range(range)

    const [headerRow, ...rows] = xlsx.utils.sheet_to_json(firstWorksheet, { header: 'A', raw: false })

    if (hasHeaderRow(headerRow, headerInfo) && rows.length >= headerInfo.rows) {
        return {
            startRow: 2 + headerInfo.rows,
            headerRow: rows[headerInfo.rows - 1],
            rows: rows.splice(headerInfo.rows),
        }
    }

    const firstRowIsEmpty = isEmptyHeaderRow(headerRow, headerInfo)
    return {
        startRow: headerInfo.rows,
        headerRow: firstRowIsEmpty ? rows[1] : headerRow,
        rows: firstRowIsEmpty ? rows.splice(headerInfo.rows) : rows,
    }
}

const filterEmptyRows = (rows, columns) => {
    return filter(rows, (row) => some(map(columns, (_, key) => has(row, key) && trim(row[key]))))
}

export const mapCustomDemographicOptions = ({ headerMapping, valuesMapping, filterMapping = false }) => {
    const mappedDemographicOptions = map(
        valuesMapping,
        (valuesForColumn, columnKey) => {
            const column = headerMapping[columnKey]
            if (!column.mapping?.hasDemographicOptions || (filterMapping && !column.isMappable)) {
                return
            }

            const {
                mapping: { demographicTypeId },
                originalText: columnName,
            } = column

            const demographicOptionsMapping = filter(
                map(valuesForColumn, ({ originalText, mapping }) => {
                    if (!mapping) {
                        return
                    }

                    const { id } = mapping
                    return { optionName: originalText, demographicId: id }
                })
            )

            return { columnName, demographicTypeId, demographicOptionsMapping }
        },
        (values) => values
    )

    return filter(mappedDemographicOptions, (values) => values)
}

export const mapData = ({ columns, startRow, headerMapping, rows, filterMapping = false }) => {
    return map(rows, (row, i) => {
        const data = reduce(
            headerMapping,
            (record, currentHeader, columnKey) => {
                const val = trim(row[columnKey])

                if (!val || (filterMapping && !currentHeader.isMappable)) {
                    return record
                }

                const { mapping, originalText } = currentHeader

                const attributeKey = mapping ? findKey(columns, (col) => col.header === mapping.header) : null

                if (attributeKey && !mapping.hasDemographicOptions) {
                    record[attributeKey] = val
                } else {
                    const additionalProperties = record?.additionalProperties ?? []
                    additionalProperties.push({ key: trim(originalText), value: val })
                    record.additionalProperties = additionalProperties
                }

                return record
            },
            {}
        )

        data.index = `${i + startRow}`

        // TODO: Fix Backend to work without additionalProperties, delete this after
        if (!data?.additionalProperties) {
            data.additionalProperties = []
        }

        return data
    })
}

export const getFieldErrorMessage = (hasError) => {
    return hasError ? 'Field cannot be assigned more than once' : null
}

export const hasColumnWithAllStandardValues = (deiDemographics, columnValues) => {
    if (isEmpty(columnValues)) {
        return false
    }

    const deiDemographicsValues = new Set(map(deiDemographics, (demographic) => toLower(trim(demographic.name))))

    return every(columnValues, (value) => deiDemographicsValues.has(toLower(trim(value.originalText))))
}
