import { Input } from '@energage/components'
import get from 'lodash/get'
import noop from 'lodash/noop'
import { useDebouncedCallback } from 'use-debounce'

export const RatingQuestion = ({ questionText, index, onChange = noop, errors, errorKey }) => {
    const error = get(errors, errorKey, null)
    const debouncedOnChange = useDebouncedCallback(onChange, 500)
    return (
        <div>
            <div className="paragraph text-grey500 text-sm flex flex-no-wrap w-full sm:w-1/2 max-w-md">
                <span className="pt-3">{'How would you rate '}</span>
                <div className="flex">
                    <Input
                        size="sm"
                        className="mx-2"
                        defaultValue={questionText}
                        onChange={(e) => {
                            e.persist()
                            debouncedOnChange(e, index)
                        }}
                        placeholder={'[SUBJECT]'}
                        error={!!error}
                        info={error}
                    />
                </div>
                <span className="pt-3">
                    {' from 0 - 99?'}
                    <span className="text-red500">{'*'}</span>
                </span>
            </div>
        </div>
    )
}
