import { useEffect, useMemo, useReducer } from 'react'
import { alert } from '@energage/components'
import { format, toDate, zonedTimeToUtc } from 'date-fns-tz'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import map from 'lodash/map'
import { useWorkplaceSurveyQuery } from 'api'
import { useAccordionGroup } from 'components/Accordion'
import config from 'config'
import { useFetchTimezones, usePostWithTaskCompletion } from 'hooks'
import {
    changeTimeZone,
    createInvitationDate,
    deleteInvitationDate,
    fetchDataSuccess,
    fetchTimeZonesSuccess,
    reducer,
    resetInvitationDates,
    setInvitationDate,
    submitSuccess,
    updateCloseDate,
    updateLaunchDateTime,
    updateStatus,
} from './scheduleReducer'

const formatDateForServer = (d) => format(d, 'yyyy-MM-dd')
export const MINIMUM_REQUIRED_INVITEES = 10

function useFetchInitialData(surveyEventId, dispatch) {
    const { data: invitationData, isLoading: fetchingInvitationData } = useWorkplaceSurveyQuery(
        ['invitation-data', surveyEventId],
        `invitation/${surveyEventId}`,
        {
            onSuccess: (data) => dispatch(fetchDataSuccess(data)),
        }
    )

    const { isFetching: fetchingTimeZones } = useFetchTimezones({
        onSuccess: (data) => dispatch(fetchTimeZonesSuccess(data)),
    })

    return { invitationData, fetchingInvitationData, fetchingTimeZones }
}

function useFetchInvitationSchedule(surveyEventId, launchDate, invitationDates, dispatch) {
    const launchDateString = launchDate ? formatDateForServer(launchDate) : null

    const { isLoading } = useWorkplaceSurveyQuery(
        ['invitation-schedule', launchDateString],
        `invitation/${surveyEventId}/schedule/${launchDateString}`,
        {
            enabled: Boolean(launchDate) && !has(invitationDates, [0, 'dateTime']),
            onSuccess: (data) => dispatch(resetInvitationDates(data)),
        }
    )

    return isLoading
}

function useSubmitInvitationDates(
    surveyEventId,
    invitationDates,
    scheduledClosedDateTime,
    timeZone,
    originalTimeZone,
    dispatch,
    goToNext
) {
    const { doPost, isPosting } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/invitation/${surveyEventId}/schedule`,
        onComplete: (awardListDeadlineDates) => {
            /*
                may not see the next accordion open because of hot module reloading
                refresh page to get it working correctly
             */
            dispatch(submitSuccess({ awardListDeadlineDates }))
            goToNext()
        },
        onError: (errors) => {
            forEach(errors, (error) => alert.danger(error.message))
        },
    })

    const submit = () => {
        const invitationDatesToSubmit = filter(invitationDates, (d) => d.dateTime)
        const requestBody = {
            surveyEventId,
            scheduledCloseDateTime: scheduledClosedDateTime
                ? zonedTimeToUtc(scheduledClosedDateTime.dateTime, timeZone.ianaKey).toISOString().replace(/\.000/, '')
                : null,
            timeZoneId: originalTimeZone.id !== timeZone.id ? timeZone.id : null,
            invitations: map(invitationDatesToSubmit, (d) => ({
                scheduledTime: zonedTimeToUtc(d.dateTime, timeZone.ianaKey).toISOString().replace(/\.000/, ''), //do we need this?
            })),
        }

        doPost(requestBody)
    }

    return { submit, isPosting }
}

function useScheduleSurveyLaunch(surveyEventId, isComplete) {
    const { goToNext } = useAccordionGroup()
    const [
        {
            submitDisabled,
            stepComplete,
            launchDate,
            invitationDates,
            scheduledCloseDateTime,
            timeZone,
            timeZones,
            surveyType,
            isRushed,
            awardListDeadlineDateTimes,
            lastAvailableSurveyDateTime,
            awardListDeadline,
            surveyCloseTimePickerDisabled,
        },
        dispatch,
    ] = useReducer(reducer, {
        submitDisabled: false,
        stepComplete: isComplete,
        launchDate: null,
        invitationDates: [],
        scheduledCloseDateTime: null,
        timeZone: null,
        timeZones: [],
        surveyType: 0,
        isTopWorkplaceParticipant: false,
    })

    useEffect(() => {
        dispatch(updateStatus(isComplete))
    }, [isComplete])

    const { invitationData, fetchingInvitationData, fetchingTimeZones } = useFetchInitialData(surveyEventId, dispatch)

    const {
        timeZone: originalTimeZone,
        earliestStartDateTime,
        latestStartDateTime,
        holidays,
        scheduledSurveys,
        isTopWorkplaceParticipant,
    } = invitationData ?? {}

    const fetchingInvitationSchedule = useFetchInvitationSchedule(surveyEventId, launchDate, invitationDates, dispatch)

    const { submit, isPosting } = useSubmitInvitationDates(
        surveyEventId,
        invitationDates,
        scheduledCloseDateTime,
        timeZone,
        originalTimeZone,
        dispatch,
        goToNext
    )

    const addInvitationDate = (index, newDate) => dispatch(createInvitationDate({ index, newDate }))
    const removeInvitationDate = (index) => dispatch(deleteInvitationDate({ index }))
    const updateInvitationDate = (index, newDate, isTimeChanged) =>
        dispatch(setInvitationDate({ index, newDate, isTimeChanged }))
    const updateTimeZone = (timeZone) => dispatch(changeTimeZone(timeZone))
    const updateScheduledCloseDateTime = (newDate, isTimeChanged) =>
        dispatch(updateCloseDate({ newDate, isTimeChanged }))
    const updateLaunchDate = (newDate, isTimeChanged) => dispatch(updateLaunchDateTime({ newDate, isTimeChanged }))

    const holidayDates = useMemo(() => map(holidays, (date) => toDate(date)), [holidays])

    const earliestStartDate = toDate(earliestStartDateTime)

    return {
        isStepComplete: stepComplete,
        loading: fetchingInvitationSchedule || fetchingInvitationData || fetchingTimeZones,
        surveyType,
        invitationDates,
        scheduledCloseDateTime: scheduledCloseDateTime,
        earliestStartDateTime: earliestStartDate,
        latestStartDateTime: toDate(latestStartDateTime),
        holidays: holidayDates,
        timeZone,
        timeZones,
        addInvitationDate,
        removeInvitationDate,
        updateInvitationDate,
        updateTimeZone,
        submit,
        updateScheduledCloseDateTime,
        updateLaunchDate,
        isSubmitDisabled: submitDisabled || isPosting,
        isSubmitting: isPosting,
        scheduledSurveys: map(scheduledSurveys, (survey) => ({
            id: survey.id,
            launchDateTime: toDate(survey.launchDateTime),
            name: survey.name,
            scheduledCloseDateTime: toDate(survey.scheduledCloseDateTime),
        })),
        isRushed,
        awardListDeadlineDateTimes,
        lastAvailableSurveyDateTime,
        awardListDeadline,
        surveyCloseTimePickerDisabled,
        isTopWorkplaceParticipant,
    }
}

export default useScheduleSurveyLaunch
