import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import { matchPath } from 'react-router-dom'
import { HISTORY_STACK } from 'constants/storageKeys'

function getInitialHistoryStack() {
    const savedHistory = window.sessionStorage.getItem(HISTORY_STACK)
    return savedHistory ? JSON.parse(savedHistory) : [{ destination: window.location.pathname }]
}

function getTitle(currentPath, allItems) {
    const subItemsForCurrentPath = find(allItems, (_, basePath) => matchPath(currentPath, { path: basePath }))
    return get(subItemsForCurrentPath, 'title')
}

export default {
    state: {
        history: getInitialHistoryStack(),
        itemsByPath: {},
    },
    reducers: {
        addHistory(state, { source, destination }) {
            const newStack = filter(state.history, (e) => e.destination !== destination)
            newStack.unshift({ source, destination })
            state.history = newStack
        },
        addItems(state, { path, title, items }) {
            state.itemsByPath[path] = { title, items }
        },
    },
    effects: {
        addHistory(payload, rootState) {
            window.sessionStorage.setItem(HISTORY_STACK, JSON.stringify(rootState.navigation.history))
        },
    },
    selectors: (slice, createSelector, hasProps) => ({
        selectBackPath: hasProps(function (models, url) {
            return slice((state) => {
                if (!url) {
                    return
                }

                return find(
                    state.history,
                    ({ source, destination }) =>
                        matchPath(destination, { path: url }) && !matchPath(source, { path: url })
                )?.source
            })
        }),
        selectNavigationItems: hasProps(function (models, currentPath) {
            return slice((state) => ({
                ...state.itemsByPath[currentPath],
                title: getTitle(currentPath, state.itemsByPath),
            }))
        }),
    }),
}
