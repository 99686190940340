import constant from 'lodash/constant'

export function accessor(object, options) {
    return function createAccessor(name) {
        object[name] = function (_) {
            if (arguments.length) {
                options[name] = _
                return object
            } else {
                return options[name]
            }
        }
    }
}

export function functorAccessor(object, options) {
    return function createFunctorAccessor(name) {
        object[name] = function (_) {
            if (arguments.length) {
                options[name] = functor(_)
                return object
            } else {
                return options[name]
            }
        }
    }

    function functor(value) {
        return typeof value === 'function' ? value : constant(value)
    }
}
