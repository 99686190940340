import { useCallback } from 'react'
import { alert } from '@energage/components'
import { useQueryClient } from 'react-query'
import { useWorkplaceSurveyQuery } from 'api'
import type { SurveyFormat } from './SurveyFormats'
import type { SurveyFormatData } from './useSurveyFormatData'

type OnSubmit = (variables: { surveyEventId: string | number; formatId: SurveyFormat }) => void

function useSubmitFormatSurvey(surveyEventId: number | string, onSubmit?: OnSubmit) {
    const queryClient = useQueryClient()

    const { mutateAsync, reset, isLoading } = useWorkplaceSurveyQuery.mutate<
        void,
        Array<{ message: string }>,
        { formatId: SurveyFormat; surveyEventId: string | number },
        { previous?: SurveyFormatData }
    >(`SurveyFormat/${surveyEventId}/Submit`, {
        onMutate: (variables) => {
            const data = queryClient.getQueryData<SurveyFormatData>(['survey-format', surveyEventId])

            if (data) {
                const updatedFormats = data.formats.map((format) => {
                    return {
                        ...format,
                        isSelected: format.id === variables.formatId,
                    }
                })

                const newData = {
                    ...data,
                    formats: updatedFormats,
                    selectedFormat: updatedFormats.find((format) => format.id === variables.formatId),
                }

                queryClient.setQueryData(['survey-format', surveyEventId], newData)
                return { previous: data }
            }
        },
        onSuccess: (_, variables) => {
            onSubmit?.(variables)
        },
        onError: (error, _, context) => {
            if (context?.previous) {
                queryClient.setQueryData(['survey-format', surveyEventId], context.previous)
            }
            alert.danger(`There was an error saving your survey format:\n${error[0].message}`)
            reset()
        },
        onSettled: () => queryClient.invalidateQueries(['survey-format', surveyEventId]),
    })

    const submit = useCallback(
        (formatId: SurveyFormat) => mutateAsync({ surveyEventId, formatId }),
        [mutateAsync, surveyEventId]
    )

    return { submit, isPosting: isLoading }
}

export default useSubmitFormatSurvey
