import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import styled from 'styled-components'

const Button = styled.button<{ background: string; backgroundHover: string; isCreateYoursBtn?: boolean }>`
    filter: drop-shadow(7px 4px 15px rgba(209, 26, 252, 0.2));
    padding: 10px 31px 10px 22px;
    background: ${(props) => (props.background ? `url(${props.background})` : '#404ddc')};
    background-repeat: no-repeat;
    border-bottom-left-radius: ${(props) => props.isCreateYoursBtn && '10px'};
    border-top-left-radius: ${(props) => props.isCreateYoursBtn && '10px'};
    border-top: ${(props) => (props.isCreateYoursBtn ? '2px solid rgba(255, 255, 255, 0.50)' : 'none')};
    border-bottom: ${(props) => (props.isCreateYoursBtn ? '2px solid rgba(255, 255, 255, 0.50)' : 'none')};
    border-left: ${(props) => (props.isCreateYoursBtn ? '2px solid rgba(255, 255, 255, 0.50)' : 'none')};

    &:hover {
        background: ${(props) => (props.backgroundHover ? `url(${props.backgroundHover})` : colors.blue500)};
        background-repeat: no-repeat;
    }

    :focus:not(:focus-visible) {
        outline: none;
    }

    @media (max-width: 768px) {
        margin-top: 30px;
    }
`

type CtaButtonProps = {
    label: string
    ariaLabel: string
    background: string
    backgroundHover: string
    icon: string
    isCreateYoursBtn?: boolean
    onClick?: () => void
}

export const CtaButton = ({
    label,
    ariaLabel,
    background,
    backgroundHover,
    icon,
    isCreateYoursBtn,
    onClick,
}: CtaButtonProps) => {
    return (
        <Button
            aria-label={ariaLabel}
            onClick={onClick}
            className="text-white font-bold"
            background={background}
            backgroundHover={backgroundHover}
            isCreateYoursBtn={isCreateYoursBtn}>
            {`${label}`}
            <Icon as={icon} size={isCreateYoursBtn ? 24 : 20} className="ml-2 mb-px" color={colors.white} />
        </Button>
    )
}
