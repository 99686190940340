import ReactDOM from 'react-dom'
import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import uniqueId from 'lodash/uniqueId'
import { Link } from 'react-router-dom'
import { IncompleteTaskNotification, TaskWorkFlowNotification } from 'components/TaskWorkflow'
import config from 'config'
import { routes } from 'constants/routes'
import { ReactComponent as BillingNotificationIcon } from 'images/billing-notification.svg'
import { monthDayYearDate } from 'util/formatters'
import * as Handlers from './Handlers'
import Notification from './Notification'
import { getProgramDeadlineNotifMessage } from './utils'

const render = (Component, props) => {
    const modalContainer = document.createElement('div')
    modalContainer.setAttribute('id', uniqueId('platform-notification-'))
    document.body.insertBefore(modalContainer, document.body.firstChild)
    ReactDOM.render(<Component {...props} />, modalContainer)
}

const BillingNotification = (props) => (
    <Notification {...props} icon={<Icon as={BillingNotificationIcon} size={28} color={colors.purple500} />} />
)

const events = {
    OrderExpiring: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Order Expiring"
                link={config.urls.peakCommerce}
                text={`The order you discussed with ${props.object.accountExecutive.name} will expire.`}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    OrderExpired: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Order Expired"
                text={`The order you discussed with ${props.object.accountExecutive.name} has expired. Please call ${props.object.accountExecutive.phone} to place your order`}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    OrderPlaced: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Order Placed"
                link={config.urls.peakCommerce}
                text={'Your order was placed'}
            />
        ),
        link: config.urls.peakCommerce,
    },
    OrderPublished: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Order Published"
                link={config.urls.peakCommerce}
                text={'Your order page is ready'}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    Subscription45DayLapse: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Subscription lapse (45 days)"
                link={config.urls.peakCommerce}
                text={`Your subscription will automatically renew in 45 days on ${monthDayYearDate(
                    props.object.expirationDate
                )}`}
            />
        ),
    },
    Subscription60DayLapse: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Subscription lapse (60 days)"
                link={config.urls.peakCommerce}
                text={`Your subscription will automatically renew in 60 days on ${monthDayYearDate(
                    props.object.expirationDate
                )}`}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    Subscription90DayLapse: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Subscription lapse (90 days)"
                link={config.urls.peakCommerce}
                text={`Your subscription will automatically renew in 90 days on ${monthDayYearDate(
                    props.object.expirationDate
                )}`}
            />
        ),
    },
    TaskWorkflow: {
        render: (props) => <TaskWorkFlowNotification {...props} />,
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    ProductDelivered: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Product Delivered"
                link={config.urls.peakCommerce}
                text={`${props.object.surveyName} results are available to view in the ${props.object.productName} product`}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    AwardNominationInApp: {
        render: (props) => (
            <Notification
                {...props}
                title="Award Nomination"
                link={routes.employerRecognition.award()}
                text={() => (
                    <>
                        {`Congratulations, you've been entered for the `}
                        <b>{props.object.programName}</b>
                        {` award. `}
                        <Link to={routes.employerRecognition.award()}>{'Manage entries here >>'}</Link>
                    </>
                )}
            />
        ),
        handler: (props) => render(Handlers[props.message.objectType], props),
    },
    AwardOutcome: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Award Notification"
                link={routes.awardNotification()}
                text={`Your Top Workplaces Award Outcome is Available`}
            />
        ),
    },
    SubscriptionNonAutoRenew45Day: {
        render: (props) => (
            <BillingNotification
                {...props}
                title="Subscription Expiration (45 days)"
                link={config.urls.peakCommerce}
                text={`Your subscription is set to expire in 45 days on ${monthDayYearDate(
                    props.object.expirationDate
                )} at which time you will no longer have access to the Energage platform`}
            />
        ),
    },
    ProgramDeadlineNotification: {
        render: (props) => {
            const { text, link } = getProgramDeadlineNotifMessage(props.object.programDeadlineNotificationTypeId)
            const message = (
                <>
                    {text}
                    <Link
                        to={routes.employerRecognition()}
                        variant="link"
                        className="p-0 font-bold text-xs text-primary-dark hover:text-blue500">
                        {link}
                    </Link>
                </>
            )

            return <Notification {...props} text={() => message} />
        },
    },
    IncompleteTask: {
        render: (props) => <IncompleteTaskNotification {...props} />,
    },
}

export default events
