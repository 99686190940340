import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { convertPermissions } from 'constants/permission'
import type { PermissionValues } from 'constants/permission'

const FALLBACK_INITIAL = 'U'

function getFullName(firstName: string, lastName: string) {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    }
    return firstName
}

function getInitials(identity: { firstName: string; lastName: string; emailAddress: string }) {
    const firstAndLast = compact([identity.firstName, identity.lastName])
    if (isEmpty(firstAndLast)) {
        return (identity.emailAddress ?? FALLBACK_INITIAL).slice(0, 1).toUpperCase()
    }
    return map(firstAndLast, (s) => s.slice(0, 1).toUpperCase()).join('')
}

type CompanyModel = {
    isPublisher: boolean
}

export type IdentityModel = {
    loginId: string
    firstName: string
    lastName: string
    emailAddress: string
    departmentId: string
    organizationId: string
    organizationName: string
    surveyCompanyId: number
    salesforceAccountId: string
    zuoraAccountId: string
    isSuperUser: boolean
    isImpersonating: boolean
    employerBrandingSurveyEventId: number
    authorizationContext?: {
        permissions: Record<string, PermissionValues>
    }
    surveyUserId: number
    isTopLevelUser: boolean
    userTitle: string
    hasUserManagementPermission: boolean
    isManager: boolean
    isActionTrackingOnlyUser: boolean
    isSurveyEventExists: boolean
    isEmployerBrandingOnly: boolean
    company: CompanyModel
}

const Identity = (identity: IdentityModel) => ({
    ...identity,
    initials: getInitials(identity),
    fullName: getFullName(identity.firstName, identity.lastName),
    permissions: convertPermissions(identity.authorizationContext?.permissions ?? {}),
})

export default Identity
