import type { ReactNode } from 'react'
import { Sheet } from '@energage/overlays'

type ShareBottomSheetProps = {
    title: string
    isOpen: boolean
    onDismiss: () => void
    children: ReactNode
}

export const ShareBottomSheet = ({ title, isOpen, onDismiss, children }: ShareBottomSheetProps) => {
    return (
        <Sheet title={title} isOpen={isOpen} onDismiss={onDismiss} className="bg-grey200">
            <div className="overflow-auto bg-grey200 h-full flex justify-center">
                <div className="w-full max-w-md m-auto">{children}</div>
            </div>
        </Sheet>
    )
}
