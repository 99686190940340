import { Fragment } from 'react'
import cx from 'clsx'
import map from 'lodash/map'
import type { TimelineListType, TimelineSectionRevamp } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import celebrationWhatsNext from 'images/awardAnnouncement/celebrationWhatsNext.svg'
import {
    Confetti,
    LeftBorder,
    LeftBorderPdf,
    PointBorder,
    PointBorderPdf,
    StyledTimeLineContent,
    TimelineHeading,
} from './StyledElements'
import timelineConfig from './timelineConfig'

const TimelineContent = ({ isPdf, celebrationImg, heading, subtitle, text, lessThanSix }: TimelineListType) => {
    const Heading = isPdf ? 'h3' : TimelineHeading

    return (
        <StyledTimeLineContent
            className={cx('relative', { 'pt-1 sm:pb-5 sm:pb-0 sm:pt-10': !isPdf, 'pt-10': isPdf })}
            lessThanSix={lessThanSix}
            isPdf={isPdf}>
            {celebrationImg && (
                <Confetti lessThanSix={lessThanSix} src={celebrationWhatsNext} alt={'Celebration Img Whats Next'} />
            )}
            <Heading className={cx('font-bold', { 'text-2xl': !isPdf, 'text-base': isPdf })}>{heading}</Heading>
            <p
                className={cx('text-yellow500 font-bold', {
                    'text-sm mt-1 py-2': isPdf,
                    'text-base sm:mt-2 sm:py-2 sm:mt-0': !isPdf,
                })}>
                {subtitle}
            </p>
            {text && (
                <p
                    className={cx({
                        'mt-2 text-sm': isPdf,
                    })}>
                    {text}
                </p>
            )}
        </StyledTimeLineContent>
    )
}

export const Timeline = ({
    publisherId,
    companyName,
    timeline,
    isPdf,
    publisherName,
    hasPublisherProvidedMediaKit,
}: TimelineSectionRevamp) => {
    const timelineList = timelineConfig(publisherId, companyName, publisherName, hasPublisherProvidedMediaKit, timeline)
    const lessThanSix = timelineList.length < 6
    const BorderComponent = isPdf ? PointBorderPdf : PointBorder
    const LeftBoderComponent = isPdf ? LeftBorderPdf : LeftBorder
    return (
        <div
            className={cx('pt-0', {
                'flex flex-wrap justify-center': isPdf,
                'pt-0 sm:flex sm:flex-wrap sm:justify-center px-5': !isPdf,
            })}>
            {map(timelineList, ({ heading, subtitle, text, celebrationImg }: TimelineListType, index: number) => (
                <Fragment key={index}>
                    <div
                        className={cx('self-start text-left', {
                            'flex flex-row pb-10': isPdf,
                            'sm:flex sm:flex-row sm:pb-10': !isPdf,
                        })}>
                        <div>
                            <div className="border-solid border-grey300 w-full relative border-2 border-t-0 border-b-0">
                                <div className="grid rounded-full h-10 w-10 bg-yellow500 px-3 px-0 content-center absolute">
                                    <span className="font-bold text-xl flex justify-center items-center">
                                        {index + 1}
                                    </span>
                                </div>
                            </div>
                            <BorderComponent
                                className="-ml-5 sm:ml-0"
                                lessThanSix={lessThanSix}
                                isLast={index + 1 === timelineList.length}
                                isPdf={isPdf}>
                                <LeftBoderComponent>
                                    <TimelineContent
                                        isPdf={isPdf}
                                        heading={heading}
                                        subtitle={subtitle}
                                        text={text}
                                        celebrationImg={celebrationImg}
                                        lessThanSix={lessThanSix}
                                    />
                                </LeftBoderComponent>
                            </BorderComponent>
                        </div>
                    </div>
                    {index === 2 && isPdf && <br />}
                </Fragment>
            ))}
        </div>
    )
}
