import { AwardHistoryColumns } from 'containers/Main/EmployerRecognition/constants'

export const tableColumns = [
    {
        header: 'Top Workplace Award',
        name: AwardHistoryColumns.TwpAward,
        className: 'w-1/4 hidden sm:flex flex-grow topWorkplaceName',
    },
    {
        header: 'Region or Category',
        name: AwardHistoryColumns.RegionOrCategory,
        className: 'w-5/12 sm:w-1/5 md:1/4 flex flex-grow regionCategory',
    },
    {
        header: 'Survey',
        name: AwardHistoryColumns.Survey,
        className: 'w-1/3 sm:w-1/5 hidden sm:flex flex-grow',
    },
    {
        header: 'Year',
        name: AwardHistoryColumns.Year,
        className: 'flex year',
    },
    {
        header: 'Outcome',
        name: AwardHistoryColumns.Outcome,
        className: 'w-32 sm:w-48 flex',
    },
]
