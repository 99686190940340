import { useState } from 'react'
import type { ChangeEvent } from 'react'
import { Loading as Spinner } from '@energage/components'
import { Text } from '@energage/typography'
import cx from 'clsx'
import filter from 'lodash/filter'
import map from 'lodash/map'
import size from 'lodash/size'
import styled from 'styled-components'
import FancyCheckbox from 'components/FancyCheckbox'
import Jiggle from 'components/Jiggle'
import { media } from 'style/breakpoints'
import { MINIMUM_DROPLETS_REQUIRED } from '../utils'
import type { DataProps, DropletProps } from './CultureCloud.types'

const CultureCloudWordGrid = styled.div.attrs({ className: 'flex flex-wrap mt-4' })`
    label {
        overflow-wrap: break-word;
        width: 50%;
        margin-bottom: 0.5rem;
    }

    input {
        vertical-align: top;
        margin-top: 3px;
    }

    ${media.smMin`
        label {
            width: 25%;
        }
    `};

    ${media.mdMin`
        label {
            width: 50%;
        }
    `};

    ${media.lgMin`
        label {
            width: 33%;
        }
    `};

    @media screen and (min-width: 1280px) {
        label {
            width: 25%;
        }
    }
`

interface CloudWordProps {
    dataId: string
    data: DataProps
    onChange: (droplets: DataProps) => void
    isLoading: boolean
    hasEmployerBrandingSubscription: boolean
}

const CultureCloudWordCheckboxes = ({
    data,
    onChange,
    isLoading,
    dataId,
    hasEmployerBrandingSubscription,
}: CloudWordProps) => {
    const [error, setError] = useState(false)
    const selectedDropletsCount = size(filter(data?.droplets, 'isSelected'))
    const wordThreshold = data?.cultureCloudWordThreshold || MINIMUM_DROPLETS_REQUIRED
    const countDroplets = size(data?.droplets) >= wordThreshold

    const setDropletSelected = (droplet: DropletProps) => {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const newDroplets = map(data?.droplets, (d) => {
                if (d.text === droplet.text) {
                    return {
                        ...d,
                        isSelected: e.target.checked,
                    }
                }
                return d
            })

            const selectedCount = size(filter(newDroplets, 'isSelected'))
            if (selectedCount < wordThreshold || selectedCount > 20) {
                setError(true)
                setTimeout(() => setError(false), 250)
                return
            }
            onChange({ ...data, droplets: newDroplets })
        }
    }

    if (!hasEmployerBrandingSubscription) {
        return <h2 className="text-center p-12">{'Unlock Employer Branding to get access to your data!'}</h2>
    }

    if (!countDroplets) {
        return ''
    }

    return (
        <>
            <Text textStyle="default">
                <h5>
                    {'Select Words to Display'}
                    <span className="text-grey400">{`(${selectedDropletsCount} selected)`}</span>
                </h5>
            </Text>
            {/* @ts-ignore */}
            <Jiggle active={error}>
                <span className={cx({ 'text-red500': error }, 'mb-3 text-sm')}>
                    {`You can select between ${wordThreshold} and 20 words to display in your word cloud`}
                </span>
            </Jiggle>
            {isLoading ? (
                <Spinner />
            ) : (
                <CultureCloudWordGrid>
                    {map(data?.droplets, (droplet) => (
                        <label key={droplet.text} data-event-id={dataId && `${dataId}-word-selection`}>
                            {/* @ts-ignore */}
                            <FancyCheckbox
                                checked={droplet.isSelected}
                                className="mr-2"
                                onChange={setDropletSelected(droplet)}
                            />
                            <span className="text-sm">{droplet.text}</span>
                        </label>
                    ))}
                </CultureCloudWordGrid>
            )}
        </>
    )
}

export default CultureCloudWordCheckboxes
