import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { COLOR_PICKER_HEIGHT, COLOR_PICKER_PRESETS } from 'containers/Main/EmployerBranding/CultureFacts/constants.ts'
import { useVisibility } from 'hooks'

const popDirections = {
    up: 'up',
    down: 'down',
}

const HexInputContainer = styled.div.attrs((props) => ({
    className: cx(props.className, props.disabled ? 'pointer-events-none' : 'pointer-events-auto'),
}))`
    ${(props) => (props.containerWidth ? `width: ${props.containerWidth};` : '')}
`

const StyledColorPicker = styled(SketchPicker).attrs({ presetColors: COLOR_PICKER_PRESETS })`
    > div {
        cursor: crosshair;
    }
`

const ColorPickerContainer = styled.div.attrs({ className: 'absolute z-10' })`
    ${(props) => props.direction === popDirections.up && `top: -${COLOR_PICKER_HEIGHT}px;`}
`

const ColorPickerInput = styled.input`
    font-size: 14px;
    width: 80px;
`

const ColorPickerLabel = styled.label.attrs((props) => ({
    className: cx(
        `h-10 relative overflow-hidden inline-flex items-center block`,
        props.labelClassName ? props.labelClassName : 'rounded',
        props.hideColorInput ? '' : 'border'
    ),
}))`
    height: ${(props) => (props.labelHeight ? props.labelHeight : '36px')};
    width: ${(props) => (props.labelWidth ? props.labelWidth : '123px')};
    button {
        outline: none;
        box-shadow: none;
    }
`

const ColorPickerSquare = styled.div`
    background-color: #${(props) => props.color};
    ${(props) => (props.squareBorder ? `border: 1px ${props.squareBorder} solid;` : '')};
    height: ${(props) => (props.squareHeight ? props.squareHeight : '41px')};
    width: ${(props) => (props.squareWidth ? props.squareWidth : '40px')};
`

const useClickOutside = (handler) => {
    const domNode = useRef()

    useEffect(() => {
        const maybeHandler = (event) => {
            if (domNode.current && !domNode.current.contains(event.target)) {
                handler()
            }
        }

        document.addEventListener('mousedown', maybeHandler)
        document.addEventListener('touchstart', maybeHandler)

        return () => {
            document.removeEventListener('mousedown', maybeHandler)
            document.removeEventListener('touchstart', maybeHandler)
        }
    }, [handler])

    return domNode
}

const HexInput = ({
    className,
    containerWidth,
    label,
    color,
    setColor,
    hexValid,
    inputProps,
    dataId,
    disabled,
    direction,
    hideColorInput,
    labelBottom,
    labelClassName,
    labelFontSizeClass,
    labelHeight,
    labelWidth,
    squareClassName,
    squareBorder,
    squareHeight,
    squareWidth,
}) => {
    const { visible, show, hide } = useVisibility()
    const pickerRef = useClickOutside(hide)

    const onChange = (e) => {
        setColor({ hex: e.target.value })
    }

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === 'Escape' && visible) {
                hide()
            }
        }

        document.addEventListener('keydown', onKeyDown)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [hide, visible])

    return (
        <HexInputContainer className={className} containerWidth={containerWidth} disabled={disabled}>
            {!labelBottom && <p className={cx({ 'text-red500': !hexValid }, labelFontSizeClass, 'mb-1')}>{label}</p>}
            <div className="relative">
                <ColorPickerLabel
                    aria-label={`${dataId} color picker`}
                    hideColorInput={hideColorInput}
                    id={`${dataId}-picker-label`}
                    labelClassName={labelClassName}
                    labelHeight={labelHeight}
                    labelWidth={labelWidth}
                    squareHeight={squareHeight}
                    squareWidth={squareWidth}>
                    <button onClick={show}>
                        <ColorPickerSquare
                            className={cx(squareClassName, 'inline-block cursor-pointer', {
                                rounded: !squareClassName,
                            })}
                            color={color}
                            squareHeight={squareHeight}
                            squareWidth={squareWidth}
                            squareBorder={squareBorder}
                        />
                    </button>
                    {!hideColorInput && (
                        <button onFocus={show} onChange={onChange}>
                            <ColorPickerInput
                                aria-labelledby={`${dataId}-picker-label`}
                                className="text-center text-grey500 px-1 outline-none uppercase"
                                value={`#${color}`}
                                {...inputProps}
                                data-event-id={dataId && `${dataId}-hex-code`}
                            />
                        </button>
                    )}
                </ColorPickerLabel>
                {!hexValid && <div className="text-red500 text-xs">{'Please provide a valid color'}</div>}
                {visible && (
                    <ColorPickerContainer direction={direction} ref={pickerRef}>
                        <div data-event-id={dataId && `${dataId}-color-picker`}>
                            <StyledColorPicker color={color} onChangeComplete={setColor} disableAlpha />
                        </div>
                    </ColorPickerContainer>
                )}
            </div>
            {labelBottom && <p className={cx({ 'text-red500': !hexValid }, labelFontSizeClass, 'mt-1')}>{label}</p>}
        </HexInputContainer>
    )
}

HexInput.propTypes = {
    containerWidth: PropTypes.string,
    label: PropTypes.string,
    labelBottom: PropTypes.bool,
    labelClassName: PropTypes.string,
    labelFontSizeClass: PropTypes.string,
    squareHeight: PropTypes.string,
    squareWidth: PropTypes.string,
    color: PropTypes.string.isRequired,
    setColor: PropTypes.func.isRequired,
    hideColorInput: PropTypes.bool,
    inputProps: PropTypes.object,
    dataId: PropTypes.string,
    direction: PropTypes.oneOf([popDirections.up, popDirections.down]),
}

HexInput.defaultProps = {
    label: 'Customize Background Color',
    direction: popDirections.down,
}

export default HexInput
