import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Toggle } from '@energage/components'
import { useToggle } from '@energage/hooks'
import cx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { QuestionType } from '../../../../StatementSetup/CustomQuestions/QuestionType'
import QuestionTableContext from '../../../../StatementSetup/QuestionTable/QuestionTableContext'
import QuestionTypeAnswerFormat, {
    StatementTitle,
} from '../../../../StatementSetup/QuestionTable/QuestionTypeAnswerFormat'
import { QuestionTypeAnswerFormats } from '../../../../StatementSetup/QuestionTable/QuestionTypeAnswerFormats'
import { TableCell, TableRow } from '../../../../StatementSetup/QuestionTable/TableElements'
import { useCoreQuestion, useCoreQuestions } from '../../../../StatementSetup/statementSetupHooks'

const ConditionDescription = ({ description }) => {
    return <div className="text-grey600 italic pt-2 pl-2">{description}</div>
}

const QuestionCell = ({ questionId, questionText, name, disableSelection }) => (
    <>
        <div className="flex w-full">
            <div className="w-1/4 mr-2 pt-2 md:pb-1 pl-2 break-words">{name}</div>
            <div className="w-3/4">
                <label htmlFor={questionId} className={cx('pt-2 md:pb-1 md:pr-1', disableSelection && 'text-grey500')}>
                    {questionText}
                </label>
            </div>
        </div>
    </>
)

const QuestionTableRow = ({ questionId, disabled, handleToggleRequiredQuestion }) => {
    const toggleQuestion = useContext(QuestionTableContext)
    const [visible, toggle] = useToggle(false)

    const [question] = useCoreQuestion(questionId)
    const {
        text,
        name,
        isPositivelyPhrased,
        isRequired,
        conditionalQuestionEssenceIds,
        targetGroupDescription,
        negativeConditionalText,
        positiveConditionalText,
        answerOptions,
        questionTypeId,
    } = question

    const [potentialParents] = useCoreQuestions(conditionalQuestionEssenceIds)
    const parentSelected = every(potentialParents, (parentQuestion) => parentQuestion?.isSelected)

    const handleCheckboxChange = useCallback(
        (e) => {
            toggleQuestion(questionId, e.target.checked)
        },
        [questionId, toggleQuestion]
    )

    const handleToggle = useCallback(
        (e) => {
            handleToggleRequiredQuestion(questionId, e.target.checked)
        },
        [questionId, handleToggleRequiredQuestion]
    )

    const isConditional = conditionalQuestionEssenceIds.length > 0
    const disableSelection = isConditional && !parentSelected

    const conditionDescription =
        targetGroupDescription &&
        `This question will only be asked to employees that identify as a ${targetGroupDescription}.`

    const hasConditionals = !!(positiveConditionalText || negativeConditionalText)
    const formatName =
        questionTypeId === QuestionType.CustomScale && answerOptions
            ? `${answerOptions.length}pt Scale`
            : QuestionTypeAnswerFormats[questionTypeId]?.name || '-'

    const title =
        hasConditionals && questionTypeId === QuestionType.Agreement
            ? 'Statement with Conditional Questions'
            : formatName

    const showOnlyTitle =
        questionTypeId !== QuestionType.Agreement &&
        isEmpty(answerOptions) &&
        !QuestionTypeAnswerFormats[questionTypeId]?.description

    return (
        <TableRow>
            <div className="flex flex-wrap md:flex-no-wrap">
                <TableCell spaced={disabled} alignTop className="w-full md:w-1/2">
                    <div className="w-full flex flex-no-wrap pl-4">
                        <div className="pt-1 md:pb-1">
                            <Checkbox
                                className={disabled || disableSelection ? 'disabled' : ''}
                                id={questionId}
                                checked={question.isSelected}
                                disabled={disabled || disableSelection}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className="pl-2 mr-1 md:mr-0 md:pr-4 w-full">
                            <QuestionCell
                                questionId={questionId}
                                name={name}
                                questionText={text}
                                disableSelection={disableSelection}
                            />
                            {conditionDescription && <ConditionDescription description={conditionDescription} />}
                        </div>
                    </div>
                </TableCell>
                <TableCell
                    alignTop
                    className={cx(
                        'w-full md:w-1/2 ml-7 md:ml-0 md:pb-1 pt-2 flex pr-10 md:pr-0',
                        disableSelection && 'text-grey500',
                        {
                            'pl-2 md:pl-0': disabled,
                        }
                    )}>
                    {showOnlyTitle ? (
                        <div className="pl-3 md:pl-2 pt-1">
                            <span>{'Answer Format: '}</span>
                            {title}
                        </div>
                    ) : (
                        <div className="w-full pl-3 md:pl-3">
                            <StatementTitle open={visible} onToggle={toggle} title={title} />
                        </div>
                    )}
                </TableCell>
                <TableCell alignTop className={cx('w-full md:w-1/12 ml-7 md:ml-0 md:pb-1 pt-2 flex pr-8 justify-end')}>
                    <Toggle id={`${questionId}-required-toggle`} checked={isRequired} onChange={handleToggle} />
                </TableCell>
            </div>
            <AnimatePresence initial="false">
                {visible ? (
                    <motion.div
                        animate="open"
                        initial="collapsed"
                        exit="collapsed"
                        variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0 },
                        }}
                        className="clearfix overflow-hidden md:w-11/12 w-full">
                        <QuestionTypeAnswerFormat
                            questionId={questionId}
                            questionTypeId={questionTypeId}
                            options={answerOptions}
                            positiveConditionalText={positiveConditionalText}
                            negativeConditionalText={negativeConditionalText}
                            isPositivelyPhrased={isPositivelyPhrased}
                        />
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </TableRow>
    )
}

QuestionTableRow.propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool.isRequired,
    handleToggleRequiredQuestion: PropTypes.func,
}

export default QuestionTableRow
