import type { ReactNode } from 'react'
import { Close } from '@energage/icons'
import { colors } from '@energage/theme'
import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import type { DialogStateReturn } from 'reakit/Dialog'
import styled from 'styled-components'

type LightboxProps = {
    children: ReactNode
    dialogState: DialogStateReturn
}

const Backdrop = styled(DialogBackdrop)`
    background-color: #333;
`

// This removes the X from the height equation
const Content = styled.div`
    height: calc(100% - 72px);
`

export const Lightbox = ({ children, dialogState }: LightboxProps) => {
    return (
        <Backdrop className="reakit-modal__backdrop flex flex-col items-center" {...dialogState}>
            <Dialog className="h-full w-5/6" {...dialogState} aria-label="Open">
                <button
                    className="m-4 float-right"
                    onClick={dialogState.hide}
                    aria-label="Close"
                    type="button"
                    data-event-id={`close-lightbox`}>
                    <Close color={colors.white} size={40} />
                </button>
                <Content>{children}</Content>
            </Dialog>
        </Backdrop>
    )
}
