import { Skeleton } from '@energage/components'
import { parse } from 'query-string'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import { SsoCheckModal } from 'components/Modals/SsoCheckModal'
import { TopNav } from 'components/TopNav'
import { useGetSecuritySettingsStatus } from 'containers/Main/Manage/Users/useUserManagementQueries'
import { MfaSettings } from './MfaSettings'
import { SsoSettings } from './SsoSettings'

const SecurityContainer = styled.div.attrs({ className: 'px-6' })`
    max-width: 780px;
`

const SecuritySettingsSkeleton = () => (
    <div className="flex flex-col gap-1">
        <Skeleton.SingleLineShape className="w-1/3 h-7" />
        <Skeleton.RectangularShape className="w-full h-40" />
        <Skeleton.SingleLineShape className="w-1/3 h-7" />
        <Skeleton.RectangularShape className="w-full h-40" />
    </div>
)

export const Security = () => {
    const { search } = useLocation()
    const { sso_check } = parse(search)

    const { surveyCompanyId } = useIdentity()
    const { data: securityStatus, isFetching: isFetchingSecurityStatus } = useGetSecuritySettingsStatus(surveyCompanyId)

    return (
        <div data-testid="security-management">
            {sso_check && <SsoCheckModal />}
            <TopNav
                xsHidden
                title="Security"
                showBackArrow={false}
                arrowBackRoute={undefined}
                className=""
                primaryAction={undefined}
                hideShadow={undefined}>
                <SecurityContainer>
                    <div className="pb-8 pr-4">
                        <p className="h4">{'Configure Security Settings'}</p>
                        <p className="pb-1 pt-2 text-primary-dark">{'Configure authentication settings'}</p>
                    </div>
                    {isFetchingSecurityStatus ? (
                        <SecuritySettingsSkeleton />
                    ) : (
                        <>
                            <SsoSettings
                                ssoEnabled={securityStatus?.isSsoEnabled ?? false}
                                mfaEnabled={securityStatus?.isMfaEnabled ?? false}
                            />
                            <MfaSettings
                                ssoEnabled={securityStatus?.isSsoEnabled ?? false}
                                mfaEnabled={securityStatus?.isMfaEnabled ?? false}
                            />
                        </>
                    )}
                </SecurityContainer>
            </TopNav>
        </div>
    )
}
