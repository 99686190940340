import PropTypes from 'prop-types'
import { Loading } from '@energage/components'
import { Redirect } from 'react-router-dom'
import { getFirstIncompletePage } from './taskStatus'

const GoToNextSetupStep = ({ taskStatuses, match, workflowTasks }) => {
    const { surveyEventId } = match.params
    if (!taskStatuses || taskStatuses.length === 0) {
        return <Loading />
    }

    const nextStep = getFirstIncompletePage(workflowTasks, taskStatuses)
    if (!nextStep) {
        return null
    }

    return <Redirect to={nextStep.route({ surveyEventId })} />
}

GoToNextSetupStep.propTypes = {
    taskStatuses: PropTypes.object,
}

export default GoToNextSetupStep
