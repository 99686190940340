import type { WhatsNextAspiringSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import withClassName from 'style/util/withClassName'
import { Section } from '../Section'

const ListElement = withClassName('my-8 list-inside leading-8', 'li', 'li')

export const WhatsNext = ({ companyName, bgColor }: WhatsNextAspiringSection) => (
    <Section bgColor={bgColor} textColor={''}>
        <h2 className="sm:text-5xl text-2xl font-black">{"What's next?"}</h2>
        <h3 className="sm:text-2xl text-lg py-4">
            {
                "While this isn't the outcome you had hoped for, there are two things you can do to stand out as a company that aspires to be a Top Workplace, including:"
            }
        </h3>
        <ol className="sm:text-2xl text-lg mb-8 pl-0">
            <ListElement>
                <span className="font-bold sm:text-2xl text-lg">{'Promote the strengths of your culture. '}</span>
                <span className="sm:text-2xl text-lg">{`Use your survey feedback to highlight what makes ${companyName} unique. Employer Branding is a tool that makes this easy to do.`}</span>
            </ListElement>
            <ListElement>
                <span className="font-bold sm:text-2xl text-lg">
                    {'See where to make the biggest impact on your culture. '}
                </span>
                <span className="sm:text-2xl text-lg">
                    {
                        'Energage Insights shows you where your culture excels – and where you should focus your attention on improvement. Reach out to your account executive to see a demo.'
                    }
                </span>
            </ListElement>
        </ol>
    </Section>
)
