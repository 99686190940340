import { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import split from 'lodash/split'
import trim from 'lodash/trim'
import config from 'config'
import { EnergageTag } from 'constants/tags'
import { useFetch, useFetchPaged } from 'hooks'
import EmployeeContext from './EmployeeContext'

const getTagByTagType = (tags, energageTag) => find(tags, (t) => isEqual(t.energageTag, energageTag))

export const buildMemberWithName = (member) => {
    const { firstName, lastName, emailAddress } = member
    const fullName = trim(`${firstName || ''} ${lastName || ''}`)
    const [emailFirstPart] = split(emailAddress, '@')
    return {
        ...member,
        name: fullName || emailFirstPart,
    }
}

function useFetchEmployeeData() {
    const [tags, setTags] = useState(null)
    const [members, setMembers] = useState(null)
    const {
        data: organization,
        isLoading: loadingOrganization,
        error: fetchOrganizationError,
    } = useFetch(`${config.api.platform}/organization`)
    const organizationId = organization?.id ?? ''

    const {
        isLoading: loadingTags,
        error: fetchTagsError,
        doFetch: fetchTags,
    } = useFetchPaged({
        url: `${config.api.organization}/Organizations/${organizationId}/Members/Tags`,
        onComplete: setTags,
    })

    const handleUpdateMembers = (membersList) => setMembers(map(membersList, buildMemberWithName))

    const {
        isLoading: loadingMembers,
        error: fetchMembersError,
        hasNextPage: hasMoreMembers,
        fetchMore: fetchMoreMembers,
        doFetch: fetchMembers,
    } = useFetchPaged({
        url: `${config.api.organization}/Organizations/${organizationId}/Members`,
        onComplete: handleUpdateMembers,
    })

    useEffect(() => {
        if (!organizationId) {
            return
        }
        fetchMembers()
        fetchTags()
    }, [organizationId, fetchMembers, fetchTags])

    return useMemo(
        () => ({
            organizationId,
            members: members ?? [],
            tags: tags ?? [],
            departmentTag: getTagByTagType(tags, EnergageTag.Department),
            supervisorTag: getTagByTagType(tags, EnergageTag.Supervisor),
            updateTags: setTags,
            updateMembers: handleUpdateMembers,
            locationNameTag: getTagByTagType(tags, EnergageTag.LocationName),
            refetchMembers: fetchMembers,
            refetchTags: fetchTags,
            isLoading: loadingOrganization || loadingTags || loadingMembers || !members || !tags,
            error: fetchOrganizationError || fetchTagsError || fetchMembersError,
            hasMoreMembers,
            fetchMoreMembers,
            organization,
        }),
        [
            organizationId,
            members,
            tags,
            fetchMembers,
            fetchTags,
            loadingOrganization,
            loadingTags,
            loadingMembers,
            fetchOrganizationError,
            fetchTagsError,
            fetchMembersError,
            hasMoreMembers,
            fetchMoreMembers,
            organization,
        ]
    )
}

function useEmployeeData() {
    return useContext(EmployeeContext)
}

const EmployeeDataProvider = ({ children }) => {
    const data = useFetchEmployeeData()
    return <EmployeeContext.Provider value={data}>{children}</EmployeeContext.Provider>
}

EmployeeDataProvider.propTypes = {
    children: PropTypes.node,
}

export { EmployeeDataProvider, useEmployeeData, getTagByTagType }
