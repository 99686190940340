import { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup } from '@energage/components'
import find from 'lodash/filter'
import { withRouter } from 'react-router-dom'
import { Accordion, useAccordionGroup } from 'components/Accordion'
import { useIdentity } from 'components/Identity'
import config from 'config'
import SurveyType from 'constants/SurveyType'
import { useLazyFetch, usePostWithTaskCompletion } from 'hooks'
import SectionTitle from '../SectionTitle'
import TemplateLink from './TemplateLink'

const HeadsUpEmail = ({ id, match, readOnly, surveyTypeId, isComplete }) => {
    const { surveyCompanyId, organizationName } = useIdentity()
    const surveyEventId = match.params.surveyEventId
    const [isStepComplete, setStepComplete] = useState(isComplete)
    const [primaryContact, setPrimaryContact] = useState({ name: '', title: '' })
    const { goToNext } = useAccordionGroup()

    useEffect(() => setStepComplete(isComplete), [isComplete])

    const { doPost, isPosting } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/305/CompleteSurveyTask`,
        onComplete: () => {
            setStepComplete(true)
            goToNext()
        },
    })

    const {
        data: headsUpEmailInfo,
        doFetch: doFetchHeadsUpEmailInfo,
        isLoading,
    } = useLazyFetch(`${config.api.workplaceSurvey}/headsupemail/${surveyEventId}`)

    const { doFetch: doFetchOrgContacts } = useLazyFetch({
        url: `${config.api.platform}/organization/contacts`,
        onComplete: (contacts) => {
            if (contacts?.length > 0) {
                let primaryContact = find(contacts, (c) => c.contactRole.id === 1)
                if (primaryContact != null) {
                    setPrimaryContact({
                        name: primaryContact[0].firstName + ' ' + primaryContact[0].lastName,
                        title: primaryContact[0].title,
                    })
                }
            }
        },
    })

    const onOpen = useCallback(() => {
        doFetchHeadsUpEmailInfo()
        doFetchOrgContacts()
    }, [doFetchHeadsUpEmailInfo, doFetchOrgContacts])

    useEffect(() => {
        onOpen()
    }, [onOpen])

    const submit = () => {
        doPost()
    }

    return (
        <Accordion
            data-testid="survey-setup-schedule-handsup-email"
            id={id}
            onOpen={onOpen}
            title={
                <SectionTitle
                    title={`Prepare ${organizationName} for the upcoming survey`}
                    isStepComplete={isStepComplete}
                />
            }>
            <div data-testid="survey-setup-schedule-prepare" className="relative">
                {!isLoading && (
                    <div className="px-7 lg:w-3/5">
                        <SendHeadsUpText
                            surveyTypeId={surveyTypeId}
                            fullHeadsUpEmailInfo={{ organizationName, primaryContact, ...headsUpEmailInfo }}
                        />
                        {!isStepComplete && (
                            <Button
                                appearance="secondary"
                                isDisabled={readOnly || isPosting}
                                onPress={submit}
                                className="mt-4">
                                {isPosting ? 'Confirming Emails Have Been Sent...' : 'Confirm Emails Have Been Sent'}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </Accordion>
    )
}

const HeadsUpButtonGroup = ({ children }) => <ButtonGroup className="flex-col items-start">{children}</ButtonGroup>

const SendHeadsUpText = ({ fullHeadsUpEmailInfo, surveyTypeId }) => {
    const isCustom = surveyTypeId === SurveyType.Custom
    if (fullHeadsUpEmailInfo.isTopWorkplaceParticipant) {
        return (
            <div>
                <div className="pb-3">
                    {`It is important that you let your employees know that they will receive an email invitation to
            complete a workplace survey. To further improve the response rate, the email should be sent from a
            Senior Leader in your organization.`}
                </div>
                <div className="pb-3">
                    {`Please use one of the three templates below. These templates position the
        survey as a workplace survey rather than as a survey associated with the Top
        Workplaces award. We find this an effective way both to get honest
        feedback about your workplace and to avoid setting expectations about being
        named to a Top Workplaces regional or national list.`}
                </div>
                <HeadsUpButtonGroup>
                    <TemplateLink linkText={'Standard Template'} {...fullHeadsUpEmailInfo} />
                    <TemplateLink
                        linkText={'Template for organizations going through tough times'}
                        {...fullHeadsUpEmailInfo}
                        isToughTimes={true}
                    />
                    <TemplateLink
                        linkText={'Template for organizations going through rapid change'}
                        {...fullHeadsUpEmailInfo}
                        isRapidChange={true}
                    />
                </HeadsUpButtonGroup>
            </div>
        )
    } else {
        return (
            <div>
                <div className="pb-3">
                    {`We have found that letting your employees know that they will receive an
                email invitation to complete a workplace survey can improve your response rate.
                However, it is not a requirement that you send a heads-up email.`}
                </div>
                <HeadsUpButtonGroup>
                    <TemplateLink
                        surveyTypeId={surveyTypeId}
                        linkText={'Click here to view the heads-up email template'}
                        {...fullHeadsUpEmailInfo}
                        isRapidChange={isCustom}
                    />
                    <TemplateLink
                        linkText={'Click here for an alternative template for organizations going through tough times'}
                        {...fullHeadsUpEmailInfo}
                        isToughTimes={true}
                        isRapidChange={isCustom}
                    />
                    {!isCustom && (
                        <TemplateLink
                            linkText={
                                'Click here for an alternative template for organizations going through rapid change'
                            }
                            {...fullHeadsUpEmailInfo}
                            isRapidChange={true}
                        />
                    )}
                </HeadsUpButtonGroup>
                <div className="pt-3">
                    {`To achieve the best response rate, the heads-up email should be sent from a
                Senior Leader in your organization a day or two before your survey starts.`}
                </div>
            </div>
        )
    }
}

export default withRouter(HeadsUpEmail)
