import { colors } from '@energage/theme'
import type { WinnerAwardPdf } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { BgContainer } from 'containers/Main/AwardAnnouncement/BgContainer'
import { COMPANY_PROFILE_URL, FAQ_URL, MERCHANDISE_URL } from 'containers/Main/AwardAnnouncement/constants'
import { getUpdatedPublisherName, updatedAwardAliasName } from 'containers/Main/AwardAnnouncement/utils'
import { Achievement } from 'containers/Main/AwardAnnouncement/WinnersRevamp/Achievement'
import { AnnouncementPoints } from 'containers/Main/AwardAnnouncement/WinnersRevamp/AnnouncementPoints'
import { Congratulations } from 'containers/Main/AwardAnnouncement/WinnersRevamp/Congratulations'
import { FAQs as FAQsWinnerRevamp } from 'containers/Main/AwardAnnouncement/WinnersRevamp/FAQs'
import { WhatsNext } from 'containers/Main/AwardAnnouncement/WinnersRevamp/WhatsNext'
import { PageBreaker } from './AwardAnnouncementPdf'

export const WinnerPdf = ({ award, baseUrl }: WinnerAwardPdf) => {
    const {
        companyName,
        publicWebsiteCode,
        year,
        awardCategoryId,
        publisherId,
        nationalAwardsSvg,
        awardAliasName,
        pressReleaseDate,
        profileDeadlineDate,
        publisherName,
        publicationDate,
        isApproxPublishDate,
        hasPublisherProvidedMediaKit,
        paidMediaKit,
        denyTWPMerch,
        eventDate,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate,
        hasEvent,
        isNationalStandard,
        requiresTheBeforeName,
    } = award

    const updatedPublisherName = getUpdatedPublisherName(requiresTheBeforeName, publisherName)
    const awardAliasNameWithoutYear = updatedAwardAliasName(awardAliasName as string)

    return (
        <>
            <BgContainer backgroundColor={colors.darkblue500} isDefaultBgHeight>
                <Congratulations
                    awardCategoryId={awardCategoryId}
                    companyName={companyName}
                    year={year}
                    publisherId={publisherId}
                    svgUrl={nationalAwardsSvg}
                    colors={{
                        bgColor: colors.darkblue500,
                        textColor: colors.white,
                    }}
                    awardAliasNameWithoutYear={awardAliasNameWithoutYear}
                    isNationalStandard={isNationalStandard}
                    isPdf
                />
            </BgContainer>
            <PageBreaker />
            <AnnouncementPoints
                awardAliasName={awardAliasName}
                pressReleaseDate={pressReleaseDate}
                profileDeadlineDate={profileDeadlineDate}
                publisherName={updatedPublisherName}
                publicationDate={publicationDate}
                isApproxPublishDate={isApproxPublishDate}
                hasPublisherProvidedMediaKit={hasPublisherProvidedMediaKit}
                paidMediaKit={paidMediaKit}
                baseUrl={baseUrl}
                isPdf
                awardAliasNameWithoutYear={awardAliasNameWithoutYear}
                year={year}
            />
            <PageBreaker />
            <BgContainer backgroundColor={colors.darkblue500} isDefaultBgHeight>
                <WhatsNext
                    publisherId={publisherId}
                    isBlackListedFromTwpMerch={denyTWPMerch}
                    companyName={companyName}
                    year={year}
                    publisherName={updatedPublisherName}
                    hasPublisherProvidedMediaKit={hasPublisherProvidedMediaKit}
                    timeline={{
                        isApproxPublishDate: isApproxPublishDate,
                        publicationDate: publicationDate,
                        pressReleaseDate: pressReleaseDate,
                        eventDate: eventDate,
                        hasAlphabeticalWinnersList: hasAlphabeticalWinnersList,
                        alphabeticalWinnersListDateTime: alphabeticalWinnersListDate,
                        hasEvent: hasEvent,
                        awardAliasName: awardAliasName,
                        isNationalStandard: isNationalStandard,
                    }}
                    colors={{
                        bgColor: colors.darkblue500,
                        textColor: colors.white,
                    }}
                    isPdf
                />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.yellow500} isDefaultBgHeight>
                {!denyTWPMerch && (
                    <>
                        <Achievement
                            shopMerchandiseUrl={MERCHANDISE_URL}
                            year={year}
                            awardAliasName={awardAliasName}
                            paidMediaKit={paidMediaKit}
                            isPdf
                        />
                    </>
                )}
                <FAQsWinnerRevamp
                    companyProfileUrl={`${COMPANY_PROFILE_URL}${publicWebsiteCode}`}
                    faqUrl={FAQ_URL}
                    colors={{
                        bgColor: colors.yellow500,
                        textColor: colors.grey700,
                    }}
                    isPdf={true}
                />
            </BgContainer>
        </>
    )
}
