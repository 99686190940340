import { FormatPaint } from '@energage/icons'
import { colors } from '@energage/theme'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryCard } from '../../Common/SummaryCard'
import { PreviewSurveyButton } from './SummarySurveyButton'

export const NotConfirmedSurvey = ({ surveyEventId, title, message, previewUrl }) => {
    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="justify-center mt-2">
                <FormatPaint fill={colors.red600} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="text-red600 mb-2" ariaText={`Required: ${title}`}>
                        {title}
                    </CardTitle>
                    <div className="justify-center">
                        <p>{message}</p>
                    </div>
                </CardContent>
                <PreviewSurveyButton
                    className="inline-block"
                    previewUrl={previewUrl}
                    surveyEventId={surveyEventId}
                    buttonText="Preview"
                />
            </CardBody>
        </SummaryCard>
    )
}
