import { useCallback, useContext, useRef, useState } from 'react'
import { alert } from '@energage/components'
import { useAtomCallback } from 'jotai/utils'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import SurveySetupContext from '../SurveySetup/SurveySetupContext'
import { __SCOPE, descriptionAtom, selectedQuestionsAtom, surveyHeadlineAtom } from './statementSetupUtility/store'

function useSubmitSurveyDefinition(surveyEventId, refetchPreview) {
    const submitPromise = useRef(Promise.resolve())
    const { surveyCompanyId } = useIdentity()
    const history = useHistory()
    const { getNextPage } = useContext(SurveySetupContext)
    const [saveComplete, setSaveComplete] = useState(false)
    const queryClient = useQueryClient()

    const {
        mutateAsync: autoSaveMutation,
        reset: autoSaveReset,
        isLoading,
    } = useWorkplaceSurveyQuery.mutate(
        ({ autosave, ...data }, api) => {
            const url = `surveycompanies/${surveyCompanyId}/surveydefinition/${autosave ? 'autosave' : 'submit'}`
            return api.postJson(url, data)
        },
        {
            onSuccess: () => {
                setSaveComplete(true)
                setTimeout(() => setSaveComplete(false), 3000)
            },
            onError: () => {
                alert.danger(`There was an error saving your survey.`)
                autoSaveReset()
            },
            onSettled: () => {
                queryClient.refetchQueries(['survey-definition'])
            },
        }
    )

    const save = useAtomCallback(
        useCallback(
            (get, _set, autosave = false) => {
                const headline = get(surveyHeadlineAtom)
                const description = get(descriptionAtom)
                const selectedQuestions = get(selectedQuestionsAtom)

                const sequencedQuestions = map(
                    filter(selectedQuestions, (c) => c.questionEssenceId !== 0),
                    (q) => ({
                        surveyQuestionEssenceId: q.questionEssenceId,
                        sequence: q.sequence,
                        text: q.text,
                    })
                )

                submitPromise.current = submitPromise.current
                    .then(() =>
                        autoSaveMutation({
                            surveyEventId,
                            headline,
                            description,
                            sequencedQuestions,
                            autosave,
                        })
                    )
                    .then(() => {
                        submitPromise.current = Promise.resolve()
                        if (autosave) {
                            return refetchPreview()
                        }
                    })
                    .catch(() => (submitPromise.current = Promise.resolve()))

                if (!autosave) {
                    history.push(getNextPage().route({ surveyEventId }))
                }

                return submitPromise.current
            },
            [autoSaveMutation, surveyEventId, refetchPreview, history, getNextPage]
        ),
        __SCOPE
    )

    const autosave = useCallback(() => save(true), [save])
    const debouncedAutosave = useDebouncedCallback(autosave, 2000)

    return {
        submit: save,
        autosave: debouncedAutosave,
        saveState: isLoading ? 'SAVING' : saveComplete ? 'SAVED' : null,
    }
}

export default useSubmitSurveyDefinition
