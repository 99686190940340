import config from 'config'
import pluralize from 'util/pluralize'
import type {
    CompanyUserDepartmentDto,
    CompanyUserDto,
    CompanyUserPublisherRolesDto,
    CompanyUserRolesDto,
    CompanyUserSurveyEventDto,
    DepartmentNode,
    PublisherRolesType,
    SurveyNode,
    UserRolesType,
    UserType,
} from './models'

export const ADD_USER = 'AddUser'
export const EDIT_USER = 'EditUser'

export const EMAIL_EXISTS = 'Email exists'

export const DUPLICATED_USER_NAME_EXCEPTION = 'DuplicateUserNameException'
export const DUPLICATED_COMPANY_USER_NAME_EXCEPTION = 'DuplicateCompanyUserNameException'

export function formatData({
    id,
    firstName,
    lastName,
    email,
    roles,
    isCustomerSuccessPrimaryContact,
    isDisabled,
}: UserType) {
    return {
        id,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email,
        isDisabled,
        roles: roles.hasAdminAccess
            ? { admin: true, isCustomerSuccessPrimaryContact }
            : isPublisherControlRoles(roles)
            ? { admin: false, marketing: { enabled: roles.hasMarketingAccess } }
            : {
                  admin: false,
                  brandingManager: {
                      enabled: roles.hasBrandingAccess,
                  },
                  insightsManager: {
                      enabled: roles.hasInsightsAccess,
                      departments: roles.departments,
                      actionTrackingOnly: roles.isActionTrackerRestriction,
                  },
                  surveyManager: {
                      enabled: roles.hasSurveyAccess,
                  },
                  isCustomerSuccessPrimaryContact,
                  securityManager: {
                      enabled: roles.hasSecurityAccess,
                  },
              },
    }
}

export function flattenDepartmentsFromSurveyEvents(surveyEventsData: CompanyUserSurveyEventDto[]) {
    return surveyEventsData.reduce(
        (acc: Map<number, CompanyUserDepartmentDto>, surveyEvent: CompanyUserSurveyEventDto) => {
            const setIds = (d: CompanyUserDepartmentDto) => {
                // Set itself
                acc.set(d.id, d)

                if (d.children) {
                    d.children.forEach((x) => setIds(x))
                }
            }

            setIds(surveyEvent.topLevelDepartment)

            return acc
        },
        new Map<number, CompanyUserDepartmentDto>()
    )
}

export function getSurveyCount(departmentIds: number[], flatDepartmentMapping: Map<number, CompanyUserDepartmentDto>) {
    if (departmentIds.length === 0) {
        return 0
    }

    const surveyEventIds = new Set<number>()

    departmentIds.forEach((d) => {
        const surveyEventId = flatDepartmentMapping.get(d)?.surveyEventId

        if (!surveyEventId) {
            return
        }

        if (!surveyEventIds.has(surveyEventId)) {
            surveyEventIds.add(surveyEventId)
        }
    })

    return surveyEventIds.size
}

export function getInsightAccessDescription(hasAdminAccess: boolean, hasInsightsAccess: boolean, surveyCount: number) {
    return hasAdminAccess
        ? 'User currently has access to all surveys'
        : hasInsightsAccess
        ? `User currently has access to ${pluralize(surveyCount, 'survey')}`
        : 'User currently has no Insights access'
}

export function isPublisherRoles(
    roles: CompanyUserRolesDto | CompanyUserPublisherRolesDto
): roles is CompanyUserPublisherRolesDto {
    return (roles as CompanyUserPublisherRolesDto).marketing !== undefined
}

export function isPublisherControlRoles(roles: UserRolesType | PublisherRolesType): roles is PublisherRolesType {
    return (roles as PublisherRolesType).hasMarketingAccess !== undefined
}

export function getRoles(user: CompanyUserDto, isPublisher: boolean) {
    if (user.isDisabled) {
        return ['Inactive']
    }

    return isPublisher ? getPublisherRoles(user.roles) : getCompanyUserRoles(user.roles)
}

export function getPublisherRoles(roles: CompanyUserPublisherRolesDto) {
    const result = []

    if (roles.admin) {
        return ['Editorial']
    }

    if (roles?.marketing?.enabled) {
        result.push('Marketing')
    }

    return result
}

export function getCompanyUserRoles(roles: CompanyUserRolesDto) {
    const result = []

    if (roles.admin) {
        return ['Admin']
    }

    if (roles?.brandingManager?.enabled) {
        result.push(config.featureFlag.enableNewRoles ? 'Branding & Profile' : 'Branding')
    }

    if (roles?.insightsManager?.enabled) {
        result.push('Insights')
    }

    if (config.featureFlag.enableNewRoles && roles?.surveyManager?.enabled) {
        result.push('Survey & Award')
    }

    if (roles?.securityManager?.enabled) {
        result.push('Security')
    }

    return result
}

export function mapDepartmentsToSurveyEvents(
    departmentIds: number[],
    flatDepartmentMapping: Map<number, CompanyUserDepartmentDto>
) {
    const map = new Map<number, CompanyUserDepartmentDto[]>()

    if (departmentIds.length === 0) {
        return map
    }

    // DepartmentIds are incremental, just sort to get proper dates
    departmentIds
        .sort()
        .reverse()
        .forEach((d) => {
            const department = flatDepartmentMapping.get(d)

            if (!department) {
                return
            }

            if (!map.has(department.surveyEventId)) {
                map.set(department.surveyEventId, [department])
            } else {
                const departments = map.get(department.surveyEventId)
                departments?.push(department)
            }
        })

    return map
}

export function mapDepartmentIdsToSurveyEvents(
    departmentIds: number[],
    flatDepartmentMapping: Map<number, CompanyUserDepartmentDto>
) {
    const map = new Map<number, number[]>()

    if (departmentIds.length === 0) {
        return map
    }

    // DepartmentIds are incremental, just sort to get proper dates
    departmentIds
        .sort()
        .reverse()
        .forEach((departmentId) => {
            const department = flatDepartmentMapping.get(departmentId)

            if (!department) {
                return
            }

            if (!map.has(department.surveyEventId)) {
                map.set(department.surveyEventId, [departmentId])
            } else {
                const departmentIds = map.get(department.surveyEventId)
                departmentIds?.push(departmentId)
            }
        })

    return map
}

export function getDepartmentsTree(children: CompanyUserDepartmentDto[]): DepartmentNode[] {
    return children.map(
        ({
            id,
            name,
            parentId,
            hierarchicalResponderCount,
            responderCountSummaryThreshold,
            hasActionTracker,
            children,
        }: CompanyUserDepartmentDto) => {
            const enoughResponders = responderCountSummaryThreshold <= hierarchicalResponderCount
            const actionTrackerOnly = !enoughResponders && hasActionTracker

            return {
                id,
                name: actionTrackerOnly
                    ? `${name} (Action Tracker Only)`
                    : !enoughResponders
                    ? `${name} (Not enough responders)`
                    : name,
                parentId,
                disabled: !enoughResponders && !actionTrackerOnly,
                children: children.length > 0 ? getDepartmentsTree(children) : [],
            }
        }
    )
}

export function getSurveysDepartmentsTree(surveyEventsData: CompanyUserSurveyEventDto[]): SurveyNode[] {
    return surveyEventsData.map(
        ({
            id: surveyId,
            name: surveyName,
            topLevelDepartment: {
                id,
                name,
                parentId,
                hierarchicalResponderCount,
                responderCountSummaryThreshold,
                hasActionTracker,
                children,
            },
        }: CompanyUserSurveyEventDto) => {
            const enoughResponders = responderCountSummaryThreshold <= hierarchicalResponderCount
            const actionTrackerOnly = !enoughResponders && hasActionTracker

            return {
                surveyId: surveyId,
                surveyName: surveyName,
                topLevelDepartment: {
                    id,
                    name: actionTrackerOnly
                        ? `${name} (Action Tracker Only)`
                        : !enoughResponders
                        ? `${name} (Not enough responders)`
                        : name,
                    parentId,
                    disabled: !enoughResponders && !actionTrackerOnly,
                    children: children.length > 0 ? getDepartmentsTree(children) : [],
                },
            }
        }
    )
}

export function filterDepartmentSelections(multiSelectOptions: DepartmentNode, selectedDepartments: number[]) {
    const result: number[] = []

    // Check if the root node is selected
    if (selectedDepartments.includes(multiSelectOptions.id)) {
        result.push(multiSelectOptions.id)

        return result
    }

    // If the root node is not selected, check its children
    for (const child of multiSelectOptions.children) {
        const childSelections = filterDepartmentSelections(child, selectedDepartments)
        if (childSelections.length > 0) {
            result.push(...childSelections)
        }
    }

    return result
}

function addAllDescendants(node: DepartmentNode, result: number[]) {
    if (!node.disabled) {
        result.push(node.id)
    }

    for (const child of node.children) {
        addAllDescendants(child, result)
    }
}

export function getSelectedNodes(multiSelectOptions: DepartmentNode, selectedIds: number[]) {
    const result: number[] = []

    // Add the root node and all its descendants to the result if it's selected
    if (selectedIds.includes(multiSelectOptions.id)) {
        addAllDescendants(multiSelectOptions, result)
    }

    // Recursively add selected children and their descendants
    for (const child of multiSelectOptions.children) {
        result.push(...getSelectedNodes(child, selectedIds))
    }

    return result
}
