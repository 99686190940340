import { forwardRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { DragIndicator } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai/utils'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import { Tooltip } from 'components/Tooltip'
import pluralize from 'util/pluralize'
import { useCustomDemographicTargets } from '../../CustomDemographicTargetContext'
import { QuestionType } from '../../CustomQuestions/QuestionType'
import { useDepartmentContext } from '../../departmentContext'
import { availableTargetDemographicQuestions } from '../../statementSetupUtility/store'
import { computeTargetingInformation } from '../../Targeting/computeTargetingInformation'
import { CollapsibleButton } from '../CollapsibleButton'
import QuestionTypeAnswerFormat, { StatementTitle } from '../QuestionTypeAnswerFormat'
import { QuestionTypeAnswerFormats } from '../QuestionTypeAnswerFormats'
import { TableCell, TableRow } from '../TableElements'
import { ComplexConditionDescription } from '../TargetingMessagingFragments'
import { RowQuestionEdit } from './RowQuestionEdit'

const ROW_DETAIL_VIEWS = {
    NONE: 'none',
    TARGETING: 'targeting',
    ANSWER_FORMAT: 'answerFormat',
}

function TargetingColumnTitle({ demographicList, onToggle, open, questionId, departmentCount }) {
    let targetGroupTitle = ''

    if (departmentCount > 0) {
        targetGroupTitle += pluralize(departmentCount, 'Department')
    }

    const demographicCount = reduce(
        demographicList,
        (acc, demographic) => {
            return acc + demographic.options.length
        },
        0
    )

    if (demographicCount > 0) {
        if (targetGroupTitle.length > 0) {
            targetGroupTitle += ', '
        }
        targetGroupTitle += pluralize(demographicCount, 'Demographic')
    }

    return (
        <CollapsibleButton
            onToggle={onToggle}
            open={open}
            text={targetGroupTitle}
            aria-controls={`targeting-${questionId}`}
        />
    )
}

const SortableQuestionTableRow = forwardRef(
    (
        {
            question,
            parentSelected,
            isConditional,
            isEditing,
            onCancelEditAdditionalQuestion,
            draggable = {},
            dragHandle = {},
            saveQuestion,
            isSavingQuestion,
            isSortingDisabled,
            QuestionOverflowMenuComponent,
            departments,
            hasTargetingColumn,
        },
        ref
    ) => {
        const {
            isRequired,
            questionTypeId,
            text: questionText,
            name,
            answerOptions,
            positiveConditionalText,
            negativeConditionalText,
            isPositivelyPhrased,
            isHighlighted,
        } = question

        const questionId = question.questionEssenceId ?? 0
        const [visibleDetails, setVisibleDetails] = useState(ROW_DETAIL_VIEWS.NONE)
        const targetingVisible = visibleDetails === ROW_DETAIL_VIEWS.TARGETING
        const answerFormatVisible = visibleDetails === ROW_DETAIL_VIEWS.ANSWER_FORMAT

        const toggleVisibleDetailView = (detailView) => {
            setVisibleDetails((view) => {
                if (view === detailView) {
                    return ROW_DETAIL_VIEWS.NONE
                }
                return detailView
            })
        }

        const { style = {} } = draggable

        const disableSelection = isConditional && !parentSelected

        const demographicQuestions = useAtomValue(availableTargetDemographicQuestions)
        const { flattenedDepartments } = useDepartmentContext()
        const customDemographicTargets = useCustomDemographicTargets()

        const targetingInformation = useMemo(() => {
            return computeTargetingInformation({
                questionTargetDemographics: question.targetDemographic,
                questionTargetDepartmentIds: question.departmentIds,
                flattenedDepartments,
                demographicQuestions,
                customDemographicTargets,
            })
        }, [
            question.targetDemographic,
            question.departmentIds,
            flattenedDepartments,
            demographicQuestions,
            customDemographicTargets,
        ])

        const hasConditionals = !!(positiveConditionalText || negativeConditionalText)
        const hasTargetingDescription = targetingInformation.targetingDescription !== null

        const formatName =
            questionTypeId === QuestionType.CustomScale && answerOptions
                ? `${answerOptions.length}pt Scale`
                : QuestionTypeAnswerFormats[questionTypeId]?.name || '-'

        const title =
            hasConditionals && questionTypeId === QuestionType.Agreement
                ? 'Statement with Conditional Questions'
                : formatName

        const showOnlyTitle =
            questionTypeId !== QuestionType.Agreement &&
            isEmpty(answerOptions) &&
            !QuestionTypeAnswerFormats[questionTypeId]?.description

        return (
            <TableRow {...draggable} style={style} ref={ref} className={isHighlighted && 'is-highlighted'}>
                <div className="flex flex-wrap md:flex-no-wrap relative items-start">
                    <TableCell
                        className="absolute"
                        css={{ left: '-3px', top: '0.5rem', transform: 'translateY(-50%)' }}>
                        <span {...dragHandle}>
                            <Tooltip
                                trigger={<DragIndicator color={isSortingDisabled ? colors.grey500 : colors.grey800} />}
                                disabled={!isSortingDisabled}
                                text="Item cannot be reordered."
                                tooltipColor={colors.grey600}
                                textColor={colors.white}
                                tooltipArrowColor={colors.grey500}
                            />
                        </span>
                    </TableCell>
                    <TableCell className="w-1/3 md:w-1/6">
                        <div className="mr-2 pl-7 break-words">
                            {name}
                            {isRequired && <span>{` *`}</span>}
                        </div>
                    </TableCell>
                    <TableCell className="w-2/3 md:w-1/3">
                        <label htmlFor={questionId} className={cx('pr-6 md:pr-1', disableSelection && 'text-grey500')}>
                            {questionText}
                        </label>
                    </TableCell>
                    <TableCell
                        className={cx(
                            'w-full  pl-7 pt-1 md:pt-0 md:pl-0 flex flex items-center pr-10',
                            {
                                'md:w-1/5': hasTargetingColumn,
                                'md:w-1/2': !hasTargetingColumn,
                            },
                            disableSelection && 'text-grey500'
                        )}>
                        {showOnlyTitle ? (
                            <div>
                                <span>{'Answer Format: '}</span>
                                {title}
                            </div>
                        ) : (
                            <StatementTitle
                                open={answerFormatVisible}
                                onToggle={() => toggleVisibleDetailView(ROW_DETAIL_VIEWS.ANSWER_FORMAT)}
                                title={title}
                                aria-controls={`answer-format-${questionId}`}
                            />
                        )}
                    </TableCell>
                    {hasTargetingColumn ? (
                        <TableCell className="w-full pl-7 md:pl-0 mt-2 md:mt-0 md:w-1/5">
                            {hasTargetingDescription ? (
                                <TargetingColumnTitle
                                    questionId={questionId}
                                    demographicList={targetingInformation.demographicNameList}
                                    departmentCount={targetingInformation.departmentNameList.length}
                                    onToggle={() => toggleVisibleDetailView(ROW_DETAIL_VIEWS.TARGETING)}
                                    open={targetingVisible}
                                />
                            ) : (
                                <span>{'All Responders'}</span>
                            )}
                            <AnimatePresence initial="false">
                                {visibleDetails === 'targeting' ? (
                                    <motion.div
                                        id={`targeting-${questionId}`}
                                        animate="open"
                                        initial="collapsed"
                                        exit="collapsed"
                                        variants={{
                                            open: { height: 'auto' },
                                            collapsed: { height: 0 },
                                        }}
                                        className="clearfix overflow-hidden md:px-0 pt-1 md:pt-0">
                                        <div className="w-full py-2 md:py-4">
                                            {hasTargetingDescription && (
                                                <ComplexConditionDescription
                                                    descriptions={targetingInformation.targetingDescription}
                                                />
                                            )}
                                        </div>
                                    </motion.div>
                                ) : null}
                            </AnimatePresence>
                        </TableCell>
                    ) : null}
                    <TableCell
                        className="absolute z-10"
                        css={{ top: '0.5rem', right: 0, transform: 'translateY(-50%)' }}>
                        {!isEditing && QuestionOverflowMenuComponent}
                    </TableCell>
                </div>
                <div className="flex flex-row">
                    <div className="hidden md:block flex-1">
                        {hasTargetingDescription && !hasTargetingColumn && (
                            <ComplexConditionDescription
                                className="text-grey600 italic pt-2"
                                descriptions={targetingInformation.targetingDescription}
                            />
                        )}
                    </div>
                    <AnimatePresence initial="false">
                        {answerFormatVisible ? (
                            <motion.div
                                id={`answer-format-${questionId}`}
                                animate="open"
                                initial="collapsed"
                                exit="collapsed"
                                variants={{
                                    open: { height: 'auto' },
                                    collapsed: { height: 0 },
                                }}
                                className="clearfix overflow-hidden px-7 md:px-0 pt-1 md:pt-0 flex-1">
                                <QuestionTypeAnswerFormat
                                    questionId={questionId}
                                    questionTypeId={questionTypeId}
                                    options={answerOptions}
                                    positiveConditionalText={positiveConditionalText}
                                    negativeConditionalText={negativeConditionalText}
                                    isPositivelyPhrased={isPositivelyPhrased}
                                />
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </div>
                {hasTargetingDescription && !hasTargetingColumn && (
                    <div className="block md:hidden">
                        <ComplexConditionDescription
                            className="text-grey600 italic pt-2"
                            descriptions={targetingInformation.targetingDescription}
                        />
                    </div>
                )}
                {isEditing && (
                    <RowQuestionEdit
                        departments={departments}
                        question={question}
                        demographicQuestions={demographicQuestions}
                        isSavingQuestion={isSavingQuestion}
                        onCancelEditAdditionalQuestion={onCancelEditAdditionalQuestion}
                        saveQuestion={saveQuestion}
                    />
                )}
            </TableRow>
        )
    }
)

SortableQuestionTableRow.propTypes = {
    question: PropTypes.shape({
        questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        questionTypeId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        targetGroupDescription: PropTypes.arrayOf(PropTypes.string),
        answerOptions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                isEditable: PropTypes.bool,
            })
        ),
        positiveConditionalText: PropTypes.string,
        negativeConditionalText: PropTypes.string,
        isHighlighted: PropTypes.bool,
        isPositivelyPhrased: PropTypes.bool,
    }),
    selected: PropTypes.bool.isRequired,
    parentSelected: PropTypes.bool.isRequired,
    isConditional: PropTypes.bool.isRequired,
    onCancelEditAdditionalQuestion: PropTypes.func.isRequired,
    saveQuestion: PropTypes.func.isRequired,
    isSavingQuestion: PropTypes.bool.isRequired,
}
SortableQuestionTableRow.displayName = 'SortableQuestionTableRow'
export { SortableQuestionTableRow }
