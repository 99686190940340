import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Container from 'components/Container'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import { routes } from 'constants/routes'
import BulkUpload from 'containers/Organization/Upload'

const Title = () => (
    <div>
        <h6 className="text-4xl font-thin w-2/3 m-auto">{`Please upload your employee list below.`}</h6>
        <p className="text-xl font-thin w-2/3 m-auto">{`In the future you can manage your employee list in the Org Management section in the upper right corner.`}</p>
    </div>
)

const AddRecipients = ({ match, history }) => {
    const surveyEventId = match.params.surveyEventId
    const identity = useIdentity()

    const handleBulkUpload = useCallback(() => {
        const route = identity.permissions.hasSmartPulse
            ? routes.survey.setup.recipients.modify.departments({ surveyEventId })
            : routes.survey.setup.recipients({ surveyEventId })
        history.push(route)
    }, [history, surveyEventId, identity])

    return (
        <Container>
            <Inset padding className="pt-0 sm:pt-0">
                <BulkUpload title={<Title />} onUpload={handleBulkUpload} isFreshUpload={false} />
            </Inset>
        </Container>
    )
}

AddRecipients.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default withRouter(AddRecipients)
