import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import type { Winner } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import colors from 'containers/Main/AwardAnnouncement/colors'
import { COMPANY_PROFILE_URL, FAQ_URL, MERCHANDISE_URL } from 'containers/Main/AwardAnnouncement/constants'
import { getUpdatedPublisherName, updatedAwardAliasName } from 'containers/Main/AwardAnnouncement/utils'
import { Achievement } from './Achievement'
import { AnnouncementPoints } from './AnnouncementPoints'
import { Congratulations } from './Congratulations'
import { FAQs } from './FAQs/FAQs'
import { WhatsNext } from './WhatsNext/WhatsNext'

export const WinnersRevamp = (announcement: Winner) => {
    const [showConfetti, setShowConfetti] = useState(false)
    const {
        awardCategoryId,
        companyName,
        year,
        publisherId,
        denyTWPMerch,
        publisherName,
        isApproxPublishDate,
        publicationDate,
        pressReleaseDate,
        eventDate,
        hasEvent,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate,
        nationalAwardsSvg,
        publicWebsiteCode,
        profileDeadlineDate,
        awardAliasName,
        requiresTheBeforeName,
        hasPublisherProvidedMediaKit,
        paidMediaKit,
        isNationalStandard,
    } = announcement

    const updatedPublisherName = getUpdatedPublisherName(requiresTheBeforeName, publisherName)

    useEffect(() => {
        setShowConfetti(true)
        const timeout = setTimeout(() => setShowConfetti(false), 5000)
        return () => clearTimeout(timeout)
    }, [])

    const awardAliasNameWithoutYear = updatedAwardAliasName(awardAliasName as string)

    return (
        <div className="bg-white">
            {showConfetti && <Confetti width={window.innerWidth - 40} height={window.innerHeight} />}
            <Congratulations
                awardCategoryId={awardCategoryId}
                companyName={companyName}
                year={year}
                publisherId={publisherId}
                svgUrl={nationalAwardsSvg}
                awardAliasNameWithoutYear={awardAliasNameWithoutYear}
                isNationalStandard={isNationalStandard}
            />
            <AnnouncementPoints
                awardAliasName={awardAliasName}
                pressReleaseDate={pressReleaseDate}
                profileDeadlineDate={profileDeadlineDate}
                publisherName={updatedPublisherName}
                publicationDate={publicationDate}
                isApproxPublishDate={isApproxPublishDate}
                hasPublisherProvidedMediaKit={hasPublisherProvidedMediaKit}
                paidMediaKit={paidMediaKit}
                awardAliasNameWithoutYear={awardAliasNameWithoutYear}
                year={year}
            />
            <WhatsNext
                publisherId={publisherId}
                isBlackListedFromTwpMerch={denyTWPMerch}
                companyName={companyName}
                year={year}
                publisherName={updatedPublisherName}
                hasPublisherProvidedMediaKit={hasPublisherProvidedMediaKit} // will use in press release as well.
                timeline={{
                    isApproxPublishDate: isApproxPublishDate,
                    publicationDate: publicationDate,
                    pressReleaseDate: pressReleaseDate,
                    eventDate: eventDate,
                    hasAlphabeticalWinnersList: hasAlphabeticalWinnersList,
                    alphabeticalWinnersListDateTime: alphabeticalWinnersListDate,
                    hasEvent: hasEvent,
                    awardAliasName: awardAliasName,
                    isNationalStandard: isNationalStandard,
                }}
                colors={{
                    bgColor: colors.bgBlue,
                    textColor: colors.textWhite,
                }}
            />
            {!denyTWPMerch && (
                <Achievement
                    shopMerchandiseUrl={MERCHANDISE_URL}
                    year={year}
                    awardAliasName={awardAliasName}
                    paidMediaKit={paidMediaKit}
                />
            )}
            <FAQs
                companyProfileUrl={`${COMPANY_PROFILE_URL}${publicWebsiteCode}`}
                faqUrl={FAQ_URL}
                colors={{
                    bgColor: colors.bgDarkYellow,
                    textColor: colors.textDark,
                }}
            />
        </div>
    )
}
