import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ArrowDropDown, ArrowDropUp, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import map from 'lodash/map'
import some from 'lodash/some'
import { stringify } from 'query-string'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import EditEmployee from './ManageEmployee/EditEmployee'
import RemoveEmployee from './RemoveEmployee'
import SortDirection from './SortDirection'

export const TABLE_COLUMNS = [
    {
        id: 'externalId',
        label: 'Employee ID',
        visible: (users) => some(users, 'externalId'),
    },
    {
        id: 'name',
        label: 'Employee Name',
        columnWidth: '25%',
        visible: true,
    },
    {
        id: 'emailAddress',
        label: 'Email Address',
        visible: (users) => some(users, 'emailAddress'),
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
        visible: (users) => some(users, 'phoneNumber'),
    },
    {
        id: 'department',
        label: 'Department',
        visible: true,
    },
    {
        id: 'actions',
        label: 'Actions',
        className: 'w:1/4 hidden md:inline-block',
        getValue: (item, onEmployeeRemove, departments, locations, onComplete, isLoading) => (
            <>
                <EditEmployee
                    employee={item}
                    allDepartments={departments}
                    onComplete={onComplete}
                    locations={locations}
                    isLoading={isLoading}
                />
                <RemoveEmployee employee={item} onEmployeeRemove={onEmployeeRemove} />
            </>
        ),
        visible: true,
    },
]

export const StyledTableRow = styled.tr.attrs({ className: 'text-xs border-b border-grey300' })`
    background-color: ${colors.white};

    :nth-child(even) {
        background-color: ${colors.grey100};
    }
`

const TableContext = createContext()

const SortIndicator = ({ active, direction }) => (
    <Icon
        className="ml-2"
        as={!active || direction === SortDirection.ascending ? ArrowDropUp : ArrowDropDown}
        color={active ? colors.grey700 : colors.grey300}
    />
)

const ColumnHeader = styled.th`
    ${(props) => props.width && `width: ${props.width}`};
`

function getSortQueryString(columnId, sortColumn, sortDirection) {
    return stringify({
        col: columnId,
        dir: columnId === sortColumn ? SortDirection.toggle(sortDirection) : SortDirection.ascending,
    })
}

const TableHeader = ({ sortColumn, sortDirection, isSortingDisabled }) => {
    const { columns } = useContext(TableContext)

    return (
        <thead className="border-b-2 border-grey300 bg-white">
            <tr className="text-sm font-bold">
                {map(columns, ({ id, label, columnWidth }) => {
                    const hasClickable = id !== 'actions' && !isSortingDisabled
                    return (
                        <ColumnHeader
                            key={label}
                            width={columnWidth}
                            className={cx('p-2', {
                                'cursor-pointer': hasClickable,
                            })}>
                            {hasClickable ? (
                                <Link to={{ search: getSortQueryString(id, sortColumn, sortDirection) }}>
                                    {label}
                                    <SortIndicator active={sortColumn === id} direction={sortDirection} />
                                </Link>
                            ) : (
                                label
                            )}
                        </ColumnHeader>
                    )
                })}
            </tr>
        </thead>
    )
}

const TableRow = ({ item, onEmployeeRemove, departments, locations, onComplete, isLoading }) => {
    const { columns } = useContext(TableContext)
    return (
        <StyledTableRow>
            {map(columns, ({ id, getValue }) => (
                <td key={id} className="p-2">
                    {getValue
                        ? getValue(item, onEmployeeRemove, departments, locations, onComplete, isLoading)
                        : item[id]}
                </td>
            ))}
        </StyledTableRow>
    )
}

TableRow.propTypes = {
    item: PropTypes.object.isRequired,
    onEmployeeRemove: PropTypes.func.isRequired,
}

const EmployeeTable = ({ columns, children, sortColumn, sortDirection, isSortingDisabled }) => {
    return (
        <TableContext.Provider value={{ columns }}>
            <table className="w-full border border-grey300 text-left">
                <TableHeader
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    isSortingDisabled={isSortingDisabled}
                />
                <tbody>{children}</tbody>
            </table>
        </TableContext.Provider>
    )
}

EmployeeTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
}

export { EmployeeTable, TableRow }
