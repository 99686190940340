import * as d3 from 'd3'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import renderText from './renderText'

const DESCENDER_HEIGHT_RATIO = 0.75
const HORIZONTAL_PADDING = 3

const WordCloudRenderer = (wordGroups, { fontScale, colors, fontWeight, opacityScale }) => {
    const renderWords = renderText()
        .text((d) => d.text)
        .fontSize((d) => fontScale(d))
        .color((d) => {
            if (d.isEmphasizedPositive) {
                return colors.positive
            } else if (d.isEmphasizedNegative) {
                return colors.negative
            } else {
                return colors.neutral || '#949494'
            }
        })
        .fontWeight(fontWeight)
        .x((d) => d.x)
        .y((d) => d.y)
        .anchor('middle')

    const renderer = {
        calculateBoundingBox(selection) {
            selection.each(function (d) {
                try {
                    d.boundingBox = this.getBBox()
                } catch (e) {
                    d.boundingBox = this.getBoundingClientRect()
                }
            })
        },

        renderWordCloudWords(selection, isInteractive) {
            function setFillOpacity(fillOpacity) {
                function setFillOpacityForWord(word) {
                    const dropletId = formatDropletId(word)
                    d3.select(`#text-background-${dropletId}`).style('fill-opacity', fillOpacity)
                }

                return (d) => {
                    if (d.isHidden) {
                        return
                    }

                    const relatedWords = wordGroups.groupForWord(d.text)
                    if (relatedWords && relatedWords.length > 0) {
                        forEach(relatedWords, setFillOpacityForWord)
                    } else {
                        setFillOpacityForWord(d.text)
                    }
                }
            }

            selection
                .style('fill-opacity', (d) => {
                    if (d.isHidden) {
                        return 0
                    }
                    return opacityScale(d)
                })
                .style('cursor', (d) => (d.isHidden || !isInteractive ? 'default' : 'pointer'))
                .on('mouseover', setFillOpacity(0.5))
                .on('mouseout', setFillOpacity(0))
            return renderWords(selection).then(() => {
                selection.call(renderer.calculateBoundingBox)
            })
        },

        renderSubCloudConnections(group, subCloud, subCloudWordBorderColor) {
            const subCloudDroplets = map(orderBy(subCloud.droplets, 'weight', 'desc'), (subDroplet) => ({
                ...subDroplet,
                x: subDroplet.x + subCloud.x,
                y: subDroplet.y + subCloud.y,
            }))
            const centerDroplet = subCloudDroplets.shift()

            if (!centerDroplet.boundingBox) {
                return
            }

            const y = (d) => d.y - d.boundingBox.height / 4

            d3.select(group)
                .selectAll('line')
                .data(filter(subCloudDroplets, (d) => !!d.boundingBox))
                .enter()
                .append('line')
                .attr('x1', centerDroplet.x)
                .attr('x2', (d) => d.x)
                .attr('y1', y(centerDroplet))
                .attr('y2', y)
                .style('stroke', '#999')
                .style('stroke-width', 1)

            const renderOutlines = renderText()
                .text((d) => d.text)
                .fontSize((d) => fontScale(d))
                .color(subCloudWordBorderColor)
                .x((d) => d.x)
                .y((d) => d.y)
                .anchor('middle')
                .attrs({
                    'stroke': subCloudWordBorderColor,
                    'stroke-linejoin': 'round',
                    'stroke-width': (d) => 2 * d.padding,
                })

            d3.select(group)
                .selectAll(renderOutlines.element)
                .data(concat(subCloudDroplets, centerDroplet))
                .enter()
                .append(renderOutlines.element)
                .call(renderOutlines)
        },

        renderWordCloudHighlights(selection) {
            selection
                .attr('id', (d) => `text-background-${formatDropletId(d.text)}`)
                .attr('x', (d) => d.x - 0.5 * d.boundingBox.width - HORIZONTAL_PADDING)
                .attr('y', (d) => d.y - DESCENDER_HEIGHT_RATIO * d.boundingBox.height)
                .attr('width', (d) => d.boundingBox.width + HORIZONTAL_PADDING * 2)
                .attr('height', (d) => d.boundingBox.height)
                .style('fill', '#FFEE00')
                .style('fill-opacity', 0)
        },

        renderTooltip(selection) {
            selection.append('svg:title').text((d) => d.totalCommentsSentence)
        },

        renderSubCloudBounds(selection, subClouds) {
            selection
                .selectAll('rect')
                .data(subClouds)
                .enter()
                .append('rect')
                .attr('x', (d) => d.x - d.width / 2)
                .attr('y', (d) => d.y - d.height / 2)
                .attr('width', (d) => d.width)
                .attr('height', (d) => d.height)
                .style('fill', 'red')
                .style('fill-opacity', 0.1)
        },
    }

    renderer.textElement = renderWords.element

    return renderer
}

export default WordCloudRenderer

function formatDropletId(text) {
    return text
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z0-9_:.]/g, '-')
}
