/* eslint-disable one-var, no-shadow, no-cond-assign, max-depth, no-param-reassign */
// Word cloud layout by Jason Davies, http://www.jasondavies.com/word-cloud/
// Algorithm due to Jonathan Feinberg, http://static.mrfeinberg.com/bv_ch03.pdf
// Adapted for use with sub-clouds by WorkplaceDynamics

import { dispatch } from 'd3-dispatch'
import fill from 'lodash/fill'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

const cloudRadians = Math.PI / 180,
    cw = (1 << 11) >> 5,
    ch = 1 << 11

function archimedeanSpiral(size) {
    const e = size[0] / size[1]
    return function (t) {
        return [e * (t *= 0.1) * Math.cos(t), t * Math.sin(t)]
    }
}

function rectangularSpiral(size) {
    const dy = 4,
        dx = (dy * size[0]) / size[1]
    let x = 0,
        y = 0
    return function (t) {
        const sign = t < 0 ? -1 : 1
        // See triangular numbers: T_n = n * (n + 1) / 2.
        switch ((Math.sqrt(1 + 4 * sign * t) - sign) & 3) {
            case 0:
                x += dx
                break
            case 1:
                y += dy
                break
            case 2:
                x -= dx
                break
            default:
                y -= dy
                break
        }
        return [x, y]
    }
}

function zeroArray(n) {
    return fill(new Array(n), 0)
}

function cloudCanvas() {
    return document.createElement('canvas')
}

function functor(d) {
    return typeof d === 'function'
        ? d
        : function () {
              return d
          }
}

const spirals = {
    archimedean: archimedeanSpiral,
    rectangular: rectangularSpiral,
}

export default function () {
    const event = dispatch('word', 'end'),
        cloud = {}
    let size = [256, 256],
        text = cloudText,
        font = cloudFont,
        fontSize = cloudFontSize,
        fontStyle = cloudFontNormal,
        fontWeight = cloudFontNormal,
        rotate = cloudRotate,
        padding = cloudPadding,
        spiral = archimedeanSpiral,
        words = [],
        timeInterval = Infinity,
        timer = null,
        canvas = cloudCanvas,
        placeAllWords = false

    cloud.canvas = function (_) {
        if (arguments.length) {
            canvas = functor(_)
            return cloud
        } else {
            return canvas
        }
    }

    cloud.start = function () {
        const contextAndRatio = getContext(canvas()),
            board = zeroArray((size[0] >> 5) * size[1]),
            n = words.length,
            tags = [],
            data = map(words, function (d, i) {
                d.text = text.call(this, d, i)
                d.font = font.call(this, d, i)
                d.style = fontStyle.call(this, d, i)
                d.fontWeight = fontWeight.call(this, d, i)
                d.rotate = rotate.call(this, d, i)
                d.size = ~~fontSize.call(this, d, i)
                d.padding = padding.call(this, d, i)
                return d
            }).sort((a, b) => b.size - a.size)
        let bounds = null,
            i = -1,
            isWordPlaced = true

        if (timer) {
            clearInterval(timer)
        }
        timer = setInterval(step, 0)
        step()

        return cloud

        function step() {
            const start = Date.now()
            while (Date.now() - start < timeInterval && ++i < n && timer && (!placeAllWords || isWordPlaced)) {
                const d = data[i]
                d.x = size[0] >> 1
                d.y = size[1] >> 1
                cloudSprite(contextAndRatio, d, data, i)

                isWordPlaced = place(board, d, bounds)

                if (d.hasText && isWordPlaced) {
                    tags.push(d)
                    event.call('word', null, d)
                    if (bounds) {
                        cloudBounds(bounds, d)
                    } else {
                        bounds = [
                            { x: d.x + d.x0, y: d.y + d.y0 },
                            { x: d.x + d.x1, y: d.y + d.y1 },
                        ]
                    }
                    // Temporary hack
                    d.x -= size[0] >> 1
                    d.y -= size[1] >> 1
                }
            }
            if (placeAllWords && !isWordPlaced) {
                forEach(data, (el) => {
                    el.sprite = null
                })
            }

            if (i >= n || (placeAllWords && !isWordPlaced)) {
                cloud.stop()
                event.call('end', null, tags, bounds, !placeAllWords || isWordPlaced)
            }
        }
    }

    cloud.stop = function () {
        if (timer) {
            clearInterval(timer)
            timer = null
        }
        return cloud
    }

    function getContext(canvas) {
        canvas.width = canvas.height = 1
        const ratio = Math.sqrt(canvas.getContext('2d').getImageData(0, 0, 1, 1).data.length >> 2)
        canvas.width = (cw << 5) / ratio
        canvas.height = ch / ratio

        const context = canvas.getContext('2d')
        context.fillStyle = context.strokeStyle = 'red'
        context.textAlign = 'center'

        return { context, ratio }
    }

    function place(board, tag, bounds) {
        const startX = tag.x,
            startY = tag.y,
            maxDelta = Math.sqrt(size[0] * size[0] + size[1] * size[1]),
            s = spiral(size),
            dt = 1
        let t = -dt,
            dxdy,
            dx,
            dy

        while ((dxdy = s((t += dt)))) {
            dx = ~~dxdy[0]
            dy = ~~dxdy[1]

            if (Math.min(Math.abs(dx), Math.abs(dy)) >= maxDelta) {
                break
            }

            tag.x = startX + dx
            tag.y = startY + dy

            if (tag.x + tag.x0 < 0 || tag.y + tag.y0 < 0 || tag.x + tag.x1 > size[0] || tag.y + tag.y1 > size[1]) {
                continue
            }

            if (!bounds || !cloudCollide(tag, board, size[0])) {
                if (!bounds || collideRects(tag, bounds)) {
                    const { sprite } = tag,
                        w = tag.width >> 5,
                        sw = size[0] >> 5,
                        lx = tag.x - (w << 4),
                        sx = lx & 0x7f,
                        msx = 32 - sx,
                        h = tag.y1 - tag.y0
                    let x = (tag.y + tag.y0) * sw + (lx >> 5),
                        last
                    for (let j = 0; j < h; j++) {
                        last = 0
                        for (let i = 0; i <= w; i++) {
                            board[x + i] |= (last << msx) | (i < w ? (last = sprite[j * w + i]) >>> sx : 0)
                        }
                        x += sw
                    }
                    delete tag.sprite
                    return true
                }
            }
        }
        return false
    }

    cloud.timeInterval = function (_) {
        if (arguments.length) {
            timeInterval = isNil(_) ? Infinity : _
            return cloud
        } else {
            return timeInterval
        }
    }

    cloud.words = function (_) {
        if (arguments.length) {
            words = _
            return cloud
        } else {
            return words
        }
    }

    cloud.placeAllWords = function (_) {
        if (arguments.length) {
            placeAllWords = _
            return cloud
        } else {
            return placeAllWords
        }
    }

    cloud.size = function (_) {
        if (arguments.length) {
            size = [+_[0], +_[1]]
            return cloud
        } else {
            return size
        }
    }

    cloud.font = function (_) {
        if (arguments.length) {
            font = functor(_)
            return cloud
        } else {
            return font
        }
    }

    cloud.fontStyle = function (_) {
        if (arguments.length) {
            fontStyle = functor(_)
            return cloud
        } else {
            return fontStyle
        }
    }

    cloud.fontWeight = function (_) {
        if (arguments.length) {
            fontWeight = functor(_)
            return cloud
        } else {
            return fontWeight
        }
    }

    cloud.rotate = function (_) {
        if (arguments.length) {
            rotate = functor(_)
            return cloud
        } else {
            return rotate
        }
    }

    cloud.text = function (_) {
        if (arguments.length) {
            text = functor(_)
            return cloud
        } else {
            return text
        }
    }

    cloud.spiral = function (_) {
        if (arguments.length) {
            spiral = spirals[_] || _
            return cloud
        } else {
            return spiral
        }
    }

    cloud.fontSize = function (_) {
        if (arguments.length) {
            fontSize = functor(_)
            return cloud
        } else {
            return fontSize
        }
    }

    cloud.padding = function (_) {
        if (arguments.length) {
            padding = functor(_)
            return cloud
        } else {
            return padding
        }
    }

    cloud.on = function (...args) {
        const value = event.on(...args)
        return value === event ? cloud : value
    }

    return cloud
}

function cloudText(d) {
    return d.text
}

function cloudFont() {
    return 'serif'
}

function cloudFontNormal() {
    return 'normal'
}

function cloudFontSize(d) {
    return Math.sqrt(d.value)
}

function cloudRotate() {
    return (~~(Math.random() * 6) - 3) * 30
}

function cloudPadding() {
    return 1
}

// Fetches a monochrome sprite bitmap for the specified text.
// Load in batches for speed.
function cloudSprite(contextAndRatio, d, data, di) {
    if (d.sprite) {
        return
    }
    const { context: c, ratio } = contextAndRatio

    c.clearRect(0, 0, (cw << 5) / ratio, ch / ratio)
    const n = data.length
    let x = 0,
        y = 0,
        maxh = 0
    --di
    while (++di < n) {
        d = data[di]
        c.save()
        let w, h
        if (d.droplets) {
            w = d.width + 2 * (d.padding || 0)
            h = d.height + 2 * (d.padding || 0)
        } else {
            c.font = `${d.style} ${d.fontWeight} ${~~((d.size + 1) / ratio)}px ${d.font}`
            w = c.measureText(`${d.text}m`).width * ratio
            h = d.size << 1
        }

        if (d.rotate) {
            const sr = Math.sin(d.rotate * cloudRadians),
                cr = Math.cos(d.rotate * cloudRadians),
                wcr = w * cr,
                wsr = w * sr,
                hcr = h * cr,
                hsr = h * sr
            w = ((Math.max(Math.abs(wcr + hsr), Math.abs(wcr - hsr)) + 0x1f) >> 5) << 5
            h = ~~Math.max(Math.abs(wsr + hcr), Math.abs(wsr - hcr))
        } else {
            w = ((w + 0x1f) >> 5) << 5
        }

        if (h > maxh) {
            maxh = h
        }
        if (x + w >= cw << 5) {
            x = 0
            y += maxh
            maxh = 0
        }
        if (y + h >= ch) {
            break
        }
        c.translate((x + (w >> 1)) / ratio, (y + (h >> 1)) / ratio)
        if (d.rotate) {
            c.rotate(d.rotate * cloudRadians)
        }
        if (d.text) {
            c.fillText(d.text, 0, 0)
            if (d.padding) {
                c.lineWidth = 2 * d.padding
                c.strokeText(d.text, 0, 0)
            }
        } else if (d.droplets) {
            renderSubCloudWords(c, ratio, d.droplets, d.padding)
        }
        c.restore()
        d.width = w
        d.height = h
        d.xoff = x
        d.yoff = y
        d.x1 = w >> 1
        d.y1 = h >> 1
        d.x0 = -d.x1
        d.y0 = -d.y1
        d.hasText = true
        x += w
    }
    const pixels = c.getImageData(0, 0, (cw << 5) / ratio, ch / ratio).data,
        sprite = []
    while (--di >= 0) {
        d = data[di]
        if (!d.hasText) {
            continue
        }
        const w = d.width,
            w32 = w >> 5
        let h = d.y1 - d.y0
        // Zero the buffer
        for (let i = 0; i < h * w32; i++) {
            sprite[i] = 0
        }
        x = d.xoff
        if (isNil(x)) {
            return
        }
        y = d.yoff
        let seen = 0,
            seenRow = -1
        for (let j = 0; j < h; j++) {
            for (let i = 0; i < w; i++) {
                const k = w32 * j + (i >> 5),
                    m = pixels[((y + j) * (cw << 5) + (x + i)) << 2] ? 1 << (31 - (i % 32)) : 0
                sprite[k] |= m
                seen |= m
            }
            if (seen) {
                seenRow = j
            } else {
                d.y0++
                h--
                j--
                y++
            }
        }
        d.y1 = d.y0 + seenRow
        d.sprite = sprite.slice(0, (d.y1 - d.y0) * w32)
    }
}

function renderSubCloudWords(ctx, ratio, words, padding) {
    forEach(words, (droplet) => {
        ctx.font = `${droplet.style} ${droplet.fontWeight} ${~~((droplet.size + 1) / ratio)}px ${droplet.font}`
        const dropletText = droplet.text
        const dropletX = droplet.x
        const dropletY = droplet.y
        ctx.fillText(dropletText, dropletX, dropletY)
        if (padding) {
            ctx.lineWidth = 2 * padding
            ctx.strokeText(dropletText, dropletX, dropletY)
        }
    })
}

// Use mask-based collision detection.
function cloudCollide(tag, board, sw) {
    sw >>= 5
    const { sprite } = tag,
        w = tag.width >> 5,
        lx = tag.x - (w << 4),
        sx = lx & 0x7f,
        msx = 32 - sx,
        h = tag.y1 - tag.y0
    let x = (tag.y + tag.y0) * sw + (lx >> 5),
        last
    for (let j = 0; j < h; j++) {
        last = 0
        for (let i = 0; i <= w; i++) {
            if (((last << msx) | (i < w ? (last = sprite[j * w + i]) >>> sx : 0)) & board[x + i]) {
                return true
            }
        }
        x += sw
    }
    return false
}

function cloudBounds(bounds, d) {
    const [b0, b1] = bounds
    if (d.x + d.x0 < b0.x) {
        b0.x = d.x + d.x0
    }
    if (d.y + d.y0 < b0.y) {
        b0.y = d.y + d.y0
    }
    if (d.x + d.x1 > b1.x) {
        b1.x = d.x + d.x1
    }
    if (d.y + d.y1 > b1.y) {
        b1.y = d.y + d.y1
    }
}

function collideRects(a, b) {
    return a.x + a.x1 > b[0].x && a.x + a.x0 < b[1].x && a.y + a.y1 > b[0].y && a.y + a.y0 < b[1].y
}
