import { useEffect, useRef } from 'react'
import { LegacyButton as Button } from '@energage/components'
import { Close } from '@energage/icons'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { Dialog, DialogBackdrop, useDialogState } from 'reakit/Dialog'
import type { DialogStateReturn } from 'reakit/Dialog'
import styled from 'styled-components'
import ssoFailureImage from 'images/sso_failure.svg'
import ssoSuccessImage from 'images/sso_success.png'
import auth from 'util/auth'

const Modal = styled(Dialog).attrs({ className: 'reakit-modal flex flex-col gap-10 pb-10 items-end' })`
    max-width: 1240px;
`

function connectionMatchesUser(expectedConnectionName: string | undefined, userConnectionName: string) {
    if (!expectedConnectionName || !userConnectionName) {
        return false
    }

    return userConnectionName === expectedConnectionName
}

type SsoModalParams = {
    dialog: DialogStateReturn
}

const SsoSuccessModal = ({ dialog }: SsoModalParams) => (
    <DialogBackdrop className="reakit-modal__backdrop" {...dialog}>
        <Modal tabIndex={0} {...dialog} aria-label="SSO Success">
            <button
                className="p-5"
                onClick={dialog.hide}
                aria-label="Close SSO Success"
                type="button"
                data-event-id={`sso-close`}>
                <Close size={55} />
            </button>
            <div className="grid grid-flow-col grid-cols-2 pl-10 items-center">
                <div>
                    <h2 className="text-black">{'Hurray! Your SSO Connection is successful!'}</h2>
                    <p className="paragraph-lead py-10">
                        {
                            'When you’re ready to turn on SSO for your entire organization, you can go back to settings and turn on the Single Sign On toggle.'
                        }
                    </p>
                    <Button onClick={dialog.hide} className="my-4">
                        {'Return to settings'}
                    </Button>
                </div>
                <img alt={'SSO Test Success'} src={ssoSuccessImage} />
            </div>
        </Modal>
    </DialogBackdrop>
)

const SsoFailureModal = ({ dialog }: SsoModalParams) => (
    <DialogBackdrop className="reakit-modal__backdrop" {...dialog}>
        <Modal tabIndex={0} {...dialog} aria-label="SSO Failed">
            <button
                className="p-5"
                onClick={dialog.hide}
                aria-label="Close SSO Failed"
                type="button"
                data-event-id={`sso-close`}>
                <Close size={55} />
            </button>
            <div className="grid grid-flow-col grid-cols-2 pl-40 items-center">
                <div>
                    <h2 className="text-black">{'Oh no! Your SSO Connection was unsuccessful!'}</h2>
                    <p className="paragraph-lead py-10">
                        {
                            'Something went wrong. Please check your connection details and make sure they match the information in your identity provider.'
                        }
                    </p>
                    <Button onClick={dialog.hide} className="my-4">
                        {'Return to settings'}
                    </Button>
                </div>
            </div>
            <img className="float-right pr-10" alt={'SSO Test Failed'} src={ssoFailureImage} />
        </Modal>
    </DialogBackdrop>
)

export const SsoCheckModal = () => {
    const dialog = useDialogState({ visible: true })
    const history = useHistory()
    const { search } = useLocation()
    const queryParams = parse(search)
    const success = connectionMatchesUser(queryParams?.connection?.toString(), auth.getUserData()?.connection_name)

    const previousVisibleRef = useRef(dialog.visible)

    useEffect(() => {
        if (previousVisibleRef.current !== dialog.visible && !dialog.visible) {
            if (queryParams.sso) {
                delete queryParams.sso_check
                delete queryParams.connection
                history.replace({
                    search: stringify(queryParams),
                })
            }
        }
        previousVisibleRef.current = dialog.visible
    }, [dialog.visible, history, queryParams])

    return success ? <SsoSuccessModal dialog={dialog} /> : <SsoFailureModal dialog={dialog} />
}
