import { useEffect } from 'react'
import { RouterProvider } from 'react-aria-components'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ApiQueryClient } from 'api'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { AuthenticationManager } from 'components/Authentication'
import ExternalAnalytics from 'components/ExternalAnalytics'
import FullPageErrorBoundary from 'components/FullPageErrorBoundary'
import { Permission } from 'constants/permission'
import { routes } from 'constants/routes'
import Callback, { LoginDenied } from 'containers/Callback'
import Logout from 'containers/Logout'
import LogoutCallback from 'containers/LogoutCallback'
import Main from 'containers/Main'
import Blocked from 'containers/Main/Blocked'
import StatementDownload from 'containers/Main/Survey/StatementDownload'
import Organization from 'containers/Organization'
import Public from 'containers/Public'
import SurveyPreview from 'containers/Public/SurveyPreview'

const AuthenticatedApp = () => (
    <AuthenticationManager>
        <ExternalAnalytics />
        <Switch>
            <Route path={routes.blocked()} component={Blocked} />
            <AuthenticatedRoute path={routes.organization()} component={Organization} />
            <AuthenticatedRoute
                authorize={Permission.SurveyPlatform}
                path={routes.survey.statementDownload()}
                component={StatementDownload}
            />
            <AuthenticatedRoute
                authorize={Permission.SurveyPlatform}
                path={routes.survey.surveyPreview()}
                component={SurveyPreview}
            />
            <AuthenticatedRoute path="/" component={Main} />
        </Switch>
    </AuthenticationManager>
)

const SHADOW_SCROLL_THRESHOLD = 50

function useScrollShadow() {
    useEffect(() => {
        function handleScroll() {
            const hasShadow = document.body.classList.contains('has-shadow')
            if (window.scrollY > SHADOW_SCROLL_THRESHOLD && !hasShadow) {
                document.body.classList.add('has-shadow')
            } else if (window.scrollY <= SHADOW_SCROLL_THRESHOLD && hasShadow) {
                document.body.classList.remove('has-shadow')
            }
        }

        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])
}

function App() {
    useScrollShadow()
    const history = useHistory()

    return (
        <RouterProvider navigate={history.push}>
            <QueryClientProvider client={ApiQueryClient}>
                <FullPageErrorBoundary>
                    <Switch>
                        <Route path={routes.loginCallback()} component={Callback} />
                        <Route path={routes.logout()} component={Logout} />
                        <Route path={routes.logoutCallback()} component={LogoutCallback} />
                        <Route path={routes.loginDenied()} component={LoginDenied} />
                        <Route path={routes.publicPages()} component={Public} />
                        <Route path="/" component={AuthenticatedApp} />
                    </Switch>
                </FullPageErrorBoundary>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </RouterProvider>
    )
}

export default App
