import range from 'lodash/range'
import { TextInputSmallPreview } from '../CustomQuestionTextInputs'

export type MultiCommentPreviewProps = {
    textboxCount: number
}

const multiCommentWordDescriptors = {
    0: 'First',
    1: 'Second',
    2: 'Third',
    3: 'Fourth',
    4: 'Fifth',
}

export function MultiCommentPreview({ textboxCount }: MultiCommentPreviewProps) {
    return (
        <div className="flex w-full flex-wrap">
            {range(textboxCount).map((count) => (
                <TextInputSmallPreview
                    className={`${count + 1 === textboxCount ? '' : 'mr-2'} mb-1 focus:outline-none flex flex-none`}
                    type="text"
                    placeholder={`${
                        multiCommentWordDescriptors[count as keyof typeof multiCommentWordDescriptors]
                    } Word`}
                    disabled
                    readOnly
                />
            ))}
        </div>
    )
}
