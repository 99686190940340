import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, UserButton, UserMenu as Menu } from '@energage/components'
import cx from 'clsx'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { selectSurveyEventId } from 'store'
import useGetNavItems from './getNavItems'

const UserMenu = ({ identity }) => {
    const { initials, organizationName, emailAddress, fullName, userTitle } = identity
    const targetedSurveyEventId = useSelector(selectSurveyEventId)
    const navItems = useGetNavItems(identity, targetedSurveyEventId)

    return (
        <Dropdown
            ariaLabel={`User icon for ${identity.fullName}`}
            showArrow
            placement="bottom-end"
            trigger={(popover) => (
                <UserButton className={cx({ active: popover.visible })} initials={identity.initials} />
            )}>
            <Menu data-testid="account-manager">
                <Menu.Header
                    initials={initials}
                    fullName={fullName}
                    role={userTitle}
                    email={emailAddress}
                    company={organizationName}
                />
                {map(navItems, (group, i) => {
                    const Group = group.isInternal ? Menu.InternalGroup : Menu.Group
                    return (
                        !group.hidden && (
                            <Fragment key={`group${i}`}>
                                <Group key={i} title={group.title}>
                                    {map(group.items, (item) => {
                                        const Item = item.icon ? Menu.IconItem : Menu.Item
                                        return (
                                            !item.hidden && (
                                                <Item key={item.label} {...item.props} icon={item.icon}>
                                                    {item.label}
                                                </Item>
                                            )
                                        )
                                    })}
                                </Group>
                                {i === 0 && <Menu.Divider />}
                            </Fragment>
                        )
                    )
                })}
            </Menu>
        </Dropdown>
    )
}

UserMenu.propTypes = {
    identity: PropTypes.object.isRequired,
}

export default UserMenu
