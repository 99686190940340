import { Checkbox } from '@energage/components'
import { colors } from '@energage/theme'
import styled from 'styled-components'

export const FocusCheckbox = styled(Checkbox)<{ disabled?: boolean; $error?: boolean }>`
    ${(props) => {
        if (props.$error) {
            return `
             && input:focus-visible + .checkbox__indicator {
                outline: 1px solid ${colors.red500};
             }
            `
        }
        return null
    }}

    cursor: ${(props) => (props.disabled ? 'not-allowed' : null)};
`
