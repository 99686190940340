import styled from 'styled-components'
import { QuestionType } from '../QuestionType'
import {
    AgreementPreview,
    CommentPreview,
    FeedbackPreview,
    MultiChoicePreview,
    MultiCommentPreview,
    RatingPreview,
    TrueFalsePreview,
    YesNoPreview,
} from './QuestionPreviews'
import type {
    AgreementPreviewProps,
    CommentPreviewProps,
    MultiChoicePreviewProps,
    MultiCommentPreviewProps,
    TrueFalsePreviewProps,
    YesNoPreviewProps,
} from './QuestionPreviews'

const Error = ({ message }: { message: string }) => <div>{message}</div>

const PreviewBox = styled.div.attrs({ className: 'w-full px-4 py-6 border border-grey400 inline-block bg-white' })`
    border-radius: 8px;
`

const customQuestionTitle = {
    [QuestionType.Agreement]: 'Statement Preview',
    [QuestionType.Comment]: 'Open-ended Question Preview',
    [QuestionType.Feedback]: 'Feedback Preview',
    [QuestionType.MultiChoice]: 'Multiple Choice Question Preview',
    [QuestionType.MultiComment]: 'MultiComment Preview',
    [QuestionType.Rating]: 'Rating Preview',
    [QuestionType.TrueFalse]: 'True or False Preview',
    [QuestionType.YesNo]: 'Yes or No Preview',
}

const { Agreement, Comment, Rating, YesNo, Feedback, MultiComment, MultiChoice, TrueFalse } = QuestionType

type CustomQuestionPreviewProps =
    | ({
          questionTypeId: typeof Agreement
      } & AgreementPreviewProps)
    | ({ questionTypeId: typeof Comment } & CommentPreviewProps)
    | { questionTypeId: typeof Rating }
    | ({ questionTypeId: typeof YesNo } & YesNoPreviewProps)
    | ({ questionTypeId: typeof MultiComment } & MultiCommentPreviewProps)
    | { questionTypeId: typeof Feedback }
    | ({ questionTypeId: typeof TrueFalse } & TrueFalsePreviewProps)
    | ({ questionTypeId: typeof MultiChoice } & MultiChoicePreviewProps)

const CustomQuestionPreview = (props: CustomQuestionPreviewProps) => {
    const questionTitle = customQuestionTitle[props.questionTypeId]
    let Preview

    switch (props.questionTypeId) {
        case Agreement:
            Preview = () => <AgreementPreview {...props} />
            break
        case Comment:
            Preview = () => <CommentPreview {...props} />
            break
        case Rating:
            Preview = () => <RatingPreview />
            break
        case YesNo:
            Preview = () => <YesNoPreview {...props} />
            break
        case MultiComment:
            Preview = () => <MultiCommentPreview {...props} />
            break
        case Feedback:
            Preview = () => <FeedbackPreview />
            break
        case TrueFalse:
            Preview = () => <TrueFalsePreview {...props} />
            break
        case MultiChoice:
            Preview = () => <MultiChoicePreview {...props} />
            break
        default:
            break
    }

    if (!Preview) {
        return <Error message={`Unknown question type ID: ${props.questionTypeId}`} />
    }

    return (
        <div className="h-full py-3 w-full sm:w-1/2 max-w-md">
            <div className="h5">{'Item Preview'}</div>
            <PreviewBox>
                <div className="text-primary-dark font-bold text-base">{questionTitle}</div>
                <Preview />
            </PreviewBox>
        </div>
    )
}

export default styled(CustomQuestionPreview).attrs({ className: 'pt-6' })`
    pointer-events: none;
`
