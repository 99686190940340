export const LINK_COLOR_CLASSES = 'text-purple700 hover:text-purple600 active:text-purple900'

export const AwardType = {
    TwpUsa: 'Top Workplaces USA',
    CE: 'Culture Excellence',
    Industry: 'Industry Award',
}

export const AwardStatus = {
    Twp: 'Top Workplace',
    InProgress: 'In Progress',
    NotNamed: 'Not Named',
    NationalStandard: 'National Standard',
}

export const AwardHistoryColumns = {
    TwpAward: 'twpAward',
    RegionOrCategory: 'regionOrCategory',
    Survey: 'survey',
    Year: 'year',
    Outcome: 'outcome',
}

export const NEW_SALES_BLOCKER = 2

export const DUPLICATE_LOCATION_ERROR =
    'Duplicate Location. There is already a location entered with the same address, city, state, and zip code. Please update this entry to be unique or remove it.'

export const MANUAL_UPLOAD_TYPE = 1
export const BULK_UPLOAD_TYPE = 2

export const TWP_USA_AWARD_ID = 55
export const REMOTE_WORK_AWARD_ID = 93
export const WOMEN_LED_AWARD_ID = 75

export const AwardSet = {
    CultureExcellence: 1,
    Industry: 2,
}

export const LocationType = {
    Headquarters: 1,
    NonHeadquarters: 2,
}

export const AwardsCategory = {
    All: 0,
    Qualified: 1,
    History: 11,
}

export const ELIGIBLE_AWARDS_SUBTEXT =
    'Based on the information you have provided, below are awards you may be eligible to participate in.'

export const FIRST_DEI_FORM_CATEGORY = 1
export const LAST_DEI_FORM_CATEGORY = 6
