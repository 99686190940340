import { FormatPaint, RemoveRedEye } from '@energage/icons'
import { colors } from '@energage/theme'
import filter from 'lodash/filter'
import map from 'lodash/map'
import styled from 'styled-components'
import { pluralizeNounOnly } from 'util/pluralize'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryCard } from '../../Common/SummaryCard'
import { PreviewSurveyButton } from './SummarySurveyButton'

const SelectedStatementsArea = styled.div`
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    word-wrap: break-word;

    @media (min-width: 768px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
`

export const SurveySetupComplete = ({
    title,
    surveyEventId,
    previewUrl,
    standardStatementsCount,
    customStatementsCount,
    questionBankStatementsCount,
    demographicQuestionsCount,
    commentQuestionsCount,
    //    conditionalQuestionsCount,
    regionalStatementsCount,
}) => {
    const statements = [
        { count: standardStatementsCount, text: 'standard statement' },
        { count: commentQuestionsCount, text: 'comment question' },
        { count: customStatementsCount, text: 'custom item' },
        { count: questionBankStatementsCount, text: 'question bank item' },
        { count: demographicQuestionsCount, text: 'demographic question' },
        { count: regionalStatementsCount, text: 'regional specific statement' },
        { text: 'conditional questions', alwaysShow: true },
    ]

    const filteredStatements = filter(statements, ({ count, alwaysShow }) => count > 0 || alwaysShow)
    const statementsTextArray = map(filteredStatements, ({ count, text }) =>
        text === 'conditional questions' ? text : `${count} ${pluralizeNounOnly(count, text)}`
    )

    const statementsText =
        'Your survey includes ' +
        statementsTextArray.slice(0, -1).join(', ') +
        (statementsTextArray.length > 1 ? ', & ' : '') +
        statementsTextArray.slice(-1) +
        '.'

    return (
        <SummaryCard padding="tight">
            <CardIcon>
                <FormatPaint fill={colors.grey500} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="mb-2">{title}</CardTitle>
                    <SelectedStatementsArea>{statementsText}</SelectedStatementsArea>
                </CardContent>
                <PreviewSurveyButton
                    previewUrl={previewUrl}
                    variant="link"
                    surveyEventId={surveyEventId}
                    buttonText={
                        <div className="flex-auto">
                            <RemoveRedEye size={22} /> <span>{`Preview`}</span>
                        </div>
                    }
                />
            </CardBody>
        </SummaryCard>
    )
}
