import { Info } from '@energage/icons'
import { colors } from '@energage/theme'
import filter from 'lodash/filter'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import SimpleTooltip from 'components/SimpleTooltip'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/strings'
import { HAS_AGREED, HAS_NOT_AGREED } from 'containers/Main/EmployerRecognition/Shared/AwardSelectionBox/constants'
import { modifyRegionName } from 'containers/Main/EmployerRecognition/utils'
import type { AwardRegistrationState, Data, TObject } from './awardRegistration.types'
import { LocationFormOptions, WOMEN_LED_AWARD_CONFIRM_HEADING } from './constants'

const SECTOR_TOOLTIP = 'Please contact support if you wish to change Sector or Sub sector details'
const SECTOR_DESCRIPTION_TOOLTIP =
    "Please provide your organization's industry in your own words. This description is used when publishing company profiles in recognition awards. (<100 characters)"

const commonClass = 'w-full mb-5'
const locationCommonClass = 'w-full mb-5 address-input'

const modifyPublisherName = (name: string) => replace(name, 'The ', '')

function getLeaderInfoLabel(regionName: string, publisherName: string) {
    return (
        <>
            {`${modifyRegionName(regionName)} Leader Information`}
            <SimpleTooltip
                placement="top"
                trigger={<Info className="ml-1" size={18} color={colors.blue600} />}
                className="m-0 inline-block">
                <div className="w-48">
                    {`Leader information is required by the ${modifyPublisherName(
                        publisherName
                    )} for award participation. For Top Workplaces winners, ${modifyPublisherName(
                        publisherName
                    )} wants to understand who the top day-to-day leader of the organization is. Leaders also may be eligible for special awards based on survey feedback from employees.`}
                </div>
            </SimpleTooltip>
        </>
    )
}

const getLocationFields = (states: AwardRegistrationState[], fieldName?: string) => ({
    streetAddress: {
        label: 'Address',
        fieldName: fieldName ? `${fieldName}.streetAddress` : 'streetAddress',
        type: 'text',
        className: locationCommonClass,
        required: true,
    },
    index: {
        fieldName: fieldName ? `${fieldName}.index` : 'index',
        type: 'hidden',
    },
    city: {
        label: 'City',
        fieldName: fieldName ? `${fieldName}.city` : 'city',
        type: 'text',
        className: locationCommonClass + ' sm:w-1/2 sm:pr-2',
        required: true,
    },
    stateAbbreviation: {
        label: 'State',
        fieldName: fieldName ? `${fieldName}.stateAbbreviation` : 'stateAbbreviation',
        type: 'dropdown',
        options: states,
        className: locationCommonClass + ' sm:w-1/4 sm:pr-2',
        required: true,
    },
    zipCode: {
        label: 'Zip Code',
        fieldName: fieldName ? `${fieldName}.zipCode` : 'zipCode',
        type: 'number',
        className: locationCommonClass + ' sm:w-1/4',
        required: true,
    },
})

const contactInformation = (fieldSet: string, isEmailRequired = true) => ({
    contactId: {
        fieldName: fieldSet + '.contactId',
        hidden: true,
        className: 'hidden',
    },
    firstName: {
        label: 'First Name',
        fieldName: fieldSet + '.firstName',
        type: 'text',
        className: commonClass + ' sm:w-1/2 sm:pr-2',
        seq: 1,
        required: true,
    },
    lastName: {
        label: 'Last Name',
        fieldName: fieldSet + '.lastName',
        type: 'text',
        className: commonClass + ' sm:w-1/2',
        seq: 2,
        required: true,
    },
    title: {
        label: 'Title',
        fieldName: fieldSet + '.title',
        type: 'text',
        className: commonClass + ' sm:w-1/2 sm:pr-2',
        seq: 3,
        required: true,
    },
    emailAddress: {
        label: 'Email',
        fieldName: fieldSet + '.emailAddress',
        type: 'text',
        className: commonClass + ' sm:w-1/2',
        seq: 4,
        required: isEmailRequired,
    },
})

export function getfieldSet(
    data: Data,
    showAwardValidation: boolean,
    sector: TObject | null,
    isRemoteWorkAward: boolean,
    isWomenLedAward: boolean,
    locationStatus: number | undefined
) {
    const {
        parentSectors,
        childSectors,
        ownershipTypes,
        contactTypes,
        states,
        hasFullyRemoteParticipation,
        awardRegistrationInfo: {
            awardName,
            publisher: { name: publisherName, isNational },
            region: { name: regionName, minCount: minEmpCount },
            locations,
        },
    } = data
    const relevantChildSectors = sector ? filter(childSectors, ['parentId', sector.id]) : []

    return {
        awardListParticipantId: {
            fieldName: 'awardListParticipantId',
            hidden: true,
            className: 'hidden',
        },
        awardId: {
            fieldName: 'awardId',
            hidden: true,
            className: 'hidden',
        },
        salesforceYear: {
            fieldName: 'salesforceYear',
            hidden: true,
            className: 'hidden',
        },
        organization: {
            heading: 'Organization Information',
            isVisible: true,
            fields: {
                companyName: {
                    label: 'Company Name, Formal Name (as it will appear in publication)',
                    fieldName: 'organization.companyName',
                    type: 'text',
                    className: commonClass,
                    required: true,
                },
                yearFounded: {
                    label: 'Year Founded',
                    fieldName: 'organization.yearFounded',
                    type: 'number',
                    className: commonClass + ' sm:w-1/2 sm:pr-2',
                    required: true,
                },
                ownershipTypeId: {
                    label: 'Ownership ',
                    fieldName: 'organization.ownershipTypeId',
                    type: 'dropdown',
                    options: ownershipTypes,
                    className: commonClass + ' sm:w-1/2',
                    required: true,
                },
                parentSector: {
                    label: 'Sector',
                    fieldName: 'organization.parentSector',
                    type: 'dropdown',
                    options: parentSectors,
                    tooltip: SECTOR_TOOLTIP,
                    className: commonClass + ' sm:w-1/2 sm:pr-2',
                    required: true,
                },
                childSector: {
                    label: 'Sub Sector',
                    fieldName: 'organization.childSector',
                    type: 'dropdown',
                    options: relevantChildSectors,
                    tooltip: SECTOR_TOOLTIP,
                    className: commonClass + ' sm:w-1/2',
                    required: true,
                },
                sectorDescription: {
                    label: 'Sector Description',
                    fieldName: 'organization.sectorDescription',
                    type: 'text',
                    tooltip: SECTOR_DESCRIPTION_TOOLTIP,
                    className: commonClass,
                    required: true,
                },
                isSectorApproved: {
                    fieldName: 'organization.isSectorApproved',
                    hidden: true,
                    className: 'hidden',
                },
            },
        },
        headquarter: {
            heading: 'United States Headquarter',
            isVisible: !hasFullyRemoteParticipation && isNational,
            employeeCount: null,
            name: '',
            fields: getLocationFields(states, 'locations[0]'),
        },
        primaryContact: {
            heading: modifyRegionName(regionName) + ' Primary Contact Information',
            isVisible: true,
            fields: sortBy(
                {
                    ...contactInformation('primaryContact'),
                    contactTypeId: {
                        label: 'Area',
                        fieldName: 'primaryContact.contactTypeId',
                        type: 'dropdown',
                        options: contactTypes,
                        className: commonClass + ' sm:w-1/2 sm:pr-2',
                        seq: 5,
                        required: true,
                    },
                    phoneNumber: {
                        label: 'Phone',
                        fieldName: 'primaryContact.phoneNumber',
                        type: 'text',
                        className: commonClass + ' sm:w-1/2',
                        seq: 6,
                        required: true,
                    },
                },
                ['seq']
            ),
        },
        leaderContact: {
            heading: getLeaderInfoLabel(regionName, publisherName),
            isVisible: true,
            fields: { ...contactInformation('leaderContact', false) },
        },
        remoteAward: {
            heading: `${awardName} requirements`,
            subTitle: (
                <span>
                    {`Please confirm the following the requirements below. You must meet these requirements in order to participate in ${awardName}`}
                </span>
            ),
            isVisible: isRemoteWorkAward,
            fields: {
                isRemoteWorkAward: {
                    fieldName: 'isRemoteWorkAward',
                    hidden: true,
                    className: 'hidden',
                },
                isRemoteAwardSelected: {
                    fieldName: 'hasAgreedToEligibilityCriteria',
                    hidden: true,
                    className: 'hidden',
                },
                confirmRemoteAwardChkOne: {
                    label: "At least 50% of my organization's workforce is mostly or fully remote.",
                    type: 'checkbox',
                    fieldName: 'remoteWorkAward.confirmRemoteAwardChkOne',
                    value: 0,
                    className: showAwardValidation ? 'text-red400 mt-2' : 'mt-2',
                    required: true,
                },
                confirmRemoteAwardChkTwo: {
                    label: `My organization is committed to hiring remote employees in the future.`,
                    type: 'checkbox',
                    fieldName: 'remoteWorkAward.confirmRemoteAwardChkTwo',
                    value: 1,
                    className: showAwardValidation ? 'text-red400 mt-2' : 'mt-2',
                    required: true,
                },
            },
        },
        womenLedAward: {
            heading: WOMEN_LED_AWARD_CONFIRM_HEADING,
            subTitle: (
                <span>{`Please confirm the following requirement in order to participate in the ${awardName} award.`}</span>
            ),
            isVisible: isWomenLedAward,
            fields: {
                isWomenLedAward: {
                    fieldName: 'isWomenLedAward',
                    hidden: true,
                    className: 'hidden',
                },
                isWomenLedAwardSelected: {
                    fieldName: 'hasAgreedToEligibilityCriteria',
                    hidden: true,
                    className: 'hidden',
                },
                confirmWomenLedAwardChk: {
                    options: [
                        {
                            label: 'Is your most senior executive at your organization a woman? If the most senior role is shared, at least 50% representation is required. Can you confirm your organization qualifies for this award?',
                            value: HAS_AGREED,
                        },
                        {
                            label: 'My most senior executive is not a woman, nor is there at least 50% representation in a shared role. I do not qualify for this award.',
                            value: HAS_NOT_AGREED,
                        },
                    ],
                    label: '',
                    type: 'radio',
                    fieldName: 'womenLedAward.confirmWomenLedAwardChk',
                    className: showAwardValidation ? 'text-red400 mt-2' : 'mt-2',
                    required: true,
                },
            },
        },
        locationRadioButtonForm: {
            heading: (
                <>
                    {`Which option below best applies to your organization in ${regionName}?`}
                    <span className="input-group__required">{' *'}</span>
                </>
            ),
            isVisible: locations.length === 0,
            fieldKey: 'LocationRadioButtonForm',
            fields: {
                options: {
                    type: 'radio',
                    fieldName: 'locationStatus',
                    required: true,
                    options: [
                        {
                            value: LocationFormOptions.HasLocation,
                            label: `I have at least one location in ${regionName}.`,
                        },
                        {
                            value: LocationFormOptions.HasMinRemoteEmpCount,
                            label: `I have no locations, but I do have at least ${minEmpCount} remote employees in ${regionName}.`,
                        },
                        {
                            value: LocationFormOptions.NoMinEmpCount,
                            label: `I have no locations and fewer than ${minEmpCount} remote employees in ${regionName}. I do not qualify.`,
                        },
                    ],
                },
            },
        },
        locations: {
            fieldKey: 'LocationForm',
            heading: `${modifyRegionName(regionName)} Location`,
            isVisible:
                !hasFullyRemoteParticipation &&
                !isNational &&
                (locations.length > 0 || locationStatus === LocationFormOptions.HasLocation),
            subTitle: (
                <span>
                    {
                        'Please provide your regional locations. Remote workers can be included in the location they report to. Have a lot of locations to upload? Contact our support team at '
                    }
                    <a href={'mailto:' + SUPPORT_EMAIL} className="btn-link">
                        {SUPPORT_EMAIL}
                    </a>
                    {` or ${SUPPORT_PHONE}`}
                </span>
            ),
            fields: {
                name: {
                    label: 'Location Nickname',
                    fieldName: 'name',
                    type: 'text',
                    className: commonClass + ' sm:w-2/3 sm:pr-2',
                    required: true,
                },
                employeeCount: {
                    label: 'Location Employee Count',
                    fieldName: 'employeeCount',
                    type: 'number',
                    className: commonClass + ' sm:w-1/3',
                    required: true,
                },
                ...getLocationFields(states),
            },
        },
    }
}
