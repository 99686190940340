import { LegacyButton as Button } from '@energage/components'
import pluralize from 'pluralize'
import { withRouter } from 'react-router-dom'
import { ExternalLink } from 'components/Anchor'

const SmsAsInvitationOptionDialog = ({
    inviteeCountWithPhoneNumberAndConsent,
    onConfirmSmsInvitationOption,
    disabled = false,
}) => {
    return (
        <div className="w-full">
            <h5>
                {`We noticed ${pluralize('employee', inviteeCountWithPhoneNumberAndConsent, true)} ${
                    inviteeCountWithPhoneNumberAndConsent > 1 ? 'are' : 'is'
                } eligible to receive SMS invitations. Would you like to use SMS messaging as an invitation option?`}
            </h5>
            <p className="py-2">
                {`By clicking yes, you are confirming that you've received explicit consent from the employees that will receive text message invitations. `}
                <ExternalLink href="https://www.energage.com/sms-terms/" target="_blank" rel="noopener noreferrer">
                    {'View SMS Terms'}
                </ExternalLink>
            </p>
            <p className="py-2">{`*SMS invitations are only eligible for responders located in the US and Canada.`}</p>
            <div className="my-2 flex flex-col sm:flex-row content-center">
                <Button
                    data-test-id="confirm-sms-consent"
                    variant="secondary"
                    className="mr-4 mb-2"
                    eventName="Confirm Sms Consent"
                    disabled={disabled}
                    onClick={() => {
                        onConfirmSmsInvitationOption(true)
                    }}>
                    {`Yes, I Have Consent`}
                </Button>
                <Button
                    data-test-id="dont-confirm-sms-consent"
                    variant="secondary"
                    className="mr-4 mb-2"
                    eventName="Do Not Confirm Sms Consent"
                    outline
                    disabled={disabled}
                    onClick={() => {
                        onConfirmSmsInvitationOption(false)
                    }}>
                    {`No, don't send SMS`}
                </Button>
            </div>
        </div>
    )
}

export default withRouter(SmsAsInvitationOptionDialog)
