import PropTypes from 'prop-types'
import { HeaderCell, HeaderRow, Table } from '../TableElements'

const OptionalItemTable = ({ categoryId, children, questionColumnName = 'Item' }) => {
    return (
        <Table>
            <HeaderRow>
                <HeaderCell className="w-1/3 md:w-1/6">
                    <div className="pr-4 md:pr-2">{'Topic'}</div>
                </HeaderCell>
                <HeaderCell className="w-2/3 md:w-1/3">
                    <label htmlFor={categoryId}>{questionColumnName}</label>
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/2 pr-2">
                    {'Details'}
                </HeaderCell>
            </HeaderRow>
            <div className="w-full">{children}</div>
        </Table>
    )
}

OptionalItemTable.propTypes = {
    categoryId: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
}

export default OptionalItemTable
