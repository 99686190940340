import config from 'config'
import withClassName from 'style/util/withClassName'

export const UPCOMING_AWARD_URL = `${config.urls.topWorkplaces}/awards-schedule/`

const LineBreaker = withClassName('my-3', 'div', 'div')

const Link = ({ href, target, text }) => {
    return (
        <a className="text-blue600 font-bold underline" target={target} rel="noopener noreferrer" href={href}>
            {text}
        </a>
    )
}

const getMediaKitUrl = (
    isMediaKitAnnouncement,
    mediaKitExternalLink,
    isPdf,
    surveyCompanyId,
    year,
    isCEAward,
    isIndustryAward,
    baseUrl
) => {
    if (isPdf) {
        return `${baseUrl}branding/media-kit?id=${surveyCompanyId}&${year}&isCEAward=${isCEAward}&isIndustryAward=${isIndustryAward}`
    }

    return !isMediaKitAnnouncement ? mediaKitExternalLink : '#'
}

export const getFaqsForWinner = (
    isPdf,
    companyProfileUrl,
    mediaKitExternalLink,
    isMediaKitAnnouncement,
    baseUrl,
    surveyCompanyId,
    year,
    isCEAward,
    isIndustryAward
) => [
    {
        question: 'How are Top Workplaces awards determined?',
        answer: (
            <>
                {
                    'All Top Workplaces awards are based solely on employee feedback. There are no subjective elements to the process, making Top Workplaces the most credible, authentic employer recognition program.'
                }
                <LineBreaker className="mt-4" />
                {
                    'Top Workplaces are determined on quantitative employee feedback, as well as the statements and demographic information that we collect within the Workplace Survey. Because we have collected data from more than 23 million surveys at over 70,000 organizations, the Energage benchmark is the most accurate benchmark available.'
                }
                <LineBreaker />
                <Link
                    target="_blank"
                    href={`${config.urls.topWorkplaces}/resources/national-top-workplaces-methodology/`}
                    text="Go here for more information about Top Workplaces Methodology."
                />
                <LineBreaker className="mt-8" />
                {
                    'Read more about our methodology for determining TopWorkplaces from Dr. Greg Barnett, Energage Chief People Scientist:'
                }
                <LineBreaker />
                <Link
                    target="_blank"
                    href="https://vimeo.com/538777429/56d31456c5"
                    text="Watch What Makes a Top Workplace"
                />
            </>
        ),
    },
    {
        question: 'Can we use the results from one survey for all Top Workplaces awards?',
        answer: (
            <>
                {
                    'Yes! Completing the Workplace Survey puts you in the running for all Top Workplaces awards determined within 12 months from the close of your survey, including the Top Workplaces USA award, Regional, Industry, and Culture Excellence awards.'
                }
                <LineBreaker className="mt-8" />
                {'For more information:'}
                <LineBreaker />
                <Link
                    target="_blank"
                    href={UPCOMING_AWARD_URL}
                    text="View the Top Workplaces National Awards schedule"
                />
                <LineBreaker />
                <Link
                    target="_blank"
                    href={`${config.urls.topWorkplaces}/regional-program-information/`}
                    text="View the Top Workplaces Regional Awards program information"
                />
            </>
        ),
    },
    {
        question: 'How can we promote our award?',
        answer: (
            <>
                {
                    'First, visit your media kit page. While your media kit may not yet be unlocked, it will be your home base for materials to promote your award. In the media kit, you can access the award logos that you have unlocked, templates to announce your award internally and externally, and ideas for how to use your award across your properties.'
                }
                <LineBreaker />
                <Link
                    target={isPdf && '_blank'}
                    href={getMediaKitUrl(
                        isMediaKitAnnouncement,
                        mediaKitExternalLink,
                        isPdf,
                        surveyCompanyId,
                        year,
                        isCEAward,
                        isIndustryAward,
                        baseUrl
                    )}
                    text="Go to the media kit"
                />
                <LineBreaker className="mt-8" />
                <span>{'Also be sure to update your'}</span>
                <span className="font-black">{' topworkplaces.com '}</span>
                <span>{`profile. This is your hub for promoting what makes your organization great. Promoting your profile on your website's Careers page or third-party recruiting sites is visible evidence that you care about your employee culture.`}</span>
                <LineBreaker />
                <Link target="_blank" href={companyProfileUrl} text="Update profile page" />
            </>
        ),
    },
]

export const getFaqsForAspiring = (companyName, year) => [
    {
        question: 'How is the Top Workplaces list determined?',
        answer: 'Top Workplaces winners are determined by who? Those who know your organization best : your employees. The Top Workplaces program starts with capturing their feedback on what matters most about your culture. Workplace Survey measures 15 culture drivers organized into four themes or "imperatives". Scoring well on these statements indicates a healthy organizaion, which means more engaged employees and better business results. We also measure what we call The Basics, The Leader and Engagement. Each of the statements is measured using seven-point Likert scale, from "Strongly Disagree" to "Neutral" and "Strongly Agree".',
    },
    {
        question: `Are you saying ${companyName} is a bad place to work?`,
        answer: 'Not at all. The Top Workplaces program is highly competitive and only a fraction of the participants win the award. Unfortunately, lots of very good workplaces did not make the list.',
    },
    {
        question: 'Will anyone else know we were not named a Top Workplace?',
        answer: "You and Energage. That's it. Confidentiality is vitally important to us. We do not (and will not) share the names of participating organizations. Energage only provides our media partner with the list of organizations that will be recognized as Top Workplaces.",
    },
    {
        question: 'When is the next chance for my organization to participate in Top Workplace?',
        answer: (
            <>
                <span>{'Your survey results apply to any award for 12 months after your survey closes.'}</span>
                <a
                    className="text-yellow500 font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={UPCOMING_AWARD_URL}>
                    {` Here is the list of awards for ${year}.`}
                </a>
                <span>{' You can resurvey at any time to try to improve your scores.'}</span>
                <div>{'Besides: '}</div>
                <div>
                    <li>
                        {
                            'Regular Employee Surveys provide refreshed data-driven insights to focus your efforts and guide your decision making.'
                        }
                    </li>
                    <li>
                        {`Participation illustrates to employees that you value their opinions and you're committed to making ${companyName}, the best workplace it can be.`}
                    </li>
                    <li>
                        {
                            "There's no negative press when you don't make the list. But there is positive recognition when you do."
                        }
                    </li>
                </div>
            </>
        ),
    },
]
