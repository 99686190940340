import { ButtonLink } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'
import type { AchievementSectionRevamp } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import twpWidgetLogo from 'images/twp-widget-logo.svg'
import { media } from 'style/breakpoints'
import { BgContainer } from '../BgContainer'

const Year = styled.span.attrs({ className: 'absolute' })`
    top: 7.5rem;
    left: ${(props: { isPdf?: boolean }) => (props.isPdf ? '47%' : '41%')};
    font-size: 1.5rem;
    ${media.smMin`
        top: 180px;
        left: 48px;
        font-size: 2rem;
    `}
    color: ${colors.darkblue700};
    @media screen and (min-width: 663px) and (max-width: 765px) {
        left: 45%;
    }
    @media screen and (min-width: 520px) and (max-width: 663px) {
        left: 44%;
    }
    @media screen and (min-width: 400px) and (max-width: 520px) {
        left: 42%;
    }
`

const PdfYear = styled.span.attrs({ className: 'absolute' })`
    top: 80px;
    left: 20px;
    color: ${colors.darkblue700};
    font-size: 1rem;
`

export const Achievement = ({
    awardAliasName,
    shopMerchandiseUrl,
    year,
    isPdf,
    paidMediaKit,
}: AchievementSectionRevamp) => {
    const YearComponent = isPdf ? PdfYear : Year
    const subText = `Show off your achievement! ${awardAliasName} store offers lots of great ways to celebrate and commemorate your award. Please note: American Registry is the only source licensed by Energage for official ${awardAliasName} merchandise.`

    return (
        <BgContainer
            backgroundColor={colors.blue50}
            textColor={colors.grey700}
            className={cx('px-4 md:px-8 sm:px-5 text-white lg:px-48', {
                'pb-8 pt-8 sm:py-8 py-8': !isPdf,
                'py-3': isPdf,
            })}>
            <div
                className={cx('flex', {
                    'flex-col px-10 sm:px-20 py-5 sm:flex-row sm:mt-5': !isPdf,
                    'flex-row p-2': isPdf,
                })}>
                <div className="relative font-bold">
                    <YearComponent>{year}</YearComponent>
                </div>
                <img
                    alt={`Top Workplaces ${year} logo`}
                    src={twpWidgetLogo}
                    className={cx({ 'h-48 sm:h-72': !isPdf, 'h-32': isPdf })}
                />
                <div className={cx('flex-1', { 'sm:text-left text-center sm:ml-12 ml-2': !isPdf, 'ml-5': isPdf })}>
                    <h4
                        className={cx({
                            'pt-6 text-3xl': !isPdf,
                            'pt-2 text-lg': isPdf,
                        })}>{`Get your ${awardAliasName} swag`}</h4>
                    <p className={isPdf ? 'text-sm' : 'text-lg pr-0 sm:pr-16'}>
                        {!paidMediaKit
                            ? subText
                            : `${subText} Access to award assets may need to be purchased in order for American Registry to process orders.`}
                    </p>
                    <div className={cx({ 'sm:mt-12 sm:mb-0 mt-8': !isPdf, 'pt-4': isPdf })}>
                        <ButtonLink appearance="twp" href={shopMerchandiseUrl} target="_blank">
                            {'Shop Merchandise'}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </BgContainer>
    )
}
