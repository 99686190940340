import { colors } from '@energage/theme'
import cx from 'clsx'
import map from 'lodash/map'
import size from 'lodash/size'
import pluralize from 'pluralize'
import Tile from 'containers/Public/RevampWidgets/Tile'
import CultureBadge from './CultureBadge'

const MAX_CULTURE_BADGE_WIDTH = 200

export const BadgeGroup = ({
    badges,
    selectedAlignment,
    footerBackgroundColor,
    organizationName,
    className,
    isRendering,
}) => {
    const containerWidthClassName = cx(
        {
            'w-full xl:w-3/4': !selectedAlignment,
            [`w-full md:${selectedAlignment.className}`]: !!selectedAlignment && !isRendering,
            'w-full': !!isRendering,
        },
        className
    )
    const widthClassName = cx('px-2 py-4 inline-block relative', {
        'w-full': selectedAlignment.width === 1 && selectedAlignment,
        [`w-1/${selectedAlignment.width}`]: selectedAlignment.width !== 1 && selectedAlignment,
        'w-1/2 md:w-1/4 lg:w-1/5': !selectedAlignment,
    })

    return (
        <div className={containerWidthClassName}>
            <Tile width="100%" className="w-full" bgColor={colors.white} footerBackgroundColor={footerBackgroundColor}>
                <div className="p-8 text-black">
                    <h3 className="font-light text-center">{`${organizationName}'s Culture ${pluralize(
                        'Badge',
                        size(badges)
                    )}`}</h3>
                    <div className={cx('text-center', { 'flex justify-center': !selectedAlignment })}>
                        {map(badges, (b, i) => (
                            <div key={i} className={widthClassName}>
                                <CultureBadge
                                    badge={b}
                                    displayUrl={b.svgUrl}
                                    hideDownloadButton
                                    maxWidth={MAX_CULTURE_BADGE_WIDTH}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Tile>
        </div>
    )
}

export default BadgeGroup
