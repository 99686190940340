export const SurveyPageConfiguration = {
    workplace: {
        id: 'workplace',
        showBrowseTemplates: false,
        templatesToShow: 2,
        labels: {
            title: 'Workplace Survey',
            setup: 'Set Up A New Survey',
            finishSetup: 'Finish Setting Up Your Survey',
            surveyHistory: 'Recent Surveys',
            inactiveSurveys: 'Inactive Surveys (Internal Only)',
        },
    },
    pulse: {
        id: 'pulse',
        showBrowseTemplates: true,
        templatesToShow: 3,
        labels: {
            title: 'Pulse',
            setup: 'Set Up a Pulse Survey',
            finishSetup: 'Finish Setting Up Your Pulse',
            surveyHistory: 'Recent Pulses',
            inactiveSurveys: 'Inactive Pulses (Internal Only)',
        },
    },
}
