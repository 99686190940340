import { CardBody, CardContent, CardTitle, SummaryCard } from '../../Common/SummaryCard'

export const ErrorScheduleSet = () => (
    <SummaryCard padding="tight" status="error">
        <CardBody>
            <CardContent className="items-center">
                <CardTitle className="text-red600 mb-2" ariaText="Required: Error">
                    {'Error'}
                </CardTitle>
                <p>{'Something went wrong'}</p>
            </CardContent>
        </CardBody>
    </SummaryCard>
)
