import { useEffect, useState } from 'react'
import { Button } from '@energage/components'
import cx from 'clsx'
import { withRouter } from 'react-router-dom'
import { Accordion, useAccordionGroup } from 'components/Accordion'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { useLazyFetch, usePostWithTaskCompletion } from 'hooks'
import SectionTitle from '../SectionTitle'
import TechnicalPreparationEmailLink from './TechnicalPreparationEmailLink'
import TestEmailLink from './TestEmailLink'
import TestInvitationHistoryTable from './TestInvitationHistoryTable'

const Section = ({ className, title, subtitle, children }) => (
    <div className={cx('flex-1', className)}>
        <h6>{title}</h6>
        {subtitle && <div className="pb-3 pt-1">{subtitle}</div>}
        {children}
    </div>
)

export const whitelistText = {
    title: 'Notify Your Technical Staff',
    subtitle:
        'Use the email template to notify your technical staff of the configurations needed for a successful survey process.',
}

const TestDelivery = ({ id, match, readOnly, isComplete }) => {
    const { surveyCompanyId } = useIdentity()
    const surveyEventId = match.params.surveyEventId
    const [isStepComplete, setStepComplete] = useState(isComplete)
    const { goToNext } = useAccordionGroup()
    const [surveyTestInvitations, setSurveyTestInvitations] = useState([])

    useEffect(() => setStepComplete(isComplete), [isComplete])

    const { doPost: doPostCompleteTask, isPosting } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/304/CompleteSurveyTask`,
        onComplete: () => {
            setStepComplete(true)
            goToNext()
        },
    })

    const submit = () => {
        doPostCompleteTask({ surveyEventId })
    }

    const { doFetch: doFetchTestInvitationsHistory } = useLazyFetch({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/SurveyTestDelivery/${surveyEventId}/History`,
        onComplete: (data) => {
            setSurveyTestInvitations(data)
        },
    })

    const {
        data: testSurveyDeliveryData,
        doFetch,
        isLoading,
    } = useLazyFetch({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/SurveyTestDelivery/${surveyEventId}`,
        onComplete: (data) => {
            setSurveyTestInvitations(data.surveyTestInvitations)
        },
    })

    useEffect(() => {
        doFetch()
    }, [doFetch])

    const { testAvailabilityStatus, techPreparationEmailView } = testSurveyDeliveryData ?? {}

    let message = ''
    switch (testAvailabilityStatus) {
        case 1:
            message =
                'You can experience the survey yourself or send to a co-worker for feedback. This test will also help to catch any configurations that may block your invitations.'
            break
        case 2:
            message = 'Test email invitations cannot be sent after the online survey has started.'
            break
        default:
            message = 'Test email invitations cannot be sent. Please contact Energage.'
    }
    return (
        <Accordion
            id={id}
            title={<SectionTitle title={'Test Your Survey'} isStepComplete={isStepComplete} />}
            onOpen={doFetch}>
            <div data-testid="survey-setup-schedule-test-delivery" className="px-7">
                <div className="flex flex-col md:flex-row ">
                    <Section className="md:pr-2" title={whitelistText.title} subtitle={whitelistText.subtitle}>
                        <TechnicalPreparationEmailLink {...techPreparationEmailView} />
                    </Section>
                    <Section className="pt-5 md:pt-0 md:pl-2" title="Test Your Survey" subtitle={!isLoading && message}>
                        {!isLoading && testAvailabilityStatus === 1 && (
                            <TestEmailLink onEmailSent={doFetchTestInvitationsHistory} />
                        )}
                    </Section>
                </div>
                {!isStepComplete && (
                    <Button
                        appearance="secondary"
                        isDisabled={readOnly || isPosting || isLoading}
                        onPress={submit}
                        className="mt-4">
                        {isPosting ? 'Confirming Test Success...' : 'Confirm Test Success'}
                    </Button>
                )}
                {surveyTestInvitations?.length > 0 && (
                    <TestInvitationHistoryTable testInvitations={surveyTestInvitations} />
                )}
            </div>
        </Accordion>
    )
}

export default withRouter(TestDelivery)
