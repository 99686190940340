import { useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import styled from 'styled-components'
import { monthYearDate } from 'util/formatters'
import type { CarouselControlProps, CultureCloudPreviewProps } from './CultureCloud.types'
import { CultureCloudPreviewTypeOne } from './CultureCloudPreviewTypeOne'
import { CultureCloudPreviewTypeTwo } from './CultureCloudPreviewTypeTwo'
import { StyledCarousel } from './StyledCarousel'

const Button = styled.button`
    border: 3px solid;

    &:focus:not(:focus-visible) {
        outline: none;
    }
`
const renderLeftControls = ({ previousSlide, slideCount }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="rounded-full" aria-label="Previous Preview Image" onClick={previousSlide}>
                <KeyboardArrowLeft size={28} />
            </Button>
        )
    )
}

const renderRightControls = ({ nextSlide, slideCount }: CarouselControlProps) => {
    return (
        slideCount > 1 && (
            <Button className="rounded-full" aria-label="Next Preview Image" onClick={nextSlide}>
                <KeyboardArrowRight size={28} />
            </Button>
        )
    )
}

const renderBottomCenterControls = ({ currentSlide, slideCount }: CarouselControlProps) =>
    slideCount > 1 && `${currentSlide + 1} of ${slideCount}`

export const CultureCloudPreview = ({
    data,
    ariaTitle,
    ariaDescription,
    dataId,
    isExpiredWidget,
    filterData,
    hasEmployerBrandingSubscription,
    minDroplets,
}: CultureCloudPreviewProps) => {
    const department = filterData?.Departments ?? ''
    const location = filterData?.Locations ?? ''
    const jobRole = filterData?.JobRoles ?? ''

    useEffect(() => {
        const sliderDivElements = document.querySelector('.cultureCloud .slider-frame')
        if (sliderDivElements) {
            sliderDivElements.setAttribute('aria-hidden', 'true')
        }
    }, [])

    return (
        <StyledCarousel
            //@ts-ignore
            heightMode=""
            width="449px"
            height="665px"
            className="cultureCloud"
            autoGenerateStyleTag={false}
            wrapAround
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderAnnounceSlideMessage={({ currentSlide }) =>
                currentSlide + 1 === 1
                    ? `${monthYearDate(
                          filterData?.surveyEventName
                      )} ${department} ${location} ${jobRole} Top Workplaces Culture Cloud`
                    : 'New Assets, Elevate your social presence with Culture Cloud'
            }
            renderBottomLeftControls={renderLeftControls}
            renderBottomRightControls={renderRightControls}
            renderBottomCenterControls={renderBottomCenterControls}>
            <CultureCloudPreviewTypeOne
                {...data}
                ariaTitle={ariaTitle}
                ariaDescription={ariaDescription}
                dataId={dataId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                minDroplets={minDroplets}
            />
            <CultureCloudPreviewTypeTwo
                {...data}
                ariaTitle={ariaTitle}
                ariaDescription={ariaDescription}
                dataId={dataId}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                isExpiredWidget={isExpiredWidget}
                filterData={filterData}
                minDroplets={minDroplets}
            />
        </StyledCarousel>
    )
}
