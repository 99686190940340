import includes from 'lodash/includes'

const QuestionCategory = {
    Demographic: -1,
    BusinessOutcomes: 2,
    Template: 12,
    Leader: 16,
    CultureDrivers: 17,
    TheBasics: 18,
    HealthCare: 32,
    OperationalReadiness: 33,
    OperationalReadinessWorkingRemotely: 34,
    OperationalReadinessCommunicationCollaboration: 35,
    OperationalReadinessReturnToWork: 36,
    Regional: 29,
    Custom: 100,
    ThreeWordCulture: 101,
    DEI: 37,
    WorkplaceExperience: 40,
    Other: 234978, // fake category for the frontend to house questions like leader and 3word culture
    Supplemental: 41,
    isStatementQuestionType(categoryId) {
        return includes(
            [
                QuestionCategory.BusinessOutcomes,
                QuestionCategory.Leader,
                QuestionCategory.CultureDrivers,
                QuestionCategory.TheBasics,
                QuestionCategory.HealthCare,
                QuestionCategory.OperationalReadiness,
                QuestionCategory.OperationalReadinessWorkingRemotely,
                QuestionCategory.OperationalReadinessCommunicationCollaboration,
                QuestionCategory.OperationalReadinessReturnToWork,
                QuestionCategory.DEI,
            ],
            categoryId
        )
    },
}

export default QuestionCategory
