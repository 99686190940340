import { LegacyButton as Button } from '@energage/components'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import type { AwardRegistrationInfo } from './awardRegistration.types'

type ProfileDeadlineProps = {
    show: boolean
    onSubmit: () => void
    award: AwardRegistrationInfo
    isLoading: boolean
}

const ProfileDeadlineConfirmModal = ({ show, onSubmit, award, isLoading }: ProfileDeadlineProps) => (
    <Modal isOpen={show} className="max-w-md" title="Hold on!" onClose={undefined}>
        <div className="px-4 sm:px-6 pt-4">
            <p>
                {'This information has already been shared with the publisher for the '}
                <span className="font-bold">{award?.awardName}</span>
                {` award. Therefore, we can't guarantee it will make it into the ${award?.salesforceYear} publication, but it will be used for future awards.`}
            </p>
        </div>
        <Inset padding className="flex justify-center">
            <Button type="submit" onClick={onSubmit} disabled={isLoading} data-event-id="Confirm Profile Deadline">
                {'Ok'}
            </Button>
        </Inset>
    </Modal>
)

export default ProfileDeadlineConfirmModal
