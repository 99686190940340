export const SurveyEventStatuses = {
    Provisional: 1,
    Active: 2,
    Dropout: 3,
    TemporarilyParked: 4,
    Test: 5,
    Deleted: 6,
}

export const SurveyStatusLabel = {
    0: 'No Status',
    1: 'Not Ready',
    2: 'Needs Approval',
    3: 'Ready',
    4: 'Active',
    5: 'Closed',
    6: 'Insights Ready',
    7: 'Provisional',
}

export const SurveyHistoryItemStatus = {
    NoStatus: 0,
    NotReady: 1,
    NeedsApproval: 2,
    Ready: 3,
    Active: 4,
    Closed: 5,
    InsightsReady: 6,
    Provisional: 7,
}
