import PropTypes from 'prop-types'
import { Icon, RadioButtonChecked, RadioButtonUnchecked } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'

const HiddenInput = styled.input.attrs({ className: 'absolute overflow-hidden p-0 border-0 h-px' })`
    clip: rect(0 0 0 0);
    width: 1px !important;
    margin: -1px;
`

const FancyRadioButton = ({ checked, className, children, disabled, readOnly, ...props }) => (
    <label
        className={cx('inline-block align-middle', className, {
            'opacity-50': disabled,
        })}>
        <HiddenInput {...props} checked={checked} type="radio" disabled={disabled || readOnly} />
        <div checked={checked} className={'w-4 h-4 inline-flex items-center'}>
            <Icon
                color={checked ? colors.blue500 : colors.grey300}
                as={checked ? RadioButtonChecked : RadioButtonUnchecked}
            />
        </div>
        {children && <span className="pl-2">{children}</span>}
    </label>
)

export const FancyRadioButtonWithLabel = ({ label, className, ...restProps }) => (
    <FancyRadioButton
        {...restProps}
        className={cx(
            'inline-flex items-center select-none',
            {
                'cursor-pointer': !restProps.readOnly,
                'cursor-not-allowed': restProps.disabled,
            },
            className
        )}>
        {label}
    </FancyRadioButton>
)

FancyRadioButton.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    children: PropTypes.node,
}

export default FancyRadioButton
