import cx from 'clsx'
import styled from 'styled-components'
import type { AchievementSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import colors from 'containers/Main/AwardAnnouncement/colors'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { YellowButton } from 'containers/Main/AwardAnnouncement/YellowButton'
import twpWidgetLogo from 'images/twp-widget-logo.svg'
import { media } from 'style/breakpoints'

const Year = styled.h2.attrs({ className: 'absolute' })`
    top: 7.5rem;
    left: ${(props: { isPdf?: boolean }) => (props.isPdf ? '47%' : '41%')};
    ${media.smMin`
        top: 180px;
        left: 48px;
    `}
    ${media.mdMin`
        top: 180px;
        left: 49px;
    `}
    color: ${colors.textBlue};
    @media screen and (min-width: 640px) and (max-width: 765px) {
        left: 46%;
        top: 7.5rem;
    }
`

const PdfYear = styled.h2.attrs({ className: 'absolute' })`
    top: 118px;
    left: 47%;
    color: ${colors.textBlue};
    font-size: 20px;
`

export const Achievement = ({ shopMerchandiseUrl, year, colors, isPdf }: AchievementSection) => {
    const YearComponent = isPdf ? PdfYear : Year

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor} isPdf={isPdf}>
            <h2 className={cx('font-black text-3xl', { 'sm:text-5xl': !isPdf })}>{'Get your Top Workplaces swag.'}</h2>
            <div className={cx('flex flex-col mt-8', { 'sm:flex-row sm:mt-12': !isPdf })}>
                <div className="relative">
                    <YearComponent>{year}</YearComponent>
                </div>
                <img alt="logo" src={twpWidgetLogo} className={cx('h-48', { 'sm:h-72': !isPdf })} />
                <div className={cx('flex-1 ml-2', { 'sm:ml-12': !isPdf })}>
                    <p className={isPdf ? 'text-base mt-6' : 'text-lg mt-8 pr-0 sm:pr-16'}>
                        {
                            'Show off your achievement! The Top Workplaces store offers lots of great ways to celebrate and commemorate your award. Please note: American Registry is the only source licensed by Energage for official Top Workplaces merchandise.'
                        }
                    </p>
                    <div className={cx('mt-8 sm:mt-12 mb-24 sm:mb-0', { 'text-center': isPdf })}>
                        <YellowButton
                            className={isPdf ? 'px-10 py-3' : 'px-12'}
                            variant="link"
                            href={shopMerchandiseUrl}
                            target="_blank">
                            {'Shop Merchandise'}
                        </YellowButton>
                    </div>
                </div>
            </div>
        </Section>
    )
}
