import { Switch, withRouter } from 'react-router-dom'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { Permission } from 'constants/permission'
import { routes } from 'constants/routes'
import { EmployeeDataProvider } from 'containers/Main/Manage/Employees/EmployeeDataProvider'
import RecipientsDataProvider from '../RecipientsDataProvider'
import DepartmentView from './DepartmentView'

const ModifyRecipients = () => {
    return (
        <>
            <div className="clearfix" />
            <EmployeeDataProvider>
                <RecipientsDataProvider>
                    <Switch>
                        <AuthenticatedRoute
                            authorize={Permission.SmartPulse}
                            path={routes.survey.setup.recipients.modify.departments()}
                            component={DepartmentView}
                        />
                    </Switch>
                </RecipientsDataProvider>
            </EmployeeDataProvider>
        </>
    )
}

export default withRouter(ModifyRecipients)
