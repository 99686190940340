import { LegacyButton as Button } from '@energage/components'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import HeaderTeleporter from '../../HeaderTeleporter'
import PageTitle from '../../PageTitle'

const ModifyActions = ({ isPosting, surveyEventId, disabledSave, onSave }) => (
    <HeaderTeleporter.Source>
        <PageTitle border title="Modify Survey Recipients">
            <div>
                <Button
                    as={Link}
                    to={routes.survey.setup.recipients({ surveyEventId })}
                    outline
                    disabled={isPosting}
                    variant="secondary">
                    {'Cancel'}
                </Button>
                <Button className="ml-3" variant="secondary" disabled={disabledSave} onClick={onSave}>
                    {'Save Changes'}
                </Button>
            </div>
        </PageTitle>
    </HeaderTeleporter.Source>
)

export default ModifyActions
