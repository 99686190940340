import { useEffect, useState } from 'react'
import type { MouseEvent } from 'react'
import { Loading as Spinner } from '@energage/components'
import map from 'lodash/map'
import size from 'lodash/size'
import styled from 'styled-components'
import { AwardHistoryColumns } from 'containers/Main/EmployerRecognition/constants'
import { useFetchCompletedSurveyTasks } from 'containers/Main/EmployerRecognition/Shared/useFetchCompletedSurveyTasks'
import { media } from 'style/breakpoints'
import type { Award } from './awardHistory.types'
import { AwardHistoryTableHeader } from './AwardHistoryTableHeader'
import { AwardHistoryTableRow } from './AwardHistoryTableRow'
import { defaultSort, sortData } from './getSortedAwards'
import useFetchAwardHistory from './useFetchAwardHistory'

const DEFAULT_SORTING_ORDER = true

const HeaderContainer = styled.div.attrs({ className: 'mt-4' })`
    .topWorkplaceName {
        ${media.mdMin`
            min-width: 145px;

            > span {
                width: 108px;
                transform: translateY(0);
            }
        `}

        ${media.lgMin`
            min-width: 195px;

            > span {
                width: auto;
            }
        `}
    }

    .regionCategory {
        min-width: 160px;

        ${media.mdMin`
            width: 19%;
            min-width: 178px;
        `}
    }

    .year {
        width: 12%;
        min-width: 70px;

        ${media.mdMin`
            width: 6%;
        `}
    }
`

const TableWrapper = styled.div`
    min-width: 384px;
`

const TableContainer = styled.div.attrs({ className: 'overflow-y-auto' })`
    max-height: 62vh;

    @media screen and (min-width: 640px) and (max-width: 767px) {
        margin-bottom: 36px;
    }
`

const EmptyState = () => (
    <div className="flex justify-left">
        {'There are no prior awards to display. Information will be displayed once you complete participation.'}
    </div>
)

export const AwardHistoryTable = () => {
    const { awards, isLoading } = useFetchAwardHistory()
    const { isFetching } = useFetchCompletedSurveyTasks()
    const [sortedAwards, setSortedAwards] = useState<Award[]>([])
    const [sortByColumn, setsortByColumn] = useState(AwardHistoryColumns.TwpAward)
    const [isSort, setSort] = useState(false)

    const sort = (e: MouseEvent<HTMLElement>) => {
        const columnName = e.currentTarget.id
        const updatedSortingOrder = columnName === sortByColumn ? !isSort : DEFAULT_SORTING_ORDER
        sortData(updatedSortingOrder, columnName, sortedAwards, setSortedAwards)
        setSort(updatedSortingOrder)
        setsortByColumn(columnName)
    }

    useEffect(() => {
        if (awards) {
            setSortedAwards(defaultSort(awards))
        }
    }, [awards])

    if (isLoading || isFetching) {
        return <Spinner />
    }

    if (size(awards) <= 0) {
        return <EmptyState />
    }

    return (
        <TableWrapper data-testid="award-history-table">
            <caption>
                <span className="sr-only">{'Column headers with buttons are sortable'}</span>
            </caption>
            <HeaderContainer>
                <AwardHistoryTableHeader sortByColumn={sortByColumn} isSort={isSort} sort={sort} />
            </HeaderContainer>
            <TableContainer>
                {map(sortedAwards, (award, index) => (
                    <AwardHistoryTableRow key={index} award={award} />
                ))}
            </TableContainer>
        </TableWrapper>
    )
}
