import { useRef, useState } from 'react'
import { alert } from '@energage/components'
import type { default as ky } from 'ky'
import { useQueryClient } from 'react-query'
import type { UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import { useWorkplaceSurveyQuery } from 'api'
import type { AwardSelectionPagePost, AwardSelectionPageResult } from './awardselection.types'

type useMutation = {
    <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
        url: string,
        options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
    ): UseMutationResult<TData, TError, TVariables, TContext>
}

type QueryWithMutate = typeof useQuery & {
    mutate: useMutation
}

type EnhancedKy = typeof ky & {
    fetch<T>(url: string): Promise<T>
}

export function useAwardSelection(surveyEventId: string) {
    const queryClient = useQueryClient()
    const dangerAlert = useRef<undefined | ReturnType<typeof alert.danger>>()
    const key = ['award-selection', surveyEventId]
    const [saveComplete, setSaveComplete] = useState(false)

    const query: UseQueryResult<AwardSelectionPageResult> = useWorkplaceSurveyQuery(
        key,
        async (_: unknown, api: EnhancedKy) => {
            return api.fetch<AwardSelectionPageResult>(`surveyevent/${surveyEventId}/awards`)
        },
        {
            refetchOnWindowFocus: true,
        }
    )

    const mutation = (useWorkplaceSurveyQuery as QueryWithMutate).mutate<
        AwardSelectionPagePost,
        unknown,
        unknown,
        { previousValues?: AwardSelectionPageResult }
    >(`surveyevent/${surveyEventId}/awards`, {
        mutationKey: ['award-selection', surveyEventId, 'post'],
        onMutate: async () => {
            await queryClient.cancelQueries(key)

            const previousValues = queryClient.getQueryData<AwardSelectionPageResult>([
                'award-selection',
                surveyEventId,
            ])

            return { previousValues }
        },
        onSuccess: () => {
            setTimeout(() => setSaveComplete(true), 1000)
            setTimeout(() => setSaveComplete(false), 4000)
            /* this is instead of using `refreshWorkflow` from SurveySetupContext. for reasons
                I don't fully understand, using refreshWorkflow causes react-query to stop
                updating its mutation state so this mutation never gets off of "loading".
             */
            queryClient.invalidateQueries(['survey-workflow', surveyEventId], { exact: true })
        },
        onError: (error, variables, context) => {
            if (context?.previousValues) {
                queryClient.setQueryData<AwardSelectionPageResult>(key, context.previousValues)
            }

            dangerAlert.current?.close()
            dangerAlert.current = alert.danger(`We've encountered an error saving`)
        },
        onSettled: () => queryClient.invalidateQueries(key),
    })

    return { query, mutation, saveComplete }
}
