import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from '@energage/hooks'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai/utils'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import pluralize from 'pluralize'
import { Tooltip } from 'components/Tooltip'
import { conjunctionJoin } from 'util/conjunctionJoin'
import { useCustomDemographicTargets } from '../../CustomDemographicTargetContext'
import { QuestionType } from '../../CustomQuestions/QuestionType'
import { useDepartmentContext } from '../../departmentContext'
import { useCoreQuestion, useCoreQuestions } from '../../statementSetupHooks'
import { availableTargetDemographicQuestions } from '../../statementSetupUtility/store'
import { computeTargetingInformation } from '../../Targeting/computeTargetingInformation'
import QuestionTypeAnswerFormat, { StatementTitle } from '../QuestionTypeAnswerFormat'
import { QuestionTypeAnswerFormats } from '../QuestionTypeAnswerFormats'
import { TableCell, TableRow } from '../TableElements'
import { ComplexConditionDescription } from '../TargetingMessagingFragments'

const OptionalItemTableRow = ({ questionId, disabled, includeQuestion }) => {
    const [visible, toggle] = useToggle(false)

    const [question] = useCoreQuestion(questionId)

    const {
        text,
        name,
        isPositivelyPhrased,
        conditionalQuestionEssenceIds,
        negativeConditionalText,
        positiveConditionalText,
        answerOptions,
        questionTypeId,
        isHighlighted,
        demographicTypeName,
    } = question

    const demographicQuestions = useAtomValue(availableTargetDemographicQuestions)
    const [potentialParents] = useCoreQuestions(conditionalQuestionEssenceIds)
    const parentSelected = every(potentialParents, (parentQuestion) => parentQuestion?.isSelected)

    const isConditional = conditionalQuestionEssenceIds.length > 0
    const disableSelection = isConditional && !parentSelected
    const { flattenedDepartments } = useDepartmentContext()

    const customDemographicTargets = useCustomDemographicTargets()

    const targetingInformation = useMemo(() => {
        return computeTargetingInformation({
            questionTargetDemographics: question.targetDemographic,
            questionTargetDepartmentIds: question.departmentIds,
            flattenedDepartments,
            demographicQuestions,
            customDemographicTargets,
        })
    }, [
        question.targetDemographic,
        question.departmentIds,
        flattenedDepartments,
        demographicQuestions,
        customDemographicTargets,
    ])

    const hasTargetingDescription = targetingInformation.targetingDescription !== null

    const hasConditionals = !!(positiveConditionalText || negativeConditionalText)
    const formatName =
        questionTypeId === QuestionType.CustomScale && answerOptions
            ? `${answerOptions.length}pt Scale`
            : QuestionTypeAnswerFormats[questionTypeId]?.name || '-'

    const title =
        hasConditionals && questionTypeId === QuestionType.Agreement
            ? 'Statement with Conditional Questions'
            : formatName

    const showOnlyTitle =
        questionTypeId !== QuestionType.Agreement &&
        isEmpty(answerOptions) &&
        !QuestionTypeAnswerFormats[questionTypeId]?.description

    const disabledTooltipText = `The ${pluralize('question', potentialParents.length)} ${conjunctionJoin(
        'and',
        map(potentialParents, 'name')
    )} ${potentialParents.length === 1 ? 'needs' : 'need'} to be included first`

    return (
        <TableRow className={isHighlighted && 'is-highlighted'}>
            <div className="flex flex-wrap md:flex-no-wrap items-start">
                <TableCell spaced={disabled} alignTop className="w-1/3 md:w-1/6">
                    <div className="mr-2 break-words">{!demographicTypeName ? name : demographicTypeName}</div>
                </TableCell>
                <TableCell spaced={disabled} alignTop className="w-2/3 md:w-1/3">
                    <label htmlFor={questionId} className={cx('md:pr-1', disableSelection && 'text-grey500')}>
                        {text}
                    </label>
                </TableCell>
                <TableCell
                    alignTop
                    className={cx(
                        'w-full md:w-1/2 flex flex justify-between pt-1 md:pt-0',
                        disableSelection && 'text-grey500',
                        {
                            'pl-2 md:pl-0': disabled,
                        }
                    )}>
                    {showOnlyTitle ? (
                        <div className="pl-2 md:pl-0 pt-1 w-1/3 md:w-3/4">
                            <span>{'Answer Format: '}</span>
                            {title}
                        </div>
                    ) : (
                        <div className="w-1/3 md:w-3/4 md:pl-0">
                            <StatementTitle
                                open={visible}
                                onToggle={toggle}
                                title={title}
                                aria-controls={`answer-format-${questionId}`}
                            />
                        </div>
                    )}
                    <div className="md:w-1/4 w-2/3 text-right">
                        {!disabled && (
                            <Tooltip
                                trigger={
                                    <button
                                        disabled={disableSelection}
                                        className={cx(
                                            'focus:outline-none',
                                            disableSelection ? 'text-grey500' : 'text-blue600'
                                        )}
                                        onClick={() => {
                                            includeQuestion(questionId)
                                        }}>
                                        <div className="text-left">{`Include Item`}</div>
                                    </button>
                                }
                                disabled={!disableSelection}
                                placement="left"
                                text={disabledTooltipText}
                                tooltipColor={colors.grey600}
                                textColor={colors.white}
                                tooltipArrowColor={colors.grey500}
                            />
                        )}
                    </div>
                </TableCell>
            </div>
            <div className="flex flex-row">
                <div className="hidden md:block flex-1">
                    {hasTargetingDescription && (
                        <ComplexConditionDescription
                            className="text-grey600 italic pt-2"
                            descriptions={targetingInformation.targetingDescription}
                        />
                    )}
                </div>
                <AnimatePresence initial="false">
                    {visible ? (
                        <motion.div
                            id={`answer-format-${questionId}`}
                            animate="open"
                            initial="collapsed"
                            exit="collapsed"
                            variants={{
                                open: { height: 'auto' },
                                collapsed: { height: 0 },
                            }}
                            className="clearfix overflow-hidden flex-1">
                            <QuestionTypeAnswerFormat
                                questionId={questionId}
                                questionTypeId={questionTypeId}
                                options={answerOptions}
                                positiveConditionalText={positiveConditionalText}
                                negativeConditionalText={negativeConditionalText}
                                isPositivelyPhrased={isPositivelyPhrased}
                            />
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
            {hasTargetingDescription && (
                <div className="block md:hidden">
                    <ComplexConditionDescription
                        className="text-grey600 italic pt-2"
                        descriptions={targetingInformation.targetingDescription}
                    />
                </div>
            )}
        </TableRow>
    )
}

OptionalItemTableRow.propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool.isRequired,
}

export default OptionalItemTableRow
