import { useCallback, useState } from 'react'
import { Loading as Spinner } from '@energage/components'
import cx from 'classnames'
import styled from 'styled-components'

const StyledSpinner = styled(Spinner).attrs({ className: 'flex items-center' })`
    margin-top: 156px;
    margin-bottom: 156px;
    @media screen and (min-width: 520px) and (max-width: 663px) {
        margin-top: 55px;
        margin-bottom: 55px;
    }
    @media screen and (min-width: 374px) and (max-width: 520px) {
        margin-top: -4px;
        margin-bottom: -4px;
    }
`

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`

type PropType = {
    imgSrc: string
    inView: boolean
    index: number
}

export const LazyLoadImage: React.FC<PropType> = (props) => {
    const { imgSrc, inView } = props
    const [hasLoaded, setHasLoaded] = useState(false)

    const setLoaded = useCallback(() => {
        if (inView) setHasLoaded(true)
    }, [inView, setHasLoaded])

    return (
        <div className="embla__slide">
            <div className={cx('embla__lazy-load', { 'embla__lazy-load--has-loaded': hasLoaded })}>
                {!hasLoaded && <StyledSpinner text={false} />}
                <img
                    className="embla__slide__img embla__lazy-load__img"
                    onLoad={setLoaded}
                    src={inView ? imgSrc : PLACEHOLDER_SRC}
                    alt="Slides"
                    data-src={imgSrc}
                />
            </div>
        </div>
    )
}
