import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { alert, LegacyButton as Button, Loading as Spinner } from '@energage/components'
import { FileDownload, Icon, Info } from '@energage/icons'
import { colors } from '@energage/theme'
import { Text } from '@energage/typography'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import some from 'lodash/some'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import HexInput from 'components/HexInput'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import config from 'config'
import { NO_CONTENT } from 'constants/httpStatusCode'
import { isTopXDisabled } from 'containers/Public/RevampWidgets/CultureStats'
import { media } from 'style/breakpoints'
import { isValidHexColor } from 'util/validators'
import { cultureStatsRequestBody } from './CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/buildCultureStatsConfiguration'
import {
    CultureCloudId,
    CultureStatsGraphicType,
    CultureStatsId,
    EmpCommentsId,
    isRectangleDesignType,
    LinkedInBannerId,
    OrgStrengthsId,
} from './CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { GetInspiredCtaButton } from './CultureFacts/CultureFactsConfiguration/GetInspiredCtaButton'
import { previewType } from './CultureFacts/CultureFactsConfiguration/utils'
import { LinkedInIntegration } from './CultureFacts/SocialSharing/LinkedInIntegration'
import InspirationGallery, { tabs } from './InspirationGallery'
import { LinkedInIntegrationButton } from './LinkedInIntegrationButton'
import { useShareContent } from './useShareContent'

const ConfigurationContainer = styled.div.attrs((props) => ({
    className: `flex-grow ${props.dataId === LinkedInBannerId ? 'mt-4' : 'md:ml-8 mt-12 md:mt-0'}`,
}))`
    width: 100%;
    ${(props) =>
        props.dataId === LinkedInBannerId
            ? 'width: 1128px;'
            : media.mdMin`
        max-width: 750px;
        min-width: 300px;
    `}
`

const PreviewContainer = styled.div.attrs({ className: 'self-start mt-4 md:mt-0' })`
    ${(props) => (props.dataId === LinkedInBannerId ? 'max-width: 1128px;' : 'max-width: 450px;')}
`

const GetInspiredBtn = styled.div`
    top: 16px;
    right: -17px;
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: translateX(-10px);
    }
`

const removeHash = (hex) => {
    if (hex && hex[0] === '#') {
        return hex.slice(1, hex.length)
    }

    return hex
}

export const DEFAULT_BG_COLOR = removeHash('#404ddc')

const WidgetsConfigurationSection = ({
    title,
    getUrl,
    postUrl,
    renderPreview,
    renderCustomizations,
    renderCustomAiSection,
    renderCustomGraphicSection,
    dataId,
    setIsTopWorkplace = noop,
    isWidgetDisabled,
    contentFilterOptions,
    forceShowFlag,
    forceHidden,
    selectedCultureType,
    filterData,
    selectedRectangleDesignType,
    setSelectedTopXDesignType,
    setSelectedPercentPositiveDesignType,
    setSelectedRectangleDesignType,
    hasEmployerBrandingSubscription,
}) => {
    const [overallData, setOverallData] = useState(null)
    // Added eslint-disable to continue merge
    // eslint-disable-next-line no-unused-vars
    const [linkedInDroplets, setLinkedInDroplets] = useState([])
    const [hexValid, setHexValid] = useState({ bgColor: true, highlightColor: true, titleColor: true, textColor: true })
    const [bgColor, setBgColor] = useState(removeHash(colors.blue400))
    const [highlightColor, setHighlightColor] = useState(removeHash(colors.blue500))
    const [titleColor, setTitleColor] = useState(removeHash(colors.yellow500))
    const [textColor, setTextColor] = useState(removeHash(colors.white))
    const widgetRef = useRef(null)
    const { isSuperUser } = useIdentity()
    const isCultureStats = dataId === CultureStatsId
    const isCulturStatsHide = title === 'Culture Stats' && overallData?.statements?.length === 0
    const isRectangleDesign = isRectangleDesignType(selectedRectangleDesignType)
    const isLinkedInBanner = dataId === LinkedInBannerId
    const isCultureCloud = dataId === CultureCloudId
    const isOrgStrengths = dataId === OrgStrengthsId
    const isEmpComments = dataId === EmpCommentsId
    const [isOpenSheet, setIsOpenSheet] = useState(false)
    const [isOpenLinkedInShare, setIsOpenLinkedInShare, onShare, isLoadingShareAuthentication] = useShareContent(dataId)

    const updateOverallData = useCallback(
        (data) => {
            if (isLinkedInBanner) {
                setOverallData({ droplets: linkedInDroplets, ...data })
            } else {
                setOverallData(data)
            }
        },
        [isLinkedInBanner, linkedInDroplets]
    )

    const getBackgroundColor = useCallback(
        (data) => {
            if (isCultureStats && isRectangleDesign) {
                return data?.rectangleDesignBackgroundColor || DEFAULT_BG_COLOR
            } else if (isLinkedInBanner) {
                return data?.linkedInbackgroundColor || DEFAULT_BG_COLOR
            }

            return data?.backgroundColor || DEFAULT_BG_COLOR
        },
        [isCultureStats, isLinkedInBanner, isRectangleDesign]
    )

    const setSelectedDesignTypes = useCallback(
        (designType) => {
            switch (designType) {
                case CultureStatsGraphicType.HalfDonut:
                case CultureStatsGraphicType.Donut:
                    setSelectedPercentPositiveDesignType(designType)
                    setSelectedTopXDesignType(CultureStatsGraphicType.None)
                    setSelectedRectangleDesignType?.(CultureStatsGraphicType.None)
                    break
                case CultureStatsGraphicType.LaurelHalfCircle:
                case CultureStatsGraphicType.Circle:
                    setSelectedTopXDesignType(designType)
                    setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
                    setSelectedRectangleDesignType?.(CultureStatsGraphicType.None)
                    break
                case CultureStatsGraphicType.RectangleBorderDesign:
                case CultureStatsGraphicType.RectangleDesignWithoutBorder:
                case CultureStatsGraphicType.ReelDesignWithBorder:
                case CultureStatsGraphicType.ReelDesignWithoutBorder:
                    setSelectedRectangleDesignType(designType)
                    setSelectedTopXDesignType(CultureStatsGraphicType.None)
                    setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
                    break
                default:
            }
        },
        [setSelectedPercentPositiveDesignType, setSelectedRectangleDesignType, setSelectedTopXDesignType]
    )

    const setData = useCallback(
        (data) => {
            const bgColor = removeHash(getBackgroundColor(data))
            const highlightColorNoHash = removeHash(data.highlightColor ?? colors.blue500)
            const titleColorNoHash = removeHash(data.titleColor ?? colors.yellow500)
            const textColorNoHash = removeHash(data.textColor ?? colors.white)

            updateOverallData(data)
            setBgColor(bgColor)
            setHighlightColor(highlightColorNoHash)
            setTitleColor(titleColorNoHash)
            setTextColor(textColorNoHash)
            setHexValid({
                bgColor: isValidHexColor(bgColor),
                highlightColor: isValidHexColor(highlightColorNoHash),
                titleColor: isValidHexColor(titleColorNoHash),
                textColor: isValidHexColor(textColorNoHash),
            })

            if (isCultureStats) {
                setSelectedDesignTypes(data.designType)
            }
        },
        [getBackgroundColor, isCultureStats, setSelectedDesignTypes, updateOverallData]
    )

    const onGet = useCallback(
        (data) => {
            setIsTopWorkplace(!isEmpty(data))
            setData(data)
        },
        [setData, setIsTopWorkplace]
    )

    const createQueryFn = (url) => async (_, api) => await api.json(url)
    const { refetch, error, isLoading, isFetching, statusCode } = useBrandingQuery(dataId, createQueryFn(`${getUrl}`), {
        onSuccess: onGet,
        onError: () => {
            setData({})
        },
    })

    const {
        mutate: doPost,
        status,
        reset,
    } = useBrandingQuery.mutate(postUrl, {
        onSuccess: (data) => {
            const { fileContents, contentType, fileDownloadName } = data

            // Decode the base64 string to binary data
            const binaryData = atob(fileContents)

            // Create an ArrayBuffer from the binary data
            const arrayBuffer = new ArrayBuffer(binaryData.length)
            const uint8Array = new Uint8Array(arrayBuffer)
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i)
            }

            // Create a Blob from the ArrayBuffer
            const blob = new Blob([arrayBuffer], { type: contentType })

            // Create a temporary URL for the Blob
            const url = URL.createObjectURL(blob)

            // Create a temporary link element and trigger the download
            const link = document.createElement('a')
            link.href = url
            link.download = fileDownloadName
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
            alert.primary('Success! Your files have successfully downloaded.')
        },
        onError: () => {
            alert.danger('Something went wrong')
            reset()
        },
    })

    const isPosting = status === 'loading'
    const isClassicDesignSelected = overallData?.selectedDesignType === previewType.Classical

    const shareAndDownload = useCallback(() => {
        const requestedData = cultureStatsRequestBody(overallData)
        const body = {
            ...requestedData,
            backgroundColor: `#${bgColor}`,
            cultureFactsColors: {
                backgroundColor: `#${bgColor}`,
                highlightColor: `#${highlightColor}`,
                titleColor: `#${titleColor}`,
                textColor: `#${textColor}`,
                rectangleDesignBackgroundColor: `#${bgColor}`,
            },
        }

        body.contentFilterOptions = contentFilterOptions
        doPost(body)
    }, [overallData, bgColor, highlightColor, titleColor, textColor, contentFilterOptions, doPost])

    const updatedFetchCall = useMemo(() => getUrl, [getUrl])

    useEffect(() => {
        if (updatedFetchCall) {
            refetch()
        }
    }, [updatedFetchCall, refetch])

    const isTopXFiltered = isTopXDisabled(filterData, selectedCultureType, overallData?.cultureStatTypeSelected)
    const isDisabled = (isFunction(isWidgetDisabled) && isWidgetDisabled(overallData)) || isTopXFiltered

    function onChange(data) {
        updateOverallData(data)
    }

    function updateBackgroundColor({ hex }) {
        const backgroundColorNoHash = removeHash(hex)

        setBgColor(backgroundColorNoHash)
        onChange({ ...overallData, backgroundColor: backgroundColorNoHash })
    }

    function updateHighlightColor({ hex }) {
        const highlightColorNoHash = removeHash(hex)

        setHighlightColor(highlightColorNoHash)
        onChange({ ...overallData, highlightColor: highlightColorNoHash })
    }

    function updateTitleColor({ hex }) {
        const titleColorNoHash = removeHash(hex)

        setTitleColor(titleColorNoHash)
        onChange({ ...overallData, titleColor: titleColorNoHash })
    }

    function updateTextColor({ hex }) {
        const textColorNoHash = removeHash(hex)

        setTextColor(textColorNoHash)
        onChange({ ...overallData, textColor: textColorNoHash })
    }

    function setCustomizeData(data) {
        updateOverallData(data)
    }

    function onBlur() {
        setHexValid({
            bgColor: isValidHexColor(bgColor),
            highlightColor: isValidHexColor(highlightColor),
            titleColor: isValidHexColor(titleColor),
            textColor: isValidHexColor(textColor),
        })
    }

    function updateLinkedInDroplets(droplets) {
        setLinkedInDroplets(droplets)
        updateOverallData({ ...overallData, droplets })
    }

    if (isLoading || !overallData) {
        return <Spinner text={title && "Loading '" + title + "'..."} />
    }

    if (error) {
        // eslint-disable-next-line no-console
        console.log(error)
    }

    const DownloadButton = () => (
        <>
            <Text textStyle="title4" className="text-base content-center">
                <h4>
                    {config.featureFlag.enableShareToLinkedIn && !isLinkedInBanner ? 'Share & Download' : 'Download'}
                </h4>
            </Text>
            <div className="flex gap-2">
                {config.featureFlag.enableShareToLinkedIn && !isLinkedInBanner && (
                    <LinkedInIntegrationButton onClick={onShare} disabled={isLoadingShareAuthentication} />
                )}
                <Button
                    className="ml-2 p-0 bg-white"
                    onClick={shareAndDownload}
                    variant="link"
                    aria-label="Download"
                    disabled={
                        !hexValid ||
                        isPosting ||
                        isDisabled ||
                        isLoading ||
                        error ||
                        (!hasEmployerBrandingSubscription && !isSuperUser)
                    }
                    eventName="generate snippet"
                    data-event-id={dataId && `${dataId}-share`}>
                    <Icon as={FileDownload} size={40} className="rounded" color={colors.black} />
                    {isPosting && <Spinner inline size="small" />}
                </Button>
            </div>
        </>
    )

    const defaultTab = some(tabs, (tab) => some(tab.items, (item) => item.id === dataId)) ? dataId : 'cultureCloud'

    return (
        <>
            {!forceHidden && !isCulturStatsHide && (
                <Inset className="w-full mb-6 md:p-12 md:bg-white rounded-lg md:shadow-md relative overflow-hidden">
                    <Text textStyle="title5" className="md:mb-6 mt-4 md:mt-1">
                        <h3>{title}</h3>
                    </Text>
                    <GetInspiredBtn className="absolute">
                        <GetInspiredCtaButton onClick={() => setIsOpenSheet(true)} dataId={dataId} />
                    </GetInspiredBtn>
                    <InspirationGallery isOpen={isOpenSheet} onDismiss={setIsOpenSheet} defaultTab={defaultTab} />
                    <div className={`flex flex-col ${isLinkedInBanner ? '' : 'md:flex-row'} w-full`}>
                        <PreviewContainer dataId={dataId}>
                            <Text textStyle="lead" className="my-2">
                                <h4>{isCultureCloud ? 'Social Media Preview' : 'Preview'}</h4>
                            </Text>
                            <div className="rounded-lg border shadow">
                                {renderPreview({
                                    ...overallData,
                                    backgroundColor: `#${bgColor}`,
                                    highlightColor: `#${highlightColor}`,
                                    titleColor: `#${titleColor}`,
                                    textColor: `#${textColor}`,
                                    headerBgColor: `#ffffff`,
                                    headerTextColor: `#000000`,
                                    className: `rounded-lg overflow-hidden mx-auto relative z-10`,
                                    ref: widgetRef,
                                    isVisible: hasEmployerBrandingSubscription || isSuperUser,
                                    updateLinkedInDroplets,
                                })}
                            </div>
                        </PreviewContainer>
                        <ConfigurationContainer dataId={dataId}>
                            <Text textStyle="lead" className="mt-3 md:mt-2 mb-2">
                                <h4>{'Personalize'}</h4>
                            </Text>
                            <div className="rounded-lg border shadow bg-white">
                                <div className="flex flex-col lg:flex-row xl:flex-row">
                                    <Inset
                                        className={`py-4 px-6 w-full ${
                                            isLinkedInBanner ? 'flex flex-col md:flex-row' : ''
                                        }`}>
                                        <div className={isLinkedInBanner ? 'flex-1' : ''}>
                                            {renderCustomGraphicSection && (
                                                <div>
                                                    {renderCustomGraphicSection(
                                                        overallData,
                                                        setCustomizeData,
                                                        isLoading || isFetching,
                                                        isDisabled
                                                    )}
                                                </div>
                                            )}
                                            {(isCultureStats || isOrgStrengths || isEmpComments) && (
                                                <Text textStyle="title6" className="mb-3 text-sm">
                                                    <h5>{'Select Colors'}</h5>
                                                </Text>
                                            )}
                                            {isCultureStats || isOrgStrengths || isEmpComments ? (
                                                <div className="flex flex-row">
                                                    <HexInput
                                                        className="text-center"
                                                        containerWidth="64px"
                                                        hideColorInput
                                                        hexValid={hexValid['bgColor']}
                                                        color={bgColor}
                                                        label="Background"
                                                        labelBottom
                                                        labelClassName="p-1"
                                                        labelFontSizeClass="text-xs"
                                                        labelHeight="50px"
                                                        labelWidth="55px"
                                                        squareClassName="rounded-full"
                                                        squareHeight="43px"
                                                        squareWidth="43px"
                                                        squareBorder={colors.grey500}
                                                        setColor={updateBackgroundColor}
                                                        inputProps={{
                                                            maxLength: 7,
                                                            minLength: 4,
                                                            onBlur,
                                                        }}
                                                        dataId={`${dataId}-background`}
                                                        disabled={isDisabled || isLoading || error}
                                                    />
                                                    {(isRectangleDesign ||
                                                        ((isOrgStrengths || isEmpComments) &&
                                                            !isClassicDesignSelected)) && (
                                                        <>
                                                            {!(
                                                                isEmpComments &&
                                                                overallData?.selectedDesignType ===
                                                                    previewType.TriangleDesign
                                                            ) && (
                                                                <HexInput
                                                                    className="text-center"
                                                                    containerWidth="64px"
                                                                    hideColorInput
                                                                    hexValid={hexValid['highlightColor']}
                                                                    color={highlightColor}
                                                                    label="Highlight"
                                                                    labelBottom
                                                                    labelClassName="p-1"
                                                                    labelFontSizeClass="text-xs"
                                                                    labelHeight="50px"
                                                                    labelWidth="55px"
                                                                    squareClassName="rounded-full"
                                                                    squareHeight="43px"
                                                                    squareWidth="43px"
                                                                    squareBorder={colors.grey500}
                                                                    setColor={updateHighlightColor}
                                                                    inputProps={{
                                                                        maxLength: 7,
                                                                        minLength: 4,
                                                                        onBlur,
                                                                    }}
                                                                    dataId={`${dataId}-highlight`}
                                                                    disabled={isDisabled || isLoading || error}
                                                                />
                                                            )}
                                                            {isEmpComments && (
                                                                <HexInput
                                                                    className="text-center"
                                                                    containerWidth="64px"
                                                                    hideColorInput
                                                                    hexValid={hexValid['titleColor']}
                                                                    color={titleColor}
                                                                    label="Title"
                                                                    labelBottom
                                                                    labelClassName="p-1"
                                                                    labelFontSizeClass="text-xs"
                                                                    labelHeight="50px"
                                                                    labelWidth="55px"
                                                                    squareClassName="rounded-full"
                                                                    squareHeight="43px"
                                                                    squareWidth="43px"
                                                                    squareBorder={colors.grey500}
                                                                    setColor={updateTitleColor}
                                                                    inputProps={{
                                                                        maxLength: 7,
                                                                        minLength: 4,
                                                                        onBlur,
                                                                    }}
                                                                    dataId={`${dataId}-title`}
                                                                    disabled={isDisabled || isLoading || error}
                                                                />
                                                            )}
                                                            <HexInput
                                                                className="text-center"
                                                                containerWidth="64px"
                                                                hideColorInput
                                                                hexValid={hexValid['textColor']}
                                                                color={textColor}
                                                                label="Text"
                                                                labelBottom
                                                                labelClassName="p-1"
                                                                labelFontSizeClass="text-xs"
                                                                labelHeight="50px"
                                                                labelWidth="55px"
                                                                squareClassName="rounded-full"
                                                                squareHeight="43px"
                                                                squareWidth="43px"
                                                                squareBorder={colors.grey500}
                                                                setColor={updateTextColor}
                                                                inputProps={{
                                                                    maxLength: 7,
                                                                    minLength: 4,
                                                                    onBlur,
                                                                }}
                                                                dataId={`${dataId}-text`}
                                                                disabled={isDisabled || isLoading || error}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <HexInput
                                                    hexValid={hexValid['bgColor']}
                                                    color={bgColor}
                                                    labelFontSizeClass="text-base font-normal"
                                                    setColor={updateBackgroundColor}
                                                    inputProps={{
                                                        maxLength: 7,
                                                        minLength: 4,
                                                        onBlur,
                                                    }}
                                                    dataId={dataId}
                                                    disabled={isDisabled || isLoading || error}
                                                />
                                            )}
                                        </div>
                                        {renderCustomizations && statusCode !== NO_CONTENT && (
                                            <div className={`mt-2 ${isLinkedInBanner ? 'flex-1 md:ml-8' : ''}`}>
                                                {renderCustomizations(
                                                    overallData,
                                                    setCustomizeData,
                                                    isLoading || isFetching,
                                                    isDisabled
                                                )}
                                            </div>
                                        )}
                                    </Inset>
                                </div>
                                {isLinkedInBanner && (
                                    <p className="py-4 px-4 w-full sm:px-6 sm:py-6">
                                        <Icon as={Info} className="mr-2" color={colors.purple700} />
                                        {`The culture cloud words are added automatically based on your top 12 words.`}
                                    </p>
                                )}
                            </div>
                            {!isCultureCloud && (
                                <Inset className="mt-4 py-2 pl-6 pr-2 border rounded-md flex justify-between bg-grey200">
                                    <DownloadButton />
                                </Inset>
                            )}
                            {isCultureCloud && (
                                <>
                                    <Text textStyle="lead" className="mt-4 md:mt-8 mb-2">
                                        <h4>
                                            {'AI Content Generator'}
                                            <span className="inline-flex rounded-lg ml-2 px-2 text-xs text-white bg-purple700 font-normal leading-4">
                                                {'BETA 1.0'}
                                            </span>
                                        </h4>
                                    </Text>
                                    <Text textStyle="label" className="my-3">
                                        <p>
                                            <Icon as={Info} className="mr-2" />
                                            {`The AI generator will use your top 3 words in the culture cloud to create content suggestions`}
                                        </p>
                                    </Text>
                                    <div className="rounded-lg border shadow bg-white">
                                        <div className="flex flex-col lg:flex-row xl:flex-row">
                                            <Inset className={`py-4 px-6 w-full`}>
                                                {renderCustomizations && statusCode !== NO_CONTENT && (
                                                    <div className={`mt-2`}>
                                                        {renderCustomAiSection(
                                                            overallData,
                                                            setCustomizeData,
                                                            isLoading || isFetching || isPosting,
                                                            isDisabled
                                                        )}
                                                    </div>
                                                )}
                                            </Inset>
                                        </div>
                                    </div>
                                    <Inset className="mt-4 py-2 pl-6 pr-2 border rounded-md flex justify-between bg-grey200">
                                        <DownloadButton />
                                    </Inset>
                                </>
                            )}
                        </ConfigurationContainer>
                    </div>
                </Inset>
            )}

            <LinkedInIntegration
                isOpenSheet={isOpenLinkedInShare}
                setIsOpenSheet={setIsOpenLinkedInShare}
                dataId={dataId}
                overallData={overallData}
                filterData={filterData}
                hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                backgroundColor={`#${bgColor}`}
                highlightColor={`#${highlightColor}`}
                titleColor={`#${titleColor}`}
                textColor={`#${textColor}`}
                widgetRef={widgetRef}
                selectedCultureType={selectedCultureType}
                selectedRectangleDesignType={selectedRectangleDesignType}
            />

            {forceShowFlag && (
                <div className="fixed">
                    {renderPreview({ ...overallData, backgroundColor: `#${bgColor}`, ref: widgetRef })}
                </div>
            )}
        </>
    )
}

WidgetsConfigurationSection.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    getUrl: PropTypes.string.isRequired,
    postUrl: PropTypes.string.isRequired,
    renderPreview: PropTypes.func.isRequired,
    renderCustomizations: PropTypes.func,
    isDisplayable: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    dataId: PropTypes.string,
    selectedCultureType: PropTypes.number,
    selectedTopXDesignType: PropTypes.number,
    selectedPercentPositiveDesignType: PropTypes.number,
}

export default WidgetsConfigurationSection
