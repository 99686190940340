import find from 'lodash/find'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'

interface PropType {
    taskDefinitionId: number
    completedDateTime: Date
}

export const useFetchOrgTaskStatus = (taskDefinitionId: number) => {
    const { surveyCompanyId } = useIdentity()

    const {
        data: statuses,
        status,
        error,
    } = useWorkplaceSurveyQuery<PropType[]>(
        ['completed-org-tasks', surveyCompanyId],
        `Workflow/${surveyCompanyId}/CompletedOrgTasks`,
        {
            enabled: Boolean(surveyCompanyId),
        }
    )
    const { completedDateTime } =
        find(statuses, (survey: PropType) => survey.taskDefinitionId === taskDefinitionId) || {}

    return {
        isCompleted: !!completedDateTime,
        loading: status === 'loading',
        error,
    }
}
