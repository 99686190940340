import { SvgLoader, SvgProxy } from 'react-svgmt'

export const ShowTWPUSAAward = ({ svgUrl, year }) => (
    <div>
        <SvgLoader path={svgUrl} height="10rem" alt="TWPUSA awards logos">
            <SvgProxy selector="text" font-family="'Yantramanav', sans-serif" font-size="46" font-weight="900" />
            <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
        </SvgLoader>
    </div>
)
