import { colors } from '@energage/theme'
import cx from 'clsx'
import map from 'lodash/map'
import styled from 'styled-components'
import { CULTURE_FACTS_TILE } from 'containers/Main/EmployerBranding/CultureFacts/constants'
import { CultureStatsGraphicType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'
import type { CultureStatsRectangleProps } from './CultureStatsRectangleView'

type RectangleHeightContainerProps = Pick<CultureStatsRectangleProps, 'cultureStatTileHeight'>

const RectangleHeightContainer = styled.div`
    ${(props: RectangleHeightContainerProps) =>
        props.cultureStatTileHeight && `height: ${props.cultureStatTileHeight};`}
`

interface RectangleContainerProps {
    backgroundColor?: string
    isRectangleBorderDesign: boolean
}

const RectangleContainer = styled.div.attrs((props: RectangleContainerProps) => ({
    className: cx(props.isRectangleBorderDesign ? 'items-center' : 'items-start'),
}))`
    background-color: ${(props: RectangleContainerProps) =>
        props.backgroundColor ? props.backgroundColor : '#404ddc'};
    margin-left: 95px;
    margin-right: 95px;
    padding-top: 180px;
`

interface RectangleBorderProps {
    highlightColor?: string
}

const RectangleBorder = styled.div`
    border: 15px solid ${(props: RectangleBorderProps) => (props.highlightColor ? `${props.highlightColor}` : '')};
    height: 882px;
    left: 213px;
    right: 213px;
    top: 50%;
    transform: translateY(-50%);
`

interface RectangleTitleWordContainerProps {
    backgroundColor?: string
    isRectangleBorderDesign: boolean
    isSingleTitleWord: boolean
}

const RectangleTitleWordContainer = styled.div.attrs((props: RectangleTitleWordContainerProps) => ({
    className: cx(
        'flex flex-col flex-wrap justify-center py-2 relative',
        props.isRectangleBorderDesign ? 'items-center' : 'items-start'
    ),
}))`
    background-color: ${(props: RectangleTitleWordContainerProps) =>
        props.backgroundColor ? props.backgroundColor : '#404ddc'};
    font-family: 'Almarai', 'Roboto', sans-serif;
    min-height: 100px;
    min-width: ${(props) => (props.isRectangleBorderDesign ? CULTURE_FACTS_TILE.DOWNLOAD_WIDTH : '100%')};
`

interface RectangleTitleWordProps {
    isRectangleBorderDesign?: boolean
    textColor?: string
    highlightColor?: string
}

const RectangleTitleWord = styled.p.attrs((props: RectangleTitleWordProps) => ({
    className: cx('font-black uppercase', props.isRectangleBorderDesign ? '' : 'inline'),
}))`
    font-size: 112px;
    line-height: ${(props) => (props.isRectangleBorderDesign ? '1.1' : '1.4')};
    padding: ${(props) => (props.isRectangleBorderDesign ? '0 60px' : '0 20px')};
    ${(props: RectangleTitleWordProps) => (props.highlightColor ? `background-color: ${props.highlightColor};` : '')}
    color: ${(props: RectangleTitleWordProps) => (props.textColor ? `${props.textColor};` : colors.white)}
`

interface TopWorkPlaceImageProps {
    isRectangleBorderDesign: boolean
    isReelBorderDesign: boolean
}

const TopWorkPlaceImage = styled.img`
    ${(props: TopWorkPlaceImageProps) =>
        props.isRectangleBorderDesign && !props.isReelBorderDesign
            ? 'height: 170px; width: 170px; left: 50%; transform: translate(-50%, -50%); top: 130px;'
            : 'height: 204px; width: 204px; right: 45px; top: 45px;'}
`

export const CultureStatsRectangle1080View = ({
    children,
    backgroundColor,
    highlightColor,
    textColor,
    selectedRectangleDesignType,
    focusWord = '',
    cultureStatTileHeight,
}: CultureStatsRectangleProps) => {
    const isReelBorderDesign = selectedRectangleDesignType === CultureStatsGraphicType.ReelDesignWithBorder
    const isRectangleBorderDesign =
        selectedRectangleDesignType === CultureStatsGraphicType.RectangleBorderDesign || isReelBorderDesign
    const titleWords = focusWord.length <= 10 ? [focusWord] : focusWord.split(' ')
    const isSingleTitleWord = titleWords.length === 1

    return (
        <RectangleHeightContainer
            className="flex flex-col justify-center"
            cultureStatTileHeight={cultureStatTileHeight}>
            <RectangleContainer
                className="flex flex-col"
                backgroundColor={backgroundColor}
                isRectangleBorderDesign={isRectangleBorderDesign}>
                <TopWorkPlaceImage
                    className="absolute rounded-full z-10"
                    isRectangleBorderDesign={isRectangleBorderDesign}
                    isReelBorderDesign={isReelBorderDesign}
                    src={CultureFactTWP}
                    alt="Top work places"
                />
                {isRectangleBorderDesign && <RectangleBorder className="absolute" highlightColor={highlightColor} />}
                <RectangleTitleWordContainer
                    backgroundColor={backgroundColor}
                    isRectangleBorderDesign={isRectangleBorderDesign}
                    isSingleTitleWord={isSingleTitleWord}>
                    {map(titleWords, (title: string, key: number) => (
                        <div key={key}>
                            <RectangleTitleWord
                                isRectangleBorderDesign={isRectangleBorderDesign}
                                highlightColor={isRectangleBorderDesign ? backgroundColor : highlightColor}
                                textColor={textColor}>
                                {title}
                            </RectangleTitleWord>
                        </div>
                    ))}
                </RectangleTitleWordContainer>
                {children}
            </RectangleContainer>
        </RectangleHeightContainer>
    )
}
