import { useCallback, useEffect, useRef, useState } from 'react'
import { Skeleton } from '@energage/components'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@energage/icons'
import { Text } from '@energage/typography'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'
import styled from 'styled-components'
import type { Slide, SwimLaneProps } from './models'
import useScrollEnd from './useScrollEnd'

const SkeletonCard = styled(Skeleton.RectangularShape)`
    height: 246px;
    width: 288px;
`
const ControlButtons = ({
    handlePrevious,
    handleNext,
    isFirstItemInView,
    isLastItemInView,
}: {
    handlePrevious: () => void
    handleNext: () => void
    isFirstItemInView: boolean
    isLastItemInView: boolean
}) => (
    <div className="flex gap-6">
        <button
            aria-label="previous article"
            className="rounded-lg"
            onClick={handlePrevious}
            disabled={isFirstItemInView}>
            <KeyboardArrowLeft
                className="bg-grey100 hover:bg-white rounded-lg"
                color={isFirstItemInView ? '#B0B2BC' : '#1D1E23'}
            />
        </button>
        <button aria-label="next article" className="rounded-lg" onClick={handleNext} disabled={isLastItemInView}>
            <KeyboardArrowRight
                className="bg-grey100 hover:bg-white rounded-lg"
                color={isLastItemInView ? '#B0B2BC' : '#1D1E23'}
            />
        </button>
    </div>
)
export const ScrollbarLessContainer = styled.div.attrs<{ className?: string }>({
    className: 'flex items-center gap-6 py-2 overflow-x-auto whitespace-nowrap ',
})`
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    /* WebKit */
    ::-webkit-scrollbar {
        display: none;
    }
    scroll-snap-type: x mandatory;
    position: relative;
`

export const SwimLaneContainer = ({ sectionTitle, items, isFetching, dataEventId }: SwimLaneProps) => {
    const scrollableComponentRef = useRef<HTMLDivElement | null>(null)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const scrollEnd = useScrollEnd(scrollableComponentRef)
    const [slides, setSlides] = useState<Slide[]>([])
    const previousSlidesCountRef = useRef<number>(0)
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const previousIndexRef = useRef<number>(-1)

    const [isLastItemInView, setIsLastItemInview] = useState<boolean>(false)
    const [isFirstItemInView, setIsFirstItemInView] = useState<boolean>(true)

    const populateSlides = items && items.length > 0 && !isFetching && previousSlidesCountRef.current === 0

    const checkOverflow = () => {
        const element = scrollableComponentRef.current
        if (element) {
            const overflow = element.scrollWidth > element.clientWidth
            setIsOverflowing(overflow)
        }
    }

    useEffect(() => {
        const debouncedCheckOverflow = debounce(checkOverflow, 20)
        window.addEventListener('resize', debouncedCheckOverflow)

        return () => window.removeEventListener('resize', debouncedCheckOverflow)
    }, [])

    useEffect(() => {
        checkOverflow()
    }, [scrollableComponentRef.current?.scrollWidth])

    useEffect(() => {
        if (populateSlides) {
            const swimlaneSlides: Slide[] = []

            forEach(scrollableComponentRef.current?.children, (child, index) => {
                swimlaneSlides.push({
                    index: index,
                    element: child,
                })
            })

            previousSlidesCountRef.current = swimlaneSlides.length
            setSlides(swimlaneSlides)
        }
    }, [populateSlides])

    const elementInView = useCallback(
        (index: number, horizontalOffsetPercentage = 0) => {
            if (slides.length === 0) {
                return false
            }

            const elementRect = slides[index].element.getBoundingClientRect()
            const elementWidth = elementRect.width
            const adjustedX = elementRect.x + (elementWidth * horizontalOffsetPercentage) / 100 // Calculate adjusted X based on offset

            const containerLeft = scrollableComponentRef.current?.getBoundingClientRect().x || 0
            const containerRight = containerLeft + (scrollableComponentRef.current?.clientWidth || 0)

            return adjustedX <= containerRight && adjustedX >= containerLeft
        },
        [slides]
    )

    const getIndexInView = useCallback(() => {
        for (let indexPivot = 0; indexPivot < slides.length; indexPivot++) {
            if (elementInView(indexPivot)) {
                return indexPivot
            }
        }

        return 0
    }, [elementInView, slides.length])

    useEffect(() => {
        if (scrollEnd) {
            setIsFirstItemInView(elementInView(0, 5))
            setIsLastItemInview(elementInView(items.length - 1, 95))
            setCurrentIndex(getIndexInView())
        }
    }, [scrollEnd, elementInView, getIndexInView, items])

    useEffect(() => {
        if (slides.length === 0) {
            return
        }

        if (previousIndexRef.current === -1) {
            previousIndexRef.current = currentIndex
            return
        }

        slides[currentIndex].element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
    }, [currentIndex, slides])

    const handlePrevious = () => {
        if (!canScroll() || currentIndex === 0) {
            return
        }

        let index = elementInView(currentIndex) ? currentIndex : getIndexInView()

        while (index > 0 && elementInView(index)) {
            index--
        }

        setCurrentIndex(index)
    }

    const handleNext = () => {
        if (!canScroll() || currentIndex + 1 === slides.length - 1) {
            return
        }

        let index = elementInView(currentIndex) ? currentIndex : getIndexInView()

        while (index < slides.length && elementInView(index)) {
            index++
        }

        setCurrentIndex(index)
    }

    const canScroll = () => {
        return (scrollableComponentRef.current?.scrollWidth || 0) > (scrollableComponentRef.current?.clientWidth || 0)
    }

    return (
        <div data-testid={sectionTitle.toLowerCase().replaceAll(' ', '-')} className="flex flex-col pb-16">
            <div className="pb-6 flex items-center justify-between">
                <Text textStyle="title2">
                    <h2>{sectionTitle}</h2>
                </Text>
                {isOverflowing && (
                    <ControlButtons
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        isFirstItemInView={isFirstItemInView}
                        isLastItemInView={isLastItemInView}
                    />
                )}
            </div>
            <ScrollbarLessContainer
                key={`${sectionTitle.toLowerCase().replaceAll(' ', '-')}-container`}
                ref={scrollableComponentRef}
                data-event-id={dataEventId}>
                {isFetching ? [...Array(7)].map((_, index) => <SkeletonCard key={index} />) : items}
                <div css="min-width: 1px" />
            </ScrollbarLessContainer>
        </div>
    )
}

export default SwimLaneContainer
