import { RadialChart } from '@energage/charts'
import { colors } from '@energage/theme'
import tint from 'polished/lib/color/tint'
import styled from 'styled-components'
import contrast from 'util/contrast'

export type CultureStatsHalfDonutGraphProps = {
    positivePercent: number
    className?: string
    height?: string
    width?: string
    topMargin?: string
    backgroundColor: string
}

const LargeTextBlock = styled.tspan.attrs({ className: 'font-bold mr-1' })`
    fill: ${(props: { backgroundColor: string }) =>
        contrast(props.backgroundColor).isLight ? colors.black : colors.white};
    font-size: 26px;
    font-family: 'Yantramanav', 'system-ui';
`

const PercentTextBlock = styled.tspan`
    fill: ${(props: { backgroundColor: string }) =>
        contrast(props.backgroundColor).isLight ? colors.black : colors.white};
    font-size: 10px;
    font-family: 'Yantramanav', 'system-ui';
`

const HalfDonutContainer = styled.div<{ height: string; width: string }>`
    height: ${(props: { height: string }) => props.height};
    width: ${(props: { width: string }) => props.width};
`

const backgroundColorOpacity = (bgColor: string) => {
    try {
        return tint(0.24, bgColor)
    } catch (err) {
        return '#F0F1F6'
    }
}

export const CultureStatsHalfDonutGraph = ({
    positivePercent,
    className,
    height = '150px',
    width = '150px',
    backgroundColor,
}: CultureStatsHalfDonutGraphProps) => {
    const backgroundFillColor =
        backgroundColor === '#FDBF3F' || contrast(backgroundColor).isLight ? '#15161A' : '#FDBF3F'

    return (
        <HalfDonutContainer className={className} height={height} width={width}>
            <RadialChart
                value={positivePercent}
                backgroundColor={backgroundColorOpacity(backgroundColor)}
                fillColor={backgroundFillColor}
                variant="arc">
                <text
                    className="relative"
                    x="0"
                    y="0"
                    textAnchor="middle"
                    fill={colors.white}
                    alignmentBaseline="central">
                    <LargeTextBlock backgroundColor={backgroundColor} x={positivePercent >= 100 ? -3 : 0} y={-6}>
                        {positivePercent}
                    </LargeTextBlock>
                    <PercentTextBlock backgroundColor={backgroundColor} x={positivePercent >= 100 ? 20 : 17} y={-16}>
                        {'%'}
                    </PercentTextBlock>
                </text>
            </RadialChart>
        </HalfDonutContainer>
    )
}
