import { useCallback, useMemo } from 'react'
import { alert } from '@energage/components'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import noop from 'lodash/noop'
import { useManagementQuery } from 'api'
import { usePermissions } from 'components/Identity'
import { EMPTY_GUID } from 'constants/emptyGuid'
import TaskDefinition from 'constants/taskDefinition'
import showErrorAlert from 'util/showErrorAlert'
import { useSurveySetupContext } from '../../../SurveySetup'

const getSelected = (department) => {
    const ids = []

    function getIds(dept) {
        if (dept.checked) {
            ids.push(dept.id)
        }

        forEach(dept.children, getIds)

        return ids
    }

    return getIds(department)
}

function useModifyRecipientDepartments(
    organizationId,
    surveyEventId,
    surveyEventSetupId,
    departments,
    selectedFilterOptionIds,
    onComplete = noop
) {
    const postBody = useMemo(() => {
        let memberIds = []
        let departmentIds = []

        forEach(departments, (dept) => {
            if (dept.id === EMPTY_GUID && dept.checked) {
                memberIds = map(dept.members, 'id')
            } else {
                departmentIds = concat(departmentIds, getSelected(dept))
            }
        })

        return {
            memberIds: memberIds,
            tagOptionIds: [...departmentIds, ...selectedFilterOptionIds],
        }
    }, [departments, selectedFilterOptionIds])

    const {
        mutateAsync: saveTags,
        isLoading: isLoadingSaveAssignments,
        reset,
    } = useManagementQuery.mutate(`SurveyEventSetup/${surveyEventSetupId}/SaveAssignments`, {
        onSuccess: (data) => {
            onComplete(data)
            alert.success('Department recipients saved successfully.')
        },
        onError: (error) => {
            showErrorAlert('There was an error saving department recipients', error)
            reset()
        },
    })

    const { getTaskCompletedDateTime } = useSurveySetupContext()
    const confirmRecipientsTaskDateTime = getTaskCompletedDateTime(TaskDefinition.ConfirmRecipients)
    const hasConfirmedRecipients = confirmRecipientsTaskDateTime !== null

    const { mutateAsync: scheduleSync, isLoading: isLoadingSync } = useManagementQuery.mutate(
        `organization/${organizationId}/startsurveysync`
    )

    const { hasUploadRecipientsAtSurveyEventLevel } = usePermissions()

    const update = useCallback(() => {
        return Promise.all([
            saveTags({ surveyEventSetupId: surveyEventSetupId, ...postBody }),
            hasConfirmedRecipients && !hasUploadRecipientsAtSurveyEventLevel ? scheduleSync({ surveyEventId }) : [],
        ])
    }, [
        saveTags,
        surveyEventSetupId,
        postBody,
        scheduleSync,
        hasConfirmedRecipients,
        hasUploadRecipientsAtSurveyEventLevel,
        surveyEventId,
    ])

    return { update, isUpdating: isLoadingSaveAssignments || isLoadingSync }
}

export default useModifyRecipientDepartments
