import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { DALLAS_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { getYearPosition } from 'containers/Main/EmployerBranding/MediaKit/Utils'
import withClassName from 'style/util/withClassName'
import { ShowIndustryAward } from './IndustryAwardView'
import { ShowRegionalAwards } from './RegionalAwardView'
import { ShowTWPUSAAward } from './TWPUSAAwardView'

const HorizontalLine = withClassName('my-5 bg-grey300', 'hr', 'horizontalLine')

const ShowCultureExcellenceAwards = ({ title, awards }) => (
    <div className="flex flex-col justify-center mt-4">
        <div className="justify-center text-center font-bold mt-4">{title}</div>
        <div className="flex justify-center flex-wrap mt-4">
            {map(awards, (x, index) => (
                <SvgLoader
                    path={x.svgUrl}
                    key={index}
                    height="10rem"
                    alt="CE awards logos"
                    className="inline-block h-32 w-1/3 py-1 px-0">
                    <SvgProxy
                        selector="text"
                        font-family="'Yantramanav', sans-serif"
                        font-size="46"
                        font-weight="900"
                    />
                    <SvgProxy selector="text tspan" y={getYearPosition(x.awardName)}>
                        {`${x.year}`}
                    </SvgProxy>
                </SvgLoader>
            ))}
        </div>
    </div>
)

const MobileView = ({
    twpUsaAward,
    regionalAwards,
    ceWonAwards,
    ceAspiringAwards,
    showNationalStandardDetails,
    industryAward,
    showDallasNationalStandardDetails,
}) => (
    <>
        {size(twpUsaAward) > 0 && (
            <>
                <div className="flex flex-col justify-center mt-4">
                    <div className="justify-center text-center font-bold mt-4">{'USA Award'}</div>
                    <div className="flex relative justify-center flex-wrap">
                        <ShowTWPUSAAward svgUrl={twpUsaAward.svgUrl} year={twpUsaAward.year} />
                    </div>
                </div>
                <HorizontalLine />
            </>
        )}
        {size(regionalAwards) > 0 && (
            <>
                <div className="flex flex-col justify-center mt-4">
                    <div className="justify-center text-center font-bold mt-4">
                        {showDallasNationalStandardDetails ? 'National Standard Award' : 'Regional Award'}
                    </div>
                    <div className="flex relative justify-center flex-wrap">
                        {map(regionalAwards, (x, index) => (
                            <ShowRegionalAwards
                                key={x.publisherId}
                                index={index}
                                svgUrl={x.svgUrl}
                                publisherId={x.publisherId}
                                year={x.year}
                                isTopWorkplace={x.isTopWorkplace}
                                showNationalStandardDetails={showNationalStandardDetails}
                                isDallasRankedWinner={x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard}
                            />
                        ))}
                    </div>
                </div>
                <HorizontalLine />
            </>
        )}
        {size(ceWonAwards) > 0 && (
            <ShowCultureExcellenceAwards title={'Culture Excellence Award'} awards={ceWonAwards} />
        )}
        {size(ceAspiringAwards) > 0 && <ShowCultureExcellenceAwards title={'Not Awarded'} awards={ceAspiringAwards} />}
        {size(industryAward) > 0 && (
            <>
                <div className="flex flex-col justify-center mt-4">
                    <div className="justify-center text-center font-bold mt-4">{'Industry Award'}</div>
                    <div className="flex relative justify-center flex-wrap">
                        <ShowIndustryAward
                            svgUrl={industryAward.svgUrl}
                            year={industryAward.year}
                            awardName={industryAward.awardName}
                        />
                    </div>
                </div>
            </>
        )}
    </>
)

export default MobileView
