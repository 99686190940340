export const nationalStandardListDiy = [
    {
        imgPath: 'pro2.svg',
        heading: 'Social media images',
        text: 'Capture the attention of followers and attract new ones.',
    },
    {
        imgPath: 'diy4.svg',
        heading: 'Formatted press release template',
        text: 'Gain immediate media exposure and expand your reach.',
    },
    {
        imgPath: 'diy3.svg',
        heading: 'Internal announcement template',
        text: 'Boost employee morale by sharing the exciting news.',
    },
]

export const nationalStandardListPro = [
    {
        imgPath: 'pro8.svg',
        heading: 'Premium Media Kits',
        text: 'Access kits for current and any future premium Top Workplaces awards.',
    },
    {
        imgPath: 'pro6.svg',
        heading: 'Premium Company Profile on topworkplaces.com',
        text: 'Create a custom profile that’s featured on the site seen by millions each year.',
    },
    {
        imgPath: 'pro7.svg',
        heading: 'Culture Facts',
        text: 'Leverage employee feedback to show what makes your culture unique',
    },
    {
        imgPath: 'pro4.svg',
        heading: 'Culture Badges',
        text: 'Show off where your company culture excels.',
    },
]

export const listMediaKitAnnouncement = {
    listDiy: [
        {
            imgPath: 'diy1.svg',
            heading: 'Update your Top Workplaces company profile',
            text: 'Leverage your company profile on topworkplaces.com, the site visited by millions.',
        },
        {
            imgPath: 'diy2.svg',
            heading: 'Link to the award listing on topworkplaces.com',
            text: 'Link to the award listing to show job seekers and customers you are a winner.',
        },
        {
            imgPath: 'diy3.svg',
            heading: "Share your company's achievement",
            text: 'Boost pride and morale and let your employees know they are appreciated.',
        },
    ],
    listPro: [
        {
            imgPath: 'pro1.svg',
            heading: 'Show off your Top Workplaces national awards',
            text: 'Feature your Top workplaces national badges on your website, social media, and more.',
        },
        {
            imgPath: 'pro2.svg',
            heading: 'Gain media attention as a Top Workplaces winner',
            text: 'Access the Top Workplaces Premium kit filled with ready-to-use tools and templates for promoting your win.',
        },
        {
            imgPath: 'pro3.svg',
            heading: 'Build a customized profile featuring your company strengths',
            text: 'Use Employee Branding assets to enhance your premium profile on topworkplaces.com.',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Leverage your company strengths to improve recruitment',
            text: 'Add Top Workplaces national awards, Culture Facts, Culture Clouds, and Culture Stats to job postings, ads, and more.',
        },
    ],
}

export const listFreeNonSubscriber = {
    listDiy: [
        {
            imgPath: 'pro2.svg',
            heading: 'Social media images',
            text: 'Capture the attention of followers and attract new ones.',
        },
        {
            imgPath: 'diy4.svg',
            heading: 'Formatted press release template',
            text: 'Gain immediate media exposure and expand your reach.',
        },
        {
            imgPath: 'diy3.svg',
            heading: 'Internal announcement template',
            text: 'Boost employee morale by sharing the exciting news.',
        },
    ],
    listPro: [
        {
            imgPath: 'pro8.svg',
            heading: 'Premium Media Kits',
            text: 'Access kits for current and any future premium Top Workplaces awards.',
        },
        {
            imgPath: 'pro6.svg',
            heading: 'Premium Company Profile on topworkplaces.com',
            text: 'Create a custom profile that’s featured on the site seen by millions each year.',
        },
        {
            imgPath: 'pro7.svg',
            heading: 'Culture Facts',
            text: 'Leverage employee feedback to show what makes your culture unique.',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Culture Badges',
            text: 'Show off where your company culture excels.',
        },
    ],
}

export const listPurchaseNonSubscriber = {
    listPro: [
        {
            imgPath: 'pro1.svg',
            heading: 'Premium Media Kits',
            text: 'Access kits for current and any future premium Top Workplaces awards.',
        },
        {
            imgPath: 'pro6.svg',
            heading: 'Premium Company Profile on topworkplaces.com',
            text: 'Create a custom profile that’s featured on the site seen by millions each year.',
        },
        {
            imgPath: 'pro7.svg',
            heading: 'Culture Facts',
            text: 'Leverage employee feedback to show what makes your culture unique.',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Culture Badges',
            text: 'Show off where your company culture excels.',
        },
    ],
}

export const listPhillyNonSubscriber = {
    listPro: [
        {
            imgPath: 'pro5.svg',
            heading: 'Premium Media Kits',
            text: 'Access kits for current and any future premium Top Workplaces awards.',
        },
        {
            imgPath: 'pro6.svg',
            heading: 'Premium Company Profile on topworkplaces.com',
            text: 'Create a custom profile that’s featured on the site seen by millions each year.',
        },
        {
            imgPath: 'pro7.svg',
            heading: 'Culture Facts',
            text: 'Leverage employee feedback to show what makes your culture unique.',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Culture Badges',
            text: 'Show off where your company culture excels.',
        },
    ],
}

export const listFreeSubscriber = {
    listPro: [
        {
            imgPath: 'pro1.svg',
            heading: 'Show off your Top Workplaces national awards',
            text: 'Feature your Top Workplaces national badges on your website, social media, and more.',
        },
        {
            imgPath: 'pro2.svg',
            heading: 'Gain media attention as a Top Workplaces winner',
            text: 'Access the Top Workplaces Premium Kit filled with ready-to-use tools and templates for promoting your win.',
        },
        {
            imgPath: 'pro3.svg',
            heading: 'Build a customized profile featuring your company strengths',
            text: 'Use Employer Branding assets to enhance your premium profile on topworkplaces.com',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Leverage your company strengths to improve recruitment',
            text: 'Add Top Workplaces national awards, Culture Facts, Culture Clouds, and Culture Stats to job postings, ads, and more.',
        },
    ],
}

export const listPurchaseSubscriber = {
    listPro: [
        {
            imgPath: 'pro8.svg',
            heading: 'Show off your Top Workplaces national awards',
            text: 'Feature your Top Workplaces national badges on your website, social media, and more.',
        },
        {
            imgPath: 'pro2.svg',
            heading: 'Gain media attention as a Top Workplaces winner',
            text: 'Access the Top Workplaces Premium Kit filled with ready-to-use tools and templates for promoting your win.',
        },
        {
            imgPath: 'pro3.svg',
            heading: 'Build a customized profile featuring your company strengths',
            text: 'Use Employer Branding assets to enhance your premium profile on topworkplaces.com',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Leverage your company strengths to improve recruitment',
            text: 'Add Top Workplaces national awards, Culture Facts, Culture Clouds, and Culture Stats to job postings, ads, and more.',
        },
    ],
}

export const listPhillySubscriber = {
    listPro: [
        {
            imgPath: 'pro5.svg',
            heading: 'Show off your Top Workplaces awards',
            text: 'Feature your Top Workplaces badges on your website, social media, and more.',
        },
        {
            imgPath: 'pro2.svg',
            heading: 'Gain media attention as a Top Workplaces winner',
            text: 'Access the Top Workplaces Premium Kit filled with ready-to-use tools and templates for promoting your win.',
        },
        {
            imgPath: 'pro3.svg',
            heading: 'Build a customized profile featuring your company strengths',
            text: 'Use Employer Branding assets to enhance your premium profile on topworkplaces.com',
        },
        {
            imgPath: 'pro4.svg',
            heading: 'Leverage your company strengths to improve recruitment',
            text: 'Add Top Workplaces national awards, Culture Facts, Culture Clouds, and Culture Stats to job postings, ads, and more.',
        },
    ],
}

export const nationalStandardHeaders = {
    sectionName: 'Learn more about Employer Branding',
    headingDiy: 'Promote Your Award',
    textDiy: 'Use Your Media Kit',
    subTextDiy: 'Kit includes materials to self-promote your Top Workplaces win, such as:',
    headingPro: 'Build Your Brand',
    textPro: 'Upgrade to Employer Branding',
    subTextPro: 'Subscription includes the Premium Media Kit plus tools for building your employer brand.',
}

export const headers = {
    sectionName: 'Promote and celebrate your Top Workplaces award',
    headingDiy: 'Celebrate Your Award',
    textDiy: 'Tell the world your company is a Top Workplace',
    subTextDiy: 'Highlight your Top Workplaces win with these quick steps:',
    headingPro: 'Build Your Brand',
    textPro: 'Promote and advertise your award-winning culture',
    subTextPro:
        'The Employer Branding subscription gives you full access to the Top Workplaces national award logos, media kit tools, and custom assets.',
}

export const subscriberHeaders = {
    sectionName: 'Promote and celebrate your Top Workplaces award',
    headingDiy: 'Celebrate Your Award',
    textDiy: 'Tell the world your company is a Top Workplace',
    subTextDiy: 'Highlight your Top Workplaces win with these quick steps:',
    headingPro: 'Build Your Brand',
    textPro: 'Promote and advertise your award-winning culture',
    subTextPro: (
        <>
            {'Your Employer Branding subscription includes the '}
            <span className="font-black">{'Premium Media Kit'}</span> {' plus tools for building your employer brand.'}
        </>
    ),
}

export const dallasSubscriberHeaders = {
    sectionName: 'Promote and celebrate your Top Workplaces award',
    headingDiy: 'Celebrate Your Award',
    textDiy: 'Tell the world your company is a Top Workplace',
    subTextDiy: 'Highlight your Top Workplaces win with these quick steps:',
    headingPro: 'Build Your Brand',
    textPro: 'Promote and advertise your award-winning culture',
    subTextPro: (
        <>
            {'Your Employer Branding subscription includes the '}
            <span className="font-black">{'Premium Media Kit'}</span> {' plus tools for building your employer brand.'}
        </>
    ),
}

export const freeNonSubscriberHeaders = {
    sectionName: 'Learn more about Employer Branding',
    headingDiy: 'Promote Your Award',
    textDiy: 'Use Your Media Kit',
    subTextDiy: 'Kit includes materials to self-promote your Top Workplaces win, such as:',
    headingPro: 'Build Your Brand',
    textPro: 'Upgrade to Employer Branding',
    subTextPro: (
        <>
            {'Subscription '}
            <span className="font-black">{'includes the Premium Media Kit'}</span>{' '}
            {' plus tools for building your employer brand.'}
        </>
    ),
}

export const nonSubscriberHeaders = {
    sectionName: 'Learn more about Employer Branding',
    headingDiy: 'Promote Your Award',
    textDiy: 'Purchase the Premium Media Kit',
    subTextDiy: 'Kit includes materials to self-promote your Top Workplaces win, such as:',
    headingPro: 'Build Your Brand',
    textPro: 'Upgrade to Employer Branding',
    subTextPro: (
        <>
            {'Your Employer Branding subscription '}
            <span className="font-black">{'includes the Premium Media Kit'}</span>{' '}
            {' plus tools for building your employer brand.'}
        </>
    ),
}
