import { colors } from '@energage/theme'
import { FAQs as FAQsAspirantRevamp } from 'containers/Main/AwardAnnouncement/AspirantRevamp/FAQs'
import { HighlightCulture } from 'containers/Main/AwardAnnouncement/AspirantRevamp/HighlightCulture'
import { MoreAwards } from 'containers/Main/AwardAnnouncement/AspirantRevamp/MoreAwards'
import { ThankYouForParticipatingRevamp } from 'containers/Main/AwardAnnouncement/AspirantRevamp/ThankYouForParticipatingRevamp'
import { UnderstandYourOrg } from 'containers/Main/AwardAnnouncement/AspirantRevamp/UnderstandYourOrg'
import type { AspiringAwardPdf } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { BgContainer } from 'containers/Main/AwardAnnouncement/BgContainer'
import { FAQ_URL } from 'containers/Main/AwardAnnouncement/constants'
import { PageBreaker } from './AwardAnnouncementPdf'

export const AspiringPdf = ({ award, baseUrl }: AspiringAwardPdf) => {
    const { companyName, year, awardAliasName, listName, requiresTheBeforeName } = award

    return (
        <>
            <BgContainer backgroundColor={colors.grey100} isDefaultBgHeight>
                <ThankYouForParticipatingRevamp
                    companyName={companyName}
                    listName={listName}
                    requiresTheBeforeName={requiresTheBeforeName}
                    awardAliasName={awardAliasName}
                />
                <HighlightCulture bgColor={colors.purple100} textColor={colors.purple700} baseUrl={baseUrl} isPdf />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.yellow100} isDefaultBgHeight>
                <UnderstandYourOrg />
                <MoreAwards bgColor={colors.purple100} textColor={colors.purple700} baseUrl={baseUrl} isPdf />
            </BgContainer>
            <PageBreaker />
            <BgContainer backgroundColor={colors.darkblue500} isDefaultBgHeight>
                <FAQsAspirantRevamp
                    companyName={companyName}
                    year={year}
                    faqUrl={FAQ_URL}
                    bgColor={colors.darkblue500}
                    btnBgColor={colors.yellow500}
                    btnColor={colors.darkblue700}
                    isPdf={true}
                />
            </BgContainer>
        </>
    )
}
