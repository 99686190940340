import { Sheet, SheetTabs } from '@energage/overlays'
import { colors } from '@energage/theme'
import ThumbCarousel from '../../../containers/Main/EmployerBranding/ThumbCarousel/ThumbCarousel'
import asset from '../../../images/employerBranding/inspirationGallery/asset.svg'
import award from '../../../images/employerBranding/inspirationGallery/award.svg'
import image_awards_01 from '../../../images/employerBranding/inspirationGallery/awards/image-awards-01.png'
import image_awards_02 from '../../../images/employerBranding/inspirationGallery/awards/image-awards-02.png'
import image_awards_03 from '../../../images/employerBranding/inspirationGallery/awards/image-awards-03.png'
import image_awards_04 from '../../../images/employerBranding/inspirationGallery/awards/image-awards-04.png'
import image_awards_05 from '../../../images/employerBranding/inspirationGallery/awards/image-awards-05.png'
import thumbnail_awards_01 from '../../../images/employerBranding/inspirationGallery/awards/thumbnail-awards-01.png'
import thumbnail_awards_02 from '../../../images/employerBranding/inspirationGallery/awards/thumbnail-awards-02.png'
import thumbnail_awards_03 from '../../../images/employerBranding/inspirationGallery/awards/thumbnail-awards-03.png'
import thumbnail_awards_04 from '../../../images/employerBranding/inspirationGallery/awards/thumbnail-awards-04.png'
import thumbnail_awards_05 from '../../../images/employerBranding/inspirationGallery/awards/thumbnail-awards-05.png'
import image_banner_01 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-01.png'
import image_banner_02 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-02.png'
import image_banner_03 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-03.png'
import image_banner_04 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-04.png'
import image_banner_05 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-05.png'
import image_banner_06 from '../../../images/employerBranding/inspirationGallery/banner/image-banner-06.png'
import thumbnail_banner_01 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-01.png'
import thumbnail_banner_02 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-02.png'
import thumbnail_banner_03 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-03.png'
import thumbnail_banner_04 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-04.png'
import thumbnail_banner_05 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-05.png'
import thumbnail_banner_06 from '../../../images/employerBranding/inspirationGallery/banner/thumbnail-banner-06.png'
import facebook from '../../../images/employerBranding/inspirationGallery/facebook.svg'
import image_facebook_01 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-01.png'
import image_facebook_02 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-02.png'
import image_facebook_03 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-03.png'
import image_facebook_04 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-04.png'
import image_facebook_05 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-05.png'
import image_facebook_06 from '../../../images/employerBranding/inspirationGallery/facebook/image-facebook-06.png'
import thumbnail_facebook_01 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-01.png'
import thumbnail_facebook_02 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-02.png'
import thumbnail_facebook_03 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-03.png'
import thumbnail_facebook_04 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-04.png'
import thumbnail_facebook_05 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-05.png'
import thumbnail_facebook_06 from '../../../images/employerBranding/inspirationGallery/facebook/thumbnail-facebook-06.png'
import Icon_PurpLine1 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine1.svg'
import Icon_PurpLine2 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine2.svg'
import Icon_PurpLine3 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine3.svg'
import Icon_PurpLine4 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine4.svg'
import Icon_PurpLine5 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine5.svg'
import Icon_PurpLine6 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine6.svg'
import Icon_PurpLine7 from '../../../images/employerBranding/inspirationGallery/Icon-PurpLine7.svg'
import instagram from '../../../images/employerBranding/inspirationGallery/instagram.svg'
import image_ig_01 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-01.png'
import image_ig_02 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-02.png'
import image_ig_03 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-03.png'
import image_ig_04 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-04.png'
import image_ig_05 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-05.png'
import image_ig_06 from '../../../images/employerBranding/inspirationGallery/instagram/image-ig-06.png'
import thumbnail_ig_01 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-01.png'
import thumbnail_ig_02 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-02.png'
import thumbnail_ig_03 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-03.png'
import thumbnail_ig_04 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-04.png'
import thumbnail_ig_05 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-05.png'
import thumbnail_ig_06 from '../../../images/employerBranding/inspirationGallery/instagram/thumbnail-ig-06.png'
import linkedin from '../../../images/employerBranding/inspirationGallery/linkedin.svg'
import image_linkedin_01 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-01.png'
import image_linkedin_02 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-02.png'
import image_linkedin_03 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-03.png'
import image_linkedin_04 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-04.png'
import image_linkedin_05 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-05.png'
import image_linkedin_06 from '../../../images/employerBranding/inspirationGallery/linkedin/image-linkedin-06.png'
import thumbnail_linkedin_01 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-01.png'
import thumbnail_linkedin_02 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-02.png'
import thumbnail_linkedin_03 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-03.png'
import thumbnail_linkedin_04 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-04.png'
import thumbnail_linkedin_05 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-05.png'
import thumbnail_linkedin_06 from '../../../images/employerBranding/inspirationGallery/linkedin/thumbnail-linkedin-06.png'
import image_quotes_01 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-01.png'
import image_quotes_02 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-02.png'
import image_quotes_03 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-03.png'
import image_quotes_04 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-04.png'
import image_quotes_05 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-05.png'
import image_quotes_06 from '../../../images/employerBranding/inspirationGallery/quotes/image-quotes-06.png'
import thumbnail_quotes_01 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-01.png'
import thumbnail_quotes_02 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-02.png'
import thumbnail_quotes_03 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-03.png'
import thumbnail_quotes_04 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-04.png'
import thumbnail_quotes_05 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-05.png'
import thumbnail_quotes_06 from '../../../images/employerBranding/inspirationGallery/quotes/thumbnail-quotes-06.png'
import image_stats_01 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-01.png'
import image_stats_02 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-02.png'
import image_stats_03 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-03.png'
import image_stats_04 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-04.png'
import image_stats_05 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-05.png'
import image_stats_06 from '../../../images/employerBranding/inspirationGallery/stats/image-stats-06.png'
import thumbnail_stats_01 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-01.png'
import thumbnail_stats_02 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-02.png'
import thumbnail_stats_03 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-03.png'
import thumbnail_stats_04 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-04.png'
import thumbnail_stats_05 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-05.png'
import thumbnail_stats_06 from '../../../images/employerBranding/inspirationGallery/stats/thumbnail-stats-06.png'
import image_strengths_01 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-01.png'
import image_strengths_02 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-02.png'
import image_strengths_03 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-03.png'
import image_strengths_04 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-04.png'
import image_strengths_05 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-05.png'
import image_strengths_06 from '../../../images/employerBranding/inspirationGallery/strengths/image-strengths-06.png'
import thumbnail_strengths_01 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-01.png'
import thumbnail_strengths_02 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-02.png'
import thumbnail_strengths_03 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-03.png'
import thumbnail_strengths_04 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-04.png'
import thumbnail_strengths_05 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-05.png'
import thumbnail_strengths_06 from '../../../images/employerBranding/inspirationGallery/strengths/thumbnail-strengths-06.png'
import website from '../../../images/employerBranding/inspirationGallery/website.svg'
import image_website_01 from '../../../images/employerBranding/inspirationGallery/website/image-website-01.png'
import image_website_02 from '../../../images/employerBranding/inspirationGallery/website/image-website-02.png'
import image_website_03 from '../../../images/employerBranding/inspirationGallery/website/image-website-03.png'
import image_website_04 from '../../../images/employerBranding/inspirationGallery/website/image-website-04.png'
import image_website_05 from '../../../images/employerBranding/inspirationGallery/website/image-website-05.png'
import image_website_06 from '../../../images/employerBranding/inspirationGallery/website/image-website-06.png'
import thumbnail_website_01 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-01.png'
import thumbnail_website_02 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-02.png'
import thumbnail_website_03 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-03.png'
import thumbnail_website_04 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-04.png'
import thumbnail_website_05 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-05.png'
import thumbnail_website_06 from '../../../images/employerBranding/inspirationGallery/website/thumbnail-website-06.png'
import image_wordcloud_01 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-01.png'
import image_wordcloud_02 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-02.png'
import image_wordcloud_03 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-03.png'
import image_wordcloud_04 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-04.png'
import image_wordcloud_05 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-05.png'
import image_wordcloud_06 from '../../../images/employerBranding/inspirationGallery/wordcloud/image-wordcloud-06.png'
import thumbnail_wordcloud_01 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-01.png'
import thumbnail_wordcloud_02 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-02.png'
import thumbnail_wordcloud_03 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-03.png'
import thumbnail_wordcloud_04 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-04.png'
import thumbnail_wordcloud_05 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-05.png'
import thumbnail_wordcloud_06 from '../../../images/employerBranding/inspirationGallery/wordcloud/thumbnail-wordcloud-06.png'
import x from '../../../images/employerBranding/inspirationGallery/x.svg'
import image_x_01 from '../../../images/employerBranding/inspirationGallery/x/image-x-01.png'
import image_x_02 from '../../../images/employerBranding/inspirationGallery/x/image-x-02.png'
import image_x_03 from '../../../images/employerBranding/inspirationGallery/x/image-x-03.png'
import image_x_04 from '../../../images/employerBranding/inspirationGallery/x/image-x-04.png'
import image_x_05 from '../../../images/employerBranding/inspirationGallery/x/image-x-05.png'
import image_x_06 from '../../../images/employerBranding/inspirationGallery/x/image-x-06.png'
import thumbnail_x_01 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-01.png'
import thumbnail_x_02 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-02.png'
import thumbnail_x_03 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-03.png'
import thumbnail_x_04 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-04.png'
import thumbnail_x_05 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-05.png'
import thumbnail_x_06 from '../../../images/employerBranding/inspirationGallery/x/thumbnail-x-06.png'
import BottomContent from './CultureFacts/BottomContent'

interface Image {
    id: string
    mainImage: string
    thumbImage: string
    backgroundColor: string[]
}

interface PlatformImages {
    [key: string]: Image[]
}

const platformImages: PlatformImages = {
    website: [
        {
            id: '1',
            mainImage: image_website_01,
            thumbImage: thumbnail_website_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_website_02,
            thumbImage: thumbnail_website_02,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '3',
            mainImage: image_website_03,
            thumbImage: thumbnail_website_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_website_04,
            thumbImage: thumbnail_website_04,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '5',
            mainImage: image_website_05,
            thumbImage: thumbnail_website_05,
            backgroundColor: ['#272726', '#373330'],
        },
        {
            id: '6',
            mainImage: image_website_06,
            thumbImage: thumbnail_website_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    awards: [
        {
            id: '1',
            mainImage: image_awards_01,
            thumbImage: thumbnail_awards_01,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '2',
            mainImage: image_awards_02,
            thumbImage: thumbnail_awards_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_awards_03,
            thumbImage: thumbnail_awards_03,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '4',
            mainImage: image_awards_04,
            thumbImage: thumbnail_awards_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_awards_05,
            thumbImage: thumbnail_awards_05,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    instagram: [
        { id: '1', mainImage: image_ig_01, thumbImage: thumbnail_ig_01, backgroundColor: ['#B9BCF2', '#898CC5'] },
        { id: '2', mainImage: image_ig_02, thumbImage: thumbnail_ig_02, backgroundColor: ['#FFB173', '#FFA359'] },
        {
            id: '3',
            mainImage: image_ig_03,
            thumbImage: thumbnail_ig_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        { id: '4', mainImage: image_ig_04, thumbImage: thumbnail_ig_04, backgroundColor: ['#3246FF', '#0B199A'] },
        { id: '5', mainImage: image_ig_05, thumbImage: thumbnail_ig_05, backgroundColor: ['#616B06', '#B4BD00'] },
        { id: '6', mainImage: image_ig_06, thumbImage: thumbnail_ig_06, backgroundColor: ['#272726', '#373330'] },
    ],
    facebook: [
        {
            id: '1',
            mainImage: image_facebook_01,
            thumbImage: thumbnail_facebook_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_facebook_02,
            thumbImage: thumbnail_facebook_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_facebook_03,
            thumbImage: thumbnail_facebook_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_facebook_04,
            thumbImage: thumbnail_facebook_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_facebook_05,
            thumbImage: thumbnail_facebook_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_facebook_06,
            thumbImage: thumbnail_facebook_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    linkedin: [
        {
            id: '1',
            mainImage: image_linkedin_01,
            thumbImage: thumbnail_linkedin_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_linkedin_02,
            thumbImage: thumbnail_linkedin_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_linkedin_03,
            thumbImage: thumbnail_linkedin_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_linkedin_04,
            thumbImage: thumbnail_linkedin_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_linkedin_05,
            thumbImage: thumbnail_linkedin_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_linkedin_06,
            thumbImage: thumbnail_linkedin_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    x: [
        { id: '1', mainImage: image_x_01, thumbImage: thumbnail_x_01, backgroundColor: ['#B9BCF2', '#898CC5'] },
        { id: '2', mainImage: image_x_02, thumbImage: thumbnail_x_02, backgroundColor: ['#FFB173', '#FFA359'] },
        {
            id: '3',
            mainImage: image_x_03,
            thumbImage: thumbnail_x_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        { id: '4', mainImage: image_x_04, thumbImage: thumbnail_x_04, backgroundColor: ['#3246FF', '#0B199A'] },
        { id: '5', mainImage: image_x_05, thumbImage: thumbnail_x_05, backgroundColor: ['#616B06', '#B4BD00'] },
        { id: '6', mainImage: image_x_06, thumbImage: thumbnail_x_06, backgroundColor: ['#272726', '#373330'] },
    ],
    stats: [
        { id: '1', mainImage: image_stats_01, thumbImage: thumbnail_stats_01, backgroundColor: ['#B9BCF2', '#898CC5'] },
        { id: '2', mainImage: image_stats_02, thumbImage: thumbnail_stats_02, backgroundColor: ['#FFB173', '#FFA359'] },
        {
            id: '3',
            mainImage: image_stats_03,
            thumbImage: thumbnail_stats_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        { id: '4', mainImage: image_stats_04, thumbImage: thumbnail_stats_04, backgroundColor: ['#3246FF', '#0B199A'] },
        { id: '5', mainImage: image_stats_05, thumbImage: thumbnail_stats_05, backgroundColor: ['#616B06', '#B4BD00'] },
        { id: '6', mainImage: image_stats_06, thumbImage: thumbnail_stats_06, backgroundColor: ['#272726', '#373330'] },
    ],
    strengths: [
        {
            id: '1',
            mainImage: image_strengths_01,
            thumbImage: thumbnail_strengths_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_strengths_02,
            thumbImage: thumbnail_strengths_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_strengths_03,
            thumbImage: thumbnail_strengths_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_strengths_04,
            thumbImage: thumbnail_strengths_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_strengths_05,
            thumbImage: thumbnail_strengths_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_strengths_06,
            thumbImage: thumbnail_strengths_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    banner: [
        {
            id: '1',
            mainImage: image_banner_01,
            thumbImage: thumbnail_banner_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_banner_02,
            thumbImage: thumbnail_banner_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_banner_03,
            thumbImage: thumbnail_banner_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_banner_04,
            thumbImage: thumbnail_banner_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_banner_05,
            thumbImage: thumbnail_banner_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_banner_06,
            thumbImage: thumbnail_banner_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    cloud: [
        {
            id: '1',
            mainImage: image_wordcloud_01,
            thumbImage: thumbnail_wordcloud_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_wordcloud_02,
            thumbImage: thumbnail_wordcloud_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_wordcloud_03,
            thumbImage: thumbnail_wordcloud_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_wordcloud_04,
            thumbImage: thumbnail_wordcloud_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_wordcloud_05,
            thumbImage: thumbnail_wordcloud_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_wordcloud_06,
            thumbImage: thumbnail_wordcloud_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
    quotes: [
        {
            id: '1',
            mainImage: image_quotes_01,
            thumbImage: thumbnail_quotes_01,
            backgroundColor: ['#B9BCF2', '#898CC5'],
        },
        {
            id: '2',
            mainImage: image_quotes_02,
            thumbImage: thumbnail_quotes_02,
            backgroundColor: ['#FFB173', '#FFA359'],
        },
        {
            id: '3',
            mainImage: image_quotes_03,
            thumbImage: thumbnail_quotes_03,
            backgroundColor: ['#525E94', '#3A4369', '#282E47'],
        },
        {
            id: '4',
            mainImage: image_quotes_04,
            thumbImage: thumbnail_quotes_04,
            backgroundColor: ['#3246FF', '#0B199A'],
        },
        {
            id: '5',
            mainImage: image_quotes_05,
            thumbImage: thumbnail_quotes_05,
            backgroundColor: ['#616B06', '#B4BD00'],
        },
        {
            id: '6',
            mainImage: image_quotes_06,
            thumbImage: thumbnail_quotes_06,
            backgroundColor: ['#272726', '#373330'],
        },
    ],
}

interface TabItem {
    id: string
    label: {
        icon: JSX.Element
        text: string
    }
    content: JSX.Element
}

interface Tab {
    id: string
    title: string
    items: TabItem[]
}

export const tabs: Tab[] = [
    {
        id: 'platform',
        title: 'Platforms',
        items: [
            {
                id: 'websites',
                label: {
                    icon: <img src={website} alt="websites Icon" />,
                    text: 'Websites',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.website} />
                        <BottomContent
                            image={Icon_PurpLine5}
                            title="Website"
                            subtitle="Platform"
                            description="Your company website, including the main site, careers page, and open positions, serves as the primary destination for candidates to learn about your company culture. Because these pages provide valuable insights into what it's like to work at your company, consider sharing assets such as your organizational strengths, employee testimonials, and any awards your company has won for aspects of the culture.
                            "
                        />
                    </>
                ),
            },
            {
                id: 'instagram',
                label: {
                    icon: <img src={instagram} alt="instagram Icon" />,
                    text: 'Instagram',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.instagram} />
                        <BottomContent
                            image={Icon_PurpLine1}
                            title="Instagram"
                            subtitle="Platform"
                            description="US adults spend an average of 33 minutes daily on Instagram, with the highest usage among individuals aged 18 to 34. This trend positions Instagram as the ideal platform for broadcasting content related to your job postings, particularly for entry-level positions or roles where extensive experience may not be required. Most assets are square for posts, with many also optimized for vertical formats like stories and reels.
                            "
                        />
                    </>
                ),
            },
            {
                id: 'x',
                label: {
                    icon: <img src={x} alt="x Icon" />,
                    text: 'X',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.x} />{' '}
                        <BottomContent
                            image={Icon_PurpLine3}
                            title="X"
                            subtitle="Platform"
                            description="79% of X (formerly Twitter) followers engage with brands to stay informed about their latest updates. Despite being a less used social media platform, X is the go-to destination for breaking news and emerging trends. Consequently, X presents an excellent avenue for sharing updates on awards won and badges earned. Include culture assets to enrich award announcements, providing deeper insights into your achievements."
                        />
                    </>
                ),
            },
            {
                id: 'facebook',
                label: {
                    icon: <img src={facebook} alt="facebook Icon" />,
                    text: 'Facebook',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.facebook} />{' '}
                        <BottomContent
                            image={Icon_PurpLine2}
                            title="Facebook"
                            subtitle="Platform"
                            description="60% of recruiting managers leverage Facebook's colossal reach of 2 billion daily active users to search for talent. This makes Facebook a powerful stage to spotlight your company culture. Share your awards, badges, and assets that support your overall company culture. When crafting job ads, don't miss out on the opportunity to personalize by department. "
                        />
                    </>
                ),
            },
            {
                id: 'linkedin',
                label: {
                    icon: <img src={linkedin} alt="linkedin Icon" />,
                    text: 'LinkedIn',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.linkedin} />{' '}
                        <BottomContent
                            image={Icon_PurpLine4}
                            title="LinkedIn"
                            subtitle="Platform"
                            description="52 million people use LinkedIn to search for jobs each week, making it a prime platform for recruitment. Maximize visibility by leveraging the LinkedIn banner to showcase organization strengths. Utilize the culture cloud to provide deeper insights into company culture. Tailor assets by department for job posts, offering candidates a glimpse into the unique culture of their potential team."
                        />
                    </>
                ),
            },
        ],
    },
    {
        id: 'achievements',
        title: 'Achievements',
        items: [
            {
                id: 'awards',
                label: {
                    icon: <img src={award} alt="award Icon" />,
                    text: 'Awards',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.awards} />
                        <BottomContent
                            image={Icon_PurpLine7}
                            title="Awards"
                            subtitle="Achievements"
                            description="Winning a Top Workplace award is a testament to excellence, deserving visibility across all platforms. Typically, companies prominently display the logo on their website. Showing that you are a Top Workplace is great for potential candidates, but it is also a great way to show other businesses more about how you run your company.
                            "
                        />
                    </>
                ),
            },
        ],
    },
    {
        id: 'assets',
        title: 'Assets',
        items: [
            {
                id: 'cultureCloud',
                label: {
                    icon: <img src={asset} alt="asset Icon" />,
                    text: 'Culture Cloud',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.cloud} />{' '}
                        <BottomContent
                            image={Icon_PurpLine6}
                            title="Culture Cloud"
                            subtitle="Assets"
                            description="Your Culture Cloud shows the words employees used when asked which words best describe the culture at your company. The larger words are not only used more often, but are less likely to be seen in the word clouds of companies in your benchmark. Leverage this asset to offer candidates an authentic glimpse into your distinctive culture, straight from the voices of your team members."
                        />
                    </>
                ),
            },
            {
                id: 'linkedInBanner',
                label: {
                    icon: <img src={asset} alt="asset Icon" />,
                    text: 'LinkedIn Banner',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.banner} />{' '}
                        <BottomContent
                            image={Icon_PurpLine6}
                            title="LinkedIn Banner"
                            subtitle="Assets"
                            description="The LinkedIn banner merges two facets of your survey data into one dynamic asset. Featuring the top 12 words from your Culture Cloud and a customizable culture stat in the center, it offers a comprehensive snapshot of your company's culture. Opt for a stat that resonates with the words in your cloud to showcase the essence of your culture. Customize the background color to reflect your brand identity."
                        />
                    </>
                ),
            },
            {
                id: 'cultureStats',
                label: {
                    icon: <img src={asset} alt="asset Icon" />,
                    text: 'Culture Stats',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.stats} />{' '}
                        <BottomContent
                            image={Icon_PurpLine6}
                            title="Culture Stats"
                            subtitle="Assets"
                            description="Culture Stats spotlight the survey statements where your employees rate your company the highest, potentially earning you top percentile rankings within your industry benchmark. These stats complement your existing narrative about your culture, providing compelling supporting material. Available in both square and vertical formats, they're perfect for enhancing your presence on platforms like Instagram reels and Facebook stories."
                        />
                    </>
                ),
            },
            {
                id: 'strengths',
                label: {
                    icon: <img src={asset} alt="asset Icon" />,
                    text: 'Strengths',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.strengths} />{' '}
                        <BottomContent
                            image={Icon_PurpLine6}
                            title="Strengths"
                            subtitle="Assets"
                            description="The Organizational Strengths asset highlights your company's top three highest-scoring survey statements, showcasing your key strengths. Choose from multiple designs, giving you the ability to focus on one, two, or all three strengths, emphasizing what sets your company apart. Available in both square and vertical formats, they're ideal for enhancing your visibility on platforms such as Instagram reels and Facebook stories."
                        />
                    </>
                ),
            },
            {
                id: 'comments',
                label: {
                    icon: <img src={asset} alt="asset Icon" />,
                    text: 'Comments',
                },
                content: (
                    <>
                        <ThumbCarousel slides={platformImages.quotes} />{' '}
                        <BottomContent
                            image={Icon_PurpLine6}
                            title="Comments"
                            subtitle="Assets"
                            description="During the the survey, engaged employees are invited to share why they love working for your company. Recognizing that candidates trust a company’s employees three times more than the company itself, these comments serve as powerful endorsements of your company culture. Select one to three comments to bolster your content, focusing on topics highlighted by your employees."
                        />
                    </>
                ),
            },
        ],
    },
]

const InspirationGallery: React.FC<{ isOpen: boolean; onDismiss: () => void; defaultTab: string }> = ({
    isOpen,
    onDismiss,
    defaultTab,
}) => {
    return (
        <Sheet title="Inspiration Gallery" isOpen={isOpen} onDismiss={onDismiss}>
            <SheetTabs
                tabs={tabs}
                defaultValue={defaultTab}
                style={{
                    background: colors.grey200,
                }}
            />
        </Sheet>
    )
}
export default InspirationGallery
