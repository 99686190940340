import { HelpOutline } from '@energage/icons'

const SupportInfo = () => (
    <div className="paragraph-small font-medium text-white w-full">
        <a
            className="sidebar-menu__navigation-link text-primary-dark"
            href="https://energagecommunity.force.com/FAQ/s/"
            target="_blank"
            aria-describedby="help-text"
            rel="noreferrer noopener">
            <HelpOutline className="sidebar-menu__navigation-icon" />
            <span className="md:sr-only sidebar-menu__navigation-label" id="help-text">
                {'Help Center'}
                <span className="sr-only">{' (opens in new window)'}</span>
            </span>
        </a>
    </div>
)

export default SupportInfo
