/* eslint-disable no-console */
import { useReducer } from 'react'
import { format } from 'date-fns'

const colors = {
    title: 'inherit',
    prevState: '#9e9e9e',
    action: '#03a9f4',
    nextState: '#4caf50',
    error: '#f20404',
}

const timer =
    typeof performance !== 'undefined' && performance !== null && typeof performance.now === 'function'
        ? performance
        : Date

const formatTime = (time) => format(time, 'HH:mm:ss.SSS')

const formatTitle = (action, time, took) => `action %c${String(action.type)} %c@ ${time} %c(in ${took.toFixed(2)} ms)`

function logValue(label, color, value) {
    console.log(`%c ${label}`, `color: ${color}; font-weight: bold;`, value)
}

function logAction({ startedTime, took, action, prevState, nextState, error }) {
    const formattedTime = formatTime(startedTime)

    const titleCSS = `color: ${colors.title}`
    const headerCSS = ['color: gray; font-weight: lighter;']
    headerCSS.push(titleCSS, 'color: gray; font-weight: lighter;', 'color: gray; font-weight: lighter;')
    const title = formatTitle(action, formattedTime, took)

    try {
        console.group(`%c ${title}`, ...headerCSS)
    } catch (e) {
        console.log(title)
    }

    logValue('prev state', colors.prevState, prevState)
    logValue('action    ', colors.action, action)
    if (error) {
        logValue('error     ', colors.error, error)
    }
    logValue('next state', colors.nextState, nextState)

    try {
        console.groupEnd()
    } catch (e) {
        console.log('-- log end --')
    }
}

const wrapReducer = (reducer) => (state, action) => {
    const record = {}

    record.started = timer.now()
    record.startedTime = new Date()
    record.prevState = state
    record.action = action

    let nextState
    try {
        nextState = reducer(state, action)
    } catch (e) {
        record.error = e
    }

    record.took = timer.now() - record.started
    record.nextState = nextState

    logAction(record)

    if (record.error) {
        throw record.error
    }

    return nextState
}

export function useLoggingReducer(reducer, initialState) {
    const wrappedReducer = process.env.NODE_ENV !== 'production' ? wrapReducer(reducer) : reducer
    return useReducer(wrappedReducer, initialState)
}
