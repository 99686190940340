import { Fragment, useEffect } from 'react'
import { Loading as Spinner } from '@energage/components'
import { Text } from '@energage/typography'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import styled from 'styled-components'
import { FancyRadioButtonWithLabel } from 'components/FancyRadioButton'
import { useIdentity } from 'components/Identity'
import Jiggle from 'components/Jiggle'
import Pagination from 'components/Paginate'
import { CultureStatsType } from 'containers/Main/EmployerBranding/util'
import {
    CultureStatsGraphicType,
    isRectangleDesignType,
    isTopXPercent,
    isTopXSelected,
    isVersionTable,
    LinkedInBannerId,
    tileData,
} from './utils'

const CultureStatsCommentColumns = styled(Pagination).attrs({ className: 'mb-5' })`
    columns: 1 auto;
    input {
        vertical-align: top;
        margin-top: 3px;
    }
`

const StyledLabel = styled.label`
    page-break-inside: avoid;
    break-inside: avoid;
`

const isSurveyFactorSelected = (item, cultureStatsTableData) =>
    item?.surveyFactorId === cultureStatsTableData?.surveyFactorId

const CommentsList = ({
    data,
    setCommentSelected,
    dataId,
    selectedCultureType,
    setSelectedCultureType,
    cultureStatsTableData,
    isTopXPercentStatement,
    isContentFilter,
    updatedItems,
    isFilterApplied,
    isTopXFiltered,
}) => {
    const itemsPerPage = 3
    const { organizationName } = useIdentity()
    const isTopXStatement = isTopXSelected(data?.cultureStatTypeSelected)
    const isLinkedInBanner = dataId === LinkedInBannerId

    let isPercentPositive = true
    let isTopX = true

    if (isContentFilter) {
        isTopX = isTopXPercentStatement
        isPercentPositive = !isTopXPercentStatement
    }

    if (!head(updatedItems) || isTopXFiltered) {
        return null
    }

    return (
        <>
            <Text textStyle="title6" className="text-sm mt-8">
                <h5>{'Select Statement'}</h5>
            </Text>
            <Jiggle>
                <span className="mb-3 text-sm">{'You can select 1 statement to display in your widget.'}</span>
            </Jiggle>
            <CultureStatsCommentColumns
                items={updatedItems}
                itemsPerPage={itemsPerPage}
                renderItem={(item) => (
                    <Fragment key={item.name}>
                        {(!isFilterApplied || item.percentPositiveStatement || !item.topXPercentStatement) &&
                            (data.sector !== null || (data.sector === null && item.percentPositiveStatement)) && (
                                <h5 className="mt-3 text-lg font-semibold">{item.name}</h5>
                            )}
                        {item.percentPositiveStatement && isPercentPositive && (
                            <StyledLabel className="block mb-3" data-event-id={dataId && `${dataId}-comment-selection`}>
                                <FancyRadioButtonWithLabel
                                    className="mr-2"
                                    name="cultureStatStatement"
                                    checked={
                                        isContentFilter
                                            ? isSurveyFactorSelected(item, cultureStatsTableData)
                                            : item.isSelected && !isTopXStatement
                                    }
                                    onChange={setCommentSelected(
                                        item,
                                        CultureStatsType.PercentPositive,
                                        selectedCultureType,
                                        setSelectedCultureType
                                    )}
                                    disabled={isContentFilter && !isSurveyFactorSelected(item, cultureStatsTableData)}
                                    label={
                                        isLinkedInBanner
                                            ? item.percentPositiveStatement.linkedInStatement
                                            : item.percentPositiveStatement.text
                                    }
                                />
                            </StyledLabel>
                        )}
                        {data.sector === null ? (
                            <StyledLabel className="block mb-3" data-event-id={dataId && `${dataId}-comment-selection`}>
                                {item.percentPositiveStatement && isPercentPositive && (
                                    <FancyRadioButtonWithLabel
                                        className="mr-2"
                                        name="cultureStatStatement"
                                        label={`Industry benchmark is not available for ${organizationName}, hence industry percentage is not applicable.`}
                                        disabled
                                    />
                                )}
                            </StyledLabel>
                        ) : (
                            <StyledLabel className="block mb-3" data-event-id={dataId && `${dataId}-comment-selection`}>
                                {item.topXPercentStatement && isTopX && !isFilterApplied && (
                                    <FancyRadioButtonWithLabel
                                        className="mr-2"
                                        name="cultureStatStatement"
                                        checked={
                                            isContentFilter
                                                ? isSurveyFactorSelected(item, cultureStatsTableData)
                                                : item.isSelected && isTopXStatement
                                        }
                                        onChange={setCommentSelected(
                                            item,
                                            CultureStatsType.TopXPercent,
                                            selectedCultureType,
                                            setSelectedCultureType
                                        )}
                                        disabled={
                                            isContentFilter && !isSurveyFactorSelected(item, cultureStatsTableData)
                                        }
                                        label={item.topXPercentStatement.text}
                                    />
                                )}
                            </StyledLabel>
                        )}
                    </Fragment>
                )}
                dataId={dataId}
            />
        </>
    )
}

const CultureStatsCommentSelector = ({
    data,
    onChange,
    isLoading,
    dataId,
    selectedCultureType,
    setSelectedCultureType,
    setSelectedTopXDesignType,
    setSelectedPercentPositiveDesignType,
    setSelectedRectangleDesignType,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType,
    cultureStatsTableData,
    isExpiredWidget,
    isFilterApplied,
    isTopXFiltered,
    hasEmployerBrandingSubscription,
}) => {
    const isTopXPercentStatement = isTopXPercent(cultureStatsTableData)
    const isContentFilter = isVersionTable(cultureStatsTableData, isExpiredWidget)
    const isTopXPercentage = isContentFilter
        ? isTopXPercentStatement
        : selectedCultureType === CultureStatsType.TopXPercent

    useEffect(() => {
        setSelectedCultureType(data?.cultureStatTypeSelected)
    }, [setSelectedCultureType, data])

    const updatedItems = isContentFilter
        ? tileData(
              isTopXPercentStatement,
              filter(data?.statements, (s) => s?.topXPercentStatement),
              filter(data?.statements, (s) => s?.percentPositiveStatement)
          )
        : data?.statements

    const setCommentSelected = (statement, cultureType) => {
        const isTopXStatement = isContentFilter ? isTopXPercentStatement : cultureType === CultureStatsType.TopXPercent

        return (e) => {
            let selectedDesignType = isRectangleDesignType(selectedRectangleDesignType)
                ? selectedRectangleDesignType
                : isTopXStatement
                ? selectedTopXDesignType
                : selectedPercentPositiveDesignType

            if (isRectangleDesignType(selectedRectangleDesignType)) {
                selectedDesignType = selectedRectangleDesignType
                setSelectedTopXDesignType(CultureStatsGraphicType.None)
                setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
            } else if (isTopXStatement) {
                switch (selectedPercentPositiveDesignType) {
                    case CultureStatsGraphicType.Donut:
                        selectedDesignType = CultureStatsGraphicType.Circle
                        break
                    default:
                        selectedDesignType = CultureStatsGraphicType.LaurelHalfCircle
                        break
                }

                setSelectedCultureType(
                    isTopXPercentage ? CultureStatsType.PercentPositive : CultureStatsType.TopXPercent
                )
                setSelectedTopXDesignType(selectedDesignType)
                setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
                setSelectedRectangleDesignType?.(CultureStatsGraphicType.None)
            } else {
                switch (selectedTopXDesignType) {
                    case CultureStatsGraphicType.Circle:
                        selectedDesignType = CultureStatsGraphicType.Donut
                        break
                    default:
                        selectedDesignType = CultureStatsGraphicType.HalfDonut
                        break
                }

                setSelectedTopXDesignType(CultureStatsGraphicType.None)
                setSelectedPercentPositiveDesignType(selectedDesignType)
                setSelectedRectangleDesignType?.(CultureStatsGraphicType.None)
            }

            const cutureStatType = !isTopXStatement ? CultureStatsType.PercentPositive : CultureStatsType.TopXPercent

            const newComments = map(updatedItems, (smt) => {
                const istopXPercentStatement = smt.topXPercentStatement?.text === statement.topXPercentStatement?.text
                const isPercentPositiveStatement =
                    smt.percentPositiveStatement?.text === statement.percentPositiveStatement?.text
                const isStatementChanged = isContentFilter
                    ? tileData(isTopXPercentStatement, istopXPercentStatement, isPercentPositiveStatement)
                    : isPercentPositiveStatement && istopXPercentStatement

                if (isStatementChanged) {
                    return {
                        ...smt,
                        isSelected: e.target.checked,
                    }
                }
                return {
                    ...smt,
                    isSelected: false,
                }
            })

            onChange({
                ...data,
                cultureStatTypeSelected: cutureStatType,
                statements: newComments,
                designType: selectedDesignType,
                topXDesignType: selectedTopXDesignType,
                percentDesignType: selectedPercentPositiveDesignType,
                rectangleDesignType: selectedRectangleDesignType,
            })
        }
    }

    if (isLoading) {
        return <Spinner />
    }

    if (!hasEmployerBrandingSubscription) {
        return <h2 className="text-center p-12">{'Unlock Employer Branding to get access to your data!'}</h2>
    }

    return (
        <CommentsList
            data={data}
            setCommentSelected={setCommentSelected}
            isLoading={isLoading}
            dataId={dataId}
            selectedCultureType={selectedCultureType}
            setSelectedCultureType={setSelectedCultureType}
            cultureStatsTableData={cultureStatsTableData}
            isExpiredWidget={isExpiredWidget}
            isTopXPercentStatement={isTopXPercentStatement}
            isContentFilter={isContentFilter}
            updatedItems={updatedItems}
            isFilterApplied={isFilterApplied}
            isTopXFiltered={isTopXFiltered}
        />
    )
}

export default CultureStatsCommentSelector
