import { useMemo } from 'react'
import { HelpCard } from '@energage/components'
import styled from 'styled-components'
import type { GoodToKnowContainerProps, HelpCardItem, Item, TailPlacement } from '../models'
import { EndCardContent, ScrollSnapSpeechBubble } from '../Shared'
import SwimLaneContainer from '../SwimLane'
import { useGetEducationalContent } from './useGetEducationalContent'

const SizedHelpCard = styled(HelpCard)`
    scroll-snap-align: start;
    display: flex;

    .card-container {
        height: 246px;
        width: 288px;
    }
`

export const QueryableGoodToKnowContainer = ({ queryKey, showEndCard }: { queryKey: string; showEndCard: boolean }) => {
    const { data, isFetching } = useGetEducationalContent(queryKey)

    const items: Item[] = useMemo(() => {
        if (!data || isFetching) {
            return []
        }

        const items = data.map((item: Item) => item)
        if (showEndCard) {
            items.push({ subType: 'endCard' })
        }

        return items
    }, [data, isFetching, showEndCard])

    return (
        <SwimLaneContainer
            sectionTitle="Good to know"
            items={items.map((item, index) => (
                <ContentDisplay key={`${index}-good-to-know`} item={item} />
            ))}
            isFetching={isFetching}
            dataEventId={'dashboard-good-to-know'}
        />
    )
}

export const GoodToKnowContainer = ({ items, showEndCard }: GoodToKnowContainerProps) => {
    const data: Item[] = useMemo(() => {
        if (showEndCard) {
            items.push({ subType: 'endCard' })
        }

        return items
    }, [items, showEndCard])

    return (
        <SwimLaneContainer
            sectionTitle={'Good to know'}
            items={data.map((item) => (
                <ContentDisplay item={item} />
            ))}
            isFetching={false}
            dataEventId={'dashboard-good-to-know'}
        />
    )
}

const ContentDisplay = ({ item }: { item: Item }) => {
    switch (item.subType) {
        case 'article':
            const { onClickUrl, subType, subText, text: textHelpCard } = item as HelpCardItem
            return (
                <SizedHelpCard
                    href={onClickUrl ?? ''}
                    preText={subText ? `${subType} - ${subText}` : subType}
                    children={<p>{textHelpCard}</p>}
                    lineCutoff={5}
                />
            )
        case 'speech':
            const { tailPlacement, text: textSpeechBubble, variant } = item as HelpCardItem
            return (
                <ScrollSnapSpeechBubble
                    tailPlacement={(tailPlacement as TailPlacement) ?? undefined}
                    tabIndex={0}
                    variant={variant ?? 'speech'}>
                    {textSpeechBubble}
                </ScrollSnapSpeechBubble>
            )
        case 'endCard':
            return (
                <EndCardContent
                    text={'Need help with something else?'}
                    linkText={'Visit our full Help Center >>'}
                    link={'https://energage.my.site.com/FAQ/s/'}
                    openNewTab={true}
                />
            )
        default:
            return <></>
    }
}
