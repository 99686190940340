import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import difference from 'lodash/difference'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import flattenDeep from 'lodash/flattenDeep'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import union from 'lodash/union'
import { EMPTY_GUID } from 'constants/emptyGuid'
import { EnergageTag } from 'constants/tags'
import buildDepartmentHierarchy from 'containers/Main/Manage/Employees/DepartmentView/buildDepartmentHierarchy'
import { useEmployeeData } from 'containers/Main/Manage/Employees/EmployeeDataProvider'
import { createAction } from 'util/actionCreator'
import { useRecipientsData } from '../../RecipientsDataProvider'

const getChildDepartmentIds = (childDepartments) =>
    flattenDeep(map(childDepartments, ({ id, children }) => [id, ...getChildDepartmentIds(children)]))

function updateAllDepartments(dept, checked) {
    const children = map(dept.children, (c) => updateAllDepartments(c, checked))

    return {
        ...dept,
        checked: checked,
        children,
    }
}

function findDepartment(departments, id) {
    if (!departments || departments.length === 0) {
        return
    }

    const dept = find(departments, (d) => d.id === id)
    if (dept != null) {
        return dept
    }

    let foundDept = null
    forEach(departments, (dept) => {
        foundDept = findDepartment(dept.children, id)

        if (foundDept) {
            return false
        }
    })

    return foundDept
}

function updateParentDepartments(allDepartments, dept) {
    if (dept.parentDepartmentId === null) {
        return []
    }

    const dep = findDepartment(allDepartments, dept.parentDepartmentId)
    if (dep && dep.children.length > 0) {
        dep.checked = every(dep.children, (child) => child.checked === true)
        const departmentIds = updateParentDepartments(allDepartments, dep)
        return [...departmentIds, dep.id]
    }
}

function updateDepartment(dept, checked, ids) {
    const children = map(dept.children, (c) => updateDepartment(c, checked, ids))

    return {
        ...dept,
        checked: includes(ids, dept.id) ? checked : dept.checked,
        children,
    }
}
const defaultState = {
    updatedRootDepartments: [],
    selectedDepartmentIds: null,
}

const Action = {
    INITIALIZE: 'INITIALIZE',
    SELECT_DEPARTMENT: 'SELECT_DEPARTMENT',
    DESELECT_DEPARTMENT: 'DESELECT_DEPARTMENT',
    SELECT_ALL: 'SELECT_ALL',
    DESELECT_ALL: 'DESELECT_ALL',
}

const initialize = createAction(Action.INITIALIZE)
const selectDepartment = createAction(Action.SELECT_DEPARTMENT)
const deselectDepartment = createAction(Action.DESELECT_DEPARTMENT)
const selectAll = createAction(Action.SELECT_ALL)
const deselectAll = createAction(Action.DESELECT_ALL)

function reducer(state, action) {
    switch (action.type) {
        case Action.INITIALIZE: {
            const { rootDepartments, initSelectedDepartmentIds } = action.payload

            //this is to preserve department selection when doing tag filtering
            const selectedDepartmentIds = initSelectedDepartmentIds
                ? initSelectedDepartmentIds
                : state.selectedDepartmentIds

            function inRecipients(dept) {
                const children = map(dept.children, inRecipients)

                return {
                    ...dept,
                    checked: !!find(selectedDepartmentIds, (dId) => dId === dept.id),
                    children,
                }
            }

            const updatedRootDepartments = map(rootDepartments, (dept) => inRecipients(dept))

            return {
                updatedRootDepartments: updatedRootDepartments,
                selectedDepartmentIds: selectedDepartmentIds,
            }
        }
        case Action.SELECT_DEPARTMENT: {
            const childIds = [...getChildDepartmentIds([action.payload]), action.payload.id]
            const updatedRootDepartments = map(state.updatedRootDepartments, (dept) => {
                return updateDepartment(dept, true, childIds)
            })

            const parentIds = updateParentDepartments(updatedRootDepartments, action.payload)

            return {
                updatedRootDepartments,
                selectedDepartmentIds: union(state.selectedDepartmentIds, union(childIds, parentIds)),
            }
        }
        case Action.DESELECT_DEPARTMENT: {
            const childIds = [...getChildDepartmentIds([action.payload]), action.payload.id]

            const updatedRootDepartments = map(state.updatedRootDepartments, (dept) => {
                return updateDepartment(dept, false, childIds)
            })

            const parentIds = updateParentDepartments(updatedRootDepartments, action.payload)

            return {
                updatedRootDepartments,
                selectedDepartmentIds: difference(state.selectedDepartmentIds, union(childIds, parentIds)),
            }
        }
        case Action.SELECT_ALL: {
            const childIds = getChildDepartmentIds(state.updatedRootDepartments)
            const rootIds = map(state.updatedRootDepartments, (d) => d.id)
            return {
                selectedDepartmentIds: [...childIds, ...rootIds],
                updatedRootDepartments: map(state.updatedRootDepartments, (dept) => updateAllDepartments(dept, true)),
            }
        }
        case Action.DESELECT_ALL: {
            return {
                selectedDepartmentIds: [],
                updatedRootDepartments: map(state.updatedRootDepartments, (dept) => updateAllDepartments(dept, false)),
            }
        }
        default:
            return state
    }
}

function useRecipients() {
    const [{ updatedRootDepartments, selectedDepartmentIds }, dispatch] = useReducer(reducer, defaultState)

    const { isLoading, error, departmentTag, members, organization, tags } = useEmployeeData()
    const [selectedFilterOptionIds, setSelectedFilterOptionIds] = useState([])
    const {
        recipients,
        surveyEventSetup,
        isLoading: recipientsLoading,
        error: recipientsError,
        doSurveyEventSetupFetch,
    } = useRecipientsData()

    const energageFilterOptionTags = useMemo(
        () =>
            filter(
                tags,
                (t) => t.energageTag !== null && t.tagTypeId === 1 && !isEqual(t.energageTag, EnergageTag.Department)
            ),
        [tags]
    )

    useEffect(() => {
        if (isLoading || recipientsLoading) {
            return
        }

        const allTagOptionAssignments = map(recipients.tagOptionAssignments, 'tagOptionId')
        const allPossibleNonDepartmentOptions = map(flatten(map(energageFilterOptionTags, 'options')), 'id')
        const selectedNonDepartmentOptions = intersection(allTagOptionAssignments, allPossibleNonDepartmentOptions)
        setSelectedFilterOptionIds(selectedNonDepartmentOptions)
    }, [isLoading, recipientsLoading, recipients, energageFilterOptionTags])

    // filter members here based on non department assignment selection
    const filteredMembers = useMemo(
        () =>
            selectedFilterOptionIds?.length > 0
                ? filter(
                      members,
                      (m) =>
                          intersection(
                              map(m?.tagValues, (v) => v.tagOptionId),
                              selectedFilterOptionIds
                          )?.length > 0
                  )
                : members,
        [members, selectedFilterOptionIds]
    )

    const noDepartmentsUploaded = !members?.length

    // TODO: fix the components below this layer
    // TODO: Do not share the checked with the department view, it is too much for 1 component, for now continue to use whats already here
    // Make sure to build root departments only once
    // RootDepartments adds a fake DefaultDepartment and by rebuilding it you wipe memory of it
    const rootDepartments = useMemo(
        () => buildDepartmentHierarchy(departmentTag, filteredMembers, organization),
        [departmentTag, filteredMembers, organization]
    )

    const selectAllRecipients = useCallback(() => dispatch(selectAll()), [dispatch])
    const deselectAllRecipients = useCallback(() => dispatch(deselectAll()), [dispatch])

    useEffect(() => {
        if (isLoading || recipientsLoading || rootDepartments === []) {
            return
        }

        if (selectedDepartmentIds == null) {
            const departmentTag = find(tags, (t) => isEqual(t.energageTag, EnergageTag.Department))
            const departmentTagOptionIds = map(departmentTag?.options, 'id')
            const initSelectedDepartmentIds = intersection(
                map(recipients.tagOptionAssignments, 'tagOptionId'),
                departmentTagOptionIds
            )

            // Root "Department" does not have any children and is checked if
            // Any members are assigned
            if (recipients?.members?.length > 0) {
                initSelectedDepartmentIds.push(EMPTY_GUID)
            }

            dispatch(initialize({ rootDepartments, initSelectedDepartmentIds }))
            // Default to all checked
            if (initSelectedDepartmentIds?.length === 0) {
                selectAllRecipients()
            }
        } else {
            dispatch(initialize({ rootDepartments }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        recipients,
        rootDepartments,
        tags,
        isLoading,
        recipientsLoading,
        selectAllRecipients,
        //selectedDepartmentIds //this is intentionally excluded from dependency list
    ])

    const modifyRecipients = useCallback(
        (data, checked = null) => {
            if (checked) {
                dispatch(selectDepartment(data))
            } else {
                dispatch(deselectDepartment(data))
            }
        },
        [dispatch]
    )

    return {
        isLoading: isLoading || recipientsLoading,
        error: error || recipientsError,
        surveyEventSetup,
        energageFilterOptionTags,
        selectedFilterOptionIds,
        setSelectedFilterOptionIds,
        modifyRecipients,
        selectAllRecipients,
        deselectAllRecipients,
        doSurveyEventSetupFetch,
        rootDepartments: updatedRootDepartments,
        noDepartmentsUploaded,
    }
}

export default useRecipients
