import cx from 'clsx'

// TODO: replace usages of this with Page.Heading
const PageTitle = ({ children, border = false, title }) => (
    <div className={cx('flex justify-between items-center h-16 w-full', border && 'border-b-2')}>
        {title && typeof title === 'string' ? <h4>{title}</h4> : title}
        {children}
    </div>
)

export default PageTitle
