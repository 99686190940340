import { useState } from 'react'
import { alert } from '@energage/components'
import { useUpdateAtom } from 'jotai/utils'
import findLast from 'lodash/findLast'
import map from 'lodash/map'
import { useQueryClient } from 'react-query'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePost } from 'hooks'
import { QuestionType } from './CustomQuestions/QuestionType'
import { prepareMultiChoiceOptionsForCreation } from './questionApiUtility'
import { forceAdditionalItemsAccordion } from './statementSetupUtility/store'
import useCustomQuestionCreation from './useCustomQuestionCreation'

function prepareQuestionsForSubmission(customQuestions) {
    return map(customQuestions, (question) => {
        if (question.questionTypeId === QuestionType.MultiChoice) {
            return {
                ...question,
                answerOptions: prepareMultiChoiceOptionsForCreation(question.answerOptions),
            }
        }

        return question
    })
}

function useStatementSetup({ surveyEventId, draftCustomQuestions, onCustomQuestionSave, refetchPreview }) {
    const { surveyCompanyId } = useIdentity()
    const [isReapprovalRequired, setIsReapprovalRequired] = useState(false)
    const setForcedAdditionalSectionOpen = useUpdateAtom(forceAdditionalItemsAccordion)
    const queryClient = useQueryClient()

    const { resetCustomQuestions, setCustomQuestions, customQuestions, ...customQuestionCreation } =
        useCustomQuestionCreation(draftCustomQuestions)

    const { doPost: _submitCustomQuestions, isPosting: isSavingCustomQuestions } = usePost({
        url: `${config.api.workplaceSurvey}/surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/CustomQuestionSubmit`,
        onComplete: (data) => {
            setIsReapprovalRequired(data.isReapprovalRequired)
            onCustomQuestionSave(data.customQuestions)
            resetCustomQuestions()
            refetchPreview()
            queryClient.invalidateQueries(['survey-definition', surveyCompanyId, surveyEventId])
        },
        onError: () => {
            alert.danger('There was an error submitting your questions')
            setCustomQuestions((questions) => map(questions, (question) => ({ ...question, isSubmitting: false })))
        },
    })

    function submitCustomQuestions() {
        setCustomQuestions((questions) => map(questions, (question) => ({ ...question, isSubmitting: true })))
        setForcedAdditionalSectionOpen(true)
        const questionsForSubmission = prepareQuestionsForSubmission(customQuestions)
        return _submitCustomQuestions(questionsForSubmission)
    }

    const { doPost: saveCustomQuestions, isPosting: isAutoSavingCustomQuestions } = usePost({
        url: `${config.api.workplaceSurvey}/surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/CustomQuestion`,
        onComplete: () => {
            queryClient.invalidateQueries(['survey-definition', surveyCompanyId, surveyEventId])
        },
        onError: (errors) => {
            let nameErrorsCount = 0
            setCustomQuestions((questions) =>
                map(questions, (question) => {
                    const updatedQuestion = question
                    const nameError = findLast(
                        errors,
                        (e) => e?.code === 'InvalidName' && e?.attemptedValue === question.name
                    )
                    if (nameError) {
                        updatedQuestion._errors.duplicateName = true
                        nameErrorsCount++
                    }

                    return question
                })
            )
            //show message if other than duplicate name error
            if (nameErrorsCount === 0) {
                if (errors?.errors?.AnswerOptions.length > 0) {
                    alert.danger('There was an error submitting your answer options')
                } else {
                    alert.danger('There was an error submitting your question')
                }
            }
        },
    })

    const { doPost: deleteCustomQuestion, isPosting: isDeletingQuestion } = usePost(
        `${config.api.workplaceSurvey}/surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/CustomQuestionDelete`
    )

    return {
        submitCustomQuestions,
        isSavingCustomQuestions,
        saveCustomQuestions,
        isAutoSavingCustomQuestions,
        deleteCustomQuestion,
        isDeletingQuestion,
        isReapprovalRequired,
        customQuestions,
        ...customQuestionCreation,
    }
}

export default useStatementSetup
