import { useWorkplaceSurveyQuery } from 'api'
import type { ResponseError } from 'api'
import { useIdentity } from 'components/Identity'
import showErrorAlert from 'util/showErrorAlert'

export const useNotifyAboutUpcomingTasks = () => {
    const { organizationId } = useIdentity()
    const {
        mutateAsync: notify,
        status,
        reset,
    } = useWorkplaceSurveyQuery.mutate<Response, ResponseError>(
        async (_, api) => await api.post(`WorkflowPhase/NotifyAboutUpcomingTasks/${organizationId}`),
        {
            onError: (error) => {
                reset()
                showErrorAlert('There was an error', error.parsed)
            },
        }
    )

    return { isNotifying: status === 'loading', notify }
}
