import * as d3 from 'd3'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import take from 'lodash/take'
import measureTextWidth from './measureTextWidth'

export const WORD_DELIMITER_REGEXP = '([\\\\_\\-/]+)'

export function wrapText(maxWidth, wrapEvenly, maxLines) {
    function wrap(selection) {
        selection.each(function () {
            const textElement = d3.select(this)
            const lines = getWrappedLines(textElement.text(), maxWidth, wrapEvenly, maxLines)
            const lineHeight = 1.1
            const x = textElement.attr('x') || 0
            const y = textElement.attr('y') || 0
            const dy = parseFloat(textElement.attr('dy')) || 0
            const alignmentBaseline = textElement.attr('alignment-baseline')

            this.lineCount = filter(lines, (line) => line && line.length).length

            textElement.text(null)
            const regexp = new RegExp(`(\\w)${WORD_DELIMITER_REGEXP}\\s`, 'g')
            forEach(lines, (line, lineNumber) => {
                textElement
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', `${lineNumber * lineHeight + dy}em`)
                    .attr('alignment-baseline', alignmentBaseline)
                    .text(line.join(' ').replace(regexp, '$1$2'))
            })
        })
    }

    return wrap
}

export function getWrappedLines(text, maxWidth, wrapEvenly, maxLines) {
    return truncateLines(getLines())

    function getLines() {
        if (measureTextWidth(text) < maxWidth) {
            return [[text]]
        }

        if (wrapEvenly) {
            const lines = splitLinesEvenly(text)
            if (d3.max(lines, (line) => measureTextWidth(line.join(' '))) < maxWidth) {
                return lines
            }
        }

        return splitLinesByMaxWidth()
    }

    function truncateLines(lines) {
        if (maxLines && lines.length > maxLines) {
            const newLines = take(lines, maxLines)
            const lastLine = newLines[maxLines - 1]
            lastLine[lastLine.length - 1] += '...'

            if (measureTextWidth(lastLine.join(' ')) > maxWidth) {
                lastLine[lastLine.length - 1] = '...'
            }

            return newLines
        }

        return lines
    }

    function splitLinesByMaxWidth() {
        const words = splitWords(text)
        const lines = []
        let currentLine = []

        while (words.length > 0) {
            const word = words.shift()
            currentLine.push(word)
            if (measureTextWidth(currentLine.join(' ')) > maxWidth) {
                currentLine.pop()
                if (currentLine.length !== 0) {
                    lines.push(currentLine)
                }
                currentLine = [word]
            }
        }
        if (currentLine.length > 0) {
            lines.push(currentLine)
        }

        return lines
    }
}

export function splitLinesEvenly(text) {
    const words = splitWords(text)
    const lines = [[words.shift()], []]

    while (words.length > 0) {
        const firstLineLength = measureTextWidth(lines[0].join(' '))
        const secondLineLength = measureTextWidth(lines[1].join(' '))
        if (firstLineLength < secondLineLength) {
            lines[0].push(words.shift())
        } else {
            lines[1].unshift(words.pop())
        }
    }

    return lines
}

function splitWords(text) {
    const regexp = new RegExp(`([a-zA-Z0-9])${WORD_DELIMITER_REGEXP}(\\w)`, 'g')
    return text.replace(regexp, '$1$2 $3').split(/\s+/)
}
