import {
    CultureStatsGraphicType,
    CultureStatsId,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { Filters } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { DEFAULT_BG_COLOR } from 'containers/Main/EmployerBranding/WidgetsConfigurationSection'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getFilterQueryParams, getSelectedFiltersRecord } from '../Widgets/utils'
import CultureStats from './CultureStats'
import { tileTypes } from './Tile'
import useFetchPublicPage from './useFetchPublicPage'

const getSelectedTypesFromDesignType = (designType) => {
    let selectedPercentPositiveDesignType = CultureStatsGraphicType.None
    let selectedTopXDesignType = CultureStatsGraphicType.None
    let selectedRectangleDesignType = CultureStatsGraphicType.None

    switch (designType) {
        case CultureStatsGraphicType.HalfDonut:
        case CultureStatsGraphicType.Donut:
            selectedPercentPositiveDesignType = designType
            break
        case CultureStatsGraphicType.LaurelHalfCircle:
        case CultureStatsGraphicType.Circle:
            selectedTopXDesignType = designType
            break
        case CultureStatsGraphicType.RectangleBorderDesign:
        case CultureStatsGraphicType.RectangleDesignWithoutBorder:
        case CultureStatsGraphicType.ReelDesignWithBorder:
        case CultureStatsGraphicType.ReelDesignWithoutBorder:
            selectedRectangleDesignType = designType
            break
        default:
    }

    return {
        selectedPercentPositiveDesignType,
        selectedTopXDesignType,
        selectedRectangleDesignType,
    }
}

export function CultureStatsFetch({ location }) {
    const parsedQuery = parseQueryParams(location)
    const query = getFilterQueryParams(parsedQuery)
    const { companyId, surveyEventId, isDownloadable, cultureStatsDesignType, cultureStatsType } = parsedQuery

    const { data, isLoading, error } = useFetchPublicPage(
        'cultureStats-filter',
        `SurveyCompanies/${companyId}/culturefacts/culturestats/${surveyEventId}/${isDownloadable}/false?${query}`
    )

    const {
        data: surveyEvents,
        isLoading: isLoadingSurveyEvents,
        error: surveyEventsError,
    } = useFetchPublicPage(
        'ContentSubFilters',
        `SurveyCompanies/${companyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}&${query}`
    )

    if (isLoading || isLoadingSurveyEvents || error || surveyEventsError) {
        return null
    }

    const selectedTypes = getSelectedTypesFromDesignType(cultureStatsDesignType)

    return (
        <Filters.Provider
            value={{
                filterData: surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery),
            }}>
            <CultureStats
                {...data}
                {...selectedTypes}
                cultureStatTypeSelected={cultureStatsType}
                type={tileTypes.vertical}
                dataId={CultureStatsId}
                filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                hasEmployerBrandingSubscription
                backgroundColor={data?.backgroundColor || DEFAULT_BG_COLOR}
                bodyBorderRadius="0"
                headerBorderRadius="0"
                isDownloadable
                isPdf
            />
        </Filters.Provider>
    )
}
