import { CalendarMonth } from '@energage/icons'
import { colors } from '@energage/theme'
import head from 'lodash/head'
import last from 'lodash/last'
import { routes } from 'constants/routes'
import { pluralizeNounOnly } from 'util/pluralize'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const ScheduleSet = ({ surveyEventId, invitations }) => {
    const reminderCount = invitations.slice(1, invitations.length - 1).length
    const launchDateTime = new Date(head(invitations).scheduledTime)
    const closeDateTime = new Date(last(invitations).scheduledTime)

    const launchDate = launchDateTime.toLocaleDateString()
    const launchTime = launchDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const closeDate = closeDateTime.toLocaleDateString()
    const closeTime = closeDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const tileUrl = routes.survey.setup.schedule({ surveyEventId }).concat('?section=schedule')

    const reminderText = pluralizeNounOnly(reminderCount, 'email')
    return (
        <SummaryCard padding="tight">
            <CardIcon className="justify-center">
                <CalendarMonth fill={colors.grey500} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="mb-2">{'Survey schedule'}</CardTitle>
                    <p>
                        {`Your survey is scheduled to launch on ${launchDate} at ${launchTime}. You have ${reminderCount} reminder ${reminderText} scheduled. Your survey is scheduled to close on ${closeDate} at ${closeTime}.`}
                    </p>
                </CardContent>
                <SummaryButton tileUrl={tileUrl} buttonText={'Edit Schedule'} className="w-fit" />
            </CardBody>
        </SummaryCard>
    )
}
