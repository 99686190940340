import { matchHeaderAliases, normalizeHeader } from 'components/Upload/helpers'
import type {
    CustomDemographics,
    DemographicOption,
} from 'containers/Main/Survey/SurveySetup/RecipientsV2/RecipientModal'

export interface DefaultColumn {
    isRequired: boolean
    alwaysUpload: boolean
    header: string
    matchHeader: (input: string) => boolean
    aliases: string[]
}

export interface ColumnWithDemographics extends DefaultColumn {
    demographicTypeId: number
    customDemographicOptions: DemographicOption[]
    hasDemographicOptions: true
}

export interface ColumnWithoutDemographics extends DefaultColumn {
    hasDemographicOptions?: false
}

export type Column = ColumnWithoutDemographics | ColumnWithDemographics

export const defaultColumns: Record<string, Column> = {
    emailAddress: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Email Address',
        matchHeader: matchHeaderAliases,
        aliases: ['emailaddress', 'email', 'workemailaddress', 'workemail'],
    },
    phoneNumber: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Phone Number',
        matchHeader: matchHeaderAliases,
        aliases: ['phonenumber', 'phone', 'telephone', 'telephonenumber'],
    },
    phoneNumberConsent: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Text Message Consent?',
        matchHeader: matchHeaderAliases,
        aliases: ['textmessageconsent', 'consent', 'textconsent'],
    },
    firstName: {
        isRequired: false,
        alwaysUpload: true,
        header: 'First Name',
        matchHeader: matchHeaderAliases,
        aliases: ['employeefirstname', 'firstname', 'first'],
    },
    lastName: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Last Name',
        matchHeader: matchHeaderAliases,
        aliases: ['employeelastname', 'lastname', 'last', 'name', 'employeename'],
    },
    department: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Department',
        matchHeader: matchHeaderAliases,
        aliases: ['employeedepartment', 'department', 'departmentname', 'division', 'unit', 'area', 'group'],
    },
    parentDepartment: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Parent Department',
        matchHeader: matchHeaderAliases,
        aliases: [
            'parentdepartment',
            'parentdepartmentname',
            'parentdivision',
            'parentarea',
            'parentunit',
            'parentgroup',
            'parent',
        ],
    },
    approximateSalary: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Approximate Salary',
        matchHeader: matchHeaderAliases,
        aliases: ['approximatesalary', 'salary', 'annualsalary', 'salaryband'],
    },
    isFullTime: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Full Time or Part Time?',
        matchHeader: matchHeaderAliases,
        aliases: ['fulltime', 'parttime', 'fulltimeorparttime', 'hours', 'time', 'hoursworked'],
    },
    jobGrade: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Manager, Senior Manager or Team Member?',
        matchHeader: matchHeaderAliases,
        aliases: ['jobgrade', 'managerseniormanagerorteammember', 'seniormanager', 'teammember', 'jobrole'],
    },
    remoteStatus: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Remote',
        matchHeader: matchHeaderAliases,
        aliases: ['remote', 'isremote', 'remotestatus'],
    },
    gender: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Gender',
        matchHeader: matchHeaderAliases,
        aliases: ['gender'],
    },
    raceOrEthnicity: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Race/ethnicity',
        matchHeader: matchHeaderAliases,
        aliases: ['raceethnicity', 'race', 'ethnicity', 'raceorethnicity'],
    },
    sexualOrientation: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Sexual Orientation',
        matchHeader: matchHeaderAliases,
        aliases: ['sexualorientation'],
    },
}

export const mapDemographicsToColumns = (
    baseRecipientUploadColumns: Record<string, Column>,
    demographics: CustomDemographics[]
): Record<string, Column> => {
    const demoGraphicsColumns: Record<string, Column> = {}

    const allAliases = new Set(getAllAliases(baseRecipientUploadColumns))

    demographics.forEach(({ name, id, customDemographicOptions }) => {
        let customName = name

        if (allAliases.has(normalizeHeader(name))) {
            customName = `${name} (Custom)`
        }

        demoGraphicsColumns[customName] = {
            demographicTypeId: id,
            isRequired: false,
            alwaysUpload: false,
            header: customName,
            matchHeader: matchHeaderAliases,
            aliases: [normalizeHeader(customName)],
            customDemographicOptions,
            hasDemographicOptions: true,
        }
    })
    return { ...baseRecipientUploadColumns, ...demoGraphicsColumns }
}

const getAllAliases = (defaultColumns: Record<string, Column>): string[] =>
    Object.values(defaultColumns).reduce<string[]>((acc, next) => acc.concat(next.aliases), [])
