import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import initial from 'lodash/initial'
import join from 'lodash/join'
import last from 'lodash/last'
import map from 'lodash/map'
import reject from 'lodash/reject'
import replace from 'lodash/replace'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import {
    getPaymentTerm,
    isAmendmentFailed,
    isConfirmed,
    isOneTimeChargedProduct,
    nonBundleBaseProductList,
    renewalOptionTypes,
    subscriptionStatus,
    unitTypes,
} from 'containers/Main/OrderManagement/common/utils'
import { dateToZeroHoursInMilliSecond } from 'containers/Main/OrderManagement/Renewals/MyProducts/Subscriptions/Products'
import { currency } from 'util/formatters'
import pluralize from 'util/pluralize'
import {
    buildProduct,
    getLineItem,
    getLineItemAmendment,
    validateDeprecatedProducts,
} from '../AmendmentConfiguration/buildAmendmentConfiguration'

export const calculateRenewalTermEndDate = (renewalTermStartDate, renewalTerm, renewalTermPeriodTypeName) => {
    switch (renewalTermPeriodTypeName) {
        case 'Day':
            return addDays(renewalTermStartDate, renewalTerm)
        case 'Week':
            return addWeeks(renewalTermStartDate, renewalTerm)
        case 'Month':
            return addMonths(renewalTermStartDate, renewalTerm)
        case 'Year':
            return addYears(renewalTermStartDate, renewalTerm)
        default:
            return null
    }
}

const getTotalProductPrice = (products) => currency(sumBy(products, (x) => x.overridePrice))

export const getFirstProductWithEmployeeUom = (products) =>
    find(products, (x) => x.unitType === unitTypes.Employees.value)

const getBundleOfBaseProducts = (products, baseProducts) => {
    const baseProductList = []
    const productName = map(products, (p) => p.productName)

    baseProductList.push({
        overridePrice: getTotalProductPrice(products),
        productName: `${join(initial(productName), ', ')}, and ${last(productName)} bundle`,
        category: products[0]?.category,
        renewalsTerms: map(products, (p) => p.renewalsTerms),
        isBundle: true,
    })

    const nonBundleBaseProducts = filter(baseProducts, (x) => includes(nonBundleBaseProductList, x.productName))

    if (size(nonBundleBaseProducts) > 0) {
        baseProductList.push(...nonBundleBaseProducts)
    }

    return baseProductList
}

const getCurrentTerm = (orderData, subscription) => {
    const amendmentOrder = get(orderData, 'amendmentOrder')
    const { lineItems } = amendmentOrder
    const currentTermProducts = map(
        filter(lineItems, (x) => x.isPartOfCurrentSubscription),
        (x) => ({
            ...x,
            ...(find(subscription.products, (sub) => sub.productId === x.productId) ?? {}),
            overridePrice: x.currentPrice || 0,
        })
    )

    const subscriptionProducts = reject(
        currentTermProducts,
        ({ isBaseProduct, effectiveEndDate }) =>
            !isBaseProduct &&
            dateToZeroHoursInMilliSecond(effectiveEndDate) <= dateToZeroHoursInMilliSecond(subscription.termStartDate)
    )
    const firstProductWithEmployeeUom = getFirstProductWithEmployeeUom(subscriptionProducts)

    return {
        title: 'Current Plan',
        totalAnnualCost: getTotalProductPrice(subscriptionProducts),
        termDuration: {
            label: 'Current Term',
            start: new Date(subscription.termStartDate),
            end: new Date(subscription.termEndDate),
        },
        platformProducts: {
            heading: 'Energage Platform',
            employees: firstProductWithEmployeeUom?.quantity,
            duration: subscription.currentTerm && pluralize(subscription.currentTerm, subscription.termPeriodType.name),
            costPeriod: 'Annual Cost',
            products: subscriptionProducts,
        },
    }
}

const getNewTerm = (orderData, subscription, isNewRenewalPage) => {
    const amendmentOrder = get(orderData, 'amendmentOrder')

    const includedProducts = compact(
        map(orderData.products, (productItem) => {
            const productId = replace(productItem.id, /-/g, '')

            const lineItem = getLineItem(amendmentOrder.lineItems, productId)

            if (!lineItem) {
                return
            }

            const product = {
                ...productItem,
                productId,
                ...lineItem,
                ...getLineItemAmendment(amendmentOrder.lineItemAmendments, productId),
            }

            const validatedProduct = validateDeprecatedProducts(product)

            if (!validatedProduct) {
                return
            }

            const mappedProduct = buildProduct(validatedProduct)
            const isPartOfCurrentSubscription =
                mappedProduct.overrideRenewalType === renewalOptionTypes.Included.value ||
                mappedProduct.overrideRenewalType === renewalOptionTypes.AddToSubscription.value ||
                mappedProduct.lastRenewalType === renewalOptionTypes.Included.value
            return isPartOfCurrentSubscription && mappedProduct
        })
    )

    const oneTimeProducts = filter(includedProducts, (x) => isOneTimeChargedProduct(x.productRatePlanCharges))
    const baseProducts = filter(includedProducts, (x) => !isOneTimeChargedProduct(x.productRatePlanCharges))

    const products = sortBy(
        filter(baseProducts, (x) => !includes(nonBundleBaseProductList, x.productName)),
        (x) => x.productName
    )

    const firstProductWithEmployeeUom = getFirstProductWithEmployeeUom(baseProducts)
    const currentTermEnd = new Date(subscription.termEndDate)

    const displayBundles = isNewRenewalPage && size(products) > 1

    return {
        title: 'Revised Plan',
        totalAnnualCost: getTotalProductPrice(includedProducts),
        termDuration: {
            label: 'Renewal Term',
            start: currentTermEnd,
            end: calculateRenewalTermEndDate(
                currentTermEnd,
                subscription.renewalTerm,
                subscription.renewalTermPeriodType?.name
            ),
        },
        platformProducts: {
            heading: 'Energage Platform',
            employees: firstProductWithEmployeeUom?.overrideQuantity || firstProductWithEmployeeUom?.quantity,
            duration:
                subscription.renewalTerm &&
                pluralize(subscription.renewalTerm, subscription.renewalTermPeriodType.name),
            costPeriod: 'Annual Cost',
            products: displayBundles ? getBundleOfBaseProducts(products, baseProducts) : baseProducts,
        },
        oneTimeAddOns: {
            heading: 'One Time Add Ons',
            addOns: oneTimeProducts,
        },
    }
}

const buildSummary = (orderData, subscription, isNewRenewalPage) => {
    const { amendmentOrder, confirmedByEmailAddress, confirmedByName } = orderData

    return {
        orderExpirationDate: new Date(amendmentOrder.expirationDate),
        currentTerm: getCurrentTerm(orderData, subscription),
        newTerm: getNewTerm(orderData, subscription, isNewRenewalPage),
        netCreditTerm: getPaymentTerm(amendmentOrder.netCreditTerm)?.label,
        amendmentOrderId: amendmentOrder.id,
        isConfirmed: isConfirmed(amendmentOrder.status),
        disableAutoRenew: amendmentOrder.disableAutoRenew,
        isAmendmentFailed: isAmendmentFailed(amendmentOrder.status),
        isSubscriptionExpired: subscription.status === subscriptionStatus.Expired,
        orderType: amendmentOrder.type,
        orderConfirmationDate: amendmentOrder.confirmedBy && new Date(amendmentOrder.confirmedDate),
        confirmedBy: confirmedByEmailAddress,
        confirmedByName,
        purchaseOrderNumber: amendmentOrder.purchaseOrderNumber,
        organizationName: subscription?.account?.name,
    }
}

export default buildSummary
