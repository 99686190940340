import { useBrandingQuery } from 'api'

export const useFetchPressReleaseData = (
    awardCategoryId: number,
    publisherId: number,
    publishYear: number,
    surveyCompanyId: number,
    isNationalStandard: boolean
) => {
    const { data, isLoading, isError } = useBrandingQuery(
        ['PressReleaseData', surveyCompanyId, publisherId],
        `SurveyCompanies/${surveyCompanyId}/MediaKit/${awardCategoryId}/${publisherId}/${publishYear}/${isNationalStandard}/PressReleaseData`
    )

    return { data, isLoading, isError }
}
