import { forwardRef, useEffect, useMemo, useState } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import {
    alert,
    Alert,
    Button,
    Checkbox,
    Collapse,
    HierarchyMultiSelect,
    Input,
    LegacyButton,
    Loading,
    Select,
    Skeleton,
    Toggle,
    useHierarchyMultiSelect,
} from '@energage/components'
import { useMediaQuery, useToggle } from '@energage/hooks'
import { DialogTrigger, Popover } from '@energage/overlays'
import { Text } from '@energage/typography'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'clsx'
import { get, useForm, useWatch } from 'react-hook-form'
import type { UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { VisuallyHidden } from 'components'
import { useIdentity } from 'components/Identity'
import { Tooltip } from 'components/Tooltip'
import { TopNav } from 'components/TopNav'
import config from 'config'
import { routes } from 'constants/routes'
import { viewports } from 'style/breakpoints'
import withClassName from 'style/util/withClassName'
import pluralize from 'util/pluralize'
import type {
    CompanyUserDepartmentDto,
    CompanyUserSurveyEventDto,
    ExistingUser,
    UserType,
    ValidationError,
} from './models'
import {
    DUPLICATED_USER_NAME_EXCEPTION,
    EDIT_USER,
    EMAIL_EXISTS,
    filterDepartmentSelections,
    flattenDepartmentsFromSurveyEvents,
    getInsightAccessDescription,
    getSelectedNodes,
    getSurveyCount,
    getSurveysDepartmentsTree,
    isPublisherControlRoles,
    mapDepartmentIdsToSurveyEvents,
    mapDepartmentsToSurveyEvents,
} from './UserManagement.util'
import { userValidationSchema } from './userValidationSchema'
import {
    useGetCompanySurveyEvents,
    useGetCompanyUsers,
    useGetPasswordResetUrl,
    useGetSecuritySettingsStatus,
    useResetUserMfa,
    useSendPasswordResetEmail,
} from './useUserManagementQueries'

const ContentContainer = styled.div`
    max-width: 636px;
`

const StyledAlert = styled(Alert)`
    max-width: 580px;
`

const FormContainer = withClassName('pt-3', 'div')

const StyledInput = styled(Input).attrs({ className: 'pt-3' })`
    max-width: 272px;

    @media (min-width: 768px) {
        max-width: 224px;
    }
`

type AccessToggleType = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    id: string
    accessName: string
    accessDescription: string
    checked?: boolean
    disabled?: boolean
    children?: ReactNode
}

const AccessToggle = forwardRef<HTMLInputElement, AccessToggleType>(function AccessToggle(
    { onChange, id, accessName, accessDescription, checked, disabled, children, ...inputProps },
    ref
) {
    return (
        <div className="mt-4 py-4 px-4 bg-white shadow-md rounded flex flex-col">
            <div className="flex">
                <Toggle
                    id={id}
                    aria-label={accessName}
                    className="mr-5"
                    onChange={onChange}
                    disabled={disabled}
                    checked={checked}
                    ref={ref}
                    {...inputProps}
                />
                <div>
                    <p className={cx(disabled ? 'py-1 paragraph-bold text-grey500' : 'py-1 paragraph-bold')}>
                        {accessName}
                    </p>
                    <p className={cx(disabled ? 'pb-2 text-grey500' : 'pb-2')}>{accessDescription}</p>
                </div>
            </div>
            {children && <div>{children}</div>}
        </div>
    )
})

const InsightsAccessSurveyEventList = ({
    flatDepartmentList,
    surveyEvents,
    noAccess = false,
}: {
    flatDepartmentList: [number, CompanyUserDepartmentDto[]][]
    surveyEvents: CompanyUserSurveyEventDto[]
    noAccess?: boolean
}) => {
    return (
        <div>
            {flatDepartmentList.map(([key, value]) => (
                <InsightsAccessSurveyEventListItem
                    surveyEvents={surveyEvents}
                    surveyEventId={key}
                    departments={value}
                    noAccess={noAccess}
                />
            ))}
        </div>
    )
}

const InsightsAccessSurveyEventListItem = ({
    surveyEventId,
    surveyEvents,
    departments,
    noAccess = false,
}: {
    surveyEvents: CompanyUserSurveyEventDto[]
    surveyEventId: number
    departments: CompanyUserDepartmentDto[]
    noAccess?: boolean
}) => {
    const surveyEvent = surveyEvents.find((s) => s.id === surveyEventId)

    if (!surveyEvent) {
        return <></>
    }

    const topLevelDepartmentSelected = departments.some(
        (userDepartment) => userDepartment.id === surveyEvent.topLevelDepartment.id
    )

    return (
        <div className="mt-5">
            <span className={cx('pl-5 pr-2', noAccess && 'line-through')}>{surveyEvent?.name}</span>
            <i className="text-grey600 text-sm">
                {noAccess
                    ? 'Removing survey access'
                    : topLevelDepartmentSelected
                    ? 'All departments'
                    : pluralize(departments.length, 'department')}
            </i>
            <ul className={cx('pl-4 my-1 list-none', noAccess && 'line-through')}>
                {topLevelDepartmentSelected && !noAccess ? (
                    <li className="paragraph-small pb-1 pl-3">
                        {'User has access to all departments for this survey'}
                    </li>
                ) : noAccess && topLevelDepartmentSelected ? (
                    <li className="paragraph-small pb-1 pl-3">{'All departments'}</li>
                ) : (
                    departments.map((d) => <li className="paragraph-small pb-1 pl-3">{d.name}</li>)
                )}
            </ul>
        </div>
    )
}

const SurveySelection = ({
    formProps,
    flatDepartmentMap,
}: {
    formProps: UseFormReturn<UserType>
    flatDepartmentMap: Map<number, CompanyUserDepartmentDto>
}) => {
    const { surveyCompanyId } = useIdentity()
    const { data: surveyEventsData } = useGetCompanySurveyEvents(surveyCompanyId)
    const [selectedSurvey, setSelectedSurvey] = useState<number | undefined>()
    const { control, setValue } = formProps

    const departmentsValue = useWatch({ name: 'roles.departments', control })

    const surveysDepartmentsTree = useMemo(() => {
        if (!surveyEventsData) {
            return []
        }

        return getSurveysDepartmentsTree(surveyEventsData)
    }, [surveyEventsData])

    const surveyEventsToDepartmentIdsMap = useMemo(() => {
        return mapDepartmentIdsToSurveyEvents(departmentsValue ?? [], flatDepartmentMap)
    }, [departmentsValue, flatDepartmentMap])

    const surveyOptions = useMemo(() => {
        return surveyEventsData?.map((item: CompanyUserSurveyEventDto) => ({
            value: item.id,
            label: item.name,
        }))
    }, [surveyEventsData])

    const multiSelectOptions = useMemo(() => {
        const multiSelectOptions = surveysDepartmentsTree?.find(
            (survey) => survey.surveyId === selectedSurvey
        )?.topLevelDepartment

        return multiSelectOptions ? [multiSelectOptions] : []
    }, [surveysDepartmentsTree, selectedSurvey])

    const settings = useMemo(() => {
        if (!surveyEventsToDepartmentIdsMap.has(selectedSurvey ?? -1)) {
            return { initialSelected: [] }
        }

        const selectedOptions = surveyEventsToDepartmentIdsMap.get(selectedSurvey ?? -1)?.sort()

        const initialSelected = getSelectedNodes(multiSelectOptions[0], selectedOptions ?? [])

        return {
            initialSelected,
            includeParents: false,
        }
    }, [surveyEventsToDepartmentIdsMap, selectedSurvey, multiSelectOptions])

    const { control: multiLayerSelectControl } = useHierarchyMultiSelect(multiSelectOptions, settings)

    const { selectedOptions } = multiLayerSelectControl

    return (
        <div className="bg-grey100 rounded mt-3 px-5 py-5">
            <Select
                className="mb-6"
                options={surveyOptions}
                onChange={(e) => setSelectedSurvey(e?.value)}
                placeholder="Select a survey"
            />
            <VisuallyHidden id="user-management-departments-dropdown-help">
                <>
                    {
                        'The following department dropdown menu can be challenging to navigate, especially if your organization has a deep department structure. If you experience any issues using this interface, please feel free to reach out to '
                    }
                    <a href="mailto:accessibility@energage.com" tabIndex={-1}>
                        {'accessibility@energage.com'}
                    </a>
                    {' for assistance.'}
                </>
            </VisuallyHidden>
            <HierarchyMultiSelect
                control={multiLayerSelectControl}
                onMenuClose={() => {
                    const validOptions = filterDepartmentSelections(multiSelectOptions[0], selectedOptions as number[])
                    const valuesToRefresh = surveyEventsToDepartmentIdsMap.get(selectedSurvey ?? -1)
                    const anotherSurveyDepartments =
                        departmentsValue?.filter((department) => !valuesToRefresh?.includes(department)) ?? []
                    if (departmentsValue) {
                        setValue('roles.departments', [...anotherSurveyDepartments, ...validOptions])
                        return
                    }
                    setValue('roles.departments', validOptions)
                }}
                isDisabled={!Boolean(selectedSurvey)}
            />
        </div>
    )
}

const InsightsAccess = ({
    currentDepartments,
    selectedDepartments,
    flatDepartmentMap,
    surveyEvents,
}: {
    currentDepartments: number[]
    selectedDepartments: number[]
    flatDepartmentMap: Map<number, CompanyUserDepartmentDto>
    surveyEvents: CompanyUserSurveyEventDto[]
}) => {
    const [open, toggle] = useToggle(false)

    if (currentDepartments.length === 0 && selectedDepartments.length === 0) {
        return <></>
    }

    const addedDepartments = selectedDepartments.filter((department) => !currentDepartments.includes(department))
    const removedDepartments = currentDepartments.filter((department) => !selectedDepartments.includes(department))

    const surveyEventsToCurrentDepartmentsMap = mapDepartmentsToSurveyEvents(currentDepartments, flatDepartmentMap)
    const surveyEventsToAddedDepartmentsMap = mapDepartmentsToSurveyEvents(addedDepartments, flatDepartmentMap)
    const surveyEventsToRemovedDepartmentsMap = mapDepartmentsToSurveyEvents(removedDepartments, flatDepartmentMap)

    const currentDepartmentsList = Array.from(surveyEventsToCurrentDepartmentsMap)
    const addedDepartmentsList = Array.from(surveyEventsToAddedDepartmentsMap)
    const removedDepartmentsList = Array.from(surveyEventsToRemovedDepartmentsMap)

    const maxListLength = 4
    const currentDepartmentslistTooBig = currentDepartmentsList.length > maxListLength

    return (
        <div className="ml-4 mt-5">
            {(addedDepartmentsList.length > 0 || removedDepartmentsList.length > 0) && (
                <>
                    <b>{'Pending Survey Access'}</b>
                    <div className="mb-5">
                        <InsightsAccessSurveyEventList
                            flatDepartmentList={addedDepartmentsList}
                            surveyEvents={surveyEvents}
                        />
                        <InsightsAccessSurveyEventList
                            flatDepartmentList={removedDepartmentsList}
                            surveyEvents={surveyEvents}
                            noAccess={true}
                        />
                    </div>
                </>
            )}
            {currentDepartments.length > 0 && (
                <>
                    <b>{'Current Survey Access'}</b>
                    <div>
                        <InsightsAccessSurveyEventList
                            flatDepartmentList={
                                currentDepartmentslistTooBig
                                    ? currentDepartmentsList.slice(0, maxListLength)
                                    : currentDepartmentsList
                            }
                            surveyEvents={surveyEvents}
                        />
                    </div>

                    {currentDepartmentslistTooBig && (
                        <div>
                            <Collapse open={open}>
                                <div>
                                    <InsightsAccessSurveyEventList
                                        flatDepartmentList={currentDepartmentsList.slice(maxListLength)}
                                        surveyEvents={surveyEvents}
                                    />
                                </div>
                            </Collapse>
                            <div className="flex items-center content-center">
                                <Divider />
                                <LegacyButton variant="link" onClick={toggle}>{`${open ? 'Hide' : 'Show'} ${pluralize(
                                    currentDepartmentsList.length - maxListLength,
                                    'survey'
                                )}`}</LegacyButton>
                                <Divider />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const Divider = () => <hr className="h-px bg-grey300 border-0 w-1/3" />

const ResetPassword = ({
    isSuperUser,
    surveyCompanyId,
    userId,
    email,
}: {
    isSuperUser: boolean
    surveyCompanyId: number
    userId: number
    email: string
}) => {
    const { data, isLoading: isLoadingUrl, refetch: refetchUrl } = useGetPasswordResetUrl(surveyCompanyId, userId)
    const {
        refetch: refetchEmail,
        isLoading: isLoadingEmail,
        status: statusEmail,
    } = useSendPasswordResetEmail(surveyCompanyId, userId, email)

    const copyIsDisabled = isLoadingUrl || data?.url == null
    const sendIsDisabled = isLoadingEmail || statusEmail === 'success'

    return (
        <div className="flex">
            <DialogTrigger>
                <Button
                    appearance="tertiary"
                    onPress={() => {
                        if (!data?.url && isSuperUser) {
                            refetchUrl()
                        }
                    }}>
                    {'Reset Password'}
                </Button>
                <Popover placement="bottom right">
                    <div className="flex-row items-center gap-4 grid grid-cols-4">
                        {isSuperUser ? (
                            <>
                                {copyIsDisabled ? (
                                    <Skeleton.RectangularShape className="h-10 w-max col-span-2" />
                                ) : (
                                    <Input defaultValue={data.url} disabled size="sm" className="col-span-2" />
                                )}
                                <LegacyButton
                                    outline
                                    variant="primary"
                                    size="sm"
                                    disabled={copyIsDisabled}
                                    className="col-span-1"
                                    onClick={() => {
                                        if (data) {
                                            navigator.clipboard.writeText(data.url)
                                            alert.success('Link copied to clipboard')
                                        }
                                    }}>
                                    {'Copy link'}
                                </LegacyButton>
                            </>
                        ) : (
                            <p className="col-span-3">{'Email this user a link to reset their password.'}</p>
                        )}
                        <LegacyButton
                            variant="primary"
                            onClick={() => {
                                refetchEmail()
                            }}
                            disabled={sendIsDisabled}
                            size="sm"
                            className="col-span-1">
                            {'Send to email'}
                        </LegacyButton>
                    </div>
                </Popover>
            </DialogTrigger>
        </div>
    )
}

const CompanyUserPermissions = ({
    formProps,
    surveyEvents,
}: {
    formProps: UseFormReturn<UserType>
    surveyEvents: CompanyUserSurveyEventDto[]
}) => {
    const { control, register, setValue } = formProps

    const hasAdminAccess = useWatch({ name: 'roles.hasAdminAccess', control })
    const hasInsightsAccess = useWatch({ name: 'roles.hasInsightsAccess', control })
    const hasSurveyAccess = useWatch({ name: 'roles.hasSurveyAccess', control })
    const hasBrandingAccess = useWatch({ name: 'roles.hasBrandingAccess', control })
    const departmentsValue = useWatch({ name: 'roles.departments', control })
    const hasSecurityAccess = useWatch({ name: 'roles.hasSecurityAccess', control })
    const [currentDepartmentAccess, setCurrentDepartmentAccess] = useState<number[] | undefined>()

    useEffect(() => {
        if (departmentsValue) {
            setCurrentDepartmentAccess(departmentsValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAdminAccessChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked
        setValue('roles.hasAdminAccess', value)
        setValue('roles.hasInsightsAccess', value)
        setValue('roles.hasBrandingAccess', value)
        setValue('roles.hasSurveyAccess', value)
        setValue('roles.hasSecurityAccess', value)
    }

    const flatDepartmentMap = flattenDepartmentsFromSurveyEvents(surveyEvents)

    const insightAccessDescription = getInsightAccessDescription(
        hasAdminAccess,
        hasInsightsAccess,
        getSurveyCount(departmentsValue ?? [], flatDepartmentMap)
    )

    return (
        <div>
            <AccessToggle
                id="admin_access_toggle"
                {...register('roles.hasAdminAccess')}
                onChange={(value) => handleAdminAccessChange(value)}
                accessName="Administrator Access"
                checked={hasAdminAccess}
                accessDescription="Full view and edit access to the platform and results data"
            />
            <AccessToggle
                id="insights_access_toggle"
                {...register('roles.hasInsightsAccess')}
                accessName="Insights Access"
                accessDescription={insightAccessDescription} // TODO: This copy is going to change depending on status and subscriptions. Handled by upcoming stories.
                checked={hasAdminAccess || hasInsightsAccess} // TODO: Also need to verify if an active subscription exits. Handled by upcoming stories.
                disabled={hasAdminAccess}>
                {!hasAdminAccess && hasInsightsAccess ? (
                    <>
                        <div className="bg-grey100 rounded">
                            <Checkbox
                                id="actionTrackerCheckbox"
                                className="pt-5 pb-1 px-5 paragraph-bold text-sm"
                                label="Action Tracker Restriction"
                                {...register('roles.isActionTrackerRestriction')}
                            />
                            <p className="pb-5 pl-10 pr-5 ml-1 text-sm">
                                {
                                    'When checked, user will see only the Action Tracker section in Insights for assigned surveys. Graphs and survey results will not be visible.'
                                }
                            </p>
                        </div>
                        <SurveySelection formProps={formProps} flatDepartmentMap={flatDepartmentMap} />
                        <InsightsAccess
                            currentDepartments={currentDepartmentAccess ?? []}
                            selectedDepartments={departmentsValue ?? []}
                            flatDepartmentMap={flatDepartmentMap}
                            surveyEvents={surveyEvents}
                        />
                    </>
                ) : (
                    <></>
                )}
            </AccessToggle>
            {config.featureFlag.enableNewRoles ? (
                <>
                    <AccessToggle
                        id="survey_access_toggle"
                        {...register('roles.hasSurveyAccess')}
                        accessName="Survey & Award Management"
                        accessDescription="Access to manage award participation, set-up & launch surveys, and Top Workplaces Profile"
                        disabled={hasAdminAccess}
                        checked={hasAdminAccess || hasSurveyAccess}
                    />
                    <AccessToggle
                        id="branding_access_toggle"
                        {...register('roles.hasBrandingAccess')}
                        accessName="Branding & Profile"
                        accessDescription="Access to Employer Branding, Media Kits, and Top Workplaces Profile"
                        disabled={hasAdminAccess}
                        checked={hasAdminAccess || hasBrandingAccess}
                    />
                    {config.featureFlag.enableSecurityManagement && (
                        <AccessToggle
                            id="security_access_toggle"
                            {...register('roles.hasSecurityAccess')}
                            accessName="Security"
                            accessDescription="Access to set up SSO and MFA"
                            disabled={hasAdminAccess}
                            checked={hasAdminAccess || hasSecurityAccess}
                        />
                    )}
                </>
            ) : (
                <AccessToggle
                    id="branding_access_toggle"
                    {...register('roles.hasBrandingAccess')}
                    accessName="Employer Branding"
                    accessDescription="Access to Media Kits and Employer Branding"
                    disabled={hasAdminAccess}
                    checked={hasAdminAccess || hasBrandingAccess}
                />
            )}
        </div>
    )
}

const PublisherPermissions = ({ formProps }: { formProps: UseFormReturn<UserType> }) => {
    const { control, register, setValue } = formProps

    const hasAdminAccess = useWatch({ name: 'roles.hasAdminAccess', control })

    return (
        <div>
            <AccessToggle
                id="editorial_access_toggle"
                {...register('roles.hasAdminAccess', {
                    onChange: (event) => {
                        setValue('roles.hasMarketingAccess', event.target.checked)
                    },
                })}
                accessName="Editorial Access"
                accessDescription="Can see ranked listings"
            />
            <AccessToggle
                id="marketing_access_toggle"
                {...register('roles.hasMarketingAccess')}
                accessName="Marketing Access"
                accessDescription="Can see alphabetical listing"
                disabled={hasAdminAccess}
            />
        </div>
    )
}

const ResetMfa = ({
    surveyCompanyId,
    userId,
    hasActiveMfa,
    firstName,
    lastName,
}: {
    surveyCompanyId: number
    userId: number
    hasActiveMfa: boolean
    firstName: string
    lastName: string
}) => {
    const {
        mutateAsync: resetMfa,
        isLoading,
        isSuccess,
    } = useResetUserMfa(surveyCompanyId, userId, firstName, lastName)
    const isDisabled = !hasActiveMfa || isLoading || isSuccess
    return (
        <div className="flex">
            <Tooltip
                trigger={
                    <LegacyButton
                        variant="link"
                        disabled={isDisabled}
                        onClick={() => {
                            resetMfa()
                        }}
                        className={cx(
                            isDisabled
                                ? 'p-0 mt-4 paragraph-bold font-medium text-grey500 text-opacity-50'
                                : 'p-0 mt-4 paragraph-bold font-medium'
                        )}>
                        {'Reset MFA'}
                    </LegacyButton>
                }
                disabled={!isDisabled}
                placement="bottom"
                text={'User will be prompted to reset their MFA when they next sign in'}
                className={'text-base'}
            />
        </div>
    )
}

const UserForm = ({
    defaultValues,
    surveyEvents,
    action,
    onSave,
    isLoading,
    existingUsers,
}: {
    defaultValues: UserType
    surveyEvents: CompanyUserSurveyEventDto[]
    action: string
    onSave: (data: UserType) => void
    isLoading: boolean
    existingUsers: ExistingUser[]
}) => {
    const {
        isSuperUser,
        surveyCompanyId,
        company: { isPublisher },
    } = useIdentity()
    const { data: securityStatus, isFetching: isFetchingSecurityStatus } = useGetSecuritySettingsStatus(surveyCompanyId)
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.xsMax })
    const formProps = useForm<UserType>({
        defaultValues,
        mode: 'all',
        delayError: 500,
        context: {
            existingUsers: existingUsers?.filter((user) => user.id !== defaultValues.id),
        },
        resolver: yupResolver(userValidationSchema),
    })
    const {
        register,
        trigger,
        setValue,
        handleSubmit,
        formState: { errors, submitCount },
        control,
    } = formProps

    const isDisabledValue = useWatch({ name: 'isDisabled', control })

    useEffect(() => {
        if (submitCount > 0) {
            trigger('email', { shouldFocus: true })
        }
    }, [submitCount, trigger, existingUsers])

    if (isLoading) {
        return <Loading />
    }

    const isEditUser = action === EDIT_USER

    const canResetPassword = !isFetchingSecurityStatus && !securityStatus?.isSsoEnabled
    const canResetMfa = !isFetchingSecurityStatus && securityStatus?.isMfaEnabled

    const TopAlert = () => {
        const isInactiveUser = defaultValues.isDisabled && isDisabledValue
        const isPendingDeactivation = !defaultValues.isDisabled && isDisabledValue
        const isPendingReactivation = defaultValues.isDisabled && !isDisabledValue

        return isInactiveUser ? (
            <Alert variant="danger" className="block mb-4 px-5">
                <span className="inline">
                    {'This user is inactive and has no access to their account. To give them access, '}
                    <LegacyButton
                        variant="link"
                        className="p-0 text-blue500 hover:text-blue600 paragraph font-medium"
                        onClick={() => setValue('isDisabled', false)}>
                        {'reactivate them.'}
                    </LegacyButton>
                </span>
            </Alert>
        ) : isPendingDeactivation ? (
            <Alert variant="danger" className="mb-4">
                {
                    'User deactivation is pending. This user will continue to have access to their account and given permissions until changes are saved.'
                }
            </Alert>
        ) : isPendingReactivation ? (
            <Alert variant="danger" className="mb-4">
                {
                    'User reactivation is pending. This user won’t have access to their account and given permissions until changes are saved.'
                }
            </Alert>
        ) : (
            <></>
        )
    }

    const getEmailError = () => {
        if (errors['email']?.type === EMAIL_EXISTS) {
            const existingUserId = Number(errors['email']?.message)
            const userBelongsToAnotherCompany = existingUserId === -1

            return userBelongsToAnotherCompany ? (
                <>{'A user with this email already exists'}</>
            ) : (
                <>
                    {'A user with this email already exists. Enter a different email or '}
                    <LegacyButton
                        variant="link"
                        as={Link}
                        to={routes.manage.users.edit({ userId: existingUserId })}
                        className="p-0 paragraph font-medium text-sm">
                        {'edit their existing permissions.'}
                    </LegacyButton>
                </>
            )
        }

        return errors['email']?.message
    }

    return (
        <form data-testid={`${action}-form`} noValidate onSubmit={handleSubmit((data: UserType) => onSave(data))}>
            <TopAlert />
            <div
                className={cx(
                    'bg-white rounded px-4 pb-4 pt-1 mb-4',
                    isEditUser && 'flex flex-wrap',
                    isMobileDevice ? 'flex-col' : 'justify-between'
                )}>
                <div className={cx(!isMobileDevice && 'w-2/3')}>
                    <StyledInput
                        {...register('firstName')}
                        required
                        label="First Name"
                        size="sm"
                        info={errors['firstName']?.message}
                        error={!!get(errors, 'firstName')}
                    />
                    <StyledInput
                        {...register('lastName')}
                        required
                        label="Last Name"
                        size="sm"
                        info={errors['lastName']?.message}
                        error={!!get(errors, 'lastName')}
                    />
                    <StyledInput
                        {...register('email')}
                        required
                        label="Email"
                        size="sm"
                        info={getEmailError()}
                        error={!!get(errors, 'email')}
                    />
                </div>

                <div className={cx('flex flex-col justify-end sm:items-end', isEditUser && 'flex flex-wrap')}>
                    {isEditUser && !defaultValues.isDisabled && canResetMfa && (
                        <ResetMfa
                            surveyCompanyId={surveyCompanyId}
                            userId={defaultValues.id}
                            hasActiveMfa={defaultValues.securitySettings.hasActiveMfa}
                            firstName={defaultValues.firstName}
                            lastName={defaultValues.lastName}
                        />
                    )}

                    {isEditUser && !defaultValues.isDisabled && canResetPassword && (
                        <ResetPassword
                            isSuperUser={isSuperUser}
                            surveyCompanyId={surveyCompanyId}
                            userId={defaultValues.id}
                            email={defaultValues.email}
                        />
                    )}
                </div>
            </div>
            {!isPublisher && isSuperUser && (
                <Checkbox
                    id="customerSuccessContactCheckbox"
                    className="mb-4 bg-white rounded py-4 px-4"
                    label="User is a Customer Success Primary Contact"
                    {...register('isCustomerSuccessPrimaryContact')}
                />
            )}
            <div className="pb-6">
                <Text textStyle="title5" className="pt-5 pl-comfortable">
                    <h3>{'Access'}</h3>
                </Text>
                {isPublisherControlRoles(defaultValues.roles) ? (
                    <PublisherPermissions formProps={formProps} />
                ) : (
                    <CompanyUserPermissions formProps={formProps} surveyEvents={surveyEvents} />
                )}
            </div>
            <div className={cx(isEditUser ? 'flex justify-between' : 'flex flex-row-reverse')}>
                {isEditUser && (
                    <div className="flex items-center">
                        <LegacyButton
                            variant="link"
                            className={cx(
                                'p-0',
                                isDisabledValue
                                    ? 'text-purple700 hover:text-purple600'
                                    : 'text-red500 hover:text-red600'
                            )}
                            onClick={() => setValue('isDisabled', !isDisabledValue)}>
                            {isDisabledValue ? 'Reactivate User' : 'Deactivate User'}
                        </LegacyButton>
                    </div>
                )}
                <LegacyButton type="submit">{isMobileDevice ? 'Save' : 'Save All'}</LegacyButton>
            </div>
        </form>
    )
}

export const UserFormContainer = ({
    defaultValues,
    surveyEvents,
    action,
    onSave,
    isLoading,
    savingError,
}: {
    defaultValues: UserType
    surveyEvents: CompanyUserSurveyEventDto[]
    action: string
    onSave: (data: UserType) => void
    isLoading: boolean
    savingError: ValidationError | undefined
}) => {
    const { surveyCompanyId, organizationName } = useIdentity()
    const { data: companyUsersData } = useGetCompanyUsers(surveyCompanyId)
    const [existingUsers, setExistingUsers] = useState<ExistingUser[]>([])

    useEffect(() => {
        if (savingError?.code === DUPLICATED_USER_NAME_EXCEPTION) {
            const email = savingError?.attemptedValue?.toString() ?? ''
            setExistingUsers([{ id: -1, email }])
        }
    }, [savingError])

    const users = useMemo(() => {
        if (!companyUsersData) {
            return existingUsers
        }

        return [...companyUsersData, ...existingUsers]
    }, [companyUsersData, existingUsers])

    const getErrorMessage = () => {
        if (!savingError?.code) {
            return 'Error saving changes'
        }

        const errorMessages: Record<string, string> = {
            DuplicateUserNameException: `${savingError?.attemptedValue} is already a user at another organization. This user will need a unique email to be a user at your organization.`,
            DuplicateCompanyUserNameException: `${savingError?.attemptedValue} is already a user at another organization. This user will need a unique email to be a user at your organization.`,
            DeactivatingUniqueAdmin: `This user is currently the only ${organizationName} admin. To deactivate them, another individual needs to be assigned as an admin.`,
            RemovingUniqueAdminPermissions: `This user is currently the only ${organizationName} admin. Before removing their admin access, another admin needs to be assigned.`,
        }

        return errorMessages[savingError?.code] ?? 'Error saving changes'
    }

    return (
        <div data-testid={action}>
            <TopNav
                xsHidden
                title="User Management"
                showBackArrow={true}
                arrowBackRoute={routes.manage.users()}
                className=""
                primaryAction={undefined}>
                <ContentContainer>
                    {savingError?.code ? (
                        <StyledAlert variant="danger" className="w-auto mt-6 mb-4 mx-4 sm:m-6">
                            {getErrorMessage()}
                        </StyledAlert>
                    ) : (
                        <Text textStyle="title3" className="pl-extra-wide py-wide">
                            <h2>{'User Permissions'}</h2>
                        </Text>
                    )}
                    <FormContainer className="px-4 sm:px-6">
                        <UserForm
                            defaultValues={defaultValues}
                            surveyEvents={surveyEvents}
                            action={action}
                            onSave={onSave}
                            isLoading={isLoading}
                            existingUsers={users}
                        />
                    </FormContainer>
                </ContentContainer>
            </TopNav>
        </div>
    )
}
