import { useContext } from 'react'
import type { SurveyTypeOption } from 'constants/SurveyType'
import type TaskDefinition from 'constants/taskDefinition'
import SurveySetupContext from './SurveySetupContext'
import type { WorkflowTask } from './workflow'

type SurveySetupContext = {
    getNextPage: () => WorkflowTask
    surveyHasLaunched: boolean
    surveyHasClosed: boolean
    surveyTypeId: SurveyTypeOption
    getTaskCompletedDateTime: (taskDefinition: typeof TaskDefinition[keyof typeof TaskDefinition]) => string
    refreshWorkflow: () => Promise<void>
    launchDate: string | undefined
}

export function useSurveyWorkflowContext() {
    return useContext(SurveySetupContext) as SurveySetupContext
}
