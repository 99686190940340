export const COLOR_PICKER_HEIGHT = 300
export const COLOR_PICKER_PRESETS = [
    '#FBDCBF',
    '#889BFF',
    '#40CADC',
    '#40DCAE',
    '#FE9191',
    '#C791FE',
    '#DC4040',
    '#D91CC6',
    '#5B1FBB',
    '#FFB23D',
    '#F1DD29',
    '#000000',
    '#404DDC',
    '#29672F',
    '#8C285E',
    '#FFFFFF',
]

export const CULTURE_FACTS_TILE = {
    PREVIEW_HEIGHT: '448px',
    PREVIEW_WIDTH: '448px',
    DOWNLOAD_HEIGHT: '1080px',
    DOWNLOAD_WIDTH: '1080px',
    REEL_DOWNLOAD_HEIGHT: '1920px',
}
