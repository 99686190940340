import { Info } from '@energage/icons'
import every from 'lodash/every'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import takeRight from 'lodash/takeRight'
import toString from 'lodash/toString'
import { EmployeeTable, StyledTableRow } from 'containers/Main/Manage/Employees/EmployeeView/EmployeeTable'
import { EMPTY_VALUE_PLACEHOLDER, mapItemBySequence } from './utils'

const ErrorHelpMsg = ({ message }) =>
    !!message && (
        <div className="w-full md:w-1/2 py-2 flex">
            <Info className="flex-shrink-0 mr-2" />
            <div className="text-sm text-blue600">{message}</div>
        </div>
    )

const getTableHeader = (headers = []) => [
    {
        id: 'spreadSheet',
        label: 'Spreadsheet Row',
        columnWidth: '20%',
    },
    ...headers,
    {
        id: 'errorMessage',
        label: 'Error',
        columnWidth: '40%',
    },
]

const getStandardTableHeader = (propertyName) =>
    getTableHeader([
        {
            id: propertyName,
            label: startCase(propertyName),
        },
    ])

const getParentTableHeader = (propertyName) =>
    getTableHeader([
        {
            id: propertyName,
            label: propertyName,
        },
        {
            id: `Parent ${propertyName}`,
            label: `Parent ${propertyName}`,
        },
    ])

export const errorTypes = {
    multipleParents: 'MultipleParents',
    supervisorIdentifier: 'SupervisorIdentifier',
    invalidIdentifier: 'InvalidIdentifier',
    invalidPhoneNumber: 'InvalidPhoneNumber',
    invalidDepartment: 'InvalidDepartment',
    invalidApproximateSalary: 'InvalidApproximateSalary',
    memberIdentifier: 'MemberIdentifier',
    circularParentDependencies: 'CircularParentDependencies',
    passwordProtected: 'PasswordProtected',
}

export const errorByIndex = [
    errorTypes.memberIdentifier,
    errorTypes.invalidPhoneNumber,
    errorTypes.invalidDepartment,
    errorTypes.invalidApproximateSalary,
]

export const errorByItem = [errorTypes.multipleParents, errorTypes.circularParentDependencies]

const getRowNumber = (errorData) =>
    toString(
        get(
            errorData,
            'customState.index',
            get(errorData, 'customState.indices', get(errorData, 'customState', EMPTY_VALUE_PLACEHOLDER))
        )
    )

const getParentDepartmentName = ({ item1 }) => {
    if (!item1 || item1 === 'null') {
        return EMPTY_VALUE_PLACEHOLDER
    }

    return item1
}

const ErrorTableRow = ({ errorMessage, rowNumber, attemptedValue, parentValue }) => (
    <StyledTableRow className="p-4">
        {rowNumber && <td className="p-2">{rowNumber}</td>}
        <td className="p-2 break-all">{attemptedValue || EMPTY_VALUE_PLACEHOLDER}</td>
        {parentValue && <td className="p-2">{parentValue}</td>}
        <td className="p-2">
            <span className="text-red500">{errorMessage}</span>
        </td>
    </StyledTableRow>
)

const renderDefaultError = (errorData) => (
    <ErrorTableRow
        errorMessage={errorData.errorMessage}
        rowNumber={getRowNumber(errorData)}
        attemptedValue={errorData.attemptedValue}
    />
)

const renderPasswordProtected = (errorData) => (
    <ErrorTableRow errorMessage={errorData.errorMessage} attemptedValue={errorData.attemptedValue} />
)

const renderErrorForItem = (errorData) =>
    map(errorData.customState, (state, index) => (
        <ErrorTableRow
            key={index}
            errorMessage={errorData.errorMessage}
            rowNumber={get(state, 'item2', get(state, 'item2', state))}
            attemptedValue={errorData.attemptedValue}
            parentValue={getParentDepartmentName(state)}
        />
    ))

const renderMultipleParents = (errorData) =>
    renderErrorForItem({
        ...errorData,
        customState: mapItemBySequence(errorData.customState?.parents),
    })

const renderCircularParentDependencies = (errorData) =>
    renderErrorForItem({
        ...errorData,
        customState: [{ item1: errorData.customState.parentDepartment, item2: errorData.customState.index }],
    })

const renderErrorByItem = (code) => {
    switch (code) {
        case errorTypes.multipleParents:
            return renderMultipleParents
        case errorTypes.circularParentDependencies:
            return renderCircularParentDependencies
        case errorTypes.passwordProtected:
            return renderPasswordProtected
        default:
            return renderDefaultError
    }
}

export default (value, key) => {
    if (includes(errorByIndex, key)) {
        const firstError = value && value[0]
        return (
            <div className="pb-3">
                <EmployeeTable
                    columns={getStandardTableHeader(firstError?.propertyName)}
                    sortColumn={false}
                    isSortingDisabled>
                    {map(value, (error) => renderDefaultError(error))}
                </EmployeeTable>
                <ErrorHelpMsg message={firstError?.customState?.help} />
            </div>
        )
    }

    return map(
        groupBy(value, ({ propertyName }) => propertyName),
        (groupError, key, index) => {
            const firstError = groupError && groupError[0]
            const renderError = renderErrorByItem(firstError?.errorCode)
            const containsRow = every(groupError, (x) => getRowNumber(x) !== '')
            const columns = includes(errorByItem, firstError?.errorCode)
                ? getParentTableHeader(key)
                : takeRight(getStandardTableHeader(key), containsRow ? 3 : 2)

            return (
                <div className="pb-3" key={index}>
                    <EmployeeTable columns={columns} sortColumn={false} isSortingDisabled>
                        {map(groupError, (error) => renderError(error))}
                    </EmployeeTable>
                    <ErrorHelpMsg message={firstError?.customState?.help} />
                </div>
            )
        }
    )
}
