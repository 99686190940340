import { LegacyButton as Button } from '@energage/components'
import config from 'config'
import { SUPPORT_PHONE } from 'constants/strings'
import auth from 'util/auth'

const AccessDenied = () => {
    return (
        <div className="p-20 flex justify-center">
            <div className="p-20 m-10 mt-20 text-center bg-white rounded-lg shadow-lg">
                <h2 className="text-center">{'Access denied'}</h2>
                <p className="pt-3">{"Uh oh. Looks like you don't have access to this page."}</p>
                <p>{`Please contact Support at ${SUPPORT_PHONE} if you think this is incorrect.`}</p>

                <div className="mt-10">
                    <Button variant="primary" onClick={() => (window.location = config.urls.surveySignIn)}>
                        {'Go to survey'}
                    </Button>
                </div>

                <div className="mt-3">
                    <Button variant="link" onClick={auth.logout}>
                        {'Login as a different user'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AccessDenied
