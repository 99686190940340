import mergeWith from './base'

const apiBaseUrl = '$API_HOST'
const auth0Domain = '$AUTH_DOMAIN'
const brandingApi = `${apiBaseUrl}/branding/v2`

// to combat terser from optimizing away '$ENABLE_USER_MANAGEMENT' === '1'
window.enableNewRoles = '$ENABLE_NEW_ROLES'
window.enableQueryEducationalContent = '$ENABLE_QUERY_EDUCATIONAL_CONTENT'
window.enableSecurityManagement = '$ENABLE_SECURITY_MANAGEMENT'
window.enableShareToLinkedIn = '$ENABLE_SHARE_TO_LINKEDIN'
window.enableContentCreator = '$ENABLE_CONTENT_CREATOR'

export default mergeWith({
    api: {
        authentication: `${apiBaseUrl}/v1/authentication`,
        workplaceSurvey: `${apiBaseUrl}/v1/twp/workplacesurvey`,
        branding: brandingApi,
        reports: `${apiBaseUrl}/v1/twp/reports`,
        platform: `${apiBaseUrl}/v1/core/orgmanagement`,
        organization: `${apiBaseUrl}/v1/core/organization`,
        subscription: `${apiBaseUrl}/v1/subscription`,
        ordering: `${apiBaseUrl}/v1/core/ordering`,
        insights: `${apiBaseUrl}/insights/v1`,
        documentManagement: `${apiBaseUrl}/documentmanagement/v1`,
    },
    auth: {
        audience: '$AUTH_AUDIENCE',
        domain: auth0Domain,
        clientId: '$AUTH_CLIENT_ID',
    },
    branding: {
        api: brandingApi, // TODO: Deprecate in favor of branding.api
        sharing: {
            api: `${apiBaseUrl}/branding/sharing`,
            linkedIn: {
                clientId: '$LINKEDIN_APP_CLIENT_ID',
            },
        },
    },
    urls: {
        results: '$RESULTS_URL',
        xenon: '$XENON_URL',
        orderPage: '$ORDER_PAGE_URL',
        surveyManager: '$SURVEY_MANAGER_URL',
        surveyTracker: '$SURVEY_TRACKER_URL',
        survey: '$SURVEY_BASE_URL',
        surveyLogout: '$SURVEY_LOGOUT_URL',
        surveySignIn: '$SURVEY_SIGNIN_URL',
        userManagement: '$MANAGE_USERS_URL',
        topWorkplaces: '$TWP_URL',
        peakCommerce: '$PEAK_COMMERCE_URL',
        energageSetup: '$ENERGAGE_SETUP_URL',
    },
    logging: {
        datadog: {
            applicationId: '$DATADOG_APPLICATION_ID',
            clientToken: '$DATADOG_CLIENT_TOKEN',
            site: '$DATADOG_SITE',
        },
    },
    zuora: {
        url: '$ZUORA_PAYMENT_URL',
        paymentGateway: '$ZUORA_PAYMENT_GATEWAY',
        hostedPagePrefix: '$ZUORA_HOSTED_PAGE_PREFIX',
    },
    calendly: {
        smallSize: '$CALENDLY_SMALL_SIZE',
        midSize: '$CALENDLY_MID_SIZE',
        largeSize: '$CALENDLY_LARGE_SIZE',
    },
    featureFlag: {
        enableNewRoles: window.enableNewRoles === '1',
        enableQueryEducationalContent: window.enableQueryEducationalContent === '1',
        enableSecurityManagement: window.enableSecurityManagement === '1',
        enableShareToLinkedIn: window.enableShareToLinkedIn === '1',
        enableContentCreator: window.enableContentCreator === '1',
    },
    environment: '$ENVIRONMENT_NAME',
    version: '$VERSION',
})
