import { Icon } from '@energage/icons'
import map from 'lodash/map'
import styled from 'styled-components'
import { TOPWORKPLACES_URL } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { ReactComponent as FacebookIcon } from 'images/employerBranding/mediaKit/facebookIconLite.svg'
import { ReactComponent as LinkedInIcon } from 'images/employerBranding/mediaKit/linkedinIconLite.svg'
import { ReactComponent as TwitterIcon } from 'images/employerBranding/mediaKit/twitterIconLite.svg'
import withClassName from 'style/util/withClassName'

const SocialMediaName = styled.label.attrs({ className: 'font-bold text-base text-primary-dark' })`
    color: rgba(0, 65, 80, 1);
    line-height: 20px;
`
const UnOrderedList = withClassName('list-decimal text-primary-dark text-base -ml-6', 'ul', 'unorderedList')
const List = withClassName('pt-3', 'li', 'list')

const SocialMediaData = ({ icon, name, labelData, listData }) => (
    <li className="flex mt-4">
        <div>
            <Icon as={icon} size={20} className="socialIcon w-8 h-8" />
        </div>
        <span className="pl-2">
            <SocialMediaName>{name} </SocialMediaName>
            <label>{labelData}</label>
            <UnOrderedList>
                {map(listData, (x, index) => (
                    <List key={index}>{x}</List>
                ))}
            </UnOrderedList>
        </span>
    </li>
)

export const SocialMediaSectionData = () => (
    <ul className="list-none text-base pl-0">
        <SocialMediaData
            icon={TwitterIcon}
            name={'Twitter:'}
            labelData={
                'Include 5-6 hashtags relevant to your company, #HR, or #EmployeeEngagement. This will increase visibility and search capabilities. You can pin the tweet to the top to keep it visible. Copy suggestions:'
            }
            listData={[
                `We've been named a [Year] #TopWorkplaces winner! Thanks to our employees, who helped to make this happen! More info here: ${TOPWORKPLACES_URL} @TopWorkplaces @TeamEnergage.`,
                "We are proud to announce we've been named a [Year] #TopWorkplaces winner. Thanks to our employees, @TopWorkplaces, and @TeamenEnergage who helped make this happen! Read more about our award here: [link to press release].",
            ]}
        />
        <SocialMediaData
            icon={FacebookIcon}
            name={'Facebook: '}
            labelData={
                'Multiple hashtags are not as relevant on this channel, and are often considered spam. Copy suggestions:'
            }
            listData={[
                `We're proud to announce we've been named a #TopWorkplaces winner! A big thank you to all of our employees, who helped make this happen. Read more here @TopWorkplaces: ${TOPWORKPLACES_URL}`,
                'Honored to announce our team has been named a [Year] #TopWorkplaces winner for our culture that values its people. Learn more  [link to press release] @TopWorkplaces',
            ]}
        />
        <SocialMediaData
            icon={LinkedInIcon}
            name={'LinkedIn:'}
            labelData={
                'Include 5-6 hashtags that are relevant to your company, #HR, or #EmployeeEngagement. This will increase visibility and search capabilities on your company page, post, and award. Copy suggestions:'
            }
            listData={[
                `[Tag your company name] is proud to announce we've been named to the [Year] @TopWorkplaces list. We’re thankful to our employees who made this happen! Read more at: ${TOPWORKPLACES_URL} @TopWorkplaces @Energage #HR #EmployeeRetention #EmployeeEngagement`,
                "We're thrilled to announce we made the [tag newspaper name] [Year] #TopWorkplaces list. This award means the world because it's based on employee' feedback! Thank YOU, team. Read more here [link to press release] @Energage #HR #Recruiting #Careers #CompanyCulture.",
            ]}
        />
    </ul>
)
