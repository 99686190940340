import { useBrandingQuery } from 'api'
import type { ResponseError } from 'api'
import showErrorAlert from 'util/showErrorAlert'

const useFetchPublicPage = <T>(widgetName: string, url: string) =>
    useBrandingQuery<T, ResponseError>(widgetName, url, {
        onError: (error) => showErrorAlert('There was an error loading data', error.parsed),
    })

export default useFetchPublicPage
