import cx from 'clsx'
import styled from 'styled-components'
import type { PreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/LoveMyJobConfiguration/EmpComments.types'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

const PreviewContainer = styled.div<{
    backgroundColor?: string
    highlightColor?: string
    width?: string
    height?: string
    alt?: string
}>`
    width: ${({ width }) => width ?? '340px'};
    height: ${({ height }) => height ?? '340px'};
    background: ${({ backgroundColor }) => backgroundColor};
    background-image: ${(props) => `radial-gradient(circle, ${props.highlightColor} 0, ${props.backgroundColor} 55%)`};
`

const TextWrapper = styled.div<{ textColor: string }>`
    color: ${({ textColor }) => textColor};
`

const Heading = styled.h3<{ titleColor?: string; isDownloadable?: boolean }>`
    padding-bottom: ${({ isDownloadable }) => (isDownloadable ? '42px' : '24px')};
    color: ${({ titleColor }) => titleColor};
    font-size: ${({ isDownloadable }) => (isDownloadable ? '98px' : '44px')};
    line-height: 108%;
`

const Paragraph = styled.p<{ isDownloadable?: boolean }>`
    font-size: ${({ isDownloadable }) => (isDownloadable ? '54px' : '24px')};
    line-height: 136%;
`

export const EmpCommentsCirclePreview = ({
    text,
    width,
    height,
    backgroundColor,
    highlightColor,
    titleColor,
    textColor,
    isDownloadable,
}: PreviewProps) => {
    return (
        <PreviewContainer
            className={cx(isDownloadable ? 'p-16' : 'p-10', 'relative grid content-center')}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            highlightColor={highlightColor}>
            <div className={cx(isDownloadable ? 'w-40 mt-8 mr-8' : 'w-20 mt-3 mr-3', 'absolute top-0 right-0')}>
                <img src={CultureFactTWP} alt="" className="border rounded-full w-full" />
            </div>
            <TextWrapper className="text-center" textColor={textColor}>
                <Heading className="font-black" titleColor={titleColor} isDownloadable={isDownloadable}>
                    {'I love my job because'}
                </Heading>
                <Paragraph className="text-left font-semibold" isDownloadable={isDownloadable}>{`${text}`}</Paragraph>
            </TextWrapper>
        </PreviewContainer>
    )
}
