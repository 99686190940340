function isModified(e) {
    return e.altKey || e.metaKey || e.ctrlKey
}

// for usage on a number input
export default function preventNonNumbers(e) {
    const key = e.keyCode || e.which
    if ((key < 46 || key > 57) && !isModified(e)) {
        e.preventDefault()
    }
}
