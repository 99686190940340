import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { ReactComponent as TwpIcon } from 'images/twp.svg'
import type { AwardSelection } from '../../../AwardSelection'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const AwardsSelectedNotConfirmed = ({ surveyEventId }: { awards: AwardSelection[]; surveyEventId: number }) => {
    const tileUrl = routes.survey.setup.awards({ surveyEventId })

    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="text-red600 justify-center mt-2" color={colors.red600}>
                <Icon as={TwpIcon} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle
                        className="mb-2 text-red600"
                        ariaText="Required: You have not confirmed the employee counts for your selected awards">
                        {' You have not confirmed the employee counts for your selected awards'}
                    </CardTitle>
                    <p>{`Please confirm your employee counts for each award selected.`}</p>
                </CardContent>
                <SummaryButton className="w-fit" tileUrl={tileUrl} buttonText="Confirm Employee Counts" />
            </CardBody>
        </SummaryCard>
    )
}
