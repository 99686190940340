import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, Select } from '@energage/components'
import find from 'lodash/find'
import { optionTagShape } from '../../../propTypes'

const SelectDepartment = ({ allDepartments, departmentId, onNewDeptSelect }) => {
    const handleNewDeptSelect = useCallback(
        (data) => {
            onNewDeptSelect(data)
        },
        [onNewDeptSelect]
    )

    return (
        <InputGroup
            label="Department"
            className="flex-1 md:pr-3"
            as={Select}
            name="department"
            id="department"
            value={find(allDepartments, (x) => x.id === departmentId)}
            options={allDepartments}
            onChange={handleNewDeptSelect}
            getOptionLabel={(opt) => opt.value}
            getOptionValue={(opt) => opt.id}
        />
    )
}

SelectDepartment.propTypes = {
    departmentId: PropTypes.string,
    allDepartments: PropTypes.arrayOf(PropTypes.shape(optionTagShape)),
    onNewDeptSelect: PropTypes.func.isRequired,
}

export default SelectDepartment
