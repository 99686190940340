import { useState } from 'react'
import { Dropdown, LegacyButton as Button } from '@energage/components'
import { Icon, UnfoldLess, UnfoldMore } from '@energage/icons'
import cx from 'clsx'
import keys from 'lodash/keys'
import map from 'lodash/map'
import range from 'lodash/range'
import styled from 'styled-components'
import chain from 'util/chainFunctions'
import DemographicEditBox from './DemographicEditBox'

const frequentlyUsedDemographicTypes = {
    0: { name: 'Generation' },
    1: { name: 'Locations' },
    2: { name: 'Roles/Function' },
    3: { name: 'Manager/Supervisor' },
}

function useDemographicTypeSelect(startingValue, submitChange) {
    const [currentValue, setCurrentValue] = useState(startingValue)
    const [customInput, setCustomInput] = useState('')

    const onTypeSelected = (selectedIndex) => {
        setCurrentValue(frequentlyUsedDemographicTypes[selectedIndex])
        submitChange(frequentlyUsedDemographicTypes[selectedIndex])
    }

    const setCustomType = () => {
        const newValue = { id: '', name: customInput }
        setCurrentValue(newValue)
        submitChange(newValue)
    }

    return { currentValue, onTypeSelected, setCustomInput, setCustomType, customInput }
}

const TypeDropdown = styled(Dropdown)`
    &.en-popover {
        box-shadow: none;
    }
`

const DemographicSelectDropdown = ({
    optionsTitle,
    options,
    value,
    allowCustomInput,
    setValue,
    showFrequentlyUsed = true,
}) => {
    const [error, setError] = useState(null)
    const optionsKeys = keys(options)
    const keysLength = optionsKeys.length
    const { currentValue, onTypeSelected, customInput, setCustomInput, setCustomType } = useDemographicTypeSelect(
        value,
        setValue
    )

    const handleOnchange = (text) => {
        const newValue = { id: '', name: text }
        setValue(newValue)
        setError(!text ? 'Required' : null)
    }

    return (
        <>
            {showFrequentlyUsed ? (
                <TypeDropdown
                    ariaLabel="Add a demographic type"
                    placement="bottom"
                    trigger={(popover) => (
                        <button className="w-full md:min-w-0 flex justify-between items-center focus:outline-none border-none">
                            <div className="w-full md:min-w-0 py-1 flex justify-between items-center focus:outline-none rounded border border-grey300 bg-white">
                                <div className="text-grey500 pl-1 whitespace-no-wrap truncate">
                                    {currentValue.name || 'Select...'}
                                </div>
                                <div className="text-primary-dark">
                                    <Icon className="block" as={popover.visible ? UnfoldLess : UnfoldMore} />
                                </div>
                            </div>
                        </button>
                    )}>
                    {(popover) => (
                        <div className={cx('bg-white w-full -my-1 mr-5 border border-grey300')}>
                            {optionsTitle && <div className="text-grey500 text-xs px-2 mt-2">{optionsTitle}</div>}
                            {map(range(keysLength), (count) => (
                                <button
                                    className="block w-full text-left px-2 py-1 text-grey800 text-base cursor-pointer hover:bg-grey200"
                                    key={optionsKeys[count]}
                                    onClick={chain(popover.hide, () => onTypeSelected(optionsKeys[count]))}>
                                    {frequentlyUsedDemographicTypes[count].name}
                                </button>
                            ))}
                            {allowCustomInput && (
                                <div className="bg-grey200 w-full px-2 pt-2 pb-3 border-t border-grey300 text-center mt-4">
                                    <label className="text-left w-full text-grey500">{'Custom Input'}</label>
                                    <DemographicEditBox
                                        className="text-base w-full"
                                        value={currentValue?.id ? currentValue.name : ''}
                                        onChange={setCustomInput}
                                    />
                                    <Button
                                        className="mt-2 w-1/2 text-xs py-2 leading-none"
                                        variant="primary"
                                        disabled={customInput.length === 0}
                                        onClick={chain(popover.hide, setCustomType)}>
                                        {'Save'}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </TypeDropdown>
            ) : (
                <>
                    <DemographicEditBox
                        className="w-full"
                        value={currentValue?.name || ''}
                        onChange={handleOnchange}
                        placeholder={'Enter Topic'}
                    />
                    <div>{error && <span className="text-red500">{error}</span>}</div>
                </>
            )}
        </>
    )
}

const DemographicTypeSelect = ({ selectedValue, setDemographicType, showFrequentlyUsed = true }) => {
    return (
        <DemographicSelectDropdown
            className="border-none w-full"
            value={selectedValue}
            optionsTitle="Frequently used"
            options={frequentlyUsedDemographicTypes}
            allowCustomInput={true}
            setValue={setDemographicType}
            showFrequentlyUsed={showFrequentlyUsed}
        />
    )
}

export default DemographicTypeSelect
