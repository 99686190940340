const ThemeCategory = {
    FairlyValued: 195,
    RespectedSupported: 197,
    EnabledtoGrow: 196,
    CloselyAligned: 199,
    EmpoweredtoExecute: 198,
    Engaged: 194,
    Healthcare: 200,
    Research: 205,
    Partner: 201,
    Regional: 144,
}

export default ThemeCategory
