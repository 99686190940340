import { Button, Skeleton } from '@energage/components'
import { Text } from '@energage/typography'
import styled from 'styled-components'
import BrandingPackBannerImage from 'images/employerBranding/dashboard/employeeBrandingPackBanner.png'
import BrandingPackTitleImage from 'images/employerBranding/dashboard/employeeBrandingPackTitle.png'
import { useDownloadBrandingPack } from './useDownloadBrandingPack'

const ScrollSnapWidgetContainer = styled.div`
    scroll-snap-align: start;
`
const ContentWrapper = styled.div`
    width: 365px;
`
const SkeletonCard = styled(Skeleton.RectangularShape)`
    height: 510px;
    width: 448px;
`

export const EmployeeBrandingPack = ({
    hasSubscription,
    isLoading,
}: {
    hasSubscription?: boolean
    isLoading: boolean
}) => {
    const { downloadAssets, isDownloading } = useDownloadBrandingPack()

    return (
        <ScrollSnapWidgetContainer>
            {isLoading ? (
                <SkeletonCard />
            ) : (
                hasSubscription && (
                    <ContentWrapper className="grid justify-items-center p-4 pt-7">
                        <h3 className="ml-2">
                            <img src={BrandingPackTitleImage} alt="Employee Branding Pack" />
                        </h3>
                        <img
                            src={BrandingPackBannerImage}
                            alt="Example of business card using the Top Workplaces logo"
                        />
                        <Text textStyle="title2" className="text-center mb-8 -mt-4">
                            <p>{'Let your employees show off your win!'}</p>
                        </Text>
                        <Button
                            className="text-xs"
                            aria-label="Download Employee Assets"
                            appearance="primary"
                            isDisabled={isDownloading}
                            onPress={() => downloadAssets()}>
                            {isDownloading ? 'Downloading...' : 'Download Employee Assets'}
                        </Button>
                    </ContentWrapper>
                )
            )}
        </ScrollSnapWidgetContainer>
    )
}
