import { Checkbox } from '@energage/components'
import { Icon } from '@energage/icons'
import map from 'lodash/map'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import Container from 'components/Container'
import Inset from 'components/Inset'
import { TOPWORKPLACES_URL } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { workplacePublicitySections } from 'containers/Main/EmployerBranding/MediaKit/WorkplacePublicityConfig'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'
import { ReactComponent as FacebookIcon } from 'images/employerBranding/mediaKit/facebookWorkplacePublicity.svg'
import { ReactComponent as LinkedInIcon } from 'images/employerBranding/mediaKit/linkedinWorkplacePublicity.svg'
import { ReactComponent as TwitterIcon } from 'images/employerBranding/mediaKit/twitterWorkplacePublicity.svg'
import withClassName from 'style/util/withClassName'
import MediaKitPDFHeader from './MediaKitPDFHeader'

const TITLE = 'Top Workplaces Publicity Guide'
const LabelForSection = withClassName('font-bold text-purple700 py-2 text-sm', 'label')
const SectionComment = withClassName('text-sm float-left py-2', 'h7')

const PageBreaker = styled.div.attrs({ className: 'pt-4' })`
    page-break-before: always;
`

export const WorkplacePublicity = ({ location }) => {
    const { year, denyTWPMerch, publisherId, awardCategoryId, mediaKitLiteExperience } =
        parseQueryParams(location) || {}
    const isDenyTWPMerch = denyTWPMerch === 'True'

    const sections = workplacePublicitySections(isDenyTWPMerch)

    return (
        <Inset className="w-full h-full pb-2 font-sans">
            <MediaKitPDFHeader
                title={TITLE}
                year={year}
                publisherId={publisherId}
                awardCategoryId={awardCategoryId}
                mediaKitLiteExperience={mediaKitLiteExperience}
            />
            <Container className="p-3">
                <div className="p-4">
                    <div className="text-center pb-2 font-bold">
                        <h4 className="text-base">{'Publicizing your Top Workplaces Award'}</h4>
                        <label className="mt-5 text-xs">
                            {
                                "Now that you've earned bragging rights, here are some ideas for spreading the word about your Top Workplaces award."
                            }
                        </label>
                    </div>
                    <div className="p-4 float-left">
                        <LabelForSection>{'Announce the news to your employees'}</LabelForSection>
                        <p>
                            <SectionComment>
                                {
                                    'Your employees made your Top Workplaces award possible. Share the news and celebrate with them!'
                                }
                            </SectionComment>
                        </p>
                        <div className="pt-3">
                            {map(
                                sections.announceNewsToYourEmployees,
                                ({ label, isHidden }) =>
                                    !isHidden && (
                                        <Checkbox
                                            key={label}
                                            inline
                                            disabled
                                            label={label}
                                            className="w-full py-1 text-xs text-black"
                                        />
                                    )
                            )}
                        </div>
                    </div>
                    <div className="p-4 float-left">
                        <LabelForSection>{'Use the Top Workplaces logo'}</LabelForSection>
                        <p>
                            <SectionComment>
                                {
                                    'As a Top Workplace, one of the perks is having our full permission to use the Top Workplaces logo to publicize your status. Our suggestions:'
                                }
                            </SectionComment>
                        </p>
                        <div className="pt-3">
                            {map(sections.useTopWorkplacesLogo, ({ label }) => (
                                <Checkbox
                                    key={label}
                                    disabled
                                    inline
                                    label={label}
                                    className="w-full py-1 text-xs text-black"
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="px-8 py-4 float-left">
                    <LabelForSection>{'Share the honor via social media'}</LabelForSection>
                    <p className="text-sm float-left pt-1">
                        <p>
                            {
                                'Use Facebook, Twitter, and LinkedIn to share the news with your network to attract talent, customers, partners, even investors. Encourage your employees to re-share your post or create their own. Across all channels, use the hashtag #TopWorkplaces and tag @TopWorkplaces for the chance to be featured on our social media.'
                            }
                        </p>
                    </p>
                </div>
                <PageBreaker>
                    <div className="px-8 py-4 float-left">
                        <p className> {'Here are some examples:'} </p>
                        <ul className="pt-3 list-none text-xs pl-0">
                            <li className="pt-3">
                                <div className="float-left">
                                    <Icon as={TwitterIcon} size={20} className="socialIcon w-8 h-8" />
                                </div>
                                <div className="pl-10">
                                    <label className="font-bold">{'Twitter:'} </label>
                                    {
                                        'Include 5-6 hashtags that are relevant to your company, #HR or #employee engagement.We have included some examples in the sample posts. This will increase visibility on your post and your award and make your organization more searchable. You can "pin" the tweet to the top to always keep this visible in your feed.'
                                    }
                                    <p className="font-bold pt-3">{'Copy Suggestion:'}</p>
                                    <ul className="list-decimal">
                                        <li>
                                            {`We've been named on the ${year} @TopWorkplace list. Thanks to our employees, who helped to make this happen! More info here #TopWorkplace:`}
                                            <ExternalLink href={TOPWORKPLACES_URL} target={TOPWORKPLACES_URL}>
                                                {TOPWORKPLACES_URL}
                                            </ExternalLink>
                                            {' @teamenergage.'}
                                        </li>
                                        <li className="pt-3 pb-5">
                                            {`We are proud to announce we've been named a ${year} @TopWorkplace. Thanks to our employees, [tag newspaper name], @topworkplaces and @teamenergage who helped make this happen! Read more about our award here: [link to press release].`}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="float-left">
                                    <Icon as={FacebookIcon} size={20} className="socialIcon w-8 h-8" />
                                </div>
                                <div className="pl-10">
                                    <label className="font-bold">{'Facebook: '}</label>
                                    {
                                        'Multiple hashtags are not as relevant on this channel, and can be considered spam if you include them.'
                                    }
                                    <p className="font-bold pt-3">{'Copy Suggestion:'}</p>
                                    <ul className="list-decimal">
                                        <li>
                                            {
                                                "We're proud to announce that we've been named a #TopWorkplace by [tag newspaper name]! A big thank you to all of our employees, who helped make this happen. Read more here @topworkplaces:"
                                            }
                                            <ExternalLink href={TOPWORKPLACES_URL} target={TOPWORKPLACES_URL}>
                                                {TOPWORKPLACES_URL}
                                            </ExternalLink>
                                            {'.'}
                                        </li>
                                        <li className="pt-3 pb-5">
                                            {`Honored to announce our team has been named a ${year} #TopWorkplace by the [tag newspaper name] for our culture that values its people and amplifies talents. Learn more @topworkplaces [link to press release] ✓ Add the award to your Facebook timeline.`}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="float-left">
                                    <Icon as={LinkedInIcon} size={20} className="socialIcon w-8 h-8" />
                                </div>
                                <div className="pl-10">
                                    <label className="font-bold">{'LinkedIn:'} </label>
                                    {
                                        'Include 5-6 hashtags that are relevant to your company, #HR or #employeeengagement.We have included some examples in the sample posts. This will increase visibility and search capabilities on your company page, post and your award.'
                                    }
                                    <p className="font-bold pt-3">{'Copy Suggestion:'}</p>
                                    <ul className="list-decimal">
                                        <li>
                                            {`[Tag your company name] is proud to announce we've been named to the ${year} @TopWorkplaces list. We're thrilled to have been included and thankful to our employees, who made this happen! Read more at: `}
                                            <ExternalLink href={TOPWORKPLACES_URL} target={TOPWORKPLACES_URL}>
                                                {TOPWORKPLACES_URL}
                                            </ExternalLink>
                                            {' #TopWorkplace @Energage #HR #Employee Retention #Employee Engagement.'}
                                        </li>
                                        <li className="pt-3">
                                            {`We're thrilled to announce that we made the [tag newspaper name] ${year} #TopWorkplace list. This award means the world because it's based on 'feedback from our people! Thank YOU, team. Read more here [link to press release] @Energage #HR #recruiting #careers #culturematters.`}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="px-8 py-4 float-left">
                        <LabelForSection>{'Issue a press release'}</LabelForSection>
                        <p>
                            <SectionComment>
                                {
                                    "Use Facebook, Twitter, and LinkedIn to share the news with your network to attract talent, customers, partners, even The media kit includes a press release template. If you have a public relations department or outside firm, they should be able to handle this. If you don't have that option, no worries!"
                                }
                            </SectionComment>
                        </p>
                        <p className="font-bold pt-3 text-xs">{'Here are some easy steps:'}</p>
                        <div className="pt-3 text-xs">
                            <ul className="list-decimal">
                                {map(sections.issuePressRelease, ({ label, description, className }) => (
                                    <li className={className}>
                                        {label}
                                        {description && <p> {description} </p>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </PageBreaker>
                <PageBreaker>
                    <div className="px-8 py-4 float-left">
                        <LabelForSection>{'Write a blog post'}</LabelForSection>
                        <p>
                            <SectionComment>
                                {
                                    'If your organization has an online content presence, you can get deeper and more personal with a blog post.'
                                }
                            </SectionComment>
                        </p>
                        <div className="pt-3">
                            {map(sections.writeBlogPost, ({ label }) => (
                                <Checkbox
                                    key={label}
                                    disabled
                                    inline
                                    label={label}
                                    className="w-full py-1 text-xs text-black"
                                />
                            ))}
                        </div>
                    </div>
                </PageBreaker>
            </Container>
        </Inset>
    )
}

export default WorkplacePublicity
