import { useToggle } from '@energage/hooks'
import { Text } from '@energage/typography'
import cx from 'clsx'
import map from 'lodash/map'
import type {
    AspiringFAQsRevampSection,
    FAQRevampSection,
} from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Button } from 'containers/Main/AwardAnnouncement/constants'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import faqIcon from 'images/employerBranding/mediaKit/faqIcon.svg'
import { YellowButton } from '../../YellowButton'
import { faqList } from './faqList'

const FAQ = ({ question, answer, isPdf }: FAQRevampSection) => {
    const [isBoxExpanded, expandBox] = useToggle(isPdf)

    return (
        <>
            <li className={cx('mb-4 pl-3', { 'border-b': !isPdf })}>
                <Button
                    className="flex items-center cursor-pointer mb-4"
                    type="button"
                    onClick={expandBox}
                    aria-expanded={isBoxExpanded}>
                    <img
                        src={faqIcon}
                        alt="FAQ question"
                        className={cx('mr-2 transform transition duration-200 ease-in-out', {
                            'rotate-90': isBoxExpanded,
                            'hidden': isPdf,
                        })}
                    />
                    <Text textStyle="title5" className="ml-2 text-left">
                        {question}
                    </Text>
                </Button>
                {isBoxExpanded && <p className="mt-2 ml-8 p-2 ">{answer}</p>}
            </li>
        </>
    )
}

export const FAQs = ({ companyName, year, faqUrl, bgColor, isPdf }: AspiringFAQsRevampSection) => (
    <Section bgColor={bgColor} textColor={''}>
        <h4 className={cx('text-3xl font-black pb-6', { 'pl-5': isPdf })}>{'Frequently Asked Questions'}</h4>
        <ol className="list-none pl-0">
            {map(faqList(companyName, year), ({ question, answer }, index: number) => (
                <FAQ key={index} question={question} answer={answer} isPdf={isPdf} />
            ))}
        </ol>
        <div className="mt-12 text-center">
            <YellowButton href={faqUrl} target="_blank">
                {'TopWorkplaces.com FAQ'}
            </YellowButton>
        </div>
    </Section>
)
