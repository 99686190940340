import map from 'lodash/map'
import { useManagementQuery } from 'api'
import type { ResponseError } from 'api'
import { getSectorInfo } from 'containers/Main/EmployerRecognition/utils'
import {
    useFetchContactTypes,
    useFetchOwnershipTypes,
    useFetchSectors,
    useFetchStates,
    useFetchZipCodes,
    useOrganizationFeatures,
} from 'hooks'
import type { ContactType, OwnershipType, Sector, State, ZipCode } from 'hooks'
import type { AwardRegistrationInfo, SectorDetails } from './awardRegistration.types'

export const useFetchAwardRegistration = (awardListParticipantId: number) => {
    const { isActive, KnownOrgFeatureIds } = useOrganizationFeatures()
    const hasFullyRemoteParticipation = isActive(KnownOrgFeatureIds.FullyRemoteParticipation)

    const {
        data: registrationInfo,
        isFetching: isRegistrationInfoLoading,
        isError: registrationInfoError,
    } = useManagementQuery<AwardRegistrationInfo, ResponseError>(
        ['awardRegistration', awardListParticipantId],
        `AwardRegistration/${awardListParticipantId}`
    )

    const { data: contactTypes, isFetching: isContactTypesLoading, isError: contactTypesError } = useFetchContactTypes()
    const { data: states, isFetching: isStatesLoading, isError: statesError } = useFetchStates()
    const { data: sectors, isFetching: isSectorsLoading, isError: sectorsError } = useFetchSectors()
    const { data: zipCodes, isFetching: isZipCodesLoading, isError: zipCodesError } = useFetchZipCodes()
    const {
        data: ownershipTypes,
        isFetching: isLoadingOwnershipType,
        isError: ownershipError,
    } = useFetchOwnershipTypes()

    const isFetching =
        isRegistrationInfoLoading ||
        isContactTypesLoading ||
        isLoadingOwnershipType ||
        isStatesLoading ||
        isSectorsLoading ||
        isZipCodesLoading

    const error =
        registrationInfoError || contactTypesError || ownershipError || statesError || sectorsError || zipCodesError

    const { parentSectors, childSectors, sectorInfo } = getSectorInfo(
        registrationInfo?.organization,
        sectors as Sector[]
    ) as SectorDetails

    const getUpdatedStates = (states: State[] | undefined) =>
        map(states, (state) => ({
            ...state,
            stateId: state.id,
            id: state.abbreviation,
        }))

    return {
        isFetching,
        error,
        awardRegistrationInfo: registrationInfo as AwardRegistrationInfo,
        states: getUpdatedStates(states),
        sectors: sectors as Sector[],
        zipCodes: zipCodes as ZipCode[],
        ownershipTypes: ownershipTypes as OwnershipType[],
        contactTypes: contactTypes as ContactType[],
        parentSectors,
        childSectors,
        sectorInfo,
        hasFullyRemoteParticipation,
    }
}

export type AwardRegistrationData = ReturnType<typeof useFetchAwardRegistration>
