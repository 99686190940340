import { useManagementQuery } from 'api'
import { useIdentity } from 'components/Identity'
import showErrorAlert from 'util/showErrorAlert'

export default function useSetTestInviteesDemographics() {
    const { organizationId } = useIdentity()

    return useManagementQuery.mutate(
        async (_, api) => await api.postJson(`Organization/${organizationId}/SetUpTestInviteeDemographics`, []),
        {
            onError: (err) => {
                showErrorAlert(
                    "We've saved your changes, but there was an error updating your survey preview.",
                    err.parsed
                )
            },
        }
    )
}
