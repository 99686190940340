import { useState } from 'react'
import PropTypes from 'prop-types'
import { LegacyButton as Button, Loading } from '@energage/components'
import cx from 'clsx'
import { Link, withRouter } from 'react-router-dom'
import { AppLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import SimpleTooltip from 'components/SimpleTooltip'
import config from 'config'
import { routes } from 'constants/routes'
import SurveyType from 'constants/SurveyType'
import TaskDefinition from 'constants/taskDefinition'
import { usePost, usePostWithTaskCompletion } from 'hooks'
import HeaderTeleporter from '../HeaderTeleporter'
import PageTitle from '../PageTitle'
import PrintableLinksMessage from '../PrintableLinksMessage'
import { useSurveySetupContext } from '../SurveySetup'
import { SetupTask } from '../workflow'
import CustomizeSmsMessage from './CustomizeSmsMessage'
import FormatSelectTitle from './FormatSelectTitle'
import { useSurveyFormat } from './FormatSurvey'
import ConfigurePaperFormat from './FormatSurvey/PaperFormat'
import MissingRecipientsDetails from './MissingRecipientsDetails'
import RecipientPageDetails from './RecipientPageDetails'
import SmsAsInvitationOptionDialog from './SmsAsInvitationOptionDialog'
import useSmsInvitationSetup from './useSmsInvitationSetup'
import useSurveyInfo from './useSurveyInfo'
import WhitelistInformation from './WhitelistInformation'

const TASK_DEFINITION_IDS = [
    TaskDefinition.UploadEmailAddresses,
    TaskDefinition.ConfigureEmailDepartments,
    TaskDefinition.ConfirmRecipients,
]

const RecipientsConfirmation = ({ match, history }) => {
    const { surveyEventId } = match.params
    const { organizationId, surveyCompanyId, permissions, isSuperUser } = useIdentity()
    const {
        surveyHasLaunched: readOnly = true,
        getTaskStatus,
        getStatus,
        getTaskCompletedDateTime,
        refreshWorkflow,
        isTaskRelevant,
        surveyTypeId,
    } = useSurveySetupContext()

    const { data: formatData, methods, fetchingSurveyFormat, orderingPaperSurveys } = useSurveyFormat(surveyEventId)

    const hasAcceptedTerms = getTaskStatus(TaskDefinition.AcceptTerms)

    const {
        surveyScope,
        organizationMemberCount,
        surveyEventMemberCount,
        fetchingMembers,
        fetchMembers,
        targetDepartmentNames,
        surveyScopeSaveState,
        handleChangeWhoYouAreSurveying,
    } = useSurveyInfo(surveyEventId, organizationId)

    const {
        fetchingSmsConfiguration,
        isSmsInvitationEnabled,
        isSmsInvitationOptionSubmitted,
        skipEmailForSmsUsers,
        smsText,
        isSmsTextSubmitted,
        onChangeSmsText,
        onChangeSkipEmailForSmsUsers,
        onSubmitSmsText,
        onConfirmSmsInvitationOption,
        isSubmittingSmsText,
    } = useSmsInvitationSetup({
        surveyEventId,
        organizationId,
        refreshWorkflow,
    })

    const { doPost: scheduleSync } = usePost(`${config.api.platform}/organization/${organizationId}/startsurveysync`)

    const { doPost: updateTwpWorkflow, isPosting: isWorkflowUpdating } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/completeSurveyTasks`,
    })

    const surveyEventMemberCountEligibleForSms =
        surveyEventMemberCount.withPhoneNumberAndSmsConsentOnly +
        surveyEventMemberCount.withEmailPhoneNumberAndSmsConsent

    const isSmsTaskRelevant = isTaskRelevant(TaskDefinition.SmsSetup)
    const isSurveyScopeTaskRelevant = isTaskRelevant(TaskDefinition.SurveyScope)

    const allTasksComplete = getStatus(SetupTask.Recipients)?.status === 'complete'
    const pageStatus = getPageStatus(
        allTasksComplete,
        getTaskStatus,
        formatData,
        surveyEventMemberCount,
        isSmsTaskRelevant,
        isSurveyScopeTaskRelevant
    )

    const confirmRecipients = () => {
        Promise.all([methods.saveFormat(formatData.selectedFormat.id), updateTwpWorkflow(TASK_DEFINITION_IDS)]).then(
            () => {
                setHasConfirmedRecipients(true)
                try {
                    if (!permissions.hasUploadRecipientsAtSurveyEventLevel) {
                        scheduleSync({ surveyEventId })
                    }
                } catch {
                    // what to do?
                }
                refreshWorkflow()
            }
        )
    }

    const orderPaperSurveys = (paperInfo) => {
        methods.orderPaperSurvey(paperInfo).then(() => {
            refreshWorkflow()
            fetchMembers()
        })
    }

    const handleModifyRecipients = () =>
        history.push(routes.survey.setup.recipients.modify.departments({ surveyEventId }))

    const handleAddRecipients = () => history.push(routes.survey.setup.recipients.add({ surveyEventId }))

    const uploadEmployeesTaskDateTime = getTaskCompletedDateTime(TaskDefinition.UploadEmployees)
    const smsSetupTaskDateTime = getTaskCompletedDateTime(TaskDefinition.SmsSetup)
    const confirmRecipientsTaskDateTime = getTaskCompletedDateTime(TaskDefinition.ConfirmRecipients)

    const [hasConfirmedRecipients, setHasConfirmedRecipients] = useState(
        new Date(uploadEmployeesTaskDateTime) < new Date(confirmRecipientsTaskDateTime)
    )

    const isLoading = fetchingMembers || fetchingSurveyFormat || fetchingSmsConfiguration
    if (isLoading) {
        return (
            <Inset padding>
                <Loading />
            </Inset>
        )
    }

    const askIfShouldEnableSms =
        isSmsTaskRelevant &&
        (isSmsInvitationEnabled == null ||
            (!isSmsInvitationOptionSubmitted && new Date(smsSetupTaskDateTime) < new Date(uploadEmployeesTaskDateTime)))

    const hasSms = isSmsTaskRelevant && isSmsInvitationEnabled

    const hideFormatDropdown = surveyTypeId === SurveyType.Custom || !isSuperUser

    return (
        <Inset padding>
            {!hasAcceptedTerms ? (
                <div>
                    {`You must `}
                    <AppLink to={routes.terms()}>{`accept terms`}</AppLink>
                    {` before selecting a survey format.`}
                </div>
            ) : (
                <div>
                    <HeaderTeleporter.Source>
                        <PageTitle
                            border
                            title={
                                <FormatSelectTitle
                                    options={formatData.formats}
                                    value={formatData.selectedFormat}
                                    onChange={methods.setSelectedFormat}
                                    isDisabled={hideFormatDropdown}
                                    hideFormatDropdown={hideFormatDropdown}
                                />
                            }>
                            <SimpleTooltip
                                disabled={pageStatus.allTasksComplete}
                                placement="left"
                                trigger={
                                    <Button
                                        as={Link}
                                        to={routes.survey.setup.schedule({ surveyEventId })}
                                        variant="primary"
                                        disabled={!pageStatus.allTasksComplete || isWorkflowUpdating}>
                                        {'Next'}
                                    </Button>
                                }>
                                {pageStatus.tooltipText}
                            </SimpleTooltip>
                        </PageTitle>
                    </HeaderTeleporter.Source>
                    <div className="max-w-lg">
                        {formatData.selectedFormat.id === 4 && (
                            <div className="w-full pl-6">
                                <h5>{'Recipients'}</h5>
                                <PrintableLinksMessage surveyEventId={surveyEventId} />
                            </div>
                        )}
                        {askIfShouldEnableSms && (
                            <div className="pl-6">
                                <SmsAsInvitationOptionDialog
                                    inviteeCountWithPhoneNumberAndConsent={surveyEventMemberCountEligibleForSms}
                                    onConfirmSmsInvitationOption={onConfirmSmsInvitationOption}
                                />
                            </div>
                        )}
                        {!askIfShouldEnableSms &&
                            (formatData.selectedFormat.id === 2 || formatData.selectedFormat.id === 3) && (
                                <>
                                    {organizationMemberCount > 0 && (
                                        <RecipientPageDetails
                                            surveyScope={surveyScope}
                                            hasSms={hasSms}
                                            handleChangeWhoYouAreSurveying={handleChangeWhoYouAreSurveying}
                                            surveyScopeSaveState={surveyScopeSaveState}
                                            surveyEventMemberCount={surveyEventMemberCount}
                                            targetDepartmentNames={targetDepartmentNames}
                                            skipEmailForSmsUsers={skipEmailForSmsUsers}
                                            onChangeSkipEmailForSmsUsers={onChangeSkipEmailForSmsUsers}
                                            showModifyRecipients={permissions.hasSmartPulse}
                                            handleModifyRecipients={handleModifyRecipients}
                                            handleConfirmRecipients={confirmRecipients}
                                            hasConfirmedRecipients={hasConfirmedRecipients}
                                            confirmDisabled={
                                                //(!formatData.paperDetails.hasOrderedPaperSurveys &&
                                                //    formatData.selectedFormat.hasPaper) ||
                                                readOnly && !isSuperUser
                                            }
                                            isPosting={isWorkflowUpdating}
                                        />
                                    )}
                                    {organizationMemberCount === 0 && (
                                        <MissingRecipientsDetails handleAddEmployees={handleAddRecipients} />
                                    )}
                                    {hasSms && (
                                        <div className="pl-6">
                                            <CustomizeSmsMessage
                                                smsText={smsText}
                                                isSmsTextSubmitted={isSmsTextSubmitted}
                                                onChangeSmsText={onChangeSmsText}
                                                onSubmitSmsText={onSubmitSmsText}
                                                isSubmittingSmsText={isSubmittingSmsText}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        {(formatData.selectedFormat.id === 1 || formatData.selectedFormat.id === 2) && (
                            <div
                                className={cx(
                                    'pb-4 pl-6 w-full',
                                    !askIfShouldEnableSms && formatData.selectedFormat.id !== 1 && 'pt-6 border-b-2',
                                    askIfShouldEnableSms && 'border-t-2 pt-6 '
                                )}>
                                <ConfigurePaperFormat
                                    key={formatData.selectedFormat.id}
                                    {...formatData.paperDetails}
                                    paperOnly={formatData.selectedFormat.id === 1}
                                    onSubmit={orderPaperSurveys}
                                    isOrdering={orderingPaperSurveys}
                                />
                            </div>
                        )}
                        {!askIfShouldEnableSms &&
                            (formatData.selectedFormat.id === 2 || formatData.selectedFormat.id === 3) && (
                                <WhitelistInformation surveyCompanyId={surveyCompanyId} surveyEventId={surveyEventId} />
                            )}
                    </div>
                </div>
            )}
        </Inset>
    )
}

RecipientsConfirmation.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
}

export default withRouter(RecipientsConfirmation)

function getPageStatus(
    allTasksComplete,
    getTaskStatus,
    formatData,
    surveyEventMemberCount,
    isSmsTaskRelevant,
    isSurveyScopeTaskRelevant
) {
    const { selectedFormat } = formatData
    const hasSurveyMembers = surveyEventMemberCount.total !== 0
    const confirmRecipientsComplete = getTaskStatus(TaskDefinition.ConfirmRecipients)
    const orderedPaperSurveys = getTaskStatus(TaskDefinition.OrderPaperSurveys)
    const setUpSMS = getTaskStatus(TaskDefinition.SmsSetup)
    const selectSurveyScope = getTaskStatus(TaskDefinition.SurveyScope)

    if (allTasksComplete) {
        return {
            allTasksComplete,
            tooltipText: null,
        }
    }

    if (selectedFormat.hasPaper && !orderedPaperSurveys) {
        const finishOrderingText = 'Please finish ordering your paper surveys'
        return {
            allTasksComplete,
            tooltipText:
                selectedFormat.id === 1
                    ? finishOrderingText
                    : getTooltipText(finishOrderingText, 'and complete your paper survey order'),
        }
    }

    if (isSmsTaskRelevant && !setUpSMS) {
        return {
            allTasksComplete,
            tooltipText: getTooltipText('Please confirm your SMS message', 'and SMS message'),
        }
    }

    if (isSurveyScopeTaskRelevant && !selectSurveyScope) {
        return {
            allTasksComplete,
            tooltipText: getTooltipText('Please select who are you surveying', 'and who are you surveying'),
        }
    }

    return {
        allTasksComplete,
        tooltipText: getTooltipText(),
    }

    function getTooltipText(recipientsEmployeeConfirmedText = '', otherText = '') {
        if (!hasSurveyMembers) {
            return `Please add and confirm survey recipients ${otherText}`
        }

        if (!confirmRecipientsComplete) {
            return `Please confirm your recipients ${otherText}`
        }

        return recipientsEmployeeConfirmedText
    }
}
