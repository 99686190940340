import { useState } from 'react'
import { Checkbox, LegacyButton as Button } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'
import { TableCell } from 'components/Table'
import preventNonNumbers from 'util/preventNonNumbers'
import useSurveyCache from './useSurveyCache'

const TableRow = styled.div.attrs({
    className: 'text-xs flex pl-4 flex-wrap md:flex-no-wrap border-b last:b-0 border-grey300',
})`
    background-color: ${colors.white};
    :nth-child(even) {
        background-color: ${colors.grey100};
    }
`

const Table = ({ className, ...rest }) => (
    <div className={cx(className, 'w-full mt-16 border-grey300 border border-b-0')} {...rest} />
)

const NumberInput = styled.div`
    input[type='number'] {
        -moz-appearance: textfield;
        height: 40px;
        min-width: 120px;
        width: 100%;
        padding-left: 5px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const Admin = () => {
    const [surveyEventId, setSurveyEventId] = useState()
    const { clearCache, clearCacheSurveyEvent, loading, isLoading } = useSurveyCache(surveyEventId)
    const [cacheEnabled, setCacheEnabled] = useState(false)
    const validationMessage =
        'I understand that clearing the entire cache during peak hours (8am - 8pm Eastern US time) may lead to significant degradation of survey application performance'

    const handleClearCacheSurveyEventId = async () => {
        await clearCacheSurveyEvent()
    }

    return (
        <Table>
            <TableRow>
                <TableCell cellWidth="50%">
                    <div className="font-bold mb-2 mt-2">
                        <label>{'Clear survey cache for a single survey event'}</label>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell cellWidth="15%">
                    <NumberInput>
                        <input
                            onChange={(e) => setSurveyEventId(e.target.value)}
                            type="number"
                            placeholder={'Survey Event Id'}
                            onKeyPress={preventNonNumbers}
                        />
                    </NumberInput>
                </TableCell>
                <TableCell cellWidth="35%">
                    <Button
                        disabled={isLoading || !surveyEventId || surveyEventId === 0}
                        onClick={() => handleClearCacheSurveyEventId()}
                        style={{ marginLeft: 34 }}>
                        {'Clear Survey Event'}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell cellWidth="50%">
                    <div className="font-bold mb-2 mt-2">
                        <label>{'Clear survey cache for all companies'}</label>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell cellWidth="50%">
                    <Checkbox
                        inline
                        label={validationMessage}
                        className="py-2"
                        onChange={() => setCacheEnabled(!cacheEnabled)}
                    />
                    <Button
                        onClick={clearCache}
                        disabled={loading || !cacheEnabled}
                        variant="secondary"
                        style={{ marginLeft: 34 }}>
                        {'Clear Entire Cache'}
                    </Button>
                </TableCell>
            </TableRow>
        </Table>
    )
}

export default Admin
