import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useWorkplaceSurveyQuery } from 'api'
import useDownloadResponseRateByDepartment from 'containers/Main/Survey/MySurveys/useDownloadResponseRateByDepartment'
import SummaryCardSkeleton from '../Common/SummaryCardSkeleton'
import ResponseRateTile from './ResponseRateTile'

const ResponseRateTileFetch = (props) => {
    const { surveyEventId, surveyFormatId } = props
    const { download, isDownloading } = useDownloadResponseRateByDepartment(surveyEventId)

    const {
        data: liveResponseRate,
        isLoading,
        error,
    } = useWorkplaceSurveyQuery(
        ['survey-response-rate-summary', surveyEventId],
        `SurveyResponseRate/live/${surveyEventId}`
    )

    if (isLoading) {
        return <SummaryCardSkeleton />
    }

    const { inviteeCount, responderCount } = liveResponseRate || {}

    return (
        <div data-testid="survey-setup-summary-response-tile">
            {inviteeCount > 0 && (
                <ResponseRateTile
                    responseRate={(responderCount ?? 0) / inviteeCount}
                    error={error}
                    inviteeCount={inviteeCount}
                    responderCount={responderCount}
                    onDownload={isDownloading ? noop : download}
                    isDownloading={isDownloading}
                    surveyFormatId={surveyFormatId}
                />
            )}
        </div>
    )
}

ResponseRateTileFetch.propTypes = {
    surveyEventId: PropTypes.number.isRequired,
    surveyFormatId: PropTypes.number.isRequired,
}

export default ResponseRateTileFetch
