import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import config from 'config'
import { routes } from 'constants/routes'
import MediaKitInternalLetterModal from './MediaKitLite/MediaKitInternalLetterModal'

const Title = styled.b.attrs({ className: 'text-base' })`
    color: rgba(0, 20, 80, 1);
    line-height: 20px;
`

export const mediaKitLiteSections = (isPdf, awardDetails) => ({
    shareExcitingNews: [
        {
            label: 'Thank employees for participating in the Workplace Survey. This message is most powerful coming from senior leadership.',
        },
        { label: 'Announce your Top Workplaces win at an in-person or virtual company meeting.' },
        {
            label: (
                <>
                    {'Share the news in a company-wide communication. '}
                    {!isPdf && <MediaKitInternalLetterModal awardDetails={awardDetails} />}
                </>
            ),
        },
        { label: 'Feature your Top Workplaces win in your company newsletter.' },
        { label: 'Host a virtual or live happy hour celebration.' },
    ],
    announceYourTopWorkplaces: [
        {
            label: (
                <>
                    <Title>{'Prepare the press release: '}</Title>
                    {
                        "Using the template, add relevant information. Be sure to include a direct quote from your company's CEO, president, or other leader that reflects on the significance of the award."
                    }
                </>
            ),
        },
        {
            label: (
                <>
                    <Title>{'Distribute the press release: '}</Title>
                    {
                        'Newswire services automatically distribute your press release to print, broadcast, and online media in your region. Reputable services include Business Wire, PR Newswire, PRWeb and MarketWired. You can also make your news searchable on Google and other search engines.'
                    }
                </>
            ),
        },
        {
            label: (
                <>
                    <Title>{'Send an email to media contacts. '}</Title>
                    {
                        'Send to the general editorial email address of local media organizations or trade journals. Include a link to your press release rather than an attachment.'
                    }
                </>
            ),
        },
        {
            label: (
                <>
                    <Title>{'Post the press release on your company website. '}</Title>
                    {'Let your visitors see that your organization is a Top Workplaces winner!'}
                </>
            ),
        },
    ],
    writeABlogPost: [
        {
            label: (
                <>
                    {
                        'Reflect on why your company made the Top Workplaces list, including key highlights from the Workplace Survey results and '
                    }
                    <ExternalLink href={routes.insights()} target="_blank" className="text-xs font-bold text-blue500">
                        <b> {'Insights'} </b>
                    </ExternalLink>
                    {'.'}
                </>
            ),
        },
        {
            label: (
                <>
                    {
                        'Include employee comments captured by the survey that reflect what makes your culture so special and unique. These can be found in '
                    }
                    <ExternalLink href={routes.branding()} target="_blank" className="text-xs font-bold text-blue500">
                        <b> {'Employer Branding'} </b>
                    </ExternalLink>
                    {'.'}
                </>
            ),
        },
        {
            label: (
                <>
                    {'Link to the Top Workplaces winners’ list found on '}
                    <ExternalLink
                        href={config.urls.topWorkplaces}
                        target={config.urls.topWorkplaces}
                        className="text-blue500">
                        {'www.topworkplaces.com'}
                    </ExternalLink>
                    {'.'}
                </>
            ),
        },
        {
            label: (
                <>
                    {'Link to your company profile on '}
                    <ExternalLink
                        href={config.urls.topWorkplaces}
                        target={config.urls.topWorkplaces}
                        className="text-blue500">
                        {'www.topworkplaces.com'}
                    </ExternalLink>
                    {'.'}
                </>
            ),
        },
    ],
})
