import { HAS_AGREED } from 'containers/Main/EmployerRecognition/Shared/AwardSelectionBox/constants'
import { getDefaultLocation, setIndex } from 'containers/Main/EmployerRecognition/utils'
import type { AwardRegistrationInfo, SectorInfo } from './awardRegistration.types'

export const getDefaultValues = (
    {
        organization,
        region,
        hasAgreedToEligibilityCriteria,
        locations,
        primaryContact,
        publisher: { isNational },
        leaderContact,
        ...registrationInfo
    }: AwardRegistrationInfo,
    sectorInfo: SectorInfo,
    hasFullyRemoteParticipation: boolean,
    isRemoteWorkAward: boolean,
    isWomenLedAward: boolean
) => ({
    isDraft: false,
    isLocationFormEmpty: locations.length === 0,
    publisher: {
        isNational,
    },
    awardListParticipantId: registrationInfo.awardListParticipantId,
    awardId: registrationInfo.awardId,
    salesforceYear: registrationInfo.salesforceYear,
    hasFullyRemoteParticipation: hasFullyRemoteParticipation,
    organization: {
        companyName: organization.name,
        yearFounded: organization.yearFounded,
        ownershipTypeId: organization.ownershipTypeId ? organization.ownershipTypeId : undefined,
        ...sectorInfo,
        isSectorApproved: !!organization.isSectorApproved,
    },
    locationStatus: registrationInfo.locationStatus,
    locations: locations.length === 0 ? [getDefaultLocation(isNational)] : setIndex(locations),
    primaryContact: {
        contactId: primaryContact?.id,
        contactTypeId: primaryContact?.typeId ? primaryContact?.typeId : undefined,
        firstName: primaryContact?.firstName,
        lastName: primaryContact?.lastName,
        title: primaryContact?.title,
        emailAddress: primaryContact?.emailAddress,
        phoneNumber: primaryContact?.phoneNumber,
    },
    leaderContact: {
        contactId: leaderContact?.id || null,
        contactTypeId: leaderContact?.typeId || null,
        firstName: leaderContact?.firstName || null,
        lastName: leaderContact?.lastName || null,
        title: leaderContact?.title || null,
        emailAddress: leaderContact?.emailAddress || null,
    },
    hasAgreedToEligibilityCriteria: hasAgreedToEligibilityCriteria,
    isRemoteWorkAward: isRemoteWorkAward,
    isWomenLedAward: isWomenLedAward,
    remoteWorkAward: {
        confirmRemoteAwardChkOne: isRemoteWorkAward && hasAgreedToEligibilityCriteria,
        confirmRemoteAwardChkTwo: isRemoteWorkAward && hasAgreedToEligibilityCriteria,
    },
    womenLedAward: {
        confirmWomenLedAwardChk: isWomenLedAward && hasAgreedToEligibilityCriteria ? HAS_AGREED : undefined,
    },
})
