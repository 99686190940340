/* eslint-disable no-template-curly-in-string */
import { useCallback } from 'react'
import { LegacyButton as Button, Modal } from '@energage/components'
import partition from 'lodash/partition'
import sort from 'lodash/sortBy'
import template from 'lodash/template'
import type { DialogStateReturn } from 'reakit/Dialog'
import styled from 'styled-components'
import { Spacer } from 'components'
import { monthDayDate } from 'util/formatters'
import { AwardDeadlineList } from './AwardDeadlineList'
import type {
    AwardChangeList,
    AwardChangeListWithSurveyName,
    UsedOnAnotherSurveyEventWithName,
} from './AwardScheduleChangeConfirmation.types'
import { AwardsUnavailableList } from './AwardsUnavailableList'

const textTemplates = {
    forwards: {
        single: template(
            '${publisher} participation has been moved from ${beforeChangeYear} to ${afterChangeYear}. To confirm this change, please select confirm.'
        ),
        multiple: template(
            'The following awards have been moved to the ${afterChangeYear} participation year. To confirm these changes, please select confirm.'
        ),
    },
    backwards: {
        single: template(
            '${publisher} has been moved to ${afterChangeYear}. Your survey must have a qualifying response rate of 35% by ${listCloseDate}. To confirm this change, please select confirm.'
        ),
        multiple: template(
            'The following awards have been moved to the ${afterChangeYear} participation year${yearPlural}. Your survey must have a qualifying response rate of 35% by ${listCloseDate}. To confirm these changes, please select confirm.'
        ),
    },
} as const

function determineAwardShiftDirection(awards: AwardChangeList): keyof typeof textTemplates {
    const awardsMovedForward = awards.every(({ currentYear, newYear }) => newYear && newYear >= currentYear)

    if (awardsMovedForward) {
        return 'forwards'
    }

    return 'backwards'
}

function getParagraphText(awards: AwardChangeList, firstDeadline?: string | Date) {
    const dir = determineAwardShiftDirection(awards)

    if (awards.length > 1) {
        const newYears = Array.from(new Set(awards.map((a) => a.newYear))).sort()
        const hasMultipleYears = newYears.length > 1
        return textTemplates[dir].multiple({
            beforeChangeYear: awards[0].currentYear,
            afterChangeYear: hasMultipleYears ? `${newYears[0]} and ${newYears[1]}` : awards[0].newYear,
            listCloseDate: monthDayDate(firstDeadline),
            yearPlural: hasMultipleYears ? 's' : '',
        })
    }

    const award = awards[0]

    return textTemplates[dir].single({
        publisher: award.awardName,
        beforeChangeYear: award.currentYear,
        afterChangeYear: award.newYear,
        listCloseDate: monthDayDate(firstDeadline),
    })
}

const StyledModal = styled(Modal)`
    .reakit-modal {
        max-width: 520px;
    }
`

type AwardScheduleChangeConfirmationProps = {
    onClose?: () => void
    onConfirm: () => void
    awards: AwardChangeList
    firstDeadline?: Date | string
    dialogState: DialogStateReturn
    surveys: Array<{ name: string; id: number }>
}

function AvailableAwardsContent({
    availableAwards,
    firstDeadline,
}: {
    availableAwards: AwardChangeListWithSurveyName
    firstDeadline: AwardScheduleChangeConfirmationProps['firstDeadline']
}) {
    const paragraphText = getParagraphText(availableAwards, firstDeadline)

    return (
        <>
            <span className="h5 mb-3 block">{'Are you sure you want to change your schedule?'}</span>
            <p>{paragraphText}</p>
            {availableAwards.length > 1 ? (
                <AwardDeadlineList awards={sort(availableAwards, 'deadline', 'asc')} />
            ) : null}
        </>
    )
}

export function AwardScheduleChangeConfirmation({
    onClose,
    onConfirm,
    awards,
    firstDeadline,
    dialogState,
    surveys,
}: AwardScheduleChangeConfirmationProps) {
    const awardsWithSurveyName = awards.map((award) => {
        const { newAwardNotAvailableInfo } = award

        if (!newAwardNotAvailableInfo || newAwardNotAvailableInfo.reason !== 1) {
            return award
        }

        const notAvailableInfoWIthSurveyName: UsedOnAnotherSurveyEventWithName = {
            name: surveys.find((s) => s.id === newAwardNotAvailableInfo.surveyEventId)?.name,
            reason: newAwardNotAvailableInfo.reason,
            surveyEventId: newAwardNotAvailableInfo.surveyEventId,
        }

        return {
            ...award,
            newAwardNotAvailableInfo: notAvailableInfoWIthSurveyName,
        }
    })

    const [availableAwards, unavailableAwards] = partition(
        awardsWithSurveyName,
        (award) => !award.newAwardNotAvailableInfo
    )

    const { hide } = dialogState

    const onHide = useCallback(() => {
        onClose?.()
        hide()
    }, [onClose, hide])

    return (
        <StyledModal modalAriaLabel="Survey Launch Date" {...dialogState} trigger={<></>} className="overflow-hidden">
            <Modal.Header hasDivider onHide={onHide}>
                <span className="h5">{'Warning!'}</span>
            </Modal.Header>
            <Modal.Body>
                {availableAwards.length > 0 ? (
                    <AvailableAwardsContent firstDeadline={firstDeadline} availableAwards={availableAwards} />
                ) : null}
                {unavailableAwards.length > 0 ? <AwardsUnavailableList awards={unavailableAwards} /> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" outline onClick={onHide}>
                    {'Cancel'}
                </Button>
                <Spacer axis="horizontal" size={16} />
                <Button variant="danger" onClick={onConfirm}>
                    {'Confirm'}
                </Button>
            </Modal.Footer>
        </StyledModal>
    )
}
