/* eslint-disable */
// https://gist.github.com/8HNHoFtE/5891086
window.getComputedStyle ||
    (window.getComputedStyle = function (e, t) {
        return (
            (this.el = e),
            (this.getPropertyValue = function (t) {
                var n = /(\-([a-z]){1})/g
                return (
                    t == 'float' && (t = 'styleFloat'),
                    n.test(t) &&
                        (t = t.replace(n, function () {
                            return arguments[2].toUpperCase()
                        })),
                    e.currentStyle[t] ? e.currentStyle[t] : null
                )
            }),
            this
        )
    })
