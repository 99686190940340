import { LegacyButton } from '@energage/components'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from 'constants/routes'
import { DEFAULT_DATE } from 'containers/Main/AwardAnnouncement/constants'
import type { AfterAwardPublishedPointsType } from 'containers/Main/EmployerRecognition/Overview/Timeline/awardTimeline.types'
import bannerFive from 'images/awardAnnouncement/points/bannerFive.jpg'
import pointFiveIcon from 'images/awardAnnouncement/points/pointFiveIcon.svg'
import pointSixIcon from 'images/awardAnnouncement/points/pointSixIcon.svg'
import { dateToObject, fullMonthDayYearDate, parseDateOnly, shortMonthYearDate } from 'util/formatters'

const StyledImg = styled.img.attrs({})`
    object-fit: contain;
`

const points = (isPdf: boolean | undefined, baseUrl: string | undefined, awardAliasNameWithoutYear: string) => ({
    five: {
        heading: 'Recruit with Top Workplaces ',
        content: (
            <span>
                {`Talented people want to work at great companies. Include ${awardAliasNameWithoutYear} award logo in your job postings and recruiting materials. `}
                <LegacyButton
                    as={Link}
                    variant="link"
                    className={cx('font-light text-blue600 p-0', { 'text-xl': !isPdf, 'text-base': isPdf })}
                    target="_blank"
                    to={routes.branding()}
                    rel="noreferrer">
                    {'Recruit with Employer Branding'}
                </LegacyButton>
            </span>
        ),
    },
    six: {
        heading: 'Issue a news release',
        content:
            'Utilize the comprehensive press release template provided within the Media Kit to effectively announce your well-deserved victory to the world!',
    },
})

export const AfterAwardPublishedPoints = ({
    publicationDate,
    isApproxPublishDate,
    baseUrl,
    isPdf,
    awardAliasNameWithoutYear,
}: AfterAwardPublishedPointsType) => {
    const {
        five: { heading: headingFive, content: contentFive },
        six: { heading: headingSix, content: contentSix },
    } = points(isPdf, baseUrl, awardAliasNameWithoutYear)

    const formattedPublicationDate = publicationDate
        ? isApproxPublishDate
            ? shortMonthYearDate(parseDateOnly(dateToObject(publicationDate)))
            : fullMonthDayYearDate(parseDateOnly(dateToObject(publicationDate)))
        : DEFAULT_DATE

    return (
        <div className={cx('flex flex-col-reverse', { 'text-xl sm:flex-row pt-20': !isPdf, 'py-10': isPdf })}>
            <div
                className={cx('flex', {
                    'sm:w-2/5 w-full sm:-mb-24 sm:-ml-5 p-16 pt-5 sm:p-0': !isPdf,
                    'justify-center p-1 py-10': isPdf,
                })}>
                <StyledImg
                    className={cx({ 'w-full': !isPdf, 'w-3/5': isPdf })}
                    src={bannerFive}
                    alt="Announcement point four and five."
                    aria-hidden
                />
            </div>
            <div className={cx('items-center flex', { 'sm:w-3/5 w-full mb-10': !isPdf })}>
                <div>
                    <h4 className="px-5 sm:px-16 pb-6 text-3xl">{`After your award is published on ${formattedPublicationDate} you can...`}</h4>
                    <div className="flex self-center px-3 sm:px-10 pb-6">
                        <div className="px-3">
                            <img src={pointFiveIcon} alt="Announcement point banner one" aria-hidden />
                        </div>
                        <div className={cx({ 'w-3/4': !isPdf, 'w-full': isPdf })}>
                            <h4
                                className={cx({ 'text-2xl': isPdf, 'text-3xl': !isPdf })}
                                aria-label={`Step 5: ${headingFive}`}>
                                {headingFive}
                            </h4>
                            <p className={cx('font-normal', { 'text-xl': !isPdf, 'text-base': isPdf })}>
                                {contentFive}
                            </p>
                        </div>
                    </div>
                    <div className="flex self-center px-3 sm:px-10">
                        <div className="px-3">
                            <img src={pointSixIcon} alt="Announcement point banner one" aria-hidden />
                        </div>
                        <div className={cx({ 'w-3/4': !isPdf, 'w-full': isPdf })}>
                            <h4
                                className={cx({ 'text-2xl': isPdf, 'text-3xl': !isPdf })}
                                aria-label={`Step 6: ${headingSix}`}>
                                {headingSix}
                            </h4>
                            <p className={cx('font-normal', { 'text-xl': !isPdf, 'text-base': isPdf })}>{contentSix}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
