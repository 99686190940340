interface Data {
    [key: string | number | symbol]: string
    name: string
}

export const DEFAULT = 0
export const ASC = 1
export const DESC = 2

export const sortData = (order: number, columnName: string, data: Data[]) => {
    const array = data.sort((a, b) => {
        const valueA: string | number = (order === ASC ? a[columnName] : b[columnName]) ?? 0,
            valueB: string | number = (order === ASC ? b[columnName] : a[columnName]) ?? 0

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueA - valueB
        } else {
            return valueA.localeCompare(valueB, undefined, {
                numeric: true,
                sensitivity: 'base',
            })
        }
    })
    return array
}
