import { useMemo } from 'react'
import type { ReactNode } from 'react'
import { colors } from '@energage/theme'
import cx from 'clsx'
import map from 'lodash/map'
import { useTable } from 'react-table'
import type { Cell, Column, HeaderGroup, Row } from 'react-table'
import styled from 'styled-components'
import type { AwardSelectionTableProps, AwardSelectionWithRHFKey } from '../awardselection.types'

const IS_TENTATIVE = 'isTentative'
const SCHEDULE_CLOSE_DATE = 'scheduledCloseDate'

const StickyTHead = styled.thead`
    position: sticky;
    box-shadow: -1px 1px ${colors.grey300};
`

const RoundIndicator = ({ className }: { className?: string }) => (
    <div
        className={cx(
            'rounded-full h-5 w-20 text-black align-middle text-center -ml-10 font-bold',
            className
        )}>{`Tentative`}</div>
)

type LegendItemProps = {
    indicator: ReactNode
}

const LegendItem = ({ indicator }: LegendItemProps) => <div className="text-xs align-middle">{indicator}</div>

type TableData = AwardSelectionTableProps['awards'][0]
type AwardSelectionTableDesktopProps = AwardSelectionTableProps & {
    columns: Column<TableData>[]
}

const showIsTentativeIcon = (cell?: Cell<AwardSelectionWithRHFKey>) =>
    cell?.column?.id === IS_TENTATIVE && cell?.value === true

export function AwardSelectionTableDesktop({ className, awards, columns }: AwardSelectionTableDesktopProps) {
    const data = useMemo(() => awards, [awards])

    const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable<TableData>({
        columns,
        data,
    })

    return (
        <div className={cx(className, 'max-w-full shadow-md border border-grey300 rounded overflow-y-auto relative')}>
            <table
                {...getTableProps({
                    className: 'bg-white w-full',
                })}>
                <StickyTHead className="top-0 bg-white">{map(headerGroups, renderHeaderGroup)}</StickyTHead>
                <tbody {...getTableBodyProps()}>
                    {map(rows, (row) => {
                        prepareRow(row)
                        return renderRow(row)
                    })}
                </tbody>
            </table>
        </div>
    )
}

function renderHeaderGroup(headerGroup: HeaderGroup<TableData>) {
    const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps({
        className: 'border-b border-grey300',
    })

    return (
        <tr key={headerGroupKey} {...headerGroupProps}>
            {map(headerGroup.headers, (column) => {
                const { key: headerKey, ...headerProps } = column.getHeaderProps({
                    className: 'p-3 text-left paragraph-small font-bold h-full',
                })

                return (
                    <th key={headerKey} {...headerProps} colSpan={column.id === SCHEDULE_CLOSE_DATE ? 2 : 0}>
                        {column.render('Header')}
                    </th>
                )
            })}
        </tr>
    )
}

function renderRow(row: Row<TableData>) {
    const { key: rowKey, ...rowProps } = row.getRowProps({
        className: 'bg-white border-t border-grey300',
    })
    return (
        <tr key={rowKey} {...rowProps}>
            {map(row.cells, (cell) => {
                const { key: cellKey, ...cellProps } = cell.getCellProps({
                    className: 'px-3 pt-5 pb-3 paragraph-small align-top',
                    style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        width: cell.column.width,
                    },
                })
                return (
                    <td key={cellKey} {...cellProps}>
                        {cell.render('Cell')}
                        {showIsTentativeIcon(cell) && (
                            <LegendItem indicator={<RoundIndicator className={'bg-purple100'} />} />
                        )}
                    </td>
                )
            })}
        </tr>
    )
}
