import styled from 'styled-components'
import type { BgContainerType } from './awardAnnouncement.types'

export const BgContainer = styled.div<BgContainerType>`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    background-image: ${(props) => props.backgroundImage};
    font-family: 'Libre Franklin', sans-serif;
    min-height: ${(props) => props.pdfBgHeight || (props.isDefaultBgHeight ? '1122px' : '')};
`
