import { LegacyButton as Button, Loading as Spinner } from '@energage/components'
import { useVisibility } from '@energage/hooks'
import { Icon } from '@energage/icons'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import { Modal } from 'components/Modals'
import { TWP_USA_PUBLISHER_ID, YEAR_2024 } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { ReactComponent as DownloadIcon } from 'images/employerBranding/mediaKit/fileDownloadArrow.svg'
import withClassName from 'style/util/withClassName'
import { UsaTodayInternalLetterModal } from './UsaTodayInternalLetterModal'
import { useDownloadInternalLetter } from './useDownloadInternalLetter'

const ModalBackground = styled.div.attrs({ className: 'overflow-y-auto w-full' })`
    border-radius: 5.07px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    max-height: 65vh;
    scrollbar-width: 16px;
`
const Paragraph = withClassName('mt-5', 'p')

const MediaKitInternalLetterModal = ({ awardDetails }) => {
    const { download, isDownloading } = useDownloadInternalLetter(awardDetails)
    const { visible, show, hide } = useVisibility()

    return (
        <>
            <ExternalLink className="text-base font-bold text-blue500" onClick={show}>
                <b>{"Here's a sample template."}</b>
            </ExternalLink>
            <Modal
                className="w-4/5 sm:overflow-y-hidden h-full"
                title="Internal Announcement Template"
                isOpen={visible}
                onClose={hide}>
                <div className="p-4">
                    <label className="w-full">{'Internal Announcement Preview'}</label>
                    <ModalBackground>
                        {awardDetails?.publisherId === TWP_USA_PUBLISHER_ID && awardDetails?.year >= YEAR_2024 ? (
                            <UsaTodayInternalLetterModal />
                        ) : (
                            <div className="px-5 py-5 text-grey500">
                                <p>{'Hi {FIRST NAME},'}</p>
                                <Paragraph>
                                    {
                                        "I have something exciting to share — {Organization Name} has been named a Top Workplace in {Publisher Name} Top Workplaces! The list will be announced on {Publication Date} in a special section of The {Publication Name}. Please keep this news to yourself until the publication date. After that, you're free to tell the world."
                                    }
                                </Paragraph>
                                <Paragraph>
                                    {
                                        'The award is based solely on the feedback many of you offered through the employee survey we administered just a few months ago. More than {# of Employee Responders} of you participated, and I want to thank you for your input.'
                                    }
                                </Paragraph>
                                <Paragraph>
                                    {
                                        "Being named a Top Workplace is a big deal — only {# of Named Top Workplaces} companies made this year's list. I'm honored, especially because the judges were you, our employees!"
                                    }
                                </Paragraph>
                                <Paragraph>{'Best,'}</Paragraph>
                                <p>{'{Signature}'}</p>
                            </div>
                        )}
                    </ModalBackground>
                    <div className="flex float-left p-2 pl-8 xl:pl-8">
                        <Button className="font-medium" disabled={isDownloading} onClick={download}>
                            <Icon as={DownloadIcon} size={14} className="mr-4" />
                            {'Download'}
                            {isDownloading && <Spinner className="ml-5" size="small" text="" inline />}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default MediaKitInternalLetterModal
