import uniqBy from 'lodash/uniqBy'
import { useQuery, useQueryClient } from 'react-query'
import type { QueryFunctionContext, UseQueryOptions } from 'react-query'
import { workplaceSurvey } from 'api'
import type { DemographicQuestionView, Theme } from '../AddQuestions/AddQuestions.types'

export type DemographicQuestionViewWithTheme = DemographicQuestionView & { themeIds: number[] }
function getDemographicQuestionsWithThemes(questions: DemographicQuestionView[]) {
    const themes: Theme[] = []
    const questionsWithFakeTheme: DemographicQuestionViewWithTheme[] = []

    for (const question of questions) {
        questionsWithFakeTheme.push({ ...question, themeIds: [question.demographicTypeId] })
        themes.push({ id: question.demographicTypeId, name: question.demographicTypeName })
    }

    return {
        questions: questionsWithFakeTheme,
        themes: uniqBy(themes, 'id'),
    }
}

async function fetcher(context: QueryFunctionContext<['demographicQuestions', number, number]>) {
    const companyId = context.queryKey[1]
    const surveyEventId = context.queryKey[2]

    const qs = await workplaceSurvey
        .get(`surveycompanies/${companyId}/surveydefinition/${surveyEventId}/getDemographicQuestionBank`)
        .json<DemographicQuestionView[]>()

    return getDemographicQuestionsWithThemes(qs)
}

export function useGetDemographicQuestionBankQuestions(
    companyId: number,
    surveyEventId: number,
    opts: UseQueryOptions<unknown, unknown, unknown, ['demographicQuestions', number, number]> = {}
) {
    return useQuery(['demographicQuestions', companyId, surveyEventId], fetcher, opts)
}

export function useDemographicQuestionBankQuestionsCacheInvalidate(companyId: number, surveyEventId: number) {
    const queryClient = useQueryClient()
    return function invalidate() {
        queryClient.invalidateQueries(['demographicQuestions', companyId, surveyEventId])
    }
}
