import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Cookies from 'js-cookie'
import { stringify } from 'query-string'
import config from 'config'
import store, { selectAccessToken } from 'store'

const queryParams = stringify({
    impersonationKey: Cookies.get('impersonationKey'),
})

const signalRConnection = new HubConnectionBuilder()
    .withUrl(`${config.api.platform}/notifications?${queryParams}`, {
        accessTokenFactory: () => selectAccessToken(store.getState()),
    })
    .configureLogging(LogLevel.Error)
    .withAutomaticReconnect()
    .build()

export default signalRConnection
