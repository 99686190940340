import { LegacyButton } from '@energage/components'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import type { AnnouncementPointsType } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { BgContainer } from 'containers/Main/AwardAnnouncement/BgContainer'
import { DEFAULT_DATE } from 'containers/Main/AwardAnnouncement/constants'
import { PageBreaker } from 'containers/Public/AwardAnnouncement/AwardAnnouncementPdf'
import bannerFour from 'images/awardAnnouncement/points/bannerFour.svg'
import bannerOnePdf from 'images/awardAnnouncement/points/bannerOne.jpg'
import bannerOne from 'images/awardAnnouncement/points/bannerOne.svg'
import bannerThreePdf from 'images/awardAnnouncement/points/bannerThree.jpg'
import bannerThree from 'images/awardAnnouncement/points/bannerThree.svg'
import bannerTwo from 'images/awardAnnouncement/points/bannerTwo.svg'
import pointFourIcon from 'images/awardAnnouncement/points/pointFourIcon.svg'
import pointOneIcon from 'images/awardAnnouncement/points/pointOneIcon.svg'
import pointThreeIcon from 'images/awardAnnouncement/points/pointThreeIcon.svg'
import pointTwoIcon from 'images/awardAnnouncement/points/pointTwoIcon.svg'
import { dateToObject, fullMonthDayYearDate, parseDateOnly } from 'util/formatters'
import { AfterAwardPublishedPoints } from './AfterAwardPublishedPoints'
import { Point } from './Point'

export const AnnouncementPoints = ({
    isPdf,
    profileDeadlineDate,
    pressReleaseDate,
    publisherName,
    isApproxPublishDate,
    publicationDate,
    hasPublisherProvidedMediaKit,
    paidMediaKit,
    awardAliasNameWithoutYear,
    year,
}: AnnouncementPointsType) => {
    const gainAccessTextDefault = (
        <>
            <span>
                {`Show off your win by adding ${awardAliasNameWithoutYear} award logo to your website, recruiting
                            materials, social media, and company profiles. Your Media Kit will be available on `}
            </span>
            <span className="font-bold">
                {`${
                    pressReleaseDate
                        ? `${fullMonthDayYearDate(parseDateOnly(dateToObject(pressReleaseDate)))}`
                        : DEFAULT_DATE
                }.`}
            </span>
        </>
    )

    const determineAccessText = () => {
        if (hasPublisherProvidedMediaKit) {
            return `Show off your win by adding ${awardAliasNameWithoutYear} to your website, recruiting
            materials, social media, and company profiles. In coming days, expect ${publisherName} to be in touch with you about how they will celebrate your achievement as well sales and marketing opportunities to amplify your award.`
        }
        if (paidMediaKit) {
            return `Show off your win by adding ${awardAliasNameWithoutYear} award logo to your website, recruiting
            materials, social media, and company profiles. Some award assets in the media kit must be purchased in order to access.`
        } else {
            return gainAccessTextDefault
        }
    }

    const Header = {
        sectionName: `Now that you're a winner for ${year} ${awardAliasNameWithoutYear}, `,
        subText: 'here are some next steps to make the most of your award  before the award is published',
        points: {
            one: {
                heading: (
                    <>
                        <span className="sr-only">{'Step 1: '}</span>
                        {'Thank your employees'}
                    </>
                ),
                content: `${awardAliasNameWithoutYear} awards are determined through employee feedback captured through the Energage Workplace Survey. Take this opportunity to thank your employees.`,
            },
            two: {
                heading: (
                    <>
                        <span className="sr-only">{'Step 2: '}</span>
                        {'Show what makes your company unique'}
                    </>
                ),
                content: (
                    <span>
                        {`${awardAliasNameWithoutYear} award celebrates your organization as an employer of choice. Show WHY your organization is a Top Workplace by using Employer Branding Culture Facts to showcase what makes your organization stand out.`}
                        <LegacyButton
                            as={Link}
                            variant="link"
                            className={cx('font-normal text-blue600 p-0', { 'text-xl': !isPdf, 'text-base': isPdf })}
                            target="_blank"
                            to={routes.branding()}
                            rel="noreferrer">
                            {' Check out Employer Branding'}
                        </LegacyButton>
                    </span>
                ),
            },
            three: {
                heading: (
                    <>
                        <span className="sr-only">{'Step 3: '}</span>
                        {'Update your Top Workplaces Profile'}
                    </>
                ),
                content: (
                    <>
                        <span>
                            {`Your Top Workplaces profile is a great opportunity to stand out among other Top Workplaces. Show off what makes your company unique. Make sure to update your profile by `}
                        </span>
                        <span className="font-bold">
                            {profileDeadlineDate
                                ? `${fullMonthDayYearDate(parseDateOnly(dateToObject(profileDeadlineDate)))}`
                                : DEFAULT_DATE}
                        </span>
                        <span>
                            {`. This information will be published on TopWorkplaces.com and provided to the media partner. `}
                            <LegacyButton
                                as={Link}
                                variant="link"
                                className={cx('font-normal text-blue600 p-0', {
                                    'text-xl': !isPdf,
                                    'text-base': isPdf,
                                })}
                                target="_blank"
                                to={routes.profile()}
                                rel="noreferrer">
                                {'Update your Top Workplaces Profile'}
                            </LegacyButton>
                        </span>
                    </>
                ),
            },
            four: {
                heading: (
                    <>
                        <span className="sr-only">{'Step 4: '}</span> {'Gain access to the award logo'}
                    </>
                ),
                content: determineAccessText(),
            },
        },
    }

    const {
        sectionName,
        subText,
        points: { one, two, three, four },
    } = Header

    const bannerOneImg = isPdf ? bannerOnePdf : bannerOne
    const bannerThreeImg = isPdf ? bannerThreePdf : bannerThree

    return (
        <>
            <BgContainer className={cx({ 'bg-yellow100': isPdf })} {...(isPdf && { isDefaultBgHeight: true })}>
                <div className={cx('text-center bg-white', { 'pt-12 sm:pb-12 px-12': !isPdf, 'py-5 px-16': isPdf })}>
                    <h3 className={cx({ 'text-3xl': isPdf, 'text-2xl lg:text-3xl mb-2': !isPdf })}>
                        {sectionName}
                        <br />
                        <span className="font-normal text-xl">{subText}</span>
                    </h3>
                </div>
                <Point sideImg={bannerOneImg} numberIcon={pointOneIcon} {...one} isPdf={isPdf} />
                <Point sideImg={bannerTwo} numberIcon={pointTwoIcon} {...two} isPdf={isPdf} bgColor />
            </BgContainer>
            {isPdf && <PageBreaker />}
            <BgContainer className={cx({ 'bg-yellow100': isPdf })} {...(isPdf && { isDefaultBgHeight: true })}>
                <Point
                    sideImg={bannerThreeImg}
                    numberIcon={pointThreeIcon}
                    {...three}
                    isPdf={isPdf}
                    pdfSpace={true}
                    imgXPadding
                />
                {!isPdf && <hr className="border-0 py-8 sm:py-0" />}
                <Point
                    sideImg={bannerFour}
                    numberIcon={pointFourIcon}
                    {...four}
                    isPdf={isPdf}
                    bgColor
                    pdfSpace={true}
                />
            </BgContainer>
            {isPdf && <PageBreaker />}
            <AfterAwardPublishedPoints
                publicationDate={publicationDate}
                isApproxPublishDate={isApproxPublishDate}
                awardAliasNameWithoutYear={awardAliasNameWithoutYear}
                isPdf={isPdf}
            />
        </>
    )
}
