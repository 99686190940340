import { colors } from '@energage/theme'
import { format } from 'date-fns'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import styled from 'styled-components'
import { currency } from 'util/formatters'

const TERM_DATE_FORMAT = 'MMM dd, yyyy'

const Table = styled.div`
    > div {
        padding: 3px 10px 3px 15px;
    }
    > div:nth-child(odd) {
        background-color: ${colors.grey200};
    }
`

const renderProduct = ({ productId, productName, name, ...product }) => (
    <div className="w-full" key={productId}>
        <div className="w-3/4 inline-block">{productName || name}</div>
        <div className="w-1/4 inline-block text-right">{`${currency(product.overridePrice)}`}</div>
    </div>
)

const TableHeader = ({ title, children }) => (
    <div className="w-3/4 inline-block">
        <span className="font-bold mr-6">{title}</span>
        {children}
    </div>
)

const AnnualPlan = ({
    title,
    termDuration,
    platformProducts,
    totalAnnualCost,
    autoRenewEnabled,
    oneTimeAddOns = {},
}) => {
    if (isEmpty(get(platformProducts, 'products'))) {
        return null
    }

    const { label, start, end } = termDuration
    const { heading, employees, products, costPeriod } = platformProducts
    const { heading: addOnHeading, addOns } = oneTimeAddOns

    return (
        <>
            <h3 className="font-bold mb-2">{title}</h3>
            <div className="w-full px-2 py-1">
                <div className="w-3/4 inline-block">
                    {`${label}: ${format(start, TERM_DATE_FORMAT)} - ${format(end, TERM_DATE_FORMAT)}`}
                    {!isUndefined(autoRenewEnabled) && ` (Auto-Renew ${autoRenewEnabled ? 'Enabled' : 'Disabled'})`}
                </div>
            </div>
            <Table>
                <div className="w-full">
                    <TableHeader title={heading}>
                        <span className="inline-block">
                            <span className="mr-6 font-bold">{`${employees} Employees`}</span>
                        </span>
                    </TableHeader>
                    <div className="w-1/4 inline-block text-right">{costPeriod}</div>
                </div>
                {map(products, renderProduct)}
                {!isEmpty(addOns) && (
                    <>
                        <div className="w-full">
                            <TableHeader title={addOnHeading} />
                        </div>
                        {map(addOns, renderProduct)}
                    </>
                )}
            </Table>
            <div className="flex sm:justify-end px-2 py-1 font-bold">
                <div className="w-full sm:w-1/3 flex justify-between">
                    {'Total Annual Cost:'}
                    <span>{`${totalAnnualCost}`}</span>
                </div>
            </div>
        </>
    )
}

export default AnnualPlan
