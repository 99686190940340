import groupBy from 'lodash/groupBy'
import colors from 'containers/Main/AwardAnnouncement/colors'
import {
    AWARD_CATEGORY,
    COMPANY_PROFILE_URL,
    FAQ_URL,
    MERCHANDISE_URL,
} from 'containers/Main/AwardAnnouncement/constants'
import type { ClassicWinner } from '../awardAnnouncement.types'
import { Header } from '../Header'
import { Achievement } from './Achievement'
import { Congratulations } from './Congratulations'
import { FAQs } from './FAQs/FAQs'
import { PromoteYourAward } from './PromoteYourAward/PromoteYourAward'
import { WhatsNext } from './WhatsNext/WhatsNext'

export const Winners = (announcement: ClassicWinner) => {
    const {
        awardCategoryId,
        companyName,
        year,
        publisherId,
        denyTWPMerch,
        publisherName,
        listName,
        isApproxPublishDate,
        publicationDate,
        pressReleaseDate,
        eventDate,
        hasEvent,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate,
        nationalAwardsSvg,
        publicWebsiteCode,
        awardName,
        awardListParticipantId,
    } = announcement

    const isCEAward = awardCategoryId === AWARD_CATEGORY.CULTURE_EXCELLENCE
    const isIndustryAward = awardCategoryId === AWARD_CATEGORY.INDUSTRY
    const mediaKitExternalLinkParameters = `?id=${awardListParticipantId}&year=${year}&isCEAward=${isCEAward}&isIndustryAward=${isIndustryAward}`

    const awards = Object.entries(awardName)
    const { true: named = [], false: notnamed = [] } = groupBy(awards, ([, value]) => value)
    const winners = named.map(([key]) => key)
    const aspirant = notnamed.map(([key]) => key)

    return (
        <>
            <Header awardListParticipantId={awardListParticipantId} />
            <Congratulations
                awardCategoryId={awardCategoryId}
                companyName={companyName}
                year={year}
                publisherId={publisherId}
                awardsSvg={nationalAwardsSvg}
                winnerAwards={winners}
                aspirantAwards={aspirant}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
                isOldAwardAnnouncement={true}
            />
            <PromoteYourAward
                colors={{
                    bgColor: colors.bgBlue,
                    textColor: colors.textBlue,
                }}
            />
            <WhatsNext
                publisherId={publisherId}
                awardsSvg={nationalAwardsSvg}
                isBlackListedFromTwpMerch={denyTWPMerch}
                companyName={companyName}
                publisherName={publisherName}
                year={year}
                listName={listName}
                mediaKitExternalLinkParameters={mediaKitExternalLinkParameters}
                timeline={{
                    isApproxPublishDate: isApproxPublishDate,
                    publicationDate: publicationDate,
                    pressReleaseDate: pressReleaseDate,
                    eventDate: eventDate,
                    hasAlphabeticalWinnersList: hasAlphabeticalWinnersList,
                    alphabeticalWinnersListDate: alphabeticalWinnersListDate,
                    hasEvent: hasEvent,
                }}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
                awardCategoryId={awardCategoryId}
            />
            {!denyTWPMerch && (
                <Achievement
                    shopMerchandiseUrl={MERCHANDISE_URL}
                    year={year}
                    colors={{
                        bgColor: colors.bgBlue,
                        textColor: colors.textWhite,
                    }}
                />
            )}
            <FAQs
                companyProfileUrl={`${COMPANY_PROFILE_URL}${publicWebsiteCode}`}
                faqUrl={FAQ_URL}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
            />
        </>
    )
}
