import type { ChangeEvent } from 'react'
import { Checkbox, Input, InputGroup, RadioButton, Select, Textarea } from '@energage/components'
import { Info } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import get from 'lodash/get'
import map from 'lodash/map'
import { useController, useFormContext } from 'react-hook-form'
import SimpleTooltip from 'components/SimpleTooltip'
import type { DropdownInfo, FieldT, Options } from './awardRegistration.types'

const SECTOR_FIELD_NAME = 'organization.parentSector'
const CHILD_SECTOR_FIELD_NAME = 'organization.childSector'
const SECTOR_APPROVED_FIELD_NAME = 'organization.isSectorApproved'
const CHECK_REMOTE_WORK_FIRST_FIELD_NAME = 'remoteWorkAward.confirmRemoteAwardChkOne'
const CHECK_REMOTE_WORK_SECOND_FIELD_NAME = 'remoteWorkAward.confirmRemoteAwardChkTwo'
const CHECK_WOMEN_LED_FIELD = 'womenLedAward.confirmWomenLedAwardChk'
const IS_AWARD_SELECTED = 'hasAgreedToEligibilityCriteria'
const SECTOR_DESC_FIELD_NAME = 'organization.sectorDescription'

const getFieldLabel = (label: string, tooltip?: string, required?: boolean | undefined, id?: string) => {
    if (tooltip) {
        return (
            <>
                {label}
                <span className="input-group__required">{' *'}</span>
                <SimpleTooltip
                    id={id}
                    placement="top"
                    trigger={<Info className="ml-1 pb-1" size={18} color={colors.blue600} />}
                    className="m-0 inline-block">
                    <div className="w-48">{tooltip}</div>
                </SimpleTooltip>
            </>
        )
    }

    return (
        <label>
            <span>{label}</span>
            {required && <span className="input-group__required">{' *'}</span>}
        </label>
    )
}

export const Field = ({
    type,
    label,
    fieldName,
    options,
    tooltip,
    hidden,
    required,
    onLocationFieldChange,
}: FieldT) => {
    const {
        control,
        getValues,
        setValue,
        register,
        formState: { errors },
    } = useFormContext()
    const error = get(errors, fieldName)?.message
    const {
        field: { onChange, onBlur, value, name, ref },
    } = useController({
        name: fieldName,
        control,
    })
    const updatedLabel = getFieldLabel(label, tooltip, required, name)

    const isSectorFieldDisabled =
        (fieldName === SECTOR_FIELD_NAME && !!getValues(SECTOR_APPROVED_FIELD_NAME)) ||
        (fieldName === CHILD_SECTOR_FIELD_NAME &&
            !!getValues(SECTOR_APPROVED_FIELD_NAME) &&
            !!getValues(SECTOR_FIELD_NAME))

    const isLocationFieldUpdate = (updateFieldName: string) => {
        const fieldName = updateFieldName?.split('[')[0]
        if (fieldName && fieldName === 'locations' && onLocationFieldChange) {
            onLocationFieldChange(updateFieldName)
        }
    }

    const onChangeHandler = (e: DropdownInfo) => {
        if (name === SECTOR_FIELD_NAME && value?.id !== e?.id) {
            setValue(CHILD_SECTOR_FIELD_NAME, {})
        }

        if (name === SECTOR_FIELD_NAME || name === CHILD_SECTOR_FIELD_NAME) {
            onChange(e)
        } else {
            onChange(e.id)
        }

        if (name === CHILD_SECTOR_FIELD_NAME && !getValues(SECTOR_DESC_FIELD_NAME)) {
            setValue(SECTOR_DESC_FIELD_NAME, e?.name, { shouldValidate: true })
        }

        isLocationFieldUpdate(name)
    }

    const checkboxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        onChange(checked)

        const isFirstRemoteWorkOptChecked =
            (fieldName === CHECK_REMOTE_WORK_FIRST_FIELD_NAME && checked) ||
            getValues(CHECK_REMOTE_WORK_FIRST_FIELD_NAME)
        const isSecondRemoteWorkOptChecked =
            (fieldName === CHECK_REMOTE_WORK_SECOND_FIELD_NAME && checked) ||
            getValues(CHECK_REMOTE_WORK_SECOND_FIELD_NAME)

        if (isFirstRemoteWorkOptChecked && isSecondRemoteWorkOptChecked) {
            setValue(IS_AWARD_SELECTED, true, { shouldValidate: true })
        } else if (getValues(IS_AWARD_SELECTED)) {
            setValue(IS_AWARD_SELECTED, false, { shouldValidate: true })
        }
    }

    const radioChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value
        onChange(value)
        setValue(name, value, { shouldValidate: true })
        if (fieldName === CHECK_WOMEN_LED_FIELD) {
            setValue(IS_AWARD_SELECTED, true, { shouldValidate: true })
        }
    }

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
        isLocationFieldUpdate(name)
    }

    const inputField = register(fieldName)

    switch (type) {
        case 'text':
        case 'number':
            return (
                <Input
                    type={type}
                    name={name}
                    className={cx({
                        'border-red500': !!error,
                    })}
                    label={updatedLabel}
                    value={value ?? ''}
                    error={error}
                    info={error ? <p className="input-group__info-text">{error}</p> : null}
                    required={required}
                    ref={inputField.ref}
                    onBlur={inputField.onBlur}
                    onChange={(e) => {
                        inputField.onChange(e)
                        onInputChange(e)
                    }}
                />
            )
        case 'textarea':
            return (
                <Textarea
                    rows={1}
                    className={cx({
                        'border-red500': !!error,
                    })}
                    label={updatedLabel}
                    value={value ?? ''}
                    error={error}
                    info={error ? <p className="input-group__info-text">{error}</p> : null}
                    {...register(fieldName)}
                />
            )
        case 'dropdown':
            const selectedValue = typeof value === 'object' ? value : options?.find((o) => o.id === value)
            register(fieldName)
            return (
                <InputGroup
                    className={cx({
                        'border-red500': !!error,
                    })}
                    as={Select}
                    name={name}
                    id={name}
                    label={updatedLabel}
                    options={options}
                    getOptionLabel={(o: Options) => o.name}
                    getOptionValue={(o: Options) => o.id}
                    value={selectedValue}
                    error={error}
                    info={error ? <p className="input-group__info-text">{error}</p> : null}
                    onChange={onChangeHandler}
                    required={required}
                    isDisabled={isSectorFieldDisabled}
                />
            )
        case 'checkbox':
            return (
                <Checkbox
                    id="checkbox-simple"
                    className={cx({
                        'border-red500': !!error,
                    })}
                    label={updatedLabel}
                    onBlur={onBlur}
                    name={name}
                    checked={value}
                    onChange={checkboxChangeHandler}
                    ref={ref}
                    required={required}
                />
            )
        case 'radio':
            return (
                <div className={cx('my-2')}>
                    {map(options, (option: { label: string; value: string }, index: number) => (
                        <RadioButton
                            key={index}
                            name={name}
                            className={cx({
                                'text-red500': !!error,
                            })}
                            value={option.value}
                            label={option.label}
                            checked={option.value === value}
                            onChange={radioChangeHandler}
                            required={required}
                        />
                    ))}
                </div>
            )
        default:
            return <Input type={type} hidden={hidden} label={label} value={value ?? ''} {...register(fieldName)} />
    }
}
