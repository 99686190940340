import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import { format } from 'date-fns'
import head from 'lodash/head'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { useManagementQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { ENERGAGE_URL, INTERNAL_COMPANY_NAME } from 'constants/strings'
import {
    EMPLOYEE_FEEDBACK_URL,
    EnergageCeoStatement,
    YEAR_2024,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { DATE_FORMAT } from 'util/formatters'
import aboutEnergageText from './aboutEnergageText'
import DownloadPressReleaseFormButton from './DownloadPressReleaseFormButton'
import { useFetchPressReleaseData } from './useFetchPressReleaseData'

const titleClass = 'text-purple700 font-bold text-2xl uppercase'

export const UsaPressReleaseFormDetails = ({
    publisherId,
    surveyCompanyId,
    awardListParticipantId,
    awardCategoryId,
    publishYear,
    isMediaKitLite,
    isNationalStandard,
    awardImageUrl,
}) => {
    const { data, isLoading, isError } = useFetchPressReleaseData(
        awardCategoryId,
        publisherId,
        publishYear,
        surveyCompanyId,
        isNationalStandard
    )

    const logoUrls = useManagementQuery(['company-logo', surveyCompanyId], 'Organization/Logo')
    const companyLogoUrl = logoUrls?.data?.previewUrl
    const awardImageUrlPath = head(awardImageUrl)

    if (isLoading) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const {
        description,
        quote,
        city,
        publishDate,
        contactName,
        contactEmail,
        contactPhone,
        url,
        stateName,
        companyName,
        mediaKitLiteExperience,
    } = data

    const downloadPdfProps = {
        label: 'Download',
        variant: 'secondary',
        awardListParticipantId,
        publisherId,
        awardCategoryId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
    }

    return (
        <>
            <div className="px-24 md:px-48 mt-8">
                <div className={cx(titleClass, 'text-center')}>
                    {companyLogoUrl && <img src={companyLogoUrl} alt="Logo" className="inline mb-6 h-16 w-auto" />}
                    <p>{`${INTERNAL_COMPANY_NAME} names ${companyName}`}</p>
                    <p>{`a winner of the ${publishYear} top workplaces USA award`}</p>
                </div>
                <div className="text-xl mt-8">
                    {`${city}, ${stateName} ${format(
                        new Date(publishDate),
                        DATE_FORMAT
                    )} - Today ${companyName} has announced it has earned the ${publishYear} Top Workplaces USA award, issued by `}
                    <ExternalLink href={ENERGAGE_URL} target={ENERGAGE_URL}>
                        {'Energage'}
                    </ExternalLink>
                    {
                        ', a purpose-driven organization that develops solutions to build and brand Top Workplaces. The Top Workplaces award has a 15-year history of surveying more than 20 million employees and recognizing the top organizations across 60 markets for regional Top Workplaces awards.'
                    }
                    <div className="mt-8 whitespace-pre-wrap">{quote}</div>
                    <div className="mt-8">
                        {
                            'Top Workplaces USA celebrates organizations with 150 or more employees that have built great cultures. Over 42,000 organizations were invited to participate in the Top Workplaces USA survey. Winners of the Top Workplaces USA list are chosen based solely on '
                        }
                        <ExternalLink href={EMPLOYEE_FEEDBACK_URL} target={EMPLOYEE_FEEDBACK_URL}>
                            {'employee feedback'}
                        </ExternalLink>
                        {' gathered through an employee engagement survey, issued by Energage.'}
                    </div>
                    <div className="mt-8">
                        {
                            'Results are calculated by comparing the survey’s research-based statements, including 15 Culture Drivers that are proven to predict high performance against industry benchmarks.'
                        }
                    </div>
                    <div className="mt-8">{EnergageCeoStatement}</div>
                    <div className={cx(titleClass, 'mt-8')}>{`about ${companyName}`}</div>
                    <div className="text-xl whitespace-pre-wrap">{description}</div>
                    <div className="flex mt-8 mr-6">
                        <p className="font-bold text-lg">{'Company Contact'}</p>
                        <div className="text-base px-5">
                            <p>{`${contactName}`}</p>
                            <p>{`${contactEmail}, ${contactPhone}`}</p>
                            <p>{`${url}`}</p>
                        </div>
                    </div>
                    {!mediaKitLiteExperience && !isMediaKitLite && (
                        <>
                            <p className={cx(titleClass, 'mt-16 mb-8')}>{'awards'}</p>

                            <SvgLoader
                                path={awardImageUrlPath}
                                alt="award-images"
                                height="auto"
                                className={publishYear >= YEAR_2024 ? 'w-32' : 'w-64'}>
                                <SvgProxy selector="text" font-family="Yantramanav, sans-serif" font-weight="900" />
                                <SvgProxy selector="text tspan" font-size={publishYear >= YEAR_2024 ? '48' : '38'}>
                                    {`${publishYear}`}
                                </SvgProxy>
                            </SvgLoader>
                        </>
                    )}
                    <p className={cx(titleClass, 'mt-8')}>{'about energage'}</p>
                    <div className="text-base">{aboutEnergageText(publisherId)}</div>
                </div>
            </div>
            <div className="flex justify-center my-8 pb-16">
                <DownloadPressReleaseFormButton {...downloadPdfProps} />
            </div>
        </>
    )
}
