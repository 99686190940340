import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import some from 'lodash/some'
import { taskWorkflowCategories } from 'components/TaskWorkflow'
import TaskDefinition from 'constants/taskDefinition'
import { getRelevantTasks, getTaskStatusFromWorkflow } from 'containers/Main/Survey/SurveySetup/taskStatus'
import { SetupTask, workflowTasks } from 'containers/Main/Survey/SurveySetup/workflow'
import safeInvoke from 'util/safeInvoke'

function getTaskDependents(tasks) {
    const tasksHavingPreRequisites = filter(tasks, (t) => !isEmpty(t.prerequisiteTasks))
    const taskDependents = {}

    forEach(tasksHavingPreRequisites, ({ prerequisiteTasks, taskDefinition }) => {
        forEach(prerequisiteTasks, (preRequisite) => {
            taskDependents[preRequisite] = taskDependents[preRequisite] || []
            taskDependents[preRequisite].push(taskDefinition)
        })
    })

    return taskDependents
}

function getWorkflowCategory(allTasks, taskStatuses, identity, surveyEventId, taskDependents, programId) {
    return (category) => {
        const tasks = map(
            filter(category.tasks, (t) => taskStatuses.hasOwnProperty(t.taskDefinitionId)),
            (t) => {
                const { prerequisiteTasks = [] } = find(allTasks, (x) => x.taskDefinition === t.taskDefinitionId)

                return {
                    ...t,
                    path: safeInvoke(t.path, { identity, surveyEventId, programId }),
                    isComplete: taskStatuses[t.taskDefinitionId],
                    dependents: taskDependents[t.taskDefinitionId],
                    prerequisiteTasks,
                }
            }
        )
        const completedCount = filter(tasks, (t) => t.isComplete).length
        const status = completedCount === tasks.length ? 'complete' : completedCount > 0 ? 'in-progress' : null
        return { ...category, tasks, status }
    }
}

function getOnboardingWorkflowCategories(identity, workflowData, taskStatuses, categories) {
    if (!workflowData) {
        return []
    }

    const { tasks, programId } = workflowData
    const taskDependents = getTaskDependents(tasks)
    return map(
        filter(categories, ({ tasks }) => some(tasks, (t) => taskStatuses.hasOwnProperty(t.taskDefinitionId))),
        getWorkflowCategory(tasks, taskStatuses, identity, workflowData.surveyEventId, taskDependents, programId)
    )
}

function getTopWorkplacesWorkflow(identity, data) {
    if (!identity || !size(data)) {
        return null
    }

    return map(data, (workflowData) => {
        const taskStatuses = getTaskStatusFromWorkflow(workflowData)
        injectSelectRecipientTask(workflowData, taskStatuses)

        return {
            ...workflowData,
            newCategories: getOnboardingWorkflowCategories(
                identity,
                workflowData,
                taskStatuses,
                taskWorkflowCategories
            ),
            completedTasks: workflowData.statuses,
        }
    })
}

export default getTopWorkplacesWorkflow

function injectSelectRecipientTask(workflowData, taskStatuses) {
    const recipientTask = find(workflowTasks, (task) => task.id === SetupTask.Recipients)
    const relevantRecipientTasks = getRelevantTasks(recipientTask.children, workflowData.tasks)
    const completedCount = filter(relevantRecipientTasks, (child) => taskStatuses[child.id]).length

    if (completedCount === relevantRecipientTasks.length) {
        workflowData.statuses.push({
            taskDefinitionId: TaskDefinition.SelectRecipients,
        })

        taskStatuses[TaskDefinition.SelectRecipients] = true
    }
}
