import merge from 'lodash/merge'

const defaultConfig = {
    api: {
        authentication: '',
        workplaceSurvey: '',
        branding: '', // TODO: Deprecate in favor of branding.api
        reports: '',
        platform: '',
        organization: '',
        subscription: '',
        ordering: '',
        insights: '',
        documentManagement: '',
    },
    auth: {
        audience: '',
        domain: '',
        clientId: '',
    },
    branding: {
        api: '',
        sharing: {
            api: '',
            linkedIn: {
                clientId: '',
            },
        },
    },
    urls: {
        results: '',
        xenon: '',
        surveyManager: '',
        orderPage: '',
        surveyLogout: '',
        surveySignIn: '',
        surveyTracker: '',
        survey: '',
        userManagement: '',
        topWorkplaces: '',
        peakCommerce: '',
        energageSetup: '',
    },
    logging: {
        datadog: null,
    },
    zuora: {
        url: '',
        paymentGateway: '',
        hostedPagePrefix: '',
    },
    calendly: {
        smallSize: '',
        midSize: '',
        largeSize: '',
    },
    featureFlag: {
        enableNewRoles: false,
        enableQueryEducationalContent: false,
        enableSecurityManagement: false,
        enableShareToLinkedIn: false,
        enableContentCreator: false,
    },
    environment: 'local',
    version: 'local',
}

export type DataDogConfiguration = {
    applicationId: string
    clientToken: string
    site: string
}

type BaseAppConfiguration = typeof defaultConfig

export type AppConfiguration = Omit<BaseAppConfiguration, 'logging' | 'featureFlag'> & {
    logging?: {
        datadog: null | DataDogConfiguration
    }
    disableERTakeover?: boolean
} & {
    featureFlag: Partial<BaseAppConfiguration['featureFlag']>
}

export default function mergeWith(envConfig?: AppConfiguration) {
    return merge({}, defaultConfig, envConfig ?? {})
}

//
