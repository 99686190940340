import { LegacyButton as Button } from '@energage/components'
import { colors } from '@energage/theme'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AccessibleTileStatus, DAY_MONTH_YEAR_FORMAT, RoundedIconHolder, SurveyDates } from '../fragments'

const NotApprovedUrgent = ({ id, surveyName, launchDateTime, scheduledCloseDateTime }) => (
    <div className="h-full text-center">
        {surveyName}
        <AccessibleTileStatus backgroundColor="bg-red500" statusText={'Not Ready'} />
        <SurveyDates launchDateTime={launchDateTime} closeDateTime={scheduledCloseDateTime} />
        <RoundedIconHolder backgroundColor="bg-red100 mt-7 ">
            <ErrorOutlineIcon fillColor={colors.red500} size={70} />
        </RoundedIconHolder>
        <h5 className="text-red500 px-10 py-6">{`Your survey is scheduled to launch on ${format(
            new Date(launchDateTime),
            DAY_MONTH_YEAR_FORMAT
        )}. Please complete setup and approve your survey launch.`}</h5>
        <div className="flex justify-center py-4 border-grey200 border-t-2">
            <Button
                size="sm"
                variant="secondary"
                as={Link}
                to={routes.survey.setup.summary({ surveyEventId: id })}>{`View Survey`}</Button>
        </div>
    </div>
)

const ErrorOutlineIcon = ({ size, fillColor }) => (
    <svg width={size} height={size}>
        <path
            fill={fillColor}
            d="M30.888,44.616 L37.752,44.616 L37.752,51.48 L30.888,51.48 L30.888,44.616 Z M30.888,17.16 L37.752,17.16 L37.752,37.752 L30.888,37.752 L30.888,17.16 Z M34.28568,0 C15.34104,0 0,15.37536 0,34.32 C0,53.26464 15.34104,68.64 34.28568,68.64 C53.26464,68.64 68.64,53.26464 68.64,34.32 C68.64,15.37536 53.26464,0 34.28568,0 Z M34.32,61.776 C19.15056,61.776 6.864,49.48944 6.864,34.32 C6.864,19.15056 19.15056,6.864 34.32,6.864 C49.48944,6.864 61.776,19.15056 61.776,34.32 C61.776,49.48944 49.48944,61.776 34.32,61.776 Z"
            id="path-1"
        />
    </svg>
)

export default NotApprovedUrgent
