export default (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
    }
}

export function copyRichTextToClipboard(el) {
    if (!el) {
        return
    }

    // IE11
    if (document.body.createTextRange) {
        const range = document.body.createTextRange()
        range.collapse()
        range.moveToElementText(el)
        range.select()
    } else {
        const range = document.createRange()
        range.selectNodeContents(el)
        const selection = document.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
    }

    document.execCommand('copy')
    document.getSelection().removeAllRanges()
}
