import PropTypes from 'prop-types'
import SurveyType from 'constants/SurveyType'
import { EmptySurvey } from './Components/EmptySurvey'
import { ErrorTile } from './Components/ErrorTile'
import { NotConfirmedSurvey } from './Components/NotConfirmedSurvey'
import { SurveySetupComplete } from './Components/SurveySetupComplete'

const DO_IT_YOURSELFT_GENERATION_ID = 60

const SurveySetupTile = ({
    surveyEventId,
    error,
    standardStatementsCount,
    customStatementsCount,
    questionBankStatementsCount,
    demographicQuestionsCount,
    commentQuestionsCount,
    conditionalQuestionsCount,
    regionalStatementsCount,
    isConfirmed,
    workplaceExperienceStatementsCount,
    surveyTypeId,
    previewUrl,
    templateName,
    surveyGenerationId,
}) => {
    if (error) {
        return <ErrorTile message="Something went wrong. Unable to get information about survey setup." />
    }

    if (
        standardStatementsCount +
            customStatementsCount +
            questionBankStatementsCount +
            commentQuestionsCount +
            conditionalQuestionsCount ===
        0
    ) {
        return (
            <EmptySurvey
                surveyEventId={surveyEventId}
                title="You haven't added anything to your survey"
                message="You'll need at least one statement or question in order to send your survey."
            />
        )
    }
    if (surveyTypeId === SurveyType.WorkplaceTrending && workplaceExperienceStatementsCount === 0) {
        return (
            <EmptySurvey
                surveyEventId={surveyEventId}
                title="Survey setup not ready"
                message={
                    'You must include at least one Workplace Experience item. If you wish to create a custom survey, please use our DIY survey template.'
                }
                previewUrl={previewUrl}
            />
        )
    }

    if (
        surveyTypeId === SurveyType.Custom &&
        standardStatementsCount === 0 &&
        surveyGenerationId !== DO_IT_YOURSELFT_GENERATION_ID
    ) {
        return (
            <EmptySurvey
                surveyEventId={surveyEventId}
                title="Survey setup not ready"
                message={`You must have one standard ${templateName} item. If you wish to create your own survey, please use our Do it Yourself Survey template.`}
                previewUrl={previewUrl}
            />
        )
    }

    if (!isConfirmed) {
        return (
            <NotConfirmedSurvey
                surveyEventId={surveyEventId}
                title="You haven't confirmed your survey"
                message="Let's make sure your survey is correct! Please preview and confirm your survey."
                previewUrl={previewUrl}
            />
        )
    }

    return (
        <SurveySetupComplete
            title="Survey content is ready"
            surveyEventId={surveyEventId}
            previewUrl={previewUrl}
            standardStatementsCount={standardStatementsCount}
            customStatementsCount={customStatementsCount}
            questionBankStatementsCount={questionBankStatementsCount}
            demographicQuestionsCount={demographicQuestionsCount}
            commentQuestionsCount={commentQuestionsCount}
            conditionalQuestionsCount={conditionalQuestionsCount}
            regionalStatementsCount={regionalStatementsCount}
        />
    )
}

SurveySetupTile.propTypes = {
    standardStatementsCount: PropTypes.number.isRequired,
    customStatementsCount: PropTypes.number.isRequired,
    questionBankStatementsCount: PropTypes.number.isRequired,
    demographicQuestionsCount: PropTypes.number.isRequired,
    commentQuestionsCount: PropTypes.number.isRequired,
    conditionalQuestionsCount: PropTypes.number.isRequired,
    regionalStatementsCount: PropTypes.number.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    isConfirmationBlocked: PropTypes.bool.isRequired,
    previewUrl: PropTypes.string.isRequired,
}

export default SurveySetupTile
