import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Check, Icon, Remove } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'

const HiddenInput = styled.input.attrs({ className: 'absolute overflow-hidden p-0 border-0 h-px' })`
    clip: rect(0 0 0 0);
    width: 1px !important;
    margin: -1px;
`

const FancyCheckbox = ({ checked, indeterminate, className, children, disabled, ...props }) => {
    const ref = useRef()
    useEffect(() => {
        ref.current.indeterminate = indeterminate
    }, [indeterminate])

    return (
        <label className={cx('align-middle', className)}>
            <HiddenInput {...props} checked={checked} type="checkbox" ref={ref} disabled={disabled} />
            <span
                className={cx('inline-flex items-center border-2 rounded', {
                    'bg-blue500 border-blue500': checked,
                    'bg-white border-grey300': !checked,
                    'opacity-50 cursor-not-allowed': disabled,
                })}>
                <Icon color={colors.white} as={indeterminate ? Remove : Check} size={14} />
            </span>
            {children && <span className="pl-2">{children}</span>}
        </label>
    )
}

export const FancyCheckboxWithLabel = ({ label, className, disabled, ...restProps }) => (
    <FancyCheckbox
        {...restProps}
        disabled={disabled}
        className={cx(
            'inline-flex items-center select-none',
            { 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled },
            className
        )}>
        {label}
    </FancyCheckbox>
)

FancyCheckbox.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export default FancyCheckbox
