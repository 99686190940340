import { alert } from '@energage/components'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import showErrorAlert from 'util/showErrorAlert'

const IGNORE_DOUBLE_QUOTES = /"/g

export const useDownloadInternalLetter = (awardDetails) => {
    const { surveyCompanyId } = useIdentity()
    const {
        mutate: download,
        isLoading,
        reset,
    } = useWorkplaceSurveyQuery.mutate(
        `SurveyCompanies/${surveyCompanyId}/mediaKit/MediaKitLite/${awardDetails.year}?publisherId=${awardDetails?.publisherId}`,
        {
            onSuccess: (url) => {
                if (url) {
                    window.open(url.internalAnnouncementLetterUrl.replace(IGNORE_DOUBLE_QUOTES, ''), '_self')
                    alert.success('Internal Letter is downloaded successfully.')
                }
            },
            onError: () => {
                showErrorAlert('There was an error downloading Internal Letter')
                reset()
            },
        }
    )

    return { download: () => download({}), isDownloading: isLoading }
}
