import type { CategorizedFeatures, Features } from './organizationFeatures.types'

interface TransformedFeaturesI<K> {
    KnownFeatureIds: K
    isActive(featureId: number): boolean
}

export class TransformedFeatures<T extends CategorizedFeatures, K> implements TransformedFeaturesI<K> {
    private features: Features[] = []
    private featureMap = new Map<number, boolean>()

    public KnownFeatureIds

    constructor(features: T, knownFeatureIds: K) {
        this.features = features.categories.flatMap((f) => f.features)
        this.KnownFeatureIds = knownFeatureIds
    }

    public isActive(featureId: number) {
        if (this.featureMap.has(featureId)) {
            return !!this.featureMap.get(featureId)
        }

        const featureState = this.features.find((f) => f.id === featureId)?.isActive ?? false

        this.featureMap.set(featureId, featureState)
        return featureState
    }
}
