import cx from 'clsx'
import Inset from 'components/Inset'

const MediaKitSection = ({ title, noBackground, noBorder, children }) => (
    <Inset padding className={cx({ 'border-b border-grey300': !noBorder }, noBackground ? '' : 'bg-grey200')}>
        {!noBackground && <h2 className="my-4 h3">{title}</h2>}
        <div className={'p-2 md:pt-6 md:p-12'}>{children}</div>
    </Inset>
)

export default MediaKitSection
