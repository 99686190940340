import { useCallback } from 'react'
import { butterBar } from '@energage/components'
import noop from 'lodash/noop'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { SUPPORT_PHONE } from 'constants/strings'
import { usePost } from 'hooks'

export default function useSyncSurvey(onPostComplete = noop) {
    const { organizationId } = useIdentity()

    const { doPost, isPosting } = usePost({
        url: `${config.api.platform}/organization/${organizationId}/SyncActiveSurveys`,
        onComplete: (res) => onPostComplete(res),
        onError: (data) => {
            butterBar.danger(`There was an error syncing active surveys. Please contact Support at ${SUPPORT_PHONE}.`, {
                dismissible: true,
            })
            onPostComplete(data)
        },
    })

    const onSync = useCallback(
        ({ delayInSeconds }) => {
            doPost({ organizationId, delayInSeconds })
        },
        [doPost, organizationId]
    )

    return {
        onSync,
        loading: isPosting,
    }
}
