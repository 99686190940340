import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import { format } from 'date-fns'
import inRange from 'lodash/inRange'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import { useManagementQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { ENERGAGE_URL } from 'constants/strings'
import { EnergageCeoStatement } from 'containers/Main/EmployerBranding/MediaKit/constants'
import aboutEnergageText from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/aboutEnergageText'
import DownloadPressReleaseFormButton from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/DownloadPressReleaseFormButton'
import { useFetchPressReleaseData } from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/useFetchPressReleaseData.ts'
import { awardCategoryId as awardCategoryEnum } from 'containers/Main/EmployerBranding/MediaKit/TopWorkplacesAwards/constants'
import { DATE_FORMAT } from 'util/formatters'

const PageBreaker = styled.div`
    page-break-before: ${(props) => (props.isPageBreak ? 'always' : 'avoid')};
`

const titleClass = 'text-purple700 font-bold text-base uppercase'
const noOfWordsIndescriptionAndQuoteLine = 90
const noOfLinesForComapanyDescription = 5

export const CeAndIndustryPressReleaseFormDetails = ({
    publisherId,
    surveyCompanyId,
    awardCategoryId,
    awardListParticipantId,
    awardImageUrl,
    publishYear,
    isMediaKitLite,
    isNationalStandard,
    isPreviewPage,
}) => {
    const { data, isLoading, isError } = useFetchPressReleaseData(
        awardCategoryId,
        publisherId,
        publishYear,
        surveyCompanyId,
        isNationalStandard
    )
    const downloadPdfProps = {
        label: 'Download',
        variant: 'secondary',
        awardListParticipantId,
        publisherId,
        awardCategoryId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
    }
    const logoUrls = useManagementQuery(['company-logo', surveyCompanyId], 'Organization/Logo')
    const companyLogoUrl = logoUrls?.data?.previewUrl

    if (isLoading) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const {
        description,
        quote,
        city,
        stateName,
        companyName,
        publishDate,
        contactName,
        contactEmail,
        contactPhone,
        url,
        awardName,
        awardDetails,
        topWorkplaceProfileUrl,
        mediaKitLiteExperience,
    } = data

    const quoteAndAboutCnt =
        (size(quote) + size(description)) / noOfWordsIndescriptionAndQuoteLine + noOfLinesForComapanyDescription
    const isIndutry = awardCategoryId === awardCategoryEnum.Industry
    const isPageBreak = inRange(quoteAndAboutCnt, 10.75, 48) || inRange(quoteAndAboutCnt, 70, 107)

    return (
        <div className="w-full relative">
            <div className="px-24 md:px-48 mt-8">
                <div className={cx(titleClass, 'text-center')}>
                    {companyLogoUrl && <img src={companyLogoUrl} alt="Logo" className="inline mb-6 h-16 w-auto" />}
                    <p className="uppercase px-0 md:px-8">
                        {`${companyName}`}
                        {` Wins ${publishYear} Top Workplaces ${
                            isIndutry ? 'Industry Award' : `Culture Excellence Award(s) for ${awardName}`
                        }`}
                    </p>
                    {isIndutry && <p>{`Organization earns national recognition as an employer of choice`}</p>}
                </div>
                <div className="text-sm mt-8">
                    {`${city}, ${stateName} ${format(
                        new Date(publishDate),
                        DATE_FORMAT
                    )} - ${companyName} announced today that it is a ${publishYear} Top Workplaces ${
                        isIndutry ? 'Industry' : 'Culture Excellence'
                    } winner. `}
                    <ExternalLink href={ENERGAGE_URL} target={ENERGAGE_URL}>
                        {'Energage'}
                    </ExternalLink>
                    {
                        ', a purpose-driven organization that builds and brands employers of choice, determines the awards. The Top Workplaces program has a 17-year history of surveying and celebrating people-first organizations nationally and across 60 regional markets.'
                    }
                    <div className="mt-8">
                        {isIndutry
                            ? 'Top Workplaces Industry awards celebrate organizations that have built people-first workplace cultures within their sector. The award marks them as an employer of choice for those seeking employment in the industry.'
                            : 'Top Workplaces Culture Excellence awards recognize organizations that excel in specific areas of workplaces culture:'}
                    </div>
                    {!isIndutry && (
                        <div className="mt-8">
                            {map(awardDetails, (award, index) => (
                                <>
                                    <div key={index} className="mt-6">
                                        <p className="font-bold text-base">{award.key}</p>
                                        {award.value}
                                    </div>
                                    {index === 3 && <PageBreaker isPageBreak={true} />}
                                </>
                            ))}
                        </div>
                    )}
                    <div className="mt-8 whitespace-pre-wrap">{quote}</div>
                    <div className="mt-8">
                        {`Top Workplaces awards are based on feedback from a research-backed employee engagement survey. Details about how ${companyName} builds a great workplace culture are available on `}
                        <ExternalLink href={topWorkplaceProfileUrl} target={topWorkplaceProfileUrl}>
                            {'Top Workplaces'}
                        </ExternalLink>
                        {`.`}
                    </div>
                    <div className="mt-8">{EnergageCeoStatement}</div>
                    <div className={cx(titleClass, 'mt-8')}>{`about ${companyName}`}</div>
                    <div className="text-sm whitespace-pre-wrap">{description}</div>
                    {isIndutry && quoteAndAboutCnt > 9.5 && quoteAndAboutCnt < 12.9 && (
                        <PageBreaker isPageBreak={true} />
                    )}
                    <div className="flex mt-12 mr-6">
                        <p className="font-bold text-base">{'Company Contact'}</p>
                        <div className="text-base px-5">
                            <p>{`${contactName}`}</p>
                            <p>{`${contactEmail}, ${contactPhone}`}</p>
                            <p>{`${url}`}</p>
                        </div>
                    </div>
                    {!isIndutry && <PageBreaker className="pt-4" isPageBreak={isPageBreak} />}
                    {!mediaKitLiteExperience && !isMediaKitLite && (
                        <>
                            <p className={cx(titleClass, 'mt-16 mb-8')}>{'awards'}</p>
                            <div className="flex relative">
                                {map(awardImageUrl, (imgSrc, index) => (
                                    <SvgLoader
                                        path={imgSrc}
                                        key={index}
                                        alt="award-images"
                                        height="auto"
                                        className="w-32">
                                        <SvgProxy
                                            selector="text tspan"
                                            font-family="'Yantramanav', sans-serif"
                                            font-size="32"
                                            font-weight="900">
                                            {`${publishYear}`}
                                        </SvgProxy>
                                    </SvgLoader>
                                ))}
                            </div>
                        </>
                    )}
                    <p className={cx(titleClass, 'mt-8')}>{'about energage'}</p>
                    <div className="text-sm">{aboutEnergageText()}</div>
                </div>
            </div>
            {isPreviewPage && (
                <div className="flex justify-center my-8 pb-16">
                    <DownloadPressReleaseFormButton {...downloadPdfProps} />
                </div>
            )}
        </div>
    )
}
