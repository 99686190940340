import { useState } from 'react'
import { alert, Alert, LegacyButton as Button } from '@energage/components'
import { parse } from 'query-string'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import config from 'config'
import { SUPPORT_EMAIL } from 'constants/strings'
import { usePostWithTaskCompletion } from 'hooks'

const AlertContent = styled.div`
    justify-content: center !important;
`

function closeWindow() {
    window.open(window.location, '_self').close()
}

function getAlertBody(isConfirmed, onConfirm) {
    if (isConfirmed) {
        return (
            <>
                <span>{'Your survey has been confirmed.'}</span>
                <Button className="ml-6" onClick={closeWindow}>
                    {'Close'}
                </Button>
            </>
        )
    } else {
        return (
            <>
                <span className="max-w-md">
                    {`After previewing, please confirm your survey. Close this tab to return and make updates to your survey. Contact `}
                    <a className="underline" href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                    </a>
                    {' for questions or help.'}
                </span>
                <Button className="ml-6" onClick={onConfirm}>
                    {'Confirm'}
                </Button>
            </>
        )
    }
}

const SurveyPreview = ({ location }) => {
    const { surveyEventId } = useParams()
    const { url, id: surveyCompanyId, a: initialConfirmed } = parse(location.search)
    const [isConfirmed, setConfirmed] = useState(initialConfirmed)
    const { doPost: confirm } = usePostWithTaskCompletion({
        //TODO: Stop hardcoding the task definition id
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/Workflow/${surveyEventId}/904/CompleteSurveyTask`,
        onComplete: () => {
            setConfirmed(true)
            closeWindow()
        },
        onError: () => {
            alert.notify("We're sorry, an error occurred while approving your survey.")
        },
    })

    const handleConfirmation = () => confirm()

    return (
        <div className="w-screen h-screen flex flex-col">
            <Alert className="butter-bar" variant="primary">
                <AlertContent className="items-center">{getAlertBody(isConfirmed, handleConfirmation)}</AlertContent>
            </Alert>
            <iframe className="flex-1 w-full" src={url} title="Preview Survey" />
        </div>
    )
}

export default SurveyPreview
