import { Paragraph } from './MediaKitExternalLetterModal'

export const UsaTodayInternalLetterModal = () => {
    return (
        <div className="px-5 py-5 text-grey500">
            <p>{`Subject: Exciting News: [Company Name] Wins the 2024 Top Workplaces USA Award!`}</p>
            <Paragraph>{`Team,`}</Paragraph>
            <Paragraph>{`I'm thrilled to share some exciting news – [Company Name] has been named a 2024 Top Workplaces USA winner! This means USA TODAY will recognize us as an employer of choice when the winners’ list goes live on Wednesday, March 20th.`}</Paragraph>
            <Paragraph>{`Being named one of the nation’s Top Workplaces is a big deal. This honor is a direct result of the valuable insights from the employee survey, with over [# of surveyed employees] participating. Your voices have been instrumental in achieving this milestone.`}</Paragraph>
            <Paragraph>{`I want to express my sincere gratitude for your survey participation. Your feedback will be used to make [Company Name] an even better place to work.`}</Paragraph>
            <Paragraph>{`Remember to mark your calendars for March 20th! Until then, please keep the news of our award hush-hush. After that, you’re welcome to spread the word and share it proudly.`}</Paragraph>
            <Paragraph>{`Thank you for being a part of our award-winning team.`}</Paragraph>
            <Paragraph>{`[Your Name]`}</Paragraph>
            <Paragraph>{`[Your Title]`}</Paragraph>
            <Paragraph>{`[Company Name]`}</Paragraph>
        </div>
    )
}
