import cx from 'clsx'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import type { PreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/OrgStrengthsConfiguration/OrgStrengths.types'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

const PreviewContainer = styled.div<{
    backgroundColor?: string
    width?: string
    height?: string
    alt?: string
}>`
    width: ${(props) => props.width ?? '340px'};
    height: ${(props) => props.height ?? '340px'};
    background-color: ${(props) => props.backgroundColor};
`

const Trangle = styled.div<{ width?: string; height?: string }>`
    border-top: ${(props) => `${props.height} solid transparent`};
    border-left: ${(props) => `${props.width} solid rgba(255, 255, 255, 0.3)`};
`

const CompanyStrengths = styled.h3<{ highlightColor: string; isDownloadable: boolean | undefined }>`
    color: ${(props) => props.highlightColor};
    font-size: ${(props) => (props.isDownloadable ? '6rem' : '3rem')};
    top: ${(props) => (props.isDownloadable ? '450px' : '208px')};
    left: ${(props) => (props.isDownloadable ? '-385px' : '-200px')};
`
const TextWrapper = styled.div<{ textColor: string }>`
    color: ${(props) => props.textColor};
    top: 50%;
    transform: translateY(-50%);
`

const StrengthsName = styled.h3<{ isDownloadable: boolean | undefined }>`
    font-size: ${(props) => (props.isDownloadable ? '6rem' : '48px')};
    line-height: 1;
`

export const OrgStrengthsTrangulerPreview = ({
    name,
    text,
    width,
    height,
    backgroundColor,
    highlightColor,
    textColor,
    isDownloadable,
}: PreviewProps) => {
    return (
        <PreviewContainer
            className={cx('relative', !isDownloadable && 'rounded-lg')}
            width={width}
            height={height}
            backgroundColor={backgroundColor}>
            <Trangle className="absolute top-0 left-0" width={width} height={height} />
            <CompanyStrengths
                className="absolute transform rotate-90"
                highlightColor={highlightColor}
                isDownloadable={isDownloadable}>
                {'Company Strengths'}
            </CompanyStrengths>
            <div className={cx(isDownloadable ? 'w-48 mt-12 mr-12' : 'w-20 mt-6 mr-6', 'absolute top-0 right-0')}>
                <ProgressiveImage src={CultureFactTWP} placeholder="">
                    {(src) => <img src={src} alt="" className="border rounded-full w-full" />}
                </ProgressiveImage>
            </div>
            <TextWrapper
                className={cx(isDownloadable ? 'pr-12 pl-40' : 'pr-6 pl-16', 'text-right relative')}
                textColor={textColor}>
                <StrengthsName className="uppercase" isDownloadable={isDownloadable}>{`${name}`}</StrengthsName>
                <p className={cx(isDownloadable ? 'text-6xl mt-12' : 'text-2xl mt-8', 'pl-8')}>{`${text}`}</p>
            </TextWrapper>
        </PreviewContainer>
    )
}
