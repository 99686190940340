import { colors } from '@energage/theme'
import type { Aspiring } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { FAQ_URL } from 'containers/Main/AwardAnnouncement/constants'
import { FAQs } from './FAQs/FAQs'
import { HighlightCulture } from './HighlightCulture'
import { MoreAwards } from './MoreAwards'
import { ThankYouForParticipatingRevamp } from './ThankYouForParticipatingRevamp'
import { UnderstandYourOrg } from './UnderstandYourOrg'

export const AspirantRevamp = (announcement: Aspiring) => {
    const { companyName, year, requiresTheBeforeName, awardAliasName, listName } = announcement

    return (
        <>
            <ThankYouForParticipatingRevamp
                companyName={companyName}
                listName={listName}
                requiresTheBeforeName={requiresTheBeforeName}
                awardAliasName={awardAliasName}
            />
            <HighlightCulture />
            <UnderstandYourOrg />
            <MoreAwards />
            <FAQs companyName={companyName} year={year} faqUrl={FAQ_URL} bgColor={colors.darkblue500} isPdf={false} />
        </>
    )
}
