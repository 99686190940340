import { LegacyButton as Button } from '@energage/components'
import { ArrowRight, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { differenceInDays, isFuture, isPast } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import map from 'lodash/map'
import { AppLink, ExternalLink } from 'components/Anchor'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import { useVisibility } from 'hooks'
import { dayOfWeekMonthDayYearDate, monthDayYearDate } from 'util/formatters'

export const POPOVER_LINE_SIZE = 10
export const MARGIN_FOR_LINE = 2
export const TIMELINE_SIZE = 4

const Link = ({ as: Component, className, children, ...props }) => (
    <span className="text-xs font-medium">
        <Component {...props} className={cx('text-xs font-medium text-blue600 p-0', className)}>
            {children}
            <Icon as={ArrowRight} size={18} />
        </Component>
    </span>
)

export const CardLink = (props) => <Link as={AppLink} {...props} />
export const CardExternalLink = (props) => <Link as={ExternalLink} {...props} />

export const EnergageHelpModal = ({ label, text }) => {
    const { visible, show, hide } = useVisibility()

    return (
        <>
            <Link as={Button} onClick={show} variant="link">
                {label}
            </Link>
            <Modal className="max-w-md" isOpen={visible} title={label} visible={show} onClose={hide}>
                <div className="px-4 sm:px-6 pt-4">
                    <p>{text}</p>
                </div>
                <Inset padding className="flex justify-center">
                    <Button variant="secondary" className="ml-4" onClick={hide}>
                        {'Okay'}
                    </Button>
                </Inset>
            </Modal>
        </>
    )
}

export const TBD_TEXT = 'TBD'

export const getDisplayDateOrDefault = (date) => (date ? dayOfWeekMonthDayYearDate(zonedTimeToUtc(date)) : TBD_TEXT)
export const getDisplayMonthDayYearDateOrDefault = (date) => (date ? monthDayYearDate(zonedTimeToUtc(date)) : TBD_TEXT)

export const getUTCDateTime = (date) => date && zonedTimeToUtc(date)

export const isEventDatePassed = (date) => date && isPast(zonedTimeToUtc(date))

export const getDifferenceInDays = (date1, date2 = new Date()) =>
    differenceInDays(zonedTimeToUtc(date1), zonedTimeToUtc(date2))

export const hasTodayOverlap = (startDate, endDate) =>
    startDate && endDate && isPast(zonedTimeToUtc(startDate)) && isFuture(zonedTimeToUtc(endDate))

export const getColor = (isDeadlinePassed, color) => (isDeadlinePassed ? colors.purple300 : color || colors.grey400)

export const getHeight = (isDeadlinePassed) => (isDeadlinePassed ? POPOVER_LINE_SIZE : TIMELINE_SIZE)

export const isAwardDisqualified = (timelineStatus) => timelineStatus === timelineStatuses.Disqualified

export const timelineStatuses = {
    Completed: 'Completed',
    Warning: 'Warning',
    Future: 'Future',
    Danger: 'Danger',
    Help: 'Help',
    Disqualified: 'Disqualified',
}

export const icons = {
    [timelineStatuses.Warning]: require('images/employerRecognition/timeline/warning.svg').default,
    [timelineStatuses.Danger]: require('images/employerRecognition/timeline/danger.svg').default,
    [timelineStatuses.Disqualified]: require('images/employerRecognition/timeline/disqualified.svg').default,
}

const colorsByStatus = {
    [timelineStatuses.Completed]: {
        bgColor: colors.white,
        borderColor: colors.purple300,
        color: colors.purple200,
    },
    [timelineStatuses.Warning]: {
        bgColor: colors.yellow100,
        borderColor: colors.yellow700,
        color: colors.yellow300,
    },
    [timelineStatuses.Future]: {
        bgColor: colors.white,
        borderColor: colors.grey400,
        color: colors.grey400,
    },
    [timelineStatuses.Danger]: {
        bgColor: colors.red100,
        borderColor: colors.red500,
        color: colors.red200,
    },
    [timelineStatuses.Help]: {
        bgColor: colors.blue100,
        borderColor: colors.blue500,
        color: colors.blue200,
    },
    [timelineStatuses.Disqualified]: {
        bgColor: colors.white,
        borderColor: colors.grey300,
        color: colors.grey400,
    },
}

const getColorsByStatus = (status) => colorsByStatus[status] || colorsByStatus[timelineStatuses.Future]
const getColorsByState = (isDeadlinePassed) =>
    isDeadlinePassed ? colorsByStatus[timelineStatuses.Completed] : colorsByStatus[timelineStatuses.Future]

export const getCardsWithColors = (cards) =>
    map(cards, (card) => ({
        ...card,
        ...(card.status ? getColorsByStatus(card.status) : getColorsByState(card.isDeadlinePassed)),
    }))

export const getStatus = (isDeadlinePassed, isActive) => {
    if (!isActive) {
        return timelineStatuses.Disqualified
    }

    if (isDeadlinePassed) {
        return timelineStatuses.Completed
    }

    return timelineStatuses.Future
}
