import { useWorkplaceSurveyQuery } from 'api'

export const getDepartments = async (surveyCompanyId, surveyEventId, api) => {
    const departments = await api.json(
        `surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/departments`
    )
    return departments
}

export default function useFetchDepartments(surveyEventId, surveyCompanyId) {
    const { data, error, isLoading, refetch } = useWorkplaceSurveyQuery(
        ['survey-definition-departments', surveyCompanyId, surveyEventId],
        (_, api) => getDepartments(surveyCompanyId, surveyEventId, api)
    )

    return {
        data,
        isLoading: isLoading,
        error,
        refetch,
    }
}
