import { LegacyButton as Button } from '@energage/components'
import omit from 'lodash/omit'
import { Link } from 'react-router-dom'
import chain from 'util/chainFunctions'
import isExternalPath from 'util/isExternalPath'

const TaskLink = ({ to, history, onClick, ...resetProps }) => {
    const props = omit(resetProps, 'programName')
    if (isExternalPath(to)) {
        return <Button {...props} as="a" href={to} onClick={onClick} />
    }

    if (history) {
        return <Button {...props} as="span" onClick={chain(onClick, () => history.push(to))} />
    }

    return <Button {...props} as={Link} to={to} onClick={onClick} />
}

export default TaskLink
