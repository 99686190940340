import map from 'lodash/map'
import omit from 'lodash/omit'
import { useQueryClient } from 'react-query'
import type { useMutation, UseMutationOptions, UseMutationResult, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useManagementQuery, useWorkplaceSurveyQuery } from 'api'
import type { ResponseError } from 'api'
import { useIdentity } from 'components/Identity'
import { routes } from 'constants/routes'
import { HAS_NOT_AGREED } from 'containers/Main/EmployerRecognition/Shared/AwardSelectionBox/constants'
import showErrorAlert from 'util/showErrorAlert'
import type { IFormInputs, Location } from './awardRegistration.types'
import { LocationFormOptions } from './constants'

const NOT_QUALIFIED = 'Not Qualified'

const getRegistrationPayload = (data: IFormInputs) => ({
    awardListParticipationId: data.awardListParticipantId,
    hasFullyRemoteParticipation: data.hasFullyRemoteParticipation,
    hasAgreedToEligibilityCriteria: data.hasAgreedToEligibilityCriteria,
    locationStatus: data.locationStatus,
    publisher: {
        isNational: data.publisher.isNational,
    },
    organization: {
        companyName: data.organization.companyName ?? '',
        yearFounded: data.organization.yearFounded ?? '',
        ownershipTypeId: data.organization.ownershipTypeId ?? '',
        sectorId: (data?.organization?.childSector?.id || data?.organization?.parentSector?.id) ?? '',
        sectorDescription: data.organization.sectorDescription ?? '',
    },
    primaryContact: {
        contactId: data.primaryContact.contactId ?? '',
        firstName: data.primaryContact.firstName ?? '',
        lastName: data.primaryContact.lastName ?? '',
        title: data.primaryContact.title ?? '',
        emailAddress: data.primaryContact.emailAddress ?? '',
        contactTypeId: data.primaryContact.contactTypeId ?? '',
        phoneNumber: data.primaryContact.phoneNumber ?? '',
    },
    leaderContact: {
        contactId: data.leaderContact.contactId || 0,
        firstName: data.leaderContact.firstName ?? '',
        lastName: data.leaderContact.lastName ?? '',
        title: data.leaderContact.title ?? '',
        emailAddress: data.leaderContact.emailAddress ?? '',
    },
})

type useMutation = {
    <TData = unknown, TError = unknown>(
        url: string,
        options?: Omit<UseMutationOptions<TData, TError>, 'mutationFn'>
    ): UseMutationResult<TData, TError>
}

type QueryWithMutate = typeof useQuery & {
    mutate: useMutation
}

export type ErrorT = ResponseError<
    {
        code: string
        message: string
        Locations: Location[]
        type: string
    }[]
>

type SaveAwardRegistrationProps = {
    awardListParticipantId: number
    publisherId: number
    salesforceYear: number
    handleError: (arg0: Location[]) => void
}

export const useSaveAwardRegistration = ({
    awardListParticipantId,
    publisherId,
    salesforceYear,
    handleError,
}: SaveAwardRegistrationProps) => {
    const { surveyCompanyId, organizationId } = useIdentity()
    const history = useHistory()
    const queryClient = useQueryClient()

    const {
        mutateAsync: save,
        isLoading,
        error,
        reset,
    } = useManagementQuery.mutate<IFormInputs, ErrorT, unknown>(`AwardRegistration/${organizationId}`, {
        updateTwpWorkflow: true,
        onSuccess: () => {
            queryClient.invalidateQueries(['survey-completed-task', surveyCompanyId])
            queryClient.invalidateQueries([
                'awardRegistration',
                surveyCompanyId,
                organizationId,
                awardListParticipantId,
            ])
            history.goBack()
        },
        onError: (err) => {
            showErrorAlert('There was an error saving registration details', err.parsed)
            if (err.parsed[0].type === 'InvalidRegionLocationException') {
                handleError(err.parsed[0].Locations)
                return
            }
            reset()
        },
    })

    const {
        mutateAsync: saveRegionCompanyName,
        isLoading: companyNameSaving,
        error: companyNameError,
    } = (useWorkplaceSurveyQuery as QueryWithMutate).mutate<string, ErrorT>(
        `Organizations/${organizationId}/RecognitionProfile/${publisherId}/Name`,
        {
            onError: (err) => {
                showErrorAlert('There was an error saving your company name', err.parsed)
            },
        }
    )

    /*
        todo(ha1): This needs to be fixed and re-tested.

        The generics for the mutate are <ResponseData, ErrorType, Variables>,
        but this is running into issues because the object with onSuccess is in the entirely wrong place. The options object is
        not being passed as the second argument. The code is essentially:
            mutate(() => {
              return (
                  {
                      await apiCall,
                      {
                          onSuccess: history.push(routes.employerRecognition()),
                      }
                  }
              )
            })

            instead of:

            mutate(() => {
              return await apiCall
            }, { onSuccess })

          The onSuccess: history.push() will also be executed immediately. This code probably looks like it works because of the await
          causing a delay before continuing to the object with onSuccess .. where the history.push will be executed immediately.
     */
    // @ts-ignore
    const { mutate: removeAward } = useWorkplaceSurveyQuery.mutate<void, unknown, unknown>(async (data, api) => {
        return (
            await api.patch(`AwardParticipation/${organizationId}/Disqualify/${salesforceYear}`, {
                json: data,
            }),
            {
                onSuccess: history.push(routes.employerRecognition()),
            }
        )
    })

    const saveRegistration = (data: IFormInputs | undefined) => {
        if (!data) {
            return
        }
        const registrationInfo =
            !data.hasFullyRemoteParticipation && data.locations
                ? {
                      surveyCompanyId,
                      ...getRegistrationPayload(data),
                      locations: map(data.locations, (location) => omit(location, ['index', 'isOutsideRegion'])),
                  }
                : { surveyCompanyId, ...getRegistrationPayload(data) }

        if (
            data.womenLedAward.confirmWomenLedAwardChk === HAS_NOT_AGREED ||
            data.locationStatus === LocationFormOptions.NoMinEmpCount
        ) {
            removeAward({
                AwardId: data.awardId,
                Reason: NOT_QUALIFIED,
                IsDropoutForOneYear: false,
            })
        } else {
            saveRegionCompanyName(data.organization.companyName)
            if (!companyNameSaving && !companyNameError) {
                save(registrationInfo)
            }
        }
    }
    return {
        saveRegistration,
        isLoading,
        error,
    }
}
