import { LegacyButton as Button } from '@energage/components'
import withClassName from 'style/util/withClassName'
import { RadioButtonPreview, TextAreaInputSmall } from '../CustomQuestionTextInputs'
import { PreviewTitleDisplay } from './Fragments'

export type AgreementPreviewProps = {
    text?: string
    positiveConditionalText?: string
    negativeConditionalText?: string
}

const agreementOptions = [
    'Strongly Disagree',
    'Disagree',
    'Slightly Disagree',
    'Neutral',
    'Slightly Agree',
    'Agree',
    'Strongly Agree',
]

const ConditionalPreview = ({ text }: { text?: string }) => (
    <div className="inline-block w-full">
        <TextAreaInputSmall
            label={<div className="text-grey500 mb-1">{text ?? 'Enter your conditional comment question here'}</div>}
            value="Enter your response here"
            disabled
        />
    </div>
)

const Divider = withClassName('h-px bg-grey300 my-3 border-0', 'hr')

export function AgreementPreview({ text, positiveConditionalText, negativeConditionalText }: AgreementPreviewProps) {
    return (
        <div>
            <PreviewTitleDisplay>{text}</PreviewTitleDisplay>
            <div className="flex-wrap w-full mb-0 pb-0 hidden md:flex">
                {agreementOptions.map((agreementOption, index) => (
                    <div className="w-16  min-w-16 mx-auto h-20" key={agreementOption}>
                        <label key={index} className="sm:w-auto text-grey500 px-4 mb-0">
                            <div className="mb-0 flex flex-col">
                                <div className="mb-3 w-full mx-auto text-xs justify-center h-5 flex">
                                    <span className="self-end text-center">{agreementOption}</span>
                                </div>
                                <div className="mx-auto text-center w-4">
                                    <RadioButtonPreview disabled type="radio" checked={false} />
                                </div>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            <div className="flex flex-col w-full mb-0 pb-0 md:hidden">
                {agreementOptions.map((agreementOption, index) => (
                    <Button className="my-1 w-full" variant="primary" outline disabled key={index}>
                        {agreementOption}
                    </Button>
                ))}
            </div>

            {positiveConditionalText || negativeConditionalText ? (
                <>
                    <Divider />
                    <div className="text-primary-dark font-bold text-base">
                        {'Conditional Comment Questions Preview'}
                    </div>
                    {positiveConditionalText ? <ConditionalPreview text={positiveConditionalText} /> : null}
                    {negativeConditionalText ? <ConditionalPreview text={negativeConditionalText} /> : null}
                </>
            ) : null}
        </div>
    )
}
