import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { Info } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import { AccordionContainer } from 'components/Accordion'
import { ExternalLink } from 'components/Anchor'
import { Tooltip } from 'components/Tooltip'
import { INTERNAL_COMPANY_NAME, SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/strings'

const Error = ({ message }: { message: ReactNode }) => (
    <div className="py-20 text-center">
        <div>{'Sorry, something went wrong.'}</div>
        <div>{message}</div>
    </div>
)

const EmptyState = () => (
    <div className="py-20 text-center">
        <div className="paragraph-lead">{'Not enough data to create a pulse survey.'}</div>
    </div>
)

const AnyQuestions = () => (
    <span>
        {`If you have any questions before then, please contact us at `}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        {` or ${SUPPORT_PHONE}.`}
    </span>
)

const ConditionalTooltipText = (isParentPositivelyPhrased: boolean | undefined) => {
    const positiveTooltipText =
        isParentPositivelyPhrased === false
            ? 'This question will appear to a subset of responders who disagreed or strongly disagreed with this negative survey statement.'
            : 'This question will appear to a subset of responders who agreed or strongly agreed with this related survey statement.'

    const negativeTooltipText =
        isParentPositivelyPhrased === false
            ? 'This question will appear to a subset of responders who agreed or only slightly disagreed with this negative survey statement.'
            : 'This question will appear to a subset of responders who disagreed or only slightly agreed with this related survey statement.'

    return { positiveTooltipText, negativeTooltipText }
}

const ConditionalTitleText = (isParentPositivelyPhrased: boolean | undefined) => {
    const positiveTitleText = isParentPositivelyPhrased
        ? 'Conditional questions for responses of agree or strongly agree'
        : 'Conditional questions for responses of disagreement or only slightly agree'

    const negativeTitleText = isParentPositivelyPhrased
        ? 'Conditional questions for responses of disagreement or only slightly agree'
        : 'Conditional questions for responses of agree or strongly agree'

    return { positiveTitleText, negativeTitleText }
}

const CustomQuestionHelpText = () => (
    <p>
        {'To create statements and questions with maximum impact, '}
        <ExternalLink
            href="https://energagecommunity.force.com/FAQ/s/article/Guide-to-Creating-Custom-Statements-or-Questions"
            target="_blank"
            rel="noopener">
            {'view our guide to gathering quality feedback'}
        </ExternalLink>
        {'.'}
    </p>
)

const CategoryLabel = ({ categoryId, title }: { categoryId: string; title: ReactNode }) => (
    <div className="flex">
        <div className="w-1/4 mr-4 md:mr-2">{'Topic'}</div>
        <div className="w-4/5">
            <label htmlFor={categoryId}>{title}</label>
        </div>
    </div>
)

const InfoTooltip = ({ text, size = 14 }: { text: ReactNode; size?: number }) => (
    <Tooltip trigger={<Info size={size} color={colors.grey500} className="-mt-1 ml-1" />} text={text} />
)

const IncludedItemsHeader = ({ className }: { className?: string }) => (
    <div className={cx('font-bold mb-2', className)}>
        {`Included items`}
        <InfoTooltip text={`Items applicable to most organizations`} size={16} />
    </div>
)
const OptionalItemsHeader = ({ className }: { className?: string }) => (
    <div className={cx('font-bold mb-2 mt-3', className)}>
        {`Optional items`}
        <InfoTooltip text={`Items you can choose based on how your organization works`} size={16} />
    </div>
)

const NonExpandableSection = ({
    children,
    title,
    text,
    ...props
}: {
    children?: ReactNode
    title: string
    text?: ReactNode
} & ComponentPropsWithoutRef<'div'>) => (
    <AccordionContainer {...props}>
        <div className="py-4">
            <div className="text-left">{title}</div>
            {text && <div className="pt-2 max-w-md paragraph">{text}</div>}
        </div>
        <div className="mt-1 pb-4">{children}</div>
    </AccordionContainer>
)

const LeadingDemographicDescription = ({
    isFullSurvey,
    isPurchaser,
}: {
    isFullSurvey: boolean
    isPurchaser: boolean
}) => {
    const start = isFullSurvey ? 'These default demographics are required for Workplace Surveys. ' : ''

    if (!isPurchaser) {
        return (
            <>
                {`${start}Demographics can provide powerful insights into how your employees are feeling based on their roles.`}
            </>
        )
    }

    return (
        <>
            {`${start}Demographic questions will not appear to responders whose demographic information was provided on the Recipients page.`}
        </>
    )
}

const TrailingDemographicDescription = () => {
    return (
        <>
            {`Diversity, Equity, and Inclusion are important to ${INTERNAL_COMPANY_NAME} and many organizations we work with, so three of the demographics are
        on by default. Responses to these questions will also be included with an Insights subscription.`}
        </>
    )
}

export {
    Error,
    EmptyState,
    CustomQuestionHelpText,
    InfoTooltip,
    AnyQuestions,
    CategoryLabel,
    NonExpandableSection,
    ConditionalTooltipText,
    ConditionalTitleText,
    LeadingDemographicDescription,
    TrailingDemographicDescription,
    IncludedItemsHeader,
    OptionalItemsHeader,
}
