import type { ReactNode } from 'react'
import { RadioButton } from '@energage/components'
import styled from 'styled-components'

export const PreviewTitleDisplay = ({ children }: { children: ReactNode }) => (
    <div className="text-sm text-primary-dark mt-2">{children}</div>
)

const Li = styled.li`
    .radio-button__label {
        word-wrap: anywhere;
    }
`

export function MultiOptionPreview({
    name,
    options,
    small = false,
}: {
    name: string
    options?: { id: string | number; name: string }[]
    small?: boolean
}) {
    return (
        <ul className="flex flex-col gap-3 text-grey500 list-none pl-0 mt-4">
            {options?.map((option) => (
                <Li key={option.id}>
                    <RadioButton className={small ? 'text-xs' : undefined} disabled name={name} label={option.name} />
                </Li>
            ))}
        </ul>
    )
}
