import { useEffect } from 'react'
import { alert } from '@energage/components'
import { useBrandingQuery } from 'api'
import { useIdentity } from 'components/Identity'

export const useDownloadBrandingPack = () => {
    const { surveyCompanyId } = useIdentity()

    const {
        data,
        mutateAsync: download,
        isLoading,
        isError,
    } = useBrandingQuery.mutate<
        { fileContents: string; contentType: string; fileDownloadName: string },
        unknown,
        unknown
    >(`Dashboard/${surveyCompanyId}/employeepack/download`)

    useEffect(() => {
        if (data) {
            const { fileContents, contentType, fileDownloadName } = data

            // Decode the base64 string to binary data
            const binaryData = atob(fileContents)

            // Create an ArrayBuffer from the binary data
            const uint8Array = Uint8Array.from(binaryData, (char) => char.charCodeAt(0))

            // Create a Blob from the ArrayBuffer
            const blob = new Blob([uint8Array], { type: contentType })

            // Create a temporary URL for the Blob
            const url = URL.createObjectURL(blob)

            // Create a temporary link element and trigger the download
            const link = document.createElement('a')
            link.href = url
            link.download = fileDownloadName
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
            alert.primary('Success! Your files have successfully downloaded.')
        }
    }, [data])

    useEffect(() => {
        if (isError) {
            alert.danger('Something went wrong')
        }
    }, [isError])

    return { downloadAssets: () => download({}), isDownloading: isLoading }
}
