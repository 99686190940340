import { Card } from '@energage/components'
import type { CardProps } from '@energage/components'
import styled from 'styled-components'

export const SummaryCard = styled((props: CardProps) => <Card {...props} />)`
    display: flex;
    width: 635px;
    height: 208px;
    padding: 16px;
    gap: 16px;
    border-radius: 8px;

    @media (max-width: 768px) {
        width: 360px;
        min-height: 282px;
        height: auto;
        padding: 16px;
        gap: 16px;
        flex-shrink: 0;
        border-radius: 8px;
    }
`
