export const SurveyType = {
    Standard: 1,
    WorkplaceTrending: 21,
    Custom: 23,
} as const

export type SurveyTypeOption = typeof SurveyType[keyof typeof SurveyType]

export const TemplateCategory = {
    Standard: 1,
    Pulse: 2,
    OperationalReadiness: 3,
    Custom: 4,
    CultureTopics: 5,
    RemoteSupport: 6,
    SpecialTopics: 7,
    TrendingTopics: 8,
    WXTS: 9, // workplace experience themes and statements
    OtherCultureTopics: 10,
} as const

export const TemplateCategoryNames = {
    [TemplateCategory.Standard]: 'Workplace',
    [TemplateCategory.Pulse]: 'Pulse',
    [TemplateCategory.OperationalReadiness]: 'Operational Readiness',
    [TemplateCategory.Custom]: 'Custom',
    [TemplateCategory.CultureTopics]: 'Culture Topics',
    [TemplateCategory.RemoteSupport]: 'Remote Support',
    [TemplateCategory.SpecialTopics]: 'Special Topics',
    [TemplateCategory.TrendingTopics]: 'Trending Topics',
    [TemplateCategory.WXTS]: 'Workplace Experience Themes and Statements',
    [TemplateCategory.OtherCultureTopics]: 'Other Culture Topics',
}

export default SurveyType
