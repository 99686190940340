import { useCallback } from 'react'
import { alert } from '@energage/components'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePost } from 'hooks'
import { useScheduleActiveSurveysSync } from '../../UpdateActiveSurveys'
import useSetTestInviteesDemographics from '../../useSetTestInviteesDemographics'

function useRemoveEmployee(employee, onComplete) {
    const { organizationId } = useIdentity()
    const { doSync } = useScheduleActiveSurveysSync()
    const { mutate: doSetTestInviteesDemographics } = useSetTestInviteesDemographics()
    const { doPost, isPosting, reset } = usePost({
        url: `${config.api.platform}/Organizations/${organizationId}/Members/RemoveMember`,
        onComplete: (data) => {
            onComplete(data)
            alert.success(`Employee removed successfully.`)
        },
        onError: (error) => {
            alert.danger(`There was an error in removing employee :\n${error.message}`)
            reset()
        },
    })

    const remove = useCallback(() => {
        doPost({ memberId: employee.id })
            .then(doSync)
            .then(() => doSetTestInviteesDemographics())
    }, [doPost, doSync, employee, doSetTestInviteesDemographics])

    return { remove, isRemoving: isPosting }
}

export default useRemoveEmployee
