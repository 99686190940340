import { useMemo, useReducer } from 'react'
import { createAction, createDispatchableActions } from 'util/actionCreator'
import { buildFiltersDataOnSuccess, calculateFetchUrl } from './buildFiltersDataOnSuccess'

const CONTENT_FILTER = 'ContentFilter'

const ActionTypes = {
    UPDATE_DATA_ON_SUCCESS: 'UpdateDataOnSuccess',
    HAS_UPDATE_ASSET: 'HasUpdateAsset',
    SET_CURRENT_SELECTED_FILTER: 'SetCurrentSelectedFilter',
    SET_FILTER_DATA: 'SetFilterData',
    RESET_ASSET_LIBRARY_FLAG: 'ResetAssetLibraryFlag',
    RESET_ASSET_FILTER_DATA: 'ResetAssetFilterData',
}

const actions = {
    updateDataOnSuccess: createAction(ActionTypes.UPDATE_DATA_ON_SUCCESS),
    hasUpdateAsset: createAction(ActionTypes.HAS_UPDATE_ASSET),
    setCurrentSelectedFilter: createAction(ActionTypes.SET_CURRENT_SELECTED_FILTER),
    setFilterData: createAction(ActionTypes.SET_FILTER_DATA),
    resetAssetLibraryFlag: createAction(ActionTypes.RESET_ASSET_LIBRARY_FLAG),
    resetAssetFilterData: createAction(ActionTypes.RESET_ASSET_FILTER_DATA),
}

const defaultState = {
    filterData: {
        surveyEventName: '',
        Locations: '',
        Departments: '',
        JobRoles: '',
    },
    currentSelectedFilter: {
        surveyEventName: '',
        Locations: -1,
        Departments: -1,
        JobRoles: -1,
    },
    persistEventName: {},
    programs: {},
    showFilterCount: {},
    assetFilterData: {},
    assetLibraryFlag: false,
    isPulseSurvey: false,
    isUpdateAsset: false,
    brandingResultsDisabledSurveys: {},
    benchmarksNotAvailableSurveys: {},
    serviceURL: CONTENT_FILTER,
    isAllSurveysDisabled: false,
    error: {},
    isLoading: true,
}

const getNewState = (state, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_DATA_ON_SUCCESS: {
            return { ...buildFiltersDataOnSuccess(state, action.payload) }
        }
        case ActionTypes.HAS_UPDATE_ASSET: {
            const { data } = action.payload
            return {
                ...state,
                isUpdateAsset: data,
            }
        }
        case ActionTypes.SET_CURRENT_SELECTED_FILTER: {
            const data = action.payload
            const currentSelectedFilter = { ...state.currentSelectedFilter, ...data }
            return {
                ...state,
                currentSelectedFilter,
                filterData: { ...state.filterData, ...data },
                serviceURL: calculateFetchUrl(currentSelectedFilter),
            }
        }
        case ActionTypes.SET_FILTER_DATA: {
            const data = action.payload
            return { ...state, filterData: { ...state.filterData, ...data } }
        }
        case ActionTypes.RESET_ASSET_LIBRARY_FLAG: {
            return { ...state, assetLibraryFlag: action.payload }
        }
        case ActionTypes.RESET_ASSET_FILTER_DATA: {
            return { ...state, assetFilterData: action.payload }
        }
        default:
            return { ...state }
    }
}

const reducer = (state, action) => {
    const newState = getNewState(state, action)
    return newState
}

const useFiltersDataReducer = () => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const dispatchableActions = useMemo(() => createDispatchableActions(actions, dispatch), [dispatch])

    return {
        state,
        actions: dispatchableActions,
    }
}

export default useFiltersDataReducer
