import PropTypes from 'prop-types'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation'
import { memberShape } from '../propTypes'

const RemoveMember = ({ member, onMemberRemove }) => {
    const onAccept = () => {
        onMemberRemove(member)
    }

    return (
        <ButtonWithConfirmation
            variant="link"
            className="p-0"
            eventName={'Remove employees'}
            onAccept={onAccept}
            confirmationMessage={`Are you sure you want to remove ${member.name} from this department? They'll be placed in the top-level group. Changes will not occur until you save.`}>
            {'Remove'}
        </ButtonWithConfirmation>
    )
}

RemoveMember.propTypes = {
    member: PropTypes.shape(memberShape).isRequired,
    onMemberRemove: PropTypes.func.isRequired,
}

export default RemoveMember
