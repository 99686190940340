import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T) {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

export function usePreviousWX<T>(value: T): T | undefined {
    const ref = useRef<{ next: T | undefined; stale: T | undefined }>({
        next: undefined,
        stale: undefined,
    })

    useEffect(() => {
        if (ref.current.next !== value) {
            ref.current.stale = ref.current.next
        }

        ref.current.next = value
    }, [value])

    return ref.current.next === value ? ref.current.stale : ref.current.next
}
