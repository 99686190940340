import every from 'lodash/every'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import intersectionBy from 'lodash/intersectionBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import max from 'lodash/max'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import SurveyFormats from './Recipients/FormatSurvey/SurveyFormats'
import { SetupTask } from './workflow'

function getTaskStatusFromWorkflow(workflow) {
    if (!workflow) {
        return {}
    }

    const { tasks, statuses } = workflow
    const completedTaskIds = map(statuses, (s) => s.taskDefinitionId)

    return reduce(
        tasks,
        (taskStatuses, { taskDefinition }) => {
            taskStatuses[taskDefinition] = includes(completedTaskIds, taskDefinition)
            return taskStatuses
        },
        {}
    )
}

function getTaskStatusCompletedDateTimeFromWorkflow(workflow) {
    if (!workflow) {
        return {}
    }

    const { tasks, statuses } = workflow

    return reduce(
        tasks,
        (taskStatuses, { taskDefinition }) => {
            taskStatuses[taskDefinition] =
                max(
                    map(
                        filter(statuses, (s) => s.taskDefinitionId === taskDefinition),
                        (s) => s?.completedDateTime
                    )
                ) ?? null
            return taskStatuses
        },
        {}
    )
}

const TASK_DISABLED_MESSAGE = 'This step is not necessary for your survey delivery format.'

function getStatusByPage(workflowTasks, taskStatuses, data, isSuperUser) {
    const workflowProgressTasks = data?.tasks
    const surveyFormatId = data?.surveyFormatId

    if (isEmpty(taskStatuses) || isEmpty(workflowProgressTasks)) {
        return {}
    }

    return reduce(workflowTasks, setStatusForTask, {})

    function setStatusForTask(pages, { id, children, clientOnly, initialInProgress, alwaysEnabled }) {
        let status = null,
            disabled =
                alwaysEnabled !== true && !taskStatuses.hasOwnProperty(id) && !clientOnly && TASK_DISABLED_MESSAGE
        if (children) {
            const relevantTasks = getRelevantTasks(children, workflowProgressTasks)
            const completedCount = filter(relevantTasks, (child) => taskStatuses[child.id]).length

            if (relevantTasks.length !== 0 && completedCount === relevantTasks.length) {
                status = 'complete'
            } else if (completedCount > 0 || initialInProgress) {
                status = 'in-progress'
            }

            disabled =
                alwaysEnabled !== true &&
                every(children, (child) => !taskStatuses.hasOwnProperty(child.id)) &&
                TASK_DISABLED_MESSAGE
            forEach(children, (child) => setStatusForTask(pages, child))
        } else if (taskStatuses[id]) {
            status = 'complete'
        }

        if (id === SetupTask.Recipients && isSuperUser === true && surveyFormatId === SurveyFormats.Links) {
            disabled = false
        }

        pages[id] = { status, disabled }

        return pages
    }
}

function getFirstIncompletePage(workflowTasks, taskStatuses) {
    if (!taskStatuses) {
        return null
    }

    const firstIncompleteStepIndex = findIndex(workflowTasks, ({ id, children, clientOnly }) =>
        children
            ? some(children, (child) => taskStatuses[child.id] === false)
            : taskStatuses[id] === false || clientOnly
    )

    return workflowTasks[Math.max(firstIncompleteStepIndex, 0)]
}

function getNextAvailablePage(workflowTasks, workflowProgress, currentPageId, taskStatuses) {
    const currentPageIndex = findIndex(workflowTasks, ({ id }) => id === currentPageId)
    if (!workflowProgress) {
        return workflowTasks[Math.min(currentPageIndex + 1, workflowTasks.length - 1)]
    }

    const nextPageIndex = findIndex(workflowTasks, isTaskAvailable, currentPageIndex + 1)
    return workflowTasks[
        nextPageIndex < 0 ? workflowTasks.length - 1 : Math.min(nextPageIndex, workflowTasks.length - 1)
    ]

    function isTaskAvailable({ id, children, clientOnly }) {
        if (children) {
            return every(getRelevantTasks(children, workflowProgress.tasks), (child) =>
                taskStatuses.hasOwnProperty(child.id)
            )
        }

        return taskStatuses.hasOwnProperty(id) || clientOnly
    }
}

function getRelevantTasks(children, workflowProgressTasks) {
    return intersectionBy(children, workflowProgressTasks, (task) => task.taskDefinition ?? task.id)
}

export {
    getStatusByPage,
    getFirstIncompletePage,
    getNextAvailablePage,
    getTaskStatusFromWorkflow,
    getTaskStatusCompletedDateTimeFromWorkflow,
    getRelevantTasks,
}
