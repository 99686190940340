import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, InputGroup, LegacyButton as Button, Select } from '@energage/components'
import find from 'lodash/find'
import { useForm } from 'react-hook-form-old'
import styled from 'styled-components'
import { DatePicker, datePickerStyles } from 'components/DateTimePicker'
import { getErrorMessage } from 'util/validators'
import { memberShape, optionTagShape } from '../../propTypes'
import { UpdateActiveSurveysCheckbox } from '../../UpdateActiveSurveys'
import SelectDepartment from './SelectDepartment/SelectDepartment'
import SelectLocationName from './SelectLocationName/SelectLocationName'

const Section = styled.div.attrs({ className: 'text-sm mb-4' })`
    ${datePickerStyles};
`
const popperModifiers = {
    flip: {
        behavior: 'clockwise',
    },
}

const StyledDatePicker = styled(DatePicker).attrs({ inputClassName: 'h-12', popperModifiers })`
    padding: 0 !important;
    width: 100% !important;
    border: none !important;
`
const hoursOptions = [
    { value: false, label: 'Part Time' },
    { value: true, label: 'Full Time' },
]

const jobRoleOptions = [
    { value: 'Senior Manager', label: 'Senior Manager' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Team Member', label: 'Team Member' },
]

const FormEmployee = ({ onSubmit, onClose, allDepartments, locations, isSaving, employee = {} }) => {
    const { errors, register, handleSubmit, setValue, formState, reset } = useForm({
        defaultValues: employee,
    })

    const [deptId, setDeptId] = useState(employee.department)
    const [locationNameId, setLocationNameId] = useState(employee.locationNameId)
    const [startDate, setStartDate] = useState(employee.startDate)

    useEffect(() => {
        register({ name: 'departmentId' })
        register({ name: 'startDate' })
        register({ name: 'isFullTime' })
        register({ name: 'jobGrade' })
        register({ name: 'locationNameId' })
    }, [register])

    const handleClose = () => {
        reset()
        onClose()
    }

    const handleChangeStartDate = useCallback(
        (data) => {
            setValue('startDate', data?.toISOString())
            setStartDate(data)
        },
        [setStartDate, setValue]
    )

    const handleChangeHours = useCallback(
        ({ value }) => {
            setValue('isFullTime', value)
        },
        [setValue]
    )

    const handleChangeJobRole = useCallback(
        ({ value }) => {
            setValue('jobGrade', value)
        },
        [setValue]
    )

    const handleSelectDepartment = useCallback(
        (data) => {
            setDeptId(data.id)
            setValue('departmentId', data.id)
        },
        [setValue, setDeptId]
    )

    const handleSelectLocation = useCallback(
        (data) => {
            setLocationNameId(data.id)
            setValue('locationNameId', data.id)
        },
        [setValue, setLocationNameId]
    )

    return (
        <form data-testid="org-management-employee-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="p-3 clearfix">
                <div className="flex flex-col md:flex-row mb-3">
                    <Input
                        className="flex-1 md:pr-3"
                        name="emailAddress"
                        label="Email"
                        autoComplete="off"
                        info={getErrorMessage(errors, 'emailAddress')}
                        error={!!errors.emailAddress}
                        setValue={setValue}
                        required
                        ref={register({
                            validate: (value) => {
                                if (!value) {
                                    return null
                                }
                                if (!/\S+@\S+\.\S+/.test(value)) {
                                    return 'Invalid email'
                                }
                            },
                        })}
                    />
                    <Input
                        className="flex-1 md:pl-3"
                        name="phoneNumber"
                        label="Phone"
                        autoComplete="off"
                        setValue={setValue}
                        info={getErrorMessage(errors, 'phoneNumber')}
                        error={!!errors.phoneNumber}
                        ref={register({
                            validate: (value) => {
                                if (!value) {
                                    return null
                                }

                                if (value.length > 15) {
                                    return 'Must be at most 15 characters'
                                }
                            },
                        })}
                    />
                </div>
                <div className="flex flex-col md:flex-row mb-3">
                    <SelectDepartment
                        allDepartments={allDepartments}
                        departmentId={deptId}
                        onNewDeptSelect={handleSelectDepartment}
                    />
                    <Input
                        className="flex-1 md:pl-3"
                        name="firstName"
                        label="First Name"
                        autoComplete="off"
                        setValue={setValue}
                        ref={register}
                    />
                </div>
                <div className="flex flex-col md:flex-row mb-3">
                    <Input
                        className="flex-1 md:pr-3"
                        name="lastName"
                        label="Last Name"
                        autoComplete="off"
                        ref={register}
                        setValue={setValue}
                    />
                    <div className="input-group flex-1 md:pl-3 input-group--md">
                        <span>{'Start date'}</span>
                        <Section>
                            <StyledDatePicker date={startDate} onChange={handleChangeStartDate} />
                        </Section>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mb-3">
                    <InputGroup
                        className="flex-1 md:pr-3"
                        name="isFullTime"
                        label="Hours"
                        as={Select}
                        id="hours"
                        options={hoursOptions}
                        onChange={handleChangeHours}
                        defaultValue={find(hoursOptions, (x) => x.value === employee.isFullTime)}
                    />
                    <InputGroup
                        className="flex-1 md:pl-3"
                        name="jobGrade"
                        label="Job Role"
                        as={Select}
                        id="jobGrade"
                        options={jobRoleOptions}
                        onChange={handleChangeJobRole}
                        defaultValue={find(jobRoleOptions, (x) => x.value === employee.jobGrade)}
                    />
                </div>
                {locations?.length > 0 && (
                    <div className="flex flex-col md:flex-row mb-3">
                        <SelectLocationName
                            allLocationNames={locations}
                            locationNameId={locationNameId}
                            onNewLocSelect={handleSelectLocation}
                        />
                    </div>
                )}
                <div className="flex flex-col md:flex-row mb-3">
                    <Input
                        className="flex-1"
                        name="approximateSalary"
                        label="Salary"
                        autoComplete="off"
                        ref={register}
                        setValue={setValue}
                    />
                </div>
                <div className="flex justify-end">
                    <UpdateActiveSurveysCheckbox />
                    <Button
                        variant="primary"
                        disabled={isSaving}
                        eventName={'Cancel edit team'}
                        outline
                        onClick={handleClose}>
                        {'Cancel'}
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSaving || !formState.dirty}
                        variant="primary"
                        eventName={'Save edit team'}
                        className="ml-6">
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </Button>
                </div>
            </div>
        </form>
    )
}

FormEmployee.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    allDepartments: PropTypes.arrayOf(PropTypes.shape(optionTagShape)),
    employee: PropTypes.shape(memberShape),
}

export default FormEmployee
