import type { ComponentType, ReactNode } from 'react'
import { Accordion } from '@energage/components'
import { KeyboardArrowDown, KeyboardArrowUp } from '@energage/icons'
import cx from 'clsx'
import map from 'lodash/map'
import { Spacer } from 'components'
import type {
    AwardSelection,
    AwardSelectionTableProps,
    OverlappingGroup,
    SelectAllCheckboxProps,
    SelectRowCellProps,
} from '../awardselection.types'
import { Label } from './AwardSelectionTable.atoms'

const checkboxSpacer = <Spacer axis="horizontal" size={24} />

type AwardSelectionTableMobileProps = AwardSelectionTableProps & {
    awardLayout: ComponentType<{
        award: AwardSelection
        className?: string
        index: number
        overlappingGroups: OverlappingGroup[]
    }>
    renderTitle(award: AwardSelection): ReactNode
    onSelectAll(checked: boolean): void
    selectAllCheckbox: ComponentType<SelectAllCheckboxProps>
    selectRowCheckbox: ComponentType<SelectRowCellProps>
    canSelectNational: boolean
    overlappingGroups: OverlappingGroup[]
}
export function AwardSelectionTableMobile({
    className,
    awards,
    onSelectAll,
    awardLayout: AwardLayout,
    renderTitle,
    canSelectNational,
    selectAllCheckbox: SelectAllCheckbox,
    selectRowCheckbox: SelectRowCheckbox,
    overlappingGroups,
}: AwardSelectionTableMobileProps) {
    return (
        <div className={className}>
            <section className="flex flex-col">
                <div className="flex items-center py-3 border border-grey300">
                    <Spacer axis="horizontal" size={33} />
                    <SelectAllCheckbox awards={awards} onSelectAll={onSelectAll} />
                    {checkboxSpacer}
                    <Label>{'Awards'}</Label>
                </div>
                {map(awards, (award, i) => {
                    const bgColor = i % 2 === 0 ? 'bg-grey100' : 'bg-white'

                    return (
                        <Accordion
                            key={award.rhfKey}
                            defaultOpen={true}
                            className="border border-grey300"
                            renderTrigger={({ open, toggle }) => {
                                const Icon = open ? KeyboardArrowUp : KeyboardArrowDown
                                return (
                                    <div className={cx('w-full flex items-center py-2', bgColor)}>
                                        <Spacer axis="horizontal" size={6} />
                                        <button type="button" onClick={() => toggle()}>
                                            <Icon style={{ minWidth: '24px' }} />
                                        </button>
                                        <Spacer axis="horizontal" size={3} />
                                        <SelectRowCheckbox
                                            canSelectNational={canSelectNational}
                                            index={i}
                                            isReadOnly={award.isFinalized}
                                        />
                                        {checkboxSpacer}
                                        <button
                                            type="button"
                                            className="w-full text-left paragraph-small text-bold"
                                            onClick={() => toggle()}>
                                            {renderTitle(award)}
                                        </button>
                                    </div>
                                )
                            }}>
                            <div className={cx(className, 'flex py-3')}>
                                <Spacer axis="horizontal" size={73} />
                                <div>
                                    <AwardLayout
                                        award={award}
                                        className={bgColor}
                                        index={i}
                                        overlappingGroups={overlappingGroups}
                                    />
                                </div>
                            </div>
                        </Accordion>
                    )
                })}
            </section>
        </div>
    )
}
