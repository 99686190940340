import map from 'lodash/map'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'

async function getSurveyHistory(surveyPage, api, surveyCompanyId) {
    const data = await api.json(`surveyhistory/${surveyCompanyId}/${surveyPage ?? ''}`)
    return map(data.surveyEvents, (surveyHistoryItem) => {
        return { ...surveyHistoryItem, status: surveyHistoryItem.surveyHistoryItemStatus }
    })
}

export default function useFetchSurveyHistory(surveyPage) {
    const { surveyCompanyId } = useIdentity()

    const { data, status, error } = useWorkplaceSurveyQuery(['survey-history', surveyPage], (_, api) =>
        getSurveyHistory(surveyPage, api, surveyCompanyId)
    )

    return {
        surveyHistory: data,
        surveyHistoryFetchStatus: status,
        surveyHistoryError: error,
    }
}
