// @energage-ignore sort-imports
import './polyfills'
import { createRoot } from 'react-dom/client'
import { butterBar } from '@energage/components'
import * as Fullstory from '@fullstory/browser'
// TODO: Replace with original package when fixed
import ScrollMemory from '@wpd/react-router-scroll-memory'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { getUserConfirmation } from 'components/Prompt'
import store from 'store'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { getBrowserName } from './util/getBrowserName'

if (process.env.NODE_ENV !== 'development') {
    const offlinePlugin = require('offline-plugin/runtime')
    offlinePlugin.install({
        onUpdated() {
            window.location.reload()
        },
        onUpdateReady() {
            offlinePlugin.applyUpdate()
        },
    })
}

// Do not remove, it's used ocasionally on local development
// if (process.env.NODE_ENV === 'development') {
//     const { worker } = require('./mocks/browser')
//     worker.start()
// }

const appRoot = document.getElementById('dashboard-root')

butterBar.subscribe(() => {
    const butterBarHeight = butterBar.getHeightAfterTransition()
    appRoot.style.transition = 'padding-top 250ms'
    appRoot.style.paddingTop = butterBarHeight + 'px'
})

if (!window.CSS.supports('container-name: test')) {
    const browserName = getBrowserName() ?? 'your browser'
    butterBar.info(
        <>{`It looks like you are using an older browser version. This site works best in the latest version of ${browserName}.`}</>,
        { announce: true, dismissible: true }
    )
}

function renderApp() {
    Modal.setAppElement(appRoot)

    const app = (
        <>
            <Provider store={store}>
                <Router getUserConfirmation={getUserConfirmation}>
                    <div>
                        <ScrollMemory />
                        <App />
                    </div>
                </Router>
            </Provider>
        </>
    )

    const root = createRoot(appRoot)
    root.render(app)

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister()
}

Fullstory.init({ orgId: '$FULLSTORY_KEY' })

renderApp()
