import map from 'lodash/map'

export function ComplexConditionDescription({
    descriptions,
    className,
}: {
    descriptions: string[] | null
    className?: string
}) {
    if (descriptions === null || descriptions.length === 0) {
        return null
    }
    if (descriptions.length === 1) {
        return (
            <div
                className={
                    className
                }>{`This question will only be shown to responders who identify as: ${descriptions[0]}.`}</div>
        )
    }

    return (
        <div className={className}>
            <p>
                {
                    'This question will only be displayed to responders who meet one criteria from each of the targeted demographics:'
                }
            </p>
            <ul>
                {map(descriptions, (part, i) => (
                    <li key={i}>{part}</li>
                ))}
            </ul>
        </div>
    )
}
