import type { MouseEvent } from 'react'
import { ArrowDropDown, ArrowDropUp, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import { ASC, DEFAULT } from 'containers/Main/EmployerRecognition/Shared/getSortedData'

type SortingIconProps = {
    columnName: string
    activeColumnName: string
    sortingOrder: number | boolean
    onSort?: (e: MouseEvent<HTMLElement>) => void
}

export const SortingIcon = ({ columnName, activeColumnName, sortingOrder, onSort }: SortingIconProps) => {
    return (
        <button className="focus:outline-none" id={columnName} onClick={onSort}>
            <Icon
                color={
                    Number(sortingOrder) > DEFAULT && activeColumnName === columnName ? colors.grey600 : colors.grey400
                }
                as={Number(sortingOrder) > ASC && activeColumnName === columnName ? ArrowDropUp : ArrowDropDown}
                size={22}
            />
        </button>
    )
}
