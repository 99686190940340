import type { ReactNode } from 'react'
import cx from 'clsx'
import styled from 'styled-components'

type InsetProps = {
    padding?: boolean
    className?: string
    children: ReactNode
}
export const Inset = styled((props: InsetProps) => <InsetComponent {...props} />)`
    ${(props) =>
        props.padding &&
        `

    @media (max-width: 768px) {
           align-items: center;
           justify-content: center;
        }
    `}
`

export const InsetComponent = ({ padding = false, className, children }: InsetProps) => (
    <div className={cx({ 'p-4 sm:p-6': padding }, className)}>{children}</div>
)

export default Inset
