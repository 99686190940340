import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as TWPLogo } from 'images/twp-widget-logo.svg'

const className = 'absolute text-black font-medium leading-none text-base text-center text-darkblue700'

const AwardSpecificLogo = styled.img`
    max-width: 130px;
    height: 170px;
`

const Year = styled.p.attrs({ className: className })`
    font-size: 14px;
    left: 40px;
    top: 122px;
`

const YearsRange = styled.p.attrs({ className: className })`
    font-size: 14px;
    left: 20px;
    top: 122px;
`

const LeftAligned = ({ year, awardLogoUrl, namedStreakYears, yearsRange, widgetText }) => (
    <div className="flex p-4">
        <div className="relative">
            {awardLogoUrl ? (
                <>
                    <AwardSpecificLogo src={awardLogoUrl} alt="Award Logo" />
                    {year && <Year>{year}</Year>}
                    {yearsRange && <YearsRange>{yearsRange}</YearsRange>}
                </>
            ) : (
                <TWPLogo />
            )}
        </div>
        <div className="px-3 self-center text-2xl font-medium leading-tight text-grey600">
            <p>{widgetText}</p>
            {namedStreakYears && <p>{`${namedStreakYears} Years Running`}</p>}
        </div>
    </div>
)

LeftAligned.propTypes = {
    companyName: PropTypes.string.isRequired,
    awardLogoUrl: PropTypes.string,
    year: PropTypes.number,
    namedStreakYears: PropTypes.number,
    yearsRange: PropTypes.string,
}

export default LeftAligned
