import { REMOTE_WORK_AWARD_ID, WOMEN_LED_AWARD_ID } from 'containers/Main/EmployerRecognition/constants'

export const HAS_AGREED = 1

export const HAS_NOT_AGREED = 0

export const AwardEligibilityCriteria = {
    [WOMEN_LED_AWARD_ID]: {
        criteria: {
            awardInfoRow: {
                label: 'Award Requirements: ',
                value: 'Organizations are eligible if their most senior executive is a woman. If the most senior role is shared, at least 50% representation is required to qualify.',
            },
            rangeModal: {
                requirement:
                    'Is your most senior executive at your organization a woman? If the most senior role is shared, at least 50% representation is required. Can you confirm your organization qualifies for this award?',
            },
        },
    },
    [REMOTE_WORK_AWARD_ID]: {
        criteria: {
            awardInfoRow: {
                label: 'Award Requirements: ',
                value: 'At least 50% of organization workforce is mostly or fully remote and committed to hiring more remote employees.',
            },
            rangeModal: {
                requirement:
                    'Is your organization at least 50% remote, and are you committed to hiring remote employees in the future?',
            },
        },
    },
}
