import concat from 'lodash/concat'
import get from 'lodash/get'
import map from 'lodash/map'
import {
    complimentaryServices,
    getAutoRenewalDescription,
    multiYearTermDescription,
    serviceAndPaymentTerms,
} from 'containers/Main/OrderManagement/Orders/OrderCart/text'
import { getProductsByCategory } from '../../common/utils'

const TermContent = ({ disableAutoRenewal, removeIncreaseLimit, selectedPackages, selectedAddOns }) => (
    <div>
        <ol>
            {!disableAutoRenewal && (
                <li className="font-bold">
                    {'Auto-renewal Terms'}
                    <p className="font-light pl-5 text-justify">{getAutoRenewalDescription(removeIncreaseLimit)}</p>
                </li>
            )}
            <li className="font-bold">
                {'Multi-Year Terms'}
                <p className="font-light pl-5 text-justify">{multiYearTermDescription()}</p>
            </li>
            <li className="font-bold">{'Service-Specific Terms'}</li>
        </ol>
        <div>
            <ServiceSpecificTerm products={selectedPackages} />
            <p className="font-bold">{'Complimentary Services'}</p>
            <p className="pl-5 text-justify">{complimentaryServices}</p>
            <ServiceSpecificTerm products={selectedAddOns} />
        </div>
    </div>
)

const ServiceAndPaymentTerm = () =>
    map(serviceAndPaymentTerms(), (x, i) => (
        <div key={i}>
            <p className="font-bold">{x.heading}</p>
            <p className="pl-5 text-justify">{x.text}</p>
        </div>
    ))

const ServiceSpecificTerm = ({ products }) =>
    map(products, (x, i) =>
        x.isBundle ? (
            map(x.renewalsTerms, (term, index) => (
                <li key={index} className="font-bold pl-10">
                    {term?.title}
                    <p className="pl-6 font-light text-justify">{term?.text}</p>
                </li>
            ))
        ) : (
            <li key={i} className="font-bold pl-10">
                {x.renewalsTerms?.title}
                <p className="pl-6 font-light text-justify">{x.renewalsTerms?.text}</p>
            </li>
        )
    )

const ServiceTermContent = ({ newTerm, disableAutoRenewal, removeIncreaseLimit }) => {
    const { platformProducts, oneTimeAddOns } = newTerm
    const groupProduct = getProductsByCategory(platformProducts?.products)
    const baseProducts = get(groupProduct, 'Base Products', [])
    const serviceAddOns = get(groupProduct, 'Add On Services', [])
    const oneTimeAddons = get(getProductsByCategory(oneTimeAddOns?.addOns), 'Add On Services', [])

    const allAddons = concat(serviceAddOns, oneTimeAddons)
    return (
        <>
            <ServiceAndPaymentTerm />
            <TermContent
                disableAutoRenewal={disableAutoRenewal}
                removeIncreaseLimit={removeIncreaseLimit}
                selectedPackages={baseProducts}
                selectedAddOns={allAddons}
            />
        </>
    )
}

export default ServiceTermContent
