import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from '@energage/hooks'
import { Create } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import pick from 'lodash/pick'

function useInlineEdit(startingValue, submitChange) {
    const preventSubmit = useRef(false)
    const inputRef = useRef(null)
    const [currentValue, setCurrentValue] = useState(startingValue)
    const [editing, toggleEditing] = useToggle(false)

    useEffect(() => {
        if (!editing) {
            setCurrentValue(startingValue)
        }
    }, [startingValue, editing])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [editing])

    const onChange = useCallback((e) => {
        setCurrentValue(e.target.value)
    }, [])

    const onBlur = useCallback(() => {
        if (!preventSubmit.current) {
            submitChange(currentValue)
        }
        preventSubmit.current = false
        toggleEditing()
    }, [currentValue, submitChange, toggleEditing])

    const onKeyDown = useCallback((e) => {
        const key = e.key || e.keyCode
        switch (key) {
            case 27:
            case 'Esc':
            case 'Escape':
                preventSubmit.current = true
            // eslint-disable-next-line no-fallthrough
            case 13:
            case 'Enter':
                e.preventDefault()
                inputRef.current.blur()
                break
            default:
                break
        }
    }, [])

    return { editing, toggleEditing, currentValue, inputRef, onChange, onBlur, onKeyDown }
}

const inputProps = ['maxLength', 'minLength', 'min', 'max', 'name']

const InlineEdit = ({ className, css, value = '', placeholder, onChange, ...rest }) => {
    const {
        editing,
        toggleEditing,
        currentValue,
        inputRef,
        onChange: onInputChange,
        onBlur,
        onKeyDown,
    } = useInlineEdit(value, onChange)

    return (
        <div className="mr-3 whitespace-no-wrap sm:whitespace-normal overflow-hidden sm:overflow-auto w-full">
            {editing ? (
                <input
                    ref={inputRef}
                    className={cx(className, 'p-1 outline-none rounded border border-blue500 w-full max-w-md')}
                    type="text"
                    value={currentValue}
                    onChange={onInputChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    {...pick(rest, inputProps)}
                />
            ) : (
                <div
                    className={cx(className, 'p-1 rounded border border-transparent hover:border-grey300 w-full', {
                        'text-grey500': !value,
                    })}
                    css={css}
                    onClick={toggleEditing}>
                    {value || placeholder}
                    {(value || placeholder) && <Create size={25} color={colors.grey400} className="ml-5 -mt-1" />}
                </div>
            )}
        </div>
    )
}

InlineEdit.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: function (props, propName, componentName) {
        if (props.required && !props[propName]) {
            return new Error(`A placeholder should be provided if required is true on ${componentName}`)
        }
    },
}

export default InlineEdit
