import { memo, useEffect, useState } from 'react'
import { Dropdown, LegacyButton as Button } from '@energage/components'
import { KeyboardArrowLeft, Notifications } from '@energage/icons'
import cx from 'clsx'
import styled from 'styled-components'
import Bubble from 'components/Bubble'
import { useIdentity } from 'components/Identity'
import { useNotifyAboutUpcomingTasks } from 'containers/Main/EmployerRecognition/Shared/useNotifyAboutUpcomingTasks'
import { useVisibility } from 'hooks'
import ArchivedList from './ArchivedList'
import NotificationList from './NotificationList'
import useNotification from './useNotification'
import useNotificationHub from './useNotificationHub'
import useUpdateWorkflowNotifications from './useUpdateWorkflowNotifications'

const NotificationContainer = styled.div.attrs({ className: 'w-screen sm:max-w-xs overflow-y-auto' })`
    width: 95vw;
    max-height: 60vh;
`

const ArchiveViewButton = styled(Button).attrs({ className: 'p-0 text-grey500 font-light' })`
    font-size: 10px !important;
`

const NotificationIcon = ({ count }) => (
    <Bubble count={count}>
        <Notifications />
    </Bubble>
)

const useNotifyPendingTasks = () => {
    const [isNotified, setIsNotified] = useState(false)
    const { notify } = useNotifyAboutUpcomingTasks()
    const { organizationId } = useIdentity()

    useEffect(() => {
        if (!isNotified && organizationId) {
            setIsNotified(true)
            notify()
        }
    }, [isNotified, organizationId, setIsNotified, notify])
}

const NotificationMenu = () => {
    useNotificationHub()
    useUpdateWorkflowNotifications()
    const { notifications, archivedNotifications, unreadCount } = useNotification()
    const { visible, show, hide } = useVisibility()
    useNotifyPendingTasks()

    return (
        <Dropdown
            ariaLabel="Notifications"
            showArrow
            trigger={
                <button aria-label="Notifications">
                    <NotificationIcon count={unreadCount} />
                </button>
            }>
            {(popover) => (
                <div className="text-primary-dark">
                    <div className="h5 p-2 border-b flex justify-center items-center">
                        <Button
                            aria-label="Hide notifications"
                            onClick={hide}
                            variant="link"
                            className={cx('p-0 text-grey500', {
                                hidden: !visible,
                            })}>
                            <KeyboardArrowLeft />
                        </Button>
                        {visible ? 'Notification Archives' : 'Notifications'}
                    </div>
                    <NotificationContainer>
                        {visible ? (
                            <ArchivedList
                                archivedNotifications={archivedNotifications}
                                hideNotificationWindow={popover.hide}
                            />
                        ) : (
                            <NotificationList notifications={notifications} hideNotificationWindow={popover.hide} />
                        )}
                    </NotificationContainer>
                    <div
                        className={cx('p-2 border-t flex justify-end', {
                            hidden: visible,
                        })}>
                        <ArchiveViewButton variant="link" onClick={show}>
                            {'View Archives'}
                        </ArchiveViewButton>
                    </div>
                </div>
            )}
        </Dropdown>
    )
}

export default memo(NotificationMenu)
