import PropTypes from 'prop-types'
import cx from 'clsx'

const Tile = ({ children, title, footer, className, css, padding = true, fullHeight = true, ...delegated }) => (
    <div
        css={css}
        className={cx({
            'h-full': fullHeight,
            'py-3': padding,
        })}
        {...delegated}>
        <div className={cx('text-primary-dark bg-white rounded shadow-md flex flex-col', { 'h-full': fullHeight })}>
            {title && (
                <div className="text-grey500 paragraph uppercase border-grey300 border-b-2 pt-4 pb-2 px-4">{title}</div>
            )}
            <div className={cx('flex-1', className ? className : 'p-4')}>{children}</div>
            {footer}
        </div>
    </div>
)

Tile.propTypes = {
    title: PropTypes.node.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    padding: PropTypes.bool,
}

export default Tile
