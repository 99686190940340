import { Text } from '@energage/typography'
import cx from 'clsx'
import filter from 'lodash/filter'
import map from 'lodash/map'
import styled from 'styled-components'
import type { DataProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/LoveMyJobConfiguration/EmpComments.types'
import { previewType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

type ReelsPreviewProps = {
    data: DataProps
    selectedDesignType: number
}

const PreviewContainer = styled.div<{
    backgroundColor?: string
    highlightColor?: string
    width?: string
    height?: string
    isCircleDesignType?: boolean
}>`
    width: ${(props) => props.width ?? '340px'};
    min-height: ${(props) => props.height ?? '340px'};
    background-color: ${(props) => props.backgroundColor};
    background-image: ${(props) =>
        props.isCircleDesignType && `radial-gradient(circle, ${props.highlightColor} 0, ${props.backgroundColor} 55%)`};
`

const TextWrapper = styled.div<{ textColor: string }>`
    color: ${(props) => props.textColor};
`

const Heading = styled.h3<{ titleColor?: string }>`
    font-size: 96px;
    color: ${(props) => props.titleColor};
    line-height: 104%;
`

const Paragraph = styled.p<{ isDownloadable?: boolean }>`
    font-size: 48px;
    line-height: 136%;
`

export const EmpCommentsReelsPreview = ({ data, selectedDesignType }: ReelsPreviewProps) => {
    const selectedData = filter(data?.comments, 'isSelected')
    const isCircleDesignType = selectedDesignType === previewType.CircleDesign

    return (
        <PreviewContainer
            className="relative grid place-items-center p-16"
            width="1080px"
            height="1920px"
            backgroundColor={data?.backgroundColor}
            highlightColor={data?.highlightColor}
            isCircleDesignType={isCircleDesignType}>
            <div className="w-40 mt-8 mr-8 absolute top-0 right-0">
                <img src={CultureFactTWP} alt={'Top work places'} className="border rounded-full w-full" />
            </div>
            <TextWrapper className={isCircleDesignType ? 'text-center' : 'text-left'} textColor={data?.textColor}>
                <Text textStyle="title1" className="font-black">
                    <Heading titleColor={data?.titleColor}>{'I love my job because'}</Heading>
                </Text>

                {map(selectedData, ({ text }, index) => (
                    <div className={cx('mt-12', index + 1 === 2 ? 'text-right' : 'text-left')}>
                        {!isCircleDesignType && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="122"
                                height="82"
                                viewBox="0 0 122 82"
                                fill="none">
                                <path
                                    d="M16.6118 0.850745H51.8058L38.373 27.7164H54.358L54.358 81.4478L0.626703 81.4478L0.626703 32.8209L16.6118 0.850745ZM83.776 0.850745H118.97L105.537 27.7164H121.522L121.522 81.4478L67.7909 81.4478V32.8209L83.776 0.850745Z"
                                    fill={data?.titleColor}
                                />
                            </svg>
                        )}
                        <Text key={index} textStyle="lead" className="font-semibold">
                            <Paragraph>{`${text}`}</Paragraph>
                        </Text>
                    </div>
                ))}
            </TextWrapper>
        </PreviewContainer>
    )
}
