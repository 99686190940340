export const BASIC_EMAIL_REGEX = /\S+@\S+\.\S+/
export const NAME_REGEX = /^(?!\.)(?!.*[.'-]{2})(?!.* [.'])[A-Za-z.'-]+?([ A-Za-z][A-Za-z.'-]+?)*\s*$/i
export const ADDRESS_REGEX = /^\w+([ A-Za-z]+?)*( \w+.)*\s*$/i
export const CITY_REGEX = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*\s*$/i
export const NUMBER_REGEX = /^[0-9]+$/i

export const REQUIRED_MESSAGE = 'This is a required field'
export const NAME_LENGTH__MESSAGE = 'First name & Last Name both should not be the single character.'

export const ADD_CONTACT_FORM_SET = [
    {
        type: 'billedTo',
        label: 'Billing Information',
    },
    {
        type: 'soldTo',
        label: 'Contact Information',
    },
]

const missingFieldClass = 'w-full inline-block'

const fields = [
    [
        {
            meta: {
                name: 'firstName',
                type: 'text',
            },
            className: 'w-full sm:w-1/2 inline-block sm:pr-2',
            missingFieldClassName: missingFieldClass,
            label: 'First Name',
            validations: {
                required: REQUIRED_MESSAGE,
                minLength: {
                    value: 1,
                    message: 'First name should contain minimum 1 characters',
                },
                maxLength: {
                    value: 25,
                    message: 'First name should contain maximum 25 characters',
                },
                pattern: {
                    value: NAME_REGEX,
                    message: 'First name should be a valid string',
                },
            },
        },
        {
            meta: {
                name: 'lastName',
                type: 'text',
            },
            className: 'w-full sm:w-1/2 inline-block sm:pl-2',
            missingFieldClassName: missingFieldClass,
            label: 'Last Name',
            validations: {
                required: REQUIRED_MESSAGE,
                minLength: {
                    value: 1,
                    message: 'Last name should contain minimum 1 characters',
                },
                maxLength: {
                    value: 25,
                    message: 'Last name should contain maximum 25 characters',
                },
                pattern: {
                    value: NAME_REGEX,
                    message: 'Last name should be a valid string',
                },
            },
        },
    ],
    [
        {
            meta: {
                name: 'email',
                type: 'text',
            },
            className: 'w-full sm:w-1/2 inline-block sm:pr-2',
            missingFieldClassName: missingFieldClass,
            label: 'Email',
            validations: {
                required: REQUIRED_MESSAGE,
                minLength: {
                    value: 7,
                    message: 'Enter valid email address with minimum 7 characters',
                },
                maxLength: {
                    value: 65,
                    message: 'Enter valid email address with maximum 65 characters',
                },
                pattern: {
                    value: BASIC_EMAIL_REGEX,
                    message: 'Enter valid email address',
                },
            },
        },
        {
            meta: {
                name: 'address',
                type: 'text',
            },
            className: 'w-full sm:w-1/2 inline-block sm:pl-2',
            missingFieldClassName: missingFieldClass,
            label: 'Address',
            validations: {
                minLength: {
                    value: 7,
                    message: 'Address should contain minimum 7 characters',
                },
                maxLength: {
                    value: 100,
                    message: 'Address should contain maximum 100 characters',
                },
                pattern: {
                    value: ADDRESS_REGEX,
                    message: 'Please enter valid address',
                },
            },
        },
    ],
    [
        {
            meta: {
                name: 'city',
                type: 'text',
            },
            className: 'w-full sm:w-1/3 inline-block sm:pr-1',
            missingFieldClassName: missingFieldClass,
            label: 'City',
            validations: {
                minLength: {
                    value: 3,
                    message: 'City name should contain minimum 3 characters',
                },
                maxLength: {
                    value: 35,
                    message: 'City name should contain maximum 35 characters',
                },
                pattern: {
                    value: CITY_REGEX,
                    message: 'City name should be a valid string',
                },
            },
        },
        {
            meta: {
                name: 'state',
                type: 'select',
            },
            className: 'w-full sm:w-1/3 inline-block sm:px-1',
            missingFieldClassName: 'w-2/3 inline-block float-left',
            label: 'State',
        },
        {
            meta: {
                name: 'zipCode',
                type: 'number',
            },
            className: 'w-full sm:w-1/3 inline-block sm:pl-1',
            missingFieldClassName: 'w-1/3 inline-block float-left pl-1',
            label: 'Zip Code',
            validations: {
                minLength: {
                    value: 5,
                    message: 'Zip Code should contain 5 digits',
                },
                maxLength: {
                    value: 5,
                    message: 'Zip Code should contain 5 digits',
                },
                pattern: {
                    value: NUMBER_REGEX,
                    message: 'Zip Code should be a valid number',
                },
            },
        },
    ],
]

export default fields
