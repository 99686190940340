import { useMediaQuery } from '@energage/hooks'
import { viewports } from 'style/breakpoints'

export const MINIMUM_DROPLETS_REQUIRED = 3

export const TileWidth = (isPdf, width) => {
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMax })

    if (isMobileDevice) {
        if (isPdf) {
            return '60%'
        }

        return '100%'
    }

    return width
}

export const ToneOption = [
    {
        id: 'Fun',
        name: 'Fun / Informal / Light-hearted',
    },
    {
        id: 'Bold',
        name: 'Bold / Eye-catching / Adventurous',
    },
    {
        id: 'Professional',
        name: 'Professional / Serious / Formal',
    },
    {
        id: 'Empathetic',
        name: 'Empathetic / Kind-hearted / Gentle',
    },
    {
        id: 'Cheerful',
        name: 'Cheerful / Optimistic / Upbeat',
    },
]

export const errorMessage = 'Sorry, something went wrong on our end. Please click the Generate button again.'

export const previewType = {
    TriangleDesign: 1,
    CircleDesign: 2,
    Classical: 3,
    Reel: 4,
}
