import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import noop from 'lodash/noop'
import { useVisibility } from 'hooks'
import chain from 'util/chainFunctions'
import Inset from './Inset'
import { Modal } from './Modals'

const ButtonWithConfirmation = ({
    children,
    onReject,
    onAccept,
    acceptLabel,
    rejectLabel,
    disableAccept,
    confirmationMessage,
    eventName = children,
    actionVariant,
    title,
    additionalButtonBarContent,
    confirmationCheckboxLabel,
    modalCSS,
    modalBodyCSS,
    ...rest
}) => {
    const { visible, show, hide } = useVisibility()
    return (
        <>
            <Button onClick={show} eventName={eventName} {...rest}>
                {children}
            </Button>
            <Modal
                isOpen={visible}
                title={title}
                onClose={chain(onReject, hide)}
                className="xl:w-2/5 lg:w-3/5 md:w-2/3 sm:w-4/5 w-5/6"
                css={modalCSS}>
                <Inset padding>
                    <div className="text-xl whitespace-pre-wrap">{confirmationMessage}</div>
                </Inset>
                <Inset padding className="border-t clearfix">
                    <div className="flex justify-end" css={modalBodyCSS}>
                        {additionalButtonBarContent}
                        <Button
                            variant={actionVariant}
                            eventName={`${eventName} - ${rejectLabel}`}
                            outline
                            onClick={chain(onReject, hide)}>
                            {rejectLabel}
                        </Button>
                        <Button
                            variant={actionVariant}
                            eventName={`${eventName} - ${acceptLabel}`}
                            className="ml-3"
                            disabled={disableAccept}
                            onClick={chain(onAccept, hide)}>
                            {acceptLabel}
                        </Button>
                    </div>
                </Inset>
            </Modal>
        </>
    )
}

ButtonWithConfirmation.propTypes = {
    confirmationMessage: PropTypes.any.isRequired,
    additionalButtonBarContent: PropTypes.any,
    onAccept: PropTypes.func,
    eventName: function (props, propName, ...rest) {
        return typeof props.children === 'string'
            ? PropTypes.string(props, propName, ...rest)
            : PropTypes.string.isRequired(props, propName, ...rest)
    },
    onReject: PropTypes.func,
    acceptLabel: PropTypes.string,
    rejectLabel: PropTypes.string,
    actionVariant: PropTypes.string,
    title: PropTypes.string,
    confirmationCheckboxLabel: PropTypes.string,
}

ButtonWithConfirmation.defaultProps = {
    acceptLabel: 'Yes',
    rejectLabel: 'No',
    onAccept: noop,
    onReject: noop,
    actionVariant: 'secondary',
    disableAccept: false,
}

export default ButtonWithConfirmation
