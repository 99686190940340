import { colors } from '@energage/theme'
import cx from 'clsx'
import styled, { keyframes } from 'styled-components'

const HeaderCell = styled.div.attrs((props) => ({
    className: cx(
        props.className,
        'text-sm font-bold md:pr-0',
        { 'pl-4': props.spaced },
        { 'hidden md:block': props.collapse }
    ),
}))`
    .checkbox__container {
        margin-top: 1px;
    }

    .checkbox__indicator {
        border-radius: 2px;
        border-width: 1px;
    }
`

const highlightAnimation = (endColor) => keyframes`
    from {
        background-color: ${colors.blue50};
    }

    to {
        background-color: ${endColor};
    }
`

const TableRow = styled.div.attrs(() => ({
    className: 'text-xs w-full border-b p-3 last:b-0 border-grey300',
}))`
    background-color: ${colors.white};

    :nth-child(even) {
        background-color: ${colors.grey100};
    }

    &.is-highlighted:nth-child(even) {
        animation: ${highlightAnimation(colors.grey100)} 2s forwards;
    }

    &.is-highlighted:nth-child(odd) {
        animation: ${highlightAnimation(colors.white)} 2s forwards;
    }
`

const HeaderRow = ({ className, ...rest }) => (
    <div className={cx(className, 'p-3 text-xs w-full border-b-2 border-grey300 flex bg-white')} {...rest} />
)

const TableCell = styled.div.attrs((props) => ({
    className: cx({ 'align-top': props.alignTop }),
}))`
    label {
        max-width: 32rem;
        display: inline-block;
    }

    .checkbox__container {
        margin-top: 1px;
    }

    .checkbox__indicator {
        border-radius: 2px;
        border-width: 1px;
    }
`

const Table = ({ className, ...rest }) => (
    <div className={cx(className, 'w-full border-grey300 border border-b-0')} {...rest} />
)

export { HeaderCell, HeaderRow, Table, TableCell, TableRow }
