export type CompanyUserRolesDto = {
    admin: boolean
    brandingManager?: { enabled: boolean }
    insightsManager?: { enabled: boolean; departments: number[]; actionTrackingOnly: boolean }
    surveyManager?: { enabled: boolean }
    isCustomerSuccessPrimaryContact?: boolean
    securityManager?: { enabled: boolean }
}
export type CompanyUserPublisherRolesDto = {
    admin: boolean
    marketing?: { enabled: boolean }
    isCustomerSuccessPrimaryContact?: boolean
}

export type CompanyUserDepartmentDto = {
    id: number
    parentId: number
    name: string
    surveyEventId: number
    hierarchicalResponderCount: number
    responderCountSummaryThreshold: number
    hasActionTracker: boolean
    children: CompanyUserDepartmentDto[]
}

export type CompanyUserSurveyEventDto = {
    id: number
    name: string
    averageResponseDate: Date
    hierarchicalResponderCount: number
    responderCountSummaryThreshold: number
    topLevelDepartment: CompanyUserDepartmentDto
}

type CompanyUserBase = {
    id: number
    companyId: number
    email: string
    firstName: string
    lastName: string
    lastActiveDate: Date
    isDisabled: boolean
    securitySettings: { hasActiveMfa: boolean }
}
export type CompanyUserDto = CompanyUserBase & {
    roles: CompanyUserRolesDto | CompanyUserPublisherRolesDto
}

export type CompanyUserPublisher = CompanyUserBase & {
    roles: CompanyUserPublisherRolesDto
}

export type CompanyUser = CompanyUserBase & {
    roles: CompanyUserRolesDto
}

export type DepartmentNode = {
    id: number
    name: string
    parentId: number | null
    disabled: boolean
    children: DepartmentNode[]
}

export type SurveyNode = {
    surveyId: number
    surveyName: string
    topLevelDepartment: DepartmentNode
}

export type UserRolesType = {
    hasAdminAccess: boolean
    hasBrandingAccess: boolean
    hasInsightsAccess: boolean
    hasSurveyAccess: boolean
    departments?: number[]
    isActionTrackerRestriction: boolean
    hasSecurityAccess: boolean
}

export type PublisherRolesType = {
    hasMarketingAccess: boolean
    hasAdminAccess: boolean
}

export type UserSecuritySettings = {
    hasActiveMfa: boolean
}

export type UserType = {
    id: number
    firstName: string
    lastName: string
    email: string
    isCustomerSuccessPrimaryContact: boolean
    roles: UserRolesType | PublisherRolesType
    isDisabled: boolean
    securitySettings: UserSecuritySettings
}

export type ReportData = {
    firstName: string
    lastName: string
    email: string
    roles: string
    status: string
}

export enum Role {
    Inactive = 'Inactive',
    Admin = 'Admin',
    Branding = 'Branding',
    Insights = 'Insights',
    Survey = 'Survey',
}

export type ExistingUser = {
    id: number
    email: string
}

export type ValidationError = {
    code: string
    message: string
    attemptedValue: string | number | boolean
    propertyName: string
}

export type ErrorT = {
    parsed: { message: string; data: ValidationError }
}
