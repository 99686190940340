import PropTypes from 'prop-types'
import { Checkbox } from '@energage/components'
import { useAtomValue } from 'jotai/utils'
import includes from 'lodash/includes'
import map from 'lodash/map'
import some from 'lodash/some'
import QuestionCategory from '../../../../../StatementSetup/QuestionCategory'
import { DemographicOptionsProvider } from '../../../../../StatementSetup/QuestionTable/DemographicOptionsContext'
import QuestionTableContext from '../../../../../StatementSetup/QuestionTable/QuestionTableContext'
import { HeaderCell, HeaderRow, Table } from '../../../../../StatementSetup/QuestionTable/TableElements'
import { useDemographicQuestionToggles } from '../../../../../StatementSetup/statementSetupHooks/useDemographicQuestionToggles'
import { allQuestionsAtom, selectedQuestionsAtom } from '../../../../../StatementSetup/statementSetupUtility/store'
import DemographicQuestionTableRow from './DemographicQuestionTableRow'

const CategoryLabel = ({ title }) => (
    <div className="flex">
        <div className="w-1/4">{'Type'}</div>
        <div className="w-4/5">
            <label htmlFor={QuestionCategory.Demographic}>{title}</label>
        </div>
    </div>
)

const DemographicQuestionTable = ({
    categoryId,
    title,
    demographicQuestions,
    onUpdateQuestions,
    disabled,
    onUpdateForRequiredQuestion,
}) => {
    const allQuestions = useAtomValue(allQuestionsAtom)
    const selectedQuestionAtoms = useAtomValue(selectedQuestionsAtom)

    const { allChecked, someChecked, handleToggleAll, handleToggleQuestion, handleToggleRequiredQuestion } =
        useDemographicQuestionToggles(
            demographicQuestions,
            allQuestions,
            onUpdateQuestions,
            onUpdateForRequiredQuestion
        )

    const isToggleAllDisabled = disabled || some(demographicQuestions, (q) => q.isRequired)
    return (
        <Table>
            <HeaderRow>
                <HeaderCell className="w-full md:w-1/2">
                    <div className="w-full flex flex-no-wrap">
                        <div className="text-center md:pb-1">
                            {!isToggleAllDisabled ? (
                                <Checkbox
                                    id={categoryId}
                                    checked={allChecked}
                                    indeterminate={someChecked}
                                    onChange={handleToggleAll}
                                />
                            ) : (
                                <div className="w-4" />
                            )}
                        </div>
                        <div className="pl-4 w-full">
                            <CategoryLabel title={title} />
                        </div>
                    </div>
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/2 pr-2">
                    {'Details'}
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/12 pr-8 justify-end">
                    {'Required'}
                </HeaderCell>
            </HeaderRow>
            <div className="w-full">
                <QuestionTableContext.Provider value={handleToggleQuestion}>
                    {map(demographicQuestions, (q, index) => (
                        <DemographicOptionsProvider
                            key={`${q.questionEssenceId}-${index}`}
                            initialState={{ question: q }}
                            onUpdateQuestions={onUpdateQuestions}>
                            <DemographicQuestionTableRow
                                index={index}
                                key={`${q.questionEssenceId}-${index}`}
                                questionId={q.questionEssenceId}
                                question={q}
                                isRequired={q.isRequired}
                                selected={q.isSelected}
                                parentSelected={q.isParentSelected}
                                disabled={disabled || q.isRequired}
                                hasActiveDependentQuestion={some(selectedQuestionAtoms, (selectedQuestion) =>
                                    includes(selectedQuestion.conditionalQuestionEssenceIds, q.questionEssenceId)
                                )}
                                targetGroupDescription={q.targetGroupDescription}
                                handleRequiredToggle={handleToggleRequiredQuestion}
                            />
                        </DemographicOptionsProvider>
                    ))}
                </QuestionTableContext.Provider>
            </div>
        </Table>
    )
}

DemographicQuestionTable.propTypes = {
    title: PropTypes.string,
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

export default DemographicQuestionTable
