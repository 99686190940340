import { RadioButton } from '@energage/components'
import { Text } from '@energage/typography'
import styled from 'styled-components'
import {
    CultureStatsGraphicType,
    CultureStatsId,
    isDesignTypeCircle,
    isDesignTypeDonut,
    isDesignTypeHalfDonut,
    isDesignTypeLaurelHalfCircle,
    isFullCircleSelected,
    isHalfCircleSelected,
    isRectangleDesignType,
    isRectangleDesignWithBorderType,
    isRectangleDesignWithoutBorderType,
    isRectangleWithBorderSelected,
    isRectangleWithoutBorderSelected,
    isTopXPercentSelected,
    isTopXSelected,
    RECTANGLE_TYPES,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'

const CultureTypeImage = styled.img.attrs({ className: 'flex justify-self-center' })`
    height: 66px;
    width: 66px;
`

const StyledContainer = styled.div.attrs({ className: 'flex flex-col relative' })`
    background-color: white;
    border: ${(props) => (props.isSelected ? '2px solid #2FCAFE' : '')};
    border-radius: 5px;
    height: 97px;
    width: 118px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23);
`

const updatedTopXDesignTypeHandler = (selectedTopXDesignType) => {
    if (isDesignTypeCircle(selectedTopXDesignType)) {
        return CultureStatsGraphicType.LaurelHalfCircle
    }
    return CultureStatsGraphicType.Circle
}

const updatedPercentPostitiveDesignTypeHandler = (selectedPercentPositiveDesignType) => {
    if (isDesignTypeDonut(selectedPercentPositiveDesignType)) {
        return CultureStatsGraphicType.HalfDonut
    }
    return CultureStatsGraphicType.Donut
}

export const CultureStatsRadioButtonSelection = ({
    onChange,
    selectedCultureType,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType,
    setSelectedTopXDesignType,
    setSelectedPercentPositiveDesignType,
    setSelectedRectangleDesignType,
    isTopXFiltered,
    data,
    dataId,
}) => {
    const isCultureStats = dataId === CultureStatsId
    const topXPercentDesignType = selectedTopXDesignType
    const percentPositiveDesignType = selectedPercentPositiveDesignType
    const topXDesignTypeHandler = updatedTopXDesignTypeHandler(selectedTopXDesignType)
    const percentPositiveDesignTypeHandler = updatedPercentPostitiveDesignTypeHandler(selectedPercentPositiveDesignType)

    const designTypeChangeHandler = (evt) => {
        const targetName = evt?.target?.name
        let changedDesignType
        let rectangleDesignType = CultureStatsGraphicType.None

        if (targetName === RECTANGLE_TYPES.BORDER_DESIGN) {
            changedDesignType = CultureStatsGraphicType.RectangleBorderDesign
            setSelectedRectangleDesignType?.(changedDesignType)
        } else if (targetName === RECTANGLE_TYPES.DESIGN_WITHOUT_BORDER) {
            changedDesignType = CultureStatsGraphicType.RectangleDesignWithoutBorder
            setSelectedRectangleDesignType?.(changedDesignType)
        } else if (isTopXPercentSelected(selectedCultureType)) {
            changedDesignType = topXDesignTypeHandler
            setSelectedTopXDesignType(topXDesignTypeHandler)
            setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
        } else {
            changedDesignType = percentPositiveDesignTypeHandler
            setSelectedTopXDesignType(CultureStatsGraphicType.None)
            setSelectedPercentPositiveDesignType(percentPositiveDesignTypeHandler)
        }

        if (isRectangleDesignType(changedDesignType)) {
            rectangleDesignType = changedDesignType
            setSelectedTopXDesignType(CultureStatsGraphicType.None)
            setSelectedPercentPositiveDesignType(CultureStatsGraphicType.None)
        } else {
            setSelectedRectangleDesignType?.(CultureStatsGraphicType.None)
        }

        onChange({
            ...data,
            designType: changedDesignType,
            topXDesignType: topXDesignTypeHandler,
            percentDesignType: percentPositiveDesignTypeHandler,
            rectangleDesignType,
        })

        setTimeout(() => evt?.target?.closest('label')?.click(), 10)
    }

    const graphicOne = () => {
        if (isTopXSelected(selectedCultureType)) {
            return (
                <img
                    alt="Top X Graphic 1"
                    src={
                        isDesignTypeLaurelHalfCircle(topXPercentDesignType)
                            ? require('images/employerBranding/cultureFacts/topXGraphicSelection1.svg').default
                            : require('images/employerBranding/cultureFacts/topXGraphicSelectionGray1.svg').default
                    }
                    className="self-center sm:self-start -ml-3 mt-1"
                />
            )
        }

        return (
            <img
                alt="half donut graph"
                src={
                    isDesignTypeHalfDonut(percentPositiveDesignType)
                        ? require('images/employerBranding/cultureFacts/percentPositiveHalfCircle.svg').default
                        : require('images/employerBranding/cultureFacts/percentPositiveHalfGrayCircle.svg').default
                }
                className="self-center sm:self-start -ml-3 mt-2"
            />
        )
    }

    const graphicTwo = () => {
        if (isTopXSelected(selectedCultureType)) {
            return (
                <img
                    alt="Top X Graphic 2"
                    src={
                        isDesignTypeCircle(topXPercentDesignType)
                            ? require('images/employerBranding/cultureFacts/topXGraphicSelection2.svg').default
                            : require('images/employerBranding/cultureFacts/topXGraphicSelectionGray2.svg').default
                    }
                    className="self-center sm:self-start -ml-3 mt-1"
                />
            )
        }

        return (
            <img
                alt="full donut graph"
                src={
                    isDesignTypeDonut(percentPositiveDesignType)
                        ? require('images/employerBranding/cultureFacts/percentPositiveFullCircle.svg').default
                        : require('images/employerBranding/cultureFacts/percentPositiveFullGrayCircle.svg').default
                }
                className="self-center sm:self-start -ml-3 mt-1"
            />
        )
    }

    const rectangleBorderDesignImage = () => {
        return (
            <CultureTypeImage
                alt="Rectangle framed"
                src={
                    !isTopXFiltered && isRectangleDesignWithBorderType(selectedRectangleDesignType)
                        ? require('images/employerBranding/cultureFacts/rectangleBorderDesign.svg').default
                        : require('images/employerBranding/cultureFacts/rectangleBorderDesignGray.svg').default
                }
                className="mt-3 self-center sm:self-start"
            />
        )
    }

    const rectangleDesignWithoutBorderImage = () => {
        return (
            <CultureTypeImage
                alt="Rectangle highlight"
                src={
                    !isTopXFiltered && isRectangleDesignWithoutBorderType(selectedRectangleDesignType)
                        ? require('images/employerBranding/cultureFacts/rectangleDesignWithoutBorder.svg').default
                        : require('images/employerBranding/cultureFacts/rectangleDesignWithoutBorderGray.svg').default
                }
                className="mt-3 self-center sm:self-start"
            />
        )
    }

    const isHalfCircleDesignTypeSelected = isHalfCircleSelected(
        selectedCultureType,
        selectedTopXDesignType,
        selectedPercentPositiveDesignType,
        selectedRectangleDesignType
    )
    const isFullCircleDesignTypeSelected = isFullCircleSelected(
        selectedCultureType,
        selectedTopXDesignType,
        selectedPercentPositiveDesignType,
        selectedRectangleDesignType
    )
    const isRectangleDesignWithBorderTypeSelected =
        !isTopXFiltered &&
        isRectangleWithBorderSelected(
            selectedTopXDesignType,
            selectedPercentPositiveDesignType,
            selectedRectangleDesignType
        )
    const isRectangleDesignWithoutBorderTypeSelected =
        !isTopXFiltered &&
        isRectangleWithoutBorderSelected(
            selectedTopXDesignType,
            selectedPercentPositiveDesignType,
            selectedRectangleDesignType
        )

    const isHalfCircleDisabled =
        isTopXFiltered ||
        isDesignTypeHalfDonut(setSelectedPercentPositiveDesignType) ||
        isDesignTypeLaurelHalfCircle(setSelectedTopXDesignType)
    const isFullCircleDisabled =
        isTopXFiltered ||
        isDesignTypeDonut(setSelectedPercentPositiveDesignType) ||
        isDesignTypeCircle(setSelectedTopXDesignType)

    return (
        <div className="mt-2 mb-8">
            <Text textStyle="title6" className="mb-2 text-sm">
                <h5>{'Select Design'}</h5>
            </Text>
            <div className="flex flex-row">
                {isCultureStats && (
                    <>
                        <StyledContainer className="mr-6" isSelected={isRectangleDesignWithBorderTypeSelected}>
                            <div className="ml-1 mt-1">
                                <RadioButton
                                    label={rectangleBorderDesignImage()}
                                    name={RECTANGLE_TYPES.BORDER_DESIGN}
                                    onChange={designTypeChangeHandler}
                                    checked={isRectangleDesignWithBorderTypeSelected}
                                    disabled={isTopXFiltered}
                                />
                            </div>
                        </StyledContainer>
                        <StyledContainer className="mr-6" isSelected={isRectangleDesignWithoutBorderTypeSelected}>
                            <div className="ml-1 mt-1">
                                <RadioButton
                                    label={rectangleDesignWithoutBorderImage()}
                                    name={RECTANGLE_TYPES.DESIGN_WITHOUT_BORDER}
                                    onChange={designTypeChangeHandler}
                                    checked={isRectangleDesignWithoutBorderTypeSelected}
                                    disabled={isTopXFiltered}
                                />
                            </div>
                        </StyledContainer>
                    </>
                )}

                <StyledContainer className="mr-6" isSelected={isHalfCircleDesignTypeSelected}>
                    <div className="ml-1 mt-1">
                        <RadioButton
                            label={graphicOne()}
                            name={`${dataId}HalfCircleDesign`}
                            onChange={designTypeChangeHandler}
                            checked={isHalfCircleDesignTypeSelected}
                            disabled={isHalfCircleDisabled}
                        />
                    </div>
                </StyledContainer>

                {!isCultureStats && (
                    <StyledContainer isSelected={isFullCircleDesignTypeSelected}>
                        <div className="ml-1 mt-1">
                            <RadioButton
                                label={graphicTwo()}
                                name="FullCircleDesign"
                                checked={isFullCircleDesignTypeSelected}
                                onChange={designTypeChangeHandler}
                                disabled={isFullCircleDisabled}
                            />
                        </div>
                    </StyledContainer>
                )}
            </div>
        </div>
    )
}
