import { useReducer } from 'react'
import { Input, InputGroup, LegacyButton as Button, Select } from '@energage/components'
import concat from 'lodash/concat'
import differenceBy from 'lodash/differenceBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import property from 'lodash/property'
import reduce from 'lodash/reduce'
import unionBy from 'lodash/unionBy'
import { useForm } from 'react-hook-form-old'
import * as yup from 'yup'
import { createAction } from 'util/actionCreator'
import { requiredStringMax255 } from 'util/validators'
import { UpdateActiveSurveysCheckbox } from '../UpdateActiveSurveys'
import MembersList from './MembersList'

const schema = yup.object().shape({
    departmentName: requiredStringMax255,
})

const getAllChildDepartments = (department) =>
    department.children?.length > 0
        ? reduce(department.children, (a, x) => [...a, x, ...getAllChildDepartments(x)], [])
        : []

const excludeChildDepartments = (currentDepartment, departments) => {
    return currentDepartment
        ? differenceBy(departments, [currentDepartment, ...getAllChildDepartments(currentDepartment)], property('id'))
        : departments
}

const topLevelDepartmentOption = {
    energageTagOption: null,
    id: null,
    lastModifiedDate: null,
    modifiedBy: null,
    modifiedDateTime: null,
    parentTagOptionId: null,
    tagId: null,
    value: 'Top Level',
}

const ParentDepartmentSelect = ({ department, departments, parentDepartmentId, onChange }) => {
    const filteredDepartments = concat([topLevelDepartmentOption], excludeChildDepartments(department, departments))

    return (
        <InputGroup
            label="Parent Department"
            className="flex-1 md:pl-3"
            as={Select}
            name="parentDepartment"
            id="parentDepartment"
            options={filteredDepartments}
            value={find(filteredDepartments, (x) => x.id === parentDepartmentId)}
            onChange={onChange}
            getOptionLabel={(opt) => opt.value}
            getOptionValue={(opt) => opt.id}
        />
    )
}

const ActionTypes = {
    ADD_MEMBER: 'AddMember',
    REMOVE_MEMBER: 'RemoveMember',
    SET_PARENT_DEPARTMENT: 'SetParentDepartment',
}

const actions = {
    addMember: createAction(ActionTypes.ADD_MEMBER),
    removeMember: createAction(ActionTypes.REMOVE_MEMBER),
    setParentDepartment: createAction(ActionTypes.SET_PARENT_DEPARTMENT),
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case ActionTypes.ADD_MEMBER:
            return {
                ...state,
                departmentMembers: unionBy(state.departmentMembers, [payload], 'id'),
                membersToRemove: filter(state.membersToRemove, (x) => x.id !== payload.id),
            }
        case ActionTypes.REMOVE_MEMBER:
            return {
                ...state,
                departmentMembers: filter(state.departmentMembers, (x) => x.id !== payload.id),
                membersToRemove: unionBy(state.membersToRemove, [payload], 'id'),
            }
        case ActionTypes.SET_PARENT_DEPARTMENT:
            return {
                ...state,
                parentDepartmentId: payload,
            }
        default:
            return state
    }
}

const DepartmentForm = ({ department, departments, members, onSubmit, onClose, isSaving = false }) => {
    const [{ parentDepartmentId, departmentMembers, memberToAdd }, dispatch] = useReducer(reducer, {
        parentDepartmentId: department?.parentDepartmentId,
        departmentMembers: department?.members,
        membersToRemove: [],
        memberToAdd: null,
    })

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        defaultValues: {
            departmentName: department?.name,
        },
    })

    const onParentDepartmentChange = (parentDepartment) => {
        dispatch(actions.setParentDepartment(parentDepartment?.id))
    }

    const onMemberAdd = (member) => {
        if (member) {
            dispatch(actions.addMember(member))
        }
    }

    const onMemberRemove = (member) => {
        if (member) {
            dispatch(actions.removeMember(member))
        }
    }

    const close = () => {
        onClose?.()
    }

    const submit = (formData) => {
        const data = {
            ...formData,
            parentDepartmentId,
            memberIds: map(departmentMembers, 'id'),
        }

        onSubmit?.(data)
    }

    return (
        <form data-testid="org-management-add-department" onSubmit={handleSubmit(submit)} noValidate>
            <div className="flex flex-col md:flex-row mb-3">
                <Input
                    className="flex-1 md:pr-3"
                    name={'departmentName'}
                    label="Department"
                    ref={register}
                    error={errors['departmentName']?.message}
                    info={errors['departmentName']?.message}
                    required
                />
                <ParentDepartmentSelect
                    department={department}
                    departments={departments}
                    parentDepartmentId={parentDepartmentId}
                    onChange={onParentDepartmentChange}
                />
            </div>
            <div className="flex flex-col md:flex-row mb-3">
                <InputGroup
                    label="Add Employees"
                    className="flex-1"
                    as={Select}
                    name="addEmployees"
                    id="addEmployees"
                    options={differenceBy(members, departmentMembers, 'id')}
                    onChange={onMemberAdd}
                    value={memberToAdd}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                />
            </div>
            <div className="flex flex-col md:flex-row mb-3">
                <InputGroup
                    label="Employees"
                    className="flex-1"
                    as={() => (
                        <MembersList
                            onMemberRemove={onMemberRemove}
                            members={departmentMembers}
                            department={department}
                        />
                    )}
                />
            </div>
            <div className="flex justify-end">
                <UpdateActiveSurveysCheckbox />
                <Button variant="primary" disabled={isSaving} outline onClick={close}>
                    {'Cancel'}
                </Button>
                <Button variant="primary" type="submit" disabled={isSaving} className="ml-6">
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
            </div>
        </form>
    )
}

export default DepartmentForm
