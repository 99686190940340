import { object, string } from 'yup'

const folderNameValidationMessage = 'The folder name must not contain special characters'

const folderNameRegex = /^[^\\/:*?"<>|]+$/

export const createFolderValidationSchema = object()
    .shape({
        folderName: string().nullable(false).trim().matches(folderNameRegex, folderNameValidationMessage).required(),
    })
    .required()
