import { alert } from '@energage/components'
import { useQueryClient } from 'react-query'
import type { WithNoContent } from 'api'
import { useAuthenticationQuery } from 'api/apiQueries'
import showErrorAlert from 'util/showErrorAlert'
import { ACTIVE_MFA_CONNECTION_EXCEPTION, ACTIVE_SSO_CONNECTION_EXCEPTION } from './constants'
import type { ErrorT, SamlAuthConnection, SecuritySettingsStatus, SsoFormProps } from './models'
import { formatCreateSamlData } from './util'

export function useGetSamlConnection(surveyCompanyId: number) {
    // todo(e3): Handle NO_CONTENT response
    return useAuthenticationQuery<WithNoContent<SamlAuthConnection>, ErrorT>(
        ['get-saml-connections', surveyCompanyId],
        `companies/${surveyCompanyId}/connections/saml`,
        {
            onError: (error) => {
                showErrorAlert('There was an error retrieving SAML connections', error.parsed)
            },
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )
}

export function useEditSamlConnection(surveyCompanyId: number) {
    const queryClient = useQueryClient()
    return useAuthenticationQuery.mutate<SamlAuthConnection, ErrorT, SsoFormProps & { id: string | number }>(
        async (data, api) =>
            await api.postJson(`companies/${surveyCompanyId}/connections/saml/${data.id}`, formatCreateSamlData(data)),
        {
            onSuccess: (response) => {
                queryClient.setQueryData(['get-saml-connections', surveyCompanyId], response)
                alert.success('SAML connection was edited successfully')
            },
            onError: (err) => {
                showErrorAlert('There was an error editing the SAML connection', err.parsed)
            },
        }
    )
}

export function useCreateSamlConnection(surveyCompanyId: number) {
    const queryClient = useQueryClient()
    return useAuthenticationQuery.mutate<SamlAuthConnection, ErrorT, SsoFormProps>(
        async (data, api) =>
            await api.postJson(`companies/${surveyCompanyId}/connections/saml`, formatCreateSamlData(data)),
        {
            onSuccess: (response: SamlAuthConnection) => {
                queryClient.setQueryData(['get-saml-connections', surveyCompanyId], response)
                alert.success('SAML connection was created successfully')
            },
            onError: (err: ErrorT) => {
                showErrorAlert('There was an error creating the SAML connection', err.parsed)
            },
        }
    )
}

export function useTurnOnSsoConnection(surveyCompanyId: number) {
    const queryClient = useQueryClient()
    return useAuthenticationQuery.mutate<unknown, ErrorT, { id: string; action: number }>(
        async (data, api) => {
            const url = `companies/${surveyCompanyId}/connections/saml/${data.id}`
            return api.postJson(url, data)
        },
        {
            onSuccess: () => {
                const currentData: SecuritySettingsStatus | undefined = queryClient.getQueryData([
                    'get-security-settings-status',
                    surveyCompanyId,
                ])
                queryClient.setQueryData(['get-security-settings-status', surveyCompanyId], {
                    ...currentData,
                    isSsoEnabled: true,
                })
            },
            onError: (err: ErrorT) => {
                if (err.parsed?.data?.code === ACTIVE_MFA_CONNECTION_EXCEPTION) {
                    showErrorAlert('There was an error', err.parsed)
                } else {
                    showErrorAlert('There was an error turning on Sso connection', err.parsed)
                }
            },
        }
    )
}

export function useTurnOnMfaConnection(surveyCompanyId: number) {
    const queryClient = useQueryClient()
    return useAuthenticationQuery.mutate<unknown, ErrorT, { enabled: boolean }>(
        async (data, api) => {
            const url = `companies/${surveyCompanyId}/connections/default/mfa`
            return api.postJson(url, data)
        },
        {
            onSuccess: () => {
                const currentData: SecuritySettingsStatus | undefined = queryClient.getQueryData([
                    'get-security-settings-status',
                    surveyCompanyId,
                ])
                queryClient.setQueryData(['get-security-settings-status', surveyCompanyId], {
                    ...currentData,
                    isMfaEnabled: !currentData?.isMfaEnabled,
                })
            },
            onError: (err) => {
                if (err.parsed?.data?.code === ACTIVE_SSO_CONNECTION_EXCEPTION) {
                    showErrorAlert('There was an error', err.parsed)
                } else {
                    showErrorAlert('There was an error changing the MFA connection status', err.parsed)
                }
            },
        }
    )
}
