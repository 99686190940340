import { createContext, useCallback, useContext, useState } from 'react'
import map from 'lodash/map'
import { generateRandomKey } from 'util/generateRandomKey'
import { isEmptyString } from 'util/strings'

const DemographicOptionsContext = createContext()

function useDemographicOptions() {
    const context = useContext(DemographicOptionsContext)

    if (!context) {
        throw new Error('useDemographicOptionContext must be used within a DemographicOptionProvider')
    }

    return context
}

function DemographicOptionsProvider({ children, initialState = {}, onUpdateQuestions }) {
    const [currentAnswerOptions, setCurrentAnswerOptions] = useState(initialState.question.answerOptions)
    const [answerOptionsValid, setAnswerOptionsValid] = useState(false)
    const [hasDuplicateOptions, setHasDuplicateOptions] = useState(false)

    const validateAnswerOptions = useCallback((options) => {
        const names = {}
        let hasUniqueNames = true
        let hasNames = true

        for (const option of options) {
            if (!!!option.name) {
                hasNames = false
            }

            if (names[option.name]) {
                hasUniqueNames = false
                break
            }

            names[option.name] = true
        }
        setHasDuplicateOptions(!hasUniqueNames)
        return hasUniqueNames && hasNames && options.length >= 2
    }, [])

    const verifyAndSetAnswerOptions = useCallback(
        (options) => {
            setCurrentAnswerOptions(options)

            const isValid = validateAnswerOptions(options)
            setAnswerOptionsValid(isValid)
        },
        [validateAnswerOptions]
    )

    const [answerOptions, setAnswerOptions] = useState(() =>
        map(initialState.question.answerOptions, (option) => {
            option._key = option.id || option._key || generateRandomKey()
            option._errors = option._errors ?? {}
            return option
        })
    )

    const handleAddOption = useCallback(() => {
        const updatedAnswerOptions = answerOptions.slice()
        const newOption = {
            id: '',
            _key: generateRandomKey(),
            name: '',
            _errors: {},
        }
        updatedAnswerOptions.push(newOption)
        setAnswerOptions(updatedAnswerOptions)
        onUpdateQuestions(updatedAnswerOptions)
    }, [answerOptions, onUpdateQuestions])

    const handleOptionTextChange = useCallback(
        (value, index) => {
            const updatedOptions = map(answerOptions, (opt, idx) => {
                if (idx === index) {
                    return {
                        ...opt,
                        name: value,
                        _errors: { required: isEmptyString(value) ? 'Required' : null },
                    }
                }
                return opt
            })

            setAnswerOptions(updatedOptions)
            verifyAndSetAnswerOptions(updatedOptions)
        },
        [answerOptions, verifyAndSetAnswerOptions]
    )

    const handleDeleteOption = useCallback(
        (index) => {
            const updatedOptions = answerOptions.slice()
            updatedOptions.splice(index, 1)
            setAnswerOptions(updatedOptions)
            verifyAndSetAnswerOptions(updatedOptions)
        },
        [answerOptions, verifyAndSetAnswerOptions]
    )

    const resetAnswerOptions = useCallback(() => {
        setAnswerOptions([])
    }, [])

    const valueToShare = {
        answerOptions,
        setAnswerOptions,
        currentAnswerOptions,
        setCurrentAnswerOptions,
        answerOptionsValid,
        hasDuplicateOptions,
        verifyAndSetAnswerOptions,
        handleAddOption,
        handleDeleteOption,
        handleOptionTextChange,
        resetAnswerOptions,
    }

    return <DemographicOptionsContext.Provider value={valueToShare}>{children}</DemographicOptionsContext.Provider>
}

export { useDemographicOptions, DemographicOptionsProvider }
