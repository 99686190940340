import PropTypes from 'prop-types'
import { ArrowBack } from '@energage/icons'
import cx from 'clsx'
import { Link, useRouteMatch } from 'react-router-dom'
import { createTeleporter } from '../util/Teleporter'
import { useBackButton } from './Navigation'

function Page({ children, className = '' }) {
    return <div className={cx('px-6', className)}>{children}</div>
}
Page.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

// todo: backPathOverride temporary until back path logic can be updated
//  to allow for top page -> sub page -> sub sub page back navigation.
function Title({ backPathOverride, defaultBackPath, children }) {
    const match = useRouteMatch(defaultBackPath)
    const backButtonDefault = useBackButton(match.url, defaultBackPath)
    const backPath = backPathOverride ?? backButtonDefault

    return (
        <div className="w-full max-w-md flex items-center">
            {backPath && (
                <Link css={{ lineHeight: 0 }} className="mr-4" to={backPath}>
                    <ArrowBack />
                </Link>
            )}
            <span>{children}</span>
        </div>
    )
}

Page.Title = Title

function Heading({ children, className = '', border = false, title }) {
    return (
        <div className={cx('flex justify-between items-center h-16 w-full', border && 'border-b-2', className)}>
            {title && typeof title === 'string' ? <h3>{title}</h3> : title}
            {children}
        </div>
    )
}

Page.Heading = Heading

const ActionContainer = createTeleporter()
Page.ActionSource = ActionContainer.Source
Page.ActionTarget = ActionContainer.Target

export { Page }
