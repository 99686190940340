import { useCallback } from 'react'
import { alert } from '@energage/components'
import { useManagementQuery } from 'api'
import type { EnhancedKy } from 'api/api'
import { useIdentity } from 'components/Identity'

function toggleState(
    { surveyCompanyId, active, id }: { surveyCompanyId?: number; active?: boolean; id: number },
    api: EnhancedKy
) {
    return api.postJson<void>(`feature/organization/${surveyCompanyId}/${id}`, active)
}

const useUpdateToggle = () => {
    const identity = useIdentity()

    const { mutateAsync } = useManagementQuery.mutate<
        void,
        unknown,
        { surveyCompanyId: number; active: boolean; id: number }
    >(toggleState)

    return useCallback(
        async (active: boolean, id: number) => {
            try {
                return await mutateAsync({
                    surveyCompanyId: identity.surveyCompanyId,
                    active,
                    id,
                })
            } catch (e: unknown) {
                alert.danger('We encountered an error while saving. Please try again.')
                throw e
            }
        },
        [identity.surveyCompanyId, mutateAsync]
    )
}

export { useUpdateToggle }
