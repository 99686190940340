import { Dropdown, Menu } from '@energage/components'
import { Delete, Edit, Icon, MoreHoriz, RemoveCircleOutline } from '@energage/icons'
import { colors } from '@energage/theme'
import map from 'lodash/map'
import { Tooltip } from 'components/Tooltip'

function QuestionOverflowMenu({ questionText, toolTipText, menuItems }) {
    if (menuItems.length === 0) {
        const disabled = !toolTipText
        return (
            <Tooltip
                placement="left"
                trigger={
                    <button disabled={disabled} className="cursor-not-allowed">
                        <MoreHoriz color={colors.grey500} />
                    </button>
                }
                disabled={disabled}
                text={toolTipText}
                tooltipColor={colors.grey600}
                textColor={colors.white}
                tooltipArrowColor={colors.grey500}
            />
        )
    }
    return (
        <Dropdown
            ariaLabel={`Context menu for question '${questionText}'`}
            placement="right-start"
            trigger={
                <button>
                    <MoreHoriz className="cursor-pointer" />
                </button>
            }>
            <Menu className="min-w-0 w-48" tabIndex={0}>
                {map(menuItems, ({ text, icon, onSelect }) => (
                    <Menu.Item key={text} className="px-3 flex items-center py-3" onSelect={onSelect}>
                        <Icon as={icon} className="mr-2 flex-shrink-0" color={colors.grey500} />
                        {text}
                    </Menu.Item>
                ))}
            </Menu>
        </Dropdown>
    )
}

export const SortableQuestionOverflowMenu = ({
    disabled,
    allowEditNonCustomQuestions,
    onEditQuestion,
    onRemoveQuestion,
    question,
}) => {
    const menuItems = []
    if (!disabled && !question.isRequired) {
        const isCustomQuestion = question.sourceType !== null

        if (isCustomQuestion || allowEditNonCustomQuestions) {
            menuItems.push({
                text: 'Edit',
                icon: Edit,
                onSelect: () => onEditQuestion(question.questionEssenceId, question.sourceType),
            })
        }
        menuItems.push({
            text: isCustomQuestion ? 'Delete' : 'Remove',
            icon: isCustomQuestion ? Delete : RemoveCircleOutline,
            onSelect: () => onRemoveQuestion(question.questionEssenceId),
        })
    }

    return (
        <QuestionOverflowMenu
            questionText={question.text}
            toolTipText={question.isRequired ? 'Required item cannot be removed or edited.' : null}
            menuItems={menuItems}
        />
    )
}

export default QuestionOverflowMenu
