import PropTypes from 'prop-types'
import map from 'lodash/map'
import { departmentShape, memberShape } from '../propTypes'
import { ColumnHeader, TableCell, TableRow } from '../Table'
import RemoveMember from './RemoveMember'

const TableHeader = () => (
    <div className="bg-white border border-b-2 border-grey300 font-bold text-sm flex items-center">
        <ColumnHeader className="flex-1">{'Name'}</ColumnHeader>
        <ColumnHeader className="w-32">{'Actions'}</ColumnHeader>
    </div>
)

const MembersList = ({ members, department, onMemberRemove }) => (
    <div className="w-full text-left">
        <TableHeader />
        {map(members, (member) => {
            const { id, name } = member
            return (
                <TableRow key={id} className="flex items-center">
                    <TableCell className="flex-1">{name}</TableCell>
                    <TableCell className="flex justify-between w-32">
                        <RemoveMember member={member} department={department} onMemberRemove={onMemberRemove} />
                    </TableCell>
                </TableRow>
            )
        })}
    </div>
)

MembersList.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape(memberShape)).isRequired,
    department: PropTypes.shape(departmentShape).isRequired,
    onMemberRemove: PropTypes.func.isRequired,
}

export default MembersList
