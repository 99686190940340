import { useState } from 'react'
import type { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import cx from 'clsx'
import ReactModal from 'react-modal'
import type { Props as ReactModalProps } from 'react-modal'
import styled from 'styled-components'
import CloseButton from './CloseButton'

declare module 'react-modal' {
    interface Props {
        children: ReactNode
    }
}

const ModalBody = styled(({ titleHeight, fullScreen, ...rest }) => <div {...rest} />)`
    overflow-y: auto;
    max-height: ${(props) => (props.fullScreen ? '100%' : '90vh')};
    height: calc(100% - ${(props) => props.titleHeight}px);
`

interface BaseModalProps {
    className: string
    onClose?: (event: MouseEvent | KeyboardEvent) => void
    dataId?: string | null
    title?: React.ReactNode
    fullScreen?: boolean
    disableCloseButton?: boolean
}

type ModalProps = React.PropsWithChildren<BaseModalProps> & Omit<ReactModalProps, 'onRequestClose'>

const Modal = ({
    className,
    title,
    onClose,
    children,
    dataId = null,
    fullScreen = false,
    disableCloseButton = false,
    ...rest
}: ModalProps) => {
    const [titleHeight, setTitleHeight] = useState(0)

    function setHeight(ref: HTMLDivElement | null) {
        const rect = ref?.getBoundingClientRect()
        const height = rect?.height ?? 0
        if (height !== titleHeight) {
            setTitleHeight(height)
        }
    }

    return (
        <ReactModal
            className={cx('p-0 overflow-auto bg-white outline-none', className, {
                'rounded-lg': !fullScreen,
            })}
            onRequestClose={onClose}
            overlayClassName="modal-overlay"
            {...rest}>
            {title && (
                <div className="px-6 py-2 flex justify-between border-b" ref={setHeight}>
                    <h5>{title}</h5>
                    {onClose && <CloseButton onClick={onClose} dataId={dataId} disabled={disableCloseButton} />}
                </div>
            )}
            <ModalBody titleHeight={titleHeight} fullScreen={fullScreen}>
                {children}
            </ModalBody>
        </ReactModal>
    )
}

export default Modal
