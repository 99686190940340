import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import type { Award } from './awardHistory.types'

const useFetchAwardHistory = () => {
    const { surveyCompanyId } = useIdentity()
    const { data, isLoading } = useWorkplaceSurveyQuery<Award[]>(
        ['award-history', surveyCompanyId],
        `AwardParticipation/History/${surveyCompanyId}`
    )

    return { awards: data, isLoading }
}

export default useFetchAwardHistory
