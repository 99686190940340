import { useCallback, useRef } from 'react'
import type { ChangeEvent } from 'react'
import { LegacyButton as Button } from '@energage/components'
import cloneDeep from 'lodash/cloneDeep'
import CustomQuestion from '../../CustomQuestions/CustomQuestion'
import useCustomQuestionCreation from '../../useCustomQuestionCreation'
import { hasErrors } from '../../verifyQuestion'

type Question = {
    _errors?: Record<string, unknown>
    _key: string
    id: number
    questionTypeId: number
    name: string
    text: string
    isPositivelyPhrased: boolean
    positiveConditionalText: string | null
    negativeConditionalText: string | null
    hasConditionals: boolean
    isSubmitting?: boolean
    departmentIds: number[]
    targetDemographics: {
        demographicTypeId: number
        demographicIds: number[]
    }
}

const useOnEditing = (originalQuestion: Question) => {
    const originalQuestionRef = useRef(cloneDeep(originalQuestion))

    const {
        customQuestions,
        handleAddRemoveConditionals,
        handleQuestionTypeSelection,
        handleQuestionNameChange,
        handleQuestionTextChange,
        handleMultiCommentCountChange,
        handlePositiveConditionalTextChange,
        handleNegativeConditionalTextChange,
        handleQuestionDepartmentSelection,
        handleDemographicTargetChange,
        resetCustomQuestions,
        handleOptionsChange,
        /*
         passing originalQuestionRef.current here as a ..hacky.. way to fix an issue with regard to
         reset the question when the user cancels editing. Since javascript passes objects by reference
         the updates to the question are still being applied to the question in the jotai store when
         we're not actively updating that (as far as I've been able to tell).
         */
    } = useCustomQuestionCreation([originalQuestionRef.current])

    const handleAddRemoveConditionalsImpl = useCallback(() => {
        handleAddRemoveConditionals(0)
    }, [handleAddRemoveConditionals])

    const handleQuestionTypeSelectionImpl = useCallback(
        (selectedValue: unknown) => {
            handleQuestionTypeSelection(selectedValue, 0)
        },
        [handleQuestionTypeSelection]
    )

    const handleQuestionNameChangeImpl = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleQuestionNameChange(e, 0)
        },
        [handleQuestionNameChange]
    )

    const handleQuestionTextChangeImpl = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleQuestionTextChange(e, 0)
        },
        [handleQuestionTextChange]
    )

    const handleMultiCommentCountChangeImpl = useCallback(
        (selectedValue: unknown) => {
            handleMultiCommentCountChange(selectedValue, 0)
        },
        [handleMultiCommentCountChange]
    )

    const handlePositiveConditionalTextChangeImpl = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handlePositiveConditionalTextChange(e, 0)
        },
        [handlePositiveConditionalTextChange]
    )

    const handleNegativeConditionalTextChangeImpl = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleNegativeConditionalTextChange(e, 0)
        },
        [handleNegativeConditionalTextChange]
    )

    const handleDemographicTargetChangeImpl = useCallback(
        (targetDemographic: unknown) => {
            handleDemographicTargetChange(targetDemographic, 0)
        },
        [handleDemographicTargetChange]
    )

    const handleDepartmentSelectionImpl = useCallback(
        (departmentIds: number[]) => {
            handleQuestionDepartmentSelection(departmentIds, 0)
        },
        [handleQuestionDepartmentSelection]
    )

    const handleOptionsChangeImpl = useCallback(
        (options: unknown) => {
            handleOptionsChange(options, 0)
        },
        [handleOptionsChange]
    )

    const resetQuestion = useCallback(() => {
        // @ts-ignore saying expected 0 arguments but got 1 ... which is just wrong
        resetCustomQuestions([originalQuestionRef.current])
    }, [resetCustomQuestions])

    return {
        question: customQuestions[0],
        handleDepartmentSelection: handleDepartmentSelectionImpl,
        handleQuestionTypeSelection: handleQuestionTypeSelectionImpl,
        handleQuestionNameChange: handleQuestionNameChangeImpl,
        handleQuestionTextChange: handleQuestionTextChangeImpl,
        handleMultiCommentCountChange: handleMultiCommentCountChangeImpl,
        handlePositiveConditionalTextChange: handlePositiveConditionalTextChangeImpl,
        handleNegativeConditionalTextChange: handleNegativeConditionalTextChangeImpl,
        handleAddRemoveConditionals: handleAddRemoveConditionalsImpl,
        handleDemographicTargetChange: handleDemographicTargetChangeImpl,
        handleOptionsChange: handleOptionsChangeImpl,
        resetQuestion,
    }
}

type Department = {
    id: number
    name: string
    children: Department[]
    parentId: number
}

type RowQuestionEditProps = {
    question: Question
    saveQuestion: (question: Question) => void
    isSavingQuestion: boolean
    departments: Department[]
    demographicQuestions: unknown[] // todo: figure this out
    onCancelEditAdditionalQuestion: () => void
}

export function RowQuestionEdit({
    question,
    saveQuestion,
    isSavingQuestion,
    departments,
    demographicQuestions,
    onCancelEditAdditionalQuestion,
}: RowQuestionEditProps) {
    const {
        question: editedQuestion,
        handleQuestionTextChange,
        handlePositiveConditionalTextChange,
        handleNegativeConditionalTextChange,
        handleAddRemoveConditionals,
        handleDemographicTargetChange,
        resetQuestion,
        handleDepartmentSelection,
        handleOptionsChange,
    } = useOnEditing(question)

    const invalidQuestion = hasErrors(editedQuestion)

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                if (invalidQuestion) {
                    return
                }
                saveQuestion(editedQuestion)
            }}
            className="pt-4 text-xs w-full flex flex-col">
            <CustomQuestion
                question={editedQuestion}
                departments={departments}
                // @ts-ignore It's not figuring out types from the JS component correctly
                variant="expand"
                demographicQuestions={demographicQuestions}
                handleQuestionTextChange={handleQuestionTextChange}
                handlePositiveConditionalTextChange={handlePositiveConditionalTextChange}
                handleNegativeConditionalTextChange={handleNegativeConditionalTextChange}
                handleAddRemoveConditionals={handleAddRemoveConditionals}
                handleDemographicTargetChange={handleDemographicTargetChange}
                handleQuestionDepartmentSelection={handleDepartmentSelection}
                handleQuestionTypeSelection={undefined}
                handleQuestionNameChange={undefined} // make TS happy since propTypes doesn't seem to want to do it
                handleOptionsChange={handleOptionsChange}
            />
            <div className="flex ml-auto">
                <Button
                    variant="secondary"
                    outline
                    className="mr-6"
                    disabled={isSavingQuestion}
                    type="button"
                    onClick={() => {
                        resetQuestion()
                        onCancelEditAdditionalQuestion()
                    }}>
                    {'Cancel'}
                </Button>
                <Button variant="secondary" disabled={isSavingQuestion || invalidQuestion} type="submit">
                    {isSavingQuestion ? 'Saving...' : 'Save'}
                </Button>
            </div>
        </form>
    )
}
