import { Textarea } from '@energage/components'
import { colors } from '@energage/theme'
import styled from 'styled-components'

export default styled(Textarea)`
    textarea:disabled {
        color: ${colors.grey500};
        background: ${colors.grey100};
    }
`
