import differenceInYears from 'date-fns/differenceInYears'
import filter from 'lodash/filter'
import head from 'lodash/head'
import size from 'lodash/size'

const CONTENT_FILTER = 'ContentFilter'
const DEFAULT_FILTER_VALUE = -1

export const calculateFetchUrl = ({ surveyEventName, Locations, Departments, JobRoles }) => {
    const eventID = surveyEventName ? `?surveyEventId=${surveyEventName}` : ''
    const location = Locations !== -1 ? `&locationId=${Locations}` : ''
    const departments = Departments !== -1 ? `&departmentId=${Departments}` : ''
    const jobRole = JobRoles !== -1 ? `&jobRoleId=${JobRoles}` : ''
    if (!!eventID || !!location || !!departments || !!jobRole) {
        return `SubFilters${eventID}${location}${departments}${jobRole}`
    }
    return CONTENT_FILTER
}

export const buildFiltersDataOnSuccess = (state, payload) => {
    const { data, hasEmployerBrandingSubscription, hasEmployerBrandingPlusSubscription, isSuperUser, errorMsg } =
        payload
    const filters = isSuperUser ? data : filter(data, (filter) => filter?.isBrandingResultsEnabled)
    const { isPulseSurvey } = head(filters) || {}
    let {
        hasPulseSurvey,
        isAllSurveysDisabled,
        serviceURL,
        benchmarksNotAvailableSurveys,
        brandingResultsDisabledSurveys,
        currentSelectedFilter,
        filterData,
        error,
        programs,
        persistEventName,
        showFilterCount,
        isLoading,
    } = state
    hasPulseSurvey = isPulseSurvey
    if (!isSuperUser && size(filters) === 0 && serviceURL === CONTENT_FILTER) {
        isAllSurveysDisabled = true
    }

    if (
        (hasEmployerBrandingSubscription || hasEmployerBrandingPlusSubscription) &&
        !currentSelectedFilter.surveyEventName
    ) {
        benchmarksNotAvailableSurveys = filter(
            data,
            (filter) =>
                !filter.isBenchmarksAvailable &&
                differenceInYears(new Date(), new Date(filter.surveyEventClosedDate)) < 2
        )
        if (!isSuperUser) {
            brandingResultsDisabledSurveys = filter(data, (filter) => !filter.isBrandingResultsEnabled)
        }
    }
    if (hasEmployerBrandingSubscription || hasEmployerBrandingPlusSubscription) {
        if (size(filters) > 0) {
            if (serviceURL === CONTENT_FILTER) {
                currentSelectedFilter = {
                    surveyEventName: head(filters).surveyEventId,
                    Locations: DEFAULT_FILTER_VALUE,
                    Departments: DEFAULT_FILTER_VALUE,
                    JobRoles: DEFAULT_FILTER_VALUE,
                }
                serviceURL = calculateFetchUrl(currentSelectedFilter)
                currentSelectedFilter = {
                    surveyEventName: head(filters).surveyEventId,
                    Locations: DEFAULT_FILTER_VALUE,
                    Departments: DEFAULT_FILTER_VALUE,
                    JobRoles: DEFAULT_FILTER_VALUE,
                }
                filterData = {
                    ...filterData,
                    surveyEventName: head(filters).surveyEventClosedDate,
                }
                persistEventName = filters
            }
        }
    }
    const firstLoadedEvent = data
    const { filteredRespondersCount, totalRespondersCount } = firstLoadedEvent || ''
    showFilterCount = {
        totalRespondersCount: totalRespondersCount,
        filteredRespondersCount: filteredRespondersCount,
    }
    programs = firstLoadedEvent
    error = errorMsg
    isLoading = false
    return {
        ...state,
        hasPulseSurvey,
        isAllSurveysDisabled,
        serviceURL,
        benchmarksNotAvailableSurveys,
        brandingResultsDisabledSurveys,
        currentSelectedFilter,
        filterData,
        error,
        isLoading,
        programs,
        persistEventName,
        showFilterCount,
    }
}
