import { FileDownload } from '@energage/icons'
import { AppLink } from 'components/Anchor'
import { routes } from 'constants/routes'

const StatementDownloadLink = ({ surveyEventId }) => (
    <AppLink to={routes.survey.statementDownload({ surveyEventId })}>
        <span>{'Download Item List'}</span>
        <FileDownload />
    </AppLink>
)

export default StatementDownloadLink
