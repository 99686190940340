import { useManagementQuery, useOrganizationQuery } from 'api'
import type { ResponseError } from 'api'

export interface State {
    abbreviation: string
    countryId: number
    id: number
    name: string
}

export interface ZipCode {
    state: string
}

export type Sector = {
    id: number
    name: string
    parentId?: number
}

// "type" here does not imply a TypeScript type, but rather a category of data
export type OwnershipType = {
    id: number
    name: string
}

export type ContactType = {
    id: number
    name: string
}

export const useFetchStates = (options = {}) => useManagementQuery<State[]>('states', 'Organization/States', options)
export const useFetchZipCodes = (options = {}) =>
    useManagementQuery<ZipCode[]>('zipCodes', 'Organization/ZipCodes', options)
export const useFetchSectors = (options = {}) =>
    useManagementQuery<Array<Sector>, ResponseError>('sectors', 'Organization/Sectors', options)
export const useFetchTimezones = (options = {}) => useManagementQuery('timezones', 'Organization/Timezones', options)

export const useFetchContactRoles = (options = {}) =>
    useOrganizationQuery('contactRoles', 'Management/ContactRoles', options)
export const useFetchContactTypes = (options = {}) =>
    useOrganizationQuery<ContactType[]>('contactTypes', 'Management/ContactTypes', options)
export const useFetchOwnershipTypes = (options = {}) =>
    useOrganizationQuery<OwnershipType[]>('ownershipTypes', 'Management/OwnershipTypes', options)
