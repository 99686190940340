import filter from 'lodash/filter'
import type { AwardSelectionPageResult } from '../../AwardSelection'
import {
    InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage,
    InviteeCountSubstantiallyDifferentFromRegionalCountsMessage,
} from '../../Recipients/messages'
import SummaryCardSkeleton from '../Common/SummaryCardSkeleton'
import { AwardsSelected } from './Components/AwardsSelected'
import { AwardsSelectedNotConfirmed } from './Components/AwardsSelectedNotConfirmed'
import { EmployeeCountMismatch } from './Components/EmployeeCountMismatch'
import { ErrorAwardIdentification } from './Components/ErrorAwardIdentification'
import { NoAwardsSelected } from './Components/NoAwardsSelected'
import { NonParticipating } from './Components/NonParticipating'

type AwardIdentificationTileProps = {
    data: AwardSelectionPageResult
    isAwardIdentificationLoading: boolean
    surveyEventId: number
    isInviteeCountSubstantiallyDifferentFromRegionalCounts: boolean
    hasMinimumQualifyingInviteeCount: boolean

    // don't know what this actually is, will fix at some point
    awardIdentificationError: unknown
}
const AwardIdentificationTile = ({
    data,
    surveyEventId,
    isAwardIdentificationLoading,
    awardIdentificationError,
    isInviteeCountSubstantiallyDifferentFromRegionalCounts,
    hasMinimumQualifyingInviteeCount,
}: AwardIdentificationTileProps) => {
    if (awardIdentificationError) {
        return <ErrorAwardIdentification />
    } else {
        if (isAwardIdentificationLoading) {
            return <SummaryCardSkeleton />
        }

        if (data == null) {
            return <ErrorAwardIdentification />
        }

        if (!data.isTopWorkplaceParticipant) {
            return (
                <NonParticipating
                    surveyEventId={surveyEventId}
                    message={'You are not currently participating in any Top Workplaces Awards.'}
                />
            )
        }

        const showInviteeRegionalCountMessage =
            !hasMinimumQualifyingInviteeCount || isInviteeCountSubstantiallyDifferentFromRegionalCounts

        const inviteeRegionalCountMessage = !hasMinimumQualifyingInviteeCount
            ? InviteeCountDifferentFromMinimumQualifyingRegionalCountsMessage
            : isInviteeCountSubstantiallyDifferentFromRegionalCounts
            ? InviteeCountSubstantiallyDifferentFromRegionalCountsMessage
            : ''

        if (showInviteeRegionalCountMessage) {
            return <EmployeeCountMismatch surveyEventId={surveyEventId} message={inviteeRegionalCountMessage} />
        }

        const selectedAwards = filter(data.awards, ['isSelected', true])
        if (selectedAwards.length > 0) {
            if (data.isEmployeeCountConfirmed) {
                return <AwardsSelected awards={selectedAwards} surveyEventId={surveyEventId} />
            } else {
                return <AwardsSelectedNotConfirmed awards={selectedAwards} surveyEventId={surveyEventId} />
            }
        }

        return (
            <NoAwardsSelected
                surveyEventId={surveyEventId}
                message={
                    'You have not selected any Top Workplace Awards for this survey yet. Please visit the select awards section and choose the awards you would like to participate in.'
                }
            />
        )
    }
}
export { AwardIdentificationTile }
