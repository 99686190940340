import { useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import pick from 'lodash/pick'
import { useIdentity } from 'components/Identity'
import config from 'config'

const ENERGAGE_ORG_ID = '82969C9E-AB7D-4506-A1B8-C872C10BD843'
const ENERGAGE_COMPANY_ID = '102'

const ExternalAnalytics = () => {
    const identity = useIdentity()

    useEffect(() => {
        if (!identity) {
            return
        }

        configureDataDog(identity)
        configureFullStory(identity)
        configurePendo(identity)
    }, [identity])

    return null
}

function configureDataDog(identity) {
    if (!config.logging.datadog) {
        return
    }

    datadogLogs.init({
        service: 'platform-dashboard',
        env: config.environment,
        version: config.version,
        clientToken: config.logging.datadog.clientToken,
        site: config.logging.datadog.site,
        forwardErrorsToLogs: true,
        sessionSampleRate: 50,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
    })

    datadogRum.init({
        service: 'platform-dashboard',
        env: config.environment,
        version: config.version,
        applicationId: config.logging.datadog.applicationId,
        clientToken: config.logging.datadog.clientToken,
        site: config.logging.datadog.site,
        allowedTracingOrigins: [/https:\/\/.*\.energage\.com/],
        sessionSampleRate: 50,
        sessionReplaySampleRate: 50,
        traceSampleRate: 50,
        trackUserInteractions: true,
        trackInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
    })

    const props = ['isSuperUser', 'isTopLevelUser', 'isManager', 'isActionTrackingOnlyUser']

    const userInfo = pick(identity, props)

    datadogLogs.setUser({
        id: identity.surveyUserId,
        companyId: identity.isSuperUser ? ENERGAGE_COMPANY_ID : identity.surveyCompanyId,
        ...userInfo,
    })

    datadogRum.setUser({
        id: identity.surveyUserId,
        companyId: identity.isSuperUser ? ENERGAGE_COMPANY_ID : identity.surveyCompanyId,
        ...userInfo,
    })

    datadogRum.startSessionReplayRecording()
}

function configurePendo(identity) {
    if (window.pendo) {
        if (identity.isImpersonating) {
            return
        }
        const props = [
            'firstName',
            'lastName',
            'emailAddress',
            'organizationName',
            'isSuperUser',
            'isTopLevelUser',
            'isManager',
            'isActionTrackingOnlyUser',
        ]

        const pendoPayload = {
            visitor: {
                id: identity.surveyUserId,
                ...pick(identity, props),
                ...identity.permissions,
                globalUserId: identity.loginId.toUpperCase(),
            },
            account: {
                id: identity.salesforceAccountId,
                globalOrganizationId: identity.isSuperUser ? ENERGAGE_ORG_ID : identity.organizationId.toUpperCase(),
            },
        }

        window.pendo.initialize(pendoPayload)
    }
}

function configureFullStory(identity) {
    if (window.FS) {
        window.FS.identify(identity.loginId, {
            displayName: `${identity.firstName} ${identity.lastName}`,
            company: identity.organizationName,
            email: identity.emailAddress,
        })
    }
}

export default ExternalAnalytics
