import { useCallback, useMemo } from 'react'
import { useAtomValue } from 'jotai/utils'
import every from 'lodash/every'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import noop from 'lodash/noop'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import { questionMapAtom, selectedAdditionalQuestionsAtom } from '../statementSetupUtility/store'
import { setAllChecked } from './statementSetupHookUtils'

function isCheckedForAllChecked(question, questionMap) {
    if (question.isSelected) {
        return true
    }

    return (
        question.conditionalQuestionEssenceIds.length > 0 &&
        !every(question.conditionalQuestionEssenceIds, (id) => questionMap[id]?.isSelected)
    )
}

/**
 * @param demographicQuestions
 * @param allQuestions
 * @param onUpdate
 * @param onUpdateForRequired
 * @returns {{someChecked: boolean, handleToggleAll: ((function(): void)|*), handleToggleQuestion: ((function(*, *=): void)|*), handleToggleRequiredQuestion: ((function(*, *=): void)|*), allChecked}}
 */
export function useDemographicQuestionToggles(
    demographicQuestions,
    allQuestions,
    onUpdate = noop,
    onUpdateForRequired = noop
) {
    const questionMap = useAtomValue(questionMapAtom)
    const selectedCustomQuestions = useAtomValue(selectedAdditionalQuestionsAtom)

    const noneChecked = useMemo(() => every(demographicQuestions, (q) => !q.isSelected), [demographicQuestions])

    const allChecked = useMemo(
        () => every(demographicQuestions, (question) => isCheckedForAllChecked(question, questionMap)),
        [demographicQuestions, questionMap]
    )

    const handleToggleAll = useCallback(() => {
        const filteredArray = filter(demographicQuestions, (demographicQuestion) => {
            const hasSelectedConditional = some(selectedCustomQuestions, (customQuestion) =>
                includes(customQuestion.conditionalQuestionEssenceIds, demographicQuestion.questionEssenceId)
            )

            if (hasSelectedConditional === true) {
                return false
            }

            return allChecked || !demographicQuestion.isConditional || demographicQuestion.isParentSelected
        })

        let questionIds = map(filteredArray, 'questionEssenceId')

        const updates = reduce(questionIds, setAllChecked(!allChecked), {})
        onUpdate(updates)
    }, [demographicQuestions, allChecked, onUpdate, selectedCustomQuestions])

    const handleToggleQuestion = useCallback(
        (id, checked) => {
            let conditionalQuestionUpdates = {}
            if (!checked) {
                const childConditionalQuestionIds = map(
                    filter(allQuestions, (q) => includes(q.conditionalQuestionEssenceIs, id)),
                    'questionEssenceId'
                )
                conditionalQuestionUpdates = reduce(childConditionalQuestionIds, setAllChecked(false), {})
            }
            onUpdate({ [id]: checked, ...conditionalQuestionUpdates })
        },
        [onUpdate, allQuestions]
    )

    const handleToggleRequiredQuestion = useCallback(
        (id, checked) => {
            const updates = { [id]: checked }
            onUpdateForRequired(updates)
        },
        [onUpdateForRequired]
    )

    return {
        allChecked,
        someChecked: !allChecked && !noneChecked,
        handleToggleAll,
        handleToggleQuestion,
        handleToggleRequiredQuestion,
    }
}
