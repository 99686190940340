import { AppLink } from 'components/Anchor'
import { routes } from 'constants/routes'
import { List } from './awardScheduleChangeConfirmation.atoms'
import type {
    AwardChangeListWithSurveyName,
    AwardChangeWithSurveyName,
    UsedOnAnotherSurveyEventWithName,
} from './AwardScheduleChangeConfirmation.types'

type AwardInUseProps = {
    award: Omit<AwardChangeWithSurveyName, 'newAwardNotAvailableInfo'> & {
        newAwardNotAvailableInfo: UsedOnAnotherSurveyEventWithName
    }
}

function AwardInUseText({ award }: AwardInUseProps) {
    const to = routes.survey.setup({ surveyEventId: award.newAwardNotAvailableInfo.surveyEventId })
    return (
        <>
            {'Participating on '}
            <AppLink to={to}>{'another survey'}</AppLink>
        </>
    )
}

function ListItem(award: AwardChangeWithSurveyName) {
    return (
        <li className="flex mb-1">
            <span className="w-1/2 mr-2">{award.awardName}</span>
            {award.newAwardNotAvailableInfo ? (
                <span className="w-1/2">
                    {award.newAwardNotAvailableInfo.reason === 1 ? (
                        <AwardInUseText award={award as AwardInUseProps['award']} />
                    ) : (
                        '—'
                    )}
                </span>
            ) : null}
        </li>
    )
}

export function AwardsUnavailableList({ awards }: { awards: AwardChangeListWithSurveyName }) {
    return (
        <section className="flex flex-col mt-4">
            <p>{'The following award(s) will no longer be available:'}</p>
            <p className="flex font-bold mt-4">
                <span className="w-1/2">{'Award'}</span>
                <span className="w-1/2">{'Reason'}</span>
            </p>
            <ul className="pl-0 mt-4">
                <List awards={awards} renderListItem={ListItem} />
            </ul>
        </section>
    )
}
