import type { ChangeEvent, ReactNode } from 'react'
import noop from 'lodash/noop'
import { useDebouncedCallback } from 'use-debounce'
import { QuestionType } from '../../QuestionType'
import { QuestionInput } from '../CustomQuestionTextInputs'
import { AgreementOptions } from './AgreementOptions'
import { ConditionalQuestions } from './ConditionalQuestions'

type ChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => void

type StatementQuestionProps = {
    prompt: string
    placeholder?: string
    questionTypeId: QuestionType
    questionText?: string
    positiveConditionalText?: string
    negativeConditionalText?: string
    hasConditionals: boolean
    onChangeQuestionText?: (e: ChangeEvent<HTMLInputElement>) => void
    onChangePositiveConditionalText: ChangeHandler
    onChangeNegativeConditionalText: ChangeHandler
    onChangeHasConditionals: () => void
    children?: ReactNode
    errors?: Record<string, string>
}

export const StatementQuestion = ({
    prompt,
    placeholder,
    questionTypeId,
    questionText,
    positiveConditionalText,
    negativeConditionalText,
    hasConditionals,
    onChangeQuestionText = noop,
    onChangePositiveConditionalText,
    onChangeNegativeConditionalText,
    onChangeHasConditionals,
    errors,
    children,
}: StatementQuestionProps) => {
    const debouncedOnChangeQuestionText = useDebouncedCallback(onChangeQuestionText, 500)

    return (
        <div className="w-full">
            <QuestionInput
                label={prompt}
                placeholder={placeholder ?? 'Enter Your Question'}
                defaultValue={questionText}
                required
                name={`statement-question`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.persist()
                    debouncedOnChangeQuestionText(e)
                }}
                errors={errors}
                errorKey="required"
            />
            {QuestionType.Agreement === questionTypeId && (
                <AgreementOptions hasConditionals={hasConditionals} onChangeHasConditionals={onChangeHasConditionals} />
            )}
            {hasConditionals && questionTypeId === QuestionType.Agreement && (
                <ConditionalQuestions
                    positiveText={positiveConditionalText}
                    negativeText={negativeConditionalText}
                    onChangePositiveText={onChangePositiveConditionalText}
                    onChangeNegativeText={onChangeNegativeConditionalText}
                />
            )}
            {children}
        </div>
    )
}
