import cx from 'clsx'
import map from 'lodash/map'
import range from 'lodash/range'

export default function Dots({ count, active }) {
    return map(range(count), (i) => (
        <div
            key={i}
            className={cx('h-2 w-2 inline-block mr-1 last:mr-0 rounded-full', {
                'bg-grey400': active === i,
                'bg-grey300': active !== i,
            })}
        />
    ))
}
