import { LegacyButton as Button } from '@energage/components'
import { CalendarToday } from '@energage/icons'
import { colors } from '@energage/theme'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AccessibleTileStatus, RoundedIconHolder } from '../fragments'

const Unscheduled = ({ id, surveyName }) => (
    <div className="h-full text-center">
        {surveyName}
        <AccessibleTileStatus backgroundColor="bg-blue500" statusText={'Not Ready'} />
        <p className="mb-6 text-grey500 text-xs">{`Not Scheduled`}</p>
        <RoundedIconHolder backgroundColor="bg-blue100">
            <CalendarToday fill={colors.blue600} size={80} />
        </RoundedIconHolder>
        <h5 className="text-blue700 px-10 py-6">{`When would you like to start collecting feedback?`}</h5>
        <div className="flex justify-center py-4 border-grey200 border-t-2">
            <Button
                size="sm"
                variant="secondary"
                as={Link}
                to={routes.survey.setup.schedule({ surveyEventId: id })}>{`Schedule Your Survey`}</Button>
        </div>
    </div>
)

export default Unscheduled
