import map from 'lodash/map'
import range from 'lodash/range'
import styled from 'styled-components'

const WatermarkText = styled.div.attrs({ className: 'text-5xl text-white absolute' })`
    transform: rotate(-45deg);
`

export const Watermark = () => {
    return (
        <div className="inset-0 block min-h-1/2 min-w-1/2 ">
            <WatermarkText>
                {map(range(6), (x) => (
                    <span key={x}> {'OUT OF DATE'}</span>
                ))}
            </WatermarkText>
        </div>
    )
}
