import { addDays, addMilliseconds, differenceInCalendarDays, isSameDay, isSaturday, isSunday } from 'date-fns'
import forEach from 'lodash/forEach'
import type { Invitations } from './scheduleReducer.types'

export function slideReminders(newStartDateWithCorrectTime: Date, invitationDates: Invitations) {
    let prevReminderDate = newStartDateWithCorrectTime
    const updatedInvitationDates: Invitations = [{ ...invitationDates[0], dateTime: prevReminderDate }]

    forEach(invitationDates, (invitationObj, index) => {
        const invitation = { ...invitationObj }
        if (invitation.reminderOffsetMs <= 0) {
            return
        }

        const msOffsetFromPrevReminder =
            invitationDates[index].reminderOffsetMs - invitationDates[index - 1].reminderOffsetMs

        let reminderDate = addMilliseconds(prevReminderDate, msOffsetFromPrevReminder)
        //do not move reminders that are on the same day as start date
        if (!isSameDay(reminderDate, newStartDateWithCorrectTime)) {
            if (isSaturday(reminderDate)) {
                reminderDate = addDays(reminderDate, -1)
                const newCalendarDaysOffsetFromPrevReminder = differenceInCalendarDays(reminderDate, prevReminderDate)
                const calendarDaysOffsetFromPrevReminder = differenceInCalendarDays(
                    invitationDates[index].dateTime as Date,
                    invitationDates[index - 1].dateTime as Date
                )
                //allow next/same day reminder only if it's originally scheduled that way
                if (
                    (newCalendarDaysOffsetFromPrevReminder < 1 && calendarDaysOffsetFromPrevReminder >= 1) ||
                    (newCalendarDaysOffsetFromPrevReminder === 1 && calendarDaysOffsetFromPrevReminder > 1)
                ) {
                    reminderDate = addDays(reminderDate, 3)
                }
            } else if (isSunday(reminderDate)) {
                reminderDate = addDays(reminderDate, 1)
            }
        }

        prevReminderDate = reminderDate
        invitation.dateTime = reminderDate
        updatedInvitationDates.push(invitation)
    })
    return updatedInvitationDates
}
