import { useCallback, useState } from 'react'
import { alert } from '@energage/components'
import { management, useWorkplaceSurveyQuery } from 'api'

export default function useSurveyCache(surveyEventId) {
    const [loading, setLoading] = useState(false)

    const clearCache = useCallback(async () => {
        setLoading(true)
        await management.delete(`administration/surveycache`)
        setLoading(false)
    }, [])

    const { mutateAsync: clearCacheSurveyEvent, isLoading } = useWorkplaceSurveyQuery.mutate(
        `surveycache/${surveyEventId}`,
        {
            onError: () => alert.danger('There was an error cleaning cache'),
        }
    )

    return {
        clearCache,
        loading,
        clearCacheSurveyEvent,
        isLoading,
    }
}
