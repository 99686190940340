import cx from 'clsx'
import map from 'lodash/map'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import QuestionCategory from '../../QuestionCategory'
import { SortableQuestionOverflowMenu } from '../../QuestionOverflowMenu'
import { HeaderCell, HeaderRow, Table, TableRow } from '../TableElements'
import { SortableQuestionTableRow } from './SortableQuestionTableRow'

export function SortableQuestionTable({
    categoryId,
    title,
    onDragEnd,
    sortableQuestions,
    otherQuestions,
    disabled,
    isSortingDisabled,
    droppableId,
    onRemoveQuestion,
    className,
    editingQuestionId,
    originalQuestionText,
    onCancelEditQuestion,
    onEditQuestion,
    onUpdateQuestion,
    isSavingQuestion,
    hasOptionalItems,
    allowEditNonCustomQuestions,
    departments,
}) {
    const questions = [...sortableQuestions, ...otherQuestions]
    const questionEditProps = {
        editingQuestionId,
        onCancelEditQuestion,
        onUpdateQuestion,
        isSavingQuestion,
        originalQuestionText,
    }

    const displayTargetingColumn = categoryId === QuestionCategory.Custom

    return (
        <div className={className}>
            {title && <div className="font-bold pb-2">{title}</div>}
            <Table>
                <HeaderRow>
                    <HeaderCell className="w-1/3 md:w-1/6">
                        <div className="pl-7 pr-4 md:pr-2">{'Topic'}</div>
                    </HeaderCell>
                    <HeaderCell className="w-2/3 md:w-1/3">
                        <label htmlFor={categoryId}>{'Item'}</label>
                    </HeaderCell>
                    <HeaderCell
                        collapse={true}
                        className={cx('w-full pr-2', {
                            'md:w-1/5': displayTargetingColumn,
                            'md:w-1/2': !displayTargetingColumn,
                        })}>
                        {'Details'}
                    </HeaderCell>
                    {displayTargetingColumn ? (
                        <HeaderCell collapse={true} className="w-1/5">
                            {'Targeting'}
                        </HeaderCell>
                    ) : null}
                </HeaderRow>
                <div className="w-full">
                    {hasOptionalItems && questions.length === 0 && (
                        <TableRow>{`Add optional items from the list below if you would like to include them in your survey.`}</TableRow>
                    )}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`${droppableId}`} isDropDisabled={isSortingDisabled}>
                            {(droppableProvided) => {
                                return (
                                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                        {map(sortableQuestions, (q, i) => {
                                            return (
                                                <Draggable
                                                    isDragDisabled={isSortingDisabled}
                                                    key={q.questionEssenceId + 'draggable'}
                                                    draggableId={`${q.questionEssenceId}`}
                                                    index={i}>
                                                    {(draggableProvided) => {
                                                        return (
                                                            <SortableRow
                                                                key={q.questionEssenceId}
                                                                question={q}
                                                                isSortingDisabled={isSortingDisabled}
                                                                departments={departments}
                                                                hasTargetingColumn={displayTargetingColumn}
                                                                questionOverflowMenuComponent={
                                                                    <SortableQuestionOverflowMenu
                                                                        disabled={disabled}
                                                                        allowEditNonCustomQuestions={
                                                                            allowEditNonCustomQuestions
                                                                        }
                                                                        onEditQuestion={onEditQuestion}
                                                                        onRemoveQuestion={onRemoveQuestion}
                                                                        question={q}
                                                                    />
                                                                }
                                                                {...questionEditProps}
                                                                draggableProvided={draggableProvided}
                                                            />
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                        {droppableProvided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    </DragDropContext>
                    {map(otherQuestions, (q) => {
                        return (
                            <SortableRow
                                key={q.questionEssenceId}
                                question={q}
                                isSortingDisabled={true}
                                departments={departments}
                                questionOverflowMenuComponent={
                                    <SortableQuestionOverflowMenu
                                        disabled={disabled}
                                        allowEditNonCustomQuestions={allowEditNonCustomQuestions}
                                        onEditQuestion={onEditQuestion}
                                        onRemoveQuestion={onRemoveQuestion}
                                        question={q}
                                    />
                                }
                                {...questionEditProps}
                            />
                        )
                    })}
                </div>
            </Table>
        </div>
    )
}

const SortableRow = ({
    question,
    questionOverflowMenuComponent,
    draggableProvided,
    isSortingDisabled,
    editingQuestionId,
    originalQuestionText,
    onCancelEditQuestion,
    onUpdateQuestion,
    isSavingQuestion,
    departments,
    hasTargetingColumn,
}) => (
    <SortableQuestionTableRow
        question={question}
        ref={draggableProvided?.innerRef}
        isSortingDisabled={isSortingDisabled}
        key={`${question.questionEssenceId}-${question.text}`}
        selected
        isConditional={question.conditionalQuestionEssenceIds.length > 0}
        parentSelected={true}
        isEditing={question.questionEssenceId === editingQuestionId}
        originalQuestionText={originalQuestionText}
        onCancelEditAdditionalQuestion={onCancelEditQuestion}
        saveQuestion={onUpdateQuestion}
        isSavingQuestion={isSavingQuestion}
        draggable={draggableProvided?.draggableProps}
        dragHandle={draggableProvided?.dragHandleProps}
        QuestionOverflowMenuComponent={questionOverflowMenuComponent}
        departments={departments}
        hasTargetingColumn={hasTargetingColumn}
    />
)
