import { borderRadius, colors, spacing, textSize } from '@energage/theme'
import { css } from 'styled-components'

const backgroundColor = colors.white
const borderColor = colors.grey300
const highlightedColor = colors.grey200
const selectedColor = colors.blue500
const selectedBackgroundColor = colors.blue100
const disabledColor = colors.grey500
const textColor = colors.grey700

const datePickerBorderRadius = borderRadius('default')
const dayMargin = spacing(0)
const fontSize = textSize('base')
const itemSize = spacing(10)
const dropdownSpacing = spacing(1)
const monthContainerMargin = spacing(4)

const getDatePickerStyles = () => css`
    .react-datepicker {
        font-size: ${fontSize};
        background-color: ${backgroundColor};
        color: ${textColor};
        border: 1px solid ${borderColor};
        border-radius: ${datePickerBorderRadius};
        display: inline-block;
        position: relative;
        padding-bottom: ${spacing(4)};

        &-wrapper {
            display: block;
        }

        &__navigation,
        &__current-month {
            display: none;
        }

        &-popper {
            z-index: 1;

            &[data-placement^='bottom'] {
                margin-top: ${dropdownSpacing};
            }

            &[data-placement^='top'] {
            }
        }

        &--time-only {
            padding-bottom: 0;

            .react-datepicker__header {
                padding: 0;
            }
        }

        &__header {
            text-align: center;
            padding-top: ${spacing(2)};
            background: none;

            &__dropdown {
                display: inline-flex;

                & select {
                    outline: none;
                    background-color: ${backgroundColor};
                }
            }
        }

        &__month-container {
            margin-left: ${monthContainerMargin};
            margin-right: ${monthContainerMargin};
            float: left;
        }

        &__month {
            border-bottom: 1px solid ${borderColor};
            margin-bottom: ${spacing(1)};
            padding-top: ${spacing(1)};
            padding-bottom: ${spacing(1)};
        }

        &__day-names,
        &__week {
            white-space: nowrap;
        }

        &__day-names {
            font-size: ${textSize('xs')};
            text-transform: uppercase;
            border-bottom: 1px solid ${borderColor};
            padding-top: ${spacing(1)};
        }

        &__day-name,
        &__day,
        &__time-name {
            color: ${textColor};
            display: inline-block;
            width: ${itemSize};
            text-align: center;
            margin: ${dayMargin};
        }

        &__day,
        &__time-name {
            line-height: ${itemSize};
        }

        &__day {
            cursor: pointer;
            font-weight: 600;

            &:hover {
                border-radius: ${datePickerBorderRadius};
                background-color: ${highlightedColor};
            }

            &--highlighted {
                font-weight: 600;
            }

            &--selected {
                border-radius: 0;
                background-color: ${selectedBackgroundColor};
                color: ${selectedColor};
                font-weight: bold;

                &:hover {
                    border-radius: 0;
                    background-color: ${selectedBackgroundColor};
                }
            }

            &--disabled {
                cursor: default;
                color: ${disabledColor};
                font-weight: normal;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        &__time {
            position: relative;
        }

        &__time-box {
            width: ${spacing(32)};
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
        }

        &__time-list {
            list-style: none;
            margin: 0;
            padding-right: 0;
            padding-left: 0;
            height: ${spacing(48)};
            overflow-y: scroll;
            width: 100%;
            box-sizing: content-box;
        }

        &__time-list-item {
            padding: ${spacing(1)} 0;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                background-color: ${highlightedColor};
            }

            &--selected {
                background-color: ${selectedBackgroundColor};
                color: ${selectedColor};
                font-weight: bold;

                &:hover {
                    background-color: ${selectedBackgroundColor};
                }
            }
        }
    }
`

export default getDatePickerStyles
