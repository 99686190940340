import { CardContainer } from 'components/CardContainer'
import FileDropZone, { contentByState } from './FileDropZone'
import type { BaseContent } from './FileDropZone'

export const ERRORS = {
    'File is password-protected': {
        passwordProtected: [
            {
                errorCode: 'PasswordProtected',
                errorMessage:
                    'This spreadsheet appears to be password-protected. Please remove the protection and upload again.',
                propertyName: 'PasswordProtected',
                attemptedValue: 'File encrypted',
                customState: '',
                severity: 0,
            },
        ],
    },
}

type Members = Record<string, unknown>

interface Warning {
    data: {
        code: string
    }
}

interface UploadState {
    membersToUpload: Members[] | null
    uploadedMembers: Members[] | null
    warnings: Warning[]
    hasActiveSurveyEvents: boolean
    successful: boolean
    validationErrors?: Error
}

type GetDropZoneState = (data?: UploadState, loading?: boolean, error?: Error) => BaseContent
const getDropZoneState: GetDropZoneState = (data, loading, error) => {
    if (error && error.hasOwnProperty('wrongHeaders')) {
        return contentByState.headersError
    }
    if (error) {
        return contentByState.error
    }
    if (loading) {
        return contentByState.loading
    }
    if (data) {
        return data.validationErrors ? contentByState.error : contentByState.success
    }
    return contentByState.waiting
}

interface UploadRecipientsProps {
    errors: Error | undefined
    onFileDrop: (file: File) => void
    data: UploadState
    loading: boolean
}

const UploadRecipients = ({ errors, onFileDrop, data, loading }: UploadRecipientsProps) => {
    return (
        <div className="h-full mx-auto text-center">
            <CardContainer>
                <p className="text-xl text-center">{'Upload your employee data sheet'}</p>

                <div className="p-4">
                    <FileDropZone
                        state={getDropZoneState(data, loading, errors)}
                        onFileDrop={onFileDrop}
                        errorMessage={errors?.message}
                    />
                </div>
                <span className="paragraph-small">{'Accepted file formats: .xls, .xlsx, .csv'}</span>
            </CardContainer>
        </div>
    )
}

export default UploadRecipients
