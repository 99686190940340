import { boolean, object, string } from 'yup'
import type { TestContext, TestFunction } from 'yup'
import type { AnyObject } from 'yup/lib/types'
import type { ExistingUser } from './models'
import { EMAIL_EXISTS } from './UserManagement.util'

const validateExistingEmail = (): TestFunction<string | undefined, AnyObject> => {
    return (value, { options: { context }, createError, path }: TestContext<AnyObject>) => {
        const match = context?.existingUsers?.find((item: ExistingUser) => item.email === value)
        if (match) {
            return createError({ path, message: match.id.toString() })
        }

        return true
    }
}

export const userValidationSchema = object()
    .shape({
        firstName: string()
            .required('First Name is a required field')
            .max(63, 'First Name must be at most 63 characters')
            .matches(/[^\s]/, 'First Name must not only contain spaces'),
        lastName: string()
            .required('Last Name is a required field')
            .max(63, 'Last Name must be at most 63 characters')
            .matches(/[^\s]/, 'Last Name must not only contain spaces'),
        email: string()
            .required('Email is a required field')
            .email('Please type a valid email')
            .max(128, 'Email must be at most 128 characters')
            .test(EMAIL_EXISTS, 'Email already exists for this organization', validateExistingEmail()),
        isCustomerSuccessPrimaryContact: boolean().required(),
        isDisabled: boolean(),
    })
    .required()
