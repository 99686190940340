import styled from 'styled-components'

export const VisuallyHidden = styled.span`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: fixed;
    width: 1px;
    white-space: nowrap;
    word-wrap: normal;
`
