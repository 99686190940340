import cx from 'clsx'
import map from 'lodash/map'
import styled from 'styled-components'
import type { ListSections } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { media } from 'style/breakpoints'

const Wrapper = styled.div.attrs({
    className: 'ml-5',
})`
    overflow-wrap: anywhere;
`

const BadgeLogo = styled.img<{ isProBadgeLogo?: boolean }>`
    ${(props) => props.isProBadgeLogo && 'right: 5px;'}
    ${(props) => (props.isProBadgeLogo ? 'top: 5px;' : 'top : 1px;')}
    ${media.smMin`
        ${(props: { isProBadgeLogo: boolean }) => props.isProBadgeLogo && 'right: 5px;'}
        ${(props: { isProBadgeLogo: boolean }) => (props.isProBadgeLogo ? 'top: -4px;' : 'top : -8px;')}
    `}
`

const PdfBadgeLogo = styled.img<{ isProBadgeLogo?: boolean }>`
    ${(props) => props.isProBadgeLogo && 'right: 5px;'}
    ${(props) => (props.isProBadgeLogo ? 'top: 5px;' : 'top : 1px;')}
`

export const ListSection = ({ heading, text, subText, list, sectionLogoPath, isProBadgeLogo, isPdf }: ListSections) => {
    const BadgeLogoComponent = isPdf ? PdfBadgeLogo : BadgeLogo

    return (
        <div className={'flex flex-col flex-1 mt-8 sm:mt-2'}>
            <div className="relative w-full">
                <BadgeLogoComponent
                    alt="logo"
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    src={require(`images/employerBranding/mediaKit/${sectionLogoPath}`).default}
                    className={cx('absolute pr-6 right-0', { 'w-28 -mt-3': isPdf, 'w-32 -mt-2 sm:mt-px': !isPdf })}
                    isProBadgeLogo={isProBadgeLogo}
                />
            </div>
            <div className={cx('flex-1 bg-white', { 'm-1 px-3 ml-1 mr-2': isPdf, 'm-2 sm:m-2 px-4 sm:px-8': !isPdf })}>
                <h1 className={cx('text-xl mt-20', { 'sm:text-4xl sm:mt-24': !isPdf })}>{heading}</h1>
                <p className={isPdf ? 'text-sm mb-2' : 'text-base sm:text-base sm:mb-8 mb-4'}>{text}</p>
                <p className={isPdf ? 'text-sm' : 'text-base sm:text-xl'}>{subText}</p>
                <div className={cx('my-2', { 'sm:my-8': !isPdf })}>
                    {map(list, ({ imgPath, heading, text }, index) => (
                        <div key={index} className={cx('py-2', { 'sm:py-4': !isPdf })}>
                            <div className="flex items-start items-center">
                                <div className="flex-none">
                                    <img
                                        alt="CE awards logos"
                                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                                        src={require(`images/employerBranding/mediaKit/${imgPath}`).default}
                                        className={isPdf ? 'w-20' : 'object-cover'}
                                    />
                                </div>
                                <Wrapper>
                                    <h2 className={cx('text-base', { 'sm:text-2xl': !isPdf })}>{heading}</h2>
                                    <p className={isPdf ? 'text-sm' : 'text-base sm:text-xl'}>{text}</p>
                                </Wrapper>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
