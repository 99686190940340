import get from 'lodash/get'
import { parse } from 'query-string'
import { IS_LOGGED_IN } from 'constants/storageKeys'

const getQueryParamToken = (search) => get(parse(search), 'renderer_token', null)

export default {
    state: {
        accessToken: getQueryParamToken(window.location.search),
        idToken: null,
        isLoggedIn: Boolean(window.localStorage.getItem(IS_LOGGED_IN) || false),
    },
    reducers: {
        setLoggedIn(state, { accessToken, idToken }) {
            state.accessToken = accessToken
            state.idToken = idToken
            state.isLoggedIn = true
        },
        updateTokens(state, { accessToken, idToken }) {
            state.accessToken = accessToken
            state.idToken = idToken
        },
        setLoggedOut(state) {
            state.isLoggedIn = false
        },
    },
    selectors: (slice) => ({
        selectAuthentication() {
            return slice
        },
        selectAccessToken() {
            return slice((state) => state.accessToken)
        },
    }),
}
