import { useCallback } from 'react'
import every from 'lodash/every'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { withRouter } from 'react-router-dom'
import Container from 'components/Container'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import { routes } from 'constants/routes'
import DepartmentRow, { TABLE_COLUMNS } from 'containers/Main/Manage/Employees/DepartmentView/DepartmentRow'
import { TableContainer } from 'containers/Main/Manage/Employees/Table'
import BulkUpload from 'containers/Organization/Upload'
import ModifyActions from '../ModifyActions'
import { TargetRecipients } from '../TargetRecipients'
import useModifyRecipientDepartments from './useModifyRecipientDepartments'
import useRecipientsList from './useRecipientsList'

const checked = (dept) => {
    if (!dept.checked) {
        return false
    }

    let allSelected = true
    forEach(dept.children, (child) => {
        if (!checked(child)) {
            allSelected = false
            return
        }
    })

    return allSelected
}

const Title = () => (
    <div>
        <h6 className="text-4xl font-thin w-2/3 m-auto">{`No departments listed.`}</h6>
        <p className="text-xl font-thin w-2/3 m-auto">{`You can add departments to your employee list upload.`}</p>
    </div>
)

const DepartmentView = ({ match, history }) => {
    const surveyEventId = match.params.surveyEventId
    const {
        isLoading,
        error,
        rootDepartments: topLevelDepartments,
        noDepartmentsUploaded,
        surveyEventSetup,
        energageFilterOptionTags,
        selectedFilterOptionIds,
        setSelectedFilterOptionIds,
        modifyRecipients,
        selectAllRecipients,
        deselectAllRecipients,
        doSurveyEventSetupFetch,
    } = useRecipientsList()
    const identity = useIdentity()

    const redirectToRecipientsConfirmationPage = () => {
        history.push(routes.survey.setup.recipients({ surveyEventId }))
    }

    const { update, isUpdating } = useModifyRecipientDepartments(
        identity.organizationId,
        surveyEventId,
        surveyEventSetup.id,
        topLevelDepartments,
        selectedFilterOptionIds,
        redirectToRecipientsConfirmationPage,
        doSurveyEventSetupFetch
    )

    const selectDepartment = useCallback(
        (item) => (event) => modifyRecipients(item, event.target.checked),
        [modifyRecipients]
    )

    const allSelected = every(map(topLevelDepartments, checked), (i) => i === true)

    const toggleAll = useCallback(() => {
        if (allSelected) {
            deselectAllRecipients()
        } else {
            selectAllRecipients()
        }
    }, [allSelected, selectAllRecipients, deselectAllRecipients])

    return (
        <Container>
            <Inset padding className="pt-0 sm:pt-0">
                <TargetRecipients
                    options={energageFilterOptionTags}
                    surveyEventId={surveyEventId}
                    selectedFilterOptionIds={selectedFilterOptionIds}
                    setSelectedFilterOptionIds={setSelectedFilterOptionIds}
                />
                <ModifyActions
                    isPosting={isUpdating}
                    surveyEventId={surveyEventId}
                    onSave={update}
                    disabledSave={isUpdating}
                />
                <div data-testid="survey-recipients-depview-table" className="py-5">
                    <h6 className="mb-2">{'Department View'}</h6>
                    {!error && !isLoading && isEmpty(topLevelDepartments) ? (
                        noDepartmentsUploaded ? (
                            <BulkUpload title={<Title />} isFreshUpload={false} />
                        ) : (
                            <div>{`No matches found.`}</div>
                        )
                    ) : (
                        <TableContainer
                            isLoading={isLoading}
                            columns={TABLE_COLUMNS}
                            listName="departments"
                            error={error}
                            onCheckboxClick={toggleAll}
                            checked={allSelected}
                            isSelectable>
                            {map(topLevelDepartments, (d) => (
                                <DepartmentRow
                                    key={d.id}
                                    department={d}
                                    isSelectable
                                    onCheckboxClick={selectDepartment}
                                />
                            ))}
                        </TableContainer>
                    )}
                </div>
            </Inset>
        </Container>
    )
}

export default withRouter(DepartmentView)
