export const QuestionType = {
    Demographic: 1,
    Agreement: 2,
    Comment: 3,
    Rating: 4,
    YesNo: 12,
    MultiComment: 7,
    Feedback: 8,
    TextBlock: 9,
    CustomScale: 10,
    MultiChoice: 11,
    OldYesNo: 5,
    TrueFalse: 13,
} as const

export type QuestionType = typeof QuestionType[keyof typeof QuestionType]
