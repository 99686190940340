import { useCallback, useState } from 'react'
import { useAtomValue } from 'jotai/utils'
import map from 'lodash/map'
import some from 'lodash/some'
import { generateRandomKey } from 'util/generateRandomKey'
import { isEmptyString } from 'util/strings'
import { QuestionType } from './CustomQuestions/QuestionType'
import { selectedAdditionalQuestionNamesAtom } from './statementSetupUtility/store'

function getDefaultQuestionProperties(questionTypeId) {
    switch (questionTypeId) {
        case QuestionType.MultiChoice:
            return {
                answerOptions: [
                    { id: generateRandomKey(), name: '' },
                    { id: generateRandomKey(), name: '' },
                ],
            }
        case QuestionType.TrueFalse:
        case QuestionType.YesNo:
            return {
                answerOptions: undefined,
            }
        case QuestionType.MultiComment:
            return {
                textboxCount: 3,
            }
        default:
            return {}
    }
}

function useCustomQuestionCreation(initialState = []) {
    const [customQuestions, setCustomQuestions] = useState(() =>
        map(initialState, (question) => {
            return {
                ...question,
                _key: question._key ?? generateRandomKey(),
                _errors: question._errors ?? {},
                hasConditionals: !!question.positiveConditionalText || !!question.negativeConditionalText,
            }
        })
    )

    const questionNames = useAtomValue(selectedAdditionalQuestionNamesAtom)

    const handleAddCustomQuestion = () => {
        const newCustomQuestions = customQuestions.slice()
        const newQuestion = {
            questionTypeId: null,
            name: '',
            text: '',
            textboxCount: null,
            isPositivelyPhrased: true,
            positiveConditionalText: null,
            negativeConditionalText: null,
            hasConditionals: false,
            isSubmitting: false,
            targetDemographic: [],
            departmentIds: [],
            _key: generateRandomKey(),
            _errors: {},
        }
        newCustomQuestions.push(newQuestion)
        setCustomQuestions(newCustomQuestions)
    }

    const handleAddRemoveConditionals = useCallback((index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            if (updatedCustomQuestions[index].hasConditionals) {
                updatedCustomQuestions[index].positiveConditionalText = ''
                updatedCustomQuestions[index].negativeConditionalText = ''
            }
            updatedCustomQuestions[index].hasConditionals = !previousCustomQuestions[index].hasConditionals
            return updatedCustomQuestions
        })
    }, [])

    const handleQuestionTypeSelection = useCallback((selectedValue, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].questionTypeId = selectedValue
            if (selectedValue !== QuestionType.MultiChoice) {
                updatedCustomQuestions[index].answerOptions = []
            }
            updatedCustomQuestions[index] = Object.assign(
                {},
                updatedCustomQuestions[index],
                getDefaultQuestionProperties(selectedValue)
            )
            return updatedCustomQuestions
        })
    }, [])

    const handleQuestionDepartmentSelection = useCallback((departmentIds, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].departmentIds = departmentIds
            return updatedCustomQuestions
        })
    }, [])

    const handleQuestionNameChange = useCallback(
        (event, index) => {
            setCustomQuestions((previousCustomQuestions) => {
                const updatedCustomQuestions = previousCustomQuestions.slice()
                updatedCustomQuestions[index]._errors.duplicateName = validateDuplicateName(event.target.value, [
                    ...updatedCustomQuestions,
                    ...questionNames,
                ])

                updatedCustomQuestions[index]._errors.noName = isEmptyString(event.target.value)
                updatedCustomQuestions[index].name = event.target.value
                return updatedCustomQuestions
            })
        },
        [questionNames]
    )

    const handleDemographicTargetChange = useCallback((targetDemographic, index) => {
        setCustomQuestions((customQuestions) => {
            const updatedCustomQuestions = customQuestions.slice()

            updatedCustomQuestions[index] = { ...updatedCustomQuestions[index], targetDemographic }
            return updatedCustomQuestions
        })
    }, [])

    const handleQuestionTextChange = useCallback((event, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            const value = event.target.value
            updatedCustomQuestions[index].text = value
            updatedCustomQuestions[index]._errors.required = isEmptyString(value) ? 'Required' : null

            updatedCustomQuestions[index]._errors.noName = isEmptyString(updatedCustomQuestions[index].name)
            return updatedCustomQuestions
        })
    }, [])

    const handleMultiCommentCountChange = useCallback((selectedValue, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].textboxCount = selectedValue
            return updatedCustomQuestions
        })
    }, [])

    const handleDeleteQuestion = useCallback((index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions.splice(index, 1)
            return updatedCustomQuestions
        })
    }, [])

    const handlePositiveConditionalTextChange = useCallback((event, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].positiveConditionalText = event.target.value
            return updatedCustomQuestions
        })
    }, [])

    const handleNegativeConditionalTextChange = useCallback((event, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].negativeConditionalText = event.target.value
            return updatedCustomQuestions
        })
    }, [])

    const resetCustomQuestions = useCallback((questions = []) => {
        setCustomQuestions(questions)
    }, [])

    const handleOptionsChange = useCallback((options, index) => {
        setCustomQuestions((previousCustomQuestions) => {
            const updatedCustomQuestions = previousCustomQuestions.slice()
            updatedCustomQuestions[index].answerOptions = options
            return updatedCustomQuestions
        })
    }, [])

    return {
        customQuestions,
        setCustomQuestions,
        handleAddCustomQuestion,
        handleAddRemoveConditionals,
        handleQuestionTypeSelection,
        handleQuestionNameChange,
        handleQuestionTextChange,
        handleMultiCommentCountChange,
        handlePositiveConditionalTextChange,
        handleNegativeConditionalTextChange,
        handleDeleteQuestion,
        handleDemographicTargetChange,
        resetCustomQuestions,
        handleQuestionDepartmentSelection,
        handleOptionsChange,
    }
}

export default useCustomQuestionCreation

function validateDuplicateName(name, questions) {
    return some(questions, ['name', name])
}
