import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import type { ContextProviderArg } from './awardRegistration.types'
import { useFetchAwardRegistration } from './useFetchAwardRegistration'
import type { AwardRegistrationData } from './useFetchAwardRegistration'

const AwardRegistrationContext = createContext<AwardRegistrationData>({} as AwardRegistrationData)

export const useAwardRegistrationData = () => {
    const ctx = useContext<AwardRegistrationData>(AwardRegistrationContext)

    if (!ctx) {
        throw new Error('useAwardRegistrationData must be used within AwardRegistrationProvider')
    }

    return ctx
}

export const AwardRegistrationProvider = ({ awardListParticipantId, children }: ContextProviderArg) => {
    const award = useFetchAwardRegistration(awardListParticipantId)
    return <AwardRegistrationContext.Provider value={award}>{children}</AwardRegistrationContext.Provider>
}

AwardRegistrationProvider.propTypes = {
    children: PropTypes.node,
}
