import { LegacyButton as Button } from '@energage/components'
import Cookies from 'js-cookie'

const ImpersonationBar = (props) => (
    <div>
        <span className={'mt-2'}>{`Impersonation in progress: ${props.emailAddress}`}</span>
        <Button variant="link" onClick={stopImpersonating}>
            {'Stop Impersonating'}
        </Button>
    </div>
)

function stopImpersonating() {
    Cookies.remove('impersonationKey', {
        domain: '.' + document.domain.split('.').reverse().splice(0, 2).reverse().join('.'),
        secure: true,
    })
    window.location.reload()
}

export default ImpersonationBar
