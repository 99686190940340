import { colors } from '@energage/theme'
import cx from 'clsx'
import head from 'lodash/head'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import type { CongratulationSectionRevamp } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { AWARD_CATEGORY, BOSTON_PUBLISHER_ID, DALLAS_PUBLISHER_ID } from 'containers/Main/AwardAnnouncement/constants'
import congratulationBannerPdf from 'images/awardAnnouncement/congratulationBanner.jpg'
import congratulationBanner from 'images/awardAnnouncement/congratulationBanner.svg'
import congratulationLayer from 'images/awardAnnouncement/congratulationsLayer.svg'
import { media } from 'style/breakpoints'
import { BgContainer } from '../BgContainer'
import { WinnerAwards } from '../WinnerAwards'

const CongratulationsBanner = styled.img`
    object-fit: cover;
    object-position: 21%;
`
const StyledBannerHeading = styled.h1`
    font-size: 2.5rem;
    ${media.smMin`
        font-size: 3rem;
    `}
    ${media.mdMin`
        font-size: 3.5rem;
    `}
`

export const Congratulations = ({
    awardCategoryId,
    companyName,
    year,
    publisherId,
    isPdf,
    svgUrl,
    awardAliasNameWithoutYear,
    isNationalStandard,
}: CongratulationSectionRevamp) => {
    const fontFamily = publisherId === BOSTON_PUBLISHER_ID ? null : `'Yantramanav', sans-serif`
    const isDallasPublisher = publisherId === DALLAS_PUBLISHER_ID
    const isBostonPublisher = publisherId === BOSTON_PUBLISHER_ID
    const heightForDallasPublisher = isNationalStandard ? (isPdf ? '13rem' : '20.8rem') : '10rem'
    const customPublisherHeight = isDallasPublisher ? heightForDallasPublisher : isPdf ? '10rem' : '7rem'
    const height = isDallasPublisher || isBostonPublisher ? customPublisherHeight : isPdf ? '13rem' : '20.8rem'
    const individualizedSvgUrl = head(svgUrl)?.svgUrl
    const congratulationBannerImg = isPdf ? congratulationBannerPdf : congratulationBanner
    const sectionBgColor = awardCategoryId === AWARD_CATEGORY.INDUSTRY ? colors.white : colors.darkblue500
    const sectionTextColor = awardCategoryId === AWARD_CATEGORY.INDUSTRY ? colors.grey900 : colors.white

    return (
        <>
            <BgContainer
                backgroundColor={sectionBgColor}
                textColor={sectionTextColor}
                {...(isPdf && { isDefaultBgHeight: true })}>
                <div className="absolute w-full z-20">
                    <img src={congratulationLayer} className="w-full" alt="Congratulation Banner" aria-hidden />
                </div>
                <div className={cx('flex', { 'flex-col-reverse': isPdf })}>
                    <div
                        className={cx('text-center flex flex-col z-30', {
                            'w-full sm:w-3/5 md:w-1/2 lg:w-2/5 mt-20': !isPdf,
                            'items-center justify-center px-4 py-12': isPdf,
                        })}>
                        <StyledBannerHeading className={cx({ 'font-light pt-5 sm:pb-5': !isPdf, 'text-6xl': isPdf })}>
                            {'Congratulations!'}
                        </StyledBannerHeading>
                        <h2
                            className={cx('text-3xl mt-4 font-black py-5', {
                                'sm:text-5xl sm:mt-0 px-5 pb-5': !isPdf,
                                'h-32 px-20': isPdf,
                            })}>
                            {isNationalStandard
                                ? `${companyName} is a ${year} ${awardAliasNameWithoutYear} National Standard winner!`
                                : `${companyName} is a ${year} ${awardAliasNameWithoutYear} winner!`}
                        </h2>
                        <div className="flex flex-col justify-center mt-0 sm:mt-0 lg:mt-8 items-center">
                            <div
                                className={cx('justify-center relative', {
                                    'mt-10 lg:mt-10 sm:mt-8 mb-5': !isPdf,
                                    'h-48': isPdf,
                                })}>
                                {awardCategoryId === AWARD_CATEGORY.CULTURE_EXCELLENCE ||
                                awardCategoryId === AWARD_CATEGORY.INDUSTRY ? (
                                    <WinnerAwards
                                        awardCategoryId={awardCategoryId}
                                        year={year}
                                        publisherId={publisherId}
                                        awardsSvg={svgUrl}
                                        isPdf={isPdf}
                                    />
                                ) : (
                                    <SvgLoader
                                        path={individualizedSvgUrl as string}
                                        height={height}
                                        className={
                                            isPdf ? (isDallasPublisher ? 'py-6 px-4 -mb-6' : 'py-6 px-4') : 'py-3 px-4'
                                        }>
                                        <SvgProxy selector="text" font-family={fontFamily} />
                                        <SvgProxy selector="text tspan" font-weight="900">
                                            {`${year}`}
                                        </SvgProxy>
                                    </SvgLoader>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('justify-center', {
                            'hidden sm:flex sm:w-2/5 md:w-1/2 lg:w-3/5': !isPdf,
                            'flex': isPdf,
                        })}>
                        <CongratulationsBanner
                            className="w-full"
                            src={congratulationBannerImg}
                            alt="Congratulation Banner"
                            aria-hidden
                        />
                    </div>
                </div>
            </BgContainer>
        </>
    )
}
