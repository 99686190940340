import { colors } from '@energage/theme'
import styled from 'styled-components'

type PropType = {
    selected: boolean
    onClick: () => void
    imageUrl: string
    ariaControls?: string
    slideNumber: number
}

const StyledButton = styled.button<{ selected: boolean }>`
    padding: 4px;
    box-shadow: ${(props) => (props.selected ? `0 0 0 4px ${colors.white}` : 'none')};

    touch-action: manipulation;
    cursor: pointer;
    margin: 6px;
    height: 60px;
    width: 60px;
    transition: box-shadow 200ms ease-out;
    @media screen and (min-width: 520px) and (max-width: 663px) {
        height: 40px;
        width: 40px;
    }
    @media screen and (min-width: 375px) and (max-width: 520px) {
        height: 32px;
        width: 32px;
    }
    &:hover {
        box-shadow: ${(props) => (props.selected ? `0 0 0 4px ${colors.white}` : `0 0 0 2px rgba(255, 255, 255, 0.5)`)};
    }
    :focus:not(:focus-visible) {
        outline: none;
    }
`

const StyledButtonContent = styled.div<{ imageUrl: string }>`
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid rgba(255, 255, 255, 0.3);
`

export const Thumb: React.FC<PropType> = ({ selected, onClick, imageUrl, ariaControls, slideNumber }) => {
    return (
        <StyledButton
            onClick={onClick}
            type="button"
            aria-pressed={selected}
            aria-controls={ariaControls}
            selected={selected}
            className={`rounded sm:rounded-md md:rounded-md`}
            tabIndex={0}
            aria-label={`Slide ${slideNumber}`}>
            <StyledButtonContent imageUrl={imageUrl} />
        </StyledButton>
    )
}
