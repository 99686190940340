import { useAtom } from 'jotai'
import { questionFamily, questionFamilyArray, sectionFamily } from '../statementSetupUtility/store'

export function useCoreQuestion(questionId) {
    return useAtom(questionFamily({ questionEssenceId: questionId }))
}

export function useCoreQuestions(questionIds) {
    return useAtom(questionFamilyArray({ questionEssenceIds: questionIds }))
}

export function useCoreSection(sectionId) {
    return useAtom(sectionFamily({ id: sectionId }))
}
