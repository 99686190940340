import cx from 'clsx'
import type { WhatsNextSectionRevamp } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { BgContainer } from 'containers/Main/AwardAnnouncement/BgContainer'
import { Timeline } from './Timeline'

export const WhatsNext = ({
    publisherId,
    companyName,
    year,
    timeline,
    colors,
    isPdf,
    publisherName,
    hasPublisherProvidedMediaKit,
}: WhatsNextSectionRevamp) => {
    return (
        <BgContainer backgroundColor={colors.bgColor} textColor={colors.textColor}>
            <div
                className={cx({
                    'px-5 sm:px-20 pt-0 sm:pt-32 sm:pb-20 sm:pb-10 pb-5': !isPdf,
                    'px-20 pt-2 pb-2': isPdf,
                })}>
                <h4
                    className={cx('font-black', {
                        'text-2xl pt-6': isPdf,
                        'text-3xl sm:text-5xl pl-4 pt-10 sm:pl-0 sm:pt-0': !isPdf,
                    })}>
                    {"What's Next?"}
                    <br />
                    {'The countdown to the big celebration.'}
                </h4>
            </div>
            <div className="flex justify-center">
                <Timeline
                    publisherId={publisherId}
                    companyName={companyName}
                    timeline={timeline}
                    isPdf={isPdf}
                    publisherName={publisherName}
                    hasPublisherProvidedMediaKit={hasPublisherProvidedMediaKit}
                />
            </div>
            <div
                className={cx('px-5 md:px-48 pb-10 sm:pb-24 sm:pt-20 text-center w-90', {
                    'py-24': !isPdf,
                    'px-10 py-3': isPdf,
                })}>
                <h5
                    className={cx('pb-5', {
                        'font-black pt-6': isPdf,
                        'sm:font-black text-3xl sm:text-5xl pl-4 sm:pl-0 sm:pt-0': !isPdf,
                    })}>
                    {`Get ready to participate in ${year + 1}`}
                </h5>
                <p>{`${companyName} is already registered in next year's award. You will be notified when it is time to start the process.`}</p>
                <p>{`In the meantime, leverage your data through Insights, Pulse, and Employer Branding to keep your culture in top shape.`}</p>
            </div>
        </BgContainer>
    )
}
