/* eslint-disable @typescript-eslint/no-explicit-any */
/*
  Combo of
   https://github.com/Volune/use-event-callback/blob/master/src/index.ts
   https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
   https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
import { useCallback, useLayoutEffect, useRef } from 'react'

type Fn<Args extends any[], R> = (...args: Args) => R

export function useEvent<A extends any[], R>(fn: Fn<A, R>): Fn<A, R> {
    const handlerRef = useRef<Fn<A, R>>(() => {
        throw new Error('Cannot call an event handler while rendering.')
    })

    useLayoutEffect(() => {
        handlerRef.current = fn
    })

    return useCallback((...args: A): R => {
        const fn = handlerRef.current
        return fn(...args)
    }, [])
}
