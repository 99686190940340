import { QuestionType } from './QuestionType'

export function AdditionalQuestionInformation({ questionTypeId }: { questionTypeId: number }) {
    if (questionTypeId !== QuestionType.YesNo && questionTypeId !== QuestionType.TrueFalse) {
        return null
    }

    const questionType = questionTypeId === QuestionType.YesNo ? 'Yes or No' : 'True or False'

    return <div className="text-grey500">{`${questionType} questions will include an N/A option.`}</div>
}
