/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky'
import merge from 'lodash/merge'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import type { QueryFunctionContext, QueryKey, UseMutationOptions, UseMutationResult } from 'react-query'
import type { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types'
import { updateTwpWorkflow } from 'components/TwpWorkflow'
import chain from 'util/chainFunctions'
import { getBodyContent } from './api'
import type { EnhancedKy } from './api'

export interface ResponseError<ResponseData = unknown> extends ky.HTTPError {
    parsed: ResponseData
}

const normalizeUrl = (url: string) => (url[0] === '/' ? url.slice(1, url.length) : url)

function wrapAsyncFunc<T, TQueryKey extends QueryKey = QueryKey>(
    func: (context: QueryFunctionContext<TQueryKey>, api: EnhancedKy) => Promise<T> | T,
    api: EnhancedKy
) {
    return async function (reactQueryContext: QueryFunctionContext<TQueryKey>) {
        try {
            return await func(reactQueryContext, api)
        } catch (e) {
            if (e instanceof ky.HTTPError) {
                const { response } = e
                if (!response.bodyUsed) {
                    ;(e as ResponseError).parsed = await getBodyContent(response)
                }
            }

            throw e
        }
    }
}

export type QueryFunction<T = unknown, TQueryKey extends QueryKey = QueryKey> = (
    context: QueryFunctionContext<TQueryKey>,
    api: EnhancedKy
) => T | Promise<T>

export type QueryConfig<
    TQueryFnData = unknown,
    TError = ResponseError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>

/**
 * Does not support the object syntax currently but we don't use that at the moment.
 */
export const makeApiUseQuery =
    (api: EnhancedKy) =>
    <TQueryFnData = unknown, TError = ResponseError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
        key: TQueryKey,
        query: string | QueryFunction<TQueryFnData, TQueryKey>,
        config?: QueryConfig<TQueryFnData, TError, TData, TQueryKey>
    ): UseQueryResult<TData, TError> => {
        const queryFn =
            typeof query === 'string'
                ? wrapAsyncFunc<TQueryFnData, TQueryKey>(() => api.fetch(normalizeUrl(query)), api)
                : wrapAsyncFunc<TQueryFnData, TQueryKey>(query, api)
        return useQuery(key, queryFn, config)
    }

export type MutationFunction<TData = unknown, TVariables = unknown> = (
    variables: TVariables,
    api: EnhancedKy
) => Promise<TData>

export const makeApiUseMutation =
    (api: EnhancedKy) =>
    <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
        mutate: MutationFunction<TData, TVariables> | string,
        config?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> & {
            updateTwpWorkflow?: boolean
        }
    ): UseMutationResult<TData, TError, TVariables, TContext> => {
        const queryClient = useQueryClient()

        const newConfig = merge({}, { throwOnError: true }, config)
        const mutateFn =
            typeof mutate === 'string'
                ? wrapAsyncFunc((data) => api.postJson(normalizeUrl(mutate), data), api)
                : // @ts-expect-error :sob:
                  wrapAsyncFunc(mutate, api)

        if (newConfig.updateTwpWorkflow) {
            // @ts-expect-error chain types are wrong
            newConfig.onSuccess = chain(config.onSuccess, () => updateTwpWorkflow(queryClient))
        }

        // @ts-expect-error types are wrong.. fixing will be :sob:
        return useMutation(mutateFn, newConfig) as unknown as UseMutationResult<TData, TError, TVariables, TContext>
    }
