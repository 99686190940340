import type { ReactNode } from 'react'
import { Accordion as AccordionBase, AccordionGroup, useAccordionGroup } from '@energage/components'
import type { AccordionProps as EnergageAccordionProps } from '@energage/components/lib/Accordion/Accordion'
import { ExpandLess, ExpandMore, Icon } from '@energage/icons'
import cx from 'clsx'
import styled from 'styled-components'

const AccordionContainer = styled.div.attrs<{ open?: boolean; className?: string }>(({ open, className }) => ({
    className: cx(className, 'border-b-2', { 'bg-grey100': open }),
}))`
    transition: background-color 500ms ease;
    &:last-of-type {
        border-bottom: 0;
    }
`

function DefaultTrigger({
    open,
    toggle,
    text,
    title,
}: {
    open?: boolean
    toggle: () => void
    text?: string
    title?: ReactNode
}) {
    return (
        <>
            <button
                className={cx('w-full flex justify-between items-center pr-5 focus:outline-none pt-4', !text && 'pb-4')}
                onClick={toggle}>
                <div className="text-left">{title}</div>
                <div className="text-grey600">
                    <Icon className="block" as={open ? ExpandLess : ExpandMore} />
                </div>
            </button>
            {text && <div className="pb-4 pt-2 paragraph">{text}</div>}
        </>
    )
}

type AccordionProps = Omit<EnergageAccordionProps, 'renderTrigger' | 'title'> & {
    testId?: string
    title?: ReactNode
    text?: string
    renderTrigger?: (props: { open: boolean; toggle: () => void; text?: string; title?: ReactNode }) => JSX.Element
}

function Accordion({
    children,
    title,
    text,
    renderTrigger: Trigger = DefaultTrigger,
    testId,
    ...props
}: AccordionProps) {
    return (
        <AccordionBase
            {...props}
            as={AccordionContainer}
            containerProps={({ open }) => ({ open, 'data-testid': testId })}
            renderTrigger={({ open, toggle }) => <Trigger title={title} text={text} open={open} toggle={toggle} />}>
            <div className="pb-4">{children}</div>
        </AccordionBase>
    )
}

export { Accordion, AccordionGroup, useAccordionGroup, AccordionContainer }
