import addDays from 'date-fns/addDays'
import endOfDay from 'date-fns/endOfDay'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import round from 'lodash/round'
import size from 'lodash/size'
import some from 'lodash/some'
import uniq from 'lodash/uniq'
import { addOnsDetails, packageDetails } from 'containers/Main/OrderManagement/Orders/OrderCart/text'
import { dateWithTime } from 'util/formatters'

const MIN_SALESFORCE_OPPORTUNITY_ID_LENGTH = 15
const COMMA_SPACE_REGEX = /,(?!.*,)/gim
const NUMBER_REGEX = /^\d*$/
const FLAT_FEE = 'FlatFee'
const STRING_NUMBER_REGEX = /^[A-Za-z0-9]+$/
const OOPID_LENGTH_INVALID_ERROR_MESSAGE = 'Must be 15 or more characters, only letters and numbers allowed'
const OOPID_SUVREYEVENT_INVALID_ERROR_MESSAGE = 'Salesforce Opportunity cannot be used for this Order.'
const PRICE_INVALID_ERROR_MESSAGE = 'Must be greater than $0'
const PRICE_MAX_ERROR_MESSAGE = 'Must be < $10,000,000'
const TWO_YEAR_DISCOUNT_ERROR_MESSAGE = 'Discount cannot exceed 5%'
const THREE_YEAR_DISCOUNT_ERROR_MESSAGE = 'Discount cannot exceed 10%'
const DISCOUNT_FOR_TWO_YEAR = 5
const DISCOUNT_FOR_THREE_YEAR = 10
const DISCOUNT_LENGTH = 4
const DISCOUNT_INVALID_ERROR_MESSAGE = 'Only two decimal places are allowed'
const DISCOUNT_ERROR_MESSAGE = 'Discount percentage should be between 0 to '
const EXPIRATION_DATE_ERROR_MESSAGE = 'Orders cannot have an expiration date in the past.'
const MAX_PURCHASE_ORDER_NUMBER_LENGTH = 20

const pricingTypes = {
    OneTime: 1,
    Recurring: 2,
}

export const REQUIRED_MESSAGE = 'This is required field'

export const PURCHASE_ORDER_NUMBER_REG_EXP = /^[A-Za-z0-9-.#]+?([A-Za-z0-9][A-Za-z0-9-.#]+?)*[A-Za-z0-9]*$/
export const purchaseOrderNumValidationMsg = {
    required: REQUIRED_MESSAGE,
    maxLength: 'Purchase Order Number should contain maximum 20 chaacters',
    format: 'Only letters, numbers, #, - and . are allowed',
}

export const SALESFORCE_ACCOUNT_URL = 'https://energage.my.salesforce.com/'

export const MAXIMUM_LENGTH = 7
export const MAXIMUM_QUANTITY_LENGTH = 5

export const MINIMUM_QUANTITY_FOR_EMPLOYEE_UOM = 35

export const CLOSED_LOST_OPPORTUNITY_STAGE = 'Closed Lost'
export const ENERGAGE_SURVEY_PRODUCT = 'Energage Survey'

export const nonBundleBaseProductList = ['Energage Survey', 'Annual Top Workplaces Subscription', 'Premier Support']

export const hasLessThanRequiredQuantity = (uom, quantity) =>
    uom === unitTypes.Employees.label && quantity < MINIMUM_QUANTITY_FOR_EMPLOYEE_UOM

export const pairedPacakages = {
    branding: [29, 44],
    insights: [30, 40],
    pulse: [35, 45],
    brandingInsights: [31, 41],
    insightsPulse: [36, 42],
    pulseBranding: [37, 46],
    brandingInsightsPulse: [38, 43],
}

export const PREMIER_SUPPORT_PRODUCT_CATEGORY = 'premierSupport'
export const PREMIER_SUPPORT_PRODUCT_NAME = 'Premier Support'
export const PREMIER_SUPPORT_STANDARD = 'Annual Premier Support - Standard Subscription'
export const PREMIER_SUPPORT_PRO = 'Annual Premier Support - Pro Subscription'
export const PREMIER_SUPPORT_PRICE_PERCENT = 25

export const VISIBLE_CATEGORY_LIST = {
    premierSupport: 'Premier Support',
    Service: 'Service Add-Ons',
    Customization: 'Product Customization Add-Ons',
}

export const VISIBLE_CATEGORY_LIST_KEYS = keys(VISIBLE_CATEGORY_LIST)

export const PACKAGE_CATEGORY_LIST = {
    baseProducts: 'Base Products',
    bundles: 'Bundles',
}

export const PACKAGE_CATEGORY_LIST_KEYS = keys(PACKAGE_CATEGORY_LIST)

export const netCreditTerms = [
    {
        label: 'Net 30',
        value: 1,
    },
    {
        label: 'Net 60',
        value: 2,
    },
]

export const renewalOptionTypes = {
    None: {
        label: 'None',
        value: 1,
    },
    Included: {
        label: 'Included',
        value: 2,
    },

    AddToSubscription: {
        label: 'Add to Subscription',
        value: 3,
    },
    RemoveFromSubscription: {
        label: 'Remove from Subscription',
        value: 4,
    },
}

export const unitTypes = {
    Employees: {
        label: 'Employees',
        value: 1,
    },
    Each: {
        label: 'Each',
        value: 2,
    },
    FlatFee: {
        label: 'Flat Fee',
        value: 3,
    },
}

export const amendmentOrderStatus = {
    Created: {
        label: 'Created',
        value: 1,
    },
    Published: {
        label: 'Published',
        value: 2,
    },
    Confirmed: {
        label: 'Confirmed',
        value: 3,
    },
    Expired: {
        label: 'Expired',
        value: 4,
    },
    Unpublished: {
        label: 'Unpublished',
        value: 5,
    },
    Synced: {
        label: 'Synced',
        value: 6,
    },
    Failed: {
        label: 'Failed',
        value: 7,
    },
    AwaitingReprocess: {
        label: 'AwaitingReprocess',
        value: 8,
    },
}

export const subscriptionStatus = {
    Draft: 1,
    PendingActivation: 2,
    PendingAcceptance: 3,
    Active: 4,
    Cancelled: 5,
    Expired: 6,
    Suspended: 7,
    Deleted: 8,
}

export const amendmentOrderType = {
    Renewal: 1,
    Instant: 2,
}

export const getPaymentTerm = (key) => find(netCreditTerms, (x) => x.value === key || x.label === key)

export const validateOpportunityId = (id, availableOpportunities) => {
    if (!id) {
        return REQUIRED_MESSAGE
    }
    if (size(id) < MIN_SALESFORCE_OPPORTUNITY_ID_LENGTH || !id.match(STRING_NUMBER_REGEX)) {
        return OOPID_LENGTH_INVALID_ERROR_MESSAGE
    }
    if (availableOpportunities && !some(availableOpportunities, { salesforceOpportunityId: id })) {
        return OOPID_SUVREYEVENT_INVALID_ERROR_MESSAGE
    }
    return ''
}

export const validatePurchaseOrderNumber = (purchaseOrderNumber, isCheckboxSelected) => {
    if (isCheckboxSelected && !purchaseOrderNumber) {
        return purchaseOrderNumValidationMsg.required
    }

    if (isCheckboxSelected && purchaseOrderNumber) {
        if (size(purchaseOrderNumber) > MAX_PURCHASE_ORDER_NUMBER_LENGTH) {
            return purchaseOrderNumValidationMsg.maxLength
        }

        if (!PURCHASE_ORDER_NUMBER_REG_EXP.test(purchaseOrderNumber)) {
            return purchaseOrderNumValidationMsg.format
        }
    }
    return ''
}

export const isOneTimeChargedProduct = (ratePlanCharges) =>
    !!find(ratePlanCharges, (x) => x.type.id === pricingTypes.OneTime)

export const getProductsByCategory = (products) => groupBy(products, (productItem) => productItem.category)

export const getDefaultExpirationDate = () => dateWithTime(endOfDay(addDays(new Date(), 7)))

export const isUnPublished = (status) => status === amendmentOrderStatus.Unpublished.value

export const isSaved = (status) => status === amendmentOrderStatus.Created.value

export const isPublished = (status) => {
    return includes(
        [
            amendmentOrderStatus.Published.value,
            amendmentOrderStatus.Expired.value,
            amendmentOrderStatus.Confirmed.value,
            amendmentOrderStatus.Synced.value,
            amendmentOrderStatus.Failed.value,
            amendmentOrderStatus.AwaitingReprocess.value,
        ],
        status
    )
}

export const isConfirmed = (status) => {
    return includes(
        [
            amendmentOrderStatus.Confirmed.value,
            amendmentOrderStatus.Synced.value,
            amendmentOrderStatus.Failed.value,
            amendmentOrderStatus.AwaitingReprocess.value,
        ],
        status
    )
}

export const isAmendmentFailed = (amendmentStatus) => amendmentStatus === amendmentOrderStatus.Failed.value

export const hasBestValue = (pkg) => size(pkg.products) > 1

export const getDiscountedPrice = (price, discount) => round(discount ? price - price * discount * 0.01 : price)

export const addDiscountedPrice = (price, discount) => round(discount ? (price * 100) / (100 - discount) : price)

export const getProductUOM = (props) => {
    if (!props) {
        return
    }
    const productRatePlans = head(props)
    const orderedProductRatePlans = orderBy(productRatePlans.productRatePlanCharges, 'IsDefault__c', ['desc'])
    const uomLists = uniq(map(orderedProductRatePlans, (x) => x.uom || FLAT_FEE))
    return uomLists.join(', ').replace(COMMA_SPACE_REGEX, ', and')
}

export const filterProductBy = (products, key) => filter(products, (x) => x[key])
export const getSelected = (products) => filterProductBy(products, 'selected') // TODO: Integrate with GetCart API
export const getSelectedCategory = (subscriptionCategories) => head(getSelected(subscriptionCategories)) || {}

export const normalize = (array, key) => {
    return reduce(
        array,
        (acc, curr) => ({
            ...acc,
            [curr[key] || curr.id]: curr,
        }),
        {}
    )
}

export const ensureValidNumber = (func) => (e) => {
    const { value, name } = e.target
    const maxLength = name === 'updatedQuantity' ? MAXIMUM_QUANTITY_LENGTH : MAXIMUM_LENGTH
    const exceedsMaxLength = size(value) > maxLength
    const isValid = isEmpty(value) ? true : NUMBER_REGEX.test(value)

    if (exceedsMaxLength || !isValid) {
        return
    }

    func(e)
}

export const getDefaultProductVisibility = (product) => packageDetails[product.id].defaultVisibility

export const getDefaultAddOnVisibility = (addOn) => {
    const addOnList = find(addOnsDetails, (x) => includes(x.ids, addOn.id))
    return addOnList?.defaultVisibility
}

export const getPercentageOfNumber = (percentage, num) => (percentage > 0 ? (percentage / 100) * num : 0)

export const getPackagesByCategory = (packages) => {
    const updatedPackages = filter(packages, (pkg) => !pkg.isSmallBusiness)
    const packagesByCategories = groupBy(updatedPackages, (pkg) =>
        pkg.products.length > 1 ? PACKAGE_CATEGORY_LIST_KEYS[1] : PACKAGE_CATEGORY_LIST_KEYS[0]
    )
    return mapValues(packagesByCategories, (value, key) => ({
        products: orderBy(normalize(value), (product) => product.name, ['asc']),
        title: PACKAGE_CATEGORY_LIST[key],
    }))
}

export const getPairedPackage = (packageId) =>
    flatten(filter(pairedPacakages, (pair) => includes(pair, parseInt(packageId))))

export const isPriceValid = (price) => {
    if (parseInt(price) === 0 || !price) {
        return PRICE_INVALID_ERROR_MESSAGE
    }
    if (parseInt(price) > 9999999) {
        return PRICE_MAX_ERROR_MESSAGE
    }
    return ''
}
const validateDiscount = (discount, maxDiscount, message) => {
    if (!!discount) {
        if (discount < 0) {
            return DISCOUNT_ERROR_MESSAGE + maxDiscount
        }
        if (discount > maxDiscount) {
            return message
        }
        if (discount.toString().length > DISCOUNT_LENGTH) {
            return DISCOUNT_INVALID_ERROR_MESSAGE
        }
    }
    return ''
}

export const validateTwoYearDiscount = (discount) =>
    validateDiscount(discount, DISCOUNT_FOR_TWO_YEAR, TWO_YEAR_DISCOUNT_ERROR_MESSAGE)

export const validateThreeYearDiscount = (discount) =>
    validateDiscount(discount, DISCOUNT_FOR_THREE_YEAR, THREE_YEAR_DISCOUNT_ERROR_MESSAGE)

export const checkIsProductAddedOrIncluded = (opt) =>
    opt === renewalOptionTypes.AddToSubscription.value || opt === renewalOptionTypes.Included.value

export const validateExpirationDate = (date) =>
    new Date(date).getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime() ? EXPIRATION_DATE_ERROR_MESSAGE : ''
