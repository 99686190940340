import cx from 'clsx'
import styled from 'styled-components'

const Marker = styled.span.attrs({ className: 'absolute bg-magenta500 text-white top-0 px-1' })`
    right: -3px;
    font-size: 9px;
`

const Bubble = ({ count, show = count > 0, children }) => {
    const markerClassNames = cx({
        'rounded': count > 0,
        'rounded-full p-1': !count,
    })

    return (
        <div className="relative cursor-pointer">
            {children}
            {show && <Marker className={markerClassNames}>{count || ''}</Marker>}
        </div>
    )
}

export default Bubble
