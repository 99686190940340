import { ArrowBack } from '@energage/icons'
import { Link, useHistory } from 'react-router-dom'
import { TopNav } from 'components/TopNav'

const TopNavWithBack = ({ title, children }) => {
    const history = useHistory()
    return (
        <TopNav
            xsHidden
            title={
                <>
                    {history && (
                        <Link css={{ lineHeight: 0 }} className="mr-4" onClick={() => history.goBack()}>
                            <ArrowBack />
                        </Link>
                    )}
                    {title}
                </>
            }>
            {children}
        </TopNav>
    )
}

export default TopNavWithBack
