import { memo, useEffect } from 'react'
import type { ReactNode } from 'react'
import { Input } from '@energage/components'
import { useToggle } from '@energage/hooks'
import { colors, width } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import { FocusCheckbox, VisuallyHidden } from 'components'
import { Tooltip } from 'components/Tooltip'
import { TwpUsaNotSurveyingAllMessage } from '../../Recipients/SurveyScope'
import type {
    AwardSelection,
    OverlappingGroup,
    SelectAllCheckboxProps,
    SelectRowCellProps,
} from '../awardselection.types'
import { AwardSelectionTableDesktop } from './AwardSelectionTableDesktop'
import { EditEmployeeCountInput, EditNationalEmployeeCountInput } from './EditEmployeeCountInput'

const ToggleAllAwards = <VisuallyHidden>{'Toggle all awards'}</VisuallyHidden>

export function SelectAllCheckbox({ onSelectAll }: SelectAllCheckboxProps) {
    const awards = useWatch({ name: 'awards' }) as AwardSelection[]
    const nonReadOnlyAwards = filter(awards, { isFinalized: false })
    const selectedCount = filter(nonReadOnlyAwards, { isSelected: true }).length
    const allSelected = selectedCount === nonReadOnlyAwards?.length
    const indeterminate = selectedCount !== 0 && !allSelected

    return (
        <FocusCheckbox
            inline
            disabled={nonReadOnlyAwards.length === 0}
            label={ToggleAllAwards}
            indeterminate={indeterminate}
            checked={allSelected}
            onChange={(event) => onSelectAll(event.target.checked)}
        />
    )
}

export function SelectRowCheckbox({ index, isReadOnly, canSelectNational }: SelectRowCellProps) {
    const { clearErrors, control, trigger, setValue } = useFormContext()

    const awards = useWatch({ name: 'awards', control }) as AwardSelection[]
    const controlName = `awards.${index}.isSelected`
    const value = awards[index].isSelected

    useEffect(() => {
        // re-trigger the employee count validation when this gets selected
        if (value) {
            trigger(`awards.${index}.employeeCount`)
        }
    }, [value, trigger, index])

    const disableNationalSelection = !value && awards[index].isNational && !canSelectNational
    const disableSelection = isReadOnly || disableNationalSelection

    return (
        <Controller
            control={control}
            name={controlName}
            render={({ field }) => {
                const { value: ignore, onChange, ...delegatedProps } = field

                return (
                    <Tooltip
                        tooltipColor={colors.grey600}
                        textColor={colors.white}
                        placement="bottom"
                        trigger={
                            <FocusCheckbox
                                inline
                                disabled={disableSelection}
                                checked={value}
                                {...delegatedProps}
                                onChange={(event) => {
                                    const checked = event.target.checked

                                    if (disableSelection) {
                                        return
                                    }

                                    onChange(event)

                                    if (!checked) {
                                        clearErrors(`awards.${index}.employeeCount`)
                                        setValue('isEmployeeCountConfirmed', false)
                                    }
                                }}
                            />
                        }
                        text={TwpUsaNotSurveyingAllMessage}
                        disabled={!disableNationalSelection}
                    />
                )
            }}
        />
    )
}

export const StyledEmployeeCountInput = styled(Input)`
    input {
        min-width: ${width(24)};
        max-width: ${width(24)};
    }
`

function LocationsCell({ locations }: { locations: string[] }) {
    const [show, toggle] = useToggle(false)

    if (!locations || locations.length === 0) {
        return <>{'No company locations uploaded'}</>
    }

    if (locations.length === 1) {
        return <>{locations[0]}</>
    }

    const maxInitiallyShownLocations = 6
    const useShowMore = locations.length - maxInitiallyShownLocations > 1

    if (!useShowMore) {
        return <>{locations.join(', ')}</>
    }

    const initialLocations = locations.slice(0, maxInitiallyShownLocations)
    const remainingLocations = locations.slice(maxInitiallyShownLocations)
    return (
        <>
            <span>
                {initialLocations.join(', ')}
                {', '}
            </span>
            {useShowMore ? (
                <>
                    {show ? (
                        <span>
                            {remainingLocations.join(', ')}
                            {'\u00a0'}
                            <button type="button" onClick={toggle} className="font-bold text-blue500">
                                {'show less'}
                            </button>
                        </span>
                    ) : (
                        <button
                            type="button"
                            onClick={toggle}
                            className="font-bold text-blue500">{`plus ${remainingLocations.length} more`}</button>
                    )}
                </>
            ) : null}
        </>
    )
}

const MemoedLocationsCell = memo(LocationsCell)
export { MemoedLocationsCell as LocationsCell }

export const Label = memo(({ children, className }: { className?: string; children: ReactNode }) => (
    <div className={cx(className, 'paragraph-small font-bold')}>{children}</div>
))

export const Description = memo(({ children }: { children: ReactNode }) => (
    <span className="paragraph-small">{children}</span>
))

export function AwardLayout({
    award,
    index,
    overlappingGroups,
}: {
    index: number
    award: AwardSelection
    overlappingGroups: OverlappingGroup[]
}) {
    return (
        <>
            <label htmlFor={`awards.${index}.isSelected`}>
                <AwardImage alt="" src={award.publisherLogoUrl} />
            </label>
            <Label className="mt-2">{'Awards'}</Label>
            <Description>{award.year}</Description>
            <Label className="mt-2">{'Locations in award region'}</Label>
            <Description>
                <LocationsCell locations={award.locations} />
            </Description>
            <Label className="mt-2">{'Employees in award region'}</Label>
            <Description>
                {award.isNational ? (
                    <EditNationalEmployeeCountInput
                        index={index}
                        readOnly={award.isFinalized}
                        overlappingGroups={overlappingGroups}
                        awardName={award.title}
                    />
                ) : (
                    <EditEmployeeCountInput
                        readOnly={award.isFinalized}
                        index={index}
                        minimumEmployeeCount={award.minimumEmployeeCount}
                    />
                )}
            </Description>
        </>
    )
}

export const AwardImage = styled.img.attrs({ alt: '' })`
    max-width: ${width(32)};
`

export const MaxHeightAwardTable = styled(AwardSelectionTableDesktop)`
    max-height: 500px;
`
