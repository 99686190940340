import { useContext } from 'react'
import { LegacyButton as Button, Loading as Spinner } from '@energage/components'
import find from 'lodash/find'
import { parse } from 'query-string'
import { Link, withRouter } from 'react-router-dom'
import { AccordionGroup } from 'components/Accordion'
import Container from 'components/Container'
import Inset from 'components/Inset'
import HeaderTeleporter from '../HeaderTeleporter'
import PageTitle from '../PageTitle'
import SurveySetupContext from '../SurveySetupContext'
import { SetupTask } from '../workflow'
import HeadsUpEmail from './HeadsUpEmail'
import ScheduleLaunch from './ScheduleLaunch'
import TestDelivery from './TestDelivery'

const scheduleTasks = [
    { taskId: SetupTask.ScheduleInvitations, accordionId: 'schedule' },
    { taskId: SetupTask.TestDelivery, accordionId: 'test' },
    { taskId: SetupTask.SendHeadsUp, accordionId: 'heads-up' },
]

const NextPageButton = ({ surveyEventId }) => {
    const { getNextPage, getStatus } = useContext(SurveySetupContext)
    const pageIsComplete = getStatus(SetupTask.ScheduleInvitations)?.status === 'complete'

    return (
        <Button as={Link} to={getNextPage().route({ surveyEventId })} disabled={!pageIsComplete}>
            {'Review & Approve Launch'}
        </Button>
    )
}

const Header = ({ surveyEventId }) => {
    return (
        <PageTitle border title="Get ready to launch your survey">
            <NextPageButton surveyEventId={surveyEventId} />
        </PageTitle>
    )
}

const ScheduleSurvey = ({ match }) => {
    const { surveyEventId } = match.params
    const { isLoading, surveyHasLaunched, getStatus, refreshWorkflow, surveyTypeId } = useContext(SurveySetupContext)
    const parsedQueryString = parse(window.location.search)
    const selectedTask =
        (parsedQueryString.section
            ? find(scheduleTasks, (t) => t.accordionId === parsedQueryString.section)
            : find(scheduleTasks, (t) => getStatus(t.taskId)?.status !== 'complete')) ?? scheduleTasks[0]

    const handleAccordionChange = () => {
        refreshWorkflow()
    }

    return (
        <>
            <HeaderTeleporter.Source>
                <Header surveyEventId={surveyEventId} />
            </HeaderTeleporter.Source>
            <Container>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Inset padding>
                        <AccordionGroup defaultActiveId={selectedTask.accordionId} onChange={handleAccordionChange}>
                            <ScheduleLaunch
                                id="schedule"
                                surveyHasLaunched={surveyHasLaunched}
                                isComplete={getStatus(SetupTask.ScheduleInvitations)?.status === 'complete'}
                            />
                            <TestDelivery
                                id="test"
                                readOnly={surveyHasLaunched}
                                isComplete={getStatus(SetupTask.TestDelivery)?.status === 'complete'}
                            />
                            <HeadsUpEmail
                                id="heads-up"
                                surveyTypeId={surveyTypeId}
                                readOnly={surveyHasLaunched}
                                isComplete={getStatus(SetupTask.SendHeadsUp)?.status === 'complete'}
                            />
                        </AccordionGroup>
                        <div className="sm:hidden text-center h-10 mt-8">
                            <NextPageButton surveyEventId={surveyEventId} />
                        </div>
                    </Inset>
                )}
            </Container>
        </>
    )
}

export default withRouter(ScheduleSurvey)
