import cx from 'clsx'
import type { AwardPoints } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'

export const Point = ({
    sideImg,
    numberIcon,
    heading,
    content,
    isPdf,
    bgColor,
    pdfSpace,
    imgXPadding,
}: AwardPoints) => {
    return (
        <div
            className={cx('bg-white', {
                'mb-6': bgColor && !isPdf,
                'flex py-2': isPdf,
                'sm:flex block': !isPdf,
                'flex-col': bgColor && isPdf,
                'flex-col-reverse': !bgColor && isPdf,
                'bg-yellow100': bgColor,
            })}>
            {bgColor ? (
                <>
                    <div
                        className={cx('flex', {
                            'w-full sm:w-3/5': !isPdf,
                            'justify-center': isPdf,
                            'py-2': pdfSpace && isPdf,
                        })}>
                        <div
                            className={cx('flex self-center', {
                                'sm:pl-10 pr-5 md:pb-5 sm:pb-10 pb-12 md:pt-15 sm:pt-20 pt-12': !isPdf,
                                'mt-2 ml-8': isPdf,
                            })}>
                            <div className="px-3">
                                <img src={numberIcon} alt="Announcement point banner one" aria-hidden />
                            </div>
                            <div className={cx({ 'w-3/4': !isPdf, 'w-4/5': isPdf })}>
                                <h4 className={cx({ 'text-2xl': isPdf, 'text-4xl': !isPdf })}>{heading}</h4>
                                <p className={cx('pt-2 font-normal', { 'text-xl': !isPdf, 'text-base': isPdf })}>
                                    {content}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx(' flex', {
                            'sm:-mb-16 w-full sm:w-2/5 sm:-mb-24 mb-0 pb-10 pt-0 sm:pt-12 items-end px-20 sm:px-0':
                                !isPdf,
                            'pt-4 justify-center': isPdf,
                        })}>
                        <img
                            className={cx({ 'w-full': !isPdf, 'w-2/5': isPdf })}
                            src={sideImg}
                            alt="Announcement point banner one"
                            aria-hidden
                        />
                    </div>
                </>
            ) : (
                <div className={cx('flex flex-col-reverse ', { 'sm:flex-row': !isPdf })}>
                    <div
                        className={cx('flex', {
                            'sm:w-2/5 w-full sm:-mb-16': !isPdf,
                            'justify-center ': isPdf,
                            'pb-2': pdfSpace && isPdf,
                            'px-10': imgXPadding && !isPdf,
                        })}>
                        <img
                            className={cx('object-cover', { 'w-full': !isPdf, 'w-2/5': isPdf })}
                            src={sideImg}
                            alt="Announcement point banner one"
                            aria-hidden
                        />
                    </div>
                    <div
                        className={cx('flex', {
                            'sm:w-3/5 w-full my-20 sm:my-0 sm:pt-16 pt-0 sm:pb-16 pb-0 self-center': !isPdf,
                            'ml-8': isPdf,
                            'py-10': pdfSpace && isPdf,
                        })}>
                        <div className={cx({ 'pl-0 sm:pl-10': !isPdf })}>
                            <img src={numberIcon} className="px-3" alt="Announcement point banner one" aria-hidden />
                        </div>
                        <div className={cx({ 'w-3/4': !isPdf, 'w-4/5': isPdf })}>
                            <h4 className={cx('mb-2', { 'text-2xl': isPdf, 'sm:text-3xl': !isPdf })}>{heading}</h4>
                            <p className={cx('font-normal', { 'text-xl': !isPdf, 'text-base': isPdf })}>{content}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
