import { createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SidebarXS as SidebarRoot, SubMenu as SubMenuRoot } from '@energage/components'
import cx from 'clsx'
import map from 'lodash/map'
import { useHistory, withRouter } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import { useNavigation } from 'components/Navigation'
import { useButterBarHeight } from 'hooks'
import chain from 'util/chainFunctions'
import NavigationLink from '../NavigationLink'

const AnimatedSubMenu = styled.div.attrs({ className: 'absolute inset-0 bg-purple700' })`
    transition: opacity 500ms, transform 500ms;
    opacity: ${({ state }) => (state === 'entering' || state === 'entered' ? 1 : 0)};
    transform: ${({ state }) =>
        state === 'entering' || state === 'entered' ? 'translate(0, 0)' : 'translate(100%, 0)'};
    pointer-events: ${({ state }) => (state === 'entered' || state === 'entering' ? 'auto' : 'none')};
`

const SubMenu = ({ state, title, items, onClose, onToggle }) => (
    <AnimatedSubMenu state={state}>
        <SubMenuRoot title={title} onClose={onClose}>
            {({ linkClassName, activeLinkClassName }) =>
                map(items, (item) => {
                    const additionalProps = item.appliesAdditionalProps ? item.props : {}
                    return (
                        <NavigationLink
                            key={item.to}
                            className={cx(linkClassName, 'px-6 py-4')}
                            activeClassName={activeLinkClassName}
                            to={item.to}
                            onClick={chain(item.appliesAdditionalProps && item.onClick, onToggle)}
                            label={item.label}
                            {...additionalProps}
                        />
                    )
                })
            }
        </SubMenuRoot>
    </AnimatedSubMenu>
)

function usePrevious(value) {
    const savedValue = useRef()
    useEffect(() => {
        savedValue.current = value
    }, [value])
    return savedValue.current
}

function useTimeout(callback, delay) {
    const savedCallback = useRef()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        function tick() {
            savedCallback.current()
        }

        if (delay !== null) {
            let id = setTimeout(tick, delay)
            return () => clearTimeout(id)
        }
    }, [delay])
}

const Context = createContext()

const Float = styled.div`
    .fixed-header {
        top: ${(props) => props.top}px;
    }
`

const SidebarXS = ({ open, children, location, onToggle, ...props }) => {
    const history = useHistory()
    const top = useButterBarHeight()
    const [showSubMenu, setShowSubMenu] = useState(false)
    const { title, items } = useNavigation(location.pathname)
    const wasOpen = usePrevious(open)
    const { pathname } = location

    useEffect(() => {
        setShowSubMenu(open && wasOpen && !!items)
    }, [open, wasOpen, items])

    useEffect(() => {
        if (!open) {
            return
        }
        return () => {
            setShowSubMenu(false)
        }
    }, [open, pathname, history])

    const shouldClose = open && wasOpen && !items
    useTimeout(onToggle, shouldClose ? 50 : null)

    useLayoutEffect(() => {
        if (open) {
            document.body.classList.add('overflow-hidden')
        }

        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    }, [open])

    const handleLinkClick = useCallback(
        (path) => {
            if (path === location.pathname && !!items) {
                setShowSubMenu(true)
            }
        },
        [location.pathname, items]
    )

    return (
        <Float top={top}>
            <SidebarRoot {...props} title={title} open={open} onToggle={onToggle}>
                <Context.Provider value={handleLinkClick}>{children}</Context.Provider>
                <Transition in={showSubMenu} timeout={500}>
                    {(state) => (
                        <SubMenu
                            state={state}
                            items={items}
                            title={title}
                            onClose={() => setShowSubMenu(false)}
                            onToggle={onToggle}
                        />
                    )}
                </Transition>
            </SidebarRoot>
        </Float>
    )
}

SidebarXS.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
}

export default withRouter(SidebarXS)

export const SubMenuContextConsumer = Context.Consumer
