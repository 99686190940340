import {
    authentication,
    branding,
    documentManagement,
    insights,
    management,
    ordering,
    organization,
    reports,
    workplaceSurvey,
    xenon,
} from './api'
import type { EnhancedKy } from './api'
import { makeApiUseMutation, makeApiUseQuery } from './makeApiHooks'

function makeApi(instance: EnhancedKy) {
    const query = makeApiUseQuery(instance) as ReturnType<typeof makeApiUseQuery> & {
        mutate: ReturnType<typeof makeApiUseMutation>
    }
    query.mutate = makeApiUseMutation(instance)
    return query
}

const useWorkplaceSurveyQuery = makeApi(workplaceSurvey)
const useXenonQuery = makeApi(xenon)
const useInsightsQuery = makeApi(insights)
const useBrandingQuery = makeApi(branding)
const useManagementQuery = makeApi(management)
const useOrganizationQuery = makeApi(organization)
const useReportsQuery = makeApi(reports)
const useOrderingQuery = makeApi(ordering)
const useAuthenticationQuery = makeApi(authentication)
const useDocumentManagementQuery = makeApi(documentManagement)

export {
    useAuthenticationQuery,
    useWorkplaceSurveyQuery,
    useXenonQuery,
    useInsightsQuery,
    useBrandingQuery,
    useManagementQuery,
    useOrderingQuery,
    useOrganizationQuery,
    useReportsQuery,
    useDocumentManagementQuery,
}
