import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import config from 'config'
import { useEmployeeData } from 'containers/Main/Manage/Employees/EmployeeDataProvider'
import { useLazyFetch } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import { useSurveySetupContext } from '../SurveySetup'

const RecipientsContext = createContext({ recipients: [] })

function useFetchRecipientsData(surveyEventId) {
    const { surveyEventSetup } = useSurveySetupContext()
    const [recipients, setRecipients] = useState(null)

    const { isLoading: employeeDataIsLoading, error: employeeDataError, organizationId } = useEmployeeData()

    const onFetchRecipients = useCallback((data) => {
        setRecipients({
            members: data?.memberAssignments ?? [],
            tagOptionAssignments: data?.tagOptionAssignments ?? [],
        })
    }, [])

    const {
        isLoading: fetchRecipientsIsLoading,
        error: fetchRecipientsError,
        doFetch: fetchRecipients,
    } = useLazyFetch({
        url: `${config.api.platform}/SurveyEventSetup/${surveyEventSetup?.id}/Assignments`,
        onComplete: onFetchRecipients,
    })

    useEffect(() => {
        if (!organizationId || !surveyEventId || !surveyEventSetup || employeeDataIsLoading) {
            return
        }
        fetchRecipients()
    }, [organizationId, surveyEventId, fetchRecipients, employeeDataIsLoading, surveyEventSetup])

    useEffect(() => {
        if (fetchRecipientsError) {
            showErrorAlert('There was an error fetching recipients', fetchRecipientsError)
        }
    }, [fetchRecipientsError])

    return useMemo(
        () => ({
            recipients,
            surveyEventSetup,
            isLoading: employeeDataIsLoading || fetchRecipientsIsLoading || !recipients,
            error: employeeDataError || fetchRecipientsError,
        }),
        [
            employeeDataError,
            employeeDataIsLoading,
            fetchRecipientsError,
            fetchRecipientsIsLoading,
            recipients,
            surveyEventSetup,
        ]
    )
}

export function useRecipientsData() {
    return useContext(RecipientsContext)
}

const RecipientsDataProvider = ({ match, children }) => {
    const surveyEventId = match.params.surveyEventId
    const data = useFetchRecipientsData(surveyEventId)

    return <RecipientsContext.Provider value={data}>{children}</RecipientsContext.Provider>
}

RecipientsDataProvider.propTypes = {
    children: PropTypes.node,
}

export default withRouter(RecipientsDataProvider)
