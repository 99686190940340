import groupBy from 'lodash/groupBy'
import type { ClassicWinnerAwardPdf } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import colors from 'containers/Main/AwardAnnouncement/colors'
import { COMPANY_PROFILE_URL, FAQ_URL, MERCHANDISE_URL } from 'containers/Main/AwardAnnouncement/constants'
import { Achievement } from 'containers/Main/AwardAnnouncement/Winners/Achievement'
import { Congratulations } from 'containers/Main/AwardAnnouncement/Winners/Congratulations'
import { PromoteYourAward } from 'containers/Main/AwardAnnouncement/Winners/PromoteYourAward'
import { WhatsNext } from 'containers/Main/AwardAnnouncement/Winners/WhatsNext'
import { PageBreaker } from './AwardAnnouncementPdf'
import { FAQs } from './FAQs'

export const ClassicWinnerPdf = ({ award, awardListParticipantId, baseUrl }: ClassicWinnerAwardPdf) => {
    const {
        awardCategoryId,
        companyName,
        year,
        publisherId,
        denyTWPMerch,
        publisherName,
        listName,
        isApproxPublishDate,
        publicationDate,
        pressReleaseDate,
        eventDate,
        hasEvent,
        hasAlphabeticalWinnersList,
        alphabeticalWinnersListDate,
        nationalAwardsSvg,
        publicWebsiteCode,
        awardName,
    } = award

    const mediaKitExternalLinkParameters = `?id=${awardListParticipantId}&year=${year}&isCEAward=false&isIndustryAward=false`
    const awards = Object.entries(awardName)
    const { true: named = [], false: notnamed = [] } = groupBy(awards, ([, value]) => value)
    const winners = named.map(([key]) => key)
    const aspirant = notnamed.map(([key]) => key)

    return (
        <>
            <Congratulations
                awardCategoryId={awardCategoryId}
                companyName={companyName}
                year={year}
                publisherId={publisherId}
                awardsSvg={nationalAwardsSvg}
                winnerAwards={winners}
                aspirantAwards={aspirant}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
                isPdf
                isOldAwardAnnouncement={true}
            />
            <PageBreaker />
            <PromoteYourAward
                isPdf
                baseUrl={baseUrl}
                colors={{
                    bgColor: colors.bgBlue,
                    textColor: colors.textBlue,
                }}
            />
            <PageBreaker />
            <WhatsNext
                publisherId={publisherId}
                awardsSvg={nationalAwardsSvg}
                isBlackListedFromTwpMerch={denyTWPMerch}
                companyName={companyName}
                publisherName={publisherName}
                year={year}
                listName={listName}
                mediaKitExternalLinkParameters={mediaKitExternalLinkParameters}
                timeline={{
                    isApproxPublishDate: isApproxPublishDate,
                    publicationDate: publicationDate,
                    pressReleaseDate: pressReleaseDate,
                    eventDate: eventDate,
                    hasEvent: hasEvent,
                    hasAlphabeticalWinnersList: hasAlphabeticalWinnersList,
                    alphabeticalWinnersListDate: alphabeticalWinnersListDate,
                }}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
                baseUrl={baseUrl}
                isPdf
                awardCategoryId={awardCategoryId}
            />
            <PageBreaker />
            {!denyTWPMerch && (
                <>
                    <Achievement
                        shopMerchandiseUrl={MERCHANDISE_URL}
                        colors={{
                            bgColor: colors.bgBlue,
                            textColor: colors.textWhite,
                        }}
                        year={year}
                        isPdf
                    />
                    <PageBreaker />
                </>
            )}
            <FAQs
                isWinner={true}
                companyProfileUrl={`${COMPANY_PROFILE_URL}${publicWebsiteCode}`}
                faqUrl={FAQ_URL}
                companyName={companyName}
                year={year}
                colors={{
                    bgColor: colors.bgLightGrey,
                    textColor: colors.textBlue,
                }}
            />
        </>
    )
}
