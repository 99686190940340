import { addDays } from 'date-fns'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import sub from 'date-fns/sub'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import slice from 'lodash/slice'
import some from 'lodash/some'

const ErrorType = {
    NO_DATE: 1,
    DATE_EXISTS: 2,
    AFTER_MAX_CLOSE: 3,
    NO_LATER_THEN_2_HOURS_BEFORE_CLOSE_DATE: 4,
}

const ErrorMessage = {
    [ErrorType.NO_DATE]: 'Please select the date first',
    [ErrorType.DATE_EXISTS]: 'This date is already on the list. Please choose another date.',
    [ErrorType.AFTER_MAX_CLOSE]: 'Please close your survey before ',
    [ErrorType.NO_LATER_THEN_2_HOURS_BEFORE_CLOSE_DATE]:
        'Reminders must be scheduled at least 2 hours before the survey close time',
}

function validateDuplicates(allDates) {
    return reduce(
        allDates,
        (validatedDates, i, index) => {
            if (i.dateTime == null) {
                validatedDates.push(i)
                return validatedDates
            }

            const isDuplicate = isDateDuplicate(
                i.dateTime,
                filter(allDates, (i, pos) => index !== pos)
            )
            const errorExists =
                some(
                    allDates,
                    (d) =>
                        d.validationInfo.errorType === ErrorType.DATE_EXISTS &&
                        d.dateTime.getTime() === i.dateTime.getTime()
                ) ||
                some(
                    validatedDates,
                    (d) =>
                        d.validationInfo.errorType === ErrorType.DATE_EXISTS &&
                        d.dateTime.getTime() === i.dateTime.getTime()
                )

            if ((isDuplicate && !errorExists) || !isDuplicate) {
                validatedDates.push(updateValidationInfo(i, !isDuplicate, ErrorType.DATE_EXISTS))
            } else {
                validatedDates.push(i)
            }

            return validatedDates
        },
        []
    )
}

function validateDates(invitationDates, scheduledCloseDateTime) {
    let validatedInvitationDates = validateDuplicates(invitationDates)
    if (scheduledCloseDateTime != null) {
        validatedInvitationDates = validateRemindersBeforeCloseDate(validatedInvitationDates, scheduledCloseDateTime)
    }

    return validatedInvitationDates
}

function validateBeforeMaxCloseFit(invitationDates, scheduledCloseDate, maxScheduledCloseDate, timeZone) {
    const hasScheduledCloseDate = scheduledCloseDate != null
    const allDates = hasScheduledCloseDate ? [...invitationDates, scheduledCloseDate] : invitationDates

    const errorAfterMaxCloseDate = format(
        addDays(utcToZonedTime(toDate(maxScheduledCloseDate), timeZone?.ianaKey), 1),
        'MMMM d, yyyy',
        {
            timeZone: timeZone?.ianaKey,
        }
    )

    const allValidatedDates = map(allDates, (i) =>
        updateValidationInfo(
            i,
            isDateBeforeMaxClose(i.dateTime, maxScheduledCloseDate),
            ErrorType.AFTER_MAX_CLOSE,
            errorAfterMaxCloseDate
        )
    )

    const validatedInvitationDates = slice(allValidatedDates, 0, invitationDates.length)
    const validatedScheduledCloseDateTime = hasScheduledCloseDate
        ? allValidatedDates[invitationDates.length]
        : scheduledCloseDate

    return { validatedInvitationDates, validatedScheduledCloseDateTime }
}

function isDateBeforeScheduledCloseDate(dateTime, scheduledCloseDate) {
    return dateTime < scheduledCloseDate
}

function isDateBeforeMaxClose(newDate, maxScheduledCloseDate) {
    return newDate <= maxScheduledCloseDate
}

function isDateDuplicate(newDate, otherInvitations) {
    return includes(
        map(otherInvitations, (i) => i.dateTime?.getTime()),
        newDate?.getTime()
    )
}

function validateNewDate(invitationDates, index, newDate, isOnlyTimeChanged) {
    let errorType = null
    if (invitationDates[index].dateTime == null && isOnlyTimeChanged) {
        errorType = ErrorType.NO_DATE
    } else if (newDate != null) {
        if (
            isDateDuplicate(
                newDate,
                filter(invitationDates, (i, pos) => index !== pos)
            )
        ) {
            errorType = ErrorType.DATE_EXISTS
        }
    }

    return errorType
}

function validateRemindersBeforeCloseDate(invitationDates, scheduledCloseDate) {
    const date2HoursBeforeScheduledCloseDate = sub(scheduledCloseDate, {
        hours: 2,
        seconds: -1,
    })

    return map(invitationDates, (i, index) => {
        return index === 0
            ? i
            : updateValidationInfo(
                  i,
                  isDateBeforeScheduledCloseDate(i.dateTime, date2HoursBeforeScheduledCloseDate),
                  ErrorType.NO_LATER_THEN_2_HOURS_BEFORE_CLOSE_DATE
              )
    })
}

function updateValidationInfo(dateObject, isValid, errorType, errorAfterMaxCloseDate) {
    return isValid
        ? dateObject.validationInfo.errorType === errorType
            ? { ...dateObject, validationInfo: { hasError: false } }
            : dateObject
        : { ...dateObject, validationInfo: { hasError: true, errorType, errorAfterMaxCloseDate } }
}

function isValid(invitationDates) {
    return !some(invitationDates, (i) => i.validationInfo.hasError)
}

export { ErrorType, ErrorMessage, validateBeforeMaxCloseFit, validateDates, validateNewDate, isValid }
