import { useCallback, useMemo } from 'react'
import { useAtomValue } from 'jotai/utils'
import concat from 'lodash/concat'
import every from 'lodash/every'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import { allQuestionsAtom, questionMapAtom } from '../statementSetupUtility/store'
import { setAllChecked } from './statementSetupHookUtils'

function isCheckedForAllChecked(question, questionMap) {
    if (question.isSelected) {
        return true
    }

    return (
        question.conditionalQuestionEssenceIds.length > 0 &&
        !every(question.conditionalQuestionEssenceIds, (id) => questionMap[id]?.isSelected)
    )
}

/**
 *
 * @param questions{[]}
 * @param onUpdate{function}
 * @returns {{someChecked: boolean, handleToggleAll: ((function(): void)|*), handleToggleQuestion: ((function(*, *=): void)|*), allChecked: boolean}}
 */
export function useQuestionToggles(questions, onUpdate) {
    const questionMap = useAtomValue(questionMapAtom)
    const allQuestions = useAtomValue(allQuestionsAtom)

    const allChecked = useMemo(
        () => every(questions, (question) => isCheckedForAllChecked(question, questionMap)),
        [questionMap, questions]
    )

    const noneChecked = every(questions, (question) => !question.isSelected)

    const handleToggleAll = useCallback(() => {
        let questionIds = reduce(
            questions,
            (ids, question) => {
                if (
                    allChecked ||
                    question.conditoinalQuestionEssenceIds.length > 0 ||
                    every(question.conditionalQuestionEssenceIds, (id) => questionMap[id]?.isSelected)
                ) {
                    return [...ids, question.questionEssenceId]
                }
                return ids
            },
            []
        )

        if (allChecked) {
            const questionIdsSet = new Set(questionIds)

            const childConditionalQuestionIds = map(
                filter(allQuestions, (question) => {
                    const { conditionalQuestionEssenceIds } = question
                    if (conditionalQuestionEssenceIds.length === 0) {
                        return false
                    }
                    return every(conditionalQuestionEssenceIds, (id) => questionIdsSet.has(id))
                }),
                (q) => q.questionEssenceId
            )
            questionIds = concat(questionIds, childConditionalQuestionIds)
        }

        const updates = reduce(questionIds, setAllChecked(!allChecked), {})
        onUpdate(updates, false)
    }, [allChecked, allQuestions, onUpdate, questionMap, questions])

    const handleToggleQuestion = useCallback(
        (id, checked) => {
            let conditionalQuestionUpdates = {}
            if (!checked) {
                const childConditionalQuestionIds = map(
                    filter(allQuestions, (q) => includes(q.conditionalQuestionEssenceIds, id)),
                    (q) => q.questionEssenceId
                )
                conditionalQuestionUpdates = reduce(childConditionalQuestionIds, setAllChecked(false), {})
            }
            const updates = { [id]: checked, ...conditionalQuestionUpdates }
            onUpdate(updates, !checked)
        },
        [allQuestions, onUpdate]
    )

    const someChecked = !allChecked && !noneChecked

    return { handleToggleAll, allChecked, someChecked, handleToggleQuestion }
}
