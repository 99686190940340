import { colors } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import size from 'lodash/size'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import type { CloudPreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureCloudConfiguration/CultureCloud.types'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'
import contrast from 'util/contrast'
import safeInvoke from 'util/safeInvoke'
import { MINIMUM_DROPLETS_REQUIRED } from '../../Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { getOpacityScale, WordCloud } from './CultureCloud'
import SelectedFilterPills from './SelectedFilterPills'

const WordCloudWrapper = styled.div.attrs({ className: 'relative transform' })<{
    foregroundColor?: string
    backgroundColor?: string
    width?: string
    isPreviewTwo?: boolean
    isShare?: boolean
}>`
    width: ${(props) => props.width ?? '340px'};
    color: ${(props) => props.foregroundColor ?? colors.white};
    background-color: ${(props) => props.backgroundColor ?? colors.darkblue500};
    border-radius: ${(props) => props.isShare && '12px'};
    text-align: center;
    --transform-rotate: ${(props) => (props.isPreviewTwo ? '-3deg' : '0')};

    & svg {
        margin-right: ${(props) => (props.isPreviewTwo ? '40px' : '5px')};
        margin-top: 10px;
    }
`

export const CultureCloudWordPreview = ({
    ariaTitle,
    ariaDescription,
    droplets,
    backgroundColor,
    isExpiredWidget,
    hasEmployerBrandingSubscription,
    minDroplets,
    filterData,
    className,
    classNamePills,
    width,
    cloudChartWidth,
    cloudChartHeight,
    isPreviewTwo,
    isShare,
}: CloudPreviewProps) => {
    const opacity = getOpacityScale(droplets)
    const selectedDroplets = filter(droplets, 'isSelected')
    const minDropletsRequired = size(droplets) >= (minDroplets || MINIMUM_DROPLETS_REQUIRED)
    const foregroundColor = contrast(backgroundColor).isLight ? colors.grey700 : colors.white
    const pillsTextColor = contrast(backgroundColor).isLight ? colors.white : backgroundColor

    return (
        <WordCloudWrapper
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            width={width}
            isPreviewTwo={isPreviewTwo}
            isShare={isShare}>
            {minDropletsRequired ? (
                <div>
                    <WordCloud
                        isVisible={hasEmployerBrandingSubscription}
                        data={{
                            ariaTitle,
                            ariaDescription,
                            droplets: selectedDroplets,
                            hoverInteraction: false,
                            withSlash: false,
                            zoomToFit: true,
                            colors: {
                                positive: foregroundColor,
                                negative: foregroundColor,
                                neutral: foregroundColor,
                            },
                            textWeightScale: 'bold',
                            spiralType: 'rectangular',
                            displaySize: {
                                width: cloudChartWidth ? cloudChartWidth : 340,
                                height: cloudChartHeight ? cloudChartHeight : 270,
                            },
                            chartSize: {
                                width: cloudChartWidth ? cloudChartWidth : 340,
                                height: cloudChartHeight ? cloudChartHeight : 270,
                            },
                            opacity,
                        }}
                        isExpiredWidget={isExpiredWidget}
                    />
                    {!isShare && (
                        <div className={cx(className, 'absolute top-0 right-0')}>
                            <ProgressiveImage src={CultureFactTWP} placeholder="">
                                {(src) => (
                                    <img src={src} alt={'Top work places'} className="border rounded-full w-full" />
                                )}
                            </ProgressiveImage>
                        </div>
                    )}
                    {!isPreviewTwo && (
                        <div className="pr-2 text-right">
                            {safeInvoke(SelectedFilterPills, {
                                foregroundColor,
                                pillsTextColor,
                                filterData,
                                className: classNamePills,
                            })}
                        </div>
                    )}
                </div>
            ) : (
                <InsufficientDataWarning
                    className={cx('font-bold flex text-xl items-center px-8 sm:px-12', isPreviewTwo ? 'h-48' : 'h-72')}
                    warningText="Not enough data for culture cloud"
                />
            )}
        </WordCloudWrapper>
    )
}
