import { useManagementQuery } from 'api/apiQueries'
import showErrorAlert from 'util/showErrorAlert'
import type { Errors, Item } from '../models'

export function useGetEducationalContent(queryKey: string | void) {
    return useManagementQuery<Item[], Errors>(
        ['get-educational-content', queryKey],
        `knowledgeBase/articles/${queryKey}`,
        {
            onError: (error) => {
                showErrorAlert('There was an error loading Educational Content', error.parsed[0].message)
            },
            staleTime: 5 * 60 * 1000 * 12, // 1 hour - meanwhile, until the cache strategy is implemented
        }
    )
}
