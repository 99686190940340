import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { colors, spacing } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import styled, { css } from 'styled-components'
import { Filters } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getFilterQueryParams, getSelectedFiltersRecord } from '../Widgets/utils'
import { Watermark } from '../Widgets/Watermark'
import SelectedFilterPills from './SelectedFilterPills'
import Tile, { tileTypes } from './Tile'
import useFetchPublicPage from './useFetchPublicPage'

const Frame = styled.div.attrs(({ isExpiredWidget, loveMyJobFontSize }) => ({
    className: cx('p-5', { 'opacity-25': isExpiredWidget }, loveMyJobFontSize),
}))`
    filter: blur(${(props) => (props.isSubscribed ? '0px' : '10px')});
`

const Direction = {
    Left: 0,
    Right: 1,
}

function commentBubbleTailDirection({ direction }) {
    const tailSide = direction === Direction.Left ? 'right' : 'left'

    return css`
        border-bottom-${tailSide}-radius: 0;

        .bubble-tail {
            margin-${tailSide}: -1.5px;

            &,
            &:before,
            &:after {
                ${tailSide}: 0;
                border-${tailSide}: 0;
            }

            &:after {
                margin-${tailSide}: 1.5px;
            }

            &:before {
                margin-${tailSide}: 0.5px;
            }
        }
    `
}

const CommentBubble = styled.div.attrs({
    className: 'rounded-lg py-2 px-3 relative shadow text-primary-dark border border-grey300',
})`
    && {
        background-color: ${colors.white};
        margin-bottom: ${spacing(8)};

        &:last-child {
            margin-bottom: ${spacing(6)};
        }

        .bubble-tail {
            &,
            &:before,
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 0;
            }

            // Fake drop shadow
            border: 27px solid transparent;
            border-top-color: rgba(0, 0, 0, 0.05);
            border-bottom: 0;
            margin-bottom: -27px;

            // Fake border
            &:before {
                border: 25px solid transparent;
                border-top-color: ${colors.grey300};
                border-bottom: 0;
                margin-bottom: 2px;
            }

            // Background color fill
            &:after {
                border: 24px solid transparent;
                border-top-color: ${colors.white};
                border-bottom: 0;
                margin-bottom: 4px;
            }
        }

        ${commentBubbleTailDirection}
    }
`

const CommentList = ({ isVisible, comments, loveMyJobFontSize, isExpiredWidget }) => (
    <Frame isSubscribed={isVisible} isExpiredWidget={isExpiredWidget} loveMyJobFontSize={loveMyJobFontSize}>
        <div className="pt-4 pb-2">
            {isExpiredWidget && <Watermark />}
            {map(comments, (quote, index) => (
                <CommentBubble key={quote.text} direction={index % 2}>
                    <span>{quote.text}</span>
                    <div className="bubble-tail" />
                </CommentBubble>
            ))}
        </div>
    </Frame>
)

const commentPropType = PropTypes.arrayOf(
    PropTypes.shape({
        text: PropTypes.string.isRequired,
    })
)

CommentList.propTypes = {
    comments: commentPropType,
}

const EmployeeComment = forwardRef(
    (
        {
            title,
            comments,
            backgroundColor,
            className,
            headerBgColor,
            headerTextColor,
            type,
            width,
            height,
            loveMyJobFontSize,
            dataId,
            isExpiredWidget,
            filterData,
            hasEmployerBrandingSubscription,
        },
        ref
    ) => (
        <Tile
            header={title}
            bgColor={backgroundColor}
            className={className}
            headerBgColor={headerBgColor}
            headerTextColor={headerTextColor}
            ref={ref}
            type={type}
            width={width}
            height={height}
            pills={SelectedFilterPills}
            dataId={dataId}
            blurTileBody={!hasEmployerBrandingSubscription}
            filterData={filterData}>
            <>
                {head(comments) ? (
                    <CommentList
                        backgroundColor={backgroundColor}
                        comments={filter(comments, 'isSelected')}
                        loveMyJobFontSize={loveMyJobFontSize}
                        isExpiredWidget={isExpiredWidget}
                        isVisible={hasEmployerBrandingSubscription}
                    />
                ) : (
                    <InsufficientDataWarning warningText="Not enough data for employee comments" />
                )}
            </>
        </Tile>
    )
)

EmployeeComment.propTypes = {
    title: PropTypes.string.isRequired,
    comments: commentPropType,
    type: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    loveMyJobFontSize: PropTypes.number,
    dataId: PropTypes.string,
}

export default EmployeeComment

export function EmployeeCommentFetch({ location }) {
    const parsedQuery = parseQueryParams(location)
    const query = getFilterQueryParams(parsedQuery)
    const { companyId, surveyEventId, isDownloadable } = parsedQuery

    const { data, isLoading, error } = useFetchPublicPage(
        'loveMyJob-filter',
        `SurveyCompanies/${companyId}/culturefacts/lovemyjob/${surveyEventId}/${isDownloadable}?${query}`
    )
    const {
        data: surveyEvents,
        isLoading: isLoadingSurveyEvents,
        error: surveyEventsError,
    } = useFetchPublicPage(
        'ContentSubFilters',
        `SurveyCompanies/${companyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}&${query}`
    )

    if (isLoading || isLoadingSurveyEvents) {
        return null
    }

    if (error || surveyEventsError) {
        return null
    }

    return (
        <Filters.Provider
            value={{
                filterData: surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery),
            }}>
            <EmployeeComment
                {...data}
                type={tileTypes.vertical}
                filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                hasEmployerBrandingSubscription
            />
        </Filters.Provider>
    )
}
