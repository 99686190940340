export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getFormatterLocaleDate(date: string) {
    return new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

export function cleanCompanyFromPath(fullPath: string) {
    return fullPath.replace(/^\d+\//, '')
}

export function convertParameterToString(value: string | string[] | null): string {
    if (typeof value === 'string') {
        return value
    } else if (Array.isArray(value)) {
        return value.join(', ')
    } else {
        return ''
    }
}
