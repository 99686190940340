import { Loading as Spinner } from '@energage/components'
import styled from 'styled-components'
import Inset from 'components/Inset'
import {
    BackgroundImg,
    getBannerImages,
    LogoDiv,
    YearBox,
} from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/PressReleaseConfiguration'
import useFetchMediaKitAwardImages from 'containers/Main/EmployerBranding/MediaKit/useFetchMediaKitAwardImages'

const className = 'absolute text-white font-medium leading-none text-base'

const MediaKitLogo = styled.div.attrs({ className })`
    height: 150px;
    width: 230px;
    transform: translate(-50%, -50%);
`

const PressReleaseConfiguration = ({
    publisherId,
    surveyCompanyId,
    publishYear,
    isNationalStandard,
    awardCategoryId,
}) => {
    const { pressReleaseResponse, isFetching, isError } = useFetchMediaKitAwardImages(
        publisherId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
        awardCategoryId
    )

    if (isFetching) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const { skyLineImageUrl, logoUrl } = getBannerImages(pressReleaseResponse, awardCategoryId, publisherId)

    return (
        <Inset>
            <div className="w-full relative">
                {skyLineImageUrl && <BackgroundImg src={skyLineImageUrl} height={30} />}
                <LogoDiv top={30}>
                    {logoUrl && (
                        <MediaKitLogo>
                            <img src={logoUrl} alt="logo-images" className="w-full h-full" />
                        </MediaKitLogo>
                    )}
                    {publishYear && (
                        <YearBox>
                            <div className="w-24 mt-24 h-6 p-1">{publishYear}</div>
                        </YearBox>
                    )}
                </LogoDiv>
            </div>
        </Inset>
    )
}

export default PressReleaseConfiguration
