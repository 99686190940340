import { Loading as Spinner } from '@energage/components'
import { useAuthentication } from 'components/Authentication'
import auth from 'util/auth'

// External providers can call /logout to wipe local memory / settings
const Logout = ({ history }) => {
    const { isLoggedIn } = useAuthentication()

    if (isLoggedIn) {
        auth.selfLogout()
    }

    setTimeout(() => {
        history.replace('/')
    }, 5000)

    return <Spinner />
}

export default Logout
