import { useMemo } from 'react'
import { Skeleton } from '@energage/components'
import { useWorkplaceSurveyQuery } from 'api/apiQueries'
import { useIdentity, usePermissions } from 'components/Identity'
import type { SurveyStatus } from 'components/SwimLane/GoodToKnow/constants'
import { getQueryKey } from 'components/SwimLane/GoodToKnow/util'
import {
    GoodToKnowContainer as DeprecatedGoodToKnowContainer,
    QueryableGoodToKnowContainer,
} from '../../../../components/SwimLane/GoodToKnow'
import items from './items.json'

type Props = {
    enableQueryable: boolean
}
export const GoodToKnowContainer = ({ enableQueryable }: Props) => {
    const { surveyCompanyId } = useIdentity()
    const { hasPlatformSurveySetup } = usePermissions()
    const { data: mostRelevantSurveyStatus, error } = useWorkplaceSurveyQuery<SurveyStatus>(
        ['most-relevant-survey-status', surveyCompanyId],
        `surveyhistory/${surveyCompanyId}/mostRelevantSurveyStatus`
    )

    const queryKey = useMemo(() => {
        if (!mostRelevantSurveyStatus) {
            return null
        }

        return getQueryKey(mostRelevantSurveyStatus, !hasPlatformSurveySetup ? false : hasPlatformSurveySetup)
    }, [mostRelevantSurveyStatus, hasPlatformSurveySetup])

    if (!queryKey || error) {
        return <Skeleton.RectangularShape />
    }

    return enableQueryable ? (
        <QueryableGoodToKnowContainer queryKey={queryKey} showEndCard={true} />
    ) : (
        <DeprecatedGoodToKnowContainer sectionTitle={'Good to know'} items={items} showEndCard={true} />
    )
}
