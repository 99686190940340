import forEach from 'lodash/forEach'
import map from 'lodash/map'
// FP reduce doesn't have index which is necessary
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import filter from 'lodash/fp/filter'
import groupBy from 'lodash/fp/groupBy'
import mapValues from 'lodash/fp/mapValues'
import partition from 'lodash/fp/partition'
import pipe from 'lodash/fp/pipe'
import sortBy from 'lodash/fp/sortBy'
import values from 'lodash/fp/values'
import SurveyType from 'constants/SurveyType'
import EditTemplateDemographicSection from '../../Templates/EditTemplatePage/CreateSurvey/DemographicCategorySection'
import EditTemplateQuestionCategorySection from '../../Templates/EditTemplatePage/CreateSurvey/QuestionCategorySection'
import { AdditionalQuestionsCategorySection } from '../AdditionalQuestionsCategorySection'
import { QuestionType } from '../CustomQuestions/QuestionType'
import { DemographicSection } from '../DemographicCategorySection'
import QuestionCategory from '../QuestionCategory'
import QuestionCategorySection from '../QuestionCategorySection'
import {
    categoryNameOverrideMap,
    getCategoryDescription,
    getCategoryNameOverride,
    HELP_TEXT,
    LEADER_QUESTION_ESSENCE_ID,
    THREE_WORD_CULTURE_QUESTION_ESSENCE_ID,
} from './constants'

function getCategoryAdjustment(question) {
    if (
        question.questionEssenceId === THREE_WORD_CULTURE_QUESTION_ESSENCE_ID ||
        question.questionEssenceId === LEADER_QUESTION_ESSENCE_ID
    ) {
        return {
            categoryId: QuestionCategory.Other,
            categoryName: categoryNameOverrideMap[QuestionCategory.Other],
        }
    }

    return {
        categoryName: getCategoryNameOverride(question),
    }
}

const separateHealthcare = partition((q) => q.categoryId === QuestionCategory.HealthCare)
const separateDemographicsByPage = partition((q) => !q.isLastPageDemographic)
const separateCustomQuestions = partition((d) => d.sourceType === null)
const groupByCategoryId = groupBy((q) => q.categoryId)

function groupQuestions(questions) {
    const [healthcare, remaining] = separateHealthcare(questions)
    const {
        null: demographicQuestions,
        [QuestionCategory.DEI]: deiStatements,
        [QuestionCategory.Regional]: regionalStatements,
        [QuestionCategory.Supplemental]: supplementalStatements,
        [QuestionCategory.Other]: otherStatements,
        ...coreSections
    } = groupByCategoryId(remaining)
    const [leadingDemographics, trailingDemographics] = separateDemographicsByPage(demographicQuestions)

    return {
        deiStatements,
        healthcare,
        regionalStatements,
        leadingDemographics,
        trailingDemographics,
        coreSections,
        supplementalStatements,
        otherStatements,
    }
}

function getHelpText(categoryId) {
    return HELP_TEXT[categoryId]
}

function getAdditionalQuestionCategory(q) {
    switch (q.questionTypeId) {
        case QuestionType.Agreement:
            return 'statements'
        case QuestionType.CustomScale:
            return 'customScale'
        case QuestionType.MultiChoice:
        case QuestionType.YesNo:
        case QuestionType.TrueFalse:
            return 'multichoice'
        default:
            return 'extraneous'
    }
}

function groupAdditionalQuestions(questions) {
    return reduce(
        questions,
        (categories, question, index) => {
            categories[getAdditionalQuestionCategory(question)].push({
                ...question,
                sequence: index,
                hasConditionals: Boolean(question.positiveConditionalText || question.negativeConditionalText),
            })
            return categories
        },
        { statements: [], customScale: [], multichoice: [], extraneous: [] }
    )
}

export function getAdditionalQuestionsSubsections(additionalQuestions) {
    const { statements, customScale, multichoice, extraneous } = groupAdditionalQuestions(additionalQuestions)

    return [
        {
            categoryName: 'Agreement Scale Items',
            questions: statements,
            categoryId: QuestionCategory.Custom,
            subsectionId: 'additional-statements',
        },
        {
            categoryName: 'Rating Scale Items',
            questions: customScale,
            categoryId: QuestionCategory.Custom,
            subsectionId: 'additional-customScale',
        },
        {
            categoryName: 'Multiple Choice',
            questions: multichoice,
            categoryId: QuestionCategory.Custom,
            subsectionId: 'multiple-choice',
        },
        {
            categoryName: 'Open-ended Items',
            questions: extraneous,
            categoryId: QuestionCategory.Custom,
            subsectionId: 'additional-extraneous',
        },
    ]
}

function makeTheData(allQuestions, useSurveySetupLayout = true, surveyType) {
    if (allQuestions.length === 0) {
        return []
    }

    const adjustedQuestions = map(allQuestions, (q) => {
        const adjustedCategory = getCategoryAdjustment(q)
        return {
            ...q,
            sequence: null,
            ...adjustedCategory,
            hasConditionals: Boolean(q.negativeConditionalText || q.positiveConditionalText),
        }
    })

    const [nonCustomQuestions, customQuestions] = separateCustomQuestions(adjustedQuestions)
    const selectedCustomQuestions = filter('isSelected', customQuestions)

    const {
        healthcare,
        regionalStatements,
        deiStatements,
        leadingDemographics,
        trailingDemographics,
        coreSections,
        supplementalStatements,
        otherStatements,
    } = groupQuestions(nonCustomQuestions)

    const demographicSection = {
        sectionName: 'Demographic Questions',
        sectionId: 'demographic',
        subsections: [
            {
                categoryName: 'Asked at Beginning of Survey',
                questions: leadingDemographics,
                categoryId: QuestionCategory.Demographic,
                subsectionId: 'leading-demographics',
            },
            {
                categoryName: 'Asked at End of Survey',
                questions: trailingDemographics,
                categoryId: QuestionCategory.Demographic,
                subsectionId: 'trailing-demographics',
            },
        ],
        Component: useSurveySetupLayout ? DemographicSection : EditTemplateDemographicSection,
    }

    const QuestionCategoryComponent = useSurveySetupLayout
        ? QuestionCategorySection
        : EditTemplateQuestionCategorySection

    const workplaceExperienceQuestions = coreSections[QuestionCategory.WorkplaceExperience]
    const isWorkplaceExperienceCategory = size(workplaceExperienceQuestions) > 0

    const sections = [
        {
            sectionName: isWorkplaceExperienceCategory ? workplaceExperienceQuestions[0].categoryName : 'Items',
            sectionId: 'core',
            defaultOpen: true,
            subsections: pipe(
                mapValues((questions) => {
                    const categoryId = questions[0].categoryId
                    const helpText = getHelpText(categoryId)
                    const categoryName = questions[0].categoryName
                    return {
                        categoryId,
                        subsectionId: categoryId,
                        categoryName,
                        questions,
                        helpText,
                        categoryDescription: null,
                    }
                }),
                //set sequence
                (subSections) => {
                    if (surveyType !== SurveyType.Custom) {
                        return subSections
                    }
                    let index = 0
                    forEach(subSections, (s) => {
                        //reorder before sequencing to match display order
                        s.questions = sortBy((q) => {
                            return [q.isSelected ? 0 : 1, q.questionTypeId === QuestionType.Agreement ? 0 : 1]
                        }, s.questions)
                        forEach(s.questions, (q) => {
                            q.sequence = index++
                        })
                    })
                    return subSections
                },
                values,
                sortBy((c) => c.id)
            )(coreSections),
            Component: QuestionCategoryComponent,
        },
        ...(otherStatements?.length > 0
            ? [
                  {
                      sectionName: otherStatements[0].categoryName,
                      sectionId: QuestionCategory.Other,
                      subsections: [
                          {
                              categoryId: QuestionCategory.Other,
                              subsectionId: QuestionCategory.Other,
                              categoryName: null,
                              questions: otherStatements,
                          },
                      ],
                      Component: QuestionCategoryComponent,
                      categoryDescription: getCategoryDescription(QuestionCategory.Other),
                  },
              ]
            : []),
        ...(supplementalStatements?.length > 0
            ? [
                  {
                      sectionName: supplementalStatements[0].categoryName,
                      sectionId: QuestionCategory.Supplemental,
                      subsections: [
                          {
                              categoryId: QuestionCategory.Supplemental,
                              subsectionId: QuestionCategory.Supplemental,
                              categoryName: null,
                              questions: supplementalStatements,
                          },
                      ],
                      Component: QuestionCategoryComponent,
                      categoryDescription: getCategoryDescription(QuestionCategory.Supplemental),
                  },
              ]
            : []),
        ...(regionalStatements?.length > 0
            ? [
                  {
                      sectionName: regionalStatements[0].categoryName,
                      sectionId: QuestionCategory.Regional,
                      subsections: [
                          {
                              categoryId: QuestionCategory.Regional,
                              subsectionId: QuestionCategory.Regional,
                              categoryName: null,
                              questions: regionalStatements,
                          },
                      ],
                      Component: QuestionCategoryComponent,
                      categoryDescription: getCategoryDescription(QuestionCategory.Regional),
                  },
              ]
            : []),
        ...(deiStatements?.length > 0
            ? [
                  {
                      sectionName: deiStatements[0].categoryName,
                      sectionId: QuestionCategory.DEI,
                      subsections: [
                          {
                              categoryId: QuestionCategory.DEI,
                              subsectionId: QuestionCategory.DEI,
                              categoryName: null,
                              questions: deiStatements,
                          },
                      ],
                      Component: QuestionCategoryComponent,
                      categoryDescription: getCategoryDescription(QuestionCategory.DEI),
                  },
              ]
            : []),
        ...(healthcare?.length > 0
            ? [
                  {
                      sectionName: healthcare[0].categoryName,
                      sectionId: QuestionCategory.HealthCare,
                      subsections: [
                          {
                              categoryId: QuestionCategory.HealthCare,
                              subsectionId: QuestionCategory.HealthCare,
                              categoryName: null,
                              questions: healthcare,
                          },
                      ],
                      Component: QuestionCategoryComponent,
                      categoryDescription: getCategoryDescription(QuestionCategory.HealthCare),
                  },
              ]
            : []),
        {
            sectionName: 'Custom/Added',
            sectionId: 'additional',
            subsections: getAdditionalQuestionsSubsections(selectedCustomQuestions),
            Component: AdditionalQuestionsCategorySection,
        },
    ]

    return {
        demographicSection,
        sections,
        questionBankQuestions: selectedCustomQuestions,
    }
}

export function transformSurveyDefinition(data, useSurveySetupLayout) {
    if (data) {
        const { questions, ...surveyData } = data
        return {
            ...surveyData,
            ...makeTheData(questions, useSurveySetupLayout, data.surveyType),
        }
    }
}
