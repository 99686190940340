import every from 'lodash/every'
import map from 'lodash/map'
import max from 'lodash/max'
import some from 'lodash/some'
import type { SurveyScopeResponse } from '../Recipients/useSurveyScope'
import type { AwardSelection, SelectedAward, SizeBand } from './awardselection.types'

export function getParticipatingNationalEmployeeCount(awards: SelectedAward[]) {
    if (!some(awards, (x) => !!x.employeeCount)) {
        return 0
    }

    return max(map(awards, (x) => Number(x.employeeCount))) ?? 0
}

export function validateAwardCount(award: AwardSelection) {
    const minimum = award.minimumEmployeeCount ?? 0
    const { employeeCount } = award

    if (!employeeCount) {
        return false
    }

    if (typeof employeeCount === 'string' && employeeCount?.trim() === '') {
        return false
    }

    if (typeof employeeCount !== 'number' || isNaN(employeeCount)) {
        return false
    }

    return employeeCount >= minimum
}

export function awardsHaveValidEmployeeCount(awards: SelectedAward[]) {
    return every(awards, validateAwardCount)
}

function getSizeBand(sizeBands: SizeBand[], employeeCount: number) {
    return sizeBands.find((sizeBand) => {
        const { minimumEmployeeCount, maximumEmployeeCount } = sizeBand

        return employeeCount >= minimumEmployeeCount && employeeCount <= maximumEmployeeCount
    })
}

type AwardWithEmployeeCount = SelectedAward & { employeeCount: number | string }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withEmployeeCount(award: any): award is AwardWithEmployeeCount {
    return award.employeeCount !== undefined && award.employeeCount !== null
}

function hasSizeBandDifference(award: AwardWithEmployeeCount, inviteeCount: number) {
    if (!award.sizeBands || award.sizeBands.length === 0) {
        return false
    }

    const awardSizeBand = getSizeBand(award.sizeBands, Number(award.employeeCount))
    const uploadSizeBand = getSizeBand(award.sizeBands, inviteeCount)
    return uploadSizeBand?.surveyBenchmarkSizeBandId !== awardSizeBand?.surveyBenchmarkSizeBandId
}

export function hasSizeBandDiscrepancy(surveyScope: SurveyScopeResponse | undefined, awards: SelectedAward[]) {
    if (!surveyScope) {
        return false
    }

    return awards
        .filter(withEmployeeCount)
        .filter((award) => surveyScope.inviteeCount < Number(award.employeeCount))
        .some((award) => hasSizeBandDifference(award, surveyScope.inviteeCount))
}
