export const badgeTypes = {
    SingleYear: 'SingleYear',
    MultiYear: 'MultiYear',
    MultiAward: 'MultiAward',
}

export const fileTypes = {
    PDF: 'PDF',
    JPEG: 'JPEG',
    PNG: 'PNG',
}

export const endpoints = {
    [badgeTypes.SingleYear]: { endpoint: 3 },
    [badgeTypes.MultiYear]: { endpoint: 5 },
    [badgeTypes.MultiAward]: { endpoint: 6 },
}

export const awardCategoryId = {
    CultureExcellence: 1,
    Industry: 2,
    TwpUsa: 3,
    Regional: 4,
}
