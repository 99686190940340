import { useMediaQuery } from '@energage/hooks'
import cx from 'clsx'
import size from 'lodash/size'
import type { CongratulationSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { AWARD_CATEGORY, DALLAS_PUBLISHER_ID } from 'containers/Main/AwardAnnouncement/constants'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { getAwardTypeName } from 'containers/Main/AwardAnnouncement/utils'
import { viewports } from 'style/breakpoints'
import withClassName from 'style/util/withClassName'
import { conjunctionJoin } from 'util/conjunctionJoin'
import { WinnerAwards } from '../WinnerAwards'

const Subtitle = withClassName('sm:text-lg md:text-xl', 'h2', 'subtitle')
const Text = withClassName('sm:text-lg md:text-xl py-5', 'p', 'text')
const PdfSubTitle = withClassName('text-xl', 'h2', 'subtitle')
const PdfText = withClassName('text-sm mt-4', 'p', 'text')

export const Congratulations = ({
    awardCategoryId,
    companyName,
    year,
    publisherId,
    awardsSvg,
    colors,
    isPdf,
    winnerAwards,
    aspirantAwards,
    isOldAwardAnnouncement,
}: CongratulationSection) => {
    const awardTypeName = getAwardTypeName(awardCategoryId)
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMax })
    const isDallasPublisher = publisherId === DALLAS_PUBLISHER_ID
    const SubTitleComponent = isPdf ? PdfSubTitle : Subtitle
    const TextComponent = isPdf ? PdfText : Text

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor} isPdf={isPdf}>
            <p className={isPdf ? 'text-4xl' : 'text-5xl sm:text-6xl'}>{'Congratulations'}</p>
            <h2 className={isPdf ? 'text-2xl font-black mt-2' : 'text-3xl sm:text-5xl mt-4 sm:mt-0 mt-4 font-black'}>
                {`${companyName} is a ${year} Top Workplaces winner!`}
            </h2>
            {isMobileDevice && !isPdf && (
                <div className="justify-center text-center font-bold mt-8 mb-4">{awardTypeName}</div>
            )}
            <WinnerAwards
                awardsSvg={awardsSvg}
                isPdf={isPdf}
                publisherId={publisherId}
                awardCategoryId={awardCategoryId}
                year={year}
                isOldAwardAnnouncement={isOldAwardAnnouncement}
            />
            {(!isMobileDevice || isPdf) && (
                <div
                    className={cx('justify-center text-center font-bold', {
                        'text-sm mb-3 mt-1': isPdf,
                        'mt-4': !isPdf,
                    })}>
                    {awardTypeName}
                </div>
            )}
            <div>
                <SubTitleComponent className={isPdf ? 'mt-2' : 'mt-8 sm:mt-16 md:mt-32'}>
                    {"You've earned the coveted gold badge!"}
                </SubTitleComponent>
                <TextComponent>{`Based entirely on the employee feedback captured with the Workplace Survey, your Top Workplaces win is proof positive that ${companyName} puts its people first.`}</TextComponent>
                {awardCategoryId === AWARD_CATEGORY.CULTURE_EXCELLENCE && (
                    <>
                        <SubTitleComponent className="pt-4 sm:pt-10">{'Awards You Won!'}</SubTitleComponent>
                        <TextComponent>
                            {`You've won ${conjunctionJoin('and', winnerAwards)}. ${
                                size(aspirantAwards) > 0
                                    ? `Unfortunately, ${companyName} is not a Top Workplaces winner for ${conjunctionJoin(
                                          'and',
                                          aspirantAwards
                                      )}. `
                                    : ''
                            }The results are based on the employee engagement survey you recently issued.`}
                        </TextComponent>
                    </>
                )}
                <SubTitleComponent className="pt-4 sm:pt-10">
                    {'Share this victory with senior leadership.'}
                </SubTitleComponent>
                <TextComponent>
                    {`It's a big deal, so make sure you share this news with senior leaders. For now, we ask that you keep your Top Workplaces win limited to this small group. But as soon as the awards list has been published, you can tell your employees – and the whole world – you're a Top Workplaces winner! See below for the timeline of events.`}
                </TextComponent>
                <SubTitleComponent className="pt-4 sm:pt-10">{`Put your Top Workplaces award to work for ${companyName}.`}</SubTitleComponent>
                <TextComponent className="mb-4">
                    {`Being a Top Workplaces winner can give your organization a competitive advantage. It can help you to attract the right talent and get the attention of customers. So, let's get ready to promote your award and showcase what makes your culture so special. Here are some ways to do that.`}
                </TextComponent>
            </div>
            {!isDallasPublisher && (
                <>
                    <div className="mt-24">
                        <h1 className={cx('text-2xl font-black', { 'sm:text-5xl': !isPdf })}>
                            {"Here's what to do next.​"}
                        </h1>
                        <TextComponent>
                            {
                                'Start by getting the Top Workplaces Media Kit that includes materials for promoting your award.​'
                            }
                        </TextComponent>
                    </div>
                </>
            )}
        </Section>
    )
}
