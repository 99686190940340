import { LegacyButton as Button, Skeleton } from '@energage/components'
import { utcToZonedTime } from 'date-fns-tz'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import Tile from 'components/Tile'
import config from 'config'
import { routes } from 'constants/routes'
import { SurveyNameWithIcon } from './fragments'
import ActiveOrClosedTile from './TileStates/ActiveOrClosed'
import NewSurveyTile from './TileStates/NewSurvey'
import NotApprovedTile from './TileStates/NotApproved'
import NotApprovedUrgentTile from './TileStates/NotApprovedUrgent'
import ReadyTile from './TileStates/Ready'
import UnscheduledTile from './TileStates/Unscheduled'

const SurveyTileStatus = {
    Active: 1,
    Closed: 2,
    Approved: 3,
    NotApprovedUrgent: 4,
    NotApproved: 5,
    Unscheduled: 6,
    NewSurvey: 7,
}

const EmptyState = () => null

const SurveyTitle = ({ title }) => (
    <div className="flex justify-between">
        <div>{title}</div>
        <div>
            <Button to={routes.survey()} variant="link" as={Link} className="p-0 text-xs capitalize ">
                {`View All`}
            </Button>
        </div>
    </div>
)

const SurveyInfo = ({ surveyTileStatus, surveyEventInfo }) => {
    const {
        surveyName: name,
        isTwpParticipant,
        id,
        launchDateTime,
        scheduledCloseDateTime,
        timeZone,
        closedDateTime,
    } = surveyEventInfo || {}
    const isClosed = surveyTileStatus === SurveyTileStatus.Closed
    const SurveyName = <SurveyNameWithIcon surveyName={name} isTwpParticipant={isTwpParticipant} />
    const localLaunchDateTime = launchDateTime ? utcToZonedTime(launchDateTime, timeZone?.ianaKey) : null
    const localScheduledCloseDateTime = scheduledCloseDateTime
        ? utcToZonedTime(scheduledCloseDateTime, timeZone?.ianaKey)
        : null
    const localClosedDateTime = closedDateTime ? utcToZonedTime(closedDateTime, timeZone?.ianaKey) : null

    if (isClosed || surveyTileStatus === SurveyTileStatus.Active) {
        const redirectUrl =
            isClosed && surveyEventInfo.showResults ? `${config.urls.results}/Home.aspx?e=${id}&fcc=y&fpp=y` : null
        return (
            <ActiveOrClosedTile
                {...surveyEventInfo}
                launchDateTime={localLaunchDateTime}
                scheduledCloseDateTime={localScheduledCloseDateTime}
                closedDateTime={localClosedDateTime}
                isClosed={isClosed}
                surveyName={SurveyName}
                redirectUrl={redirectUrl}
            />
        )
    }

    if (surveyTileStatus === SurveyTileStatus.Unscheduled) {
        return <UnscheduledTile id={id} surveyName={SurveyName} />
    }

    if (surveyTileStatus === SurveyTileStatus.Approved) {
        return (
            <ReadyTile
                id={id}
                surveyName={SurveyName}
                launchDateTime={localLaunchDateTime}
                scheduledCloseDateTime={localScheduledCloseDateTime}
            />
        )
    }

    if (surveyTileStatus === SurveyTileStatus.NotApproved) {
        return (
            <NotApprovedTile
                id={id}
                surveyName={SurveyName}
                launchDateTime={localLaunchDateTime}
                scheduledCloseDateTime={localScheduledCloseDateTime}
            />
        )
    }

    if (surveyTileStatus === SurveyTileStatus.NotApprovedUrgent) {
        return (
            <NotApprovedUrgentTile
                id={id}
                surveyName={SurveyName}
                launchDateTime={localLaunchDateTime}
                scheduledCloseDateTime={localScheduledCloseDateTime}
            />
        )
    }

    return <NewSurveyTile />
}

export default SurveyInfo

const SurveyInfoSkeleton = styled(Skeleton.RectangularShape)`
    height: 470px;
    width: 365px;
`

export function SurveyInfoFetch() {
    const { surveyCompanyId } = useIdentity()
    const { data, isLoading, error } = useWorkplaceSurveyQuery(
        ['survey-tile', surveyCompanyId],
        `surveyhistory/${surveyCompanyId}/surveyTile`
    )

    if (isLoading) {
        return <SurveyInfoSkeleton />
    }

    if (error || !data) {
        return <EmptyState />
    }

    const { surveyTileStatus, surveyEventInfo } = data

    return (
        <div data-testid="survey-tile" className="h-full">
            <Tile
                style={{ width: '365px' }}
                className="p-0"
                title={surveyTileStatus === SurveyTileStatus.NewSurvey ? 'Survey' : <SurveyTitle title="Survey" />}>
                <SurveyInfo surveyTileStatus={surveyTileStatus} surveyEventInfo={surveyEventInfo} />
            </Tile>
        </div>
    )
}
