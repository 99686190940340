import forEach from 'lodash/forEach'
import { array, boolean, mixed, object, string } from 'yup'

const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const urlRegex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

const ssoFormValidationMessage =
    'This information is provided in the enterprise application you set up for Energage your identity provider.'

export const ssoValidationSchema = object()
    .shape({
        signInUrl: string().required(ssoFormValidationMessage).matches(urlRegex, 'Sing-In URL must be a valid URL'),
        userIdAttribute: string().matches(urlRegex, ssoFormValidationMessage).required(),
        certificateFile: mixed().test('fileExists', ssoFormValidationMessage, (value) => !!value),
        domains: array<{ value: string }[]>()
            .min(1, 'You need at least one domain. Enter all the email domains your employees might have.')
            .test('is-valid-domain', 'Domains validation', function (domains, ctx) {
                if (!domains) {
                    return ctx.createError({
                        path: 'domains',
                        message: 'You need at least one domain. Enter all the email domains your employees might have.',
                    })
                }

                if (domains && domains.length === 1) {
                    const domain = domains[0]
                    if (domain === null || domain === undefined || domain.value === '') {
                        return ctx.createError({
                            path: 'domains.0.value',
                            message:
                                'You need at least one domain. Enter all the email domains your employees might have.',
                        })
                    }
                }

                if (domains) {
                    let failedDomain = -1
                    forEach(domains, (domain, index) => {
                        if (
                            domain === null ||
                            domain === undefined ||
                            domain.value === '' ||
                            !domainRegex.test(domain.value)
                        ) {
                            failedDomain = index
                            return false
                        }
                    })

                    if (failedDomain !== -1) {
                        const domainNumber = `domains.${failedDomain}.value`
                        return ctx.createError({
                            path: domainNumber,
                            message: 'Value must be a valid domain.',
                        })
                    }
                }

                return true
            })
            .required(),
        idpSsoEnabled: boolean().required(),
    })
    .required()
