import ky from 'ky'

export async function uploadToStorage({ url, mimeType, blob, headers }) {
    return ky.put(url, {
        headers: {
            ...headers,
            'x-ms-blob-type': 'BlockBlob',
            'x-ms-content-type': mimeType,
        },
        body: blob,
    })
}
