import { useCallback } from 'react'
import { alert } from '@energage/components'
import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePostWithTaskCompletion } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import { useScheduleActiveSurveysSync } from '../UpdateActiveSurveys'

function transformErrorMessages(errors) {
    let newErrors = []

    const newError = (message) => newErrors.push({ message })

    forEach(errors, (error) => {
        if (!error.code) {
            newError('There was an error saving the department')
            return
        }

        switch (error?.code) {
            case 'DepartmentNameAlreadyExists':
                newError('Department has already been added')
                return
            default:
                newError(error?.message)
        }
    })

    return newErrors
}

export function useAddDepartment(onComplete = noop) {
    const { organizationId } = useIdentity()
    const { doSync } = useScheduleActiveSurveysSync()
    const { doPost, isPosting, reset } = usePostWithTaskCompletion({
        url: `${config.api.platform}/Organizations/${organizationId}/Departments`,
        onComplete: (data) => {
            onComplete(data)
            alert.success('Department added successfully.')
        },
        onError: (error) => {
            showErrorAlert('Error', transformErrorMessages(error))
            reset()
        },
    })

    const add = useCallback(
        (departmentName, parentDepartmentId, memberIds) => {
            doPost({
                departmentName,
                parentDepartmentId,
                memberIds,
            }).then(doSync)
        },
        [doPost, doSync]
    )

    return { add, isAdding: isPosting }
}

export function useEditDepartment(departmentId, onComplete = noop) {
    const { organizationId } = useIdentity()
    const { doSync } = useScheduleActiveSurveysSync()
    const { doPost, isPosting, reset } = usePostWithTaskCompletion({
        url: `${config.api.platform}/Organizations/${organizationId}/Departments/${departmentId}`,
        onComplete: (data) => {
            onComplete(data)
            alert.success('Department edited successfully.')
        },
        onError: (error) => {
            showErrorAlert('There was an error in editing the department', error)
            reset()
        },
    })

    const edit = useCallback(
        (departmentName, parentDepartmentId, memberIds) => {
            doPost({
                departmentName,
                parentDepartmentId,
                memberIds,
            }).then(doSync)
        },
        [doPost, doSync]
    )

    return { edit, isEditing: isPosting }
}
