import { ReactComponent as TWPLogo } from 'images/twp-widget-logo.svg'
import type { ImageTile } from '../models'
import { TileCard } from './TileCard'

const ImageCard = (imageTile: ImageTile) => {
    return (
        <TileCard height={'264px'} tile={imageTile}>
            <div className="p-4 bg-grey100">
                <TWPLogo className="float-right" width={'26px'} height={'50px'} />
                <p className="paragraph-small">{'RESEARCH LAB REPORT'}</p>
                <h4 className="paragraph-lead font-bold text-darkblue700 pb-5">{imageTile.text}</h4>
            </div>
            <img
                className="h-auto"
                alt={imageTile.text}
                /* eslint-disable-next-line @typescript-eslint/no-var-requires */
                src={require(`images/scienceLab/${imageTile.imageUrl}`).default}
                width={'356px'}
            />
        </TileCard>
    )
}

export { ImageCard }
