import { useMediaQuery } from '@energage/hooks'
import { FormatPaint } from '@energage/icons'
import { colors } from '@energage/theme'
import { viewports } from 'style/breakpoints'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryCard } from '../../Common/SummaryCard'

export const ErrorTile = ({ message }) => {
    const isSmallScreen = useMediaQuery({ maxWidth: viewports.smMin })

    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="justify-center mt-2">
                <FormatPaint fill={colors.red600} size={88} className="hidden sm:block" />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="text-red600 mb-2" ariaText="Required: We're sorry, but something went wrong">
                        {isSmallScreen && (
                            <CardIcon className="justify-center mt-2">
                                <FormatPaint fill={colors.red600} size={30} className="mr-3" />
                            </CardIcon>
                        )}
                        {"We're sorry, but something went wrong"}
                    </CardTitle>
                    <p>{message}</p>
                </CardContent>
            </CardBody>
        </SummaryCard>
    )
}
