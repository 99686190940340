import { useState } from 'react'
import type { ReactNode } from 'react'
import { Input, LegacyButton as Button } from '@energage/components'
import { Paginate as Pagination } from '@energage/components/lib/Pagination/Paginate'
import { useMediaQuery } from '@energage/hooks'
import { FileDownload, Icon, Impersonate as ImpersonateIcon, Search } from '@energage/icons'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'
import type { Cell, CellProps, Column, FilterValue, HeaderGroup, Row, TableInstance } from 'react-table'
import styled from 'styled-components'
import { VisuallyHidden } from 'components'
import { useIdentity } from 'components/Identity'
import { routes } from 'constants/routes'
import { media, viewports } from 'style/breakpoints'
import pluralize from 'util/pluralize'
import { Role } from './models'
import useDownloadUsers from './useDownloadUsers'
import { useGetCompanyUsers } from './useUserManagementQueries'

export type GlobalFilterValue = {
    text: string
    activeOnly: boolean
}

export type TableData = {
    id: number
    firstName: string // Only used for searching, hidden
    lastName: string // Only used for searching, hidden
    email: string
    roles: Role[]
    surveyCount: number
    lastActive: string
    isDisabled: boolean
    isCustomerSuccess: boolean
}

interface ColumnHeaderNames {
    User: string
    Permissions: string
    LastActive: string
    Impersonate: string
}

const columnHeaderNames: ColumnHeaderNames = {
    User: 'User',
    Permissions: 'Permissions',
    LastActive: 'Last Active',
    Impersonate: '',
}

interface ColumnIdNames {
    firstName: string
    lastName: string
    email: string
    roles: string
    lastActive: string
    impersonate: string
}

const columnIdNames: ColumnIdNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    roles: 'roles',
    lastActive: 'lastActive',
    impersonate: 'impersonate',
}

const SearchIcon = styled(Search).attrs({ className: 'absolute text-primary-dark' })`
    left: 10px;
    top: 10px;
    height: 27px;
    width: 15px;
`

const SearchInput = styled(Input).attrs({
    className: 'w-1/5 sm:w-1/3 md:w-1/3 flex items-center',
    placeholder: 'Find a person',
})`
    ${media.smMin`
        overflow: auto;
        scrollbar-gutter: stable;
    `}

    input {
        padding-left: 1.75rem;
    }

    .input-group__info-text {
        display: none;
    }

    min-width: 130px;
`

const Pill = styled.li.attrs<{ className: string }>({
    className: 'flex items-center whitespace-no-wrap w-auto h-4 rounded px-2 paragraph-small font-medium',
})`
    align-self: baseline;
`

const CSPill = ({ className }: { className: string }) => (
    <Pill as="span" className={`bg-blue400 text-primary-dark ${className}`}>
        {'CS'}
        <VisuallyHidden>{' Primary Contact'}</VisuallyHidden>
    </Pill>
)
const InactivePill = () => (
    <Pill className="bg-grey300 mt-1" role="listitem">
        {'Inactive'}
    </Pill>
)
const RolePill = ({ children }: { children: ReactNode }) => (
    <Pill className="bg-purple100 mt-1" role="listitem">
        {children}
    </Pill>
)

export const Columns: Column<TableData>[] = [
    {
        id: columnIdNames.firstName,
        accessor: 'firstName',
    },
    {
        id: columnIdNames.lastName,
        accessor: 'lastName',
    },
    {
        Header: columnHeaderNames.User,
        id: columnIdNames.email,
        accessor: 'email',
        Cell: ({
            row: {
                original: { id, firstName, lastName, email, isCustomerSuccess },
            },
        }: CellProps<TableData>) => {
            const {
                isSuperUser,
                company: { isPublisher },
            } = useIdentity()

            return (
                <Button
                    variant="link"
                    className="px-3 py-5 flex flex-col items-start gap-1 w-full"
                    as={Link}
                    to={routes.manage.users.edit({ userId: id })}>
                    <div className="flex items-end align-top mb-2 font-bold text-base text-left">
                        <p>
                            {firstName} {lastName}
                        </p>
                        {!isPublisher && isSuperUser && isCustomerSuccess && <CSPill className="ml-2" />}
                    </div>
                    <p className="text-xs text-primary-dark font-normal text-left">{email}</p>
                </Button>
            )
        },
    },
    {
        Header: columnHeaderNames.Permissions,
        id: columnIdNames.roles,
        accessor: 'roles',
        Cell: ({ row }) =>
            row.original.roles ? (
                <div className="p-3 pt-3 flex flex-col items-start gap-1 max-w-xs">
                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                    <ol className="mt-1 pb-1 pl-0 flex flex-wrap items-start gap-x-1" role="list">
                        {row.original.roles.map((i: string) =>
                            i === Role.Inactive ? <InactivePill key="inactive" /> : <RolePill key={i}>{i}</RolePill>
                        )}
                    </ol>
                    {row.original.roles.includes(Role.Admin) ? (
                        <p className="pt-1 text-xs">{`All Surveys`}</p>
                    ) : row.original.roles.includes(Role.Insights) ? (
                        <p className="pt-1 text-xs">{pluralize(row.original.surveyCount, 'Survey')}</p>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            ),
        width: 250,
        maxWidth: 267,
    },
    {
        Header: columnHeaderNames.LastActive,
        id: columnIdNames.lastActive,
        accessor: 'lastActive',
        Cell: ({ row }) => {
            return <div className="px-3 py-4">{row.original.lastActive}</div>
        },
    },
    {
        Header: columnHeaderNames.Impersonate,
        id: columnIdNames.impersonate,
        Cell: ({ row }: CellProps<TableData>) => {
            return row.original.isDisabled ? (
                <></>
            ) : (
                <Button
                    onClick={() => {
                        // @ts-ignore
                        Cookies.set('impersonationKey', row.original.id, {
                            domain: '.' + document.domain.split('.').reverse().splice(0, 2).reverse().join('.'),
                            secure: true,
                        })
                        window.location.href = '/'
                    }}
                    variant="link"
                    className="mt-1 flex justify-center text-black">
                    <VisuallyHidden>{'Impersonate'}</VisuallyHidden>
                    <ImpersonateIcon />
                </Button>
            )
        },
    },
]

const TableCell = (cell: Cell<TableData>) => {
    return (
        <td {...cell.getCellProps()} className="align-top">
            {cell.render('Cell')}
        </td>
    )
}

const TableRow = (row: Row<TableData>) => {
    return (
        <tr {...row.getRowProps()} className="border-grey200 border-t-2">
            {row.cells.map(TableCell)}
        </tr>
    )
}

const TableHeader = (column: HeaderGroup<TableData>) => {
    const { isSuperUser } = useIdentity()

    const columnName = column.id
    const classes = {
        [columnIdNames.email]: 'w-5/12',
        [columnIdNames.roles]: isSuperUser ? 'w-4/12' : 'w-5/12',
        [columnIdNames.lastActive]: 'w-2/12',
        [columnIdNames.impersonate]: 'w-1/12',
    }

    const columnWidth = classes[columnName] ?? ''

    return (
        <th
            {...column.getHeaderProps({
                className: `p-3 text-grey500 text-left whitespace-no-wrap paragraph-small font-bold h-full ${columnWidth}`,
            })}>
            {column.render('Header')}
        </th>
    )
}

type GlobalFilterProps = {
    setGlobalFilter: (filterValue: FilterValue) => void
    globalFilter: string
    filteredRowsCount: number
}

function GlobalFilter({ globalFilter, setGlobalFilter, filteredRowsCount }: GlobalFilterProps) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value && value !== '' ? { activeOnly: false, text: value } : { activeOnly: true, text: '' })
    }, 200)
    const { writeExcelFile } = useDownloadUsers()
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMin })
    const { surveyCompanyId } = useIdentity()
    const { data: companyUsersData } = useGetCompanyUsers(surveyCompanyId)

    return (
        <div className="sm:mt-0 relative flex justify-between mb-3">
            <SearchIcon role="presentation" />
            <SearchInput
                size="sm"
                value={value ?? ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            />
            <VisuallyHidden aria-live="polite">{`${pluralize(
                filteredRowsCount,
                'user'
            )} matching criteria found`}</VisuallyHidden>
            <div className="flex items-center">
                <Button
                    variant="link"
                    onClick={() => writeExcelFile(companyUsersData)}
                    className="text-purple700 hover:text-purple600">
                    {isMobileDevice ? <VisuallyHidden>{'Download Users'}</VisuallyHidden> : 'Download Users'}
                    <Icon as={FileDownload} />
                </Button>
                <Button variant="primary" as={Link} size="sm" to={routes.manage.users.add()} className="">
                    {isMobileDevice ? 'Add User' : 'Add New User'}
                </Button>
            </div>
        </div>
    )
}

export const Table = ({
    state,
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageOptions,
    state: { pageIndex, pageSize },
    rows,
}: TableInstance<TableData>) => {
    return (
        <div className="max-w-lg px-6">
            <GlobalFilter
                globalFilter={state.globalFilter.text}
                setGlobalFilter={setGlobalFilter}
                filteredRowsCount={rows.length}
            />
            <div className="overflow-x-auto shadow-md rounded">
                <table {...getTableProps()} className="bg-white w-full border-collapse mx-auto sm:mx-0">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps({ className: 'border-b border-grey300' })}>
                                {headerGroup.headers.map((column) => TableHeader(column))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return TableRow(row)
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                key={pageIndex /* hack to use react-tables pagination with this component and keep pages aligned */}
                paginationClassName="flex justify-center mt-4"
                itemCount={pageOptions.length * pageSize}
                itemCountPerPage={pageSize}
                pageNumber={pageIndex + 1}
                onPageChange={(pageIndex) => gotoPage(pageIndex)}
            />
        </div>
    )
}
