import cx from 'clsx'
import map from 'lodash/map'
import { routes } from 'constants/routes'
import type { FeatureYourCultureSection } from '../awardAnnouncement.types'
import { Section } from '../Section'
import { YellowButton } from '../YellowButton'

export const listItems = [
    {
        imgPath: 'pro5.svg',
        heading: 'Premium Media Kits',
        text: 'Access kits for current and any future premium Top Workplaces awards.',
    },
    {
        imgPath: 'pro4.svg',
        heading: 'Culture Badges',
        text: 'Show off where your company culture excels.',
    },
    {
        imgPath: 'pro6.svg',
        heading: 'Premium Company Profile on topworkplaces.com',
        text: 'Create a custom profile that’s featured on the site seen by millions each year.',
    },
    {
        imgPath: 'pro7.svg',
        heading: 'Culture Facts',
        text: 'Leverage employee feedback to show what makes your culture unique.',
    },
]

export const FeatureYourCulture = ({ companyName, colors, baseUrl, isPdf }: FeatureYourCultureSection) => (
    <Section bgColor={colors.bgColor} textColor={colors.textColor}>
        <h2 className="sm:text-5xl text-2xl font-black">{'And feature what makes your culture special and unique.'}</h2>
        <p className="sm:text-2xl text-lg pt-4 pb-0 sm:py-4 ">{`Employer Branding tools will help ${companyName} to stand out among the competition:`}</p>
        <div
            className={cx('flex bg-white px-3 shadow-xl', {
                'flex-col mt-12 py-6': isPdf,
                'flex-wrap mt-16 pt-2 pb-6 sm:py-6 justify-center': !isPdf,
            })}>
            {map(listItems, ({ imgPath, heading, text }, index) => (
                <div
                    key={index}
                    className={cx('flex px-3 py-6', { 'flex-row': isPdf, 'flex-col sm:flex-row sm:w-1/2': !isPdf })}>
                    <img
                        alt=""
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        src={require(`images/employerBranding/mediaKit/${imgPath}`).default}
                        className={
                            isPdf ? 'pb-0 w-24 pr-6 self-start' : 'pb-6 sm:pb-0 sm:pr-6 self-center sm:self-start'
                        }
                    />
                    <div className={isPdf ? 'w-3/4 ml-4' : 'text-center'}>
                        <h2 className={cx('text-2xl text-center', { 'sm:text-left': !isPdf })}>{heading}</h2>
                        <p className={cx('text-xl pt-2 text-center', { 'sm:text-left': !isPdf })}>{text}</p>
                    </div>
                </div>
            ))}
        </div>
        <div className="mt-12 text-center">
            <YellowButton href={isPdf ? `${baseUrl}branding` : routes.branding()} variant="link" target="_blank">
                {'Go to Employer Branding'}
            </YellowButton>
        </div>
    </Section>
)
