import filter from 'lodash/filter'
import find from 'lodash/find'
import reduce from 'lodash/reduce'

const PRICE_FORMAT = {
    FLAT_FEE: 'FlatFee',
}

const PRICING_MODEL = {
    TIERED: 'Tiered',
    PER_UNIT: 'PerUnit',
    VOLUME: 'Volume',
    FLAT_FEE: 'FlatFee',
}

const tieredChargeModelPricingCalculator = ({ tiers, quantity }) =>
    reduce(
        filter(tiers, (tier) => quantity >= tier.startingUnit),
        (total, tier) => {
            if (tier.priceFormat === PRICE_FORMAT.FLAT_FEE || tier.priceFormat.name === PRICE_FORMAT.FLAT_FEE) {
                return total + tier.price
            }
            const startingUnits = quantity - tier.startingUnit
            const endingUnits = Math.max(quantity - (tier.endingUnit || quantity), 0)
            return total + tier.price * (startingUnits - endingUnits + 1)
        },
        0
    )

const perUnitChargeModelPricingCalculator = ({ price, quantity }) => price * quantity

const flatFeeChargeModelPricingCalculator = ({ price }) => price

const volumeChargeModelPricingCalculator = ({ tiers, quantity }) => {
    const tier = filter(tiers, (tier) => quantity >= tier.startingUnit)
    if (tier.length === 0) {
        return 0
    }
    const { priceFormat, price } = find(
        tiers,
        (t) => quantity >= t.startingUnit && quantity <= (t.endingUnit || quantity)
    )

    return priceFormat.name === PRICE_FORMAT.FLAT_FEE || priceFormat === PRICE_FORMAT.FLAT_FEE
        ? price
        : price * quantity
}

const zuoraChargeCalculatorFactory = (model) => {
    switch (model) {
        case PRICING_MODEL.TIERED:
            return (ratePlanCharge) => tieredChargeModelPricingCalculator(ratePlanCharge)
        case PRICING_MODEL.PER_UNIT:
            return (ratePlanCharge) => perUnitChargeModelPricingCalculator(ratePlanCharge)
        case PRICING_MODEL.FLAT_FEE:
            return (ratePlanCharge) => flatFeeChargeModelPricingCalculator(ratePlanCharge)
        case PRICING_MODEL.VOLUME:
            return (ratePlanCharge) => volumeChargeModelPricingCalculator(ratePlanCharge)
        default:
            throw Error(`Invalid PricingModel: ${model}`)
    }
}

export default zuoraChargeCalculatorFactory
