import { useCallback, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { alert, Alert, LegacyButton as Button, Loading as Spinner, Select } from '@energage/components'
import { Add, Close } from '@energage/icons'
import { spacing } from '@energage/theme'
import cx from 'clsx'
import { addDays, endOfDay, isSameDay, isWeekend, startOfDay, toDate } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import some from 'lodash/some'
import { useParams } from 'react-router-dom'
import type { StylesConfig } from 'react-select'
import { useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'
import { useWorkplaceSurveyQuery } from 'api'
import type { QueryConfig } from 'api'
import { Accordion } from 'components/Accordion'
import { useEvent, useVisibility } from 'hooks'
import { ReactComponent as TwpLogo } from 'images/twp-chevron-logo.svg'
import WorkplacesDeadlines from '../../AwardSelection/AwardSelectionTable/WorkplacesDeadlines'
import SectionTitle from '../SectionTitle'
import { AwardScheduleChangeConfirmation } from './AwardScheduleChangeConfirmation'
import type {
    AwardChangeList,
    AwardChangesView,
} from './AwardScheduleChangeConfirmation/AwardScheduleChangeConfirmation.types'
import { showIsTentativeLogo, showTwpLogo } from './calendarUtil'
import CalendarView from './CalendarView'
import EmailEvent from './EmailEvent'
import type { InvitationDate } from './scheduleReducer/scheduleReducer'
import SurveyInvitationOverlappingScheduleConfirmation from './SurveyInvitationOverlappingScheduleConfirmation'
import SurveyInvitationSendTimeConfirmation from './SurveyInvitationSendTimeConfirmation'
import useScheduleSurveyLaunch from './useScheduleSurveyLaunch'

type TimeZone = {
    id: string
    ianaKey: string
    microsoftKey: string
    displayName: string
}

const selectStyles: StylesConfig<TimeZone, false> = {
    container: () => ({ width: spacing(48), position: 'relative' }),
    control: () => ({ minHeight: 0 }),
}

const InvitationListContainer = styled.div.attrs({ className: 'relative' })<{ isLoading?: boolean }>`
    min-height: ${(props) => (props.isLoading ? '8rem' : 0)};
`

const LoadingContainer = styled.div.attrs({
    className: 'absolute inset-0 flex justify-center items-center pointer-events-none',
})`
    background-color: rgba(255, 255, 255, 0.7);
`

const Loading = styled(Spinner)`
    && {
        padding: 0;
    }
`

const WorkplacesDeadlinesStyle = styled.div.attrs({})`
    padding-top: 20px;
`

function usingRecommendedSendDates(selectedDates: InvitationDate[], holidays: Date[]) {
    return !some(
        selectedDates,
        ({ dateTime }) =>
            isWeekend(dateTime as Date) ||
            some(holidays, (holidayDate) => holidayDate.getTime() === new Date(dateTime as Date).setHours(0, 0, 0, 0))
    )
}

type ScheduledSurvey = {
    scheduledCloseDateTime: Date
    id: number
    launchDateTime: Date
    name: string
}

function getSurveyOverlap(
    selectedLaunchDate: InvitationDate,
    selectedCloseDate: InvitationDate,
    scheduledSurveys: ScheduledSurvey[],
    surveyEventId: string | number
) {
    if (!selectedLaunchDate || !selectedCloseDate) {
        return null
    }

    return find(
        scheduledSurveys,
        (scheduledSurvey) =>
            (selectedLaunchDate.dateTime as Date) <= scheduledSurvey?.scheduledCloseDateTime &&
            scheduledSurvey?.launchDateTime <= (selectedCloseDate.dateTime as Date) &&
            scheduledSurvey.id !== surveyEventId
    )
}

const RoundIndicator = ({ className }: { className?: string }) => (
    <div className={cx('rounded-full h-5 w-5 ml-2 mr-1', className)} />
)

type LegendItemProps = {
    indicator: ReactNode
    children: ReactNode
    title?: string
}

const LegendItem = ({ indicator, children, title }: LegendItemProps) => (
    <div title={title} className="flex-0 flex items-left justify-left text-xs mr-1">
        {indicator}
        {children}
    </div>
)

type CalendarLegendProps = {
    awardName: string
    twpAwardDeadlineDate: Date
    timeZone: TimeZone
    isTopWorkplaceParticipant: boolean
    isTentative: boolean
}

const CalendarLegend = ({
    awardName,
    twpAwardDeadlineDate,
    timeZone,
    isTopWorkplaceParticipant,
    isTentative,
}: CalendarLegendProps) => (
    <div className="flex mt-3">
        <div className="mt-3 grid grid-cols-2">
            <LegendItem indicator={<RoundIndicator className={'bg-green500 mb-4'} />} children={'Survey Window'} />

            {isTopWorkplaceParticipant && showTwpLogo(twpAwardDeadlineDate) && (
                <LegendItem
                    indicator={
                        <TwpLogo className="h-6 w-5 ml-2 mr-1 mb-4" title="Top Workplaces survey close deadline" />
                    }
                    title={
                        awardName +
                        ', ' +
                        format(utcToZonedTime(toDate(twpAwardDeadlineDate), timeZone?.ianaKey), 'MMMM d, yyyy', {
                            timeZone: timeZone?.ianaKey,
                        })
                    }
                    children={'Upcoming Top Workplace Deadline'}
                />
            )}

            <LegendItem
                indicator={<RoundIndicator className={'bg-blue200 border-2 border-blue500 mb-4'} />}
                children={'Reminder Email'}
            />

            {isTentative && (
                <LegendItem
                    indicator={<RoundIndicator className={'bg-purple100 mb-4'} />}
                    children={'Tentative Top Workplace Deadline'}
                />
            )}
        </div>
    </div>
)

function useFetchAwardChanges(
    surveyEventId: string | number,
    closeDate: Date,
    options: QueryConfig<AwardChangesView>,
    timeZone: { ianaKey: string }
) {
    const closeDateTime = closeDate && timeZone ? zonedTimeToUtc(closeDate, timeZone.ianaKey)?.toISOString() : null

    return useWorkplaceSurveyQuery<AwardChangesView>(
        ['award-changes', closeDate, surveyEventId],
        `/invitation/${surveyEventId}/awardyearchanges/${closeDateTime}`,
        options
    )
}

type ScheduleLaunchProps = {
    id: string
    match: { params: { surveyEventId: string } }
    surveyHasLaunched: boolean
    isComplete: boolean
}

function getRelativeToDateWord(scheduledCloseDateTime: InvitationDate, awardListDeadline: Date) {
    if (!scheduledCloseDateTime?.dateTime) {
        return null
    }

    const startOfDayCloseDate = startOfDay(scheduledCloseDateTime.dateTime)
    const startOfDayAwardListDeadline = startOfDay(awardListDeadline)

    if (startOfDayCloseDate < startOfDayAwardListDeadline) {
        return 'before'
    }

    if (isSameDay(startOfDayCloseDate, startOfDayAwardListDeadline)) {
        return 'on'
    }

    return 'after'
}

const ScheduleLaunch = ({ id, surveyHasLaunched, isComplete }: ScheduleLaunchProps) => {
    const { visible: isModalVisible, show: showModal, hide: hideModal } = useVisibility()
    const params = useParams<{ surveyEventId: string }>()
    const surveyEventId = Number(params.surveyEventId)

    const {
        isStepComplete,
        loading,
        invitationDates,
        scheduledCloseDateTime,
        earliestStartDateTime,
        holidays,
        timeZone,
        timeZones,
        addInvitationDate,
        removeInvitationDate,
        updateInvitationDate,
        updateScheduledCloseDateTime,
        updateLaunchDate,
        updateTimeZone,
        submit,
        isSubmitting,
        isSubmitDisabled,
        scheduledSurveys,
        isRushed,
        awardListDeadlineDateTimes,
        lastAvailableSurveyDateTime,
        awardListDeadline,
        surveyCloseTimePickerDisabled,
        isTopWorkplaceParticipant,
    } = useScheduleSurveyLaunch(surveyEventId, isComplete)

    const [overlappingSurvey, setOverlappingSurvey] = useState<ScheduledSurvey | null | undefined>(null)

    useEffect(() => {
        if (!loading && invitationDates.length > 0 && !overlappingSurvey) {
            setOverlappingSurvey(
                getSurveyOverlap(invitationDates[0], scheduledCloseDateTime, scheduledSurveys, surveyEventId)
            )
        }
    }, [invitationDates, loading, overlappingSurvey, scheduledCloseDateTime, scheduledSurveys, surveyEventId])

    const readOnly = surveyHasLaunched

    const awardChangeDialogState = useDialogState()
    const awardChangeQuery = useFetchAwardChanges(
        surveyEventId,
        scheduledCloseDateTime?.dateTime,
        {
            enabled: false,
        },
        timeZone
    )

    const isTentative = showIsTentativeLogo(awardListDeadlineDateTimes)

    const handleSubmitSansAwardCheck = useEvent(() => {
        const overlap = getSurveyOverlap(invitationDates[0], scheduledCloseDateTime, scheduledSurveys, surveyEventId)
        setOverlappingSurvey(overlap)
        if (usingRecommendedSendDates(invitationDates, holidays) && !overlap) {
            submit()
        } else {
            showModal()
        }
    })

    const handleSubmitClick = useEvent(async () => {
        try {
            const resp = await awardChangeQuery.refetch()
            if (resp.data?.awardChanges && resp.data.awardChanges.length > 0) {
                awardChangeDialogState.show()
                return
            }

            handleSubmitSansAwardCheck()
        } catch (e) {
            alert.danger(`We've encountered an error trying to submit your schedule`)
        }
    })

    const handleConfirmModal = () => {
        hideModal()
        submit()
    }

    const updateLaunchDateFromCalendar = useCallback(
        (date: Date) => {
            updateLaunchDate(date, false)
        },
        [updateLaunchDate]
    )

    const launchDate = invitationDates[0]
    const reminderDates = invitationDates.slice(1)

    const validDates = filter(
        [...invitationDates, scheduledCloseDateTime],
        (d) => d && !d.validationInfo.hasError && d.dateTime != null
    )

    const canAddReminder = new Date() < endOfDay(lastAvailableSurveyDateTime)

    const confirmAwardChanges = useCallback(() => {
        awardChangeDialogState.hide()
        handleSubmitSansAwardCheck()
    }, [awardChangeDialogState, handleSubmitSansAwardCheck])

    const relativeToDateWord = isRushed ? getRelativeToDateWord(scheduledCloseDateTime, awardListDeadline) : null

    return (
        <>
            <SurveyInvitationSendTimeConfirmation
                show={isModalVisible && !overlappingSurvey}
                onConfirm={handleConfirmModal}
                onCancel={hideModal}
            />
            <SurveyInvitationOverlappingScheduleConfirmation
                show={isModalVisible && !!overlappingSurvey}
                onConfirm={handleConfirmModal}
                onCancel={hideModal}
                overlappingSurvey={overlappingSurvey as ScheduledSurvey}
                timeZone={timeZone}
            />

            <AwardScheduleChangeConfirmation
                onConfirm={confirmAwardChanges}
                awards={(awardChangeQuery.data?.awardChanges ?? []) as AwardChangeList}
                firstDeadline={awardChangeQuery.data?.firstAwardDeadline}
                dialogState={awardChangeDialogState}
                surveys={scheduledSurveys}
            />
            <Accordion
                id={id}
                title={<SectionTitle title={'Survey Launch Schedule'} isStepComplete={isStepComplete} />}>
                <div data-testid="survey-setup-schedule-launch" className="flex">
                    {
                        <>
                            <div className="flex-1 pl-7">
                                <p className="mb-6">{`We research the optimal times for emails to be sent in order to optimize response rates, so all you need to do is select dates.`}</p>
                                {isRushed ? (
                                    <Alert
                                        variant={relativeToDateWord === 'after' ? 'danger' : 'warning'}
                                        className="paragraph-small w-full mb-4">
                                        {`The survey will close ${relativeToDateWord} the publisher award deadline day`}
                                    </Alert>
                                ) : null}
                                <InvitationListContainer isLoading={loading}>
                                    {launchDate && (
                                        <div className="mb-8">
                                            <EmailEvent
                                                title={'Survey Launch'}
                                                dateTime={launchDate.dateTime}
                                                readOnly={readOnly}
                                                minDate={earliestStartDateTime}
                                                maxDate={lastAvailableSurveyDateTime}
                                                holidays={holidays}
                                                validationInfo={launchDate.validationInfo} //will need this
                                                onDateChange={(launchDate: Date, isTimeChanged: boolean) =>
                                                    updateLaunchDate(launchDate, isTimeChanged)
                                                }>
                                                <div className="inline-block">
                                                    <Select
                                                        styles={selectStyles}
                                                        size="sm"
                                                        value={timeZone}
                                                        options={timeZones}
                                                        onChange={updateTimeZone}
                                                        getOptionLabel={(opt) => opt.displayName}
                                                        getOptionValue={(opt) => opt.id}
                                                        isDisabled={readOnly}
                                                    />
                                                </div>
                                            </EmailEvent>
                                            {scheduledCloseDateTime && (
                                                <EmailEvent
                                                    title={'Survey Close*'}
                                                    timeTitle={'Close Time'}
                                                    dateTime={scheduledCloseDateTime.dateTime}
                                                    readOnly={scheduledCloseDateTime.isLocked}
                                                    minDate={addDays(launchDate.dateTime, 1)}
                                                    maxDate={lastAvailableSurveyDateTime}
                                                    holidays={holidays}
                                                    validationInfo={scheduledCloseDateTime?.validationInfo}
                                                    timePickerDisabled={surveyCloseTimePickerDisabled}
                                                    onDateChange={(
                                                        scheduledCloseDateTime: Date,
                                                        isTimeChanged: boolean
                                                    ) =>
                                                        updateScheduledCloseDateTime(
                                                            scheduledCloseDateTime,
                                                            isTimeChanged
                                                        )
                                                    }
                                                />
                                            )}
                                            {map(reminderDates, (date, index) => (
                                                <EmailEvent
                                                    key={date.key}
                                                    title={`Reminder ${index + 1}`}
                                                    dateTime={date.dateTime}
                                                    readOnly={date.isLocked}
                                                    minDate={addDays(launchDate.dateTime, 1)}
                                                    maxDate={lastAvailableSurveyDateTime}
                                                    holidays={holidays}
                                                    validationInfo={date.validationInfo}
                                                    onDateChange={(date, isTimeChanged) =>
                                                        updateInvitationDate(index + 1, date, isTimeChanged)
                                                    }>
                                                    {!date.isLocked && (
                                                        <Button
                                                            variant="link"
                                                            className="hover:underline p-0 focus:underline focus:outline-none text-left"
                                                            onClick={() => removeInvitationDate(index + 1)}>
                                                            <div className="ml-1">
                                                                {`Remove`}
                                                                <Close size={22} className="ml-1" />
                                                            </div>
                                                        </Button>
                                                    )}
                                                </EmailEvent>
                                            ))}
                                        </div>
                                    )}
                                    {loading && (
                                        <LoadingContainer>
                                            <Loading text="Getting survey dates..." />
                                        </LoadingContainer>
                                    )}
                                </InvitationListContainer>
                                {!loading && canAddReminder && (
                                    <>
                                        {canAddReminder && (
                                            <div className="-mt-2">
                                                <Button
                                                    variant="link"
                                                    className="hover:underline p-0 focus:underline focus:outline-none text-left"
                                                    onClick={() => addInvitationDate(invitationDates.length, null)}>
                                                    <div>
                                                        {`Add a survey reminder`}
                                                        <Add />
                                                    </div>
                                                </Button>
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        <div className="inline-block">
                                            <Button
                                                disabled={isSubmitDisabled}
                                                variant="secondary"
                                                onClick={handleSubmitClick}>
                                                {isSubmitting
                                                    ? 'Confirming Survey Schedule...'
                                                    : 'Confirm Survey Schedule'}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {scheduledCloseDateTime && (
                                    <p className="paragraph-small mt-5">
                                        {`* Pending post-survey work, results become available 2-4 business days after close.`}
                                    </p>
                                )}
                                <WorkplacesDeadlinesStyle className="md:hidden">
                                    <WorkplacesDeadlines awardListDeadlineDateTimes={awardListDeadlineDateTimes} />
                                </WorkplacesDeadlinesStyle>
                            </div>
                            <div className="flex-col flex-1">
                                <div className="hidden md:flex flex-1 justify-center">
                                    <div>
                                        <CalendarView
                                            invitationDates={map(validDates, (i) => i.dateTime)}
                                            minDate={earliestStartDateTime}
                                            maxDate={lastAvailableSurveyDateTime}
                                            readOnly={readOnly}
                                            awardListDeadlineDateTimes={awardListDeadlineDateTimes}
                                            onSelectDay={updateLaunchDateFromCalendar}
                                        />
                                        {!readOnly && (
                                            <div className="text-center text-xs italic">
                                                {'Available dates are shown in '}
                                                <span className="ml-px font-bold not-italic">{'black'}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="hidden md:flex justify-center">
                                    <CalendarLegend
                                        awardName={
                                            awardListDeadlineDateTimes?.length > 0
                                                ? awardListDeadlineDateTimes[0].awardName
                                                : ''
                                        }
                                        timeZone={timeZone}
                                        twpAwardDeadlineDate={
                                            awardListDeadlineDateTimes?.length > 0
                                                ? awardListDeadlineDateTimes[0].internalScheduledCloseDate
                                                : null
                                        }
                                        isTopWorkplaceParticipant={isTopWorkplaceParticipant}
                                        isTentative={isTentative}
                                    />
                                </div>
                                <WorkplacesDeadlinesStyle className="hidden md:flex justify-center">
                                    <WorkplacesDeadlines awardListDeadlineDateTimes={awardListDeadlineDateTimes} />
                                </WorkplacesDeadlinesStyle>
                            </div>
                        </>
                    }
                </div>
            </Accordion>
        </>
    )
}

export default ScheduleLaunch
