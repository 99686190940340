import { colors } from '@energage/theme'
import { Tooltip } from 'components/Tooltip'

export function DemographicTooltip({ trigger, disabled }) {
    return (
        <Tooltip
            trigger={trigger}
            disabled={disabled}
            text="This survey has a question that depends on this demographic."
            tooltipColor={colors.grey600}
            textColor={colors.white}
            tooltipArrowColor={colors.grey500}
        />
    )
}
