import PropTypes from 'prop-types'
import { Tooltip } from '@energage/components'
import { HelpOutline } from '@energage/icons'
import { colors } from '@energage/theme'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import reject from 'lodash/reject'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import Inset from 'components/Inset'
import withClassName from 'style/util/withClassName'
import { changeType, getAddOns, getBaseProducts, isRemoved } from '../buildSubscriptions'

export const dateToZeroHoursInMilliSecond = (dateValue) =>
    !!dateValue && new Date(new Date(dateValue).setHours(0, 0, 0, 0)).getTime()

const Label = withClassName('rounded px-2 text-xs font-bold mr-2', 'span')

const getLabelForProduct = (lastChangeType) => {
    switch (lastChangeType) {
        case changeType.Remove:
            return <Label className="bg-yellow500">{'Cancelled'}</Label>
        case changeType.Add:
            return <Label className="bg-green500 text-white">{'Added'}</Label>
        default:
            return null
    }
}

const ProductList = ({ title, products, isExtraInfoVisible }) => (
    <>
        {size(products) > 0 && <p className="font-bold">{title}</p>}
        {map(products, (productItem, index) => {
            const { name, description, daysLeft, lastChangeType, isPremierSupport, ratePlanCharges } = productItem
            const productName = isPremierSupport ? ratePlanCharges[0].name : name
            return (
                <span className="flex items-center ml-4" key={index}>
                    {getLabelForProduct(lastChangeType)}
                    {productName}
                    {isExtraInfoVisible && !!daysLeft && <span className="ml-1">{`(${daysLeft} Days Left)`}</span>}
                    {description && (
                        <Tooltip
                            placement="right"
                            renderTrigger={({ ref, getTriggerProps }) => (
                                <span ref={ref} {...getTriggerProps()} className="flex align-middle">
                                    <HelpOutline className="ml-1" size={18} color={colors.blue600} />
                                </span>
                            )}>
                            <span className="paragraph-small max-w-xs flex justify-center content-center">
                                {description}
                            </span>
                        </Tooltip>
                    )}
                </span>
            )
        })}
    </>
)

const Products = ({ products, isExtraInfoVisible, termStartDate }) => {
    const uniqueProducts = filter(
        uniq(
            map(products, function (item) {
                if (filter(products, { id: item.id }).length > 1) {
                    return item.id
                }
            })
        ),
        function (value) {
            return value
        }
    )
    products = reject(
        products,
        ({ lastChangeType, id, isBaseProduct, effectiveEndDate }) =>
            (isRemoved(lastChangeType) && includes(uniqueProducts, id)) ||
            (!isBaseProduct &&
                dateToZeroHoursInMilliSecond(effectiveEndDate) <= dateToZeroHoursInMilliSecond(termStartDate))
    )
    const hasCancelledProduct = size(find(products, ({ lastChangeType }) => isRemoved(lastChangeType))) > 0
    return (
        <Inset className="border-t-2 sm:border-2 sm:rounded p-3 border-grey300">
            <ProductList
                title={'Products'}
                products={getBaseProducts(products)}
                isExtraInfoVisible={isExtraInfoVisible}
            />
            <ProductList
                title={'Addons'}
                products={uniqBy(getAddOns(products), 'id')}
                isExtraInfoVisible={isExtraInfoVisible}
            />
            {isExtraInfoVisible && hasCancelledProduct && (
                <p className="text-xs">
                    {'* Cancelled products/addons will still be available until the end of the billing cycle'}
                </p>
            )}
        </Inset>
    )
}

Products.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            cancelled: PropTypes.bool,
            days: PropTypes.number,
        })
    ).isRequired,
    isExtraInfoVisible: PropTypes.bool.isRequired,
}

export default Products
