import PropTypes from 'prop-types'
import { alert, LegacyButton as Button, Skeleton } from '@energage/components'
import loadable from '@loadable/component'
import { useAtomValue } from 'jotai/utils'
import filter from 'lodash/filter'
import map from 'lodash/map'
import partition from 'lodash/partition'
import some from 'lodash/some'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity, usePermissions } from 'components/Identity'
import { IncludedItemsHeader, NonExpandableSection, OptionalItemsHeader } from '../fragments'
import QuestionCategory from '../QuestionCategory'
import { DemographicQuestionTable } from '../QuestionTable/DemographicQuestionTable'
import OptionalItemTable, { OptionalItemTableRow } from '../QuestionTable/OptionalItemTable'
import { TableRow } from '../QuestionTable/TableElements'
import { useDemographicQuestionToggles } from '../statementSetupHooks/useDemographicQuestionToggles'
import { allQuestionsAtom } from '../statementSetupUtility/store'
import useDemographicQuestionCreation from '../useDemographicQuestionCreation'
import { DemographicSectionProvider } from './DemographicSectionContext'
import { useDemographicSubsection } from './useDemographicSubsection'
import {
    useDemographicQuestionBankQuestionsCacheInvalidate,
    useGetDemographicQuestionBankQuestions,
} from './useGetDemographicQuestionBankQuestions'

const AddPreviouslyUsedDemographicModal = loadable(async () => {
    const modalImport = await import('../AddPreviouslyUsedDemographicModal')
    return { default: modalImport.AddPreviouslyUsedDemographicModal }
})

const DemographicCategorySection = ({
    subsectionId,
    evaluateQuestionSelected,
    evaluateQuestionParentSelected,
    surveyEventId,
    onUpdateQuestions,
    addSelectedDemographic,
    disabled,
    isEditable,
    className,
}) => {
    const { surveyCompanyId } = useIdentity()
    const invalidateDemographicQb = useDemographicQuestionBankQuestionsCacheInvalidate(surveyCompanyId, surveyEventId)
    const demographicSubsectionStore = useDemographicSubsection(subsectionId)
    const [, updateSubsection] = demographicSubsectionStore

    const {
        demographicQuestions,
        handleAddDemographicQuestion,
        handleDemographicQuestionTextChange,
        handleDeleteDemographicQuestion,
        onDemographicQuestionSave,
        onDemographicQuestionUpdate,
        changeQuestionId,
        editingQuestionId,
        deleteDemographicQuestionState,
        validateDuplicateDemographicType,
        savedQuestion,
    } = useDemographicQuestionCreation(
        surveyEventId,
        demographicSubsectionStore,
        evaluateQuestionSelected,
        evaluateQuestionParentSelected
    )

    function handleQuestionUpdate(updates) {
        updateSubsection((ss) => ({
            ...ss,
            questions: map(ss.questions, (q) => {
                if (updates.hasOwnProperty(q.questionEssenceId)) {
                    return { ...q, isSelected: updates[q.questionEssenceId], isHighlighted: true }
                }
                return q.isHighlighted ? { ...q, isHighlighted: false } : q
            }),
        }))
        onUpdateQuestions()
    }

    const allQuestions = useAtomValue(allQuestionsAtom)
    const { handleToggleQuestion } = useDemographicQuestionToggles(
        demographicQuestions,
        allQuestions,
        handleQuestionUpdate
    )
    const includeQuestion = (questionId) => {
        if (editingQuestionId >= 0) {
            handleDeleteDemographicQuestion(editingQuestionId, true)
        }
        handleToggleQuestion(questionId, true)
    }

    const { mutateAsync: submitDemographicQuestion, ...state } = useWorkplaceSurveyQuery.mutate(
        `demographicquestion/${surveyEventId}/save`,
        {
            onSuccess: (updatedQuestion, questionSaved) => {
                const newQuestion = { ...questionSaved, ...updatedQuestion }
                newQuestion.name = questionSaved?.demographicType?.name
                if (!questionSaved.questionEssenceId) {
                    addSelectedDemographic(newQuestion, subsectionId)
                    onDemographicQuestionSave(newQuestion)
                } else {
                    onDemographicQuestionUpdate(updatedQuestion, questionSaved)
                }

                invalidateDemographicQb()
            },
            onError: () => {
                alert.danger('There was an error submitting your question')
            },
        }
    )

    const { hasSmartPulse, hasInsights } = usePermissions()
    const isPurchaser = hasInsights || hasSmartPulse

    const { data, isLoading } = useGetDemographicQuestionBankQuestions(surveyCompanyId, surveyEventId, {
        enabled: isEditable && isPurchaser,
    })

    const isSavingDemographicQuestion = state.status === 'loading' ?? false
    const isDeletingDemographicQuestion = deleteDemographicQuestionState?.status === 'loading' ?? false
    const isPosting = isSavingDemographicQuestion || isDeletingDemographicQuestion

    const [selectedQuestions, nonSelectedQuestions] = partition(demographicQuestions, (q) => q.isSelected)
    const selectedCustomDemographics = filter(selectedQuestions, (q) => !q.isTemplateDemographicQuestion)

    const templateHasOptionalItems = some(
        demographicQuestions,
        (q) => !q.isSelectedByDefault && q.isTemplateDemographicQuestion
    )
    const hasOptionalItems = nonSelectedQuestions.length > 0
    const showOptionalItemsTable = hasOptionalItems || templateHasOptionalItems
    return (
        <div className={className}>
            {templateHasOptionalItems && <IncludedItemsHeader />}
            <DemographicSectionProvider>
                <DemographicQuestionTable
                    title="Question"
                    onUpdateQuestions={handleQuestionUpdate}
                    demographicQuestions={selectedQuestions}
                    disabled={disabled}
                    handleDemographicQuestionTextChange={handleDemographicQuestionTextChange}
                    handleDeleteDemographicQuestion={handleDeleteDemographicQuestion}
                    handleSaveDemographicQuestion={submitDemographicQuestion}
                    editingQuestionId={editingQuestionId}
                    isEditable={isEditable}
                    isPosting={isPosting}
                    setEditingQuestionId={changeQuestionId}
                    validateDuplicateDemographicType={validateDuplicateDemographicType}
                    hasOptionalItems={templateHasOptionalItems}
                    isPurchaser={isPurchaser}
                    savedQuestion={savedQuestion}
                />
                <div className="float-right">
                    {isEditable && isPurchaser && (
                        <div className="flex ml-auto pb-2">
                            {data?.questions.length > 0 ? (
                                <AddPreviouslyUsedDemographicModal
                                    surveyEventId={surveyEventId}
                                    previouslyAddedQuestions={selectedCustomDemographics}
                                    questionBankQuestions={data?.questions}
                                    themes={data?.themes}
                                    isLoading={isLoading}
                                    disabled={isPosting}
                                />
                            ) : null}
                            <Button
                                className="mt-2 text-xs mb-6 sm:mb-0 py-2 ml-2 leading-none"
                                variant="secondary"
                                onClick={() => handleAddDemographicQuestion()}
                                outline
                                disabled={editingQuestionId !== undefined || isPosting}>
                                {'Add New Demographic'}
                            </Button>
                        </div>
                    )}
                </div>
                {showOptionalItemsTable && (
                    <>
                        <OptionalItemsHeader className={isEditable ? 'pt-4' : ''} />
                        <OptionalItemTable
                            categoryId={QuestionCategory.Demographic}
                            disabled={disabled}
                            questionColumnName="Question">
                            {templateHasOptionalItems && !hasOptionalItems && (
                                <TableRow>{`All optional items are included in your survey.`}</TableRow>
                            )}
                            {map(nonSelectedQuestions, (q) => (
                                <OptionalItemTableRow
                                    key={`${q.questionEssenceId}-${q.text}nonselected`}
                                    includeQuestion={includeQuestion}
                                    questionId={q.questionEssenceId ?? 0}
                                    name={q.name}
                                    text={q.text}
                                    disabled={disabled}
                                />
                            ))}
                        </OptionalItemTable>
                    </>
                )}
            </DemographicSectionProvider>
        </div>
    )
}

DemographicCategorySection.propTypes = {
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

function QuestionCategorySectionSkeleton() {
    return (
        <NonExpandableSection
            title={<Skeleton.HeaderShape className="w-64 pl-6" />}
            text={<Skeleton.ParagraphShape rows={5} className="pl-5" />}
        />
    )
}

DemographicCategorySection.Skeleton = QuestionCategorySectionSkeleton

export default DemographicCategorySection
