import { ButtonLink as Button } from '@energage/components'

export const SummaryButton = ({
    buttonText,
    className = '',
    tileUrl,
    onPress,
}: {
    buttonText: string
    className?: string
    tileUrl?: string
    onPress?: () => void
}) => {
    return (
        <div className={className}>
            <Button className="text-center inline-block" appearance="secondary" href={tileUrl} onPress={onPress}>
                {buttonText}
            </Button>
        </div>
    )
}
