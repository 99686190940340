import differenceInDays from 'date-fns/differenceInDays'
import filter from 'lodash/filter'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import reduce from 'lodash/reduce'
import sumBy from 'lodash/sumBy'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import {
    amendmentOrderStatus,
    getPercentageOfNumber,
    isAmendmentFailed,
    isConfirmed,
    isPublished,
    PREMIER_SUPPORT_PRODUCT_NAME,
} from 'containers/Main/OrderManagement/common/utils'
import generateObject from 'util/generateObject'

export const changeType = {
    Add: 0,
    Update: 1,
    Remove: 2,
}

export const getBaseProducts = (products) =>
    filter(products, ({ isBaseProduct, isPremierSupport }) => isBaseProduct || isPremierSupport)
export const getRecurringChargeProducts = (products) => filter(products, (p) => p.hasRecurringCharge)
export const getAddOns = (products) => filter(products, (p) => !p.isBaseProduct && !p.isPremierSupport)
export const isRemoved = (lastChangeType) => lastChangeType === changeType.Remove

const getDaysLeft = ({ lastChangeType, effectiveEndDate }) =>
    isRemoved(lastChangeType) && differenceInDays(new Date(effectiveEndDate), new Date())

const filterRemoveProducts = (products) => filter(products, ({ lastChangeType }) => !isRemoved(lastChangeType))

const getIsPremierSupport = (name) => name === PREMIER_SUPPORT_PRODUCT_NAME

const PRODUCT_ATTRIBUTES = {
    id: get('productId'),
    name: get('name'),
    effectiveStartDate: get('effectiveStartDate'),
    description: get('description'),
    quantity: get('quantity'),
    isBaseProduct: get('isBaseProduct'),
    lastChangeType: get('lastChangeType'),
    effectiveEndDate: get('effectiveEndDate'),
    hasRecurringCharge: get('hasRecurringCharge'),
    daysLeft: getDaysLeft,
    isPremierSupport: pipe([get('name'), getIsPremierSupport]),
    ratePlanCharges: get('ratePlanCharges'),
}

const buildProduct = (addOn) => generateObject(PRODUCT_ATTRIBUTES, addOn)
const buildProducts = (addOns) => map(addOns, buildProduct)

const calculateProductsTotalCost = (products) =>
    reduce(
        products,
        (total, product) => {
            const { price, ratePlanCharges } = product
            const totalPriceIncreasePercentage = sumBy(ratePlanCharges, ({ priceIncreasePercentage }) =>
                priceIncreasePercentage ? priceIncreasePercentage : 0
            )
            const totalCost =
                total +
                (totalPriceIncreasePercentage
                    ? getPercentageOfNumber(totalPriceIncreasePercentage, price) + price
                    : price)
            return totalCost
        },
        0
    )

const getMaxQuantity = (products) => maxBy(products, 'quantity')?.quantity

const isExpired = (amendmentOrder) => {
    if (!amendmentOrder) {
        return false
    }
    const { status, isExpired } = amendmentOrder
    return status === amendmentOrderStatus.Expired.value || isExpired
}

const SUBSCRIPTION_ATTRIBUTES = {
    subscriptionId: get('subscriptionId'),
    subscriptionNumber: get('subscriptionNumber'),
    termStartDate: get('termStartDate'),
    termEndDate: get('termEndDate'),
    autoRenew: get('autoRenew'),
    quantity: pipe([get('products'), getRecurringChargeProducts, getBaseProducts, getMaxQuantity]),
    price: pipe([get('products'), getRecurringChargeProducts, filterRemoveProducts, calculateProductsTotalCost]),
    products: pipe([get('products'), buildProducts]),
    isPublished: pipe([get('amendmentOrder.status'), isPublished]),
    isConfirmed: pipe([get('amendmentOrder.status'), isConfirmed]),
    amendmentCreateDate: get('amendmentCreateDate'),
    hasPastAmendment: get('hasPastAmendment'),
    isAmendmentFailed: pipe([get('amendmentOrder.status'), isAmendmentFailed]),
    amendmentOrderId: get('amendmentOrder.id'),
    isExpired: pipe([get('amendmentOrder'), isExpired]),
}

const buildSubscription = (subscription) => generateObject(SUBSCRIPTION_ATTRIBUTES, subscription)
const buildSubscriptions = (subscriptions) => map(subscriptions, buildSubscription)

export default buildSubscriptions
