import type { ReactNode } from 'react'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'
import { CULTURE_FACTS_TILE } from 'containers/Main/EmployerBranding/CultureFacts/constants'
import { CultureStatsGraphicType } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

export interface CultureStatsRectangleProps {
    children: ReactNode
    className?: string
    backgroundColor?: string
    highlightColor?: string
    textColor?: string
    selectedRectangleDesignType: number
    focusWord?: string
    cultureStatTileHeight: string
}

type RectangleHeightContainerProps = Pick<CultureStatsRectangleProps, 'cultureStatTileHeight'>

const RectangleHeightContainer = styled.div`
    ${(props: RectangleHeightContainerProps) =>
        props.cultureStatTileHeight && `height: ${props.cultureStatTileHeight};`}
`

interface RectangleContainerProps {
    backgroundColor?: string
    isRectangleBorderDesign: boolean
}

const RectangleContainer = styled.div.attrs((props: RectangleContainerProps) => ({
    className: cx(props.isRectangleBorderDesign ? 'items-center' : 'items-start'),
}))`
    background-color: ${(props: RectangleContainerProps) =>
        props.backgroundColor ? props.backgroundColor : '#404ddc'};
    padding-top: 80px;
`

interface RectangleBorderProps {
    highlightColor?: string
}

const RectangleBorder = styled.div`
    border: 6px solid ${(props: RectangleBorderProps) => (props.highlightColor ? `${props.highlightColor}` : '')};
    height: 340px;
    left: 75px;
    right: 75px;
    top: 50%;
    transform: translateY(-50%);
`

interface RectangleTitleWordContainerProps {
    backgroundColor?: string
    isRectangleBorderDesign: boolean
    isSingleTitleWord: boolean
}

const RectangleTitleWordContainer = styled.div.attrs((props: RectangleTitleWordContainerProps) => ({
    className: cx(
        'flex flex-col flex-wrap justify-center py-2 relative',
        props.isRectangleBorderDesign ? 'items-center' : 'items-start'
    ),
}))`
    background-color: ${(props: RectangleTitleWordContainerProps) =>
        props.backgroundColor ? props.backgroundColor : '#404ddc'};
    font-family: 'Almarai', 'Roboto', sans-serif;
    min-height: 60px;
    min-width: ${(props) => (props.isRectangleBorderDesign ? CULTURE_FACTS_TILE.PREVIEW_WIDTH : '100%')};
`

interface RectangleTitleWordProps {
    isRectangleBorderDesign?: boolean
    textColor?: string
    highlightColor?: string
}

const RectangleTitleWord = styled.p`
    font-size: 40px;
    font-weight: 800;
    line-height: ${(props: RectangleTitleWordProps) => (props.isRectangleBorderDesign ? '1.1' : '1.3')};
    ${(props) => (props.isRectangleBorderDesign ? '' : 'margin-bottom: 5px;')}
    padding: ${(props: RectangleTitleWordProps) => (props.isRectangleBorderDesign ? '0 30px' : '0 10px')};
    ${(props: RectangleTitleWordProps) => (props.highlightColor ? `background-color: ${props.highlightColor};` : '')}
    color: ${(props: RectangleTitleWordProps) => (props.textColor ? `${props.textColor};` : colors.white)}
`

interface TopWorkPlaceImageProps {
    isRectangleBorderDesign: boolean
}

const TopWorkPlaceImage = styled.img`
    ${(props: TopWorkPlaceImageProps) =>
        props.isRectangleBorderDesign
            ? 'height: 70px; width: 70px; left: 50%; transform: translate(-50%, -50%); top: 50px;'
            : 'height: 80px; width: 80px; right: 20px; top: 16px;'}
`

export const CultureStatsRectangleView = ({
    children,
    backgroundColor,
    highlightColor,
    textColor,
    selectedRectangleDesignType,
    focusWord = '',
    cultureStatTileHeight,
}: CultureStatsRectangleProps) => {
    const isRectangleBorderDesign = selectedRectangleDesignType === CultureStatsGraphicType.RectangleBorderDesign
    const titleWords = focusWord.length <= 10 ? [focusWord] : focusWord.split(' ')
    const isSingleTitleWord = titleWords.length === 1

    return (
        <RectangleHeightContainer
            className="flex flex-col justify-center"
            cultureStatTileHeight={cultureStatTileHeight}>
            <RectangleContainer
                className="flex flex-col mx-12"
                backgroundColor={backgroundColor}
                isRectangleBorderDesign={isRectangleBorderDesign}>
                <TopWorkPlaceImage
                    className="absolute rounded-full z-10"
                    isRectangleBorderDesign={isRectangleBorderDesign}
                    src={CultureFactTWP}
                    alt="Top work places"
                />
                {isRectangleBorderDesign && <RectangleBorder className="absolute" highlightColor={highlightColor} />}
                <RectangleTitleWordContainer
                    backgroundColor={backgroundColor}
                    isRectangleBorderDesign={isRectangleBorderDesign}
                    isSingleTitleWord={isSingleTitleWord}>
                    {titleWords.map((title: string, key: number) => (
                        <div key={key}>
                            <RectangleTitleWord
                                className="uppercase"
                                isRectangleBorderDesign={isRectangleBorderDesign}
                                highlightColor={isRectangleBorderDesign ? backgroundColor : highlightColor}
                                textColor={textColor}>
                                {title}
                            </RectangleTitleWord>
                        </div>
                    ))}
                </RectangleTitleWordContainer>
                {children}
            </RectangleContainer>
        </RectangleHeightContainer>
    )
}
