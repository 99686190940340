export default function safeInvoke(invokee, ...args) {
    if (typeof invokee === 'function') {
        return invokee(...args)
    }

    return invokee
}

function curriedSafeInvoke(...args) {
    return function (invokee) {
        return safeInvoke(invokee, ...args)
    }
}
export { curriedSafeInvoke as safeInvokeFp }
