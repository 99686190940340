import PropTypes from 'prop-types'
import { SUPPORT_EMAIL } from 'constants/strings'
import SurveyFormats from '../../Recipients/FormatSurvey/SurveyFormats'
import {
    RandomSampleCountMismatch,
    RandomSampleHelpCenterLink,
    randomSampleMinimumRequirementNotMet,
    RandomSampleNotAnOption,
} from '../../Recipients/SurveyScope'
import { ErrorRecipientsSet } from './Components/ErrorRecipientsSet'
import { NotEnoughRecipients } from './Components/NotEnoughRecipients'
import { NotTWPUsaQualified } from './Components/NotTWPUsaQualified'
import { RecipientsCompleted } from './Components/RecipientsCompleted'

const RecipientsTile = ({
    surveyFormat,
    inviteeCount,
    paperInviteeCount,
    error,
    surveyEventId,
    linkInviteeCount,
    hasSmsConsent,
    emailOnlyInviteeCount,
    emailAndSmsInviteeCount,
    smsOnlyInviteeCount,
    responderCountSummaryThreshold,
    hasConfirmedRecipients,
    hasIncompleteSmsSetup,
    isRandomSample,
    hasAllLocationsSurveyed,
    hasNationalAward,
    minimumInviteeCountForRandomSampling,
    regionsConfirmedEmployeeTotal,
    isSurveyScopeTaskRelevant,
}) => {
    if (error) {
        return <ErrorRecipientsSet />
    }
    const showManageRecipients = surveyFormat.id !== SurveyFormats.Links

    if (inviteeCount === 0) {
        return (
            <NotEnoughRecipients
                surveyEventId={surveyEventId}
                showManageRecipients={showManageRecipients}
                message={
                    surveyFormat.id === SurveyFormats.Links
                        ? `This survey is using printable links. To add recipients to this survey, please contact ${SUPPORT_EMAIL}.`
                        : "This survey doesn't have any recipients. You must add recipients in order to send your survey."
                }
                title="This survey doesn't have any recipients"
            />
        )
    }

    if (inviteeCount > 0 && inviteeCount < responderCountSummaryThreshold) {
        return (
            <NotEnoughRecipients
                surveyEventId={surveyEventId}
                showManageRecipients={showManageRecipients}
                message={`Please include at least ${responderCountSummaryThreshold} recipients. This is the minimum number of responders required for survey results.`}
                title="This survey doesn't have enough recipients"
            />
        )
    }

    if (inviteeCount >= responderCountSummaryThreshold && !hasConfirmedRecipients) {
        return (
            <NotEnoughRecipients
                surveyEventId={surveyEventId}
                showManageRecipients={showManageRecipients}
                message="Please review and confirm your survey recipients."
                title="Recipients have not been confirmed"
            />
        )
    }

    if (hasIncompleteSmsSetup) {
        return (
            <NotEnoughRecipients
                surveyEventId={surveyEventId}
                showManageRecipients={showManageRecipients}
                message="In order for SMS invitations to be sent, you must add a message to your SMS invitation."
                title="SMS message has not been completed"
            />
        )
    }

    if (isSurveyScopeTaskRelevant) {
        if (hasNationalAward && hasAllLocationsSurveyed === false) {
            return <NotTWPUsaQualified surveyEventId={surveyEventId} inviteeCount={inviteeCount} />
        }

        if (hasAllLocationsSurveyed === null) {
            return (
                <NotEnoughRecipients
                    surveyEventId={surveyEventId}
                    showManageRecipients={showManageRecipients}
                    message="Please indicate if this survey covers your entire employee population."
                    title="You haven't selected who you are surveying."
                />
            )
        }

        if (isRandomSample && regionsConfirmedEmployeeTotal > 0) {
            if (regionsConfirmedEmployeeTotal < 1000) {
                return (
                    <NotEnoughRecipients
                        surveyEventId={surveyEventId}
                        showManageRecipients={showManageRecipients}
                        message={`${RandomSampleNotAnOption}.`}
                        title="Random sampling requirements are not met"
                    />
                )
            }

            if (inviteeCount < minimumInviteeCountForRandomSampling) {
                return (
                    <NotEnoughRecipients
                        surveyEventId={surveyEventId}
                        showManageRecipients={showManageRecipients}
                        message={` ${randomSampleMinimumRequirementNotMet(minimumInviteeCountForRandomSampling)}.`}
                        title="This survey doesn't have enough recipients"
                    />
                )
            }
            if (regionsConfirmedEmployeeTotal <= inviteeCount) {
                const message = (
                    <>
                        {`${RandomSampleCountMismatch}. `}
                        <RandomSampleHelpCenterLink linkText="Learn more about random sampling." />
                    </>
                )
                return (
                    <NotEnoughRecipients
                        surveyEventId={surveyEventId}
                        showManageRecipients={showManageRecipients}
                        message={message}
                        title="Random sampling requirements are not met"
                    />
                )
            }
        }
    }

    return (
        <RecipientsCompleted
            inviteeCount={inviteeCount}
            surveyFormat={surveyFormat}
            paperInviteeCount={paperInviteeCount}
            emailOnlyInviteeCount={emailOnlyInviteeCount}
            emailAndSmsInviteeCount={emailAndSmsInviteeCount}
            smsOnlyInviteeCount={smsOnlyInviteeCount}
            linkInviteeCount={linkInviteeCount}
            hasSmsConsent={hasSmsConsent}
            surveyEventId={surveyEventId}
        />
    )
}

RecipientsTile.propTypes = {
    inviteeCount: PropTypes.number.isRequired,
    surveyFormat: PropTypes.object.isRequired,
    paperInviteeCount: PropTypes.number.isRequired,
    error: PropTypes.bool,
    surveyEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    linkInviteeCount: PropTypes.number,
    hasSmsConsent: PropTypes.bool,
    emailOnlyInviteeCount: PropTypes.number.isRequired,
    emailAndSmsInviteeCount: PropTypes.number.isRequired,
    smsOnlyInviteeCount: PropTypes.number.isRequired,
    responderCountSummaryThreshold: PropTypes.number.isRequired,
    hasConfirmedRecipients: PropTypes.bool.isRequired,
    hasIncompleteSmsSetup: PropTypes.bool.isRequired,
}

export default RecipientsTile
