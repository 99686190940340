import { useMediaQuery } from '@energage/hooks'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { viewports } from 'style/breakpoints'
import withClassName from 'style/util/withClassName'
import Section from '../../Section'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Subtitle = withClassName('sm:text-lg md:text-xl', 'h2', 'subtitle')
const Text = withClassName('sm:text-lg md:text-xl py-5', 'p', 'text')

const Congratulations = ({
    bgColor,
    textColor,
    companyName,
    awards,
    year,
    isPdf,
    sectionClassName,
    showNationalStandardDetails,
    isNationalStandardAwardId,
    regionName,
    isDallasRankedWinner,
    showDallasNationalStandardDetails,
}) => {
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMax })
    const AwardsComponent = isMobileDevice && !isPdf ? MobileView : DesktopView
    const {
        regional,
        national: { cultureExcellence, twpUsa, industry },
    } = awards
    const regionalAwards = filter(regional, (x) => x.svgUrl !== '')
    const { true: ceWonAwards, false: ceAspiringAwards } = groupBy(cultureExcellence, (x) => !!x.isTopWorkplace)

    return (
        <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName} isPdf={isPdf}>
            <p className="text-5xl sm:text-6xl">{'Congratulations'}</p>
            <h1 className="text-3xl sm:text-5xl sm:mt-0 mt-4 font-black">
                {`${companyName} is a ${year} Top Workplaces ${
                    showNationalStandardDetails ? 'National Standard' : ''
                } winner${showNationalStandardDetails ? ' in ' + regionName : ''}!`}
            </h1>
            <AwardsComponent
                twpUsaAward={twpUsa}
                regionalAwards={orderBy(regionalAwards, 'isTopWorkplace', 'desc')}
                ceWonAwards={ceWonAwards}
                ceAspiringAwards={ceAspiringAwards}
                showNationalStandardDetails={showNationalStandardDetails}
                isDallasRankedWinner={isDallasRankedWinner}
                showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                industryAward={industry}
            />
            <div>
                <Subtitle className="mt-8 sm:mt-16 md:mt-32">{"You've earned the coveted gold badge!"}</Subtitle>
                <Text>{`Based entirely on the employee feedback captured with the Workplace Survey, your Top Workplaces win is proof positive that ${companyName} puts its people first.`}</Text>
                <Subtitle className="pt-4 sm:pt-10">{'Share this victory with senior leadership.'}</Subtitle>
                <Text>
                    {`It's a big deal, so make sure you share this news with senior leaders. For now, we ask that you keep your Top Workplaces win limited to this small group. But as soon as the awards list has been published, you can tell your employees – and the whole world – you're a Top Workplaces winner! See below for the timeline of events.`}
                </Text>
                <Subtitle className="pt-4 sm:pt-10">{`Put your Top Workplaces award to work for ${companyName}.`}</Subtitle>
                <Text className="mb-4">
                    {`Being a Top Workplaces winner can give your organization a competitive advantage. It can help you to attract the right talent and get the attention of customers. So, let's get ready to promote your award and showcase what makes your culture so special. Here are some ways to do that.`}
                </Text>
            </div>
            {(showDallasNationalStandardDetails || !isDallasRankedWinner) && !isNationalStandardAwardId && (
                <>
                    <div className="mt-24">
                        <h1 className="sm:text-5xl text-2xl font-black">{"Here's what to do next.​"}</h1>
                        <Text>
                            {
                                'Start by getting the Top Workplaces Media Kit that includes materials for promoting your award.​'
                            }
                        </Text>
                    </div>
                </>
            )}
        </Section>
    )
}

export default Congratulations
