/* eslint-disable @typescript-eslint/no-explicit-any */
import { screens } from '@energage/theme'
import mapValues from 'lodash/mapValues'
import add from 'lodash/fp/add'
import eq from 'lodash/fp/eq'
import findIndex from 'lodash/fp/findIndex'
import get from 'lodash/fp/get'
import initial from 'lodash/fp/initial'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import reduce from 'lodash/fp/reduce'
import sortBy from 'lodash/fp/sortBy'
import tail from 'lodash/fp/tail'
import toPairs from 'lodash/fp/toPairs'
import { css } from 'styled-components'

const screenSizes = mapValues(screens, (width) => +width.replace('px', ''))

const unshift = (val: string) => (arr: any[]) => [val, ...arr]
const getFrom =
    <TObject extends object, TKey extends keyof TObject>(collection: TObject) =>
    (key: TKey): TObject[TKey] =>
        get(key, collection)

const pairedScreens = toPairs(screenSizes) as [keyof typeof screenSizes, number][]
const sorter = sortBy<[keyof typeof screenSizes, number]>(get(1))
const mapper = map<[keyof typeof screenSizes, number], string>(get(0))
const sortedScreens = pipe(sorter, mapper, unshift('xs'))(pairedScreens) as ['xs', 'sm', 'md', 'lg', 'xl']

type BreakPointGetter = (key: keyof typeof screenSizes) => number

const addBreakpoint =
    (suffix: 'Min' | 'Max', getBreakpointValue: BreakPointGetter) =>
    (breakpoints: Record<string, number>, key: keyof typeof screenSizes) => ({
        ...breakpoints,
        [key + suffix]: getBreakpointValue(key),
    })

const getMinimumBreakpointWidth = getFrom(screenSizes)
const getMaximumBreakpointWidth = (key: keyof typeof screenSizes) =>
    pipe(findIndex(eq(key)), add(1), getFrom(sortedScreens), getFrom(screenSizes), add(-1))(sortedScreens)

type Map<collection extends string, viewport extends 'Min' | 'Max'> = `${collection}${viewport}`

type ViewportMinimums = Map<typeof sortedScreens[number], 'Min'>
type ViewportMaximums = Map<typeof sortedScreens[number], 'Max'>

const viewportMinimums = pipe(
    tail,
    reduce(addBreakpoint('Min', getMinimumBreakpointWidth), {})
)(sortedScreens) as Record<ViewportMinimums, number>

const viewportMaximums = pipe(
    initial,
    reduce(addBreakpoint('Max', getMaximumBreakpointWidth), {})
)(sortedScreens) as Record<ViewportMaximums, number>

export const viewports = {
    ...viewportMinimums,
    ...viewportMaximums,
}

type CssFunc = (...args: any[]) => ReturnType<typeof css>

function createMediaQueryTemplates(sizes: typeof viewportMinimums, property: 'min-width') {
    const sizeKeys = Object.keys(sizes) as ViewportMinimums[]

    return reduce((templates: Record<ViewportMinimums, CssFunc>, size: ViewportMinimums) => {
        templates[size] = (first: any, ...args: any[]) => {
            return css`
                @media (${property}: ${sizes[size]}px) {
                    ${css(first, ...args)}
                }
            `
        }

        return templates
    }, {} as Record<ViewportMinimums, CssFunc>)(sizeKeys)
}

export const media = createMediaQueryTemplates(viewportMinimums, 'min-width')
