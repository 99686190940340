import { LegacyButton as Button, Modal, Toggle } from '@energage/components'
import { useDialogState } from 'reakit/Dialog'
import { useIdentity } from 'components/Identity'
import type { MfaConnectionStatus } from './models'
import { useTurnOnMfaConnection } from './useSecuritySettingsQueries'

export const MfaSettings = ({ ssoEnabled, mfaEnabled }: { ssoEnabled: boolean; mfaEnabled: boolean }) => {
    const { surveyCompanyId } = useIdentity()
    const modalState = useDialogState()
    const { mutateAsync: turnOnMfaConnection } = useTurnOnMfaConnection(surveyCompanyId)

    const openMfaConfirmationModal = async () => {
        modalState.show()
    }

    const onMfaToggleChange = async (enabled: boolean) => {
        const mfaChangeStatusRequest: MfaConnectionStatus = {
            enabled,
        }
        await turnOnMfaConnection(mfaChangeStatusRequest)
    }

    const onChange = async () => {
        await onMfaToggleChange(!mfaEnabled)
        modalState.hide()
    }

    const onCancel = () => {
        modalState.hide()
    }

    return (
        <>
            <h5 className="pb-4 pt-8 text-primary-dark">{'Multi-factor Authentication'}</h5>
            <div id={'mfa-form'} className="flex flex-row pb-4">
                <Toggle
                    size="md"
                    id="mfa-toggle"
                    onContent="ON"
                    offContent="OFF"
                    checked={mfaEnabled}
                    disabled={ssoEnabled}
                    onChange={mfaEnabled ? onChange : openMfaConfirmationModal}
                />
                <Modal
                    className="settings-drawer__confirmation-modal"
                    {...modalState}
                    modalAriaLabel="Confirm if you want to turn on multi factor authentication">
                    <Modal.Header hasDivider={true} onHide={modalState.hide}>
                        <p className="font-medium text-primary-dark text-xl">{'Are you sure?'}</p>
                    </Modal.Header>
                    <Modal.Body className="py-4">
                        <p className="h5 pb-1">{'You are turning MFA on for your entire organization'}</p>
                        <p className="paragraph-lead text-lg text-primary-dark pb-4">
                            {'This will force everyone in your organization to use MFA.'}
                        </p>
                        <p className="paragraph-lead text-base text-primary-dark font-bold">
                            {'You will not be able to turn on SSO for your organization while MFA is on.'}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button outline variant="primary" className="mr-3" size="sm" onClick={onCancel}>
                                {'Cancel'}
                            </Button>
                            <Button variant="primary" size="sm" onClick={onChange}>
                                {'Turn on MFA'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <div className="flex flex-col pl-4 pt-1 pb-2 text-base text-primary-dark">
                    <p className="font-bold">{`Multi-factor Authentication is ${
                        mfaEnabled ? 'enabled' : 'off'
                    } for your organization`}</p>
                    <span>
                        {ssoEnabled
                            ? 'SSO is currently enabled for your organization. MFA and SSO cannot be enabled at the same time. You will need to disable SSO in order to enable MFA.'
                            : mfaEnabled
                            ? 'MFA can be reset for individuals in the User Management interface'
                            : 'Turning on MFA adds an extra layer of security to all users on your account.'}
                    </span>
                </div>
            </div>
        </>
    )
}
