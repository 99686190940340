import type { ReactNode } from 'react'
import filter from 'lodash/filter'
import partition from 'lodash/partition'
import { useController, useFormContext } from 'react-hook-form'
import { FocusCheckbox } from 'components'
import { AppLink } from 'components/Anchor'
import { monthYearDate } from 'util/formatters'
import { useSurveyWorkflowContext } from '../useSurveyWorkflowContext'
import type { AwardSelection, OverlappingGroup, SelectedAward } from './awardselection.types'
import { getParticipatingNationalEmployeeCount, validateAwardCount } from './awardselection.utils'
import { getEmployeeCountsUsingOverlaps } from './getEmployeeCountsUsingOverlaps'

const ConfirmationLabel = ({ children }: { children: ReactNode }) => <span className="text-grey500">{children}</span>

type SurveyEmployeeCountLabelProps = {
    companyName: string
    employeeCount: number
    date: string
}

function SurveyEmployeeCountLabel({ companyName, employeeCount, date }: SurveyEmployeeCountLabelProps) {
    return (
        <ConfirmationLabel>
            {`I hereby confirm that ${companyName} has a minimum of ${employeeCount} employees in the selected awards as of `}
            <span data-chromatic="ignore">{date}</span>
            {`, that the selected awards will publish this number if ${companyName} is named to the selected award list(s), 
            and that ${companyName} will be disqualified from the selected awards if this number is determined to be substantively incorrect.`}
        </ConfirmationLabel>
    )
}

type ControlledCheckboxProps = {
    name: string
    label: ReactNode
    className: string
    required: boolean
    disabled: boolean
    onChange?: undefined | ((value: unknown) => boolean | null)
}

function ControlledCheckbox({ name: fieldName, label, className, required, disabled }: ControlledCheckboxProps) {
    const { control } = useFormContext()
    const {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, invalid },
    } = useController({
        name: fieldName,
        rules: { required: required ? 'Required' : undefined },
        control,
    })

    return (
        <FocusCheckbox
            className={className}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            ref={ref}
            checked={value ?? false}
            disabled={disabled}
            $error={invalid && isTouched}
        />
    )
}

export function TermsLink() {
    return (
        <>
            {'After entering your employee counts, please confirm the following. See '}
            <AppLink to="/terms">{'terms and conditions'}</AppLink>
            {' for details.'}
        </>
    )
}

export function isSelectedAward(a: AwardSelection): a is SelectedAward {
    return a.isSelected
}

function isEmployeeCountConfirmationDisabled(selectedAwards: SelectedAward[], regionalCount: number): boolean {
    return !selectedAwards.every((award) => {
        if (award.isNational) {
            return (Number(award.employeeCount) ?? 0) >= regionalCount
        }

        return validateAwardCount(award)
    })
}

export type SurveyConfirmationsProps = {
    className?: string
    companyName: string
    awards: AwardSelection[]
    overlappingGroups: OverlappingGroup[]
}

export function SurveyConfirmations({
    className,
    companyName,
    awards = [],
    overlappingGroups,
}: SurveyConfirmationsProps) {
    const selectedAwards = awards.filter(isSelectedAward)
    const [selectedRegionalAwards, selectedNationalAwards] = partition(selectedAwards, (award) => !award.isNational)
    const { surveyHasLaunched, launchDate } = useSurveyWorkflowContext()

    const regionEmployeeCountUsingOverlaps = getEmployeeCountsUsingOverlaps(selectedRegionalAwards, overlappingGroups)
    const nationalAwardEmployeeCount = getParticipatingNationalEmployeeCount(selectedNationalAwards)

    const employeeCount =
        selectedNationalAwards.length > 0 ? nationalAwardEmployeeCount : regionEmployeeCountUsingOverlaps

    const disableEmployeeConfirmation = isEmployeeCountConfirmationDisabled(
        filter(selectedAwards, (sa) => !sa.isFinalized),
        regionEmployeeCountUsingOverlaps
    )

    const date = surveyHasLaunched ? monthYearDate(launchDate) : monthYearDate(new Date())

    return (
        <section className={className}>
            <p>
                <TermsLink />
            </p>
            <ControlledCheckbox
                name="isEmployeeCountConfirmed"
                required={surveyHasLaunched}
                label={<SurveyEmployeeCountLabel companyName={companyName} employeeCount={employeeCount} date={date} />}
                className="mt-1"
                disabled={disableEmployeeConfirmation}
            />
        </section>
    )
}
