import { ExternalLink } from 'components/Anchor'
import config from 'config'

export const workplacePublicitySections = (denyTWPMerch) => ({
    announceNewsToYourEmployees: [
        {
            label: 'Make the big announcement. This is most powerful coming from your leader and be sure to thank employees for their input.',
        },
        { label: 'Throw a party! Earning a Top Workplaces award is a big reason to celebrate.' },
        { label: 'Tout the news at a company meeting or webcast.' },
        { label: 'Feature the news in the company newsletter and on your intranet.' },
        {
            label: (
                <>
                    {
                        'Commemorate your achievement with celebration merchandise, such as plaques, pens and balloons. All are available at: '
                    }
                    <ExternalLink href="https://www.americanregistry.com/topworkplaces" target="_blank">
                        {'americanregistry.com/topworkplaces'}
                    </ExternalLink>
                    {'.'}
                </>
            ),
            isHidden: denyTWPMerch,
        },
        {
            label: 'Throw a virtual zoom happy hour, with the option of sending your employees wine/beer to have during the meeting.',
        },
        {
            label: 'Send out an email blast highlighting the announcement, with some points from the survey on what your company does best. Feature some long-standing employees and their story about what they love about the company.',
        },
    ],
    useTopWorkplacesLogo: [
        { label: 'Post it on your website and blog, especially your homepage and careers section.' },
        { label: 'Include the logo on marketing materials.' },
        { label: 'Add to your letterhead.' },
        { label: 'Put it in employee email signatures.' },
        { label: 'Incorporate it into your Twitter and Facebook cover photos.' },
        {
            label: 'Create an Instagram highlight reel called `Top Workplaces` showcasing your employees and previous team events.',
        },
        {
            label: 'Create a zoom background with the logo to utilize during internal and external meetings.',
        },
        {
            label: 'Get creative: if you are back or still in an office, show it off, in your lobby, common areas-even on your building!',
        },
        { label: 'Let employees show their Top Workplaces pride with swag.' },
    ],
    writeBlogPost: [
        {
            label: 'Share insights about why your company made the list - your specific strengths (take a look at the Energage survey results and Insights for content.',
        },
        { label: 'Share employee comments from the survey that reflect these strengths (see your Energage Insights).' },
        {
            label: (
                <>
                    {'Link to the winners list on the Top Workplaces website('}
                    <ExternalLink href={config.urls.topWorkplaces} target="_blank">
                        {'www.topworkplaces.com'}
                    </ExternalLink>
                    {').'}
                </>
            ),
        },
        { label: 'Link to your company profile on the Top Workplaces website.' },
        {
            label: 'Embed the Top Workplaces logo (and link through to your Top Workplaces profile on the Top Workplaces website).',
        },
    ],
    issuePressRelease: [
        {
            label: 'Prepare the press release: ',
            description:
                "Start with the template we provided in the media kit. Add relevant information (e.g., [COMPANY NAME] or [INSERT RANK]). Include a direct quote from your company's CEO, president, or other leader, reflecting on the meaning of the recognition.",
            className: 'py-2',
        },
        {
            label: 'Distribute the press release:',
            description:
                'Share on newswire services. These services automatically distribute your press release to print, broadcast, and online media in your region, and also make your news searchable on Google and other search engines. Prices vary depending on the service and the region served. Reputable services include Business Wire, PR Newswire, PRWeb and MarketWired.',
            className: 'pt-2',
        },
        {
            label: "Send an email to appropriate media contacts. Embed the content of your press release in your email message-reporters don't like attachments, which should go to the general editorial email address of local media organizations or trade journals (these email addresses are usually available on the media company's website).",
            className: 'pt-2 pb-2',
        },
        {
            label: 'Post the press release on your company website or blog. Let your visitors see the good news!',
            className: 'py-2',
        },
    ],
})
