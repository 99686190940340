import { Loading as Spinner } from '@energage/components'
import head from 'lodash/head'
import styled from 'styled-components'
import colors from 'containers/Main/AwardNotification/colors'
import Achievement from 'containers/Main/AwardNotification/Sections/Achievement'
import AdditionalChances from 'containers/Main/AwardNotification/Sections/AdditionalChances'
import CommunicateWithLeaders from 'containers/Main/AwardNotification/Sections/CommunicateWithLeaders'
import Congratulations from 'containers/Main/AwardNotification/Sections/Congratulations'
import PromoteYourAward from 'containers/Main/AwardNotification/Sections/PromoteYourAward'
import ThankYouForParticipating from 'containers/Main/AwardNotification/Sections/ThankYouForParticipating'
import WhatsNextAspiring from 'containers/Main/AwardNotification/Sections/WhatsNextAspiring'
import { DALLAS_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'
import FAQs from './FAQs'
import FeatureYourCulture from './FeatureYourCulture'
import { useFetchOnlineAnnouncementPdfData } from './useFetchOnlineAnnouncementPdfData'
import WhatsNext from './WhatsNext'

const sectionClassName = 'px-4 md:px-8 sm:px-5 pt-8 pb-8 sm:py-8 text-white lg:px-48'

export const PageBreaker = styled.div`
    page-break-before: always;
`
const BlueBgContainer = styled.div`
    background-color: ${colors.bgBlue};
    min-height: 1122px;
`
const OffWhiteBgContainer = styled.div`
    background-color: ${colors.bgLightGrey};
    min-height: 1122px;
`

const OnlineAnnouncementPagePdf = ({ location }) => {
    const {
        baseUrl,
        isMediaKitAnnouncement,
        url,
        isNationalStandardAwardId,
        showNationalStandardDetails,
        regionName,
        publisherId,
        awardName,
        surveyCompanyId,
    } = parseQueryParams(location)
    const { data, isLoading } = useFetchOnlineAnnouncementPdfData(url, 'awards-data')

    if (isLoading) {
        return <Spinner />
    }

    const {
        active,
        company,
        shopMerchandiseUrl,
        lastSurveyCloseDate,
        denyTWPMerch,
        faqUrl,
        year,
        participation,
        hasEmployerBrandingSubscription,
        hasCEPremiumMediaKit,
        hasPremiumMediaKit,
        hasTwpUsaPremiumMediaKit,
        hasIndustryPremiumMediaKit,
    } = data

    const regionalData = head(data?.active?.regional)
    const isDallasRankedWinner = regionalData?.publisherId === DALLAS_PUBLISHER_ID && !regionalData.isNationalStandard
    const showDallasNationalStandardDetails =
        regionalData?.publisherId === DALLAS_PUBLISHER_ID && regionalData.isNationalStandard

    const announcementComponentProps = {
        companyName: company?.name,
        bgColor: colors.bgLightGrey,
        textColor: colors.textBlue,
        isParticipated: participation?.isActive,
        baseUrl: baseUrl,
        year: year,
        participatedListName: participation?.listName,
        participatedListsalesforceYear: participation?.year,
        timelineAwards: active,
    }
    const isWinner = data?.active

    if (!isWinner) {
        if (participation?.isActive) {
            return (
                <>
                    <BlueBgContainer>
                        <ThankYouForParticipating
                            {...announcementComponentProps}
                            isPdf
                            sectionClassName={sectionClassName}
                        />
                        <WhatsNextAspiring
                            companyName={company?.name}
                            bgColor={colors.bgBlue}
                            sectionClassName={sectionClassName}
                        />
                    </BlueBgContainer>
                    <PageBreaker />
                    <OffWhiteBgContainer>
                        <FeatureYourCulture {...announcementComponentProps} isPdf sectionClassName={sectionClassName} />
                    </OffWhiteBgContainer>
                    <PageBreaker />
                    <OffWhiteBgContainer>
                        <AdditionalChances
                            bgColor={colors.bgBlue}
                            lastSurveyCloseDate={lastSurveyCloseDate}
                            sectionClassName={sectionClassName}
                        />
                        <CommunicateWithLeaders {...announcementComponentProps} sectionClassName={sectionClassName} />
                    </OffWhiteBgContainer>
                    <PageBreaker />
                    <BlueBgContainer>
                        <FAQs
                            companyName={company?.name}
                            year={year}
                            bgColor={colors.bgBlue}
                            faqUrl={faqUrl}
                            companyProfileUrl={company?.profileUrl}
                            isMediaKitAnnouncement={isMediaKitAnnouncement}
                            baseUrl={baseUrl}
                            surveyCompanyId={surveyCompanyId}
                            awardName={awardName}
                            hasCEPremiumMediaKit={hasCEPremiumMediaKit}
                            hasIndustryPremiumMediaKit={hasIndustryPremiumMediaKit}
                            sectionClassName={sectionClassName}
                        />
                    </BlueBgContainer>
                </>
            )
        } else {
            return (
                <ThankYouForParticipating {...announcementComponentProps} isPdf sectionClassName={sectionClassName} />
            )
        }
    }

    return (
        <>
            {isWinner && (
                <>
                    <Congratulations
                        awards={active}
                        sectionClassName={sectionClassName}
                        {...announcementComponentProps}
                        isNationalStandardAwardId={isNationalStandardAwardId}
                        showNationalStandardDetails={showNationalStandardDetails}
                        regionName={regionName}
                        isMediaKitAnnouncement={isMediaKitAnnouncement}
                        isDallasRankedWinner={isDallasRankedWinner}
                        showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                        isPdf
                        hasGoToMediaKitButton
                    />
                    <PageBreaker />
                    <PromoteYourAward
                        sectionClassName={sectionClassName}
                        bgColor={colors.bgBlue}
                        textColor={colors.textBlue}
                        baseUrl={baseUrl}
                        isNationalStandardAwardId={isNationalStandardAwardId}
                        publisherId={publisherId}
                        awardName={awardName}
                        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                        hasCEPremiumMediaKit={hasCEPremiumMediaKit}
                        hasPremiumMediaKit={hasPremiumMediaKit}
                        hasTwpUsaPremiumMediaKit={hasTwpUsaPremiumMediaKit}
                        hasIndustryPremiumMediaKit={hasIndustryPremiumMediaKit}
                        isMediaKitAnnouncement={isMediaKitAnnouncement}
                        isDallasRankedWinner={isDallasRankedWinner}
                        isPdf
                    />
                    <PageBreaker />
                    <WhatsNext
                        sectionClassName={sectionClassName}
                        isBlackListedFromTwpMerch={denyTWPMerch}
                        showNationalStandardDetails={showNationalStandardDetails}
                        isMediaKitAnnouncement={isMediaKitAnnouncement}
                        isDallasRankedWinner={isDallasRankedWinner}
                        showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                        awardName={awardName}
                        {...announcementComponentProps}
                        isPdf
                    />
                    <PageBreaker />
                    {!denyTWPMerch && (
                        <>
                            <Achievement
                                shopMerchandiseUrl={shopMerchandiseUrl}
                                bgColor={colors.bgBlue}
                                year={year}
                                sectionClassName={sectionClassName}
                                isPdf
                            />
                            <PageBreaker />
                        </>
                    )}
                    <FAQs
                        sectionClassName={sectionClassName}
                        faqUrl={faqUrl}
                        year={year}
                        isWinner
                        companyProfileUrl={company?.profileUrl}
                        isMediaKitAnnouncement={isMediaKitAnnouncement}
                        baseUrl={baseUrl}
                        surveyCompanyId={surveyCompanyId}
                        awardName={awardName}
                        hasCEPremiumMediaKit={hasCEPremiumMediaKit}
                        hasIndustryPremiumMediaKit={hasIndustryPremiumMediaKit}
                        isDallasRankedWinner={isDallasRankedWinner}
                        showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                        isPdf
                        {...announcementComponentProps}
                    />
                </>
            )}
        </>
    )
}

export default OnlineAnnouncementPagePdf
