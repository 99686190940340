import { Skeleton } from '@energage/components'
import { Delete, FileDownload, Image, Link } from '@energage/icons'
// TODO: Fix the module to correctly export @energage/icons/filled
// @ts-ignore - Ignored to be able to use icon filled version of Folder
import { Folder } from '@energage/icons/filled'
import { Text } from '@energage/typography'
import cx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { ALLOWED_ACCESS_TIER } from './constants'
import type { DocumentsTableProps, FileItem } from './models'

const SkeletonRow = () => (
    <tr>
        <td>
            <Skeleton.RectangularShape className="h-10" />
        </td>
        <td>
            <Skeleton.RectangularShape className="h-10" />
        </td>
        <td>
            <Skeleton.RectangularShape className="h-10" />
        </td>
        <td>
            <Skeleton.RectangularShape className="h-10" />
        </td>
    </tr>
)

export const DocumentsTable = ({
    files,
    isLoading,
    onNavigate,
    onLink,
    onDownload,
    onDelete,
    onDrop,
    isGettingShareableLink,
    isDeletingFolder,
}: DocumentsTableProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
    })

    return (
        <div
            {...getRootProps({
                className: cx({ 'border-dashed border-4 border-purple700 bg-blue50': isDragActive }),
            })}
            onClick={(e) => e.stopPropagation()}>
            <input {...getInputProps()} />
            <table className={cx('min-w-full bg-white border border-grey200', { 'opacity-50': isDragActive })}>
                <thead>
                    <tr className="w-full bg-gray-100 text-gray-600 text-sm leading-normal border-b border-gray-200">
                        <th className="py-2 px-6 text-left">
                            <Text textStyle="label" className="font-black text-black">
                                {'Name'}
                            </Text>
                        </th>
                        <th className="py-2 px-6 text-left">
                            <Text textStyle="label" className="font-black text-black">
                                {'Last Modified'}
                            </Text>
                        </th>
                        <th className="py-2 px-6 text-left">
                            <Text textStyle="label" className="font-black text-black">
                                {'Contents'}
                            </Text>
                        </th>
                        <th className="py-2 px-6 text-left">
                            <Text textStyle="label" className="font-black text-black">
                                {'Actions'}
                            </Text>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-black">
                    {isLoading ? (
                        <>
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                            <SkeletonRow />
                        </>
                    ) : (
                        files.map((file: FileItem) => (
                            <tr
                                className="border-b border-gray-200 hover:bg-grey100 cursor-pointer"
                                key={file.id}
                                onClick={() =>
                                    file.type === 'folder'
                                        ? onNavigate(file.id)
                                        : file.accessTier === ALLOWED_ACCESS_TIER
                                        ? onLink(file.id)
                                        : null
                                }
                                tabIndex={0}>
                                <td className="py-3 px-6 text-left flex items-center">
                                    {file.type === 'folder' ? (
                                        <Folder size={32} className="pr-1" />
                                    ) : (
                                        <Image size={32} className="pr-1" />
                                    )}
                                    {file.name}
                                </td>
                                <td className="py-3 px-6 text-left">{file.modified}</td>
                                <td className="py-3 px-6 text-left">{file.contents}</td>
                                <td className="py-3 px-6 text-left w-1/12">
                                    <div className="flex items-center gap-2">
                                        {file.type === 'file' ? (
                                            file.accessTier === ALLOWED_ACCESS_TIER ? (
                                                <>
                                                    <button
                                                        disabled={isGettingShareableLink}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            onLink(file.id)
                                                        }}>
                                                        <Link size={32} />
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            onDownload(file.id)
                                                        }}>
                                                        <FileDownload size={32} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <div>{'ARCHIVED'}</div>
                                                </>
                                            )
                                        ) : (
                                            <></>
                                        )}

                                        {file.type === 'folder' ||
                                        (file.type === 'file' && file.accessTier === ALLOWED_ACCESS_TIER) ? (
                                            <button
                                                disabled={isDeletingFolder}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    onDelete(file.id)
                                                }}>
                                                <Delete size={32} />
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    )
}
