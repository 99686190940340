import { useState } from 'react'
import type { ChangeEvent } from 'react'
import { Button, InputGroup, Textarea, UnderlineSelect } from '@energage/components'
import { colors } from '@energage/theme'
import { Text } from '@energage/typography'
import find from 'lodash/find'
import styled from 'styled-components'
import {
    CultureCloudId,
    CultureStatsId,
    OrgStrengthsId,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { OrgStrengthsPreview } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/OrgStrengthsConfiguration/OrgStrengthsPreview'
import CultureStats from 'containers/Public/RevampWidgets/CultureStats'
import LoveMyJobBecause from 'containers/Public/RevampWidgets/LoveMyJobBecause'
import defaultProfilePic from 'images/employerBranding/socialShare/defaultProfilePic.svg'
import linkedInLogo from 'images/employerBranding/socialShare/linkedInLogo.png'
import { useGetConnectionStatus } from '../../usePlatform'
import { CultureCloudSharePreview } from './CultureCloudSharePreview'

const MINIMUM_DROPLETS_REQUIRED = 3

const Section = styled.div`
    .input-group .indicatorContainer {
        color: ${colors.black};
    }
`

const ProfileImage = styled.img`
    width: 70px;
`

const CustomInputGroup = styled(InputGroup)`
    .input-group .indicatorContainer {
        color: ${colors.black};
    }
`

const CustomUnderlineSelect = styled(UnderlineSelect)`
    .indicatorContainer {
        color: ${colors.black};
    }
`

type Options = {
    profileImage: string
    name: string
}

const UserOption = [
    {
        profileImage: 'Fun',
        name: 'Personal',
    },
    {
        profileImage: 'Bold',
        name: 'Jennifer Lee',
    },
    {
        profileImage: 'Professional',
        name: 'Business',
    },
    {
        profileImage: 'Empathetic',
        name: 'Energage',
    },
    {
        profileImage: 'Cheerful',
        name: 'Topworkplaces.com',
    },
]

export const ReadyToSharePage = ({ ...props }) => {
    const {
        dataId,
        data,
        filterData,
        hasEmployerBrandingSubscription,
        selectedCultureType,
        selectedRectangleDesignType,
    } = props
    const linkedInQuery = useGetConnectionStatus('LinkedIn')
    const { name, imageUrl } = linkedInQuery.data?.account ?? {}
    const [selectedUser, setSelectedUser] = useState<string>(name ?? 'Personal')
    const [postContent, setPostContent] = useState<string>(data?.aiContent)
    const [profilePicture, setProfilePicture] = useState<string>(imageUrl ?? defaultProfilePic)

    const handleImageError = () => {
        setProfilePicture(defaultProfilePic)
    }

    const commonProps = {
        dataId,
        filterData,
        isExpiredWidget: false,
        hasEmployerBrandingSubscription,
    }

    const selectedPreview = () => {
        switch (dataId) {
            case CultureCloudId:
                return (
                    <CultureCloudSharePreview
                        data={data}
                        {...commonProps}
                        minDroplets={data?.cultureCloudWordThreshold || MINIMUM_DROPLETS_REQUIRED}
                    />
                )
            case CultureStatsId:
                return (
                    <CultureStats
                        {...data}
                        {...commonProps}
                        selectedCultureType={selectedCultureType ?? data?.cultureStatTypeSelected}
                        selectedRectangleDesignType={selectedRectangleDesignType}
                    />
                )
            case OrgStrengthsId:
                return <OrgStrengthsPreview data={data} {...commonProps} />
            default:
                return <LoveMyJobBecause {...data} {...commonProps} />
        }
    }

    const handleUserChange = (user: Options) => {
        setSelectedUser(user.name)
    }

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const currentText = event?.target?.value
        setPostContent(currentText)
    }

    return (
        <>
            <Text textStyle="title3" className="text-center mt-8" aria-label="Ready to share to LinkedIn">
                <h1>
                    {'Ready to share to '}
                    <img alt="LinkedIn logo" src={linkedInLogo} className="mb-1 inline-flex" />
                </h1>
            </Text>
            <div className="bg-white rounded-lg p-6 my-10">
                <Section className="flex items-center gap-loose">
                    <ProfileImage
                        alt="Profile"
                        src={profilePicture}
                        onError={handleImageError}
                        className="self-center sm:self-start rounded-full"
                    />
                    <CustomInputGroup
                        as={CustomUnderlineSelect}
                        className="w-52 text-base"
                        name="user"
                        id="user"
                        displayArrow={true}
                        primaryColor={colors.purple700}
                        secondaryColor={colors.purple100}
                        options={UserOption}
                        getOptionLabel={(opt: Options) => opt.name}
                        getOptionValue={(opt: Options) => opt.name}
                        value={find(UserOption, (x) => x.name === selectedUser)}
                        onChange={handleUserChange}
                    />
                </Section>
                <Section className="mt-8">
                    <Text textStyle="lead" className="font-bold mb-2">
                        <h2>{'Post'}</h2>
                    </Text>
                    <InputGroup
                        as={Textarea}
                        className="flex-1 text-base"
                        name="generatedMessage"
                        id="generatedMessage"
                        rows={8}
                        placeholder="What do you want to talk about?"
                        value={postContent}
                        onChange={handleTextChange}
                    />
                </Section>
                <Section className="mt-8">
                    <Text textStyle="lead" className="font-bold mb-2">
                        <h2>{'Preview Image '}</h2>
                    </Text>
                    {selectedPreview()}
                </Section>
                <Section className="mt-12 text-right">
                    <Button appearance="primary" type="submit">
                        {'Share'}
                    </Button>
                </Section>
            </div>
        </>
    )
}
