import { Loading as Spinner } from '@energage/components'
import { useHistory, useParams } from 'react-router-dom'
import Inset from 'components/Inset'
import { TakeoverModal } from 'components/Modals'
import { TWP_USA_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import { AwardSet } from 'containers/Main/EmployerRecognition/constants'
import WizardContainer from 'containers/Main/EmployerRecognition/Setup/WizardContainer'
import showErrorAlert from 'util/showErrorAlert'
import type { QueryParams } from './awardRegistration.types'
import { AwardRegistrationForm } from './AwardRegistrationForm'
import { AwardRegistrationProvider, useAwardRegistrationData } from './AwardRegistrationProvider'

const overrideStyle = {
    header: {
        logo: 'mb-6 h-24 md:h-32',
    },
}

const overrideStyleForUS24 = {
    header: {
        logo: 'mb-6 h-24 md:h-48',
    },
}

const TITLE = 'Award Registration'

const AwardRegistrationModal = () => {
    const history = useHistory()
    const { isFetching, error, awardRegistrationInfo } = useAwardRegistrationData()

    if (isFetching) {
        return <Spinner />
    }

    if (error) {
        showErrorAlert('There was an error in fetching company details', error)
        return null
    }

    const style = awardRegistrationInfo?.publisher.id === TWP_USA_PUBLISHER_ID ? overrideStyleForUS24 : overrideStyle
    const awardName =
        awardRegistrationInfo?.awardSetId === AwardSet.CultureExcellence
            ? awardRegistrationInfo?.groupName
            : awardRegistrationInfo?.awardName

    return (
        <TakeoverModal
            isOpen
            onClose={() => history.goBack()}
            dataId={undefined}
            disableClose={undefined}
            customCloseButton={undefined}
            customButton={undefined}
            onCustomButtonClick={undefined}
            disableCustomButton={undefined}>
            <WizardContainer
                title={TITLE}
                subtitle={undefined}
                logoUrl={awardRegistrationInfo.publisher.twpBadgeUrl}
                overrideStyle={style}
                description={undefined}
                logo={undefined}>
                <Inset className="text-center paragraph-lead mb-12">
                    <p className="px-2 md:px-24">
                        {`${awardName} requires the following information for you to participate in the ${awardRegistrationInfo?.salesforceYear} Top Workplaces Award.`}
                    </p>
                </Inset>
                <AwardRegistrationForm />
            </WizardContainer>
        </TakeoverModal>
    )
}

export const AwardRegistration = () => {
    const { awardListParticipantId }: QueryParams = useParams()

    return (
        <AwardRegistrationProvider awardListParticipantId={parseInt(awardListParticipantId ?? '0')}>
            <AwardRegistrationModal />
        </AwardRegistrationProvider>
    )
}
