import { Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import styled from 'styled-components'
import { ReactComponent as BlockingNotificationIcon } from 'images/blocking-notification.svg'
import taskTypes from './taskTypes'

const IconContainer = styled.div`
    width: 30px;
`

const TaskCategoryIcon = ({ category, isUrgent }) => {
    const type = taskTypes[category.type]
    return (
        <IconContainer>
            {isUrgent ? (
                <Icon as={BlockingNotificationIcon} size={28} color={colors.red500} />
            ) : (
                <Icon
                    as={type.icon}
                    size={28}
                    color={colors.white}
                    className={cx('rounded-full', type.iconBackground)}
                />
            )}
        </IconContainer>
    )
}

export default TaskCategoryIcon
