import { Loading as Spinner } from '@energage/components'
import { useIdentity } from 'components/Identity'
import { CustomDemographicTargetContext } from './CustomDemographicTargetContext'
import { Error } from './fragments'
import { SurveyScopeProvider } from './statementSetupUtility/store'
import { SurveyStatementSetup } from './SurveyStatementSetup'
import useFetchSurveyDefinition from './useFetchSurveyDefinition'

const SurveyStatementSetupFetch = ({ surveyEventId, header, readOnly }) => {
    const { surveyCompanyId, organizationId } = useIdentity()
    const { data, isLoading, error, refetch } = useFetchSurveyDefinition(surveyEventId, surveyCompanyId, organizationId)

    if (error) {
        return <Error message={error.message} />
    }

    if (isLoading && !data) {
        return <Spinner className="mt-32" />
    }

    return (
        <CustomDemographicTargetContext.Provider value={data.uploadedDemographic}>
            <SurveyScopeProvider initialData={data}>
                <SurveyStatementSetup
                    surveyCompanyId={surveyCompanyId}
                    surveyEventId={surveyEventId}
                    header={header}
                    refresh={refetch}
                    readOnly={readOnly || data.disableLayoutModifications}
                    surveyType={data.surveyType}
                    draftCustomQuestions={data.customQuestions}
                    hasAdvancedCustomizations={data.disableLayoutModifications}
                    templateName={data.templateName}
                />
            </SurveyScopeProvider>
        </CustomDemographicTargetContext.Provider>
    )
}

export default SurveyStatementSetupFetch
