import { LegacyButton as Button } from '@energage/components'
import styled from 'styled-components'

type ActionButtonProps = {
    hasConditionals: boolean
    onClick: () => void
    className?: string
}

const ActionButton = ({ hasConditionals, onClick, className }: ActionButtonProps) => (
    <Button outline className={className} onClick={onClick}>
        {`${hasConditionals ? 'Remove' : 'Add'} Conditional Comment Questions`}
    </Button>
)

const StyledActionButton = styled(ActionButton).attrs({
    className: 'text-blue600 text-sm border-0 p-0 flex flex-no-wrap items-center relative',
})`
    top: 2px;
`

type AgreementOptionsProps = {
    hasConditionals: boolean
    onChangeHasConditionals: () => void
}

export const AgreementOptions = ({ hasConditionals, onChangeHasConditionals }: AgreementOptionsProps) => {
    return (
        <div className="flex flex-wrap mt-2 w-full max-w-md text-sm mr-0 items-center relative">
            <StyledActionButton hasConditionals={hasConditionals} onClick={onChangeHasConditionals} />
        </div>
    )
}
