import { useCallback, useState } from 'react'
import { alert, Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import map from 'lodash/map'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { FancyRadioButtonWithLabel } from 'components/FancyRadioButton'
import config from 'config'
import { useFetch, usePost } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import { useAccountData } from '../Account'
import useFetchPaymentMethods from './useFetchPaymentMethods'
import ZuoraPaymentGateway, { filterHostedPages, getHostedPageId } from './ZuoraPaymentGateway'

const renderPaymentNote = (emailAddress = '"Bill to contact" email address') => (
    <p className="pb-3 text-grey500">{`An email will be sent to ${emailAddress} with payment instructions.`}</p>
)

const renderZuoraPaymentGateway = (pageId, accountId, onComplete) =>
    pageId && <ZuoraPaymentGateway pageId={pageId} accountId={accountId} onComplete={onComplete} />

const Payment = ({
    className,
    title = 'Payment Information',
    organizationId,
    isEditable = true,
    showPaymentGateway,
    hostedPages,
    isLoading,
    netCreditTerm,
    purchaseOrderNumber,
    isRenewalSummary,
}) => {
    const { accountData } = useAccountData()
    const { id: accountId, defaultPaymentMethodId, billToContact } = accountData
    const [paymentMethodId, setPaymentMethodId] = useState(defaultPaymentMethodId)
    const { supportedPaymentMethods, savedPaymentMethods, loading, error, fetchSavedPaymentMethods } =
        useFetchPaymentMethods(organizationId, accountId)

    const { doPost, isPosting, reset } = usePost({
        url: `${config.api.platform}/organizations/${organizationId}/accounts/${accountId}/paymentMethods`,
        onComplete: (data) => {
            setPaymentMethodId(data)
            alert.success(`Payment method saved successfully.`)
        },
        onError: (error) => {
            showErrorAlert('There was an error saving payment method', error)
            reset()
        },
    })

    const getPageId = useCallback(
        (id) =>
            showPaymentGateway &&
            !some(savedPaymentMethods, (method) => method.id === id) &&
            getHostedPageId(hostedPages, supportedPaymentMethods, id),
        [savedPaymentMethods, supportedPaymentMethods, showPaymentGateway, hostedPages]
    )

    const handleSelection = useCallback(
        (event) => {
            const newPaymentMethodId = event.target.value
            if (getPageId(newPaymentMethodId)) {
                setPaymentMethodId(newPaymentMethodId)
            } else {
                doPost({
                    accountId,
                    paymentMethodId: newPaymentMethodId,
                })
            }
        },
        [getPageId, setPaymentMethodId, doPost, accountId]
    )

    const renderPaymentOptions = useCallback(
        (options) =>
            map(
                sortBy(options, (x) => x.sequence),
                ({ id, name }) => (
                    <FancyRadioButtonWithLabel
                        key={id}
                        name="paymentMethod"
                        className="pr-4"
                        disabled={isPosting}
                        readOnly={!isEditable}
                        onChange={handleSelection}
                        checked={id === paymentMethodId}
                        value={id}
                        label={name}
                    />
                )
            ),
        [isPosting, isEditable, handleSelection, paymentMethodId]
    )

    const handleZuoraPaymentSaveSuccess = useCallback(
        ({ refId }) => {
            fetchSavedPaymentMethods()
            doPost({
                accountId,
                paymentMethodId: refId,
            })
        },
        [fetchSavedPaymentMethods, doPost, accountId]
    )

    if (error) {
        return null
    }

    if (loading || isLoading) {
        return <Spinner />
    }

    return (
        <div className={className}>
            <div
                className={cx('w-full', {
                    'flex flex-row': isRenewalSummary,
                })}>
                <div className="w-full sm:w-2/3 inline-block mb-2 float-left">
                    <div className="flex flex-col ">
                        <span className="text-sm font-bold">{title}</span>
                        <div className="inline-block">
                            {renderPaymentOptions(supportedPaymentMethods)}
                            {renderPaymentOptions(savedPaymentMethods)}
                        </div>
                    </div>
                </div>
                <div
                    className={cx('w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-2 inline-block', {
                        'sm:block md:block lg:inline-block': purchaseOrderNumber,
                    })}>
                    <div
                        className={cx('sm:w-1/2 flex flex-col float-left mb-2', {
                            'w-full': !isRenewalSummary,
                            'mr-4': isRenewalSummary,
                        })}>
                        <span className="text-sm font-bold">{'Credit Terms'}</span>
                        {netCreditTerm}
                    </div>
                    {purchaseOrderNumber && (
                        <div
                            className={cx(
                                'sm:w-1/2 flex flex-col sm:ml-2  sm:break-normal md:break-normal lg:break-words',
                                {
                                    'w-full': !isRenewalSummary,
                                }
                            )}>
                            <span className="text-sm font-bold">{'Purchase Order'}</span>
                            {purchaseOrderNumber}
                        </div>
                    )}
                </div>
            </div>
            {renderZuoraPaymentGateway(getPageId(paymentMethodId), accountId, handleZuoraPaymentSaveSuccess)}
            {renderPaymentNote(billToContact.workEmail)}
        </div>
    )
}

const PaymentWithHostedPages = (props) => {
    const { data: hostedPages, isLoading: isLoadingHostedPages } = useFetch(
        `${config.api.platform}/organizations/${props.organizationId}/Payment/ConfiguredPaymentPages`
    )

    return <Payment {...props} isLoading={isLoadingHostedPages} hostedPages={filterHostedPages(hostedPages)} />
}

const PaymentMethodSelection = (props) => {
    const Component = props.showPaymentGateway ? PaymentWithHostedPages : Payment
    return <Component {...props} />
}

export default PaymentMethodSelection
