import { MultiOptionPreview, PreviewTitleDisplay } from './Fragments'

export type TrueFalsePreviewProps = {
    text?: string
    answerOptions?: { id: string | number; name: string }[]
}

const defaultOptions = [
    { id: 1, name: 'True' },
    { id: 2, name: 'False' },
    { id: 3, name: 'N/A' },
]

export function TrueFalsePreview({ text, answerOptions = defaultOptions }: TrueFalsePreviewProps) {
    return (
        <div>
            <PreviewTitleDisplay>{text}</PreviewTitleDisplay>
            <MultiOptionPreview name="truefalse" options={answerOptions} />
        </div>
    )
}
