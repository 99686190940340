import { LegacyButton as Button } from '@energage/components'
import styled from 'styled-components'
import colors from './colors'

export const YellowButton = styled(Button).attrs({ className: 'bg-yellow500 hover:bg-yellow500' })`
    color: ${colors.textBlue};
    & :hover {
        color: ${colors.textBlue};
    }
`
