import { LegacyButton as Button } from '@energage/components'
import { Text } from '@energage/typography'
import cx from 'clsx'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import { motion } from 'framer-motion'
import { checkReadinessOfSelectAwardsTask, getPendingTasksMessage } from './LaunchReadiness.helpers'

const getLaunchReadinessTitle = ({ isReady, hasLinks, hasPaper, isApproved, launchDateString, hasError }) => {
    if (hasError) {
        return 'Oops! Something went wrong.'
    }
    if (isReady && isApproved) {
        return `Your survey will launch at ${launchDateString}`
    }
    if (isReady) {
        return hasLinks || hasPaper
            ? 'Congratulations, your online survey launch is ready for approval!'
            : 'Congratulations, your survey launch is ready for approval!'
    }
    return "You're not quite ready to launch!"
}

const getLaunchReadinessMessage = ({
    isReady,
    hasLinks,
    hasPaper,
    hasValidLaunchDate,
    responderCountSummaryThreshold,
    hasEnoughRecipients,
    hasIncompleteSmsSetup,
    hasSelectedAwards,
    isTopWorkplaceParticipant,
    hasConfirmedRecipients,
    hasConfirmedRegionalCounts,
    isSurveyPreviewConfirmed,
    hasNonDemographicQuestion,
    hasOrganizationData,
    isApproved,
    hasError,
    timeZone,
    launchApprovalWorkflowTask,
    hasDeliveryContactsSetup,
    hasSelectedWhoAreSurveyed,
    isTwpUsaQualified,
    isRandomSampleMeetsMinimumRequirement,
    isRandomSamplingValid,
    minimumInviteeCountForRandomSampling,
    isInviteeCountSubstantiallyDifferentFromRegionalCounts,
    hasMinimumQualifyingInviteeCount,
    showWorkplaceTrendingStatementsValidation,
}) => {
    const launchApprovalName = launchApprovalWorkflowTask?.isCompletedByEnergage
        ? 'Energage'
        : launchApprovalWorkflowTask?.userName
    const launchApprovalDate = launchApprovalWorkflowTask?.completedDateTime
    const launchApprovalDateString =
        !!launchApprovalDate &&
        format(utcToZonedTime(toDate(launchApprovalDate), timeZone?.ianaKey), `MMMM d, Y`, {
            timeZone: timeZone?.ianaKey,
        })

    if (hasError) {
        return 'There was an error determining your survey launch readiness. Please reach out to support@energage.com to complete your survey setup.'
    }

    if (!isReady) {
        return getPendingTasksMessage({
            hasOrganizationData,
            hasConfirmedRecipients,
            hasEnoughRecipients,
            hasIncompleteSmsSetup,
            hasSelectedAwards,
            isTopWorkplaceParticipant,
            hasConfirmedRegionalCounts,
            isSurveyPreviewConfirmed,
            hasNonDemographicQuestion,
            hasValidLaunchDate,
            hasDeliveryContactsSetup,
            responderCountSummaryThreshold,
            hasSelectedWhoAreSurveyed,
            isTwpUsaQualified,
            isRandomSampleMeetsMinimumRequirement,
            isRandomSamplingValid,
            minimumInviteeCountForRandomSampling,
            isInviteeCountSubstantiallyDifferentFromRegionalCounts,
            hasMinimumQualifyingInviteeCount,
            showWorkplaceTrendingStatementsValidation,
        })
    }

    if (hasPaper) {
        return 'Once your paper surveys arrive, we recommend planning to distribute your paper surveys close to your online survey launch.'
    }

    if (hasLinks) {
        return 'Your printable links will be delivered by your support contact. We recommend distributing links close to your online survey launch.'
    }

    if (isApproved) {
        return (
            (!!launchApprovalName
                ? `Your survey launch was approved by ${launchApprovalName} on ${launchApprovalDateString}. `
                : '') + 'You can make edits until then, and you can still add reminders while the survey is active.'
        )
    }
    return 'If everything looks ready to go, please click to approve your survey.'
}

export const LaunchReadiness = ({
    launchDate,
    isSurveyPreviewConfirmed,
    recipientsData,
    surveySetupData,
    organizationData,
    onApprove,
    isApproved,
    hasSelectedAwards,
    isTopWorkplaceParticipant,
    hasConfirmedRegionalCounts,
    isApproving,
    onUnapprove,
    isUnapproving,
    timeZone,
    hasError,
    launchApprovalWorkflowTask,
    hasConfirmedRecipients,
    hasDeliveryContactsSetup,
    hasIncompleteSmsSetup,
    showWorkplaceTrendingStatementsValidation,
}) => {
    const {
        paperInviteeCount,
        linkInviteeCount,
        inviteeCount,
        responderCountSummaryThreshold,
        hasNationalAward,
        hasAllLocationsSurveyed,
        isRandomSample,
        minimumInviteeCountForRandomSampling,
        isInviteeCountSubstantiallyDifferentFromRegionalCounts,
        hasMinimumQualifyingInviteeCount,
        regionsConfirmedEmployeeTotal,
    } = recipientsData

    const {
        customStatementsCount,
        questionBankStatementsCount,
        standardStatementsCount,
        commentQuestionsCount,
        conditionalQuestionsCount,
    } = surveySetupData

    const hasValidLaunchDate = !!launchDate && (new Date(launchDate) >= Date.now() || isApproved)
    const hasNonDemographicQuestion =
        standardStatementsCount > 0 ||
        questionBankStatementsCount > 0 ||
        customStatementsCount > 0 ||
        commentQuestionsCount > 0 ||
        conditionalQuestionsCount > 0
    const hasLinks = !!linkInviteeCount && linkInviteeCount > 0
    const hasPaper = !!paperInviteeCount && paperInviteeCount > 0
    const hasEnoughRecipients = inviteeCount >= responderCountSummaryThreshold
    const hasOrganizationData = organizationData?.totalEmployeeCount && organizationData?.sector?.sectorId
    const hasSelectedWhoAreSurveyed = !isTopWorkplaceParticipant || hasAllLocationsSurveyed !== null
    const isTwpUsaQualified = !isTopWorkplaceParticipant || !hasNationalAward || hasAllLocationsSurveyed !== false
    const isRandomSampleMeetsMinimumRequirement =
        !isTopWorkplaceParticipant || !isRandomSample || inviteeCount >= minimumInviteeCountForRandomSampling
    const isRandomSamplingValid = !isRandomSample || regionsConfirmedEmployeeTotal > inviteeCount

    const isReady =
        hasOrganizationData &&
        hasConfirmedRecipients &&
        hasEnoughRecipients &&
        hasValidLaunchDate &&
        isSurveyPreviewConfirmed &&
        hasNonDemographicQuestion &&
        hasDeliveryContactsSetup &&
        !hasIncompleteSmsSetup &&
        hasSelectedWhoAreSurveyed &&
        isTwpUsaQualified &&
        isRandomSampleMeetsMinimumRequirement &&
        isRandomSamplingValid &&
        checkReadinessOfSelectAwardsTask({
            isTopWorkplaceParticipant,
            hasSelectedAwards,
            hasConfirmedRegionalCounts,
            isInviteeCountSubstantiallyDifferentFromRegionalCounts,
            hasMinimumQualifyingInviteeCount,
        })

    const className = cx(
        isApproved
            ? 'bg-purple200 text-purple900'
            : isReady
            ? 'bg-blue100 text-purple900 border-blue500 border-r-0 border-l-0'
            : 'bg-red100 text-red700 border-red700 border-r-0 border-l-0',
        'w-full border flex justify-between py-1 w-full'
    )

    const launchDateString =
        !!launchDate &&
        format(utcToZonedTime(toDate(launchDate), timeZone?.ianaKey), `h:mm a zzz 'on' MMMM d, Y`, {
            timeZone: timeZone?.ianaKey,
        })

    const title = getLaunchReadinessTitle({ isReady, hasLinks, hasPaper, isApproved, launchDateString, hasError })
    const message = getLaunchReadinessMessage({
        isReady,
        hasLinks,
        hasPaper,
        hasValidLaunchDate,
        responderCountSummaryThreshold,
        hasEnoughRecipients,
        hasIncompleteSmsSetup,
        hasSelectedAwards,
        isTopWorkplaceParticipant,
        hasConfirmedRegionalCounts,
        hasConfirmedRecipients,
        isSurveyPreviewConfirmed,
        hasNonDemographicQuestion,
        hasOrganizationData,
        isApproved,
        hasError,
        timeZone,
        launchApprovalWorkflowTask,
        hasDeliveryContactsSetup,
        hasSelectedWhoAreSurveyed,
        isTwpUsaQualified,
        isRandomSampleMeetsMinimumRequirement,
        isRandomSamplingValid,
        minimumInviteeCountForRandomSampling,
        isInviteeCountSubstantiallyDifferentFromRegionalCounts,
        hasMinimumQualifyingInviteeCount,
        showWorkplaceTrendingStatementsValidation,
    })

    return (
        <div data-testid="survey-setup-summary-launch-readiness" className="overflow-hidden w-full">
            <motion.div initial={{ y: -50 }} animate={{ y: 0 }} className={className}>
                <div className="w-full flex flex-col sm:flex-row justify-between items-center px-6 py-2">
                    <div>
                        <Text textStyle="title4">
                            <h2>{title}</h2>
                        </Text>
                        {message}
                    </div>
                    {isReady && !isApproved ? (
                        <Button className="mt-4 sm:mt-0 sm:ml-4" onClick={() => onApprove()} disabled={isApproving}>
                            {'Approve Survey Launch'}
                        </Button>
                    ) : isApproved ? (
                        <Button className="mt-4 sm:mt-0 sm:ml-4" onClick={() => onUnapprove()} disabled={isUnapproving}>
                            {'Unapprove Survey Launch'}
                        </Button>
                    ) : null}
                </div>
            </motion.div>
        </div>
    )
}
