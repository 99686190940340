import { useCallback, useRef } from 'react'
import { useToggle } from '@energage/hooks'

export function useAutoToggle(initial = false, timeout = 250) {
    const [state, internalToggle] = useToggle(initial)
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

    const toggle = useCallback(() => {
        internalToggle()

        if (timeoutId.current) {
            clearTimeout(timeoutId.current)
        }

        timeoutId.current = setTimeout(internalToggle, timeout)
    }, [internalToggle, timeout])

    return [state, toggle]
}
