import { useEffect, useState } from 'react'
import { alert, Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import noop from 'lodash/noop'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { useMatchFetch } from 'hooks'
import withZuoraResource from './withZuoraResource'

const paymentMethodTypes = {
    CreditCard: 'Credit Card',
    ACH: 'ACH',
}

export const getHostedPageId = (hostedPages, paymentMethods, selectedMethodId) => {
    if (!hostedPages) {
        return null
    }
    const paymentMethodType = paymentMethodTypes[find(paymentMethods, (x) => x.id === selectedMethodId)?.type]
    return find(hostedPages, (x) => x.type === paymentMethodType)?.pageId
}

export const filterHostedPages = (hostedPages) =>
    filter(hostedPages, (x) => includes(x.name, config.zuora.hostedPagePrefix))

const IframeWrapper = styled.div`
    #z_hppm_iframe {
        background-color: transparent;
        width: 100% !important;
        max-width: 400px;
        min-height: 280px;

        body {
            margin: 0px;
        }
    }
`

const showError = () => alert.danger('Something went wrong, Please try again later')

const ZuoraContainer = ({ data, pageId, accountId, onComplete = noop, onError = showError }) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (data) {
            if (!window.Z) {
                throw new Error('Zuora resource for hosted page not found!')
            }

            const prePopulateFields = {}
            const params = {
                tenantId: data.tenantId,
                token: data.token,
                signature: data.signature,
                key: data.key,
                id: pageId,
                style: 'inline',
                submitEnabled: 'true',
                url: `${config.zuora.url}/apps/PublicHostedPageLite.do`,
                paymentGateway: config.zuora.paymentGateway,
                field_accountId: accountId,
            }
            const callback = (response) => {
                if (response.success) {
                    onComplete(response)
                } else {
                    onError(response)
                }
            }
            window.Z.render(params, prePopulateFields, callback)
            window.Z.runAfterRender(() => {
                setLoading(false)
            })
        }
    }, [data, pageId, accountId, onComplete, onError])

    return (
        <>
            {loading && <Spinner />}
            <IframeWrapper
                id="zuora_payment"
                className={cx({
                    block: !loading,
                    hidden: loading,
                })}
            />
        </>
    )
}

const ZuoraPaymentGateway = ({ pageId, ...props }) => {
    const { organizationId } = useIdentity()

    const render = useMatchFetch(
        `${config.api.platform}/organizations/${organizationId}/Payment/ConfiguredPaymentPages/${pageId}/AccessToken`
    )

    return render({
        loading: () => <Spinner />,
        data: (data) => <ZuoraContainer data={data} pageId={pageId} {...props} />,
    })
}

export default withZuoraResource(ZuoraPaymentGateway)
