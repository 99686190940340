import PropTypes from 'prop-types'
import { Route as BaseRoute, Switch } from 'react-router-dom'
import { BadgeFetch as RewampBadgeFetch, SingleYearSingleAward } from '../RevampWidgets/Badge'
import { CultureCloudFetch as RewampCultureCloudFetch } from '../RevampWidgets/CultureCloud'
import { CultureStatsFetch as RewampCultureStatsFetch } from '../RevampWidgets/CultureStatsFetch'
import { EmployeeCommentFetch as RewampEmployeeCommentFetch } from '../RevampWidgets/EmployeeComment'
import { LinkedInBannerFetch as RevampLinkedInBannerFetch } from '../RevampWidgets/LinkedInBannerFetch'
import { LoveMyJobBecauseFetch as RewampLoveMyJobBecauseFetch } from '../RevampWidgets/LoveMyJobBecause'
import { MultiAwardFetch as RewampMultiAwardFetch, SingleYearMultiAward } from '../RevampWidgets/MultiAwardBadge'
import { MultiCultureBadgeFetch as RewampMultiCultureBadgeFetch } from '../RevampWidgets/MultiCultureBadge'
import { MultiYearBadge, MultiYearBadgeFetch as RewampMultiYearBadgeFetch } from '../RevampWidgets/MultiYearBadge'
import { OrganizationalStrengthsFetch as RewampOrganizationalStrengthsFetch } from '../RevampWidgets/OrganizationalStrengths'
import { parseQueryParams } from './QueryParser'

const Route = (props) => (
    <div id="tile-container">
        <BaseRoute {...props} />
    </div>
)

const Widgets = ({ match, location }) => {
    const { isVersion2 } = parseQueryParams(location)

    return (
        <Switch>
            <Route path={`${match.url}/linkedinbanner`} component={RevampLinkedInBannerFetch} />
            <Route path={`${match.url}/lovemyjob`} component={RewampLoveMyJobBecauseFetch} />
            <Route path={`${match.url}/culturecloud`} component={RewampCultureCloudFetch} />
            <Route path={`${match.url}/percentpositive`} component={RewampCultureStatsFetch} />
            <Route path={`${match.url}/topXPercent`} component={RewampCultureStatsFetch} />
            <Route path={`${match.url}/strengths`} component={RewampOrganizationalStrengthsFetch} />
            <Route path={`${match.url}/badge`} component={isVersion2 ? SingleYearSingleAward : RewampBadgeFetch} />
            <Route
                path={`${match.url}/multiyearbadges`}
                component={isVersion2 ? MultiYearBadge : RewampMultiYearBadgeFetch}
            />
            <Route
                path={`${match.url}/multiProgrambadges`}
                component={isVersion2 ? SingleYearMultiAward : RewampMultiAwardFetch}
            />
            <Route path={`${match.url}/multiculturebadge`} component={RewampMultiCultureBadgeFetch} />
            <Route path={`${match.url}/employeecomment`} component={RewampEmployeeCommentFetch} />
        </Switch>
    )
}

Widgets.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
}

export default Widgets
