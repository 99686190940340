import { useContext, useEffect } from 'react'
import { useNavigation } from 'components/Navigation'
import SurveyStatementSetupFetch from '../../StatementSetup'
import HeaderTeleporter from '../HeaderTeleporter'
import SurveySetupContext from '../SurveySetupContext'
import SurveySetupHeader from './SurveySetupHeader'

const ConfigureSurvey = ({ match }) => {
    const { addItems } = useNavigation(match.url)
    const { surveyHasLaunched } = useContext(SurveySetupContext)

    useEffect(() => {
        addItems(match.url, 'Configure Survey', [])
    }, [addItems, match.url])

    const surveyEventId = match.params.surveyEventId

    return (
        <SurveyStatementSetupFetch
            surveyEventId={Number(surveyEventId)}
            header={(props) => (
                <HeaderTeleporter.Source>
                    <SurveySetupHeader {...props} />
                </HeaderTeleporter.Source>
            )}
            fullSurvey={true}
            readOnly={surveyHasLaunched}
        />
    )
}

export default ConfigureSurvey
