import { useEffect, useState } from 'react'
import { Input, InputGroup, LegacyButton as Button, SmallSelect } from '@energage/components'
import { colors } from '@energage/theme'
import cx from 'clsx'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import slice from 'lodash/slice'
import split from 'lodash/split'
import toLower from 'lodash/toLower'
import { useFormContext } from 'react-hook-form-old'
import styled from 'styled-components'
import fields, { ADD_CONTACT_FORM_SET, NAME_LENGTH__MESSAGE, REQUIRED_MESSAGE } from './fields'
import { contactTypes } from './utils'

const customControlStyles = (hasError) => ({
    menuList: (base) => ({
        ...base,
        height: 75,
    }),
    control: (base, state) => {
        const highlightColor = hasError ? colors.red500 : colors.blue300
        const isHighlighted = state.isFocused || state.menuIsOpen
        return {
            ...base,
            borderColor: isHighlighted ? highlightColor : colors.grey300,
            outline: 'none',
            boxShadow: 'none',
        }
    },
})

const InputField = styled(Input)`
    &.input-group input {
        min-height: 38px;
    }
    &.input-group.input-group--error input,
    &.input-group.input-group--error textarea {
        border-color: ${colors.red500};
    }
`

const Field = ({
    field,
    formProps,
    contact,
    triggerValidation,
    isSummary,
    updateFormDetails,
    isAddressFieldRequired,
}) => {
    const name = `${contact.type}-${field.meta.name}`
    const [selectedState, selectState] = useState()
    const handleSelectChange = (selectedOption) => {
        formProps.setValue(name, selectedOption.abbreviation)
        selectState(selectedOption)
        triggerValidation(name)
        updateFormDetails?.(name, selectedOption.abbreviation)
    }

    const handleUpdateFormDetails = (event) => {
        const { name, value } = event.target
        updateFormDetails?.(name, value)
    }

    const validateInput = (fieldName, values) => {
        const fieldDetails = split(fieldName, '-')
        const nameFields = {
            firstName: 'firstName',
            lastName: 'lastName',
        }
        if (
            includes(Object.values(nameFields), fieldDetails[1]) &&
            size(values[fieldDetails[0] + '-' + nameFields.firstName]) === 1 &&
            size(values[fieldDetails[0] + '-' + nameFields.lastName]) === 1
        ) {
            return NAME_LENGTH__MESSAGE
        }
    }

    const fieldValidation =
        isSummary || isAddressFieldRequired ? { required: REQUIRED_MESSAGE, ...field.validations } : field.validations

    switch (field.meta.type) {
        case 'select': {
            formProps.register(name, fieldValidation)
            const state = find(formProps.states, (s) => {
                return (
                    toLower(s.name) === toLower(contact[field.meta.name]) ||
                    toLower(s.abbreviation) === toLower(contact[field.meta.name])
                )
            })

            // little hack to set preselected option
            if (state && formProps.getValues()[name] === undefined) {
                formProps.setValue(name, state.abbreviation)
            }

            const error = formProps.errors[name]

            return (
                <InputGroup
                    {...field.meta}
                    data-test-id={name}
                    name={name}
                    size="sm"
                    as={SmallSelect}
                    info={error?.message}
                    error={!!error}
                    styles={customControlStyles(!!error)}
                    options={formProps.states}
                    onChange={handleSelectChange}
                    value={selectedState || state}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                />
            )
        }
        default: {
            const error = formProps.errors[name]

            return (
                <InputField
                    {...field.meta}
                    data-test-id={name}
                    name={name}
                    className="w-full"
                    size="sm"
                    autoComplete="off"
                    info={error?.message}
                    error={!!error}
                    disabled={false}
                    ref={formProps.register({
                        ...fieldValidation,
                        validate: () => validateInput(name, formProps.getValues()),
                    })}
                    defaultValue={contact[field.meta.name]}
                    onChange={handleUpdateFormDetails}
                />
            )
        }
    }
}

const ContactForm = ({ close, contacts, isSummary, isAddressFieldRequired }) => {
    const formDetails = !!contacts ? contacts : ADD_CONTACT_FORM_SET
    const { saveContacts, handleSubmit, triggerValidation, ...formProps } = useFormContext()
    const onSubmit = handleSubmit((data) => {
        saveContacts(data)
        close()
    })

    useEffect(() => {
        triggerValidation()
    }, [triggerValidation])

    return (
        <form onSubmit={onSubmit} noValidate>
            {map(formDetails, (c) => {
                return (
                    <div key={c.type} className="mb-4">
                        <p className="font-bold">{c.label}</p>
                        <hr className="mb-2" />
                        {map(fields, (set, i) => (
                            <div key={i} className="flex items-start flex-col md:flex-row">
                                {map(set, (field, j) => (
                                    <div key={j} className={cx('my-1', field.className)}>
                                        <p className="text-sm">{field.label}</p>
                                        <Field
                                            field={field}
                                            formProps={formProps}
                                            triggerValidation={triggerValidation}
                                            contact={c}
                                            isSummary={isSummary}
                                            isAddressFieldRequired={isAddressFieldRequired}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )
            })}
            <div className="w-full text-right mt-10">
                <Button variant="link" onClick={close} className="px-3 py-2">
                    {'Cancel'}
                </Button>
                <Button
                    data-test-id={'save-contact'}
                    variant="primary"
                    type="submit"
                    disabled={size(formProps.errors) > 0}
                    className="px-3 py-2">
                    {'Save'}
                </Button>
            </div>
        </form>
    )
}

const MobileView = ({ formDetails, isSummary, formProps, triggerValidation, fieldList, updateFormDetails }) =>
    map(formDetails, (c) => (
        <div key={c.type} className="w-full sm:w-1/2 block sm:inline-block mb-4 sm:mb-0 sm:pr-12  sm:mt-0 clear-both">
            <p className="text-xl">{c.label}</p>
            <div className="w-full">
                {map(slice(fieldList, 0, 5), (field, index) => (
                    <div key={index} className="my-1 flex items-start flex-col md:flex-row">
                        <div className="w-3/4 sm:w-full block sm:inline-block  sm:mb-0 clear-both">
                            <p className="text-sm">{field.label}</p>
                            <div className={cx('sm:w-full', field.missingFieldClassName)}>
                                <Field
                                    field={field}
                                    formProps={formProps}
                                    triggerValidation={triggerValidation}
                                    contact={c}
                                    isSummary={isSummary}
                                    updateFormDetails={updateFormDetails}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="flex flex-wrapper">
                    <div key={c.type} className="sm:inline-block mb-4 sm:mb-0 clear-both ">
                        <div>
                            <div className={cx('my-1', fieldList?.[5].missingFieldClassName)}>
                                <p className="text-sm">{fieldList?.[5].label}</p>
                                <Field
                                    field={fieldList?.[5]}
                                    formProps={formProps}
                                    triggerValidation={triggerValidation}
                                    contact={c}
                                    isSummary={isSummary}
                                    updateFormDetails={updateFormDetails}
                                />
                            </div>
                            <div className={cx('my-1', fieldList?.[6].missingFieldClassName)}>
                                <p className="text-sm">{fieldList?.[6].label}</p>
                                <Field
                                    field={fieldList?.[6]}
                                    formProps={formProps}
                                    triggerValidation={triggerValidation}
                                    contact={c}
                                    isSummary={isSummary}
                                    updateFormDetails={updateFormDetails}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))

const DesktopView = ({ formDetails, isSummary, formProps, triggerValidation, fieldList, updateFormDetails }) => (
    <>
        {map(formDetails, (c) => (
            <div
                key={c.type}
                className={cx('block sm:inline-block mb-4 sm:mb-0 md:1/2 clear-both', {
                    'sm:w-3/5 sm:pr-12': c.type === contactTypes.BILLED_TO,
                    'sm:w-2/5': c.type === contactTypes.SOLD_TO,
                })}>
                <p className="text-xl">{c.label}</p>{' '}
            </div>
        ))}
        <div className="w-full">
            {map(slice(fieldList, 0, 5), (field, index) => (
                <div key={index} className="my-1 flex flex-wrapper">
                    <div className="sm:w-3/5 sm:pr-12 block sm:inline-block mb-4 sm:mb-0 sm:pr-12 clear-both">
                        <p className="text-sm">{field.label}</p>
                        <div className={cx('sm:w-3/4', field.missingFieldClassName)}>
                            <Field
                                field={field}
                                formProps={formProps}
                                triggerValidation={triggerValidation}
                                contact={formDetails?.[0]}
                                isSummary={isSummary}
                                updateFormDetails={updateFormDetails}
                            />
                        </div>
                    </div>
                    <div className="sm:w-2/5 block sm:inline-block mb-4 sm:mb-0 clear-both">
                        <p className="text-sm">{field.label}</p>
                        <div className={cx('sm:w-11/12', field.missingFieldClassName)}>
                            <Field
                                field={field}
                                formProps={formProps}
                                triggerValidation={triggerValidation}
                                contact={formDetails?.[1]}
                                isSummary={isSummary}
                                updateFormDetails={updateFormDetails}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex flex-wrapper">
                {map(formDetails, (c) => (
                    <div
                        key={c.type}
                        className={cx('sm:inline-block mb-4 sm:mb-0 clear-both', {
                            'sm:w-3/5 sm:pr-12': c.type === contactTypes.BILLED_TO,
                            'sm:w-2/5': c.type === contactTypes.SOLD_TO,
                        })}>
                        <div
                            className={cx({
                                'sm:w-3/4': c.type === contactTypes.BILLED_TO,
                                'sm:w-11/12': c.type === contactTypes.SOLD_TO,
                            })}>
                            <div className={cx('my-1', fieldList?.[5].missingFieldClassName)}>
                                <p className="text-sm">{fieldList?.[5].label}</p>
                                <Field
                                    field={fieldList?.[5]}
                                    formProps={formProps}
                                    triggerValidation={triggerValidation}
                                    contact={c}
                                    isSummary={isSummary}
                                    updateFormDetails={updateFormDetails}
                                />
                            </div>
                            <div className={cx('my-1', fieldList?.[6].missingFieldClassName)}>
                                <p className="text-sm">{fieldList?.[6].label}</p>
                                <Field
                                    field={fieldList?.[6]}
                                    formProps={formProps}
                                    triggerValidation={triggerValidation}
                                    contact={c}
                                    isSummary={isSummary}
                                    updateFormDetails={updateFormDetails}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
)

export const SummaryContact = ({
    contacts,
    isSummary,
    isInvalidSummaryContact,
    saveSummaryContact,
    isOrderConfirmed,
    setContactUpdated,
}) => {
    const formDetails = !!contacts ? contacts : ADD_CONTACT_FORM_SET
    const { handleSubmit, triggerValidation, ...formProps } = useFormContext()
    const [isMobileDevice, setMobileDevice] = useState()
    const maxWidth = 768
    const onSubmit = handleSubmit((data) => {
        saveSummaryContact(data)
    })

    isInvalidSummaryContact(!isEmpty(formProps.errors))
    setContactUpdated(isEmpty(formProps.errors))

    useEffect(() => {
        const handleWindowResize = () => setMobileDevice(window.innerWidth < maxWidth ? true : false)

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    useEffect(() => {
        triggerValidation()
    }, [triggerValidation, isMobileDevice])

    useEffect(() => {
        if (isOrderConfirmed) {
            onSubmit()
        }
    }, [isOrderConfirmed, onSubmit])

    const FormViewComponent = isMobileDevice ? MobileView : DesktopView

    const updateFormDetails = (name, value) => {
        const fieldDetails = split(name, '-')
        map(formDetails, (c) => (c.type === fieldDetails?.[0] ? (c[fieldDetails?.[1]] = value) : ''))
    }

    return (
        <form noValidate className="w-full">
            <FormViewComponent
                formDetails={formDetails}
                formProps={formProps}
                triggerValidation={triggerValidation}
                isSummary={isSummary}
                fieldList={flatten(fields)}
                updateFormDetails={updateFormDetails}
            />
        </form>
    )
}
export default ContactForm
