import { useEffect, useState } from 'react'

type WindowSize =
    | {
          width: number
          height: number
      }
    | {
          width: undefined
          height: undefined
      }

const isClient = typeof window === 'object'
const getSize = () =>
    isClient
        ? {
              width: window.innerWidth,
              height: window.innerHeight,
          }
        : { width: undefined, height: undefined }

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return
        }

        const handleResize = () => setWindowSize(getSize())

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}
