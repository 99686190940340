import { useState } from 'react'
import { UnderlineSelect } from '@energage/components'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import { useLocation } from 'react-router-dom'
import { routes } from 'constants/routes'
import { categories } from '../../../Templates/templateEnums'
import extractWorkplaceTrendingTemplate from '../extractWorkplaceTrendingTemplate'
import MobileTemplateSelection from '../MobileTemplateSelection'
import { SurveyTemplateCardController } from '../SurveyTemplateCard'

const buildTemplates = ({ templates, keepMobileView, ...commonOptions }) => {
    return map(templates, (template) => {
        return buildTemplate({ template, keepMobileView, ...commonOptions })
    })
}

export const buildTemplate = ({
    template,
    keepMobileView,
    workingSurveyGenerationId,
    setupSurvey,
    returnUrl,
    labelKey,
}) => {
    const { surveyTypeId, surveyGenerationId, title, description, thumbnail, locked } = template

    return (
        <SurveyTemplateCardController
            className="mb-6"
            key={`${surveyGenerationId}-${labelKey}`}
            title={title}
            description={description}
            image={thumbnail.previewUrl}
            locked={locked}
            working={surveyGenerationId === workingSurveyGenerationId}
            onSetup={() => setupSurvey({ surveyTypeId, surveyGenerationId, title })}
            linkTo={{
                pathname: routes.survey.preview({ templateId: surveyGenerationId }),
                state: { from: returnUrl },
            }}
            keepMobileView={keepMobileView}
        />
    )
}

export const buildFeaturedTemplates = ({
    templates,
    keepMobileView,
    maxFeaturedTemplates,
    setupSurvey,
    returnUrl,
    ...delegated
}) => {
    const availableTemplates = [...templates]
    const [workplaceTrendingTemplate] = extractWorkplaceTrendingTemplate(availableTemplates)
    const featuredTemplates = workplaceTrendingTemplate
        ? [...take(availableTemplates, maxFeaturedTemplates - 1), workplaceTrendingTemplate]
        : take(availableTemplates, maxFeaturedTemplates)

    return buildTemplates({ templates: featuredTemplates, keepMobileView, setupSurvey, returnUrl, ...delegated })
}

const FullSurveySelection = ({ templates, title, maxFeaturedTemplates, workingSurveyGenerationId, setupSurvey }) => {
    const allTemplatesCategory = { label: 'All Templates', value: -1 }
    const featuredTemplatesCategory = { label: 'Featured Pulses', value: 0 }
    const availableCategories = filter(categories, (category) => find(templates, ['templateCategory', category.value]))
    const selectableCategories = [allTemplatesCategory, featuredTemplatesCategory, ...availableCategories]
    const [selectedCategory, setSelectedCategory] = useState(allTemplatesCategory)
    const location = useLocation()

    return (
        <>
            <div className="px-4 sm:px-6 pt-4 sm:pt-6 flex flex-col sm:flex-row sm:justify-between items-start sm:items-center sm:mb-4">
                <h2 className="mr-3">{title}</h2>
                <div data-testid="survey-templates-selection" className="flex flex-wrap mt-2 mb-3 mr-6 sm:mb-0">
                    <h5 className="mt-2 mr-4">{'Viewing'}</h5>
                    <UnderlineSelect
                        isSearchable={false}
                        fontSize={20}
                        primaryColor="#560BCE"
                        secondaryColor="#FAF7FF"
                        getOptionLabel={(v) => v.label}
                        getOptionValue={(v) => v.value}
                        value={selectedCategory}
                        options={selectableCategories}
                        onChange={setSelectedCategory}
                        className="mb-3 inline-block"
                        displayArrow={true}
                        styles={{
                            option: () => ({ whiteSpace: 'normal' }),
                        }}
                    />
                </div>
            </div>
            <div className="px-4 pt-4 sm:px-6 sm:pt-6">
                {templates.length > 1 &&
                    map(selectableCategories, (category) => {
                        if (
                            selectedCategory?.value !== allTemplatesCategory.value &&
                            selectedCategory?.value !== category.value
                        ) {
                            return null
                        }

                        const commonOptions = {
                            setupSurvey,
                            workingSurveyGenerationId,
                            returnUrl: location.pathname,
                            labelKey: category.label,
                        }

                        const children =
                            category.value === featuredTemplatesCategory.value
                                ? buildFeaturedTemplates({
                                      templates,
                                      maxFeaturedTemplates,
                                      ...commonOptions,
                                  })
                                : buildTemplates({
                                      templates: orderBy(filter(templates, ['templateCategory', category.value]), [
                                          'title',
                                      ]),
                                      ...commonOptions,
                                  })

                        return (
                            children.length > 0 && (
                                <div
                                    data-testid={`survey-templates-category-section-${category.label.replaceAll(
                                        ' ',
                                        '-'
                                    )}`}
                                    key={category.label}>
                                    <h5 className="mb-3">{category.label}</h5>
                                    <div className="hidden sm:flex sm:flex-wrap pb-5">
                                        {children}
                                        <div css="min-width: 1px" />
                                    </div>

                                    <MobileTemplateSelection>{children}</MobileTemplateSelection>
                                </div>
                            )
                        )
                    })}
            </div>
        </>
    )
}

export default FullSurveySelection
