import { useEffect, useMemo } from 'react'
import filter from 'lodash/filter'
import { useBrandingQuery } from 'api'
import type { EnhancedKy } from 'api'
import { LinkedInBanner } from 'containers/Public/RevampWidgets/LinkedInBanner'

type Data = {
    statements?: unknown
    backgroundColor: string
    className?: string
    type?: string
    updateLinkedInDroplets?: (droplets: unknown) => void
}

type LinkedInBannerProps = {
    cultureCloudServiceURL: string
    ariaDescription?: string
    data: Data
    dataId?: string
    filterData?: unknown
    isExpiredWidget?: boolean
    hasEmployerBrandingSubscription?: boolean
    selectedTopXDesignType?: number
    selectedPercentPositiveDesignType?: number
    cultureStatTypeSelected?: number
    cultureStatsTableData?: {
        designTypeId?: number
        surveyFactorId?: number
    }
    isTopXFiltered?: boolean
}

type Droplet = {
    isSelected: boolean
    text: string
    weight: number
}

type BrandingData = {
    droplets: Droplet[]
}

export const LinkedInBannerPreview = ({
    data,
    cultureCloudServiceURL,
    ariaDescription,
    dataId,
    hasEmployerBrandingSubscription,
    isExpiredWidget,
    filterData,
    cultureStatTypeSelected,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    isTopXFiltered,
    cultureStatsTableData,
}: LinkedInBannerProps) => {
    const { updateLinkedInDroplets } = data
    const updatedCultureCloudServiceURLCall = useMemo(() => cultureCloudServiceURL, [cultureCloudServiceURL])

    const createQueryFn = (url: string) => async (_: unknown, api: EnhancedKy) => await api.json<BrandingData>(url)
    const {
        data: brandingData = { droplets: [] },
        isLoading,
        refetch,
        isError,
    } = useBrandingQuery(
        'linkedInBannerWordCloud',
        cultureCloudServiceURL ? createQueryFn(cultureCloudServiceURL) : async () => ({ droplets: [] })
    )

    useEffect(() => {
        if (brandingData?.droplets) {
            const selectedDroplets = filter(brandingData?.droplets, 'isSelected').slice(0, 12)
            updateLinkedInDroplets?.(selectedDroplets)
        }

        if (isError) {
            updateLinkedInDroplets?.([])
        }
        // eslint-disable-next-line
    }, [filterData, brandingData?.droplets])

    useEffect(() => {
        if (updatedCultureCloudServiceURLCall) {
            refetch()
        }
    }, [updatedCultureCloudServiceURLCall, refetch])

    return (
        <LinkedInBanner
            {...data}
            ariaDescription={ariaDescription}
            droplets={brandingData?.droplets}
            dataId={dataId}
            isLoading={isLoading}
            hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
            isExpiredWidget={isExpiredWidget}
            filterData={filterData}
            cultureStatTypeSelected={cultureStatTypeSelected}
            selectedTopXDesignType={selectedTopXDesignType}
            selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
            isTopXFiltered={isTopXFiltered}
            cultureStatsTableData={cultureStatsTableData}
        />
    )
}
