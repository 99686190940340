import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import isFunction from 'lodash/isFunction'
import { Prompt as BasePrompt } from 'react-router-dom'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'

const Prompt = ({
    when,
    title = "Wait, don't go!",
    message = 'Are you sure you want to leave without saving changes?',
}) => (
    <BasePrompt
        when={when}
        message={(location) =>
            JSON.stringify({
                title,
                message: isFunction(message) ? message(location) : message,
            })
        }
    />
)

Prompt.propTypes = {
    when: PropTypes.bool.isRequired,
}

export const getUserConfirmation = (data, callback) => {
    const modal = document.createElement('div')
    document.body.appendChild(modal)

    const withCleanup = (shouldTransition) => {
        // Using setTimeout prevents ReactDOM warning that the node
        // being unmounted was rendered by React.
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(modal)
            document.body.removeChild(modal)
            callback(shouldTransition)
        }, 0)
    }

    // getUserConfirmation only works when a string is passed.
    // If we want to pass multiple props (for example, a title and
    // a message, or configurable button text), we need to pass
    // stringified JSON and then parse it here.
    const { title, message } = JSON.parse(data)

    ReactDOM.render(
        <Modal isOpen className="w-5/6 sm:w-4/5 md:w-2/3 lg:w-2/5">
            <Inset padding>
                <h5>{title}</h5>
                <p className="pt-3">{message}</p>
            </Inset>
            <div className="flex justify-end px-3 pb-3">
                <Button variant="primary" onClick={() => withCleanup(false)}>
                    {'Stay on Page'}
                </Button>
                <Button variant="primary" className="ml-4" onClick={() => withCleanup(true)}>
                    {'Leave Page'}
                </Button>
            </div>
        </Modal>,
        modal
    )
}

export default Prompt
