import { alert } from '@energage/components'
import config from 'config'
import { usePostWithTaskCompletion } from 'hooks'

export function useSubmitPaperSurvey(surveyEventId, onFinished) {
    const { doPost, isPosting, reset } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/SurveyFormat/${surveyEventId}/OrderPaperSurveys`,
        onComplete: () => {
            onFinished(true)
        },
        onError: () => {
            alert.danger(`There was an error placing your order for paper surveys`)
            onFinished(false)
            reset()
        },
    })

    return {
        orderPaperSurveys: doPost,
        orderingPaperSurveys: isPosting,
    }
}

export default useSubmitPaperSurvey
