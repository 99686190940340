import trim from 'lodash/trim'
import { MultiOptionPreview, PreviewTitleDisplay } from './Fragments'

export type MultiChoicePreviewProps = {
    text?: string
    answerOptions?: { id: string | number; name: string }[]
}

export function MultiChoicePreview({ text, answerOptions }: MultiChoicePreviewProps) {
    return (
        <div>
            <PreviewTitleDisplay>{text}</PreviewTitleDisplay>
            <MultiOptionPreview
                name="multi-choice"
                options={answerOptions?.filter((option) => trim(option.name) !== '')}
            />
        </div>
    )
}
