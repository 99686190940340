import { useCallback, useEffect, useState } from 'react'
import { butterBar, LegacyButton as Button, Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import every from 'lodash/every'
import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { FormContext, useForm } from 'react-hook-form-old'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import { useFetchStates, useVisibility } from 'hooks'
import withClassName from 'style/util/withClassName'
import { useAccountData } from '../Account'
import ContactForm, { SummaryContact } from './ContactForm'
import { useFetchContacts, useSaveContacts } from './fetch'
import {
    buildContacts,
    buildContactsPayload,
    buildOrderPageContacts,
    buildOrderPageContactsPayload,
    contactTypes,
    mapRawFormToState,
    useUpdateContacts,
} from './utils'

const ContactField = withClassName('truncate', 'p', 'ContactField')

export const Card = ({ open, isEditable, isSuperUser, hideEdit, ...contact }) => {
    const { firstName, lastName, email, address, city, state, zipCode, type } = contact

    return (
        <>
            <ContactField data-test-id={`contactField-${type}-fullName`}>{`${firstName} ${lastName}`}</ContactField>
            <ContactField data-test-id={`contactField-${type}-email`}>{email}</ContactField>
            <p>{address}</p>
            <ContactField>{`${city}${city ? ',' : ''} ${state || ''} ${zipCode}`}</ContactField>
        </>
    )
}

export const ContactCards = ({ contacts, open, isEditable, responsive, isSuperUser, isSummary }) => {
    contacts = sortBy(contacts, 'type')
    return (
        <div className="relative">
            <div className={cx('flex items-start', { 'flex-col sm:flex-row mb-0 sm:mb-4': responsive })}>
                {map(contacts, (c) => (
                    <div
                        key={c.type}
                        className={cx('w-full block sm:inline-block mb-4 sm:mb-0', {
                            'sm:w-1/2': !isSummary,
                            'sm:w-2/3 sm:pr-12 md:1/2': c.type === contactTypes.BILLED_TO && isSummary,
                            'sm:w-1/3 md:1/2': c.type === contactTypes.SOLD_TO && isSummary,
                        })}>
                        <Card isEditable={isEditable} isSuperUser={isSuperUser} open={open} {...c} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const Contacts = ({
    contacts,
    states,
    className,
    formContextProps,
    isEditable,
    isSummary,
    responsive = true,
    notifyInterest,
    isInvalidSummaryContact,
    saveSummaryContact,
    setContactUpdated,
    isInvalidContact,
    isSuperUser,
    isOrderConfirmed,
    isAddressFieldRequired,
}) => {
    const { visible, show, hide } = useVisibility()
    const formMethods = useForm({
        mode: 'onBlur',
    })

    const hideModal = () => {
        formMethods.clearError()
        hide()
    }

    const handleShow = () => {
        show()
        notifyInterest?.()
    }

    return (
        <div className={className}>
            {isSummary && isInvalidContact && !isSuperUser ? (
                <div className="relative">
                    <div className={cx('flex items-start', { 'flex-col sm:flex-row mb-0 sm:mb-4': responsive })}>
                        <div className="w-full">
                            <FormContext {...formMethods} states={states} {...formContextProps}>
                                <SummaryContact
                                    contacts={contacts}
                                    isSummary={isSummary}
                                    isInvalidSummaryContact={isInvalidSummaryContact}
                                    saveSummaryContact={saveSummaryContact}
                                    setContactUpdated={setContactUpdated}
                                    isOrderConfirmed={isOrderConfirmed}
                                />
                            </FormContext>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <ContactCards
                        contacts={contacts}
                        open={handleShow}
                        isEditable={isEditable}
                        responsive={responsive}
                        isSuperUser={isSuperUser}
                        isSummary={isSummary}
                        isAddressFieldRequired={isAddressFieldRequired}
                    />
                    <Modal
                        title="Update Contacts"
                        isOpen={visible}
                        onClose={hideModal}
                        className="w-full md:w-3/4 lg:w-1/2 mx-4">
                        <Inset padding>
                            <FormContext {...formMethods} states={states} {...formContextProps}>
                                <ContactForm
                                    contacts={contacts}
                                    close={hideModal}
                                    isSummary={isSummary}
                                    isAddressFieldRequired={isAddressFieldRequired}
                                />
                            </FormContext>
                        </Inset>
                    </Modal>
                </div>
            )}
        </div>
    )
}

const RenewalPageContacts = ({
    isOrderConfirmed,
    saveSummaryContact,
    setContactInfoStatus,
    isSuperUser,
    setContactUpdated,
    ...props
}) => {
    const { accountData, updateBillToContact } = useAccountData()
    const [contacts, setContacts] = useState(buildContacts(accountData))
    const { data: states, status: statesStatus, error: statesError } = useFetchStates()
    const [isInvalidContact, setIsContactVaild] = useState(false)

    useEffect(() => {
        const isInvalidContact = map(contacts, (contact) => includes(contact, ''))
        setIsContactVaild(includes(isInvalidContact, true))
        setContactInfoStatus?.(includes(isInvalidContact, true))
    }, [contacts, setContactInfoStatus])

    // Update context only after successful POST
    const onContactUpdate = useCallback(() => {
        updateBillToContact(buildContactsPayload(contacts)?.billToContact)
    }, [updateBillToContact, contacts])

    const { updateContacts } = useUpdateContacts(accountData.id, onContactUpdate)

    const saveContacts = (rawForm) => {
        const updatedContacts = mapRawFormToState(rawForm)
        const payload = buildContactsPayload(updatedContacts)
        updateContacts(payload)
        setContacts(updatedContacts)
    }

    if (statesStatus === 'loading') {
        return (
            <p className="text-center p-8">
                <Spinner inline size="small" /> {'Fetching contact information...'}
            </p>
        )
    }

    if (statesError) {
        return <p className="text-center p-8">{'There was an error loading contact details'}</p>
    }

    return (
        <Contacts
            contacts={contacts}
            states={states}
            isEditable
            formContextProps={{ saveContacts }}
            {...props}
            isInvalidContact={isInvalidContact}
            isInvalidSummaryContact={(val) => setContactInfoStatus?.(val)}
            isSuperUser={isSuperUser}
            setContactUpdated={setContactUpdated}
            isOrderConfirmed={isOrderConfirmed}
            saveSummaryContact={saveSummaryContact}
        />
    )
}

export const OrderPageContacts = ({
    surveyEventId,
    organizationId,
    setPublishContactInfoStatus,
    setContactInfoStatus,
    isEditable,
    notifyInterest,
    saveSummaryContact,
    setContactUpdated,
    isSuperUser,
    isOrderConfirmed,
    ...props
}) => {
    const [contacts, setContacts] = useState()
    const [isInvalidContact, setIsContactVaild] = useState(false)
    const getPublishContactInfoStatus = (contacts) => {
        const publishContactInfo = map(
            contacts,
            (contact) => !!contact.firstName && !!contact.lastName && !!contact.email
        )
        return includes(publishContactInfo, false)
    }
    const onSuccess = (data) => {
        if (every(data, (x) => x === null)) {
            setContacts(undefined)
        } else {
            const orderPageContacts = buildOrderPageContacts(data)
            if (isEditable) {
                const isInvalidContact = map(orderPageContacts, (contact) => includes(contact, ''))
                setContactInfoStatus?.(includes(isInvalidContact, true))
                setIsContactVaild(includes(isInvalidContact, true))
                setPublishContactInfoStatus?.(getPublishContactInfoStatus(orderPageContacts))
            }
            setContacts(orderPageContacts)
        }
    }
    const { status: contactsStatus, error: contactsError } = useFetchContacts(surveyEventId, organizationId, onSuccess)
    const { data: states, status: statesLoading, error: statesError } = useFetchStates()
    const { saveContactDetails, saveContactsError } = useSaveContacts(surveyEventId, organizationId)
    const { visible, show, hide } = useVisibility()

    const formMethods = useForm({
        mode: 'onBlur',
    })

    const hideModal = () => {
        formMethods.clearError()
        hide()
    }

    const saveContacts = async (rawForm) => {
        const updatedContacts = mapRawFormToState(rawForm)
        const payload = buildOrderPageContactsPayload(surveyEventId, updatedContacts)
        await saveContactDetails(payload)
        if (!saveContactsError) {
            setContacts(updatedContacts)
            setContactInfoStatus?.(false)
            setPublishContactInfoStatus?.(false)
        }
    }

    useEffect(() => {
        if (getPublishContactInfoStatus(contacts)) {
            butterBar.closeAll()
            butterBar.danger('Please provide account contact information in order to publish this order.', {
                dismissible: true,
            })
        }
        return () => {
            butterBar.closeAll()
        }
    }, [contacts])

    if (contactsStatus === 'loading' || statesLoading === 'loading') {
        return (
            <p className="text-center p-8">
                <Spinner inline size="small" /> {'Fetching contact information...'}
            </p>
        )
    }

    if (!contacts || contactsError || statesError) {
        setContactInfoStatus?.(true)
        setPublishContactInfoStatus?.(true)
        return (
            <div className="relative">
                <p className="text-center p-8">
                    {
                        'No contact information is currently available for this account. Please provide a name and email address before publishing this order.'
                    }
                </p>
                <p className="absolute top-0 right-0 lg:px-4 text-right">
                    <Button className="p-0" variant="link" onClick={show}>
                        {'Add Contacts'}
                    </Button>
                </p>
                <Modal
                    title="Add Contacts"
                    isOpen={visible}
                    onClose={hideModal}
                    className="w-full md:w-3/4 lg:w-1/2 mx-4">
                    <Inset padding>
                        <FormContext {...formMethods} states={states} saveContacts={saveContacts}>
                            <ContactForm contacts={contacts} close={hideModal} isSummary={!!setContactInfoStatus} />
                        </FormContext>
                    </Inset>
                </Modal>
            </div>
        )
    }

    return (
        <Contacts
            contacts={contacts}
            states={states}
            isEditable={isEditable}
            formContextProps={{ saveContacts }}
            notifyInterest={notifyInterest}
            isSummary={!!setContactInfoStatus}
            isInvalidSummaryContact={(val) => setContactInfoStatus?.(val)}
            saveSummaryContact={saveSummaryContact}
            setContactUpdated={setContactUpdated}
            isInvalidContact={isInvalidContact}
            isSuperUser={isSuperUser}
            isOrderConfirmed={isOrderConfirmed}
            {...props}
        />
    )
}

export default RenewalPageContacts
