import { useWorkplaceSurveyQuery } from 'api'
import type { ApiQueryClient, EnhancedKy } from 'api'
import type { IdentityModel } from 'models/Identity'
import { useIdentity } from '../Identity'
import getTopWorkplacesWorkflow from './workflowHelpers'

const getTwpWorkflow = async (identity: IdentityModel, api: EnhancedKy) =>
    getTopWorkplacesWorkflow(
        identity,
        await api.json(`Workflow/${identity.surveyCompanyId}/ParticipatedProgramsWorkflow/${identity.organizationId}`)
    )

function useTwpWorkflow() {
    const identity = useIdentity()
    const { data, isLoading } = useWorkplaceSurveyQuery<unknown>(
        'twp-workflow',
        (_, api) => getTwpWorkflow(identity, api),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: false,
            staleTime: Infinity,
            enabled: identity.permissions.hasSurveyPlatform,
        }
    )

    return { workflow: data, isLoading: isLoading }
}

const updateTwpWorkflow = (queryClient: typeof ApiQueryClient) => {
    /**
     * Assumes that if we don't get data back the first time, there is no twp workflow
     * so we shouldn't bother make an http call to update it.
     */
    if (!queryClient.getQueryData('twp-workflow')) {
        return
    }

    queryClient.invalidateQueries('twp-workflow')
}

export { useTwpWorkflow, updateTwpWorkflow }
