import { LegacyButton as Button } from '@energage/components'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Anchor = ({ className, innerAs, ...props }) => (
    <Button as={innerAs} variant="link" className={cx('p-0', className)} {...props} />
)

const StyledAnchor = styled(Anchor)`
    && {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
`

const AppLink = ({ ...props }) => <StyledAnchor innerAs={Link} {...props} />

const ExternalLink = ({ ...props }) => <StyledAnchor innerAs="a" {...props} />

export { AppLink, ExternalLink }
