import { monthDayShortYearDate } from 'util/formatters'
import { List } from './awardScheduleChangeConfirmation.atoms'
import type { AwardChange, AwardChangeList } from './AwardScheduleChangeConfirmation.types'

function ListItem(award: AwardChange) {
    return (
        <li className="flex mb-1" key={award.awardName}>
            <span className="w-1/2 mr-2">{award.awardName}</span>
            {award.newAwardDeadline && <span className="w-1/2">{monthDayShortYearDate(award.newAwardDeadline)}</span>}
        </li>
    )
}

export function AwardDeadlineList({ awards }: { awards: AwardChangeList }) {
    return (
        <section className="flex flex-col mt-4">
            <p className="flex font-bold">
                <span className="w-1/2">{'Award'}</span>
                <span className="w-1/2">{'Deadline'}</span>
            </p>
            <ul className="pl-0">
                <List awards={awards} renderListItem={ListItem} />
            </ul>
        </section>
    )
}
