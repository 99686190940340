import config from 'config'
import { useFetch } from 'hooks'
import TechnicalPreparationEmailLink from '../ScheduleSurvey/TestDelivery/TechnicalPreparationEmailLink'
import { whitelistText } from '../ScheduleSurvey/TestDelivery/TestDelivery'

const WhitelistInformation = ({ surveyEventId, surveyCompanyId }) => {
    const { data, isLoading } = useFetch({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${surveyCompanyId}/SurveyTestDelivery/${surveyEventId}`,
    })

    if (isLoading || !data) {
        return null
    }

    return (
        <div className="pt-6 pb-4 pl-6">
            <h5>{whitelistText.title}</h5>
            <span className="pt-2 inline-block">{whitelistText.subtitle}</span>
            <TechnicalPreparationEmailLink {...data.techPreparationEmailView} />
        </div>
    )
}

export default WhitelistInformation
