import { useSelector } from 'react-redux'
import { selectIdentity } from 'store'

function useIdentity() {
    const identity = useSelector(selectIdentity)
    return window.location.pathname === '/callback' ? null : identity
}

function usePermissions() {
    const identity = useIdentity()
    return identity ? identity.permissions : {}
}

export { useIdentity, usePermissions }
