import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import cx from 'clsx'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import styled from 'styled-components'
import { CULTURE_FACTS_TILE } from 'containers/Main/EmployerBranding/CultureFacts/constants'
import {
    CultureStatsGraphicType,
    isDesignTypeDonut,
    isRectangleDesignType,
    isRectangleDesignWithBorderType,
    isReelDesignWithBorderType,
    isTopXSelected,
    isVersionTable,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/CultureStatsConfiguration/utils'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { TileWidth } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { CultureStatsType } from 'containers/Main/EmployerBranding/util'
import { CultureStatsDonutGraph } from './CultureStatsDonutGraph'
import { CultureStatsHalfDonutGraph } from './CultureStatsHalfDonutGraph'
import { CultureStatsRectangle1080View } from './CultureStatsRectangle1080View'
import { CultureStatsRectangleView } from './CultureStatsRectangleView'
import { CultureStatsTopXView } from './CultureStatsTopXView'
import SelectedFilterPills from './SelectedFilterPills'
import Tile from './Tile'

const RectangleSelectedStatementContainer = styled.div`
    height: ${(props) => (props.isDownloadable ? '400px' : '120px')};
    ${(props) => props.isRectangleBorderDesign && (props.isDownloadable ? 'max-width: 560px;' : 'max-width: 205px;')}
`

const RectangleSelectedStatement = styled.div`
    font-family: 'Roboto', sans-serif;
    color: ${(props) => props.textColor ?? colors.white};
    font-size: ${(props) => (props.isDownloadable ? '40px' : '16px')};
    ${(props) => props.isDownloadable && (props.isRectangleBorderDesign ? 'top: 80px;' : 'top: 160px;')}
    ${(props) => !props.isDownloadable && (props.isRectangleBorderDesign ? 'top: 20px;' : 'top: 40px;')}
`

const SelectedStatement = styled.div`
    font-family: 'Roboto', sans-serif;
`

const SelectedBoldWord = styled.span`
    font-size: ${(props) => (props.isDownloadable ? '76px' : '28px')};
`

export const isTopXDisabled = (filterData, selectedCultureType, cultureStatTypeSelected) => {
    const { Locations, Departments, JobRoles } = filterData ?? {}

    return (
        CultureStatsType.TopXPercent === (selectedCultureType || cultureStatTypeSelected) &&
        (Locations || Departments || JobRoles)
    )
}

export const filteredData = (filterData) => {
    const { Locations, Departments, JobRoles } = filterData ?? {}

    return Locations || Departments || JobRoles
}

const GetPercentageText = ({ isRectangleBorderDesign, text, textColor, percentage, isDownloadable }) => {
    const percentageRegex = new RegExp(`(${percentage}%|Top ${percentage}%)`, 'gi')
    const parts = text?.split(percentageRegex)

    return (
        <RectangleSelectedStatementContainer
            className="flex flex-col items-center justify-center"
            isDownloadable={isDownloadable}
            isRectangleBorderDesign={isRectangleBorderDesign}>
            <RectangleSelectedStatement
                className="font-normal leading-snug text-left"
                isRectangleBorderDesign={isRectangleBorderDesign}
                isDownloadable={isDownloadable}
                textColor={textColor}>
                {map(parts, (part, i) =>
                    percentageRegex.test(part) ? (
                        <SelectedBoldWord className="font-black" isDownloadable={isDownloadable} key={i}>
                            {part}
                        </SelectedBoldWord>
                    ) : (
                        part
                    )
                )}
            </RectangleSelectedStatement>
        </RectangleSelectedStatementContainer>
    )
}

const GetHighlightedText = ({ text, highlight, isDownloadable }) => {
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'))

    return (
        <SelectedStatement className={cx('leading-snug', isDownloadable ? 'text-5xl mx-8 mb-16' : 'text-lg mx-4 mb-8')}>
            {map(parts, (part, i) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={i} className={cx('font-bold', isDownloadable ? 'text-6xl' : 'text-2xl')}>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </SelectedStatement>
    )
}

const getTileHeight = (height, selectedRectangleDesignType, isDownloadable) => {
    switch (selectedRectangleDesignType) {
        case CultureStatsGraphicType.RectangleBorderDesign:
        case CultureStatsGraphicType.RectangleDesignWithoutBorder:
            return isDownloadable ? CULTURE_FACTS_TILE.DOWNLOAD_HEIGHT : CULTURE_FACTS_TILE.PREVIEW_HEIGHT
        case CultureStatsGraphicType.ReelDesignWithBorder:
        case CultureStatsGraphicType.ReelDesignWithoutBorder:
            return CULTURE_FACTS_TILE.REEL_DOWNLOAD_HEIGHT
        default:
            return height
    }
}

const CultureStatsTypeChange = ({
    children,
    percentage,
    backgroundColor,
    highlightColor,
    textColor,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType,
    cultureStatTypeSelected,
    isContentFilter,
    isRectangleDesign,
    cultureStatsTableData,
    cultureStatTileHeight,
    focusWord,
    isDownloadable,
}) => {
    const isTopXView = isTopXSelected(cultureStatTypeSelected)

    if (isRectangleDesign && isDownloadable) {
        return (
            <CultureStatsRectangle1080View
                backgroundColor={backgroundColor}
                highlightColor={highlightColor}
                textColor={textColor}
                focusWord={focusWord}
                selectedRectangleDesignType={selectedRectangleDesignType}
                cultureStatTileHeight={cultureStatTileHeight}>
                {children}
            </CultureStatsRectangle1080View>
        )
    } else if (isRectangleDesign) {
        return (
            <CultureStatsRectangleView
                backgroundColor={backgroundColor}
                highlightColor={highlightColor}
                textColor={textColor}
                focusWord={focusWord}
                selectedRectangleDesignType={selectedRectangleDesignType}
                cultureStatTileHeight={cultureStatTileHeight}>
                {children}
            </CultureStatsRectangleView>
        )
    }

    if (isTopXView) {
        return (
            <CultureStatsTopXView
                className={cx('relative', isDownloadable ? 'mt-12 mb-6' : 'mt-6 mb-3')}
                topXPercent={percentage}
                backgroundColor={backgroundColor}
                selectedTopXDesignType={isContentFilter ? cultureStatsTableData?.designTypeId : selectedTopXDesignType}
                topXTextTop="74px"
                topXLaurelTextTop={isDownloadable ? '136px' : '68px'}
                topXTextLeft={isDownloadable ? '134px' : '67px'}
                percentTextFontSize={isDownloadable ? '80px' : '40px'}
                percentTextSupFontSize={isDownloadable ? '32px' : '16px'}
                topXImageHeight={isDownloadable ? '240px' : '120px'}
                topXImageWidth={isDownloadable ? '250px' : '125px'}
            />
        )
    }

    return (
        <div className="relative">
            {isDesignTypeDonut(
                isContentFilter ? cultureStatsTableData?.designTypeId : selectedPercentPositiveDesignType
            ) ? (
                <CultureStatsDonutGraph
                    positivePercent={percentage}
                    className="flex-auto mt-5"
                    height="120px"
                    width="120px"
                    backgroundColor={backgroundColor}
                />
            ) : (
                <CultureStatsHalfDonutGraph
                    positivePercent={percentage}
                    className={cx('flex-auto', isDownloadable ? 'mt-16' : 'mt-8')}
                    height={isDownloadable ? '236px' : '118px'}
                    width={isDownloadable ? '360px' : '180px'}
                    backgroundColor={backgroundColor}
                />
            )}
        </div>
    )
}

const CultureStats = forwardRef(
    (
        {
            title,
            statements,
            backgroundColor,
            highlightColor,
            textColor,
            headerBgColor,
            headerTextColor,
            bodyBorderRadius,
            headerBorderRadius,
            className,
            type,
            height,
            dataId,
            filterData,
            isExpiredWidget,
            hasEmployerBrandingSubscription,
            selectedTopXDesignType,
            selectedPercentPositiveDesignType,
            selectedRectangleDesignType,
            isTopXFiltered,
            cultureStatsTableData,
            cultureStatTypeSelected,
            isPdf,
            isDownloadable,
        },
        ref
    ) => {
        const isContentFilter = isVersionTable(cultureStatsTableData, isExpiredWidget)
        const isRectangleDesign = isRectangleDesignType(selectedRectangleDesignType)
        const isRectangleBorderDesign =
            isRectangleDesignWithBorderType(selectedRectangleDesignType) ||
            isReelDesignWithBorderType(selectedRectangleDesignType)
        const selectedItems = filter(statements, 'isSelected')
        const selectedStatement = isContentFilter
            ? head(filter(statements, (item) => item.surveyFactorId === cultureStatsTableData?.surveyFactorId))
            : head(selectedItems)
        const {
            cultureStatTitle,
            focusWord = '',
            percentPositiveStatement,
            topXPercentStatement,
        } = selectedStatement ?? {}
        const statementsData = isTopXSelected(cultureStatTypeSelected) ? topXPercentStatement : percentPositiveStatement
        const { percentage = 0, text = '', highlightedWord = '' } = statementsData ?? {}
        const header = isRectangleDesign ? '' : cultureStatTitle ?? title
        const cultureStatTileHeight = getTileHeight(height, selectedRectangleDesignType, isDownloadable)
        const cultureStatTileWidth = isDownloadable
            ? CULTURE_FACTS_TILE.DOWNLOAD_WIDTH
            : TileWidth(isPdf, CULTURE_FACTS_TILE.PREVIEW_WIDTH)

        return (
            <Tile
                header={header}
                bgColor={backgroundColor}
                headerBgColor={headerBgColor}
                headerTextColor={headerTextColor}
                highlightColor={highlightColor}
                textColor={textColor}
                className={className}
                ref={ref}
                type={type}
                width={cultureStatTileWidth}
                height={cultureStatTileHeight}
                minHeight={isRectangleDesign ? cultureStatTileHeight : undefined}
                dataId={dataId}
                pills={SelectedFilterPills}
                bodyBorderRadius={bodyBorderRadius}
                headerBorderRadius={headerBorderRadius}
                blurTileBody={!hasEmployerBrandingSubscription}
                filterData={filterData}
                isPdf={isPdf}>
                <>
                    {selectedStatement && !isTopXFiltered ? (
                        <div className="flex flex-col text-center items-center">
                            <CultureStatsTypeChange
                                percentage={percentage}
                                backgroundColor={backgroundColor}
                                highlightColor={highlightColor}
                                textColor={textColor}
                                selectedTopXDesignType={selectedTopXDesignType}
                                selectedPercentPositiveDesignType={selectedPercentPositiveDesignType}
                                cultureStatTypeSelected={cultureStatTypeSelected}
                                selectedRectangleDesignType={selectedRectangleDesignType}
                                isContentFilter={isContentFilter}
                                isRectangleDesign={isRectangleDesign}
                                cultureStatsTableData={cultureStatsTableData}
                                cultureStatTileHeight={cultureStatTileHeight}
                                focusWord={focusWord}
                                isDownloadable={isDownloadable}>
                                {isRectangleDesign && (
                                    <GetPercentageText
                                        isDownloadable={isDownloadable}
                                        isRectangleBorderDesign={isRectangleBorderDesign}
                                        text={text}
                                        textColor={textColor}
                                        percentage={percentage}
                                    />
                                )}
                            </CultureStatsTypeChange>
                            {!isRectangleDesign && (
                                <GetHighlightedText
                                    text={text}
                                    highlight={highlightedWord}
                                    isDownloadable={isDownloadable}
                                />
                            )}
                        </div>
                    ) : (
                        <InsufficientDataWarning warningText="There are no culture stats above the 25% threshold for this survey" />
                    )}
                </>
            </Tile>
        )
    }
)

CultureStats.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    dataId: PropTypes.string,
}

export default CultureStats
