import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import config from 'config'

export function PrintableLinksMessage({ surveyEventId }) {
    const { isSuperUser } = useIdentity()

    return (
        <>
            <p>
                {`Your survey will be distributed through Printable Links sent to you by Energage.`}
                {isSuperUser && (
                    <>
                        {' Configuring departments is an important step. '}
                        <ExternalLink
                            href={`${config.urls.surveyTracker}/ConfigureDepartments.aspx?e=${surveyEventId}&t=3`}>
                            {'Click here'}
                        </ExternalLink>
                        {` to Configure Departments.`}
                    </>
                )}
            </p>
        </>
    )
}

export default PrintableLinksMessage
