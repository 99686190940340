import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@energage/hooks'
import { colors } from '@energage/theme'
import map from 'lodash/map'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import { LONG_ISLAND_PUBLISHER_ID, YEAR_2021 } from 'containers/Main/EmployerBranding/MediaKit/constants'
import twpSingleBlurred from 'images/employerBranding/mediaKit/twpSingle-blurred.svg'
import twpSingle from 'images/employerBranding/mediaKit/twpSingle.svg'
import { viewports } from 'style/breakpoints'
import { parseQueryParams } from '../Widgets/QueryParser'
import Tile from './Tile'

const renderAwardLogos = (awards) => (
    <div className="flex flex-wrap justify-around pb-4">
        {map(awards, (p) => (
            <div className="w-1/2 p-1" key={p.awardLogoUrl}>
                <img className="w-full" src={p.awardLogoUrl} alt="Logo" />
            </div>
        ))}
    </div>
)

const MultiBadgeWrapper = styled.div`
    p {
        font-size: ${(props) => (props.isPngDownload ? '52px' : props.isPdfDownload ? '15px' : '14px')};
    }
`

const Year = styled.p`
    color: ${colors.darkblue700};
    font-family: 'Yantramanav';
    font-weight: 900;
    margin-left: 1.1em;
    margin-top: -2.9em;
    ${(props) =>
        (props.isPngDownload && 'font-size: 59px;') ||
        (props.isPdfDownload && 'margin-left: 1.4em; margin-top: -3.2em; font-size: 21px;')};
`

const MultiAwardBadge = ({ year, companyName, awardDetails, isPngDownload, isPdfDownload, isMediaKitLite }) => (
    <div className="w-3/4 flex flex-row justify-center p-4 mt-4">
        <div className="w-1/4 relative">
            <img className="w-full" src={isMediaKitLite ? twpSingleBlurred : twpSingle} alt="TWP Logo" />
            <Year isPngDownload={isPngDownload} isPdfDownload={isPdfDownload} className="text-base">
                {year}
            </Year>
        </div>
        <MultiBadgeWrapper
            className="w-3/4"
            style={{ minHeight: isPngDownload ? 540 : isPdfDownload ? 220 : 130 }}
            isPdfDownload={isPdfDownload}
            isPngDownload={isPngDownload}>
            <p
                className={
                    'text-xs pl-2 text-grey600 font-semibold leading-none text-center'
                }>{`${companyName} is a ${year} Top Workplace!`}</p>
            <div className="mt-2 pl-2 w-full text-center">{renderAwardLogos(awardDetails)}</div>
        </MultiBadgeWrapper>
    </div>
)

MultiAwardBadge.propTypes = {
    companyName: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    awardDetails: PropTypes.array.isRequired,
}

const MultiAwardTWPBadge = forwardRef(
    ({ isFileDownload, isPngDownload, className, backgroundColor, ...props }, ref) => {
        const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMin })
        const isPdfDownload = !isPngDownload && isFileDownload && isMobileDevice
        const width = useMediaQuery({ minWidth: viewports.smMin }) ? 350 : isPdfDownload ? 594 : 280
        const tileWidth = isPngDownload ? 1400 : width

        return (
            <Tile
                width={tileWidth + 'px'}
                className={className}
                bgColor={colors.transparent}
                footerBackgroundColor={backgroundColor}
                ref={ref}>
                <MultiAwardBadge isPngDownload={isPngDownload} isPdfDownload={isPdfDownload} {...props} />
            </Tile>
        )
    }
)

export default MultiAwardTWPBadge

export function MultiAwardFetch({ location }) {
    const { id, awardListParticipantId, tileTypeId, isPngDownload, mediaKitLiteExperience } = parseQueryParams(location)
    const { data } = useBrandingQuery(
        ['surveyCompanies', id],
        `SurveyCompanies/${id}/Tile/${tileTypeId}/${awardListParticipantId}`
    )

    if (!data) {
        return null
    }

    return (
        <MultiAwardTWPBadge
            isPngDownload={isPngDownload}
            isFileDownload
            isMediaKitLite={!!mediaKitLiteExperience}
            {...data}
        />
    )
}

export function SingleYearMultiAward({ location }) {
    const { year, companyName, publisherIds, storageBaseUrl, isPngDownload, mediaKitLiteExperience } = parseQueryParams(
        location,
        {
            parseNumbers: true,
            parseBooleans: true,
            arrayFormat: 'comma',
        }
    )

    const _publisherIds = typeof publisherIds == 'number' ? [publisherIds] : publisherIds

    const awardDetails = map(_publisherIds, (id) => ({
        awardLogoUrl:
            id === LONG_ISLAND_PUBLISHER_ID && year === YEAR_2021
                ? `${storageBaseUrl}twp/publishers/${id}/${year}/blocklogo.svg`
                : `${storageBaseUrl}twp/publishers/${id}/blocklogo.svg`,
    }))

    const data = {
        year,
        companyName,
        awardDetails,
    }

    return (
        <MultiAwardTWPBadge
            isPngDownload={isPngDownload}
            isFileDownload
            isMediaKitLite={!!mediaKitLiteExperience}
            {...data}
        />
    )
}
