export default {
    state: {
        twpWizard: true,
    },
    reducers: {
        enable(state) {
            state.twpWizard = true
        },
        disable(state) {
            state.twpWizard = false
        },
    },
    selectors: (slice) => ({
        isTwpWizardEnabled: () => slice((state) => state.twpWizard),
    }),
}
