import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'
import contrast from 'util/contrast'
import { hexToRGB } from 'util/formatters'
import safeInvoke from 'util/safeInvoke'

const getForegroundColor = (backgroundColor) => (contrast(backgroundColor).isLight ? colors.grey700 : colors.white)

const TileContainer = styled.div.attrs({ className: 'mx-auto' })`
    box-sizing: border-box;
`

const TileHeader = styled.div.attrs({ className: 'relative' })`
    background-color: ${(props) => props.backgroundColor};
`

const TileHeaderTitle = styled.div.attrs({ className: 'text-3xl font-bold w-11/12' })`
    color: ${(props) => props.foregroundColor};
`

const TileBody = styled.div`
    flex: 2;
    color: ${(props) => props.foregroundColor};
    ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : colors.blue400)}
`
const HorizontalTile = ({ header, children, className, bgColor, pills, filterData }) => {
    const foregroundColor = getForegroundColor(bgColor)
    const pillsTextColor = contrast(foregroundColor).isLight ? colors.black : colors.white

    return (
        <TileContainer className={className}>
            <div className="h-full">
                <TileHeader backgroundColor={hexToRGB(bgColor, 0.76)}>
                    {header && (
                        <div className="font-bold self-center p-2">
                            <div className="flex flex-row px-5 pt-4 pb-1">
                                <TileHeaderTitle foregroundColor={foregroundColor}>{header}</TileHeaderTitle>
                                <div className="w-24">
                                    <ProgressiveImage src={CultureFactTWP} placeholder="">
                                        {(src) => (
                                            <img
                                                src={src}
                                                alt={'Top work places'}
                                                className="border rounded-full w-full"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                            </div>
                            {safeInvoke(pills, { foregroundColor, pillsTextColor, filterData })}
                        </div>
                    )}
                </TileHeader>
                <TileBody
                    className="flex justify-center items-center"
                    backgroundColor={bgColor}
                    foregroundColor={foregroundColor}>
                    {safeInvoke(children, { foregroundColor })}
                </TileBody>
            </div>
        </TileContainer>
    )
}

const StyledHorizontalTile = styled(HorizontalTile)`
    width: ${(props) => (props.width ? props.width : null)}px;
    height: ${(props) => (props.height ? props.height : null)}px;
`
StyledHorizontalTile.propTypes = {
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
    bgColor: PropTypes.string,
}

StyledHorizontalTile.defaultProps = {
    bgColor: null,
}

export default StyledHorizontalTile
