import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import type { ErrorT } from 'containers/Main/Manage/Users/models'
import showErrorAlert from 'util/showErrorAlert'

export enum StateDefinition {
    GoodForNow,
    Warning,
    Danger,
    Complete,
    Help,
    Incomplete,
    NotAplicable = -1,
}

export type AwardStatus = {
    awardId: number
    awardListId: number
    awardListParticipantId: number
    awardName: string
    awardNameAlias: string
    awardYear: number
    isSurveyLaunched: boolean
    profileDeadline: Date
    profileState: string
    profileStateDefinitionId: StateDefinition
    publisherId: number
    publisherName: string
    registrationState: string
    registrationStateDefinitionId: StateDefinition
    registrationDeadline: Date
    surveyLaunchState: string
    surveyLaunchStateDefinitionId: StateDefinition
    surveyScheduleState: string
    surveyScheduleDeadline: Date
    surveyScheduleStateDefinitionId: StateDefinition
    surveyEventId?: number | null
    surveyStartDate?: Date | null
    surveyStateDefinitionId: StateDefinition
    daysLeft: number
    isSurveyConnected: boolean
    recentSurveyEventId: number | null
    isParticipationMissed: boolean
}

export const useFetchYourAwards = () => {
    const { surveyCompanyId } = useIdentity()
    const { data, isFetching } = useWorkplaceSurveyQuery<AwardStatus[], ErrorT>(
        ['get-your-awards', surveyCompanyId],
        `AwardParticipation/YourAwards/${surveyCompanyId}`,
        {
            onError: (error) => {
                showErrorAlert('There was an error retrieving your awards', error.parsed)
            },
        }
    )

    return { data, isFetching }
}
