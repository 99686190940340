import styled from 'styled-components'
import CultureCloud from 'containers/Public/RevampWidgets/CultureCloud'
import { CultureCloudWordPreview } from 'containers/Public/RevampWidgets/CultureCloudWordPreview'
import cultureCloudPreviewBg1 from 'images/employerBranding/cultureFacts/cultureCloudPreviewBg1.svg'
import cultureCloudPreviewBg2 from 'images/employerBranding/cultureFacts/cultureCloudPreviewBg2.svg'
import type { CloudPreviewProps } from './CultureCloud.types'

const PreviewContainer = styled.div`
    background-image: url(${cultureCloudPreviewBg1});
    background-repeat: no-repeat;
`

const PreviewWrapper = styled.div`
    height: 550px;
    background-image: url(${cultureCloudPreviewBg2});
    background-repeat: no-repeat;
    background-size: contain;
`

const AbsolutePreviewWrapper = styled.div`
    position: absolute;
    top: 205px;
    right: 25px;
`

export const CultureCloudPreviewTypeTwo = ({
    ariaTitle,
    ariaDescription,
    className,
    title,
    droplets,
    backgroundColor,
    foregroundColor,
    headerBgColor,
    headerTextColor,
    type,
    height,
    dataId,
    isExpiredWidget,
    filterData,
    hasEmployerBrandingSubscription,
    minDroplets,
    isPdf,
}: CloudPreviewProps) => {
    return (
        <PreviewContainer>
            <h2 className="p-8 pb-4 text-center text-3xl">{'New Assets, Elevate your social presence'}</h2>
            <PreviewWrapper className="relative p-6">
                <CultureCloudWordPreview
                    ariaTitle={ariaTitle}
                    ariaDescription={ariaDescription}
                    droplets={droplets}
                    hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                    isExpiredWidget={isExpiredWidget}
                    filterData={filterData}
                    className="w-12 mt-2 mr-2"
                    minDroplets={minDroplets}
                    backgroundColor={backgroundColor}
                    width="360px"
                    cloudChartWidth={300}
                    cloudChartHeight={210}
                    isPreviewTwo
                />
                <AbsolutePreviewWrapper className="rounded-lg border shadow transform rotate-3">
                    <CultureCloud
                        //@ts-ignore
                        ariaTitle={ariaTitle}
                        ariaDescription={ariaDescription}
                        className={className}
                        title={title}
                        droplets={droplets}
                        backgroundColor={backgroundColor}
                        foregroundColor={foregroundColor}
                        headerBgColor={headerBgColor}
                        headerTextColor={headerTextColor}
                        type={type}
                        width="275px"
                        height={height}
                        cloudChartWidth={275}
                        cloudChartHeight={190}
                        dataId={dataId}
                        isExpiredWidget={isExpiredWidget}
                        filterData={filterData}
                        hasEmployerBrandingSubscription={hasEmployerBrandingSubscription}
                        minDroplets={minDroplets}
                        isPdf={isPdf}
                    />
                </AbsolutePreviewWrapper>
            </PreviewWrapper>
        </PreviewContainer>
    )
}
