import { LegacyButton as Button } from '@energage/components'
import linkedInIcon from 'images/employerBranding/socialShare/linkedInIcon.svg'

interface LinkedInIntegrationButtonProps {
    onClick: () => void
    disabled: boolean
}

const LinkedInIntegrationButton: React.FC<LinkedInIntegrationButtonProps> = ({ onClick, disabled }) => {
    return (
        <Button className="p-0" aria-label="Share on LinkedIn" onClick={onClick} disabled={disabled}>
            <img alt="LinkedIn Icon" src={linkedInIcon} />
        </Button>
    )
}

export { LinkedInIntegrationButton }
