import classic from 'images/employerBranding/cultureFacts/orgStrength/classic.png'
import classicGray from 'images/employerBranding/cultureFacts/orgStrength/classicGray.png'
import rectangle from 'images/employerBranding/cultureFacts/orgStrength/rectangle.png'
import rectangleCircle from 'images/employerBranding/cultureFacts/orgStrength/rectangleCircle.png'
import rectangleCircleGray from 'images/employerBranding/cultureFacts/orgStrength/rectangleCircleGray.png'
import rectangleGray from 'images/employerBranding/cultureFacts/orgStrength/rectangleGray.png'
import { PreviewTypeSelection } from '../PreviewTypeSelection'
import type { OrgStrengthsProps } from './OrgStrengths.types'

export const OrgStrengthsDesignSelection = ({ data, dataId, onChange }: OrgStrengthsProps) => {
    const SELECT_STATEMENT = 'Select Design'

    const designTypeChangeHandler = (selectedDesign: number) => {
        onChange({ ...data, selectedDesignType: selectedDesign })
    }

    return (
        <PreviewTypeSelection
            selectedDesignType={data?.selectedDesignType}
            dataId={dataId}
            designTypeChangeHandler={designTypeChangeHandler}
            selectStatement={SELECT_STATEMENT}
            rectangle={rectangle}
            rectangleGray={rectangleGray}
            rectangleCircle={rectangleCircle}
            rectangleCircleGray={rectangleCircleGray}
            classic={classic}
            classicGray={classicGray}
        />
    )
}
