import { ButtonLink as Button, Loading as Spinner } from '@energage/components'
import styled from 'styled-components'

type LoadingButtonProps = {
    children: React.ReactNode
    isLoading: boolean
    disabled?: boolean
}

const StyledSpinner = styled(Spinner).attrs({ className: 'ml-2 flex items-center' })`
    .loading__title {
        margin-bottom: 0;
    }

    && {
        padding: 0;
    }
`

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, isLoading, disabled = false, ...props }) => (
    <Button {...props} isDisabled={isLoading || disabled} appearance="secondary">
        <div className="flex content-center justify-center cursor-pointer">
            {children}
            {isLoading && <StyledSpinner text={false} size="small" />}
        </div>
    </Button>
)

export default LoadingButton
