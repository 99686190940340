import { CardBody, CardContent, SummaryCard } from '../../Common/SummaryCard'

export const ErrorRecipientsSet = () => (
    <SummaryCard padding="tight" status="error">
        <CardBody>
            <CardContent>
                <p>{'Something went wrong'}</p>
            </CardContent>
        </CardBody>
    </SummaryCard>
)
