import { forwardRef, useRef } from 'react'
import { Alarm, CalendarToday, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import cx from 'clsx'
import DateTimePicker from 'react-datepicker'
import styled from 'styled-components'

const DatePickerIcon = styled(Icon).attrs({
    className: 'ml-3 flex-shrink-0 absolute cursor-pointer',
    size: 18,
    color: colors.grey700,
})`
    right: 0.8rem;
`

const DateTimeInput = forwardRef(({ className, inputClassName, icon, datePickerRef, ...props }, ref) => (
    <div className={cx(className, 'w-32 h-10 items-center inline-flex relative')}>
        <input
            ref={ref}
            className={cx(
                'w-full rounded border border-grey300 p-2 h-full',
                props.disabled ? 'bg-grey200' : 'bg-white',
                inputClassName
            )}
            type="text"
            {...props}
        />
        <DatePickerIcon
            as={icon}
            onClick={() => datePickerRef?.current?.setOpen(!datePickerRef?.current?.isCalendarOpen())}
        />
    </div>
))

const CalendarContainer = ({ className, children }) => <div className={className}>{children}</div>

const defaultPopperModifiers = {
    preventOverflow: {
        enabled: true,
        escapeWithReference: false,
        boundariesElement: 'viewport',
    },
    flip: {
        behavior: 'clockwise',
    },
}

const DatePicker = ({
    date,
    holidays,
    className = 'mr-8',
    inputClassName,
    popperModifiers = defaultPopperModifiers,
    ...props
}) => {
    const ref = useRef(null)
    return (
        <DateTimePicker
            {...props}
            selected={date}
            highlightDates={holidays}
            useWeekdaysShort
            popperModifiers={popperModifiers}
            calendarContainer={CalendarContainer}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={
                <DateTimeInput
                    className={className}
                    inputClassName={inputClassName}
                    icon={CalendarToday}
                    datePickerRef={ref}
                />
            }
            ref={ref}>
            <div className="text-center text-xs italic">
                {'Available dates are shown in '}
                <span className="ml-px font-bold not-italic">{'black'}</span>
            </div>
        </DateTimePicker>
    )
}

const TimePicker = ({ date, ...props }) => {
    const ref = useRef(null)
    return (
        <DateTimePicker
            {...props}
            selected={date}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="p"
            calendarContainer={CalendarContainer}
            customInput={<DateTimeInput className="mr-2 justify-between" icon={Alarm} datePickerRef={ref} />}
            wrapperClassName="inline-flex"
            timeCaption=""
            ref={ref}
        />
    )
}

export { DatePicker, TimePicker }
