import join from 'lodash/join'

export function conjunctionJoin(conjunction: string, values: string[]) {
    const len = values.length

    if (len === 0) {
        return ''
    }

    if (len === 1) {
        return values[0]
    }

    if (len === 2) {
        return `${values[0]} ${conjunction} ${values[1]}`
    }

    return `${join(values.slice(0, len - 1), ', ')} ${conjunction} ${values.slice(len - 1, len)}`
}
