import { useEffect, useState } from 'react'
import { LegacyButton as Button, Loading as Spinner } from '@energage/components'
import { parse } from 'query-string'
import { useLocation } from 'react-router'
import { useAuthentication } from 'components/Authentication'
import { setLoggedIn } from 'store'
import auth from 'util/auth'

export const LoginDenied = () => (
    <div className="p-20 flex justify-center">
        <div className="p-20 m-10 mt-20 text-center bg-white rounded-lg shadow-lg">
            <h2 className="text-center">{'Access denied'}</h2>
            <p className="pt-3">
                {"You do not have access to this resource. If this isn't expected, please escalate to your manager."}
            </p>

            <div className="mt-3">
                <Button variant="link" onClick={auth.logout}>
                    {'Login as a different user'}
                </Button>
            </div>
        </div>
    </div>
)

export const ConnectionError = () => {
    const { search } = useLocation()
    const { error, connection, error_description } = parse(search)
    const timestamp = new Date(Date.now()).toISOString()

    function copyToClipboard() {
        navigator.clipboard.writeText(
            `Timestamp: ${timestamp}\nConnection: ${connection}\nError: ${error}\nDescription: ${error_description}`
        )
    }

    return (
        <div className="p-20 flex justify-center">
            <div className="p-20 m-10 mt-20 text-center bg-white rounded-lg shadow-lg">
                <h2 className="text-center">{'Connection Error'}</h2>
                <p className="pt-3">
                    {"There was a problem with your connection. If this isn't expected, please escalate to support."}
                </p>

                <div className="mt-3">
                    <Button variant="link" onClick={auth.logout}>
                        {'Login as a different user'}
                    </Button>

                    <div className="mt-5 text-left">
                        <h3 className="h4">{'Troubleshooting details'}</h3>
                        <p>{'If you contact your administrator, send this info to them.'}</p>
                        <div className="mt-5">
                            <p>
                                <b>{'Timestamp: '}</b>
                                {timestamp}
                            </p>
                            <p>
                                <b>{`Connection: `}</b>
                                {connection}
                            </p>
                            <p>
                                <b>{`Error: `}</b>
                                {error}
                            </p>
                            <p>
                                <b>{`Description: `}</b>
                                {error_description}
                            </p>
                        </div>
                    </div>
                    <Button variant="link" onClick={copyToClipboard}>
                        {'Copy info to clipboard'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const getError = (err) => {
    // https://auth0.com/docs/libraries/error-messages
    switch (err?.error) {
        case 'invalid_request':
            return ConnectionError()
        default:
            return LoginDenied()
    }
}

const Callback = ({ history }) => {
    const [error, setError] = useState(null)
    const { accessToken, isLoggedIn } = useAuthentication()
    const { search } = useLocation()
    const { nextPath: nextPathParam, error: queryError, error_description: queryErrorDescription } = parse(search)

    // We must honor the query param first
    // if not, check the local storage incase the app set it at one point, it will automatically delete teh key for us
    // otherwise default to dashboard main page
    const nextPath = nextPathParam || auth.getRedirectPath() || '/dashboard'

    // if user goes here by mistake but is logged in
    if (accessToken && isLoggedIn) {
        history.replace(nextPath)
    }

    useEffect(() => {
        if (queryError) {
            setError({
                error: queryError,
                errorDescriptor: queryErrorDescription,
            })
            return
        }

        auth.handleAuthentication()
            .then(() => {
                const accessToken = auth.getAccessToken()
                const idToken = auth.getIdToken()

                setLoggedIn(accessToken, idToken)
                history.replace(nextPath)
            })
            .catch((err) => {
                if (err) {
                    setError(err)
                    return
                }

                // User navigated here without a hash and is not logged in
                history.replace(nextPath)
            })
    }, [history, nextPath, queryError, queryErrorDescription])

    return error ? getError(error) : <Spinner />
}

export default Callback
