import find from 'lodash/find'
import { SurveyHistoryItemStatus } from 'components/SurveyHistoryTable/surveyStatus'

export const findInProgressTWP = (surveys) =>
    find(surveys, (survey) => {
        const status = survey.surveyHistoryItemStatus
        return (
            status !== SurveyHistoryItemStatus.Closed &&
            status !== SurveyHistoryItemStatus.InsightsReady &&
            survey.isTopWorkplaceParticipant
        )
    })
