import { alert } from '@energage/components'
import isArray from 'lodash/isArray'
import join from 'lodash/join'
import map from 'lodash/map'

const showErrorAlert = (msg, errors) => {
    const errorMessages = isArray(errors)
        ? join(
              map(errors, ({ message }) => message),
              ', '
          )
        : errors?.message
    alert.danger(`${msg} ${errorMessages ? `\n : ${errorMessages}` : ''}`)
}

export default showErrorAlert
