import type { ThankYouRevampSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import bgAwardAnnouncement from 'images/awardAnnouncement/bgAwardAnnouncementRevamp.png'
import { BgContainer } from '../BgContainer'

export const ThankYouForParticipatingRevamp = ({ companyName, awardAliasName, listName }: ThankYouRevampSection) => {
    const awardName = awardAliasName ? awardAliasName : listName + ' awards'

    return (
        <BgContainer
            backgroundImage={`url(${bgAwardAnnouncement})`}
            className="p-20 text-white bg-cover md:bg-center flex items-center justify-center">
            <div className="m-auto">
                <h1 className="sm:text-4xl md:text-6xl md:text-5xl text-center font-normal">
                    {'Thank you for participating in '}
                </h1>
                <h1 className="sm:text-4xl md:text-6xl md:text-5xl text-center mb-8 font-normal mx-auto w-4/5">
                    {awardName}
                </h1>
                <h2 className="sm:text-xl md:text-4xl text-lg leading-relaxed text-center font-normal">
                    {`Unfortunately, ${companyName} did not win this year.`}
                </h2>
            </div>
        </BgContainer>
    )
}
