import PropTypes from 'prop-types'
import { ArrowForward } from '@energage/icons'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import TopNavIcon from './TopNavIcon'

const DisabledLink = styled.span`
    && {
        opacity: 0.6;
        cursor: default;
    }
`

const Link = ({ children, disabled, ...props }) => {
    if (disabled) {
        return (
            <DisabledLink className={props.className} title={typeof disabled === 'string' ? disabled : null}>
                {children}
            </DisabledLink>
        )
    } else {
        return <NavLink {...props}>{children}</NavLink>
    }
}

const TopNavLink = ({ label, sequential, icon, status = null, as: Component = Link, ...linkProps }) => {
    return (
        <>
            {sequential && <ArrowForward className="hidden sm:block" size={12} />}
            <Component {...linkProps}>
                {icon && <TopNavIcon className="mr-2" icon={icon} status={status} />}
                <span>{label}</span>
            </Component>
        </>
    )
}

TopNavLink.propTypes = {
    label: PropTypes.string,
    sequential: PropTypes.bool,
    iconName: PropTypes.string,
    status: PropTypes.oneOf([null, undefined, 'complete', 'in-progress', 'alert']),
}

export default TopNavLink
