import type { SsoFormProps } from './models'

export const formatCreateSamlData = ({
    base64SigningCertificate,
    signInUrl,
    domains,
    userIdAttribute,
    idpSsoEnabled,
}: SsoFormProps) => {
    return {
        base64SigningCertificate,
        signInUrl,
        domains: domains.map((domain) => domain.value),
        userIdAttribute,
        idPInitiatedEnabled: idpSsoEnabled,
    }
}
