import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import head from 'lodash/head'
import noop from 'lodash/noop'
import size from 'lodash/size'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'
import Upload, { HeaderMapperTable } from 'components/Upload'
import { getImportColumnMapping, matchHeaderAliases } from 'components/Upload/helpers'
import config from 'config'
import { routes } from 'constants/routes'
import { usePost, useVisibility } from 'hooks'
import { defaultColumns } from './defaultColumns.ts'
import UploadValidationError from './UploadValidationError'
import useBulkUpload from './useBulkUpload'
import useSyncSurvey from './useSyncSurvey'

const Header = () => (
    <>
        <div className="mt-6 sm:m-0 float-right sm:float-none sm:relative">
            <Button
                className="sm:absolute right-0 m-4"
                variant="secondary"
                as={Link}
                to={routes.manage.employees()}
                outline>
                {'Close'}
            </Button>
        </div>
        <div className="clearfix" />
    </>
)

const bulkUploadColumns = {
    ...defaultColumns,
    employeeId: {
        isRequired: false,
        alwaysUpload: true,
        header: 'Employee ID',
        matchHeader: matchHeaderAliases,
        aliases: ['employeeid', 'id', 'memberid'],
    },
    supervisor: {
        isRequired: false,
        alwaysUpload: true,
        header: "Employee's Supervisor/Manager Email",
        matchHeader: matchHeaderAliases,
        aliases: [
            'supervisor',
            'manager',
            'managers',
            'managersupervisor',
            'boss',
            'leader',
            'directsupervisor',
            'management',
            'principal',
            'supervisors',
            'teamlead',
            'supervisormanager',
            'projectmanager',
            'managerdirector',
            'operationsmanager',
            'departmenthead',
            'departmentmanager',
            'employeessupervisormanager',
            'employeessupervisormanageremail',
            'employeessupervisor',
            'employeessupervisoremail',
            'employeesmanager',
            'employeesmanageremail',
            'employeesboss',
            'employeesbossemail',
            'employeesupervisormanager',
            'employeesupervisormanageremail',
            'employeesupervisor',
            'employeesupervisoremail',
            'employeemanager',
            'employeemanageremail',
            'employeeboss',
            'employeebossemail',
        ],
    },
    startDate: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Start Date',
        matchHeader: matchHeaderAliases,
        aliases: ['startdate', 'startdatemmddyyyy', 'start'],
    },
    caregiver: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Caregiver',
        matchHeader: matchHeaderAliases,
        aliases: ['caregiver', 'iscaregiver'],
    },
    birthDate: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Birth Date',
        matchHeader: matchHeaderAliases,
        aliases: ['birthdate', 'birthdatemmddyyyy', 'dob', 'dateofbirth', 'dobmmddyyyy', 'dateofbirthmmddyyyy'],
    },
    locationName: {
        isRequired: false,
        alwaysUpload: false,
        header: 'Location Name',
        matchHeader: matchHeaderAliases,
        aliases: ['locationname'],
    },
}

const BulkUpload = ({ title, onUpload = noop, isFreshUpload = true }) => {
    const { visible: partialModalVisible, show: partialModalShow, hide: partialModalHide } = useVisibility()
    const { visible: syncSurveyVisible, show: syncSurveyShow, hide: syncSurveyHide } = useVisibility()
    const {
        organizationId,
        surveyCompanyId,
        surveyUserId,
        permissions: { hasInsights },
    } = useIdentity()

    const { doPost } = usePost({
        url: `${config.api.platform}/organizations/${organizationId}/members/BulkUpload`,
    })

    /**
     * Passing a noop here for onPostComplete because where it was originally being called ..
     * it was never actually being called, due to a bad destructure in useBulkUpload for
     * hasActiveSurveyEvents. i.e.:
     *
     * if (hasActiveSurveyEvents) { <--- hasActiveSurveyEvents has been undefined for who knows how long
     *     onPostComplete(data)
     * }
     *
     * the passed in onUpload gets called from the onSyncSuccess, so it always appeared to be working
     */
    const { headerMapping, rows, data, loading, error, onFileDrop, onSubmit, reset } = useBulkUpload(noop, {
        getFileUploadMSTags: () => {
            return `CompanyId=${surveyCompanyId},SurveyUserId=${surveyUserId}`
        },
        postMembers: doPost,
        memberColumns: bulkUploadColumns,
    })

    const onSyncSuccess = useCallback(() => {
        syncSurveyHide()
        onUpload(data)
    }, [syncSurveyHide, onUpload, data])

    const { onSync, loading: syncLoading } = useSyncSurvey(onSyncSuccess)

    const errors = data?.validationErrors ?? error?.errors ?? error
    const hasErrors = size(errors) > 0
    const requiresMapping = headerMapping && rows && !data && !loading
    const [uploadedFile, setUploadedFile] = useState(null)

    useEffect(() => {
        if (data?.hasActiveSurveyEvents) {
            syncSurveyShow()
        } else if (data?.successful) {
            onSyncSuccess()
        }
    }, [data, syncSurveyShow, onSyncSuccess])

    const handleFileDrop = useCallback(
        (file) => {
            if (isFreshUpload) {
                partialModalShow()
            } else {
                onFileDrop(file, true)
            }
            setUploadedFile(file)
        },
        [partialModalShow, isFreshUpload, onFileDrop, setUploadedFile]
    )

    const handleUpload = useCallback(
        (isFullUpload) => {
            partialModalHide()
            onFileDrop(uploadedFile, isFullUpload)
        },
        [partialModalHide, onFileDrop, uploadedFile]
    )

    const handleFullUpload = () => handleUpload(true)
    const handlePartialUpload = () => handleUpload(false)

    if (requiresMapping) {
        const importColumnMapping = getImportColumnMapping(headerMapping, head(rows))

        return (
            <HeaderMapperTable
                setColumnMapping={(columnMapping, newColumnMapping) => {
                    headerMapping[columnMapping.key].mapping = newColumnMapping
                }}
                importColumnMapping={importColumnMapping}
                columns={bulkUploadColumns}
                onClose={reset}
                onSave={onSubmit}
                loading={loading}
            />
        )
    }

    return (
        <Container>
            {isFreshUpload && <Header />}
            <Inset padding className="text-center">
                {!title && <h4>{hasErrors ? 'Upload Not Valid' : 'Add Your Employees'}</h4>}
                {title}
                <p>
                    {hasErrors
                        ? 'Once you’ve corrected these issues, try the upload again.'
                        : !title && "Let's put your organization together!"}
                </p>
            </Inset>
            {hasErrors && <UploadValidationError errors={errors} />}
            <Upload
                templateName={hasInsights ? 'Employee Upload Template.xlsx' : 'Employee List Template.xlsx'}
                errors={errors}
                onFileDrop={handleFileDrop}
                data={data}
                loading={loading}
            />
            <Modal
                isOpen={partialModalVisible}
                onClose={partialModalHide}
                className="m-0 md:ml-48 xl:w-1/5 lg:w-1/3 sm:w-2/5 w-2/3 max-w-md">
                <Inset padding className="flex flex-col items-center text-center mx-8">
                    <p className="mb-2">
                        {`This survey event already has an employee data sheet uploaded.
                            Do you wish to add recipients to the current survey or do you wish to replace the existing file with a new one?`}
                    </p>
                    <Button className="m-2" variant="primary" onClick={handlePartialUpload}>
                        {'Add to current list'}
                    </Button>
                    <Button className="m-2" variant="primary" onClick={handleFullUpload} outline>
                        {'Replace current list'}
                    </Button>
                </Inset>
            </Modal>
            <Modal
                isOpen={syncSurveyVisible}
                title={'Update active surveys?'}
                onClose={onSyncSuccess}
                className="xl:w-2/5 lg:w-3/5 md:w-2/3 sm:w-4/5 w-5/6">
                <div className="px-4 py-2">
                    <p className="text-xl whitespace-pre-wrap">
                        {
                            'Changes to your employee list can be applied to your actively running surveys. Newly added employees will receive an invitation, and removed employees will not receive any more reminders. Would you like to update the recipients for your current surveys?'
                        }
                    </p>
                </div>
                <Inset padding className="clearfix">
                    <div className="flex justify-end">
                        <Button
                            variant={'secondary'}
                            data-event-id={'SyncActiveSurveys - Just Save Changes'}
                            outline
                            onClick={onSyncSuccess}
                            disabled={syncLoading}>
                            {'Just Save Changes'}
                        </Button>
                        <Button
                            variant={'secondary'}
                            data-event-id={'SyncActiveSurveys - Update Recipients'}
                            className="ml-6"
                            onClick={onSync}
                            disabled={syncLoading}>
                            {'Update Recipients'}
                        </Button>
                    </div>
                </Inset>
            </Modal>
        </Container>
    )
}

BulkUpload.propTypes = {
    title: PropTypes.node,
    onUpload: PropTypes.func,
    isFreshUpload: PropTypes.bool,
}

export default BulkUpload
