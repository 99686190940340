import { alert } from '@energage/components'
import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePost } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import { useScheduleActiveSurveysSync } from '../../UpdateActiveSurveys'
import useSetTestInviteesDemographics from '../../useSetTestInviteesDemographics'

function transformErrorMessages(errors) {
    let newErrors = []

    const newError = (message) => newErrors.push({ message })

    forEach(errors, (error) => {
        if (!error.code) {
            newError('There was an error saving the employee')
            return
        }

        switch (error?.code) {
            case 'InvalidApproximateSalary':
                newError('Approximate Salary must be a valid number')
                break
            case 'InvalidPhoneNumber':
                newError('Phone number must be a valid phone number')
                break
            case 'MemberAlreadyExists':
                if (error?.ExistingEmailAddress) {
                    newError('This email address has already been added')
                }
                if (error?.ExistingPhoneNumber) {
                    newError('This phone number has already been added')
                }
                if (error?.ExistingExternalId) {
                    newError('This employee ID has already been added')
                }
                break
            case 'MemberIdentifier':
                newError('All members require an email address, phone number or employee ID')
                break
            default:
                newError(error?.message)
        }
    })

    return newErrors
}

function useSaveEmployee(onComplete = noop, employeeId = null, modifiedDateTime = null) {
    const action = employeeId ? `/${employeeId}` : ''
    const { organizationId } = useIdentity()
    const { doSync } = useScheduleActiveSurveysSync()
    const { mutate: doSetTestInviteesDemographics } = useSetTestInviteesDemographics()
    const { doPost, isPosting, reset } = usePost({
        url: `${config.api.platform}/Organizations/${organizationId}/Members${action}`,
        onComplete: (data) => {
            onComplete(data)
            alert.success('Employee saved successfully.')
        },
        onError: (error) => {
            showErrorAlert('Error', transformErrorMessages(error))
            reset()
        },
    })

    const save = ({
        emailAddress,
        phoneNumber,
        departmentId,
        supervisor,
        firstName,
        lastName,
        startDate,
        approximateSalary,
        isFullTime,
        jobGrade,
        locationNameId,
    }) => {
        const employee = employeeId ? { memberId: employeeId, modifiedDateTime } : {}
        doPost({
            ...employee,
            emailAddress,
            phoneNumber,
            departmentId,
            supervisor,
            firstName,
            lastName,
            startDate,
            approximateSalary,
            isFullTime,
            jobGrade,
            locationNameId,
        })
            .then(doSync)
            .then(() => doSetTestInviteesDemographics())
    }

    return { save, isSaving: isPosting }
}

export default useSaveEmployee
