import { useBrandingQuery } from 'api'

const useFetchMediaKitAwardImages = (
    publisherId,
    surveyCompanyId,
    publishYear,
    isNationalStandard,
    awardCategoryId
) => {
    const { data, isFetching, isError } = useBrandingQuery(
        ['mediaKitSkyLineImage', surveyCompanyId, publisherId, publishYear, isNationalStandard],
        `SurveyCompanies/${surveyCompanyId}/mediaKit/${publisherId}/${publishYear}/${awardCategoryId}/${isNationalStandard}/path`
    )

    return {
        pressReleaseResponse: data,
        isFetching,
        isError,
    }
}

export default useFetchMediaKitAwardImages
