import { useEffect, useState } from 'react'
import { Loading as Spinner } from '@energage/components'
import { Text } from '@energage/typography'
import cx from 'clsx'
import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import uniqBy from 'lodash/uniqBy'
import styled from 'styled-components'
import FancyCheckbox from 'components/FancyCheckbox'
import Jiggle from 'components/Jiggle'
import Pagination from 'components/Paginate'
import { previewType } from '../utils'

const maxCommentsSelection = 3

const LoveMyJobCommentColumns = styled(Pagination).attrs({ className: 'mb-5' })`
    columns: 1 auto;

    input {
        vertical-align: top;
        margin-top: 3px;
    }
`

const StyledLabel = styled.label`
    page-break-inside: avoid;
    break-inside: avoid;
`

const LoveMyJobCommentSelector = ({ data, onChange, isLoading, dataId, hasEmployerBrandingSubscription }) => {
    const [error, setError] = useState(false)
    const moreComments = filter(data?.comments, (d) => d.numberOfCharacters > 250)
    const comments =
        data?.selectedDesignType === previewType.Classical
            ? data?.comments
            : filter(data?.comments, (d) => d.numberOfCharacters <= 250)

    useEffect(() => {
        if (!head(filter(comments, 'isSelected'))) {
            const newComments = map(comments, (comment, index) => {
                if (index < 3) {
                    return {
                        ...comment,
                        isSelected: true,
                    }
                }
                return comment
            })

            const allComments = uniqBy([...newComments, ...moreComments], 'text')
            onChange({ ...data, comments: allComments })
        }
        // eslint-disable-next-line
    }, [data?.selectedDesignType])

    const setCommentSelected = (comment) => {
        return (e) => {
            const newComments = map(comments, (d) => {
                if (d.text === comment.text) {
                    return {
                        ...d,
                        isSelected: e.target.checked,
                    }
                }
                return d
            })

            const selectedCount = size(filter(newComments, 'isSelected'))
            if (selectedCount < 1 || selectedCount > maxCommentsSelection) {
                setError(true)
                setTimeout(() => setError(false), 250)
                return
            }

            const allComments = uniqBy([...newComments, ...moreComments], 'text')
            onChange({ ...data, comments: allComments })
        }
    }

    if (!hasEmployerBrandingSubscription) {
        return <h2 className="text-center p-12">{'Unlock Employer Branding to get access to your data!'}</h2>
    }

    if (!head(data?.comments)) {
        return ''
    }

    return (
        <>
            <Text textStyle="title6" className="mt-8 text-sm">
                <h5>{'Comments to Display'}</h5>
            </Text>
            <Jiggle active={error}>
                <div className={cx({ 'text-red500': error }, 'mt-1 mb-2 text-xs')}>
                    {`You can select up to ${maxCommentsSelection} comments to display in your widget.`}
                </div>
            </Jiggle>
            {isLoading ? (
                <Spinner />
            ) : (
                <LoveMyJobCommentColumns
                    items={comments}
                    itemsPerPage={6}
                    renderItem={(item) => (
                        <StyledLabel
                            className="block mb-3"
                            key={item.text}
                            data-event-id={dataId && `${dataId}-comment-selection`}>
                            <FancyCheckbox
                                checked={item.isSelected}
                                className="mr-2"
                                onChange={setCommentSelected(item)}
                            />
                            <span className="text-base text-primary-dark inline-block w-4/5 align-text-top">
                                {item.text}
                            </span>
                        </StyledLabel>
                    )}
                    dataId={dataId}
                />
            )}
        </>
    )
}

export default LoveMyJobCommentSelector
