import { useQueryClient } from 'react-query'
import { getFetchConfig, usePost } from 'hooks'
import chain from 'util/chainFunctions'
import { updateTwpWorkflow } from '../components/TwpWorkflow'

export function usePostWithTaskCompletion(options) {
    const postOptions = getFetchConfig(options)
    const queryClient = useQueryClient()
    return usePost({
        ...postOptions,
        onComplete: chain(options.onComplete, () => updateTwpWorkflow(queryClient)),
    })
}
