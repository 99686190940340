import { LONG_ISLAND_PUBLISHER_ID, MINNEAPOLIS_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'

export const getMarginValueForMediaScreen = (publisherId) => {
    switch (publisherId) {
        case MINNEAPOLIS_PUBLISHER_ID:
            return '0rem'
        case LONG_ISLAND_PUBLISHER_ID:
            return '1.2rem'
        default:
            return '1rem'
    }
}
