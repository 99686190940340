import { useEffect, useMemo, useState } from 'react'
import config from 'config'
import { useLazyFetch } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'

function useFetchPaymentMethods(organizationId, accountDataId) {
    const [savedPaymentMethods, setSavedPaymentMethods] = useState(null)
    const [supportedPaymentMethods, setSupportedPaymentMethods] = useState(null)

    const {
        isLoading: loadingSavedPaymentMethods,
        error: fetchSavedPaymentMethodsError,
        doFetch: fetchSavedPaymentMethods,
    } = useLazyFetch({
        url: `${config.api.platform}/organizations/${organizationId}/accounts/${accountDataId}/SavedPaymentMethods`,
        onComplete: setSavedPaymentMethods,
        onError: (error) => {
            showErrorAlert('There was an error in fetching SavedPaymentMethods', error)
        },
    })

    const {
        isLoading: loadingSupportedPaymentMethods,
        error: fetchSupportedPaymentMethodsError,
        doFetch: fetchSupportedPaymentMethods,
    } = useLazyFetch({
        url: `${config.api.platform}/organizations/${organizationId}/Payment/SupportedPaymentMethods`,
        onComplete: setSupportedPaymentMethods,
        onError: (error) => {
            showErrorAlert('There was an error in fetching SupportedPaymentMethods', error)
        },
    })

    useEffect(() => {
        if (!organizationId || !accountDataId || savedPaymentMethods) {
            return
        }
        fetchSavedPaymentMethods()
    }, [organizationId, fetchSavedPaymentMethods, accountDataId, savedPaymentMethods])

    useEffect(() => {
        if (!organizationId || supportedPaymentMethods) {
            return
        }
        fetchSupportedPaymentMethods()
    }, [organizationId, fetchSupportedPaymentMethods, supportedPaymentMethods])

    return useMemo(
        () => ({
            loading: loadingSavedPaymentMethods || loadingSupportedPaymentMethods,
            error: fetchSavedPaymentMethodsError || fetchSupportedPaymentMethodsError,
            savedPaymentMethods,
            supportedPaymentMethods,
            fetchSupportedPaymentMethods,
            fetchSavedPaymentMethods,
        }),
        [
            loadingSavedPaymentMethods,
            loadingSupportedPaymentMethods,
            fetchSavedPaymentMethodsError,
            fetchSupportedPaymentMethodsError,
            savedPaymentMethods,
            supportedPaymentMethods,
            fetchSupportedPaymentMethods,
            fetchSavedPaymentMethods,
        ]
    )
}

export default useFetchPaymentMethods
