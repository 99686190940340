import { useContext } from 'react'
import PropTypes from 'prop-types'
import { useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import { usePreviewQuery } from '../../Preview'
import { useSurveySetupContext } from '../../SurveySetup'
import SurveySetupContext from '../../SurveySetupContext'
import SummaryCardSkeleton from '../Common/SummaryCardSkeleton'
import SurveySetupTile from './SurveySetupTile'

const SurveySetupTileFetch = ({ surveyEventId }) => {
    const { surveyCompanyId } = useIdentity()
    const { refreshWorkflow } = useContext(SurveySetupContext)

    const {
        data,
        isLoading: isSurveySetupSummaryLoading,
        error: errorSurveySetupSummary,
    } = useWorkplaceSurveyQuery(['survey-setup-summary', surveyEventId], `SurveySummary/${surveyEventId}/SurveySetup`)

    const {
        previewUrl,
        isLoading: isPreviewStateLoading,
        isConfirmed,
    } = usePreviewQuery(surveyEventId, surveyCompanyId, refreshWorkflow)

    const { surveyTypeId, templateName, surveyGenerationId } = useSurveySetupContext()

    if (isSurveySetupSummaryLoading || isPreviewStateLoading) {
        return <SummaryCardSkeleton />
    }

    const surveySetupSummaryData = data || {}

    return (
        <div data-testid="survey-setup-summary-tile">
            <SurveySetupTile
                {...{
                    surveyEventId,
                    error: errorSurveySetupSummary,
                    previewUrl,
                    isConfirmed,
                    surveyTypeId,
                    ...surveySetupSummaryData,
                    templateName,
                    surveyGenerationId,
                }}
            />
        </div>
    )
}

SurveySetupTileFetch.propTypes = {
    surveyEventId: PropTypes.string.isRequired,
}

export default SurveySetupTileFetch
