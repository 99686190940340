import { useState } from 'react'
import { Alert, LegacyButton as Button } from '@energage/components'
import { yupResolver } from '@hookform/resolvers/yup'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { FormProvider, useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import Inset from 'components/Inset'
import { REMOTE_WORK_AWARD_ID, WOMEN_LED_AWARD_ID } from 'containers/Main/EmployerRecognition/constants'
import type { FieldSet, FieldT, IFormInputs, Location } from './awardRegistration.types'
import { useAwardRegistrationData } from './AwardRegistrationProvider'
import { awardRegistrationSchema } from './awardRegistrationSchema'
import { LocationFormOptions, WOMEN_LED_AWARD_CONFIRM_HEADING } from './constants'
import { Field } from './Field'
import { getfieldSet } from './fieldSet'
import { getDefaultValues } from './getDefaultValues'
import ProfileDeadlineConfirmModal from './ProfileDeadlineConfirmModal'
import { RegionLocationForm } from './RegionLocationForm'
import { useSaveAwardRegistration } from './useSaveAwardRegistration'

const addIsDeleted = (locations: Location[]) => {
    return addIndex(map(locations, (location) => ({ ...location, isDeleted: false })))
}

const addIndex = (locations: Location[]) => {
    return map(locations, (location: Location, index: number) => ({ ...location, index }))
}

const addIsOutsideRegion = (allLocations: Location[], invalidLocations: Location[]): Location[] => {
    return map(allLocations, (location) => ({
        ...location,
        isOutsideRegion: !!find(invalidLocations, {
            id: location.id,
            name: location.name,
            employeeCount: +location.employeeCount,
            streetAddress: location.streetAddress,
            city: location.city,
            stateAbbreviation: location.stateAbbreviation,
            zipCode: location.zipCode,
        }),
    }))
}

export const AwardRegistrationForm = () => {
    const [isProfileDeadlineModalVisible, setIsProfileDeadlineModalVisible] = useState(false)
    const [registrationDetails, setRegistrationDetails] = useState<IFormInputs>()
    const data = useAwardRegistrationData()
    const { awardRegistrationInfo, sectorInfo, hasFullyRemoteParticipation, states, zipCodes, sectors } = data
    const {
        awardListParticipantId,
        awardName,
        awardId,
        publisher,
        isVisibleToMediaPartner,
        region: { name: regionName },
        locations,
        salesforceYear,
    } = awardRegistrationInfo
    const isRemoteWorkAward = awardId === REMOTE_WORK_AWARD_ID
    const isWomenLedAward = awardId === WOMEN_LED_AWARD_ID

    const { id: publisherId, isNational } = publisher
    const [formLocations, setFormLocations] = useState(addIsDeleted(locations))
    const [showAwardAlert, setShowAwardAlert] = useState(false)
    const formMethods = useForm({
        mode: 'onChange',
        context: {
            states,
            zipCodes,
            sectors,
            isNational,
            locations: formLocations,
        },
        defaultValues: getDefaultValues(
            { ...awardRegistrationInfo, locations: formLocations },
            sectorInfo,
            hasFullyRemoteParticipation,
            isRemoteWorkAward,
            isWomenLedAward
        ),
        resolver: yupResolver(awardRegistrationSchema),
    })

    const handleError = async (locations: Location[]) => {
        const updatedLocations = addIsOutsideRegion(formLocations, locations)
        await setFormLocations(updatedLocations)
        formMethods.resetField('locations', { defaultValue: locations })
    }

    const { saveRegistration, isLoading, error } = useSaveAwardRegistration({
        awardListParticipantId,
        publisherId,
        salesforceYear,
        handleError,
    })

    const [deletedLocation, setDeletedLocations] = useState<Location[]>([])
    const { watch, setValue, getValues, handleSubmit } = formMethods
    const locStatus = watch('locationStatus')
    const showAwardValidation = !watch('hasAgreedToEligibilityCriteria') && showAwardAlert

    const fieldSets = getfieldSet(
        data,
        showAwardValidation,
        watch('organization.parentSector'),
        isRemoteWorkAward,
        isWomenLedAward,
        locStatus
    )

    const onFinishLater = () => {
        const isRemoteAwardSelected =
            watch('remoteWorkAward.confirmRemoteAwardChkOne') || watch('remoteWorkAward.confirmRemoteAwardChkTwo')

        if (isRemoteAwardSelected) {
            setShowAwardAlert(true)
        }

        setValue('isDraft', true)
        setValue('isLocationFormEmpty', formLocations.length === 0)
        handleSubmit(validateAndSubmit as () => void)()
    }

    const deleteLocation = (location: Location) => {
        setDeletedLocations([...deletedLocation, { ...location, isDeleted: true }] as Location[])
    }

    const onConfirm = () => {
        const isAwardSelected = watch('hasAgreedToEligibilityCriteria')
        if (!isAwardSelected) {
            setShowAwardAlert(true)
        }
        setValue('isDraft', false)
        setValue('isLocationFormEmpty', formLocations.length === 0)
        handleSubmit(validateAndSubmit as () => void)()
    }

    const validateAndSubmit: SubmitHandler<IFormInputs> = (data) => {
        if (!getValues('isDraft') && isVisibleToMediaPartner) {
            setRegistrationDetails(data as IFormInputs)
            setIsProfileDeadlineModalVisible(true)
            return
        }
        const nonEmptyLocations = filter(
            [...data.locations, ...deletedLocation],
            (location: Location) =>
                location.streetAddress && location.city && location.stateAbbreviation && location.zipCode
        )

        saveRegistration({
            ...data,
            locations: nonEmptyLocations,
        } as IFormInputs)
    }

    const handleFormsubmit = () => {
        setIsProfileDeadlineModalVisible(false)

        if (registrationDetails) {
            saveRegistration({
                ...registrationDetails,
                locations: [...registrationDetails.locations, ...deletedLocation],
            } as IFormInputs)
        }
    }

    return (
        <>
            <ProfileDeadlineConfirmModal
                show={isProfileDeadlineModalVisible}
                onSubmit={handleFormsubmit}
                award={awardRegistrationInfo}
                isLoading={isLoading}
            />
            <FormProvider {...formMethods}>
                <form className="px-2 md:px-16">
                    {map(
                        fieldSets,
                        (fieldSet: FieldSet, index: number) =>
                            fieldSet.isVisible && (
                                <div key={index}>
                                    {fieldSet.heading && (
                                        <h6 className="font-bold text-base my-3">{fieldSet.heading}</h6>
                                    )}
                                    {fieldSet.heading === `${awardName} requirements` && showAwardValidation && (
                                        <Alert variant="danger" className="mb-4 mt-3">
                                            {`These requirements must be confirmed to participate in this award program.`}
                                        </Alert>
                                    )}
                                    {fieldSet.heading === WOMEN_LED_AWARD_CONFIRM_HEADING && showAwardValidation && (
                                        <Alert variant="danger" className="mb-4 mt-3">
                                            {`This requirement must be confirmed to participate in this award program.`}
                                        </Alert>
                                    )}
                                    {fieldSet.subTitle && <p className="text-base">{fieldSet.subTitle}</p>}
                                    {fieldSet.fieldKey === 'LocationForm' ? (
                                        <RegionLocationForm
                                            formFields={fieldSet.fields}
                                            isNationalPublisher={isNational}
                                            awardName={awardName}
                                            regionName={regionName}
                                            locations={formLocations}
                                            setFormLocations={(locations: Location[]) => {
                                                setFormLocations(addIndex(locations as Location[]))
                                            }}
                                            setDeletedLocation={deleteLocation}
                                            error={error}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                fieldSet.heading === awardName + ' requirements'
                                                    ? 'flex flex-col'
                                                    : 'flex flex-wrap'
                                            }>
                                            {map(fieldSet.fields, (field: FieldT, index: number) => (
                                                <div key={index} className={field.className}>
                                                    <Field {...field} />
                                                    {fieldSet.fieldKey === 'LocationRadioButtonForm' &&
                                                        locStatus === LocationFormOptions.HasMinRemoteEmpCount && (
                                                            <div className="py-6">
                                                                <Alert>
                                                                    {
                                                                        'To ensure your eligibility for this award, please confirm the number of remote employees in this region when setting up your Workplace Survey for participation.'
                                                                    }
                                                                </Alert>
                                                            </div>
                                                        )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )
                    )}
                    <Inset className={'flex items-center text-center justify-center mt-4'}>
                        <Button onClick={onFinishLater} className="mr-2" outline disabled={isLoading}>
                            {"I'll finish later"}
                        </Button>
                        <Button onClick={onConfirm} disabled={isLoading}>
                            {'Confirm'}
                        </Button>
                    </Inset>
                </form>
            </FormProvider>
        </>
    )
}
