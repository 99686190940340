import { withRouter } from 'react-router-dom'
import LoadingButton from 'components/LoadingButton'
import Textarea from 'components/Textarea'

const BaseTextAreaInput = ({ onChange, name, className, ...rest }) => {
    return <Textarea {...rest} name={name} className={className} rows={4} maxLength={500} onChange={onChange} />
}

const TextAreaInputPreview = (props) => (
    <Textarea {...props} className="w-full md:w-1/2 mt-2 mb-3 mt-2" disabled rows={5} />
)

const TextAreaInput = (props) => <BaseTextAreaInput className="w-full md:w-1/2 mt-2 mb-0" {...props} />

const CustomizeSmsMessage = ({
    smsText,
    isSmsTextSubmitted,
    onChangeSmsText,
    onSubmitSmsText,
    isSubmittingSmsText,
    disableConfirmButton = false,
}) => {
    const defaultMessageTail = ' [link] Text HELP for help, STOP to cancel. Message and data rates may apply.'
    const remainingCharactersText = 160 - smsText.length + '/160 recommended characters remaining'

    return (
        <div className="pt-6 pb-4 w-full">
            <h5>{`Customize the SMS message your employees will receive`}</h5>
            <p className="py-2">
                {`Survey invitations sent via SMS will include the link and required message previewed below. Please edit the message that your employees will see.`}
            </p>
            <TextAreaInput
                label="Enter your message here"
                placeholder="Please take this survey"
                value={smsText}
                required
                name="smsText"
                onChange={({ target: { value } }) => onChangeSmsText(value)}
            />
            <TextAreaInputPreview
                label="Preview of SMS Invitation"
                placeholder={smsText + defaultMessageTail}
                name="smsTextPreview"
                info={remainingCharactersText}
            />
            <LoadingButton
                data-test-id="confirm-sms-configuration"
                variant="secondary"
                disabled={smsText.length === 0 || isSmsTextSubmitted || disableConfirmButton}
                isLoading={isSubmittingSmsText}
                onClick={onSubmitSmsText}>
                {isSmsTextSubmitted ? 'Confirmed' : 'Confirm Messaging'}
            </LoadingButton>
        </div>
    )
}

export default withRouter(CustomizeSmsMessage)
