/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie'
import ky from 'ky'
import { matchPath } from 'react-router-dom'
import { routes } from 'constants/routes'
import config from '../config'
import store, { selectAccessToken } from '../store'
import { NO_CONTENT } from './statusCodeSymbols'

// TODO: when useFetch is removed this won't have to be exported
export const api = ky.create({
    retry: 0,
    timeout: false,
    hooks: {
        beforeRequest: [
            (request) => {
                const token = selectAccessToken(store.getState())
                if (token) {
                    request.headers.set('Authorization', `Bearer ${token}`)
                }

                const impersonationKey = Cookies.get('impersonationKey')
                if (impersonationKey) {
                    request.headers.set('ImpersonationKey', impersonationKey)
                }

                return request
            },
        ],
        afterResponse: [
            async (request, options, response) => {
                if (!response.ok) {
                    if (response.status === 401 || response.status === 403) {
                        if (matchPath(window.location.pathname, { path: routes.accessDenied() })) {
                            return
                        }
                        // @ts-ignore
                        window.location = routes.accessDenied()
                        return
                    }
                }

                return response
            },
        ],
    },
})

export async function getBodyContent(response: Response) {
    // eslint-disable-next-line eqeqeq
    if (response.headers.get('content-length') == '0') {
        return null
    }

    const contentType = response.headers.get('content-type')

    if (!contentType) {
        return null
    }

    if (contentType.indexOf('text/plain') > -1) {
        return await response.text()
    } else if (contentType.indexOf('application/json') > -1) {
        return await response.json()
    }

    return null
}

async function parseData(response: Response) {
    if (response.ok) {
        const status = response.status

        if (status === 204) {
            return NO_CONTENT
        }

        const data = await getBodyContent(response)
        return data ?? response
    }

    return null
}

export type EnhancedKy = typeof ky & {
    fetch: <Response>(url: string) => Promise<Response>
    json: <Response>(url: string) => Promise<Response>
    postJson: <ResponseData, Data = any>(url: string, data?: Data) => Promise<ResponseData>
    postFormData: <Response>(url: string, formData: FormData) => Promise<Response>
    deleteFormData: <Response>(url: string) => Promise<Response>
}

function makeSpecificApi(prefixUrl: string) {
    const extendedApi = api.extend({
        prefixUrl,
    })

    const m = {
        fetch: async (url: string) => {
            const response = await extendedApi.get(url)
            return await parseData(response)
        },
        json: (url: string) => extendedApi.get(url).json(),
        postJson: async <T>(url: string, data: T) => {
            const response = await extendedApi.post(url, {
                json: data,
            })

            return parseData(response)
        },
        postFormData: async (url: string, formdata: FormData) => {
            const response = await extendedApi.post(url, {
                body: formdata,
            })
            return parseData(response)
        },
        deleteFormData: async (url: string) => {
            const response = await extendedApi.delete(url)
            return parseData(response)
        },
    }

    return Object.assign(extendedApi, m) as EnhancedKy
}

export const workplaceSurvey = makeSpecificApi(config.api.workplaceSurvey)
export const xenon = makeSpecificApi(config.urls.xenon)
export const branding = makeSpecificApi(config.api.branding)
export const management = makeSpecificApi(config.api.platform)
export const organization = makeSpecificApi(config.api.organization)
export const reports = makeSpecificApi(config.api.reports)
export const ordering = makeSpecificApi(config.api.ordering)
export const authentication = makeSpecificApi(config.api.authentication)
export const insights = makeSpecificApi(config.api.insights)
export const documentManagement = makeSpecificApi(config.api.documentManagement)
