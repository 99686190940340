import PropTypes from 'prop-types'
import cx from 'clsx'
import map from 'lodash/map'
import FancyCheckbox from 'components/FancyCheckbox'
import withClassName from 'style/util/withClassName'

export const HeaderCell = ({ className, ...props }) => <span className={cx(className, 'inline-block p-2')} {...props} />

HeaderCell.propTypes = {
    className: PropTypes.string.isRequired,
}

export const HeaderRow = withClassName('bg-white border border-b-4 border-grey300 font-bold text-sm', 'div')

export const TableHeader = ({ columns, isSelectable, checked, onCheckboxClick }) => (
    <HeaderRow>
        {map(columns, ({ label, key, className, hasCheckbox }) => (
            <HeaderCell className={className} key={key}>
                {hasCheckbox && isSelectable && onCheckboxClick && (
                    <FancyCheckbox checked={checked} className="align-middle mx-2" onChange={onCheckboxClick} />
                )}
                {label}
            </HeaderCell>
        ))}
    </HeaderRow>
)

TableHeader.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            className: PropTypes.string,
            hasCheckbox: PropTypes.string,
        })
    ).isRequired,
    isSelectable: PropTypes.bool,
    checked: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
}

export default TableHeader
