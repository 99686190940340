import { useMediaQuery } from '@energage/hooks'
import cx from 'clsx'
import chunk from 'lodash/chunk'
import map from 'lodash/map'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { AWARD_CATEGORY, BOSTON_PUBLISHER_ID, DALLAS_PUBLISHER_ID } from 'containers/Main/AwardAnnouncement/constants'
import { viewports } from 'style/breakpoints'
import type { WinnerAwardsType } from './awardAnnouncement.types'

const AwardBadge = (
    index: number,
    publisherId: number,
    year: number,
    svgUrl: string,
    updatedHeight: string | number,
    className: string
) => {
    const fontFamily = publisherId === BOSTON_PUBLISHER_ID ? null : `'Yantramanav', sans-serif`

    return (
        <SvgLoader path={svgUrl} height={updatedHeight} className={className} key={index}>
            <SvgProxy selector="text" font-family={fontFamily} />
            <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
        </SvgLoader>
    )
}

export const WinnerAwards = ({
    awardsSvg,
    isPdf,
    publisherId,
    awardCategoryId,
    year,
    isOldAwardAnnouncement,
}: WinnerAwardsType) => {
    const isMobileDevice = useMediaQuery({ maxWidth: viewports.smMax })
    const isDallasPublisher = publisherId === DALLAS_PUBLISHER_ID
    const isBostonPublisher = publisherId === BOSTON_PUBLISHER_ID
    const isCEAward = awardCategoryId === AWARD_CATEGORY.CULTURE_EXCELLENCE
    const isIndustryAward = awardCategoryId === AWARD_CATEGORY.INDUSTRY
    const customPublisherHeight = isDallasPublisher ? '10rem' : isPdf ? '9rem' : '8rem'
    const ceAwardsHeight = !isMobileDevice && !isPdf ? '15rem' : '12rem'
    const height = isDallasPublisher || isBostonPublisher ? customPublisherHeight : isPdf ? '12rem' : '15rem'
    const industryAwardHeight = isPdf ? '13rem' : '20.8rem'
    const nonCEAwardsHeight = isIndustryAward && !isOldAwardAnnouncement ? industryAwardHeight : height
    const updatedHeight = isCEAward ? ceAwardsHeight : nonCEAwardsHeight

    const className =
        isCEAward || isIndustryAward ? '' : isPdf ? (isDallasPublisher ? 'py-6 px-4 -mb-6' : 'py-6 px-4') : 'py-3 px-4'

    return (
        <>
            {isMobileDevice && !isPdf ? (
                <div className="flex flex-col self-end justify-center">
                    {map(chunk(awardsSvg, 2), (coupledUrls, i) => (
                        <div key={i} className="flex flex-1 justify-center">
                            {map(coupledUrls, ({ svgUrl }, index: number) =>
                                AwardBadge(index, publisherId, year, svgUrl, updatedHeight, className)
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className={cx('flex w-full self-end justify-center', { 'mt-10 sm:mt-8 lg:mt-16': !isPdf })}>
                    {map(awardsSvg, ({ svgUrl }, index: number) =>
                        AwardBadge(index, publisherId, year, svgUrl, updatedHeight, className)
                    )}
                </div>
            )}
        </>
    )
}
