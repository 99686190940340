import compact from 'lodash/compact'
import find from 'lodash/find'
import map from 'lodash/map'
import { conjunctionJoin } from 'util/conjunctionJoin'
import type { useCustomDemographicTargets } from '../CustomDemographicTargetContext'

type ComputeTarget = {
    questionTargetDemographics: {
        demographicTypeId: number
        demographicIds: number[]
    }[]
    questionTargetDepartmentIds: number[]
    flattenedDepartments: Map<number, { name: string }>
    demographicQuestions: {
        demographicTypeId: number
        name: string
        answerOptions: {
            id: number
            name: string
        }[]
    }[]
    customDemographicTargets: ReturnType<typeof useCustomDemographicTargets>
}

function createTargetingDescription({
    demographicNameList,
    departmentNameList,
}: {
    departmentNameList: string[]
    demographicNameList: {
        demographic: { name: string }
        options: string[]
    }[]
}) {
    let description: string[] = []

    if (departmentNameList.length > 0) {
        const joinedDepartmentString = conjunctionJoin('or', departmentNameList)
        if (demographicNameList.length === 0) {
            description.push(joinedDepartmentString)
        } else {
            description.push(`Departments: ${joinedDepartmentString}`)
        }
    }

    if (demographicNameList.length > 0) {
        if (demographicNameList.length === 1 && description.length === 0) {
            const { options } = demographicNameList[0]
            description.push(conjunctionJoin('or', options))
        } else {
            description = description.concat(
                demographicNameList.map(
                    ({ demographic, options }) => `${demographic.name}: ${conjunctionJoin('or', options)}`
                )
            )
        }
    }

    if (description.length === 0) {
        return null
    }

    return description
}

export function computeTargetingInformation({
    questionTargetDemographics = [],
    questionTargetDepartmentIds = [],
    flattenedDepartments,
    demographicQuestions = [],
    customDemographicTargets = [],
}: ComputeTarget) {
    const departmentNameList = compact(map(questionTargetDepartmentIds, (dId) => flattenedDepartments.get(dId)?.name))
    const allDemographicList = demographicQuestions.concat(customDemographicTargets)

    const demographicNameList = compact(
        map(questionTargetDemographics, (target) => {
            const demographic = find(allDemographicList, (d) => d.demographicTypeId === target.demographicTypeId)

            if (demographic) {
                const options = compact(
                    map(target.demographicIds, (dId) => {
                        return find(demographic.answerOptions, (a) => a.id === dId)?.name
                    })
                )

                return {
                    demographic: {
                        name: demographic.name,
                    },
                    options,
                }
            }
        })
    )

    const targetingDescription = createTargetingDescription({
        departmentNameList,
        demographicNameList,
    })

    return {
        departmentNameList,
        demographicNameList,
        targetingDescription,
    }
}
