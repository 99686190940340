import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import HorizontalTile from './HorizontalTile'
import SingleTile from './SingleTile'
import VerticalTile from './VerticalTile'

export const tileTypes = {
    single: 'Single',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    wide: 'Wide',
}

const renderTile = {
    [tileTypes.single]: SingleTile,
    [tileTypes.horizontal]: HorizontalTile,
    [tileTypes.vertical]: VerticalTile,
}

const Tile = forwardRef(({ type, ...restProps }, ref) => {
    const TileComponent = renderTile[type]
    if (!TileComponent) {
        return <div ref={ref} />
    }
    return (
        <div ref={ref}>
            <TileComponent {...restProps} />
        </div>
    )
})

Tile.defaultProps = {
    type: tileTypes.vertical,
}

Tile.propTypes = {
    type: PropTypes.oneOf(Object.values(tileTypes)),
}

export default Tile
