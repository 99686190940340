/* eslint-disable @typescript-eslint/no-var-requires */

import type { AppConfiguration } from './base'

let config
if (process.env.NODE_ENV === 'production') {
    config = require('./production')
    //@ts-ignore
} else if (process.env.CI === 'true' || process.env.NODE_ENV === 'test') {
    config = require('./test')
} else {
    config = require('./development')
}

export default config.default as AppConfiguration
