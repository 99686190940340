import { useMediaQuery } from '@energage/hooks'
import { Domain } from '@energage/icons'
import { Tooltip } from '@energage/overlays'
import { zIndex } from '@energage/theme'
import cx from 'clsx'
import { Link, TooltipTrigger } from 'react-aria-components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useIdentity } from 'components/Identity'
import { routes } from 'constants/routes'
import { useButterBarHeight } from 'hooks'
import { viewports } from 'style/breakpoints'
import NotificationMenu from './NotificationMenu'
import UserMenu from './UserMenu'

const MenuContainer = styled.div.attrs(({ className }) => ({
    className: cx('fixed right-0 h-16', className),
}))`
    top: ${(props) => props.top}px;
    transition: top 250ms;
    z-index: ${+zIndex('navigation') + 1};
`

const StatusBarXS = ({ top }) => (
    <MenuContainer top={top}>
        <div className="h-full flex items-center mx-2" data-testid="top-action-bar">
            <NotificationMenu />
        </div>
    </MenuContainer>
)

const StatusBarMd = ({ top, identity }) => {
    const { pathname } = useLocation()
    const isOrgManagement = pathname === routes.manage()

    return (
        <MenuContainer top={top} className="px-10">
            <div className="h-full flex items-center" data-testid="top-action-bar">
                <NotificationMenu />
                {(identity.permissions.hasEmployerBranding || identity.permissions.hasPlatformSurveySetup) && (
                    <TooltipTrigger>
                        <Link
                            aria-label="View and manage your organization"
                            className={cx('ml-4 mr-4', isOrgManagement ? 'text-purple700' : 'text-purple900')}
                            href={routes.manage()}
                            data-event-id="platform-dashboard-org-management">
                            <Domain />
                        </Link>
                        <Tooltip placement="bottom">
                            <span className="paragraph-small flex justify-center content-center">
                                {'View and manage your organization'}
                            </span>
                        </Tooltip>
                    </TooltipTrigger>
                )}
                <span className="h-8 w-1 pl-5 border-l-2" />
                <UserMenu identity={identity} />
            </div>
        </MenuContainer>
    )
}

const StatusBar = () => {
    const isTabletOrBigger = useMediaQuery({ minWidth: viewports.smMax + 1 })
    const top = useButterBarHeight()
    const identity = useIdentity()
    if (!identity) {
        return null
    }

    const StatusBarComponent = isTabletOrBigger ? StatusBarMd : StatusBarXS
    return <StatusBarComponent top={top} identity={identity} />
}

export default StatusBar
