import { useAtomValue } from 'jotai/utils'
import { Accordion } from 'components/Accordion'
import CustomQuestions from './CustomQuestions'
import { CustomQuestionHelpText } from './fragments'
import { availableTargetDemographicQuestions } from './statementSetupUtility/store'

function CustomQuestionsSection({
    hasSmartPulse,
    customQuestions,
    handleSubmitQuestions,
    autoSaving,
    disableSubmit,
    handleOnQuestionSave,
    departments,
    ...questionCreationMethods
}) {
    const demographicQuestions = useAtomValue(availableTargetDemographicQuestions)

    return (
        <div data-testid="survey-setup-custom-section">
            <Accordion
                id="custom-questions"
                className="border-t-2"
                title={
                    <div className="pl-6 pb-4 flex items-center">
                        <span className="h5 mr-5">{'Custom Items'}</span>
                    </div>
                }
                text={
                    hasSmartPulse && (
                        <div className="pl-6 pb-1 -mt-2">
                            <CustomQuestionHelpText />
                        </div>
                    )
                }
                defaultOpen>
                {hasSmartPulse ? (
                    <CustomQuestions
                        demographicQuestions={demographicQuestions}
                        customQuestions={customQuestions}
                        handleSubmitQuestions={handleSubmitQuestions}
                        autoSaving={autoSaving}
                        disableSubmit={disableSubmit}
                        handleOnQuestionSave={handleOnQuestionSave}
                        departments={departments}
                        {...questionCreationMethods}
                    />
                ) : null}
            </Accordion>
        </div>
    )
}

export default CustomQuestionsSection
