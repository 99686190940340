import cx from 'clsx'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import type { PreviewProps } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/OrgStrengthsConfiguration/OrgStrengths.types'
import CultureFactTWP from 'images/employerBranding/cultureFacts/cultureFactTWP.svg'

const PreviewContainer = styled.div<{
    backgroundColor?: string
    width?: string
    height?: string
    alt?: string
}>`
    width: ${(props) => props.width ?? '340px'};
    height: ${(props) => props.height ?? '340px'};
    background: ${(props) => props.backgroundColor};
`

const CompanyStrengths = styled.h3<{ highlightColor: string; isDownloadable: boolean | undefined }>`
    color: ${(props) => props.highlightColor};
    font-size: ${(props) => (props.isDownloadable ? '6rem' : '3rem')};
    top: ${(props) => (props.isDownloadable ? '450px' : '208px')};
    right: ${(props) => (props.isDownloadable ? '-385px' : '-200px')};
`

const Circle = styled.div<{ highlightColor: string; isDownloadable: boolean | undefined }>`
    width: ${(props) => (props.isDownloadable ? '650px' : '300px')};
    height: ${(props) => (props.isDownloadable ? '650px' : '300px')};
    border: 1px solid ${(props) => props.highlightColor};
    border-radius: 100%;
    top: 50%;
    left: 45%;
    transform: translate(-52%, -50%);
`

const TextWrapper = styled.div<{ textColor: string; isDownloadable: boolean | undefined }>`
    width: ${(props) => (props.isDownloadable ? '760px' : '380px')};
    color: ${(props) => props.textColor};
    top: 50%;
    left: 45%;
    transform: translate(-52%, -50%);
`

const StrengthsName = styled.h3<{ isDownloadable: boolean | undefined }>`
    font-size: ${(props) => (props.isDownloadable ? '6rem' : '48px')};
    line-height: 1;
`

const Statement = styled.p<{ isDownloadable: boolean | undefined }>`
    width: ${(props) => (props.isDownloadable ? '650px' : '300px')};
    transform: ${(props) => (props.isDownloadable ? 'translateX(9%)' : 'translateX(14%)')};
`

export const OrgStrengthsCirculerePreview = ({
    name,
    text,
    width,
    height,
    backgroundColor,
    highlightColor,
    textColor,
    isDownloadable,
}: PreviewProps) => {
    return (
        <PreviewContainer
            className={cx('relative', !isDownloadable && 'rounded-lg')}
            width={width}
            height={height}
            backgroundColor={backgroundColor}>
            <Circle className="absolute" highlightColor={highlightColor} isDownloadable={isDownloadable} />
            <CompanyStrengths
                className="absolute transform -rotate-90"
                highlightColor={highlightColor}
                isDownloadable={isDownloadable}>
                {'Company Strengths'}
            </CompanyStrengths>
            <div className={cx(isDownloadable ? 'w-48 mt-12 ml-12' : 'w-20 mt-6 ml-6', 'absolute top-0 left-0')}>
                <ProgressiveImage src={CultureFactTWP} placeholder="">
                    {(src) => <img src={src} alt="" className="border rounded-full w-full" />}
                </ProgressiveImage>
            </div>
            <TextWrapper className="text-center relative" textColor={textColor} isDownloadable={isDownloadable}>
                <StrengthsName className="uppercase" isDownloadable={isDownloadable}>{`${name}`}</StrengthsName>
                <Statement
                    className={cx(isDownloadable ? 'text-6xl mt-12' : 'text-2xl mt-8', 'text-left')}
                    isDownloadable={isDownloadable}>{`${text}`}</Statement>
            </TextWrapper>
        </PreviewContainer>
    )
}
