import { alert } from '@energage/components'
import { useBrandingQuery } from 'api'
import showErrorAlert from 'util/showErrorAlert'

const useDownloadPressReleaseForm = (url) => {
    const {
        mutate: download,
        isLoading,
        reset,
    } = useBrandingQuery.mutate(url, {
        onSuccess: (data) => {
            if (data) {
                const { fileContents, contentType, fileDownloadName } = data

                // Decode the base64 string to binary data
                const binaryData = atob(fileContents)

                // Create an ArrayBuffer from the binary data
                const arrayBuffer = new ArrayBuffer(binaryData.length)
                const uint8Array = new Uint8Array(arrayBuffer)
                for (let i = 0; i < binaryData.length; i++) {
                    uint8Array[i] = binaryData.charCodeAt(i)
                }

                // Create a Blob from the ArrayBuffer
                const blob = new Blob([arrayBuffer], { type: contentType })

                // Create a temporary URL for the Blob
                const url = URL.createObjectURL(blob)

                // Create a temporary link element and trigger the download
                const link = document.createElement('a')
                link.href = url
                link.download = fileDownloadName
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                URL.revokeObjectURL(url)
                alert.success('Press release document downloaded successfully.')
            } else {
                showErrorAlert('There was an error downloading your press release document.')
            }
        },

        onError: () => {
            showErrorAlert('There was an error downloading your press release document.')
            reset()
        },
    })

    return { download: () => download({}), isDownloading: isLoading }
}

export default useDownloadPressReleaseForm
