import type { ReactNode } from 'react'
import { LegacyButton as Button } from '@energage/components'
import { useToggle } from '@energage/hooks'
import remove from 'lodash/remove'
import type { AwardChange, AwardChangeListWithSurveyName } from './AwardScheduleChangeConfirmation.types'

const maxInitialAwards = 4

export function List({
    awards,
    renderListItem,
}: {
    awards: AwardChangeListWithSurveyName
    renderListItem: (award: AwardChange) => ReactNode
}) {
    const [open, toggle] = useToggle(false)
    const awardsCopy = [...awards]
    const initiallySeenAwards = remove(awardsCopy, (_, idx) => idx < maxInitialAwards)

    return (
        <>
            <ul className="pl-0">
                {initiallySeenAwards.map((award) => renderListItem(award))}
                {open && awardsCopy.length > 0 ? awardsCopy.map((award) => renderListItem(award)) : null}
            </ul>
            {awardsCopy.length > 0 ? (
                <Button variant="link" className="m-0 p-0" onClick={toggle}>{`Plus ${awardsCopy.length} more`}</Button>
            ) : null}
        </>
    )
}
