import { forwardRef, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { scaleQuantile } from 'd3'
import filter from 'lodash/filter'
import map from 'lodash/map'
import styled from 'styled-components'
import { Filters } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { InsufficientDataWarning } from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/InsufficientDataWarning'
import { CultureCloudWordPreview } from 'containers/Public/RevampWidgets/CultureCloudWordPreview'
import { media } from 'style/breakpoints'
import { MINIMUM_DROPLETS_REQUIRED } from './../../Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/utils'
import { parseQueryParams } from '../Widgets/QueryParser'
import { getFilterQueryParams, getSelectedFiltersRecord } from '../Widgets/utils'
import { Watermark } from '../Widgets/Watermark'
import wordCloudBuilder from '../Widgets/WordCloud/WordCloudBuilder'
import SelectedFilterPills from './SelectedFilterPills'
import Tile, { tileTypes } from './Tile'
import useFetchPublicPage from './useFetchPublicPage'

const Frame = styled.div.attrs(({ isExpiredWidget }) => ({
    className: cx('relative overflow-hidden', { 'opacity-25': isExpiredWidget }),
}))`
    filter: blur(${(props) => (props.isSubscribed ? '0px' : '10px')});

    ${media.smMin`
            min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : null)};
        `}
`

export function getOpacityScale(droplets) {
    const scale = scaleQuantile()
        .domain(map(droplets, (d) => d.weight))
        .range(['0.5', '0.8', '1'])
    return (d) => scale(d.weight)
}

export function WordCloud({ isVisible, data, isExpiredWidget }) {
    const cloudContainer = useRef()
    const wordCloud = useRef(null)
    useLayoutEffect(() => {
        wordCloud.current = wordCloudBuilder.create(cloudContainer.current, data)
        return () => wordCloudBuilder.destroy(wordCloud.current)
    }, [data])
    return (
        <Frame
            isSubscribed={isVisible}
            isExpiredWidget={isExpiredWidget}
            minWidth={data.displaySize.width}
            ref={cloudContainer}>
            {isExpiredWidget && <Watermark />}
        </Frame>
    )
}

const CultureCloud = forwardRef(
    (
        {
            ariaTitle,
            ariaDescription,
            className,
            title,
            droplets,
            backgroundColor,
            foregroundColor,
            headerBgColor,
            headerTextColor,
            type,
            width,
            height,
            cloudChartWidth,
            cloudChartHeight,
            dataId,
            isExpiredWidget,
            filterData,
            hasEmployerBrandingSubscription,
            minDroplets,
            isPdf,
        },
        ref
    ) => {
        const opacity = getOpacityScale(droplets)
        const selectedDroplets = filter(droplets, 'isSelected')
        const minDropletsRequired = droplets?.length >= (minDroplets || MINIMUM_DROPLETS_REQUIRED)

        return (
            <Tile
                header={title}
                bgColor={backgroundColor}
                fgColor={foregroundColor}
                headerBgColor={headerBgColor}
                headerTextColor={headerTextColor}
                className={className}
                type={type}
                width={width}
                height={height}
                cloudChartWidth={cloudChartWidth}
                cloudChartHeight={cloudChartHeight}
                pills={SelectedFilterPills}
                dataId={dataId}
                blurTileBody={!hasEmployerBrandingSubscription}
                filterData={filterData}
                isPdf={isPdf}
                ref={ref}>
                {({ foregroundColor }) => (
                    <>
                        {minDropletsRequired ? (
                            <WordCloud
                                isVisible={hasEmployerBrandingSubscription}
                                data={{
                                    ariaTitle,
                                    ariaDescription,
                                    droplets: selectedDroplets,
                                    hoverInteraction: false,
                                    withSlash: false,
                                    zoomToFit: true,
                                    colors: {
                                        positive: foregroundColor,
                                        negative: foregroundColor,
                                        neutral: foregroundColor,
                                    },
                                    textWeightScale: 'bold',
                                    spiralType: 'rectangular',
                                    displaySize: {
                                        width: cloudChartWidth ? cloudChartWidth : 400,
                                        height: cloudChartHeight ? cloudChartHeight : 300,
                                    },
                                    chartSize: {
                                        width: cloudChartWidth ? cloudChartWidth : 400,
                                        height: cloudChartHeight ? cloudChartHeight : 300,
                                    },
                                    opacity,
                                }}
                                isExpiredWidget={isExpiredWidget}
                            />
                        ) : (
                            <InsufficientDataWarning warningText="Not enough data for culture cloud" />
                        )}
                    </>
                )}
            </Tile>
        )
    }
)

CultureCloud.propTypes = {
    ariaTitle: PropTypes.string,
    ariaDescription: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    droplets: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            weight: PropTypes.number,
            isEmphasizedPositive: PropTypes.bool,
            isEmphasizedNegative: PropTypes.bool,
        })
    ),
    type: PropTypes.string,
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.number,
    cloudChartWidth: PropTypes.number,
    cloudChartHeight: PropTypes.number,
    dataId: PropTypes.string,
    isExpiredWidget: PropTypes.bool,
    filterData: PropTypes.object,
    isVisible: PropTypes.bool,
}
export default CultureCloud

export function CultureCloudFetch({ location }) {
    const parsedQuery = parseQueryParams(location)
    const query = getFilterQueryParams(parsedQuery)
    const { companyId, surveyEventId, isDownloadable, wordsOnly } = parsedQuery

    const { data, isLoading, error } = useFetchPublicPage(
        'cultureCloud-filter',
        `SurveyCompanies/${companyId}/culturefacts/culturecloud/${surveyEventId}/${isDownloadable}?${query}`
    )

    const {
        data: surveyEvents,
        isLoading: isLoadingSurveyEvents,
        error: surveyEventsError,
    } = useFetchPublicPage(
        'ContentSubFilters',
        `SurveyCompanies/${companyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}&${query}`
    )

    if (isLoading || isLoadingSurveyEvents) {
        return null
    }

    if (error || surveyEventsError) {
        return null
    }

    return (
        <Filters.Provider
            value={{
                filterData: surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery),
            }}>
            {wordsOnly ? (
                <CultureCloudWordPreview
                    {...data}
                    className="w-32 mt-6 mr-6"
                    classNamePills="text-lg pr-3 pb-5"
                    filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                    hasEmployerBrandingSubscription
                    minDroplets={data.cultureCloudWordThreshold}
                    width="1200px"
                    cloudChartWidth={825}
                    cloudChartHeight={575}
                />
            ) : (
                <CultureCloud
                    type={tileTypes.vertical}
                    filterData={surveyEvents && getSelectedFiltersRecord(surveyEvents, parsedQuery)}
                    {...data}
                    hasEmployerBrandingSubscription
                    minDroplets={data.cultureCloudWordThreshold}
                    width="1080px"
                    cloudChartWidth={1080}
                    cloudChartHeight={795}
                    isPdf
                />
            )}
        </Filters.Provider>
    )
}
