import { init } from '@rematch/core'
import immerPlugin from '@rematch/immer'
import selectPlugin from '@rematch/select'

function createStore(models) {
    return init({
        models,
        plugins: [immerPlugin(), selectPlugin()],
    })
}

export default createStore
