import { useEffect } from 'react'
import { Loading as Spinner } from '@energage/components'
import map from 'lodash/map'
import styled from 'styled-components'
import { useWorkplaceSurveyQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/strings'
import { Error } from '../StatementSetup/fragments'
import { getSurveyDefinition } from '../StatementSetup/useFetchSurveyDefinition'
import getDisplayableSections from './getDisplayableSections'

const Container = styled(Inset).attrs({ className: 'w-1/2 mx-auto' })`
    && {
        @media print {
            width: 100%;

            @page {
                size: auto;
                margin: 20mm 0 25mm 10mm;
            }
        }
    }
`
const Logo = styled.img.attrs({
    src: require('images/company-logo-color.svg').default,
    className: 'w-32 mx-auto mt-2 mb-5',
    alt: 'Energage Logo',
})`
    && {
        @media print {
            margin-top: 0;
        }
    }
`

function StatementDownload({ data }) {
    const sections = getDisplayableSections(data)

    useEffect(() => {
        const title = document.title
        document.title = `${data.name} Items Download`
        window.print?.()

        return () => {
            document.title = title
        }
    }, [data.name])
    return (
        <Container padding className="w-1/2 mx-auto">
            <div className="text-center">
                <Logo />
                <h2 className="mb-8">{`${data.name} Items`}</h2>
            </div>
            <div className="mb-24">
                {map(sections, ({ id, sectionName, subsections }) => (
                    <div key={id} className="mb-6">
                        <h3 className="text-purple800 mb-1">{sectionName}</h3>
                        {map(subsections, (subsection) => {
                            if (subsection.questions.length === 0) {
                                return null
                            }

                            return (
                                <div className="my-3">
                                    <h5>{subsection.categoryName}</h5>
                                    <ul className="list-none list-outside pl-0">
                                        {map(subsection.questions, (question) => (
                                            <li key={question.questionEssenceId}>{question.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                ))}
            </div>
            <div className="text-center mt-20">
                <ExternalLink href="https://www.energage.com" className="text-primary-dark">
                    {'energage.com'}
                </ExternalLink>
                {' | '}
                <ExternalLink href={`tel:${SUPPORT_PHONE}`} className="text-primary-dark">
                    {SUPPORT_PHONE}
                </ExternalLink>
                {' | '}
                <ExternalLink href={`mailto:${SUPPORT_EMAIL}`} className="text-primary-dark">
                    {SUPPORT_EMAIL}
                </ExternalLink>
            </div>
        </Container>
    )
}

function StatementDownloadFetch({ match }) {
    const { surveyEventId } = match.params
    const { surveyCompanyId } = useIdentity()

    const { data, error, isLoading } = useWorkplaceSurveyQuery(
        ['survey-definition', surveyCompanyId, surveyEventId],
        (_, api) => getSurveyDefinition(surveyCompanyId, surveyEventId, api)
    )

    if (error) {
        return <Error message={error?.message} />
    }

    if (isLoading || !data) {
        return <Spinner className="mt-32" />
    }

    return <StatementDownload data={data} />
}

export default StatementDownloadFetch
