import { useMemo } from 'react'
import { HierarchyMultiSelect, Input, InputGroup, Select, useHierarchyMultiSelect } from '@energage/components'
import cx from 'clsx'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import noop from 'lodash/noop'
import size from 'lodash/size'
import styled from 'styled-components'
import { usePermissions } from 'components/Identity'
import { AdditionalQuestionInformation } from './AdditionalQuestionInformation'
import { StatementQuestion } from './CustomQuestionTypes'
import CustomQuestionPreview from './CustomQuestionTypes/CustomQuestionPreview'
import { DemographicTarget } from './DemographicTarget'
import { MultiChoiceOptionsEditor } from './MultiChoiceOptionsEditor'
import { QuestionType } from './QuestionType'

function getQuestionNameErrorMessage(question) {
    if (!question._errors) {
        return
    }

    if (question._errors.noName) {
        return 'Please give this question a useful name.*'
    }

    if (question._errors.duplicateName) {
        return 'Question names must be unique.'
    }
}

const QuestionContainer = styled.div.attrs(({ variant }) => ({
    className: cx('flex flex-col sm:flex-row pb-4', variant === 'fixed' && 'justify-between'),
}))`
    ${({ variant }) =>
        variant === 'fixed' &&
        `
    max-width: 92rem;
  `}
`

const initialDepartmentOptions = {
    placeholder: {
        clearSelection: 'Select Departments',
        allSelected: 'All Departments',
        someSelected: (count) => `${count} Selected Departments`,
    },
    root: {
        name: 'All Departments',
    },
}

function CustomQuestion({
    question,
    demographicQuestions,
    handleQuestionTextChange,
    handlePositiveConditionalTextChange,
    handleNegativeConditionalTextChange,
    handleAddRemoveConditionals,
    handleQuestionNameChange,
    handleQuestionTypeSelection,
    handleDemographicTargetChange,
    handleOptionsChange,
    handleQuestionDepartmentSelection,
    variant = 'fixed',
    departments,
}) {
    const departmentsData = useMemo(() => departments ?? [], [departments])
    const selectedDepartmentIds = question.departmentIds

    const { control: departmentsSelectControl, selectedOptions } = useHierarchyMultiSelect(departmentsData, {
        ...initialDepartmentOptions,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        initialSelected: useMemo(() => selectedDepartmentIds ?? [], []),
    })

    const allDepartments = useMemo(() => {
        const flatDepartments = (departmentList, result = {}) => {
            forEach(departmentList, (d) => {
                result[d.id] = d
                flatDepartments(d.children, result)
            })
            return result
        }
        return flatDepartments(departments || [])
    }, [departments])

    const onMenuClose = () => {
        let departmentsToSave = []
        if (size(Object.keys(allDepartments)) !== size(selectedOptions)) {
            forEach(selectedOptions, (departmentId) => {
                const item = allDepartments[departmentId]
                if (!item.parentId || (item.parentId && !includes(selectedOptions, item.parentId))) {
                    departmentsToSave.push(departmentId)
                }
            })
        }
        handleQuestionDepartmentSelection(departmentsToSave)
    }

    const { hasFeedbackRatingScaleCustomQuestion } = usePermissions()

    let customQuestionOptionsFinal = customQuestionOptions
    if (hasFeedbackRatingScaleCustomQuestion) {
        customQuestionOptionsFinal = [...customQuestionOptions, feedbackQuestionOption]
    }

    customQuestionOptionsFinal = [...customQuestionOptionsFinal]

    const questionNameErrorMessage = getQuestionNameErrorMessage(question)
    const handleQuestionName = handleQuestionNameChange ?? noop

    const handleQuestionType = (selectedOption) => handleQuestionTypeSelection(selectedOption.value) ?? noop
    const isCustomQuestion = question.sourceType !== null

    return (
        <QuestionContainer variant={variant}>
            <div className={cx('py-3 text-sm w-full sm:w-1/2', variant === 'expand' ? 'pr-8' : 'pr-16')}>
                <div className="pb-2 w-full max-w-md">
                    <Input
                        label="Topic"
                        name="question-name"
                        defaultValue={question.name}
                        onChange={handleQuestionName}
                        disabled={!handleQuestionNameChange}
                        maxLength={46}
                        placeholder="Write your topic here"
                        required
                    />
                    {questionNameErrorMessage && <div className="text-red500">{questionNameErrorMessage}</div>}
                </div>

                <div className="sm:flex items-center">
                    <InputGroup
                        label="Item Type"
                        as={Select}
                        className="w-full max-w-md"
                        options={customQuestionOptionsFinal}
                        value={find(customQuestionOptionsFinal, (cqo) => cqo.value === question.questionTypeId)}
                        onChange={handleQuestionType}
                        placeholder="Select an item Type"
                        isDisabled={!handleQuestionTypeSelection}
                        required
                    />
                </div>
                {question.questionTypeId && (
                    <>
                        <AdditionalQuestionInformation questionTypeId={question.questionTypeId} />
                        <StatementQuestion
                            {...question}
                            prompt={questionTypePrompts[question.questionTypeId].prompt}
                            placeholder={questionTypePrompts[question.questionTypeId].placeholder}
                            questionText={question.text}
                            questionType={question.questionTypeId}
                            onChangeQuestionText={handleQuestionTextChange}
                            onChangePositiveConditionalText={handlePositiveConditionalTextChange}
                            onChangeNegativeConditionalText={handleNegativeConditionalTextChange}
                            onChangeHasConditionals={handleAddRemoveConditionals}>
                            {question.questionTypeId === QuestionType.MultiChoice ? (
                                <MultiChoiceOptionsEditor
                                    options={question.answerOptions}
                                    onOptionsUpdate={handleOptionsChange}
                                />
                            ) : null}
                        </StatementQuestion>

                        {isCustomQuestion && (
                            <div className="mt-4 max-w-md">
                                <div className="font-bold text-base">{'Who is this going to?'}</div>
                                <p className="text-xs">
                                    {
                                        'Use the drop downs below to target your question to specific demographics and departments. The questions will go to all selected demographics and departments.'
                                    }
                                </p>
                                <div className="mt-4">
                                    <DemographicTarget
                                        selectedDemographicTargets={question.targetDemographic}
                                        demographicQuestions={demographicQuestions}
                                        handleDemographicTargetChange={handleDemographicTargetChange}
                                    />
                                </div>
                                <InputGroup
                                    label="Departments"
                                    as={HierarchyMultiSelect}
                                    className="w-full max-w-md mt-4"
                                    control={departmentsSelectControl}
                                    onMenuClose={onMenuClose}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {question.questionTypeId && <CustomQuestionPreview {...question} />}
        </QuestionContainer>
    )
}

export default CustomQuestion

const customQuestionOptions = [
    { value: QuestionType.Agreement, label: 'Statement' },
    { value: QuestionType.Comment, label: 'Open-ended Question' },
    { value: QuestionType.YesNo, label: 'Yes or No' },
    { value: QuestionType.TrueFalse, label: 'True or False' },
    { value: QuestionType.MultiChoice, label: 'Multiple Choice' },
]

const feedbackQuestionOption = { value: QuestionType.Feedback, label: 'Feedback Rating (0-10)' }

const questionTypePrompts = {
    [QuestionType.Agreement]: { prompt: 'Statement', placeholder: 'Write your statement here' },
    [QuestionType.Feedback]: { prompt: 'Ask a feedback rating question' },
    [QuestionType.Comment]: { prompt: 'Type a Question', placeholder: 'Write your question here' },
    [QuestionType.MultiChoice]: { prompt: 'Question', placeholder: 'Write your question here' },
    [QuestionType.YesNo]: { prompt: 'Question', placeholder: 'Write your question here' },
    [QuestionType.TrueFalse]: { prompt: 'Statement', placeholder: 'Write your question here' },
}
