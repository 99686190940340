import { alert } from '@energage/components'
import { useWorkplaceSurveyQuery } from 'api'
import showErrorAlert from 'util/showErrorAlert'

const IGNORE_DOUBLE_QUOTES = /"/g

export const useDownloadAwardAnnouncement = (awardListParticipantId: number) => {
    const {
        mutateAsync: download,
        isLoading,
        reset,
    } = useWorkplaceSurveyQuery.mutate<string, unknown>(`AwardAnnouncement/Pdf/${awardListParticipantId}`, {
        onSuccess: (url: string) => {
            if (url) {
                window.open(url.replace(IGNORE_DOUBLE_QUOTES, ''), '_self')
                alert.success('Announcement Page was downloaded successfully.')
            }
        },
        onError: () => {
            showErrorAlert('There was an error downloading announcement page')
            reset()
        },
    })

    return { downloadAnnouncementPage: () => download(), isDownloading: isLoading }
}
