import filter from 'lodash/filter'
import head from 'lodash/head'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import { getMarginValueForMediaScreen } from 'containers/Main/AwardNotification/Sections/utils'
import { StyledLogoYear } from 'containers/Main/AwardNotification/StyledLogoYear'
import {
    CE_AND_INDUSTRY_PUBLISHER_ID,
    CE_AWARD_NAME,
    DALLAS_PUBLISHER_ID,
    INDUSTRY_AWARD_NAME,
    LONG_ISLAND_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    TWP_USA_AWARD_NAME,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { getYearPosition } from 'containers/Main/EmployerBranding/MediaKit/Utils'
import { getCeAwards } from 'containers/Public/AnnouncementPage/WhatsNext'
import withClassName from 'style/util/withClassName'
import Section from '../../Section'
import Timeline from './Timeline'

const HorizontalLine = withClassName(
    'border-grey300 border-2 border-solid border-r-0 border-l-0 border-t-0 pb-20 -mx-4',
    'hr',
    'horizontalLine'
)

const RegionalAwardImage = styled.img.attrs({ className: 'inline-block relative' })`
    height: ${(props) => (props.isDallasRankedWinner ? '8rem' : '10rem')};
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: ${(props) => props.publisherId === LONG_ISLAND_PUBLISHER_ID && '0.5rem'};

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        width: ${(props) =>
            props.publisherId === MINNEAPOLIS_PUBLISHER_ID ? '8rem' : props.isDallasRankedWinner ? '12rem' : '6rem'};
        margin-bottom: ${(props) => getMarginValueForMediaScreen(props.publisherId)};
        height: ${(props) => (props.publisherId === MINNEAPOLIS_PUBLISHER_ID ? '10rem' : '8rem')};
    }
`

export const getExternalLinkParameters = (year, awardListParticipantId, isCEAward = false) => {
    const nationalAwardParameters = `year=${year}&isCEAward=${isCEAward}&isIndustryAward=false`
    const regionalAwardsParameters = `id=${awardListParticipantId}&${nationalAwardParameters}`

    return `?${isCEAward ? nationalAwardParameters : regionalAwardsParameters}`
}

const splitDateAndTime = (date) => date && head(date.split('T'))

const CEAwardsSection = ({
    awards,
    quarter,
    companyName,
    ceAwards,
    isBlackListedFromTwpMerch,
    mediaKitExternalLink,
    horizontalLineClassName,
    showNationalStandardDetails,
}) => {
    const awardDetails = head(awards)
    const lastQuarter = 4
    const forOnlineAnnouncement = quarter === '0'

    return (
        <>
            <div className="flex flex-row flex-wrap justify-center mt-12">
                {map(awards, (x, index) => (
                    <SvgLoader path={x.svgUrl} key={index} height="8rem" alt="CE awards logos">
                        <SvgProxy
                            selector="text"
                            font-family="'Yantramanav', sans-serif"
                            font-size="46"
                            font-weight="900"
                        />
                        <SvgProxy selector="text tspan" y={getYearPosition(x.awardName)}>
                            {`${x.year}`}
                        </SvgProxy>
                    </SvgLoader>
                ))}
            </div>
            <div className="flex flex-col justify-center mt-8 pb-8">
                {awardDetails && (
                    <h1 className="text-xl sm:text-5xl text-center font-black mt-5">
                        {awardDetails.publisherId !== CE_AND_INDUSTRY_PUBLISHER_ID
                            ? awardDetails.awardName
                            : CE_AWARD_NAME}
                    </h1>
                )}
                <Timeline
                    companyName={companyName}
                    publicationDate={splitDateAndTime(awardDetails.publicationDate)}
                    pressReleaseDate={splitDateAndTime(awardDetails.pressReleaseDate)}
                    eventDate={splitDateAndTime(awardDetails.eventDate)}
                    listName={awardDetails.listName}
                    isApproxPublishDate={awardDetails.isApproxPublishDate}
                    year={awardDetails.year}
                    mediaKitExternalLink={forOnlineAnnouncement && mediaKitExternalLink}
                    showNationalStandardDetails={showNationalStandardDetails}
                    mediaKitExternalLinkParameters={
                        forOnlineAnnouncement &&
                        getExternalLinkParameters(awardDetails.year, awardDetails.surveyEventId, true)
                    }
                    isCeOrIndustryAward={true}
                    hasAlphabeticalWinnersList={awardDetails.hasAlphabeticalWinnersList}
                    alphabeticalWinnersListDate={splitDateAndTime(awardDetails.alphabeticalWinnersListDate)}
                />
                {(quarter < lastQuarter || isBlackListedFromTwpMerch) && size(ceAwards) > 1 && (
                    <HorizontalLine className={horizontalLineClassName} />
                )}
            </div>
        </>
    )
}

const WhatsNext = ({
    bgColor,
    textColor,
    isBlackListedFromTwpMerch,
    timelineAwards,
    companyName,
    awardName,
    sectionClassName,
    mediaKitExternalLink,
    horizontalLineClassName,
    showNationalStandardDetails,
    isDallasRankedWinner,
    showDallasNationalStandardDetails,
}) => {
    const {
        regional,
        national: { twpUsa, industry, cultureExcellence },
    } = timelineAwards

    const regionalAwards = filter(regional, (x) => x.isTopWorkplace)
    const industryAward = industry?.isTopWorkplace ? industry : null
    const ceAwards = filter(cultureExcellence, (x) => x.isTopWorkplace)
    const cultureExcellenceAwards = getCeAwards(ceAwards)

    return (
        <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName}>
            <h1 className="sm:text-5xl font-black text-3xl pl-4 pt-10 sm:pl-0 sm:pt-0">{"What's Next?"}</h1>
            <h1 className="sm:text-5xl text-3xl font-black pl-4 sm:pl-0">{'The countdown to the big celebration.'}</h1>
            {twpUsa && twpUsa.isTopWorkplace && (
                <div className="flex flex-col justify-center mt-12 pb-8">
                    <div className="relative self-center">
                        <SvgLoader path={twpUsa.svgUrl} height="10rem" alt="TWPUSA awards logos">
                            <SvgProxy
                                selector="text"
                                font-family="'Yantramanav', sans-serif"
                                font-size="46"
                                font-weight="900"
                            />
                            <SvgProxy selector="text tspan">{`${twpUsa.year}`}</SvgProxy>
                        </SvgLoader>
                    </div>
                    <h1 className="text-xl sm:text-5xl text-center font-black mt-3">{TWP_USA_AWARD_NAME}</h1>
                    <Timeline
                        companyName={companyName}
                        publicationDate={splitDateAndTime(twpUsa.publicationDate)}
                        pressReleaseDate={splitDateAndTime(twpUsa.pressReleaseDate)}
                        eventDate={splitDateAndTime(twpUsa.eventDate)}
                        listName={twpUsa.listName}
                        isApproxPublishDate={twpUsa.isApproxPublishDate}
                        year={twpUsa.year}
                        mediaKitExternalLink={mediaKitExternalLink}
                        mediaKitExternalLinkParameters={getExternalLinkParameters(
                            twpUsa.year,
                            twpUsa.awardListParticipantId
                        )}
                        hasAlphabeticalWinnersList={twpUsa.hasAlphabeticalWinnersList}
                        alphabeticalWinnersListDate={splitDateAndTime(twpUsa.alphabeticalWinnersListDate)}
                    />
                    {(isBlackListedFromTwpMerch ||
                        size(regionalAwards) > 0 ||
                        size(cultureExcellenceAwards) > 0 ||
                        size(industryAward) > 0) && <HorizontalLine className={horizontalLineClassName} />}
                </div>
            )}
            {size(regionalAwards) > 0 &&
                map(regionalAwards, (x, index) => (
                    <div className="flex flex-col justify-center mt-12 pb-8">
                        <div className="relative self-center">
                            <RegionalAwardImage
                                publisherId={x.publisherId}
                                alt="logo"
                                src={x.svgUrl}
                                className="h-32"
                                isDallasRankedWinner={x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard}
                            />
                            <StyledLogoYear
                                publisherId={x.publisherId}
                                showNationalStandardDetails={showNationalStandardDetails}
                                isDallasRankedWinner={x.publisherId === DALLAS_PUBLISHER_ID && !x.isNationalStandard}
                                year={x.year}>
                                {x.year}
                            </StyledLogoYear>
                        </div>
                        <h1 className="text-xl sm:text-5xl text-center font-black mt-5">
                            {showDallasNationalStandardDetails ? 'National Standard Award' : awardName || x.regionName}
                        </h1>
                        <Timeline
                            companyName={companyName}
                            publicationDate={splitDateAndTime(x.publicationDate)}
                            pressReleaseDate={splitDateAndTime(x.pressReleaseDate)}
                            eventDate={splitDateAndTime(x.eventDate)}
                            listName={x.listName}
                            isApproxPublishDate={x.isApproxPublishDate}
                            year={x.year}
                            mediaKitExternalLink={mediaKitExternalLink}
                            showNationalStandardDetails={showNationalStandardDetails}
                            isDallasRankedWinner={isDallasRankedWinner}
                            showDallasNationalStandardDetails={showDallasNationalStandardDetails}
                            mediaKitExternalLinkParameters={getExternalLinkParameters(x.year, x.awardListParticipantId)}
                            hasAlphabeticalWinnersList={x.hasAlphabeticalWinnersList}
                            alphabeticalWinnersListDate={splitDateAndTime(x.alphabeticalWinnersListDate)}
                        />
                        {(isBlackListedFromTwpMerch ||
                            index + 1 !== size(regionalAwards) ||
                            size(cultureExcellenceAwards) > 0 ||
                            size(industryAward) > 0) && <HorizontalLine className={horizontalLineClassName} />}
                    </div>
                ))}
            {size(cultureExcellenceAwards) > 0 &&
                map(
                    cultureExcellenceAwards,
                    (awards, key) =>
                        awards && (
                            <CEAwardsSection
                                awards={awards}
                                quarter={key}
                                horizontalLineClassName={horizontalLineClassName}
                                mediaKitExternalLink={mediaKitExternalLink}
                                companyName={companyName}
                                isBlackListedFromTwpMerch={isBlackListedFromTwpMerch}
                                ceAwards={cultureExcellenceAwards}
                            />
                        )
                )}
            {industryAward && (
                <div className="flex flex-col justify-center mt-12 pb-8">
                    <div className="relative self-center">
                        <SvgLoader path={industryAward.svgUrl} height="10rem" alt="Industry awards logos">
                            <SvgProxy
                                selector="text"
                                font-family="'Yantramanav', sans-serif"
                                font-size="46"
                                font-weight="900"
                            />
                            <SvgProxy selector="text tspan" y={getYearPosition(industryAward.awardName)}>
                                {`${industryAward.year}`}
                            </SvgProxy>
                        </SvgLoader>
                    </div>
                    <h1 className="text-xl sm:text-5xl text-center font-black mt-3">{INDUSTRY_AWARD_NAME}</h1>
                    <Timeline
                        companyName={companyName}
                        publicationDate={splitDateAndTime(industryAward.publicationDate)}
                        pressReleaseDate={splitDateAndTime(industryAward.pressReleaseDate)}
                        eventDate={splitDateAndTime(industryAward.eventDate)}
                        listName={industryAward.listName}
                        isApproxPublishDate={industryAward.isApproxPublishDate}
                        year={industryAward.year}
                        mediaKitExternalLink={mediaKitExternalLink}
                        mediaKitExternalLinkParameters={getExternalLinkParameters(
                            industryAward.year,
                            industryAward.awardListParticipantId
                        )}
                        isCeOrIndustryAward={true}
                        hasAlphabeticalWinnersList={industryAward.hasAlphabeticalWinnersList}
                        alphabeticalWinnersListDate={splitDateAndTime(industryAward.alphabeticalWinnersListDate)}
                    />
                    {isBlackListedFromTwpMerch && <HorizontalLine className={horizontalLineClassName} />}
                </div>
            )}
        </Section>
    )
}

export default WhatsNext
