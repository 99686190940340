import reduce from 'lodash/reduce'

export default function (obj) {
    return reduce(
        obj,
        (cleanParams, value, key) => {
            if (value) {
                cleanParams[key] = value
                return cleanParams
            }

            return cleanParams
        },
        {}
    )
}
