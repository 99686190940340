import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import size from 'lodash/size'
import split from 'lodash/split'
import Notification from 'components/StatusBar/NotificationMenu/Notification'
import safeInvoke from 'util/safeInvoke'
import TaskCategoryIcon from './TaskCategoryIcon'
import taskInformation from './taskInformation'
import taskTypes from './taskTypes'
import taskWorkflowCategories from './taskWorkflowCategories'

const getCategoryAndTaskFromWorkflow = (incompleteTaskList, workflowTaskName) => {
    const category = find(taskWorkflowCategories, (c) => c.name === workflowTaskName)
    const incompleteTaskIds = split(incompleteTaskList, ',')
    const incompleteTasks = map(category?.tasks, (t) => ({
        ...t,
        isComplete: !includes(incompleteTaskIds, `${t.taskDefinitionId}`),
    }))

    return {
        category,
        tasks: filter(incompleteTasks, (t) => !t.isComplete),
    }
}

const getMessageForTaskWorkflow = ({
    deadlineDateTime: deadlineDate,
    incompleteTaskList,
    awardListParticipantId,
    workflowTaskName,
    identity,
}) => {
    const { category, tasks } = getCategoryAndTaskFromWorkflow(incompleteTaskList, workflowTaskName)

    if (size(tasks) === 0 || !category) {
        return {}
    }

    const isUrgent = taskTypes[category.type]?.isUrgent(deadlineDate)
    const getMessage = taskInformation[workflowTaskName]
    const path = safeInvoke(category.path, { identity, awardListParticipantId })
    return { tasks, isUrgent, path, category, getMessage }
}

export const IncompleteTaskNotification = (props) => {
    const { tasks, isUrgent, path, category, getMessage } = getMessageForTaskWorkflow({
        ...props.object,
        identity: props.identity,
    })

    const message = getMessage?.({ isUrgent, awardName: props.object.awardName, path, tasks })

    if (!message) {
        return null
    }

    return (
        <Notification
            {...props}
            link={path}
            text={() => message}
            icon={<TaskCategoryIcon category={category} isUrgent={isUrgent} />}
        />
    )
}

export default IncompleteTaskNotification
