import { useCallback, useState } from 'react'

type UseVisibilityReturn = {
    visible: boolean
    show: () => void
    hide: () => void
}

export function useVisibility(initialState = false): UseVisibilityReturn {
    const [visible, setVisible] = useState(initialState)

    const show = useCallback(() => {
        setVisible(true)
    }, [])

    const hide = useCallback(() => {
        setVisible(false)
    }, [])

    return { visible, show, hide }
}
