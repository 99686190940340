import { ErrorScheduleSet } from './Components/ErrorScheduleSet'
import { NoScheduleSet } from './Components/NoScheduleSet'
import { ScheduleSet } from './Components/ScheduleSet'

const ScheduleTile = ({
    surveyEventId,
    invitations,
    isEditable,
    timeZone,
    error,
    completedTask,
    earliestStartDateTime,
    latestStartDateTime,
    useScheduledSurveyClose,
    awardListDeadlineDateTimes,
    lastAvailableSurveyDateTime,
}) => {
    if (error) {
        return <ErrorScheduleSet />
    } else {
        return completedTask ? (
            <ScheduleSet
                invitations={invitations}
                useScheduledSurveyClose={useScheduledSurveyClose}
                isEditable={isEditable}
                timeZone={timeZone}
                surveyEventId={surveyEventId}
                earliestStartDateTime={earliestStartDateTime}
                latestStartDateTime={latestStartDateTime}
                awardListDeadlineDateTimes={awardListDeadlineDateTimes}
                lastAvailableSurveyDateTime={lastAvailableSurveyDateTime}
            />
        ) : (
            <NoScheduleSet surveyEventId={surveyEventId} />
        )
    }
}

export default ScheduleTile
