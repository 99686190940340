import PropTypes from 'prop-types'
import { Skeleton } from '@energage/components'
import cx from 'clsx'
import map from 'lodash/map'
import { useDemographicSubsection } from '../../../../StatementSetup/DemographicCategorySection/useDemographicSubsection'
import { NonExpandableSection } from '../../../../StatementSetup/fragments'
import QuestionCategory from '../../../../StatementSetup/QuestionCategory'
import useDemographicQuestionCreation from '../../../../StatementSetup/useDemographicQuestionCreation'
import { DemographicQuestionTable } from '../QuestionTable/DemographicQuestionTable'

const DemographicCategorySection = ({
    subsectionId,
    evaluateQuestionSelected,
    evaluateQuestionParentSelected,
    surveyEventId,
    onUpdateQuestions,
    disabled,
    className,
    onUpdateForRequiredQuestion,
}) => {
    const demographicSubsectionStore = useDemographicSubsection(subsectionId)
    const [, updateSubsection] = demographicSubsectionStore

    const { demographicQuestions } = useDemographicQuestionCreation(
        surveyEventId,
        demographicSubsectionStore,
        evaluateQuestionSelected,
        evaluateQuestionParentSelected
    )

    function handleQuestionUpdate(updates) {
        updateSubsection((ss) => ({
            ...ss,
            questions: map(ss.questions, (q) => {
                if (updates.hasOwnProperty(q.questionEssenceId)) {
                    return { ...q, isSelected: updates[q.questionEssenceId] }
                }
                return q
            }),
        }))
        onUpdateQuestions()
    }

    function handleQuestionRequiredUpdate(updates) {
        updateSubsection((ss) => ({
            ...ss,
            questions: map(ss.questions, (q) => {
                if (updates.hasOwnProperty(q.questionEssenceId)) {
                    if (q.isSelected === false) {
                        return {
                            ...q,
                            isSelected: updates[q.questionEssenceId],
                            isRequired: updates[q.questionEssenceId],
                        }
                    } else {
                        return { ...q, isRequired: updates[q.questionEssenceId] }
                    }
                }
                return q
            }),
        }))
        onUpdateForRequiredQuestion()
    }

    return (
        <div className={cx('pr-8', className)}>
            <DemographicQuestionTable
                title="Question"
                categoryId={QuestionCategory.Demographic}
                onUpdateQuestions={handleQuestionUpdate}
                onUpdateForRequiredQuestion={handleQuestionRequiredUpdate}
                demographicQuestions={demographicQuestions}
                disabled={disabled}
            />
        </div>
    )
}

DemographicCategorySection.propTypes = {
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

function QuestionCategorySectionSkeleton() {
    return (
        <NonExpandableSection
            title={<Skeleton.HeaderShape className="w-64 pl-6" />}
            text={<Skeleton.ParagraphShape rows={5} className="pl-5" />}
        />
    )
}

DemographicCategorySection.Skeleton = QuestionCategorySectionSkeleton

export default DemographicCategorySection
