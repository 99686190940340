import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, Select } from '@energage/components'
import find from 'lodash/find'
import { optionTagShape } from '../../../propTypes'

const SelectLocationName = ({ allLocationNames, locationNameId, onNewLocSelect }) => {
    const handleNewLocSelect = useCallback(
        (data) => {
            onNewLocSelect(data)
        },
        [onNewLocSelect]
    )

    return (
        <InputGroup
            label="Location"
            className="flex-1 md:pr-3"
            as={Select}
            name="locationName"
            id="locationName"
            value={find(allLocationNames, (x) => x.id === locationNameId)}
            options={allLocationNames}
            onChange={handleNewLocSelect}
            getOptionLabel={(opt) => opt.value}
            getOptionValue={(opt) => opt.id}
        />
    )
}

SelectLocationName.propTypes = {
    locationNameId: PropTypes.string,
    allLocationNames: PropTypes.arrayOf(PropTypes.shape(optionTagShape)),
    onNewLocSelect: PropTypes.func.isRequired,
}

export default SelectLocationName
