import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { array, boolean, number, object, string } from 'yup'
import type { TestContext } from 'yup'
import { IS_FULLY_REMOTE_COMPANY } from 'containers/Main/EmployerRecognition/utils'
import { PHONE_NUMBER_REGEX } from 'util/validators'
import type { Location } from './awardRegistration.types'
import { LocationFormOptions } from './constants'

const ZIP_CODE_LENGTH = 5
const IS_DRAFT = 'isDraft'
const IS_REGIONAL_EMP_COUNT_VISIBLE = 'isRegionEmployeeCountVisible'
const IS_REMOTE_WORK_AWARD = 'isRemoteWorkAward'
const IS_WOMEN_LED_AWARD = 'isWomenLedAward'
// eslint-disable-next-line no-template-curly-in-string
const REQUIRED_ERROR = '${label} is a required field'
const CHECK_REMOTE_FIELD_ONE = 'remoteWorkAward.confirmRemoteAwardChkOne'
const CHECK_REMOTE_FIELD_TWO = 'remoteWorkAward.confirmRemoteAwardChkTwo'
const CHECK_WOMEN_LED_FIELD = 'womenLedAward.confirmWomenLedAwardChk'

export const isDuplicateLocation = (allLocations: Location[], currentLocation: Location, field: string) => {
    if (allLocations && allLocations.length > 0) {
        const explodeField = field?.match(/\[(.*?)\]/)
        if (
            explodeField &&
            currentLocation.stateAbbreviation &&
            currentLocation.streetAddress &&
            currentLocation.zipCode &&
            currentLocation.city
        ) {
            const index = parseInt(explodeField[1])
            const duplicateLocations = filter(
                allLocations,
                (location: Location) =>
                    location.index !== index &&
                    location.streetAddress?.toLowerCase().trim() ===
                        currentLocation.streetAddress?.toLowerCase().trim() &&
                    location.zipCode === currentLocation.zipCode &&
                    location.stateAbbreviation?.toLowerCase().trim() ===
                        currentLocation.stateAbbreviation?.toLowerCase().trim() &&
                    location.city?.toLowerCase().trim() === currentLocation.city?.toLowerCase().trim()
            )
            return duplicateLocations.length === 0
        }
    }
    return true
}

const locationSchemaObject = {
    id: number().nullable(),
    name: string()
        .nullable(true)
        .label('Location Nickname')
        .typeError(REQUIRED_ERROR)
        .test({
            name: 'requiredInRegional',
            message: REQUIRED_ERROR,
            test: function (value: string | null | undefined, formData: TestContext) {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    // @ts-ignore
                    const { name, streetAddress, zipCode, stateAbbreviation, city } = this?.options?.parent
                    return (
                        this?.options?.context?.isNational ||
                        value ||
                        !(name || streetAddress || zipCode || stateAbbreviation || city) ||
                        !isEmpty(value)
                    )
                } else {
                    return this?.options?.context?.isNational || value
                }
            },
        }),
    employeeCount: number()
        .nullable(true)
        .label('Location Employee Count')
        .typeError(REQUIRED_ERROR)
        .transform((v) => (Number.isNaN(v) ? null : v))
        .test({
            name: 'requiredInRegional',
            message: REQUIRED_ERROR,
            test: function (value: number | null | undefined, formData: TestContext) {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    // @ts-ignore
                    const { name, streetAddress, zipCode, stateAbbreviation, city } = this?.options?.parent
                    return (
                        this?.options?.context?.isNational ||
                        value ||
                        !(name || streetAddress || zipCode || stateAbbreviation || city) ||
                        !isEmpty(value)
                    )
                } else {
                    return this?.options?.context?.isNational || value
                }
            },
        }),
    streetAddress: string()
        .test({
            name: 'duplicate',
            message: 'Duplicate Address',
            test: function (data, name) {
                return isDuplicateLocation(this?.options?.context?.locations, this?.parent, name.path)
            },
        })
        .label('Address')
        .typeError(REQUIRED_ERROR)
        .test({
            name: 'requiredIf',
            message: REQUIRED_ERROR,
            test: (value: string | null | undefined, formData: TestContext) => {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    const { name, employeeCount, zipCode, stateAbbreviation, city } = formData.parent
                    return !(name || employeeCount || zipCode || stateAbbreviation || city) || !isEmpty(value)
                } else {
                    return !isEmpty(value)
                }
            },
        }),
    city: string()
        .test({
            name: 'duplicate',
            message: 'Duplicate City',
            test: function (data, name) {
                return isDuplicateLocation(this?.options?.context?.locations, this?.parent, name.path)
            },
        })
        .label('City')
        .typeError(REQUIRED_ERROR)
        .test({
            name: 'requiredIf',
            message: REQUIRED_ERROR,
            test: (value: string | null | undefined, formData: TestContext) => {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    const { name, employeeCount, zipCode, stateAbbreviation, streetAddress } = formData.parent
                    return !(name || employeeCount || zipCode || stateAbbreviation || streetAddress) || !isEmpty(value)
                } else {
                    return !isEmpty(value)
                }
            },
        }),
    stateAbbreviation: string()
        .nullable()
        .typeError(REQUIRED_ERROR)
        .test({
            name: 'duplicate',
            message: 'Duplicate State',
            test: function (data, name) {
                return isDuplicateLocation(this?.options?.context?.locations, this?.parent, name.path)
            },
        })
        .test({
            name: 'stateAbbreviationValidation',
            exclusive: true,
            message: REQUIRED_ERROR,
            test: function (item: string | null | undefined, formData: TestContext) {
                // @ts-ignore
                const states = this?.options?.context?.states
                return (
                    // @ts-ignore
                    (formData.from[1].value.isDraft && !item) ||
                    !states ||
                    !!find(states, (s) => s.abbreviation === item)
                )
            },
        })
        .test({
            name: 'requiredIf',
            message: REQUIRED_ERROR,
            test: (value: string | null | undefined, formData: TestContext) => {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    const { name, employeeCount, zipCode, city, streetAddress } = formData.parent
                    return !(name || employeeCount || zipCode || city || streetAddress) || !isEmpty(value)
                } else {
                    return !isEmpty(value)
                }
            },
        })
        .label('State'),
    zipCode: string()
        .test({
            name: 'length',
            params: { length: ZIP_CODE_LENGTH },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${label} must be exactly ${length} characters',
            test: (value) => !value || size(value) === ZIP_CODE_LENGTH,
        })
        .test({
            name: 'stateAndZipCodeValidation',
            // eslint-disable-next-line no-template-curly-in-string
            message: "${label} doesn't belong to the selected state",
            test: function (item) {
                const zipCodes = this?.options?.context?.zipCodes
                if (isEmpty(zipCodes)) {
                    return true
                }
                const selectedState = this?.parent?.stateAbbreviation
                const applicableState = item && zipCodes[item]?.state
                return !selectedState || !item || selectedState === applicableState
            },
        })
        .test({
            name: 'duplicate',
            message: 'Duplicate Zip',
            test: function (data, name) {
                return isDuplicateLocation(this?.options?.context?.locations, this?.parent, name.path)
            },
        })
        .label('Zip Code')
        .typeError(REQUIRED_ERROR)
        .test({
            name: 'requiredIf',
            message: REQUIRED_ERROR,
            test: (value: string | null | undefined, formData: TestContext) => {
                // @ts-ignore
                if (formData.from[1].value.isDraft) {
                    const { name, employeeCount, stateAbbreviation, city, streetAddress } = formData.parent
                    return !(name || employeeCount || stateAbbreviation || city || streetAddress) || !isEmpty(value)
                } else {
                    return !isEmpty(value)
                }
            },
        }),
}

export const awardRegistrationSchema = object({
    isDraft: boolean().defined(),
    isLocationFormEmpty: boolean().defined(),
    publisher: object({
        isNational: boolean().defined(),
    }),
    remoteWorkAward: object({
        confirmRemoteAwardChkOne: boolean(),
        confirmRemoteAwardChkTwo: boolean(),
    }),
    womenLedAward: object({
        confirmWomenLedAwardChk: number(),
    }),
    isRegionEmployeeCountVisible: boolean(),
    isRemoteWorkAward: boolean(),
    isWomenLedAward: boolean(),
    hasFullyRemoteParticipation: boolean(),
    awardListParticipantId: number().defined(),
    awardId: number().defined(),
    salesforceYear: number().defined(),
    hasAgreedToEligibilityCriteria: boolean().when([IS_REMOTE_WORK_AWARD, IS_WOMEN_LED_AWARD], {
        is: (isRemoteWorkAward: boolean, isWomenLedAward: boolean) => isRemoteWorkAward || isWomenLedAward,
        then: boolean().when(IS_DRAFT, {
            is: true,
            then: boolean().when([CHECK_REMOTE_FIELD_ONE, CHECK_REMOTE_FIELD_TWO, CHECK_WOMEN_LED_FIELD], {
                is: (
                    confirmRemoteAwardChkOne: boolean,
                    confirmRemoteAwardChkTwp: boolean,
                    confirmWomenLedAwardChk: number
                ) => confirmRemoteAwardChkOne || confirmRemoteAwardChkTwp || confirmWomenLedAwardChk,
                then: boolean().oneOf([true]).required(),
            }),
            otherwise: boolean().when(IS_DRAFT, {
                is: false,
                then: boolean().oneOf([true]).required(),
            }),
        }),
    }),
    organization: object({
        companyName: string().nullable(true).label('Company Name').required(),
        yearFounded: number()
            .nullable(true)
            .typeError('Year Founded must be valid')
            .test('yearFoundedValidation', "Year Founded can't be in the future", (value) => {
                return Number(value) <= new Date().getFullYear()
            })
            .label('Year Founded')
            .required(),
        ownershipTypeId: number().typeError('Ownership must be selected').label('Ownership').required(),
        sectorDescription: string()
            .nullable(true)
            .max(100, 'Must be at most 100 characters')
            .label('Sector Description'),
        childSector: object().nullable().label('Sub Sector'),
        isSectorApproved: boolean(),
        parentSector: object().nullable().label('Sector'),
    }).when(IS_DRAFT, {
        is: false,
        then: (schema) =>
            schema.shape({
                sectorDescription: string()
                    .nullable(true)
                    .max(100, 'Must be at most 100 characters')
                    .label('Sector Description')
                    .required(),
                childSector: object()
                    .nullable()
                    .label('Sub Sector')
                    .when('isSectorApproved', {
                        is: false,
                        then: () =>
                            object()
                                .nullable()
                                .test({
                                    name: 'childSectorValidation',
                                    exclusive: true,
                                    message: 'Sub Sector is a required field',
                                    test: function (item) {
                                        const sectors = this?.options?.context?.sectors
                                        return !item || !sectors || !!find(sectors, (c) => c.id === item.id)
                                    },
                                })
                                .label('Sub Sector')
                                .required(),
                    }),
                parentSector: object()
                    .nullable()
                    .test({
                        name: 'parentSectorValidation',
                        exclusive: true,
                        message: 'Sector is a required field',
                        test: function (item) {
                            const sectors = this?.options?.context?.sectors
                            return !item || !sectors || !!find(sectors, (p) => p.id === item.id)
                        },
                    })
                    .label('Sector')
                    .required(),
            }),
    }),
    region: object({
        employeeCount: number()
            .nullable(true)
            .transform((_, val) => (Number(val) ? Number(val) : null))
            .label('Number of Employees in United States'),
        confirmEmpCountChkOne: boolean(),
        confirmEmpCountChkThree: boolean(),
        confirmEmpCountChkTwo: boolean(),
        hasConfirmedEmpCount: boolean(),
    }).when([IS_DRAFT, IS_REGIONAL_EMP_COUNT_VISIBLE], {
        is: (isDraft: boolean, isRegionEmployeeCountVisible: boolean) => !isDraft && isRegionEmployeeCountVisible,
        then: (schema) =>
            schema.shape({
                employeeCount: number()
                    .nullable(true)
                    .transform((_, val) => (Number(val) ? Number(val) : null))
                    .label('Number of Employees in United States')
                    .test({
                        name: 'regionEmployeeCountValidation',
                        exclusive: true,
                        message: 'Number of Employees in United States should be more than 0',
                        test: function (value) {
                            return Number(value) > 0
                        },
                    })
                    .required(),
            }),
    }),
    locationStatus: number().when([IS_DRAFT, IS_WOMEN_LED_AWARD, IS_REMOTE_WORK_AWARD, 'isLocationFormEmpty'], {
        is: (isDraft: boolean, isWomenLedAward: boolean, isRemoteWorkAward: boolean, isLocationFormEmpty: boolean) =>
            !isDraft && !isWomenLedAward && !isRemoteWorkAward && isLocationFormEmpty,
        then: number().required().notOneOf([0], 'Location option is required'),
        otherwise: number(),
    }),
    locations: array()
        .when([IS_FULLY_REMOTE_COMPANY, 'locationStatus'], {
            is: (hasFullyRemoteParticipation: boolean, locationStatus: number) =>
                !hasFullyRemoteParticipation && locationStatus === LocationFormOptions.HasLocation,
            then: (schema) => schema.of(object(locationSchemaObject)),
        })
        .compact(),
    primaryContact: object({
        contactId: number()
            .nullable(true)
            .transform((_, val) => (Number(val) ? Number(val) : null)),
        emailAddress: string().nullable(true).email().label('Email Address'),
        contactTypeId: number().nullable(true).label('Area'),
        firstName: string().nullable(true).label('First Name'),
        lastName: string().nullable(true).label('Last Name'),
        title: string().nullable(true).label('Title'),
        phoneNumber: string().nullable(true).label('Phone Number'),
    }).when(IS_DRAFT, {
        is: false,
        then: (schema) =>
            schema.shape({
                contactTypeId: number().typeError('Area must be selected').label('Area').required(),
                firstName: string().nullable(true).trim().label('First Name').required(),
                lastName: string().nullable(true).trim().label('Last Name').required(),
                title: string().nullable(true).trim().label('Title').required(),
                emailAddress: string().nullable(true).email().label('Email Address').required(),
                phoneNumber: string()
                    .nullable(true)
                    .trim()
                    .matches(PHONE_NUMBER_REGEX, 'Phone Number is not valid')
                    .label('Phone Number')
                    .required(),
            }),
    }),
    leaderContact: object({
        contactId: number()
            .nullable(true)
            .transform((_, val) => (Number(val) ? Number(val) : null)),
        contactTypeId: number()
            .nullable(true)
            .transform((_, val) => (Number(val) ? Number(val) : null)),
        emailAddress: string().nullable(true).email().label('Email Address'),
        firstName: string().nullable(true).label('First Name'),
        lastName: string().nullable(true).label('Last Name'),
        title: string().nullable(true).label('Title'),
    }).when(IS_DRAFT, {
        is: false,
        then: (schema) =>
            schema.shape({
                firstName: string().nullable(true).trim().label('First Name').required(),
                lastName: string().nullable(true).trim().label('Last Name').required(),
                title: string().nullable(true).trim().label('Title').required(),
            }),
    }),
}).required()
