import { useRef } from 'react'
import { Button } from '@energage/components'
import { FileCopy } from '@energage/icons'
import { DialogTrigger, Modal } from '@energage/overlays'
import { INTERNAL_COMPANY_NAME, SUPPORT_PHONE } from 'constants/strings'
import { copyRichTextToClipboard } from 'util/copyToClipboard'

//use em and margins because Outlook better understands it when copying from web page
const pStyle = {
    marginBottom: '1em',
    border: '0',
}
const bulletStyle = {
    verticalAlign: 'middle',
    border: '0',
    fontSize: '22px',
    lineHeight: '16px',
}

const P = ({ children }) => <p style={pStyle}>{children}</p>
const BulletedText = ({ children }) => (
    <p style={{ border: '0' }}>
        <span style={bulletStyle}>{'\u2022'} </span> {children}
    </p>
)

const getRapidChangeTemplate = (templateDetails) => {
    const { responderCountSummaryThreshold, primaryContact, isFormatHasPaper, organizationName } = templateDetails

    return (
        <div data-testid="survey-setup-schedule-email-r-template">
            <P>{'Dear Colleague,'}</P>
            <P>
                {`Now more than ever, your input matters to us as we begin to define what the “new
                normal” looks like for our organization. We’d like to hear about your experience
                working at ${organizationName}, so we are conducting an employee engagement
                survey that will enable us to make people-informed decisions.`}
            </P>
            <P>
                {`Within the next week, you will receive an email that includes an access link.
                Please take a few minutes to complete the survey and provide us with your feedback.`}
            </P>
            <P>
                {`${INTERNAL_COMPANY_NAME}, an independent research company, is conducting the survey. To
                protect your confidentiality, they will not disclose your responses to us.
                Department, team, or group scores are revealed only when there is a `}
                <span
                    className="underline"
                    style={{
                        border: 0,
                    }}>{` minimum of ${responderCountSummaryThreshold} survey responses.`}</span>
                {` Managers at ${organizationName} may see the comments
                you offer on the survey's final page, so please do not write anything that could
                reveal your identity.`}
            </P>
            <P>{'A few things to note:'}</P>
            <P>{isFormatHasPaper && 'Some employees will receive a paper survey. '}</P>
            <BulletedText>
                {`If you do not receive the survey email within the next week, please check your
                spam or junk email for a message from “${INTERNAL_COMPANY_NAME} Survey.” To open the survey
                invitation, you may have to confirm the email is not spam.`}
            </BulletedText>
            <BulletedText>
                {
                    'If you delete the survey email accidentally, you will receive a reminder message within a few days that includes another access link.'
                }
            </BulletedText>
            <BulletedText>
                {
                    'Please do not forward the survey email to a colleague. Each email has a unique code to prevent multiple responses from a single person.'
                }
            </BulletedText>
            <P>{`Please reach out to ${INTERNAL_COMPANY_NAME} at ${SUPPORT_PHONE} or reply to your survey email if you have questions.`}</P>
            <P>{'Thanks for taking the time to complete the survey. We look forward to your feedback!'}</P>
            <P>
                {primaryContact.name} <br />
                {primaryContact.title}
            </P>
        </div>
    )
}

const getStandardTemplate = (templateDetails) => {
    const {
        isToughTimes,
        organizationName,
        responderCountSummaryThreshold,
        isFormatHasPaper,
        isRandomSelection,
        primaryContact,
    } = templateDetails

    return (
        <div data-testid="survey-setup-schedule-email-s-template">
            <P>{'Dear Colleague,'}</P>
            <P>
                {isToughTimes
                    ? `As you know, this has been a difficult time for ${organizationName}. However we feel it is critical to our future success
                        that we find out how everyone feels about working here, and that we identify our strengths and weaknesses. We are therefore
                        conducting an employee engagement survey to hear your views.`
                    : `Your input matters to us. We’d like to hear about your experience working at ${organizationName}, so we are conducting an employee engagement survey.`}
            </P>
            <P>{`Within the next week, you will receive an email that includes an access link. Please
                take a few minutes to complete the survey and provide us with your feedback.`}</P>

            <P>
                {`${INTERNAL_COMPANY_NAME}, an independent research company, is conducting the survey. To protect
                your confidentiality, they will not disclose your responses to us. Department, team,
                or group scores are revealed only when there is a `}
                <span
                    className="underline"
                    style={{
                        border: 0,
                    }}>
                    {`minimum of ${responderCountSummaryThreshold} survey responses.`}
                </span>
                {` Managers at ${organizationName} may see the comments you offer
                on the survey's final page, so please do not write anything that could reveal your
                identity.`}
            </P>

            {'A few things to note:'}
            {isFormatHasPaper && <BulletedText>{'Some employees will receive a paper survey.'}</BulletedText>}
            {isRandomSelection && (
                <BulletedText>{'Only randomly selected employees will receive a survey. '}</BulletedText>
            )}
            <BulletedText>
                {`If you do not receive the survey email within the next week, please check your
                spam or junk email for a message from “${INTERNAL_COMPANY_NAME} Survey.” To open the
                survey invitation, you may have to confirm the email is not spam.`}
            </BulletedText>
            <BulletedText>
                {`If you delete the survey email accidentally, you will receive a reminder
                message within a few days that includes another access link.`}
            </BulletedText>
            <BulletedText>
                {`Please do not forward the survey email to a colleague. Each email has a
                unique code to prevent multiple responses from a single person.`}
            </BulletedText>

            <P>
                <br />
                {`Please reach out to ${INTERNAL_COMPANY_NAME} at ${SUPPORT_PHONE} or reply to your survey email
                if you have questions.`}
            </P>
            <P>{'Thanks for taking the time to complete the survey. We look forward to your feedback!'}</P>
            <P>
                {primaryContact.name} <br />
                {primaryContact.title}
            </P>
        </div>
    )
}

const getTemplate = (templateDetails) => {
    const { isRapidChange } = templateDetails

    if (isRapidChange) {
        return getRapidChangeTemplate(templateDetails)
    }

    return getStandardTemplate(templateDetails)
}

const TemplateLink = ({
    linkText = 'Standard Template',
    isToughTimes = false,
    isRapidChange = false,
    organizationName,
    responderCountSummaryThreshold,
    isFormatHasPaper,
    isRandomSelection,
    primaryContact,
}) => {
    const emailContent = useRef(null)

    const templateDetails = {
        organizationName,
        isToughTimes,
        responderCountSummaryThreshold,
        isFormatHasPaper,
        isRandomSelection,
        primaryContact,
        isRapidChange,
    }

    return (
        <DialogTrigger>
            <Button appearance="tertiary" className="-ml-4">
                {linkText}
            </Button>
            <Modal title={`Subject: ${organizationName} Employee Survey`}>
                <p className="text-red500 mb-4">
                    <strong>{'Please copy and paste the text below into an email.'}</strong>
                    <span
                        className="cursor-pointer text-grey500 hover:text-grey600 ml-2"
                        onClick={() => copyRichTextToClipboard(emailContent.current)}
                        title="Copy to Clipboard">
                        <FileCopy />
                    </span>
                </p>
                <div ref={emailContent}>{getTemplate(templateDetails)}</div>
            </Modal>
        </DialogTrigger>
    )
}

export default TemplateLink
