import { alert } from '@energage/components'
import find from 'lodash/find'
import last from 'lodash/last'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import split from 'lodash/split'
import startsWith from 'lodash/startsWith'
import trim from 'lodash/trim'
import config from 'config'
import { usePost } from 'hooks'

const DEFAULT_COUNTRY = 'United States of America'
const BILLED_TO_LABEL = 'Billing Information'
const SOLD_TO_LABEL = 'Contact Information'

// TODO: Unify mapping for orders/renewals
const BILLED_TO_TYPE_ID = 1
const SOLD_TO_TYPE_ID = 2

export const contactTypes = {
    BILLED_TO: 'billedTo',
    SOLD_TO: 'soldTo',
}

const orderPageContactTypes = {
    [BILLED_TO_TYPE_ID]: {
        value: contactTypes.BILLED_TO,
        label: BILLED_TO_LABEL,
    },
    [SOLD_TO_TYPE_ID]: {
        value: contactTypes.SOLD_TO,
        label: SOLD_TO_LABEL,
    },
}

export const useUpdateContacts = (accountId, onComplete) => {
    const { doPost, isLoading: isUpdatingContacts } = usePost({
        url: `${config.api.subscription}/subscriptions/accounts/${accountId}`,
        onComplete: () => {
            onComplete()
            alert.success('Contacts updated successfully.')
        },
        onError: () =>
            alert.danger(
                'There was a problem saving contact details. Please make sure Contact and Billing information is complete.'
            ),
    })

    const updateContacts = (contacts) => {
        doPost({
            accountId,
            ...contacts,
        })
    }

    return {
        updateContacts,
        isUpdatingContacts,
    }
}

const createContactPayload = (contact) =>
    mapValues(
        {
            firstName: contact.firstName,
            lastName: contact.lastName,
            workEmail: contact.email,
            address1: contact.address,
            city: contact.city,
            state: contact.state,
            zipCode: contact.zipCode,
        },
        trim
    )

const getContact = (contacts) => (contactType) => createContactPayload(find(contacts, (c) => c.type === contactType))
const withOriginalKeys = (_, key) => last(split(key, '-'))
const sanitize = (contact) => mapKeys(contact, withOriginalKeys)
const pickKeys = (contacts) => (contactType) => sanitize(pickBy(contacts, (_, key) => startsWith(key, contactType)))

export const mapRawFormToState = (contacts) => {
    const pickByContactType = pickKeys(contacts)
    const billedToContact = {
        ...mapValues(pickByContactType(contactTypes.BILLED_TO), trim),
        label: BILLED_TO_LABEL,
        type: contactTypes.BILLED_TO,
    }
    const soldToContact = {
        ...mapValues(pickByContactType(contactTypes.SOLD_TO), trim),
        label: SOLD_TO_LABEL,
        type: contactTypes.SOLD_TO,
    }
    return [billedToContact, soldToContact]
}

export const buildContactsPayload = (contacts) => {
    const get = getContact(contacts)
    const billToContact = get(contactTypes.BILLED_TO)
    const soldToContact = get(contactTypes.SOLD_TO)
    return {
        billToContact,
        soldToContact,
    }
}

export const buildOrderPageContactsPayload = (surveyEventId, contacts) => ({
    surveyEventId,
    contacts: map(contacts, (c) => ({
        ...mapValues(
            {
                firstName: c.firstName,
                lastName: c.lastName,
                emailAddress: c.email,
                address: c.address,
                city: c.city,
                state: c.state,
                zipCode: c.zipCode,
                country: DEFAULT_COUNTRY,
            },
            trim
        ),
        type: c.type === contactTypes.BILLED_TO ? BILLED_TO_TYPE_ID : SOLD_TO_TYPE_ID,
    })),
})

const createContact = (contact, type) =>
    mapValues(
        {
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.workEmail || contact.personalEmail,
            address: `${contact.address1 || ''} ${contact.address2 || ''}`,
            city: contact.city,
            state: contact.state,
            zipCode: contact.zipCode,
            label: type === contactTypes.BILLED_TO ? BILLED_TO_LABEL : SOLD_TO_LABEL,
            type,
        },
        trim
    )

export const buildContacts = ({ billToContact, soldToContact }) => [
    createContact(billToContact, contactTypes.BILLED_TO),
    createContact(soldToContact, contactTypes.SOLD_TO),
]

const createRenewalContact = (contact, type) =>
    mapValues(
        {
            firstName: contact.firstName,
            lastName: contact.lastName,
            emailAddress: contact.workEmail || contact.personalEmail,
            address: `${contact.address1 || ''} ${contact.address2 || ''}`,
            city: contact.city,
            state: contact.state,
            zipCode: contact.zipCode,
            type,
        },
        trim
    )

export const buildRenewalContacts = ({ billToContact, soldToContact }) => [
    createRenewalContact(billToContact, BILLED_TO_TYPE_ID),
    createRenewalContact(soldToContact, SOLD_TO_TYPE_ID),
]

const createOrderPageContact = (contact) => {
    const type = orderPageContactTypes[contact.type]
    return mapValues(
        {
            firstName: contact.firstName ?? '',
            lastName: contact.lastName ?? '',
            email: contact.emailAddress ?? '',
            address: contact.address ?? '',
            city: contact.city ?? '',
            state: contact.state ?? '',
            zipCode: contact.zipCode ?? '',
            label: type.label,
            type: type.value,
        },
        trim
    )
}

export const buildOrderPageContacts = (contacts) => map(contacts, createOrderPageContact)
