import type { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import cx from 'clsx'
import map from 'lodash/map'
import styled from 'styled-components'
import { media } from 'style/breakpoints'

const DESKTOP_INDENT = 32
const MOBILE_INDENT = 16

const getIndentationWidth = (unitIndentWidth: number, indentLevel?: number): number =>
    (indentLevel || 0) * unitIndentWidth
const getColumnWidth = (unitIndentWidth: number, width?: number, indentLevel?: number): string =>
    `${width || 0} - ${(indentLevel || 0) * (unitIndentWidth - 16)}`

export const TableRow = styled.div.attrs((props) => ({
    className: cx('text-xs -mt-px relative', props.className),
}))`
    :after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: ${(props: { indentLevel?: number }) => getIndentationWidth(MOBILE_INDENT, props.indentLevel)}px;
        content: '';
        z-index: 1;
        background-color: ${colors.white};
        border: 1px solid ${colors.grey300};
    }

    :nth-child(even):after {
        background-color: ${colors.grey100};
    }

    ${media.mdMin`
        :after {
            left: ${(props: { indentLevel?: number }) => getIndentationWidth(DESKTOP_INDENT, props.indentLevel)}px;
        }
    `}
`

export const TableCell = styled.span.attrs<{ className?: string }>({
    className: 'inline-block p-2 relative z-10',
})`
    ${(props: { cellWidth?: number; indentLevel?: number }) =>
        props.cellWidth && `width: calc(${getColumnWidth(MOBILE_INDENT, props.cellWidth, props.indentLevel)}px);`}
    padding-left: ${(props: { indentLevel?: number }) =>
        getIndentationWidth(MOBILE_INDENT, props.indentLevel) + 8}px !important;

    ${media.mdMin`
        ${(props: { cellWidth?: number; indentLevel?: number }) =>
            props.cellWidth && `width: calc(${getColumnWidth(DESKTOP_INDENT, props.cellWidth, props.indentLevel)}px);`}
        padding-left: ${(props: { indentLevel?: number }) =>
            getIndentationWidth(DESKTOP_INDENT, props.indentLevel) + 8}px !important;
    `}
`

type Column<T> = {
    className: string
    key: keyof T
    render: (item: T) => ReactNode
}

type RowProps<T> = {
    className: string
    columns: Column<T>[]
    item: T
}

export const Row = <T,>({ className, columns, item }: RowProps<T>) => (
    <TableRow className={className}>
        {map(columns, ({ className, key, render }) => (
            <TableCell className={className} key={key as string}>
                {render ? render(item) : (item[key] as unknown as ReactNode)}
            </TableCell>
        ))}
    </TableRow>
)

Row.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            key: PropTypes.string,
            render: PropTypes.func,
        })
    ).isRequired,
}

export default Row
