import { Loading as Spinner } from '@energage/components'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { useIdentity } from 'components/Identity'
import { ReactComponent as EmptyArchiveNotification } from 'images/celebrate3.svg'
import { List, Notification } from './NotificationList'

const ArchivedList = ({ archivedNotifications, hideNotificationWindow }) => {
    const identity = useIdentity()
    const { notifications, isFetchingMore, fetchMore, canFetchMore } = archivedNotifications

    const infiniteRef = useInfiniteScroll({
        loading: isFetchingMore,
        hasNextPage: canFetchMore,
        onLoadMore: fetchMore,
        scrollContainer: 'parent',
    })

    if (size(notifications) === 0) {
        return (
            <div className="m-6 flex flex-col items-center">
                <EmptyArchiveNotification className="m-4 w-24 lg:w-32 xl:w-40" />
                <p className="h6 text-center">{"You don't have any archived notifications!"}</p>
            </div>
        )
    }

    const sortedNotifications = orderBy(notifications, 'readDate', 'desc')

    return (
        <List ref={infiniteRef}>
            {map(sortedNotifications, (notification) => (
                <Notification
                    key={notification.id}
                    {...notification}
                    identity={identity}
                    onClick={hideNotificationWindow}
                />
            ))}
            {isFetchingMore && (
                <div className="flex justify-center items-center p-2">
                    <Spinner size="small" text="" inline />
                </div>
            )}
        </List>
    )
}

export default ArchivedList
