/*
   Modified from: https://bobbyhadz.com/blog/get-browser-type-and-version-using-javascript
   to only account for the major browsers that we claim to support on our support page.
 */
export function getBrowserName() {
    const test = (regexp: RegExp) => {
        return regexp.test(navigator.userAgent)
    }

    if (test(/edg/i)) {
        return 'Microsoft Edge'
    } else if (test(/chrome|chromium|crios/i)) {
        return 'Chrome'
    } else if (test(/firefox|fxios/i)) {
        return 'Firefox'
    } else if (test(/safari/i)) {
        return 'Safari'
    }

    return undefined
}
