import some from 'lodash/some'
import take from 'lodash/take'
import values from 'lodash/values'
import { isEmptyString } from 'util/strings'
import { QuestionType } from './CustomQuestions/QuestionType'

export function hasErrors(question) {
    if (some(values(question._errors), (value) => value)) {
        return true
    }

    if (question.questionTypeId === QuestionType.MultiChoice && question.answerOptions?.length > 0) {
        return some(take(question.answerOptions, 2), (option) => {
            if (some(option._errors, (value) => value)) {
                return true
            }

            return isEmptyString(option.name)
        })
    }
}
