import PropTypes from 'prop-types'
import { SidebarLG } from '@energage/components'
import Media from 'react-media'
import { viewports } from 'style/breakpoints'
import SidebarXS from './SidebarXS'

const ResponsiveSidebar = (props) => (
    <>
        <Media query={{ maxWidth: viewports.smMax }}>
            <SidebarXS {...props} />
        </Media>
        <Media query={{ minWidth: viewports.mdMin }}>
            <SidebarLG {...props} />
        </Media>
    </>
)

ResponsiveSidebar.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ResponsiveSidebar
