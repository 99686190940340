import { SvgLoader, SvgProxy } from 'react-svgmt'
import { getYearPosition } from 'containers/Main/EmployerBranding/MediaKit/Utils'

export const ShowIndustryAward = ({ svgUrl, year, awardName }) => (
    <div>
        <SvgLoader path={svgUrl} height="10rem" alt="Industry awards logos">
            <SvgProxy selector="text" font-family="'Yantramanav', sans-serif" font-size="46" font-weight="900" />
            <SvgProxy selector="text tspan" y={getYearPosition(awardName)}>
                {`${year}`}
            </SvgProxy>
        </SvgLoader>
    </div>
)
