import { useMemo, useState } from 'react'
import { Skeleton, SpeechBubble } from '@energage/components'
import { colors } from '@energage/theme'
import size from 'lodash/size'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import { useIdentity, usePermissions } from 'components/Identity'
import SwimLaneContainer from 'components/SwimLane'
import { EndCardContent } from 'components/SwimLane/Shared'
import { routes } from 'constants/routes'
import {
    FiltersDataProvider,
    useFiltersData,
} from 'containers/Main/EmployerBranding/CultureFacts/CultureFactsConfiguration/FiltersDataProvider'
import { useFetchSubscriber } from 'containers/Main/EmployerBranding/useFetchSubscriber'
import CultureCloud from '../../../Public/RevampWidgets/CultureCloud'
import LoveMyJobBecause from '../../../Public/RevampWidgets/LoveMyJobBecause'
import OrganizationalStrengths from '../../../Public/RevampWidgets/OrganizationalStrengths'
import { tileTypes } from '../../../Public/RevampWidgets/Tile'
import { EmployeeBrandingPack } from './EmployeeBrandingPack'

const DEFAULT_BG_COLOR = colors.darkblue500

const SkeletonCard = styled(Skeleton.RectangularShape)`
    height: 510px;
    width: 448px;
`

const SwimLane = styled(SwimLaneContainer)`
    min-height: 500px;
`

type WidgetProps = {
    // eslint-disable-next-line
    data: any
    // eslint-disable-next-line
    filterData: any
    hasSubscription: boolean
    isLoading: boolean
}

export const BrandingSwimLane = () => {
    return (
        <FiltersDataProvider>
            <SwimLaneContent />
        </FiltersDataProvider>
    )
}

const SwimLaneContent = () => {
    const { employerBrandingSurveyEventId } = useIdentity()
    const { isFetching: initialIsFetching, hide, surveyEventId } = useFetchInitialLoadData()

    if (initialIsFetching || hide) {
        return <></>
    }

    return <BrandingItems surveyEventId={surveyEventId || employerBrandingSurveyEventId} />
}

const BrandingItems = ({ surveyEventId }: { surveyEventId: number }) => {
    const { hasEmployerBrandingSubscription } = usePermissions()

    const {
        surveyEventData,
        cultureCloudData,
        loveMyJobData,
        organizationalStrengthsData,
        isFetching,
        cultureCloudIsLoading,
        loveMyJobIsLoading,
        organizationalStrengthsIsLoading,
        isError,
        ShowEmployeePack,
        employeeBrandingPackIsLoading,
    } = useFetchBrandingItems(surveyEventId)

    const items = useMemo(() => {
        if (isFetching || isError) {
            return []
        }

        const filterData = {
            // @ts-ignore Not sure what this data is supposed to be at all.
            surveyEventName: surveyEventData && surveyEventData.surveyEventClosedDate, // Why is this a date?
        }
        const keyPrefix = 'dashboard-branding-swimlane'
        return [
            <EmployeeBrandingPack
                key={`${keyPrefix}-branding-pack`}
                hasSubscription={ShowEmployeePack}
                isLoading={employeeBrandingPackIsLoading}
            />,
            <CultureCloudWidget
                key={`${keyPrefix}-culture-cloud`}
                data={cultureCloudData}
                hasSubscription={hasEmployerBrandingSubscription}
                filterData={filterData}
                isLoading={cultureCloudIsLoading}
            />,
            <LoveMyJobBecauseWidget
                key={`${keyPrefix}-love-my-job`}
                data={loveMyJobData}
                hasSubscription={hasEmployerBrandingSubscription}
                filterData={filterData}
                isLoading={loveMyJobIsLoading}
            />,
            <SpeechBubble key={`${keyPrefix}-speech-unique`} tailPlacement="bottom-start" variant="speech" tabIndex={0}>
                {'Tell people what makes your company unique.'}
            </SpeechBubble>,
            <OrganizationalStrengthsWidget
                key={`${keyPrefix}-org-strengths`}
                data={organizationalStrengthsData}
                hasSubscription={hasEmployerBrandingSubscription}
                filterData={filterData}
                isLoading={organizationalStrengthsIsLoading}
            />,
            <EndCardContent
                key={`${keyPrefix}-end-card`}
                text={
                    'There’s plenty more where those came from! Check them out,  edit these, add your brand colors palette... and most of all, share them!'
                }
                linkText={'Visit Branding >>'}
                link={routes.branding()}
                openNewTab={false}
            />,
        ]
    }, [
        isFetching,
        isError,
        surveyEventData,
        cultureCloudIsLoading,
        cultureCloudData,
        hasEmployerBrandingSubscription,
        loveMyJobIsLoading,
        loveMyJobData,
        organizationalStrengthsIsLoading,
        organizationalStrengthsData,
        employeeBrandingPackIsLoading,
        ShowEmployeePack,
    ])

    if (isError) {
        return <></>
    }

    return (
        <SwimLane
            sectionTitle={'Build your brand'}
            items={items}
            isFetching={isFetching}
            dataEventId={'dashboard-branding-tiles'}
        />
    )
}

export const ScrollSnapWidgetContainer = styled(Link)`
    scroll-snap-align: start;
`
const CultureCloudWidget = ({ data, hasSubscription, filterData, isLoading }: WidgetProps) => (
    <ScrollSnapWidgetContainer to={routes.branding.cultureFacts()}>
        {isLoading ? (
            <SkeletonCard />
        ) : (
            <CultureCloud
                {...data}
                type={tileTypes.vertical}
                hasEmployerBrandingSubscription={hasSubscription}
                minDroplets={data.cultureCloudWordThreshold}
                backgroundColor={data?.backgroundColor || DEFAULT_BG_COLOR}
                filterData={filterData}
            />
        )}
    </ScrollSnapWidgetContainer>
)

const LoveMyJobBecauseWidget = ({ data, hasSubscription, filterData, isLoading }: WidgetProps) => (
    <ScrollSnapWidgetContainer to={routes.branding.cultureFacts()}>
        {isLoading ? (
            <SkeletonCard />
        ) : (
            <LoveMyJobBecause
                {...data}
                type={tileTypes.vertical}
                hasEmployerBrandingSubscription={hasSubscription}
                backgroundColor={data?.backgroundColor || DEFAULT_BG_COLOR}
                filterData={filterData}
            />
        )}
    </ScrollSnapWidgetContainer>
)

const OrganizationalStrengthsWidget = ({ data, hasSubscription, filterData, isLoading }: WidgetProps) => (
    <ScrollSnapWidgetContainer to={routes.branding.cultureFacts()}>
        {isLoading ? (
            <SkeletonCard />
        ) : (
            <OrganizationalStrengths
                {...data}
                type={tileTypes.vertical}
                hasEmployerBrandingSubscription={hasSubscription}
                backgroundColor={data?.backgroundColor || DEFAULT_BG_COLOR}
                filterData={filterData}
            />
        )}
    </ScrollSnapWidgetContainer>
)

function useFetchInitialLoadData() {
    const {
        data: hasEmployerBrandingSubscription,
        isFetching,
        isError,
    } = useFetchSubscriber('SurveyCompanies', 'CultureFacts')

    // @ts-ignore
    const { state } = useFiltersData()
    const {
        // @ts-ignore
        currentSelectedFilter: { surveyEventName } = {},
        brandingResultsDisabledSurveys,
        benchmarksNotAvailableSurveys,
        isAllSurveysDisabled,
        isLoading,
    } = state

    if (isLoading || isFetching) {
        return {
            isFetching: true,
        }
    }

    if (!hasEmployerBrandingSubscription) {
        return {
            isFetching: false,
            hide: true, // Instead of upselling we are currently hiding
        }
    }

    if (isAllSurveysDisabled || !surveyEventName || isError) {
        return {
            isFetching: false,
            hide: true,
        }
    }

    const isBenchmarksNotAvailableSurveys = size(benchmarksNotAvailableSurveys) > 0
    const isBrandingResultsDisabledSurveys = size(brandingResultsDisabledSurveys) > 0

    if (isBrandingResultsDisabledSurveys && isBenchmarksNotAvailableSurveys) {
        return {
            isFetching: false,
            hide: true,
        }
    }

    return {
        isFetching: false,
        hide: false,
        surveyEventId: surveyEventName,
    }
}

function useFetchBrandingItems(surveyEventId: number) {
    const { surveyCompanyId } = useIdentity()
    const [cultureCloudData, setCultureCloudData] = useState({})
    const [loveMyJobData, setLoveMyJobData] = useState({})
    const [organizationalStrengthsData, setOrganizationalStrengthsData] = useState({})

    // Survey Event Information Filters
    const {
        data: surveyEventData,
        isLoading: surveyEventIsLoading,
        isError: surveyEventError,
    } = useBrandingQuery(
        'ContentSubFilters',
        `SurveyCompanies/${surveyCompanyId}/culturefacts/SubFilters?surveyEventId=${surveyEventId}`,
        {
            onError: () => {
                return
            },
        }
    )

    // Culture Cloud
    const { isLoading: cultureCloudIsLoading } = useBrandingQuery(
        'cultureCloud-filter',
        `SurveyCompanies/${surveyCompanyId}/culturefacts/culturecloud/${surveyEventId}/false`,
        {
            // @ts-ignore
            onSuccess: setCultureCloudData,
            onError: () => {
                setCultureCloudData({})
            },
        }
    )

    // Love My Job
    const { isLoading: loveMyJobIsLoading } = useBrandingQuery(
        'loveMyJob-filter',
        `SurveyCompanies/${surveyCompanyId}/culturefacts/lovemyjob/${surveyEventId}/false`,
        {
            // @ts-ignore
            onSuccess: setLoveMyJobData,
            onError: () => {
                setLoveMyJobData({})
            },
        }
    )

    // Organizational Strengths
    const { isLoading: organizationalStrengthsIsLoading } = useBrandingQuery(
        'orgStrengths-filter',
        `SurveyCompanies/${surveyCompanyId}/culturefacts/orgstrengths/${surveyEventId}/false`,
        {
            // @ts-ignore
            onSuccess: setOrganizationalStrengthsData,
            onError: () => {
                setOrganizationalStrengthsData({})
            },
        }
    )

    // Employee Branding Pack
    const { data: ShowEmployeePack, isLoading: employeeBrandingPackIsLoading } = useBrandingQuery<boolean>(
        'ShowEmployeePack',
        `Dashboard/${surveyCompanyId}/employeepack/show`
    )

    return {
        surveyEventData,
        cultureCloudData,
        loveMyJobData,
        organizationalStrengthsData,
        isFetching: surveyEventIsLoading,
        cultureCloudIsLoading,
        loveMyJobIsLoading,
        organizationalStrengthsIsLoading,
        isError: surveyEventError,
        employeeBrandingPackIsLoading,
        ShowEmployeePack,
    }
}
