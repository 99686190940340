/* eslint-disable @typescript-eslint/no-explicit-any */
import compact from 'lodash/compact'
import noop from 'lodash/noop'
import reduce from 'lodash/reduce'

// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5, R6, R7, R8, R9, R10>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8, arg9: R9, arg10: R10) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8, arg9: R9, arg10: R10) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5, R6, R7, R8, R9>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8, arg9: R9) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8, arg9: R9) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5, R6, R7, R8>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7, arg8: R8) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5, R6, R7>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6, arg7: R7) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5, R6>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5, arg6: R6) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4, R5>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4, arg5: R5) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3, R4>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3, arg4: R4) => void)>): (arg1: R1, arg2: R2, arg3: R3, arg4: R4) => void
// prettier-ignore
export function chainFunctions<R1, R2, R3>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2, arg3: R3) => void)>): (arg1: R1, arg2: R2, arg3: R3) => void
// prettier-ignore
export function chainFunctions<R1, R2>(...funcs: Array<null | undefined | ((arg1: R1, arg2: R2) => void)>): (arg1: R1, arg2: R2) => void
// prettier-ignore
export function chainFunctions<R1>(...funcs: Array<null | undefined | ((arg1: R1) => void)>): (arg1: R1) => void
export function chainFunctions(...funcs: Array<null | undefined | ((...args: any[]) => void)>) {
    const chainedFunction = reduce(
        compact(funcs),
        (acc: ((...args: any[]) => void) | null, f) => {
            if (typeof f !== 'function') {
                throw new Error('Invalid Argument: must only provide functions, undefined, or null.')
            }

            if (acc === null) {
                return f
            }

            return function (...args: any[]) {
                acc(...args)
                f(...args)
            }
        },
        null
    )

    if (chainedFunction === null) {
        return noop
    }

    return chainedFunction
}

export default chainFunctions
