export default {
    celebrateNewsInternallyItems: {
        title: 'Celebrate the news internally',
        checkboxes: [
            { label: 'Make an announcement at a company meeting', className: 'w-full' },
            { label: 'Write an article in the company newsletter', className: 'w-full pt-1' },
            { label: 'Hold a celebration!', className: 'w-full pt-1' },
        ],
    },
    integrateIntoYourHiring: {
        title: 'Integrate into your hiring',
        checkboxes: [
            { label: 'Update your job postings', className: 'w-full' },
            { label: 'Update your recruiting materials', className: 'w-full pt-1' },
            { label: 'Update your careers page', className: 'w-full pt-1' },
            { label: 'Add to your Glassdoor profile', className: 'w-full pt-1' },
            { label: 'Add to your Monster profile', className: 'w-full pt-1' },
            { label: 'Add to your LinkedIn profile', className: 'w-full pt-1' },
            { label: 'Add to your recruiting profile', className: 'w-full pt-1' },
        ],
    },
    useTheTopWorkplacesLogo: {
        title: 'Use the Top Workplaces logo',
        checkboxes: [
            { label: 'Familiarize yourself with brand guidelines', className: 'w-full' },
            { label: 'Include on your homepage', className: 'w-full pt-1' },
            { label: 'Include in print and digital marketing materials', className: 'w-full pt-1' },
            { label: 'Insert into employee email signatures', className: 'w-full pt-1' },
            { label: 'Add the logo to your letterhead', className: 'w-full pt-1' },
            { label: 'Use the logo in your social media cover graphics', className: 'w-full pt-1' },
        ],
    },
    enlistYourLeader: {
        title: 'Enlist your leader',
        checkboxes: [
            { label: 'Include quote from your leader in media release', className: 'w-full' },
            { label: 'Have your leader share on their social media', className: 'w-full pt-1' },
            { label: 'Have leader post award on leader blog', className: 'w-full pt-1' },
            { label: 'Have your leader announce award internally', className: 'w-full pt-1' },
        ],
    },
    shareTheNewsExternally: {
        title: 'Share the news externally',
        checkboxes: [
            { label: 'Write a blog post', className: 'w-full' },
            { label: 'Share on Facebook', className: 'w-full pt-1' },
            { label: 'Share on Twitter', className: 'w-full pt-1' },
            { label: 'Share on LinkedIn', className: 'w-full pt-1' },
            { label: 'Share on any other social media sites', className: 'w-full pt-1' },
            { label: 'Write and distribute a media release', className: 'w-full pt-1' },
        ],
    },
    otherIdeas: {
        title: 'Other ideas',
        checkboxes: [
            { label: 'Improve your Top Workplaces profile', className: 'w-full' },
            { label: 'Link to your Top Workplace profile', className: 'w-full pt-1' },
            { label: 'Purchase a Top Workplaces plaque', className: 'w-full pt-1' },
            { label: 'Purchase other Top Workplaces merchandise', className: 'w-full pt-1' },
        ],
    },
}
