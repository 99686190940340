import cx from 'clsx'
import map from 'lodash/map'
import styled from 'styled-components'
import {
    CE_AND_INDUSTRY_PUBLISHER_ID,
    DALLAS_PUBLISHER_ID,
    LONG_ISLAND_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import {
    BackgroundImg,
    getBannerImages,
    LogoDiv,
    YearBox,
} from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/PressReleaseConfiguration'
import useFetchMediaKitAwardImages from 'containers/Main/EmployerBranding/MediaKit/useFetchMediaKitAwardImages'
import { parseQueryParams } from '../Widgets/QueryParser'

const className = 'absolute text-white font-medium leading-none text-base'

const MediaKitLogo = styled.div.attrs({ className })`
    height: 400px;
    width: 500px;
    transform: translate(-50%, -50%);
`
const SkylineYear = styled.div`
    margin-top: 15.5rem;
    width: 175px;
    line-height: 70px;
    font-size: 2.2rem;
`
const AwardYear = styled.p.attrs({ className: 'text-7xl font-bold absolute' })`
    margin-top: ${(props) => props.marginTop};
    margin-left: ${(props) => props.marginLeft};
    font-size: ${(props) => props.fontSize};
`
export const SkylineAndAwardImage = ({ location }) => {
    const parsedQuery = parseQueryParams(location)
    const { surveyCompanyId, publisherId, publishYear, isSkylineImage, isNationalStandard, awardCategoryId } =
        parsedQuery || {}

    const isCeAndIndutry = publisherId === CE_AND_INDUSTRY_PUBLISHER_ID

    const { pressReleaseResponse, isFetching, isError } = useFetchMediaKitAwardImages(
        publisherId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
        awardCategoryId
    )

    if (isFetching) {
        return null
    }

    if (isError) {
        return null
    }

    const { skyLineImageUrl, awardImageUrl, logoUrl } = getBannerImages(
        pressReleaseResponse,
        awardCategoryId,
        publisherId
    )

    const yearProps =
        +publisherId === TWP_USA_PUBLISHER_ID
            ? {
                  marginTop: '420px',
                  marginLeft: '120px',
              }
            : isNationalStandard && publisherId !== DALLAS_PUBLISHER_ID
            ? {
                  marginTop: '458px',
                  marginLeft: '130px',
              }
            : publisherId === REMOTE_WORK_PUBLISHER_ID
            ? {
                  marginTop: '267px',
                  marginLeft: '163px',
                  fontSize: '46px',
              }
            : publisherId === LONG_ISLAND_PUBLISHER_ID
            ? {
                  marginTop: '490px',
                  marginLeft: '130px',
              }
            : {
                  marginTop: '455px',
                  marginLeft: '130px',
              }

    return (
        <>
            {isSkylineImage ? (
                <div className="w-full relative">
                    {skyLineImageUrl && <BackgroundImg src={skyLineImageUrl} height={100} />}
                    <LogoDiv top={35}>
                        {logoUrl && (
                            <MediaKitLogo>
                                <img src={logoUrl} alt="logo-images" className="w-full h-full" />
                            </MediaKitLogo>
                        )}
                        {publishYear && (
                            <YearBox>
                                <SkylineYear>{publishYear}</SkylineYear>
                            </YearBox>
                        )}
                    </LogoDiv>
                </div>
            ) : (
                <div className="w-full relative bg-white">
                    <div className="flex relatives">
                        {!isCeAndIndutry && <AwardYear {...yearProps}>{publishYear}</AwardYear>}
                        {map(awardImageUrl, (imgSrc, index) => (
                            <img
                                key={index}
                                src={imgSrc}
                                alt="award-images"
                                className={cx(
                                    isNationalStandard && publisherId !== DALLAS_PUBLISHER_ID
                                        ? 'w-1/3 pt-4 pb-16'
                                        : publisherId === REMOTE_WORK_PUBLISHER_ID ||
                                          publisherId === MINNEAPOLIS_PUBLISHER_ID
                                        ? 'w-1/3 pt-4 pb-12'
                                        : 'w-full pt-16'
                                )}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
