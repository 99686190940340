import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import safeInvoke from 'util/safeInvoke'

const style = {
    container: 'w-full md:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto',
    header: {
        container: 'flex flex-col items-center text-center mb-10',
        logo: 'mb-6 w-32 lg:w-40 xl:w-64',
        title: 'h1 font-grey700 display4 mb-5',
        subtitle: 'h4 text-xl',
        description: 'paragraph mt-5',
    },
}

interface Style {
    container?: string
    header?: {
        container?: string
        logo?: string
        title?: string
        subtitle?: string
        description?: string
    }
}

const WizardContainer = ({
    title,
    subtitle,
    description,
    logo,
    logoUrl,
    overrideStyle,
    children,
}: {
    title?: string
    subtitle?: string
    description?: string
    logo?: string
    logoUrl?: string
    overrideStyle: Style
    children: React.ReactNode
}) => {
    const customizeStyle = merge(cloneDeep(style), overrideStyle)
    return (
        <div className={customizeStyle.container}>
            <div className={customizeStyle.header.container}>
                {(logo || logoUrl) && (
                    <img
                        alt="Logo"
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        src={logoUrl || require(`images/${logo}`).default}
                        className={customizeStyle.header.logo}
                    />
                )}
                {title && <p className={customizeStyle.header.title}>{safeInvoke(title)}</p>}
                {subtitle && <h4 className={customizeStyle.header.subtitle}>{subtitle}</h4>}
                {description && <h4 className={customizeStyle.header.description}>{description}</h4>}
            </div>
            {children}
        </div>
    )
}

export default WizardContainer
