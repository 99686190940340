import { useState } from 'react'
import { InputGroup, LegacyButton as Button, Select } from '@energage/components'
import { colors } from '@energage/theme'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import some from 'lodash/some'
import values from 'lodash/values'
import styled from 'styled-components'
import Container from 'components/Container'
import { viewports } from 'style/breakpoints'
import withClassName from 'style/util/withClassName'

const Table = styled.table.attrs({ className: 'w-full text-left mx-auto border' })`
    max-width: ${viewports.mdMin}px;
`

const FieldSelect = styled(Select).attrs({ className: 'text-left' })`
    max-width: 17em;
`

const Th = styled.th.attrs({ className: 'bg-white font-bold text-sm border-collapse p-3' })`
    width: 33%;
`
const Td = withClassName('p-2', 'td')
const Tr = styled.tr.attrs({ className: 'text-xs -mt-px relative border-b border-grey300' })`
    background-color: ${colors.white};

    :nth-child(even) {
        background-color: ${colors.grey100};
    }
`

const removeOptionValue = '(DO NOT UPLOAD THIS COLUMN)'

const TableRow = ({ columnMap, setColumnMapping, selectedOptions, selectOptions }) => {
    const [selectedHeader, setHeader] = useState(columnMap.mapping)

    const hasError = columnMap.mapping
        ? some(selectedOptions, (map) => map.mapping === columnMap.mapping && map.key !== columnMap.key)
        : false

    const handleSelect = (selectedOption) => {
        if (removeOptionValue === selectedOption?.header) {
            setHeader(null)
            setColumnMapping(columnMap, null)
        } else {
            setHeader(selectedOption)
            setColumnMapping(columnMap, selectedOption)
        }
    }

    return (
        <Tr>
            <Td>{columnMap.headerText}</Td>
            <Td>{columnMap.firstRowValue}</Td>
            <Td>
                <InputGroup
                    info={hasError ? 'Field cannot be assigned more than once' : null}
                    error={hasError}
                    name={'FieldMapping_' + columnMap.headerText}
                    value={selectedHeader}
                    options={selectOptions}
                    onChange={handleSelect}
                    getOptionLabel={(opt) => opt.header}
                    getOptionValue={(opt) => opt}
                    as={FieldSelect}
                />
            </Td>
        </Tr>
    )
}

const CancelButton = ({ onClose }) => (
    <>
        <div className="mt-6 sm:m-0 float-right sm:float-none sm:relative">
            <Button className="sm:absolute right-0 m-4" variant="secondary" onClick={onClose} outline>
                {'Close'}
            </Button>
        </div>
        <div className="clearfix" />
    </>
)

const HeaderMapperTable = ({ columns, importColumnMapping, setColumnMapping, onClose, onSave }) => {
    const [selectedOptions, setSelectedOptions] = useState(importColumnMapping)
    const hasErrors = some(
        groupBy(map(filter(selectedOptions, 'mapping'), 'mapping'), 'header'),
        (opt) => opt.length > 1
    )
    const selectOptions = concat(
        [
            {
                header: removeOptionValue,
            },
        ],
        values(columns)
    )

    const handleSelect = (columnMap, selectedOption) => {
        const newSelections = map(selectedOptions, (opt) => {
            if (opt.key === columnMap.key) {
                opt.mapping = selectedOption
            }

            return opt
        })

        setSelectedOptions(newSelections)
        setColumnMapping(columnMap, selectedOption)
    }

    return (
        <Container>
            {onClose && <CancelButton onClose={onClose} />}
            <div className="text-center">
                <h2 className="pt-6">{'Did we get this right?'}</h2>
                <p className="w-full text-sm py-4 mb-4">
                    {`Looking at your spreadsheet, we took our best guess at which each field meant. How'd we do?`}
                </p>
            </div>
            <Table>
                <thead className="bg-white border border-b-2 border-grey300 font-bold text-sm">
                    <tr>
                        <Th>{'Your Header'}</Th>
                        <Th>{'First Row'}</Th>
                        <Th>{'Energage Field'}</Th>
                    </tr>
                </thead>
                <tbody className="border-b-2 border-grey300 bg-white">
                    {map(importColumnMapping, (columnMap) => (
                        <TableRow
                            key={columnMap.key}
                            setColumnMapping={handleSelect}
                            columnMap={columnMap}
                            selectOptions={selectOptions}
                            selectedOptions={selectedOptions}
                        />
                    ))}
                </tbody>
            </Table>
            {onSave && (
                <Button variant="secondary" className="mt-4 float-right" onClick={onSave} disabled={hasErrors}>
                    {'Confirm'}
                </Button>
            )}
        </Container>
    )
}

export default HeaderMapperTable
