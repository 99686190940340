import { useEffect, useMemo, useState } from 'react'
import { useIdentity } from 'components/Identity'
import { getUrl, publishBodyParams } from 'containers/Main/EmployerBranding/util'
import {
    CultureCloudConfiguration,
    CultureStatsConfiguration,
    EmployeeCommentSection,
    LinkedInBannerConfiguration,
    LoveMyJobConfiguration,
    OrgStrengthsConfiguration,
} from './ConfigurationLayout'
import { useFiltersData } from './FiltersDataProvider'

export const endPoints = {
    CultureCloud: 'CultureCloud',
    CultureStats: 'culturestats',
    OrgStrengths: 'orgstrengths',
    LoveMyJob: 'lovemyjob',
    EmployeeComment: 'EmployeeCommentTile',
    LinkedInBanner: 'LinkedInBanner',
}

const useConfig = () => {
    const { surveyCompanyId, employerBrandingSurveyEventId, organizationName } = useIdentity()
    const {
        state: { currentSelectedFilter: filters, assetFilterData, filterData, isPulseSurvey },
    } = useFiltersData()
    const selectedFilters = publishBodyParams(filters)
    return {
        surveyCompanyId,
        employerBrandingSurveyEventId,
        selectedFilters,
        filters,
        assetFilterData,
        filterData,
        isPulseSurvey,
        organizationName,
    }
}
const CultureFactsConfiguration = ({ hasEmployerBrandingSubscription }) => {
    const [isPublished, updatePublishStatus] = useState(false)
    const [widgetService, resetWidgetService] = useState(null)
    const {
        surveyCompanyId,
        employerBrandingSurveyEventId,
        selectedFilters,
        filters,
        filterData,
        isPulseSurvey,
        organizationName,
    } = useConfig()

    const restProps = {
        surveyCompanyId,
        selectedFilters,
        hasEmployerBrandingSubscription,
        filters,
        updatePublishStatus,
        filterData,
        organizationName,
    }

    const surveyEventId = filters.surveyEventName || employerBrandingSurveyEventId

    const [cultureCloudUrl, cultureStatsUrl, orgStrengthUrl, loveMyJobUrl, employeeComment, linkedInBannerUrl] =
        useMemo(
            () => [
                getUrl(surveyCompanyId, endPoints.CultureCloud, filters, surveyEventId),
                getUrl(surveyCompanyId, endPoints.CultureStats, filters, surveyEventId),
                getUrl(surveyCompanyId, endPoints.OrgStrengths, filters, surveyEventId),
                getUrl(surveyCompanyId, endPoints.LoveMyJob, filters, surveyEventId),
                getUrl(surveyCompanyId, endPoints.EmployeeComment, filters, surveyEventId),
                getUrl(surveyCompanyId, endPoints.LinkedInBanner, filters, surveyEventId),
            ],
            [surveyCompanyId, filters, surveyEventId]
        )

    useEffect(() => {
        if (
            cultureCloudUrl &&
            cultureStatsUrl &&
            orgStrengthUrl &&
            loveMyJobUrl &&
            employeeComment &&
            linkedInBannerUrl
        ) {
            resetWidgetService({
                getCultureCloudUrl: cultureCloudUrl,
                getCultureStatsUrl: cultureStatsUrl,
                getOrgStrendthUrl: orgStrengthUrl,
                getLoveMyJobUrl: loveMyJobUrl,
                getEmployeeCommentUrl: employeeComment,
                getLinkedInBannerUrl: linkedInBannerUrl,
            })
        }
    }, [
        cultureCloudUrl,
        cultureStatsUrl,
        orgStrengthUrl,
        loveMyJobUrl,
        employeeComment,
        linkedInBannerUrl,
        isPublished,
    ])
    return (
        <>
            <div className="xl:flex md:flex">
                <div className="flex flex-col items-center w-full">
                    {!isPulseSurvey ? (
                        <>
                            {widgetService && (
                                <CultureCloudConfiguration
                                    widgetServiceURL={widgetService?.getCultureCloudUrl}
                                    endPoint={endPoints.CultureCloud}
                                    {...restProps}
                                />
                            )}
                            {widgetService && (
                                <LinkedInBannerConfiguration
                                    cultureCloudServiceURL={widgetService?.getCultureCloudUrl}
                                    widgetServiceURL={widgetService?.getLinkedInBannerUrl}
                                    endPoint={endPoints.CultureStats}
                                    {...restProps}
                                />
                            )}
                            {widgetService && (
                                <CultureStatsConfiguration
                                    widgetServiceURL={widgetService?.getCultureStatsUrl}
                                    endPoint={endPoints.CultureStats}
                                    {...restProps}
                                />
                            )}
                            {widgetService && (
                                <OrgStrengthsConfiguration
                                    widgetServiceURL={widgetService?.getOrgStrendthUrl}
                                    endPoint={endPoints.OrgStrengths}
                                    {...restProps}
                                />
                            )}
                            {widgetService && (
                                <LoveMyJobConfiguration
                                    widgetServiceURL={widgetService?.getLoveMyJobUrl}
                                    endPoint={endPoints.LoveMyJob}
                                    {...restProps}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {widgetService && (
                                <EmployeeCommentSection
                                    widgetServiceURL={widgetService?.getEmployeeCommentUrl}
                                    endPoint={endPoints.EmployeeComment}
                                    {...restProps}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default CultureFactsConfiguration
