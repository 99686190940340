import { colors } from '@energage/theme'
import Carousel from 'nuka-carousel'
import styled from 'styled-components'

export const StyledCarousel = styled(Carousel)`
    outline: none;

    .slider-list {
        pointer-events: none;
    }

    .slider-control-bottomcenter {
        color: ${colors.grey600};
        bottom: -38px !important;
        background-color: ${colors.grey200};
        padding: 3px 25px;
        border-radius: 15px;
        font-weight: 700;
    }

    .slider-control-bottomright {
        bottom: -38px !important;
        right: 138px !important;
        color: ${colors.grey500};
    }

    .slider-control-bottomleft {
        bottom: -38px !important;
        left: 138px !important;
        color: ${colors.grey500};
    }
`
