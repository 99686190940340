import { InputGroup, Select } from '@energage/components'
import find from 'lodash/find'
import noop from 'lodash/noop'
import { useDebouncedCallback } from 'use-debounce'
import { TextAreaInput } from './CustomQuestionTextInputs'

const textboxCountOptions = [
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
]

export const MultiCommentQuestion = ({
    questionText,
    textboxCount,
    index,
    onChangeQuestionText = noop,
    onChangeTextboxCount = noop,
    errors,
}) => {
    const debouncedOnChange = useDebouncedCallback(onChangeQuestionText, 500)

    return (
        <div>
            <div>
                <TextAreaInput
                    label="Ask a multicomment question"
                    name={`multi-comment-${index}`}
                    placeholder={'Enter Your Question'}
                    defaultValue={questionText}
                    onChange={(e) => {
                        e.persist()
                        debouncedOnChange(e, index)
                    }}
                    errors={errors}
                    required
                    errorKey="required"
                />
            </div>
            <div className="mt-4">
                <span className="text-grey500">{'How many response fields would you like?'}</span>
                <InputGroup
                    as={Select}
                    className="w-24 text-sm"
                    options={textboxCountOptions}
                    value={find(textboxCountOptions, (opts) => opts.value === textboxCount)}
                    onChange={(selectedOption) => onChangeTextboxCount(index, selectedOption.value)}
                    placeholder={'Number of Words'}
                />
            </div>
        </div>
    )
}
