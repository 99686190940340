import { useCallback, useState } from 'react'
import { colors } from '@energage/theme'
import cx from 'clsx'
import chunk from 'lodash/chunk'
import map from 'lodash/map'
import range from 'lodash/range'
import size from 'lodash/size'
import styled from 'styled-components'

const PageValue = styled.button.attrs({
    className: 'text-purple700 cursor-pointer flex justify-center items-center px-4',
})`
    border-right-width: 1px;
    border-style: solid;
    border-color: ${colors.grey300};
    line-height: 35px;
    border-top-width: 1px;
    border-bottom-width: 1px;

    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid black;
    }

    :first-child {
        border-left-width: 1px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    ${(props) => (props.active ? `background-color: ${colors.grey200}` : null)};
`

const Paginate = ({ items, itemsPerPage, renderItem, className, paginationClassName, dataId }) => {
    const [page, setPage] = useState(0)
    const pageStart = page * itemsPerPage
    const itemsToShow = items.slice(pageStart, pageStart + itemsPerPage)
    const pageCount = Math.ceil(items.length / itemsPerPage)
    const maxPagesToShow = 3
    const groupings = chunk(range(pageCount), maxPagesToShow)
    const groupingIndex = Math.floor(page / maxPagesToShow)
    const pageNumbers = groupings[groupingIndex]
    const setPageState = useCallback(
        (newPage) => {
            return () => {
                if (newPage > pageCount) {
                    setPage(pageCount)
                } else if (newPage < 0) {
                    setPage(0)
                } else {
                    setPage(newPage)
                }
            }
        },
        [pageCount, setPage]
    )

    const paginatorClasses = paginationClassName || 'flex justify-center'

    return (
        <div>
            <div className={cx(className, 'relative')}>{map(itemsToShow, renderItem)}</div>
            {size(items) > itemsPerPage && (
                <div className={paginatorClasses}>
                    {groupingIndex !== 0 && (
                        <PageValue
                            aria-label="Previous page group"
                            className="text-2xl px-3"
                            active={false}
                            data-event-id={dataId && `${dataId}-page-turn`}
                            onClick={setPageState(groupingIndex * maxPagesToShow - 1)}>
                            {'\u00ab'}
                        </PageValue>
                    )}
                    {map(pageNumbers, (number) => (
                        <PageValue
                            aria-label={`Go to page ${number + 1}`}
                            key={number}
                            active={number === page}
                            data-event-id={dataId && `${dataId}-page-turn`}
                            onClick={setPageState(number)}>
                            <span className="mt-px">{number + 1}</span>
                        </PageValue>
                    ))}
                    {groupingIndex < groupings.length - 1 && (
                        <PageValue
                            aria-label="Next page group"
                            className="text-2xl px-3"
                            active={false}
                            data-event-id={dataId && `${dataId}-page-turn`}
                            onClick={setPageState((groupingIndex + 1) * maxPagesToShow)}>
                            {'\u00bb'}
                        </PageValue>
                    )}
                </div>
            )}
        </div>
    )
}

export default Paginate
