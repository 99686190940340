import { useState } from 'react'

const ZUORA_PAYMENT_RESOURCE_CDN = 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js'
const ZUORA_PAYMENT_RESOURCE_ID = 'zuora_payment_resource'

const withZuoraResource = (WrappedComponent) => (props) => {
    const [loading, setLoading] = useState(false)

    const existingScript = document.getElementById(ZUORA_PAYMENT_RESOURCE_ID)
    if (!existingScript && !loading) {
        setLoading(true)
        const script = document.createElement('script')
        script.src = ZUORA_PAYMENT_RESOURCE_CDN
        script.id = ZUORA_PAYMENT_RESOURCE_ID
        document.body.appendChild(script)
        script.onload = () => {
            setLoading(false)
        }
        return null
    }

    if (loading) {
        return null
    }

    return <WrappedComponent {...props} />
}

export default withZuoraResource
