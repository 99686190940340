import invert from 'lodash/invert'
import reduce from 'lodash/reduce'

const Permission = {
    Insights: 0,
    EmployerBranding: 1,
    EmployerRecognition: 2,
    SmartPulse: 4,
    PlatformSurveySetup: 5,
    SurveyPlatform: 6,
    OperationalReadinessSurvey: 7,
    EmployerRecognitionWizard: 8,
    EmployerBrandingSubscription: 10,
    PremiumMediaKit: 12,
    CEPremiumMediaKit: 13,
    TwpUsaPremiumMediaKit: 14,
    IndustryPremiumMediaKit: 15,
    Profile: 16,
    Security: 17,
    EnableHrisIntegration: 18,
    UserManagement: 100,
    ActionTrackerTile: 101,
    SendSignInEmail: 102,
    MultiRegionTwpSurveys: 104,
    HideQuestionBank: 1000,
    EmployerBrandingTwpEditProfile: 1009,
    EmployerBrandingTwpEditProfileFeaturedComments: 1010,
    NewOnlineAnnouncementExperience: 1013,
    FeedbackRatingScaleCustomQuestion: 1014,
    LeaderReportDelivery: 1015,
    PlatformNewRenewalsPage: 1016,
    NewCultureFactsExperience: 1018,
    PlatformRenewalsPage: 1019,
    ShowAwardsSelection: 1020,
    UploadRecipientsAtSurveyEventLevel: 1021,
    MultiChoiceQuestionCreation: 1022,
    EmployerBrandingPlusSubscription: 1023,
    EnableMsTeamsIntegration: 1025,
    ConfidentialityLevelSelection: 1028,
} as const

const PermissionName = invert(Permission)
type HasTypePermission = `has${keyof typeof Permission}`
export type PermissionValues = typeof Permission[keyof typeof Permission]

const getPermissionName = (permission: PermissionValues) => `has${PermissionName[permission]}` as HasTypePermission

export const convertPermissions = (permissions: Record<string, PermissionValues>) => {
    return reduce(
        permissions,
        (convertedPermissions, value) => {
            if (!PermissionName[value]) {
                return convertedPermissions
            }

            convertedPermissions[getPermissionName(value)] = true
            return convertedPermissions
        },
        {} as Record<HasTypePermission, boolean>
    )
}

export { Permission, PermissionName, getPermissionName }
