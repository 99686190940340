import range from 'lodash/range'
import { RadioButtonPreview } from '../CustomQuestionTextInputs'

export function FeedbackPreview() {
    return (
        <div className="flex flex-wrap w-full mb-2 pb-0">
            {range(11).map((rating) => (
                <div className="w-10 min-w-10 mt-2">
                    <label key={rating} className="mt-1 sm:w-auto text-grey500">
                        <div className="mb-0 flex flex-col">
                            <div className="mb-3 w-full mx-auto text-xs justify-center h-5 flex">
                                <span className="self-end text-center">{rating}</span>
                            </div>
                            <div className="mx-auto text-center w-4">
                                <RadioButtonPreview type="radio" checked={false} />
                            </div>
                        </div>
                    </label>
                </div>
            ))}
        </div>
    )
}
