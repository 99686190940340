import map from 'lodash/map'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import { percentage } from 'util/formatters'
import loadXlsxAsync from 'util/loadXlsxAsync'

const EXCEL_WRITE_CONFIG = {
    cellStyles: true,
    type: 'array',
    bookType: 'xlsx',
    bookSST: true,
}

function flattenChildren(array, d) {
    return [...array, omit(d, 'children'), ...reduce(d.children, flattenChildren, [])]
}

function makeReportReady(responseStatistic) {
    return {
        'Department': responseStatistic.isSufficient
            ? responseStatistic.name
            : responseStatistic.name + ' (not enough responders)',
        'Invitees': responseStatistic.inviteeCount,
        'Responders': responseStatistic.isSufficient ? responseStatistic.responderCount : '*',
        'Response Rate': responseStatistic.isSufficient ? percentage(responseStatistic.responseRate, 2) : '*',
    }
}

function flattenResponseStatistics(rootResponseStatistic) {
    function mapStats(depth) {
        return (d) => ({
            ...d,
            name: '    '.repeat(depth + 1) + d.name,
            children: map(sortBy(d.children, 'name'), mapStats(depth + 1)),
        })
    }
    const flattened = reduce(map(sortBy(rootResponseStatistic.children, 'name'), mapStats(0)), flattenChildren, [
        rootResponseStatistic,
    ])
    return map(flattened, makeReportReady)
}

const downloadExcelFile = async ({ rootDepartment, surveyEventName }) => {
    const xlsxModule = await loadXlsxAsync()

    const reportContent = flattenResponseStatistics(rootDepartment)
    const worksheet = xlsxModule.utils.json_to_sheet(reportContent)
    const workbook = xlsxModule.utils.book_new()
    xlsxModule.utils.book_append_sheet(workbook, worksheet, 'Response Rates')
    xlsxModule.writeFile(workbook, `${surveyEventName} Response Rates By Department.xlsx`, EXCEL_WRITE_CONFIG)
}

export default downloadExcelFile
