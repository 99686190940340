import PropTypes from 'prop-types'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import errorRenderer from './errorRenderer'

const ErrorGroup = ({ errors }) => {
    const parsedErrors = []

    const parse = (error) => {
        let err = error

        try {
            if (typeof error === 'string') {
                err = JSON.parse(error)
            }

            if (err?.errorCode) {
                parsedErrors.push(err)
            } else if (err?.code) {
                parsedErrors.push({ ...err, errorCode: err.code, errorMessage: err?.message })
            }
        } catch {}
    }

    if (isArray(errors)) {
        forEach(errors, parse)
    } else {
        parse(errors)
    }

    const group = groupBy(parsedErrors, (e) => e.errorCode)
    return map(group, (value, key) => errorRenderer(value, key))
}

const UploadValidationError = ({ errors }) => (
    <div className="max-w-2xl p-3 mx-auto">
        {map(errors, (groupErrors, code) => (
            <ErrorGroup key={code} errors={groupErrors} />
        ))}
    </div>
)

UploadValidationError.propTypes = {
    errors: PropTypes.any,
}

export default UploadValidationError
