import PropTypes from 'prop-types'
import { MainContent as MainContentRoot } from '@energage/components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from 'constants/routes'
import { media } from 'style/breakpoints'
import Footer from './Footer'
import StatusBar from './StatusBar'

const Root = styled(({ backgroundColor, hideHorizontalOverflow, ...props }) => <MainContentRoot {...props} />).attrs({
    className: 'min-h-screen relative bg-grey200',
})`
    padding-bottom: 12rem;
    overflow-x: ${(props) => (props.hideHorizontalOverflow ? 'hidden' : 'unset')};

    ${media.smMin`
        padding-bottom: 8rem;
    `}
`

const MainContent = ({ children }) => {
    let location = useLocation()

    const hideHorizontalOverflow = location.pathname === routes() || location.pathname === routes.dashboard()

    return (
        <Root role="main" hideHorizontalOverflow={hideHorizontalOverflow}>
            {children}
            <div className="clearfix" />
            <StatusBar />
            <Footer />
        </Root>
    )
}

MainContent.propTypes = {
    children: PropTypes.node.isRequired,
}

export default MainContent
