import { Loading as Spinner } from '@energage/components'
import ProgressiveImage from 'react-progressive-image'
import { POWERED_BY_ENERGAGE_URL } from 'constants/strings'

const CultureBadge = ({ badge, displayUrl, className }) => (
    <ProgressiveImage src={displayUrl} placeholder="">
        {(src, loading) =>
            loading ? (
                <div className="-mt-16 ml-6 h-48">
                    <Spinner text={false} />
                </div>
            ) : (
                <a href={POWERED_BY_ENERGAGE_URL} target="_blank" rel="noopener noreferrer" className="w-full">
                    <img src={src} className={className} alt={badge.type?.name || badge.type || 'Culture Badges'} />
                </a>
            )
        }
    </ProgressiveImage>
)

export default CultureBadge
