import { parse, stringify } from 'query-string'
import { Redirect, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { routes } from 'constants/routes'
import OnlineAnnouncementNotificationPagePdf from './AnnouncementPage/OnlineAnnouncementNotificationPagePdf'
import OnlineAnnouncementPagePdf from './AnnouncementPage/OnlineAnnouncementPagePdf'
import { AwardAnnouncementPdf } from './AwardAnnouncement/AwardAnnouncementPdf'
import AwardCheckList from './MediaKit/AwardCheckList'
import InternalAnnouncementLetter from './MediaKit/InternalAnnouncementLetter'
import MediaKitLite from './MediaKit/MediaKitLite'
import { PressReleaseForm } from './MediaKit/PressReleaseForm'
import { SkylineAndAwardImage } from './MediaKit/SkylineAndAwardImage'
import WorkplacePublicity from './MediaKit/WorkplacePublicity'
import RenewalSummary from './RenewalSummary'
import Widgets from './Widgets'

const GlobalStyle = createGlobalStyle`
    .osano-cm-window {
        display: none !important;
    }
`

const Public = () => {
    return (
        <>
            <GlobalStyle />
            <Switch>
                <Route path={routes.publicPages.widgets()} component={Widgets} />
                <Route path={routes.publicPages.renewalsSummary()} component={RenewalSummary} />
                <Route
                    path={routes.publicPages.surveyPreview()}
                    render={({ location }) => {
                        const { e: surveyEventId, ...search } = parse(location.search)
                        return (
                            <Redirect
                                to={{
                                    pathname: routes.survey.surveyPreview({ surveyEventId }),
                                    search: stringify(search),
                                }}
                            />
                        )
                    }}
                />
                <Route path={routes.publicPages.awardChecklist()} component={AwardCheckList} />
                <Route path={routes.publicPages.announcementLetter()} component={InternalAnnouncementLetter} />
                <Route path={routes.publicPages.pressReleaseform()} component={PressReleaseForm} />
                <Route path={routes.publicPages.workplacePublicity()} component={WorkplacePublicity} />
                <Route path={routes.publicPages.skylineAndAwardImage()} component={SkylineAndAwardImage} />
                <Route path={routes.publicPages.mediaKitLite()} component={MediaKitLite} />
                <Route path={routes.publicPages.onlineAnnouncement()} component={OnlineAnnouncementPagePdf} />
                <Route
                    path={routes.publicPages.onlineAnnouncementNotification()}
                    component={OnlineAnnouncementNotificationPagePdf}
                />
                <Route path={routes.publicPages.awardAnnouncement()} component={AwardAnnouncementPdf} />
            </Switch>
        </>
    )
}

export default Public
