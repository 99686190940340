import PropTypes from 'prop-types'
import { Accordion as AccordionBase, LegacyButton as Button } from '@energage/components'
import { Add } from '@energage/icons'
import cx from 'clsx'
import filter from 'lodash/filter'
import find from 'lodash/find'
import keys from 'lodash/keys'
import map from 'lodash/map'
import { AccordionContainer } from 'components/Accordion'
import { QuestionType } from 'containers/Main/Survey/StatementSetup/CustomQuestions/QuestionType'
import { RadioButtonPreview } from '../../CustomQuestions/CustomQuestionTypes/CustomQuestionTextInputs'
import { useDemographicOptions } from '../DemographicOptionsContext'
import { StatementTitle } from '../QuestionTypeAnswerFormat'
import { agreementOptions, QuestionTypeAnswerFormats } from '../QuestionTypeAnswerFormats'
import { AnswerFormatName } from '../StyledComponents'
import DemographicEditBox from './DemographicEditBox'

export const DemographicOptionsPreview = ({
    options,
    isEditing,
    className,
    handleOptionTextChange,
    handleAddOption,
    handleDeleteOption,
}) => {
    const optionKeys = keys(options)
    const optionKeysLength = optionKeys.length
    const disableAdd = optionKeysLength >= 10

    return (
        <>
            <div className={cx('flex', className)}>
                <div className="flex-col hidden md:flex max-w-sm">
                    {map(
                        filter(options, (opt) => isEditing || (opt.id && opt.name)),
                        (option, i) => (
                            <div key={option._key}>
                                <label className="w-auto text-grey500">
                                    <div className="flex flex-row">
                                        {!isEditing && option.id && (
                                            <div className={cx('text-center w-4 block mr-2', isEditing && 'my-auto')}>
                                                <RadioButtonPreview type="radio" checked={false} disabled />
                                            </div>
                                        )}
                                        <form className={cx('text-xs', isEditing ? 'mb-2' : 'mb-3')}>
                                            {isEditing && (option.isEditable ?? true) ? (
                                                <DemographicEditBox
                                                    className={cx('w-full', {
                                                        'border border-red500': option._errors.required,
                                                    })}
                                                    name={`option-text-${option.id}`}
                                                    value={option.name}
                                                    onChange={(e) => handleOptionTextChange(e, i)}
                                                    maxLength={110}
                                                    tabIndex={0}
                                                    hasDelete={true}
                                                    onDelete={() => handleDeleteOption(i)}
                                                    placeholder={`Option ${String.fromCharCode(i + 65)}`}
                                                />
                                            ) : (
                                                <span className="text-center">{option.name}</span>
                                            )}
                                        </form>
                                    </div>
                                </label>
                            </div>
                        )
                    )}
                </div>
                <div className="flex flex-col w-full md:hidden">
                    {map(options, (option, i) => (
                        <div className="w-full" key={option._key}>
                            {isEditing && (option.isEditable ?? true) ? (
                                <div className="my-1 mr-2">
                                    <DemographicEditBox
                                        className={cx('pl-2 border-0 my-1', {
                                            'border border-red500': option._errors.required,
                                        })}
                                        name={`option-text-${option.id}`}
                                        value={option.name}
                                        onChange={(e) => handleOptionTextChange(e, i)}
                                        maxLength={110}
                                        hasDelete={true}
                                        onDelete={() => handleDeleteOption(i)}
                                        placeholder={`Option ${String.fromCharCode(i + 65)}`}
                                    />
                                </div>
                            ) : (
                                <Button className="my-1 w-full" variant="primary" outline disabled={true}>
                                    <span className="text-center">{option.name}</span>
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
                {isEditing && !disableAdd && (
                    <div className="mt-auto h-10 text-blue600 w-10 flex ml-1" style={{ display: 'none' }}>
                        <Button
                            className="m-0 p-0 self-end mb-3 outline-none"
                            eventName="Add another option"
                            outline
                            variant="link"
                            onClick={() => handleAddOption()}
                            disabled={disableAdd}>
                            <Add className="block" />
                        </Button>
                    </div>
                )}
            </div>
            {isEditing && options.length < 2 && (
                <div className="paragraph-small text-red500 w-full">{'Must have at least two options'}</div>
            )}
            {isEditing && (
                <button
                    className="flex justify-between items-center sm:pr-5 focus:outline-none font-bold text-blue600"
                    onClick={handleAddOption}>
                    <div className="text-left">{'Add Another Choice'}</div>
                </button>
            )}
        </>
    )
}

const Accordion = ({ children, title, text, defaultOpen, ...props }) => (
    <AccordionBase
        {...props}
        as={AccordionContainer}
        containerProps={({ open }) => ({ open })}
        renderTrigger={({ open, toggle }) => (
            <>
                <StatementTitle title={title} onToggle={toggle} open={open} />
                {text && <div className="pb-4 md:pb-0 pt-2 max-w-md paragraph">{text}</div>}
            </>
        )}
        defaultOpen={defaultOpen}>
        <div className="pb-4 md:pb-0 w-full">{children}</div>
    </AccordionBase>
)

const OptionsAnswerFormat = ({ questionTypeId, options, isEditing, savedQuestion }) => {
    const formatName =
        questionTypeId === QuestionType.CustomScale && options
            ? `${options.length}pt Scale`
            : QuestionTypeAnswerFormats[questionTypeId].name || '-'
    const { answerOptions, handleAddOption, handleOptionTextChange, handleDeleteOption } = useDemographicOptions()

    return (
        <Accordion className="w-full" title={<AnswerFormatName>{formatName}</AnswerFormatName>} defaultOpen={isEditing}>
            <DemographicOptionsPreview
                options={map(answerOptions, (answerOpt) => {
                    if (!answerOpt.id && savedQuestion) {
                        return {
                            ...answerOpt,
                            id: find(savedQuestion.answerOptions, (opt) => opt.name === answerOpt.name)?.id,
                        }
                    }
                    return answerOpt
                })}
                isEditing={isEditing}
                className="pt-3 w-full"
                handleAddOption={handleAddOption}
                handleOptionTextChange={handleOptionTextChange}
                handleDeleteOption={handleDeleteOption}
            />
        </Accordion>
    )
}

const OtherAnswerFormat = ({ questionTypeId }) => {
    const formatName = QuestionTypeAnswerFormats[questionTypeId].name || '-'
    const description = QuestionTypeAnswerFormats[questionTypeId]?.description
    return description ? (
        <Accordion title={<AnswerFormatName>{QuestionTypeAnswerFormats[questionTypeId].name}</AnswerFormatName>}>
            <div className="flex-wrap md:pb-4 md:flex max-w-sm text-grey600">{description}</div>
        </Accordion>
    ) : (
        <span>{formatName}</span>
    )
}

const DemographicAnswerFormat = ({
    questionId,
    questionTypeId,
    options,
    isEditing,
    handleSetAnswerOptions,
    savedQuestion,
}) => {
    const answerOptions = questionTypeId === QuestionType.Agreement ? agreementOptions : options
    if (
        answerOptions &&
        (questionTypeId === QuestionType.Agreement ||
            questionTypeId === QuestionType.Demographic ||
            questionTypeId === QuestionType.CustomScale)
    ) {
        return (
            <OptionsAnswerFormat
                questionId={questionId}
                questionTypeId={questionTypeId}
                options={answerOptions}
                isEditing={isEditing}
                handleSetAnswerOptions={handleSetAnswerOptions}
                savedQuestion={savedQuestion}
            />
        )
    }

    return <OtherAnswerFormat questionTypeId={questionTypeId} />
}

DemographicAnswerFormat.propTypes = {
    questionId: PropTypes.number.isRequired,
    questionTypeId: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
            isEditable: PropTypes.bool,
        })
    ),
    isEditing: PropTypes.bool,
}

export default DemographicAnswerFormat
