import { useBrandingQuery, useWorkplaceSurveyQuery } from 'api'

export const useFetchOnlineAnnouncementPdfData = (url, text) => {
    const { data, isFetching } = useBrandingQuery([`${text}`], url)

    return {
        data: data,
        isLoading: isFetching,
    }
}

export const useFetchOnlineAnnouncementNotificationPdfData = (url, text) => {
    const { data, isFetching } = useWorkplaceSurveyQuery([`${text}`], url)

    return {
        data: data,
        isLoading: isFetching,
    }
}
