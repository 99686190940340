import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import cx from 'clsx'
import keys from 'lodash/keys'
import map from 'lodash/map'
import range from 'lodash/range'
import { useIdentity } from 'components/Identity'
import { QuestionType } from 'containers/Main/Survey/StatementSetup/CustomQuestions/QuestionType'
import { RadioButtonPreview } from '../CustomQuestions/CustomQuestionTypes/CustomQuestionTextInputs'
import { MultiOptionPreview } from '../CustomQuestions/CustomQuestionTypes/QuestionPreviews/Fragments'
import { ConditionalTitleText, ConditionalTooltipText, InfoTooltip } from '../fragments'
import { CollapsibleButton } from './CollapsibleButton'
import { DemographicOptionsPreview } from './DemographicQuestionTable/DemographicAnswerFormat'
import { agreementOptions, QuestionTypeAnswerFormats } from './QuestionTypeAnswerFormats'
import { AnswerFormatName, ScaleValueLabel } from './StyledComponents'

const ScaleOptionsPreview = ({ options, isDefaultScale }) => {
    const optionKeys = keys(options)
    const optionKeysLength = optionKeys.length
    return (
        <div className="w-full pb-2">
            {isDefaultScale && <div className="font-bold">{'Answer Format: 7-Point Likert Scale'}</div>}
            <div className="md:w-full md:max-w-md md:flex md:flex-wrap hidden md:py-2">
                {map(range(optionKeysLength), (count) => (
                    <div className="flex mt-auto mr-auto" key={optionKeys[count]}>
                        <label key={count} className="text-grey500">
                            <div className="flex flex-col mx-auto">
                                <div className="mb-2 text-xs flex justify-center mx-auto">
                                    <ScaleValueLabel className="self-end text-center break-words">
                                        {options[count].name}
                                    </ScaleValueLabel>
                                </div>
                                <div className="mx-auto text-center w-4">
                                    <RadioButtonPreview type="radio" checked={false} disabled />
                                </div>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            <div className="flex flex-col w-full md:hidden">
                {map(range(optionKeysLength), (count) => (
                    <Button className="w-full my-1" variant="primary" outline disabled={true} key={count}>
                        {options[count].name}
                    </Button>
                ))}
            </div>{' '}
        </div>
    )
}

const ConditionalTitle = ({ children, className, tooltip }) => (
    <dt css={{ fontSize: 14 }} className={cx(className, 'font-bold')}>
        {children}
        <InfoTooltip text={tooltip} size={14} />
    </dt>
)

const ConditionalDescription = ({ children }) => <dd className="mt-1 text-grey500">{children}</dd>

const ConditionalWrapper = ({ children }) => <div className="w-full md:pb-2">{children}</div>

const GenericConditionals = () => {
    const { organizationName } = useIdentity()
    const tooltipText =
        "Because there are conditional questions on this survey, responders may see this question if they don't trigger any other conditional comment questions."
    const title = 'Conditional question for other responses'

    return (
        <>
            <ConditionalTitle tooltip={tooltipText} className="mt-4">
                {title}
            </ConditionalTitle>
            <ConditionalDescription>{'What do you like most about your job?'}</ConditionalDescription>
            <ConditionalDescription>{`How could ${organizationName} better help you do your job?`}</ConditionalDescription>
        </>
    )
}

const ScaleOptionsWithConditionalsPreview = ({
    options,
    positiveConditionalText,
    negativeConditionalText,
    isPositivelyPhrased,
    isDefaultScale,
}) => {
    const { positiveTooltipText, negativeTooltipText } = ConditionalTooltipText(isPositivelyPhrased)
    const { positiveTitleText, negativeTitleText } = ConditionalTitleText(isPositivelyPhrased)
    return (
        <>
            <ScaleOptionsPreview options={options} isDefaultScale={isDefaultScale} />
            <ConditionalWrapper>
                {positiveConditionalText ? (
                    <>
                        <ConditionalTitle tooltip={positiveTooltipText}>{positiveTitleText}</ConditionalTitle>
                        <ConditionalDescription>{positiveConditionalText}</ConditionalDescription>
                    </>
                ) : null}
                {negativeConditionalText ? (
                    <>
                        <ConditionalTitle className="mt-4" tooltip={negativeTooltipText}>
                            {negativeTitleText}
                        </ConditionalTitle>
                        <ConditionalDescription>{negativeConditionalText}</ConditionalDescription>
                    </>
                ) : null}

                {!positiveConditionalText || !negativeConditionalText ? <GenericConditionals /> : null}
            </ConditionalWrapper>
        </>
    )
}

const ScaleOptionsWrapper = ({ isDemographic, children }) => {
    return <div className={cx('w-full py-2 md:py-4', isDemographic && 'md:pb-0 pr-10 md:pr-0')}>{children}</div>
}

export function StatementTitle({ open, title, onToggle, ...buttonProps }) {
    return (
        <AnswerFormatName>
            <CollapsibleButton text={title} open={open} onToggle={onToggle} {...buttonProps} />
        </AnswerFormatName>
    )
}

const OptionsAnswerFormat = ({
    questionTypeId,
    options,
    positiveConditionalText,
    negativeConditionalText,
    isPositivelyPhrased,
}) => {
    const hasConditionals = !!(positiveConditionalText || negativeConditionalText)

    return (
        <ScaleOptionsWrapper isDemographic={questionTypeId === QuestionType.Demographic}>
            {questionTypeId === QuestionType.Agreement && hasConditionals && (
                <ScaleOptionsWithConditionalsPreview
                    options={options}
                    positiveConditionalText={positiveConditionalText}
                    negativeConditionalText={negativeConditionalText}
                    isPositivelyPhrased={isPositivelyPhrased}
                    isDefaultScale={true}
                />
            )}
            {questionTypeId !== QuestionType.Demographic && !hasConditionals && (
                <ScaleOptionsPreview options={options} isDefaultScale={questionTypeId === QuestionType.Agreement} />
            )}
            {questionTypeId === QuestionType.Demographic && (
                <>
                    <div className="">
                        <DemographicOptionsPreview options={options} />
                    </div>
                </>
            )}
        </ScaleOptionsWrapper>
    )
}

const OtherAnswerFormat = ({ questionTypeId }) => {
    const description = QuestionTypeAnswerFormats[questionTypeId]?.description
    return description ? (
        <div className="flex">
            <div className="pb-4 w-full md:w-1/2 text-grey600">{description}</div>
        </div>
    ) : null
}

const QuestionTypeAnswerFormat = ({
    questionTypeId,
    options,
    positiveConditionalText,
    negativeConditionalText,
    isPositivelyPhrased,
}) => {
    const answerOptions = questionTypeId === QuestionType.Agreement ? agreementOptions : options

    if (
        answerOptions &&
        (questionTypeId === QuestionType.Agreement ||
            questionTypeId === QuestionType.CustomScale ||
            questionTypeId === QuestionType.Demographic)
    ) {
        return (
            <OptionsAnswerFormat
                questionTypeId={questionTypeId}
                isPositivelyPhrased={isPositivelyPhrased}
                options={answerOptions}
                positiveConditionalText={positiveConditionalText}
                negativeConditionalText={negativeConditionalText}
            />
        )
    }

    if (
        questionTypeId === QuestionType.MultiChoice ||
        questionTypeId === QuestionType.YesNo ||
        questionTypeId === QuestionType.TrueFalse
    ) {
        return <MultiOptionPreview small name="multi-choice" options={answerOptions} />
    }

    return <OtherAnswerFormat questionTypeId={questionTypeId} />
}

QuestionTypeAnswerFormat.propTypes = {
    questionTypeId: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
            isEditable: PropTypes.bool,
        })
    ),
    positiveConditionalText: PropTypes.string,
    negativeConditionalText: PropTypes.string,
}

export default QuestionTypeAnswerFormat
