import { SpeechBubble } from '@energage/components'
import appreciationPulseImage from './appreciation-pulse.png'

export const UpsellPulse = () => {
    return (
        <div
            id="upsell-pulse-tile"
            data-testid="upsell-pulse-tile"
            className="flex flex-col items-center py-7 px-3 gap-5 bg-white rounded-lg shadow-md cursor-pointer"
            role="button"
            tabIndex={0}
            aria-label="Learn more about pulse surveys">
            <SpeechBubble variant="thought" tailPlacement="bottom-end">
                {'Pulse surveys help you build a better company'}
            </SpeechBubble>
            <img src={appreciationPulseImage} alt="appreciation pulse survey sample" width="314" height="146" />
            <span className="btn btn--secondary-outline btn--sm">{'Learn more about Pulse surveys'}</span>
        </div>
    )
}
