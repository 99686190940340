import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'

const MissingRecipientsDetails = ({ handleAddEmployees }) => (
    <div className="pb-4 border-b-2 pl-6 ">
        <h5>{`Add recipients to your online survey`}</h5>
        <Button onClick={handleAddEmployees} className="my-4" outline variant="secondary">
            {'Add Recipients'}
        </Button>
    </div>
)

MissingRecipientsDetails.propTypes = {
    handleAddEmployees: PropTypes.func.isRequired,
}

export default MissingRecipientsDetails
