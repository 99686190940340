import map from 'lodash/map'

export default {
    state: {
        notifications: {},
    },
    reducers: {
        addNotification(state, notification) {
            state.notifications[notification.id] = notification
        },
        updateNotifications(state, notifications) {
            map(notifications, (notification) => {
                state.notifications[notification.id] = notification
            })
        },
        dismissNotification(state, notificationId) {
            state.notifications[notificationId].readDate = new Date()
        },
    },
    selectors: (slice) => ({
        selectNotifications: () => slice((state) => state.notifications),
    }),
}
