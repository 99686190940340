import { Children, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import styled from 'styled-components'
import Dots from './Dots'
import { ScrollbarLessContainer } from './ScrollbarlessContainer'

const StyledInview = styled(InView)`
    scroll-snap-align: center;
`

const MobileTemplateSelection = ({ children }) => {
    const ref = useRef()
    const count = Children.count(children)
    const [inviewDot, setInviewDot] = useState(0)
    const onViewable = (inView, index) => {
        if (inView) {
            setInviewDot(index)
        }
    }
    return (
        <div className="sm:hidden">
            <ScrollbarLessContainer className="px-2" ref={ref} scrollSnap>
                {/* eslint-disable-next-line @energage/energage/prefer-lodash-method */}
                {Children.map(children, (child, index) => (
                    <StyledInview
                        rootMargin="0px 10px 0px 0px"
                        root={ref.current}
                        threshold={1}
                        onChange={(inView) => onViewable(inView, index)}>
                        {child}
                    </StyledInview>
                ))}
                <div css="min-width: 1px" />
            </ScrollbarLessContainer>
            {count > 1 && (
                <div className="text-center pb-2">
                    <Dots count={count} active={inviewDot} />
                </div>
            )}
        </div>
    )
}

export default MobileTemplateSelection
