import { useState } from 'react'
import type { ComponentProps } from 'react'
import { Input, LegacyButton as Button } from '@energage/components'
import { Delete } from '@energage/icons'
import noop from 'lodash/noop'
import remove from 'lodash/remove'
import some from 'lodash/some'
import at from 'lodash/fp/at'
import compact from 'lodash/fp/compact'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/fp/values'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { VisuallyHidden } from 'components'
import { generateRandomKey } from 'util/generateRandomKey'
import { isEmptyString } from 'util/strings'

const getFirstError = pipe(get('_errors'), values, compact, at(0))

type MultiChoiceOptionsEditorProps = {
    options: { id: string | number; name: string; _errors?: Record<string, unknown>; isReadOnly?: boolean }[]
    onOptionsUpdate: (options: MultiChoiceOptionsEditorProps['options']) => void
}

const DeleteButton = styled.button.attrs({ className: 'absolute' })`
    top: 32px;
    right: -30px;
`

const MAX_OPTIONS = 10

function MultiChoiceOptionInput({ onChange = noop, ...delegatedProps }: ComponentProps<typeof Input>) {
    const onTextChange = useDebouncedCallback(onChange, 500)
    return (
        <Input
            {...delegatedProps}
            className="w-full"
            type="text"
            placeholder="Write your option here"
            onChange={onTextChange}
        />
    )
}

const UNIQUE_ERROR = 'Options should be unique'
const REQUIRED_ERROR = 'Required'

export function MultiChoiceOptionsEditor({ options, onOptionsUpdate }: MultiChoiceOptionsEditorProps) {
    const [autoFocusIndex, setAutoFocusIndex] = useState<number | null>(null)

    const addNewOption = () => {
        if (MAX_OPTIONS === options.length) {
            return
        }
        onOptionsUpdate([...options, { id: generateRandomKey(), name: '' }])
        setAutoFocusIndex(options.length)
    }

    const removeOption = (idx: number) => {
        const seenText: Record<string, boolean> = {}

        const verifiedRemainingOptions = options
            .filter((_, index) => index !== idx)
            .map((option) => {
                let { name } = option
                name = name?.toLowerCase().trim()

                const verifiedOption = {
                    ...option,
                    _errors: {
                        ...option._errors,
                        unique: !isEmptyString(name) && seenText[name] ? UNIQUE_ERROR : null,
                    },
                }

                seenText[name] = true

                return verifiedOption
            })

        onOptionsUpdate(verifiedRemainingOptions)
    }

    const onOptionTextChange = (text: string, optionIndex: number) => {
        text = text?.trim()
        const remainingOptions = options.slice()
        const [updatedOption] = remove(remainingOptions, (_, index) => {
            return index === optionIndex
        })

        const currentOptionTexts = remainingOptions.map((option) => option.name.toLowerCase())

        updatedOption.name = text
        updatedOption._errors = {
            required: isEmptyString(text) && optionIndex < 2 ? REQUIRED_ERROR : null,
            unique: !isEmptyString(text) && currentOptionTexts.includes(text.toLowerCase()) ? UNIQUE_ERROR : null,
        }

        const lowerCaseNewOptionText = updatedOption.name.toLowerCase()

        const updatedOptions = remainingOptions.map((option) => {
            const optionsWithoutCurrentOption = remainingOptions
                .filter((option2) => option2.id !== option.id)
                .map((option2) => option2.name.toLowerCase())

            return {
                ...option,
                _errors: {
                    ...option._errors,
                    unique:
                        !isEmptyString(option.name) &&
                        (option.name.toLowerCase() === lowerCaseNewOptionText ||
                            optionsWithoutCurrentOption.includes(option.name.toLowerCase()))
                            ? UNIQUE_ERROR
                            : null,
                },
            }
        }) as MultiChoiceOptionsEditorProps['options']

        updatedOptions.splice(optionIndex, 0, updatedOption)

        onOptionsUpdate(updatedOptions)
    }

    return (
        <div className="mt-3 max-w-md">
            <ul className="flex flex-col gap-3 list-none pl-0">
                {options.map((option, idx) => (
                    <li key={option.id} className="flex gap-3 relative">
                        <MultiChoiceOptionInput
                            name={`option-${idx}`}
                            label={`Option ${idx + 1}`}
                            required={idx < 2}
                            aria-required={idx < 2}
                            maxLength={100}
                            defaultValue={option.name}
                            error={some(option._errors, (value) => value)}
                            info={getFirstError(option)}
                            autoFocus={autoFocusIndex === idx}
                            disabled={option.isReadOnly}
                            onChange={(e) => {
                                onOptionTextChange(e.target.value, idx)
                            }}
                        />{' '}
                        {idx >= 2 ? (
                            <DeleteButton onClick={() => removeOption(idx)}>
                                <VisuallyHidden>
                                    {option.name ? `Remove ${option.name} option` : ` Remove empty option ${idx + 1}`}
                                </VisuallyHidden>
                                <Delete />
                            </DeleteButton>
                        ) : null}
                    </li>
                ))}
            </ul>
            {options.length < MAX_OPTIONS ? (
                <Button className="pl-0" size="sm" variant="link" onClick={addNewOption} type="button">
                    {'Add another option'}
                </Button>
            ) : null}
        </div>
    )
}
