import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { FileDownload, Icon } from '@energage/icons'
import cx from 'clsx'
import noop from 'lodash/noop'
import { Link } from 'react-router-dom'
import { usePermissions } from 'components/Identity'
import ResponseRateChart from 'components/ResponseRateChart'
import SimpleTooltip from 'components/SimpleTooltip'
import { routes } from 'constants/routes'
import useDownloadResponseRateByDepartment from 'containers/Main/Survey/MySurveys/useDownloadResponseRateByDepartment'
import { AccessibleTileStatus, SurveyDates } from '../fragments'

const ActiveOrClosed = ({
    id,
    isClosed,
    responseRate,
    surveyName,
    surveysSentCount,
    surveysRespondedCount,
    commentsCount,
    redirectUrl,
    launchDateTime,
    scheduledCloseDateTime,
    closedDateTime,
}) => {
    const { download, isDownloading } = useDownloadResponseRateByDepartment(id)
    const { hasInsights } = usePermissions()

    return (
        <div className="h-full text-center">
            {surveyName}
            <AccessibleTileStatus backgroundColor="bg-purple700" statusText={isClosed ? 'Closed' : 'Active'} />
            <SurveyDates launchDateTime={launchDateTime} closeDateTime={scheduledCloseDateTime ?? closedDateTime} />
            <ResponseRateChart responseRate={responseRate} />
            <div className="table border-collapse">
                <div className="table-row border-grey200 border-b-2 ">
                    <div className="table-cell text-left px-5 py-2 font-bold">
                        <div className="flex">
                            <div>{`Responses `}</div>
                            {responseRate > 0 && (
                                <div className="pl-1">
                                    <SimpleTooltip
                                        trigger={
                                            <Icon
                                                as={FileDownload}
                                                onClick={isDownloading ? noop : download}
                                                className={cx(
                                                    'align-middle text-grey400 hover:text-grey600',
                                                    isDownloading ? 'cursor-wait' : 'cursor-pointer'
                                                )}
                                            />
                                        }>
                                        <span>{'Download response rates by department'}</span>
                                    </SimpleTooltip>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="table-cell text-right w-full px-5 py-2">{`${surveysRespondedCount}/${surveysSentCount}`}</div>
                </div>
                <div className="table-row border-grey200 border-b-2">
                    <div className="table-cell text-left px-5 py-2 font-bold">{`Comments`}</div>
                    <div className="table-cell text-right px-5 py-2">{commentsCount}</div>
                </div>
            </div>
            <div className="py-4">
                {isClosed ? (
                    <>
                        <Button
                            size="sm"
                            className="mr-3"
                            variant="secondary"
                            outline
                            as={Link}
                            to={routes.survey.workplace()}>{`Create New Survey`}</Button>
                        {redirectUrl && hasInsights ? (
                            <Button size="sm" variant="secondary" href={redirectUrl}>{`View Insights`}</Button>
                        ) : (
                            redirectUrl ?? (
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    as={Link}
                                    to={routes.survey.setup.summary({ surveyEventId: id })}>{`View Survey`}</Button>
                            )
                        )}
                    </>
                ) : (
                    <Button
                        size="sm"
                        variant="secondary"
                        as={Link}
                        to={routes.survey.setup.summary({ surveyEventId: id })}>{`View Survey`}</Button>
                )}
            </div>
        </div>
    )
}

ActiveOrClosed.propTypes = {
    id: PropTypes.number.isRequired,
    isClosed: PropTypes.bool.isRequired,
    responseRate: PropTypes.number.isRequired,
    surveyName: PropTypes.node.isRequired,
    surveysSentCount: PropTypes.number.isRequired,
    surveysRespondedCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
    redirectUrl: PropTypes.string,
    launchDateTime: PropTypes.object.isRequired,
    scheduledCloseDateTime: PropTypes.object,
    closedDateTime: PropTypes.object,
}

export default ActiveOrClosed
