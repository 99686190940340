import map from 'lodash/map'
import { routes } from 'constants/routes'
import Section from '../Section'
import YellowButton from '../YellowButton'

export const listItems = [
    {
        imgPath: 'pro5.svg',
        heading: 'Premium Media Kits',
        text: 'Access kits for current and any future premium Top Workplaces awards.',
    },
    {
        imgPath: 'pro4.svg',
        heading: 'Culture Badges',
        text: 'Show off where your company culture excels.',
    },
    {
        imgPath: 'pro6.svg',
        heading: 'Premium Company Profile on topworkplaces.com',
        text: 'Create a custom profile that’s featured on the site seen by millions each year.',
    },
    {
        imgPath: 'pro7.svg',
        heading: 'Culture Facts',
        text: 'Leverage employee feedback to show what makes your culture unique.',
    },
]

const FeatureYourCulture = ({ bgColor, textColor, companyName, sectionClassName }) => (
    <Section bgColor={bgColor} textColor={textColor} sectionClassName={sectionClassName}>
        <h1 className="sm:text-5xl text-2xl font-black">{'And feature what makes your culture special and unique.'}</h1>
        <p className="sm:text-2xl text-lg pt-4 pb-0 sm:py-4 ">{`Employer Branding tools will help ${companyName} to stand out among the competition:`}</p>
        <div className="flex flex-wrap mt-16 bg-white px-3 pt-2 pb-6 sm:py-6 shadow-xl justify-center">
            {map(listItems, ({ imgPath, heading, text }, index) => (
                <div key={index} className="flex flex-col sm:flex-row sm:w-1/2 px-3 py-6">
                    <img
                        alt="CE awards logos"
                        src={require(`images/employerBranding/mediaKit/${imgPath}`).default}
                        className="pb-6 sm:pb-0 sm:pr-6 self-center sm:self-start"
                    />
                    <div>
                        <h2 className="text-2xl text-center sm:text-left">{heading}</h2>
                        <p className="text-xl pt-2 text-center sm:text-left">{text}</p>
                    </div>
                </div>
            ))}
        </div>
        <div className="mt-12 text-center">
            <YellowButton href={routes.branding()} variant="link" target="_blank">
                {'Go to Employer Branding'}
            </YellowButton>
        </div>
    </Section>
)

export default FeatureYourCulture
