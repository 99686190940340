import remove from 'lodash/remove'

const WORKPLACE_TRENDING_GENERATION_ID = 35

type Template = {
    surveyGenerationId: number
}

const extractWorkplaceTrendingTemplate = (templates: Template[]) =>
    remove(templates, (template) => template.surveyGenerationId === WORKPLACE_TRENDING_GENERATION_ID)

export default extractWorkplaceTrendingTemplate
