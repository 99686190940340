import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Toggle } from '@energage/components'
import cx from 'clsx'
import { QuestionType } from '../../../../../StatementSetup/CustomQuestions/QuestionType'
import DemographicAnswerFormat from '../../../../../StatementSetup/QuestionTable/DemographicQuestionTable/DemographicAnswerFormat'
import { DemographicTooltip } from '../../../../../StatementSetup/QuestionTable/DemographicQuestionTable/DemographicTooltip'
import QuestionTableContext from '../../../../../StatementSetup/QuestionTable/QuestionTableContext'
import { TableCell, TableRow } from '../../../../../StatementSetup/QuestionTable/TableElements'

const Description = ({ description }) => {
    return <div className="text-grey600 italic pt-2">{description}</div>
}

const QuestionCell = ({ questionId, questionText, disableSelection, demographicTypeName }) => (
    <>
        <div className="flex w-full">
            <div className="w-1/4 mr-2 pt-2 md:pb-1">{demographicTypeName}</div>

            <div className="w-4/5">
                <label htmlFor={questionId} className={cx('pt-2 md:pb-1 md:pr-1', disableSelection && 'text-grey500')}>
                    {questionText}
                </label>
            </div>
        </div>
    </>
)

const DemographicQuestionTableRow = ({
    question,
    selected,
    parentSelected,
    disabled,
    hasActiveDependentQuestion,
    isRequired,
    handleRequiredToggle,
}) => {
    const {
        questionEssenceId,
        text,
        demographicTypeName,
        conditionalQuestionEssenceIds,
        answerOptions,
        targetGroupDescription,
    } = question

    const questionId = questionEssenceId ?? 0
    const toggleQuestion = useContext(QuestionTableContext)

    const handleCheckboxChange = useCallback(
        (e) => {
            toggleQuestion(questionId, e.target.checked)
        },
        [questionId, toggleQuestion]
    )

    const handleToggle = useCallback(
        (e) => {
            handleRequiredToggle(questionId, e.target.checked)
        },
        [questionId, handleRequiredToggle]
    )

    const disableSelection = conditionalQuestionEssenceIds.length > 0 && !parentSelected

    let description =
        targetGroupDescription &&
        `This question will only be asked to employees that identify as a ${targetGroupDescription}.`

    return (
        <TableRow>
            <div className="flex flex-wrap md:flex-no-wrap relative">
                <TableCell spaced={disabled} alignTop className="w-full md:w-1/2">
                    <div className="pl-4 w-full flex flex-no-wrap">
                        <div className="pt-1 md:pb-1">
                            <DemographicTooltip
                                trigger={
                                    <Checkbox
                                        className={disabled || disableSelection ? 'disabled' : ''}
                                        id={questionId}
                                        checked={selected && !disableSelection}
                                        disabled={disabled || disableSelection || hasActiveDependentQuestion}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                disabled={!hasActiveDependentQuestion}
                            />
                        </div>
                        <div className="pl-4 mr-1 md:mr-0 md:pr-4 w-full pr-10">
                            <QuestionCell
                                questionId={questionId}
                                questionText={text}
                                disableSelection={disableSelection}
                                demographicTypeName={demographicTypeName}
                            />
                            {description && <Description description={description} />}
                        </div>
                    </div>
                </TableCell>
                <TableCell
                    alignTop
                    className={cx(
                        'w-full md:w-1/2 ml-7 md:ml-0 md:pb-1 pt-2 flex',
                        disableSelection && 'text-grey500',
                        {
                            'pl-4 md:pl-0': disabled,
                        }
                    )}>
                    <div className="w-full pl-5 md:pl-0">
                        <DemographicAnswerFormat
                            questionId={questionId}
                            questionTypeId={QuestionType.Demographic}
                            options={answerOptions}
                            isEditing={false}
                        />
                    </div>
                </TableCell>
                <TableCell alignTop className={cx('w-full md:w-1/12 ml-7 md:ml-0 md:pb-1 pt-2 flex pr-8 justify-end')}>
                    <Toggle id={`${questionId}-required-toggle`} checked={isRequired} onChange={handleToggle} />
                </TableCell>
            </div>
        </TableRow>
    )
}

DemographicQuestionTableRow.propTypes = {
    question: PropTypes.shape({
        questionEssenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        demographicTypeId: PropTypes.number,
        demographicTypeName: PropTypes.string,
        conditionalQuestionEssenceIds: PropTypes.arrayOf(PropTypes.number),
        answerOptions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                name: PropTypes.string,
                isEditable: PropTypes.bool,
            })
        ),
        targetGroupDescription: PropTypes.arrayOf(PropTypes.string),
    }),
    selected: PropTypes.bool.isRequired,
    parentSelected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleSaveQuestion: PropTypes.func,
    isRequired: PropTypes.bool,
}

export default DemographicQuestionTableRow
