import { TextInputPreview } from '../CustomQuestionTextInputs'
import { PreviewTitleDisplay } from './Fragments'

export type CommentPreviewProps = {
    text?: string
}

export function CommentPreview({ text }: CommentPreviewProps) {
    return (
        <div className="w-full">
            <PreviewTitleDisplay>{text}</PreviewTitleDisplay>
            <TextInputPreview disabled placeholder="Enter your response here" />
        </div>
    )
}
