import { colors } from '@energage/theme'
import cx from 'clsx'
import replace from 'lodash/replace'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { useBrandingQuery } from 'api'
import Inset from 'components/Inset'
import {
    CE_AND_INDUSTRY_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { parseQueryParams } from 'containers/Public/Widgets/QueryParser'

const StyledTextArea = styled(ReactMarkdown).attrs({ className: 'flex-1 md:pr-3' })`
    font-size: 16px;
    a {
        color: ${colors.blue600};
        text-decoration: underline;
    }
`

const InternalAnnouncementLetter = ({ location }) => {
    const parsedQuery = parseQueryParams(location)
    const { surveyCompanyId, publisherId, awardCategoryId, year, isNationalStandard, mediaKitLiteExperience } =
        parsedQuery || {}

    const { data, isLoading, isError } = useBrandingQuery(
        ['announcement-letter', surveyCompanyId, awardCategoryId],
        `SurveyCompanies/${surveyCompanyId}/MediaKit/${awardCategoryId}/${publisherId}/${year}/${isNationalStandard}/InternalAnnouncementLetter`
    )

    if (isLoading || isError) {
        return null
    }

    const { letterImageUrl, letterText } = data || {}

    const showFullLogo =
        TWP_USA_PUBLISHER_ID === publisherId ||
        publisherId === CE_AND_INDUSTRY_PUBLISHER_ID ||
        publisherId === REMOTE_WORK_PUBLISHER_ID ||
        publisherId === MINNEAPOLIS_PUBLISHER_ID

    return (
        <Inset className="w-full h-full pt-2 pb-2 bg-white">
            <div className="p-12">
                {!mediaKitLiteExperience && (
                    <div
                        className={cx('border-2 border-white focus:outline-none flex items-center justify-center', {
                            'p-12': !showFullLogo,
                        })}>
                        <img
                            src={letterImageUrl}
                            alt="Announcement Letter"
                            className={cx({ 'w-1/3': showFullLogo, 'w-auto': !showFullLogo })}
                        />
                    </div>
                )}
                <div className="clearfix relative pt-8">
                    <StyledTextArea name="LetterText" id="LetterText" linkTarget="_blank">
                        {replace(letterText, /\n/g, '\\\n')}
                    </StyledTextArea>
                </div>
            </div>
        </Inset>
    )
}

export default InternalAnnouncementLetter
