import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import type { ExtractRouteParams } from 'react-router'
import { generatePath } from 'react-router-dom'

function extend(...segments: string[]) {
    return segments.join('/').replace('//', '/')
}

type AddBase = (base: string) => void

type BaseBuildRoute = {
    <P extends string>(params?: ExtractRouteParams<P>): string
    addBase: AddBase
}

type BuildRoute<ChildrenRoutes> = BaseBuildRoute &
    (ChildrenRoutes extends Record<string, BaseBuildRoute>
        ? {
              [K in keyof ChildrenRoutes]: ChildrenRoutes[K]
          }
        : Record<string, never>)

function route<Keys extends string, ChildrenRoutes extends Record<Keys, BaseBuildRoute>>(
    path: string,
    childRoutes?: ChildrenRoutes
): BuildRoute<ChildrenRoutes> {
    let fullPath = path

    function buildRoute<P extends string>(params?: ExtractRouteParams<P>) {
        return typeof params === 'object' ? generatePath(fullPath, params) : fullPath
    }

    buildRoute.addBase = function addBase(base: string) {
        fullPath = extend(base, fullPath)
        forEach(childRoutes, (childRoute) => childRoute.addBase(base))
    }

    return reduce(
        childRoutes,
        (root, childRoute, name) => {
            childRoute.addBase(fullPath)

            root[name as Keys] = childRoute as BuildRoute<ChildrenRoutes>[Keys]
            return root
        },
        buildRoute as BuildRoute<ChildrenRoutes>
    )
}

const routes = route('/', {
    admin: route('admin'),
    branding: route('branding', {
        employeeCommunication: route('communication'),
        cultureFacts: route('culturefacts'),
        contentCreator: route('contentcreator'),
        twpWidgets: route('twpwidgets'),
        cultureBadges: route('culturebadges'),
        reports: route('reports'),
        resources: route('resources', {
            selectedResource: route(':selectedResourceId'),
        }),
        mediaKit: route('media-kit', {
            preview: route('preview'),
            awardAnnouncement: route('award-announcement'),
        }),
    }),
    dashboard: route('dashboard'),
    insights: route('insights', {
        survey: route(':surveyEventId', {
            participationReview: route('participation-review'),
            results: route('results'),
            reports: route('reports'),
            strengths: route('strengths'),
            focusAreas: route('focus-areas'),
            comments: route('comments'),
            sharing: route('sharing'),
            actionTracker: route('action-tracker'),
        }),
    }),
    manage: route('manage', {
        employees: route('employees', {
            departments: route('departments'),
            supervisor: route('supervisor'),
            upload: route('upload'),
        }),
        cultureProfile: route('profile'),
        phrasing: route('phrasing'),
        deliveryContacts: route('delivery-contacts'),
        leaderReportDelivery: route('leader-reports'),
        users: route('users', {
            add: route('add'),
            edit: route('edit/:userId'),
        }),
    }),
    organization: route('organization', {
        bulkUpload: route('upload'),
    }),
    publicPages: route('public', {
        widgets: route('widgets'),
        renewalsSummary: route('order-summary'),
        surveyPreview: route('survey-preview'),
        awardChecklist: route('awards-check-list'),
        announcementLetter: route('announcement-letter'),
        pressReleaseform: route('press-release-form'),
        workplacePublicity: route('workplace-publicity'),
        skylineAndAwardImage: route('skyline-award-image'),
        mediaKitLite: route('media-kit-lite'),
        onlineAnnouncement: route('online-announcement'),
        onlineAnnouncementNotification: route('online-announcement-notification'),
        awardAnnouncement: route('award-announcement'),
    }),
    settings: route('settings', {
        security: route('security'),
    }),
    survey: route('survey', {
        pulse: route('pulse'),
        workplace: route('workplace'),
        setup: route('setup/:surveyEventId', {
            nextStep: route('nextstep'),
            create: route('create'),
            preview: route('preview'),
            recipients: route('recipients', {
                add: route('add'),
                modify: route('modify', {
                    departments: route('departments'),
                    template: route('template'),
                }),
            }),
            schedule: route('schedule'),
            summary: route('summary'),
            awards: route('awards'),
        }),
        choose: route('choose'),
        preview: route('preview/:templateId'),
        templates: route('templates', {
            manage: route('manage'),
            edit: route('edit/:id', {
                create: route('create'),
            }),
        }),
        statementDownload: route(':surveyEventId/statements-download'),
        surveyPreview: route(':surveyEventId/survey-preview'),
    }),
    terms: route('terms'),
    profile: route('profile', {
        premium: route('premium'),
    }),
    employerRecognition: route('employer-recognition', {
        awardTimeline: route('award/timeline/:awardListParticipantId'),
        award: route('award', {
            add: route('add'),
            registration: route('registration/:awardListParticipantId?'),
        }),
        manageLocations: route('manage-locations', {
            add: route('add'),
            uploadLocations: route('upload-locations'),
        }),
        companyDetails: route('company-details'),
        participationHistory: route('participation-history'),
        participation: route('participation', {
            confirmation: route('confirmation/:surveyEventId', {
                companyDetails: route('company-details', {
                    uploadLocations: route('upload-locations'),
                    awardSelection: route('award-selection'),
                }),
                thankYou: route('thank-you'),
            }),
        }),
        publicProfile: route('public-profile'),
        setup: route('setup', {
            twpInfo: route('twp-info'),
            overview: route('overview'),
            terms: route('terms'),
            registration: route('registration', {
                companyDetails: route('company-details', {
                    uploadLocations: route('upload-locations'),
                    awardSelection: route('award-selection'),
                }),
                thankYou: route('thank-you'),
                phrasing: route('phrasing'),
                newCongratulations: route('newCongratulations'),
                congratulations: route('congratulations'),
                scheduleCall: route('schedule-call'),
            }),
        }),
        deiForm: route('/:page/dei-form/:awardListParticipantId/:categoryId'),
    }),
    awardAnnouncement: route('award-announcement/:awardListParticipantId'),
    awardNotification: route('award-notification'),
    blocked: route('blocked'),
    accessDenied: route('accessdenied'),
    legacyOrder: route('legacy-order', {
        myBillings: route('my-billings'),
        manage: route('manage/:subscriptionId'),
        amendSubscription: route('amend-subscription/:subscriptionId'),
        confirmation: route('confirmation/:subscriptionId'),
        amendmentConfirmation: route('amendment-confirmation/:subscriptionId'),
    }),
    fakeProfile: route('profilepath'),
    loginCallback: route('callback'),
    loginDenied: route('logindenied'),
    logoutCallback: route('logoutcallback'),
    logout: route('logout'),
    integrations: route('integrations'),
    documents: route('documents'),
})

type Path = <P extends string>(params?: ExtractRouteParams<P>) => string
type Params = Parameters<Path>[0]

const makePath = (params: Params) => (path: Path) => (withParams: boolean) => withParams ? path(params) : path()

export { routes, makePath }
