import { useEffect, useState } from 'react'
import { alert } from '@energage/components'
import ky from 'ky'
import map from 'lodash/map'
import pick from 'lodash/pick'
import type { ParsingOptions, SheetJSONOpts, WritingOptions } from 'xlsx'
import { useIdentity } from 'components/Identity'
import loadXlsxAsync from 'util/loadXlsxAsync'
import type { CompanyUserDto, ReportData } from './models'
import { getCompanyUserRoles, getPublisherRoles } from './UserManagement.util'

const FILE_PATH = `${process.env.PUBLIC_URL}/assets/User Download Template.xlsx`

const HEADER = ['firstName', 'lastName', 'email', 'roles', 'status']

const SHEET_CONFIG: SheetJSONOpts = {
    header: [],
    skipHeader: true,
    origin: 'A2',
}

const EXCEL_READ_CONFIG: ParsingOptions = {
    type: 'array',
    raw: false,
    cellStyles: true,
    cellNF: true,
    sheetStubs: true,
    bookVBA: true,
    sheetRows: 2,
}

const EXCEL_WRITE_CONFIG: WritingOptions = {
    cellStyles: true,
    type: 'array',
    bookType: 'xlsx',
    bookSST: true,
}

const useDownloadUsers = () => {
    const [blob, setBlob] = useState<Blob>(new Blob())
    const {
        organizationName,
        company: { isPublisher },
    } = useIdentity()

    const writeExcelFile = async (Users: CompanyUserDto[] | undefined) => {
        if (!Users) {
            return
        }
        const reportData = getReportData(Users)
        const filterUsers = getFilterUsers(reportData)

        // eslint-disable-next-line @typescript-eslint/consistent-type-imports
        const xlsxModule: void | typeof import('xlsx') = await loadXlsxAsync()
        if (typeof xlsxModule === 'undefined') {
            alert.danger('We encountered an error while downloading users. Please try again')
            return
        }
        const reader = new FileReader()

        reader.readAsArrayBuffer(blob)
        reader.onloadend = function () {
            const base64data = reader.result
            const response = xlsxModule.read(base64data, EXCEL_READ_CONFIG)
            const worksheet = response.Sheets['User List']
            SHEET_CONFIG.header = HEADER

            xlsxModule.utils.sheet_add_json(worksheet, filterUsers, SHEET_CONFIG)
            const new_workbook = xlsxModule.utils.book_new()
            new_workbook.Props = response.Props
            xlsxModule.utils.book_append_sheet(new_workbook, worksheet, 'User List')
            xlsxModule.writeFile(new_workbook, `Energage - ${organizationName} User List.xlsx`, EXCEL_WRITE_CONFIG)
        }
    }

    const getPlainRoles = (roles: string[]) => {
        return roles.join(', ')
    }

    const getReportData = (Users: CompanyUserDto[]) =>
        map(
            Users,
            ({ firstName, lastName, email, roles, isDisabled }): ReportData => ({
                firstName,
                lastName,
                email,
                roles: isPublisher
                    ? getPlainRoles(getPublisherRoles(roles))
                    : getPlainRoles(getCompanyUserRoles(roles)),
                status: isDisabled ? 'Inactive' : 'Active',
            })
        )

    const getFilterUsers = (Users: ReportData[]) => map(Users, (l) => pick(l, HEADER))

    useEffect(() => {
        async function getUserListTemplate() {
            const data = await ky.get(FILE_PATH).blob()
            setBlob(data)
        }

        getUserListTemplate()
    }, [])

    return { writeExcelFile }
}

export default useDownloadUsers
