export default {
    state: null,
    reducers: {
        setIdentity(state, identity) {
            return identity
        },
        updateCompanyName(state, name) {
            state.organizationName = name
            return state
        },
        clearIdentity() {
            return null
        },
    },
    selectors: (slice) => ({
        selectIdentity() {
            return slice
        },
    }),
}
