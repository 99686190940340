import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { alert, Skeleton } from '@energage/components'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import indexOf from 'lodash/indexOf'
import keys from 'lodash/keys'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import parseInt from 'lodash/parseInt'
import partition from 'lodash/partition'
import some from 'lodash/some'
import { Accordion } from 'components/Accordion'
import SurveyType from 'constants/SurveyType'
import { useSurveySetupContext } from '../SurveySetup/SurveySetup'
import { QuestionType } from './CustomQuestions/QuestionType'
import { IncludedItemsHeader, InfoTooltip, NonExpandableSection, OptionalItemsHeader } from './fragments'
import QuestionCategory from './QuestionCategory'
import { reorder, resetSequenceForSubsection } from './questionsReorderFunctions'
import OptionalItemTable, { OptionalItemTableRow } from './QuestionTable/OptionalItemTable'
import { SortableQuestionTable } from './QuestionTable/SortableQuestionTable'
import { TableRow } from './QuestionTable/TableElements'
import { useQuestionToggles } from './statementSetupHooks'
import { sectionFamily, sectionQuestionCountFamily, subSectionFamily } from './statementSetupUtility/store'
import ThemeCategory from './ThemeCategory'
import useEditQuestion from './useEditQuestion'

const INLINE_MESSAGE = {
    [ThemeCategory.Research]:
        'The statements below are statements that Energage is planning to add to the core Workplace Survey within the next year. They are included in the survey to gather benchmark data, and so that, if you are an Energage Insights subscriber, you will have trend data when these statements are officially added to the Workplace Survey.',
}

const DO_IT_YOURSELFT_GENERATION_ID = 60

export function QuestionCategorySection({
    sectionId,
    sectionName,
    subsections,
    categoryDescription,
    onUpdateQuestions,
    defaultOpen,
    disabled,
    surveyEventId,
    isCustomSurvey = false,
    testId,
}) {
    const { surveyTypeId, templateName, surveyGenerationId } = useSurveySetupContext()

    const getRemoveMessage = () => {
        return surveyTypeId === SurveyType.Custom
            ? `You must have one standard ${templateName} item. If you wish to create your own survey, please use our Do it Yourself Survey template`
            : 'You must include at least one Workplace Experience item. If you wish to have only open ended items please use our DIY survey template.'
    }

    const { totalCount } = useAtomValue(sectionQuestionCountFamily({ sectionId }))

    const updateQuestionsSection = useUpdateAtom(sectionFamily({ id: sectionId }))
    const {
        editingQuestionId,
        originalQuestionText,
        onUpdateQuestion,
        onEditQuestion,
        onCancelEditQuestion,
        isSavingQuestion,
    } = useEditQuestion(surveyEventId, updateQuestionsSection)

    const resetSequence = useCallback(
        (subsectionId, newQuestionOrder) =>
            resetSequenceForSubsection(updateQuestionsSection)(subsectionId, newQuestionOrder),
        [updateQuestionsSection]
    )

    const onDragEnd = useCallback(
        (newQuestionOrder, subsectionId) => {
            resetSequence(subsectionId, newQuestionOrder)
            onUpdateQuestions()
        },
        [resetSequence, onUpdateQuestions]
    )

    const onRemove = useCallback(() => {
        alert.danger(getRemoveMessage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const groupSection = (subsection) => {
        const result = groupBy(subsection.questions, (p) => p.surveyThemeId)
        const keys = Object.keys(result)

        return orderBy(
            map(keys, (surveyThemeId) => {
                return {
                    surveyThemeId: parseInt(surveyThemeId),
                    themeName: result[surveyThemeId][0].themeName,
                }
            }),
            'themeName',
            'asc'
        )
    }

    const toolTipByTheme = (surveyThemeId) => {
        switch (surveyThemeId) {
            case ThemeCategory.FairlyValued:
                return 'These statements assess perceptions of fairness around pay, benefits, and work-life flexibility.'
            case ThemeCategory.RespectedSupported:
                return 'These statements assess perceptions of appreciation, inclusion, manager support, and respect for new ideas and differing points of view.'
            case ThemeCategory.EnabledtoGrow:
                return 'These statements assess growth and development themes related to formal training, working to potential, and manager impact on development.'
            case ThemeCategory.CloselyAligned:
                return 'These statements assess perceptions of company direction, values, and work meaningfulness. '
            case ThemeCategory.EmpoweredtoExecute:
                return 'These statements assess perceptions of communication, cooperation, meetings, and execution efficiency and quality.'
            case ThemeCategory.Engaged:
                return 'These statements assess the psychological construct of employee engagement including motivation, loyalty, and willingness to recommend the company to others.'
            case ThemeCategory.Regional:
                return 'Regional questions are used by media partners to inform research and special awards. They do not appear in results by default.'
            case ThemeCategory.Healthcare:
                return 'These statements are specific to areas that are important to organizations in the healthcare sectors.  Understanding how your organization performs against these statements can help you decide how to focus your efforts to improve employee and patient experience.'
            case ThemeCategory.Partner:
                return 'Regional questions are used by media partners to inform research and special awards. They do not appear in results by default.'
            default:
                return ''
        }
    }

    if (totalCount === 0) {
        return null
    }
    const categoryId = subsections?.length > 0 ? subsections[0].categoryId : null

    return (
        <Accordion
            testId={testId}
            defaultOpen={defaultOpen}
            key={sectionName}
            title={
                <>
                    <div className="h5 px-6">
                        <span>
                            {sectionName}
                            {categoryDescription ? <InfoTooltip text={categoryDescription} size={16} /> : null}
                        </span>
                    </div>
                </>
            }>
            {surveyTypeId === SurveyType.WorkplaceTrending && categoryId === QuestionCategory.WorkplaceExperience && (
                <div className="px-6 mb-4">{'You must include at least 1 Workplace Experience item'}</div>
            )}
            {surveyTypeId === SurveyType.Custom &&
                categoryId === QuestionCategory.Template &&
                surveyGenerationId !== DO_IT_YOURSELFT_GENERATION_ID && (
                    <div className="px-6 mb-4">
                        {`You must have one standard ${templateName} item. If you wish to create your own survey, please use our Do it Yourself Survey template`}
                    </div>
                )}
            {map(subsections, (subsection) => (
                <div
                    data-testid={`survey-setup-section-category-${subsection.categoryId}`}
                    className="px-0 sm:px-6"
                    key={subsection.categoryName}>
                    {subsection.categoryId !== QuestionCategory.Template &&
                        subsection.categoryId !== QuestionCategory.WorkplaceExperience && (
                            <div className="font-bold mb-2">
                                <span>{subsection.categoryName}</span>{' '}
                                {subsection.helpText ? <InfoTooltip text={subsection.helpText} size={16} /> : null}
                            </div>
                        )}
                    {subsection.subsectionId === QuestionCategory.WorkplaceExperience ||
                    subsection.subsectionId === QuestionCategory.Supplemental ? (
                        <>
                            {map(groupSection(subsection), (gr) => (
                                <div key={gr.surveyThemeId}>
                                    <div className="mb-2">
                                        <div className="font-bold">
                                            <span>{gr.themeName}</span>{' '}
                                            {toolTipByTheme(gr.surveyThemeId) !== '' && (
                                                <InfoTooltip text={toolTipByTheme(gr.surveyThemeId)} size={16} />
                                            )}
                                        </div>
                                        {INLINE_MESSAGE[gr.surveyThemeId] ? (
                                            <div className="md:w-1/2 pr-8">{INLINE_MESSAGE[gr.surveyThemeId]}</div>
                                        ) : null}
                                    </div>
                                    <QuestionCategorySubsection
                                        onUpdateQuestions={onUpdateQuestions}
                                        subsectionId={subsection.subsectionId}
                                        sectionId={sectionId}
                                        disabled={disabled}
                                        editingQuestionId={editingQuestionId}
                                        originalQuestionText={originalQuestionText}
                                        onUpdateQuestion={onUpdateQuestion}
                                        onEditQuestion={onEditQuestion}
                                        onCancelEditQuestion={onCancelEditQuestion}
                                        isSavingQuestion={isSavingQuestion}
                                        isCustomSurvey={isCustomSurvey}
                                        onDragEnd={onDragEnd}
                                        onRemove={onRemove}
                                        surveyTypeId={surveyTypeId}
                                        subgroup={gr.themeName}
                                        surveyGenerationId={surveyGenerationId}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <QuestionCategorySubsection
                            onUpdateQuestions={onUpdateQuestions}
                            subsectionId={subsection.subsectionId}
                            sectionId={sectionId}
                            disabled={disabled}
                            editingQuestionId={editingQuestionId}
                            originalQuestionText={originalQuestionText}
                            onUpdateQuestion={onUpdateQuestion}
                            onEditQuestion={onEditQuestion}
                            onCancelEditQuestion={onCancelEditQuestion}
                            isSavingQuestion={isSavingQuestion}
                            isCustomSurvey={isCustomSurvey}
                            onDragEnd={onDragEnd}
                            onRemove={onRemove}
                            surveyTypeId={surveyTypeId}
                            surveyGenerationId={surveyGenerationId}
                        />
                    )}
                </div>
            ))}
            {some(subsections, { questions: [{ isRequired: true }] }) && (
                <div className="mx-6 text-xs">{`* Required items cannot be reordered, removed or edited.`}</div>
            )}
        </Accordion>
    )
}

const QuestionCategorySubsection = ({
    sectionId,
    subsectionId,
    onUpdateQuestions,
    disabled,
    editingQuestionId,
    originalQuestionText,
    onUpdateQuestion,
    onEditQuestion,
    onCancelEditQuestion,
    isSavingQuestion,
    isCustomSurvey,
    onDragEnd,
    onRemove,
    subgroup,
    surveyTypeId,
    surveyGenerationId,
}) => {
    const [subsection, updateSubsection] = useAtom(subSectionFamily({ sectionId, subsectionId }))
    const updateQuestionsSection = useUpdateAtom(sectionFamily({ id: sectionId }))

    const questionsList = subgroup
        ? filter(subsection.questions, (q) => q.themeName === subgroup)
        : subsection.questions
    const [selectedQuestions, nonSelectedQuestions] = partition(questionsList, (q) => q.isSelected)
    //non agreement questions are made non sortable in Platform because Survey application logic always puts them to the end of the page
    const [selectedSortableQuestions, selectedOtherQuestions] = partition(
        selectedQuestions,
        (q) => q.questionTypeId === QuestionType.Agreement
    )

    function onUpdate(updates, isRemoveAction) {
        updateSubsection((ss) => {
            let orderedQuestions = ss.questions
            if (isCustomSurvey) {
                orderedQuestions = [...selectedSortableQuestions, ...selectedOtherQuestions, ...nonSelectedQuestions]
                //though it's always only one key in 'updates' now - needs refactoring
                forEach(keys(updates), (qId) => {
                    if (updates[qId]) {
                        const question = find(orderedQuestions, (q) => q.questionEssenceId === parseInt(qId))
                        const oldIndex = indexOf(orderedQuestions, question)
                        const newIndex =
                            question.questionTypeId === QuestionType.Agreement
                                ? selectedSortableQuestions.length
                                : selectedQuestions.length
                        orderedQuestions = reorder(orderedQuestions, oldIndex, newIndex)
                    }
                })
            }

            if (
                isRemoveAction &&
                (surveyTypeId === SurveyType.WorkplaceTrending || surveyTypeId === SurveyType.Custom) &&
                (ss.categoryId === QuestionCategory.WorkplaceExperience ||
                    ss.categoryId === QuestionCategory.Template) &&
                surveyGenerationId !== DO_IT_YOURSELFT_GENERATION_ID &&
                filter(orderedQuestions, (c) => c.isSelected).length === 1
            ) {
                onRemove()
            }

            return {
                ...ss,
                questions: map(orderedQuestions, (q) => {
                    if (updates.hasOwnProperty(q.questionEssenceId)) {
                        return {
                            ...q,
                            isSelected: updates[q.questionEssenceId],
                            isHighlighted: true,
                        }
                    }
                    return q.isHighlighted ? { ...q, isHighlighted: false } : q
                }),
            }
        })
        //set sequence through subsections
        if (isCustomSurvey) {
            resetSequenceForSubsection(updateQuestionsSection)(subsectionId)
        }

        onUpdateQuestions()
    }

    const handleDragEnd = useCallback(
        (result) => {
            if (!result.destination) {
                return
            }
            const newOrder = reorder(selectedSortableQuestions, result.source.index, result.destination.index)
            onDragEnd([...newOrder, ...selectedOtherQuestions, ...nonSelectedQuestions], subsectionId)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onDragEnd, subsection.questions, subsectionId]
    )

    const { handleToggleQuestion } = useQuestionToggles(subsection.questions, onUpdate)
    const includeQuestion = (questionId) => handleToggleQuestion(questionId, true)
    const hasOptionalItems = nonSelectedQuestions.length > 0
    const templateHasOptionalItems = some(subsection.questions, (q) => !q.isSelectedByDefault)
    const showOptionalItemsTable = hasOptionalItems || templateHasOptionalItems

    return (
        <div className="pb-4">
            {showOptionalItemsTable && <IncludedItemsHeader />}
            <SortableQuestionTable
                categoryId={subsection.categoryId}
                sortableQuestions={selectedSortableQuestions}
                otherQuestions={selectedOtherQuestions}
                droppableId={subsection.subsectionId}
                //title={subsection.categoryName}
                disabled={disabled}
                isSortingDisabled={!isCustomSurvey}
                allowEditNonCustomQuestions={isCustomSurvey}
                onRemoveQuestion={(questionId) => handleToggleQuestion(questionId, false)}
                onDragEnd={handleDragEnd}
                editingQuestionId={editingQuestionId}
                originalQuestionText={originalQuestionText}
                onCancelEditQuestion={onCancelEditQuestion}
                onEditQuestion={onEditQuestion}
                onUpdateQuestion={onUpdateQuestion}
                isSavingQuestion={isSavingQuestion}
                hasOptionalItems={hasOptionalItems}
            />
            {showOptionalItemsTable && (
                <>
                    <OptionalItemsHeader />
                    <OptionalItemTable categoryId={subsection.categoryId} disabled={disabled}>
                        {templateHasOptionalItems && !hasOptionalItems && (
                            <TableRow>{`All optional items are included in your survey.`}</TableRow>
                        )}
                        {map(nonSelectedQuestions, (q) => (
                            <OptionalItemTableRow
                                key={`${q.questionEssenceId}-${q.text}nonselected`}
                                includeQuestion={includeQuestion}
                                questionId={q.questionEssenceId ?? 0}
                                name={q.name}
                                text={q.text}
                                disabled={disabled}
                            />
                        ))}
                    </OptionalItemTable>
                </>
            )}
        </div>
    )
}

QuestionCategorySection.propTypes = {
    sectionName: PropTypes.string.isRequired,
    subsections: PropTypes.array.isRequired,
    isCustomSurvey: PropTypes.bool,

    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onUpdateQuestions: function (props, propName) {
        if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
            return new Error('Please provide onUpdateQuestions function')
        }
    },
    disabled: PropTypes.bool.isRequired,
}

function QuestionCategorySectionSkeleton() {
    return (
        <NonExpandableSection
            title={<Skeleton.HeaderShape className="w-64 pl-6" />}
            text={<Skeleton.ParagraphShape rows={5} className="pl-5" />}
        />
    )
}

QuestionCategorySection.Skeleton = QuestionCategorySectionSkeleton

export default QuestionCategorySection
