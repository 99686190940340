import { useState } from 'react'
import { useWorkplaceSurveyQuery } from 'api'
import downloadExcelFile from './downloadExcelFile'

export default function useDownloadResponseRateByDepartment(surveyEventId) {
    const [enableDownload, setEnableDownload] = useState(false)

    const { isLoading: isDownloading } = useWorkplaceSurveyQuery(
        ['survey-response-rate-report', surveyEventId],
        `SurveyResponseRate/${surveyEventId}/Department`,
        {
            onSuccess: async (data) => {
                setEnableDownload(false)
                await downloadExcelFile(data)
            },
            onError: () => setEnableDownload(false),
            enabled: enableDownload,
        }
    )

    function download() {
        setEnableDownload(true)
    }

    return {
        download,
        isDownloading: isDownloading || enableDownload,
    }
}
