import { useEffect, useRef, useState } from 'react'

const useScrollEnd = (ref: React.RefObject<HTMLElement>) => {
    const [scrollEnd, setScrollEnd] = useState<boolean>(false)
    const scrollEndTimeoutRef = useRef<number | null>(null)

    useEffect(() => {
        const handleScroll = () => {
            setScrollEnd(false)
            if (scrollEndTimeoutRef.current) {
                clearTimeout(scrollEndTimeoutRef.current)
            }
            scrollEndTimeoutRef.current = window.setTimeout(() => {
                setScrollEnd(true)
            }, 150) // 150ms after the last scroll event
        }

        const element = ref.current
        if (element) {
            element.addEventListener('scroll', handleScroll)
            return () => {
                if (scrollEndTimeoutRef.current) {
                    clearTimeout(scrollEndTimeoutRef.current)
                }
                element.removeEventListener('scroll', handleScroll)
            }
        }
    }, [ref])

    return scrollEnd
}

export default useScrollEnd
