import { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TopNav as TopNavBase } from '@energage/components'
import { ArrowBack } from '@energage/icons'
import cx from 'clsx'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useButterBarHeight } from 'hooks'
import { media } from 'style/breakpoints'

const TopNavContainer = styled.div.attrs({
    className: 'z-navigation inset-x-0 main-content fixed',
})`
    top: ${(props) => props.top}px;
    transition: top 250ms;

    body.has-shadow & {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    }

    ${(props) =>
        !props.showPrimaryAction &&
        `.top-nav__title {
        display: none;
    }`}

    ${media.smMin`
        .top-nav__title {
            display: flex;
        }
    `}
`

const Content = styled.div`
    padding-top: ${(props) => props.paddingTop || 0}px;
    ${media.smMin`
        padding-top: ${(props) => props.paddingTop || 0}px;
    `}
`
const previousPageRoute = (history) => history.goBack()

const TopNav = ({
    showBackArrow,
    arrowBackRoute,
    children,
    className = '',
    primaryAction = undefined,
    ...topNavProps
}) => {
    const butterBarHeight = useButterBarHeight()
    const history = useHistory()
    const topNavRef = useRef()
    const [topNavHeight, setTopNavHeight] = useState(0)

    useLayoutEffect(() => {
        updateNavHeight()
        window.addEventListener('resize', updateNavHeight)
        return () => window.removeEventListener('resize', updateNavHeight)

        function updateNavHeight() {
            setTopNavHeight(topNavRef.current.getBoundingClientRect().height)
        }
    }, [primaryAction])

    return (
        <>
            <TopNavContainer
                id="top-navigation-container"
                showPrimaryAction={!!primaryAction}
                className={className}
                ref={topNavRef}
                top={butterBarHeight}>
                <div data-testid="header" className="flex w-full flex-wrap bg-grey200">
                    {showBackArrow && (
                        <ArrowBack
                            className="self-center ml-4 cursor-pointer"
                            size={30}
                            onClick={() =>
                                arrowBackRoute
                                    ? history.push(arrowBackRoute)
                                    : previousPageRoute(history, showBackArrow)
                            }
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    if (arrowBackRoute) {
                                        history.push(arrowBackRoute)
                                    } else {
                                        previousPageRoute(history, showBackArrow)
                                    }
                                }
                            }}
                        />
                    )}
                    <TopNavBase
                        className={cx(showBackArrow ? 'w-11/12' : 'w-full')}
                        {...topNavProps}
                        primaryAction={primaryAction}
                    />
                </div>
            </TopNavContainer>
            {children && <Content paddingTop={topNavHeight}>{children}</Content>}
        </>
    )
}

TopNav.propTypes = {
    children: PropTypes.node,
    xsHidden: PropTypes.bool,
}

export default TopNav
