import { useEffect } from 'react'
import { Loading as Spinner } from '@energage/components'
import { Redirect } from 'react-router-dom'
import { useWorkplaceSurveyQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import Inset from 'components/Inset'
import { TakeoverModal, TakeoverModalData } from 'components/Modals'
import config from 'config'
import WizardContainer from 'containers/Main/EmployerRecognition/Setup/WizardContainer'
import { useCompanyStatusDetails } from 'containers/Main/EmployerRecognition/Shared/useCompanyStatusDetails'

const RESOURCE_LINK = `${config.urls.topWorkplaces}/resources/`

const Blocked = () => {
    const { surveyCompanyId, isSuperUser } = useIdentity()
    const { showBranding } = TakeoverModalData()
    const { isCompanyBlocked, isLoading: isCompanyStatusDetailsLoading } = useCompanyStatusDetails()
    const {
        data: callPreference,
        isLoading,
        isError,
    } = useWorkplaceSurveyQuery(
        ['call-preference', surveyCompanyId],
        `Prospect/${surveyCompanyId}/ProspectCallPreference`
    )

    useEffect(() => {
        showBranding()
    }, [showBranding])

    if (isLoading || isCompanyStatusDetailsLoading || isError) {
        return <Spinner />
    }

    const isAccountExecutiveOutReach = callPreference === 'AccountExecutiveOutReach'

    if (isSuperUser || !isCompanyBlocked) {
        return <Redirect to="/" />
    }

    return (
        <WizardContainer
            title="Almost Done!"
            logo={isAccountExecutiveOutReach ? 'lets-talk-now.svg' : 'scheduled-call-calendly.svg'}>
            <Inset className="text-center justify-center mt-4 w-5/6 m-auto h4">
                <p>
                    {isAccountExecutiveOutReach
                        ? "Your Top Workplaces specialist will be calling you shortly to ensure you're ready to launch your survey and to get the most from your participation. Our business hours are 9am-8pm Eastern Time."
                        : "Your Top Workplaces specialist will be calling you on scheduled time to ensure you're ready to launch your survey and to get the most from your participation."}
                </p>
                <p className="mt-4">
                    {"After your call you'll be able to set up and launch your survey. In the meantime "}
                    <ExternalLink href={RESOURCE_LINK} target="_blank">
                        {'check out some of our Top Workplaces resources.'}
                    </ExternalLink>
                </p>
            </Inset>
        </WizardContainer>
    )
}

export default () => (
    <TakeoverModal isOpen>
        <Blocked />
    </TakeoverModal>
)
