const TaskDefinition = {
    ProvideContactInfo: 101,
    OrganizationOverview: 102,
    AcceptTerms: 103,
    ConfirmEmployeeCount: 200,
    ImproveProfile: 201,
    ListLocations: 202,
    ConfirmProfile: 203,
    UploadLogo: 205,
    UploadPhotos: 206,
    BenefitsInformation: 207,
    CompanyCulture: 208,
    DownloadMediaKit: 209,
    UploadEmailAddresses: 301,
    ConfigureEmailDepartments: 302,
    ChooseSurveyStartDate: 303,
    TestInvitationEmail: 304,
    SendHeadsUpEmail: 305,
    CustomizeSurveyWording: 306,
    PreviewSurvey: 307,
    OrderPaperSurveys: 401,
    ReceivePaperSurveys: 404,
    DistributePaperSurveys: 405,
    PreOrderDetailedResults: 501,
    TrackResponseRate: 502,
    OrderDetailedResults: 503,
    DownloadResults: 504,
    ChooseLeaderReportDelivery: 505,
    SendLeaderReportHeadsUp: 506,
    AccessYourResults: 507,
    InviteAnotherUser: 508,
    ThankEmployees: 509,
    ViewResultsSummary: 510,
    ScheduleExpertKickOffCall: 511,
    ChooseExecutiveTeam: 512,
    TopWorkplacesAnnouncement: 513,
    ManageEmployerBranding: 514,
    WorkplaceExcellenceAward: 701,
    SummaryReport: 801,
    ExecutionComments: 802,
    DownloadOutcomeReport: 803,
    TrackingSignIn: 901,
    ViewingReorderPaperCodes: 902,
    RequestAdditionalUsers: 903,
    ConfirmSurvey: 904,
    CreateSurvey: 905,
    FormatSurvey: 906,
    ConfirmRecipients: 907,
    TopWorkplacesOverview: 908,
    TopWorkplacesCultureProfile: 909,
    SmsSetup: 910,
    SelectRecipients: 911,
    UploadEmployees: 912,
    CompanyInitialSetup: 913,
    ApproveSurveyLaunch: 914,
    ProvideNationalEmployeeCount: 915,
    RegistrationCompletion: 916,
    SelectAwards: 917,
    SurveyScope: 918,
    FocusAreaTrendingSetup: 1004,
} as const

export default TaskDefinition
