import type { Dispatch, SetStateAction } from 'react'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import { AwardHistoryColumns, AwardStatus, AwardType } from 'containers/Main/EmployerRecognition/constants'
import type { Award } from './awardHistory.types'

export const defaultSort = (awards: Award[]): Award[] => {
    return (
        awards &&
        awards
            .sort((a, b) => a.topWorkplaceName.localeCompare(b.topWorkplaceName))
            .sort((a, b) => Number(b.isNational) - Number(a.isNational))
            .sort((a, b) => b.salesforceYear - a.salesforceYear)
    )
}

const sortByAwardColumn = (awards: Award[]): Award[] => {
    const sortByName = 'topWorkplaceName'
    const filterData = (AwardsType: string) => {
        return orderBy(
            filter(awards, (award) => award.topWorkplaceName === AwardsType),
            sortByName
        )
    }

    const topWorkplaces = filterData(AwardType.TwpUsa)
    const industry = filterData(AwardType.Industry)
    const cultureExcellence = filterData(AwardType.CE)

    const regional = filter(awards, (award) => !award.isNational).sort((a, b) =>
        a.topWorkplaceName.localeCompare(b.topWorkplaceName)
    )

    return concat(topWorkplaces, industry, cultureExcellence, regional)
}

const sortBySurveyColumn = (awards: Award[]): Award[] => {
    const inProgressAwards = filter(awards, (award) => award.status === AwardStatus.InProgress).sort(
        (a, b) => new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime()
    )

    const restAwards = filter(awards, (award) => award.status !== AwardStatus.InProgress)
        .sort((a, b) => new Date(b.closedDateTime).getTime() - new Date(a.closedDateTime).getTime())
        .sort((a, b) => new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime())

    return concat(inProgressAwards, restAwards)
}

const sortByOutcomesColumn = (awards: Award[]): Award[] => {
    const filterData = (AwardStatus: string) => {
        return orderBy(
            filter(awards, (award) => award.status === AwardStatus),
            'internalScheduledCloseDate',
            'desc'
        )
    }

    const topWorkplaces = filterData(AwardStatus.Twp)
    const inProgress = filterData(AwardStatus.InProgress)
    const notNamed = filterData(AwardStatus.NotNamed)

    return concat(topWorkplaces, inProgress, notNamed)
}

export const sortData = (
    isSorting: boolean,
    activeSortColumnName: string,
    awardHistory: Award[],
    setAwardHistory: Dispatch<SetStateAction<Award[]>>
) => {
    const awardData = (): Award[] => {
        if (isSorting) {
            switch (activeSortColumnName) {
                case AwardHistoryColumns.TwpAward:
                    return sortByAwardColumn(awardHistory)
                case AwardHistoryColumns.RegionOrCategory:
                    return (
                        awardHistory &&
                        awardHistory.sort((a, b) => a.regionCategoryName.localeCompare(b.regionCategoryName))
                    )
                case AwardHistoryColumns.Survey:
                    return sortBySurveyColumn(awardHistory)
                case AwardHistoryColumns.Year:
                    return awardHistory && awardHistory.sort((a, b) => a.salesforceYear - b.salesforceYear)
                case AwardHistoryColumns.Outcome:
                    return sortByOutcomesColumn(awardHistory)
                // no default
            }
        }
        return defaultSort(awardHistory)
    }
    setAwardHistory(awardData())
}
