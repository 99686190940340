import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import some from 'lodash/some'
import { useDebouncedCallback } from 'use-debounce'
import { useUpdatedEffect } from 'hooks'
import { hasErrors } from '../verifyQuestion'
import CustomQuestion from './CustomQuestion'

const CustomQuestions = ({
    customQuestions,
    demographicQuestions,
    handleAddRemoveConditionals,
    handleQuestionTypeSelection,
    handleQuestionTextChange,
    handleMultiCommentCountChange,
    handleQuestionNameChange,
    handlePositiveConditionalTextChange,
    handleNegativeConditionalTextChange,
    handleDeleteQuestion,
    handleSubmitQuestions,
    handleOnQuestionSave,
    handleDemographicTargetChange,
    disableSubmit = false,
    autoSaving = false,
    departments,
    handleQuestionDepartmentSelection,
    handleOptionsChange,
}) => {
    return (
        <form onSubmit={handleSubmitQuestions} className="pr-6 pl-6">
            {map(customQuestions, (question, i) => (
                <AutoSaveCustomQuestion
                    key={question._key}
                    question={question}
                    index={i}
                    demographicQuestions={demographicQuestions}
                    handleAddRemoveConditionals={handleAddRemoveConditionals}
                    handleQuestionTypeSelection={handleQuestionTypeSelection}
                    handleQuestionTextChange={handleQuestionTextChange}
                    handleMultiCommentCountChange={handleMultiCommentCountChange}
                    handleQuestionNameChange={handleQuestionNameChange}
                    handlePositiveConditionalTextChange={handlePositiveConditionalTextChange}
                    handleNegativeConditionalTextChange={handleNegativeConditionalTextChange}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleOnQuestionSave={handleOnQuestionSave}
                    handleDemographicTargetChange={handleDemographicTargetChange}
                    departments={departments}
                    handleQuestionDepartmentSelection={handleQuestionDepartmentSelection}
                    handleOptionsChange={handleOptionsChange}
                />
            ))}
            <div className={`clearfix text-center sm:text-left ${customQuestions.length >= 1 ? 'pb-4 pt-0' : 'py-4'}`}>
                {customQuestions.length > 0 && (
                    <Button
                        variant="secondary"
                        className="sm:float-right ml-4"
                        type="submit"
                        disabled={disableSubmitCustomQuestions(disableSubmit, autoSaving, customQuestions)}>
                        {autoSaving ? 'Saving your progress.. ' : `Add Item to Survey`}
                    </Button>
                )}
            </div>
        </form>
    )
}

CustomQuestions.propTypes = {
    customQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            questionTypeId: PropTypes.number,
            name: PropTypes.string,
            text: PropTypes.string,
            textboxCount: PropTypes.number,
            isPositivelyPhrased: PropTypes.bool,
            hasConditionals: PropTypes.bool,
            positiveConditionalText: PropTypes.string,
            negativeConditionalText: PropTypes.string,
        })
    ),
    handleAddRemoveConditionals: PropTypes.func.isRequired,
    handleQuestionTypeSelection: PropTypes.func.isRequired,
    handleQuestionTextChange: PropTypes.func.isRequired,
    handleMultiCommentCountChange: PropTypes.func.isRequired,
    handlePositiveConditionalTextChange: PropTypes.func.isRequired,
    handleNegativeConditionalTextChange: PropTypes.func.isRequired,
    handleDeleteQuestion: PropTypes.func.isRequired,
    handleSubmitQuestions: PropTypes.func.isRequired,
    handleOnQuestionSave: PropTypes.func.isRequired,
    handleDemographicTargetChange: PropTypes.func.isRequired,
    disableSubmit: PropTypes.bool,
    autoSaving: PropTypes.bool,
}

export default CustomQuestions

function disableSubmitCustomQuestions(disabled, isAutoSaving, customQuestions) {
    if (disabled || isAutoSaving || customQuestions.length === 0) {
        return true
    }

    return some(
        customQuestions,
        (question) => hasErrors(question) || question.questionTypeId === undefined || !question.text
    )
}

function AutoSaveCustomQuestion(props) {
    const { question, handleOnQuestionSave, index } = props

    const saveQuestion = (question) => {
        if (
            question.questionTypeId &&
            question.text &&
            question.name &&
            !hasErrors(question) &&
            !question.isSubmitting
        ) {
            let submitData = cloneDeep(question)
            submitData.answerOptions = map(question.answerOptions, (c) => c.name)
            handleOnQuestionSave(submitData).then(([savedQuestion]) => {
                question.id = savedQuestion.id
            })
        }
    }

    const autoSaveCustomQuestion = useDebouncedCallback(saveQuestion, 1250)

    const handleAddRemoveConditionals = () => props.handleAddRemoveConditionals(index)
    const handleQuestionTypeSelection = (value) => props.handleQuestionTypeSelection(value, index)
    const handleQuestionTextChange = (value) => props.handleQuestionTextChange(value, index)
    const handleMultiCommentCountChange = (value) => props.handleMultiCommentCountChange(value, index)
    const handleQuestionNameChange = (value) => props.handleQuestionNameChange(value, index)
    const handlePositiveConditionalTextChange = (value) => props.handlePositiveConditionalTextChange(value, index)
    const handleNegativeConditionalTextChange = (value) => props.handleNegativeConditionalTextChange(value, index)
    const handleDeleteQuestion = () => props.handleDeleteQuestion(index)
    const handleDemographicTargetChange = (value) => props.handleDemographicTargetChange(value, index)
    const handleQuestionDepartmentSelection = (value) => props.handleQuestionDepartmentSelection(value, index)

    const handleOptionsChange = (value) => props.handleOptionsChange(value, index)

    const handleQuestionNameChangeDebounced = useDebouncedCallback(handleQuestionNameChange, 500)

    useUpdatedEffect(() => {
        autoSaveCustomQuestion(question)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question.text, question.name, question.targetDemographic, question.questionTypeId, question.departmentIds])

    if (question.isSubmitting) {
        return null
    }

    return (
        <CustomQuestion
            {...props}
            handleQuestionNameChange={handleQuestionNameChangeDebounced}
            handleAddRemoveConditionals={handleAddRemoveConditionals}
            handleQuestionTypeSelection={handleQuestionTypeSelection}
            handleQuestionTextChange={handleQuestionTextChange}
            handleMultiCommentCountChange={handleMultiCommentCountChange}
            handlePositiveConditionalTextChange={handlePositiveConditionalTextChange}
            handleNegativeConditionalTextChange={handleNegativeConditionalTextChange}
            handleDeleteQuestion={handleDeleteQuestion}
            handleDemographicTargetChange={handleDemographicTargetChange}
            handleQuestionDepartmentSelection={handleQuestionDepartmentSelection}
            handleOptionsChange={handleOptionsChange}
        />
    )
}
