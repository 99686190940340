import { Group } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { TwpUsaNotSurveyingAllMessage } from '../../../Recipients/SurveyScope'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const NotTWPUsaQualified = ({ surveyEventId, inviteeCount }) => {
    const tileUrl = routes.survey.setup.recipients({ surveyEventId })
    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="justify-center mt-2">
                <Group fill={colors.red600} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle
                        className="text-red600 mb-2"
                        ariaText={`Required: Your survey is going to ${inviteeCount} employees`}>
                        {`Your survey is going to ${inviteeCount} employees`}
                    </CardTitle>

                    <p>{TwpUsaNotSurveyingAllMessage}</p>
                </CardContent>
                <SummaryButton tileUrl={tileUrl} buttonText={'Manage Recipients'} className={'w-fit'} />
            </CardBody>
        </SummaryCard>
    )
}
