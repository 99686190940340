import { useAtomValue } from 'jotai/utils'
import every from 'lodash/every'
import map from 'lodash/map'
import styled from 'styled-components'
import { Accordion } from 'components/Accordion'
import QuestionCategory from '../../../../StatementSetup/QuestionCategory'
import {
    demographicSectionAtom,
    questionMapAtom,
    sectionQuestionCountFamily,
} from '../../../../StatementSetup/statementSetupUtility/store'
import DemographicCategorySection from './DemographicCategorySection'

const DemographicSectionAccordionContent = styled.div`
    .demographic-section-container + .demographic-section-container {
        margin-top: 2rem;
    }
`

export function DemographicSection({
    surveyEventId,
    onUpdateQuestions,
    addSelectedDemographic,
    disabled,
    leadingDescription,
    trailingDescription,
    testId,
}) {
    const section = useAtomValue(demographicSectionAtom)
    const { totalCount } = useAtomValue(sectionQuestionCountFamily({ sectionId: section.sectionId }))
    const questionMap = useAtomValue(questionMapAtom)
    const isSelected = (q) => q.isSelected
    const isParentSelected = (q) => {
        return every(q.conditionalQuestionEssenceIds, (id) => questionMap[id]?.isSelected ?? false)
    }

    if (totalCount === 0) {
        return null
    }

    return (
        <Accordion
            testId={testId}
            key={QuestionCategory.Demographic}
            title={<div className="h5 px-6">{section.sectionName}</div>}>
            <DemographicSectionAccordionContent className="px-6 mb-4 clearfix">
                {map(section.subsections, (subsection) =>
                    subsection.questions.length > 0 ? (
                        <div className="demographic-section-container" key={subsection.subsectionId}>
                            <span className="font-bold">{subsection.categoryName}</span>
                            <p className="max-w-md mb-2">
                                {subsection.subsectionId === 'leading-demographics'
                                    ? leadingDescription
                                    : trailingDescription}
                            </p>
                            <DemographicCategorySection
                                subsectionId={subsection.subsectionId}
                                disabled={disabled}
                                onUpdateQuestions={onUpdateQuestions}
                                onUpdateForRequiredQuestion={onUpdateQuestions}
                                addSelectedDemographic={addSelectedDemographic}
                                evaluateQuestionSelected={isSelected}
                                evaluateQuestionParentSelected={isParentSelected}
                                surveyEventId={surveyEventId}
                            />
                        </div>
                    ) : null
                )}
            </DemographicSectionAccordionContent>
        </Accordion>
    )
}
