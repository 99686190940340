import { Text } from '@energage/typography'
import styled from 'styled-components'

type SummaryCardTitleProps = {
    children?: React.ReactNode
    className?: string
    ariaText?: string
}

const CardTitleComponent: React.FC<SummaryCardTitleProps> = ({ children, className, ariaText }) => (
    <Text textStyle="title5">
        {ariaText ? (
            <h3 aria-label={`${ariaText}`} className={className}>
                {children}
            </h3>
        ) : (
            <h3 className={className}>{children}</h3>
        )}
    </Text>
)

export const CardTitle = styled((props: SummaryCardTitleProps) => <CardTitleComponent {...props} />)`

@media (max-width: 768px) {
    display: flex;
    flex-direction: row;
`
