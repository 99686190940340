import { Loading as Spinner } from '@energage/components'
import cx from 'clsx'
import { format } from 'date-fns'
import inRange from 'lodash/inRange'
import size from 'lodash/size'
import styled from 'styled-components'
import { useManagementQuery } from 'api'
import { ExternalLink } from 'components/Anchor'
import { ENERGAGE_URL } from 'constants/strings'
import {
    DALLAS_PUBLISHER_ID,
    EMPLOYEE_FEEDBACK_URL,
    EnergageCeoStatement,
    LONG_ISLAND_PUBLISHER_ID,
    MINNEAPOLIS_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import aboutEnergageText from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/aboutEnergageText'
import { useFetchPressReleaseData } from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/useFetchPressReleaseData.ts'
import { MonsterPressReleaseFormDetails } from 'containers/Public/MediaKit/MonsterPressReleaseFormDetails'
import withClassName from 'style/util/withClassName'
import { DATE_FORMAT } from 'util/formatters'

const PageBreaker = styled.div.attrs({ className: 'pt-4' })`
    page-break-before: ${(props) => (props.isPageBreak ? 'always' : 'avoid')};
`

const titleClass = 'text-purple700 font-bold text-base uppercase'
const SubSection = withClassName('flex mt-8 mr-6', 'div')
const Year = styled.p.attrs({ className: 'text-base font-black absolute' })`
    margin-top: ${(props) =>
        props.publisherId === MINNEAPOLIS_PUBLISHER_ID
            ? '83px'
            : props.publisherId === LONG_ISLAND_PUBLISHER_ID
            ? '87px'
            : '78px'};
    margin-left: 24px;
    font-family: Yantramanav;
`
const noOfWordsInquoteLine = 102
const noOfWordsIndescriptionLine = 80
const noOfLinesForComapanyDescription = 5

const PressReleaseFormSection = ({
    publisherId,
    surveyCompanyId,
    awardCategoryId,
    awardImageUrl,
    publishYear,
    isMediaKitLite,
    isNationalStandard,
}) => {
    const { data, isLoading, isError } = useFetchPressReleaseData(
        awardCategoryId,
        publisherId,
        publishYear,
        surveyCompanyId,
        isNationalStandard
    )
    const logoUrls = useManagementQuery(['company-logo', surveyCompanyId], 'Organization/Logo')
    const companyLogoUrl = logoUrls?.data?.previewUrl

    if (isLoading) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const {
        description,
        quote,
        city,
        publishDate,
        contactName,
        contactEmail,
        contactPhone,
        url,
        awardName,
        publisherName,
        region,
        stateName,
        companyName,
        mediaKitLiteExperience,
    } = data

    const quoteAndAboutCnt =
        size(quote) / noOfWordsInquoteLine +
        size(description) / noOfWordsIndescriptionLine +
        noOfLinesForComapanyDescription

    const isPageBreak = inRange(quoteAndAboutCnt, 10, 15.56) || inRange(quoteAndAboutCnt, 48, 107)

    if (publisherId === REMOTE_WORK_PUBLISHER_ID) {
        return (
            <MonsterPressReleaseFormDetails
                titleClass={titleClass}
                data={data}
                companyLogoUrl={companyLogoUrl}
                publishYear={publishYear}
                awardImageUrl={awardImageUrl}
            />
        )
    }

    return (
        <div className="w-full relative">
            <div className="text-purple700 font-bold text-base my-8 text-center">
                {companyLogoUrl && <img src={companyLogoUrl} alt="Logo" className="inline mb-6 h-16 w-auto" />}
                <p className="uppercase px-0 md:px-8">{`${publisherName} Names ${companyName}`}</p>
                <p className="uppercase px-0 md:px-8">{`A WINNER OF THE ${region} TOP WORKPLACES ${publishYear} AWARD`}</p>
            </div>
            <div className="text-sm px-20 mt-6">
                {`${city}, ${stateName} ${format(
                    new Date(publishDate),
                    DATE_FORMAT
                )} - ${companyName} has been awarded a Top Workplaces ${publishYear} honor by The ${awardName}. This list is based solely on `}
                <ExternalLink href={EMPLOYEE_FEEDBACK_URL} target={EMPLOYEE_FEEDBACK_URL}>
                    {'employee feedback'}
                </ExternalLink>
                {' gathered through a third-party survey administered by employee engagement technology partner '}
                <ExternalLink href={ENERGAGE_URL} target={ENERGAGE_URL}>
                    {'Energage'}
                </ExternalLink>
                {
                    ', LLC. The confidential survey uniquely measures the employee experience and its component themes, including employees feeling Respected & Supported, Enabled to Grow, and Empowered to Execute, to name a few.'
                }
                <div className="mt-8">{EnergageCeoStatement}</div>
                <div className="mt-8 whitespace-pre-wrap">{quote}</div>
            </div>
            <SubSection>
                <div className={cx(titleClass, 'text-right w-1/5')}>{'about'}</div>
                <div className="flex flex-col px-8 w-4/5">
                    <div className="text-sm whitespace-pre-wrap">{description}</div>
                    {quoteAndAboutCnt > 15.55 && quoteAndAboutCnt < 20.15 && <PageBreaker isPageBreak={true} />}
                    <SubSection>
                        <p className="text-xs font-bold">{'Company Contact'}</p>
                        <div className="text-xs px-5">
                            <p>{`${contactName}`}</p>
                            <p>{`${contactEmail}, ${contactPhone}`}</p>
                            <p>{`${url}`}</p>
                        </div>
                    </SubSection>
                </div>
            </SubSection>
            <PageBreaker isPageBreak={isPageBreak} />
            {!mediaKitLiteExperience && !isMediaKitLite && (
                <SubSection>
                    <p className={cx(titleClass, 'text-right w-1/5')}>{'awards'}</p>
                    <div className="px-8">
                        <div className="relatives">
                            <Year publisherId={publisherId}>{publishYear}</Year>
                            <img
                                src={awardImageUrl}
                                alt="award-images"
                                className={
                                    (isNationalStandard && publisherId !== DALLAS_PUBLISHER_ID) ||
                                    publisherId === MINNEAPOLIS_PUBLISHER_ID
                                        ? 'w-20'
                                        : 'w-64'
                                }
                            />
                        </div>
                    </div>
                </SubSection>
            )}
            <SubSection>
                <p className={cx(titleClass, 'text-right w-1/5')}>{'about energage'}</p>
                <div className="text-sm px-8 w-4/5">{aboutEnergageText(publisherId)}</div>
            </SubSection>
        </div>
    )
}

export default PressReleaseFormSection
