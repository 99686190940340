import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import {
    CE_AND_INDUSTRY_PUBLISHER_ID,
    REMOTE_WORK_PUBLISHER_ID,
    TWP_USA_PUBLISHER_ID,
    YEAR_2024,
} from 'containers/Main/EmployerBranding/MediaKit/constants'
import { awardCategoryId as awardCategoryEnum } from 'containers/Main/EmployerBranding/MediaKit/TopWorkplacesAwards/constants'
import ceBadge from 'images/employerBranding/mediaKit/ceBadge.svg'
import industryBadge from 'images/employerBranding/mediaKit/industryBadge.svg'
import pdfHeader from 'images/employerBranding/mediaKit/pdfHeader.svg'
import pdfHeaderNoChevron from 'images/employerBranding/mediaKit/pdfHeaderNoChevron.svg'
import remoteWorkBadge from 'images/employerBranding/mediaKit/remoteWorkBadge.svg'
import twpbadge from 'images/employerBranding/mediaKit/twpbadge.svg'
import usaTodayBadge from 'images/employerBranding/mediaKit/usaTodayBadge.svg'

const PdfTitle = styled.p.attrs({ className: 'absolute text-5xl text-darkblue700 font-normal leading-tight' })`
    left: 5%;
    top: ${(props) => (props.isAwardCheckList ? '33%' : '23%')};
    width: 50%;
`
const BadgeWrapper = styled.div.attrs({ className: 'absolute' })`
    top: 6px;
    right: 38px;
    height: 160px;
`

const NationalAwards = ({ title, year, awardCategoryId, isAwardCheckList, publisherId }) => {
    const usaTodayLogo = year >= YEAR_2024 ? usaTodayBadge : twpbadge

    const badgePath =
        awardCategoryId === awardCategoryEnum.CultureExcellence
            ? ceBadge
            : awardCategoryId === awardCategoryEnum.Industry
            ? industryBadge
            : publisherId === REMOTE_WORK_PUBLISHER_ID
            ? remoteWorkBadge
            : usaTodayLogo

    return (
        <>
            <img
                className="w-full"
                src={require('images/employerBranding/mediaKit/nationalPdfHeader.svg').default}
                alt="Media Kit Header"
            />

            <PdfTitle isAwardCheckList={isAwardCheckList}>{title}</PdfTitle>

            <BadgeWrapper>
                <SvgLoader className="max-w-full" path={badgePath}>
                    <SvgProxy selector="svg" width="160px" height="auto" />

                    <SvgProxy
                        selector="text"
                        font-size="46"
                        font-family="'Yantramanav', sans-serif"
                        font-weight="900"
                    />

                    <SvgProxy selector="text tspan">{`${year}`}</SvgProxy>
                </SvgLoader>
            </BadgeWrapper>
        </>
    )
}

const MediaKitPDFHeader = ({ title, year, publisherId, awardCategoryId, isAwardCheckList, mediaKitLiteExperience }) => {
    const badgePath = mediaKitLiteExperience ? pdfHeaderNoChevron : pdfHeader
    const yearInfo = mediaKitLiteExperience ? '' : year
    return (
        <div className="relative">
            {!mediaKitLiteExperience &&
            (publisherId === TWP_USA_PUBLISHER_ID ||
                publisherId === CE_AND_INDUSTRY_PUBLISHER_ID ||
                publisherId === REMOTE_WORK_PUBLISHER_ID) ? (
                <NationalAwards
                    title={title}
                    year={year}
                    awardCategoryId={awardCategoryId}
                    isAwardCheckList={isAwardCheckList}
                    publisherId={publisherId}
                />
            ) : (
                <>
                    <SvgLoader className="max-w-full" path={badgePath}>
                        <SvgProxy selector="svg" width="100%" height="auto" />
                        <SvgProxy
                            selector="text"
                            font-size="46"
                            font-family="'Yantramanav', sans-serif"
                            font-weight="900"
                        />
                        <SvgProxy selector="text tspan">{`${yearInfo}`}</SvgProxy>
                    </SvgLoader>
                    <PdfTitle isAwardCheckList={isAwardCheckList}>{title}</PdfTitle>
                </>
            )}
        </div>
    )
}

export default MediaKitPDFHeader
