import filter from 'lodash/filter'
import head from 'lodash/head'

export const cultureStatsRequestBody = (data) => {
    const surveyFactorId = head(filter(data?.statements, (s) => s.isSelected === true))?.surveyFactorId

    return {
        ...data,
        surveyFactorId: surveyFactorId,
    }
}
