export const optimizedPreview = {
    Facebook: {
        width: 1200,
        height: 'Auto',
        orgStrenghtFontSize: 'text-5xl',
        loveMyJobFontSize: 'text-3xl',
        cloudChartWidth: 700,
        cloudChartHeight: 500,
    },
    LinkedIn: {
        width: 1104,
        height: 'Auto',
        orgStrenghtFontSize: 'text-5xl',
        loveMyJobFontSize: 'text-3xl',
        cloudChartWidth: 700,
        cloudChartHeight: 500,
    },
    Twitter: {
        width: 880,
        height: 'Auto',
        orgStrenghtFontSize: 'text-2xl',
        cloudChartWidth: 500,
    },
    TopWorkplacesCultureCloud: {
        cloudChartWidth: 575,
        cloudChartHeight: 350,
        backgroundColor: '#ffffff',
        foregroundColor: '#0F617E',
        type: 'Single',
    },
    TopWorkplacesComments: {
        backgroundColor: '#ffffff',
        type: 'Single',
    },
}
