import { ExternalLink } from 'components/Anchor'
import { routes } from 'constants/routes'
import type { TimelinesRevamp } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import {
    DALLAS_PUBLISHER_ID,
    DEFAULT_DATE,
    MINNEAPOLIS_PUBLISHER_ID,
} from 'containers/Main/AwardAnnouncement/constants'
import { dateToObject, monthDayYearDate, parseDateOnly, shortMonthYearDate } from 'util/formatters'

export default (
    publisherId: number,
    companyName: string | undefined | null,
    publisherName: string | undefined,
    hasPublisherProvidedMediaKit: boolean,
    {
        isApproxPublishDate,
        publicationDate,
        pressReleaseDate,
        eventDate,
        hasEvent,
        hasAlphabeticalWinnersList,
        awardAliasName,
        alphabeticalWinnersListDateTime,
        isNationalStandard,
    }: TimelinesRevamp
) => {
    const isDallasPublisher = publisherId === DALLAS_PUBLISHER_ID
    const isMinneapolisPublisher = publisherId === MINNEAPOLIS_PUBLISHER_ID

    let formattedPublicationDate = DEFAULT_DATE
    if (publicationDate) {
        formattedPublicationDate = isApproxPublishDate
            ? shortMonthYearDate(parseDateOnly(dateToObject(publicationDate)))
            : monthDayYearDate(parseDateOnly(dateToObject(publicationDate)))
    }

    let formattedAlphabeticalWinnersListDate = DEFAULT_DATE
    if (hasAlphabeticalWinnersList && alphabeticalWinnersListDateTime) {
        formattedAlphabeticalWinnersListDate = monthDayYearDate(
            parseDateOnly(dateToObject(alphabeticalWinnersListDateTime))
        )
    }

    return [
        {
            heading: 'Today',
            subtitle: 'Update your company profile',
            text: (
                <>
                    <span>{'Update your '}</span>
                    <ExternalLink href={routes.profile()} target="_blank" className="text-blue600 underline">
                        {'company profile'}
                    </ExternalLink>
                    <span>{' for the media partner and TopWorkplaces.com website.'}</span>
                </>
            ),
        },
        {
            heading: 'ASAP',
            subtitle: `Tell senior leaders ${companyName} is a ${awardAliasName} winner`,
            text: `You can start spreading word of your win on ${formattedPublicationDate}. Expect to hear more about opportunities to amplify and celebrate your achievement.`,
        },
        ...(!isDallasPublisher
            ? [
                  {
                      heading: pressReleaseDate
                          ? monthDayYearDate(parseDateOnly(dateToObject(pressReleaseDate)))
                          : DEFAULT_DATE,
                      subtitle: 'Explore your Media Kit',
                      text: hasPublisherProvidedMediaKit
                          ? `Get ready to tell your employees (and the world!) that ${companyName} is a winner. In coming days, expect ${publisherName} to be in touch with you about how they will celebrate your achievement as well sales and marketing opportunities to amplify your award.`
                          : `Get ready to tell your employees (and the world!) that ${companyName} is a winner.`,
                  },
              ]
            : []),
        ...(hasAlphabeticalWinnersList
            ? [
                  {
                      heading: formattedAlphabeticalWinnersListDate,
                      subtitle: `Alphabetical Award Publication`,
                      text: (
                          <span>{`Share the news that ${companyName} is a Top Workplaces winner! Be sure to check the alphabetical list of winners!`}</span>
                      ),
                  },
              ]
            : []),
        ...((!isDallasPublisher && !isMinneapolisPublisher && hasEvent) || (!isNationalStandard && hasEvent)
            ? [
                  {
                      heading: eventDate ? monthDayYearDate(parseDateOnly(dateToObject(eventDate))) : DEFAULT_DATE,
                      subtitle: `Celebrate with fellow winners`,
                      celebrationImg: !!eventDate,
                      text: `Join other winners for ${awardAliasName} celebration event. Contact ${publisherName} for event details!`,
                  },
              ]
            : []),
        {
            heading: `${formattedPublicationDate}`,
            subtitle: 'The announcement goes live',
            text: `Now the world knows ${companyName} is a winner. Share the big news on social media.`,
        },
    ]
}
