import styled from 'styled-components'

type SummaryCardBodyProps = {
    children: React.ReactNode
    className?: string
}

const CardBodyComponent: React.FC<SummaryCardBodyProps> = ({ children, className }) => {
    return <div className={className}>{children}</div>
}

export const CardBody = styled(CardBodyComponent)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    flex: 1 0 0;
`
