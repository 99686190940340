import { useCallback, useRef } from 'react'

type UseDownloadFile = (fileLocation: string) => {
    downloadFile: () => void
    element: JSX.Element
}

export const useDownloadFile: UseDownloadFile = (fileLocation) => {
    const ref = useRef<HTMLIFrameElement>(null)
    const downloadFile = useCallback(() => {
        if (ref.current) {
            ref.current.src = fileLocation
        }
    }, [fileLocation])

    const element = <iframe className="hidden w-0 h-0" title="download file" ref={ref} />
    return { element, downloadFile }
}
