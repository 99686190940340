import { useEffect, useRef } from 'react'

export function useUpdatedEffect(func: () => void, dependencies: unknown[]): void {
    const firstRun = useRef(true)

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false
            return
        }

        func()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)
}
