import { memo, useEffect, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import { get, useController, useFormContext, useWatch } from 'react-hook-form'
import { VisuallyHidden } from 'components'
import { ExternalLink } from 'components/Anchor'
import { useIdentity } from 'components/Identity'
import { SUPPORT_EMAIL } from 'constants/strings'
import preventNonNumbers from 'util/preventNonNumbers'
import { useSurveyWorkflowContext } from '../../useSurveyWorkflowContext'
import type { AwardSelection, AwardSelectionPageResult, SelectedAward } from '../awardselection.types'
import { getEmployeeCountsUsingOverlaps } from '../getEmployeeCountsUsingOverlaps'
import { StyledEmployeeCountInput } from './AwardSelectionTable.atoms'
import { useAwardSelectionTableContext } from './AwardSelectionTableContext'

const valueAsNumber = (value?: string) => {
    if (isUndefined(value) || value === '') {
        return null
    }

    return Number(value)
}

type EmployeeCountInputProps = {
    index: number
    minimumEmployeeCount?: number
    readOnly: boolean
    employeeCountValidationMessage?: string
    employeeCountsBetweenNationalAwardsMessage?: string
}

const getActionMessagePart = (thingy: string) =>
    `or opt out of the award if ${thingy} not meet eligibility requirements`

function getValidationMessage(
    minimumEmployeeCount: number | undefined,
    {
        isSuperUser,
        organizationName,
        surveyHasLaunched,
    }: { isSuperUser: boolean; surveyHasLaunched: boolean; organizationName: string }
) {
    const firstPart = `You must meet the minimum employee count for this region (${minimumEmployeeCount})`

    const userActionPreLaunch = getActionMessagePart('you do')
    const supportAction = getActionMessagePart(`${organizationName} does`)

    if (!surveyHasLaunched) {
        return <>{`${firstPart} ${isSuperUser ? supportAction : userActionPreLaunch}`}</>
    }

    if (isSuperUser) {
        return (
            <>
                {`${firstPart} `} {supportAction}
            </>
        )
    }

    const userActionPostLaunch = (
        <>
            {'. If you do not meet the minimum, please contact '}{' '}
            <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</ExternalLink>
        </>
    )

    return (
        <>
            {firstPart} {userActionPostLaunch}
        </>
    )
}

function isInputEditable(isReadOnly: boolean, isSelected: boolean) {
    if (!isSelected || isReadOnly) {
        return false
    }

    return true
}

function EditEmployeeCountInput({
    index,
    minimumEmployeeCount,
    readOnly,
    employeeCountValidationMessage,
}: EmployeeCountInputProps) {
    const [showMinimumEmployeeCountMessage, setShowMinimumEmployeeCountMessage] = useState(false)
    const { watch, formState, getValues } = useFormContext()
    const { errors } = formState
    const { isSuperUser, organizationName } = useIdentity()
    const { surveyHasLaunched } = useSurveyWorkflowContext()
    const isSelected = watch(`awards.${index}.isSelected` as const)
    const fieldName = `awards.${index}.employeeCount`
    const isEditable = isInputEditable(readOnly, isSelected)
    const allNationalValues = getValues().awards.filter(
        (award: AwardSelection, idx: number) => idx !== index && award.isNational && watch(`awards.${idx}.isSelected`)
    )

    const isNational = getValues(`awards.${index}.isNational`)
    const {
        showEmployeeCountsBetweenNationalAwardsMessage,
        employeeCountsBetweenNationalAwardsMessage,
        changeEmployeeCountsBetweenNationalAwardsMessage,
        setFields,
        nationalInputOnBlur,
    } = useAwardSelectionTableContext()

    const {
        field: { onChange, onBlur, value, name, ref },
    } = useController({
        name: fieldName,
        rules: {
            validate: (value) => {
                let hasEmployeeCountsBetweenNationalAwardsDifference = false

                if (!isEditable) {
                    return true
                }

                if (!isSelected || minimumEmployeeCount === undefined) {
                    return undefined
                }

                if (typeof value === 'number') {
                    setShowMinimumEmployeeCountMessage(value < minimumEmployeeCount)
                    if (value < minimumEmployeeCount) {
                        return false
                    }

                    if (isNational) {
                        hasEmployeeCountsBetweenNationalAwardsDifference = allNationalValues.some(
                            (award: AwardSelection) => value !== award.employeeCount
                        )

                        changeEmployeeCountsBetweenNationalAwardsMessage(
                            hasEmployeeCountsBetweenNationalAwardsDifference
                        )

                        return !hasEmployeeCountsBetweenNationalAwardsDifference
                    }
                }

                return undefined
            },
        },
    })

    useEffect(() => {
        if (isNational) {
            setFields((field) => [...field, onBlur])
        }
    }, [value, isNational, setFields, onBlur])

    const hasError = !!get(errors, fieldName)
    const awardName = getValues(`awards.${index}.title`)
    const id = `${awardName}_employeeCount_${index}`

    const showErrorMessage = () => {
        if (showMinimumEmployeeCountMessage) {
            return (
                employeeCountValidationMessage ||
                getValidationMessage(minimumEmployeeCount, {
                    surveyHasLaunched,
                    isSuperUser,
                    organizationName,
                })
            )
        } else if (showEmployeeCountsBetweenNationalAwardsMessage) {
            return employeeCountsBetweenNationalAwardsMessage
        }
    }

    return (
        <label>
            <VisuallyHidden>{`Employee count for ${awardName}`}</VisuallyHidden>
            <StyledEmployeeCountInput
                id={id}
                disabled={!isEditable}
                readOnly={!isEditable}
                name={name}
                value={value ?? ''}
                ref={ref}
                onChange={(event) => {
                    onChange(valueAsNumber(event.target.value))
                }}
                onBlur={isNational ? nationalInputOnBlur : onBlur}
                size="sm"
                type="number"
                onKeyPress={preventNonNumbers}
                error={hasError}
                info={
                    hasError ? (
                        <span className="text-red500 paragraph-small">{showErrorMessage()}</span>
                    ) : (
                        <span className="paragraph-small">{`Minimum ${minimumEmployeeCount} employees`}</span>
                    )
                }
            />
        </label>
    )
}

const MemoedEditEmployeeInput = memo(EditEmployeeCountInput)

function EditNationalEmployeeCountInput({
    readOnly,
    minimumEmployeeCount,
    index,
    overlappingGroups,
    awardName,
}: EmployeeCountInputProps & { overlappingGroups: AwardSelectionPageResult['overlappingGroups']; awardName: string }) {
    const { control } = useFormContext()

    const awards = useWatch({ name: 'awards', control }) as AwardSelection[]
    const regionalAwardCount = getEmployeeCountsUsingOverlaps(
        awards.filter((a) => a.isSelected && !a.isNational) as SelectedAward[],
        overlappingGroups
    )

    const minEmployeeCount = Math.max(regionalAwardCount, minimumEmployeeCount ?? 0)
    const employeeCountValidationMessage = `You must survey your entire employee population to participate in ${awardName}`

    return (
        <MemoedEditEmployeeInput
            minimumEmployeeCount={minEmployeeCount}
            employeeCountValidationMessage={regionalAwardCount > 0 ? employeeCountValidationMessage : undefined}
            readOnly={readOnly}
            index={index}
        />
    )
}
export { MemoedEditEmployeeInput as EditEmployeeCountInput, EditNationalEmployeeCountInput }
