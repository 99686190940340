import auth0 from 'auth0-js'
import every from 'lodash/every'
import map from 'lodash/map'
import config from 'config'
import { getPermissionName } from 'constants/permission'
import { clearIdentity } from 'store'
import workflowVisibility from 'util/workflowVisibility'

const NEXT_PATH_KEY = 'nextPath'
const IS_LOGGED_IN_KEY = 'isLoggedIn'

const EXTERNAL_APPS = [
    {
        appName: 'Survey',
        logoutUrl: config.urls.surveyLogout,
    },
]

class Auth {
    accessToken
    idToken
    expiresAt

    auth0 = new auth0.WebAuth({
        domain: config.auth.domain,
        clientID: config.auth.clientId,
        redirectUri: `${window.location.origin}/callback`,
        responseType: 'token id_token',
        scope: 'openid profile email',
        audience: config.auth.audience,
        __enableIdPInitiatedLogin: true,
    })

    constructor() {
        this.temporaryRendererTokenSetter = this.temporaryRendererTokenSetter.bind(this)
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.handleAuthentication = this.handleAuthentication.bind(this)
        this.isAuthenticated = this.isAuthenticated.bind(this)
        this.getAccessToken = this.getAccessToken.bind(this)
        this.getIdToken = this.getIdToken.bind(this)
        this.renewSession = this.renewSession.bind(this)
        this.setRedirectPath = this.setRedirectPath.bind(this)
        this.getRedirectPath = this.getRedirectPath.bind(this)
        this.getUserData = this.getUserData.bind(this)
    }

    // TODO: Temporary, remove when renderer fixed
    temporaryRendererTokenSetter(accessToken) {
        this.accessToken = accessToken
    }

    login(nextPathName) {
        if (nextPathName) {
            this.setRedirectPath(nextPathName)
        }

        workflowVisibility.set(true)
        this.auth0.authorize()
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash({ hash: window.location.hash, __enableIdPInitiatedLogin: true }, (err, authResult) => {
                if (err) {
                    return reject(err)
                }

                if (!authResult || !authResult.idToken) {
                    return reject(err)
                }

                this.setSession(authResult)
                resolve()
            })
        })
    }

    getAccessToken() {
        return this.accessToken
    }

    getIdToken() {
        return this.idToken
    }

    setSession(authResult) {
        // Set isLoggedIn flag in localStorage
        localStorage.setItem(IS_LOGGED_IN_KEY, 'true')

        // Set the time that the access token will expire at
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        this.accessToken = authResult.accessToken
        this.idToken = authResult.idToken
        this.expiresAt = expiresAt
    }

    renewSession() {
        return new Promise((resolve, reject) => {
            this.auth0.checkSession({}, (err, authResult) => {
                if (err || !authResult || !authResult.idToken || !authResult.accessToken) {
                    this.logout()
                    return reject(err)
                }

                this.setSession(authResult)
                resolve()
            })
        })
    }

    selfLogout() {
        // Remove tokens and expiry time
        this.accessToken = null
        this.idToken = null
        this.expiresAt = 0

        // Remove isLoggedIn flag from localStorage
        localStorage.removeItem(IS_LOGGED_IN_KEY)
        workflowVisibility.remove()

        clearIdentity()
    }

    logout() {
        this.selfLogout()
        this.auth0.logout({
            returnTo: window.location.origin + '/logoutcallback',
        })
    }

    externalLogout() {
        return Promise.all(
            map(EXTERNAL_APPS, (app) => {
                return new Promise((resolve, reject) => {
                    const iframe = window.document.createElement('iframe')
                    iframe.setAttribute('style', 'display:none;')
                    iframe.setAttribute('src', app.logoutUrl)
                    iframe.onload = function () {
                        resolve()
                    }

                    window.document.body.appendChild(iframe)

                    setTimeout(() => {
                        reject()
                    }, 5000)
                })
            })
        )
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = this.expiresAt
        return new Date().getTime() < expiresAt
    }

    isLoggedIn() {
        return localStorage.getItem('isLoggedIn') === 'true'
    }

    getRedirectPath() {
        const nextPathName = localStorage.getItem(NEXT_PATH_KEY) || '/dashboard'
        localStorage.removeItem(NEXT_PATH_KEY)

        return nextPathName
    }

    setRedirectPath(nextPathName) {
        localStorage.setItem(NEXT_PATH_KEY, nextPathName)
    }

    getUserData() {
        if (!this.idToken) {
            return {}
        }

        return JSON.parse(Buffer.from(this.idToken.split('.')[1], 'base64').toString())
    }
}

const auth = new Auth()

window.addEventListener('storage', function (e) {
    if (e.key === IS_LOGGED_IN_KEY && e.newValue === null) {
        this.location.reload()
    }
})

export function hasAuthorization(identity, authorize) {
    if (authorize instanceof Array) {
        return every(authorize, (permission) => hasAuthorization(identity, permission))
    }

    if (authorize !== undefined && authorize !== null) {
        if (!identity) {
            return false
        }
        if (typeof authorize === 'string') {
            return !!identity[authorize]
        } else if (typeof authorize === 'function') {
            return authorize(identity)
        } else if (typeof authorize === 'number') {
            return identity.permissions[getPermissionName(authorize)]
        }
    }

    return true
}

export default auth
