import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

export const EMPTY_VALUE_PLACEHOLDER = ' '

/**
 * Add the provided key in the list to form a new group sequence
 *
 * @param {(number|number[])[]} list
 * @param {number} key
 * @return {(number|number[])[]}
 *
 * @example
 *
 * var list = [[7], [9, 10], [17], [19]]
 * var key = 11
 * groupSequence(list, key)
 * // => [[7], [9, 10, 11], [17], [19]]
 */

const groupSequence = (list, key) => {
    let hasSequence = false
    const newList = map(list, (x) => {
        const { [size(x) - 1]: last } = x
        if (last === key - 1) {
            x.push(key)
            hasSequence = true
        }
        return x
    })
    if (!hasSequence) {
        newList.push([key])
    }
    return newList
}

/**
 * Converts all elements in array into a string separated by ',' and join the sequence by '-'
 *
 * @param {number[]} list - The array of row index to convert
 * @return {string} - Joined string of sequence
 * @example
 *
 * var list = [[7], [9, 10, 11], [17], [19]]
 * joinSequence(list)
 * // => "7, 9-11, 17, 19"
 */

const joinSequence = (list) =>
    map(list, (item) => {
        if (size(item) > 1) {
            const { 0: first, [size(item) - 1]: last } = item
            return [`${first}-${last}`]
        }
        return item
    }).join(', ')

/**
 * InputState for mapItemBySequence
 * @typedef {Object} InputState
 * @property {string} item1 - Property Name
 * @property {string} item2 - Current row index of employee
 *
 * @example
 *
 * {
 *  item1: "Energage"
 *  item2: 7
 * }
 *
 * InputState for mapItemBySequence
 * @typedef {Object} OutputState
 * @property {string} item1 - Property Name
 * @property {string} item2 - Sequence of row index for identical properties
 *
 * @example
 *
 * {
 *  item1: "Energage"
 *  item2: "7, 9-11, 17, 19"
 * }
 *
 * Format the Sequence of rows of identical properties
 *
 * @param {InputState[]} state
 * @return {OutputState[]}
 */

export const mapItemBySequence = (state) =>
    reduce(
        groupBy(state, ({ item1 }) => item1),
        (acc, value, key) => [
            ...acc,
            {
                item1: key === 'undefined' ? EMPTY_VALUE_PLACEHOLDER : key,
                item2: joinSequence(
                    reduce(
                        map(sortBy(value), ({ item2 }) => item2),
                        groupSequence,
                        []
                    )
                ),
            },
        ],
        []
    )
