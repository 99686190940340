import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import config from 'config'
import { selectAccessToken } from 'store'

const BASE_URL = config.branding.sharing.api

type Widget = 'cultureCloud' | 'linkedInBanner' | 'cultureStats' | 'strengths' | 'comments'

interface Account {
    name: string
    imageUrl: string
}

interface Connection {
    platform: string
    staleStatusTimestamp: string
    connected: boolean
    widget: Widget
    account: Account
}

const platformUrls = {
    LinkedIn: {
        checkStatus: `${BASE_URL}/v1/connection/LinkedIn`,
        connect: `${BASE_URL}/v1/connection/LinkedIn/create`,
    },
}

const checkConnectionStatus = async (token: string, platform: keyof typeof platformUrls): Promise<Connection> => {
    const resource = platformUrls[platform].checkStatus
    const response = await fetch(resource, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
    if (!response.ok) {
        throw new Error('Failed to check connection status')
    }
    return await response.json()
}

export const useGetConnectionStatus = (platform: keyof typeof platformUrls) => {
    const token = useSelector(selectAccessToken)
    const queryClient = useQueryClient()

    const fetchWithDynamicStaleTime = async () => {
        const data = await checkConnectionStatus(token, platform)
        const staleTime = new Date(data.staleStatusTimestamp).getTime() - new Date().getTime()
        const cacheTime = staleTime + 5 * 60 * 1000

        queryClient.setQueryDefaults(['connection-status', platform], {
            staleTime,
            cacheTime,
        })

        return data
    }

    return useQuery(['connection-status', platform], fetchWithDynamicStaleTime, {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
    })
}

const connectAccount = async (
    token: string,
    platform: keyof typeof platformUrls,
    authCode: string,
    scopes: string[]
) => {
    const resource = platformUrls[platform].connect
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Energage-Authentication-Code': authCode,
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ scopes }),
    }

    const response = await fetch(resource, options)
    if (!response.ok) {
        const errorResponse = await response.json()
        throw new Error(errorResponse.message || 'Failed to connect account')
    }
    return await response.json()
}

export const usePostConnection = (platform: keyof typeof platformUrls) => {
    const token = useSelector(selectAccessToken)
    return useMutation((params: { authCode: string; scopes: string[] }) =>
        connectAccount(token, platform, params.authCode, params.scopes)
    )
}
