import { QueryKeys, SurveyStatus } from './constants'

const queryKeysMap: { [key in SurveyStatus]: string | ((hasPlatformSurveySetup: boolean) => string) } = {
    [SurveyStatus.Active]: QueryKeys.InFlightSurvey,
    [SurveyStatus.Closed]: (hasPlatformSurveySetup: boolean) =>
        hasPlatformSurveySetup ? QueryKeys.PostSurveySubscriber : QueryKeys.NonPostSurveySubscriber,
    [SurveyStatus.Approved]: QueryKeys.SurveySetUp,
    [SurveyStatus.NotApprovedUrgent]: QueryKeys.SurveySetUp,
    [SurveyStatus.NotApproved]: QueryKeys.SurveySetUp,
    [SurveyStatus.Unscheduled]: QueryKeys.SurveySetUp,
    [SurveyStatus.NewSurvey]: QueryKeys.SurveySetUp,
}

export const getQueryKey = (status: SurveyStatus, hasPlatformSurveySetup: boolean): string => {
    // queryKeysMap can return a string value or a function
    const keyValue = queryKeysMap[status]

    if (!keyValue) {
        return QueryKeys.Default
    }

    if (typeof keyValue === 'function') {
        // Safe to call since we've confirmed it's a function
        return keyValue(hasPlatformSurveySetup)
    }

    return keyValue
}
