import { ButtonLink } from '@energage/components'
import { useToggle } from '@energage/hooks'
import { Text } from '@energage/typography'
import cx from 'clsx'
import map from 'lodash/map'
import type { FAQSection, FAQsSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Button } from 'containers/Main/AwardAnnouncement/constants'
import { Section } from 'containers/Main/AwardAnnouncement/WinnersRevamp/Section'
import faqIcon from 'images/employerBranding/mediaKit/blueFaqIcon.svg'
import { faqList } from './faqList'

const FAQ = ({ question, answer, isPdf }: FAQSection) => {
    const [isBoxExpanded, expandBox] = useToggle(isPdf || false)

    return (
        <>
            <li className={cx('pl-3 pb-2', { 'mb-4 border-b border-darkblue700': !isPdf })}>
                <Button
                    className={cx('flex items-center cursor-pointer mb-4', {
                        'rotate-90': isBoxExpanded,
                    })}
                    type="button"
                    onClick={expandBox}
                    aria-expanded={isBoxExpanded}>
                    <img
                        src={faqIcon}
                        alt="FAQ question"
                        className={cx('mr-2 transform transition duration-200 ease-in-out', {
                            'rotate-90': isBoxExpanded,
                            'hidden': isPdf,
                        })}
                    />
                    <Text textStyle="title5" className={cx({ 'ml-2': !isPdf, 'mt-2 text-base': isPdf })}>
                        {question}
                    </Text>
                </Button>
                {isBoxExpanded && <p className={cx({ 'mt-2 ml-8 p-2': !isPdf, 'text-sm': isPdf })}>{answer}</p>}
            </li>
        </>
    )
}

export const FAQs = ({ companyProfileUrl, faqUrl, colors, isPdf }: FAQsSection) => (
    <Section bgColor={colors.bgColor} textColor={colors.textColor}>
        <h4 className={cx('font-black', { 'pb-6 text-3xl sm:text-5xl pl-5': !isPdf, 'pl-3 -mt-3 text-lg': isPdf })}>
            {'Frequently Asked Questions'}
        </h4>
        <ol className="list-none pl-0">
            {map(
                faqList(companyProfileUrl, isPdf),
                ({ question, answer }: { question: string; answer: string }, index: number) => (
                    <FAQ key={index} question={question} answer={answer} isPdf={isPdf} />
                )
            )}
        </ol>
        <div className={cx('text-center', { 'mt-12': !isPdf, '-mt-2': isPdf })}>
            <ButtonLink href={faqUrl} target="_blank" appearance="twp">
                {'TopWorkplaces.com FAQ'}
            </ButtonLink>
        </div>
    </Section>
)
