import type { ReactNode } from 'react'
import { Card } from '@energage/components'
import cx from 'clsx'
import { DialogDisclosure, useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'
import { Lightbox } from '../../../../../components/Lightbox'
import type { Tile } from '../models'

const StyledCard = styled((props) => <Card padding="none" radius="md" {...props} />).attrs({
    className: 'cursor-pointer',
})`
    width: 356px;
    border-width: 0;
`

type TileCardProps = {
    className?: string
    children: ReactNode
    tile: Tile
    height: string
}

const TileCard = ({ className, children, tile, height }: TileCardProps) => {
    const dialogState = useDialogState()

    return (
        <>
            <DialogDisclosure {...dialogState}>
                <StyledCard
                    style={{ height: height }}
                    className={cx(className, 'cursor-pointer overflow-hidden items-start text-left')}>
                    {children}
                </StyledCard>
            </DialogDisclosure>
            <Lightbox dialogState={dialogState}>
                <iframe
                    loading="lazy"
                    className="bg-white w-full h-full"
                    title={tile.id}
                    id={tile.id}
                    src={tile.onClickUrl}
                />
            </Lightbox>
        </>
    )
}

export { TileCard }
