import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { useToggle } from '@energage/hooks'
import { ExpandLess, ExpandMore, Icon } from '@energage/icons'
import { colors } from '@energage/theme'
import map from 'lodash/map'
import size from 'lodash/size'
import FancyCheckbox from 'components/FancyCheckbox'
import { Modal } from 'components/Modals'
import { EMPTY_GUID } from 'constants/emptyGuid'
import { useVisibility } from 'hooks'
import { departmentShape, memberShape, optionTagShape } from '../propTypes'
import { TableCell, TableRow } from '../Table'
import DepartmentForm from './DepartmentForm'
import RemoveDepartment from './RemoveDepartment'
import { useEditDepartment } from './useSaveDepartment'

export const TABLE_COLUMNS = [
    {
        label: 'Department',
        className: 'w-full md:w-1/2 pl-12',
        hasCheckbox: true,
    },
    {
        label: 'Employees',
        className: 'hidden w-0 md:inline-block w-1/4',
    },
    {
        label: 'Actions',
        className: 'w:1/4 hidden md:inline-block',
    },
]

const DepartmentRow = ({
    department,
    depth = 0,
    allDepartments,
    updateDepartment,
    allMembers,
    updateDepartmentMembers,
    refetchMembers,
    onCheckboxClick,
    isSelectable,
    isEditable,
}) => {
    const { visible, show, hide } = useVisibility()
    const [expanded, toggle] = useToggle(false)
    const { name, employeeCount, children: childDepartments, members, checked } = department

    const handleDepartmentEdit = useCallback(
        (data) => {
            hide()
            updateDepartment?.(data)
            refetchMembers?.()
        },
        [hide, updateDepartment, refetchMembers]
    )

    const { edit, isEditing } = useEditDepartment(department.id, handleDepartmentEdit)

    const editDepartment = ({ departmentName, parentDepartmentId, memberIds }) => {
        edit(departmentName, parentDepartmentId, memberIds)
    }

    const isExpandable = size(childDepartments) > 0 || size(members) > 0
    const handleDepartmentRemove = useCallback(
        (data) => {
            updateDepartment(data)
            refetchMembers()
        },
        [updateDepartment, refetchMembers]
    )

    return (
        <>
            <TableRow indentLevel={depth}>
                <TableCell className="w-full md:w-1/2" indentLevel={depth}>
                    <span className="inline-block w-10 text-center -my-2">
                        {isExpandable && (
                            <button onClick={toggle}>
                                <Icon color={colors.grey600} as={expanded ? ExpandLess : ExpandMore} />
                            </button>
                        )}
                    </span>
                    {isSelectable && onCheckboxClick && (
                        <FancyCheckbox
                            checked={checked}
                            className="align-middle mx-2"
                            onChange={onCheckboxClick(department)}
                        />
                    )}
                    <span>{name}</span>
                </TableCell>
                <TableCell className="hidden md:inline-block md:w-1/4">{employeeCount}</TableCell>
                {isEditable && department.id !== EMPTY_GUID && (
                    <TableCell className="hidden md:inline-block md:w-1/4 ">
                        <Button variant="link" className="p-0" onClick={show}>
                            {'Edit'}
                        </Button>
                        <RemoveDepartment department={department} onDepartmentRemove={handleDepartmentRemove} />
                        <Modal
                            isOpen={visible}
                            onClose={hide}
                            title="Edit Department"
                            className="w-5/6 sm:w-1/2 xl:w-1/4">
                            <div className="p-3">
                                <DepartmentForm
                                    department={department}
                                    departments={allDepartments}
                                    members={allMembers}
                                    onSubmit={editDepartment}
                                    isSaving={isEditing}
                                    onClose={hide}
                                />
                            </div>
                        </Modal>
                    </TableCell>
                )}
            </TableRow>
            {expanded && (
                <>
                    {size(members) > 0 && (
                        <TableRow indentLevel={depth + 1}>
                            <TableCell cellWidth="100%" indentLevel={depth + 1}>
                                <div className="p-2">
                                    <p className="p-1 text-grey500 font-bold">{'Direct Reports'}</p>
                                    {map(members, (m) => (
                                        <p key={m.id} className="text-blue500 p-1">
                                            {m.name}
                                        </p>
                                    ))}
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {map(childDepartments, (d) => (
                        <DepartmentRow
                            key={d.id}
                            department={d}
                            parentDepartment={department}
                            depth={depth + 1}
                            allDepartments={allDepartments}
                            allMembers={allMembers}
                            updateDepartment={updateDepartment}
                            updateDepartmentMembers={updateDepartmentMembers}
                            refetchMembers={refetchMembers}
                            onCheckboxClick={onCheckboxClick}
                            isSelectable={isSelectable}
                            isEditable={isEditable}
                        />
                    ))}
                </>
            )}
        </>
    )
}

DepartmentRow.propTypes = {
    department: PropTypes.shape(departmentShape),
    depth: PropTypes.number,
    allDepartments: PropTypes.arrayOf(PropTypes.shape(optionTagShape)),
    updateDepartment: PropTypes.func,
    allMembers: PropTypes.arrayOf(PropTypes.shape(memberShape)),
    updateDepartmentMembers: PropTypes.func,
    refetchMembers: PropTypes.func,
    onCheckboxClick: PropTypes.func,
}

export default DepartmentRow
