import { useCallback, useMemo } from 'react'
import { useManagementQuery } from 'api'
import type { EnhancedKy } from 'api'
import { useIdentity } from 'components/Identity'
import { organizationFeatures as orgFeatures } from 'constants/organizationFeatures'
import showErrorAlert from 'util/showErrorAlert'
import type { CategorizedFeatures } from './organizationFeatures.types'
import { TransformedFeatures } from './TransformedFeatures'

export function useOrganizationFeatures() {
    const { surveyCompanyId } = useIdentity()
    const {
        refetch: getOrganizationFeatures,
        data: organizationFeatures,
        isLoading,
    } = useManagementQuery(
        ['featureFlags', surveyCompanyId],
        async (_: unknown, api: EnhancedKy) => {
            const features = await api.fetch<CategorizedFeatures>(`feature/organization/${surveyCompanyId}`)
            return new TransformedFeatures(features, orgFeatures)
        },
        {
            enabled: !!surveyCompanyId,
            staleTime: Infinity,
            onError: () => {
                showErrorAlert('There was an error fetching Organization Features')
            },
        }
    )

    const isActive = useCallback(
        (featureId: number) => {
            if (!organizationFeatures) {
                return false
            }
            return organizationFeatures.isActive(featureId)
        },
        [organizationFeatures]
    )

    return {
        isLoading,
        getOrganizationFeatures,
        isActive,
        KnownOrgFeatureIds: useMemo(() => orgFeatures, []),
    }
}
