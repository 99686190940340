import cx from 'clsx'
import styled from 'styled-components'
import { routes } from 'constants/routes'
import type { PromoteYourAwardsSection } from 'containers/Main/AwardAnnouncement/awardAnnouncement.types'
import { Section } from 'containers/Main/AwardAnnouncement/Section'
import { YellowButton } from 'containers/Main/AwardAnnouncement/YellowButton'
import { media } from 'style/breakpoints'
import { ListSection } from './ListSection'
import { sectionsList } from './sectionsList'

const PromoteAwardsComponent = styled.div<{ isPdf?: boolean }>`
    ${(props) => (props.isPdf ? 'display:flex' : 'flex-direction:row')};
    ${media.smMin`
        display: flex
    `}
`

const Header = {
    sectionName: 'Learn more about Employer Branding',
    headingDiy: 'Promote Your Award',
    textDiy: 'Use Your Media Kit',
    subTextDiy: 'Kit includes materials to self-promote your Top Workplaces win, such as:',
    headingPro: 'Build Your Brand',
    textPro: 'Upgrade to Employer Branding',
    subTextPro: 'Subscription includes the Premium Media Kit plus tools for building your employer brand.',
}

export const PromoteYourAward = ({ isPdf, baseUrl, colors }: PromoteYourAwardsSection) => {
    const { sectionName, headingDiy, headingPro, textDiy, textPro, subTextDiy, subTextPro } = Header

    return (
        <Section bgColor={colors.bgColor} textColor={colors.textColor} isPdf={isPdf}>
            <h2 className={cx('text-white font-black', { 'text-2xl': isPdf, 'sm:text-5xl text-3xl': !isPdf })}>
                {sectionName}
            </h2>
            <PromoteAwardsComponent className="sm:mt-24 mt-10" isPdf={isPdf}>
                <ListSection
                    heading={headingDiy}
                    text={textDiy}
                    subText={subTextDiy}
                    list={sectionsList.listDiy}
                    sectionLogoPath="diy.svg"
                    isProBadgeLogo={false}
                    isPdf={isPdf}
                />
                <ListSection
                    heading={headingPro}
                    text={textPro}
                    subText={subTextPro}
                    list={sectionsList.listPro}
                    sectionLogoPath="pro.svg"
                    isProBadgeLogo
                    isPdf={isPdf}
                />
            </PromoteAwardsComponent>
            <div className={cx('text-center', { 'px-8 mt-10': isPdf, 'mt-12': !isPdf })}>
                <YellowButton
                    href={isPdf ? `${baseUrl}branding` : routes.branding()}
                    className={isPdf ? 'px-10 py-3' : 'px-12'}>
                    {'Learn More'}
                </YellowButton>
            </div>
        </Section>
    )
}
