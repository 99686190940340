import get from 'lodash/get'
import { parse } from 'query-string'
import { optimizedPreview } from './OptimizedPreview'

export const parseQueryParams = (location, config = {}) => {
    const parsedQuery = parse(location.search, { parseBooleans: true, parseNumbers: true, ...config })
    const isPreview = get(parsedQuery, 'preview', false)
    const versionDetails = optimizedPreview[get(parsedQuery, 'version')]
    return { ...parsedQuery, isPreview, versionDetails }
}
