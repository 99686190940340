import { Dropdown, LegacyButton as Button, Loading as Spinner } from '@energage/components'
import useDownloadPressReleaseForm from './useDownloadPressReleaseForm'

const ButtonWithLoading = ({ isLoading, label, onClick }) => (
    <Button className="font-medium rounded-none my" disabled={isLoading} onClick={onClick}>
        <span>{label}</span>
        {isLoading && <Spinner className="ml-4" inline size="small" />}
    </Button>
)

const Download = ({
    awardListParticipantId,
    publisherId,
    awardCategoryId,
    surveyCompanyId,
    publishYear,
    type,
    isNationalStandard,
    ...props
}) => {
    const { download, isDownloading } = useDownloadPressReleaseForm(
        `SurveyCompanies/${surveyCompanyId}/MediaKit/${publisherId}/${publishYear}/${awardCategoryId}/${isNationalStandard}/${awardListParticipantId}/PressRelease${type}`
    )

    return <ButtonWithLoading {...props} onClick={download} isLoading={isDownloading} />
}

const DownloadPressReleaseFormButton = (props) => {
    return (
        <Dropdown
            ariaLabel="Download press release"
            className="inline-block"
            trigger={
                <span>
                    <Button className="px-12">{'Download'}</Button>
                </span>
            }>
            <div className="flex flex-col">
                <Download {...props} type="Pdf" label="Download as PDF" />
                <Download {...props} type="Docx" label="Download as DOC" />
            </div>
        </Dropdown>
    )
}

export default DownloadPressReleaseFormButton
