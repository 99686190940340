import { useCallback, useEffect, useState } from 'react'
import { colors } from '@energage/theme'
import { useDropzone } from 'react-dropzone'
import styled, { css, keyframes } from 'styled-components'
import type { FlattenSimpleInterpolation } from 'styled-components'

export interface BaseContent {
    content: (() => JSX.Element) | (({ message }: { message?: string }) => JSX.Element)
    background: string | FlattenSimpleInterpolation
}

export interface ContentByState {
    headersError: BaseContent
    error: BaseContent
    loading: BaseContent
    success: BaseContent
    waiting: BaseContent
}

interface FileDropZoneProps {
    state: BaseContent
    onFileDrop: (file: File) => void
    errorMessage: string | undefined
}

const ContentWaiting = () => <p className="text-purple700">{'Click or Drag to Upload File Here'}</p>

const ContentLoading = () => <p>{'Uploading...'}</p>

const ContentSuccess = () => <p className="text-green700">{'Success!'}</p>

const ContentError = ({ message = 'Please try uploading again' }) => (
    <>
        <p className="text-red700">{'Error'}</p>
        <p className="text-red700">{message}</p>
    </>
)

const HeaderError = ({ message = 'Cannot recognize data sheet headers' }) => (
    <>
        <p className="text-red700">{message}</p>
    </>
)

const INNER_DIAMETER_PX = 128
const OUTER_RADIUS_PX = 10
const OUTER_DIAMETER_PX = INNER_DIAMETER_PX + 2 * OUTER_RADIUS_PX
const PX_PER_REM = 16

const BACKGROUND_DEFAULT = colors.purple100
const BACKGROUND_SUCCESS = colors.green200
const BACKGROUND_FAILURE = colors.red200

const pulse = keyframes`
    0% {
        background-color: ${BACKGROUND_DEFAULT};
    }
    50% {
        background-color: ${BACKGROUND_SUCCESS};
    }
    100% {
        background-color: ${BACKGROUND_DEFAULT};
    }
`

export const contentByState: ContentByState = {
    headersError: {
        content: HeaderError,
        background: `background-color: ${BACKGROUND_FAILURE};`,
    },
    error: {
        content: ContentError,
        background: `background-color: ${BACKGROUND_FAILURE};`,
    },
    loading: {
        content: ContentLoading,

        background: css`
            animation: ${pulse} 2s infinite;
        `,
    },
    success: {
        content: ContentSuccess,
        background: `background-color: ${BACKGROUND_SUCCESS};`,
    },
    waiting: {
        content: ContentWaiting,
        background: `background-color: ${BACKGROUND_DEFAULT};`,
    },
}

const Root = styled.div.attrs({
    className: 'text-center mx-auto rounded-full border-dashed border-2 border-purple700',
})`
    width: ${OUTER_DIAMETER_PX / PX_PER_REM}rem;
    height: ${OUTER_DIAMETER_PX / PX_PER_REM}rem;
    padding: ${OUTER_RADIUS_PX / PX_PER_REM}rem;
    ${({ state }: Pick<FileDropZoneProps, 'state'>) => state.background}
`

const FileDropZone = ({ state = contentByState.waiting, onFileDrop, errorMessage }: FileDropZoneProps) => {
    const [internalState, setInternalState] = useState(state)
    const [error, setError] = useState<string>()
    useEffect(() => {
        setInternalState(state)
    }, [state])

    const handleFileDrop = useCallback(
        (files: File[]) => {
            setInternalState(contentByState.waiting)

            if (files.length === 0) {
                setInternalState(contentByState.error)
                setError('Please upload an excel file')
                return
            }

            onFileDrop(files[0])
        },
        [onFileDrop]
    )
    const { getInputProps, getRootProps } = useDropzone({
        // @ts-ignore
        accept: ['.xls', '.xlsx', '.csv'], // @TODO Dropzone types 'accept' as 'string'
        multiple: false,
        onDrop: handleFileDrop,
    })
    const { content: Content } = internalState

    return (
        <Root state={state}>
            <input {...getInputProps()} />
            <div className="inline-block outline-none" {...getRootProps()}>
                <div className="w-32 h-32 table-cell align-middle rounded-full mx-auto">
                    <Content message={error || errorMessage} />
                </div>
            </div>
        </Root>
    )
}

export default FileDropZone
