import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import Inset from 'components/Inset'
import { Modal } from 'components/Modals'

const SurveyInvitationSendTimeConfirmation = ({ show, onConfirm, onCancel }) => (
    <Modal
        isOpen={show}
        onClose={onCancel}
        className="w-5/6 sm:w-4/5 md:w-2/3 lg:w-2/5"
        title="Confirm Survey Send Dates">
        <Inset padding>
            <p>{`In order to optimize your response rate, we recommend sending at variable times across several workdays, while avoiding holidays or weekends.
            Please confirm this is when you'd like survey invitations to send.`}</p>
        </Inset>
        <div className="flex justify-end px-3 pb-3">
            <Button variant="secondary" outline onClick={onCancel}>
                {'Cancel'}
            </Button>
            <Button variant="secondary" className="ml-4" onClick={onConfirm}>
                {'Confirm'}
            </Button>
        </div>
    </Modal>
)

SurveyInvitationSendTimeConfirmation.propTypes = {
    show: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default SurveyInvitationSendTimeConfirmation
