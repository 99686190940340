import { CheckCircle } from '@energage/icons/filled'
import timingFunctions from 'polished/lib/mixins/timingFunctions'
import styled from 'styled-components'

const SafeIcon = ({ visible, ...props }) => <CheckCircle {...props} />
const CompletedIcon = styled(SafeIcon).attrs({ size: 20 })`
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 250ms, transform 250ms ${timingFunctions('easeOutBack')};

    ${(props) =>
        props.visible &&
        `
        opacity: 1;
        transform: scale(1);
    `}
`

export default CompletedIcon
