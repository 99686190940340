import { Route, Switch } from 'react-router-dom'
import { routes } from 'constants/routes'
import BulkUpload from './Upload'

const Organization = () => (
    <Switch>
        <Route path={routes.organization.bulkUpload()} component={BulkUpload} />
    </Switch>
)

Organization.propTypes = {}

export default Organization
