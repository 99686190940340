import PropTypes from 'prop-types'
import { colors } from '@energage/theme'
import styled from 'styled-components'
import contrast from 'util/contrast'
import safeInvoke from 'util/safeInvoke'

const getForegroundColor = (backgroundColor) => (contrast(backgroundColor).isLight ? colors.grey700 : colors.white)

const TileBody = styled.div`
    color: ${(props) => props.foregroundColor};
    ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : colors.blue400)}
`
const SingleTile = ({ children, className, bgColor, fgColor }) => {
    const foregroundColor = fgColor || getForegroundColor(bgColor)
    return (
        <div className={className}>
            <TileBody
                className="flex justify-center items-center"
                backgroundColor={bgColor}
                foregroundColor={foregroundColor}>
                {safeInvoke(children, { foregroundColor })}
            </TileBody>
        </div>
    )
}

const StyledSingleTile = styled(SingleTile)`
    width: ${(props) => (props.width ? props.width : null)}px;
    height: ${(props) => (props.height ? props.height : null)}px;
`
StyledSingleTile.propTypes = {
    className: PropTypes.string,
    bgColor: PropTypes.string,
    fgColor: PropTypes.string,
}

export default StyledSingleTile
