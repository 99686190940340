import { colors } from '@energage/theme'
import styled from 'styled-components'
import { media } from 'style/breakpoints'

const TemplateImage = styled(({ src, skeleton, keepMobileView, ...rest }) => <div {...rest} />)`
    flex-shrink: 0;
    height: 282px;
    width: 282px;
    background-size: contain;
    background-color: ${(props) => (props.skeleton ? colors.grey300 : null)};
    background-image: ${(props) => {
        if (props.skeleton) {
            return null
        }

        return props.src ? `url(${props.src})` : `url('/images/template-placeholder.jpg')`
    }};
    mask-image: radial-gradient(80% 95% at 50% 5%, black 99%, transparent 100%);

    ${(props) =>
        !props.keepMobileView &&
        media.smMin`
        height: 172px;
        width: 172px;
        mask-image: radial-gradient(90% 95% at 9% 50%, black 99%, transparent 100%);
    `};
`

export default TemplateImage
