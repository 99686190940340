import { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup } from '@energage/components'
import { Modal } from '@energage/overlays'
import { withRouter } from 'react-router-dom'
import { useManagementQuery } from 'api'
import { useIdentity } from 'components/Identity'
import MultiSelectTextInput from 'components/MultiSelectTextInput'
import config from 'config'
import { usePostWithTaskCompletion, useVisibility } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'

const TestEmailLink = ({ onEmailSent = null, match }) => {
    const surveyEventId = match.params.surveyEventId
    const identity = useIdentity()
    const { visible, show, hide } = useVisibility()
    const [emailAddresses, setEmailAddresses] = useState(identity.isSuperUser ? [] : [identity.emailAddress])
    const [queueEmailsResult, setPostResults] = useState(null)
    const [noEmailsEntered, setNoEmailsEntered] = useState(emailAddresses.length === 0)

    const { mutate: doSetTestInviteeDemographicInfo } = useManagementQuery.mutate(
        async (invitees, api) =>
            await api.postJson(`Organization/${identity.organizationId}/SetUpTestInviteeDemographics`, invitees),
        {
            onError: (err) => {
                showErrorAlert('Something went wrong. Your preview will have incorrect data', err.parsed)
            },
        }
    )

    const { doPost, isPosting } = usePostWithTaskCompletion({
        url: `${config.api.workplaceSurvey}/SurveyCompanies/${identity.surveyCompanyId}/SurveyTestDelivery/${surveyEventId}`,
        onComplete: (data) => {
            setPostResults(data)
            if (onEmailSent != null && data?.sentEmails?.length > 0) {
                onEmailSent()
            }
            if (data && allEmailsSent(data)) {
                hide()
            }
            if (data?.testInvitees?.length > 0) {
                doSetTestInviteeDemographicInfo(data.testInvitees)
            }
        },
    })

    useEffect(() => {
        setNoEmailsEntered(emailAddresses.length === 0)
    }, [emailAddresses])

    useEffect(() => {
        if (!visible) {
            return
        }
        setPostResults(null)
    }, [visible])

    const allEmailsSent = ({ sentEmails, notSentValidEmails, invalidEmails }) => {
        return sentEmails?.length && !notSentValidEmails?.length && !invalidEmails?.length
    }

    const submit = useCallback(() => {
        doPost(emailAddresses)
    }, [doPost, emailAddresses])

    return (
        <>
            <Button appearance="tertiary" className="pl-0" onPress={show}>
                {`Send Test Email`}
            </Button>
            <Modal isOpen={visible} onOpenChange={hide} title="Test Survey Delivery">
                {queueEmailsResult != null && <QueueEmailsResultMsg {...queueEmailsResult} />}
                {`Send test survey email to: `}
                <MultiSelectTextInput
                    placeholderText={'Add emails'}
                    initialOptions={emailAddresses}
                    onOptionsChange={setEmailAddresses}
                />
                <ButtonGroup>
                    <Button isDisabled={isPosting || noEmailsEntered} appearance="primary" onPress={submit}>
                        {'Send Invitation'}
                    </Button>
                </ButtonGroup>
            </Modal>
        </>
    )
}

const QueueEmailsResultMsg = ({ sentEmails, notSentValidEmails, invalidEmails }) => {
    return (
        <div>
            {sentEmails.length > 0 && <SentEmailsMsg sentEmails={sentEmails} />}
            {notSentValidEmails.length > 0 && <NotSentValidEmailsMsg notSentValidEmails={notSentValidEmails} />}
            {invalidEmails.length > 0 && <InvalidEmailsMsg invalidEmails={invalidEmails} />}
        </div>
    )
}

const SentEmailsMsg = ({ sentEmails }) => {
    return (
        <div className="pb-5">
            <p className="font-medium">
                {sentEmails.length === 1 ? `An invitation email` : `${sentEmails.length} invitation emails`}
                {` will be sent in the next five minutes.`}
            </p>
            <p className="pt-3">{`When you receive the invitation email, click on the link inside to complete the test.
                Click the Send Invitation Email button to conduct another test.`}</p>
        </div>
    )
}

const NotSentValidEmailsMsg = ({ notSentValidEmails }) => {
    return (
        <div className="pb-5">
            {`Unable to send invitation email for `}
            <span className="font-medium">{notSentValidEmails.join(', ')}</span>
            {`. Please contact Energage.`}
        </div>
    )
}

const InvalidEmailsMsg = ({ invalidEmails }) => {
    let isMultipleEmails = invalidEmails.length > 1
    return (
        <div className="pb-5">
            <span className="font-medium">{invalidEmails.join(', ')}</span> {isMultipleEmails ? 'do' : 'does'}
            {` not appear to be a valid email address`}
        </div>
    )
}

export default withRouter(TestEmailLink)
