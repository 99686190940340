import type { MouseEvent } from 'react'
import { LegacyButton as Button } from '@energage/components'

export const NoAwardReasons = {
    NOT_ADDED: 1,
    ALL_USED: 2,
} as const

type NoAwardReasonKeys = keyof typeof NoAwardReasons
type NoAwardReasonOptions = typeof NoAwardReasons[NoAwardReasonKeys]

type ConfirmationPromptProps = {
    onNoParticipateClick: (e: MouseEvent<HTMLButtonElement>) => void
    noAwardReason?: NoAwardReasonOptions
    hideOptOut: boolean
}

const allAwardsUsed =
    'Your Top Workplaces awards are already selected on another survey event. Please refer to the Top Workplace survey on the Workplace Survey page that contains your award(s).'
const noAwardsAdded = 'You have not selected any Top Workplaces awards yet.'

const NoAwardTexts: Record<NoAwardReasonOptions, string> = {
    [NoAwardReasons.NOT_ADDED]: noAwardsAdded,
    [NoAwardReasons.ALL_USED]: allAwardsUsed,
} as const

const awardsAvailable = 'Please confirm your employee counts are correct for the awards below.'

export function ParticipatingConfirmationPrompt({
    onNoParticipateClick,
    noAwardReason,
    hideOptOut,
}: ConfirmationPromptProps) {
    return (
        <section className="h6">
            <p className="inline md:block">
                {noAwardReason ? NoAwardTexts[noAwardReason] : awardsAvailable}
                <span className="md:hidden">{'\u00a0'}</span>
            </p>
            {!hideOptOut ? (
                <p className="inline md:block">
                    <Button onClick={onNoParticipateClick} className="p-0 h6" variant="link">
                        {'Click here'}
                    </Button>
                    {` if you don't want to use this survey for Top Workplaces.`}
                </p>
            ) : null}
        </section>
    )
}
