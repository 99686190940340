import { colors } from '@energage/theme'
import size from 'lodash/size'
import type { Column } from 'react-table'
import styled from 'styled-components'
import { monthDayYearDate } from 'util/formatters'
import { showIsTentativeLogo } from '../../ScheduleSurvey/ScheduleLaunch/calendarUtil'
import type { AwardSelectionTableProps } from '../awardselection.types'
import { AwardSelectionTableDesktop } from './AwardSelectionTableDesktop'

export const MaxHeightAwardTable = styled(AwardSelectionTableDesktop)`
    max-height: 300px;
    max-width: 450px;
    th {
        padding-bottom: 0.41rem;
        padding-top: 0.41rem;
    }
    td {
        padding-bottom: 0.35rem;
        padding-top: 0.35rem;
    }
    tbody tr:nth-child(odd) {
        background-color: ${colors.grey100};
    }
`
const columns = [
    {
        Header: 'Award',
        accessor: 'title',
        width: 270,
    },
    {
        Header: 'Survey Close Deadline',
        accessor: 'scheduledCloseDate',
        width: 200,
    },
    {
        accessor: 'isTentative',
        width: 15,
    },
] as Column<AwardSelectionTableProps['awards'][0]>[]

type AwardListDeadlineDateTime = {
    awardName: string
    internalScheduledCloseDate: string
    isTentative: boolean
}

interface WorkplacesDeadlinesProps {
    awardListDeadlineDateTimes: AwardListDeadlineDateTime[]
}

const WorkplacesDeadlines = ({ awardListDeadlineDateTimes }: WorkplacesDeadlinesProps) => {
    const isTentative = showIsTentativeLogo(awardListDeadlineDateTimes)
    const awards = awardListDeadlineDateTimes
        ? awardListDeadlineDateTimes.map((item) => {
              const { awardName, internalScheduledCloseDate, isTentative } = item
              return {
                  awardId: 0,
                  employeeCount: 0,
                  id: 0,
                  isFinalized: false,
                  isNational: false,
                  isSelected: true,
                  locations: [],
                  minimumEmployeeCount: 50,
                  publisherLogoUrl: '',
                  rhfKey: '',
                  sizeBands: [],
                  title: awardName,
                  year: 0,
                  scheduledCloseDate: monthDayYearDate(internalScheduledCloseDate),
                  isTentative: isTentative,
              }
          })
        : []
    return (
        <>
            {size(awards) > 0 && (
                <div>
                    <div className="font-bold pb-2">{'Top Workplaces Deadlines'}</div>
                    <MaxHeightAwardTable awards={awards} columns={columns} />
                    {isTentative && (
                        <div className="max-w-sm text-xs mt-5">
                            {`All tentative survey deadlines are subject to change. 
                            Please survey now to ensure your survey is qualified.`}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
export default WorkplacesDeadlines
