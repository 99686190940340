import { butterBar, LegacyButton as Button } from '@energage/components'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import map from 'lodash/map'
import size from 'lodash/size'
import split from 'lodash/split'
import uniqBy from 'lodash/uniqBy'
import Notification from 'components/StatusBar/NotificationMenu/Notification'
import { routes } from 'constants/routes'
import safeInvoke from 'util/safeInvoke'
import workflowVisibility from 'util/workflowVisibility'
import TaskCategoryIcon from './TaskCategoryIcon'
import taskInformation from './taskInformation'
import taskTypes from './taskTypes'
import taskWorkflowCategories from './taskWorkflowCategories'

const getCategoryAndTaskFromWorkflow = (incompleteTaskList, workflowTaskName) => {
    const category = find(taskWorkflowCategories, (c) => c.name === workflowTaskName)
    const incompleteTaskIds = split(incompleteTaskList, ',')
    const incompleteTasks = map(category?.tasks, (t) => ({
        ...t,
        isComplete: !includes(incompleteTaskIds, `${t.taskDefinitionId}`),
    }))

    return {
        category,
        tasks: filter(incompleteTasks, (t) => !t.isComplete),
    }
}

const getMessageForTaskWorkflow = ({ deadlineDate, incompleteTaskList, surveyEventId, workflowTaskName, identity }) => {
    const { category, tasks } = getCategoryAndTaskFromWorkflow(incompleteTaskList, workflowTaskName)

    if (size(tasks) === 0 || !category) {
        return {}
    }

    const isUrgent = taskTypes[category.type]?.isUrgent(deadlineDate)
    const getMessage = taskInformation[workflowTaskName]
    const path = safeInvoke(category.path, { identity, surveyEventId })

    return { tasks, isUrgent, path, category, getMessage }
}

const butterBarOptions = {
    dismissible: true,
}

export const getButterBarForTaskWorkflow = (currentNotification, history, workflowNotifications, identity) => {
    const { isUrgent, path, category, getMessage } = getMessageForTaskWorkflow({ ...currentNotification, identity })

    if (!isUrgent || !!currentNotification?.readDate) {
        return
    }

    const groupNotificationByType = groupBy(
        uniqBy(workflowNotifications, (w) => w.object.workflowTaskName),
        (w) => find(taskWorkflowCategories, (c) => c.name === w.object.workflowTaskName)?.type
    )

    const count = size(groupNotificationByType[category.type])
    if (count > 1) {
        const showTaskTakeover = () => {
            butterBar.closeAll()
            workflowVisibility.set(true)
            history.push(routes.employerRecognition.taskTakeover())
        }

        return () =>
            butterBar.danger(
                <span className="whitespace-pre-wrap">
                    {`You have ${count} urgent tasks for ${currentNotification.programName} `}
                    <Button variant="link" onClick={showTaskTakeover} className="p-0 underline text-base text-red600">
                        {'View all outstanding tasks.'}
                    </Button>
                </span>,
                butterBarOptions
            )
    }

    const butterBarMessage = getMessage({
        isUrgent,
        category,
        programName: currentNotification.programName,
        path,
        history,
        onClick: () => butterBar.closeAll(),
        className: 'p-0 font-bold underline text-base text-purple600 leading-normal',
    })

    return () => butterBar.primary(<span className="whitespace-pre-wrap">{butterBarMessage}</span>, butterBarOptions)
}

const TaskWorkFlowNotification = (props) => {
    const { tasks, isUrgent, path, category, getMessage } = getMessageForTaskWorkflow({
        ...props.object,
        identity: props.identity,
    })

    const message = getMessage?.({ isUrgent, programName: props.object.programName, path, tasks })

    if (!message) {
        return null
    }

    return (
        <Notification
            {...props}
            link={path}
            text={() => message}
            icon={<TaskCategoryIcon category={category} isUrgent={isUrgent} />}
        />
    )
}

export default TaskWorkFlowNotification
