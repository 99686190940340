import differenceInYears from 'date-fns/differenceInYears'
import { ExternalLink } from 'components/Anchor'
import config from 'config'

const ENERGAGE_LINK = 'https://energage.com/?utm_source=mediakitPR&utm_medium=pressrelease'
const TOP_WORKPLACE_LINK = `${config.urls.topWorkplaces}/?utm_source=mediakitPR&utm_medium=pressrelease`
const YEAR_COUNT = differenceInYears(new Date(), new Date('01-01-2006'))

const aboutEnergageText = () => {
    return (
        <>
            <span className="italic">{'Making the world a better place to work together.™ '}</span>
            <div className="mt-1" />
            <span>
                {'Energage is a purpose-driven company that helps organizations turn employee feedback into useful business intelligence and credible employer recognition through Top Workplaces. Built on ' +
                    YEAR_COUNT +
                    ' years of culture research and the results from 27 million employees surveyed across more than 70,000 organizations,  Energage delivers the most accurate competitive benchmark available. With access to a unique combination of patented analytic tools and expert guidance, Energage customers lead the competition with an engaged workforce and an opportunity to gain recognition for their people-first approach to culture. For more information or to nominate your organization, visit '}
                <ExternalLink href={ENERGAGE_LINK} target={ENERGAGE_LINK}>
                    {'energage.com'}
                </ExternalLink>
                {' or '}
                <ExternalLink href={TOP_WORKPLACE_LINK} target={TOP_WORKPLACE_LINK}>
                    {'topworkplaces.com.'}
                </ExternalLink>
            </span>
        </>
    )
}
export default aboutEnergageText
