import { useCallback, useRef, useState } from 'react'
import SurveyType from 'constants/SurveyType'
import { useIsMounted } from 'hooks'
import { useSaveSurvey } from './MySurveys/SurveySelection/useSaveSurvey'

export const SurveyCreationPreventionReasons = {
    InProgress: Symbol.for('twp-in-progress'),
}

export function useCreateSurvey({ hasInProgressTWPSurvey }) {
    const { postSurvey, loading: creatingSurvey } = useSaveSurvey()
    const [surveyCreationPreventionReason, setSurveyCreationPreventionReason] = useState(null)
    const [workingSurveyGenerationId, setWorkingSurveyGenerationId] = useState(null)
    const capturedSurveyInfo = useRef({})
    const isMounted = useIsMounted()

    const resetSurveyCreationPreventionReason = useCallback(() => {
        setSurveyCreationPreventionReason(null)
    }, [])

    const createSurvey = useCallback(
        async ({ isTwpParticipant } = { isTwpParticipant: null }) => {
            try {
                resetSurveyCreationPreventionReason()
                setWorkingSurveyGenerationId(capturedSurveyInfo.current.surveyGenerationId)
                await postSurvey({ ...capturedSurveyInfo.current, isTwpParticipant })
            } finally {
                if (isMounted()) {
                    setWorkingSurveyGenerationId(null)
                    capturedSurveyInfo.current = null
                }
            }
        },
        [isMounted, postSurvey, resetSurveyCreationPreventionReason]
    )

    async function createSurveyWithoutParticipation() {
        createSurvey({ isTwpParticipant: false })
    }

    const verifySurveyCreation = useCallback(
        ({ surveyTypeId }) => {
            const isStandardSurvey = surveyTypeId === SurveyType.Standard

            if (hasInProgressTWPSurvey && isStandardSurvey) {
                return SurveyCreationPreventionReasons.InProgress
            }

            return null
        },
        [hasInProgressTWPSurvey]
    )

    const setupSurvey = useCallback(
        ({ surveyTypeId, surveyGenerationId, title, levelOfConfidentialityId }) => {
            if (creatingSurvey) {
                return
            }

            const preventionReason = verifySurveyCreation({ surveyTypeId })

            capturedSurveyInfo.current = { surveyGenerationId, title, levelOfConfidentialityId }

            if (!preventionReason) {
                createSurvey()
            }

            setSurveyCreationPreventionReason(preventionReason)
        },
        [createSurvey, creatingSurvey, verifySurveyCreation]
    )

    return {
        setupSurvey,
        creatingSurvey,
        surveyCreationPreventionReason,
        workingSurveyGenerationId,
        createSurveyWithoutParticipation,
        resetSurveyCreationPreventionReason,
    }
}
