import { Loading as Spinner } from '@energage/components'
import styled from 'styled-components'
import { CE_AND_INDUSTRY_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import ceSkyline from 'images/employerBranding/mediaKit/ceSkyline.png'
import cultureExcellenceWhiteLogo from 'images/employerBranding/mediaKit/cultureExcellenceWhiteLogo.svg'
import industryWhiteLogo from 'images/employerBranding/mediaKit/industryWhiteLogo.svg'
import useFetchMediaKitAwardImages from '../useFetchMediaKitAwardImages'

const className = 'absolute text-white font-medium leading-none text-base'

export const YearBox = styled.div.attrs({ className: 'bg-darkblue700' })`
    font-family: 'Libre Franklin';
    font-weight: bold;
    text-align: center;
    background-size: cover;
    box-shadow: 4px 4px 4px #10171780;
    transform: translate(-50%, -50%);
    outline-style: solid;
    outline-width: thin;
    border-radius: 2.5px;
`

const MediaKitLogo = styled.div.attrs({ className })`
    height: 245px;
    width: 230px;
    transform: translate(-50%, -50%);
    margin-bottom: 20px;
    padding-bottom 30px;
`
export const BackgroundImg = styled.div`
    width: 100%;
    ${(props) => `height: ${props.height}vh;`}
    ${(props) => `background-image: linear-gradient(to bottom, #16186166, #33437282),
        url(${props.src});`}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`
export const LogoDiv = styled.div.attrs({ className })`
    left: 50%;
    ${(props) => `top: ${props.top}%;`}
`

export const getBannerImages = (pressReleaseResponse, awardCategoryId, publisherId) => {
    if (awardCategoryId === 1 && publisherId === CE_AND_INDUSTRY_PUBLISHER_ID) {
        return {
            logoUrl: cultureExcellenceWhiteLogo,
            skyLineImageUrl: ceSkyline,
            awardImageUrl: pressReleaseResponse?.awardImageUrl,
        }
    } else if (awardCategoryId === 2) {
        return {
            logoUrl: industryWhiteLogo,
            skyLineImageUrl: ceSkyline,
            awardImageUrl: pressReleaseResponse?.awardImageUrl,
        }
    } else {
        return {
            logoUrl: pressReleaseResponse?.logoUrl,
            skyLineImageUrl: pressReleaseResponse?.skyLineImageUrl,
            awardImageUrl: pressReleaseResponse?.awardImageUrl,
        }
    }
}

const PressReleaseConfiguration = ({
    publisherId,
    surveyCompanyId,
    publishYear,
    isNationalStandard,
    awardCategoryId,
}) => {
    const { pressReleaseResponse, isFetching, isError } = useFetchMediaKitAwardImages(
        publisherId,
        surveyCompanyId,
        publishYear,
        isNationalStandard,
        awardCategoryId
    )

    if (isFetching) {
        return <Spinner />
    }

    if (isError) {
        return null
    }

    const { skyLineImageUrl, logoUrl } = getBannerImages(pressReleaseResponse, awardCategoryId, publisherId)

    return (
        <>
            {skyLineImageUrl && <BackgroundImg src={skyLineImageUrl} height={50} />}
            <LogoDiv top={20}>
                {logoUrl && (
                    <MediaKitLogo>
                        <img src={logoUrl} alt="logo-images" className="w-full h-full" />
                    </MediaKitLogo>
                )}
                {publishYear && (
                    <YearBox>
                        <div className="w-24 mt-32 h-8 p-2">{publishYear}</div>
                    </YearBox>
                )}
            </LogoDiv>
        </>
    )
}

export default PressReleaseConfiguration
