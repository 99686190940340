import { Checkbox } from '@energage/components'
import map from 'lodash/map'
import styled from 'styled-components'
import { SocialMediaSectionData } from 'containers/Main/EmployerBranding/MediaKit/MediaKitLite/SocialMediaSectionData'
import { mediaKitLiteSections } from 'containers/Main/EmployerBranding/MediaKit/MediaKitLiteConfig'
import MediaKitSection from 'containers/Main/EmployerBranding/MediaKit/MediaKitSection'
import withClassName from 'style/util/withClassName'

const IS_PDF = 1

const LabelForSection = styled.div.attrs({ className: 'font-bold py-2' })`
    color: rgba(0, 20, 80, 1);
    font-size: 18px;
`
const SectionComment = withClassName('text-base float-left py-2 text-primary-dark', 'h7')
const PageBreaker = styled.div.attrs({ className: 'pt-4' })`
    page-break-before: always;
`
const BackgroundImg = styled.img`
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 40vh;
`
const Heading = styled.div.attrs({ className: 'font-bold mt-5 text-xl text-primary-dark' })`
    font-family: 'Libre Franklin';
    line-height: 28px;
`
const MediaKitLiteLogo = styled.div.attrs({ className: 'mt-4' })`
    width: 222px;
    line-height: 50px;
    position: absolute;
    left: -60%;
`
const LogoDiv = styled.div.attrs({ className: 'absolute' })`
    left: 45%;
    top: 1%;
`
const MediaKitLiteLabel = styled.p.attrs({
    className: 'text-center absolute text-xl font-bold',
})`
    color: white;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    line-height: 26px;
    font-family: 'Libre Franklin';
    height: 26px;
    width: 160px;
    letter-spacing: 0;
`

const SkylineLogo = () => (
    <LogoDiv>
        <img
            src={require('images/employerBranding/mediaKit/tWPLogoNoyear.svg').default}
            alt="twp"
            className="w-full h-full"
        />
        <MediaKitLiteLogo>
            <img
                className="w-full relative text-center"
                src={require('images/employerBranding/mediaKit/publicityGuide.svg').default}
                alt="Media Kit Lite"
            />
            <MediaKitLiteLabel>{'Publicity Guide'}</MediaKitLiteLabel>
        </MediaKitLiteLogo>
    </LogoDiv>
)

const MediaKitLite = () => {
    const sections = mediaKitLiteSections(IS_PDF)

    return (
        <div className="w-full relative">
            <BackgroundImg
                src={require('images/employerBranding/mediaKit/mediakitliteSkyline.png').default}
                alt="Media Kit Lite"
            />
            <SkylineLogo />
            <MediaKitSection noBorder>
                <Heading>
                    {'Congratulations on your Top Workplaces win! '}
                    <p>
                        {
                            'Now is the time to shine. Here are just some of the ways you can use your award to boost employee morale, attract new talent, and capture the attention of potential customers. '
                        }
                    </p>
                </Heading>
                <div className="float-left pt-5">
                    <LabelForSection>{'Share the exciting news and celebrate with employees'}</LabelForSection>
                    <div className="pt-3">
                        {map(sections.shareExcitingNews, ({ label }) => (
                            <Checkbox
                                key={label}
                                inline
                                disabled
                                label={label}
                                className="w-full py-1 text-base text-primary-dark"
                            />
                        ))}
                    </div>
                </div>
                <PageBreaker>
                    <div className="float-left pt-5 pl-0">
                        <LabelForSection>{'Broadcast your Top Workplaces win on social media'}</LabelForSection>
                        <p className="text-base pt-2 pb-5 text-primary-dark">
                            {
                                'Across all channels, use the hashtag #TopWorkplaces and tag @TopWorkplaces and @TeamEnergage for the chance to be featured on our social media. Also, encourage your employees to re-share your post or create their own.'
                            }
                        </p>
                        <SocialMediaSectionData />
                    </div>
                </PageBreaker>
                <PageBreaker>
                    <div className="float-left pt-5">
                        <LabelForSection>{'Announce your Top Workplaces win with a press release'}</LabelForSection>
                        <p>
                            <SectionComment>
                                {'Tell the world you’re a Top Workplaces winner with a media release.'}
                            </SectionComment>
                        </p>
                        <div className="pt-3">
                            {map(sections.announceYourTopWorkplaces, ({ label }) => (
                                <Checkbox
                                    key={label}
                                    inline
                                    disabled
                                    label={label}
                                    className="w-full py-1 text-base text-primary-dark"
                                />
                            ))}
                        </div>
                    </div>
                </PageBreaker>
                <div className="float-left pt-5">
                    <LabelForSection>{'Write a blog post about being a Top Workplace'}</LabelForSection>
                    <div className="pt-3">
                        {map(sections.writeABlogPost, ({ label }) => (
                            <Checkbox
                                key={label}
                                inline
                                disabled
                                label={label}
                                className="w-full py-1 text-base text-primary-dark"
                            />
                        ))}
                    </div>
                </div>
            </MediaKitSection>
        </div>
    )
}

export default MediaKitLite
