import PropTypes from 'prop-types'
import { Checkbox } from '@energage/components'
import { CategoryLabel } from '../../../../StatementSetup/fragments'
import QuestionTableContext from '../../../../StatementSetup/QuestionTable/QuestionTableContext'
import { HeaderCell, HeaderRow, Table } from '../../../../StatementSetup/QuestionTable/TableElements'

const QuestionTable = ({
    categoryId,
    children,
    handleToggleQuestion,
    allChecked,
    someChecked,
    handleToggleAll,
    disabled,
}) => {
    return (
        <Table>
            <HeaderRow>
                <HeaderCell className="w-full md:w-1/2">
                    <div className="w-full flex flex-no-wrap">
                        <div className="text-center pl-0 md:pb-1">
                            {!disabled ? (
                                <Checkbox
                                    id={categoryId}
                                    checked={allChecked}
                                    indeterminate={someChecked}
                                    onChange={handleToggleAll}
                                />
                            ) : (
                                <div className="w-4" />
                            )}
                        </div>
                        <div className="pl-4 w-full">
                            <CategoryLabel categoryId={categoryId} title="Items" />
                        </div>
                    </div>
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/2 pr-2">
                    {'Details'}
                </HeaderCell>
                <HeaderCell collapse={true} className="w-full md:w-1/12 pr-8 justify-end">
                    {'Required'}
                </HeaderCell>
            </HeaderRow>
            <div className="w-full">
                <QuestionTableContext.Provider value={handleToggleQuestion}>{children}</QuestionTableContext.Provider>
            </div>
        </Table>
    )
}

function requiredFunction(props, propName) {
    if (props['disabled'] === false && (props[propName] === undefined || typeof props[propName] != 'function')) {
        return new Error(`Please provide ${propName}`)
    }
}

QuestionTable.propTypes = {
    categoryId: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    handleToggleQuestion: requiredFunction,
    disabled: PropTypes.bool.isRequired,
    someChecked: PropTypes.bool.isRequired,
    allChecked: PropTypes.bool.isRequired,
    handleToggleAll: requiredFunction,
}

export default QuestionTable
