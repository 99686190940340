import { Skeleton } from '@energage/components'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryCard } from './SummaryCard'

export default function SummaryCardSkeletonComponent() {
    return (
        <SummaryCard>
            <CardIcon className="text-grey300 justify-center p-2">
                <Skeleton.RoundShape className="w-20 h-20 hidden sm:block" />
            </CardIcon>
            <CardBody className="w-full">
                <CardContent>
                    <CardTitle className="mb-2">
                        <CardIcon className="text-grey300 justify-center p-2">
                            <Skeleton.RoundShape className="hidden sm:hidden mr-3" />
                        </CardIcon>
                        <Skeleton.SingleLineShape className="w-3/5 mt-2" />
                    </CardTitle>
                    <Skeleton.SingleLineShape className="w-3/4 mt-2" />
                    <Skeleton.SingleLineShape className="w-3/5 mt-6" />
                    <Skeleton.SingleLineShape className="w-3/4 mt-2" />
                </CardContent>
            </CardBody>
        </SummaryCard>
    )
}
