import PropTypes from 'prop-types'
import { Tooltip as BaseTooltip } from '@energage/components'

// TODO: update so that a div isn't wrapping the trigger
const SimpleTooltip = ({ children, className, disabled = false, trigger, ...props }) => (
    <BaseTooltip
        disabled={disabled}
        className={className}
        placement="bottom"
        renderTrigger={({ ref, getTriggerProps }) => (
            <div className={className} ref={ref} {...getTriggerProps()}>
                {trigger}
            </div>
        )}
        {...props}>
        <span className="paragraph-small flex justify-center content-center">{children}</span>
    </BaseTooltip>
)

SimpleTooltip.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    trigger: PropTypes.node.isRequired,
}

export default SimpleTooltip
