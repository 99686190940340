import { LegacyButton as Button } from '@energage/components'
import { AlarmOn } from '@energage/icons'
import { colors } from '@energage/theme'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AccessibleTileStatus, DAY_MONTH_YEAR_FORMAT, RoundedIconHolder, SurveyDates } from '../fragments'

const NotApproved = ({ id, surveyName, launchDateTime, scheduledCloseDateTime }) => (
    <div className="h-full text-center">
        {surveyName}
        <AccessibleTileStatus backgroundColor="bg-blue500" statusText={'Not Ready'} />
        <SurveyDates launchDateTime={launchDateTime} closeDateTime={scheduledCloseDateTime} />
        <RoundedIconHolder backgroundColor="bg-blue100 mt-7 ">
            <AlarmOn fill={colors.blue600} size={80} />
        </RoundedIconHolder>
        <h5 className="text-blue700 px-10 py-6">{`Your survey is scheduled to launch on ${format(
            new Date(launchDateTime),
            DAY_MONTH_YEAR_FORMAT
        )}. Please complete setup and approve your survey launch.`}</h5>
        <div className="flex justify-center py-4 border-grey200 border-t-2">
            <Button
                size="sm"
                variant="secondary"
                as={Link}
                to={routes.survey.setup.summary({ surveyEventId: id })}>{`View Survey`}</Button>
        </div>
    </div>
)

export default NotApproved
