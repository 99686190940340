import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { CultureStatsType } from 'containers/Main/EmployerBranding/util'

export const CultureStatsId = 'cultureStats'
export const LinkedInBannerId = 'linkedInBanner'
export const CultureCloudId = 'cultureCloud'
export const OrgStrengthsId = 'strengths'
export const EmpCommentsId = 'comments'

export const isTopXPercent = (cultureStatsTableData) =>
    cultureStatsTableData?.tileTypeId === CultureStatsTileTypeId.TopXPercentTile

export const CultureStatsTileTypeId = {
    PercentPositiveTile: 14,
    TopXPercentTile: 15,
}

export const CultureStatsGraphicType = {
    None: 0,
    HalfDonut: 1,
    Donut: 2,
    LaurelHalfCircle: 3,
    Circle: 4,
    RectangleDesignWithoutBorder: 5,
    RectangleBorderDesign: 6,
    ReelDesignWithBorder: 7,
    ReelDesignWithoutBorder: 8,
}

export const RECTANGLE_TYPES = {
    BORDER_DESIGN: 'RectangleDesignWithBorder',
    DESIGN_WITHOUT_BORDER: 'RectangleDesignWithoutBorder',
}

export const isTopXSelected = (selectedCultureType) => selectedCultureType === CultureStatsType.TopXPercent

export const isVersionTable = (cultureStatsTableData, isExpiredWidget) =>
    !isEmpty(cultureStatsTableData) && !isUndefined(isExpiredWidget)

export const tileData = (filterCondition, topXData, percentPositiveData) =>
    filterCondition ? topXData : percentPositiveData

export const isTopXPercentSelected = (selectedCultureType) => selectedCultureType === CultureStatsType.TopXPercent

export const isDesignTypeCircle = (selectedTopXDesignType) => selectedTopXDesignType === CultureStatsGraphicType.Circle

export const isDesignTypeDonut = (selectedPercentPositiveDesignType) =>
    selectedPercentPositiveDesignType === CultureStatsGraphicType.Donut

export const isDesignTypeLaurelHalfCircle = (selectedTopXDesignType) =>
    selectedTopXDesignType === CultureStatsGraphicType.LaurelHalfCircle

export const isDesignTypeHalfDonut = (selectedPercentPositiveDesignType) =>
    selectedPercentPositiveDesignType === CultureStatsGraphicType.HalfDonut

export const isRectangleDesignWithBorderType = (selectedRectangleDesignType) =>
    selectedRectangleDesignType === CultureStatsGraphicType.RectangleBorderDesign

export const isRectangleDesignWithoutBorderType = (selectedRectangleDesignType) =>
    selectedRectangleDesignType === CultureStatsGraphicType.RectangleDesignWithoutBorder

export const isReelDesignWithBorderType = (selectedRectangleDesignType) =>
    selectedRectangleDesignType === CultureStatsGraphicType.ReelDesignWithBorder

export const isRectangleDesignType = (selectedRectangleDesignType) => {
    switch (selectedRectangleDesignType) {
        case CultureStatsGraphicType.RectangleBorderDesign:
        case CultureStatsGraphicType.RectangleDesignWithoutBorder:
        case CultureStatsGraphicType.ReelDesignWithBorder:
        case CultureStatsGraphicType.ReelDesignWithoutBorder:
            return true
        default:
            return false
    }
}

export const isFullCircleSelected = (
    selectedCultureType,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType
) => {
    if (isRectangleDesignType(selectedRectangleDesignType)) {
        return false
    }

    return isTopXSelected(selectedCultureType)
        ? isDesignTypeCircle(selectedTopXDesignType)
        : isDesignTypeDonut(selectedPercentPositiveDesignType)
}

export const isHalfCircleSelected = (
    selectedCultureType,
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType
) => {
    if (isRectangleDesignType(selectedRectangleDesignType)) {
        return false
    }

    return isTopXSelected(selectedCultureType)
        ? isDesignTypeLaurelHalfCircle(selectedTopXDesignType)
        : isDesignTypeHalfDonut(selectedPercentPositiveDesignType)
}

export const isRectangleWithBorderSelected = (
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType
) =>
    selectedTopXDesignType === CultureStatsGraphicType.None &&
    selectedPercentPositiveDesignType === CultureStatsGraphicType.None &&
    isRectangleDesignWithBorderType(selectedRectangleDesignType)

export const isRectangleWithoutBorderSelected = (
    selectedTopXDesignType,
    selectedPercentPositiveDesignType,
    selectedRectangleDesignType
) =>
    selectedTopXDesignType === CultureStatsGraphicType.None &&
    selectedPercentPositiveDesignType === CultureStatsGraphicType.None &&
    isRectangleDesignWithoutBorderType(selectedRectangleDesignType)
