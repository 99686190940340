import cx from 'clsx'
import { format } from 'date-fns'
import inRange from 'lodash/inRange'
import map from 'lodash/map'
import size from 'lodash/size'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import styled from 'styled-components'
import { ExternalLink } from 'components/Anchor'
import { ENERGAGE_URL } from 'constants/strings'
import aboutEnergageText from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/aboutEnergageText'
import DownloadPressReleaseFormButton from 'containers/Main/EmployerBranding/MediaKit/MediaKitPressReleaseFormPreview/DownloadPressReleaseFormButton'
import { DATE_FORMAT } from 'util/formatters'

const noOfWordsIndescriptionAndQuoteLine = 90
const noOfLinesForComapanyDescription = 5

type PressReleaseDataProps = {
    description: string
    quote: string
    city: string
    stateName: string
    companyName: string
    publishDate: string
    contactName: string
    contactEmail: string
    contactPhone: string
    url: string
    mediaKitLiteExperience?: boolean
}

type DownloadPdfProps = {
    label: string
    variant: string
    awardListParticipantId: number
    publisherId: number
    awardCategoryId: number
    surveyCompanyId: number
    publishYear: number
    isNationalStandard: boolean
}

type MonsterPressReleaseDetailsProps = {
    titleClass: string
    data: PressReleaseDataProps
    companyLogoUrl: string
    publishYear: string
    isPageBreak: boolean
    awardImageUrl: string
    isMediaKitLite?: boolean
    isPreviewPage: boolean
    downloadPdfProps: DownloadPdfProps
}

const PageBreaker = styled.div<{ isPageBreak: boolean }>`
    page-break-before: ${(props) => (props.isPageBreak ? 'always' : 'avoid')};
`

export const MonsterPressReleaseFormDetails = ({
    titleClass,
    data,
    companyLogoUrl,
    publishYear,
    awardImageUrl,
    isMediaKitLite,
    isPreviewPage,
    downloadPdfProps,
}: MonsterPressReleaseDetailsProps) => {
    const {
        description,
        quote,
        city,
        stateName,
        companyName,
        publishDate,
        contactName,
        contactEmail,
        contactPhone,
        url,
        mediaKitLiteExperience,
    } = data

    const quoteAndAboutCnt =
        (size(quote) + size(description)) / noOfWordsIndescriptionAndQuoteLine + noOfLinesForComapanyDescription
    const isPageBreak = inRange(quoteAndAboutCnt, 10.75, 48) || inRange(quoteAndAboutCnt, 70, 107)

    return (
        <div className="w-full relative">
            <div className="px-24 md:px-48 mt-8">
                <div className={cx(titleClass, 'text-center')}>
                    {companyLogoUrl && <img src={companyLogoUrl} alt="Logo" className="inline mb-6 h-16 w-auto" />}
                    <p className="uppercase px-0 md:px-8">
                        {`${companyName}`}
                        {` Earns ${publishYear} Top Workplaces for Remote Work Award, Recognized by Industry Leader Monster`}
                    </p>
                    <p>{`Elevating the Employee Experience in the Virtual Workspace`}</p>
                </div>
                <div className="text-sm mt-8">
                    {`${city}, ${stateName} ${format(
                        new Date(publishDate),
                        DATE_FORMAT
                    )} - ${companyName} announced today it is a recipient of the ${publishYear} Top Workplaces for Remote Work award, featured by Monster, a global leader in connecting people and jobs. `}
                    <ExternalLink href={ENERGAGE_URL} target={ENERGAGE_URL}>
                        {'Energage'}
                    </ExternalLink>
                    {
                        ', a purpose-driven organization that builds and brands employers of choice, determines the awards through an employee survey.'
                    }
                    <div className="mt-8">
                        {
                            'The Top Workplaces employer recognition program has a 17-year history of researching, surveying, and celebrating people-first organizations nationally and across 65 regional markets.'
                        }
                    </div>
                    <div className="mt-8">
                        {`The ${publishYear} Top Workplaces for Remote Work award celebrates organizations with 150 or more employees cultivating exceptional remote working environments. ${companyName} earned the award based on employee feedback. Results of a confidential employee engagement survey were evaluated by comparing responses to research-based statements that predict high performance against industry benchmarks.`}
                    </div>
                    <div className="mt-8 whitespace-pre-wrap">{quote}</div>
                    <div className="mt-8">
                        {`“Top Workplaces awards are a powerful tool for companies to attract exceptional talent,” said Eric Rubino, Energage CEO. “They showcase distinctive culture strengths and communicate a clear message to potential recruits: This is a place where you can thrive and excel.”`}
                    </div>
                    <div className="mt-8">{`“These awards underscore the importance of listening to employees about where and when they can be their most productive and happiest selves,” shares Monster CEO Scott Gutz. “We know that this flexibility is essential to helping both employers and candidates find the right fit.”`}</div>

                    <PageBreaker className="pt-4" isPageBreak={isPageBreak}>
                        <div className={cx(titleClass, 'mt-8')}>{`about`}</div>
                        <div className="text-sm whitespace-pre-wrap">{description}</div>
                        <div className="flex mt-12 mr-6">
                            <p className="font-bold text-base">{'For media inquiries, please contact:'}</p>
                            <div className="text-base px-5">
                                <p>{`${contactName}`}</p>
                                <p>{`${contactEmail}`}</p>
                                <p>{`${contactPhone}`}</p>
                                <p>{`${url}`}</p>
                            </div>
                        </div>
                        {!mediaKitLiteExperience && !isMediaKitLite && (
                            <>
                                <p className={cx(titleClass, 'mt-16 mb-8')}>{'award'}</p>
                                <div className="flex relative">
                                    {map(awardImageUrl, (imgSrc, index) => (
                                        <SvgLoader path={imgSrc} key={index} height="auto" className="w-32">
                                            <SvgProxy
                                                selector="text tspan"
                                                font-family="'Yantramanav', sans-serif"
                                                font-size="46"
                                                font-weight="900">
                                                {`${publishYear}`}
                                            </SvgProxy>
                                        </SvgLoader>
                                    ))}
                                </div>
                            </>
                        )}
                        <p className={cx(titleClass, 'mt-8')}>{'about energage'}</p>
                        <div className="text-sm">{aboutEnergageText()}</div>
                    </PageBreaker>
                </div>
            </div>
            {isPreviewPage && (
                <div className="flex justify-center my-8 pb-16">
                    <DownloadPressReleaseFormButton {...downloadPdfProps} />
                </div>
            )}
        </div>
    )
}
