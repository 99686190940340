import { useState } from 'react'
import PropTypes from 'prop-types'
import { ArrowBack } from '@energage/icons'
import { Link } from 'react-router-dom'
import { useIdentity } from 'components/Identity'
import config from 'config'
import { usePost, useUpdatedEffect } from 'hooks'
import { media } from 'style/breakpoints'
import { isEmptyString } from 'util/strings'
import InlineEdit from '../StatementSetup/InlineEdit'

const DEFAULT_SURVEY_NAME = 'Untitled Survey'

export function SurveyTitle({ value, surveyEventId, backPath }) {
    const { surveyCompanyId } = useIdentity()
    const [title, setTitle] = useState(value)

    const { doPost } = usePost(
        `${config.api.workplaceSurvey}/surveycompanies/${surveyCompanyId}/surveydefinition/${surveyEventId}/surveyname`
    )

    useUpdatedEffect(() => {
        doPost(title)
    }, [doPost, title])

    return (
        <div className="w-full max-w-lg flex items-center">
            {backPath && (
                <Link css={{ lineHeight: 0 }} className="mr-4" to={backPath}>
                    <ArrowBack />
                </Link>
            )}
            <InlineEdit
                css={`
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    ${media.smMin`
                            max-width: 27rem;
                        `}
                    ${media.mdMin`
                            max-width: 32rem;
                        `}
                        ${media.lgMin`
                            max-width: 100%;
                        `}
                `}
                onChange={(newTitle) => {
                    const surveyTitle = isEmptyString(newTitle) ? DEFAULT_SURVEY_NAME : newTitle
                    setTitle(surveyTitle)
                }}
                value={title}
                maxLength={50}
            />
        </div>
    )
}

SurveyTitle.propTypes = {
    value: PropTypes.string,
    surveyEventId: PropTypes.string.isRequired,
}
