import { FormatPaint } from '@energage/icons'
import { colors } from '@energage/theme'
import { routes } from 'constants/routes'
import { CardBody, CardContent, CardIcon, CardTitle, SummaryButton, SummaryCard } from '../../Common/SummaryCard'

export const EmptySurvey = ({ surveyEventId, title, message }) => {
    const tileUrl = routes.survey.setup.create({ surveyEventId })
    return (
        <SummaryCard padding="tight" status="error">
            <CardIcon className="justify-center mt-2">
                <FormatPaint fill={colors.red600} size={88} />
            </CardIcon>
            <CardBody>
                <CardContent>
                    <CardTitle className="text-red600 mb-2" ariaText={`Required: ${title}`}>
                        {title}
                    </CardTitle>
                    <p>{message}</p>
                </CardContent>
                <SummaryButton tileUrl={tileUrl} buttonText="Add Statements" className="w-fit" />
            </CardBody>
        </SummaryCard>
    )
}
