import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { Loading as Spinner } from '@energage/components'
import config from 'config'
import { NO_CONTENT } from 'constants/httpStatusCode'
import { useFetch } from 'hooks'
import showErrorAlert from 'util/showErrorAlert'
import AccountNotFound from '../AccountNotFound'

const AccountContext = createContext()

export const useAccountData = () => useContext(AccountContext)

const AccountContextProvider = ({ children, salesforceAccountId }) => {
    const [accountData, setAccountData] = useState()
    const { isLoading: loadingAccount, statusCode } = useFetch({
        url: `${config.api.platform}/subscriptions/account/${salesforceAccountId}`,
        onComplete: setAccountData,
        onError: (error) => {
            showErrorAlert('There was an error in fetching account info', error)
        },
    })

    const updateBillToContact = useCallback(
        (newData) =>
            setAccountData({
                ...accountData,
                billToContact: {
                    ...accountData.billToContact,
                    ...newData,
                },
            }),
        [accountData, setAccountData]
    )

    const value = useMemo(
        () => ({
            loading: loadingAccount || !accountData,
            accountData,
            statusCode,
            updateBillToContact,
        }),
        [loadingAccount, accountData, statusCode, updateBillToContact]
    )

    return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}

const Account = (props) => {
    const { loading, statusCode } = useAccountData()

    if (statusCode === NO_CONTENT) {
        return <AccountNotFound />
    }

    if (loading) {
        return <Spinner />
    }
    return <div {...props} />
}

export default ({ salesforceAccountId, ...restProps }) => {
    if (!salesforceAccountId) {
        return null
    }

    return (
        <AccountContextProvider salesforceAccountId={salesforceAccountId}>
            <Account {...restProps} />
        </AccountContextProvider>
    )
}
