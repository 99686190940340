import { alert } from '@energage/components'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import config from 'config'
import { usePost } from 'hooks'

const mapping = {
    4: 'w-full',
    3: 'w-3/4',
    2: 'w-1/2',
    1: 'w-1/3',
}

const getReducer = (typeId) => (acc, curr) => {
    acc[curr.id] = typeId ? curr.id === typeId : curr.isUnlocked
    return acc
}

export const getInitialMapping = (badgeTypes, selectedBadgeType) =>
    reduce(badgeTypes, getReducer(selectedBadgeType), {})

export const alignments = map([4, 3, 2, 1], (width, id) => ({
    id,
    width,
    label: `${width} Wide`,
    className: mapping[width],
}))

// [badgeCount(s), width]
const defaultMapping = [
    [1, 1],
    [2, 2],
    [[3, 5, 6, 9], 3],
    [[4, 7, 8, 10, 11, 12], 4],
]

export const getDefaultAlignment = (count, defaultValue) => {
    if (defaultValue) {
        return find(alignments, (a) => a.width === defaultValue)
    }
    return reduce(
        defaultMapping,
        (acc, group) => {
            const counts = isArray(group[0]) ? group[0] : [group[0]]
            const alignment = group[1]
            if (includes(counts, count)) {
                return find(alignments, (a) => a.width === alignment)
            }
            return acc
        },
        alignments[0]
    )
}

export const usePublishMultiCultureBadge = (setDownloadUrls) => {
    const { doPost, ...rest } = usePost({
        url: `${config.api.workplaceSurvey}/CultureBadges/Multi/Publish`,
        onComplete: (data) => {
            setDownloadUrls(data)
            alert.success('Your culture badges are ready for downloading!')
        },
        onError: () => alert.danger('Something went wrong. Please try again.'),
    })

    const publish = (body) => {
        alert.warning('Your culture badge is on its way. This might take some time to complete.')
        doPost(body)
    }

    return { publish, ...rest }
}

export const mergeBadgeData = (data) => {
    const { cultureBadges, previousCultureBadges, surveyEvent } = data
    const badges = map(cultureBadges, (c) => {
        const previousBadge = find(previousCultureBadges, (p) => p.type.id === c.type.id)
        return {
            ...c,
            previousYear: previousBadge?.year,
            previousPercentile: previousBadge?.percentile,
            surveyEventName: surveyEvent?.surveyEventName,
            isTopWorkplaceParticipant: surveyEvent?.isTopWorkplaceParticipant,
        }
    })

    return badges
}
