import { colors, textSize } from '@energage/theme'
import cx from 'clsx'
import { format as formatDate } from 'date-fns'
import head from 'lodash/head'
import last from 'lodash/last'
import Calendar from 'react-calendar/dist/entry.nostyle'
import styled from 'styled-components'
import { ReactComponent as TwpLogo } from 'images/twp-chevron-logo.svg'
import {
    baseCalendarCSS,
    HighlightIndicator,
    isCloseDate,
    isDateDisabled,
    isDateHighlighted,
    isEndOfWeek,
    isLaunchDate,
    isReminderDate,
    isStartOfWeek,
    isTentativeDate,
    IsTentativeDateIndicator,
    isTwpAwardDeadlineDate,
    MonthYearLabel,
    NavigationIcon,
    SelectedIndicator,
} from './calendarUtil'

const StyledCalendar = styled(Calendar).attrs({
    minDetail: 'month',
    maxDetail: 'month',
    prev2Label: null,
    next2Label: null,
    prevLabel: <NavigationIcon direction="prev" />,
    nextLabel: <NavigationIcon direction="next" />,
})`
    ${baseCalendarCSS};

    .react-calendar {
        &__navigation {
            border-top: 1px solid ${colors.grey300};
        }

        &__month-view {
            &__weekdays {
                &__weekday {
                    font-size: ${textSize('xs')};
            }

            &__days {
                border-bottom: 1px solid ${colors.grey300};
                &__day {
                    cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
                }
            }
        }
    }
`

const StyledDay = styled.div.attrs((props) => ({
    className: cx('font-bold my-2 relative text-2xl flex justify-center', {
        'text-grey500': props.disabled,
    }),
}))`
    padding: 6px 0;
`

const CalendarDay = ({
    date,
    disabled,
    highlighted,
    isLaunchDate,
    isCloseDate,
    isReminderDate,
    showTWPLogo,
    isTentativeDate,
}) => (
    <StyledDay disabled={disabled}>
        <HighlightIndicator
            hidden={!highlighted}
            isLaunchDate={isLaunchDate}
            isCloseDate={isCloseDate}
            startOfWeek={isStartOfWeek(date)}
            endOfWeek={isEndOfWeek(date)}
            isTentativeDate={isTentativeDate}
        />
        <SelectedIndicator
            isPrimary={isLaunchDate || isCloseDate}
            isSecondary={isReminderDate}
            isTentativeDate={isTentativeDate}
            className="h-12 w-12"
        />

        <IsTentativeDateIndicator
            isTentativeDate={isTentativeDate}
            className={cx({
                'h-12 w-12': isTentativeDate && !(isLaunchDate || isCloseDate || isReminderDate),
                'h-10 w-10': (isLaunchDate || isCloseDate || isReminderDate) && isTentativeDate,
            })}
        />

        <TwpLogo
            className={cx('absolute w-7', {
                'h-10': showTWPLogo && !highlighted && !isTentativeDate,
                'h-8': (showTWPLogo && highlighted) || isTentativeDate,
                'hidden': !showTWPLogo,
            })}
        />

        <span className="relative">{formatDate(date, 'd')}</span>
    </StyledDay>
)

const getInvitationDates = (invitationDates) => ({
    launchDate: head(invitationDates),
    closeDate: last(invitationDates),
    reminderDates: invitationDates.slice(1, invitationDates.length - 1),
})

const CalendarView = ({ invitationDates, minDate, maxDate, readOnly, onSelectDay, awardListDeadlineDateTimes }) => {
    const { launchDate, closeDate, reminderDates } = getInvitationDates(invitationDates)

    return (
        <StyledCalendar
            activeStartDate={launchDate}
            calendarType="US"
            formatShortWeekday={(locale, date) => formatDate(date, 'EE')}
            navigationLabel={({ label }) => <MonthYearLabel>{label}</MonthYearLabel>}
            tileDisabled={({ date }) => readOnly || isDateDisabled(date, minDate, maxDate)}
            tileContent={({ date }) => (
                <CalendarDay
                    date={date}
                    disabled={readOnly || isDateDisabled(date, minDate, maxDate)}
                    highlighted={isDateHighlighted(date, launchDate, closeDate)}
                    isLaunchDate={isLaunchDate(date, launchDate)}
                    isCloseDate={isCloseDate(date, closeDate)}
                    isReminderDate={isReminderDate(date, reminderDates)}
                    showTWPLogo={isTwpAwardDeadlineDate(date, awardListDeadlineDateTimes)}
                    isTentativeDate={isTentativeDate(date, awardListDeadlineDateTimes)}
                />
            )}
            onClickDay={onSelectDay}
        />
    )
}

export default CalendarView
