import { colors } from '@energage/theme'
import getContrast from 'polished/lib/color/getContrast'
import { isValidHexColor } from './validators'

// Contrast ratio will be between 1 (white background) and 21 (black background)
const DEFAULT_CONTRAST_RATIO_THRESHOLD = 1.8

const LIGHT = { isLight: true, isDark: false }
const DARK = { isLight: false, isDark: true }

// Check if a given color is light or dark by calculating it contrast ratio compared to pure white
function contrast(hexString, contrastRatioThreshold = DEFAULT_CONTRAST_RATIO_THRESHOLD) {
    if (hexString.charAt && hexString.charAt(0) !== '#') {
        hexString = `#${hexString}`
    }

    if (!isValidHexColor(hexString)) {
        return LIGHT
    }

    const contrastRatio = getContrast(hexString, colors.white)
    return contrastRatio < contrastRatioThreshold ? LIGHT : DARK
}

export default contrast
