import { select } from 'd3'
import assign from 'lodash/assign'
import constant from 'lodash/constant'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import { accessor, functorAccessor } from './accessor'
import { getWrappedLines, WORD_DELIMITER_REGEXP, wrapText } from './wrapText'

const defaultOptions = {
    wrapLines: false,
    maxWidth: 100,
    wrapEvenly: false,
    maxLines: 3,
    text: constant(''),
    fontSize: constant(14),
    fontWeight: constant(400),
    color: constant('#515151'),
    x: constant(0),
    y: constant(0),
    anchor: constant('start'),
    pathXStart: (x, d, i) => x(d, i),
    pathYStart: (y, d, i, dy) => y(d, i) + dy,
    attrs: {},
}

function text() {
    const options = assign({}, defaultOptions)

    function renderText(selection) {
        if (window.renderTextAsPath) {
            return renderAsPath(selection)
        } else {
            renderAsTextElement(selection)
            return Promise.resolve(selection)
        }
    }

    const createAccessor = accessor(renderText, options)
    createAccessor('wrapLines')
    createAccessor('maxWidth')
    createAccessor('wrapEvenly')
    createAccessor('maxLines')
    createAccessor('attrs')
    const createFunctionAccessor = functorAccessor(renderText, options)
    createFunctionAccessor('text')
    createFunctionAccessor('fontSize')
    createFunctionAccessor('fontWeight')
    createFunctionAccessor('color')
    createFunctionAccessor('x')
    createFunctionAccessor('y')
    createFunctionAccessor('anchor')
    createFunctionAccessor('pathXStart')
    createFunctionAccessor('pathYStart')

    renderText.element = window.renderTextAsPath ? 'g' : 'text'

    return renderText

    function renderAsTextElement(selection) {
        selection
            .text(options.text)
            .attr('fill', options.color)
            .attr('font-size', options.fontSize)
            .attr('font-weight', options.fontWeight)
            .attr('x', options.x)
            .attr('y', options.y)
            .attr('text-anchor', options.anchor)
            .call(addExtraAttributes)

        if (options.wrapLines) {
            selection
                // eslint-disable-next-line @energage/energage/prefer-lodash-method
                .filter(function () {
                    return this.getComputedTextLength() > options.maxWidth
                })
                .call(wrapText(options.maxWidth, options.wrapEvenly, options.maxLines))
        }
    }

    function addExtraAttributes(selection) {
        forEach(options.attrs, (attr, name) => {
            selection.attr(name, attr)
        })
    }

    function renderAsPath(selection) {
        const data = selection.data()
        const labels = map(data, (d, i) => options.text(d, i))
        return Promise.all(map(labels, getPathsForLabel)).then((paths) => {
            selection.each(function (d, i) {
                const group = select(this)
                forEach(paths[i], (path) => {
                    group.append('path').attr('d', path).attr('fill', options.color(d, i)).call(addExtraAttributes)
                })
            })
            return selection
        })

        function getPathsForLabel(label, i) {
            const lines = options.wrapLines
                ? getWrappedLines(label, options.maxWidth, options.wrapEvenly, options.maxLines)
                : [[label]]
            const lineHeight = 16 * 1.1
            return Promise.all(
                map(lines, (line, lineNumber) => {
                    const regex = new RegExp(`(\\w)${WORD_DELIMITER_REGEXP}\\s`, 'g')
                    return getPath(line.join(' ').replace(regex, '$1$2'), data[i], i, lineNumber * lineHeight)
                })
            )
        }
    }

    function getPath(string, d, i, dy) {
        const { x, y, fontSize, fontWeight, pathXStart, pathYStart } = options
        const renderTextToPath = getFontWeightRenderer(fontWeight(d, i))
        return renderTextToPath(string, {
            fontSize: fontSize(d, i),
            x: pathXStart(x, d, i),
            y: pathYStart(y, d, i, dy),
            anchor: `${getAnchor(d, i)}`,
        })
    }

    function getAnchor(d, i) {
        switch (options.anchor(d, i)) {
            case 'start':
                return 'left'
            case 'end':
                return 'right'
            default:
                return 'center'
        }
    }

    function getFontWeightRenderer(fontWeight) {
        switch (fontWeight) {
            case 300:
                return window.textToPathLight
            case 400:
            case 'normal':
                return window.textToPath
            case 700:
            case 'bold':
                return window.textToPathBold
            default:
                // eslint-disable-next-line no-console
                console.warn(`No text-to-path renderer known for font weight "${fontWeight}"`)
                return window.textToPath
        }
    }
}

export default text
