import cx from 'clsx'
import styled from 'styled-components'
import { media } from 'style/breakpoints'
import colors from '../colors'
import Section from '../Section'
import YellowButton from '../YellowButton'

const Year = styled.h2.attrs({ className: 'absolute' })`
    top: 7.5rem;
    left: ${(props) => (props.isPdf ? '47%' : '44%')};
    ${media.smMin`
        top: 180px;
        left: 48px;
    `}
    ${media.mdMin`
        top: 180px;
        left: 55px;
    `}
    color: ${colors.textBlue};
    @media screen and (min-width: 640px) and (max-width: 765px) {
        left: 46%;
        top: 7.5rem;
    }
`

const Achievement = ({ bgColor, shopMerchandiseUrl, isPdf, year, sectionClassName }) => (
    <Section bgColor={bgColor} sectionClassName={sectionClassName} isPdf={isPdf}>
        <h1 className="text-3xl sm:text-5xl font-black">{'Get your Top Workplaces swag.'}</h1>
        <div className="flex sm:flex-row flex-col sm:mt-12 mt-8">
            <div className="relative">
                <Year className="absolute sm:text-4xl text-2xl" isPdf={isPdf}>
                    {year}
                </Year>
            </div>
            <img alt="logo" src={require('images/twp-widget-logo.svg').default} className="sm:h-72 h-48" />
            <div className="flex-1 sm:ml-12 ml-2">
                <p className="text-lg mt-8 pr-0 sm:pr-16">
                    {
                        'Show off your achievement! The Top Workplaces store offers lots of great ways to celebrate and commemorate your award. Please note: American Registry is the only source licensed by Energage for official Top Workplaces merchandise.'
                    }
                </p>
                <div className={cx('mt-8 sm:mt-12 mb-24 sm:mb-0', { 'text-center': isPdf })}>
                    <YellowButton variant="link" href={shopMerchandiseUrl} target="_blank">
                        {'Shop Merchandise'}
                    </YellowButton>
                </div>
            </div>
        </div>
    </Section>
)

export default Achievement
