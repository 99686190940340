import { createContext, useContext } from 'react'

type Department = {
    id: number
    name: string
    children: Department[]
    parentId: number
}
export const DepartmentContext = createContext({
    flattenedDepartments: new Map<number, { id: number; name: string }>(),
    departments: [] as Department[],
})

export function useDepartmentContext() {
    const context = useContext(DepartmentContext)
    if (!context) {
        throw new Error('DepartmentContext is not provided')
    }
    return context
}
