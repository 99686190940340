import PropTypes from 'prop-types'
import { LegacyButton as Button } from '@energage/components'
import { useIdentity } from 'components/Identity'
import { Page } from 'components/Page'
import SimpleTooltip from 'components/SimpleTooltip'
import CreateNewTemplateButton from '../../StatementSetup/CreateNewTemplate'
import { PreviewSurveyButton } from '../Preview'

export const NextPageButton = ({ onSubmit, isDisabled, ...props }) => {
    return (
        <Button {...props} disabled={isDisabled} onClick={onSubmit}>
            {'Next'}
        </Button>
    )
}

export const SaveIndicator = ({ state }) => (
    <SimpleTooltip
        trigger={
            <span className="mr-4 text-grey500">
                {state === 'SAVING' ? 'Saving...' : 'All changes saved to Survey'}
            </span>
        }>
        <div className="w-48">{'Every change you make is automatically saved to your survey.'}</div>
    </SimpleTooltip>
)

const Header = ({ onSubmit, isDisabled, surveyEventId, previewUrl, templateName, requiresPreview, saveState }) => {
    const { isSuperUser } = useIdentity()
    return (
        <Page.Heading
            border
            title={
                <span className="h4">
                    {templateName}
                    {' Template'}
                </span>
            }>
            <div className="flex items-center">
                {saveState !== null ? <SaveIndicator state={saveState} /> : null}
                {isSuperUser && <CreateNewTemplateButton surveyEventId={surveyEventId} />}
                <PreviewSurveyButton
                    surveyEventId={surveyEventId}
                    previewUrl={previewUrl}
                    buttonText="Preview"
                    variant="primary"
                    outline={!requiresPreview}
                    className="ml-4"
                />
                <NextPageButton className="ml-4" onSubmit={onSubmit} isDisabled={isDisabled} />
            </div>
        </Page.Heading>
    )
}

Header.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    selectedQuestionCount: PropTypes.number,
    isFullSurvey: PropTypes.bool,
}

export default Header
