import { useOrganizationQuery } from 'api'
import showErrorAlert from 'util/showErrorAlert'
import { useSurveyScope } from './useSurveyScope'

function useSurveyInfo(surveyEventId, organizationId) {
    const {
        data: organizationMembers,
        refetch: fetchOrganizationMemberCount,
        isLoading: isOrganizationMemberCountLoading,
    } = useOrganizationQuery(
        ['org-member-count', surveyEventId],
        `surveyOrganizations/GetMembersCount/${surveyEventId}/${organizationId}`,
        {
            onError: (error) => showErrorAlert('There was an error loading recipients data', error.parsed),
        }
    )

    const { surveyScope, isLoadingSurveyScope, surveyScopeSaveState, handleChangeWhoYouAreSurveying } =
        useSurveyScope(surveyEventId)

    return {
        fetchingMembers: isLoadingSurveyScope || isOrganizationMemberCountLoading,
        fetchMembers: fetchOrganizationMemberCount,
        surveyScope,
        surveyEventMemberCount: {
            total: organizationMembers?.totalSurveyEventMembers ?? 0,
            withEmailOnly: organizationMembers?.totalSurveyEventMembersWithEmailNoSmsConsent ?? 0,
            withPhoneNumberAndSmsConsentOnly:
                organizationMembers?.totalSurveyEventMembersWithPhoneNumberAndSmsConsentNoEmail ?? 0,
            withEmailPhoneNumberAndSmsConsent:
                organizationMembers?.totalSurveyEventMembersWithEmailPhoneNumberAndSmsConsent ?? 0,
        },
        organizationMemberCount: organizationMembers?.totalOrganizationMembers,
        targetDepartmentNames: organizationMembers?.targetDepartmentNames,
        surveyScopeSaveState,
        handleChangeWhoYouAreSurveying,
    }
}

export default useSurveyInfo
