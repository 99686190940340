import { createContext, useContext, useMemo } from 'react'

export type CustomDemographicTarget = {
    id: number
    name: string
    demographics: Array<{ id: number; name: string }>
}

export const CustomDemographicTargetContext = createContext<CustomDemographicTarget[]>([])

export function useCustomDemographicTargets() {
    const value = useContext(CustomDemographicTargetContext)

    if (!value) {
        throw new Error('useCustomDemographicTarget must be used within a CustomDemographicTargetProvider')
    }

    return useMemo(
        () =>
            value.map(({ id, name, demographics }) => ({
                demographicTypeId: id,
                name,
                answerOptions: demographics,
            })),
        [value]
    )
}
