import styled, { css } from 'styled-components'

export const ScrollbarLessContainer = styled.div.attrs({
    className: 'pl-4 sm:pl-6 overflow-x-auto pb-3 whitespace-nowrap flex ',
})`
    ${(props) =>
        props.scrollSnap &&
        css`
            scroll-snap-type: x mandatory;
        `}
`
