import Inset from 'components/Inset'
import { CE_AND_INDUSTRY_PUBLISHER_ID, TWP_USA_PUBLISHER_ID } from 'containers/Main/EmployerBranding/MediaKit/constants'
import useFetchMediaKitAwardImages from 'containers/Main/EmployerBranding/MediaKit/useFetchMediaKitAwardImages'
import { parseQueryParams } from '../Widgets/QueryParser'
import { CeAndIndustryPressReleaseFormDetails } from './CeAndIndustryPressReleaseFormDetails'
import PressReleaseConfiguration from './PressReleaseConfiguration'
import PressReleaseFormSection from './PressReleaseFormSection'
import { UsaPressReleaseFormSection } from './UsaPressReleaseFormSection'

export const PressReleaseForm = ({ location }) => {
    const parsedQuery = parseQueryParams(location)
    const {
        surveyCompanyId,
        awardListParticipantId,
        publisherId,
        awardCategoryId,
        year,
        isNationalStandard,
        mediaKitLiteExperience,
    } = parsedQuery || {}

    const { pressReleaseResponse } = useFetchMediaKitAwardImages(
        publisherId,
        surveyCompanyId,
        year,
        isNationalStandard,
        awardCategoryId
    )
    const awardImageUrl = pressReleaseResponse?.awardImageUrl
    const PressReleaseDetailsComp =
        publisherId === CE_AND_INDUSTRY_PUBLISHER_ID || awardCategoryId === 1
            ? CeAndIndustryPressReleaseFormDetails
            : publisherId === TWP_USA_PUBLISHER_ID
            ? UsaPressReleaseFormSection
            : PressReleaseFormSection

    return (
        <Inset>
            <PressReleaseConfiguration
                className="pb-12"
                publisherId={publisherId}
                surveyCompanyId={surveyCompanyId}
                publishYear={year}
                isNationalStandard={isNationalStandard}
                awardCategoryId={awardCategoryId}
            />
            <div className="w-full mt-4 mr-4 ml-4">
                <PressReleaseDetailsComp
                    publisherId={publisherId}
                    surveyCompanyId={surveyCompanyId}
                    awardListParticipantId={awardListParticipantId}
                    awardCategoryId={awardCategoryId}
                    awardImageUrl={awardImageUrl}
                    publishYear={year}
                    isMediaKitLite={!!mediaKitLiteExperience}
                    isNationalStandard={isNationalStandard}
                />
            </div>
        </Inset>
    )
}
