import PropTypes from 'prop-types'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation'
import { UpdateActiveSurveysCheckbox } from '../../UpdateActiveSurveys'
import useRemoveEmployee from './useRemoveEmployee'

const RemoveEmployee = ({ employee, onEmployeeRemove }) => {
    const { remove, isRemoving } = useRemoveEmployee(employee, onEmployeeRemove)

    return (
        <ButtonWithConfirmation
            variant="link"
            className="p-0"
            title="Remove Employee"
            eventName={'Remove employees'}
            onAccept={remove}
            confirmationMessage={'Are you sure you want to remove this employee?'}
            additionalButtonBarContent={<UpdateActiveSurveysCheckbox />}
            actionVariant="danger">
            {isRemoving ? 'Removing...' : 'Remove'}
        </ButtonWithConfirmation>
    )
}

RemoveEmployee.propTypes = {
    employee: PropTypes.shape({
        id: PropTypes.string.isRequired,
        emailAddress: PropTypes.string.isRequired,
    }).isRequired,
    onEmployeeRemove: PropTypes.func.isRequired,
}

export default RemoveEmployee
