import { LegacyButton as Button, Loading as Spinner } from '@energage/components'
import styled from 'styled-components'

const StyledSpinner = styled(Spinner).attrs({ className: 'ml-2 flex items-center' })`
    .loading__title {
        margin-bottom: 0;
    }

    && {
        padding: 0;
    }
`

const LoadingButton = ({ children, type = 'button', isLoading, disabled = false, ...props }) => (
    <Button {...props} type={type} disabled={isLoading || disabled}>
        <div className="flex content-center justify-center">
            {children}
            {isLoading && <StyledSpinner text={false} size="small" />}
        </div>
    </Button>
)

export default LoadingButton
