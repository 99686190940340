import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import merge from 'lodash/merge'
import noop from 'lodash/noop'
import reduce from 'lodash/reduce'
import { useSelector } from 'react-redux'
import { api } from 'api'
import { selectAccessToken } from 'store'
import { createAction } from 'util/actionCreator'
import compactObject from 'util/compactObject'

const createResponseHandler = (getData) => async (response) => {
    if (response.ok) {
        const contentType = response.headers.get('content-type')
        let data
        try {
            data = contentType ? await response[getData]() : null
        } catch (e) {
            // eslint-disable-next-line no-console
            data = null
        }

        return { data, statusCode: response.status }
    }

    throw response
}

function getFetchConfig(options) {
    return typeof options === 'string' || Array.isArray(options)
        ? { url: options }
        : { ...options, opts: options.options }
}

const Actions = {
    AUTH_ERROR: 'AUTH_ERROR',
    FETCH_INIT: 'FETCH_INIT',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_ERROR: 'FETCH_ERROR',
    FETCH_RESET: 'FETCH_RESET',
}

const authError = createAction(Actions.AUTH_ERROR)
const fetchInit = createAction(Actions.FETCH_INIT)
const fetchSuccess = createAction(Actions.FETCH_SUCCESS)
const fetchError = createAction(Actions.FETCH_ERROR)
const fetchReset = createAction(Actions.FETCH_RESET)

function fetchReducer(state, action) {
    switch (action.type) {
        case Actions.AUTH_ERROR:
            return { ...state, isAuthenticated: false }
        case Actions.FETCH_INIT:
            return { ...state, isLoading: true }
        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: Array.isArray(action.payload) ? action.payload : action.payload?.data,
                statusCode: action.payload?.statusCode,
            }
        case Actions.FETCH_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, statusCode: action.payload.statusCode }
        case Actions.FETCH_RESET:
            return { ...state, isLoading: false, error: null, statusCode: null }
        default:
            throw new Error()
    }
}

function useFetchState(initialLoading) {
    return useReducer(fetchReducer, {
        isLoading: initialLoading,
        error: null,
        data: null,
        statusCode: null,
        isAuthenticated: null,
    })
}

function useVolatile(value) {
    const savedValue = useRef()
    useEffect(() => {
        savedValue.current = value
    }, [value])
    return savedValue
}

async function getFetchPromise(url, opts, onComplete) {
    if (isArray(url)) {
        return Promise.all(map(url, (url) => api(url, opts).then(onComplete))).then((responses) =>
            reduce(
                responses,
                ({ data, statusCode }, response) => {
                    data.push(response.data)
                    statusCode.push(response.statusCode)
                    return { data, statusCode }
                },
                { data: [], statusCode: [] }
            )
        )
    }

    return api(url, opts).then(onComplete)
}

export async function getError(thrownError) {
    return thrownError.response?.headers?.get('content-type')
        ? (await thrownError?.response?.json()) ?? new Error()
        : thrownError
}

function useFetchData(dispatch, onComplete = noop, onError = noop, responseType = 'json') {
    const accessToken = useSelector(selectAccessToken)
    const mounted = useRef(false)
    const savedOnComplete = useVolatile(onComplete)
    const savedOnError = useVolatile(onError)
    const savedGetData = useVolatile(createResponseHandler(responseType))

    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    return useCallback(
        async (url, opts) => {
            if (!accessToken) {
                if (mounted.current) {
                    dispatch(authError())
                }
                return
            }

            if (mounted.current) {
                dispatch(fetchInit())
            }

            try {
                const response = await getFetchPromise(url, opts, savedGetData.current)
                if (mounted.current) {
                    savedOnComplete.current(response.data)
                    dispatch(fetchSuccess(response))
                }
                return response.data
            } catch (e) {
                const error = await getError(e)
                if (mounted.current) {
                    dispatch(fetchError({ error, statusCode: e.status }))
                    savedOnError.current(error)
                }
                throw error
            }
        },
        [accessToken, dispatch, savedOnComplete, savedOnError, savedGetData]
    )
}

function validateCallback(callback, name) {
    if (callback && typeof callback !== 'function') {
        throw Error(`${name} callback should be a function but was ${callback}`)
    }
}

/**
 * @callback CompletionCallback
 * @param {Object} data
 */

/**
 * @callback ErrorCallback
 * @param {Error} error
 */

/**
 * @callback GetResultCallback
 * @param {Response} response - The request response
 * @return {*}
 */

/**
 * Options for useFetch hooks
 * @typedef {Object} UseFetchOptions
 * @property {string} url - The URL to fetch data from
 * @property {RequestInit} [options] - Options to pass to the fetch function
 * @property {CompletionCallback} [onComplete] - Called when a request successfully completes
 * @property {ErrorCallback} [onError] - Called when a request fails with an error
 * @property {string} [getResult] - Called with the Response object to get the data from the response
 */

/**
 * @typedef {Object} UseFetchResult
 * @property {boolean} isLoading
 * @property {(Error|null)} error
 * @property {(Object|null)} data
 * @property {boolean} isAuthenticated
 */

/**
 * Fetches data from a URL on mount and whenever the URL changes
 * @param {(string|string[]|UseFetchOptions)} options
 * @returns {UseFetchResult}
 */
function useFetch(options) {
    const { url, opts, onComplete, onError, getResult } = getFetchConfig(options)
    const savedOpts = useRef(undefined)

    validateCallback(onComplete, 'onComplete')
    validateCallback(onError, 'onError')
    const [{ isLoading, error, data, isAuthenticated, statusCode }, dispatch] = useFetchState(true)
    const fetchData = useFetchData(dispatch, onComplete, onError, getResult)

    useEffect(() => {
        savedOpts.current = opts
    }, [opts])

    const doFetch = useCallback(() => {
        fetchData(url, savedOpts.current).catch(() => null)
    }, [fetchData, url])

    useEffect(() => {
        doFetch()
    }, [doFetch])

    return { isLoading, data, error, statusCode, isAuthenticated, doFetch }
}

/**
 * @property {function(): void} doFetch
 * @typedef {UseFetchResult|doFetch} UseLazyFetchResult
 */

/**
 * Fetches data from a URL when the doFetch function is called
 * @param {(string|string[]|UseFetchOptions)} options
 * @param {boolean} initialLoading
 * @return {UseLazyFetchResult}
 */
function useLazyFetch(options) {
    const { url, opts, onComplete, onError, getResult } = getFetchConfig(options)
    const savedOpts = useRef(undefined)

    validateCallback(onComplete, 'onComplete')
    validateCallback(onError, 'onError')
    const [{ isLoading, error, data, isAuthenticated, statusCode }, dispatch] = useFetchState(false)
    const fetchData = useFetchData(dispatch, onComplete, onError, getResult)

    useEffect(() => {
        savedOpts.current = opts
    }, [opts])

    const doFetch = useCallback(() => {
        fetchData(url, savedOpts.current).catch(() => null)
    }, [fetchData, url])

    return { isLoading, data, error, isAuthenticated, statusCode, doFetch }
}

/**
 * @typedef {UseFetchOptions} UseFetchPagedOptions
 * @property {number} [pageSize] - the number of items to fetch per page
 */

/**
 * @callback FetchPagedData
 * @param {Object} [query]
 */

/**
 * @typedef {UseFetchResult} UseFetchPagedResult
 * @property {Object[]} items
 * @property {boolean} hasNextPage
 * @property {FetchPagedData} doFetch - fetch the first page of data
 * @property {FetchPagedData} fetchMore - fetch an additional page of data
 */

/**
 * Fetches paginated data from a URL
 * @param {(string|UseFetchPagedOptions)} options
 * @return {UseFetchPagedResult}
 */
function useFetchPaged(options) {
    const { url, opts, pageSize, onComplete = noop, onError } = getFetchConfig(options)
    const savedOpts = useRef(undefined)
    const savedOnComplete = useRef(null)
    validateCallback(onComplete, 'onComplete')
    validateCallback(onError, 'onError')

    useEffect(() => {
        savedOnComplete.current = onComplete
    }, [onComplete])
    useEffect(() => {
        savedOpts.current = opts
    }, [opts])

    const [{ isLoading, error, statusCode, isAuthenticated }, dispatch] = useFetchState(false)
    const [items, setItems] = useState([])
    const [currentPageInfo, setCurrentPageInfo] = useState({ hasNextPage: false })

    const setData = useCallback(({ items, pageInfo }) => {
        setCurrentPageInfo(pageInfo)
        const newItems = map(items, (i) => i.item)
        setItems(newItems)
        savedOnComplete.current(newItems)
    }, [])

    const updateData = useCallback(({ items, pageInfo }) => {
        setCurrentPageInfo(pageInfo)
        setItems((currentItems) => {
            const newItems = currentItems.concat(map(items, (i) => i.item))
            savedOnComplete.current(newItems)
            return newItems
        })
    }, [])

    const fetchData = useFetchData(dispatch, setData, onError)
    const refetchData = useCallback(
        (query) => {
            setItems((items) => (items.length > 0 ? [] : items))
            setCurrentPageInfo((pageInfo) => (pageInfo.hasNextPage ? { hasNextPage: false } : pageInfo))
            const searchParams = compactObject({ ...query, first: pageSize })
            return fetchData(url, merge({}, savedOpts.current, { searchParams })).catch(() => null)
        },
        [fetchData, url, pageSize]
    )

    const fetchMoreData = useFetchData(dispatch, updateData, onError)
    const fetchNextPage = useCallback(
        (query) => {
            if (!currentPageInfo.hasNextPage) {
                return
            }
            const searchParams = compactObject({ ...query, first: pageSize, after: currentPageInfo.endCursor })
            return fetchMoreData(
                url,
                merge({}, savedOpts.current, {
                    searchParams,
                })
            ).catch(() => null)
        },
        [fetchMoreData, url, pageSize, currentPageInfo]
    )

    return {
        doFetch: refetchData,
        fetchMore: fetchNextPage,
        hasNextPage: currentPageInfo.hasNextPage,
        items,
        isLoading,
        error,
        statusCode,
        isAuthenticated,
    }
}

function getPostOptions(opts, data, query) {
    return merge({}, opts, {
        method: 'POST',
        json: data,
        searchParams: query,
    })
}

/**
 * @typedef {Object} UsePostResult
 * @property {boolean} isPosting
 * @property {object} data
 * @property {object} error
 * @property {boolean} isAuthenticated
 * @property {function} reset - Resets the data, loading, and error state to their default
 * @property {function(data): void} doPost
 */

/**
 * Posts data to a URL when the doPost function is called
 * @param {string|UseFetchOptions} options
 * @return {UsePostResult}
 */
function usePost(options) {
    const { url, opts, onComplete, onError } = getFetchConfig(options)
    const savedOpts = useRef(undefined)

    validateCallback(onComplete, 'onComplete')
    validateCallback(onError, 'onError')
    const [{ isLoading, error, data, statusCode, isAuthenticated }, dispatch] = useFetchState(false)
    const fetchData = useFetchData(dispatch, onComplete, onError)

    useEffect(() => {
        savedOpts.current = opts
    }, [opts])

    const doPost = useCallback(
        (data, query = null) => fetchData(url, getPostOptions(savedOpts.current, data, query)),
        [fetchData, url]
    )

    const reset = useCallback(() => dispatch(fetchReset()), [dispatch])

    return { isPosting: isLoading, data, error, reset, statusCode, isAuthenticated, doPost }
}

export { useFetch, useLazyFetch, useFetchPaged, usePost, getFetchConfig }
