import type { Dispatch, ReducerAction } from 'react'
import mapValues from 'lodash/mapValues'

export const createAction =
    <T, R extends string>(type: R) =>
    (payload: T) => ({ type, payload })

/*
  this is a 'hack' around being unable to union createAction<never, 'action_string'>
  and createAction<some_type, 'action_string'>. If you union the ReturnType of those
  two together, typescript infers the union as "any" and creating a new fn gets around
  it. There's most definitely some more typescript appropriate way to do it, but
  idk what it is at the moment.
 */
export const createActionWithoutPayload =
    <R extends string>(type: R) =>
    () => ({ type })

type CreateActionReturnType = ReturnType<typeof createAction> | ReturnType<typeof createActionWithoutPayload>

export const createDispatchableActions = <T extends Record<string, CreateActionReturnType>>(
    actions: T,
    dispatch: Dispatch<ReducerAction<CreateActionReturnType>>
) =>
    mapValues(
        actions,
        (action) =>
            <T>(data: T) =>
                dispatch(action(data))
    )
