import { routes } from 'constants/routes'
import TaskDefinition from 'constants/taskDefinition'

export const SetupTask = {
    Award: TaskDefinition.SelectAwards,
    Create: 'create',
    Recipients: 'recipients',
    Schedule: 'schedule',
    ScheduleInvitations: TaskDefinition.ChooseSurveyStartDate,
    TestDelivery: TaskDefinition.TestInvitationEmail,
    SendHeadsUp: TaskDefinition.SendHeadsUpEmail,
    ApproveSurveyLaunch: TaskDefinition.ApproveSurveyLaunch,
    Summary: 'summary',
}

export type WorkflowTask = {
    id: string | number
    route: (params: Record<string, string | number>) => string
    children?: Array<{ id: typeof TaskDefinition[keyof typeof TaskDefinition] }>
    clientOnly?: boolean
    alwaysEnabled?: boolean
    initialInProgress?: boolean
}

export const workflowTasks: WorkflowTask[] = [
    {
        id: SetupTask.Create,
        route: routes.survey.setup.create,
        children: [{ id: TaskDefinition.ConfirmSurvey }, { id: TaskDefinition.CreateSurvey }],
    },
    {
        id: SetupTask.Recipients,
        route: routes.survey.setup.recipients,
        children: [
            { id: TaskDefinition.ConfirmRecipients },
            { id: TaskDefinition.FormatSurvey },
            { id: TaskDefinition.OrderPaperSurveys },
            { id: TaskDefinition.UploadEmployees },
            { id: TaskDefinition.SmsSetup },
            { id: TaskDefinition.SurveyScope },
        ],
        clientOnly: true,
    },
    {
        id: SetupTask.Schedule,
        route: routes.survey.setup.schedule,
        children: [
            { id: SetupTask.ScheduleInvitations },
            { id: SetupTask.TestDelivery },
            { id: SetupTask.SendHeadsUp },
        ],
    },
    {
        id: SetupTask.Summary,
        route: routes.survey.setup.summary,
        children: [{ id: SetupTask.ApproveSurveyLaunch }],
        initialInProgress: true,
        clientOnly: true,
        alwaysEnabled: true,
    },
]

const awardSelectionTask: WorkflowTask = {
    id: SetupTask.Award,
    route: routes.survey.setup.awards,
    alwaysEnabled: true,
}

export function getWorkflowTasks(awardSelectionEnabled: boolean) {
    const defaultWorkflowTasks = workflowTasks

    return awardSelectionEnabled ? [awardSelectionTask].concat(defaultWorkflowTasks) : defaultWorkflowTasks
}
