import { useEffect } from 'react'
import { LegacyButton as Button } from '@energage/components'
import { Icon } from '@energage/icons'
import { colors, margin } from '@energage/theme'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { TERMS_URL } from 'constants/strings'
import { ReactComponent as FacebookIcon } from 'images/facebook.svg'
import { ReactComponent as LinkedInIcon } from 'images/linkedin.svg'
import { ReactComponent as TwitterIcon } from 'images/twitter.svg'
import { media } from 'style/breakpoints'

function scrollToTop() {
    window.scroll({
        top: 0,
        behavior: 'smooth',
    })
}

const MainContentFooter = styled.div.attrs({
    'className': 'absolute bottom-0 w-full bg-grey700 mt-20 h-32 sm:h-24',
    'data-testid': 'footer',
})`
    .nonSocialLinks {
        a {
            color: white;
            margin-left: 10px;
            margin-right: 10px;
            text-decoration: none;

            &:hover {
                color: ${colors.blue500};
            }
        }

        .btn {
            color: white;
            margin-left: 10px;
            margin-right: 10px;
            padding: 0px;

            text-decoration: none;
            font-weight: 400;

            &:hover {
                color: ${colors.blue500};
            }
        }
    }

    .socialLinksAndMission {
        a {
            margin: 0.75rem;
        }
    }

    .backToTopButton {
        transform: scale(0.5);
        margin-top: ${margin(-40)};
        right: ${margin(-4)};
    }

    .socialIcon {
        height: 25px;
        width: 25px;
    }

    ${media.smMin`

        .socialLinksAndMission {
            a {
                margin: 0.25rem;
            }
        }

        .backToTopButton {
            transform:scale(0.6);
            margin-top: -3rem;
        }

        .socialIcon {
            height: 20px;
            width: 20px;
        }

        .missionStatement {
            padding-left: 2em;
            text-indent:-1.7em;
        }
    `}
`

const Footer = ({ tracking }) => {
    const [ref, showBackToTop] = useInView({ threshold: 0.75 })
    const [footerRef, footerVisible] = useInView()

    useEffect(() => {
        if (footerVisible) {
            document.body.classList?.add('footer-visible')
        } else {
            document.body.classList?.remove('footer-visible')
        }
    }, [footerVisible])

    return (
        <>
            <div className="absolute top-0 right-0 h-screen w-px pointer-events-none" ref={ref} />
            <MainContentFooter ref={footerRef}>
                <div className="contactAndFAQ h-auto w-full text-center sm:w-2/3 sm:float-left sm:h-full sm:text-left md:w-1/2">
                    <div className="nonSocialLinks mt-4 sm:mt-10 text-grey400 text-sm pl-6">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.energage.com/contact/"
                            onClick={() => tracking.trackEvent({ event: 'click', specificComponent: 'ContactUsLink' })}>
                            {'Contact Us'}
                        </a>
                        {'\u00a0|\u00a0'}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.energage.com/privacy/"
                            onClick={() =>
                                tracking.trackEvent({ event: 'click', specificComponent: 'PrivacyPolicyLink' })
                            }>
                            {'Privacy Policy'}
                        </a>
                        {'\u00a0|\u00a0'}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={TERMS_URL}
                            onClick={() => tracking.trackEvent({ event: 'click', specificComponent: 'TermsLink' })}>
                            {'Terms'}
                        </a>
                        {window.Osano?.cm?.mode !== 'debug' && (
                            <>
                                {'\u00a0|\u00a0'}
                                <Button
                                    variant="link"
                                    onClick={() => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}>
                                    {'Cookie Preferences'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className="socialLinksAndMission text-grey500 text-sm text-center mt-2 sm:w-1/3 sm:float-left sm:mt-10 md:w-1/2">
                    <div className="inline-block text-center sm:table sm:float-right sm:mr-10 md:mr-6 sm:text-left">
                        <div className="w-full inline-block mt-2 sm:table-cell sm:w-24 whitespace-no-wrap align-top">
                            <a
                                aria-label="Energage Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/Energage/"
                                onClick={() =>
                                    tracking.trackEvent({ event: 'click', specificComponent: 'FacebookLink' })
                                }>
                                <Icon as={FacebookIcon} size={20} className="socialIcon" />
                            </a>
                            <a
                                aria-label="Energage LinkedIn"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/company/energage/"
                                onClick={() =>
                                    tracking.trackEvent({ event: 'click', specificComponent: 'LinkedInLink' })
                                }>
                                <Icon as={LinkedInIcon} size={20} className="socialIcon" />
                            </a>
                            <a
                                aria-label="Energage Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/teamenergage"
                                onClick={() =>
                                    tracking.trackEvent({ event: 'click', specificComponent: 'TwitterLink' })
                                }>
                                <Icon as={TwitterIcon} size={20} className="socialIcon" />
                            </a>
                        </div>
                        <div className="w-full mt-2 sm:w-auto sm:table-cell sm:mt-0 align-top">
                            <div className="inline sm:table-cell whitespace-no-wrap">{'\u00a9 Energage LLC'}</div>
                            <div className="missionStatement hidden md:table-cell">
                                <div className="inline mx-2">{' | '}</div>
                                {'Making the world a better place to work together\u2122'}
                            </div>
                        </div>
                    </div>
                </div>
                {!showBackToTop && (
                    <img
                        onClick={() => {
                            scrollToTop()
                            tracking.trackEvent({ event: 'click', specificComponent: 'ScrollToTop' })
                        }}
                        title="Return to top"
                        className="backToTopButton cursor-pointer absolute right-0"
                        alt="back-to-top-button"
                        src={require('images/Take_to_top_btn.png').default}
                    />
                )}
            </MainContentFooter>
        </>
    )
}

export default Footer
