import { createContext, useContext, useState } from 'react'
import type { SetStateAction } from 'react'
import forEach from 'lodash/forEach'
import type { Noop } from 'react-hook-form'

type SetField = (_Zzzstate: SetStateAction<Noop[]>) => void
type ShowMessage = (showMessage: boolean) => void

const defaultAwardSelectionTable = {
    employeeCountsBetweenNationalAwardsMessage: '',
    showEmployeeCountsBetweenNationalAwardsMessage: false,
    changeEmployeeCountsBetweenNationalAwardsMessage: (() => undefined) as ShowMessage,
    fields: [] as Noop[],
    setFields: (() => undefined) as SetField,
    nationalInputOnBlur: (): void => undefined,
}

const AwardSelectionTableContext = createContext(defaultAwardSelectionTable)

function useAwardSelectionTableContext() {
    const context = useContext(AwardSelectionTableContext)

    if (!context) {
        throw new Error('useAwardSelectionTableContext must be used within a AwardSelectionTableContextProvider')
    }

    return context
}

function AwardSelectionTableProvider({ children }: { children: React.ReactNode }) {
    const [showEmployeeCountsBetweenNationalAwardsMessage, setShowEmployeeCountsBetweenNationalAwardsMessage] =
        useState(false)
    const employeeCountsBetweenNationalAwardsMessage = 'National program employee counts must match'

    const [fields, setFields] = useState<Noop[]>([])

    const changeEmployeeCountsBetweenNationalAwardsMessage = (showMessage: boolean) => {
        setShowEmployeeCountsBetweenNationalAwardsMessage(showMessage)
    }

    const nationalInputOnBlur = () => {
        forEach(fields, (field) => field())
    }

    const valueToShare = {
        employeeCountsBetweenNationalAwardsMessage,
        showEmployeeCountsBetweenNationalAwardsMessage,
        changeEmployeeCountsBetweenNationalAwardsMessage,
        fields,
        setFields,
        nationalInputOnBlur,
    }

    return <AwardSelectionTableContext.Provider value={valueToShare}>{children}</AwardSelectionTableContext.Provider>
}

export { useAwardSelectionTableContext, AwardSelectionTableProvider }
