import filter from 'lodash/filter'
import find from 'lodash/find'
import size from 'lodash/size'
import styled from 'styled-components'
import config from 'config'
import BadgeGroup from 'containers/Main/EmployerBranding/CultureBadges/BadgeGroup'
import { alignments } from 'containers/Main/EmployerBranding/CultureBadges/utils'
import { useFetch } from 'hooks'
import { parseQueryParams } from '../Widgets/QueryParser'

const MAX_WIDTH = 1024
const MULTIPLIER = 256

const Container = styled.div`
    width: ${(props) => props.width}px;
`

const getMaxBadgeCountInARow = (badges) => {
    if (size(badges) > 4) {
        return 4
    }
    if (size(badges) === 1) {
        return 2
    }
    return size(badges)
}

export const MultiCultureBadgeFetch = ({ location }) => {
    const { multiCultureBadgeTileId, useFullWidth } = parseQueryParams(location)
    const { data } = useFetch(`${config.api.workplaceSurvey}/CultureBadges/Multi/${multiCultureBadgeTileId}`)

    if (!data) {
        return null
    }

    const { organizationName, backgroundColor } = data
    const badgesWithStatus = filter(data.cultureBadges, (b) => b.isUnlocked)
    const alignment = find(alignments, (a) => a.width === data.alignment)
    const containerClassName = useFullWidth ? 'w-full' : alignment.className
    const maxBadgeCountInARow = getMaxBadgeCountInARow(badgesWithStatus)
    const totalWidth = useFullWidth ? maxBadgeCountInARow * MULTIPLIER : MAX_WIDTH

    return (
        <Container width={totalWidth}>
            <div id="multi-culture-badge-tile-container" className={containerClassName}>
                <BadgeGroup
                    organizationName={organizationName}
                    badges={badgesWithStatus}
                    footerBackgroundColor={backgroundColor}
                    selectedAlignment={alignment}
                    isRendering
                />
            </div>
        </Container>
    )
}
