import { useCallback } from 'react'
import { alert } from '@energage/components'
import { useHistory } from 'react-router-dom'
import { useManagementQuery, useWorkplaceSurveyQuery } from 'api'
import { useIdentity } from 'components/Identity'
import { routes } from 'constants/routes'

export function useSaveSurvey() {
    const { surveyCompanyId, organizationId } = useIdentity()
    const history = useHistory()

    const { mutateAsync: createSurvey, status: surveyEventStatus } = useWorkplaceSurveyQuery.mutate(
        `surveyCompanies/${surveyCompanyId}/surveydefinition/createsurvey`
    )
    const { mutateAsync: createSurveySetup, status: surveySetupStatus } = useManagementQuery.mutate('surveyEventSetup')

    const postSurvey = useCallback(
        async ({ surveyGenerationId, title, isTwpParticipant, levelOfConfidentialityId = 1 }) => {
            const monthName = new Date().toLocaleString(window.navigator.language, { month: 'long' })
            const year = new Date().getFullYear()
            const surveyName = isTwpParticipant ? `${monthName} ${year} Top Workplaces Survey` : title

            try {
                const { surveyEventId } = await createSurvey({
                    surveyCompanyId,
                    surveyName,
                    surveyGenerationId,
                    isTwpParticipant,
                    levelOfConfidentialityId,
                })

                await createSurveySetup({ surveyEventId, organizationId })
                history.push(routes.survey.setup({ surveyEventId }))
            } catch (e) {
                alert.danger(`There was an error creating the survey.`)
            }
        },
        [history, organizationId, createSurvey, createSurveySetup, surveyCompanyId]
    )

    return { postSurvey, loading: surveyEventStatus === 'loading' || surveySetupStatus === 'loading' }
}
