import type { ReactNode } from 'react'
import { SpeechBubble } from '@energage/components'
import styled from 'styled-components'
import sciencetistAvatar from 'images/scienceLab/sciencetistAvatar.png'
import type { GroupTile, Tiles, WordTile } from './models'
import tileData from './scienceTiles.json'
import { DataCard } from './tiles/DataCard'
import { ImageCard } from './tiles/ImageCard'

const Bubble = styled(SpeechBubble)`
    width: 356px;
`

const PercentageGroup = ({ children }: { children: ReactNode }) => (
    <div className="grid grid-cols-1 gap-4">{children}</div>
)

const renderTile = (tile: Tiles) => {
    switch (tile.type) {
        case 'data':
            return <DataCard key={tile.id} {...tile} />
        case 'group':
            return <GroupCard key={tile.id} {...tile} />
        case 'image':
            return <ImageCard key={tile.id} {...tile} />
        case 'word':
            const wordTile = tile as WordTile
            return (
                <Bubble key={tile.id} tailPlacement={wordTile.tailPlacement}>
                    {wordTile.text}
                </Bubble>
            )
        default:
            return <></>
    }
}

// Assuming all grouped cards are Percentage groups for now
const GroupCard = ({ items }: GroupTile) => {
    return <PercentageGroup>{items.map(renderTile)}</PercentageGroup>
}

export const ScienceLabContainer = () => {
    const tiles = tileData as Tiles[]

    return (
        <div aria-hidden="true" data-testid="data-science-lab">
            <h2>{'From our data science lab'}</h2>
            <p className="pb-6 paragraph-lead">
                {'Dig into data trends from the thousands of companies who have surveyed with us'}
            </p>
            <div className="flex flex-wrap w-auto gap-6 mb-10 items-center">
                <img alt="Keep up with our latest data" src={sciencetistAvatar} />
                {tiles.map(renderTile)}
            </div>
        </div>
    )
}
