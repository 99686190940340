import config from 'config'

export const INTERNAL_COMPANY_NAME = 'Energage'
export const FULL_COMPANY_NAME = 'Energage, LLC'
export const SUPPORT_PHONE = '1-800-749-0187'
export const SUPPORT_EMAIL = 'support@energage.com'
export const SURVEY_EMAIL = 'survey@energage.com'
export const INFO_EMAIL = 'info@energage.com'
export const ELECTRONIC_PAYMENT_EMAIL = 'AR@energage.com'
export const ONBOARDING_SUPPORT_EMAIL = 'onboardingsupport@energage.com'

export const POWERED_BY_ENERGAGE_URL = 'https://www.energage.com/powered-by-energage/'
export const TERMS_URL = 'https://www.energage.com/terms/'
export const TWP_GUIDELINES =
    'https://energagecommunity.force.com/FAQ/s/article/Employee-Count-Guidelines-for-Adding-Awards-to-a-Closed-Survey'

export const EMPLOYER_BRANDING_DISPLAY_NAME = 'Employer Branding'
export const COMPANY_MANAGEMENT_DISPLAY_NAME = 'Organization Management'

export const ENERGAGE_ADDRESS = {
    line1: '397 Eagleview Blvd, Ste 200',
    line2: 'Exton, PA 19341',
}

export const ENERGAGE_URL = 'http://www.energage.com/'
export const FAQ_URL = `${config.urls.topWorkplaces}/faqs`
