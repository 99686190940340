import { Loading } from '@energage/components'
import { useIdentity } from 'components/Identity'
import type { UserType } from '../models'
import { UserFormContainer } from '../UserFormContainer'
import { ADD_USER } from '../UserManagement.util'
import { useCreateCompanyUser, useGetCompanySurveyEvents } from '../useUserManagementQueries'

function AddUser() {
    const {
        surveyCompanyId,
        company: { isPublisher },
    } = useIdentity()
    const { data: surveyEventsData } = useGetCompanySurveyEvents(surveyCompanyId)
    const defaultValues: UserType = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        isCustomerSuccessPrimaryContact: false,
        isDisabled: false,
        roles: isPublisher
            ? { hasAdminAccess: false, hasMarketingAccess: false }
            : {
                  hasAdminAccess: false,
                  hasBrandingAccess: false,
                  hasInsightsAccess: false,
                  hasSurveyAccess: false,
                  departments: [],
                  isActionTrackerRestriction: false,
                  hasSecurityAccess: false,
              },
        securitySettings: {
            hasActiveMfa: false,
        },
    }

    const { mutateAsync: createUser, isLoading, error } = useCreateCompanyUser(surveyCompanyId)

    if (!surveyEventsData) {
        return <Loading />
    }

    return (
        <UserFormContainer
            defaultValues={defaultValues}
            surveyEvents={surveyEventsData}
            action={ADD_USER}
            onSave={createUser}
            isLoading={isLoading}
            savingError={error?.parsed?.data}
        />
    )
}

export { AddUser }
